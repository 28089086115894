import Moment from "moment-business-days";
import utils from "root/utils/functions";
import {
    getDeliveryDaysForSubServices,
    createSubServicePriceMap,
    getFinalPriceDays2,
    loadSubServiceVersion_Worker,
    getWorkerHours,
    getSubServiceCost,
    getWorkHours,
    getServicePrice2,
    getSubServicesForService,
    getTasksForService,
    getServicePackageData,
    getServiceOptionData,
    getVisiblePackage,
    getMinDeliveryDays,
    getVersionData,
    getServicePreReqItems,
    getWorkerRate, clearCache, getServicePriceAndDays2, createServicePriceMap, getSubServiceCost2, getDataFromPriceMap, getPriceMapFromPriceMap, getDoneDate, getStartDate, calcStartDayIndex, getParentPath
} from "root/service-functions";

const { roundMoney, profitMargins, getValueItem, roundNumber, updateCartItem, getAllItemsForProposal, getAllItemsForVersion, getDeleteRemovedItems, pluralText, pluralTab, plural, getLabelItem, money, skillLevels, staffServiceTypes } = utils;

const getTasks = function (tasks: Array<any>) {
    return tasks.map(item => {
        return {
            ...item,
            getStartDate: function () {
                const { delayOptions, requiredPreServices } = item

                const { delayStartDate, delayType, delayStart, delayDays } = delayOptions ? delayOptions : {};
                var dt = Moment().startOf("day");

                if (requiredPreServices && requiredPreServices.length > 0) {
                    const parentItem = this.parentItem
                    const items = [...parentItem.tasks.map(item => { return { id: item.task.id, item } }), ...parentItem.subServices.map(item => { return { id: item.subService.id, item } })]

                    requiredPreServices.forEach(pId => {
                        const item = items.find(item => item.id == pId);

                        if (item) {
                            const doneDate = item.item.getDoneDate ? item.item.getDoneDate() : null

                            if (doneDate && doneDate > dt) {
                                dt = doneDate;
                            }
                        }
                    });
                }

                if (delayStart) {
                    if (delayType == "start-date") {
                        if (dt < delayStartDate) {
                            dt = delayStartDate;
                        }
                    } else if (delayType == "start-of-order") {
                        dt = Moment()
                            .businessAdd(delayDays)
                            .toDate();
                    } else if (delayType == "days-from-pre-req") {
                        dt = Moment(dt)
                            .businessAdd(delayDays)
                            .toDate();
                    }
                }
                return Moment(dt).toDate();
            },
            getDoneDate: function () {
                const startDate = this.getStartDate();

                const { hours } = item
                return Moment(startDate)
                    .add(hours, "hours")
                    .toDate();
            }
        }
    })
}

const doMasterOverrides2 = function ({ rootItem, rtn, masterOverrides }) {
    masterOverrides.forEach(masterOverride => {
        const { proposalItem } = masterOverride

        const index = rootItem.items.findIndex(item => item.proposalItem.id == proposalItem.id)
        const item = rootItem.items[index]
        if (item) {
            const { itemType, id } = proposalItem
            const path = `p-item[${id}]`
            const newItem = getMainServiceModel({ proposalItem, rootItem, configData: masterOverride })
            newItem.itemType = itemType
            newItem.proposalItem = proposalItem
            newItem.path = path
            rootItem.items[index] = newItem
        }
    })
}

const doMasterOverrides = function ({ rootItem, rtn, masterOverrides }) {
    masterOverrides.forEach(masterOverride => {
        const { path } = masterOverride
        const sp = path.split(".")
        var item = rtn
        for (var i = 0; i < sp.length; i++) {
            const pid = sp[i]
            const foundItem = item.subServices.find(item => item.pid == pid)
            if (foundItem) {
                item = foundItem
            } else {
                item = null
                break
            }
        }

        if (item) {
            const { parentItem } = item
            const subServices = parentItem.subServices
            const index = subServices.findIndex(item => item.pid == sp[sp.length - 1])
            const oldItem = subServices[index]
            const { subServiceId } = oldItem
            subServices[index] = getSubServiceObj({ subServiceId, rootItem, parentItem, subServiceVersion: masterOverride, path: parentItem.path })
        }
    })
}

const getDateAdd = function ({ noBusinessDays, date, days }) {
    if (noBusinessDays) {
        return Moment(date)
            .add(days, "days")
            .toDate();
    } else {
        return Moment(date)
            .businessAdd(days)
            .toDate();
    }
}

const getSubServiceObj = function ({ proposalItem, subServiceId, rootItem, parentItem, subServiceVersion, path: startPath }) {
    //check for validate data or not, show if invalidate
    const { requiredPreServices, delayOptions, isRemoved, actualServiceVersion, packageId, serviceOptionIds, deliveryType, subService } = subServiceVersion
    //const { id } = subService

    const pid = `service[${subServiceId}]`
    const path = (startPath ? startPath + "." : "") + pid

    const tasks = getTasksForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });
    const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

    const rtn = MainItem({
        proposalItem,
        subServiceId,
        rootItem,
        subService,
        isRemoved,
        parentItem,
        pid,
        path,
        configData: subServiceVersion,
        tasks: getTasks(tasks)
    })

    rtn.subServices = subServices.map(subServiceVersion => getSubServiceObj({ proposalItem, subServiceId: subServiceVersion.subService.id, rootItem, parentItem: rtn, subServiceVersion, path }))

    const { masterOverrides } = actualServiceVersion
    doMasterOverrides({ rootItem, rtn, masterOverrides })

    return rtn
}

export function getMainServiceModel({ proposalItem, rootItem, configData }) {
    //check for validate data or not
    const { requiredPreServices, actualServiceVersion, packageId, serviceOptionIds, deliveryType, delayOptions } = configData

    const tasks = getTasksForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });
    const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

    const rtn = MainItem({
        proposalItem,
        rootItem,
        configData,
        tasks: getTasks(tasks),
        setOverride: function ({ path, masterOverride }) {
            const sp = path.split(".")
            const item = this.getItem(path)

            const { parentItem } = item
            const subServices = parentItem.subServices
            const index = subServices.findIndex(item => item.pid == sp[sp.length - 1])
            const oldItem = subServices[index]
            const { subServiceId } = oldItem
            const newItem = getSubServiceObj({ proposalItem, subServiceId, rootItem, parentItem, subServiceVersion: masterOverride, path: parentItem.path })
            subServices[index] = newItem
            return newItem
        }
    })

    rtn.subServices = subServices.map(subServiceVersion => getSubServiceObj({ proposalItem, subServiceId: subServiceVersion.subService.id, rootItem, parentItem: rtn, subServiceVersion }))

    const { masterOverrides } = actualServiceVersion
    doMasterOverrides({ rootItem, rtn, masterOverrides })

    return rtn
}

export function getMainProposalModel({ proposal, proposalVersion }) {
    const allItemsV = getAllItemsForVersion(proposalVersion).values()
    const allItems = proposal.allItems

    const items = []

    const factory = {
        items,
        getServiceItems: function () {
            return items.filter(item => item.itemType == "service")
        },
        getStaffItems: function () {
            return items.filter(item => item.itemType == "staff")
        },
        getItem: function ({ proposalItemId }) {
            return items.find(item => item.proposalItem.id == proposalItemId)
        },
        getMicroServices: function () {
            const serviceItems = this.getServiceItems()
            var arr = []
            serviceItems.forEach(item => {
                arr.push(item)
                arr = arr.concat(item.getItems())
            })
            return arr
        }
    }

    allItemsV.forEach(({ id }) => {
        const proposalItem = allItems.get(id)
        const { itemType } = proposalItem
        const path = `p-item[${id}]`
        if (itemType == "service") {
            const newItem = getMainServiceModel({ proposalItem, rootItem: factory, configData: proposalItem })
            newItem.itemType = itemType
            newItem.proposalItem = proposalItem
            newItem.path = path
            items.push(newItem)

        } else if (itemType == "staff") {
            //this is a staff order
            items.push({
                rootItem: factory,
                itemType,
                path,
                proposalItem,
                getStartDate: function () {
                    //check for pre-required for this staff role as well
                    return this.proposalItem.startDate
                },
                getDoneDate: function () {
                    return this.proposalItem.doneDate
                }
            })
        }
    })

    const { masterOverrides } = proposalVersion
    doMasterOverrides2({ rootItem: factory, masterOverrides })
    return factory
}

export function getMainProposalModel2({proposal, proposalItems }) {
    const items = []

    const factory = {
        items,
        getDeliveryDays: function () {
            const startDate = factory.getStartDate({ noBusinessDays: true })
            const doneDate = factory.getDoneDate({ noBusinessDays: true })
            return Moment(doneDate).diff(startDate, "days")
        },
        getStartDate: function (props) {
            const { noBusinessDays } = props ? props : {}

            var minStartDate
            items.forEach(item => {
                const startDate = item.getStartDate({ noBusinessDays });

                if (!minStartDate || startDate < minStartDate) {
                    minStartDate = startDate;
                }
            });

            return minStartDate
        },
        getDoneDate: function (props) {
            const { noBusinessDays } = props ? props : {}

            var maxDoneDate
            items.forEach(item => {
                const doneDate = item.getDoneDate({ noBusinessDays });

                if (!maxDoneDate || doneDate > maxDoneDate) {
                    maxDoneDate = doneDate;
                }
            });

            return maxDoneDate
        },
        getServiceItems: function () {
            return items.filter(item => item.itemType == "service")
        },
        getStaffItems: function () {
            return items.filter(item => item.itemType == "staff")
        },
        getItem: function (path) {
            const sp = path.split(".")
            const foundItem = items.find(item => item.path == sp[0])
            if (foundItem) {
                return foundItem.getItem(sp.slice(1).join("."))
            }
        },
        getItemPP: function ({ proposalItemId }) {
            return items.find(item => item.proposalItem.id == proposalItemId)
        },
        getMicroServices: function () {
            const serviceItems = this.getServiceItems()
            var arr = []
            serviceItems.forEach(item => {
                arr = arr.concat(item.getItems())
            })
            return arr
        }
    }

    proposalItems.forEach((proposalItem) => {
        const { itemType, id } = proposalItem
        const path = `p-item[${id}]`
        if (itemType == "service") {
            const newItem = getMainServiceModel({ path, proposalItem, rootItem: factory, configData: proposalItem })
            newItem.itemType = itemType
            newItem.proposalItem = proposalItem
            newItem.path = path
            items.push(newItem)

        } else if (itemType == "staff") {
            items.push({
                rootItem: factory,
                itemType,
                path,
                proposalItem,
                getStartDate: function () {
                    //check for pre-required for this staff role as well
                    return this.proposalItem.startDate
                },
                getDoneDate: function () {
                    return this.proposalItem.doneDate
                }
            })
        }
    })

    const { buyerMasterOverrides: masterOverrides } = proposal
    doMasterOverrides2({ rootItem: factory, masterOverrides })
    return factory
}

function MainItem(data) {
    const { configData, rootItem } = data
    const { overridePrice, requiredPreServices, actualServiceVersion, packageId, serviceOptionIds, deliveryType, delayOptions } = configData

    const factory = {
        ...data,
        getItems: function () {
            var arr = [factory]
            factory.subServices.forEach(subService => {
                const { isRemoved } = subService
                if (!isRemoved) {
                    arr = arr.concat(subService.getItems())
                }
            })
            return arr
        },
        getItem: function (path) {
            if (!path) {
                return factory
            }
            const sp = path.split(".")
            var item = factory
            for (var i = 0; i < sp.length; i++) {
                const pid = sp[i]
                const foundItem = item.subServices.find(item => item.pid == pid)
                if (foundItem) {
                    item = foundItem
                } else {
                    item = null
                    break
                }
            }

            return item
        },
        getStartDate: function (props) {
            const { noBusinessDays } = props ? props : {}
            const { delayStartDate, delayType, delayStart, delayDays } = delayOptions ? delayOptions : {};
            const parentItem = factory.parentItem

            var dt = Moment(parentItem ? parentItem.getStartDate({ noBusinessDays }) : new Date()).startOf("day");

            if (requiredPreServices && requiredPreServices.length > 0) {
                var items = []
                if (parentItem) {
                    items = [
                        ...parentItem.tasks.map(item => { return { id: item.task.id, item } }),
                        ...parentItem.subServices.map(item => { return { id: item.subService.id, item } })
                    ]
                }
                if (rootItem) {
                    items = [...items,
                    ...rootItem.items.map(item => {
                        return {
                            id: item.proposalItem.id,
                            item
                        }
                    })]
                }

                requiredPreServices.forEach(pId => {
                    const item = items.find(item => item.id == pId);

                    if (item) {
                        const doneDate = item.item.getDoneDate ? item.item.getDoneDate({ noBusinessDays }) : null

                        if (doneDate && doneDate > dt) {
                            dt = doneDate;
                        }
                    }
                });
            }

            if (delayStart) {
                if (delayType == "start-date") {
                    if (dt < delayStartDate) {
                        dt = delayStartDate;
                    }
                } else if (delayType == "start-of-order") {
                    return getDateAdd({ noBusinessDays, date: dt, days: delayDays })
                } else if (delayType == "days-from-pre-req") {
                    return getDateAdd({ noBusinessDays, date: dt, days: delayDays })
                }
            }
            return Moment(dt).toDate();
        },
        getDoneDate: function (props) {
            const { noBusinessDays } = props ? props : {}
            const startDate = factory.getStartDate({ noBusinessDays });

            var maxDoneDate
            factory.subServices.forEach(subService => {
                const { isRemoved } = subService
                if (!isRemoved) {
                    const doneDate = subService.getDoneDate({ noBusinessDays });

                    if (!maxDoneDate || doneDate > maxDoneDate) {
                        maxDoneDate = doneDate;
                    }
                }
            });

            const stDate = maxDoneDate ? maxDoneDate : startDate
            const deliveryDays = factory.getServiceDeliveryDays()
            return getDateAdd({ noBusinessDays, date: stDate, days: deliveryDays })
        },
        getServiceWorkHours: function () {
            return getServicePriceAndDays2({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds }).workHours
        },
        getServiceDeliveryDays: function () {
            return getServicePriceAndDays2({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds }).days
        },
        getFastServicePrice: function () {
            return getServicePriceAndDays2({ serviceVersion: actualServiceVersion, deliveryType: "fast", packageId, serviceOptionIds }).price
        },
        getServicePrice: function () {
            return getServicePriceAndDays2({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds }).price
        },
        getTotalDeliveryDays: function () {
            const startDate = factory.getStartDate({ noBusinessDays: true })
            const doneDate = factory.getDoneDate({ noBusinessDays: true })
            return Moment(doneDate).diff(startDate, "days")
        },
        getTotalFastPrice: function () {
            if (overridePrice != null) {
                return overridePrice
            }

            const price = factory.getFastServicePrice()

            var ssPrice = 0
            factory.subServices.forEach(subService => {
                const { isRemoved } = subService
                if (!isRemoved) {
                    const price = subService.getTotalFastPrice()
                    ssPrice += price
                }
            })

            return price + ssPrice
        },
        getTotalPrice: function () {
            if (deliveryType == "fast") {
                return factory.getTotalFastPrice()
            }

            if (overridePrice != null) {
                return overridePrice
            }

            const price = factory.getServicePrice()

            var ssPrice = 0
            factory.subServices.forEach(subService => {
                const { isRemoved } = subService
                if (!isRemoved) {
                    const price = subService.getTotalPrice()
                    ssPrice += price
                }
            })

            return price + ssPrice
        }
    }

    return factory
}