import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import dL from "root/utils/dl";
import db from "root/utils/db";
import http from "root/utils/http";
import utils from "root/utils/functions";
import session from "root/utils/session";
import PromiseA from "root/utils/promise";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { __DEV__ } from "root/dev";
import { SelectUsers, ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { SelectBox, LocationSearchInput, SortableList, ImageElement, ColorPicker, BoxItem, Loading, TabBar, Section, NoRecords, TrashIcon, Text, TouchButton, ModalBasic, FlexExpand, FlexRow, TextLine, View, FormItem, MyInput } from "root/pack-1";

export function Company() {
  //company members (role is admin or member)
  //can't delete the company admin unless change the admin first (admin)

  const { eid, companyId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ tabValue: "details", isLoading: true });
  const { members, member, newTeamMemberVisible, isLoading, company, tabValue } = state;
  const [projectVisible, setProjectVisible] = useState();
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    var promise = new PromiseA();
    if (eid) {
      promise = db
        .getQuery("Member")
        .equalTo("_id", eid)
        .containedIn("removed", [undefined, false])
        .select([])
        .first()
        .then(function (obj) {
          if (obj) {
            return obj
              .set("status", "")
              .set("user", db.getObj("User", session.user.id))
              .save();
          } else {
            alert("Company invitation not found.");
          }
        });
    } else {
      promise.resolve();
    }

    promise
      .then(function () {
        dL.getQuery("Company")
          .include("companyAdmin")
          .get(companyId)
          .then(function (obj) {
            const company = dL.loadCompany(obj);

            const newState = { ...state };
            const promises = [];


            promises[promises.length] = dL
              .getQuery("Member")
              .equalTo("company", dL.getObj("Company", companyId))
              .containedIn("removed", [undefined, false])
              .include("user")
              .find()
              .then(function (objs) {
                const members = dL.loadObjects("Member", objs);
                newState.members = members;
                newState.memberCount = members.length;
              });

            promises[promises.length] = dL
              .getQuery("Member")
              .equalTo("company", dL.getObj("Company", companyId))
              .equalTo("user", dL.getObj("User", session.user.id))
              .containedIn("removed", [undefined, false])
              .include("createdBy")
              .first()
              .then(function (obj) {
                if (obj) {
                  newState.member = dL.loadMember(obj);
                }
              });

            return Promise.all(promises).then(function () {
              newState.isLoading = false;
              newState.company = company;
              setState(newState);
            });
          });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { name, isArchived } = company;
  const { memberCount } = state;
  const { role } = member ? member : {};

  const buttons = [];
  if (role == "admin") {
    if (!isArchived) {
      buttons.push({
        label: "Edit",
        onPress: () => {
          setProjectVisible(true);
        }
      });
      buttons.push({
        label: "Delete",
        onPress: () => {
          dL.getObj("Company", companyId)
            .set("removed", true)
            .save()
            .then(function () {
              history.goBack();
            });
        }
      });
      buttons.push({
        label: "Archive",
        onPress: () => {
          dL.getObj("Company", companyId)
            .set("isArchived", true)
            .save()
            .then(function () {
              company.isArchived = true;
              setState({ ...state, company });
            });
        }
      });
    } else {
      buttons.push({
        label: "Un-Archive",
        onPress: () => {
          dL.getObj("Company", companyId)
            .set("isArchived", false)
            .save()
            .then(function () {
              company.isArchived = false;
              setState({ ...state, company });
            });
        }
      });
    }
  }

  const tabs = [];
  //tabs.push({ label: "Details", value: "details" });
  tabs.push({ label: "Team Members", value: "team" });

  return (
    <View>
      <HeaderText label={name} buttons={buttons} />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 15 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ? (
        <Section>
          <HeaderText subHeader label="Overview:" />
        </Section>
      ) : null}

      {tabValue == "team" ? (
        <Section>
          <HeaderText
            subHeader
            label="Team Members:"
            description="Add team member to your company."
            onButtonPress={
              role == "admin"
                ? () => {
                  setState({ ...state, newTeamMemberVisible: true });
                }
                : null
            }
          />

          <FlexRow>
            {members.length == 0 ? (
              <NoRecords label="No team members found." />
            ) : (
              members.map((item, index) => {
                const { user, status, email, id, role } = item;
                return (
                  <BoxRowItem
                    style={{ cursor: "pointer", height: 95, marginRight: 10, marginBottom: 10 }}
                    onPress={() => {
                      //show the profile of this person (?)
                    }}>
                    <FlexRow>
                      <FlexExpand>
                        {user ? (
                          <UserItem avatarSize={65} user={user}>
                            <FlexRow>
                              <TextLine value={role} uppercase size={10} spacer />
                              {status == "pending" ? <TextLine value="Pending" size={12} /> : null}
                            </FlexRow>
                          </UserItem>
                        ) : (
                          <View>
                            <TextLine value={email} />
                            <FlexRow>
                              <TextLine value={role} uppercase size={10} spacer />
                              {status == "pending" ? <TextLine value="Pending" size={12} /> : null}
                            </FlexRow>
                          </View>
                        )}
                      </FlexExpand>

                      {(user && user.id != session.user.id) || !user ? (
                        <TrashIcon
                          style={{ marginLeft: 25 }}
                          onPress={() => {
                            db.getObj("Member", id)
                              .set("removed", true)
                              .save();
                            members.splice(index, 1);
                            setState({ ...state, members });
                          }}
                        />
                      ) : null}
                    </FlexRow>
                  </BoxRowItem>
                );
              })
            )}
          </FlexRow>
        </Section>
      ) : null}

      {newTeamMemberVisible ? (
        <InviteMember
          companyId={companyId}
          onAddEmail={({ email }) => {
            return db
              .getObj("Member")
              .set("email", email)
              .set("company", db.getObj("Company", companyId))
              .set("createdBy", db.getObj("User", session.user.id))
              .set("status", "pending")
              .save();
          }}
          onAddUser={user => {
            const { id } = user;
            return db
              .getObj("Member")
              .set("user", db.getObj("User", id))
              .set("company", db.getObj("Company", companyId))
              .set("createdBy", db.getObj("User", session.user.id))
              .set("status", "pending")
              .save();
          }}
          onCancel={() => {
            setState({ ...state, newTeamMemberVisible: false });
          }}
          onDone={() => {
            setState({ ...state, newTeamMemberVisible: false });
            setRefresh(new Date());
          }}
        />
      ) : null}
      {projectVisible ? (
        <EditCompany
          companyId={companyId}
          onCancel={() => {
            setProjectVisible(false);
          }}
          onSave={() => {
            setProjectVisible(false);
            setRefresh(new Date());
          }}
        />
      ) : null}
    </View>
  );
}

export function Companies() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({})
  const [projectVisible, setProjectVisible] = useState();
  const refC = useRef();
  const userId = session.user.id;

  const buttons = [
    {
      label: "Add",
      onPress: () => {
        setProjectVisible(true);
      }
    }
  ];

  return (
    <React.Fragment>
      {projectVisible ? (
        <NewCompany
          onCancel={() => {
            setProjectVisible(false);
          }}
          onSave={() => {
            setProjectVisible(false);
            refC.current.refresh();
          }}
        />
      ) : null}

      <ListRender
        setRef={ref => {
          refC.current = ref;
        }}
        buttons={buttons}
        searchFields={[{ field: "name" }]}
        defaultSort={{ field: "createdAt", desc: true }}
        onWhere={(query) => {
          query.existsObj("company", true);
          query.equalTo("user", dL.getObj("User", userId));
        }}
        type="Member"
        emptyLabel="No companies created."
        includes={["company"]}
        title="Companies"
        description="Manage companies."
        renderItem={(item) => {
          const { role, company } = item;
          const { name, id } = company

          return (
            <BoxRowItem>
              <FlexRow alignTop>
                <FlexExpand>
                  <TextLine
                    size={22}
                    value={name}
                    onPress={() => {
                      history.push(`/${sectionId}/company/${id}`);
                    }}
                  />
                  <TextLine grey uppercase value={role} size={12} top={-4} />
                </FlexExpand>

                {!session.company || session.company.id != id ? <TouchButton micro onPress={() => {
                  session.company = company
                  session.companyMember = item
                  dL.getObj("User", session.user.id)
                    .set("defaultCompany", dL.getObj("Company", company.id))
                    .save()
                  session.dispatch("company-change")
                  setState({ ...state })
                }} label="Switch" /> : null}
              </FlexRow>
            </BoxRowItem>
          );
        }}
      />
    </React.Fragment>
  );
}

export function NewCompany({ onCancel, onSave }) {
  const [state, setState] = useState({ model: { name: "" } });
  const { model } = state;
  const { name } = model;

  const buttons = [];

  buttons.push({
    label: "Create Company",
    onPress: () => {
      if (!name) {
        alert("Must enter company name.");
        return;
      }
      return dL.newCompany({ name }).then(function (companyId) {
        return dL
          .getQuery("Company")
          .get(companyId)
          .then(function (obj) {
            const company = dL.loadCompany(obj);
            onSave(company);
          });
      });
    },
  });

  return (
    <ModalBasic title="New Company" onCancel={onCancel} buttons={buttons}>
      <MyInput
        label="Company Name:"
        placeholder="Enter name"
        value={name}
        onChange={(value) => {
          model.name = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

export function EditCompany({ companyId, onSave, onCancel }) {
  const [state, setState] = useState({ isLoading: true });

  useEffect(() => {
    dL.getQuery("Company")
      .get(companyId)
      .then(function (obj) {
        setState({ ...state, isLoading: false, model: dL.loadCompany(obj) });
      });
  }, []);

  const { isLoading, model } = state;
  if (isLoading) {
    return <Loading />;
  }

  const { website, name, primaryColor, logo } = model;

  const { companyGoals, employeeSize, revenueSize, companyShortBio, companyStage, companyCity, companyIndustry } = model

  const buttons = [];

  buttons.push({
    label: "Save",
    onPress: () => {
      if (!name) {
        alert("Must enter company name.");
        return;
      }
      return dL.saveCompany(companyId, { name, primaryColor, logo }).then(function () {
        onSave();
      });
    },
  });

  return (
    <ModalBasic large notCentered title="Edit Company" onCancel={onCancel} buttons={buttons}>
      <Section>
        <ImageElement
          label="Logo:"
          allowClear
          value={logo}
          onChange={value => {
            model.logo = value
            setState({ ...state, model });
          }}
        />

        <MyInput
          label="Company Name:"
          placeholder="Enter name"
          value={name}
          onChange={(value) => {
            model.name = value;
            setState({ ...state, model });
          }}
        />

        <FormItem label="Primary Color:">
          <ColorPicker
            color={primaryColor}
            onChange={(value) => {
              model.primaryColor = value;
              setState({ ...state, model });
            }}
          />
        </FormItem>
      </Section>

      <Section>
        <FlexRow>
          <FormItem label="Business Stage:">
            <SelectBox
              value={companyStage}
              style={{ maxWidth: 250 }}
              options={utils.companyStages}
              onChange={items => {
                model.companyStage = items;
                setState({ ...state, model });
              }}
            />
          </FormItem>
          <View style={{ width: 25 }} />
          <FormItem label="Industry:">
            <SelectBox
              style={{ maxWidth: 350 }}
              value={companyIndustry}
              options={utils.industries}
              onChange={items => {
                model.companyIndustry = items;
                setState({ ...state, model });
              }}
            />
          </FormItem>
        </FlexRow>

        <FormItem label="Primary location of your company:">
          {state.isLoaded2 ? (
            <LocationSearchInput
              style={{ maxWidth: 350 }}
              defaultValue={companyCity}
              onChange={value => {
                model.companyCity = value;
                setState({ ...state, model });
              }}
            />
          ) : null}
        </FormItem>
      </Section>

      <Section>
        <FlexRow>
          <FormItem label="Employee Size:">
            <SelectBox
              value={employeeSize}
              options={utils.employeeSizes}
              onChange={items => {
                model.employeeSize = items;
                setState({ ...state, model });
              }}
            />
          </FormItem>
          <View style={{ width: 25 }} />
          <FormItem label="Revenue Size:">
            <SelectBox
              value={revenueSize}
              options={utils.revenueSizes}
              onChange={items => {
                model.revenueSize = items;
                setState({ ...state, model });
              }}
            />
          </FormItem>
        </FlexRow>

        <MyInput
          label="Company Website:"
          value={website}
          onChange={value => {
            model.website = value;
            setState({ ...state, model });
          }}
        />

        <MyInput
          multiline
          maxLength={144}
          label="Company Description:"
          value={companyShortBio}
          onChange={value => {
            model.companyShortBio = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <Section>
        {companyGoals && companyGoals.length > 0 ? <View bottom={25}>
          <TextLine bold value="Prioritize these in terms of importance to you:" />
          <TextLine size={12} bottom={10} value="Drag to re-order the priority. Max 7 items." />

          <SortableList
            items={companyGoals}
            onChange={(list) => {
              model.companyGoals = list;
              setState({ ...state, model });
            }}
            renderItem={(item, index) => {
              return <BoxItem hasMove onDelete={() => {
                companyGoals.splice(index, 1)
                setState({ ...state, model });
              }}>
                <TextLine bold label={(index + 1) + ":"} value={item.label} />
              </BoxItem>
            }} />
        </View> : null}

        <FormItem>
          <TextLine bold bottom={10} size={18} value="Need help with some business initiatives?"></TextLine>
          <TextLine bottom={15} value="Select from the list of business initiatives to share what could help with your growth."></TextLine>

          <SelectBox
            placeholder="Select up to 7 items"
            value={null}
            options={utils.getGroupedItems({ groupBy: "category", list: utils.goals.filter(item => !companyGoals || !companyGoals.find(item2 => item2.value == item.value)) })}
            onChange={value => {
              if (!companyGoals) { model.companyGoals = [] }
              if (companyGoals.length == 7) {
                alert("You are limited to 7 items.")
                return
              }
              model.companyGoals.push(value)
              setState({ ...state, model });
            }}
          />
        </FormItem>
      </Section>
    </ModalBasic>
  );
}

export function InviteMember({ companyId, onCancel, onAddUser, onAddEmail, onDone }) {
  const [state, setState] = useState({ inviteUsers: [] });
  const { inviteUsers, inviteEmails, inviteMessage } = state;

  return (
    <ModalBasic
      title="Invite Member"
      okText="Invite"
      onCancel={onCancel}
      onOk={() => {
        if (inviteUsers.length == 0 && !inviteEmails) {
          alert("Must select an user.");
          return;
        }

        const sendEmail = function ({ email, id }) {
          var message = "Hi,<br><br>You are invite to join a Growly platform chat by " + session.user.firstName + " " + session.user.lastName + ".<br><br>" + inviteMessage + "<br><br>Follow the following link to join the chat: http://growly.app/user/company/" + companyId + "/" + id;
          console.log(message);
          return http.run("sendEmail", {
            to: email,
            subject: "New Invitation",
            message: message,
            fromEmail: "support@interviewxpress.com",
          });
        };

        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              if (!utils.isEmail(email)) {
                alert(email + " is not a valid email.");
                return;
              }
            }
          }
        }

        const promises = [];
        const emails = [];
        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              promises[promises.length] = onAddEmail({ email }).then(function (obj) {
                return sendEmail({ email, id: obj.id });
              });
            }
          }
        }

        inviteUsers.forEach((user) => {
          const { email } = user;

          promises[promises.length] = onAddUser(user).then(function (obj) {
            return sendEmail({ email, id: obj.id });
          });
        });

        return Promise.all(promises).then(function () {
          return onDone();
        });
      }}>
      <FormItem label="Add members:" required>
        <SelectUsers
          excludedValues={[...inviteUsers, session.user]}
          onChange={(items) => {
            setState({ ...state, inviteUsers: items });
          }}
          value={inviteUsers}
        />
      </FormItem>

      <TextLine bold bottom={25} value="OR" />
      <MyInput
        multiline
        label="Emails:"
        description="Enter one email per line to invite."
        value={inviteEmails}
        onChange={(value) => {
          setState({ ...state, inviteEmails: value });
        }}
      />
      <MyInput
        lastItem
        multiline
        label="Email Message:"
        description="Enter a short message in the email invite body."
        value={inviteMessage}
        onChange={(value) => {
          setState({ ...state, inviteMessage: value });
        }}
      />
    </ModalBasic>
  );
}