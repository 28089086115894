module.exports = function() {
  var factory = {};

  var p = new Promise(function(resolve, reject) {
    factory.resolve = resolve;
    factory.reject = reject;
  });

  factory.then = function(d1, d2) {
    return p.then(d1, d2);
  };

  factory.catch = function(d1, d2) {
    return p.catch(d1, d2);
  };
    
  factory.resolved = function() {
    var promise = new factory();
    promise.resolve();
    return promise;
  };
    
  return factory;
};

/*
var PromiseA=function(){
    var factory={}

    var p=new Promise(function(resolve,reject){
        factory.resolve=resolve
        factory.reject=reject
    })

    factory.as=function(result){
        return p.then(d1,d2)
    }

    factory.then=function(d1,d2){
        return p.then(d1,d2)
    }

    factory.done=function(d1,d2){
        return p.catch(d1,d2)
    }

    factory.fail=function(d1,d2){
        return p.catch(d1,d2)
    }

    return factory
}
*/
