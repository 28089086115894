import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { ListRender2, RenderUser, Price, RenderServiceItemWorker, RenderUserServiceWorker } from "root/pack-3";
import { SelectWorker } from "root/worker";
import { BoxRowItem, UserItem, ListRender, HeaderText } from "root/pack-2";
import { MicroButton, Loading, DateTimeItem, SmallHeader, DeleteConfirm, Section, ModalBasic, BoxItem, LabelItem, LinkButton2, Hide, FlexExpand, FlexRow, TabBar, Text, TextLine, View, Toggle, FormItem, MyInput, TouchButton, SelectBox, FileElement, Avatar, NoRecords } from "root/pack-1";
import "root/loading.css";
import { UserProfileBuyer } from "root/worker";
import {
  getIncludedServiceOptionsForBundledPackage,
  getOptionalServiceOptionsForBundledPackage,
  getIncludedDeliverablesForServiceOption,
  getDeliverablesForService,
  getInputsForServiceOption,
  getSubServicesForServiceOption,
  getTasksForServiceOption,
  getInputsForBundledPackage,
  getTasksForBundledPackage,
  getSubServicesForBundledPackage,
  getDeliverablesForBundledPackage,
  getVisiblePackage,
  getVersionData,
  getServicePriceAndDelivery
} from "root/service-functions";
import renderHTML from "react-render-html";
import { FormEditor } from "root/forms";
import { ViewTrainingNote } from "root/training"
import { getServiceFull, getForkData, RenderSelectUserRole } from "root/services"
import { ServiceEdit, SelectServiceDialog, AddInputsButton, RenderSelectedService } from "root/services-select"

const { roundMoney, noteTypes, moneyRange, plural, deliverableTypes, skillLevels, serviceCores, getLabelItems, money, getLabelItem, serviceTypes } = utils;

export function RenderUserService({ userService, onPress }) {
  const { id, user, minPrice, maxPrice } = userService;

  return (
    <RenderUser
      key={id}
      style={{ position: "relative" }}
      onPress={onPress}
      item={user}>
      <View style={{ position: "absolute", top: 8, right: 8 }}>
        {moneyRange(minPrice, maxPrice)}
      </View>
    </RenderUser>
  );
}

export function ServiceDetails3({ service, userService, serviceVersion, servicePackage, children }) {
  const serviceData = getVersionData({ service, userService, serviceVersion })
  const { enablePackages } = serviceData;
  const { allowedRevisions, hasExtraFastDelivery, deliveryDays, extraFastDeliveryDays } = servicePackage ? servicePackage : serviceData;

  return !enablePackages ? (
    <FlexRow>
      <LabelItem label="Standard Delivery:" value={plural(deliveryDays, "day")} />

      {hasExtraFastDelivery ? <LabelItem label="Fast Delivery:" value={plural(extraFastDeliveryDays, "day")} /> : null}

      {allowedRevisions ? <LabelItem label="Revisions:" value={getLabelItem(utils.revisionOptions, allowedRevisions)} description="Included revisions for deliverables." /> : null}

      {children}
    </FlexRow>
  ) : (
    <View />
  );
}

export function ServiceDetails2({ service, userService, serviceVersion }) {
  const { businessRole, skillLevel } = getVersionData({ service, userService, serviceVersion })

  return (
    <React.Fragment>
      {businessRole ? <LabelItem label="Business Role:" value={businessRole.name} /> : null}
      {skillLevel ? <LabelItem label="Skill Level:" value={getLabelItem(utils.priceTiers, skillLevel)} /> : null}
    </React.Fragment>
  );
}

export function ServiceDetails1({ service, userService, serviceVersion }) {
  const { companyStages, serviceCore, serviceType } = getVersionData({ service, userService, serviceVersion })

  return (
    <React.Fragment>
      <LabelItem label="Company Stages:" value={getLabelItems(utils.companyStages, companyStages)} />
      <LabelItem label="Service Type:" value={getLabelItem(serviceCores, serviceCore)} />
      <LabelItem label="Business Sector:" value={getLabelItem(serviceTypes, serviceType)} />
    </React.Fragment>
  );
}

export function RenderServiceDetails({ service, userService, servicePackage, serviceVersion, children }) {
  return (
    <Section>
      <HeaderText subHeader label="Service Overview:" description="Details about the service and delivery." />

      <FlexRow>
        <ServiceDetails1 service={service} userService={userService} serviceVersion={serviceVersion} />
      </FlexRow>

      <FlexRow>
        <ServiceDetails2 service={service} userService={userService} serviceVersion={serviceVersion} />
      </FlexRow>

      {userService ? (
        <LabelItem label="Consultant:" style={{ marginRight: 0 }}>
          <WorkerView userService={userService} />
        </LabelItem>
      ) : null}

      <ServiceDetails3 service={service} serviceVersion={serviceVersion} userService={userService} servicePackage={servicePackage}>
        {children}
      </ServiceDetails3>
    </Section>
  );
}

export function WorkerView({ userService }) {
  const [visibleUserId, setVisibleUserId] = useState();

  const { user } = userService;
  const hourlyRate = user.minHourlyRate;

  return (
    <BoxRowItem
      onPress={() => {
        setVisibleUserId(user.id);
      }}>
      <FlexRow>
        <FlexExpand>
          <UserItem user={user}>
            <TextLine grey size={14} top={-4} value={"Hourly Rate: " + money(hourlyRate)} />
          </UserItem>
        </FlexExpand>
      </FlexRow>

      {visibleUserId ? (
        <ModalBasic
          full
          notCentered
          onCancel={() => {
            setVisibleUserId();
          }}>
          <UserProfileBuyer userId={visibleUserId} />
        </ModalBasic>
      ) : null}
    </BoxRowItem>
  );
}

export function MyServices(props) {
  const { sectionId } = useParams();
  var userId = session.user.id;
  if (props && props.userId) {
    userId = props.userId;
  }
  const { teamId, setRef, subHeader } = props;
  const history = useHistory();
  const refC1 = useRef();
  const { url } = useRouteMatch();
  const [state, setState] = useState({ serviceTabValue: "catalog" });
  const { deleteIndex, visibleServiceDialog, showDelete, deleteId } = state;

  useEffect(() => {
    if (setRef) {
      setRef({
        addService: () => {
          setState({ ...state, visibleServiceDialog: true })
        }
      })
    }
  }, [])

  const buttons = [
    {
      label: "Add From Catalog",
      onPress: () => {
        setState({ ...state, visibleServiceDialog: true })
      },
    },

    {
      label: "Add New Service",
      onPress: () => {
        if (teamId) {
          history.push(`${url}/service/add`);
        } else {
          history.push(`/worker/service/add`);
        }
      },
    },
  ];

  return (
    <React.Fragment>
      <ListRender
        subHeader={subHeader ? subHeader : teamId != null}
        setRef={(ref) => {
          refC1.current = ref;
        }}
        searchFields={[{ field: "searchText" }]}
        defaultSort={{ field: "searchText" }}
        buttons={buttons}
        filters={[
          {
            label: "Draft",
            value: "draft",
            description: "Draft service not yet published.",
          },
          {
            label: "Public",
            value: "public",
            description: "Published services that are visible in the service marketplace to other consultants.",
            onQuery: function (query) {
              query.equalTo("status", "active");
              query.equalTo("isWorkerPublic", true);
            },
          },
          {
            label: "Private",
            value: "private",
            description: "Services available in your private service catalog visible only to you but usable in proposals.",
            onQuery: function (query) {
              query.equalTo("status", "active");
              query.containedIn("isWorkerPublic", [undefined, false]);
            },
          },
          {
            label: "Archived",
            value: "archived",
            autoHide: true,
          },
        ]}
        onWhere={(query) => {
          if (teamId) {
            query.equalTo("team", dL.getObj("Team", teamId));
          } else {
            query.equalTo("user", dL.getObj("User", userId));
          }
        }}
        queryId="s1"
        type="UserService"
        emptyLabel="No services added."
        includes={["businessRole", "userRole"]}
        title={teamId ? "Team Service Catalog" : "My Service Catalog"}
        description="Setup and manage your service catalog."
        renderItem={(userService, index) => {
          const { id, service, status } = userService;

          return (
            <RenderUserServiceWorker
              key={id}
              userService={userService}
              onPress={() => {
                if (status == "draft") {
                  history.push(`/${sectionId}/service/${service.id}/${id}/admin-edit`);
                } else {
                  history.push(`/${sectionId}/service/${service.id}/${id}`);
                }
              }}
              onEdit={() => {
                history.push(`/${sectionId}/service/${service.id}/${id}/admin-edit`);
              }}
              onDelete={status == "draft" ? () => {
                setState({ ...state, showDelete: true, deleteId: id, deleteIndex: index });
              } : null}
            />
          );
        }}
      />

      {showDelete ? (
        <DeleteConfirm
          onCancel={() => {
            setState({ ...state, showDelete: false });
          }}
          onConfirm={() => {
            refC1.current.deleteIndex(deleteIndex)
            dL.removeUserService({ userId, userServiceId: deleteId }).then(function () {
              refC1.current.refresh();
            })
            setState({ ...state, showDelete: false });
          }}
        />
      ) : null}

      {visibleServiceDialog ? (
        <SelectServiceDialog
          onSelect={({ service, userService }) => {
            const serviceId = service.id

            const run = function () {
              if (userService) {
                history.push(`/worker/service/${serviceId}/${userService.id}/action/fork`);
              } else {
                history.push(`/worker/service/${serviceId}/action/fork`);
              }
              setState({ ...state, visibleServiceDialog: null })
            }
            if (teamId) {
              return dL.getUserService({ serviceId, teamId }).then(function (foundUserService) {
                if (foundUserService) {
                  alert("This service is already added to the team service catalog.")
                  return
                }
                run()
              })
            } else {
              return dL.getUserService({ serviceId, userId: session.user.id }).then(function (foundUserService) {
                if (foundUserService) {
                  alert("This service is already added to your service catalog.")
                  return
                }
                run()
              })
            }
          }}
          onCancel={() => {
            setState({ ...state, visibleServiceDialog: null })
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

export function ServicesWorkerAdmin() {
  const { sectionId } = useParams();
  const history = useHistory();

  const filters = [
    {
      label: "Public",
      value: "public",
      description: "Services that have been made public in the service catalog.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.equalTo("isWorkerPublic", true);
      }
    },
    {
      label: "1st Review",
      value: "1-review",
      description: "Services that require review for the first time.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.equalTo("needsReview", true);
        query.exists("lastReviewedAt", false);
      }
    },
    {
      label: "Needs Review",
      value: "needs-review",
      description: "Services that require review after being modified.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.equalTo("needsReview", true);
      }
    },
    {
      label: "Private",
      value: "private",
      description: "Services that have been published but are private.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.containedIn("isWorkerPublic", [undefined, false]);
      }
    },
    {
      label: "Archived", value: "archived",
      onQuery: function (query) {
        query.equalTo("status", "archived");
      }
    }
  ];

  return (
    <ListRender2
      displayNoCategory={true}
      queryId="lr"
      statFunction="getServicesPerSector"
      searchFields={[{ field: "searchText" }]}
      defaultSort={{ field: "searchText" }}
      filters={filters}
      type="UserService"
      emptyLabel="No services found."
      includes={["deliverables", "packages", "businessRole"]}
      title="Worker Service Catalog"
      description="View services in the worker service marketplace."
      renderItem={(userService) => {
        const { id, service } = userService;
        if (service) {
          const { id: serviceId } = service

          return (
            <RenderServiceItemWorker
              key={id}
              onPress={() => {
                history.push(`/${sectionId}/service/${serviceId}/${id}`);
              }}
              item={userService}
            />
          );
        } else {
          console.log("SERVICE NOT FOUND for User Service:" + id)
        }
      }}
    />
  );
}

export function ServiceWorker() {
  const { serviceId, userServiceId, sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ serviceOptionIds: {}, isLoading: true });
  const { addServiceDialog, forkServiceDialog, isLoading, serviceOptionIds, servicePackage, deliveryType, servicePeople, relatedServices, service, userService } = state;

  useEffect(() => {
    var data;
    var userService;
    getServiceFull({ serviceId }).then(function (_data) {
      data = _data;

      if (userServiceId) {
        return dL.getUserService2({ userServiceId }).then(function (_userService) {
          userService = _userService
        });
      }
    }).then(function () {
      const { servicePeople, relatedServices, service } = data;

      setState({ ...state, isLoading: false, userService, servicePeople, relatedServices, service });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const packageId = servicePackage ? servicePackage.package.id : null;

  const serviceData = userService ? userService : service
  const { enablePackages } = serviceData

  const deliverables = getDeliverablesForService({ serviceVersion: serviceData, packageId, serviceOptionIds });

  const renderPrice = function () {
    const { days, total } = getServicePriceAndDelivery({ serviceVersion: serviceData, serviceOptionIds, packageId, deliveryType })

    const { allowedRevisions } = servicePackage ? servicePackage : service;

    return <Price price={total} days={days} deliveryText="Delivery in " allowedRevisions={allowedRevisions} />;
  };

  const renderUser = function () {
    const { user } = userService;
    const { firstName, lastName, avatar, title, companyName, userText, id } = user;
    return (
      <Section>
        <HeaderText subHeader label="Service Provider" description="This service is particularly provided by this user." />
        <FlexRow>
          <FlexRow>
            <Avatar size={65} value={avatar} style={{ marginRight: 15 }} />

            <View>
              <TextLine value={firstName + " " + lastName} size={18} />
              {companyName ? <TextLine top={-3} value={title ? title + " at " + companyName : companyName} size={14} color="grey" /> : null}
              {userText ? <TextLine top={-3} value={userText} size={14} color="grey" /> : null}
            </View>
          </FlexRow>

          <MicroButton
            style={{ marginLeft: 35 }}
            onPress={() => {
              history.push(`/user/people/${id}`);
            }}
            label="View Profile"
          />
        </FlexRow>
      </Section>
    );
  };

  return (
    <div class="row">
      <div class="col-8">
        <DisplayService
          RenderServiceItem={RenderServiceItemWorker}
          userService={userService}
          servicePeople={userServiceId ? servicePeople.filter((item) => item.id != userServiceId) : servicePeople}
          relatedServices={relatedServices}
          service={service}
          hideMainPrice
          onSelectionUpdate={(data) => {
            setState({ ...state, ...data });
          }}
        />

        {userService ? renderUser() : null}
      </div>

      <div class="col-4">
        <div class="card-bordered">
          <div class="card-body">
            <div>
              <span class="h2 text-lh-sm mr-1">{!enablePackages || servicePackage ? renderPrice() : null}</span>
            </div>

            <div>
              {deliverables && deliverables.length > 0 ? (
                <View style={{ marginTop: 25 }}>
                  <h2 class="h4">Service will includes:</h2>

                  {deliverables.map((item) => {
                    const { name, id } = item;

                    return (
                      <div key={id} class="media text-body font-size-1 mb-2">
                        <div class="min-w-3rem text-center mr-3">
                          <i class="fa fa-check" style={{ color: "green" }} />
                        </div>
                        <div class="media-body">{name}</div>
                      </div>
                    );
                  })}
                </View>
              ) : null}
            </div>

            {sectionId == "worker" ? <React.Fragment>
              <View style={{ height: 10 }} />

              <BoxItem name="Add this service">
                <TextLine size={14} top={10} value="Add this service to your or team service catalog." />
                <View style={{ height: 15 }} />
                <MicroButton label="Add This Service" onPress={() => {
                  setState({ ...state, addServiceDialog: true })
                }} />
              </BoxItem>

              <BoxItem name="Improve this service">
                <TextLine size={14} top={10} value="Modify this service in your service catalog and make improvements." />
                <View style={{ height: 15 }} />
                <MicroButton label="Fork This Service" description="Add this service to your service catalog." onPress={() => {
                  setState({ ...state, forkServiceDialog: true })
                }} />
              </BoxItem>

              <View style={{ height: 15 }} />
              <BoxItem name="Become a trainer">
                <TextLine size={14} top={10} value="Become a trainer of this service and start showcasing your thought leadership." />
                <View style={{ height: 15 }} />
                <MicroButton label="Add Training Note" onPress={() => {
                  alert("add training notes on this service")
                }} />
              </BoxItem>
            </React.Fragment> : null}
          </div>
        </div>
      </div>

      {addServiceDialog ? (
        <RenderAddServiceDialog serviceId={serviceId} userServiceId={userServiceId} onCancel={() => {
          setState({ ...state, addServiceDialog: null })
        }} />
      ) : null}

      {forkServiceDialog ? (
        <RenderForkServiceDialog serviceId={serviceId} userServiceId={userServiceId} onCancel={() => {
          setState({ ...state, forkServiceDialog: null })
        }} />
      ) : null}
    </div>
  );
}

export function DisplayService({ RenderServiceItem, displaySelected, userService, hideMainPrice, hidePrice, servicePeople, relatedServices, service, subHeader, onSelectionUpdate }) {
  const allowSelect = onSelectionUpdate != null;
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    isLoading: true,
    serviceData: {
      service,
      userService,
      serviceOptionIds: {},
      deliveryType: "standard",
    },
  });
  const { serviceData } = state;
  const { servicePackage, serviceOptionIds, deliveryType } = serviceData;

  const onSelectPackage = function (servicePackage) {
    var dType = deliveryType;
    if (deliveryType == "fast" && !servicePackage.hasExtraFastDelivery) {
      dType = "standard";
    }
    setState({ ...state, serviceOptionIds: {}, deliveryType: dType, servicePackage });
    onSelectionUpdate && onSelectionUpdate({ servicePackage, serviceOptionIds: {}, deliveryType: dType });
  };

  useEffect(() => {
    if (allowSelect) {
      const servicePackage = getVisiblePackage({ serviceData: userService ? userService : service });

      if (servicePackage) {
        onSelectPackage(servicePackage);
      }
    }
  }, []);

  const serviceVersionData = userService ? userService : service
  const { icon, enablePackages, name, shortDescription, description } = serviceVersionData

  const packageId = servicePackage ? servicePackage.package.id : null;

  const renderPrice = function () {
    const { days, total } = getServicePriceAndDelivery({ serviceVersion: serviceVersionData, serviceOptionIds, packageId, deliveryType })

    return <Price price={total} days={days} />;
  };

  return (
    <View>
      <HeaderText icon={icon} subHeader={subHeader} label={name} description={shortDescription} rightRender={!enablePackages && !hideMainPrice ? renderPrice() : null} />

      <Section>
        <FlexRow>
          <ServiceDetails1 service={service} userService={userService} />
        </FlexRow>
      </Section>

      {description ? <Section>
        <HeaderText subHeader label="Description of service:" />
        {description}
      </Section> : null}

      <Section>
        <ServiceEdit
          displaySelected={displaySelected}
          hidePrice={hidePrice}
          allowSelect={allowSelect}
          model={serviceData}
          onUpdate={(serviceData) => {
            setState({ ...state, serviceData });

            onSelectionUpdate && onSelectionUpdate(serviceData);
          }}
        />
      </Section>

      {relatedServices.length > 0 ? (
        <Section>
          <HeaderText subHeader label="Related Services:" description="See other related services that can help complete any pre-requirements for this service." />

          {relatedServices.length == 0 ? (
            <NoRecords label="No related services found." />
          ) : (
            relatedServices.map((item) => {
              return (
                <RenderServiceItem
                  onPress={() => {
                    history.push(`/${sectionId}/service/${item.id}`);
                    setState({ ...state });
                  }}
                  item={item}
                />
              );
            })
          )}
        </Section>
      ) : null}

      {servicePeople.length > 0 ? (
        <Section>
          <HeaderText subHeader label="Service Experts:" description="See some of the people that can deliver this service." />
          {servicePeople.length == 0 ? (
            <NoRecords label="No experts found." />
          ) : (
            <FlexRow>
              {servicePeople.map((userService) => {
                const { user, service } = userService;

                return <RenderUserService userService={userService} onPress={() => {
                  history.push(`/${sectionId}/service/${service.id}/${user.id}`);
                  setState({ ...state });
                }} />
              })}
            </FlexRow>
          )}
        </Section>
      ) : null}
    </View>
  );
}

function RenderAddServiceDialog({ serviceId, userServiceId, onCancel }) {
  const [state, setState] = useState({ tabValue: "mine", isLoading: true });
  const { tabValue, team, isLoading, teams, model, userRoles, service } = state

  useEffect(() => {
    dL.getQuery("Member")
      .equalTo("type", "team")
      .equalTo("user", dL.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .include("team")
      .find()
      .then(function (objs) {
        const teams = dL.loadObjects("Member", objs).map(item => item.team)
        return getForkData({ serviceId, serviceId }).then(function ({ model, service, userService, userRoles }) {
          setState({ ...state, isLoading: false, teams, model, service, userService, userRoles });
        })
      })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { businessRole, name, shortDescription, userRole } = model

  return <ModalBasic
    title="Add This Service:"
    onCancel={onCancel} okText="Add Service" onOk={() => {
      if (tabValue == "team") {
        if (!team) {
          alert("Must select team.");
          return;
        }
      }

      if (!name) {
        alert("Must enter service name.");
        return;
      }
      if (!userRole) {
        alert("Must enter business role.");
        return;
      }

      return dL.getUserService({ serviceId, userId: team ? null : session.user.id, teamId: team ? team.id : null })
        .then(function (userService) {
          if (userService) {
            alert("This service is already added to the team service catalog.")
            return
          }

          return dL.addUserService({ team, forkUserServiceId: userServiceId, service, data: model })
            .then(function ({ }) {
              alert("Added to service catalog.")
            }).catch(function (err) {
              alert("Error: " + err);
            });
        });
    }}>

    <TabBar
      style={{ marginBottom: 20 }}
      autoHide
      options={[
        { label: "Your Catalog", value: "mine" },
        { label: "Team Catalog", value: "team" },
      ]}
      onChange={item => {
        setState({ ...state, tabValue: item.value });
      }}
      value={tabValue}
    />

    {tabValue == "team" ? <SelectBox
      label="Team:"
      value={team}
      textField="name"
      valueField="id"
      options={teams}
      onChange={(team) => {
        setState({ ...state, team })
      }}
    /> : null}

    <MyInput
      required
      label="Change the service name or title:"
      value={name}
      onChange={(value) => {
        model.name = value;
        setState({ ...state, model });
      }}
    />
    <MyInput
      required
      maxLength={144}
      label="Change the short description of this service:"
      value={shortDescription}
      onChange={(value) => {
        model.shortDescription = value;
        setState({ ...state, model });
      }}
    />
    <RenderSelectUserRole
      businessRole={businessRole}
      userRole={userRole}
      defaultUserRoles={userRoles}
      onChange={(userRole) => {
        const { businessRole, skillLevel } = userRole
        model.businessRole = businessRole;
        model.skillLevel = skillLevel;
        model.userRole = userRole;
        setState({ ...state, model });
      }} />
  </ModalBasic>
}

function RenderForkServiceDialog({ serviceId, userServiceId, onCancel }) {
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { team, isLoading, teams } = state

  useEffect(() => {
    dL.getQuery("Member")
      .equalTo("type", "team")
      .equalTo("user", dL.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .include("team")
      .find()
      .then(function (objs) {
        const teams = dL.loadObjects("Member", objs).map(item => item.team)
        setState({ ...state, isLoading: false, teams })
      })

  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return <ModalBasic
    title="Fork This Service:"
    onCancel={onCancel}>
    <SelectBox
      label="Team:"
      value={team}
      textField="name"
      valueField="id"
      options={teams}
      onChange={(team) => {
        setState({ ...state, team })
      }}
    />
    <TouchButton
      onPress={() => {
        if (!team) {
          alert("Must select team.")
          return
        }
        return dL.getUserService({ serviceId, teamId: team.id })
          .then(function (userService) {
            if (userService) {
              alert("This service is already added to the team service catalog.")
              return
            }

            if (userServiceId) {
              history.push(`/worker/team/${team.id}/admin/service/${serviceId}/${userServiceId}/action/fork`)
            } else {
              history.push(`/worker/team/${team.id}/admin/service/${serviceId}/action/fork`)
            }
          })
      }}
      label="Add to team service catalog"
    />

    <View style={{ height: 35 }} />
    <TextLine bold size={22} value="OR"></TextLine>
    <View style={{ height: 35 }} />

    <TouchButton
      onPress={() => {
        return dL.getUserService({ serviceId, userId: session.user.id })
          .then(function (userService) {
            if (userService) {
              alert("This service is already added to your service catalog.")
              return
            }

            history.push(`/worker/service/${serviceId}/action/fork`)
          })
      }}
      label="Add to my service catalog"
    />
  </ModalBasic>
}

export function ServiceViewDialog({ displaySelected, serviceId, hidePrice, onClose }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, servicePeople, relatedServices, service } = state;

  useEffect(() => {
    getServiceFull({ serviceId }).then(function ({ servicePeople, relatedServices, service }) {
      setState({ ...state, isLoading: false, servicePeople, relatedServices, service });
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalBasic notCentered full onCancel={onClose}>
      <DisplayService
        RenderServiceItem={RenderServiceItemWorker}
        displaySelected={displaySelected}
        hidePrice={hidePrice}
        subHeader
        servicePeople={servicePeople}
        relatedServices={relatedServices}
        service={service}
        onSelectionUpdate={(data) => {
          setState({ ...state, ...data });
        }}
      />
    </ModalBasic>
  );
}
/*
export function EditUserService() {
  var { userId, userServiceId } = useParams();
  if (!userId) {
    userId = session.user.id;
  }
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true, sellTabValue: "service", learnTabValue: "service" });
  const { user, isLoading, model, hoursValue, priceEditId, yourDefaultHoursValue, defaultHoursValue, taskEdit } = state;
  const { subServiceEdit, taskVisible, optionVisible, deliverableVisible, subServiceVisible, packageVisible, inputVisible, sellTabValue, learnTabValue, hidePrice } = state;

  useEffect(() => {
    var model = { overrides: {} };
    var user;
    dL.getQuery("User")
      .get(userId)
      .then(function (obj) {
        user = dL.loadUser(obj);

        if (userServiceId) {
          return db
            .getQuery("UserService")
            .get(userServiceId)
            .then(function (obj) {
              model = dL.loadUserService(obj);
              return dL.getService(model.service.id, true);
            })
            .then(function (service) {
              model.service = service;
            });
        }
      })
      .then(function () {
        setState({ ...state, isLoading: false, user, model });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!model.overrides) {
    model.overrides = {};
  }
  const { service, serviceVersion, overrides, makeServiceSellable, rateType } = model;

  const workerHourlyRate = user.minHourlyRate;
  const allowFixedPriceService = user.permissions.allowFixedPriceService;

  const { skillLevel, price, allowedRevisions, businessRole, enablePackages, isFixedPriced, enableServiceOptions } = service ? service : {};

  const { subServices, tasks, packages, deliverables, serviceOptions } = service ? service : {};

  const preReqItems = service ? getServicePreReqItems({ serviceData:service }) : [];

  const { maxHourlyRate, minHourlyRate, minPrice, maxPrice, baseWorkHours, overrideBasePrice } ={}// service ? getPriceInfo({ overrides, service, rateType, workerHourlyRate }) : {};

  return (
    <EditDialog>
      <HeaderText label={userServiceId ? "Edit Service" : "Add Service"} description="Add or setup a service from the service catalog that you can provide." />

      <Section>
        {userServiceId ? <HeaderText subHeader label="Selected Service:" description="Below is the selected service from the service catalog that you are providing." /> : <HeaderText subHeader label="Select the service:" description="Select an existing service from the service catalog." />}
        <FormItem box>
          <SelectService
            readonly={userServiceId != null}
            onChange={({ service }) => {
              model.serviceId = service.id;

              dL.getService(service.id, true).then(function (service) {
                model.service = service;
                model.serviceVersion = service.currentVersion;
                const servicePackage = getVisiblePackage({ serviceData: model.serviceVersion });
                setState({ ...state, service, servicePackage, model });
              });
            }}
            value={service}
          />
        </FormItem>
      </Section>

      {service ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Learn and Adjust Tasks:" description="Learn about the tasks required for this service and change the hours from defaults if you can deliver fast or require more time. Keep in mind faster delivery with quality results will create more growth opportunities for you." />
            <Hide>
              <TextLine>Learn more about the service, tasks, deliverables by clicking on them below. You will be able see details instructions on what is included and how to complete the tasks for the service and deliverable examples and requirements.</TextLine>
            </Hide>

            <TabBar
              style={{ marginBottom: 20 }}
              queryId="tb3"
              autoHide
              options={[
                { label: "Service Overview", value: "service" },
                tasks.length > 0 ? { label: plural(tasks.length, "Task"), value: "tasks" } : null,
                subServices.length > 0 ? { label: plural(subServices.length, "Sub Service"), value: "sub-services" } : null,
                !enablePackages && deliverables.length > 0 ? { label: plural(deliverables.length, "Deliverable"), value: "deliverables" } : null,
                enableServiceOptions && serviceOptions.length > 0 ? { label: plural(serviceOptions.length, "Service Option"), value: "options" } : null,
                enablePackages && isFixedPriced && packages.length > 0 ? { label: plural(packages.length, "Bundled Package"), value: "packages" } : null
              ]}
              onChange={item => {
                setState({ ...state, learnTabValue: item.value });
              }}
              value={learnTabValue}
            />

            {learnTabValue == "service" ? (
              <Section>
                <HeaderText subHeader label="Service Overview:" description="Details about the service and delivery." />
                <FlexRow>
                  <ServiceDetails1 service={service} />
                  <ServiceDetails2 service={service} />
                </FlexRow>

                <ServiceDetails3 service={service} />
              </Section>
            ) : null}

            {learnTabValue == "tasks" ? (
              <Section>
                <HeaderText subHeader label="Tasks:" description="These are the tasks that are required for this service. Any tasks that have '**' have adjusted hours or pricing." />

                {tasks.map(item => {
                  const { name, description, id, hours } = item;
                  const override = overrides[id];

                  return (
                    <BoxItem>
                      <FlexRow>
                        <FlexExpand>
                          <TextLine
                            onPress={() => {
                              setState({ ...state, taskVisible: item });
                            }}
                            size={16}
                            value={name}
                          />
                        </FlexExpand>

                        <FlexRow alignTop>
                          {plural(override ? override : hours, "hr")}
                          {override ? " **" : null}

                          <TouchableOpacity
                            style={{ marginLeft: 8 }}
                            onPress={() => {
                              setState({ ...state, taskEdit: item, defaultHoursValue: hours, hoursValue: override ? override : hours });
                            }}>
                            <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 18, color: "#c0c0c0" }} />
                          </TouchableOpacity>
                        </FlexRow>
                      </FlexRow>

                      <TextLine grey top={4} size={14}>
                        {description}
                      </TextLine>
                    </BoxItem>
                  );
                })}
              </Section>
            ) : null}

            {learnTabValue == "sub-services" ? (
              <Section>
                <HeaderText subHeader label="Sub-Services:" description="These are the additional services that are required for this service. Any services that have '**' have adjusted worker." />

                {subServices.map((item) => {
                  const { assignedTo, costPrice, costHours, requiredPreServices, id, service, servicePackage, limitedToOptions, limitedToPackages, deliveryDays } = item;
                  const { name, shortDescription, businessRole, skillLevel } = service;
                  const override = overrides[id];

                  return (
                    <BoxItem
                      onPress={() => {
                        setState({ ...state, hidePrice: true, subServiceVisible: item });
                      }}
                      name={name + (servicePackage ? " - " + servicePackage.name : "")}
                      description={shortDescription}
                      rightRender={<View>
                        <FlexRow style={{ marginTop: 20 }}>
                          <UserItem label="Consultant:" user={override ? override : assignedTo} style={{ marginTop: 6 }} />
                          {override ? " **" : null}

                          <TouchableOpacity
                            style={{ marginLeft: 8 }}
                            onPress={() => {
                              setState({ ...state, subServiceEdit: item, defaultHoursValue: assignedTo, hoursValue: override ? override : assignedTo });
                            }}>
                            <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 18, color: "#c0c0c0" }} />
                          </TouchableOpacity>
                        </FlexRow>
                      </View>} >
                      { businessRole ? <TextLine grey size={14} top={4} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : null}

                      <Hide>
                        <FlexRow top={4}>
                          <TextLine right={10} size={14} value={"Delivery: " + plural(deliveryDays, "day")} />

                          <TextLine size={14} value={"Cost: " + utils.money(costPrice) + " (" + plural(costHours, "hr") + ")"} />
                        </FlexRow>
                      </Hide>

                      <RenderArrayItems
                        label="Pre-Required:"
                        value={requiredPreServices}
                        list={preReqItems}
                        onGetText={item => {
                          const { task, service } = item;
                          if (task) {
                            return task.name;
                          } else if (service) {
                            return service.name;
                          }
                        }}
                      />
                      {enablePackages ? <RenderLimitedPackages packages={packages} value={limitedToPackages} /> : null}
                      <RenderLimitedOptions options={serviceOptions} value={limitedToOptions} />
                    </BoxItem>
                  );
                })}
              </Section>
            ) : null}

            {learnTabValue == "deliverables" ? (
              <Section>
                <HeaderText subHeader label="Included Deliverables:" />

                {deliverables.map(item => {
                  const { name, description, deliverableType, formItems } = item;

                  return (
                    <BoxItem
                      name={name + " (" + getLabelItem(deliverableTypes, deliverableType) + ") " + (deliverableType == "form" ? "(" + plural(formItems ? formItems.length : 0, "question") + ")" : "")}
                      description={description}
                      onPress={() => {
                        setState({ ...state, deliverableVisible: item });
                      }}
                    />
                  );
                })}
              </Section>
            ) : null}

            {learnTabValue == "options" ? (
              <Section>
                <HeaderText subHeader label="Service Options:" description="These are all of the service options that are available." />

                {serviceOptions.map((item) => {
                  const { name, description, id, deliveryDays } = item;

                  const dels = getIncludedDeliverablesForServiceOption({ service, serviceOptionId: id });
                  const tasks = getTasksForServiceOption({ serviceOptionId: id, service });
                  const workHours = getWorkHours({ tasks, overrides });

                  return (
                    <BoxItem
                      key={id}
                      name={name}
                      description={description}
                      rightRender={<View>
                        <TextLine size={14} value={"Additional Work Hours: +" + plural(workHours, "hr")} />
                        {deliveryDays ? <TextLine size={14} value={"+ " + plural(deliveryDays, "day")} /> : null}
                      </View>}
                      onPress={() => {
                        setState({ ...state, hidePrice: true, optionVisible: item });
                      }}>
                      <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} />
                    </BoxItem>
                  );
                })}
              </Section>
            ) : null}

            {learnTabValue == "packages" ? (
              <Section>
                <HeaderText subHeader label="Bundled Packages:" description="These are all of the bundles packages that are available." />

                {packages.map((item) => {
                  const { name, shortDescription, id, deliveryDays } = item;

                  const opts = getIncludedServiceOptionsForBundledPackage({ serviceOptions, packageId: id });
                  const optOpts = getOptionalServiceOptionsForBundledPackage({ serviceOptions, packageId: id });
                  const dels = getDeliverablesForBundledPackage({ service, packageId: id });
                  const tasks = getTasksForBundledPackage({ packageId: id, service });
                  const workHours = getWorkHours({ tasks, overrides });

                  return (
                    <BoxItem
                      key={id}
                      name={name}
                      description={shortDescription}
                      rightRender={<View>
                        <TextLine size={14} value={"Work Hours: " + plural(workHours, "hr")} />
                        {deliveryDays ? <TextLine size={14} value={"+ " + plural(deliveryDays, "day")} /> : null}
                      </View>}
                      onPress={() => {
                        setState({ ...state, hidePrice: true, packageVisible: item });
                      }}>
                      <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} />
                      <DisplayOptions service={service} overrides={overrides} hidePrice options={opts} otherOptions={optOpts} />
                    </BoxItem>
                  );
                })}
              </Section>
            ) : null
            }
          </Section >

          { isFixedPriced && allowFixedPriceService ? (
            <Section>
              <HeaderText subHeader label="Service Catalog:" description="You can make this service directly sellable to buyer and have it show up in your profile." />
              <Toggle
                label="Make this service sellable:"
                description="Display this service in your public service catalog."
                value={makeServiceSellable}
                onChange={value => {
                  if (!model.rateType) {
                    model.rateType = "standard";
                  }
                  model.makeServiceSellable = value;
                  setState({ ...state, model });
                }}
              />

              {makeServiceSellable ? (
                <React.Fragment>
                  <HeaderText subHeader label="Service Pricing:" description="Change the service pricing based on standard pricing or your hourly rate. The above changes to hours will be reflected in your rate pricing." />

                  <Hide>//display the price or adjust //set the price for the service, adjust the delivery days for the service, adjust the fast price and delivery days ** the discounted price of service options and packages will be reflected based on your changes</Hide>

                  <TabBar
                    style={{ marginBottom: 20 }}
                    queryId="tb2"
                    options={[{ label: "Standard: " + (enablePackages ? getPriceRangeText({ overrides, service, rateType: "standard", workerHourlyRate }) : money(getStandardPrice({ overrides, service }))), value: "standard" }, { label: "Your Rate: " + (enablePackages ? getPriceRangeText({ overrides, service, rateType: "worker-rate", workerHourlyRate }) : money(roundMoney(baseWorkHours * workerHourlyRate))), value: "worker-rate" }]}
                    onChange={item => {
                      model.rateType = item.value;
                      setState({ ...state, model });
                    }}
                    value={rateType}
                  />

                  <TabBar
                    style={{ marginBottom: 20 }}
                    queryId="tb4"
                    options={[{ label: "Base Service", value: "service" }, enableServiceOptions ? { label: plural(serviceOptions.length, "Service Option"), value: "options" } : null, enablePackages && isFixedPriced ? { label: plural(packages.length, "Bundled Package"), value: "packages" } : null, subServices.length > 0 ? { label: plural(subServices.length, "Sub Service"), value: "sub-services" } : null]}
                    onChange={item => {
                      setState({ ...state, sellTabValue: item.value });
                    }}
                    value={sellTabValue}
                  />

                  {sellTabValue == "service" ? (
                    <Section>
                      <FlexRow>
                        {enablePackages ? (
                          <LabelItem label="Price Range:" value={moneyRange(minPrice, maxPrice)} />
                        ) : (
                          <FlexRow alignTop style={{ marginRight: 25 }}>
                            <LabelItem label="Base Price:" value={money(overrideBasePrice ? overrideBasePrice : rateType == "standard" ? price : roundMoney(baseWorkHours * workerHourlyRate))} />
                            {overrideBasePrice ? " **" : null}
                            <TouchableOpacity
                              style={{ marginLeft: 8 }}
                              onPress={() => {
                                setState({ ...state, priceEditId: rateType + "-" + service.id, defaultHoursValue: rateType == "standard" ? price : roundMoney(baseWorkHours * workerHourlyRate), hoursValue: overrideBasePrice ? overrideBasePrice : rateType == "standard" ? price : roundMoney(baseWorkHours * workerHourlyRate) });
                              }}>
                              <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 18, color: "#c0c0c0" }} />
                            </TouchableOpacity>
                          </FlexRow>
                        )}

                        <ServiceDetails3 service={service} />

                        {!enablePackages ? <React.Fragment>{rateType == "worker-rate" ? <LabelItem label="Work Hours:" value={plural(baseWorkHours, "hr")} /> : null}</React.Fragment> : null}
                      </FlexRow>
                    </Section>
                  ) : null}

                  {sellTabValue == "sub-services" ? (
                    <Section>
                      <HeaderText subHeader label="Sub-Services:" description="These are the additional services that are required for this service. You can setup a specific worker that you would like to fulfill the service. Any services that have '**' have an adjusted settings from standard." />

                      {subServices.map((item) => {
                        const { assignedTo, costPrice, costHours, requiredPreServices, id, service, servicePackage, limitedToOptions, limitedToPackages, total, deliveryDays } = item;

                        const { name, shortDescription, businessRole, skillLevel } = service;

                        const override = overrides[id];
                        return (
                          <BoxItem
                            onPress={() => {
                              setState({ ...state, hidePrice: false, subServiceVisible: item });
                            }}
                            name={name + (servicePackage ? " - " + servicePackage.name : "")}
                            description={shortDescription}
                            rightRender={<View>
                              <Price label="Cost:" price={total} days={deliveryDays} />

                              <FlexRow style={{ marginTop: 20 }}>
                                <UserItem label="Consultant:" user={override ? override : assignedTo} style={{ marginTop: 6 }} />
                                {override ? " **" : null}
                              </FlexRow>
                            </View>} >
                            { businessRole ? <TextLine grey size={14} top={4} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : null}

                            <Hide>
                              <FlexRow top={4}>
                                <TextLine right={10} size={14} value={"Delivery: " + plural(deliveryDays, "day")} />

                                <TextLine size={14} value={"Cost: " + utils.money(costPrice) + " (" + plural(costHours, "hr") + ")"} />
                              </FlexRow>
                            </Hide>

                            <RenderArrayItems
                              label="Pre-Required:"
                              value={requiredPreServices}
                              list={preReqItems}
                              onGetText={item => {
                                const { task, service } = item;
                                if (task) {
                                  return task.name;
                                } else if (service) {
                                  return service.name;
                                }
                              }}
                            />
                            {enablePackages ? <RenderLimitedPackages packages={packages} value={limitedToPackages} /> : null}
                            <RenderLimitedOptions options={serviceOptions} value={limitedToOptions} />
                          </BoxItem>
                        );
                      })}
                    </Section>
                  ) : null}
                  {sellTabValue == "options" ? (
                    <Section>
                      <HeaderText subHeader label="Service Options:" description="These are all of the service options that are available." />

                      {serviceOptions.map((item) => {
                        const { hours, name, description, id, price, deliveryDays } = item;

                        const dels = getIncludedDeliverablesForServiceOption({ service, serviceOptionId: id });
                        const tasks = getTasksForServiceOption({ service, serviceOptionId: id });
                        const subServices = getSubServicesForServiceOption({ service, serviceOptionId: id });

                        const workHours = getWorkHours({ tasks, overrides });
                        const subServiceCostRate = getSubServiceCost({ subServices });

                        var costPrice = 0;
                        var hourlyRate;
                        if (businessRole) {
                          hourlyRate = businessRole.rates[skillLevel];
                          costPrice = hourlyRate * workHours;
                        }

                        var extraCost = "";
                        if (subServiceCostRate) {
                          extraCost = " + " + money(subServiceCostRate);
                        }

                        const getRatePrice = function (rateType) {
                          return rateType == "worker-rate" ? roundMoney(workHours * workerHourlyRate) : price;
                        };

                        const overrideId = rateType + "-" + id;
                        const overridePrice = overrides[overrideId];
                        const priceSet = overridePrice ? overridePrice : getRatePrice(rateType);

                        return (
                          <BoxItem
                            key={id}
                            name={name}
                            description={description}
                            rightRender={<View>
                              <FlexRow alignTop>
                                <Price price={priceSet} days={deliveryDays} deliveryText="+" priceText="+" />
                                {overridePrice ? " **" : null}

                                <TouchableOpacity
                                  style={{ marginLeft: 8 }}
                                  onPress={() => {
                                    setState({ ...state, priceEditId: overrideId, defaultHoursValue: getRatePrice("standard"), yourDefaultHoursValue: getRatePrice("worker-rate"), hoursValue: priceSet });
                                  }}>
                                  <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 18, color: "#c0c0c0" }} />
                                </TouchableOpacity>
                              </FlexRow>

                              {hours ? <TextLine color="grey" top={4} value={"+" + hours + " hrs"} /> : null}
                            </View>}
                            onPress={() => {
                              setState({ ...state, hidePrice: false, optionVisible: item });
                            }}>
                            <TextLine size={14} value={"Additional Work Hours: +" + plural(workHours, "hr")} />
                            <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} />
                          </BoxItem>
                        );
                      })}
                    </Section>
                  ) : null}
                  {
                    sellTabValue == "packages" ? (
                      <Section>
                        <HeaderText subHeader label="Bundled Packages:" description="These are all of the bundles packages that are available." />
                        {packages.map((item) => {
                          const { name, shortDescription, id, deliveryDays, hasExtraFastDelivery, extraFastPrice, extraFastDeliveryDays } = item;

                          const { dels, opts, optOpts, workHours, subServiceCost, overridePrice, overrideId } = getServicePackageData({
                            service,
                            servicePackage: item,
                            userService: {
                              overrides,
                              user: session.user,
                              rateType
                            }
                          });

                          /*
                          const opts = getIncludedServiceOptionsForBundledPackage({serviceOptions, packageId: id});
                          const optOpts = getOptionalServiceOptionsForBundledPackage({serviceOptions, packageId: id});
                          const dels = getDeliverablesForBundledPackage({service, packageId: id});
                          const tasks = getTasksForBundledPackage({packageId: id, service});
                          const workHours = getWorkHours({tasks, overrides});
                          const subServices = getSubServicesForBundledPackage({packageId: id, service});
                  
                          const subServiceCostRate = getSubServiceCost({subServices});
                  
                          var costPrice = 0;
                          var hourlyRate;
                          if (businessRole) {
                            hourlyRate = businessRole.rates[skillLevel];
                            costPrice = hourlyRate * workHours;
                          }
                  
                          var extraCost = "";
                          if (subServiceCostRate) {
                            extraCost = " + " + money(subServiceCostRate);
                          }
                  
                          const getRatePrice = function(rateType) {
                            return rateType == "worker-rate" ? roundMoney(workHours * workerHourlyRate, 2) : price;
                          };
                          const overrideId = rateType + "-" + id;
                          const overridePrice = overrides[overrideId];
                          const priceSet = overridePrice ? overridePrice : getRatePrice(rateType);
                  * /

                          const getRatePrice = function ({ rateType, workHours, price }) {
                            return rateType == "worker-rate" ? roundMoney(workHours * workerHourlyRate) : price;
                          };

                          var extraCost = "";
                          if (subServiceCost) {
                            extraCost = " + " + money(subServiceCost);
                          }

                          return (
                            <BoxItem
                              key={id}
                              name={name}
                              description={shortDescription}
                              onPress={() => {
                                setState({ ...state, hidePrice: false, packageVisible: item });
                              }}
                              rightRender={<FlexRow alignTop>
                                <Price price={price} days={deliveryDays} />
                                {overridePrice ? " **" : null}
                                <TouchableOpacity
                                  style={{ marginLeft: 8 }}
                                  onPress={() => {
                                    setState({ ...state, priceEditId: overrideId, defaultHoursValue: getRatePrice("standard"), yourDefaultHoursValue: getRatePrice("worker-rate"), hoursValue: price });
                                  }}>
                                  <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 18, color: "#c0c0c0" }} />
                                </TouchableOpacity>
                              </FlexRow>} >
                              <TextLine size={14} value={"Work Hours: " + plural(workHours, "hr")} />

                              <TextLine size={14} value={"Standard Delivery: " + plural(deliveryDays, "day")} />
                              <TextLine size={14} value={"Revisions: " + getLabelItem(utils.revisionOptions, allowedRevisions)} />

                              { hasExtraFastDelivery ? <TextLine size={14} value={"Expedited Delivery/Price: " + money(extraFastPrice) + " in " + plural(extraFastDeliveryDays, "day")} /> : null}

                              <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} />
                              <DisplayOptions service={service} overrides={overrides} options={opts} otherOptions={optOpts} />
                            </BoxItem>
                          );
                        })}
                      </Section >
                    ) : null
                  }
                </React.Fragment >
              ) : null}
            </Section >
          ) : null}
        </React.Fragment >
      ) : null}

      {
        service ? (
          <Section>
            <HeaderText subHeader label="Hourly Rate:" description="Your hourly rate will change based on bundled packages and service options. This provides a change based on these variations." />
            <LabelItem label="Hourly Rate:" value={moneyRange(minHourlyRate, maxHourlyRate)} />
          </Section>
        ) : null
      }

      {
        subServiceEdit ? (
          <ModalBasic
            title="Edit Worker:"
            onCancel={() => {
              setState({ ...state, subServiceEdit: null });
            }}
            onOk={() => {
              const { id } = subServiceEdit;

              if (hoursValue) {
                overrides[id] = hoursValue;
              } else {
                delete overrides[id];
              }

              dL.saveUserServiceOverrides(model.id, overrides);
              setState({ ...state, model, subServiceEdit: null });
            }}>
            <LabelUserItem inline label="Set Worker:" user={defaultHoursValue} />

            <FormItem label="Select Worker:" box>
              <SelectWorker
                hideMySelf
                serviceId={subServiceEdit.service.id}
                onChange={value => {
                  const { user, userService } = value ? value : {};

                  //calculate the subService total and set the overrides with the assignedTo, userService, total
                  const { service, deliveryType, serviceOptionIds, servicePackage } = subServiceEdit;
                  const { price: total } = getServicePrice2({ service, userService, packageId: servicePackage ? servicePackage.id : null, serviceOptionIds, deliveryType });

                  setState({
                    ...state,
                    hoursValue: {
                      assignedTo: user,
                      userService,
                      total
                    }
                  });
                }}
                value={hoursValue}
              />
            </FormItem>
          </ModalBasic>
        ) : null
      }
      {
        taskEdit ? (
          <ModalBasic
            title="Edit Hours:"
            onCancel={() => {
              setState({ ...state, taskEdit: null });
            }}
            onOk={() => {
              const { id } = taskEdit;

              if (hoursValue) {
                if (!utils.isNumber(hoursValue)) {
                  alert("Must enter valid hours.");
                  return;
                }

                if (Number(hoursValue) < 0) {
                  alert("Must enter valid hours.");
                  return;
                }
                overrides[id] = Number(hoursValue);
              } else {
                delete overrides[id];
              }

              dL.saveUserServiceOverrides(model.id, overrides);
              setState({ ...state, model, taskEdit: null });
            }}>
            <LabelItem inline label="Standard Hours:" value={plural(defaultHoursValue, "hr")} />

            <MyInput
              placeholder="Hours"
              boxStyle={{ marginBottom: 0 }}
              style={{ marginLeft: 15, maxWidth: 100 }}
              inputType="integer"
              minValue={0}
              value={hoursValue}
              onChange={value => {
                setState({ ...state, hoursValue: value });
              }}
            />
          </ModalBasic>
        ) : null
      }

      {
        priceEditId ? (
          <ModalBasic
            title="Edit Price:"
            onCancel={() => {
              setState({ ...state, priceEditId: null });
            }}
            onOk={() => {
              if (hoursValue) {
                if (!utils.isNumber(hoursValue)) {
                  alert("Must enter valid price.");
                  return;
                }

                if (Number(hoursValue) < 0) {
                  alert("Must enter valid price.");
                  return;
                }
                overrides[priceEditId] = Number(hoursValue);
              } else {
                delete overrides[priceEditId];
              }

              dL.saveUserServiceOverrides(model.id, overrides);
              setState({ ...state, model, priceEditId: null });
            }}>
            <LabelItem inline label="Standard Price:" value={money(defaultHoursValue)} />
            {yourDefaultHoursValue ? <LabelItem inline label="Your Rate:" value={money(yourDefaultHoursValue)} /> : null}

            <MyInput
              label="New Price:"
              placeholder="Price"
              boxStyle={{ marginBottom: 0 }}
              style={{ marginLeft: 15, maxWidth: 150 }}
              inputType="integer"
              minValue={0}
              value={hoursValue}
              onChange={value => {
                setState({ ...state, hoursValue: value });
              }}
            />
          </ModalBasic>
        ) : null
      }
      {taskVisible ? (
        <ViewServiceTask
          overrides={overrides}
          item={taskVisible}
          onClose={() => {
            setState({ ...state, taskVisible: null });
          }}
        />
      ) : null}

      {packageVisible ? (
        <ViewServicePackage
          hidePrice={hidePrice}
          overrides={overrides}
          service={service}
          item={packageVisible}
          onClose={() => {
            setState({ ...state, packageVisible: null });
          }}
        />
      ) : null}

      {deliverableVisible ? (
        <ViewServiceDeliverable
          item={deliverableVisible}
          onClose={() => {
            setState({ ...state, deliverableVisible: null });
          }}
        />
      ) : null}

      {optionVisible ? (
        <ViewServiceOption
          hidePrice={hidePrice}
          overrides={overrides}
          service={service}
          item={optionVisible}
          onClose={() => {
            setState({ ...state, optionVisible: null });
          }}
        />
      ) : null}

      {subServiceVisible ? (
        <ViewSubService
          hidePrice={hidePrice}
          overrides={overrides}
          service={service}
          item={subServiceVisible}
          onClose={() => {
            setState({ ...state, subServiceVisible: null });
          }}
        />
      ) : null}

      {inputVisible ? (
        <ViewServiceInput
          item={inputVisible}
          value={inputVisible}
          onClose={() => {
            setState({ ...state, inputVisible: null });
          }}
        />
      ) : null}

      <TouchButton
        label="Save Service"
        onPress={() => {
          if (!service) {
            alert("Must select service.");
            return;
          }

          /*
          var minPrice = 9999999;
          var maxPrice = 0;
          for (var i = 0; i < packages.length; i++) {
            const packageItem = packages[i];
            const hoursP = packageHours[packageItem.id];
      
            if (!utils.isNumber(hoursP)) {
              alert("Must enter valid hours for package '" + packageItem.name + "'.");
              return;
            }
      
            if (Number(hoursP) <= 0) {
              alert("Must enter valid hours for package '" + packageItem.name + "'.");
              return;
            }
            packageHours[packageItem.id] = Number(hoursP);
      
            if (Number(hoursP) < minPrice) {
              minPrice = Number(hoursP);
            }
            if (Number(hoursP) > maxPrice) {
              maxPrice = Number(hoursP);
            }
            if (packageItem.serviceOptions) {
              for (var k = 0; k < packageItem.serviceOptions.length; k++) {
                const option = packageItem.serviceOptions[i];
                const hoursP = packageHours[option.id];
      
                if (!utils.isNumber(hoursP)) {
                  alert("Must enter valid hours for package option '" + option.name + "'.");
                  return;
                }
      
                if (Number(hoursP) <= 0) {
                  alert("Must enter valid hours for package option '" + option.name + "'.");
                  return;
                }
      
                packageHours[option.id] = Number(hoursP);
              }
            }
          }
          * /
          
          return dL
            .newUserService(userServiceId, {
              overrides,
              makeServiceSellable,
              rateType,
              userId,
              service,
              serviceVersion
            })
            .then(function () {
              history.goBack();
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </EditDialog >
  );
}
*/

export function ViewServiceInput({ item, onClose }) {
  const { icon, formItems, name, description } = item;

  const similarServices = item.similarServices ? item.similarServices : [];

  return (
    <ModalBasic large title="Question Form:" okText="OK" onOk={onClose}>
      <HeaderText subHeader icon={icon} label={name} description={description} />

      <Section>
        <FormEditor formItems={formItems} />
      </Section>

      {similarServices.length ? (
        <Section>
          <HeaderText subHeader label="Helper Services:" description="Add services that can help to prepare the requirements of this form." />

          {similarServices.map((item) => {
            const { name, shortDescription } = item;
            return (
              <View style={{ margin: 10 }}>
                <TextLine value={name} />
                <TextLine value={shortDescription} />
              </View>
            );
          })}
        </Section>
      ) : null}
    </ModalBasic>
  );
}

export function ViewSubService(props) {
  const match = useRouteMatch("/:sectionId/*");
  const history = useHistory();
  const { onClose, allowWorkerSelect } = props;
  const model = props.item;

  const [state, setState] = useState({});
  const { serviceVersion, service, assignedToUser, inputData, assignedTo, packageId, serviceOptionIds, deliveryType } = model;
  const { inputs } = serviceVersion;

  const buttons = [
    {
      label: "View",
      onPress: () => {
        history.push(`/${match.params.sectionId}/service/${service.id}/admin-edit`);
      },
    },
    {
      label: "OK",
      onPress: onClose,
    },
  ];

  return (
    <ModalBasic full large title="Sub-Service:" buttons={buttons}>
      <RenderSelectedService serviceItem={model} />

      {allowWorkerSelect ? (
        <Section>
          <HeaderText subHeader label="Assigned Consultant:" description="Manage the consultant that is assigned for completion of this service." />

          <Toggle
            label="Assign a specific worker:"
            value={assignedToUser}
            onChange={(value) => {
              model.assignedToUser = value;
              setState({ ...state, model });
            }}
          />

          {assignedToUser ? (
            <FormItem label="Assigned Consultant:" description="Find a consultant that is capable of completing this service." box>
              <SelectWorker
                serviceId={service.id}
                serviceConfig={{
                  packageId, serviceOptionIds, deliveryType
                }}
                onChange={(value) => {
                  const { user } = value ? value : {};
                  model.assignedTo = user;
                  setState({ ...state, model });
                }}
                value={assignedTo}
              />
            </FormItem>
          ) : null}
        </Section>
      ) : null}

      {inputs && inputs.length > 0 ? (
        <Section>
          <HeaderText label="Questions:" subHeader description="Change or set answers to questions that are required for the start of this service. If not answer now they will be required before the service can be started." />

          <AddInputsButton
            label="Enter Answers"
            inputs={inputs}
            inputData={inputData}
            onChange={(value) => {
              model.inputData = value;
              setState({ ...state, model });
            }}
          />
        </Section>
      ) : null}
    </ModalBasic>
  );
}

function RenderTrainingNotes({ trainingNotes, draftTrainingNotes, onUpdate }) {
  const history = useHistory();
  const [state, setState] = useState({})
  const { url } = useRouteMatch();
  const { viewNote } = state
  const match = useRouteMatch("/:sectionId/*");

  return <React.Fragment>
    {trainingNotes.length > 0 ? <Section>
      <HeaderText subHeader label="Training Notes:" description="Connect with trainers and see training notes for this task." />

      {draftTrainingNotes.length > 0 ? <Section>
        <TextLine value="My Drafts:" bold size={18}></TextLine>

        {draftTrainingNotes.map((trainingNote, index) => {
          const { id, name, noteType, shortDescription } = trainingNote

          return <BoxItem key={id} name={name} nameSmall={getLabelItem(noteTypes, noteType)} description={shortDescription} onPress={() => {
            setState({ ...state, viewNote: trainingNote })
          }} onEdit={() => {
            history.push(`${url}/training-note/${id}/edit`)
          }} onDelete={() => {
            draftTrainingNotes.splice(index, 1)
            onUpdate(draftTrainingNotes)
            dL.getObj("TrainingNote", id).set("removed", true).save()
          }}>

          </BoxItem>
        })}
      </Section> : null}

      {draftTrainingNotes.length > 0 ? <TextLine value="Published Training:" bold size={18}></TextLine> : null}

      {trainingNotes.map((trainingNote) => {
        const { name, noteType, shortDescription, createdBy, createdAt, service } = trainingNote

        return <BoxItem name={name} nameSmall={getLabelItem(noteTypes, noteType)} description={shortDescription} onPress={() => {
          setState({ ...state, viewNote: trainingNote })
        }}>
          <FlexRow>
            <FlexExpand onPress={() => {
              history.push(`/${match.params.sectionId}/service/${service.id}/trainer/${createdBy.id}`)
            }}>
              <UserItem user={createdBy} />
            </FlexExpand>
            <DateTimeItem value={createdAt} fromNow />
          </FlexRow>
        </BoxItem>
      })}
    </Section> : null}

    {viewNote ? <ViewTrainingNote
      recordId={viewNote.id}
      onClose={() => {
        setState({ ...state, viewNote: null })
      }}
    /> : null}
  </React.Fragment>
}

export function ViewServicePackage({ service, item, onClose, overrides, hidePrice }) {
  const { package: servicePackage, icon, name, description, price, deliveryDays } = item;
  const { id } = servicePackage
  const { isFixedPriced, serviceOptions } = service;

  const opts = getIncludedServiceOptionsForBundledPackage({ serviceOptions, packageId: id });
  const optOpts = getOptionalServiceOptionsForBundledPackage({ serviceOptions, packageId: id });
  const inps = getInputsForBundledPackage({ service, packageId: id });
  const dels = getDeliverablesForBundledPackage({ service, packageId: id });
  const tsks = getTasksForBundledPackage({ packageId: id, service });
  const subs = getSubServicesForBundledPackage({ packageId: id, service });

  return (
    <ModalBasic full large title="Bundled Package:" okText="OK" onOk={onClose}>
      <HeaderText subHeader icon={icon} label={name} description={description} />

      <FlexRow>
        {isFixedPriced && !hidePrice ? <LabelItem label="Price:" description="Additional price that will be charged for this option." value={money(price)} /> : null}

        <LabelItem label="Delivery Days:" description="Additional delivery days that will be added for this option." value={plural(deliveryDays, "day")} />
      </FlexRow>

      <DisplayDeliverables deliverables={dels} />
      <DisplayOptions hidePrice service={service} overrides={overrides} options={opts} otherOptions={optOpts} />
      <DisplayInputs2 inputs={inps} />
      <DisplayTasks2 tasks={tsks} overrides={overrides} />
      <DisplaySubServices2 service={service} subServices={subs} />
    </ModalBasic>
  );
}

export function ViewServiceTask({ item, onClose }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, trainingNotes, draftTrainingNotes } = state;
  const { icon, name, description, hours, task, taskInstructions } = item;

  useEffect(() => {
    dL.getQuery("TrainingNote")
      .equalTo("status", "active")
      .equalTo("task", dL.getObj("ServiceTask", task.id))
      .containedIn("removed", [undefined, false])
      .include("createdBy")
      .include("taskVersion")
      .include("deliverableVersion")
      .descending("createdAt")
      .find()
      .then(function (objs) {
        const trainingNotes = dL.loadObjects("TrainingNote", objs)

        return dL.getQuery("TrainingNote")
          .equalTo("status", "draft")
          .equalTo("task", dL.getObj("ServiceTask", task.id))
          .equalTo("createdBy", dL.getObj("User", session.user.id))
          .containedIn("removed", [undefined, false])
          .include("createdBy")
          .include("taskVersion")
          .include("deliverableVersion")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            const draftTrainingNotes = dL.loadObjects("TrainingNote", objs)
            setState({ ...state, isLoading: false, trainingNotes, draftTrainingNotes });
          })
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalBasic large title="Task:" okText="OK" onOk={onClose}>
      <HeaderText subHeader icon={icon} label={name} description={description} />

      <LabelItem label="Estimated Hours:" description="Enter ideal hours for completion of this task." value={plural(hours, "hr")} />

      {taskInstructions ? (
        <Section>
          <HeaderText subHeader label="Task instructions to complete:" description="Provide a details explanation and resources on how to complete this task." />

          {taskInstructions ? renderHTML(taskInstructions.replace(/\n/g, "<br>")) : <NoRecords label="No instructions added." />}
        </Section>
      ) : null}

      <RenderTrainingNotes
        trainingNotes={trainingNotes}
        draftTrainingNotes={draftTrainingNotes}
        onUpdate={(draftTrainingNotes) => {
          setState({ ...state, draftTrainingNotes })
        }} />
    </ModalBasic>
  );
}

export function ViewServiceDeliverable({ item, onClose }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, trainingNotes, draftTrainingNotes } = state;
  const { deliverable, icon, formItems, deliverableType, name, description, file } = item;

  useEffect(() => {
    return dL.getQuery("TrainingNote")
      .equalTo("status", "active")
      .equalTo("deliverable", dL.getObj("ServiceDeliverable", deliverable.id))
      .containedIn("removed", [undefined, false])
      .include("createdBy")
      .include("taskVersion")
      .include("deliverableVersion")
      .descending("createdAt")
      .find()
      .then(function (objs) {
        const trainingNotes = dL.loadObjects("TrainingNote", objs)

        return dL.getQuery("TrainingNote")
          .equalTo("status", "draft")
          .equalTo("deliverable", dL.getObj("ServiceDeliverable", deliverable.id))
          .equalTo("createdBy", dL.getObj("User", session.user.id))
          .containedIn("removed", [undefined, false])
          .include("createdBy")
          .include("taskVersion")
          .include("deliverableVersion")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            const draftTrainingNotes = dL.loadObjects("TrainingNote", objs)

            setState({ ...state, isLoading: false, trainingNotes, draftTrainingNotes });
          })
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }


  return (
    <ModalBasic large title="Deliverable" okText="OK" onOk={onClose}>
      <HeaderText subHeader icon={icon} label={name} description={description} />

      <LabelItem label="Deliverable Format:" value={getLabelItem(deliverableTypes, deliverableType)} />

      {deliverableType == "file" ? (
        file ? (
          <FileElement readonly label="Example File/Template:" value={file} />
        ) : null
      ) : (
        <Section>
          <TextLine bold>Deliverable Response:</TextLine>
          <FormEditor formItems={formItems} />
        </Section>
      )}

      <RenderTrainingNotes
        trainingNotes={trainingNotes}
        draftTrainingNotes={draftTrainingNotes}
        onUpdate={(draftTrainingNotes) => {
          setState({ ...state, draftTrainingNotes })
        }} />
    </ModalBasic>
  );
}

export function ViewServiceOption({ service, item, onClose, overrides, hidePrice }) {
  const { option, icon, name, description, price, deliveryDays } = item;
  const { id } = option
  const { isFixedPriced } = service;

  const inps = getInputsForServiceOption({ service, serviceOptionId: id });
  const dels = getIncludedDeliverablesForServiceOption({ service, serviceOptionId: id });
  const tsks = getTasksForServiceOption({ service, serviceOptionId: id });
  const subs = getSubServicesForServiceOption({ service, serviceOptionId: id });

  return (
    <ModalBasic large title="Service Option:" okText="OK" onOk={onClose}>
      <HeaderText subHeader icon={icon} label={name} description={description} />

      <FlexRow>
        {isFixedPriced && !hidePrice ? <LabelItem label="Price:" description="Additional price that will be charged for this option." value={money(price)} /> : null}

        <LabelItem label="Delivery Days:" description="Additional delivery days that will be added for this option." value={plural(deliveryDays, "day")} />
      </FlexRow>

      {inps.length > 0 || dels.length > 0 || tsks.length > 0 || subs.length > 0 ? <View style={{ height: 25 }} /> : null}

      {dels.length > 0 ? <DisplayDeliverables deliverables={dels} /> : null}

      <DisplayInputs2 inputs={inps} />
      <DisplayTasks2 tasks={tsks} overrides={overrides} />
      <DisplaySubServices2 service={service} subServices={subs} />
    </ModalBasic>
  );
}

export function DisplaySubServices2({ service, subServices }) {
  const [state, setState] = useState({});
  const { subServiceVisible } = state;

  return (
    <React.Fragment>
      {subServices.length > 0 ? (
        <Section>
          <SmallHeader bottom={12} label="Required Sub-Services:" />

          {subServices.map((item) => {
            const { assignedTo, costPrice, costHours, service, servicePackage, total, deliveryDays } = item;
            const { icon, name, shortDescription, businessRole, skillLevel } = service;
            return (
              <BoxItem
                onPress={() => {
                  setState({ ...state, subServiceVisible: item });
                }}
                icon={icon}
                name={name}
                nameSmall={servicePackage ? "Bundled Package: " + servicePackage.name : ""}
                description={shortDescription}
                rightRender={<Price label="Standard Cost:" price={total} days={deliveryDays} />}>
                {businessRole ? <TextLine grey size={14} top={4} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : null}

                {assignedTo ? <UserItem label="Consultant:" user={assignedTo} style={{ marginTop: 10 }} /> : null}

                <Hide>
                  <FlexRow top={4}>
                    <TextLine right={10} size={14} value={"Delivery: " + plural(deliveryDays, "day")} />

                    <TextLine size={14} value={"Cost: " + utils.money(costPrice) + " (" + plural(costHours, "hr") + ")"} />
                  </FlexRow>
                </Hide>
              </BoxItem>
            );
          })}
        </Section>
      ) : null}

      {subServiceVisible ? (
        <ViewSubService
          service={service}
          item={subServiceVisible}
          onClose={() => {
            setState({ ...state, subServiceVisible: null });
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

export function DisplayTasks2({ tasks, overrides }) {
  const [state, setState] = useState({});
  const { taskVisible } = state;

  return (
    <React.Fragment>
      {tasks.length > 0 ? (
        <Section>
          <TextLine bold bottom={12}>
            Required Tasks:
          </TextLine>

          {tasks.map((item) => {
            const { icon, name, description, id, hours } = item;
            const override = overrides ? overrides[id] : null;

            return (
              <BoxItem icon={icon} name={name} description={description}
                onPress={() => {
                  setState({ ...state, taskVisible: item });
                }} rightRender={<FlexRow alignTop>
                  {plural(override ? override : hours, "hr")}
                  {override ? " **" : null}
                </FlexRow>}>
              </BoxItem>
            );
          })}
        </Section>
      ) : null}

      {taskVisible ? (
        <ViewServiceTask
          overrides={overrides}
          item={taskVisible}
          onClose={() => {
            setState({ ...state, taskVisible: null });
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

export function DisplayInputs2({ inputs }) {
  const [state, setState] = useState({});
  const { inputVisible } = state;

  return (
    <React.Fragment>
      {inputs.length > 0 ? (
        <Section>
          <TextLine bold bottom={12}>
            Required Questions:
          </TextLine>

          {inputs.map((item) => {
            const { icon, name, description, formItems } = item;

            return (
              <BoxItem
                icon={icon}
                name={name}
                nameSmall={plural(formItems ? formItems.length : 0, "question")}
                description={description}
                onPress={() => {
                  setState({ ...state, inputVisible: item });
                }}
              />
            );
          })}
        </Section>
      ) : null}

      {inputVisible ? (
        <ViewServiceInput
          item={inputVisible}
          value={inputVisible}
          onClose={() => {
            setState({ ...state, inputVisible: null });
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

export function DisplayDeliverables({ deliverables, onPress, style }) {
  const [state, setState] = useState({});
  const { deliverableVisible } = state;

  return (
    <View style={style}>
      {deliverables.length > 0 ? (
        <View style={{ flex: 1 }}>
          <SmallHeader label="Included Deliverables:" />

          <ul>
            {deliverables.map((item) => {
              const { name, description, id } = item;

              return (
                <li key={id}>
                  <LinkButton2
                    onPress={() => {
                      if (onPress) {
                        onPress(item);
                      } else {
                        setState({ ...state, deliverableVisible: item });
                      }
                    }}
                    description={description}>
                    {name}
                  </LinkButton2>
                </li>
              );
            })}
          </ul>
        </View>
      ) : null}

      {deliverableVisible ? (
        <ViewServiceDeliverable
          item={deliverableVisible}
          onClose={() => {
            setState({ ...state, deliverableVisible: null });
          }}
        />
      ) : null}
    </View>
  );
}

export function DisplayOptions(props) {
  const [state, setState] = useState({});
  const { optionVisible } = state;

  var { overrides, service, options, otherOptions, onPress, hidePrice } = props;
  if (!options) {
    options = [];
  }
  if (!otherOptions) {
    otherOptions = [];
  }

  return (
    <React.Fragment>
      {options.length > 0 || otherOptions.length > 0 ? (
        <View style={{ flex: 1 }}>
          {options && options.length > 0 ? (
            <React.Fragment>
              <TextLine bold>Included Options:</TextLine>
              <ul>
                {options.map((item) => {
                  const { name, description, id } = item;
                  return (
                    <li key={id}>
                      <LinkButton2
                        onPress={() => {
                          if (onPress) {
                            onPress(item);
                          } else {
                            setState({ ...state, optionVisible: item });
                          }
                        }}
                        description={description}>
                        {name}
                      </LinkButton2>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          ) : null}

          {otherOptions.length > 0 ? (
            <React.Fragment>
              <TextLine bold top={options && options.length > 0 ? 20 : 0}>
                Available Options:
              </TextLine>

              <ul>
                {otherOptions.map((item) => {
                  const { name, description, deliveryDays, price, id } = item;
                  return (
                    <li key={id}>
                      <LinkButton2
                        onPress={() => {
                          if (onPress) {
                            onPress(item);
                          } else {
                            setState({ ...state, optionVisible: item });
                          }
                        }}
                        description={description}>
                        {name} {!hidePrice ? (price ? " for " + money(price) : null) : null} {deliveryDays ? " +" + plural(deliveryDays, "day") : null}
                      </LinkButton2>
                    </li>
                  );
                })}
              </ul>
            </React.Fragment>
          ) : null}
        </View>
      ) : null}

      {optionVisible ? (
        <ViewServiceOption
          overrides={overrides}
          service={service}
          item={optionVisible}
          onClose={() => {
            setState({ ...state, optionVisible: null });
          }}
        />
      ) : null}
    </React.Fragment>
  );
}
