import "./App.css";
import React, { useState, useEffect } from "react";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import Moment from "moment-business-days";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { __DEV__ } from "root/dev";
import { ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { Section, BoxItem, LabelItem, FlexExpand, FlexRow, Text, View, TouchButton, NoRecords } from "root/pack-1";

const { roundNumber, plural } = utils;

export function TimeRecord() {
  //user, start/end, hours, task record, staff aug service, staff aug share
  return <View>SOMETHING</View>;
}

export function TimeRecords() {
  const history = useHistory();
  const { sectionId } = useParams();

  return (
    <ListRender
      defaultSort={{ field: "startDate", desc: true }}
      type="TimeRecord"
      emptyLabel="No time log found."
      includes={["user", "staffAugService", "staffAugService.businessRole", "staffAugService.user", "task"]}
      filters={[
        {
          label: "Working",
          value: "open",
          onQuery: function (query) {
            query.exists("endDate", false);
          }
        },
        {
          label: "Closed",
          value: "closed",
          onQuery: function (query) {
            query.exists("endDate", true);
          }
        }
      ]}
      title="Time Log"
      description="Manage time log."
      renderItem={item => {
        const { startDate, user, endDate, seconds, task, staffAugService } = item;
        return (
          <BoxRowItem onPress={() => {
            history.push(`/${sectionId}/tasks/${task.id}`)
          }}>
            {task ? <LabelItem label="Task:" value={task.title} /> : null}

            {staffAugService ? <LabelItem label="Staff Service:" value={staffAugService.businessRole.name + " (" + staffAugService.serviceType + ")"} /> : null}

            <FlexRow top={6}>
              <LabelItem label="Start Time:" value={Moment(startDate).format("M/D/YYYY [at] h:mm a")} />
              {endDate ? <LabelItem label="End Time:" value={Moment(endDate).format("M/D/YYYY [at] h:mm a")} /> : "WORKING"}
              {seconds ? <LabelItem label="Hours:" value={plural(roundNumber(seconds / 60 / 60, 2), "hr")} /> : null}
            </FlexRow>

            <FlexRow top={12}>
              {staffAugService ? <UserItem label="Client:" user={staffAugService.user} style={{ marginRight: 12 }} /> : null}
              <UserItem label="Consultant:" user={user} />
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function MyTimeRecords() {
  const history = useHistory();
  const { sectionId } = useParams();

  return (
    <ListRender
      defaultSort={{ field: "startDate", desc: true }}
      type="TimeRecord"
      emptyLabel="No time log found."
      includes={["user", "staffAugService", "staffAugService.businessRole", "staffAugService.user", "task"]}
      filters={[
        {
          label: "Working",
          value: "open",
          onQuery: function (query) {
            query.exists("endDate", false);
          }
        },
        {
          label: "Closed",
          value: "closed",
          onQuery: function (query) {
            query.exists("endDate", true);
          }
        }
      ]}
      onWhere={query => {
        const userId = session.user.id;
        query.equalTo("user", dL.getObj("User", userId));
      }}
      title="My Time Log"
      description="Manage my time records."
      renderItem={item => {
        const { startDate, user, endDate, seconds, task, staffAugService } = item;
        return (
          <BoxItem onBoxPress={() => {
            history.push(`/${sectionId}/tasks/${task.id}`)
          }}>
            {task ? <LabelItem label="Task:" value={task.title} /> : null}

            {staffAugService ? <LabelItem label="Staff Service:" value={staffAugService.businessRole.name + " (" + staffAugService.serviceType + ")"} /> : null}

            <FlexRow top={6}>
              <LabelItem label="Start Time:" value={Moment(startDate).format("M/D/YYYY [at] h:mm a")} />
              {endDate ? <LabelItem label="End Time:" value={Moment(endDate).format("M/D/YYYY [at] h:mm a")} /> : "WORKING"}
              {seconds ? <LabelItem label="Hours:" value={plural(roundNumber(seconds / 60 / 60, 2), "hr")} /> : null}
            </FlexRow>

            {staffAugService ? <FlexRow top={12}><UserItem label="Client:" user={staffAugService.user} />  </FlexRow> : null}
          </BoxItem>
        );
      }}
    />
  );
}

export function EditTimeRecord() {
  const { recordId } = useParams();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ model: {}, isLoading: recordId ? true : false });
  const { isLoading } = state;

  useEffect(() => {
    if (recordId) {
      dL.getQuery("TimeRecord")
        .get(recordId)
        .then(function (obj) {
          setState({ ...state, isLoading: false, model: dL.loadTimeRecord(obj) });
        });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
}

export function RenderTimeLog({ item, onUpdate }) {
  const { startDate, endDate, seconds, task, id } = item;

  return (
    <BoxRowItem>
      {task ? <LabelItem label="Task:" value={task.title} /> : null}

      <FlexRow>
        <LabelItem label="Start Time:" value={Moment(startDate).format("M/D/YYYY [at] h:mm a")} />
        {endDate ? <LabelItem label="End Time:" value={Moment(endDate).format("M/D/YYYY [at] h:mm a")} /> : "WORKING"}
        {seconds ? <LabelItem label="Hours:" value={plural(utils.roundNumber(seconds / 60, 1), "hr")} /> : null}
        {!endDate ? (
          <TouchButton
            label="Stop Work"
            onPress={() => {
              dL.stopTimeRecord(id).then(function () {
                onUpdate();
              });
            }}
          />
        ) : null}
      </FlexRow>
    </BoxRowItem>
  );
}

export function DisplayTimeLogs({ times, onRefresh }) {
  return (
    <Section>
      <HeaderText subHeader label="Time Log:" />
      {times.length == 0 ? (
        <NoRecords label="No time log found." />
      ) : (
        times.map(item => {
          const { startDate, endDate, seconds, task, id, user } = item;
          return (
            <BoxRowItem>
              <FlexRow>
                <FlexExpand>{task ? <LabelItem label="Task:" value={task.title} /> : null}</FlexExpand>
                <UserItem label="Consultant:" user={user} />
              </FlexRow>

              <FlexRow>
                <LabelItem label="Start Time:" value={Moment(startDate).format("M/D/YYYY [at] h:mm a")} />
                {endDate ? <LabelItem label="End Time:" value={Moment(endDate).format("M/D/YYYY [at] h:mm a")} /> : "WORKING"}
                {seconds ? <LabelItem label="Hours:" value={plural(utils.roundNumber(seconds / 60, 1), "hr")} /> : null}
              </FlexRow>

              {!endDate ? (
                <TouchButton
                  label="Stop Work"
                  onPress={() => {
                    dL.stopTimeRecord(id).then(function () {
                      onRefresh();
                    });
                  }}
                />
              ) : null}
            </BoxRowItem>
          );
        })
      )}
    </Section>
  );
}