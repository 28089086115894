import "./App.css";
import { FlatList, TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import http from "root/utils/http";
import utils from "root/utils/functions";
import session from "root/utils/session";
import PromiseA from "root/utils/promise";
import Moment from "moment-business-days";
import { withRouter } from "react-router-dom";
import "root/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import packUtils from "root/pack-4";
import { SelectOptions, SelectUsers, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { BoxItem, CommentIcon, ModalBasic, FlexExpand, FlexRow, TabBar, Text, TextLine, View, TrashIcon, FormItem, MyInput, TouchButton, UploadFileElement, Avatar, NoRecords } from "root/pack-1";
import { firestore, firebase } from "root/firebase-db";
import Linkify from "linkifyjs/react";
import VisibilitySensor from "react-visibility-sensor";
import { MentionsInput, Mention } from "react-mentions";
import fs from "root/utils/firestore";
import Dock from "react-dock";
import StickyBox from "react-sticky-box";

const { plural, getFsDate } = utils;

export function getChatRoomsWorker({ id, chatRoomName, clientUsers, growlyUsers }) {
  const chatRooms = [
    {
      label: "Client Team", value: "c-team", roomName: chatRoomName, roomId: id + "-i", users: clientUsers,
      emptyRender: <BoxItem top={10}>
        <TextLine size={18} bottom={10} bold value="Start a chat with client" />
        <TextLine bottom={15} value="Quickly start a chat with the client to address key issues about this record. You can add links to specific part for quick references" />
      </BoxItem>
    }, {
      label: "Growly Team", value: "g-team", roomName: chatRoomName, roomId: id + "-g", users: growlyUsers,
      emptyRender: <BoxItem top={10}>
        <TextLine size={18} bottom={10} bold value="Ask our team a question?" />
        <TextLine bottom={15} value="Have a question. Directly ask our team that is dedicated to helping identify your ideal solution." />
      </BoxItem>
    }];
  return chatRooms
}

export function getChatRoomsBuyer({ id, chatRoomName, clientUsers, growlyUsers }) {
  const chatRooms = [
    {
      label: "Your Team", value: "c-team", roomName: chatRoomName, roomId: id + "-i", users: clientUsers,
      emptyRender: <BoxItem top={10}>
        <TextLine size={18} bottom={10} bold value="Start a chat with your team" />
        <TextLine bottom={15} value="Quickly start a chat with you team to address key issues about this record. You can add links to specific part for quick references" />
      </BoxItem>
    }, {
      label: "Growly Team", value: "g-team", roomName: chatRoomName, roomId: id + "-g", users: growlyUsers,
      emptyRender: <BoxItem top={10}>
        <TextLine size={18} bottom={10} bold value="Ask our team a question?" />
        <TextLine bottom={15} value="Have a question. Directly ask our team that is dedicated to helping identify your ideal solution." />
      </BoxItem>
    }];
  return chatRooms
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function ChatWindow({ chatRooms, children, tabOptions, onTabChange, tabValue, tabPanel }) {
  const { height: windowHeight, width } = useWindowDimensions();

  const [state, setState] = useState({ chatTabValue: chatRooms ? chatRooms[0].value : null });
  const { chatTabValue } = state;

  const user = session.user;
  const chatRoom = chatRooms ? chatRooms.find(item => item.value == chatTabValue) : null;
  if (chatRoom) {
    const { roomName, roomId, users: defaultUsers, emptyRender } = chatRoom;
    const users = defaultUsers.filter(item => !item)
    return (
      <FlexRow alignTop>
        <View style={{ flex: 1 }}>{children}</View>

        <View style={{ marginLeft: 25, width: 350 }}>
          {tabOptions && tabOptions.length > 0 ? (
            <TabBar
              style={{ marginBottom: 15 }}
              options={tabOptions}
              onChange={item => {
                onTabChange(item);
                setState({ ...state });
              }}
              value={tabValue}
            />
          ) : null}
          {tabValue == "chat" || !tabOptions || tabOptions.length == 0 ? (
            <View>
              {chatRooms.length > 1 ?
                <FlexRow>
                  <CommentIcon iconStyle={{ fontSize: 30, marginRight: 10 }} />
                  <TabBar
                    style={{ marginBottom: 4 }}
                    queryId="tbch"
                    options={chatRooms}
                    onChange={item => {
                      setState({ ...state, chatTabValue: item.value });
                    }}
                    value={chatTabValue}
                  />
                </FlexRow> : null}

              <View style={{ height: windowHeight - 250, display: "flex", flexDirection: "column" }}>
                <Chat roomName={roomName} roomId={roomId} chatType="group" firstName={user.firstName} lastName={user.lastName} avatar={user.avatar} roomUserId={roomId + user.id} defaultUsers={users} emptyRender={emptyRender} />
              </View>
            </View>
          ) : (
            tabPanel
          )}
        </View>
      </FlexRow>
    );
  } else {
    return children;
  }
}

export function useChat() {
  const [chat, setChat] = useState({});
  const { roomId } = chat;

  const loadChat = function (users, roomName) {
    setChat({
      roomId: getChatStr(users),
      roomName: roomName ? roomName : "",
      defaultUsers: users
    });
  };

  const isChatHilight = function (users) {
    return roomId == getChatStr(users);
  };

  return [chat, setChat, loadChat, isChatHilight];
}

export function getNewChatCount() {
  const promise = new PromiseA()

  firestore
    .collection("ChatUser")
    .where("userId", "==", session.user.id)
    .where("newMessageCount", ">", 0)
    .limit(100)
    .onSnapshot(function (results) {
      promise.resolve(results.docs.length);
    });

  return promise
}

export function useChatCount() {
  const [chatNewCount, setChatNewCount] = useState(0);

  useEffect(() => {
    const w1 = firestore
      .collection("ChatUser")
      .where("userId", "==", session.user.id)
      .where("newMessageCount", ">", 0)
      .limit(100)
      .onSnapshot(function (results) {
        setChatNewCount(results.docs.length);
      });

    return function () {
      return w1();
    };
  }, []);

  return chatNewCount;
}

export function openChat(data) {
  session.dispatch("chat-visible", data);
}

export function ChatDock() {
  const [visible, setVisible] = useState(false);
  const [chatData, setChatData] = useState();

  useEffect(() => {
    return session.watch("chat-visible", function (action, data) {
      setChatData(data);
      setVisible(true);
    });
  }, []);

  if (!visible) {
    return <View />;
  }

  const { roomName, roomId, chatType, users } = chatData ? chatData : {};

  return (
    <Dock position="right" isVisible={visible}>
      <View style={{ padding: 15, flexDirection: "column", height: "100%", display: "flex" }}>
        <Chat
          roomName={roomName}
          roomId={roomId}
          firstName={session.user.firstName}
          lastName={session.user.lastName}
          avatar={session.user.avatar}
          roomUserId={roomId + session.user.id}
          chatType={chatType}
          defaultUsers={users}
          onClose={() => {
            setVisible(false);
          }}
        />
      </View>
    </Dock>
  );
}

export function getRoomId(users) {
  const ids = {};
  var userIds = [];
  users
    .filter(item => item)
    .forEach(item => {
      var id = item.id;
      if (!ids[id]) {
        ids[id] = true;
        userIds.push(id);
      }
    });
  userIds = userIds.sort();
  var str = "";
  userIds.forEach(item => {
    str += item;
  });
  return str;
}

export function getChatStr(users) {
  var userIds = users.filter(item => item).map(item => item.id);
  userIds = userIds.sort();
  var str = "";
  userIds.forEach(item => {
    str += item;
  });
  return str;
}

class _MyChats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: "inbox",
      users: [],
      rooms: [],
      sent: [],
      isLoading: true
    };
  }

  setChat = chatId => {
    const th = this;
    firestore
      .collection("ChatRoom")
      .doc(chatId)
      .get()
      .then(function (results) {
        if (results.exists) {
          const room = results.data();
          th.setState({ roomName: room.roomName, roomId: room.id, chatType: room.chatType });
        }
      });
  };

  componentDidMount() {
    const th = this;
    const { state, props } = th;
    const { match } = props;
    const { chatId } = match.params;

    if (chatId) {
      th.setChat(chatId);
    }

    //can only access this room is part of defaultUsers or part of the ChatUsers, else deny access

    var { rooms, sent } = state;

    th.w2 = firestore
      .collection("ChatUser")
      .where("userId", "==", session.user.id)
      .orderBy("lastSendAt", "desc")
      .limit(100)
      .onSnapshot(
        function (results) {
          var rooms = sent;
          var promises = [];
          results.docChanges().forEach(change => {
            const roomUser = change.doc.data();

            if (change.type === "added") {
              if (!roomUser.hidden) {
                promises[promises.length] = firestore
                  .collection("ChatRoom")
                  .doc(roomUser.roomId)
                  .get()
                  .then(function (results) {
                    if (results.exists) {
                      const room = results.data();

                      rooms.push({
                        room,
                        roomUser,
                        lastMessageAt: roomUser.lastSendAt
                      });
                    }
                  });
              }
            } else if (change.type === "modified") {
              var index = rooms.findIndex(item => item.roomUser.id == roomUser.id);

              if (index != -1) {
                if (!roomUser.hidden) {
                  rooms[index].lastMessageAt = roomUser.lastSendAt;
                  rooms[index].roomUser = roomUser;
                } else {
                  rooms.splice(index, 1);
                }
              } else {
                promises[promises.length] = firestore
                  .collection("ChatRoom")
                  .doc(roomUser.roomId)
                  .get()
                  .then(function (results) {
                    if (results.exists) {
                      const room = results.data();

                      rooms.push({
                        room,
                        roomUser,
                        lastMessageAt: roomUser.lastSendAt
                      });
                    }
                  });
              }
            } else if (change.type === "removed") {
              var index = rooms.findIndex(item => item.roomUser.id == roomUser.id);

              if (index != -1) {
                rooms.splice(index, 1);
              }
            }
          });

          Promise.all(promises)
            .then(function () {
              rooms = rooms.sort((a, b) => getFsDate(b.lastMessageAt) - getFsDate(a.lastMessageAt));
              th.setState({ sent: rooms });
            })
            .catch(function (err) {
              alert("Error in loading:" + err.message);
            });
        },
        function (error) {
          console.log(error.message);
          alert("error" + error.message);
        }
      );

    th.w1 = firestore
      .collection("ChatUser")
      .where("userId", "==", session.user.id)
      .orderBy("lastMessageAt", "desc")
      .limit(100)
      .onSnapshot(
        function (results) {

          var promises = [];
          results.docChanges().forEach(change => {
            const roomUser = change.doc.data();

            if (change.type === "added") {
              if (!roomUser.hidden) {
                promises[promises.length] = firestore
                  .collection("ChatRoom")
                  .doc(roomUser.roomId)
                  .get()
                  .then(function (results) {
                    if (results.exists) {
                      const room = results.data();

                      rooms.push({
                        room,
                        roomUser,
                        lastMessageAt: room.chatType == "large-group" ? room.lastMessageAt : roomUser.lastMessageAt
                      });
                    }
                  });
              }
            } else if (change.type === "modified") {
              var index = rooms.findIndex(item => item.roomUser.id == roomUser.id);

              if (index != -1) {
                if (!roomUser.hidden) {
                  rooms[index].lastMessageAt = rooms[index].room.chatType == "large-group" ? rooms[index].room.lastMessageAt : roomUser.lastMessageAt;
                  rooms[index].roomUser = roomUser;
                } else {
                  rooms.splice(index, 1);
                }
              }
            } else if (change.type === "removed") {
              var index = rooms.findIndex(item => item.roomUser.id == roomUser.id);

              if (index != -1) {
                rooms.splice(index, 1);
              }
            }
          });

          Promise.all(promises)
            .then(function () {
              rooms = rooms.sort((a, b) => getFsDate(b.lastMessageAt) - getFsDate(a.lastMessageAt));

              th.setState({ rooms, isLoading: false });
            })
            .catch(function (err) {
              alert("Error in loading:" + err.message);
            });
        },
        function (error) {
          console.log(error.message);
          alert("error" + error.message);
        }
      );
  }

  componentWillUnmount() {
    const th = this;

    //th.b1();
    th.w1();
    th.w2();
  }

  render() {
    const th = this;
    const { state, props } = th;
    const { rooms, tabValue } = state;
    const { sectionId } = props.match.params;

    const { isLoading, visible, sent } = state;

    if (isLoading) {
      return <Text>Loading...</Text>;
    }

    var newCount = 0;
    rooms.forEach(item => {
      const { roomUser } = item;
      const hasNewMessages = roomUser.newMessageCount > 0;
      if (hasNewMessages) {
        newCount++;
      }
    });
    const tabs = [{ label: "Inbox (" + newCount + ")", value: "inbox" }, { label: "Sent", value: "sent" }];

    var fList = rooms;

    if (tabValue == "sent") {
      fList = sent;
    }

    return (
      <View>
        <HeaderText
          label="My Chats"
          onButtonPress={() => {
            th.setState({ visible: true });
          }}
        />

        <FlexRow alignTop>
          <FlexExpand>
            <TabBar
              queryId="tb"
              options={tabs}
              onChange={item => {
                th.setState({ tabValue: item.value });
              }}
              value={tabValue}
            />
            <FlatList
              style={{ flex: 1 }}
              keyExtractor={item => {
                return item.id;
              }}
              data={fList}
              ListEmptyComponent={() => <NoRecords label="No chats found." />}
              renderItem={({ item }) => {
                const { room, roomUser, lastMessageAt } = item;
                var options = [];
                if (roomUser.isMute) {
                  options.push({ label: "Un-Mute", value: "un-mute" });
                } else {
                  options.push({ label: "Mute", value: "mute" });
                }
                options.push({ label: "Leave Chat", value: "leave" });

                const hasNewMessages = roomUser.newMessageCount > 0;
                return (
                  <BoxRowItem style={{ borderColor: hasNewMessages ? "red" : "#c0c0c0" }} onPress={() => {
                    th.setState({ roomName: item.room.roomName, roomId: item.room.id, chatType: room.chatType });
                    props.history.push(`/${sectionId}/chats/${item.room.id}`);
                  }}>
                    <FlexRow alignTop>
                      <FlexExpand>
                        {room.roomName ? <TextLine bold style={{ flex: 1, marginBottom: 10 }}>
                          {room.chatType == "direct" ? "DIRECT: " : ""}{room.roomName}
                        </TextLine> : null}

                        {room.chatUsers ? (
                          <FlexRow>
                            {room.chatUsers.map((item) => (
                              <FlexRow style={{ marginRight: 10 }}>
                                <UserItem size={35} user={item} />
                              </FlexRow>
                            ))}
                          </FlexRow>
                        ) : null}

                        {hasNewMessages ? <TextLine uppercase bold top={10} size={12} color="red" value={plural(roomUser.newMessageCount, "NEW MESSAGE")} /> : null}
                      </FlexExpand>

                      <View alignRight>
                        <TextLine size={14}>{Moment(getFsDate(lastMessageAt)).fromNow()} </TextLine>

                        <TextLine color="grey" size={14}>
                          {plural(room.userCount, "user")}
                        </TextLine>

                        <SelectOptions
                          options={options}
                          onChange={selItem => {
                            if (selItem.value == "mute") {
                              firestore
                                .collection("ChatUser")
                                .doc(roomUser.id)
                                .update({
                                  isMute: true
                                });
                            } else if (selItem.value == "un-mute") {
                              firestore
                                .collection("ChatUser")
                                .doc(roomUser.id)
                                .update({
                                  isMute: false
                                });
                            } else if (selItem.value == "leave") {
                              firestore
                                .collection("ChatUser")
                                .doc(roomUser.id)
                                .update({
                                  hidden: true
                                });
                              th.setState({ roomId: null })
                            }
                          }}>
                          <TextLine bold size={12} value="ACTIONS" />
                        </SelectOptions>
                      </View>
                    </FlexRow>
                  </BoxRowItem>
                );
              }}
            />
          </FlexExpand>

          {state.roomId ? (
            <View style={{ marginLeft: 25, width: 350 }}>
              <View style={{ maxHeight: 550, display: "flex", flexDirection: "column" }}>
                <Chat roomName={state.roomName} roomId={state.roomId} firstName={session.user.firstName} lastName={session.user.lastName} avatar={session.user.avatar} roomUserId={state.roomId + session.user.id} chatType={state.chatType} defaultUsers={state.chatOrderUsers} onChangeRoom={({ roomId, chatType }) => {
                  th.setState({ roomId, chatType });
                }} />
              </View>
            </View>
          ) : null}
        </FlexRow>

        {visible ? (
          <NewChat onCancel={() => {
            th.setState({
              visible: false
            });
          }}
            onNew={({ chatType, users }) => {
              th.setState({
                visible: false,
                chatOrderUsers: users,
                roomId: getChatStr(users),
                chatType
              });
            }} />
        ) : null}
      </View>
    );
  }
}

const idCache = {}
function NewChat({ onCancel, onNew }) {
  const [state, setState] = useState({ users: [], tabValue: "direct" })
  const { users, tabValue } = state

  return <ModalBasic
    title="New Chat"
    okText="Talk"
    onCancel={onCancel}
    onOk={() => {
      if (users.length == 0) {
        alert("Must select an user.");
        return;
      }
      onNew({ users: [...users, session.user], chatType: tabValue })
    }}>
    <TabBar
      style={{ marginBottom: 25 }}
      options={[{ label: "1 on 1 Talk", value: "direct" }, { label: "Group Chat", value: "small-group" }]}
      onChange={item => {
        setState({ ...state, tabValue: item.value });
      }}
      value={tabValue}
    />

    <FormItem label="Add chat users:" required>
      <SelectUsers
        excludedValues={[...users, session.user]}
        onChange={items => {
          setState({ ...state, users: items });
        }}
        value={users}
      />
    </FormItem>
  </ModalBasic>
}

export const MyChats = withRouter(_MyChats);

export function addChatRoomUser({ roomId, user }) {
  const { firstName, lastName, avatar, id: userId } = user
  const roomUserId = roomId + userId

  //console.log(user)
  return firestore
    .collection("ChatUser")
    .doc(roomUserId)
    .get()
    .then(function (results) {
      if (!results.exists) {
        firestore
          .collection("ChatUser")
          .doc(roomUserId)
          .set(
            {
              waitingHidden: true,
              hidden: true,
              id: roomUserId,
              roomId,
              userId,
              createdAt: new Date(),
              firstName,
              lastName,
              avatar,
              lastViewedAt: new Date(),
              lastMessageAt: new Date(),
              lastReadAt: new Date()
            },
            { merge: true }
          );
        firestore
          .collection("ChatRoom")
          .doc(roomId)
          .update({
            chatUsers: firebase.firestore.FieldValue.arrayUnion({
              id: roomUserId,
              userId,
              firstName,
              lastName,
              avatar
            }),
            userCount: firebase.firestore.FieldValue.increment(1),
            lastUserAddedAt: new Date()
          });
      } else {
        firestore
          .collection("ChatUser")
          .doc(roomUserId)
          .set(
            {
              waitingHidden: true,
            },
            { merge: true }
          );
      }
    });
}

export function createChatRoom({ roomName, roomData, roomId, chatType, users }) {
  const promise = new PromiseA();
  firestore
    .collection("ChatRoom")
    .doc(roomId)
    .get()
    .then(function (results) {
      var room;

      if (!results.exists) {
        room = {
          roomName,
          roomData,
          id: roomId,
          createdAt: new Date(),
          chatType,
          userCount: 0,
          chatUsers: [],
          lastMessageAt: new Date()
        };
        firestore
          .collection("ChatRoom")
          .doc(roomId)
          .set(room, { merge: true });
      } else {
        room = results.data();
      }

      const promises = []
      users.filter(item => item).forEach(item => {
        const { id, avatar, firstName, lastName } = item;

        promises[promises.length] = addChatRoomUser({ roomId, user: { id, avatar, firstName, lastName } });
      });

      return Promise.all(promises).then(function () {
        promise.resolve({ room });
      })
    });
  return promise
}

class _Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    const { roomId, roomUserId } = props;

    return {
      tabValueCh: "",
      vis: true,
      presenceData: {
        roomId,
        roomUserId
      },
      isLoading: true,
      messages: [],
      extraMessages: [],
      messageMentions: "",
      message: "",
      files: [],
      tabValue1: "chat",
      isBottomReached: true
    };
  }

  updateRoom() {
    const th = this;

    th.componentWillUnmount();
    th.componentDidMount();
  }

  componentDidUpdate(prevProps) {
    const th = this;

    const { roomId } = th.props;
    const prevRoomId = prevProps.roomId;

    if (roomId && prevRoomId && roomId != prevRoomId) {
      th.setState(th.getInitialState(th.props), function () {
        th.updateRoom()
      });
    }
  }

  componentDidMount() {
    const th = this;
    const { state, props } = th;

    const { eid } = props.match.params;

    const { roomName, roomData, defaultUsers, roomId, roomUserId, chatType, firstName, lastName, avatar } = props;
    const { presenceData } = state;
    th.lastDocMessage = null;

    th.setState({ startChat: false })

    if (eid) {
      firestore
        .collection("ChatUser")
        .doc(eid)
        .get()
        .then(function (results) {
          if (results.exists) {
            //remove, allow to add to this chat
          }
        });
    }

    var users = []
    if (defaultUsers) {
      //const userIds = {}
      users = defaultUsers
      /*
        .filter(item => {
          if (item) {
            const { id } = item;
            const rtn = !userIds[id] && roomId + id != roomUserId;
            userIds[id] = true
            return rtn
          }
        })
        */
    }

    return createChatRoom({ roomData, roomId, chatType, users }).then(function ({ room }) {
      th.setState({ room });

      firestore
        .collection("ChatUser")
        .doc(roomUserId)
        .get()
        .then(function (results) {
          if (results.exists) {
            firestore
              .collection("ChatUser")
              .doc(roomUserId)
              .update({
                //newMessageCount: 0,
                lastViewedAt: new Date()
              });
          }
        });

      firestore
        .collection("ChatUser")
        .doc(roomUserId)
        .get()
        .then(function (results) {
          var roomUser;

          if (!results.exists) {
            roomUser = {
              hidden: true,
              id: roomUserId,
              roomId,
              userId: session.user.id,
              createdAt: new Date(),
              firstName,
              lastName,
              avatar,
              //userData,
              lastViewedAt: new Date(),
              lastMessageAt: new Date(),
              lastReadAt: new Date()
            };
            firestore
              .collection("ChatUser")
              .doc(roomUserId)
              .set(roomUser, { merge: true });

            firestore
              .collection("ChatRoom")
              .doc(roomId)
              .update({
                chatUsers: firebase.firestore.FieldValue.arrayUnion({
                  id: roomUserId,
                  userId: session.user.id,
                  firstName, lastName,
                  avatar
                }),
                userCount: firebase.firestore.FieldValue.increment(1),
                lastUserAddedAt: new Date()
              });
          } else {
            roomUser = results.data();
            /*
            if (roomUser.hidden) {
              firestore
                .collection("ChatUser")
                .doc(roomUserId)
                .update({hidden: false, lastMessageAt: new Date()});
            }
            */

            if (!roomUser.lastReadAt) {
              firestore
                .collection("ChatUser")
                .doc(roomUserId)
                .update({ lastReadAt: new Date() });
            }
          }

          th.setState({ roomUser });
        });

      th.w3 = firestore
        .collection("status")
        .where("roomId", "==", roomId)
        .onSnapshot(
          function (results) {
            var statuses = {};

            results.forEach(function (doc) {
              var statusDoc = doc.data();
              statuses[statusDoc.roomUserId] = statusDoc.state == "online";
            });

            th.setState({ statuses });
          },
          function (error) {
            console.log(error.message);
            alert("error" + error.message);
          }
        );

      th.w2 = firestore
        .collection("ChatUser")
        .where("roomId", "==", roomId)
        .onSnapshot(
          function (results) {
            const users = [];

            results.forEach(function (doc) {
              users.push(doc.data());
            });

            th.setState({ users });
          },
          function (error) {
            console.log(error.message);
            alert("error" + error.message);
          }
        );

      var promiseM = new PromiseA();
      var first = true;
      th.w1 = firestore
        .collection("ChatMessage")
        .where("roomId", "==", roomId)
        .orderBy("createdAt", "desc")
        .limit(25)
        .onSnapshot(
          function (results) {
            var extraSet = false;
            const { isBottomReached, messages, extraMessages } = th.state;

            results.docChanges().forEach(change => {
              const chatMessage = change.doc.data();

              if (change.type === "added") {
                if (first) {
                  messages.push(chatMessage);
                } else if (isBottomReached) {
                  messages.unshift(chatMessage);
                } else {
                  extraSet = true;
                  extraMessages.unshift(chatMessage);
                }
              }
            });

            if (first) {
              th.lastDocMessage = results.docs[results.docs.length - 1];
              first = false;
            }

            if (extraSet) {
              th.setState({ extraMessages });
            } else {
              th.setState({ messages });
            }
            promiseM.resolve();
          },
          function (error) {
            console.log(error.message);
            alert("error" + error.message);
          }
        );

      return promiseM.then(function () {
        return db
          .getQuery("ChatMessage")
          .equalTo("user", dL.getObj("User", session.user.id))
          .equalTo("roomId", roomId)
          .first()
          .then(function (obj) {
            if (obj) {
              th.setState({
                mentions: obj.get("mentions"),
                messageMentions: obj.get("messageMentions"),
                message: obj.get("message"),
                files: obj.get("files")
              });
            }

            th.setState({ isLoading: false });
          });
      });
    });

    packUtils.presence(roomId, roomUserId, presenceData);
  }

  componentWillUnmount() {
    const th = this;
    const { props } = th;

    const { roomId, roomUserId } = props;
    packUtils.unpresence(roomId, roomUserId);

    if (th.w1) {
      th.w1();
      th.w2();
      th.w3();
    }
  }

  addUserEmail = ({ roomId, email, eid }) => {
    const lEmail = email.toLowerCase();
    return firestore
      .collection("ChatUser")
      .doc(eid)
      .get()
      .then(function (results) {
        if (!results.exists) {
          firestore
            .collection("ChatUser")
            .doc(eid)
            .set(
              {
                eid,
                hidden: true,
                roomId,
                email: lEmail,
                userId: "",
                createdAt: new Date()
              },
              { merge: true }
            );
        }
      });
  };

  addUser = ({ roomId, userId, avatar, firstName, lastName }) => {
    return addChatRoomUser({
      roomId, user: {
        id: userId, avatar, firstName, lastName
      }
    })
  };

  render() {
    const th = this;
    const { props, state } = th;

    const { emptyRender, onClose, roomId, roomUserId, chatType, firstName, lastName, avatar } = props;
    const { startChat, newChatTitle, newChatVisible, tabValueCh, mentions, replyMessage, inviteMessage, inviteEmails, inviteVisible, inviteUsers, messageMentions, vis, statuses, isLoading, message, files, tabValue1, users, messages, roomUser, extraMessages, isBottomReached } = state;

    if (isLoading) {
      return <Text>Loading...</Text>;
    }

    const circleC = { marginLeft: 4, width: 8, height: 8, backgroundColor: "green", borderRadius: 100, marginRight: 4 };

    const linkProps = {
      onClick: () => {
        //alert("CLICK");
        /*
        if (!confirm("Are you sure you want to leave this page?")) {
          event.preventDefault();
        }
        */
      }
    };
    //.replaceAll(regEx, "http://somelink/people/$0")

    const destroyMessage = function ({ roomId }) {
      return db
        .getQuery("ChatMessage")
        .equalTo("user", dL.getObj("User", session.user.id))
        .equalTo("roomId", roomId)
        .first()
        .then(function (obj) {
          if (obj) {
            return obj.destroy();
          }
        });
    };

    const saveMessage = function ({ roomId, messageMentions, message, files }) {
      return db
        .getQuery("ChatMessage")
        .equalTo("user", dL.getObj("User", session.user.id))
        .equalTo("roomId", roomId)
        .first()
        .then(function (obj) {
          if (!obj) {
            obj = db
              .getObj("ChatMessage")
              .set("user", dL.getObj("User", session.user.id))
              .set("roomId", roomId);
          }
          return obj
            .set("mentions", mentions)
            .set("message", message)
            .set("messageMentions", messageMentions)
            .set("files", files)
            .save();
        });
    };

    const renderMessage = function ({ item, limitMessage }) {
      const { user, message, createdAt, files } = item;
      const online = statuses[item.roomUserId];

      return (
        <React.Fragment>
          <TextLine size={12} color="grey" value={Moment(getFsDate(createdAt)).fromNow()} />
          {user ? (
            <FlexRow bold>
              <Avatar size={25} value={user.avatar} style={{ marginRight: 6 }} />
              <Text style={{ fontWeight: "bold" }}>{user.firstName} {user.lastName} says:</Text>

              {online ? <View style={circleC} /> : null}
            </FlexRow>
          ) : null}

          <Linkify
            options={{
              attributes: linkProps
            }}>
            <div style={{ whiteSpace: "pre-wrap" }} className={limitMessage ? "d-message" : ""}>
              {message}
            </div>
          </Linkify>

          {files && files.length > 0
            ? files.map((item) => {
              var url = http.baseUrl + "/sign_file?remotePath=" + item.path + item.file + "&name=" + item.fileName;
              return (
                <View>
                  <a href={url} download={item.fileName}>
                    {item.fileName}
                  </a>
                </View>
              );
            })
            : null}
        </React.Fragment>
      );
    };

    const doSend = function () {
      firestore
        .collection("ChatUser")
        .doc(roomUserId)
        .update({
          lastSendMessageAt: new Date()
        });

      fs.sendChatMessage({ firstName, lastName, avatar, chatType, roomUserId, roomId, message, files, users, replyMessage });

      if (tabValueCh == "new") {
        //create new chat with
        const newRoomId = utils.guid()

        const user = session.user
        const users = [user]
        mentions.forEach(item => {
          const { userId, avatar, firstName, lastName } = idCache[item.id]
          users.push({ id: userId, avatar, firstName, lastName })
        });

        createChatRoom({ roomName: newChatTitle, roomId: newRoomId, chatType: "group", users }).then(function ({ room }) {
          const newRoomUserId = newRoomId + user.id

          fs.sendChatMessageAPI({ roomId: newRoomId, roomUserId: newRoomUserId, message, files })

          if (props.onChangeRoom) {
            props.onChangeRoom({ roomId: newRoomId, chatType: "group" })
          } else {
            openChat({
              roomId: newRoomId,
              chatType: "group"
            });
          }
        })

      } else if (tabValueCh == "invite") {
        //invite all mentioned users to this chat

        mentions.forEach(item => {
          const { userId, avatar, firstName, lastName } = idCache[item.id]
          th.addUser({ roomId, userId, avatar, firstName, lastName });
        });
      }

      setTimeout(function () {
        destroyMessage({ roomId });
      }, 1000);

      th.setState({ newChatTitle: null, mentions: [], replyMessage: null, messageMentions: "", message: "", files: [] });
    }

    if (messages.length == 0 && emptyRender && !startChat) {
      return <View>
        {emptyRender}

        <TouchButton label="Start Chat"
          onPress={() => {
            th.setState({ startChat: true });
          }} />
      </View>
    }

    return (
      <View style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden", borderRadius: "6px", border: "2px solid #e0e0e0", padding: 10 }}>
        <FlexRow style={{ marginBottom: 15 }}>
          <FlexExpand>
            <TabBar
              options={[{ label: "Messages", value: "chat" }, { label: "People (" + users.length + ")", value: "users" }]}
              onChange={item => {
                th.setState({ tabValue1: item.value });
              }}
              value={tabValue1}
            />
          </FlexExpand>

          {chatType != "direct" ? (
            <TouchButton
              micro
              label="Invite"
              onPress={() => {
                th.setState({ inviteVisible: true, inviteUsers: [] });
              }}
            />
          ) : null}

          {onClose ? (
            <TouchButton
              style={{ marginLeft: 8 }}
              grey
              micro
              label="Close"
              onPress={() => {
                onClose();
              }}
            />
          ) : null}
        </FlexRow>

        {tabValue1 == "chat" ? (
          <View style={{ flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
            {messages.length == 0 ? <NoRecords style={{ marginBottom: 50 }} label="No chat messages." /> : null}
            <div style={{ position: "relative", flex: 1, display: "flex", flexDirection: "column", overflow: "hidden" }}>
              <div id="scroll-chat" style={{ flex: 1, display: "flex", flexDirection: "column-reverse", overflow: "scroll" }}
                onScroll={event => {
                  if (event.target.scrollTop == 0) {
                    const newState = { isBottomReached: true };
                    if (extraMessages.length > 0) {
                      var messages2 = extraMessages.concat(messages);
                      newState.extraMessages = [];
                      newState.messages = messages2;
                      const el = document.getElementById("scroll-chat");
                      el.scrollTop = 0;
                    }
                    th.setState(newState);
                  } else if (isBottomReached) {
                    th.setState({ isBottomReached: false });
                  }
                }}>
                {messages.map(item => {
                  const { createdAt, replyMessage } = item;
                  const isNew = getFsDate(createdAt) >= getFsDate(roomUser.lastReadAt) && item.roomUserId != roomUserId;

                  return (
                    <View className="hidden-parent" style={{ position: "relative", padding: 8, borderRadius: 6.7, borderWidth: 2, borderColor: isNew ? "red" : "#c0c0c0", borderStyle: "solid", marginBottom: 6 }}>
                      {replyMessage ? (
                        <View
                        /*
                          onPress={() => {
                            //go to this message
                            //need to load this much history the need to move cursor to this item
                          }}*/
                        >
                          <TextLine size={12} bold value="Reply To:" />
                          <View style={{ position: "relative", padding: 8, borderRadius: 6.7, borderWidth: 2, borderColor: isNew ? "red" : "grey", borderStyle: "solid", marginBottom: 6 }}>{renderMessage({ item: replyMessage, limitMessage: true })}</View>
                        </View>
                      ) : null}

                      {renderMessage({ item })}

                      <div className="hidden" style={{ display: "flex", position: "absolute", right: 5, bottom: 4, alignItems: "flex-end" }}>
                        <TouchableOpacity
                          onPress={() => {
                            th.setState({ replyMessage: item });
                          }}>
                          <TextLine size={10} color="grey" value="Reply" />
                        </TouchableOpacity>
                        {isNew ? (
                          <TouchableOpacity
                            style={{ marginLeft: 10 }}
                            onPress={() => {
                              //get all newer message that are not from the users as not read
                              firestore
                                .collection("ChatMessage")
                                .where("roomId", "==", roomId)
                                .where("createdAt", ">", createdAt)
                                .get()
                                .then(function (results) {
                                  var count = 0;

                                  results.forEach(item => {
                                    if (item.data().roomUserId != roomUserId) {
                                      count++;
                                    }
                                  });

                                  roomUser.lastReadAt = Moment(getFsDate(createdAt))
                                    .add(1, "milliseconds")
                                    .toDate();
                                  firestore
                                    .collection("ChatUser")
                                    .doc(roomUserId)
                                    .update({ lastReadAt: roomUser.lastReadAt, newMessageCount: count }, function () {
                                      alert("err");
                                    });

                                  th.setState({ roomUser });
                                });
                            }}>
                            <TextLine size={10} color="grey" value="Mark above as read" />
                          </TouchableOpacity>
                        ) : null}
                      </div>
                    </View>
                  );
                })}

                {vis ? (
                  <VisibilitySensor
                    onChange={isVisible => {
                      if (isVisible) {
                        th.setState({ vis: false });

                        if (!th.lastDocMessage) {
                          return;
                        }

                        firestore
                          .collection("ChatMessage")
                          .where("roomId", "==", roomId)
                          .orderBy("createdAt", "desc")
                          .startAfter(th.lastDocMessage)
                          .limit(100)
                          .get()
                          .then(function (results) {
                            const { messages } = th.state;

                            if (results.docs.length > 0) {
                              results.forEach(item => {
                                const chatMessage = item.data();
                                messages.push(chatMessage);
                              });

                              th.lastDocMessage = results.docs[results.docs.length - 1];
                              th.setState({ messages, vis: true });
                            }
                          });
                      }
                    }}>
                    <div>Loading more...</div>
                  </VisibilitySensor>
                ) : null}
              </div>

              {extraMessages.length > 0 ? (
                <View style={{ background: "rgba(0,0,0,.65)", position: "absolute", bottom: 0, left: 0, alignItems: "center", right: 0, margin: 8, borderRadius: 22.7 }}>
                  <TouchableOpacity
                    style={{ alignItems: "center", paddingVertical: 4, paddingHorizontal: 12 }}
                    onPress={() => {
                      const newState = { isBottomReached: true };
                      const messages2 = extraMessages.concat(messages);
                      newState.extraMessages = [];
                      newState.messages = messages2;
                      const el = document.getElementById("scroll-chat");
                      el.scrollTop = 0;
                      th.setState(newState);
                    }}>
                    <TextLine bold color="white" size={12}>
                      New Messages
                    </TextLine>
                  </TouchableOpacity>
                </View>
              ) : null}
            </div>
            <div>
              {replyMessage ? (
                <FlexRow style={{ marginTop: 10, marginBottom: 10 }}>
                  <TextLine bold size={12} value="Reply to Message:" />
                  <TextLine
                    left={8}
                    size={12}
                    color="grey"
                    onPress={() => {
                      th.setState({ replyMessage: null });
                    }}>
                    Remove
                  </TextLine>
                </FlexRow>
              ) : null}

              <View style={{ position: "relative" }}>
                <MentionsInput
                  allowSpaceInQuery={true}
                  className="mention-input"
                  allowSuggestionsAboveCursor={true}
                  placeholder="Enter message..."
                  value={state.messageMentions}
                  onChange={(event, newValue, newPlainTextValue, mentions) => {
                    mentions.forEach(item => {
                      item.cacheItem = idCache[item.id]
                    })
                    th.setState({ message: newPlainTextValue, messageMentions: newValue, mentions });
                  }}
                  onBlur={() => {
                    saveMessage({ roomId, files, message, messageMentions });
                  }}>
                  <Mention
                    className="mention-item"
                    trigger="@"
                    data={(search, callback) => {
                      const getUsers = function (arr) {
                        return arr.map(item => {
                          const { id, firstName, lastName, email, avatar } = item;
                          const hash = utils.getHashCode(id ? id : email)
                          idCache[hash] = item
                          return { id: hash, display: firstName ? (firstName + " " + lastName) : email, avatar };
                        })
                      }

                      if (search) {
                        return dL.getQuery("User")
                          .containedIn("removed", [undefined, false])
                          .startsWith("searchText", search)
                          .limit(25)
                          .find()
                          .then(function (objs) {
                            const users1 = dL.loadObjects("User", objs)
                            callback([...getUsers(users1), ...getUsers(users)])
                          })
                      } else {
                        callback(getUsers(users))
                      }
                    }}
                    displayTransform={(id, display) => {
                      return "@[" + display + "](" + id + ")";
                    }}
                    appendSpaceOnAdd={true}
                    renderSuggestion={item => {
                      const { display, avatar } = item;
                      return (
                        <FlexRow style={{ marginBottom: 8 }}>
                          <Avatar size={25} value={avatar} />
                          <Text style={{ marginLeft: 4, fontSize: 14 }}>{display}</Text>
                        </FlexRow>
                      );
                    }}
                  />
                </MentionsInput>
                {mentions && mentions.length > 0 ? <View style={{ marginTop: 10 }}>
                  <TextLine bold value="Mentioned in message:" />
                  {mentions.map(item => {
                    if (item.cacheItem) {
                      const { email, firstName, lastName, id } = item.cacheItem
                      var foundUser
                      var foundEmail: String
                      var online: Boolean
                      if (id) {
                        foundUser = users.find(item => item.id == id)
                        if (foundUser) {
                          online = statuses[foundUser.id];
                        }
                      } else {
                        foundEmail = users.find(item => item.email == email)
                      }
                      return <View>
                        {firstName ? firstName + " " + lastName : email}
                        {!foundUser && !foundEmail ? <TextLine size={10} value="Not In Chat" /> : null}
                      </View>
                    }
                  })}
                  <TabBar
                    style={{ marginTop: 10 }}
                    options={[{ label: "Do Nothing", value: "" }, mentions.filter(item => {
                      if (item.cacheItem) {
                        const { email, id } = item.cacheItem
                        var foundUser
                        var foundEmail: String
                        if (id) {
                          foundUser = users.find(item => item.id == id)

                        } else {
                          foundEmail = users.find(item => item.email == email)
                        }
                        return !foundEmail && !foundUser
                      }
                    }).length > 0 ? { label: "Invite", value: "invite" } : null, { label: "New Chat", value: "new" }]}
                    onChange={item => {
                      th.setState({ tabValueCh: item.value });
                    }}
                    value={tabValueCh}
                  />
                </View>
                  : null}

                {files.length > 0
                  ? files.map((item, index) => {
                    var url = http.baseUrl + "/sign_file?remotePath=" + item.path + item.file + "&name=" + item.fileName;
                    return (
                      <FlexRow>
                        <a href={url} download={item.fileName}>
                          {item.fileName}
                        </a>
                        <TrashIcon
                          style={{ marginLeft: 10 }}
                          onPress={() => {
                            files.splice(index, 1);
                            th.setState({ files });
                            saveMessage({ roomId, files, message, messageMentions });
                          }}
                        />
                      </FlexRow>
                    );
                  })
                  : null}
                <UploadFileElement
                  style={{ position: "absolute", right: 8, top: 14 }}
                  onChange={file => {
                    var files1 = files ? files : [];
                    files1.push(file);
                    th.setState({ files: files1 });
                    saveMessage({ roomId, files: files1, message, messageMentions });
                  }}
                  onProgress={() => { }}>
                  <FontAwesomeIcon icon={faPaperclip} style={{ fontSize: 18, color: "grey" }} />
                </UploadFileElement>
              </View>

              <TouchButton
                style={{ marginTop: 6, width: "100%" }}
                label="Send"
                onPress={() => {
                  if (tabValueCh == "new") {
                    th.setState({ newChatVisible: true })
                  } else {
                    doSend()
                  }
                }}
              />
            </div>
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            {users.map((item, index) => {
              const { eid, userId, id, lastSendMessageAt, email } = item;
              const online = statuses[id];

              return (
                <View style={{ marginBottom: 15 }}>
                  <FlexRow>
                    {userId ? <UserItem size={35} user={item} /> : <TextLine value={email} />}
                    {online ? <View style={circleC} /> : null}
                    {!userId ? (
                      <TrashIcon
                        style={{ marginLeft: 8 }}
                        onPress={() => {
                          users.splice(index, 1);

                          firestore
                            .collection("ChatUser")
                            .doc(eid)
                            .delete();

                          th.setState({});
                        }}
                      />
                    ) : null}
                  </FlexRow>
                  {lastSendMessageAt ? (
                    <TextLine size={12} color="grey">
                      Last Message: {Moment(getFsDate(lastSendMessageAt)).fromNow()}
                    </TextLine>
                  ) : null}
                </View>
              );
            })}
          </View>
        )}

        {newChatVisible ? (
          <ModalBasic
            title="New Chat"
            okText="Talk"
            onCancel={() => {
              th.setState({ newChatVisible: false });
            }}
            onOk={() => {
              doSend()
              th.setState({
                newChatVisible: false
              });
            }}>
            <MyInput
            focus
              label="Enter a subject for the chat:"
              value={newChatTitle}
              onChange={value => {
                th.setState({ ...state, newChatTitle: value });
              }}
            />
            <MyInput
              lastItem
              multiline
              label="Enter an invitation message:"
              description="Enter a short message in the email invite body."
              value={inviteMessage}
              onChange={value => {
                th.setState({ ...state, inviteMessage: value });
              }}
            />
          </ModalBasic>
        ) : null}

        {inviteVisible ? (
          <ModalBasic
            title="Invite to Chat"
            okText="Invite"
            onCancel={() => {
              th.setState({ inviteVisible: false });
            }}
            onOk={() => {
              if (inviteUsers.length == 0 && !inviteEmails) {
                alert("Must select an user.");
                return;
              }
              var emails = [];
              if (inviteEmails) {
                var sp = inviteEmails.split(/\n/);
                for (var i = 0; i < sp.length; i++) {
                  const email = sp[i];
                  if (email) {
                    if (!utils.isEmail(email)) {
                      alert(email + " is not a valid email.");
                      return;
                    }

                    emails.push(email);
                  }
                }
              }

              inviteUsers.forEach(user => {
                const { id, avatar, firstName, lastName } = user;

                th.addUser({ roomId, userId: id, avatar, firstName, lastName });
              });

              if (emails.length > 0) {
                emails.forEach(email => {
                  var eid = utils.guid();
                  th.addUserEmail({ roomId, email, eid });
                  var message = "Hi,<br><br>You are invite to join a Growly platform chat by " + session.user.firstName + " " + session.user.lastName + ".<br><br>" + inviteMessage + "<br><br>Follow the following link to join the chat: http://localhost:3000/user/chats/" + roomId + "/" + eid;
                  http.run("sendEmail", {
                    to: email,
                    subject: "New Chat Invitation",
                    message: message,
                    fromEmail: "support@interviewxpress.com"
                  });
                });
              }

              th.setState({
                inviteVisible: false
              });
            }}>
            <FormItem label="Add chat users:" required>
              <SelectUsers
                excludedValues={[...inviteUsers, session.user]}
                onChange={items => {
                  th.setState({ ...state, inviteUsers: items });
                }}
                value={inviteUsers}
              />
            </FormItem>

            <TextLine bold bottom={25} value="OR" />
            <MyInput
              multiline
              label="Emails:"
              description="Enter one email per line to invite to this chat."
              value={inviteEmails}
              onChange={value => {
                th.setState({ ...state, inviteEmails: value });
              }}
            />
            <MyInput
              lastItem
              multiline
              label="Email Message:"
              description="Enter a short message in the email invite body."
              value={inviteMessage}
              onChange={value => {
                th.setState({ ...state, inviteMessage: value });
              }}
            />
          </ModalBasic>
        ) : null}
      </View>
    );
  }
}

export const Chat = withRouter(_Chat);
