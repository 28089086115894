import "./App.css";
import React, { } from "react";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import Moment from "moment-business-days";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { Price } from "root/pack-3";
import { __DEV__ } from "root/dev";
import { ListRender, BoxRowItem, UserItem } from "root/pack-2";
import { DateTimeItem, Columns, LabelItem, FlexExpand, FlexRow, TextLine, View, FileElements, NoRecords } from "root/pack-1";

const { plural, getLabelItem } = utils;

function ProposalItem({ item }) {
  const { comments, files, sentAt, status, createdBy } = item;

  return (
    <View style={{ marginBottom: -15 }}>
      <Columns left={<LabelItem label="Send Date:" value={Moment(sentAt).fromNow()} />} middle={<LabelItem label="Delivery By:" value={createdBy.firstName + " " + createdBy.lastName} />} right={<LabelItem label="Status:" value={utils.getServiceProposalStatus(status)} />} />

      {comments ? <LabelItem label="Comments:" value={comments} /> : null}
      {files && files.length > 0 ? <FileElements readonly label="Files:" value={files} /> : null}
    </View>
  );
}

export function ProposalsWorker({ teamId }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const userId = session.user.id;

  return (
    <ListRender
      subHeader={teamId != null}
      searchFields={[{ field: "proposalNumber", prefix: "PS" }, { field: "email" }]}
      defaultSort={{ field: "createdAt", desc: true }}
      topFilters={[
        {
          label: "All",
          value: "all",
          onQuery: query => {
            query.equalTo("accessUsers.userId", userId);
          },
          autoHide: true
        },
        {
          label: "BD",
          value: "bd",
          description: "Business development workers on platform.",
          onQuery: query => {
            query.equalTo("businessDevelopment", dL.getObj("User", userId));
          },
          autoHide: true
        },
        {
          label: "PM",
          value: "pm",
          description: "Project management workers on platform.",
          onQuery: query => {
            query.equalTo("projectManager", dL.getObj("User", userId));
          },
          autoHide: true
        },
        {
          label: "SC",
          value: "sc",
          description: "Strategy consultant workers on platform.",
          onQuery: query => {
            query.equalTo("strategyConsultant", dL.getObj("User", userId));
          },
          autoHide: true
        }
      ]}
      filters={[
        {
          label: "Draft",
          value: "draft",
          onQuery: function (query) {
            query.equalTo("createdBy", dL.getObj("User", userId));
            query.equalTo("status", "draft");
            query.descending("lastUpdatedAt");
          },
          autoHide: true
        },
        {
          label: "Submitted By You",
          value: "submitted",
          onQuery: function (query) {
            query.equalTo("createdBy", dL.getObj("User", userId));
            query.equalTo("status", "open");
            query.greaterThan("expireDate", new Date());
            query.descending("sentAt");
          }
        },
        {
          label: "Expiring Soon",
          value: "expiring",
          onQuery: function (query) {
            const et = Moment()
              .add(2, "days")
              .toDate();
            query.equalTo("accessUsers.userId", userId);
            query.equalTo("status", "open");
            query.lessThan("expireDate", et);
            query.descending("sentAt");
          },
          autoHide: true
        },
        {
          label: "Expired",
          value: "expired",
          onQuery: function (query) {
            query.equalTo("accessUsers.userId", userId);
            query.equalTo("status", "open");
            query.lessThan("expireDate", new Date());
            query.descending("expireDate");
          },
          autoHide: true
        },
        {
          label: "Withdrawn",
          value: "withdrawn",
          onQuery: function (query) {
            query.equalTo("accessUsers.userId", userId);
            query.equalTo("status", "withdrawn");
            query.descending("withdrawnAt");
          },
          autoHide: true
        }
      ]}
      onButtonPress={() => {
        history.push(`/${sectionId}/proposal/add`);
      }}
      type="ServiceProposal"
      emptyLabel="No strategy maps found."
      includes={["user", "createdBy"]}
      title="Strategy Maps"
      description="Create and manage strategy maps."
      onWhere={query => {
        if (teamId) {
          query.equalTo("team", dL.getObj("Team", teamId));
        }
      }}
      renderItem={item => {
        const { id, serviceTypes, total, deliveryDays, createdBy, serviceItems, createdAt, title, user, status } = item;

        return (
          <BoxRowItem>
            <FlexRow alignTop>
              <FlexExpand>
                <DateTimeItem value={createdAt} fromNow />
                <TextLine
                  size={22}
                  value={title}
                  top={6}
                  onPress={() => {
                    history.push(`/${sectionId}/proposal/${id}`);
                  }}
                />
                {user ? <UserItem label="Client:" user={user} style={{ marginTop: 8 }} /> : <NoRecords label="Client: None" />}
              </FlexExpand>

              <View alignRight>
                <Price price={total} days={deliveryDays} />
                <TextLine size={12} uppercase color="grey" value={utils.getServiceProposalStatus(status)} />
                {serviceItems ? <TextLine color="grey" value={plural(serviceItems.length, "service")} /> : null}
                <UserItem label="Created By:" user={createdBy} style={{ marginTop: 8 }} />
              </View>
            </FlexRow>

            {serviceTypes ? (
              <TextLine size={14} color="grey" top={6}>
                {serviceTypes.map((item, index) => (index != 0 ? ", " : "") + getLabelItem(utils.serviceTypes, item))}
              </TextLine>
            ) : null}
          </BoxRowItem>
        );
      }}
    />
  );
}

export function ProposalsBuyer() {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      searchFields={[{ field: "proposalNumber", prefix: "PS" }]}
      defaultSort={{ field: "proposalDate", desc: true }}
      onWhere={query => {
        query.equalTo("user", dL.getObj("User", session.user.id));
      }}
      filters={[
        {
          label: "Received",
          value: "received",
          description: "These strategy maps that you have received recently.",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.greaterThan("expireDate", new Date());
            query.descending("sentAt");
          }
        },
        {
          label: "Expiring Soon",
          value: "expiring",
          description: "These strategy maps that you expiring soon.",
          onQuery: function (query) {
            const et = Moment()
              .add(2, "days")
              .toDate();

            query.equalTo("status", "open");
            query.lessThan("expireDate", et);
            query.descending("sentAt");
          },
          autoHide: true
        },
        {
          label: "Expired",
          value: "expired",
          description: "These strategy maps have already expired.",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.lessThan("expireDate", new Date());
            query.descending("expireDate");
          },
          autoHide: true
        },
        {
          label: "Withdrawn",
          value: "withdrawn",
          description: "These strategy maps have been withdrawn by the worker.",
          onQuery: function (query) {
            query.equalTo("status", "withdrawn");
            query.descending("withdrawnAt");
          },
          autoHide: true
        }
      ]}
      type="ServiceProposal"
      emptyLabel="No strategy maps found."
      includes={["user", "createdBy"]}
      title="Strategy Maps"
      description="Manage the strategy maps that you have received on the platform."
      renderItem={item => {
        const { id, serviceTypes, serviceItems, createdAt, createdBy, title, total, deliveryDays } = item;
        return (
          <BoxRowItem>
            <FlexRow alignTop>
              <FlexExpand>
                <TextLine
                  size={22}
                  value={title}
                  onPress={() => {
                    history.push(`/${sectionId}/proposal/${id}`);
                  }}
                />
                <UserItem label="Submitted By:" user={createdBy} style={{ marginTop: 12 }} />
              </FlexExpand>
              <View alignRight >
                <Price price={total} days={deliveryDays} />
                {serviceItems ? <TextLine color="grey" value={plural(serviceItems.length, "service")} /> : null}
                <View style={{ height: 12 }} />
                <DateTimeItem alignRight value={createdAt} fromNow />
              </View>
            </FlexRow>

            {serviceTypes ? (
              <TextLine size={14} color="grey" top={6}>
                {serviceTypes.filter(item=>item).map((item, index) => (index != 0 ? ", " : "") + getLabelItem(utils.serviceTypes, item))}
              </TextLine>
            ) : null}
          </BoxRowItem>
        );
      }}
    />
  );
}
