import "./App.css";
import db from "root/utils/db";
import React, { useState, useEffect } from "react";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { RenderUser } from "root/pack-3";
import { __DEV__ } from "root/dev";
import { BoxRowItem, ListRender, HeaderText } from "root/pack-2";
import { TouchButton, Section, ModalBasic, LinkButton, FlexExpand, FlexRow, Text, TextLine, View, CommentIcon, MyInput, Avatar, NoRecords } from "root/pack-1";
import { UserProfileWorker } from "root/worker"
import { getRoomId, openChat } from "root/chat";
import ReactCardFlip from 'react-card-flip';
import http from "root/utils/http";

export function RenderUserItem({ item, onPress }) {
  const [userId, setUserId] = useState();
  const [isFlipped, setIsFlipped] = useState()
  const rect = { overflow: "hidden", width: 225, height: 300, borderRadius: 6.7, borderWidth: 1, borderColor: "#c0c0c0", paddingTop: 25, paddingBottom: 12, paddingRight: 12, paddingLeft: 12, marginRight: 12, marginBottom: 15 };
  const rect2 = { width: "100%", textAlign: "center", height: 65, alignItems: "center", borderWidth: 1, justifyContent: "flex-end" };
  const rect1 = { marginTop: 12, width: "100%", textAlign: "center", flex: 1, alignItems: "center", borderWidth: 1, justifyContent: "center" };

  const { firstName, lastName, avatar, companyName, title, serviceCore, serviceTypes } = item;

  return (
    <ReactCardFlip isFlipped={isFlipped}>
      <View className="card card-bordered card-hover-shadow hidden-parent" onPress={() => {
        if (onPress) {
          onPress()
        } else {
          setUserId(item.id)
        }
      }} style={rect}>
        <View style={rect1} alignCenter>
          <Avatar value={avatar} />
          <FlexRow>
            <TextLine top={12} size={18} value={utils.concatString(firstName, " ", lastName)} />
            <CommentIcon style={{ marginLeft: 10, marginTop: 12 }} onPress={() => {
              openChat({
                roomId: getRoomId([session.user, item]),
                chatType: "direct",
                users: [session.user, item]
              });
            }} />
          </FlexRow>
          <TextLine top={4} size={14} value={utils.concatString(title, " at ", companyName)} />
        </View>

        <View style={rect2} alignCenter>
          {serviceTypes ? <TextLine maxLines={2} color="grey" size={12} value={utils.renderArray(serviceTypes)} /> : null}
          {serviceCore ? <TextLine top={6} color="grey" size={12} value={utils.renderArray(serviceCore)} /> : null}
        </View>

        <View alignCenter top={4} style={{ height: 15 }}>
          <div className="hidden">
            <LinkButton textStyle={{ fontSize: 11 }} label="Flip to Back" onPress={() => {
              setIsFlipped(true)
            }}></LinkButton>
          </div>
        </View>

        {userId ? (
          <ModalBasic
            full
            notCentered
            onCancel={() => {
              setUserId();
            }}>
            <UserProfileWorker userId={userId} />
          </ModalBasic>
        ) : null}
      </View>
      <View className="card card-bordered card-hover-shadow" onPress={() => {
        setIsFlipped(false)
      }} style={rect}>
        <FlexRow style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <FlexExpand>
            (More details about this user)
          </FlexExpand>
          <View alignCenter top={4} style={{ height: 15 }}>
            <div className="hidden">
              <LinkButton textStyle={{ fontSize: 11 }} label="Flip to Front" onPress={() => {
                setIsFlipped(false)
              }}></LinkButton>
            </div>
          </View>
        </FlexRow>
      </View>
    </ReactCardFlip>
  );
}

export function ServicesUsers() {
  const { serviceCategory, sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, list, searchText } = state;

  useEffect(() => {
    dL.getUsers({
      onWhere: (query) => {
        if (sectionId == "user") {
          query.equalTo("isPublicBuyerDirectory", true);
        } else if (sectionId == "worker") {
          query.equalTo("isPublicWorkerDirectory", true);
        }
      }
    }).then(function (users) {
      setState({ ...state, isLoading: false, list: users });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <View>
      <HeaderText label="Search for People:" description="Find people on the platform that meet can help deliver your services." style={{ marginBottom: 10 }} />

      <MyInput
        placeholder="Search..."
        value={searchText}
        onChange={text => {
          setState({ ...state, searchText: text });
        }}
      />

      <UserList
        list={list}
        searchText={searchText}
        onSelect={item => {
          history.push(`/${sectionId}/people/${item.id}`);
        }}
      />
    </View>
  );
}

export function UserList({ searchText, list, onSelect }) {
  var fList = list;

  if (searchText) {
    fList = list.filter(item => {
      return item.firstName && item.firstName.toLowerCase().startsWith(searchText.toLowerCase());
    });
  }

  return (
    <FlexRow style={{ flexWrap: "wrap" }}>
      {fList.length == 0 ? (
        <NoRecords label="No people found." />
      ) : (
        fList.map(item => {
          return (
            <RenderUserItem
              item={item}
              onPress={() => {
                onSelect(item);
              }}
            />
          );
        })
      )}
    </FlexRow>
  );
}

export function RestOfTeam({ allTeam }) {
  return allTeam.length > 0 ? (
    <Section>
      <HeaderText subHeader label="Rest of the Team:" description="Meet your extended delivery team." />

      <FlexRow>
        {allTeam.map(item => {
          return <RenderUser item={item} />;
        })}
      </FlexRow>
    </Section>
  ) : null;
}

export function UsersAdmin() {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      columns
      searchFields={[{ field: "searchText" }]}
      defaultSort={{ field: "lastUpdatedAt", desc: true }}
      topFilters={[
        {
          label: "Consultant",
          value: "workers",
          description: "All active worker profiles.",
          onQuery: function (query) {
            query.equalTo("isWorker", true);
          }
        },
        {
          label: "BD",
          value: "bd",
          description: "Business development workers on platform.",
          onQuery: function (query) {
            query.equalTo("enabledRoles.businessDevelopment", true);
          }
        },
        {
          label: "PM",
          value: "pm",
          description: "Project management workers on platform.",
          onQuery: function (query) {
            query.equalTo("enabledRoles.projectManager", true);
          }
        },
        {
          label: "SC",
          value: "sc",
          description: "Strategy consultant workers on platform.",
          onQuery: function (query) {
            query.equalTo("enabledRoles.strategyConsultant", true);
          }
        },
        {
          label: "RC",
          value: "rc",
          description: "Recruiter workers on platform.",
          onQuery: function (query) {
            query.equalTo("enabledRoles.recruiter", true);
          }
        }
      ]}
      defaultFilter="pending"
      filters={[
        {
          label: "Draft",
          value: "draft",
          onQuery: function (query) {
            query.equalTo("status", "draft");
            query.equalTo("createdBy", dL.getObj("User", session.user.id));
          },
          description: "Only your draft user profiles."
        },
        {
          label: "Pending",
          value: "pending",
          description: "User profiles pending approval."
        },
        {
          label: "Active",
          value: "active",
          description: "All active user profiles."
        },
        {
          label: "Archived",
          value: "archived",
          description: "All archived user profiles."
        }
      ]}
      onButtonPress={() => {
        history.push("/admin/people/add");
      }}
      type="User"
      emptyLabel="No users found."
      includes={[]}
      title="Users"
      description="Manage users on the platform."
      renderItem={item => {
        const { id } = item;

        return (
          <RenderUserItem
            item={item}
            onPress={() => {
              history.push(`/${sectionId}/people/${id}`);
            }}
          />
        );
      }}
    />
  );
}

export function RenderInvitations() {
  const [state, setState] = useState({ isLoading: true });

  const { sendInvite, usedInvitations, isLoading, list } = state
  const [refresh, setRefresh] = useState()

  useEffect(() => {
    db.getQuery("Invitation")
      .equalTo("createdBy", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const list = dL.loadObjects("Invitation", objs)
        setState({ ...state, usedInvitations: list.length, list, isLoading: false })
      })
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const userHasInvitations = session.user.allowedInvitations ? session.user.allowedInvitations : 3
  const left = userHasInvitations - usedInvitations

  return <View>
    <HeaderText subHeader label="Invitations:" description="Send an personalized invitation to join our platform." />

    <BoxRowItem>
      <TextLine bold size={22} value={(left < 0 ? 0 : left) + " of " + userHasInvitations + " Invitations Left"} />
      <TextLine top={10} bold size={16} value="Know someone who could use Growly growth services?" />
      <TextLine size={14} top={10} value="Use one your invitations to invite someone." />
      {usedInvitations < userHasInvitations ? <TouchButton micro style={{ marginTop: 15 }} label="Send Invite" onPress={() => {
        setState({ ...state, sendInvite: true })
      }} /> : null}

      {list.map(item => {
        const { email, user } = item
        return <BoxRowItem>
          <FlexRow>
            <FlexExpand>
              <TextLine size={14} value={email} />
            </FlexExpand>

            <TextLine left={25} size={12} grey value={user ? "Joined" : "Pending"} />
          </FlexRow>
        </BoxRowItem>
      })}

      {sendInvite ? (
        <SendInvite
          onCancel={() => {
            setState({ ...state, sendInvite: null });
          }}
          onSend={() => {
            setState({ ...state, sendInvite: null });
            setRefresh(new Date())
          }}
        />
      ) : null}
    </BoxRowItem>
  </View>
}

export function SendInvite({ onCancel, onSend }) {
  const [state, setState] = useState({});
  const { email, message } = state;

  return (
    <ModalBasic
      title="Invite Member"
      okText="Invite"
      onCancel={onCancel}
      onOk={() => {
        if (!utils.isEmail(email)) {
          alert("Must enter valid email.");
          return;
        }

        const sendInvitation = function ({ email }) {
          const inviteCode = utils.guid().toLowerCase()
          return dL.getObj("Invitation")
            .set("createdBy", db.getObj("User", session.user.id))
            .set("email", email)
            .set("inviteCode", inviteCode)
            .save()
            .then(function (obj) {
              const id = obj.id
              const emailMessage = "Hi,<br><br>You are invite to join a Growly platform chat by " + session.user.firstName + " " + session.user.lastName + ".<br><br>" + message + "<br><br>Follow the following link to join the chat: http://growly.app/user/invite/" + id;

              return http.run("sendEmail", {
                to: email,
                subject: "New Invitation",
                message: emailMessage,
                fromEmail: "support@interviewxpress.com"
              });
            })
        };

        return sendInvitation({ email }).then(function () {
          alert("Invitation Send.")
          return onSend();
        });
      }}>
      <MyInput
        label="Email:"
        value={email}
        onChange={value => {
          setState({ ...state, email: value });
        }}
      />
      <MyInput
        lastItem
        multiline
        label="Message:"
        description="Enter a short message in the email invite body."
        value={message}
        onChange={value => {
          setState({ ...state, message: value });
        }}
      />
    </ModalBasic>
  );
}
