import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { Typeahead } from "react-bootstrap-typeahead";
import { RenderDelayOptions } from "root/services";
import { ServiceEdit, AddInputsButton, SelectService, RenderSelectedService } from "root/services-select"
import Moment from "moment-business-days";
import { getMainServiceModel } from "root/service-model"
import { RenderServiceDetails } from "root/services-worker";
import { Quantity, Price, LimitProposalOptionsBox, LimitProposalPackageBox } from "root/pack-3";
import { UserProfileBuyer, WorkerSelection2, WorkerSelectionProposal, SelectWorker } from "root/worker";
import { reactLoading } from "root/loading";
import "root/loading.css";
import { __DEV__ } from "root/dev";
import PromiseA from "root/utils/promise";
import { validateStaffEdit, StaffView, StaffEdit, getStaffTotal } from "root/staff-aug";
import { SelectUser, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { Loading, IconPicker, Section, EditDialog, ModalBasic, LabelItem, LinkButton, FlexExpand, FlexRow, TabBar, Text, TextLine, View, TrashIcon, Toggle, FormItem, MyInput, TouchButton, SelectBox, BackButton } from "root/pack-1";
import { createServicePriceMap, getDoneDate, getStartDate, createProposalItemPriceMap, getFinalPriceDays2, getServicePriceAndDays2, getSelectedServiceOptions, getInputsForService, getDeliverablesForService, getVisiblePackage, calcStartDayIndex } from "root/service-functions";
import { RenderServiceNeedsUpdate } from "root/services"

const { getValueItem, userRoles, plural, getLabelItem, money, priorityLevels } = utils;

function LimitSection({ proposal, model, onUpdate }) {
  const [state, setState] = useState({});
  const { limitedToOptions, limitedToPackages } = model;

  return (
    <Section>
      <HeaderText subHeader label="Limiting Options:" description="Limit the selection of this service based on proposal options and bundled packages." />
      <LimitProposalOptionsBox
        label="Limit to Strategy Options:"
        value={limitedToOptions}
        options={proposal.options ? proposal.options : []}
        onChange={value => {
          model.limitedToOptions = value;
          setState({ ...state, model });
          onUpdate(model);
        }}
      />

      <LimitProposalPackageBox
        value={limitedToPackages}
        packages={proposal.packages ? proposal.packages : []}
        onChange={limitedToPackages => {
          model.limitedToPackages = limitedToPackages;
          setState({ ...state, model });
          onUpdate(model);
        }}
      />
    </Section>
  );
}

export function getServiceFinalData({ service, userService, servicePackage, serviceOptionIds, deliveryType }) {
  const packageId = servicePackage ? servicePackage.package.id : null;

  const { total: unitPrice, days } = getFinalPriceDays2({ serviceVersion: userService ? userService.currentVersion : service.currentVersion, packageId, serviceOptionIds, deliveryType })

  const { deliveryDays, price, extraFastDeliveryDays, hasExtraFastDelivery, extraFastPrice } = servicePackage ? servicePackage : service;

  const opts = getSelectedServiceOptions({ service, serviceOptionIds });
  const inputs = getInputsForService({ service, packageId, serviceOptionIds });
  const dels = getDeliverablesForService({ service, packageId, serviceOptionIds });

  return { days, unitPrice, opts, inputs, dels, deliveryDays, price, extraFastDeliveryDays, hasExtraFastDelivery, extraFastPrice };
}

function WorkerView2({ userRole }) {
  const [visibleUserId, setVisibleUserId] = useState();

  const { user, hourlyRate } = userRole;

  return (
    <BoxRowItem
      onPress={() => {
        setVisibleUserId(user.id);
      }}>
      <FlexRow>
        <FlexExpand>
          <UserItem user={user}>
            <TextLine grey size={14} top={-4} value={"Hourly Rate: " + money(hourlyRate)} />
          </UserItem>
        </FlexExpand>
      </FlexRow>

      {visibleUserId ? (
        <ModalBasic
          full
          notCentered
          onCancel={() => {
            setVisibleUserId();
          }}>
          <UserProfileBuyer userId={visibleUserId} />
        </ModalBasic>
      ) : null}
    </BoxRowItem>
  );
}

export function EditServiceInCart({ cartItem, isEdit, onCancel, onAddToCart, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, cartItemE } = state;

  useEffect(() => {
    setState({ ...state, isLoading: false, cartItemE: Object.assign({}, cartItem) });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const { service, servicePackage, userService, actualServiceVersion } = cartItemE;
  const { deliveryType, packageId, serviceOptionIds } = cartItemE

  const { name, shortDescription } = actualServiceVersion;

  const { total: unitPrice, days: deliveryDays } = getFinalPriceDays2({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds })

  cartItemE.deliveryDays = deliveryDays;
  cartItemE.unitPrice = unitPrice;
  cartItemE.total = unitPrice * cartItemE.quantity;

  const { total, quantity } = cartItemE;

  const onPress = () => { };

  const buttons = [];
  if (onSave) {
    buttons.push({
      label: "Save for Later",
      onPress: () => {
        onPress();
        onSave(cartItemE);
      }
    });
  }

  buttons.push({
    label: isEdit ? "Update" : "Add to Cart",

    onPress: () => {
      onPress();
      onAddToCart(cartItemE);
    }
  });

  //able to select the worker if not selected
  //if proposalItem then show the preferred workers list
  //able to add similar service (based on input requirements)

  return (
    <ModalBasic
      full
      large
      title={isEdit ? "Edit Service in Cart:" : "Add Service to Cart:"}
      onCancel={onCancel}
      buttons={buttons}
      headerRight={<FlexRow>
        <Quantity
          value={quantity}
          allowAdjust={true}
          onUpdate={value => {
            cartItemE.quantity = value;
            cartItemE.total = cartItemE.unitPrice * value;
            setState({ ...state, cartItemE });
          }}
        />

        <Price price={total} days={deliveryDays} deliveryText="Delivery in " />
        <View style={{ width: 15 }} />
      </FlexRow>}>

      <HeaderText subHeader label={name} description={shortDescription} />

      <RenderServiceDetails service={service} userService={userService} serviceVersion={actualServiceVersion} servicePackage={servicePackage} />

      <ServiceEdit
        allowSelect
        model={cartItemE}
        onUpdate={cartItemE => {
          setState({ ...state, cartItemE });
        }}
      />
    </ModalBasic>
  );
}

export function EditStaffInCart({ cartItem, isEdit, onCancel, onAddToCart, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, cartItemE } = state;

  const calculate = function (cartItemE) {
    const { total } = getStaffTotal({ model: cartItemE });
    cartItemE.unitPrice = total;
    cartItemE.total = total;
  };

  useEffect(() => {
    const cartItemE = Object.assign({}, cartItem)
    calculate(cartItemE);
    setState({ ...state, isLoading: false, cartItemE });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const { monthlyTotal, workerUsers, userRole, businessRole, staffServiceType } = cartItemE;

  const onPress = () => {
    if (!validateStaffEdit({ staffServiceData: cartItemE })) {
      return;
    }

    calculate(cartItemE);
  };

  const buttons = [];
  if (onSave) {
    buttons.push({
      label: "Save for Later",
      onPress: () => {
        onPress();
        onSave(cartItemE);
      }
    });
  }

  buttons.push({
    label: isEdit ? "Update" : "Add to Cart",

    onPress: () => {
      onPress();
      onAddToCart(cartItemE);
    }
  });

  return (
    <ModalBasic
      full
      large
      title={isEdit ? "Edit Staff in Cart:" : "Add Staff to Cart:"}
      onCancel={onCancel}
      buttons={buttons}
      headerRight={<View right={15} alignRight>
        <Price price={monthlyTotal} />
        <View style={{ marginTop: -10 }}>
          <small>per month</small>
        </View>
      </View>}>

      {workerUsers ? (
        <WorkerSelection2
          model={cartItemE}
          onChange={model => {
            calculate(cartItemE);
            setState({ ...state, cartItemE: model });
          }}
        />
      ) : userRole ? (
        <Section>
          <HeaderText subHeader label="Consultant Selected:" />
          <WorkerView2 userRole={userRole} />
        </Section>
      ) : (
        <Section>
          <HeaderText subHeader label="Consultant Selection:" />

          <FlexRow>
            <LabelItem label="Service Type:" value={getLabelItem(utils.staffServiceTypes, staffServiceType)} />

            {businessRole || userRole ? <LabelItem label="Business Role:" value={businessRole ? businessRole.name : userRole ? userRole.name : null} /> : null}
          </FlexRow>
        </Section>
      )}

      <StaffEdit
        staffServiceData={cartItemE}
        onUpdate={cartItemE => {
          calculate(cartItemE);
          setState({ ...state, cartItemE });
        }}
      />
    </ModalBasic>
  );
}

export function EditProposalStage({ proposal, proposalItem, onDone }) {
  const [state, setState] = useState({});
  const { requiredPreServices, icon, name, description, projectManager } = proposalItem;
  const model = proposalItem;

  return (
    <EditDialog>
      <HeaderText label="Edit Stage" description="Edit this stage." />

      <MyInput
        required
        label="Name:"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        maxLength={144}
        label="Short Description:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this stage:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />

      <FormItem label="Project Manager:">
        <SelectUser
          onChange={value => {
            model.projectManager = value;
            setState({ ...state, model });
          }}
          value={projectManager}
        />
      </FormItem>

      <RenderDependentItemEdit
        proposal={proposal}
        proposalItem={proposalItem}
        requiredPreServices={requiredPreServices}
        onChange={value => {
          model.requiredPreServices = value;
          setState({ ...state, model });
        }} />

      <LimitSection
        proposal={proposal}
        model={model}
        onUpdate={() => {
          setState({ ...state, model });
        }}
      />

      <TouchButton
        label="Save Item"
        onPress={() => {
          if (!name) {
            alert("Must enter name.");
            return;
          }

          if (!model.id) {
            model.id = utils.guid();
          }

          return onDone(model);
        }}
      />
    </EditDialog>
  );
}

export function EditProposalStageStep({ proposal, proposalItem, onDone }) {
  const [state, setState] = useState({});
  const { requiredPreServices, icon, name, strategyConsultant, description } = proposalItem;
  const model = proposalItem;

  return (
    <EditDialog>
      <HeaderText label="Edit Step" description="Edit this step." />

      <MyInput
        required
        label="Name:"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        maxLength={144}
        label="Short Description:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this step:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />

      <FormItem label="Strategy Consultant:">
        <SelectUser
          onChange={value => {
            model.strategyConsultant = value;
            setState({ ...state, model });
          }}
          value={strategyConsultant}
        />
      </FormItem>

      <RenderDependentItemEdit
        proposal={proposal}
        proposalItem={proposalItem}
        requiredPreServices={requiredPreServices}
        onChange={value => {
          model.requiredPreServices = value;
          setState({ ...state, model });
        }} />

      <LimitSection
        proposal={proposal}
        model={model}
        onUpdate={() => {
          setState({ ...state, model });
        }}
      />

      <TouchButton
        label="Save Step"
        onPress={() => {
          if (!name) {
            alert("Must enter name.");
            return;
          }

          if (!model.id) {
            model.id = utils.guid();
          }

          return onDone(model);
        }}
      />
    </EditDialog>
  );
}

export function EditProposalService({ rootProposalVersion, proposal, proposalItem, onDone }) {
  const [state, setState] = useState({ isLoading: true, hasOverridePrice: proposalItem.overridePrice != null ? true : false, assignedToUser: proposalItem.userService ? true : false, model: Object.assign({}, proposalItem) });
  const { isLoading, hasOverridePrice, assignedToUser, model } = state

  if (!model.delayOptions) { model.delayOptions = {}; }
  if (!model.inputData) { model.inputData = {}; }
  const { overridePrice, service, servicePackage, serviceVersion, userService, inputData, serviceOptionIds, deliveryType, delayOptions, assignedTo, description, quantity, requiredPreServices, subService, userServiceVersion, actualVersionType, actualServiceVersion } = model;

  useEffect(() => {
    const promises = []
    if (service) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: service.currentVersion.id }).then(function (serviceVersion) {
        service.currentVersion = serviceVersion
      })
    }
    if (userService) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
        userService.currentVersion = serviceVersion
      })
    }
    Promise.all(promises).then(function () {
      setState({ ...state, isLoading: false })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  const { delayType, delayDays, delayStart, delayStartDate } = delayOptions;
  const { enablePackages, inputs } = actualServiceVersion ? actualServiceVersion : {}
  const packageId = servicePackage ? servicePackage.package.id : null;

  const rootPath = subService ? `service[${subService.id}]` : null

  const qty = utils.isNumber(quantity) ? Number(quantity) : 1;

  const getPriceDays = function ({ serviceVersion }) {
    const { deliveryType, packageId, serviceOptionIds } = model

    return getFinalPriceDays2({ rootPriceMap: rootProposalVersion.priceMap, rootPath, serviceVersion, deliveryType, packageId, serviceOptionIds })
  }

  const { days, total } = service ? getPriceDays({ serviceVersion }) : {}

  const { days: uDays, total: uTotal } = userService ? getPriceDays({ serviceVersion: userServiceVersion }) : {}

  const calculate = function () {
    const { actualServiceVersion, deliveryType, packageId, serviceOptionIds } = model

    const { price, days } = getServicePriceAndDays2({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds })

    calcStartDayIndex(model)

    model.unitPrice = price
    model.deliveryDays = days
    model.total = price
  }

  var unitPrice = actualVersionType == "worker" ? uTotal : total
  const finalDays = actualVersionType == "worker" ? uDays : days
  if (service || servicePackage) {
    if (hasOverridePrice) {
      if (utils.isNumber(overridePrice)) {
        unitPrice = Number(overridePrice)
      }
    }
  }

  return (
    <EditDialog>
      <HeaderText label={model.id ? "Edit Service" : "Add Service"} description="Add/edit service into strategy map." rightRender={service && (!enablePackages || servicePackage) ? <Price price={unitPrice * qty} days={finalDays} deliveryText="Delivery in " /> : null} />

      <Section>
        <HeaderText subHeader label="Service Details:" description="Select a service to add to proposal." />

        <FormItem label="Service:" box>
          <SelectService onChange={({ service, userService }) => {
            reactLoading({
              onLoading:
                () => {
                  return dL.getService2({ serviceId: service.id }).then(function (service) {
                    var assignedToUser

                    const { currentVersion, serviceType } = service

                    return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                      model.service = service;
                      model.serviceVersion = serviceVersion

                      const servicePackage = getVisiblePackage({ serviceData: serviceVersion });

                      model.serviceType = serviceType;
                      model.servicePackage = servicePackage;
                      model.serviceOptionIds = {};
                      model.deliveryType = "standard";
                      model.inputData = {};

                      if (!model.description) {
                        model.description = service.shortDescription;
                      }

                      if (userService) {
                        const { currentVersion } = userService
                        return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                          assignedToUser = true
                          model.userService = userService;
                          model.userServiceVersion = serviceVersion
                          model.assignedTo = userService.user;

                          model.actualServiceVersion = serviceVersion
                          model.actualVersionType = "worker"

                          calculate()
                        })
                      } else {
                        assignedToUser = false
                        model.userService = null;
                        model.userServiceVersion = null
                        model.assignedTo = null;

                        model.actualServiceVersion = serviceVersion
                        model.actualVersionType = "service"

                        calculate()
                      }
                    }).then(function () {
                      setState({ ...state, model, assignedToUser });
                    })
                  })
                }
            })
          }}
            value={service}
            rightRender={service && (!enablePackages || servicePackage) ? <Price price={total} days={days} deliveryText="Delivery in " /> : null}
          >
            {service ? <RenderServiceNeedsUpdate
              configData={model}
              usingVersion={serviceVersion}
              currentVersion={service.currentVersion}
              price={total}
              days={days}
              onGetVersionPriceDays={(serviceVersion) => {
                return getPriceDays({ serviceVersion })
              }}
              onUpdateNewest={() => {
                model.serviceVersion = service.currentVersion

                if (model.actualVersionType = "service") {
                  model.actualServiceVersion = model.serviceVersion

                  calculate()
                }

                setState({ ...state, model });
                alert("Service updated.")
              }}
            /> : null}
          </SelectService>
        </FormItem>

        {service ? (
          <React.Fragment>
            <MyInput
              multiline
              maxLength={144}
              label="Short Description:"
              description="Add a short description for the proposal."
              value={description}
              onChange={value => {
                model.description = value;
                setState({ ...state, model });
              }}
            />

            <Toggle
              label="Override Price:"
              description="Set a new price for this service for this proposal only."
              value={hasOverridePrice}
              onChange={value => {
                if (!value) {
                  model.overridePrice = null
                }
                setState({ ...state, model, hasOverridePrice: value });
              }}
            />

            <FlexRow>
              <MyInput
                style={{ maxWidth: 175, marginRight: 25 }}
                inputType="integer"
                minValue={0}
                label="Quantity:"
                placeholder="Enter quantity"
                value={quantity ? String(quantity) : null}
                onChange={value => {
                  model.quantity = value;
                  setState({ ...state, model });
                }}
              />

              {hasOverridePrice ?
                <MyInput
                  label="New Price:"
                  placeholder="Price"
                  style={{ maxWidth: 150 }}
                  inputType="money"
                  minValue={0}
                  value={overridePrice ? String(overridePrice) : null}
                  onChange={value => {
                    model.overridePrice = value;
                    setState({ ...state, model });
                  }}
                />
                : null}
            </FlexRow>

            <Toggle
              label="Assign to a specific consultant:"
              value={assignedToUser}
              onChange={(value) => {
                if (!value) {
                  model.userService = null;
                  model.userServiceVersion = null
                  model.assignedTo = null;

                  model.actualServiceVersion = model.serviceVersion
                  model.actualVersionType = "service"

                  calculate()
                }
                setState({ ...state, model, assignedToUser: value });
              }}
            />

            {assignedToUser ? (
              <FormItem label="Assigned consultant:">
                <SelectWorker
                  hideMySelf
                  serviceId={service ? service.id : null}
                  onGetVersionPriceDays={(serviceVersion) => {
                    return getPriceDays({ serviceVersion })
                  }}
                  onChange={(value) => {
                    const { userService } = value ? value : {};

                    reactLoading({
                      onLoading:
                        () => {
                          if (userService) {
                            return dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
                              model.userService = userService;
                              model.userServiceVersion = serviceVersion
                              model.assignedTo = userService.user;

                              model.actualServiceVersion = serviceVersion
                              model.actualVersionType = "worker"

                              calculate()

                              setState({ ...state, model });
                            })
                          } else {
                            model.userService = null;
                            model.userServiceVersion = null
                            model.assignedTo = null;

                            model.actualServiceVersion = model.serviceVersion
                            model.actualVersionType = "service"

                            calculate()

                            setState({ ...state, model });
                          }
                        }
                    })
                  }}
                  value={userService ? userService.user : null}
                  rightRender={userService ? <Price price={uTotal} days={uDays} deliveryText="Delivery in " /> : null}
                />

                {userService ? <RenderServiceNeedsUpdate
                  configData={model}
                  usingVersion={userServiceVersion}
                  currentVersion={userService.currentVersion}
                  price={uTotal}
                  days={uDays}
                  onGetVersionPriceDays={(serviceVersion) => {
                    return getPriceDays({ serviceVersion })
                  }}
                  onUpdateNewest={() => {
                    model.userServiceVersion = userService.currentVersion

                    if (model.actualVersionType = "worker") {
                      model.actualServiceVersion = model.userServiceVersion

                      calculate()
                    }

                    setState({ ...state, model });
                    alert("Service version updated.")
                  }}
                /> : null}
              </FormItem>
            ) : null}

            <RenderDelayOptions
              delayOptions={delayOptions}
              onUpdate={() => {
                calculate()
                setState({ ...state, model });
              }}
            />
          </React.Fragment>
        ) : null}
      </Section>

      {service ? (
        <React.Fragment>
          <RenderDependentItemEdit
            proposal={proposal}
            proposalItem={model}
            requiredPreServices={requiredPreServices}
            onChange={value => {
              model.requiredPreServices = value;
              calculate()
              setState({ ...state, model });
            }} />

          <ServiceEdit
            allowSelect
            model={model}
            onUpdate={model => {
              calculate()
              setState({ ...state, model });
            }}
          />

          {inputs && inputs.length > 0 ? (
            <Section>
              <HeaderText label="Questions:" subHeader description="Set pre-answers to these questions to start this service. If not answer they will be required before the service can be started." />
              <AddInputsButton
                label="Enter Answers"
                inputs={inputs}
                inputData={inputData}
                onChange={value => {
                  model.inputData = value;
                  setState({ ...state, model });
                }}
              />
            </Section>
          ) : null}

          <LimitSection
            proposal={proposal}
            model={model}
            onUpdate={() => {
              setState({ ...state, model });
            }}
          />

        </React.Fragment>
      ) : null}

      <TouchButton
        label="Save Service"
        onPress={() => {
          if (!service) {
            alert("Must select service.");
            return;
          }
          if (enablePackages) {
            if (!servicePackage) {
              alert("Must select service package.");
              return;
            }
          }
          if (!utils.isNumber(quantity)) {
            alert("Must enter valid quantity.");
            return;
          }

          if (hasOverridePrice) {
            if (!utils.isNumber(overridePrice)) {
              alert("Must enter valid price.");
              return;
            }
          }

          if (delayStart) {
            if (!delayType) {
              alert("Must select delay type.");
              return;
            }
            if (delayType == "start-date") {
              if (!delayStartDate) {
                alert("Must enter start date.");
                return;
              }
            } else if (delayType == "start-of-order" || delayType == "days-from-pre-req") {
              if (!utils.isInteger(delayDays)) {
                alert("Must enter valid delay days.");
                return;
              }
            }
          }

          if (assignedToUser) {
            if (!assignedTo) {
              alert("Must select recommended consultant.");
              return;
            }
          }

          if (!deliveryType) {
            alert("Must select a delivery type.");
            return;
          }

          if (!model.id) {
            model.id = utils.guid();
            model.itemType = "service";
          }
          model.quantity = Number(quantity);
          model.deliveryType = deliveryType;
          model.unitPrice = Number(unitPrice);
          model.total = model.unitPrice * model.quantity;
          model.deliveryDays = Number(days);

          if (hasOverridePrice) {
            model.overridePrice = Number(overridePrice);
            model.originalPrice = Number(unitPrice);
          } else {
            delete model.overridePrice
            delete model.originalPrice
          }

          if (delayDays) {
            delayOptions.delayDays = Number(delayDays);
          }

          calcStartDayIndex(model)

          model.priceMap = createProposalItemPriceMap({ proposalVersion: rootProposalVersion, proposalItem: model, serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds })

          return onDone(model);
        }}
      />
    </EditDialog>
  );
}

export function EditProposalServiceBuyer({ proposal, proposalItem, onDone }) {
  const [state, setState] = useState({ isLoading: true, assignedToUser: proposalItem.userService ? true : false, model: Object.assign({}, proposalItem) });
  const { isLoading, assignedToUser, model } = state

  if (!model.delayOptions) { model.delayOptions = {}; }
  if (!model.inputData) { model.inputData = {}; }
  const { service, servicePackage, serviceVersion, userService, inputData, serviceOptionIds, deliveryType, delayOptions, assignedTo, description, quantity, requiredPreServices, subService, userServiceVersion, actualVersionType, actualServiceVersion } = model;

  useEffect(() => {
    const promises = []
    if (service) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: service.currentVersion.id }).then(function (serviceVersion) {
        service.currentVersion = serviceVersion
      })
    }
    if (userService) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
        userService.currentVersion = serviceVersion
      })
    }
    Promise.all(promises).then(function () {
      setState({ ...state, isLoading: false })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  const { delayType, delayDays, delayStart, delayStartDate } = delayOptions;
  const { enablePackages, inputs } = actualServiceVersion ? actualServiceVersion : {}
  const packageId = servicePackage ? servicePackage.package.id : null;

  const rootPath = subService ? `service[${subService.id}]` : null

  const qty = utils.isNumber(quantity) ? Number(quantity) : 1;

  /*
  const getPriceDays = function ({ serviceVersion }) {
    const { deliveryType, packageId, serviceOptionIds } = model

    return getFinalPriceDays2({ rootPriceMap: rootProposalVersion.priceMap, rootPath, serviceVersion, deliveryType, packageId, serviceOptionIds })
  }
*/

  const getPriceDays = function ({ serviceVersion }) {
    const rtn = getMainServiceModel({ configData: { ...model, actualServiceVersion: serviceVersion } })
    const days = rtn.getTotalDeliveryDays()
    const total = rtn.getTotalPrice()
    return { total, days }
  }

  const { days, total } = service ? getPriceDays({ serviceVersion }) : {}

  const { days: uDays, total: uTotal } = userService ? getPriceDays({ serviceVersion: userServiceVersion }) : {}

  const calculate = function () {
    calcStartDayIndex(model)

    const rtn = getMainServiceModel({ configData: model })
    const days = rtn.getTotalDeliveryDays()
    const price = rtn.getTotalPrice()

    model.unitPrice = price
    model.deliveryDays = days
    model.total = price
  }

  var unitPrice = actualVersionType == "worker" ? uTotal : total
  const finalDays = actualVersionType == "worker" ? uDays : days

  return (
    <EditDialog>
      <HeaderText label={model.id ? "Edit Service" : "Add Service"} description="Add/edit service into strategy map." rightRender={service && (!enablePackages || servicePackage) ? <Price price={unitPrice * qty} days={finalDays} deliveryText="Delivery in " /> : null} />

      <Section>
        <HeaderText subHeader label="Service Details:" description="Select a service to add to proposal." />

        <FormItem label="Service:" box>
          <SelectService onChange={({ service, userService }) => {
            reactLoading({
              onLoading:
                () => {
                  return dL.getService2({ serviceId: service.id }).then(function (service) {
                    var assignedToUser

                    const { currentVersion, serviceType } = service

                    return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                      model.service = service;
                      model.serviceVersion = serviceVersion

                      const servicePackage = getVisiblePackage({ serviceData: serviceVersion });

                      model.serviceType = serviceType;
                      model.servicePackage = servicePackage;
                      model.serviceOptionIds = {};
                      model.deliveryType = "standard";
                      model.inputData = {};

                      if (!model.description) {
                        model.description = service.shortDescription;
                      }

                      if (userService) {
                        const { currentVersion } = userService
                        return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                          assignedToUser = true
                          model.userService = userService;
                          model.userServiceVersion = serviceVersion
                          model.assignedTo = userService.user;

                          model.actualServiceVersion = serviceVersion
                          model.actualVersionType = "worker"

                          calculate()
                        })
                      } else {
                        assignedToUser = false
                        model.userService = null;
                        model.userServiceVersion = null
                        model.assignedTo = null;

                        model.actualServiceVersion = serviceVersion
                        model.actualVersionType = "service"

                        calculate()
                      }
                    }).then(function () {
                      setState({ ...state, model, assignedToUser });
                    })
                  })
                }
            })
          }}
            value={service}
            rightRender={service && (!enablePackages || servicePackage) ? <Price price={total} days={days} deliveryText="Delivery in " /> : null}
          >
            {service ? <RenderServiceNeedsUpdate
              configData={model}
              usingVersion={serviceVersion}
              currentVersion={service.currentVersion}
              price={total}
              days={days}
              onGetVersionPriceDays={(serviceVersion) => {
                return getPriceDays({ serviceVersion })
              }}
              onUpdateNewest={() => {
                model.serviceVersion = service.currentVersion

                if (model.actualVersionType = "service") {
                  model.actualServiceVersion = model.serviceVersion

                  calculate()
                }

                setState({ ...state, model });
                alert("Service updated.")
              }}
            /> : null}
          </SelectService>
        </FormItem>

        {service ? (
          <React.Fragment>
            <FlexRow>
              <MyInput
                style={{ maxWidth: 175, marginRight: 25 }}
                inputType="integer"
                minValue={0}
                label="Quantity:"
                placeholder="Enter quantity"
                value={quantity ? String(quantity) : null}
                onChange={value => {
                  model.quantity = value;
                  setState({ ...state, model });
                }}
              />
            </FlexRow>

            <Toggle
              label="Select a specific consultant:"
              value={assignedToUser}
              onChange={(value) => {
                if (!value) {
                  model.userService = null;
                  model.userServiceVersion = null
                  model.assignedTo = null;

                  model.actualServiceVersion = model.serviceVersion
                  model.actualVersionType = "service"

                  calculate()
                }
                setState({ ...state, model, assignedToUser: value });
              }}
            />

            {assignedToUser ? (
              <FormItem label="Selected consultant:">
                <SelectWorker
                  hideMySelf
                  serviceId={service ? service.id : null}
                  onGetVersionPriceDays={(serviceVersion) => {
                    return getPriceDays({ serviceVersion })
                  }}
                  onChange={(value) => {
                    const { userService } = value ? value : {};

                    reactLoading({
                      onLoading:
                        () => {
                          if (userService) {
                            return dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
                              model.userService = userService;
                              model.userServiceVersion = serviceVersion
                              model.assignedTo = userService.user;

                              model.actualServiceVersion = serviceVersion
                              model.actualVersionType = "worker"

                              calculate()

                              setState({ ...state, model });
                            })
                          } else {
                            model.userService = null;
                            model.userServiceVersion = null
                            model.assignedTo = null;

                            model.actualServiceVersion = model.serviceVersion
                            model.actualVersionType = "service"

                            calculate()

                            setState({ ...state, model });
                          }
                        }
                    })
                  }}
                  value={userService ? userService.user : null}
                  rightRender={userService ? <Price price={uTotal} days={uDays} deliveryText="Delivery in " /> : null}
                />

                {userService ? <RenderServiceNeedsUpdate
                  configData={model}
                  usingVersion={userServiceVersion}
                  currentVersion={userService.currentVersion}
                  price={uTotal}
                  days={uDays}
                  onGetVersionPriceDays={(serviceVersion) => {
                    return getPriceDays({ serviceVersion })
                  }}
                  onUpdateNewest={() => {
                    model.userServiceVersion = userService.currentVersion

                    if (model.actualVersionType = "worker") {
                      model.actualServiceVersion = model.userServiceVersion

                      calculate()
                    }

                    setState({ ...state, model });
                    alert("Service version updated.")
                  }}
                /> : null}
              </FormItem>
            ) : null}

            <RenderDelayOptions
              delayOptions={delayOptions}
              onUpdate={() => {
                calculate()
                setState({ ...state, model });
              }}
            />
          </React.Fragment>
        ) : null}
      </Section>

      {service ? (
        <React.Fragment>
          <RenderDependentItemEdit2
            proposal={proposal}
            proposalItem={model}
            requiredPreServices={requiredPreServices}
            onChange={value => {
              model.requiredPreServices = value;
              calculate()
              setState({ ...state, model });
            }} />

          <ServiceEdit
            allowSelect
            model={model}
            onUpdate={model => {
              calculate()
              setState({ ...state, model });
            }}
          />
        </React.Fragment>
      ) : null}

      <TouchButton
        label="Save Service"
        onPress={() => {
          if (!service) {
            alert("Must select service.");
            return;
          }
          if (enablePackages) {
            if (!servicePackage) {
              alert("Must select service package.");
              return;
            }
          }
          if (!utils.isNumber(quantity)) {
            alert("Must enter valid quantity.");
            return;
          }

          if (delayStart) {
            if (!delayType) {
              alert("Must select delay type.");
              return;
            }
            if (delayType == "start-date") {
              if (!delayStartDate) {
                alert("Must enter start date.");
                return;
              }
            } else if (delayType == "start-of-order" || delayType == "days-from-pre-req") {
              if (!utils.isInteger(delayDays)) {
                alert("Must enter valid delay days.");
                return;
              }
            }
          }

          if (assignedToUser) {
            if (!assignedTo) {
              alert("Must select recommended consultant.");
              return;
            }
          }

          if (!deliveryType) {
            alert("Must select a delivery type.");
            return;
          }

          if (!model.id) {
            model.id = utils.guid();
            model.itemType = "service";
          }
          model.quantity = Number(quantity);
          model.deliveryType = deliveryType;
          model.unitPrice = Number(unitPrice);
          model.total = model.unitPrice * model.quantity;
          model.deliveryDays = Number(days);

          if (delayDays) {
            delayOptions.delayDays = Number(delayDays);
          }

          calcStartDayIndex(model)

          model.priceMap = createServicePriceMap({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds })

          return onDone(model);
        }}
      />
    </EditDialog>
  );
}

function RenderPITem({ item }) {
  const { itemType, service, businessRole } = item;
  if (itemType == "service") {
    return <TextLine value={service.name} />
  } else if (itemType == "staff") {
    return <TextLine value={businessRole.name} />
  }
}

function RenderDependentItemEdit2({ proposal, proposalItem, requiredPreServices, onChange }) {
  const refC1 = useRef();

  const { proposalItems } = proposal

  return <Section>
    <HeaderText subHeader label="Dependent on other items in the cart:" />

    <View style={{ marginBottom: 15 }}>
      {requiredPreServices
        ? requiredPreServices.map((pId, index) => {
          const item = proposalItems.find(item => item.id == pId);
          if (item) {
            return (
              <FlexRow style={{ marginBottom: 10 }}>
                <View>
                  <RenderPITem item={item} />
                </View>

                <TrashIcon
                  style={{ marginLeft: 15 }}
                  onPress={() => {
                    requiredPreServices.splice(index, 1);
                    onChange(requiredPreServices)
                  }}
                />
              </FlexRow>
            );
          }
        })
        : null}
    </View>

    <Typeahead
      options={proposalItems.filter(item => {
        if (requiredPreServices && requiredPreServices.find(pId => item.id == pId)) {
          return false;
        }

        if (item.id == proposalItem.id) {
          return false;
        }

        const isParent = function (item) {
          if (item.parentProposalItem) {
            if (item.parentProposalItem.id == proposalItem.id) {
              return true
            } else {
              return isParent(item.parentProposalItem)
            }
          }
        }

        if (isParent(item)) {
          return false
        }

        const m1 = function (item) {
          const { requiredPreServices } = item;

          if (requiredPreServices) {
            if (requiredPreServices.find(pId => pId == proposalItem.id)) {
              return true;
            }

            for (var i = 0; i < item.requiredPreServices.length; i++) {
              const item3 = item.requiredPreServices[i];
              if (m1(item3)) {
                return true;
              }
            }
          }
        };

        if (m1(item)) {
          return false;
        }
        return true;
      })}
      ref={ref => {
        refC1.current = ref;
      }}
      onChange={item => {
        var arr = requiredPreServices
        if (!requiredPreServices) {
          arr = [];
        }
        arr.push(item[0].id);

        refC1.current.clear();
        onChange(arr)
      }}
      labelKey="id"
      placeholder="Add pre-required items..."
      renderMenuItemChildren={(item) => {
        return (
          <View>
            <TextLine value={item.service.name} style={{ margin: item.stageId ? 10 : 0 }} />

            {item.requiredPreServices ? <TextLine grey size={12} label="Pre-Required:" value={utils.renderArray(item.requiredPreServices.map(id => {
              const foundItem = proposalItems.find(item => item.id == id)
              const { itemType, service, businessRole } = foundItem ? foundItem : {}
              return itemType == "service" ? service.name : businessRole.name
            }))} /> : ""}
          </View>
        );
      }}
    />
  </Section>
}

function RenderDependentItemEdit({ proposal, proposalItem, requiredPreServices, onChange }) {
  const refC1 = useRef();

  const allItems = proposal.allItems;
  const allServiceItems = dL.getAllServiceProposalItems({ proposal });

  return <Section>
    <HeaderText subHeader label="Dependent on other items in the proposal:" />

    <View style={{ marginBottom: 15 }}>
      {requiredPreServices
        ? requiredPreServices.map((pId, index) => {
          const item = allItems.get(pId);
          if (item) {
            const { stageId, service } = item;
            return (
              <FlexRow style={{ marginBottom: 10 }}>
                <View>
                  {stageId ? <TextLine value={allItems.get(stageId).name + ": " + allItems.get(item.stageItemId).name} /> : null}
                  <TextLine value={service.name} style={{ margin: stageId ? 10 : 0 }} />
                </View>

                <TrashIcon
                  style={{ marginLeft: 15 }}
                  onPress={() => {
                    requiredPreServices.splice(index, 1);
                    onChange(requiredPreServices)
                  }}
                />
              </FlexRow>
            );
          }
        })
        : null}
    </View>

    <Typeahead
      options={allItems.filter(item => {
        if (requiredPreServices && requiredPreServices.find(pId => item.id == pId)) {
          return false;
        }

        if (item.id == proposalItem.id) {
          return false;
        }

        const isParent = function (item) {
          if (item.parentProposalItem) {
            if (item.parentProposalItem.id == proposalItem.id) {
              return true
            } else {
              return isParent(item.parentProposalItem)
            }
          }
        }

        if (isParent(item)) {
          return false
        }

        const m1 = function (item) {
          const { requiredPreServices } = item;

          if (requiredPreServices) {
            if (requiredPreServices.find(pId => pId == proposalItem.id)) {
              return true;
            }

            for (var i = 0; i < item.requiredPreServices.length; i++) {
              const item3 = item.requiredPreServices[i];
              if (m1(item3)) {
                return true;
              }
            }
          }
        };

        if (m1(item)) {
          return false;
        }
        return true;
      })}
      ref={ref => {
        refC1.current = ref;
      }}
      onChange={item => {
        var arr = requiredPreServices
        if (!requiredPreServices) {
          arr = [];
        }
        arr.push(item[0].id);

        refC1.current.clear();
        onChange(arr)
      }}
      labelKey="id"
      placeholder="Add pre-required items..."
      renderMenuItemChildren={(item) => {
        return (
          <View>
            {item.stageId ? <TextLine value={allItems.get(item.stageId).name + ": " + allItems.get(item.stageItemId).name} /> : null}
            <TextLine value={item.service.name} style={{ margin: item.stageId ? 10 : 0 }} />

            {item.requiredPreServices ? <TextLine grey size={12} label="Pre-Required:" value={utils.renderArray(item.requiredPreServices.map(id => {
              const { itemType, service, businessRole } = allItems.get(id)
              return itemType == "service" ? service.name : businessRole.name
            }))} /> : ""}
          </View>
        );
      }}
    />
  </Section>
}

export function EditProposalStaff({ proposal, proposalItem, onDone }) {
  if (!proposalItem.id) {
    proposalItem.id = utils.guid();
    proposalItem.itemType = "staff";
    proposalItem.staffServiceType = "staff";
    proposalItem.workerSelectionType = "role";
    proposalItem.priorityLevel = priorityLevels[1].value;
  }

  const [state, setState] = useState({ model: proposalItem });
  const { model } = state;

  const { staffServiceType, requiredPreServices } = model;
  const { total } = getStaffTotal({ model });

  return (
    <EditDialog>
      <HeaderText
        label="Add Staff Service"
        description="Add staff service to proposal."
        rightRender={<TabBar
          options={utils.staffServiceTypes}
          onChange={item => {
            model.staffServiceType = item.value;
            setState({ ...state, model });
          }}
          value={staffServiceType}
        />}
      />

      <WorkerSelectionProposal
        model={model}
        onChange={model => {
          setState({ ...state, model });
        }}
      />

      <StaffEdit
        staffServiceData={model}
        onUpdate={model => {
          setState({ ...state, model });
        }}
      />

      <RenderDependentItemEdit
        proposal={proposal}
        proposalItem={proposalItem}
        requiredPreServices={requiredPreServices}
        onChange={value => {
          model.requiredPreServices = value;
          setState({ ...state, model });
        }} />

      <LimitSection
        proposal={proposal}
        model={model}
        onUpdate={() => {
          setState({ ...state, model });
        }}
      />

      <TouchButton
        label="Save Service"
        onPress={() => {
          if (!validateStaffEdit({ staffServiceData: model })) {
            return;
          }

          model.quantity = 1;
          model.unitPrice = model.total;
          return onDone(model);
        }}
      />
    </EditDialog>
  );
}

export function EditProposalPackage({ model, onDone, onCancel }) {
  const [state, setState] = useState({});
  const { icon, name, description } = model;

  return (
    <View style={{ maxWidth: 550, marginHorizontal: 23 }}>
      <HeaderText
        label="Edit Strategy Package"
        description="Add strategy package."
        rightRender={<BackButton
          onPress={() => {
            onCancel();
          }}
        />}
      />
      <MyInput
        required
        label="Name:"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />


      <MyInput
        multiline
        label="More details:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this package:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />
      <TouchButton
        label="Save Package"
        onPress={() => {
          if (!name) {
            alert("Must enter title.");
            return;
          }
          if (!model.id) {
            model.id = utils.guid();
          }

          return onDone(model);
        }}
      />
    </View>
  );
}

export function EditProposalOption({ model, packages, onDone, onCancel }) {
  const [state, setState] = useState({});
  const { icon, name, description, limitedToPackages } = model;

  return (
    <View style={{ maxWidth: 550, marginHorizontal: 23 }}>
      <HeaderText
        label="Edit Strategy Option"
        description="Add strategy option."
        rightRender={<BackButton
          onPress={() => {
            onCancel();
          }}
        />}
      />
      <MyInput
        required
        label="Title:"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        label="More details:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this option:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />


      <LimitProposalPackageBox
        value={limitedToPackages}
        packages={packages}
        onChange={limitedToPackages => {
          model.limitedToPackages = limitedToPackages;
          setState({ ...state, model });
        }}
      />

      <TouchButton
        label="Save Option"
        onPress={() => {
          if (!name) {
            alert("Must enter title.");
            return;
          }
          if (!model.id) {
            model.id = utils.guid();
          }

          return onDone(model);
        }}
      />
    </View>
  );
}

export function SaveProposalTemplate({ proposal, onCancel, onSave }) {
  const { isTemplate, templateName, templateId } = proposal;
  const [state, setState] = useState({ name: templateName });
  const { name } = state;

  const buttons = [];

  if (!isTemplate && !templateId) {
    buttons.push({
      label: "Save As Template",
      onPress: () => {
        const template = Object.assign({}, proposal);
        template.id = null;
        template.isTemplate = true;
        template.templateName = name;
        template.serviceRequest = null;
        return dL.saveServiceProposal(template.id, template).then(function () {
          alert("Saved.");
          onSave();
        });
      }
    });
  } else {
    buttons.push({
      label: "Save As New",
      onPress: () => {
        const template = Object.assign({}, proposal);
        template.id = null;
        template.isTemplate = true;
        template.templateName = name;
        return dL.saveServiceProposal(template.id, template).then(function () {
          alert("Saved.");
          onSave();
        });
      }
    });
    buttons.push({
      label: "Override Existing",
      onPress: () => {
        const template = Object.assign({}, proposal);
        template.id = proposal.templateId;
        template.isTemplate = true;
        template.serviceRequest = null;
        return dL.saveServiceProposal(template.id, template).then(function () {
          alert("Saved.");
          onSave();
        });
      }
    });
  }

  return (
    <ModalBasic title="Strategy Template" onCancel={onCancel} buttons={buttons}>
      <MyInput
        label="Template Name:"
        placeholder="Name"
        boxStyle={{ marginBottom: 0 }}
        value={name}
        onChange={value => {
          setState({ ...state, name: value });
        }}
      />
    </ModalBasic>
  );
}

export function SelectProposalTemplate({ onCancel, onSelect }) {
  const [templates, setTemplates] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [templateId, setTemplateId] = useState();

  useEffect(() => {
    dL.getQuery("ServiceProposal")
      .equalTo("isTemplate", true)
      .equalTo("createdBy", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .include("service")
      .find()
      .then(function (objs) {
        const templates = dL.loadObjects("ServiceProposal", objs);
        setTemplates(
          templates.map(item => {
            return { label: item.templateName, value: item.id, template: item };
          })
        );
        setIsLoading(false);
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalBasic
      title="Select Template:"
      onCancel={onCancel}
      okText="Use Template"
      onOk={() => {
        if (!templateId) {
          alert("Must select template.");
          return;
        }
        const item = templates.find(item => item.value == templateId);
        onSelect(item.template);
      }}>
      <SelectBox
        label="Templates:"
        value={templateId ? templates.find(item => item.value == templateId) : null}
        options={templates}
        onChange={item => {
          setTemplateId(item.value);
        }}
      />
      {templateId ? (
        <LinkButton
          onPress={() => {
            dL.getObj("ServiceProposal", templateId)
              .set("removed", true)
              .save();

            const index = templates.findIndex(item => item.value == templateId);
            templates.splice(index, 1);
            setTemplateId();
            setTemplates(templates);
          }}
          label="Delete Template From List"
        />
      ) : null}
    </ModalBasic>
  );
}

export function EditProposal() {
  const { proposalId, serviceRequestId, sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    model: {
      fluxDelivery: "normal",
      fluxScope: "average",
      items: [], status: "draft"
    }, isLoading: proposalId ? true : false
  });
  const { isLoading, templateVisible, model } = state;

  useEffect(() => {
    var serviceRequest;
    var promise = new PromiseA()
    if (serviceRequestId) {
      promise = db
        .getQuery("ServiceRequest")
        .get(serviceRequestId)
        .then(function (obj) {
          serviceRequest = dL.loadServiceRequest(obj);
        });
    } else {
      promise.resolve();
    }

    promise.then(function () {
      if (proposalId) {
        dL.getQuery("ServiceProposal")
          .include("user")
          .get(proposalId)
          .then(function (obj) {
            const proposal = dL.loadServiceProposal(obj)
            proposal.serviceRequest = serviceRequest
            setState({ ...state, isLoading: false, model: proposal });
          });
      } else {
        const isBD = dL.isBD();
        const isSC = dL.isSC();
        var userRole = "pm";
        if (isBD) {
          userRole = "bd";
        } else if (isSC) {
          userRole = "sc";
        }
        model.userRole = getValueItem(userRoles, userRole).value;
        setState({ ...state, model });
      }
    })
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { userRole, title, objectives, additionalNotes, description, user } = model;

  return (
    <EditDialog>
      <HeaderText
        label="Edit Strategy Map"
        description="Edit this strategy map."
        buttons={[
          {
            label: "+ Templates",
            onPress: () => {
              setState({ ...state, templateVisible: true });
            }
          }
        ]}
      />

      {templateVisible ? (
        <SelectProposalTemplate
          onSelect={item => {
            const proposal = Object.assign({}, item);

            proposal.status = "draft";
            delete proposal.isTemplate;
            proposal.templateId = item.id;

            setState({
              ...state,
              model: proposal,
              templateVisible: false
            });
          }}
          onCancel={() => {
            setState({ ...state, templateVisible: false });
          }}
        />
      ) : null}
      {!serviceRequestId ? (
        <FormItem label="Client:">
          <SelectUser
            onChange={value => {
              model.user = value;
              setState({ ...state, model });
            }}
            value={user}
          />
        </FormItem>
      ) : null}
      <MyInput
        required
        label="Title:"
        value={title}
        onChange={value => {
          model.title = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        multiline
        maxLength={255}
        label="Define the business objective:"
        value={objectives}
        onChange={value => {
          model.objectives = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        multiline
        label="Description:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        multiline
        label="Additional Notes/Terms and Conditions:"
        value={additionalNotes}
        onChange={value => {
          model.additionalNotes = value;
          setState({ ...state, model });
        }}
      />

      {!proposalId ? (
        <SelectBox
          required
          label="Your Role:"
          value={getValueItem(userRoles, userRole)}
          options={userRoles}
          onChange={item => {
            model.userRole = item.value;
            setState({ ...state, model });
          }}
        />
      ) : null}

      <TouchButton
        label={!proposalId ? "Create Strategy Map" : "Update"}
        onPress={() => {
          if (!title) {
            alert("Must enter title.");
            return;
          }
          if (!proposalId) {
            if (!userRole) {
              alert("Must select your user role.");
              return;
            }

            model.businessDevelopment = userRole == "bd" ? session.user : null;
            model.strategyConsultant = userRole == "sc" ? session.user : null;
            model.projectManager = userRole == "pm" ? session.user : null;
          }
          model.user = user ? user : null;

          return dL
            .saveServiceProposal(proposalId, model)
            .then(function (proposalId) {
              history.replace(`/${sectionId}/proposal/${proposalId}`);
            })
            .catch(function (err) {
              alert("Error in strategy map:" + err);
            });
        }}
      />
    </EditDialog>
  );
}

export function ViewProposalStaffServiceItem({ onClose, proposalItem }) {
  const { recommended } = proposalItem;

  const { staffServiceType, businessRole, userRole, workerUsers } = proposalItem;
  const { total } = getStaffTotal({ model: proposalItem });

  return (
    <ModalBasic full large title="Strategy Staff:" okText="OK" onOk={onClose}>
      {recommended ? <TextLine size={12} color="red" value="Recommended Service" /> : null}
      {workerUsers ? (
        <WorkerSelection2 model={proposalItem} />
      ) : userRole ? (
        <LabelItem label="Consultant:" style={{ marginRight: 0 }}>
          <WorkerView2 userRole={userRole} />
        </LabelItem>
      ) : (
        <Section>
          <HeaderText subHeader label="Consultant Selection:" />

          <FlexRow>
            <LabelItem label="Service Type:" value={getLabelItem(utils.staffServiceTypes, staffServiceType)} />

            {businessRole || userRole ? <LabelItem label="Business Role:" value={businessRole ? businessRole.name : userRole ? userRole.name : null} /> : null}
          </FlexRow>
        </Section>
      )}

      <StaffView staffServiceData={proposalItem} />
    </ModalBasic>
  );
  return (
    <ModalBasic full large title="Proposal Staff:" okText="OK" onOk={onClose}>
      {recommended ? <TextLine size={12} color="red" value="Recommended Service" /> : null}

      <TextLine>(Business Role)</TextLine>
      <TextLine>Skill Level: Expert</TextLine>

      <TextLine>Contract Details:</TextLine>
      <TextLine>Total:</TextLine>
    </ModalBasic>
  );
}

export function ViewProposalServiceItem({ onClose, proposalItem }) {
  const { recommended, servicePackage, serviceOptionIds, serviceVersion } = proposalItem;

  return (
    <ModalBasic full large title="Strategy Service:" okText="OK" onOk={onClose}>
      {recommended ? <TextLine size={12} color="red" value="Recommended Service" /> : null}

      <RenderSelectedService serviceItem={proposalItem} recommendedPackageId={recommended ? (servicePackage ? servicePackage.id : null) : null} recommendedServiceOptionIds={serviceOptionIds}>

        <LabelItem label="Service Version:" value={"#" + serviceVersion.versionNum} />

      </RenderSelectedService>

    </ModalBasic>
  );
}

export function EditProposalSimple() {
  const { proposalId, serviceRequestId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ proposal: { items: [], status: "draft" }, serviceItem: { quantity: 1 }, isLoading: true });
  const { serviceRequest, isLoading, proposal, serviceItem, service, servicePackage, userService, templateVisible } = state;
  const [visible, setVisible] = useState();

  const model = {}
  const calculate = function () { }

  useEffect(() => {
    if (proposalId) {
      dL.getServiceProposal({ proposalId }).then(function ({ proposal }) {
        const proposalItem = proposal.allItems.values()[0]

        const { service, userService, servicePackage } = proposalItem

        setState({
          ...state,
          isLoading: false,
          packages: service.packages,
          service,
          userService,
          servicePackage,
          proposal,
          serviceRequest: proposal.serviceRequest,
          serviceItem: proposalItem
        });
      });
    } else {
      return db
        .getQuery("ServiceRequest")
        .include("user")
        .include("strategyConsultant")
        .include("businessDevelopment")
        .include("createdBy")
        .get(serviceRequestId)
        .then(function (obj) {
          const serviceRequest = dL.loadServiceRequest(obj);

          setState({ ...state, serviceRequest, isLoading: false });
        });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { assignedToUser, servicePackageId, inputData, serviceOptionIds, deliveryType, assignedTo, description, quantity } = serviceItem;

  const serviceVersion = service ? service.currentVersion : {}
  const { enablePackages, inputs } = serviceVersion ? serviceVersion : {}

  const packageId = servicePackageId;

  var days;
  var total;
  if (service || servicePackage) {
    const { total: t1, days: d1 } = getFinalPriceDays2({ serviceVersion, deliveryType, packageId, serviceOptionIds })
    total = t1
    days = d1
    total = total * (quantity ? Number(quantity) : 1);
  }

  const validateSetItem = function ({ validateAll }) {
    if (!service) {
      alert("Must select service.");
      return;
    }

    if (validateAll) {
      if (enablePackages) {
        if (!servicePackageId) {
          alert("Must select service package.");
          return;
        }
      }
      if (!utils.isNumber(quantity)) {
        alert("Must enter valid quantity.");
        return;
      }

      if (assignedToUser) {
        if (!assignedTo) {
          alert("Must select consultant.");
          return;
        }
      }
    }

    serviceItem.quantity = Number(quantity);
    serviceItem.deliveryType = deliveryType;
    serviceItem.total = Number(total);
    serviceItem.deliveryDays = Number(days);
    serviceItem.serviceOptionIds = serviceOptionIds;

    if (!proposalId && !serviceItem.id) {
      serviceItem.id = utils.guid();
      serviceItem.itemType = "service";
      //proposal.items.push(serviceItem);
      //proposal.currentProposalVersion.items.push(serviceItem)
      proposal.title = service.name;
      proposal.user = serviceRequest.user
      proposal.serviceRequest = serviceRequest
      proposal.proposalType = "simple";

      proposal.projectManager = serviceRequest.user
    }

    proposal.deliveryDays = days;
    proposal.total = total;

    return true;
  };

  const getQuestionCount = function ({ inputs }) {
    var questionCount = 0;
    inputs.forEach(item => {
      const { formItems } = item;
      questionCount += formItems ? formItems.length : 0;
    });
    return questionCount;
  };

  const saveItem = function ({ validateAll }) {
    if (validateSetItem({ validateAll })) {
      proposal.projectManager = session.user;
      return dL.saveServiceProposal(proposal.id, proposal, [serviceItem])
        .then(function (proposalId) {
          //get the service proposal
          //calculate the total and delivery days
          //save the proposal version with update information
          proposal.id = proposalId;
        })
        .catch(function (err) {
          alert("Error: " + err);
        });
    } else {
      const promise = new PromiseA()
      promise.reject()
      return promise
    }
  };

  const onChangeWorker = value => {
    const { user, userService } = value ? value : {};

    if (value) {
      serviceItem.assignedTo = user;
      serviceItem.userService = userService;
    } else {
      serviceItem.assignedTo = null;
      serviceItem.userService = null;
    }

    setState({ ...state, serviceItem, userService });
  };

  return (
    <EditDialog>
      <HeaderText
        label="Send Proposal"
        description="Send a service proposal to the buyer."

        buttons={[
          {
            label: "+ Templates",
            onPress: () => {
              setState({ ...state, templateVisible: true });
            }
          }
        ]}
      />

      {templateVisible ? (
        <SelectProposalTemplate
          onSelect={item => {
            const proposal = Object.assign({}, item);

            proposal.status = "draft";
            delete proposal.isTemplate;
            proposal.templateId = item.id;

            var { serviceId, servicePackageId, userServiceId } = proposal.items[0];

            var service;
            var servicePackage;
            var userService;
            var promises = [];
            promises[promises.length] = dL.getService2(serviceId).then(function (_service) {
              service = _service;
            });
            if (servicePackageId) {
              promises[promises.length] = dL.getServicePackage(servicePackageId).then(function (_servicePackage) {
                servicePackage = _servicePackage;
              });
            }
            if (userServiceId) {
              promises[promises.length] = dL.getUserService2({ userServiceId }).then(function (_userService) {
                userService = _userService;
              });
            }

            return Promise.all(promises).then(function (service) {
              setState({
                ...state,
                packages: service.packages,
                service,
                userService,
                servicePackage,
                proposal,
                serviceItem: proposal.items[0],
                templateVisible: false
              });
            });
          }}
          onCancel={() => {
            setState({ ...state, templateVisible: false });
          }}
        />
      ) : null}

      <Section>
        <HeaderText subHeader label="Service Selection:" description="Select a service to add to proposal." rightRender={
          service ? (
            <View alignRight>
              <TextLine size={22}>Total: {money(total)}</TextLine>

              <TextLine size={14} grey>
                {plural(days, "day")}
              </TextLine>
            </View>
          ) : null
        } />

        <FormItem label="Select the service:" box>
          <SelectService onChange={({ service, userService }) => {
            reactLoading({
              onLoading:
                () => {
                  return dL.getService2({ serviceId: service.id }).then(function (service) {
                    var assignedToUser

                    const { currentVersion, serviceType } = service

                    return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                      model.service = service;
                      model.serviceVersion = serviceVersion

                      const servicePackage = getVisiblePackage({ serviceData: serviceVersion });

                      model.serviceType = serviceType;
                      model.servicePackage = servicePackage;
                      model.serviceOptionIds = {};
                      model.deliveryType = "standard";
                      model.inputData = {};

                      if (!model.description) {
                        model.description = service.shortDescription;
                      }

                      if (userService) {
                        const { currentVersion } = userService
                        return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                          assignedToUser = true
                          model.userService = userService;
                          model.userServiceVersion = serviceVersion
                          model.assignedTo = userService.user;

                          model.actualServiceVersion = serviceVersion
                          model.actualVersionType = "worker"

                          calculate()
                        })
                      } else {
                        assignedToUser = false
                        model.userService = null;
                        model.userServiceVersion = null
                        model.assignedTo = null;

                        model.actualServiceVersion = serviceVersion
                        model.actualVersionType = "service"

                        calculate()
                      }
                    }).then(function () {
                      setState({ ...state, model, assignedToUser });
                    })
                  })
                }
            })
          }}
            value={service}
          />
        </FormItem>

        {service ? (
          <React.Fragment>
            <MyInput
              style={{ maxWidth: 175 }}
              inputType="integer"
              minValue={0}
              label="Quantity:"
              placeholder="Enter quantity"
              value={quantity ? String(quantity) : null}
              onChange={value => {
                serviceItem.quantity = value;
                setState({ ...state, serviceItem });
              }}
            />

            {userService ? (
              <Section>
                <HeaderText label="Change Consultant:" subHeader description="You provide this service, but you change the worker for the proposal." />

                <Toggle
                  label="Select different consultant:"
                  value={assignedToUser}
                  onChange={value => {
                    serviceItem.assignedToUser = value;
                    setState({ ...state, serviceItem });
                  }}
                />

                {assignedToUser ? (
                  <FormItem label="Select Consultant:" box>
                    <SelectWorker
                      hideMySelf
                      serviceId={service.id}
                      serviceConfig={{
                        packageId, serviceOptionIds, deliveryType
                      }}
                      onChange={onChangeWorker}
                      value={assignedTo} />
                  </FormItem>
                ) : null}
              </Section>
            ) : (
              <React.Fragment>
                <Section>
                  <HeaderText label="Consultant Selection:" subHeader description="You do not provide this service, select a different worker or create your service." />

                  <FormItem label="Select Consultant:" box>
                    <SelectWorker
                      hideMySelf
                      serviceId={service.id}
                      serviceConfig={{
                        packageId, serviceOptionIds, deliveryType
                      }}
                      onChange={onChangeWorker} value={assignedTo} />
                  </FormItem>
                </Section>

                <Section>
                  <HeaderText label="Add your Own Service:" subHeader description="You can alternatively create and setup this service for you in your service catalog." />

                  <TouchButton
                    onPress={() => {
                      history.push(`/worker/profile/user-service/add`);
                    }}
                    label="Create Service for You"
                  />
                </Section>
              </React.Fragment>
            )}

            <Section>
              <HeaderText label="Additional Message:" subHeader description="Add short message or description for your proposal." />

              <MyInput
                multiline
                maxLength={144}
                placeholder="Enter message..."
                label="Short description or message:"
                description="Short description or message to buyer for the proposal."
                value={description}
                onChange={value => {
                  proposal.description = value;
                  setState({ ...state, proposal });
                }}
              />
            </Section>
          </React.Fragment>
        ) : null}
      </Section>

      {service ? (
        <React.Fragment>
          <ServiceEdit
            allowSelect
            model={serviceItem}
            onUpdate={serviceItem => {
              setState({ ...state, serviceItem });
            }}
          />

          {inputs && inputs.length > 0 ? (
            <Section>
              <HeaderText label="Questions:" subHeader description="Set pre-answers to these questions to start this service. If not answer they will be required before the service can be started." />
              <AddInputsButton
                label={"Answer " + plural(getQuestionCount({ inputs }), "Question")}
                inputs={inputs}
                inputData={inputData}
                onChange={value => {
                  serviceItem.inputData = value;
                  setState({ ...state, serviceItem });
                }}
              />
            </Section>
          ) : null}
        </React.Fragment>
      ) : null}

      {service ? (
        <React.Fragment>
          <TouchButton
            label="Send Proposal"
            onPress={() => {
              return saveItem({ validateAll: true }).then(function () {
                return dL
                  .sendServiceProposal(proposal.id)
                  .then(function () {
                    history.goBack();
                  })
                  .catch(function (err) {
                    alert("Error: " + err);
                  });
              });
            }}
            spacer
          />

          <TouchButton
            label="Save As Template"
            grey
            onPress={() => {
              if (validateSetItem({})) {
                setVisible(true);
              }
            }}
            spacer
          />

          <TouchButton
            label="Save Draft"
            grey
            onPress={() => {
              return saveItem({}).then(function () {
                history.goBack();
              });
            }}
            spacer
          />
        </React.Fragment>
      ) : null}

      {visible ? (
        <SaveProposalTemplate
          proposal={proposal}
          onCancel={() => {
            setVisible();
          }}
          onSave={() => {
            setVisible();
          }}
        />
      ) : null}
    </EditDialog>
  );
}