import session from "root/utils/session";
import { PixelRatio } from "react-native";
import * as Sentry from "@sentry/react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import http from "root/utils/http";
import gutils from "root/utils/global-utils";
import { HashTable } from "root/hashtable";

const base64 = require("base-64");

function formatMoney(str, c, d, t) {
  var n = str,
    c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "",
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
      Math.abs(n - i)
        .toFixed(c)
        .slice(2)
      : "")
  );
}

const factory = {
  years: [
    { label: "New Graduate", value: 0 },
    { label: "1 Year", value: 1 },
    { label: "2 Years", value: 2 },
    { label: "3 Years", value: 3 },
    { label: "4 Years", value: 4 },
    { label: "5 Years", value: 5 },
    { label: "6 Years", value: 6 },
    { label: "7 Years", value: 7 },
    { label: "8 Years", value: 8 },
    { label: "9 Years", value: 9 },
    { label: "10 Years", value: 10 },
    { label: "11 Years", value: 11 },
    { label: "12 Years", value: 12 },
    { label: "13 Years", value: 13 },
    { label: "14 Years", value: 14 },
    { label: "15 Years", value: 15 },
    { label: "16 Years", value: 16 },
    { label: "17 Years", value: 17 },
    { label: "18 Years", value: 18 },
    { label: "19 Years", value: 19 },
    { label: "20 Years", value: 20 },
    { label: "21 Years", value: 21 },
    { label: "22 Years", value: 22 },
    { label: "23 Years", value: 23 },
    { label: "24 Years", value: 24 },
    { label: "25 Years", value: 25 },
    { label: "25+ Years", value: 26 },
  ],
  fluxDeliveryOptions: [
    { label: "Faster", value: "faster" },
    { label: "Normal", value: "normal" },
    { label: "Cheaper", value: "cheaper" }
  ],
  fluxScopeCommitments: [
    { label: "Longest", value: "longest" },
    { label: "Average", value: "average" },
    { label: "Shortest", value: "shortest" }
  ],
  profitMargins: [
    { label: "Aggressive", value: "aggressive" },
    { label: "Normal", value: "normal" },
    { label: "Reduced", value: "reduced" }
  ],
  noteTypes: [{ label: "How To?", value: "how-to" }, { label: "Expert Tip", value: "tip" }, { label: "Example", value: "example" }],
  sortByString: function (arr, propName) {
    return arr.sort(function (a, b) {
      var nameA = a[propName].toUpperCase();
      var nameB = b[propName].toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  },
  clearShoppingCart: function () {
    const cart = session.cart;
    cart.items.forEach(item => {
      utils.deleteCartItem(item);
    });
    cart.items = [];
    return dL.saveCart();
  },
  deleteCartItem: function (cartItem) {
    const cart = session.cart;
    const items = cart.items;
    const index = items.findIndex(item2 => item2.id == cartItem.id);
    items.splice(index, 1);
    session.dispatch("update-cart");
    return db
      .getObj("CartItem", cartItem.id)
      .set("removed", true)
      .save();
  },
  updateCartItem: function (item) {
    const cartItems = session.cart.items;
    const index = cartItems.findIndex(item2 => item2.id == item.id);
    if (index != -1) {
      cartItems[index] = item;
    } else {
      cartItems.push(item);
    }
    session.dispatch("update-cart");
    return dL.saveCartItem(item.id, item);
  },
  getAllItemsForProposal: function ({ proposal, proposalVersion }) {
    const { items } = proposalVersion

    const { allItems } = proposal
    const arr = []

    getDeleteRemovedItems(proposalVersion, items).forEach(item => {
      const { id, items } = item;
      arr.push(allItems.get(id))

      if (items) {
        getDeleteRemovedItems(proposalVersion, items).forEach(item => {
          const { id, items } = item;
          arr.push(allItems.get(id))

          getDeleteRemovedItems(proposalVersion, items).forEach(item => {
            const { id } = item;
            arr.push(allItems.get(id))
          });
        });
      }
    });

    return arr;
  },
  getHasExpertise: function ({ expertise }) {
    var hasExpertise = false;
    utils.expertise.forEach(item => {
      const { value, label } = item;
      const value1 = expertise[value];

      if (value1) {
        hasExpertise = true;
      }
    });
    return hasExpertise;
  },
  getPriceDays: function ({ item, deliveryType, serviceOptions, serviceOptionIds }) {
    const { deliveryDays, price, extraFastPrice, extraFastDeliveryDays } = item;

    var days = deliveryDays;
    var total = price;
    if (deliveryType == "fast") {
      total = extraFastPrice;
      days = extraFastDeliveryDays;
    }

    serviceOptions.forEach(item => {
      if (serviceOptionIds[item.option.id]) {
        total += item.price;
        days += item.deliveryDays;
      }
    });

    return { total, days };
  },
  pluralTab: function ({ list, label }) {
    return list.length > 0 ? utils.plural(list.length, label) : utils.pluralText(list.length, label);
  },
  valueRange: function (minPrice, maxPrice) {
    if (minPrice != maxPrice) {
      return minPrice + " - " + maxPrice
    } else {
      return minPrice
    }
  },
  moneyRange: function (minPrice, maxPrice) {
    if (minPrice != maxPrice) {
      return utils.money(minPrice) + " - " + utils.money(maxPrice);
    } else {
      return utils.money(minPrice);
    }
  },
  register: function ({ inviteCode, firstName, lastName, email, password }) {
    return utils.digestMessage(password).then(function (digest) {
      return http
        .run("register", {
          inviteCode,
          firstName,
          lastName,
          email,
          password: digest
        })
        .then(function (data) {
          if (data.err) {
            throw data.err;
          }
          if (!data.user) {
            throw "Email or password not found.";
          }

          session.sessionId = data.sessionId;
          session.user = data.user;

          return utils.doLogin();
        });
    });
  },
  login: function ({ email, password }) {
    return utils.digestMessage(password).then(function (digest) {
      return http.run("login", { email, password: digest }).then(function (data) {
        if (!data.user) {
          throw "Email or password not found.";
        }

        session.sessionId = data.sessionId;
        session.user = data.user;

        return utils.doLogin();
      });
    });
  },
  logout: function () {
    //this will logout the user on the server
    return http.run("logout", {
      userId: session.user.id
    });
  },
  getTaskStatus: function (status) {
    return utils.getLabelItem(utils.taskRecordStatuses, status)
  },
  userStatuses: [{ label: "Pending", value: "pending" }, { label: "Active", value: "active" }, { label: "Archived", value: "archived" }],
  delayTypes: [{ label: "From start of order", value: "start-of-order" }, { label: "After dependent items completed", value: "days-from-pre-req" }],
  bonusTypes: [{ label: "Company", value: "company" }, { label: "Team", value: "team" }, { label: "Individual", value: "individual" }],
  getValueItem: function (items, value) {
    for (var i = 0; i < items.length; i++) {
      if (items[i].value == value) {
        return items[i];
      }
    }
  },
  projectTypes: [{ label: "Project", value: "project" }],
  augRoles: [{ label: "React Dev", value: 12, businessSector: "Technology" }],
  reviewCategories: [{ label: "Communications/promptness of responses", value: "communications" }, { label: "Timeliness of deliverables", value: "timeliness" }, { label: "Thoroughness/quality, insights/thoughtfulness/innovation/creativeness", value: "quality" }, { label: "Attention to details/detail oriented", value: "detail" }, { label: "Spelling/grammar/language comprehension", value: "language" }, { label: "Adherence to guidance/outlined requirements", value: "requirements" }],
  staffServiceTypes: [{ label: "Staff", value: "staff" }, { label: "Advisory", value: "advisory" }],
  skillLevels: [{ label: "Master", value: "master" }, { label: "Expert", value: "expert" }, { label: "Junior", value: "junior" }],
  staffTypes: [{ label: "Staff", value: "staff" }, { label: "Advisor", value: "advisor" }],
  priceTiers: [{ label: "Master", value: "master" }, { label: "Expert", value: "expert" }, { label: "Junior", value: "junior" }],
  requestTypes: [{ label: "Consulting Services", value: "consulting" }, { label: "Staff Augmentation", value: "staff" }, { label: "Advisory", value: "advisory" }, { label: "Business Initiative", value: "initiative" }],
  priorityLevels: [{ label: "High", value: "high", rateM: 1.1, deliveryDays: 1, turboDeliveryDays: 0 }, { label: "Normal", value: "normal", rateM: 1, deliveryDays: 4, turboDeliveryDays: 2 }, { label: "Reduced Cost", value: "low", rateM: 0.9, deliveryDays: 7, turboDeliveryDays: 4 }],
  deliverableTypes: [{ label: "File", value: "file" }, { label: "Form", value: "form" }],
  concatString: function (str1, middle, str2) {
    var str = "";
    if (str1) {
      str += str1;
    }
    if (str1 && str2) {
      str += middle;
    }
    if (str2) {
      str += str2;
    }
    return str;
  },
  renderArray: function (arr) {
    var str = "";
    arr.forEach((item, index) => {
      str += index != 0 ? ", " : "";
      if (item.label) {
        str += item.label;
      } else {
        str += item;
      }
    });
    return str;
  },
  getJSONObj(value, defaultValue) {
    if (value) {
      return gutils.jsonParse(value);
    } else {
      return defaultValue;
    }
  },
  getLabelItems: function (list, arr) {
    if (!arr) { return }
    var str = "";
    arr.forEach((item, index) => {
      if (index != 0) {
        str = str + ", ";
      }
      str += utils.getLabelItem(list, item);
    });
    return str;
  },
  getLabelItem: function (list, value) {
    var item = list.find(item => item.value == value);
    if (item) {
      return item.label;
    } else {
      return value;
    }
  },
  toLowerCase: function (value) {
    return value.toLowerCase()
  },
  workerLevels: [{
    label: "Level 0", value: "0",
    description: "Level one description"
  }, { label: "Level 1", value: "1" }, { label: "Level 2", value: "2" }, { label: "Level 3", value: "3" }, { label: "Level 4", value: "4" }, { label: "Level 5", value: "5" }],

  taskTypes: [{ label: "Pre-Defined", value: "tasks" }, { label: "Service", value: "services" }, { label: "Custom", value: "custom" }],

  deliverableReviewPeriods: [{ label: "No Review", value: "" }, { label: "Short (hours)", value: "short" }, { label: "Normal (2 days)", value: "normal" }, { label: "Long (up to 4 days)", value: "long" }],

  userStatuses: [{ label: "Draft", value: "draft" }, { label: "Pending", value: "pending" }, { label: "Active", value: "active" }, { label: "Archived", value: "archived" }],
  businessRoleStatuses: [{ label: "Active", value: "active" }, { label: "Archived", value: "archived" }],

  taskRecordStatuses: [{ label: "Open", value: "open" }, { label: "Ready to Start", value: "ready" }, { label: "Pending", value: "pending" }, { label: "Completed", value: "completed" }, { label: "Started", value: "started" }, { label: "Working", value: "working" }, { label: "Canceled", value: "canceled" }],

  serviceStatuses: [{ label: "Draft", value: "draft" }, { label: "Pending", value: "pending" }, { label: "Active", value: "active" }, { label: "Archived", value: "archived" }],

  userRoles: [{ label: "Business Development", value: "bd" }, { label: "Strategy Consultant", value: "sc" }, { label: "Project Manager", value: "pm" }, { label: "Consultant", value: "worker" }, { label: "Admin", value: "admin" }],
  budgets: [{ label: "Undefined", value: "Undefined" }, { label: "< $250", value: "250" }, { label: "$250 - $750", value: "750" }, { label: "$750 - $1500", value: "1500" }, { label: "$1500 - $5000", value: "5000" }, { label: "$5000 - $10000", value: "10000" }, { label: "$10000+", value: "10001" }],
  urgencyLevels: [{ label: "Need services ASAP", value: "asap" }, { label: "Within the next 2 weeks", value: "2-weeks" }, { label: "Within the next month", value: "next-month" }, { label: "Within the next 3 months", value: "3-months" }],
  urgencyLevels2: [{ label: "Just browsing", value: "browsing" }, { label: "Comparing services", value: "comparing" }, { label: "Ready to purchase", value: "ready" }, { label: "Ready to purchase", value: "ready" }],
  companyStages2: [{ label: "Startup", value: "startup" }, { label: "Growth", value: "growth" }, { label: "Enterprise", value: "enterprise" }],
  companyStages: [{ label: "Startup", value: "startup" }, { label: "Small Business", value: "small-business" }, { label: "Medium Business", value: "medium-business" }, { label: "Enterprise", value: "enterprise" }],
  revisionOptions: [{ label: "N/A", value: null }, { label: "1", value: 1 }, { label: "2", value: 2 }, { label: "3", value: 3 }, { label: "4", value: 4 }, { label: "5", value: 5 }, { label: "6", value: 6 }, { label: "7", value: 7 }, { label: "8", value: 8 }, { label: "9", value: 10 }, { label: "Unlimited", value: 999 }],
  serviceTypes: [
    { label: "Concept Development", value: "concept" },
    { label: "Business Setup", value: "business" },
    { label: "Fundraising", value: "fundraising" },
    { label: "People", value: "people" },
    { label: "Operations", value: "operations" },
    { label: "Sales", value: "sales" },
    { label: "Business/Partner Development", value: "business-dev" },
    { label: "Design", value: "design" },
    { label: "Finance", value: "finance" },
    { label: "Accounting/Bookkeeping", value: "accounting" },
    { label: "Marketing", value: "marketing" },
    { label: "Procurement/Fulfillment", value: "procurement" },
    { label: "Product Development", value: "product-dev" },
    { label: "Product Management", value: "product-mgmt" },
    { label: "Technical", value: "technical" },
    { label: "User Experience", value: "user-xperience" },
    { label: "Strategy", value: "strategy" },
    { label: "Manufacturing", value: "manufacturing" },
    { label: "HR/Recruitment", value: "hr" },
    { label: "Legal", value: "legal" }
  ],
  getGroupedItems: function ({ list, groupBy }) {
    const hash = new HashTable()
    list.forEach(item => {
      var groupValue = item[groupBy]
      if (!hash.has(groupValue)) {
        hash.set(groupValue, {
          label: groupValue,
          options: []
        })
      }
      hash.get(groupValue).options.push(item)
    })
    return hash.values()
  },
  getHashCode: function (str) {
    var hash = 0;
    if (str.length == 0) return hash;
    for (var i = 0; i < str.length; i++) {
      var char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
  },
  roles: [{ label: "Mentor", value: "Mentor" }, { label: "Advisor", value: "Advisor" }, { label: "Investor", value: "Investor" }, { label: "Consultant", value: "Consultant" }, { label: "Affiliate/Sales Person", value: "Affiliate/Sales Person" }, { label: "Co-Founder", value: "Co-Founder" }, { label: "Other", value: "Other" }],
  goals: [
    { label: "Validating my idea", value: "1", category: "Concept",sector:"concept" },
    { label: "Preparing my pitch", value: "2", category: "Concept",sector:"concept" },
    { label: "Finding co-founders", value: "3", category: "Concept",sector:"concept" },
    { label: "Building a product prototype", value: "4", category: "Concept",sector:"concept" },
    { label: "Conducting focus groups", value: "5", category: "Concept",sector:"concept" },
    { label: "Finding seed funding", value: "6", category: "Concept",sector:"concept" },
    { label: "Building a startup team", value: "7", category: "Concept" ,sector:"concept"},
    { label: "Getting beta customers", value: "8", category: "Concept" ,sector:"concept"},
    { label: "Finding a plan to market", value: "9", category: "Concept",sector:"concept" },

    { label: "Finding new leads", value: "10", category: "Marketing" ,sector:"marketing"},
    { label: "Building my brand", value: "11", category: "Marketing" ,sector:"marketing"},
    { label: "Generating marketing material", value: "12", category: "Marketing" ,sector:"marketing"},
    { label: "Determine a marketing strategy", value: "13", category: "Marketing",sector:"marketing" },
    { label: "Social media marketing plan or execution", value: "14", category: "Marketing",sector:"marketing" },
    { label: "Public relations strategy or execution", value: "15", category: "Marketing" ,sector:"marketing"},
    { label: "Advertising strategy or execution", value: "16", category: "Marketing",sector:"marketing" },
    { label: "SEO/SEM execution", value: "17", category: "Marketing" ,sector:"marketing"},
    { label: "Affiliate/partner relations", value: "18", category: "Marketing",sector:"marketing" },

    { label: "Connecting with sales leads", value: "19", category: "Sales",sector:"sales" },
    { label: "Creating sales material and proposals", value: "20", category: "Sales" ,sector:"sales"},
    { label: "Closing deals", value: "21", category: "Sales" ,sector:"sales"},
    { label: "Hiring sales people", value: "22", category: "Sales" ,sector:"sales"},
    { label: "Expanding into new markets", value: "24", category: "Sales",sector:"sales" },
    { label: "Expanding into new industries", value: "23", category: "Sales",sector:"sales" },

    { label: "Finding a co-founder", value: "25", category: "People" ,sector:"people"},
    { label: "Finding the right people", value: "27", category: "People"  ,sector:"people"},
    { label: "Managing people", value: "26", category: "People"  ,sector:"people"},
    { label: "Staff turnover", value: "28", category: "People"  ,sector:"people"},
    { label: "Staff motivation and performance", value: "29", category: "People"  ,sector:"people"},
    { label: "Other HR related issues", value: "30", category: "People" ,sector:"people" },

    { label: "Reducing cost", value: "31", category: "Operations" ,sector:"operations"},
    { label: "Documenting and optimizing processes", value: "32", category: "Operations"  ,sector:"operations"},

    { label: "Distribution and warehousing issues", value: "33", category: "Fulfillment"  ,sector:"procurement"},
    { label: "Find office/manufacturing/fulfillment space", value: "34", category: "Fulfillment"  ,sector:"procurement"},
    { label: "Creating a customer service team", value: "35", category: "Fulfillment" ,sector:"procurement" },

    { label: "Accounting or bookkeeping issues", value: "36", category: "Back Office" ,sector:"accounting" },
    { label: "HR & payroll setup and other issues", value: "37", category: "Back Office",sector:"accounting"  },

    { label: "Building the prototype", value: "37", category: "Product" ,sector:"product-dev"},
    { label: "Building the MVP", value: "38", category: "Product" ,sector:"product-dev" },
    { label: "Building the roadmap", value: "39", category: "Product" ,sector:"product-dev" },
    { label: "Scaling and performance issues", value: "40", category: "Product" ,sector:"product-mgmt" },
    { label: "Manufacturing issues", value: "41", category: "Product"  ,sector:"manufacturing"},
    { label: "Finding designer or developers", value: "42", category: "Product"  ,sector:"product-dev"},

    { label: "Developing my financing plan", value: "43", category: "Financing",sector:"finance" },
    { label: "Finding financing", value: "44", category: "Financing" ,sector:"finance"},

    { label: "Setting up my business", value: "45", category: "Legal" ,sector:"legal"},
    { label: "Create terms and conditions", value: "46", category: "Legal" ,sector:"legal"},
    { label: "HR related legal issues", value: "47", category: "Legal",sector:"legal" }
  ],
  employeeSizes: [{ label: "Sole employee", value: 1 }, { label: "1-3 employees", value: 3 }, { label: "4-10 employees", value: 10 }, { label: "11-25 employees", value: 25 }, { label: "26-50 employees", value: 50 }, { label: "51-100 employees", value: 100 }, { label: "101-250 employees", value: 250 }, { label: "250+ employees", value: 251 }],
  revenueSizes: [
    { label: "No Revenue", value: 0 },
    { label: "$0 - $25,000/yr", value: 25 },
    { label: "$25,000 - $50,000/yr", value: 50 },
    { label: "$50,000 - $100,000/yr", value: 100 },
    { label: "$100,000 - $250,000/yr", value: 250 },
    { label: "$250,000 - $500,000/yr", value: 500 },
    { label: "$500,000 - $1,000,000/yr", value: 1000 },
    { label: "$1,000,000 - $2,500,000/yr", value: 2500 },
    { label: "$2,500,000 - $10,000,000/yr", value: 10000 },
    { label: "$10,000,000 - $25,000,000/yr", value: 25000 },
    { label: "$25,000,000 - $50,000,000/yr", value: 50000 },
    { label: "$50,000,000 - $100,000,000/yr", value: 100000 },
    { label: "$100,000,000 - $250,000,000/yr", value: 250000 },
    { label: "$250,000,000/yr+", value: 250001 }
  ],
  expertise: [
    { label: "Concept Development", value: "Concept Development" },
    { label: "Business Setup", value: "Business Setup" },
    { label: "Fundraising", value: "Fundraising" },
    { label: "People", value: "People" },
    { label: "Operations", value: "Operations" },
    { label: "Sales", value: "Sales" },
    { label: "Business/Partner Development", value: "Business Development" },
    { label: "Design", value: "Design" },
    { label: "Finance", value: "Finance" },
    { label: "Accounting/Back-Office Operations", value: "Accounting/Back-End Operations" },
    { label: "Marketing", value: "Marketing" },
    { label: "Fulfillment", value: "Fulfillment" },
    { label: "Product Development", value: "Product Development" },
    { label: "Product Management", value: "Product Management" },
    { label: "Technical", value: "Technical" },
    { label: "User Experience", value: "User Experience" },
    { label: "Strategy", value: "Strategy" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "HR/Recruitment", value: "HR/Recruitment" },
    { label: "Legal", value: "Legal" }
  ],
  industries: [
    { label: "3D Printing", value: "3D Printing" },
    { label: "Aerospace", value: "Aerospace" },
    { label: "Agriculture", value: "Agriculture" },
    { label: "Art and Design", value: "Art and Design" },
    { label: "B2B Services", value: "B2B Services" },
    { label: "Big Data", value: "Big Data" },
    { label: "Biotechnology", value: "Biotechnology" },
    { label: "Consulting", value: "Consulting" },
    { label: "Consumer Products", value: "Consumer Products" },
    { label: "Defence and Security", value: "Defence and Security" },
    { label: "E-Commerce", value: "E-Commerce" },
    { label: "Education", value: "Education" },
    { label: "Energy", value: "Energy" },
    { label: "Entertainment", value: "Entertainment" },
    { label: "Event Planning", value: "Event Planning" },
    { label: "Fashion", value: "Fashion" },
    { label: "Finance", value: "Finance" },
    { label: "Food and Beverage", value: "Food and Beverage" },
    { label: "Hardware", value: "Hardware" },
    { label: "Health, Fitness and Wellness", value: "Health, Fitness and Wellness" },
    { label: "Healthcare", value: "Healthcare" },
    { label: "Human Resources", value: "Human Resources" },
    { label: "Legal Services", value: "Legal Services" },
    { label: "Media", value: "Media" },
    { label: "Mining", value: "Mining" },
    { label: "Mobile", value: "Mobile" },
    { label: "Non-Profit", value: "Non-Profit" },
    { label: "Politics and Government", value: "Politics and Government" },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Technology", value: "Technology" },
    { label: "Travel", value: "Travel" }
  ],
  businessStages: [{ label: "Idea phase", value: "Idea phase" }, { label: "Minimal viable product built", value: "Minimal viable product built" }, { label: "Business plan mapped out", value: "Business plan mapped out" }, { label: "Paying customers", value: "Paying customers" }],
  works: [
    { label: "Very little", value: "Very little" },
    { label: "Some what", value: "Some what" },
    { label: "A lot", value: "A lot" },
    //{label:"--",value:""},
    { label: "15 mins", value: 15 },
    { label: "30 mins", value: 30 },
    { label: "45 mins", value: 45 },
    { label: "1 hr", value: 60 },
    { label: "2 hrs", value: 60 * 2 },
    { label: "3 hrs", value: 60 * 3 },
    { label: "4 hrs", value: 60 * 4 },
    { label: "1 dy", value: 60 * 8 * 1 },
    { label: "2 dys", value: 60 * 8 * 2 }
  ],
  communityCategories: [
    { label: "Technology Startup", value: "Technology Startup" },
    { label: "Art & Design", value: "Art & Design" },
    { label: "Executive Coaching", value: "Executive Coaching" },
    { label: "Health/Fitness/Beauty", value: "Health/Fitness/Beauty" },
    { label: "Sales/Marketing", value: "Sales/Marketing" },
    { label: "Accounting/Finance", value: "Accounting/Finance" },
    { label: "HR/Recruitment", value: "HR/Recruitment" },
    { label: "Educational", value: "Educational" },
    { label: "Product Development", value: "Product Development" },
    { label: "Legal/International", value: "Legal/International" },
    { label: "Other", value: "Other" }
  ],
  product: [{ label: "Concept", value: "Concept" }, { label: "Mockups/Design", value: "" }, { label: "Prototype", value: "" }, { label: "MVP product/short run production", value: "" }, { label: "released product/production", value: "" }, { label: "extended roadmap/scaling logistics/distribution/price", value: "" }, { label: "stable", value: "" }],
  employees: [{ label: "need founders", value: "" }, { label: "need tech help", value: "" }, { label: "team built", value: "" }, { label: "need to fill gaps", value: "" }, { label: "need to grow", value: "" }, { label: "stable", value: "" }],
  customers: [
    { label: "no customers", value: "" },
    { label: "focus group customers", value: "" },
    { label: "beta free customers", value: "" },
    { label: "beta paying customers", value: "" },
    { label: "released paying customers", value: "" },
    { label: "early customer growth - sales funnel/message/pitch development/optimization/initial channel development", value: "" },
    { label: "customer growth - new channel development/multiple messages/markets", value: "" },
    { label: "rapid growth/adverising", value: "" },
    { label: "stable growth", value: "" }
  ],
  finance: [{ label: "pre-seed", value: "" }, { label: "seed", value: "" }, { label: "series A", value: "" }, { label: "series B", value: "" }, { label: "series C", value: "" }, { label: "series D", value: "" }, { label: "stable", value: "" }, { label: "", value: "" }, { label: "", value: "" }],
  revenue: [{ label: "no revenue", value: "" }, { label: "early beta customer revenue", value: "" }, { label: "discounted revenue", value: "" }, { label: "steady growth", value: "" }, { label: "rapid growth", value: "" }, { label: "stable", value: "" }],
  backEndOps: [{ label: "pre-company formed", value: "" }, { label: "company formed", value: "" }, { label: "legal/accounting setup", value: "" }, { label: "minimal team", value: "" }, { label: "efficiency/growth team development", value: "" }, { label: "stable", value: "" }],
  resourceTypes: [
    { name: "Company", icon: "pe-7s-box2", value: "company", type: "Company" },
    { name: "Person", icon: "pe-7s-user", value: "person", type: "Person" },
    { name: "Article", icon: "pe-7s-light", value: "article", type: "Article" },
    { name: "Event", icon: "pe-7s-date", value: "event", type: "Event" },
    { name: "Read", icon: "pe-7s-news-paper", value: "read", type: "Reading Item" },
    { name: "Watch", icon: "pe-7s-video", value: "watch", type: "Video" },
    { name: "Product", icon: "pe-7s-cash", value: "product", type: "Product" },
    { name: "Place", icon: "pe-7s-map-marker", value: "place", type: "Place" }
  ],
  serviceRoles: [{ sector: "Accounting", label: "Bookkeeper", value: "Bookkeeper" }],
  serviceCores: [{ label: "Research", value: "research" }, { label: "Advisory", value: "advisory" }, { label: "Strategy", value: "strategy" }, { label: "Execution", value: "execution" }],
  padWithZero: function (number) {
    if (!number) {
      return "00";
    }
    const string = number.toString();
    if (number < 10) {
      return "0" + string;
    }
    return string;
  },
  getCountdownBreakdown: function (millis) {
    const totalSeconds = millis / 1000;
    const days = Math.floor(totalSeconds / 60 / 60 / 24);
    var daySecs = days * 60 * 60 * 24;
    const hours = Math.floor((totalSeconds - daySecs) / 60 / 60);
    const minutes = Math.floor((totalSeconds - hours * 60 * 60 - daySecs) / 60);
    const seconds = Math.floor(totalSeconds - hours * 60 * 60 - minutes * 60 - daySecs);

    return { days, hours, minutes, seconds };
  },
  getMMSSFromMillis: function (millis) {
    const totalSeconds = millis / 1000;
    const seconds = Math.floor(totalSeconds % 60);
    const minutes = Math.floor(totalSeconds / 60);

    const padWithZero = number => {
      const string = number.toString();
      if (number < 10) {
        return "0" + string;
      }
      return string;
    };
    return padWithZero(minutes) + ":" + padWithZero(seconds);
  },
  getServiceProposalStatus: function (status) {
    return utils.getLabelItem(utils.serviceProposalStatuses, status);
  },
  getServiceDeliveryStatus: function (status) {
    return utils.getLabelItem(utils.serviceDeliveryStatus, status);
  },
  getDeliveryType: function (value) {
    return utils.getLabelItem(utils.deliveryTypes, value);
  },
  getServiceOrderStatus: function (status) {
    return utils.getLabelItem(utils.serviceOrderStatuses, status);
  },
  getOrderStatus: function (status) {
    return utils.getLabelItem(utils.orderStatuses, status);
  },
  serviceProposalStatuses: [{ label: "All", value: "all" }, { label: "Draft", value: "draft" }, { label: "Open", value: "open" }, { label: "Captured", value: "captured" }, { label: "Lost", value: "lost" }, { label: "Withdrawn", value: "withdrawn" }],

  serviceDeliveryStatus: [{ label: "Pending Client Review", value: "pending" }, { label: "Accepted", value: "accept" }, { label: "Request Modifications", value: "decline" }, { label: "Removed", value: "removed" }],
  deliveryTypes: [{ label: "Standard", value: "standard" }, { label: "Fast Delivery", value: "fast" }],
  orderStatuses: [{ label: "All", value: "all" }, { label: "Pending", value: "pending" }, { label: "Working", value: "working" }, { label: "Completed", value: "completed" }],

  serviceRequestStatuses: [{ label: "All", value: "all" }, { label: "New", value: "new" }, { label: "Open", value: "open" }, { label: "Captured", value: "captured" }, { label: "Lost", value: "lost" }],

  workRequestStatuses: [{ label: "All", value: "all" }, { label: "Pending", value: "pending" }, { label: "Accepted", value: "accept" }, { label: "Declined", value: "decline" }],

  serviceOrderStatuses: [{ label: "All", value: "all" }, { label: "Waiting", value: "waiting" }, { label: "Pending Questions", value: "pending" }, { label: "Ready to Start", value: "ready" }, { label: "Working", value: "working" }, { label: "Pending Review", value: "review" }, { label: "Completed", value: "completed" }],
  debounce: function (func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  },
  isEmail: function (email) {
    if (!email) {
      return false;
    }
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
  },
  isPhone: function (txt) {
    if (!txt) {
      return false;
    }

    var phoneno = /^1?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (txt.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  },
  digestMessage: async function (message) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
    return hashHex;
  },
  getFsDate: function (dt) {
    if (!dt) {
      return;
    }
    if (dt.toDate) {
      return dt.toDate();
    } else {
      return dt;
    }
  },
  doLogin: function (props) {
    const { disableStoreLogin } = props ? props : {};
    var user = session.user;
    var name = user.firstName + " " + user.lastName;

    Sentry.configureScope(function (scope) {
      scope.setUser({
        id: user.id,
        name: name,
        email: user.email
      });
    });

    if (session.pusher) {
      session.pusher.disconnect();
    }

    if (!disableStoreLogin) {
      localStorage.setItem(
        "loginData",
        JSON.stringify({
          sessionId: session.sessionId,
          userId: session.user.id
        }),
        function (err) {
          if (err) {
            alert("error in async:" + err.message);
          }
        }
      );
    }

    session.cart = { items: [] };

    return dL
      .getUserLogin(user.id)
      .then(function (user) {
        session.user = user;
        const { defaultCompany } = user
        session.company = defaultCompany;

        return dL
          .getQuery("CartItem")
          .equalTo("user", db.getObj("User", user.id))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            session.cart.items = dL.loadObjects("CartItem", objs);
          });
      })
      .then(function () {
        session.dispatch("on-login");
      });
  },
  getCountItem: function (item, list) {
    if (list && list.length > 0) {
      const { label, value } = item;
      return { label: label + " (" + list.length + ")", value };
    } else {
      return item;
    }
  },
  getImageUrl: function (value) {
    var value1;
    if (value) {
      value1 = "https://growly.s3.amazonaws.com/" + value.path + value.file;
    }
    return value1;
  },
  roundMoney: function (value, decimals) {
    if (decimals == null) {
      decimals = 2;
    }
    return utils.roundNumber(value, decimals);
  },
  roundNumber: function (value, decimals) {
    if (!decimals) { decimals = 0 }
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  },
  yesNo: function (value) {
    if (value) {
      return "YES";
    } else {
      return "NO";
    }
  },
  money: function (input, round) {
    if (round == null) {
      round = 2;
    }
    if (input == undefined) {
      return "";
    }
    input = Number(input);
    var rtn = "";
    if (input < 0) {
      if (input % 1 != 0) {
        rtn = "($" + formatMoney(input * -1, round, ".", ",") + ")";
      } else {
        rtn = "($" + formatMoney(input * -1, 0, ".", ",") + ")";
      }
    } else {
      if (input % 1 != 0) {
        rtn = "$" + formatMoney(input, round, ".", ",");
      } else {
        rtn = "$" + formatMoney(input, 0, ".", ",");
      }
    }

    if (input % 1 != 0) {
      if (round > 2 || round == 1) {
        for (var i = 1; i <= round - 2; i++) {
          if (rtn.length > 0) {
            if (rtn[rtn.length - 1] == "0") {
              rtn = rtn.substring(0, rtn.length - 1);
            }
          }
        }
      }
    }
    return rtn;
  },
  randomNumber: function (min, max) {
    min = Math.ceil(min);
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  guid: function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  },
  isMoney: function (value) {
    if (utils.isNumber(value)) {
      var number = utils.isNumber(value);
      if (number >= 0) {
        return true;
      }
    }
    return false;
  },
  isInteger: function (value) {
    if (utils.isNumber(value)) {
      return Number.isInteger(Number(value));
    }
    return false;
  },
  isNumber: function (value) {
    return !Number.isNaN(Number(value));
  },
  getUri: function (item, forced, noLocal, width, height) {
    var signFunct = "sign_file";

    if (item.type == "video/mp4") {
      signFunct = "sign_file_2";
    }

    noLocal = true;

    if (item.type == "video/mp4") {
      return Promise.resolve("https://d3es2mz6f6h4lv.cloudfront.net/" + item.path + item.file);
    }

    var url = session.masterUrl + "/" + signFunct + "?name=" + item.fileName + "&remotePath=" + encodeURIComponent(item.path + item.file);

    var dt = base64.encode(
      JSON.stringify({
        bucket: "growly",
        key: item.path + item.file,
        edits: {
          jpeg: { quality: 95 },
          resize: {
            width: PixelRatio.getPixelSizeForLayoutSize(width ? width : 375),
            height: PixelRatio.getPixelSizeForLayoutSize(height ? height : 812),
            fit: "inside"
          },
          sharpen: 2
        }
      })
    );
    return Promise.resolve("https://d1ihj6glbvf3e1.cloudfront.net/" + dt);
  },
  pluralText: function (value, single, plural) {
    const d2 = function () {
      return single;
    };
    const d1 = function () {
      if (plural) {
        return plural;
      } else {
        return single + "s";
      }
    };

    if (Array.isArray(value)) {
      if (value.length == 0 || value.length > 1) {
        return d1();
      } else {
        return d2();
      }
    } else {
      if (Number(value) == 0 || Number(value) > 1) {
        return d1();
      } else if (Math.abs(Number(value)) == 1) {
        return d2();
      } else {
        return d1();
      }
    }
  },
  plural: function (value, single, plural) {
    const d2 = function () {
      return value + " " + single;
    };
    const d1 = function () {
      if (plural) {
        return value + " " + plural;
      } else {
        //if (single[single.length - 1] == "y") {
        //  return value + " " + single.substring(0, single.length - 1) + "ies";
        //} else {
        return value + " " + single + "s";
        //}
      }
    };

    if (Array.isArray(value)) {
      if (value.length == 0 || value.length > 1) {
        return d1();
      } else {
        return d2();
      }
    } else {
      //if (Number.isInteger(value) == true) {
      if (Number(value) == 0 || Number(value) > 1) {
        return d1();
      } else if (Math.abs(Number(value)) == 1) {
        return d2();
      } else {
        return d1();
      }
      //}
    }
  }
};

const utils = { ...gutils, ...factory };

const { getDeleteRemovedItems } = utils

export default utils;
