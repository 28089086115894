import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import colors from "root/colors";
import Moment from "moment-business-days";
import { Switch, Route, useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { HashTable } from "root/hashtable";
import { RenderClientInputRequirements } from "root/forms";
import http from "root/utils/http";
import { EditKeyUser, DisplayKeyUser, RenderLimitedOptions, RenderLimitedPackages, Quantity, Price } from "root/pack-3";
import { __DEV__ } from "root/dev";
import { StaffOrderStats } from "root/staff-aug"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { RenderProposalStepItem, ProposalCheckmark, DisplaySubServiceItem, RenderAddToCartButtons, RenderProposalStage, RenderRow, RenderProposalStaff, ProposalOverview, RenderProposalTimeline, RenderProposalVersions, getProposalSelectedItems, getCartItemFromProposalItem, addProposalItemToCart, updateTotals, getAllTeam, RenderServiceBlocks, StepWrapper, ServiceWrapper, RenderPreReq, RenderCartPanel, InviteMember, getProposalChatRoom, getPaymentType } from "root/proposals-2"
import ReactSlider from "react-slider"
import { getTotalTimeline, RenderStartDoneDates, getTimelineBasicData, getTimelineData, RenderVisualTimeline } from "root/services"
import { getMainServiceModel, getMainProposalModel2, getMainProposalModel } from "root/service-model"
import { SelectUsers, BasicTop, SelectOptions, BoxRowItem, HeaderText, LabelUserItem, UserItem } from "root/pack-2";
import { MyInput, DragHandle, SortableList, ItemExpandSection, ItemExpand, RenderIcon, DateTimeItem, BoxItem4, NoRecordsBox, DeleteConfirm, Section, ModalBasic, BoxItem, LabelItem, LinkButton, Hide, FlexExpand, FlexRow, TabBar, Text, TextLine, View, UnDeleteIcon, TrashIcon, EditIcon, CheckIcon, FormItem, TouchButton, NoRecords } from "root/pack-1";
import { createProposalItemPriceMap, getDeliveryDaysAll, getStartDate, getDoneDateAll, getDoneDate, getSubServicesForService, getDataFromPriceMap, createProposalPriceMapBuyer, getFinalPriceDays2, getPriceMapFromPriceMap, createProposalPriceMap, canDisplay, getTasksForService, getWorkHours, getServicePrice2, calcStartDayIndex } from "root/service-functions";
import { ChatWindow, getChatRoomsWorker, getChatRoomsBuyer } from "root/chat";
import { EditProposalServiceBuyer, EditStaffInCart, EditServiceInCart, SaveProposalTemplate, EditProposalOption, ViewProposalStaffServiceItem, ViewProposalServiceItem, EditProposalPackage, EditProposalStaff, EditProposalService, EditProposalStage, EditProposalStageStep } from "root/proposals-1";
import Datetime from "react-datetime";
import { RenderOrderWorker } from "root/orders"
import { RenderCartItems } from "root/cart"
import { ViewSubService } from "root/services-worker"
import { RenderWorkConfig, RenderServiceNeedsUpdate, EditServiceSubServiceConfigBuyer, EditServiceSubServiceConfig } from "root/services"
import { RestOfTeam } from "root/users"

const { roundMoney, profitMargins, getValueItem, roundNumber, updateCartItem, getAllItemsForProposal, getAllItemsForVersion, getDeleteRemovedItems, pluralText, pluralTab, plural, getLabelItem, money, skillLevels, staffServiceTypes } = utils;

const deleteItem = function ({ proposalItem }) {
  const { inCart, total, id, proposal } = proposalItem
  const { id: proposalId } = proposal

  return dL.getObj("ServiceProposalItem", id)
    .set("removed", true)
    .save()
    .then(function () {
      if (inCart) {
        return dL.getObj("ServiceProposal", proposalId)
          .increment("buyerItemsInCart", -1)
          .increment("buyerCartTotal", -1 * total)
          .set("lastBuyerCartUpdatedAt", new Date())
          .save()
      } else {
        return dL.getObj("ServiceProposal", proposalId)
          .set("inCart", inCart)
          .increment("buyerItemsInCart")
          .increment("buyerCartTotal", total)
          .set("lastBuyerCartUpdatedAt", new Date())
          .save()
      }
    })
}

const addBackToCart = function ({ proposalItem }) {
  const { total, inCart, id, proposal } = proposalItem
  const { id: proposalId } = proposal

  return dL.getObj("ServiceProposalItem", id)
    .set("inCart", inCart)
    .save()
    .then(function () {
      return dL.getObj("ServiceProposal", proposalId)
        .increment("buyerItemsInCart", -1)
        .increment("buyerCartTotal", -1 * total)
        .increment("buyerItemsForFuture")
        .increment("buyerFutureTotal", total)
        .set("lastBuyerCartUpdatedAt", new Date())
        .save()
    })
}

const saveForLater = function ({ proposalItem }) {
  const { total, inCart, id, proposal } = proposalItem
  const { id: proposalId } = proposal

  return dL.getObj("ServiceProposalItem", id)
    .set("inCart", inCart)
    .save()
    .then(function () {
      return dL.getObj("ServiceProposal", proposalId)
        .set("inCart", inCart)
        .increment("buyerItemsInCart")
        .increment("buyerCartTotal", total)
        .increment("buyerItemsForFuture", -1)
        .increment("buyerFutureTotal", -1 * total)
        .set("lastBuyerCartUpdatedAt", new Date())
        .save()
    })
}

export function ProposalBuyerView() {
  const { proposalId, sectionId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({ tabValue3: "cart", tabValue: "items", tabValue2: "options", optionIds: {} });
  const { editItem, isNew, addUserVisible, tabValue3, staffOrders, tabValue2, tabValue, proposalVersions, historicalProposalVersion, serviceStaffView, serviceOrders, serviceView, packageId, optionIds, proposal, selectedStepItem, selectedProposalItem, priceDisplayType } = state;

  const isSubUrl = useRouteMatch(`${url}/:type/:recordId?`);

  useEffect(() => {
    dL.getServiceProposal({ proposalId })
      .then(function ({ proposal, serviceOrders, staffOrders }) {
        const { paymentTermType, allItems, lastSentProposalVersion } = proposal;
        const { marginAdder, fluxDelivery, fluxScope, profitMarginLevel } = proposal;

        if (!paymentTermType) {
          proposal.paymentTermType = "monthly"
        }

        if (!fluxDelivery) {
          proposal.fluxDelivery = "normal"
        }
        if (!fluxScope) {
          proposal.fluxScope = "average"
        }
        if (!profitMarginLevel) {
          proposal.profitMarginLevel = profitMargins[1].value
        }
        if (!marginAdder) {
          proposal.marginAdder = 0
        }

        if (isSubUrl) {
          const { recordId, type } = isSubUrl.params;

          const { proposalItems } = proposal;

          var isNew = false;
          var editItem = {};
          if (recordId) {
            editItem = proposalItems.find(item => item.id == recordId);
          }
        }

        setState({ ...state, editItem, proposal })
        setIsLoading(false)
      })
    /*
    .catch(function (err) {
      alert("Error:" + err);
    });
    */
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { paymentTermType, proposalItems, buyerSharedWith, buyerPriceMap, fluxDelivery, fluxScope, id, status, title, finalTotal, finalDeliveryDays, businessDevelopment, strategyConsultant, projectManager } = proposal;

  const isBuyerView = true
  const chatRooms = getProposalChatRoom({ proposal, isBuyerView: true });

  const tabs = []
  tabs.push({ label: "Cart Items", value: "items" });
  tabs.push({ label: "Timeline", value: "timeline" });
  tabs.push({ label: "Share Cart", value: "shared" });

  const renderPanel = function () {
    const paymentTermTypes = getPaymentType(totalMonths)

    const { paymentTermType } = proposal

    return (
      <React.Fragment>
        {tabValue2 == "cart" ? <RenderCartPanel /> : null}

        {tabValue2 == "options" ? <View>
          <Section>
            <HeaderText smallHeader label="Contract Details:" description="One click purchasing and execution." />
            <TextLine bold label="Contract Total:" value={money(total)} />
            <TextLine bold label="Contract Length:" value={plural(totalMonths, "month")} />
            {paymentTermType == "monthly-equal" ? <TextLine bold label="Monthly:" value={money(roundMoney(total / totalMonths)) + " per month"} /> : paymentTermType == "monthly" ? monthArr.map((item, index) => {
              return <TextLine bold label={"Month " + (index + 1) + ":"} value={money(item.total)} />
            }) : null}

            <TouchButton style={{ marginTop: 25, width: "100%" }} label="Place Order" onPress={() => {
              //this will go to the review order screen with the select project option, select credit card option
              //then review goes to place order
              //place order will process all the proposal items in the cart (this will get the start/end dates from the rtn object (which it will set before processing into the objects structure))
              //then the credit card payment will be processed (add into the account payments)
              //then the related service orders for the month will be marked for execution (which will add charges for each service order/staff order into the account charges page) (once charged, service order is marked accordingly)
            }} />
          </Section>

          <Section>
            <HeaderText smallHeader label="Strategy Flux:" description="An agile way to buy and execute projects, allow you proposal to be dynamic with changing needs." />

            <TextLine value="Faster Delivery:" tooltip="Select the delivery speed for you project. This will impact the cost and duration." bottom={10} />

            <TabBar
              options={utils.fluxDeliveryOptions}
              onChange={item => {
                proposal.fluxDelivery = item.value
                setState({ ...state, proposal })
              }}
              value={fluxDelivery}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Scope Commitment:" tooltip="Create flexibility by changing the committed project scope. This will allow you to change scope easily and quicker instead of committing to the entire duration of the project." bottom={10} />
            <TabBar
              options={utils.fluxScopeCommitments}
              onChange={item => {
                proposal.fluxScope = item.value
                setState({ ...state, proposal })
              }}
              value={fluxScope}
            />

            {paymentTermTypes.length > 0 ? <View>
              <View style={{ height: 25 }} />
              <TextLine value="Payment Type:" tooltip="Save money by paying all at once." bottom={10} />
              <TabBar
                options={paymentTermTypes}
                onChange={item => {
                  proposal.paymentTermType = item.value
                  setState({ ...state, proposal })
                }}
                value={paymentTermType}
              />
            </View> : null}
          </Section>
        </View> : null}
      </React.Fragment>
    );
  };

  const render = function ({ proposalItem }) {
    const { itemType } = proposalItem

    if (itemType == "service") {
      return (
        <RenderProposalServiceBuyer2
          rtn={rtn}
          proposal={proposal}
          proposalItem={proposalItem}
          onEdit={() => {
            setState({ ...state, editItem: proposalItem });
            history.push(`${url}/service-item/${proposalItem.id}`);
          }}
          onPress={() => {
            setState({ ...state, serviceView: proposalItem });
          }}
          onUpdate={() => {
            setState({ ...state });
          }}
          onRefresh={() => {
            setState({ ...state });
          }}
        />
      );
    } else if (itemType == "staff") {
      return (
        <RenderProposalStaff2
          rtn={rtn}
          proposal={proposal}
          proposalItem={proposalItem}
          onPress={() => {
            setState({ ...state, serviceStaffView: proposalItem });
          }}
          onUpdate={() => {
            setState({ ...state });
          }}
          onRefresh={() => {
            setState({ ...state });
          }}
        />
      );
    }
  };

  const saveProposalItem = function ({ isNew, item }) {
    return dL.saveProposalItem({ isNew, item })
  };

  var proposalItemsList
  var cartItems = proposalItems.filter(item => item.inCart)
  var laterItems = proposalItems.filter(item => !item.inCart)
  var rtn
  if (tabValue3 == "cart") {
    proposalItemsList = cartItems
    rtn = getMainProposalModel2({ proposal, proposalItems: cartItems })
  } else if (tabValue3 == "later") {
    proposalItemsList = laterItems
    rtn = getMainProposalModel2({ proposal, proposalItems })
  }
  var finalTabValue3 = tabValue3
  if (laterItems.length == 0) {
    finalTabValue3 = "cart"
  }
  const totalDays = rtn.getDeliveryDays()
  const items3 = rtn.getMicroServices()

  const { weekArr, roleArr, workerArr, workers } = getTimelineBasicData(items3)

  const monthArr = []

  if (weekArr.length > 0) {
    var dt = Moment(weekArr[0].date)
    for (var i = 0; i < weekArr.length; i = i + 4) {
      var month = {
        date: dt.toDate(),
        total: 0
      }
      var total = 0
      for (var j = 0; j < 4; j++) {
        if (i + j < weekArr.length) {
          month.total += getTotalTimeline(weekArr[i + j].items)
        }
      }
      monthArr.push(month)
    }
  }

  var total = 0
  var profit = 0
  const totalMonths = Math.ceil(weekArr.length / 4)

  cartItems.forEach(proposalItem => {
    const { id, quantity, total: pTotal, priceMap } = proposalItem
    total += pTotal
    /*
        if (priceMap) {
          priceMap.forEach(mapItem => {
            const { item } = mapItem
            total += item.unitPrice * quantity
            profit += (item.unitPrice - item.oldUnitPrice) * quantity
          })
        } else {
          total += pTotal
        }
        */
  })

  return (
    <Switch>
      <Route path={`${url}/staff-item/:recordId?`}>
        <EditProposalStaff
          rtn={rtn}
          proposal={proposal}
          proposalItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              editItem.proposal = proposal;
              //editItem.parentProposalItem = parentItem;
              proposalItems.push(editItem)
            } else {
              const index = proposalItems.findIndex(item => item.id == editItem.id)
              proposalItems[index] = editItem
            }

            return saveProposalItem({ isNew, item: editItem }).then(function () {
              setState({ ...state, isNew: false, proposal });
              history.goBack();
            });
          }}
        />
      </Route>
      <Route path={`${url}/service-item/:recordId?`}>
        <EditProposalServiceBuyer
          rtn={rtn}
          proposal={proposal}
          proposalItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              editItem.proposal = proposal;
              //editItem.parentProposalItem = parentItem;
              proposalItems.push(editItem)
            } else {
              const index = proposalItems.findIndex(item => item.id == editItem.id)
              proposalItems[index] = editItem
            }

            return saveProposalItem({ isNew, item: editItem }).then(function () {
              history.goBack();
              setState({ ...state, isNew: false, proposal });
            }).catch(function (err) {
              alert("Error:" + err);
            });
          }}
        />
      </Route>
      <Route>
        <ChatWindow chatRooms={chatRooms}
          tabPanel={renderPanel()}
          onTabChange={item => {
            setState({ ...state, tabValue2: item.value });
          }}
          tabOptions={tabValue == "items" || tabValue == "timeline" ? [{ label: "Purchase Options", value: "options" }, { label: "Chat", value: "chat" }] : [{ label: "Chat", value: "chat" }]}
          tabValue={tabValue2}>
          <BasicTop title={title} />

          <TabBar
            queryId="tb"
            style={{ marginBottom: 20 }}
            options={tabs}
            onChange={item => {
              setState({ ...state, tabValue: item.value });
            }}
            value={finalTabValue3}
          />

          {tabValue == "timeline" ?
            <RenderVisualTimeline items={items3} deliveryType={"standard"} totalDays={totalDays} />
            : null}

          {tabValue == "shared" ? (
            <Section>
              <HeaderText subHeader label="Share Cart with Others:" description="Share this service cart with others." buttonText="Share" onButtonPress={buyerSharedWith.length > 0 ? () => {
                setState({ ...state, addUserVisible: true })
              } : null} />

              {buyerSharedWith.length == 0 ? <NoRecordsBox label="Not shared with any team members.">
                <TouchButton
                  onPress={() => {
                    setState({ ...state, addUserVisible: true });
                  }}
                  label="Share"
                />
              </NoRecordsBox> : buyerSharedWith.map((item, index) => {
                const { userId, email, id } = item

                return <BoxItem key={id} onDelete={() => {
                  buyerSharedWith.slice(index, 1)
                  setState({ ...state })
                  return db.getObj("ServiceProposal")
                    .set("buyerSharedWith", buyerSharedWith)
                    .save();
                }}>
                  {userId ? <UserItem userId={userId} /> : <TextLine value={email} />}
                </BoxItem>
              })}

              {addUserVisible ? (
                <InviteMember
                  proposalId={proposalId}
                  onAddEmail={({ email }) => {
                    const item = {
                      id: utils.guid(),
                      email
                    }
                    buyerSharedWith.push(item)
                    setState({ ...state })

                    db.getObj("ServiceProposal")
                      .set("buyerSharedWith", buyerSharedWith)
                      .save();
                  }}
                  onAddUser={user => {
                    const { id } = user;

                    const item = {
                      id: utils.guid(),
                      userId: id
                    }
                    buyerSharedWith.push(item)
                    setState({ ...state })

                    db.getObj("ServiceProposal")
                      .set("buyerSharedWith", buyerSharedWith)
                      .save();
                  }}
                  onCancel={() => {
                    setState({ ...state, addUserVisible: false });
                  }}
                  onDone={() => {
                    setState({ ...state, addUserVisible: false });
                  }}
                />
              ) : null}
            </Section>
          ) : null}

          {tabValue == "items" ? (
            <Section>
              <HeaderText subHeader label="Cart Items:" description="See your add cart items. Select purchase options and then start executing services." rightRender={
                <TabBar
                  options={[{ label: `Items in Cart (${cartItems.length})`, value: "cart" }, laterItems.length > 0 ? { label: `Save for Later (${laterItems.length})`, value: "later" } : null]}
                  onChange={item => {
                    setState({ ...state, tabValue3: item.value })
                  }}
                  value={tabValue3}
                />
              } />

              {proposalItemsList.length == 0 ?
                <NoRecordsBox label="Not cart items have been added.">
                  <FlexRow>
                    <TouchButton
                      style={{ marginRight: 10 }}
                      onPress={() => {
                        history.push("/user/services");
                      }}
                      micro
                      description="Add a service to this cart."
                      label="Add Service"
                    />

                    <TouchButton
                      onPress={() => {
                        history.push("/user/people");
                      }}
                      micro
                      description="Add a staff role or consultant to this cart."
                      label="Add Staff"
                    />
                  </FlexRow>
                </NoRecordsBox>
                : proposalItemsList.map(proposalItem => {
                  return render({ proposalItem })
                })
              }
            </Section>
          ) : null}
        </ChatWindow>
      </Route>
    </Switch>
  );
}

function RenderProposalServiceBuyer({ thisVersion, onRefresh, recommended, deleted, historicalProposalVersion, disableCheck, allowAdjustQty, proposal, proposalItem, onChecked, onEdit, onDelete, onPress, checked, onUpdate, serviceOrders, onUnDelete, onUpdateOverrides }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { visibleConfig, addItem } = state;

  const rootProposalVersion = thisVersion
  const { status, expireDate } = proposal;
  const isClosed = status != "open" || new Date() >= expireDate;

  const path = `p-item[${proposalItem.id}]`

  const { buyerPriceMap, buyerMasterOverrides: masterOverrides } = proposal
  const { priceMap: pPriceMap } = rootProposalVersion
  const priceMap = buyerPriceMap ? buyerPriceMap : pPriceMap
  var mapItem = priceMap.find(item => item.path == path)
  if (buyerPriceMap && !mapItem) {
    mapItem = pPriceMap.find(item => item.path == path)
  }
  const { inCart, id, servicePackage, delayStart, assignedTo, requiredPreServices, limitedToPackages, limitedToOptions, proposalVersion } = proposalItem;
  const overrides = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")
  const { actualServiceVersion } = overrides ? overrides : proposalItem

  const { isOverride } = mapItem
  const { quantity, serviceOptionIds, deliveryType, isRemoved, actualServiceVersionId, packageId } = mapItem.item

  const { service, name, userService, options } = actualServiceVersion
  const serviceOptions = options.filter((item) => serviceOptionIds[item.option.id]);

  const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

  const circularIds = {}
  circularIds[service.id] = true

  const purchased = serviceOrders ? serviceOrders.filter(item => item.proposalItem.id == proposalItem.id) : null

  const rtn = getMainServiceModel({
    configData: overrides ? overrides : proposalItem
  })

  const deliveryDays = rtn.getTotalDeliveryDays()
  const unitPrice = rtn.getTotalPrice()
  const startDate = rtn.getStartDate()
  const doneDate = rtn.getDoneDate()
  const total = quantity * unitPrice

  const doReset = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    if (record) {
      const index = masterOverrides.findIndex(item => item.id == record.id)
      masterOverrides.splice(index, 1)
      dL.removeMasterOverrideRecordPB({ recordId: record.id, proposalId: proposal.id })
    }

    onUpdateOverrides()
    setState({ ...state, visibleConfig: null })
  }

  const getOverrideRecord = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    const isNew = !record
    if (isNew) {
      record = createNewOverrideRecord()
      record._isNew = true
    }

    return record
  }

  const getCopyObj = function () {
    const rtn = Object.assign({}, proposalItem)
    delete rtn.id
    rtn.delayOptions = Object.assign({}, rtn.delayOptions)
    return rtn
  }

  const createNewOverrideRecord = function () {
    const record = getCopyObj()
    record.id = utils.guid()
    record.rootProposal = rootProposalVersion.proposal
    record.rootProposalVersion = rootProposalVersion
    record.proposalItem = proposalItem
    record.path = path
    record.overrideType = "sub-service"
    record.isBuyerOverride = true
    masterOverrides.push(record)
    return record
  }

  const saveOverrideRecord = function ({ record }) {
    dL.saveMasterOverrideRecord({ isNew: record._isNew, recordId: record.id, data: record })
    onUpdateOverrides()
  }

  const render21 = function () {
    return <FlexRow>
      {isRemoved ? <TextLine size={12} color="red" bold value="Service Removed" spacer /> : null}

      {!historicalProposalVersion ?
        <SelectOptions
          options={[!isRemoved ? {
            label: "Edit Recommendation", value: "config"
          } : null, !isRemoved && isOverride ? {
            label: "Remove Overrides", value: "remove-overides"
          } : null]}
          onChange={selItem => {
            const { value } = selItem
            if (value == "config") {
              setState({ ...state, visibleConfig: true })
            } else if (value == "remove") {
              const record = getOverrideRecord()

              record.isRemoved = true

              saveOverrideRecord({ record })

              setState({ ...state, visibleConfig: null })
            } else if (value == "add") {
              doReset()
            } else if (value == "remove-overides") {
              doReset()
            }
          }}>
          <TextLine size={12} value="Edit Recommendations" underline />
        </SelectOptions>
        : null}

      {isOverride && !isRemoved ? <TextLine left={8} size={12} value="(has overrides)" /> : null}
    </FlexRow>
  }

  return (
    <RenderRow key={id} onEdit={onEdit} onDelete={onDelete} onUnDelete={onUnDelete}>
      <BoxItem render={<View>
        {subServices.length > 0 ? <View top={15}>
          <ItemExpandSection label={plural(subServices.length, "Sub Service") + ":"} >
            {subServices.map(subServiceVersion => {
              return <DisplaySubServiceItem
                key={id}
                circularIds={circularIds}
                rootProposalVersion={thisVersion}
                historicalProposalVersion={historicalProposalVersion}
                onUpdateOverrides={onUpdateOverrides}
                subServiceVersion={subServiceVersion}
                path={path} />
            })}
          </ItemExpandSection>
        </View> : null}
      </View>}>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine strikeThrough={isRemoved} onPress={onPress} strike={deleted} onPress={onPress} bold size={22} value={name} />
            {servicePackage ? <TextLine color="grey" size={16} label="Package:" value={servicePackage.name} /> : null}

            {delayStart && startDate ? <TextLine top={4} size={14} value={"Start: " + Moment(startDate).format("M/D/YYYY") + " (" + Moment(startDate).fromNow() + ")"} /> : null}

            <TextLine top={4} size={14} value={(deliveryType == "fast" ? "FAST " : "") + "Delivery: " + Moment(doneDate).format("M/D/YYYY") + " (" + plural(Moment(doneDate).diff(Moment().startOf("day"), "days"), "day", "days") + ")"} />

            {serviceOptions && serviceOptions.length > 0 ? (
              <View style={{ marginLeft: 15, marginTop: 8 }}>
                {serviceOptions.map(item => {
                  const { name } = item;
                  return <TextLine size={16} value={"+ " + name} />;
                })}
              </View>
            ) : null}

            {assignedTo ? <UserItem user={assignedTo} label="Consultant:" style={{ marginTop: 15 }} /> : null}

            <View style={{ height: 12 }} />
            <RenderPreReq proposal={proposal} services={requiredPreServices} />
          </FlexExpand>

          <Quantity
            value={quantity}
            allowAdjust={allowAdjustQty}
            onUpdate={(value) => {
              const record = getOverrideRecord()

              record.quantity = value
              record.total = unitPrice * value

              saveOverrideRecord({ record })
              onUpdateOverrides()
            }}
          />

          <View style={{ width: 115, display: "flex", flexDirection: "column", alignItems: "flex-end", alignSelf: "stretch", marginBottom: 0 }}>
            <FlexRow style={{ alignItems: "flex-end" }}>
              <Price strikeThrough={isRemoved} price={total} days={deliveryDays} size={18} />
            </FlexRow>

            <View style={{ flex: 1 }} />

            <ProposalCheckmark disabled={disableCheck} checked={checked} onChecked={onChecked} tooltip="Select this service for purchase." />
          </View>
        </FlexRow>

        <RenderLimitedPackages packages={thisVersion.packages} value={limitedToPackages} />
        <RenderLimitedOptions options={thisVersion.options} value={limitedToOptions} />

        <FlexRow top={15}>
          <FlexExpand>
            <FlexRow>
              {proposalVersion.versionNum == thisVersion.versionNum && thisVersion.versionNum != 1 ? <TextLine right={15} bold size={12} color="blue" value="NEW" /> : null}

              {recommended ? <TextLine right={15} bold size={12} color="red" value="Recommended" /> : null}

              {render21()}
            </FlexRow>
          </FlexExpand>

          {!isRemoved && !isClosed && !historicalProposalVersion ? (
            inCart ? (
              <TouchButton
                onPress={() => {
                  proposalItem.inCart = false
                  dL.saveProposalItem({ item: proposalItem })
                  setState({ ...state });
                  onRefresh();
                }}
                micro
                grey
                description="Remove this items from the cart."
                label="Remove from Cart"
              />
            ) : (
              <TouchButton
                onPress={() => {
                  setState({ ...state, addItem: true });
                }}
                micro
                description="Add this service to the cart."
                label="Add to Cart"
              />
            )
          ) : null}
        </FlexRow>

        {addItem ? (
          <RenderAddToCartButtons
            thisVersion={thisVersion}
            proposal={proposal}
            proposalItem={proposalItem}
            onAddToCart={(newRecord) => {
              const record = getOverrideRecord()
              Object.assign(record, newRecord)
              record.isRemoved = false
              saveOverrideRecord({ record })

              proposalItem.inCart = true
              dL.saveProposalItem({ item: proposalItem })

              onChecked(false);
              setState({ ...state, addItem: false });
            }}
            onClose={() => {
              setState({ ...state, addItem: false });
            }}
            onUpdate={() => {
              onUpdate();
            }}
          />
        ) : null}

        {purchased && purchased.length > 0 ? (
          <View style={{ marginTop: 25 }}>
            <TextLine bold>Purchased:</TextLine>
            {purchased.map(serviceOrder => {
              const { orderNumber, orderDate, total, id, status } = serviceOrder;
              return (
                <BoxItem
                  name={"Ref #: " + orderNumber}
                  onPress={() => {
                    history.push(`/${sectionId}/service-order/${id}`);
                  }} rightRender={<Price price={total} />}>
                  {Moment(orderDate).format("M/D/YY")} {status}
                </BoxItem>
              );
            })}
          </View>
        ) : null}
      </BoxItem>


      {visibleConfig ? <EditServiceSubServiceConfigBuyer
        rtn={rtn}
        rootServiceVersion={rootProposalVersion}
        path={path}
        editItem={getCopyObj()}
        onCancel={() => {
          setState({ ...state, visibleConfig: null })
        }}
        hasOverride={isOverride}
        onReset={() => {
          doReset()
        }}
        onSave={(newRecord) => {
          const record = getOverrideRecord()
          Object.assign(record, newRecord)
          record.isRemoved = false
          saveOverrideRecord({ record })

          setState({ ...state, visibleConfig: null })
        }} /> : null}
    </RenderRow>
  );
}

function RenderRow2({ children, readonly, options, onSelectOption }) {
  return (
    <FlexRow style={{ position: "relative", paddingRight: 35 }}>
      <FlexExpand>{children}</FlexExpand>

      {!readonly ? (
        <View style={{ position: "absolute", right: 0, top: 15 }}>
          <SelectOptions options={options} onChange={onSelectOption}>
            <FontAwesomeIcon icon={faCog} style={{ fontSize: 18, color: "#c0c0c0" }} />
          </SelectOptions>
        </View>
      ) : null}
    </FlexRow>
  );
}

function DisplaySubServiceItem2({ proposal, proposalItem, circularIds: startC1, subServiceVersion: d1, path: startPath, onUpdateOverrides, rtn: rtn1 }) {
  const [state, setState] = useState({ isLoading: true, rtn: rtn1 });
  const { rtn, subServiceVersion, isLoading, visibleConfig, subServiceVisible, actualServiceVersion } = state;

  const { subService } = d1
  const path = (startPath ? startPath + "." : "") + "service[" + subService.id + "]"
  const pathSS = "service[" + subService.id + "]"
  const { buyerMasterOverrides: masterOverrides, priceMap } = proposal

  const overrideRecord = masterOverrides.find(item => {
    return (item.proposalItem && item.proposalItem.id == proposalItem.id) && item.path == path
  })

  const configData = overrideRecord ? overrideRecord : d1

  useEffect(() => {
    const { actualServiceVersion } = configData
    dL.getSubServiceVersion({ subServiceVersionId: d1.id }).then(function (subServiceVersion) {
      return dL.getServiceVersion4({ serviceVersionId: actualServiceVersion.id }).then(function (actualServiceVersion) {
        setState({ ...state, actualServiceVersion, subServiceVersion, isLoading: false })
      })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const isOverride = overrideRecord != null
  const { deliveryType, overridePrice, isRemoved, actualVersionType, serviceId, packageId, serviceOptionIds } = configData
  const { quantity } = proposalItem
  const isCircularRef = startC1[serviceId] != null
  const circularIds = { ...startC1 }
  circularIds[serviceId] = true

  const { name, icon, shortDescription, service, userService } = actualServiceVersion
  const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

  const getPriceDays = function ({ serviceVersion }) {
    const itemG = rtn.setOverride({ path, masterOverride: { ...configData, actualServiceVersion: serviceVersion } })
    const days = itemG.getTotalDeliveryDays()
    const total = itemG.getTotalPrice()
    return { total, days }
  }

  const itemG = rtn.getItem(path)
  const deliveryDays = itemG.getTotalDeliveryDays()
  const unitPrice = itemG.getTotalPrice()
  const startDate = itemG.getStartDate()
  const doneDate = itemG.getDoneDate()
  const total = quantity * unitPrice

  const doReset = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    if (record) {
      const index = masterOverrides.findIndex(item => item.id == record.id)
      masterOverrides.splice(index, 1)
      dL.removeMasterOverrideRecordPB({ recordId: record.id, proposalId: proposal.id })
    }

    //onUpdateOverrides()
    setState({ ...state, visibleConfig: null })
  }

  const getOverrideRecord = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    const isNew = !record
    if (isNew) {
      record = createNewOverrideRecord()
      record._isNew = true
    }

    return record
  }

  const getCopyObj = function () {
    const rtn = Object.assign({}, proposalItem)
    delete rtn.id
    rtn.delayOptions = Object.assign({}, rtn.delayOptions)
    return rtn
  }

  const createNewOverrideRecord = function () {
    const record = getCopyObj()
    record.id = utils.guid()
    record.rootProposal = proposal
    record.proposalItem = proposalItem
    record.path = path
    record.overrideType = "sub-service"
    record.isBuyerOverride = true
    record.isBuyerSide = true
    masterOverrides.push(record)
    return record
  }

  const saveOverrideRecord = function ({ record }) {
    dL.saveMasterOverrideRecord({ isNew: record._isNew, recordId: record.id, data: record })
    //onUpdateOverrides()
  }

  return <React.Fragment>
    {isCircularRef ? <TextLine color="red" size={14} bold value="The below service is a CIRCULAR REFERENCE: It will removed automatically." /> : null}

    <BoxItem strikeThrough={isCircularRef || isRemoved} name={name} icon={icon} description={shortDescription} onPress={() => {
      setState({ ...state, subServiceVisible: true });
    }} rightRender={
      !isCircularRef ? <Price strikeThrough={isRemoved} price={total} days={deliveryDays} hasOverride={overridePrice != null} /> : null
    } render={
      !isCircularRef ?
        <View>
          {!isRemoved && actualVersionType == "worker" ?
            <RenderWorkConfig subServiceVersion={subServiceVersion} />
            : null}

          <FlexRow>
            {isRemoved ? <TextLine top={10} size={12} color="red" bold value="Service Removed" spacer /> : null}

            {!isRemoved && userService && !userService.isWorkerPublic ? <TextLine top={10} size={12} value="Private Service (not configurable)" spacer /> : null}

            <SelectOptions
              options={[!isRemoved && (!userService || userService.isWorkerPublic) ? {
                label: "Edit Overrides", value: "config"
              } : null, !isRemoved && isOverride ? {
                label: "Remove Overrides", value: "remove-overides"
              } : null, !isRemoved ? {
                label: "Remove Service", value: "remove"
              } : {
                label: "Add Service Back", value: "add"
              }]}
              onChange={selItem => {
                const { value } = selItem
                if (value == "config") {
                  setState({ ...state, visibleConfig: true })
                } else if (value == "remove") {
                  const record = getOverrideRecord()

                  record.isRemoved = true

                  saveOverrideRecord({ record })

                  setState({ ...state, visibleConfig: null })
                } else if (value == "add") {
                  doReset()
                } else if (value == "remove-overides") {
                  doReset()
                }
              }}>
              <View style={{ marginTop: 10, marginRight: 10 }}>
                <TextLine size={12} value="Edit Configuration" underline />
              </View>
            </SelectOptions>

            {isOverride && !isRemoved ? <TextLine top={10} size={12} value="(has overrides)" /> : null}
          </FlexRow>

          {!isRemoved && subServices.length > 0 ?
            <View top={15}>
              <ItemExpandSection label={plural(subServices.length, "Sub-Service") + ":"}>
                {subServices.map(subServiceVersion => {
                  const { id } = subServiceVersion

                  return <DisplaySubServiceItem2
                    key={id}
                    proposal={proposal}
                    proposalItem={proposalItem}
                    rtn={rtn}
                    circularIds={{ ...circularIds }}
                    onUpdateOverrides={onUpdateOverrides}
                    subServiceVersion={subServiceVersion}
                    path={path} />
                })}
              </ItemExpandSection>
            </View>
            : null}
        </View> : null
    }>
      <RenderStartDoneDates deliveryType={deliveryType} startDate={startDate} doneDate={doneDate} />

      {!isCircularRef && !isRemoved ?
        <RenderServiceNeedsUpdate
          style={{ marginTop: 10 }}
          configData={configData}
          usingVersion={actualServiceVersion}
          currentVersion={actualVersionType == "worker" ? userService.currentVersion : service.currentVersion}
          price={total}
          days={deliveryDays}
          onGetVersionPriceDays={(serviceVersion) => {
            return getPriceDays({ serviceVersion })
          }}
          onUpdateNewest={() => {
            setState({ ...state, visibleConfig: true })
          }}
        /> : null}
    </BoxItem>

    {subServiceVisible ? (
      <ViewSubService
        hidePrice={true}
        item={subServiceVersion}
        onClose={() => {
          setState({ ...state, subServiceVisible: null });
        }}
      />
    ) : null}

    {visibleConfig ? <EditServiceSubServiceConfig
      rtn={rtn}
      path={path}
      editItem={getCopyObj()}
      onCancel={() => {
        setState({ ...state, visibleConfig: null })
      }}
      hasOverride={isOverride}
      onReset={() => {
        doReset()
      }}
      onSave={(newRecord) => {
        const record = getOverrideRecord()

        Object.assign(record, newRecord)
        record.isRemoved = false
        saveOverrideRecord({ record })

        setState({ ...state, visibleConfig: null })
      }} /> : null}
  </React.Fragment>
}

function RenderProposalServiceBuyer2({ rtn, onRefresh, proposal, proposalItem, onEdit, onDelete, onPress, checked, onUpdate, onUpdateOverrides }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { visibleConfig, addItem } = state;

  const path = `p-item[${proposalItem.id}]`

  const { proposalItems, buyerPriceMap: priceMap, buyerMasterOverrides: masterOverrides } = proposal

  const { inCart, id, servicePackage, delayStart, assignedTo, requiredPreServices, limitedToPackages, limitedToOptions, proposalVersion } = proposalItem;
  const overrides = masterOverrides.find(item => item.path == path && item.proposalItem.id == proposalItem.id)
  const dataItem = overrides ? overrides : proposalItem
  const { actualServiceVersion } = dataItem

  const isOverride = overrides != null
  const { quantity, serviceOptionIds, deliveryType, actualServiceVersionId, packageId } = dataItem

  const { service, name, userService, options } = actualServiceVersion
  const serviceOptions = options.filter((item) => serviceOptionIds[item.option.id]);

  const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

  const circularIds = {}
  circularIds[service.id] = true

  const itemG = rtn.getItemPP({ proposalItemId: proposalItem.id })
  const deliveryDays = itemG.getTotalDeliveryDays()
  const unitPrice = itemG.getTotalPrice()
  const startDate = itemG.getStartDate()
  const doneDate = itemG.getDoneDate()
  const total = quantity * unitPrice

  const buttons = []
  buttons.push({
    label: "Edit", onPress: () => {
      onEdit()
    }
  })

  buttons.push({
    label: "Delete", onPress: () => {
      const index = proposalItems.find(item => item.id == proposalItem.id)
      proposalItems.splice(index, 1)
      setState({ ...state })
      onUpdate()
      return deleteItem({ proposalItem })
    }
  })

  if (inCart) {
    buttons.push({
      label: "Save for later", onPress: () => {
        proposalItem.inCart = false
        setState({ ...state })
        onUpdate()
        return saveForLater({ proposalItem })
      }
    })
  } else {
    buttons.push({
      label: "Add to Cart", onPress: () => {
        proposalItem.inCart = true
        onUpdate()
        return addBackToCart({ proposalItem })
      }
    })
  }

  return (
    <RenderRow2 key={id} options={buttons} >
      <BoxItem render={<View>
        {subServices.length > 0 ? <View top={15}>
          <ItemExpandSection label={plural(subServices.length, "Sub Service") + ":"} >
            {subServices.map(subServiceVersion => {
              return <DisplaySubServiceItem2
                proposal={proposal}
                rtn={rtn}
                key={id}
                proposalItem={proposalItem}
                circularIds={circularIds}
                subServiceVersion={subServiceVersion}
                path={path}
              />
            })}
          </ItemExpandSection>
        </View> : null}
      </View>}>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine onPress={onPress} onPress={onPress} bold size={22} value={name} />
            {servicePackage ? <TextLine color="grey" size={16} label="Package:" value={servicePackage.name} /> : null}

            {delayStart && startDate ? <TextLine top={4} size={14} value={"Start: " + Moment(startDate).format("M/D/YYYY") + " (" + Moment(startDate).fromNow() + ")"} /> : null}

            <TextLine top={4} size={14} value={(deliveryType == "fast" ? "FAST " : "") + "Delivery: " + Moment(doneDate).format("M/D/YYYY") + " (" + plural(Moment(doneDate).diff(Moment().startOf("day"), "days"), "day", "days") + ")"} />

            {serviceOptions && serviceOptions.length > 0 ? (
              <View style={{ marginLeft: 15, marginTop: 8 }}>
                {serviceOptions.map(item => {
                  const { name } = item;
                  return <TextLine size={16} value={"+ " + name} />;
                })}
              </View>
            ) : null}

            {assignedTo ? <UserItem user={assignedTo} label="Consultant:" style={{ marginTop: 15 }} /> : null}

            <View style={{ height: 12 }} />
            <RenderPreReq proposal={proposal} services={requiredPreServices} />
          </FlexExpand>

          <Quantity
            value={quantity}
            allowAdjust={false}
          />

          <View style={{ width: 115, display: "flex", flexDirection: "column", alignItems: "flex-end", alignSelf: "stretch", marginBottom: 0 }}>
            <FlexRow style={{ alignItems: "flex-end" }}>
              <Price price={total} days={deliveryDays} size={18} />
            </FlexRow>
          </View>
        </FlexRow>
      </BoxItem>
    </RenderRow2>
  );
}

function RenderProposalStaff2({ rtn, proposal, checked, proposalItem, onEdit, onDelete, onPress, onUpdate, onUpdateOverrides }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { addItem } = state;

  //const { status, expireDate } = proposal;
  //const isClosed = status != "open" || new Date() >= expireDate;

  const { recommendedUser, staffServiceType, workerUsers, id, unitPrice, businessRole, quantity, assignedTo, requiredPreServices, hoursPerMonth, limitedToPackages, limitedToOptions, skillLevel, numberOfMonths } = proposalItem;

  //const v1 = getAllItemsForProposal({ proposal, proposalVersion: thisVersion })
  //const startDate = getDoneDate(v1, proposalItem);
  //const doneDate = getDoneDate(v1, proposalItem);

  const itemG = rtn.getItemPP({ proposalItemId: proposalItem.id })
  const deliveryDays = itemG.getTotalDeliveryDays()
  const startDate = itemG.getStartDate()
  const doneDate = itemG.getDoneDate()
  const total = quantity * unitPrice

  return (
    <RenderRow2 key={id} onEdit={onEdit} onDelete={onDelete} >
      <BoxRowItem onPress={onPress}>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine onPress={onPress} bold size={22} value={businessRole.name} />

            <FlexRow>
              {staffServiceType != "staff" ? <TextLine color="grey" label="Type:" value={getLabelItem(staffServiceTypes, staffServiceType)} spacer /> : null}
              <TextLine color="grey" label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} />
            </FlexRow>

            <TextLine color="grey" top={8} size={14} label="Terms:" value={plural(hoursPerMonth, "hr") + " per month for " + plural(numberOfMonths, "month")} />

            <FlexRow>
              <TextLine color="grey" size={14} label="Start Date:" value={Moment(startDate).format("M/D/YYYY")} spacer />
              <TextLine color="grey" size={14} label="End Date:" value={Moment(doneDate).format("M/D/YYYY")} />
            </FlexRow>

            <View style={{ height: 12 }} />
            <RenderPreReq proposal={proposal} services={requiredPreServices} />

            <View style={{ height: 12 }} />

            {assignedTo ? <UserItem label="Selected Consultant:" user={assignedTo} /> : null}

            {recommendedUser && recommendedUser.id != assignedTo.id ? <UserItem label="Recommended Consultant:" user={recommendedUser} /> : null}

            {workerUsers && workerUsers.length > 0 ? <TextLine color="grey" size={12} value={plural(workerUsers.length + (recommendedUser.id == assignedTo.id ? 0 : 1), "other consultant") + " recommended"} top={8} /> : null}
          </FlexExpand>

          <Quantity
            value={quantity}
            allowAdjust={false}
          />

          <View style={{ width: 115, display: "flex", flexDirection: "column", alignItems: "flex-end", alignSelf: "stretch", marginBottom: 0 }}>
            <Price price={total} size={18} />
          </View>
        </FlexRow>
      </BoxRowItem>
    </RenderRow2>
  );
}


export function ProposalBuyerView2() {
  const { proposalId, sectionId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({ tabValue3: "cart", tabValue2: "cart", tabValue: "details", optionIds: {} });
  const { addUserVisible, tabValue3, staffOrders, tabValue2, tabValue, proposalVersions, historicalProposalVersion, serviceStaffView, serviceOrders, serviceView, packageId, optionIds, proposal, selectedStepItem, selectedProposalItem, priceDisplayType } = state;

  const updateSave = function (userSelected) {
    setState({ ...state, packageId: null, optionIds: {} });

    updateTotals(thisVersion, proposal, userSelected);

    const { finalTotal, finalDeliveryDays } = proposal;

    return db
      .getObj("ServiceProposal", proposalId)
      .set("userSelected", JSON.stringify(userSelected))
      .set("total", finalTotal)
      .set("deliveryDays", finalDeliveryDays)
      .save()
      .catch(function (err) {
        alert("Error:" + err);
      });
  };

  useEffect(() => {
    dL.getServiceProposal({ proposalId })
      .then(function ({ proposal, serviceOrders, staffOrders }) {
        const { paymentType, allItems, lastSentProposalVersion } = proposal;

        if (!paymentType) {
          proposal.paymentType = "monthly"
        }

        /*
        var { userSelected } = proposal;
        if (!userSelected) {
          userSelected = {};
          proposal.userSelected = userSelected;

          const allItemsV = getAllItemsForVersion(lastSentProposalVersion);

          allItemsV.values().forEach(item => {
            const proposalItem = allItems.get(item.id);
            const { recommended, id } = proposalItem;
            if (recommended) {
              userSelected[id] = true;
            }
          });
        }*/

        allItems.values().forEach(proposalItem => {
          proposalItem.expanded = false;
        });

        return dL
          .getQuery("ServiceProposalVersion")
          .equalTo("status", "open")
          .equalTo("proposal", dL.getObj("ServiceProposal", proposalId))
          .containedIn("removed", [undefined, false])
          .include("sentBy")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            const proposalVersions = dL.loadObjects("ServiceProposalVersion", objs);

            setState({ ...state, proposalVersions, staffOrders, serviceOrders, proposal });
            setIsLoading(false);
          });
      })
    /*
    .catch(function (err) {
      alert("Error:" + err);
    });
    */
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const allTeam = getAllTeam(proposal);

  const getPrice = function ({ packageId, optionIds }) {
    const c1 = function (item) {
      const { limitedToOptions, limitedToPackages } = item;
      return canDisplay({ limitedToOptions, limitedToPackages, packageId, serviceOptionIds: optionIds });
    };

    var finalTotal = 0;

    utils.getDeleteRemovedItems(thisVersion, thisVersion.items).forEach(item => {
      const { id } = item;
      const proposalItem = allItems.get(id);
      const { itemType } = proposalItem;

      if (itemType == "service") {
        const { total } = proposalItem;
        if (c1(proposalItem)) {
          finalTotal += total;
        }
      } else if (itemType == "staff") {
        const { total } = proposalItem;
        if (c1(proposalItem)) {
          finalTotal += total;
        }
      } else if (itemType == "stage") {
        if (c1(proposalItem)) {
          const items = getDeleteRemovedItems(thisVersion, item.items);

          items.forEach(item => {
            const { id } = item;
            const items = getDeleteRemovedItems(thisVersion, item.items);
            const stageItem = allItems.get(id);

            if (c1(stageItem)) {
              items.forEach(item => {
                const { id } = item;
                const serviceItem = allItems.get(id);
                const { total } = serviceItem;
                if (c1(serviceItem)) {
                  finalTotal += total;
                }
              });
            }
          });
        }
      }
    });

    return finalTotal;
  };

  const getServiceItems = function ({ packageId, optionIds }) {
    const c1 = function (item) {
      const { limitedToOptions, limitedToPackages } = item;
      return canDisplay({ limitedToOptions, limitedToPackages, packageId, serviceOptionIds: optionIds });
    };

    const arr = [];

    getDeleteRemovedItems(thisVersion, thisVersion.items).forEach(item => {
      const { id, items } = item;
      const proposalItem = allItems.get(id);
      const { itemType } = proposalItem;

      if (itemType == "service") {
        if (c1(proposalItem)) {
          arr.push(proposalItem);
        }
      } else if (itemType == "staff") {
        if (c1(proposalItem)) {
          arr.push(proposalItem);
        }
      } else if (itemType == "stage") {
        if (c1(proposalItem)) {
          getDeleteRemovedItems(thisVersion, items).forEach(item => {
            const { id, items } = item;
            const stageItem = allItems.get(id);

            if (c1(stageItem)) {
              getDeleteRemovedItems(thisVersion, items).forEach(item => {
                const { id } = item;
                const serviceItem = allItems.get(id);
                if (c1(serviceItem)) {
                  arr.push(serviceItem);
                }
              });
            }
          });
        }
      }
    });

    return arr;
  };

  const setChecks = function ({ packageId, optionIds }) {
    const c1 = function (item) {
      const { limitedToOptions, limitedToPackages } = item;
      return canDisplay({ limitedToOptions, limitedToPackages, packageId, serviceOptionIds: optionIds });
    };

    const data = { ...userSelected };

    thisVersion.items.forEach(item => {
      const { items } = item;
      const proposalItem = allItems.get(item.id);
      const { itemType } = proposalItem;

      if (itemType == "service" || itemType == "staff") {
        data[id] = c1(proposalItem);
      } else if (itemType == "stage") {
        const { items } = proposalItem;

        if (c1(proposalItem)) {
          items.forEach(item => {
            const { items } = item;
            const stageItem = allItems.get(item.id);

            if (c1(stageItem)) {
              items.forEach(item => {
                const serviceItem = allItems.get(item.id);
                const { id } = serviceItem;
                data[id] = c1(serviceItem);
              });
            } else {
              items.forEach(item => {
                const serviceItem = allItems.get(item.id);
                const { id } = serviceItem;
                data[id] = false;
              });
            }
          });
        } else {
          items.forEach(item => {
            const { items } = item;
            const stageItem = allItems.get(item.id);
            const { id } = stageItem;

            items.forEach(item => {
              const serviceItem = allItems.get(item.id);
              const { id } = serviceItem;
              data[id] = false;
            });
          });
        }
      }
    });

    proposal.userSelected = data;
    updateSave(data);
  };

  const { paymentType, buyerSharedWith, buyerPriceMap, fluxDelivery, fluxScope, userSelected, allItems, lastSentProposalVersion, id, status, expireDate, packages, options, title, finalTotal, finalDeliveryDays, businessDevelopment, strategyConsultant, projectManager } = proposal;

  const rootProposalVersion = lastSentProposalVersion
  const currentPVersion = lastSentProposalVersion;

  const thisVersion = historicalProposalVersion ? historicalProposalVersion : currentPVersion;
  const versionItems = getDeleteRemovedItems(thisVersion, thisVersion.items);
  const allItemsV = getAllItemsForVersion(thisVersion);

  const isBuyerView = sectionId == "user";
  const isClosed = status != "open" || new Date() >= expireDate;
  const chatRooms = getProposalChatRoom({ proposal, isBuyerView: true });

  proposal.finalDoneDate = getDoneDateAll(getServiceItems({ packageId, optionIds }));
  updateTotals(thisVersion, proposal, userSelected);

  const tabs = [{ label: "Overview", value: "details" }, { label: "Team", value: "team" }];

  tabs.push({ label: "Shared With", value: "shared" });
  tabs.push({ label: "Strategy", value: "items" });
  tabs.push({ label: "Timeline", value: "timeline" });
  if (proposalVersions.length > 1) {
    tabs.push({ label: "Versions", value: "versions" });
  }

  const RenderStep = function ({ items, stageItem, stepItem }) {
    const { id } = stepItem;
    const selected = selectedStepItem && selectedStepItem.id == id;

    return (
      <RenderProposalStepItem
        userSelected={userSelected}
        thisVersion={thisVersion}
        items={items}
        isBuyerView={isBuyerView}
        readonly
        proposal={proposal}
        proposalItem={stepItem}
        selected={selected}
        onPress={() => {
          if (selected) {
            setState({ ...state, selectedProposalItem: null, selectedStepItem: null });
          } else {
            setState({ ...state, selectedProposalItem: stageItem, selectedStepItem: stepItem });
          }
        }}
      />
    );
  };

  const RenderItem = function (props) {
    const { item, index } = props;
    //item = getProposalItemOverridden({ proposalItem: item });

    const { recommended, itemType, id, requiredPreServices } = item;

    const selectedPreRequired = function (requiredPreServices, data, addItems) {
      if (requiredPreServices) {
        requiredPreServices.forEach(pId => {
          const item = allItems.get(pId);
          const { requiredPreServices, id } = item;
          if (!data[id]) {
            addItems.push(id);
          }
          selectedPreRequired(requiredPreServices, data);
        });
      }
    };

    const doCheck = function (value) {
      if (isClosed && !isBuyerView) {
        return;
      }
      const addItems = [];
      const data = { ...userSelected, [id]: value };
      if (value) {
        selectedPreRequired(requiredPreServices, data, addItems);
      }
      if (addItems.length > 0) {
        confirmAlert({
          title: "Alert!",
          message: plural(addItems.length, "item") + " pre-required services for this service are not selected. Do you want to selected them?",
          buttons: [
            {
              label: "Yes, select them.",
              onClick: () => {
                addItems.forEach(item => {
                  data[item.id] = true;
                });
                proposal.userSelected = data;
                updateSave(data);
              }
            },
            {
              label: "No, leave them unselected.",
              onClick: () => {
                proposal.userSelected = data;
                updateSave(data);
              }
            }
          ]
        });
      } else {
        //setUserSelected(data);
        proposal.userSelected = data;
        updateSave(data);
      }
    };

    const isChecked = userSelected[id] != null ? userSelected[id] : false;

    const cartItems = session.cart.items.filter(item => item.proposalItem && item.proposalItem.id == id);

    const render = function () {
      if (itemType == "service") {
        return (
          <RenderProposalServiceBuyer
            recommended={recommended}
            thisVersion={thisVersion}
            historicalProposalVersion={historicalProposalVersion}
            allowAdjustQty={status == "draft" || isBuyerView}
            isBuyerView={isBuyerView}
            serviceOrders={serviceOrders}
            index={index}
            hideLimitText
            proposal={proposal}
            readonly
            checked={isChecked}
            proposalItem={item}
            onChecked={value => {
              doCheck(value);
            }}
            onPress={() => {
              setState({ ...state, serviceView: item });
            }}
            onUpdate={() => {
              //saveProposalBuyerOverrides();
              setState({ ...state });
            }}
            onRefresh={() => {
              setState({ ...state });
            }}
            onUpdateOverrides={() => {
              calculatePriceMap()
              setState({ ...state })
            }}
          />
        );
      } else if (itemType == "staff") {
        return (
          <RenderProposalStaff
            thisVersion={thisVersion}
            recommended={recommended}
            historicalProposalVersion={historicalProposalVersion}
            isBuyerView={isBuyerView}
            staffOrders={staffOrders}
            index={index}
            hideLimitText
            proposal={proposal}
            readonly
            checked={isChecked}
            proposalItem={item}
            onChecked={value => {
              doCheck(value);
            }}
            onPress={() => {
              setState({ ...state, serviceStaffView: item });
            }}
            onUpdate={() => {
              //saveProposalBuyerOverrides();
              setState({ ...state });
            }}
            onRefresh={() => {
              setState({ ...state });
            }}
            onUpdateOverrides={() => {
              calculatePriceMap()
              setState({ ...state })
            }}
          />
        );
      }
    };

    return (
      <React.Fragment>
        {render()}

        {cartItems.length > 0 ? (
          <View>
            <TextLine bold value="Added to Cart:" />
            {cartItems.map(cartItem => {
              const { quantity, total } = cartItem;
              return (
                <FlexRow
                  onPress={() => {
                    alert("View this cart item.");
                  }}>
                  <TextLine value={"QTY: " + quantity + " TOTAL: " + money(total)} />
                  <TrashIcon
                    style={{ marginLeft: 15 }}
                    onPress={() => {
                      utils.deleteCartItem(cartItem);
                      setState({ ...state });
                    }}
                    tooltip="Delete from cart."
                  />
                </FlexRow>
              );
            })}
          </View>
        ) : null}
      </React.Fragment>
    );
  };

  const calculatePriceMap = function () {
    const priceMap = createProposalPriceMapBuyer({ proposal, proposalVersion: currentPVersion })
    proposal.buyerPriceMap = priceMap

    return dL.getObj("ServiceProposal", proposal.id)
      .set("buyerPriceMap", priceMap)
      .save()
  }

  const optionsList = options.filter(item => canDisplay({ limitedToPackages: item.limitedToPackages, packageId }))

  const renderPanel = function () {
    const paymentTypes = [{ label: "Pay Entire", value: "entire" }, { label: "Pay Monthly", value: "monthly" }, { label: "Pay Quarterly", value: "quarterly" }, { label: "Pay Yearly", value: "yearly" }]

    return (
      <React.Fragment>
        {tabValue2 == "cart" ? <RenderCartPanel /> : null}

        {tabValue2 == "options" ? <View>
          {packages.length > 0 ? <Section>
            <HeaderText smallHeader label="Strategy Packages:" />
            {packages.map((item) => {
              const { id, name, description } = item;

              const priceChange = getPrice({ packageId: id, optionIds });
              const doneDate = getDoneDateAll(getServiceItems({ packageId: id, optionIds }));

              return (
                <BoxItem
                  hasCheck
                  checked={packageId == id}
                  onBoxPress={() => {
                    setChecks({ packageId: item.id, optionIds });
                    setState({ ...state, packageId: item.id });
                  }}
                  name={name}
                  description={description}
                  rightRender={<Price size={18} price={priceChange} days={Moment(doneDate).diff(Moment().startOf("day"), "days")} />}
                />
              );
            })}
          </Section> : null}

          {optionsList.length > 0 ? <Section>
            <HeaderText smallHeader label="Strategy Options:" />
            {optionsList.map((item) => {
              const { name, description, id } = item;

              const dt = { ...optionIds };
              const dt1 = { ...optionIds };
              const checked = optionIds[id];
              if (checked) {
                dt[id] = false;
              } else {
                dt[id] = true;
              }
              dt1[id] = true;
              const priceChange = getPrice({ packageId, optionIds: dt });
              const doneDate = getDoneDateAll(getServiceItems({ packageId, optionIds: dt1 }));

              return (
                <BoxItem
                  onBoxPress={() => {
                    if (!checked) {
                      optionIds[id] = true;
                    } else {
                      delete optionIds[id];
                    }
                    setChecks({ packageId, optionIds });
                    setState({ ...state, optionIds });
                  }}
                  hasCheck
                  checked={checked}
                  name={name}
                  description={description}
                  rightRender={<Price size={18} price={priceChange} days={Moment(doneDate).diff(Moment().startOf("day"), "days")} />}
                />
              );
            })}
          </Section> : null}

          <Section>
            <HeaderText smallHeader label="Strategy Flux:" description="An agile way to buy and execute projects, allow you proposal to be dynamic with changing needs." />

            <TextLine value="Faster Delivery:" tooltip="Select the delivery speed for you project. This will impact the cost and duration." bottom={10} />

            <TabBar
              options={utils.fluxDeliveryOptions}
              onChange={item => {
                proposal.fluxDelivery = item.value
                setState({ ...state, proposal })
              }}
              value={fluxDelivery}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Scope Commitment:" tooltip="Create flexibility by changing the committed project scope. This will allow you to change scope easily and quicker instead of committing to the entire duration of the project." bottom={10} />
            <TabBar
              options={utils.fluxScopeCommitments}
              onChange={item => {
                proposal.fluxScope = item.value
                setState({ ...state, proposal })
              }}
              value={fluxScope}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Payment Type:" tooltip="Save money by paying all at once." bottom={10} />
            <TabBar
              options={paymentTypes}
              onChange={item => {
                proposal.paymentType = item.value
                setState({ ...state, proposal })
              }}
              value={paymentType}
            />
          </Section>
        </View> : null}
      </React.Fragment>
    );
  };

  return (
    <ChatWindow chatRooms={chatRooms}
      tabPanel={renderPanel()}
      onTabChange={item => {
        setState({ ...state, tabValue2: item.value });
      }}
      tabOptions={tabValue == "items" || tabValue == "timeline" ? [{ label: "Cart", value: "cart" }, { label: "Options", value: "options" }, { label: "Chat", value: "chat" }] : [{ label: "Cart", value: "cart" }, { label: "Chat", value: "chat" }]}
      tabValue={tabValue2}>
      <BasicTop title={title} />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ?
        <React.Fragment>
          <ProposalOverview proposal={proposal} isBuyerView />

          {proposal.buyerPriceMap && session.user.isSystemAdmin ? <Section>
            <HeaderText subHeader label="Price List:" />

            {proposal.buyerPriceMap.length == 0 ? <NoRecords label="No price list exists without strategy map items." /> : null}

            {proposal.buyerPriceMap.map(mapItem => {
              const { path, item, isOverride } = mapItem
              const { actualVersionType, quantity, total, unitPrice, days, isRemoved, actualVersionNum, actualServiceVersionId } = item

              return <BoxItem name={"path: " + path} rightRender={<Price price={unitPrice} days={days} />}>
                <FlexRow>
                  <TextLine label="Quantity:" value={quantity} spacer />
                  <TextLine label="Total:" value={money(total)} spacer />
                  {isOverride ? <TextLine value={"OVERRIDE"} spacer /> : null}
                  {isRemoved ? <TextLine value={"REMOVED"} spacer /> : null}
                </FlexRow>

                <View top={10} />
                <TextLine label="Actual Version Id:" value={actualServiceVersionId} spacer />
                <FlexRow>
                  <TextLine label="Actual Version Type:" value={actualVersionType} spacer />
                  <TextLine label="Version Num:" value={actualVersionNum} />
                </FlexRow>
              </BoxItem>
            })}
          </Section> : null}
        </React.Fragment> : null}

      {tabValue == "timeline" ? <RenderProposalTimeline historicalProposalVersion={historicalProposalVersion} rootProposalVersion={rootProposalVersion} serviceOrders={serviceOrders} staffOrders={staffOrders} proposal={proposal} currentPVersion={currentPVersion} onUpdateOverrides={() => {
        calculatePriceMap()
        setState({ ...state })
      }} onUpdateProposal={() => {
        setState({ ...state, proposal })
      }} /> : null}

      {tabValue == "shared" ? (
        <Section>
          <HeaderText subHeader label="Shared With:" buttonText="Share" onButtonPress={() => {
            setState({ ...state, addUserVisible: true })
          }} />

          {buyerSharedWith.length == 0 ? <NoRecordsBox label="Not shared with any team members.">
            <TouchButton
              micro
              onPress={() => {
                setState({ ...state, addUserVisible: true });
              }}
              label="Share"
            />
          </NoRecordsBox> : buyerSharedWith.map((item, index) => {
            const { userId, email, id } = item

            return <BoxItem key={id} onDelete={() => {
              buyerSharedWith.slice(index, 1)
              setState({ ...state })
              return db.getObj("ServiceProposal")
                .set("buyerSharedWith", buyerSharedWith)
                .save();
            }}>
              {userId ? <UserItem userId={userId} /> : <TextLine value={email} />}
            </BoxItem>
          })}

          {addUserVisible ? (
            <InviteMember
              proposalId={proposalId}
              onAddEmail={({ email }) => {
                const item = {
                  id: utils.guid(),
                  email
                }
                buyerSharedWith.push(item)
                setState({ ...state })

                db.getObj("ServiceProposal")
                  .set("buyerSharedWith", buyerSharedWith)
                  .save();
              }}
              onAddUser={user => {
                const { id } = user;

                const item = {
                  id: utils.guid(),
                  userId: id
                }
                buyerSharedWith.push(item)
                setState({ ...state })

                db.getObj("ServiceProposal")
                  .set("buyerSharedWith", buyerSharedWith)
                  .save();
              }}
              onCancel={() => {
                setState({ ...state, addUserVisible: false });
              }}
              onDone={() => {
                setState({ ...state, addUserVisible: false });
              }}
            />
          ) : null}
        </Section>
      ) : null}

      {tabValue == "team" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Strategy Team:" description="Meet your proposal team." />
            <FlexRow style={{ minHeight: 95 }} alignTop>
              <DisplayKeyUser style={{ flex: 1 }} label="Business Development:" value={businessDevelopment} />
              <DisplayKeyUser style={{ flex: 1 }} label="Strategy Consultant:" value={strategyConsultant} />
              <DisplayKeyUser style={{ flex: 1 }} label="Project Manager" value={projectManager} />
            </FlexRow>
          </Section>

          <RestOfTeam allTeam={allTeam} />
        </React.Fragment>
      ) : null}

      {tabValue == "versions" ? (
        <RenderProposalVersions
          description="See the history of strategy map versions submitted to you."
          historicalProposalVersion={historicalProposalVersion}
          currentPVersion={currentPVersion}
          proposal={proposal}
          proposalVersions={proposalVersions}
          onSelect={proposalVersion => {
            if (proposalVersion.versionNum == currentPVersion.versionNum) {
              setState({ ...state, tabValue: "items", historicalProposalVersion: null });
            } else {
              setState({ ...state, tabValue: "items", historicalProposalVersion: proposalVersion });
            }
          }}
        />
      ) : null}

      {tabValue == "items" ? (
        <Section>
          <HeaderText subHeader label="Strategy:" description="See your strategy items below. Select the services that you would like to order." />

          <TabBar
            options={[{ label: "Cart Items", value: "cart" }, { label: "Saved for Later", value: "later" }]}
            onChange={item => {
              setState({ ...state, tabValue3: item.value })
            }}
            value={tabValue3}
          />
          <View style={{ height: 25 }} />

          (display all the cart or saved for future  items here)
        </Section>
      ) : null}

      {tabValue == "items-2" ? (
        <Section>
          <HeaderText subHeader label="Strategy:" description="See your strategy items below. Select the services that you would like to order." />

          <TabBar
            options={[{ label: "Cart Items", value: "cart" }, { label: "Save For Future", value: "future" }]}
            onChange={item => {
              setState({ ...state, tabValue3: item.value })
            }}
            value={tabValue3}
          />
          <View style={{ height: 25 }} />

          <RenderServiceBlocks
            priceMap={buyerPriceMap ? buyerPriceMap : currentPVersion.priceMap}
            isBuyerView={isBuyerView}
            currentPVersion={currentPVersion}
            historicalProposalVersion={historicalProposalVersion}
            sectionId={sectionId}
            proposal={proposal}
            serviceOrders={serviceOrders}
            userSelected={userSelected}
            onRemove={() => {
              setState({ ...state, historicalProposalVersion: null });
            }}
            onReset={() => {
              const run = function () {
                proposal.buyerMasterOverrides = []
                setState({ ...state, proposal })
                dL.getObj("ServiceProposal", proposal.id)
                  .set("buyerMasterOverrides", null)
                  .save()
              };

              confirmAlert({
                title: "Alert!",
                message: "Selection and quantity has been reset to recommended services.",
                buttons: [
                  {
                    label: "Yes, reset to recommended.",
                    onClick: () => {
                      run();
                    }
                  },
                  {
                    label: "No."
                  }
                ]
              });
            }}
            onAddToCart={
              !historicalProposalVersion && sectionId == "user" && status == "open" && new Date() <= expireDate
                ? () => {
                  if (!session.cart) {
                    session.cart = { items: [] };
                  }

                  const { selectedItems } = getProposalSelectedItems({ proposal: proposal, userSelected });

                  selectedItems.forEach(proposalItem => {
                    addProposalItemToCart({ proposal, proposalItem, proposalVersion: currentPVersion });
                  });

                  dL.saveCart();

                  alert("Added to cart.");

                  history.push(`/${sectionId}/cart`);
                }
                : null
            }
          />

          {versionItems.length == 0 ? (
            <NoRecords label="No items added." />
          ) : (
            versionItems.map((item, index) => {
              const { items } = item;
              const proposalItem = allItems.get(item.id);
              const { id, itemType } = proposalItem;

              if (itemType == "service" || itemType == "staff") {
                return <RenderItem key={id} index={index} item={proposalItem} />;
              } else if (itemType == "stage") {
                const { expanded } = proposalItem;

                var spItems;
                if (selectedStepItem && selectedProposalItem && selectedProposalItem.id == proposalItem.id) {
                  spItems = getDeleteRemovedItems(thisVersion, allItemsV.get(selectedStepItem.id).items);
                }
                const pIItems = getDeleteRemovedItems(thisVersion, items);
                return (
                  <RenderProposalStage
                    key={id}
                    userSelected={userSelected}
                    thisVersion={thisVersion}
                    items={pIItems}
                    isBuyerView={isBuyerView}
                    hideLimitText
                    proposal={proposal}
                    readonly
                    proposalItem={proposalItem}
                    onExpand={value => {
                      proposalItem.expanded = value;
                      setState({ ...state, proposal, selectedStepItem: null });
                    }}>
                    {expanded ? (
                      <StepWrapper stepItems={pIItems} stageItem={proposalItem}>
                        <View style={{ display: "flex", flexWrap: "wrap" }}>
                          {pIItems.map((item) => {
                            const items = getDeleteRemovedItems(thisVersion, item.items);
                            const stepItem = allItems.get(item.id);

                            return <RenderStep userSelected={userSelected} items={items} key={stepItem.id} stageItem={proposalItem} stepItem={stepItem} />;
                          })}
                        </View>
                      </StepWrapper>
                    ) : null}

                    {selectedStepItem && selectedProposalItem && selectedProposalItem.id == proposalItem.id ? (
                      <ServiceWrapper items={spItems} selectedStepItem={selectedStepItem}>
                        {spItems.map((item, index) => {
                          const serviceItem = allItems.get(item.id);
                          return <RenderItem key={serviceItem.id} index={index} item={serviceItem} />;
                        })}
                      </ServiceWrapper>
                    ) : null}
                  </RenderProposalStage>
                );
              }
            })
          )}
        </Section>
      ) : null}

      {serviceStaffView ? (
        <EditStaffInCart
          cartItem={getCartItemFromProposalItem({ proposal, proposalItem: serviceStaffView, proposalVersion: currentPVersion })}
          onCancel={() => {
            setState({ ...state, serviceStaffView: null })
          }}
          onAddToCart={cartItem => {
            updateCartItem(cartItem);
            setState({ ...state, serviceStaffView: null })
            dL.saveCart();

            alert("Added to cart.");
            setState({ ...state, serviceStaffView: null })
          }}
          onSave={() => {
            setState({ ...state, serviceStaffView: null })
          }}
        />
      ) : null}

      {serviceView ? (
        <EditServiceInCart
          cartItem={getCartItemFromProposalItem({ proposal, proposalItem: serviceStaffView, proposalVersion: currentPVersion })}
          onCancel={() => {
            setState({ ...state, serviceView: null })
          }}
          onAddToCart={cartItem => {
            updateCartItem(cartItem);
            setState({ ...state, serviceView: null })
            dL.saveCart();

            alert("Added to cart.");
            setState({ ...state, serviceView: null })
          }}
          onSave={() => {
            setState({ ...state, serviceView: null })
          }}
        />
      ) : null}
    </ChatWindow>
  );
}

