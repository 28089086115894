import "./App.css";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import http from "root/utils/http";
import "root/App.css";
import axios from "axios";
import { __DEV__ } from "root/dev";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { Loading,DateTimeItem, ItemExpandSection, Section, FlexExpand, TextLine, FlexRow, BoxItem, EditDialog, ModalBasic, View, FormItem, MyInput, SelectBox } from "root/pack-1";
import { RenderButtons, BoxRowItem, HeaderText } from "root/pack-2";
import { RenderUserItem } from "root/users"
import "draft-js/dist/Draft.css";
import "draftail/dist/draftail.css";
import "@draft-js-plugins/image/lib/plugin.css";
import { convertToRaw, convertFromRaw, AtomicBlockUtils } from "draft-js";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import renderHTML from "react-render-html";
import { EditorState } from "draft-js";
import "@draft-js-plugins/alignment/lib/plugin.css";
import "medium-draft/lib/index.css";
import "medium-draft/lib/basic.css";
import mediumDraftExporter from 'medium-draft/lib/exporter';
import { ChatWindow, getRoomId } from "root/chat";
import Moment from "moment-business-days";

import { StringToTypeMap, beforeInput, getCurrentBlock, rendererFn, Modifier, ImageSideButton, Block, addNewBlock, Editor, createEditorState, addNewBlockAt, HANDLED, NOT_HANDLED } from "medium-draft";

const DQUOTE_START = "“";
const DQUOTE_END = "”";
const SQUOTE_START = "‘";
const SQUOTE_END = "’";

const newTypeMap = StringToTypeMap;
newTypeMap["2."] = Block.OL;

const { getLabelItem, plural, noteTypes } = utils;

/*
Training for Service:
Top Trainers: (trainers that are here to help you learn to execute with questions)

Task Training:
(list of task and training on each item)

Deliverables Training:
(list of deliverables and training on each item)
*/

class SeparatorSideButton extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    let editorState = this.props.getEditorState();
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("separator", "IMMUTABLE", {});
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    editorState = EditorState.push(editorState, contentWithEntity, "create-entity");
    this.props.setEditorState(AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, "-"));
    this.props.close();
  }

  render() {
    return (
      <button className="md-sb-button md-sb-img-button" type="button" title="Add a separator" onClick={this.onClick}>
        <i className="fa fa-minus" />
      </button>
    );
  }
}

class EmbedSideButton extends React.Component {
  static propTypes = {
    setEditorState: PropTypes.func,
    getEditorState: PropTypes.func,
    close: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.addEmbedURL = this.addEmbedURL.bind(this);
  }

  onClick() {
    const url = window.prompt("Enter a URL", "https://www.youtube.com/watch?v=PMNFaAUs2mo");
    this.props.close();
    if (!url) {
      return;
    }
    this.addEmbedURL(url);
  }

  addEmbedURL(url) {
    let editorState = this.props.getEditorState();
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("embed", "IMMUTABLE", { url });
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    editorState = EditorState.push(editorState, contentWithEntity, "create-entity");
    this.props.setEditorState(AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, "E"));
  }

  render() {
    return (
      <button className="md-sb-button md-sb-img-button" type="button" title="Add an Embed" onClick={this.onClick}>
        <i className="fa fa-code" />
      </button>
    );
  }
}

class CustomImageSideButton extends ImageSideButton {
  /*
  We will only check for first file and also whether
  it is an image or not.
  */
  onChange(e) {
    const th = this;
    const file = e.target.files[0];
    if (file.type.indexOf("image/") === 0) {
      // This is a post request to server endpoint with image as `image`

      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        //this.setState({ ...state, imgSrc: [reader.result] });
      }.bind(this);

      const path = "apps/root/";
      const fileName = utils.guid();
      const query = {
        fileName: path + fileName,
        fileType: file.type
      };

      http
        .post(session.masterUrl + "/sign_s3", query)
        .then(function (response) {
          return response.json();
        })
        .then(function (result) {
          const returnData = result.data.returnData;
          const signedRequest = returnData.signedRequest;
          const options = {
            headers: {
              "Content-Type": file.type
            },
            onUploadProgress: function (progressEvent) {
              //setProgress((progressEvent.loaded / progressEvent.total) * 100);
            }
          };
          axios
            .put(signedRequest, file, options)
            .then(result => {
              const data = {
                fileName: file.name,
                path,
                file: fileName,
                type: file.type,
                size: file.size
              };

              //setProgress(0);

              const url = "https://growly.s3.amazonaws.com/" + data.path + data.file;
              th.props.setEditorState(addNewBlock(th.props.getEditorState(), Block.IMAGE, { src: url }));
            })
            .catch(error => {
              alert("ERROR " + JSON.stringify(error));
            });
        });
    }
    this.props.close();
  }
}

const AtomicBlock = props => {
  const { blockProps, block } = props;
  const content = blockProps.getEditorState().getCurrentContent();
  const entity = content.getEntity(block.getEntityAt(0));
  const data = entity.getData();
  const type = entity.getType();
  if (blockProps.components[type]) {
    const AtComponent = blockProps.components[type];
    return (
      <div className={`md-block-atomic-wrapper md-block-atomic-wrapper-${type}`}>
        <AtComponent data={data} />
      </div>
    );
  }
  return (
    <p>
      Block of type <b>{type}</b> is not supported.
    </p>
  );
};

class AtomicEmbedComponent extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showIframe: false
    };

    this.enablePreview = this.enablePreview.bind(this);
  }

  componentDidMount() {
    this.renderEmbedly();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showIframe !== this.state.showIframe && this.state.showIframe === true) {
      this.renderEmbedly();
    }
  }

  getScript() {
    const script = document.createElement("script");
    script.async = 1;
    script.src = "//cdn.embedly.com/widgets/platform.js";
    script.onload = () => {
      window.embedly();
    };
    document.body.appendChild(script);
  }

  renderEmbedly() {
    if (window.embedly) {
      window.embedly();
    } else {
      this.getScript();
    }
  }

  enablePreview() {
    this.setState({
      showIframe: true
    });
  }

  render() {
    const { url } = this.props.data;
    const innerHTML = `<div><a class="embedly-card" href="${url}" data-card-controls="0" data-card-theme="dark">Embedded ― ${url}</a></div>`;
    return (
      <div className="md-block-atomic-embed">
        <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
      </div>
    );
  }
}

const AtomicSeparatorComponent = props => <hr />;

export function EditTrainingNote({ service, userService, serviceVersion, onSave, onCancel }) {
  const history = useHistory();
  const { recordId, type, linkRecordId, versionId } = useParams();
  const [state, setState] = useState({
    isLoading: recordId ? true : false,
    model: {
      status: "draft",
      task: type == "task" ? { id: versionId, task: { id: linkRecordId } } : null,
      deliverable: type == "deliverable" ? { id: versionId, deliverable: { id: linkRecordId } } : null,
      service,
      userService,
      serviceVersion
    }
  });
  const editor = React.useRef(null);
  const [editorState, setEditorState] = useState(() => createEditorState());
  const { model, isLoading } = state;
  const { status, name, shortDescription, noteType, content } = model;

  useEffect(() => {
    if (recordId) {
      dL.getQuery("TrainingNote")
        .include("createdBy")
        .include("taskVersion")
        .include("deliverableVersion")
        .get(recordId)
        .then(function (obj) {
          const model = dL.loadTrainingNote(obj);
          const { content } = model
          setEditorState(createEditorState(content))
          setState({ ...state, isLoading: false, model });
        });
    }
  }, []);

  if (isLoading) {
    return <Loading/>;
  }

  const doSubmit = function () {
    if (!name) {
      alert("Must enter title.");
      return;
    }
    if (!noteType) {
      alert("Must select note type.");
      return;
    }
    const newlyActive = model.status != "active"
    model.status = "active";
    dL.saveTrainingNote({ newlyActive, isNew: !recordId, data: model }).then(function () {
      onSave();
    });
  };

  const buttons = [
    {
      label: "Cancel",
      grey: true,
      onPress: () => {
        history.goBack();
      }
    }
  ];

  if (status == "draft") {
    buttons.push({
      label: "Save Draft",
      onPress: () => {
        if (!name) {
          alert("Must enter title.");
          return;
        }
        model.status = "draft";
       return dL.saveTrainingNote({ isNew: !recordId, data: model }).then(function () {
          onSave();
        });
      }
    });

    buttons.push({
      label: "Submit",
      onPress: () => {
        return doSubmit();
      }
    });
  } else {
    buttons.push({
      label: "Update",
      onPress: () => {
        return doSubmit();
      }
    });
  }

  const sideButtons = [
    {
      title: "Image",
      component: CustomImageSideButton
    },
    {
      title: "Embed",
      component: EmbedSideButton
    },
    {
      title: "Separator",
      component: SeparatorSideButton
    }
  ];

  const onChange = function (editorState, callback = null) {
    const content = editorState.getCurrentContent()
    if (content) {
      model.content = convertToRaw(content)
      setState({ ...state, model });
    }

    setEditorState(editorState);
    if (callback) {
      callback();
    }
  };

  const handleDroppedFiles = function (selection, files) {
    window.ga("send", "event", "draftjs", "filesdropped", files.length + " files");
    const file = files[0];
    if (file.type.indexOf("image/") === 0) {
      // eslint-disable-next-line no-undef
      const src = URL.createObjectURL(file);
      onChange(
        addNewBlockAt(editorState, selection.getAnchorKey(), Block.IMAGE, {
          src
        })
      );
      return HANDLED;
    }
    return NOT_HANDLED;
  };

  const rendererFn2 = function (setEditorState, getEditorState, ...args) {
    const atomicRenderers = {
      embed: AtomicEmbedComponent,
      separator: AtomicSeparatorComponent
    };
    const rFnOld = rendererFn(setEditorState, getEditorState, ...args);
    const rFnNew = contentBlock => {
      const type = contentBlock.getType();
      switch (type) {
        case Block.ATOMIC:
          return {
            component: AtomicBlock,
            editable: false,
            props: {
              components: atomicRenderers,
              getEditorState
            }
          };
        default:
          return rFnOld(contentBlock);
      }
    };
    return rFnNew;
  };

  const handleReturn = function (e) {
    // const currentBlock = getCurrentBlock(this.state.editorState);
    // var text = currentBlock.getText();
    return NOT_HANDLED;
  };

  return (
    <EditDialog style={{ maxWidth: 800 }}>
      <HeaderText label="Edit Training Note:" description="Edit a training note." />

      <MyInput
        required
        label="Title of note:"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        maxLength={144}
        label="Short description of note:"
        value={shortDescription}
        onChange={value => {
          model.shortDescription = value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        label="Note Type:"
        value={noteTypes.find(item => item.value == noteType)}
        options={noteTypes}
        onChange={item => {
          model.noteType = item.value;
          setState({ ...state, model });
        }}
      />

      <FormItem label="Content:" box>
        <Editor
          sideButtons={sideButtons}
          handleDroppedFiles={handleDroppedFiles}
          editorState={editorState}
          onChange={onChange}
          ref={editor}
          rendererFn={rendererFn2}
          handleReturn={handleReturn}
          beforeInput={handleBeforeInput} />
      </FormItem>

      <View style={{ height: 25 }} />
      <RenderButtons buttons={buttons} />
    </EditDialog>
  );
}

const handleBeforeInput = (editorState, str, onChange) => {
  if (str === '"' || str === "'") {
    const currentBlock = getCurrentBlock(editorState);
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const text = currentBlock.getText();
    const len = text.length;
    if (selectionState.getAnchorOffset() === 0) {
      onChange(EditorState.push(editorState, Modifier.insertText(contentState, selectionState, str === '"' ? DQUOTE_START : SQUOTE_START), "transpose-characters"));
      return HANDLED;
    } else if (len > 0) {
      const lastChar = text[len - 1];
      if (lastChar !== " ") {
        onChange(EditorState.push(editorState, Modifier.insertText(contentState, selectionState, str === '"' ? DQUOTE_END : SQUOTE_END), "transpose-characters"));
      } else {
        onChange(EditorState.push(editorState, Modifier.insertText(contentState, selectionState, str === '"' ? DQUOTE_START : SQUOTE_START), "transpose-characters"));
      }
      return HANDLED;
    }
  }
  return beforeInput(editorState, str, onChange, newTypeMap);
};

export function ViewTrainingNote({ recordId, onClose }) {
  const [state, setState] = useState({ isLoading: true });
  const { model, isLoading } = state;

  useEffect(() => {
    dL.getQuery("TrainingNote")
      .get(recordId)
      .then(function (obj) {
        const model = dL.loadTrainingNote(obj);
        setState({ ...state, isLoading: false, model });
      });
  }, []);

  if (isLoading) {
    return <Loading/>;
  }

  const { name, shortDescription, noteType, content } = model;

  return (
    <ModalBasic large notCentered full title={name + " (" + getLabelItem(noteTypes, noteType) + ")"} onCancel={onClose} cancelText="Close">
      <TextLine bottom={15} value={shortDescription} />

      {renderHTML(mediumDraftExporter(convertFromRaw(content)).replace(/\n/g, "<br>"))}
    </ModalBasic>
  );
}

export function getSummaryItems({ trainingNotes }) {
  const summary = {}
  trainingNotes.forEach(item => {
    const { task, deliverable } = item
    const compareId = task ? task.id : deliverable.id
    if (!summary[compareId]) {
      summary[compareId] = {
        items: []
      }
    }
    summary[compareId].items.push(item)
  })
  const summaryItems = []
  for (var key in summary) {
    summaryItems.push(summary[key])
  }
  return summaryItems
}

export function RenderSummaryItems({ summaryItems }) {
  const [state, setState] = useState({ isLoading: true })
  const { viewNote } = state

  return summaryItems.map(item => {
    const { task, deliverable } = item.items[0]
    const { name, description } = task ? task : deliverable

    return <BoxItem name={name} nameSmall={task ? "Task" : "Deliverable"} description={description}>
      <ItemExpandSection>
        {item.items.map(trainingNote => {
          const { noteType, name, shortDescription, createdAt } = trainingNote

          return <BoxItem name={name} nameSmall={getLabelItem(noteTypes, noteType)} description={shortDescription} onPress={() => {
            setState({ ...state, viewNote: trainingNote })
          }} rightRender={<DateTimeItem value={createdAt} alignRight fromNow />}>
          </BoxItem>
        })}
      </ItemExpandSection>

      {viewNote ? <ViewTrainingNote
        recordId={viewNote.id}
        onClose={() => {
          setState({ ...state, viewNote: null })
        }}
      /> : null}
    </BoxItem>
  })
}

export function ViewTrainingProfile() {
  const history = useHistory();
  const match = useRouteMatch("/:sectionId/*");
  const { trainerId } = useParams()
  const [state, setState] = useState({ isLoading: true });
  const { trainer, isLoading, summaryItems, otherServices, trainingNotes } = state;

  useEffect(() => {
    dL.getQuery("Trainer")
      .equalTo("user", dL.getObj("User", trainerId))
      .include("user")
      .first()
      .then(function (obj) {
        const trainer = dL.loadTrainer(obj);
        const { user, service } = trainer
        //load other services that this trainer trains

        return dL.getQuery("TrainingNote")
          .equalTo("status", "active")
          .equalTo("service", dL.getObj("Service", service.id))
          .equalTo("createdBy", dL.getObj("User", user.id))
          .containedIn("removed", [undefined, false])
          .include("createdBy")
          .include("taskVersion")
          .include("deliverableVersion")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            const trainingNotes = dL.loadObjects("TrainingNote", objs)

            const summaryItems = getSummaryItems({ trainingNotes })

            return dL.getQuery("Trainer")
              .equalTo("user", dL.getObj("User", user.id))
              .descending("lastNoteUpdatedAt")
              .include("service")
              .find()
              .then(function (objs) {
                const otherServices = dL.loadObjects("Trainer", objs)

                setState({ ...state, isLoading: false, otherServices, summaryItems, trainer, trainingNotes });
              });
          });
      });
  }, []);

  if (isLoading) {
    return <Loading/>;
  }

  const { user, id, noteCount, lastNoteUpdatedAt } = trainer;

  const privateRoomId = getRoomId([trainer, session.user.id])
  const chatRoomName = "Service: " + "service name";
  const chatRooms = [{ label: "Public", value: "public", roomName: chatRoomName, roomId: id + "-p", users: [user, session.user] }, { label: "1 on 1", value: "1on1", roomName: chatRoomName, roomId: id + privateRoomId, users: [user, session.user.id] }];

  return <ChatWindow
    chatRooms={chatRooms}>
    <HeaderText label="Trainer Profile:" />

    <Section>
      <FlexRow alignTop>
        <RenderUserItem item={user} />

        <FlexExpand>
          <BoxRowItem>
            <TextLine label="Training Count:" value={plural(noteCount, "note")} />
            <TextLine label="Last Note Added:" value={Moment(lastNoteUpdatedAt).fromNow()} />
          </BoxRowItem>
        </FlexExpand>
      </FlexRow>
    </Section>

    <Section>
      <HeaderText subHeader label="Training Notes:" />
      <RenderSummaryItems summaryItems={summaryItems} />
    </Section>

    <Section>
      <HeaderText subHeader label="Other Services Trained:" />

      {otherServices.map(item => {
        const { id, name, shortDescription, icon } = item.service

        return <BoxItem onPress={() => {
          history.push(`/${match.params.sectionId}/service/${id}`)
        }} name={name} description={shortDescription} icon={icon}></BoxItem>
      })}
    </Section>
  </ChatWindow>
}

export function ViewTrainingProfileService() {
  //profile of trainer
  //stats for trainer and training of this service (?)
  //training notes for service (under the task or under the deliverable: categorized)
  //comments
}

if (__DEV__) {
  window.ga = function () {
    console.log(arguments);
  };
}
