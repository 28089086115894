import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyCOYmq1ZSdy6Lm5PEeGkURVIcKP-W9m-mw",
  authDomain: "growly-app.firebaseapp.com",
  databaseURL: "https://growly-app.firebaseio.com",
  projectId: "growly-app",
  storageBucket: "growly-app.appspot.com",
  messagingSenderId: "638030863716",
  appId: "1:638030863716:web:47c703f89a3751ef476e71",
  measurementId: "G-LVNB91C4BM"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

var firestore = firebase.firestore();
firestore.settings({ignoreUndefinedProperties: true});
export {firestore, firebase};
