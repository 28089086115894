import "./App.css";
import { TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import Moment from "moment-business-days";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { __DEV__ } from "root/dev";
import { ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { BoxItem, Loading, SelectBox, TextLine, DeleteConfirm, Section, Columns, ModalBasic, LabelItem, LinkButton, FlexExpand, FlexRow, TabBar, Text, View, Toggle, FormItem, MyInput, TouchButton, NoRecords } from "root/pack-1";
import { ChatWindow, getChatRoomsBuyer } from "root/chat";
import Datetime from "react-datetime";
import { getMinDeliveryDays } from "root/service-functions";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useLayer, Arrow } from "react-laag";
import { HashTable } from "root/hashtable";
import { RenderTimeLog } from "root/time-logs"

const { money, roundNumber, randomNumber, getValueItem, skillLevels, yesNo, plural, getTaskStatus, getLabelItem, priorityLevels } = utils;

export function TaskRecord() {
  const { recordId, sectionId } = useParams();
  const history = useHistory();
  const [refresh, setRefresh] = useState(new Date());
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model, times } = state;

  useEffect(() => {
    var model, times;

    dL.getQuery("TaskRecord")
      .include("assignedTo")
      .include("createdBy")
      .include("service")
      .include("serviceOrder")
      .include("serviceOrder.serviceVersion")
      .include("serviceOrder.user")
      .include("order")
      .include("staffAugShare")
      .include("staffAugService")
      .include("staffAugService.user")
      .include("staffAugService.businessRole")
      .get(recordId)
      .then(function (obj) {
        model = dL.loadTaskRecord(obj);

        var promises = [];
        promises[promises.length] = db
          .getQuery("TimeRecord")
          .equalTo("task", dL.getObj("TaskRecord", recordId))
          .containedIn("removed", [undefined, false])
          .include("user")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            times = dL.loadObjects("TimeRecord", objs);
          });
        return Promise.all(promises);
      })
      .then(function () {
        setState({ ...state, isLoading: false, times, model });
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { chargeAccount, id, canStart, timeLogSeconds, assignedTo, title, description, deliverables, hours, startDate, dueDate, createdAt, createdBy, status, service, serviceOrder, staffAugService, staffAugShare } = model;

  const chatRoomName = "Task"
  const chatRooms = getChatRoomsBuyer({ id, chatRoomName, clientUsers: [createdBy], growlyUsers: [staffAugService ? staffAugService.projectManager : null, assignedTo, session.user, staffAugShare ? staffAugShare.assignedTo : null] })

  return (
    <ChatWindow
      chatRooms={chatRooms}>
      <Section>
        <HeaderText
          label={title}
          description={description}
          rightRender={
            sectionId != "user" && assignedTo && assignedTo.id == session.user.id ? (
              <FlexRow>
                {status == "ready" || status == "pending" || status == "started" ? (
                  <TouchButton
                    style={{ marginRight: 10 }}
                    label="Start Work" micro
                    onPress={() => {
                      return dL.getIsAccountUpToDate({ chargeAccount }).then(function (status) {
                        if (status) {
                          alert(status)
                          return
                        }
                        return dL
                          .startTaskRecord({
                            recordId
                          })
                          .then(function () {
                            setRefresh(new Date());
                          });
                      });
                    }}
                  />
                ) : status == "working" ? (
                  <TouchButton
                    style={{ marginRight: 10 }}
                    label="Stop Work"
                    micro grey
                    onPress={() => {
                      return dL
                        .stopTaskRecord({
                          recordId
                        })
                        .then(function () {
                          setRefresh(new Date());
                        });
                    }}
                  />
                ) : null}
                {status == "completed" ? (
                  <TouchButton
                    label="Mark Un-Completed" micro grey
                    onPress={() => {
                      return dL
                        .markCompleted({
                          recordId,
                          completed: false
                        })
                        .then(function () {
                          setRefresh(new Date());
                        });
                    }}
                  />
                ) : status != "canceled" ? (
                  <TouchButton
                    label="Mark Completed" micro
                    onPress={() => {
                      if (status == "working") {
                        return dL
                          .stopTaskRecord({
                            recordId,
                            completed: true
                          })
                          .then(function () {
                            setRefresh(new Date());
                          });
                      } else {
                        return dL
                          .markCompleted({
                            recordId,
                            completed: true
                          })
                          .then(function () {
                            setRefresh(new Date());
                          });
                      }
                    }}
                  />
                ) : null}
              </FlexRow>
            ) : status == "pending" || status == "ready" ? (
              <TouchButton
                label="Cancel Task" micro
                onPress={() => {
                  confirmAlert({
                    title: "Alert!",
                    message: "Are you sure you want to cancel this task?",
                    buttons: [
                      {
                        label: "Yes, cancel task.",
                        onClick: () => {
                          return dL
                            .cancelTaskRecord({
                              recordId
                            })
                            .then(function () {
                              setRefresh(new Date());
                            });
                        }
                      },
                      { label: "No." }
                    ]
                  });

                }}
              />
            ) : null
          }
        />
        <Section>
          <HeaderText subHeader label="Task Details:" />
          <LabelItem label="Deliverables:" value={deliverables} hideIfNull />
          <Columns left={<LabelItem label="Assigned To:">{assignedTo ? <UserItem user={assignedTo} /> : null}</LabelItem>} right={<LabelItem label="Status:" value={getLabelItem(utils.taskRecordStatuses, status)} />} />
          <Columns left={<LabelItem label="Hours:" value={hours} />} middle={<LabelItem label="Start Date:" value={Moment(startDate).format("M/D/YYYY")} />} right={<LabelItem label="Due Date:" value={Moment(dueDate).format("M/D/YYYY")} />} />

          <FlexRow>
            <LabelItem label="Spent Hours:" value={plural(roundNumber(timeLogSeconds ? timeLogSeconds / 60 : 0, 1), "hr")} />
            <LabelItem label="Can Start:" value={yesNo(canStart)} />
          </FlexRow>

          <Columns
            left={<LabelItem label="Created By:">
              <UserItem user={createdBy} />
            </LabelItem>}
            right={<LabelItem label="Created At:" value={Moment(createdAt).format("M/D/YYYY [at] h:mm a")} />}
          />
        </Section>
      </Section>

      {serviceOrder ? (
        <Section>
          <HeaderText subHeader label="Service Order Details:" />
          <FlexRow>
            <LabelItem label="Purchased By:">
              <UserItem user={serviceOrder.user} />
            </LabelItem>

            <LabelItem label="Service Order:" value={"Ref #: " + serviceOrder.orderNumber + ": " + service.name} onPress={() => {
              history.push(`/${sectionId}/service-order/${serviceOrder.id}`);
            }} />
          </FlexRow>
        </Section >
      ) : null}

      {staffAugService ? (
        <Section>
          <HeaderText subHeader label="Service Details:" />
          <FlexRow>
            <LabelItem label="Purchased By:">
              <UserItem user={staffAugService.user} />
            </LabelItem>

            <LabelItem label="Staff Contract:" onPress={() => {
              history.push(`/${sectionId}/staff-service/${staffAugService.id}`);
            }}>
              Ref # {staffAugService.serviceNumber}: {staffAugService.businessRole ? staffAugService.businessRole.name : ""}
            </LabelItem>
          </FlexRow>
        </Section>
      ) : null}

      <Section>
        <HeaderText subHeader label="Time Log:" />
        {times.length == 0 ? (
          <NoRecords label="No time records found." />
        ) : (
          times.map(item => {
            const { startDate, endDate, seconds, task, user } = item;
            return (
              <BoxRowItem>
                <FlexRow alignTop>
                  <FlexExpand>
                    <TextLine size={12} label="Start Time:" value={Moment(startDate).format("M/D/YYYY [at] h:mm a")} spacer />
                    {endDate ? <TextLine size={12} label="End Time:" value={Moment(endDate).format("M/D/YYYY [at] h:mm a")} spacer /> : "[still working]"}
                    {seconds ? <TextLine top={10} value={plural(roundNumber(seconds / 60 / 60, 2), "hr")} /> : null}
                  </FlexExpand>

                  <UserItem label="Consultant:" user={user} />
                </FlexRow>

                {!endDate ? (
                  <TouchButton
                    label="Stop Work" micro
                    onPress={() => {
                      return dL.stopTaskRecord({ recordId: task.id }).then(function () {
                        setRefresh(new Date());
                      });
                    }}
                  />
                ) : null}
              </BoxRowItem>
            );
          })
        )}
      </Section>
    </ChatWindow >
  );
}

function DateTime2(props) {
  const [isOpen, setOpen] = React.useState(false);
  const { value, onChange, ...others } = props

  const {
    renderLayer,
    triggerProps,
    layerProps,
    arrowProps
  } = useLayer({
    isOpen,
    auto: true,
    arrowOffset: 4,
    onDisappear: disappearType => {
      if (disappearType === "full") {
        setOpen(false);
      }
    },
    onOutsideClick: () => setOpen(false)
  });

  return (
    <>
      <div {...triggerProps} onClick={() => setOpen(!isOpen)}>
        {value ? Moment(value).format("M/D/YYYY") : "Empty"}
      </div>

      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{ overflow: "hidden", borderRadius: "6px", border: "2px solid #c0c0c0", zIndex: 99999, ...layerProps.style }}
          >
            <Datetime
              input={false}
              open={true}
              value={value}
              timeFormat={false}
              {...others}
              onChange={value => {
                onChange(value.toDate())
                setOpen(false)
              }}
            />

            <Arrow {...arrowProps} size={12} roundness={1} />
          </div>
        )}
    </>
  );
}

const getTaskPriorityLevel = function ({ staffService, turboCharge }) {
  const { priorityLevel } = staffService;
  const level = getValueItem(priorityLevels, priorityLevel);
  var priority = level.priority;
  if (turboCharge) {
    priority--
  }
  return priority
}

const getEarliestDates = function ({ staffService, businessRoleTask, turboCharge, hours }) {
  const { priorityLevel, hoursPerMonth } = staffService;
  var levels = [
    { hours: 160, priority: "high", days: .5 },
    { hours: 160, priority: "normal", days: 1 },
    { hours: 160, priority: "low", days: 2 },
    { hours: 80, priority: "high", days: 2 },
    { hours: 80, priority: "normal", days: 3 },
    { hours: 80, priority: "low", days: 4 },
    { hours: 40, priority: "high", days: 3 },
    { hours: 40, priority: "normal", days: 4 },
    { hours: 40, priority: "low", days: 5 },
    { hours: 20, priority: "high", days: 4 },
    { hours: 20, priority: "normal", days: 5 },
    { hours: 20, priority: "low", days: 6 }
  ]
  //160 hours, high: less than 1 day
  //160 hours, normal: 1
  //160 hours, low: 2
  //80 hours, high: 2
  //80 hours, normal: 3
  //80 hours, low: 4
  //40 hours, high: 3
  //40 hours, normal: 4
  //40 hours, low: 5
  //20 hours, high: 4
  //20 hours, normal: 5
  //20 hours, low: 6

  const levels2 = levels.filter(item => item.hours <= hoursPerMonth)
  var days = levels2[priorityLevel == "high" ? 0 : priorityLevel == "normal" ? 1 : 2]
  if (turboCharge) {
    var index = levels.findIndex(item => item.priority == priorityLevel && item.hours == hoursPerMonth)
    index -= 2
    if (index < 0) {
      days = 0
    } else {
      days = levels2[index].days
    }
  }

  const startDate = Moment().businessAdd(days)

  const { deliveryDays } = businessRoleTask ? businessRoleTask : {}
  var dueDate

  var earliestDueDate
  if (utils.isNumber(hours)) {
    const { minDeliveryDays } = getMinDeliveryDays({ workerHours: Number(hours), deliveryType: turboCharge ? "fast" : null })
    earliestDueDate = startDate.businessAdd(minDeliveryDays)
  }

  if (deliveryDays) {
    dueDate = startDate.businessAdd(Number(deliveryDays))
    if (dueDate < earliestDueDate) {
      dueDate = earliestDueDate
    }
  } else {
    dueDate = earliestDueDate
  }

  return { startDate, dueDate };
};

export function CreateTaskRecord({ staffServiceShare, staffService, recordId, onSave, onClose }) {
  const [state, setState] = useState({ taskType: "tasks", model: {}, isLoading: true });
  const { companyTasks, taskType, saveTaskOption, definedTaskItem, isLoading, model, businessRole } = state;

  useEffect(() => {
    dL.getQuery("BusinessRole")
      .include("tasks")
      .get(staffService.businessRole.id).then(function (obj) {
        const businessRole = dL.loadBusinessRole(obj)

        return dL.getQuery("BusinessRoleTask")
          .equalTo("company", db.getObj("Company", session.company.id))
          .containedIn("removed", [undefined, false])
          .find().then(function (objs) {
            const companyTasks = dL.loadObjects("BusinessRoleTask", objs)

            setState({ ...state, isLoading: false, companyTasks, businessRole });
          })

      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Loading />
  }

  const { turboCharge, delayStart, title, description, dueDate, deliverableNotes, hours, startDate } = model;

  const tabs = [{ label: "Pre-Defined Task", value: "tasks" }, { label: "Custom Task", value: "custom" }];

  const { turboBoostsLeft } = staffService
  const { tasks } = businessRole

  var definedTaskList = [];
  if (businessRole && businessRole.taskListText) {
    definedTaskList.push(
      {
        id: 1,
        label: "Quick Tasks",
        options:
          businessRole.taskListText.split("\n").map(item => { return { label: item, value: item, group: "Business Role" } })
      })
  }

  definedTaskList.push({
    id: 2,
    label: "Custom Defined",
    options: companyTasks.map(item => {
      const { id, title } = item
      return { label: title, value: id, businessRoleTask: item }
    })
  })

  definedTaskList.push({
    id: 3,
    label: "System Defined",
    options: tasks.map(item => {
      const { id, title } = item
      return { label: title, value: id, businessRoleTask: item }
    })
  })

  const { businessRoleTask } = definedTaskItem ? definedTaskItem : {}
  const isMine = businessRoleTask && businessRoleTask.company && businessRoleTask.createdBy.id == session.user.id
  const saveOptions = []
  saveOptions.push({ label: "No, one time use", value: null })
  if (isMine) {
    saveOptions.push({ label: "Yes, save this as a new task", value: "new" })
    saveOptions.push({ label: "Yes, update the existing saved task", value: "update" })
  } else {
    saveOptions.push({ label: "Yes, save this task for future use", value: "new" })
  }

  const { startDate: earliestStartDate, dueDate: earliestDueDate } = getEarliestDates({ staffService, businessRoleTask, turboCharge, hours })

  return (
    <ModalBasic
      title="Add Task"
      okText="Save"
      onCancel={() => {
        onClose();
      }}
      onOk={() => {
        if (!title) {
          alert("Must enter title.");
          return;
        }

        if (saveTaskOption == "new" && definedTaskItem) {
          if (businessRoleTask.title == title) {
            alert("Must give this task an unique title to save for future use. Task with given title already exists.")
            return
          }
        }

        if (!utils.isNumber(hours)) {
          alert("Must enter valid not to exceed hours.");
          return;
        }

        model.priority = getTaskPriorityLevel({ staffService, turboCharge })
        model.hours = Number(hours);
        model.staffAugService = staffService
        model.staffAugShare = staffServiceShare

        return dL.createTaskRecord(model).then(function () {
          const data = {
            businessRole,
            company: session.company,
            title,
            description,
            deliverableNotes,
            workHours: hours
          }

          if (saveTaskOption == "new") {
            return dL.saveBusinessRoleTask({ isNew: true, data })

          } else if (saveTaskOption == "update") {
            Object.assign(businessRoleTask, data)
            return dL.saveBusinessRoleTask({ recordId: businessRoleTask.id, data: businessRoleTask })
          }
        }).then(function () {
          alert("Task created.")
          onSave();
        });
      }}>

      {tasks.length > 0 ? <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, taskType: item.value });
        }}
        value={taskType}
      /> : null}

      {taskType == "tasks" ? (
        <Section>
          <SelectBox
            label="Pre-Defined Task List:"
            description="Select from an existing task that is available for this business role."
            value={definedTaskItem}
            options={definedTaskList}
            onChange={item => {
              const { businessRoleTask, label } = item

              if (businessRoleTask) {
                const { title, description, workHours, deliverableNotes } = businessRoleTask
                model.title = title
                model.description = description
                model.hours = workHours
                model.deliverableNotes = deliverableNotes
              } else {
                model.title = label
              }
              setState({ ...state, model, definedTaskItem: item });
            }}>
            {isMine ? <LinkButton
              style={{ marginTop: 8 }}
              onPress={() => {
                confirmAlert({
                  title: "Alert!",
                  message: "Are you sure you want to remove this task?",
                  buttons: [
                    {
                      label: "Yes, remove task.",
                      onClick: () => {
                        const index = companyTasks.findIndex(item => item.id == definedTaskItem.id)
                        companyTasks.splice(index)
                        dL.getObj("BusinessRoleTask", businessRoleTask.id).set("removed", true).save()
                        setState({ ...state, companyTasks, definedTaskItem: null })
                      }
                    },
                    { label: "No." }
                  ]
                });
              }} label="Remove this pre-defined task" /> : null}
          </SelectBox>
        </Section>
      ) : null}

      {definedTaskItem || taskType == "custom" ? <React.Fragment>
        <Section>
          <MyInput
            required
            label="Title:"
            value={title}
            onChange={value => {
              var newState = { ...state, model }
              if (!definedTaskItem && taskType == "tasks") {
                newState.taskType = "custom"
              }
              model.title = value;
              setState(newState);
            }}
          />

          <MyInput
            required
            multiline
            label="Description/Notes:"
            value={description}
            onChange={value => {
              model.description = value;
              setState({ ...state, model });
            }}
          />

          <MyInput
            style={{ maxWidth: 125 }}
            required
            inputType="integer"
            minValue={0}
            label="Hours:"
            description="Worker will not go beyond this time estimate with out checking with you to proceed."
            value={hours}
            onChange={value => {
              model.hours = value;

              const { dueDate: earliestDueDate } = getEarliestDates({ staffService, businessRoleTask, turboCharge, hours: value })

              if (dueDate < earliestDueDate) {
                model.dueDate = null
              }

              setState({ ...state, model });
            }}
          />

          <MyInput
            required
            multiline
            label="Required Deliverables:"
            description="Share a description of the exact deliverables that you require for this task."
            value={deliverableNotes}
            onChange={value => {
              model.deliverableNotes = value;
              setState({ ...state, model });
            }}
          />

          <SelectBox
            label="Save task for future use:"
            value={getValueItem(saveOptions, saveTaskOption)}
            options={saveOptions}
            onChange={item => {
              setState({ ...state, saveTaskOption: item.value });
            }}
          />
        </Section>

        <Section style={{ marginBottom: 0 }}>
          <HeaderText subHeader label="Set Task Dates:" />

          <TextLine bottom={25} label="Earliest available start date:" value={Moment(earliestStartDate).format("M/D/YYYY") + " (In " + plural(Moment(earliestStartDate).diff(new Date(), "days"), "day") + ")"} />

          <Toggle
            label="Turbo boost task:"
            description="Need a quicker start date?"
            value={turboCharge}
            onChange={value => {
              if (value) {
                if (turboBoostsLeft <= 0) {
                  alert("You are out of the turbo boosts for this month.")
                  return
                }
              }
              model.turboCharge = value;

              if (!value) {
                const { startDate: earliestStartDate, dueDate: earliestDueDate } = getEarliestDates({ staffService, businessRoleTask, turboCharge: value, hours })

                if (delayStart && startDate) {
                  if (startDate < earliestStartDate) {
                    model.startDate = null
                  }
                }

                if (dueDate < earliestDueDate) {
                  model.dueDate = null
                }
              }
              setState({ ...state, model });
            }} />

          <Toggle
            label="Delay the start of this task:"
            value={delayStart}
            onChange={value => {
              model.delayStart = value;
              if (!value) {
                model.startDate = null
              }
              setState({ ...state, model });
            }}
          />

          <FlexRow>
            {delayStart ? (
              <FormItem box label="Start Date:" style={{ marginRight: 25, maxWidth: 200 }}>
                <DateTime2
                  isValidDate={(current) => {
                    return current.isAfter(earliestStartDate);
                  }}
                  value={startDate}
                  onChange={value => {
                    model.startDate = value
                    setState({ ...state, model });
                  }} />
              </FormItem>
            ) : null}

            {!delayStart || startDate ? <FormItem box label="Due Date:" description="Due date must be after start date." style={{ maxWidth: 200 }}>
              <DateTime2
                isValidDate={(current) => {
                  return current.isAfter(startDate ? startDate : earliestDueDate);
                }}
                value={dueDate}
                onChange={value => {
                  model.dueDate = value
                  setState({ ...state, model });
                }} />
            </FormItem> : null}
          </FlexRow>
        </Section>
      </React.Fragment> : null}
    </ModalBasic>
  );
}

export function EditTaskRecord({ recordId, onSave, onClose }) {
  const [state, setState] = useState({ isLoading: true });
  const { isTurboCharged, isLoading, model, staffService } = state;

  useEffect(() => {
    dL.getQuery("TaskRecord")
      .include("businessRoleTask")
      .get(recordId)
      .then(function (obj) {
        const task = dL.loadTaskRecord(obj);

        const { staffAugService } = task

        return dL.getQuery("StaffAugService")
          .include("businessRole")
          .get(staffAugService.id)
          .then(function (obj) {
            const staffService = dL.loadStaffAugService(obj);

            setState({ ...state, isTurboCharge: task.turboCharge, isLoading: false, model: task, staffService });
          })
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  const { turboBoostsLeft } = staffService

  const { businessRoleTask, turboCharge, delayStart, title, description, dueDate, deliverableNotes, hours, startDate } = model;

  const { startDate: earliestStartDate, dueDate: earliestDueDate } = getEarliestDates({ staffService, businessRoleTask, turboCharge, hours })

  return (
    <ModalBasic
      title="Edit Task"
      okText="Save"
      onCancel={() => {
        onClose();
      }}
      onOk={() => {
        if (!title) {
          alert("Must enter title.");
          return;
        }

        if (!utils.isNumber(hours)) {
          alert("Must enter valid not to exceed hours.");
          return;
        }
        model.priority = getTaskPriorityLevel({ staffService, turboCharge })
        model.hours = Number(hours);

        return dL.saveTaskRecord(recordId, model).then(function () {
          onSave();
        });
      }}>

      <Section>
        <MyInput
          required
          label="Title:"
          value={title}
          onChange={value => {
            model.title = value;
            setState({ ...state, model });
          }}
        />

        <MyInput
          required
          multiline
          label="Description:"
          value={description}
          onChange={value => {
            model.description = value;
            setState({ ...state, model });
          }}
        />

        <MyInput
          style={{ maxWidth: 125 }}
          required
          inputType="integer"
          minValue={0}
          label="Hours:"
          description="Consultant will NOT go beyond this time estimate with out checking with you to proceed."
          value={hours}
          onChange={value => {
            model.hours = value;
            setState({ ...state, model });
          }}
        />

        <MyInput
          required
          multiline
          label="Deliverables:"
          value={deliverableNotes}
          onChange={value => {
            model.deliverableNotes = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <Section style={{ marginBottom: 0 }}>
        <HeaderText subHeader label="Set Task Dates:" />

        <TextLine bottom={25} label="Earliest available start date:" value={Moment(earliestStartDate).format("M/D/YYYY") + " (In " + plural(Moment(earliestStartDate).diff(new Date(), "days"), "day") + ")"} />

        {!isTurboCharged ? <Toggle
          label="Turbo boost task:"
          description="Need a quicker start date?"
          value={turboCharge}
          onChange={value => {
            if (value) {
              if (turboBoostsLeft <= 0) {
                alert("You are out of the turbo boosts for this month.")
                return
              }
            }
            model.turboCharge = value;

            if (!value) {
              const { startDate: earliestStartDate, dueDate: earliestDueDate } = getEarliestDates({ staffService, businessRoleTask, turboCharge: value, hours })

              if (delayStart && startDate) {
                if (startDate < earliestStartDate) {
                  model.startDate = null
                }
              }

              if (dueDate < earliestDueDate) {
                model.dueDate = null
              }
            }
            setState({ ...state, model });
          }} /> : null}

        <Toggle
          label="Delay the start of this task:"
          value={delayStart}
          onChange={value => {
            model.delayStart = value;
            if (!value) {
              model.startDate = null
            }
            setState({ ...state, model });
          }}
        />

        <FlexRow>
          {delayStart ? (
            <FormItem box label="Start Date:" style={{ marginRight: 25, maxWidth: 200 }}>
              <DateTime2
                isValidDate={(current) => {
                  return current.isAfter(earliestStartDate);
                }}
                value={startDate}
                onChange={value => {
                  model.startDate = value
                  setState({ ...state, model });
                }} />
            </FormItem>
          ) : null}

          {!delayStart || startDate ? <FormItem box label="Due Date:" description="Due date must be after start date." style={{ maxWidth: 200 }}>
            <DateTime2
              isValidDate={(current) => {
                return current.isAfter(startDate ? startDate : earliestDueDate);
              }}
              value={dueDate}
              onChange={value => {
                model.dueDate = value
                setState({ ...state, model });
              }} />
          </FormItem> : null}
        </FlexRow>
      </Section>
    </ModalBasic>
  );
}

export function EditBlockingTaskRecord({ taskId, recordId, onSave, onCancel }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model } = state;

  useEffect(() => {
    dL.getQuery("TaskRecord")
      .include("businessRoleTask")
      .get(taskId)
      .then(function (obj) {
        const task = dL.loadTaskRecord(obj);
        setState({ ...state, isLoading: false, model: task });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const { businessRoleTask, turboCharge, delayStart, title, description, dueDate, deliverableNotes, hours, startDate } = model;

  return (
    <ModalBasic
      title="Edit Blocking Task"
      okText="Save"
      onCancel={onCancel}
      onOk={() => {
        if (!title) {
          alert("Must enter title.");
          return;
        }
        model.hours = 1
        model.blockingTask = { id: taskId }
        model.priority = 1

        return dL.saveTaskRecord(recordId, model).then(function () {
          onSave();
        });
      }}>

      <Section>
        Type: (need review, need approval, need questions answered )

        <MyInput
          required
          label="Title:"
          value={title}
          onChange={value => {
            model.title = value;
            setState({ ...state, model });
          }}
        />

        <MyInput
          required
          multiline
          label="Description:"
          value={description}
          onChange={value => {
            model.description = value;
            setState({ ...state, model });
          }}
        />

        Questions?
        Files?

        Assigned to (client, PM, SC, BD, other?)
      </Section>
    </ModalBasic>
  );
}

export function DisplayTaskRecord({ item, staffService }) {
  const { assignedTo, title, hours, startDate, dueDate, createdAt, createdBy, status, timeLogSeconds, staffAugService, businessRoleTask, turboCharge, serviceOrder } = item;

  return (
    <React.Fragment>
      <FlexRow alignTop>
        <FlexExpand>
          <TextLine bottom={12} value={title + " (" + (businessRoleTask ? "Pre-Defined" : serviceOrder ? "Service Order" : "Custom") + ")"} />
        </FlexExpand>

        {staffAugService && !staffService ? <LabelItem label="Staff Service:" value={staffAugService.businessRole.name + " (" + staffAugService.serviceType + ")"} /> : null}
      </FlexRow>

      <FlexRow alignTop>
        <FlexExpand>
          <FlexRow>
            <LabelItem label="Start Date:" value={Moment(startDate).format("M/D/YYYY")} hideIfNull />
            <LabelItem label="Due Date:" value={Moment(dueDate).format("M/D/YYYY")} hideIfNull />
            {turboCharge ? <LabelItem label="Turbo:" value={yesNo(turboCharge)} /> : null}
            <LabelItem label="Status:" value={getLabelItem(utils.taskRecordStatuses, status)} />
          </FlexRow>

          <FlexRow>
            <LabelItem label="Created At:" value={Moment(createdAt).format("M/D/YYYY [at] h:mm a")} />
            <LabelItem label="Hours:" value={hours} hideIfNull />
            <LabelItem label="Spent Hours:" value={plural(roundNumber(timeLogSeconds ? timeLogSeconds / 60 : 0, 1), "hr")} />
          </FlexRow>
        </FlexExpand>

        <View alignRight>
          <UserItem label="Assigned To:" user={assignedTo} />
          <View style={{ height: 20 }} />
          <UserItem label="Created By:" user={createdBy} />
        </View>
      </FlexRow>
    </React.Fragment>
  );
}

export function DisplayTasksWorker({ shares, tasks }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ tabValue2: "all", tabValue: "pending" });
  const { tabValue, tabValue2 } = state;

  const lists = {};
  var fTasks = tasks;
  if (tabValue2 != "all") {
    fTasks = tasks.filter(task => task.staffAugShare && task.staffAugShare.id == tabValue2);
  }
  lists.pending = fTasks.filter(item => {
    const { status, startDate } = item;
    return status == "pending" && startDate >= new Date();
  });
  lists.ready = fTasks.filter(item => item.status == "ready" && item.startDate <= new Date());
  lists.started = fTasks.filter(item => item.status == "started");
  lists.working = fTasks.filter(item => item.status == "working");
  lists.completed = fTasks.filter(item => item.status == "completed");
  lists.canceled = fTasks.filter(item => item.status == "canceled");

  const fList = lists[tabValue];

  var tabs = [{ label: "Pending", value: "pending" }, { label: "Ready to Start", value: "ready" }, { label: "Started", value: "started", autoHide: true }, { label: "Working", value: "working", autoHide: true }, { label: "Completed", value: "completed" }, { label: "Canceled", value: "canceled", autoHide: true }];

  const arr = [];
  tabs.forEach(item => {
    const { label, value, autoHide } = item;
    const count = lists[value].length;
    if (!autoHide || count > 0) {
      arr.push({ label: label + " (" + count + ")", value });
    }
  });
  tabs = arr;

  const tabs2 = [];

  shares.forEach(share => {
    const items = tasks.filter(task => task.staffAugShare && task.staffAugShare.id == share.id);
    const { assignedTo, id } = share;
    const count = items.length;
    if (count > 0) {
      tabs2.push({ label: assignedTo.firstName + " " + assignedTo.lastName + " (" + count + ")", value: id });
    }
  });

  tabs2.unshift({ label: "All (" + tasks.length + ")", value: "all" });

  return (
    <Section>
      <HeaderText subHeader label="Tasks:" description="Tasks that have been issued to you." />

      {tabs2.length > 1 ? (
        <TabBar
          queryId="tt"
          style={{ marginBottom: 20 }}
          options={tabs2}
          onChange={item => {
            setState({ ...state, tabValue2: item.value });
          }}
          value={tabValue2}
        />
      ) : null}

      <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {fList.length == 0 ? (
        <NoRecords label="No tasks created." />
      ) : (
        fList.map((item) => {
          const { id } = item;

          return (
            <BoxRowItem
              onPress={() => {
                history.push(`/${sectionId}/tasks/${id}`);
              }}>
              <DisplayTaskRecord item={item} />
            </BoxRowItem>
          );
        })
      )}
    </Section>
  );
}

export function DisplayTasks({ staffService, staffServiceShare, tasks, onRefresh }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ tabValue: "ready" });
  const { createMode, tabValue, deleteIndex, deleteId, showDelete, editId, editMode } = state;

  const lists = {};
  lists.pending = tasks.filter(item => {
    const { status, startDate } = item;
    return status == "pending" || (startDate && startDate > new Date())
  });
  lists.ready = tasks.filter(item => {
    const { status, startDate } = item;
    return status == "ready" && (startDate && startDate <= new Date())
  });
  lists.started = tasks.filter(item => item.status == "started");
  lists.working = tasks.filter(item => item.status == "working");
  lists.completed = tasks.filter(item => item.status == "completed");
  lists.canceled = tasks.filter(item => item.status == "canceled");

  const fList = lists[tabValue];

  var tabs = [{ label: "Pending", value: "pending", autoHide: true }, { label: "Ready to Start", value: "ready" }, { label: "Started", value: "started", autoHide: true }, { label: "Working", value: "working" }, { label: "Completed", value: "completed", autoHide: true }, { label: "Canceled", value: "canceled", autoHide: true }];

  tabs = tabs.map(item => {
    const { label, value, autoHide } = item;
    const count = lists[value].length;
    if (!autoHide || count > 0) {
      return { label: label + " (" + count + ")", value };
    }
  });

  return (
    <Section>
      <HeaderText
        subHeader
        label="Tasks:"
        description="Tasks that have been issued for this consultant."
        onButtonPress={() => {
          setState({ ...state, editId: null, createMode: true });
        }}
      />
      <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {fList.length == 0 ? (
        <NoRecords label="No tasks created." />
      ) : (
        fList.map((item, index) => {
          const { id, status } = item;

          return (
            <BoxRowItem
              onPress={() => {
                history.push(`/${sectionId}/tasks/${id}`);
              }}
              onEdit={status != "canceled" && status != "completed" ? () => {
                setState({ ...state, editId: id, editMode: true });
              } : null}
              onDelete={status == "pending" || status == "ready" ? () => {
                setState({ ...state, showDelete: true, deleteId: id, deleteIndex: index });
              } : null}>
              <DisplayTaskRecord staffService={staffService} item={item} />
            </BoxRowItem>
          );
        })
      )}

      {createMode ? <CreateTaskRecord
        staffService={staffService} staffServiceShare={staffServiceShare}
        onClose={() => {
          setState({ ...state, createMode: false });
        }}
        onSave={() => {
          setState({ ...state, createMode: false });
          onRefresh();
        }}
      /> : editMode ? (
        <EditTaskRecord
          recordId={editId}
          onClose={() => {
            setState({ ...state, editMode: false });
          }}
          onSave={() => {
            setState({ ...state, editMode: false });
            onRefresh();
          }}
        />
      ) : showDelete ? (
        <DeleteConfirm
          onCancel={() => {
            setState({ ...state, showDelete: false });
          }}
          onConfirm={() => {
            tasks.splice(deleteIndex, 1);
            dL.cancelTaskRecord({ recordId: deleteId })
            setState({ ...state, showDelete: false });
            onRefresh();
          }}
        />
      ) : null}
    </Section>
  );
}

export function TasksAdmin() {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      searchFields={[{ field: "title" }]}
      defaultSort={{ field: "createdAt", desc: true }}
      filters={[
        { label: "All", value: "all", disableStat: true },
        {
          label: "Open",
          value: "open",
          onQuery: function (query) {
            query.containedIn("status", ["pending", "working"]);
          }
        },
        {
          label: "Not Assigned",
          value: "not-assigned",
          onQuery: function (query) {
            query.existsObj("assignedTo", false);
          }
        },
        {
          label: "Past Due",
          value: "past",
          onQuery: function (query) {
            query.containedIn("status", ["pending", "working"]);
            query.exists("dueDate", false);
            query.lessThan("dueDate", new Date());
          }
        },
        { label: "Working", value: "working" },
        { label: "Completed", value: "completed" }
      ]}
      type="TaskRecord"
      emptyLabel="No tasks found."
      includes={["createdBy", "assignedTo"]}
      title="Tasks"
      description="Manage tasks."
      renderItem={item => {
        const { id } = item;

        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/tasks/${id}`);
            }}>
            <DisplayTaskRecord item={item} />
          </BoxRowItem>
        );
      }}
    />
  );
}

export function RenderTasks({ tasks }) {
  const [state, setState] = useState({});
  const { taskVisible } = state;

  return <React.Fragment>
    {tasks.map(item => {
      const { hours, task, hoursLeft } = item
      const { title, hours: totalHours, serviceOrder } = task
      return <BoxItem name={title ? title : "Empty"} rightRender={<TextLine value={plural(roundNumber(hours, 1), "hr")} />} onPress={() => {
        setState({ ...state, taskVisible: task.id })
      }}>
        {serviceOrder && true == false ? <TextLine size={14} bottom={10} label="Service Order #:" value={serviceOrder.orderNumber} /> : null}

        {totalHours != hours ?
          <FlexRow>
            <TextLine size={14} value={"Total Hours: " + plural(roundNumber(totalHours, 1), "hr")} spacer />
            <TextLine size={14} value={"Hours Left: " + plural(roundNumber(hoursLeft, 1), "hr")} />
          </FlexRow>
          : null}
      </BoxItem>
    })}

    {taskVisible ? <ViewTaskRecord recordId={taskVisible} onClose={() => {
      setState({ ...state, taskVisible: null })
    }} /> : null}
  </React.Fragment>
}

export function RenderBlockTasks({ tasks, onUpdate }) {
  const [state, setState] = useState({});
  const { taskBlockVisible, taskVisible } = state;

  return <React.Fragment>
    {tasks.map(task => {
      const { hours, title, serviceOrder } = task
      return <BoxItem name={title} onPress={() => {
        setState({ ...state, taskVisible: task.id })
      }}>
        <FlexRow>
          <FlexExpand>
            {serviceOrder && true == false ? <TextLine size={14} bottom={10} label="Service Order #:" value={serviceOrder.orderNumber} /> : null}

            <TextLine size={14} value={"Hours: " + plural(roundNumber(hours, 1), "hr")} />
          </FlexExpand>

          <TouchButton micro label="Add Blocking Task" onPress={() => {
            setState({ ...state, taskBlockVisible: task })
          }} />
        </FlexRow>
      </BoxItem>
    })}

    {taskBlockVisible ? <EditBlockingTaskRecord taskId={taskBlockVisible.id} onCancel={() => {
      setState({ ...state, taskBlockVisible: null })
    }} onSave={() => {
      onUpdate(taskBlockVisible)
      setState({ ...state, taskBlockVisible: null })
    }} /> : null}

    {taskVisible ? <ViewTaskRecord recordId={taskVisible} onClose={() => {
      setState({ ...state, taskVisible: null })
    }} /> : null}
  </React.Fragment>
}

function ViewTaskCalendarDay({ day, onCancel }) {
  const [state, setState] = useState({});
  const { } = state;

  const { date, dt, tasks, maxHours, idealHours, staffRoles } = day

  var srHours = 0
  staffRoles.forEach(item => {
    const { staffRole } = item
    const srTasks = tasks.filter(item => item.task.staffAugService && item.task.staffAugService.id == staffRole.id)
    srHours += getHours(srTasks)
  })

  const soAllTasks = tasks.filter(item => !item.task.staffAugService)
  const soHours = getHours(soAllTasks)

  const hash = new HashTable()
  soAllTasks.forEach(item => {
    const serviceOrder = item.task.serviceOrder
    hash.set(serviceOrder.id, serviceOrder)
  })

  const serviceOrders = hash.values()

  return <ModalBasic
    large
    title="Day View:"
    okText="Close"
    onOk={onCancel}>
    {srHours == 0 && soHours == 0 ? <NoRecords label="No tasks found."></NoRecords> : null}

    {srHours > 0 ? <Section>
      <HeaderText subHeader label="Staff Roles:" rightRender={<TextLine size={14} value={plural(roundNumber(srHours, 1), "hr")} />} />

      {staffRoles.map(item => {
        const { staffRole, bookHours } = item
        const { name } = staffRole
        const srAllTasks = tasks.filter(item => item.task.staffAugService && item.task.staffAugService.id == staffRole.id)

        if (srAllTasks.length == 0) {
          return
        }

        const srTasks = getNotDueTasks({ date, tasks: srAllTasks })
        const srDueTasks = getDueTasks({ date, tasks: srAllTasks })
        const bookedHours = getHours(srAllTasks)

        return <BoxItem name={name + ":"}>
          <FlexRow>
            <TextLine size={14} label="Booked:" value={plural(roundNumber(bookedHours, 1), "hr")} spacer />
            <TextLine size={14} label="Bookable:" value={plural(roundNumber(bookHours, 1), "hr")} />
          </FlexRow>

          {srTasks.length > 0 ? <View top={15}>
            <TextLine size={14} bold value={"Scheduled Tasks:"} />
            <RenderTasks tasks={srTasks} />
          </View> : null}

          {srDueTasks.length > 0 ? <View top={15}>
            <TextLine size={14} bold value={"Due Tasks:"} />
            <RenderTasks tasks={srDueTasks} />
          </View> : null}
        </BoxItem>
      })}
    </Section> : null}

    {soHours > 0 ? <Section>
      <HeaderText subHeader label="Service Orders:" rightRender={<TextLine size={14} value={plural(roundNumber(soHours, 1), "hr")} />} />

      {serviceOrders.map(serviceOrder => {
        const { orderNumber, service } = serviceOrder
        const soATasks = soAllTasks.filter(item => item.task.serviceOrder.id == serviceOrder.id)
        const soTasks = getNotDueTasks({ date, tasks: soATasks })
        const soDueTasks = getDueTasks({ date, tasks: soATasks })

        return <BoxItem>
          <TextLine size={14} bold value={"Ref #:" + orderNumber} />
          <TextLine value={service.name} />

          {soTasks.length > 0 ? <View top={15}>
            <TextLine size={14} bold value={"Scheduled Tasks:"} />
            <RenderTasks tasks={soTasks} />
          </View> : null}

          {soDueTasks.length > 0 ? <View top={15}>
            <TextLine size={14} bold value={"Due Tasks:"} />
            <RenderTasks tasks={soDueTasks} />
          </View> : null}
        </BoxItem>
      })}

    </Section> : null}
  </ModalBasic>
}

const d1 = [
  {
    id: 1,
    name: "Client 1",
    hours: 20,
    priority: 1,
    endDate: Moment().add(2, "months").toDate()
  }, {
    id: 2,
    name: "Client 2",
    hours: 20,
    priority: 2,
    endDate: Moment().add(2, "weeks").toDate()
  }, {
    id: 3,
    name: "Client 3",
    hours: 80,
    priority: 3,
    endDate: Moment().add(6, "months").toDate()
  }
]

function generateFakeTasks() {
  const tasks = [
    {
      id: 9999,
      title: "Test 1",
      startDate: new Date(2021, 3, 22),
      hours: 2.25
    }
  ]

  const taskStaffTests = 25
  for (var i = 0; i < taskStaffTests; i++) {
    var staffRole = d1[randomNumber(0, d1.length - 1)]
    tasks.push({
      id: "s" + i,
      staffAugServiceId: staffRole.id,
      title: "Test Staff 1-" + i + "(" + staffRole.name + ")",
      startDate: new Date(2021, 3, randomNumber(19, 30)),
      hours: roundNumber(randomNumber(5, 35) / 10, 1)
    })
  }
  for (var i = 0; i < taskStaffTests; i++) {
    var staffRole = d1[randomNumber(0, d1.length - 1)]
    tasks.push({
      id: "s2" + i,
      staffAugServiceId: staffRole.id,
      title: "Test Staff 2-" + i + "(" + staffRole.name + ")",
      startDate: new Date(2021, 4, randomNumber(1, 30)),
      hours: roundNumber(randomNumber(5, 35) / 10, 1)
    })
  }

  const taskTests = 25
  for (var i = 0; i < taskTests; i++) {
    tasks.push({
      id: i,
      title: "Test " + i,
      startDate: new Date(2021, 3, randomNumber(19, 30)),
      hours: roundNumber(randomNumber(5, 35) / 10, 1)
    })
  }

  tasks.forEach(task => {
    const { startDate } = task
    task.dueDate = Moment(startDate).add(randomNumber(1, 6), "days").toDate()
  })
  return tasks
}

const getHours = function (tasks) {
  if (!tasks) { return 0 }
  var hours = 0
  tasks.forEach(task => {
    hours += task.hours
  })
  return hours
}

function getNotDueTasks({ date, tasks }) {
  return tasks.filter(item => {
    var dueDate = item.task ? item.task.dueDate : item.dueDate
    if (!dueDate) { dueDate = new Date() }
    return Moment(dueDate) > Moment(date).endOf("day")
  })
}

function getDueTasks({ date, tasks }) {
  return tasks.filter(item => {
    var dueDate = item.task ? item.task.dueDate : item.dueDate
    if (!dueDate) { dueDate = new Date() }
    return Moment(dueDate) <= Moment(date).endOf("day")
  })
}

function TaskCalendar() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [days, setDays] = useState()
  const [stats, setStats] = useState({})
  const [state, setState] = useState({ staffRoles: d1, isLoading: true });
  const { daysWorkLoad, stats2, timeRecords, dayVisible, overrideEdit, editValue, assignedTasks, isLoading, staffRoles } = state;

  const { maxHoursPerDay, maxHoursPerWeek, maxHoursPerMonth, workHoursOverrides: overrides } = session.user

  const getDaysWorkLoad = function ({ tasks }) {
    const days = {}
    const today = new Date()
    tasks.forEach(task => {
      var { startDate, dueDate, hoursLeft } = task
      if (startDate < today) {
        startDate = today
      }

      var diff = Moment(dueDate).diff(startDate, "days") + 1
      var date = Moment(startDate)
      if (diff < 1) { diff = 1 }
      for (var i = 0; i < diff; i++) {
        const dt = Moment(date).format("MM-DD-YYYY")
        const day = days[dt]
        if (!day) {
          days[dt] = {
            hours: 0
          }
        }
        days[dt].hours += hoursLeft / diff
        date = date.add(1, "days")
      }
    })

    return days
  }

  const getAverageHoursPerDay = function ({ tasks, nextDays }) {
    const days = {}
    const today = new Date()
    tasks.forEach(task => {
      var { startDate, dueDate, hoursLeft } = task
      if (startDate < today) {
        startDate = today
      }

      var diff = Moment(dueDate).diff(startDate, "days") + 1
      var date = Moment(startDate)
      if (diff < 1) { diff = 1 }
      for (var i = 0; i < diff; i++) {
        const dt = Moment(date).format("MM-DD-YYYY")
        const day = days[dt]
        if (!day) {
          days[dt] = {
            hours: 0
          }
        }
        days[dt].hours += hoursLeft / diff
        date = date.add(1, "days")
      }
    })

    var totalHours = 0
    if (tasks.length > 0) {
      var date = Moment()
      for (var i = 0; i < nextDays; i++) {
        const dt = Moment(date).format("MM-DD-YYYY")
        if (days[dt]) {
          totalHours += days[dt].hours
        }
        date = date.add(1, "days")
      }
    }

    return totalHours / nextDays
  }

  const setDataForStaffRoles = function ({ staffRoles, allUserTasks }) {
    staffRoles.forEach(staffRole => {
      const { id, hours, priority } = staffRole

      const tasksForRole = allUserTasks.filter(item => item.staffAugServiceId == id)
      staffRole.tasks = tasksForRole

      const hoursPerDay = hours / 20
      staffRole.dailyHours = hoursPerDay

      //min per day, max per day based on priority and hours per month for staff role
      var minHours, maxHours

      if (priority == 1) {
        minHours = 2.5
        maxHours = 1.25
      } else if (priority == 2) {
        minHours = 1.5
        maxHours = 1
      } else if (priority == 3) {
        minHours = 1.25
        maxHours = .75
      }

      staffRole.minHours = hoursPerDay * minHours
      staffRole.maxHours = hoursPerDay * maxHours

      //priority=1 - max: 2.5x min:1.25x
      //priority=2 - max: 1.5x min:1x
      //priority=3 - max:1.25x min:.75x
    })
  }

  const getHoursForStaffRoles = function ({ staffRoles }) {
    var totalHours = 0
    staffRoles.forEach(staffRole => {
      const { hours } = staffRole
      totalHours += hours
    })
    return totalHours
  }

  const getTimeAllocation = function ({ staffRoles }) {
    const hoursForStaffRolesPerMonth = getHoursForStaffRoles({ staffRoles })

    var forServiceOrders = 0
    var forStaffRoles = 0
    if (hoursForStaffRolesPerMonth > 0) {
      forStaffRoles = hoursForStaffRolesPerMonth / maxHoursPerMonth
      if (forStaffRoles > 1) {
        forStaffRoles = 1
      }
    }
    forServiceOrders = 1 - forStaffRoles
    if (forServiceOrders < 0) {
      forServiceOrders = 0
    }

    return { forServiceOrders, forStaffRoles }
  }

  const getBookedHours = function ({ days, startDate, endDate }) {
    var hours = 0
    var diff = Moment(endDate).diff(startDate, "days")
    var date = Moment(startDate)
    for (var i = 0; i < diff; i++) {
      const dt = Moment(date).format("MM-DD-YYYY")
      const day = days[dt]
      if (day) {
        const workHours = getHours(day.tasks)
        hours += workHours + day.workedHours
      }
      date = date.add(1, "days")
    }
    return hours
  }

  const processTasks = function ({ staffRoles, timeRecords, tasks: allUserTasks }) {
    const days = {}
    setDataForStaffRoles({ staffRoles, allUserTasks })

    //percent per day of hours for staff orders versus service orders based on 
    const { forServiceOrders: timeAllocationServiceOrders, forStaffRoles: timeAllocationStaffRoles } = getTimeAllocation({ staffRoles })

    var date = Moment().startOf("day")
    const hoursPerDay = maxHoursPerMonth / 20
    for (var i = 0; i < 120; i++) {
      const dt = Moment(date).format("MM-DD-YYYY")

      const overideMaxHours = overrides[dt]
      const dayOfWeek = date.day()
      const isWeekend = dayOfWeek == 0 || dayOfWeek == 6
      const isOverride = overideMaxHours != null ? true : false
      const overrideHours = isOverride ? overideMaxHours : null
      const maxHours = isOverride ? overrideHours : (isWeekend ? 0 : maxHoursPerDay)
      const idealHours = isOverride ? overrideHours : (isWeekend ? 0 : hoursPerDay)
      const day = {
        dt,
        date: date.toDate(),
        tasks: [],
        dueTasks: [],
        startTasks: [],
        isWeekend,
        isOverride,
        maxHours,
        idealHours,
        workedHours: 0
      }
      days[dt] = day

      date = date.add(1, "days")
    }

    timeRecords.forEach(item => {
      const { startDate, seconds } = item
      const hours = seconds / 60 / 60
      const dt = Moment(startDate).format("MM-DD-YYYY")
      const day = days[dt]
      if (day) {
        day.workedHours += hours
      }
    })

    const allTasks = [...allUserTasks].sort(function (a, b) {
      var aSize = a.dueDate;
      var bSize = b.startDate;
      var aLow = a.dueDate;
      var bLow = b.startDate;

      if (aSize == bSize) {
        return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
      }
      else {
        return (aSize < bSize) ? -1 : 1;
      }
    });

    const serviceTasks = allTasks.filter(item => !item.staffAugServiceId)

    var date = Moment()
    for (var v = 0; v < 120; v++) {
      const dt = Moment(date).format("MM-DD-YYYY")
      const day = days[dt]

      const staffRolesForDay = staffRoles.filter(staffRole => {
        const { endDate } = staffRole
        return date.toDate() <= endDate
      })

      const { isWeekend, maxHours, isOverride } = day

      const bookedThisWeek = getBookedHours({ days, startDate: Moment(date).startOf("week"), endDate: date })
      const bookedThisMonth = getBookedHours({ days, startDate: Moment(date).startOf("month"), endDate: date })

      var dayHoursLeft = isOverride ? maxHours : isWeekend ? 0 : maxHoursPerDay
      if (!isOverride) {
        if (bookedThisWeek + dayHoursLeft > maxHoursPerWeek) {
          dayHoursLeft = maxHoursPerWeek - bookedThisWeek
        }
        if (bookedThisMonth + dayHoursLeft > maxHoursPerMonth) {
          dayHoursLeft = maxHoursPerMonth - bookedThisMonth
        }
      }
      if (dayHoursLeft < 0) { dayHoursLeft = 0 }

      const defaultDailyMax = 10
      var maxDayHoursLeft
      if (isOverride) {
        maxDayHoursLeft = maxHours
      } else {
        maxDayHoursLeft = isWeekend ? 0 : defaultDailyMax
      }

      const maxHoursPerDayServiceOrders = timeAllocationServiceOrders * dayHoursLeft
      const maxHoursPerDayStaffRoles = timeAllocationStaffRoles * dayHoursLeft

      const avgDailyHoursServiceOrder = getAverageHoursPerDay({ tasks: allTasks, nextDays: 7 })
      if (Moment(day.date).date() == 11) {
        var tt = 1
      }
      day.bookedThisWeek = bookedThisWeek
      day.bookedThisMonth = bookedThisMonth
      day.avgDailyHoursServiceOrder = avgDailyHoursServiceOrder
      day.timeAllocationServiceOrders = timeAllocationServiceOrders
      day.timeAllocationStaffRoles = timeAllocationStaffRoles
      day.maxHoursPerDayServiceOrders = maxHoursPerDayServiceOrders
      day.maxHoursPerDayStaffRoles = maxHoursPerDayStaffRoles

      const allocateTasks = function ({ day, maxHours, tasks }) {
        var maxUseHours = maxHours

        const rtnTasks = []
        do {
          if (maxUseHours <= 0 || maxDayHoursLeft <= 0) {
            break
          }

          const canAllocateTasks = tasks.filter(task => {
            const { hoursLeft, startDate } = task
            return Moment(day.date).toDate() >= startDate && hoursLeft > 0
          })

          if (canAllocateTasks.length == 0) {
            break
          }

          const task = canAllocateTasks[0]

          if (task.staffAugService) {
            var tt = 1
          }
          var { hoursLeft } = task
          var alcHours = hoursLeft
          if (alcHours > maxUseHours) {
            alcHours = maxUseHours
          }
          hoursLeft -= alcHours
          maxUseHours -= alcHours
          maxDayHoursLeft -= alcHours
          dayHoursLeft -= alcHours
          task.hoursLeft = hoursLeft

          const data = { hours: alcHours, task, hoursLeft }
          rtnTasks.push(data)
          day.tasks.push(data)
        } while (true == true)

        return rtnTasks
      }

      if (Moment(day.date).date() == 26) {
        var tt = 1
      }
      const dueTasks = getDueTasks({ date, tasks: allTasks })
      const startedTasks = dueTasks.filter(task => {
        const { status } = task
        return status == "started" && (!task.blockingTasks || task.blockingTasks.filter(task => task.status != "completed" && task.status != "canceled").length == 0)
      })
      allocateTasks({ day, maxHours: maxDayHoursLeft, tasks: startedTasks })
      allocateTasks({ day, maxHours: maxDayHoursLeft, tasks: dueTasks })

      const r1 = []
      for (var i = 0; i < staffRolesForDay.length; i++) {
        const staffRole = staffRolesForDay[i]
        const { minHours, maxHours, tasks } = staffRole

        const dailyHourAverage = getAverageHoursPerDay({ tasks, nextDays: 14 })

        var bookHours = dailyHourAverage
        if (bookHours > maxHours) {
          bookHours = maxHours
        }
        if (bookHours < minHours) {
          bookHours = minHours
        }
        if (bookHours > maxHoursPerDayStaffRoles) {
          bookHours = maxHoursPerDayStaffRoles
        }

        allocateTasks({ day, maxHours: bookHours, tasks })
        r1.push({ bookHours, staffRole, dailyHourAverage })
      }

      day.staffRoles = r1
      allocateTasks({ day, maxHours: dayHoursLeft, tasks: serviceTasks })

      date = date.add(1, "days")
    }

    return days
  }

  useEffect(() => {
    //get total for the day
    const stats = {}
    var promises = [];
    promises[promises.length] = dL.getTimeRecordHours({ startDate: Moment().startOf("day").toDate(), endDate: Moment().endOf("day").toDate() }).then(function (hours) {
      stats.dayHours = hours
    })
    promises[promises.length] = dL.getTimeRecordHours({ startDate: Moment().startOf("week").toDate(), endDate: Moment().endOf("day").toDate() }).then(function (hours) {
      stats.weekHours = hours
    })
    promises[promises.length] = dL.getTimeRecordHours({ startDate: Moment().startOf("month").toDate(), endDate: Moment().endOf("day").toDate() }).then(function (hours) {
      stats.monthHours = hours
    })
    return Promise.all(promises).then(function () {
      setStats(stats)
    })
  }, [])

  useEffect(() => {
    db
      .getQuery("StaffAugService")
      .equalTo("status", "open")
      .greaterThan("endDate", new Date())
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .include("user")
      .include("company")
      .find()
      .then(function (objs) {
        var staffRoles = dL.loadObjects("StaffAugService", objs)
        var timeRecords
        var tasks1
        var tasks2
        staffRoles.forEach(item => {
          item.name = item.user ? item.user.name : item.id
          item.tasks = []
        })

        const promises = []

        promises[promises.length] = db
          .getQuery("TimeRecord")
          .greaterThan("endDate", Moment().startOf("month").toDate())
          .equalTo("user", db.getObj("User", session.user.id))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            timeRecords = dL.loadObjects("TimeRecord", objs)
          })

        promises[promises.length] = db
          .getQuery("TaskRecord")
          .notContainedIn("status", ["completed", "canceled"])
          .existsObj("staffAugService", false)
          .equalTo("assignedTo", db.getObj("User", session.user.id))
          .containedIn("removed", [undefined, false])
          .ascending("dueDate")
          .descending("priorityLevel")
          .include("serviceOrder")
          .include("serviceOrder.service")
          .find()
          .then(function (objs) {
            tasks1 = dL.loadObjects("TaskRecord", objs)
          })

        //get the tasks for each staff role that are required to be completed
        promises[promises.length] = db
          .getQuery("TaskRecord")
          .notContainedIn("status", ["completed", "canceled"])
          .containedInO("staffAugService", staffRoles.map(item => db.getObj("StaffAugService", item.id)))
          .equalTo("assignedTo", db.getObj("User", session.user.id))
          .containedIn("removed", [undefined, false])
          .ascending("dueDate")
          .descending("priorityLevel")
          .find()
          .then(function (objs) {
            tasks2 = dL.loadObjects("TaskRecord", objs)

            //sort the staff roles based on the earliest due date completion for the tasks
            const staffRoleIds = {}
            staffRoles.forEach(item => staffRoleIds[item.id] = item)
            tasks2.forEach(item => {
              const staffRole = staffRoleIds[item.staffAugService.id]
              if (!staffRole.nextDueDate || staffRole.nextDueDate > item.dueDate) {
                staffRole.nextDueDate = item.dueDate
              }
              staffRole.tasks.push(item)
            })

            staffRoles = staffRoles.sort(function (a, b) {
              return a.nextDueDate - b.nextDueDate;
            })
          });

        return Promise.all(promises).then(function () {
          const tasks = tasks1.concat(tasks2)

          tasks.forEach(task => {
            const { hours } = task
            if (!hours) { task.hours = 1 }
          })

          timeRecords.forEach(item => {
            const { seconds, task } = item
            const hours = seconds / 60 / 60
            var taskItem = tasks.find(item => item.id == task.id)
            if (taskItem) {
              taskItem.timeHours += hours
            }
          })

          tasks.forEach(task => {
            const { hours, timeHours } = task
            task.hoursLeft = hours
            if (timeHours) {
              task.hoursLeft -= timeHours
            }
          })

          const daysWorkLoad = getDaysWorkLoad({ tasks })
          const days = processTasks({ staffRoles, timeRecords, tasks })
          setDays(days)

          const stats2 = {}
          //get the queued tasks based on the due dates (get the number of hours)
          //also get the average workload for the next 7, 14, 30 days
          //also show the average workload per day based on scheduled

          setState({ ...state, daysWorkLoad, stats2, timeRecords, staffRoles, isLoading: false, assignedTasks: tasks })
        })
      });
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const saveOverrides = function () {
    const days = processTasks({ staffRoles, timeRecords, tasks: assignedTasks })
    setDays(days)

    return dL.getObj("User", session.user.id)
      .set("workHoursOverrides", JSON.stringify(overrides))
      .save()
  }

  const renderDay = function (date) {
    const dt = Moment(date).format("MM-DD-YYYY")
    const workLoad = daysWorkLoad[dt]
    const day = days[dt]

    if (Moment(date).date() == 26) {
      var tt = 1
    }

    if (!day) {
      return <View key={dt} style={{ alignSelf: "stretch", padding: 4, display: "flex", border: "1px solid #c0c0c0", borderRadius: "6px", flex: 1, minHeight: 150, marginRight: 5, flexDirection: "column" }}>
        <TextLine bold size={14} value={date.format("Do (dd)")} />
      </View>
    }

    const { bookedThisWeek, bookedThisMonth, timeAllocationServiceOrders, timeAllocationStaffRoles, maxHoursPerDayServiceOrders, maxHoursPerDayStaffRoles, avgDailyHoursServiceOrder, isOverride, maxHours } = day
    const { tasks, staffRoles } = day

    const dueTasks = getDueTasks({ date, tasks })
    const soTasks = tasks.filter(item => !item.task.staffAugService)

    const workHours = getHours(tasks)
    const dueHours = getHours(dueTasks)

    const workHoursSO = getHours(soTasks)

    const cont = date >= Moment().startOf("day").toDate()

    return <View key={dt} style={{ alignSelf: "stretch", padding: 4, display: "flex", border: "1px solid #c0c0c0", borderRadius: "6px", flex: 1, minHeight: 150, marginRight: 5, flexDirection: "column" }} onPress={() => {
      setState({ ...state, dayVisible: day })
    }}>
      <TextLine bold size={14} value={date.format("Do (dd)")} />
      {cont ? <React.Fragment>
        <View style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <View>
            <TextLine size={13} value={plural(tasks.length + dueTasks.length, "task")} />
            {workHours ? <TextLine size={13} value={plural(roundNumber(workHours, 1), "hr")} /> : null}
            {dueHours ? <TextLine size={11} color="red" label="Due:" value={plural(roundNumber(dueHours, 1), "hr")} /> : null}
          </View>

          <View top={10}>
            {workHoursSO > 0 ? <View>
              <TextLine size={13} value={"Services: " + plural(roundNumber(workHoursSO, 1), "hr")} />
            </View> : null}

            {staffRoles.map(item => {
              const { staffRole } = item
              const srTasks = tasks.filter(item => item.task.staffAugService && item.task.staffAugService.id == staffRole.id)
              const { name } = staffRole

              if (srTasks.length > 0) {
                const hours = getHours(srTasks)

                return <View>
                  <TextLine size={13} value={name + ": " + plural(roundNumber(hours, 1), "hr")} />
                </View>
              }
            })}
          </View>
        </View>

        <View top={10}>
          <TextLine size={12} label="Earnings:" value={money(workHours * session.user.minHourlyRate)} />

          <FlexRow>
            <FlexExpand>
              <TextLine size={12} label="Max:" value={plural(maxHours, "hr") + (isOverride ? " **" : "")} />
            </FlexExpand>

            <TouchableOpacity
              style={{ marginLeft: 8 }}
              onPress={() => {
                setState({ ...state, overrideEdit: dt, editValue: maxHours });
              }}>
              <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 13, color: "#c0c0c0" }} />
            </TouchableOpacity>
          </FlexRow>
        </View>

        <View top={10}>
          <TextLine size={10} value="Debug Data:" />
          {workLoad ? <TextLine size={10} bottom={6} label="work load:" value={plural(roundNumber(workLoad.hours, 1), "hr")} /> : null}

          <TextLine size={10} label="book/week:" value={plural(roundNumber(bookedThisWeek, 1), "hr")} />
          <TextLine size={10} label="book/month:" value={plural(roundNumber(bookedThisMonth, 1), "hr")} />

          <TextLine size={10} label="dHrAvg:" value={plural(roundNumber(avgDailyHoursServiceOrder, 1), "hr")} />
          <TextLine size={10} label="timeSO:" value={roundNumber(timeAllocationServiceOrders * 100) + "%"} />
          <TextLine size={10} label="timeSR:" value={roundNumber(timeAllocationStaffRoles * 100) + "%"} />
          <TextLine size={10} label="maxHrSO:" value={plural(roundNumber(maxHoursPerDayServiceOrders, 1), "hr")} />
          <TextLine size={10} label="maxHrSR:" value={plural(roundNumber(maxHoursPerDayStaffRoles, 1), "hr")} />

          {staffRoles.map(item => {
            const { staffRole, dailyHourAverage, bookHours } = item

            return <View top={6}>
              <TextLine size={10} value={staffRole.name + ":"} />
              <TextLine size={10} label="dHrAvg:" value={plural(roundNumber(dailyHourAverage, 1), "hr")} />
              <TextLine size={10} label="bHr:" value={plural(roundNumber(bookHours, 1), "hr")} />
            </View>
          })}
        </View>
      </React.Fragment> : null}
    </View>
  }

  const renderWeek = function (startDate) {
    var date = Moment(startDate)
    const dt1 = Moment(startDate).format("MM-DD-YYYY")
    const dayViews = []
    var totalWorkHours = 0
    for (var i = 0; i < 7; i++) {
      const dt = Moment(date).format("MM-DD-YYYY")
      const day = days[dt]

      dayViews.push(renderDay(date))
      if (day) {
        totalWorkHours += getHours(day.tasks)
      }
      date = date.add(1, "day")
    }

    const overrideMaxHours = overrides[dt1 + "-week"]
    const isOverride = overrideMaxHours != null ? true : false
    const maxHoursThisWeek = isOverride ? overrideMaxHours : maxHoursPerWeek

    return <View bottom={25}>
      <FlexRow bottom={10} >
        <FlexExpand>
          <TextLine size={18} label="Week Of" value={Moment(startDate).format("MMM Do, YYYY") + ":"} />
        </FlexExpand>

        <View alignRight>
          <TextLine size={18} value={plural(roundNumber(totalWorkHours, 1), "hr")} />

          <FlexRow>
            <TextLine size={12} value={"Max: " + plural(roundNumber(maxHoursThisWeek, 1), "hr") + (isOverride ? " **" : "")} />
            <TouchableOpacity
              style={{ marginLeft: 8 }}
              onPress={() => {
                setState({ ...state, overrideEdit: dt1 + "-week", editValue: maxHoursThisWeek });
              }}>
              <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 12, color: "#c0c0c0" }} />
            </TouchableOpacity>
          </FlexRow>
        </View>
      </FlexRow>

      <FlexRow key={dt1}>
        {dayViews}
      </FlexRow>
    </View>
  }

  const { dayHours, weekHours, monthHours } = stats

  const getQHours = function ({ startDate, endDate }) {
    var diff = Moment(endDate).diff(startDate, "days") + 1
    var date = Moment(startDate)
    var hours = 0
    for (var i = 0; i < diff; i++) {
      const dt = Moment(date).format("MM-DD-YYYY")
      const day = days[dt]
      if (day) {
        hours += getHours(day.tasks)
      }
      date = date.add(1, "days")
    }

    return hours
  }
  return <View>
    {dayVisible ? <ViewTaskCalendarDay day={dayVisible} onCancel={() => {
      setState({ ...state, dayVisible: false })
    }}></ViewTaskCalendarDay> : null}

    <View bottom={10}>
      <FlexRow>
        <TextLine value="Max Hours:" spacer />
        <TextLine label="Daily:" value={plural(maxHoursPerDay, "hr")} spacer />
        <TextLine label="Weekly:" value={plural(maxHoursPerWeek, "hr")} spacer />
        <TextLine label="Monthly:" value={plural(maxHoursPerMonth, "hr")} />

        <TouchableOpacity
          style={{ marginLeft: 10 }}
          onPress={() => {
            alert("Not yet implemented.")
          }}>
          <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 16, color: "#c0c0c0" }} />
        </TouchableOpacity>
      </FlexRow>
    </View>

    <View bottom={10}>
      <FlexRow>
        <TextLine value="Hours Worked:" spacer />
        <TextLine label="Today:" value={plural(dayHours, "hr")} spacer />
        <TextLine label="This Week:" value={plural(weekHours, "hr")} spacer />
        <TextLine label="This Month:" value={plural(monthHours, "hr")} />
      </FlexRow>
    </View>

    <View bottom={10}>
      <FlexRow>
        <TextLine value="Queued Tasks:" spacer />

        <TextLine label="Today:" value={plural(getQHours({ startDate: Moment().startOf("day"), endDate: Moment().add(1, "day").startOf("day") }), "hr")} spacer />

        <TextLine label="This Week:" value={plural(getQHours({ startDate: Moment().startOf("day"), endDate: Moment().endOf("week") }), "hr")} spacer />

        <TextLine label="Next Week:" value={plural(getQHours({ startDate: Moment().add(1, "week").startOf("week").startOf("day"), endDate: Moment().add(1, "week").endOf("week").startOf("day") }), "hr")} spacer />

        <TextLine label="30 Days:" value={plural(roundNumber(getQHours({ startDate: Moment().startOf("day"), endDate: Moment().add(30, "days").startOf("day") }) / 4, 1), "hr")} spacer />
      </FlexRow>
    </View>

    <View>
      {[0, 1, 2, 3, 4, 5, 6, 7].map(index => {
        return renderWeek(Moment().add(index, "week").startOf("week"))
      })}
    </View>

    {overrideEdit ? (
      <ModalBasic
        title="Edit Day:"
        onCancel={() => {
          setState({ ...state, overrideEdit: null });
        }}
        buttons={[{
          label: "Remove Override", onPress: () => {
            delete overrides[overrideEdit];
            saveOverrides();
            setState({ ...state, overrideEdit: null });
          }
        }]}
        okText="Save"
        onOk={() => {
          if (!utils.isNumber(editValue)) {
            alert("Must enter valid value.");
            return;
          }
          overrides[overrideEdit] = Number(editValue);

          saveOverrides();
          setState({ ...state, overrideEdit: null });
        }}>
        <MyInput
          label="Max Hours:"
          placeholder="Hours"
          boxStyle={{ marginBottom: 0 }}
          style={{ maxWidth: 150 }}
          inputType="integer"
          minValue={0}
          value={editValue}
          onChange={value => {
            setState({ ...state, editValue: value });
          }}
        />
      </ModalBasic>
    ) : null}
  </View>
}

export function TasksWorker() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ tabValue: "calendar", isLoading: true });
  const { tasksNeedBlocking, tabValue, isLoading, staffRoles } = state;

  useEffect(() => {
    db
      .getQuery("StaffAugService")
      .equalTo("status", "open")
      .greaterThan("endDate", new Date())
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .include("user")
      .find()
      .then(function (objs) {
        var staffRoles = dL.loadObjects("StaffAugService", objs)
        var tasksNeedBlocking
        staffRoles.forEach(item => {
          item.tasks = []
        })

        db
          .getQuery("TaskRecord")
          .equalTo("status", "started")
          .lessThan("startedAt", Moment().add(-48, "hours").toDate())
          .equalTo("assignedTo", db.getObj("User", session.user.id))
          .containedIn("removed", [undefined, false])
          .include("blockingTasks")
          .find()
          .then(function (objs) {
            tasksNeedBlocking = dL.loadObjects("TaskRecord", objs)
            tasksNeedBlocking = tasksNeedBlocking.filter(task => {
              return !task.blockingTasks || task.blockingTasks.filter(task => task.status != "completed" && task.status != "canceled").length == 0
            })
            db
              .getQuery("TaskRecord")
              .notContainedIn("status", ["completed", "canceled"])
              .containedInO("staffAugService", staffRoles.map(item => db.getObj("StaffAugService", item.id)))
              .equalTo("assignedTo", db.getObj("User", session.user.id))
              .containedIn("removed", [undefined, false])
              .ascending("dueDate")
              .descending("priorityLevel")
              .find()
              .then(function (objs) {
                const tasks = dL.loadObjects("TaskRecord", objs)

                //sort the staff roles based on the earliest due date completion for the tasks
                const staffRoleIds = {}
                staffRoles.forEach(item => staffRoleIds[item.id] = item)
                tasks.forEach(item => {
                  const staffRole = staffRoleIds[item.staffAugService.id]
                  if (!staffRole.nextDueDate || staffRole.nextDueDate > item.dueDate) {
                    staffRole.nextDueDate = item.dueDate
                  }
                  staffRole.tasks.push(item)
                })

                staffRoles = staffRoles.sort(function (a, b) {
                  return a.nextDueDate - b.nextDueDate;
                })
                setState({ ...state, tasksNeedBlocking, isLoading: false, staffRoles })
              });
          })
      });
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const alerts = []
  const displayTasks = 3

  const tabs = [
    { label: "Work Calendar", value: "calendar" },
    { label: "Task List", value: "list" }
  ]

  //alert: need to add block task for started tasks (that have been started for too long)

  const renderTab = function () {
    return <TabBar
      queryId="tb"
      style={{ marginBottom: 20 }}
      options={tabs}
      onChange={item => {
        setState({ ...state, tabValue: item.value });
      }}
      value={tabValue}
    />
  }

  return (
    <React.Fragment>
      {tasksNeedBlocking.length > 0 ?
        <BoxItem name="Alert: Tasks that beed blocking task added:">
          <RenderBlockTasks tasks={tasksNeedBlocking} onUpdate={(rtn) => {
            const index = tasksNeedBlocking.find(item => item.id == rtn.id)
            tasksNeedBlocking.splice(index, 1)
            setState({ ...state, tasksNeedBlocking })
          }} />
        </BoxItem>
        : null}

      {tabValue == "list" ?
        <React.Fragment>
          <HeaderText label="Task List:" rightRender={renderTab()} />

          <Section>
            <HeaderText
              subHeader
              label="Tasks for staff roles:"
              description="Tasks that have been issued to you based on service orders." />

            {staffRoles.map(item => {
              const { priorityLevel, user, tasks, businessRole, skillLevel, hoursPerMonth, id } = item

              return <BoxRowItem>
                <FlexRow alignTop>
                  <FlexExpand>
                    <TextLine value={getLabelItem(skillLevels, skillLevel) + " " + businessRole.name} size={18} onPress={() => {
                      history.push(`/${sectionId}/staff-service/${id}`);
                    }} />

                    <FlexRow top={4}>
                      <TextLine size={14} label="Hours:" value={plural(hoursPerMonth, "hr") + "/month"} spacer />
                      <TextLine size={14} label="Priority:" value={getLabelItem(priorityLevels, priorityLevel)} />
                    </FlexRow>
                  </FlexExpand>

                  <UserItem inline label="Client:" user={user} />
                </FlexRow>

                <View top={14}>
                  {tasks.length > 0 ?
                    <React.Fragment>
                      {tasks.slice(0, displayTasks).map(task => {
                        const { id, title, status } = task
                        return <BoxRowItem onPress={() => {
                          history.push(`/worker/tasks/${id}`)
                        }}>
                          <FlexRow>
                            <FlexExpand>
                              <TextLine value={title} />
                            </FlexExpand>
                            <TextLine value={getTaskStatus(status)} />
                          </FlexRow>
                        </BoxRowItem>
                      })}

                      {tasks.length > displayTasks ? <TextLine size={14} value={plural(tasks.length - displayTasks, "additional task")} /> : null}
                    </React.Fragment>
                    : <TextLine size={14} value={"0 Tasks Submitted"} />}
                </View>
              </BoxRowItem>
            })}
          </Section>

          <ListRender
            searchFields={[{ field: "title" }]}
            defaultSort={{ field: "createdAt", desc: true }}
            onWhere={query => {
              const userId = session.user.id;

              query.existsObj("serviceOrder", true);
              query.equalTo("assignedTo", dL.getObj("User", userId));
            }}
            filters={[
              {
                label: "Pending",
                value: "pending"
              },
              {
                label: "Ready to Start",
                value: "ready"
              },
              {
                label: "Started",
                value: "started"
              },
              {
                label: "Working",
                value: "working"
              },
              {
                label: "Past Due",
                value: "past",
                onQuery: function (query) {
                  query.containedIn("completed", [null, false]);
                  query.exists("dueDate", false);
                  query.lessThan("dueDate", new Date());
                }
              },
              { label: "Completed", value: "completed" }
            ]}
            type="TaskRecord"
            emptyLabel="No tasks found."
            includes={["createdBy", "assignedTo", "staffAugService", "staffAugService.businessRole", "staffAugShare", "serviceOrder"]}
            subHeader
            title="Tasks for service orders:"
            description="Tasks that have been issued to you based on service orders."
            renderItem={item => {
              const { id } = item;

              return (
                <BoxRowItem
                  onPress={() => {
                    history.push(`/${sectionId}/tasks/${id}`);
                  }}>
                  <DisplayTaskRecord item={item} />
                </BoxRowItem>
              );
            }}
          />
        </React.Fragment> : null}

      {tabValue == "calendar" ?
        <React.Fragment>
          <HeaderText label="Work Calendar" rightRender={renderTab()} />

          <TaskCalendar />
        </React.Fragment> : null}
    </React.Fragment>
  );
}

export function ViewTaskRecord({ recordId, onClose }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model, times } = state;

  useEffect(() => {
    dL.getQuery("TaskRecord")
      .include("assignedTo")
      .include("createdBy")
      .include("service")
      .include("serviceOrder")
      .include("order")
      .include("staffAugShare")
      .include("staffAugService")
      .include("staffAugService.user")
      .include("staffAugService.businessRole")
      .get(recordId)
      .then(function (obj) {
        const task = dL.loadTaskRecord(obj);
        return db
          .getQuery("TimeRecord")
          .equalTo("task", dL.getObj("TaskRecord", recordId))
          .containedIn("removed", [undefined, false])
          .include("user")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            const times = dL.loadObjects("TimeRecord", objs);
            setState({ ...state, isLoading: false, model: task, times });
          });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalBasic
      large
      title="View Task"
      onCancel={onClose}
      buttons={[{
        label: "Details", onPress: () => {
          history.push(`/${sectionId}/tasks/${recordId}`);
        }
      }]}>
      <Section>
        <HeaderText subHeader label="Details:" />
        <DisplayTaskRecord item={model} />
      </Section>

      <Section>
        <HeaderText subHeader label="Time Log:" description="Your time log for this task." />

        {times.length == 0 ? (
          <NoRecords label="No time records found." />
        ) : (
          times.map(item => {
            return (
              <RenderTimeLog item={item} />
            );
          })
        )}
      </Section>
    </ModalBasic>
  );
}