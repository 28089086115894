import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { ServiceCatList } from "root/pack-3";
import { __DEV__ } from "root/dev";
import renderHTML from "react-render-html";
import { Card2, ListRender, HeaderText } from "root/pack-2";
import { FormBuilder, FormEditor } from "root/forms";
import { FlexExpand, SortableList, BoxItem, DeleteConfirm, Toggle, Section, EditDialog, ModalBasic, LabelItem, LinkButton, Hide, FlexRow, TabBar, Text, TextLine, View, MyInput, TouchButton, SelectBox, NoRecords } from "root/pack-1";
import { UserList } from "root/users";
import { Portal } from "react-portal";

const { skillLevels: skillLevelsList, serviceTypes, getLabelItem, plural, money } = utils;

function SelectBusinessRoleDialog({ allowCreate, onCancel, onSelect }) {
  const [state, setState] = useState({ isLoading: true });
  const { editBusinessRoleVisible, category, rolePeople, sectorPeople, isLoading, list, searchText } = state;

  var foundCategory;
  if (category) {
    foundCategory = utils.serviceTypes.find(item => item.value == category);
  }

  useEffect(() => {
    dL.getBusinessRoles().then(function (roles) {
      return dL.getPeoplePerSector().then(function (sectorPeople) {
        return dL.getPeoplePerBusinessRole().then(function (rolePeople) {
          setState({ ...state, isLoading: false, list: roles, rolePeople, sectorPeople });
        });
      });
    });
  }, []);

  if (isLoading) {
    return <View />
  }

  var fList = list;
  if (category) {
    fList = fList.filter(item => item.businessSector == foundCategory.value);
  }

  const getBusinesRolesInSector = function (sectorValue) {
    return list.filter(item => item.businessSector == sectorValue).length;
  };

  const buttons = [];

  if (foundCategory) {
    buttons.push({
      label: "Back",
      onPress: () => {
        setState({ ...state, category: null });
      }
    });
  }

  return (
    <Portal>
      <ModalBasic notCentered full title={foundCategory ? foundCategory.label + " Roles" : "Business Roles"} onCancel={onCancel} buttons={buttons}>
        <FlexRow
          style={{ marginBottom: 28 }}>
          <MyInput
            boxStyle={{ marginBottom: 0, flex: 1 }}
            placeholder="Search..."
            value={searchText}
            onChange={text => {
              setState({ ...state, searchText: text });
            }}
          />
          {allowCreate ? <TouchButton style={{ marginLeft: 25 }} label="Create New Role" onPress={() => {
            setState({ ...state, editBusinessRoleVisible: true })
          }} /> : null}
        </FlexRow>

        {searchText || foundCategory ? (
          <BusinessRoleList
            list={fList}
            rolePeople={rolePeople}
            searchText={searchText}
            onSelect={item => {
              onSelect(item);
            }}
            showButton={true}
          />
        ) : (
          <ServiceCatList
            onShouldDisplay={sectorValue => {
              const num1 = getBusinesRolesInSector(sectorValue);
              return num1 && num1 > 0;
            }}
            onGetDescription={sectorValue => {
              const num1 = getBusinesRolesInSector(sectorValue);
              const num2 = sectorPeople[sectorValue] ? sectorPeople[sectorValue] : 0;

              return plural(num1, "role") + (num2 > 0 ? ", " + plural(sectorPeople ? num2 : 0, "people", "person") : "");
            }}
            onSelect={item => {
              setState({ ...state, category: item.value });
            }}
          />
        )}

        {editBusinessRoleVisible ? <EditBusinessRoleDialog onCancel={() => {
          setState({ ...state, editBusinessRoleVisible: null })
        }} onSave={(item) => {
          onSelect(item);
          setState({ ...state, editBusinessRoleVisible: null })
        }} /> : null}
      </ModalBasic>
    </Portal>
  );
}

export function SelectBusinessRole({ allowCreate, readonly, onChange, value }) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [visible, setVisible] = useState(false);

  return (
    <FlexRow>
      {value ? (
        <FlexRow style={{ marginRight: 10 }}>
          <TextLine value={value.name} />
        </FlexRow>
      ) : (
        <TextLine color="grey" value="Empty" right={10} />
      )}

      {!readonly ? (
        <React.Fragment>
          <LinkButton
            onPress={() => {
              setVisible(true);
            }}
            label="Select"
          />
          {value ? (
            <LinkButton
              style={{ marginLeft: 6 }}
              onPress={() => {
                onChange(null);
              }}
              label="Clear"
            />
          ) : null}
        </React.Fragment>
      ) : null}

      {visible ? (
        <SelectBusinessRoleDialog
          allowCreate={allowCreate}
          onCancel={() => {
            setVisible(false);
          }}
          onSelect={item => {
            onChange(item);
            setVisible(false);
          }}
        />
      ) : null}
    </FlexRow>
  );
}

export function EditBusinessRoleDialog({ onSave, onCancel }) {
  const [state, setState] = useState({ model: {} });
  const { model } = state;

  const { name, businessSector } = model;

  const doSave = function () {
    if (!name) {
      alert("Must enter role name.");
      return;
    }
    if (!businessSector) {
      alert("Must select business sector for role.");
      return;
    }

    return dL
      .saveBusinessRole(null, {
        name,
        businessSector,
        needApproval: true,
        status: "active"
      })
      .then(function (id) {
        return dL.getQuery("BusinessRole").get(id).then(function (obj) {
          const item = dL.loadBusinessRole(obj)
          onSave && onSave(item)
        })
      });
  }

  return <ModalBasic onCancel={onCancel} onOk={() => {
    return doSave()
  }}>
    <HeaderText label="Add Business Role" description="Add a business role that does not already exist on the platform." />

    <MyInput
      label="Name or title for the role:"
      value={name}
      onChange={value => {
        model.name = value;
        setState({ ...state, model });
      }}
    />

    <SelectBox
      style={{ marginRight: 10 }}
      label="Business Sector:"
      value={utils.serviceTypes.find(item => item.value == businessSector)}
      options={utils.serviceTypes}
      onChange={item => {
        model.businessSector = item.value;
        setState({ ...state, model });
      }}
    />
  </ModalBasic>
}

/*
      <Section>
        <HeaderText subHeader label="Hourly Rates:" description="Set the minimum hourly rate and then set the maximum rates for different skill levels." />

        <FlexRow>
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Min. Rate:"
            description="Minimum hourly rate for this role."
            value={rates.lowest}
            onChange={value => {
              rates.lowest = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Junior Rate:"
            description="Average hourly for 1-3 years of experience."
            value={rates.junior}
            onChange={value => {
              rates.junior = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Expert Rate:"
            description="Average hourly rate for 3-10 years of experience."
            value={rates.expert}
            onChange={value => {
              rates.expert = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Master Rate:"
            description="Average hourly rate for 10+ years of experience."
            value={rates.master}
            onChange={value => {
              rates.master = value;
              setState({ ...state, model });
            }}
          />
            <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Highest. Rate:"
            description="Minimum hourly rate for this role."
            value={rates.highest}
            onChange={value => {
              rates.highest = value;
              setState({ ...state, model });
            }}
          />
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="Years Of Experience:" description="Set the minimum hourly rate and then set the maximum rates for different skill levels." />

        <FlexRow>
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Min. Years:"
            description="Minimum hourly rate for this role."
            value={years.lowest}
            onChange={value => {
              years.lowest = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Junior Years:"
            description="Average hourly for 1-3 years of experience."
            value={years.junior}
            onChange={value => {
              years.junior = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Expert Years:"
            description="Average hourly rate for 3-10 years of experience."
            value={years.expert}
            onChange={value => {
              years.expert = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Master Years:"
            description="Average hourly rate for 10+ years of experience."
            value={years.master}
            onChange={value => {
              years.master = value;
              setState({ ...state, model });
            }}
          />
            <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Highest Years:"
            description="Minimum hourly rate for this role."
            value={years.highest}
            onChange={value => {
              years.highest = value;
              setState({ ...state, model });
            }}
          />
        </FlexRow>
      </Section>
*/

export function EditBusinessRole() {
  const { roleId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ model: { status: "active" }, isLoading: roleId ? true : false });
  const { isLoading, model } = state;

  useEffect(() => {
    if (roleId) {
      var model;
      var services;
      dL.getQuery("BusinessRole")
        .get(roleId)
        .then(function (obj) {
          model = dL.loadBusinessRole(obj);

          return db
            .getQuery("Service")
            .equalTo("businessRole", dL.getObj("BusinessRoleTask", roleId))
            .containedIn("removed", [undefined, false])
            .find();
        })
        .then(function (objs) {
          services = dL.loadObjects("Service", objs);
          setState({ ...state, isLoading: false, model, services });
        });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { taskListText, enableRates, jobDescription, status, name, description, businessSector } = model;

  var rates = model.rates;
  if (!rates) {
    rates = {};
  }

  const doSave = function () {
    if (!name) {
      alert("Must enter role name.");
      return;
    }
    if (!businessSector) {
      alert("Must select business sector for role.");
      return;
    }

    if (enableRates) {
      const { lowest, junior, expert, master, highest } = rates;

      if (lowest) {
        if (!utils.isNumber(lowest)) {
          alert("Must enter valid lowest.");
          return;
        }
      }
      if (junior) {
        if (!utils.isNumber(junior)) {
          alert("Must enter valid junior.");
          return;
        }
      }
      if (expert) {
        if (!utils.isNumber(expert)) {
          alert("Must enter valid expert.");
          return;
        }
      }
      if (master) {
        if (!utils.isNumber(master)) {
          alert("Must enter valid master.");
          return;
        }
      }
      if (highest) {
        if (!utils.isNumber(highest)) {
          alert("Must enter valid highest.");
          return;
        }
      }
      rates.lowest = Number(lowest);
      rates.junior = Number(junior);
      rates.expert = Number(expert);
      rates.master = Number(master);
      rates.highest = Number(highest);
    }

    model.needsReview = false
    return dL
      .saveBusinessRole(roleId, model)
      .then(function () {
        history.goBack()
      });
  }

  return (
    <EditDialog>
      <HeaderText label="Edit Business Role" description="Manage business roles to define various worker rates and job descriptions." />

      <Section>
        <MyInput
          required
          label="Name or title for the role:"
          value={name}
          onChange={value => {
            model.name = value;
            setState({ ...state, model });
          }}
        />
        <MyInput
          maxLength={144}
          label="Short description of the role:"
          value={description}
          onChange={value => {
            model.description = value;
            setState({ ...state, model });
          }}
        />
        <FlexRow>
          <SelectBox
            style={{ marginRight: 10 }}
            label="Business Sector:"
            value={utils.serviceTypes.find(item => item.value == businessSector)}
            options={utils.serviceTypes}
            onChange={item => {
              model.businessSector = item.value;
              setState({ ...state, model });
            }}
          />
          <SelectBox
            label="Status:"
            value={utils.businessRoleStatuses.find(item => item.value == status)}
            options={utils.businessRoleStatuses}
            onChange={item => {
              model.status = item.value;
              setState({ ...state, model });
            }}
          />
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="Hourly Rates:" description="Set the minimum hourly rate and then set the maximum rates for different skill levels." />

        <Toggle
          label="Enable Rates"
          description="Specify worker cost rates for this business role based on skill level."
          value={enableRates}
          onChange={value => {
            model.enableRates = value;
            setState({ ...state, model });
          }}
        />

        {enableRates ? <FlexRow>
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Min. Rate:"
            description="Minimum hourly rate for this role."
            value={rates.lowest}
            onChange={value => {
              rates.lowest = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Junior Rate:"
            description="Average hourly for 1-3 years of experience."
            value={rates.junior}
            onChange={value => {
              rates.junior = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Expert Rate:"
            description="Average hourly rate for 3-10 years of experience."
            value={rates.expert}
            onChange={value => {
              rates.expert = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Master Rate:"
            description="Average hourly rate for 10+ years of experience."
            value={rates.master}
            onChange={value => {
              rates.master = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ minWidth: 10 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="money"
            minValue={0}
            label="Highest Rate:"
            description="Average hourly rate max rate for business role."
            value={rates.highest}
            onChange={value => {
              rates.highest = value;
              setState({ ...state, model });
            }}
          />
        </FlexRow> : null}
      </Section>

      <Section>
        <HeaderText subHeader label="Role Tasks:" description="Add one task per line that this role could complete. These tasks will be display when to clients when tasks are being assigned to this role during staff augmentation services." />

        <MyInput
          style={{ height: 350 }}
          placeholder="Enter one task per line."
          multiline
          value={taskListText}
          onChange={value => {
            model.taskListText = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <Section>
        <HeaderText subHeader label="Job Description:" description="Provide an overview of a job description for this role for recruiting purposes." />

        <MyInput
          style={{ height: 350 }}
          multiline
          placeholder="Enter job description"
          value={jobDescription}
          onChange={value => {
            model.jobDescription = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <TouchButton
        label="Save Role"
        onPress={() => {
          doSave()
        }}
      />
    </EditDialog>
  );
}

export function BusinessRoleList({ searchText, list, onSelect, rolePeople }) {
  var fList = list;

  if (searchText) {
    fList = fList.filter(item => {
      return item.name.toLowerCase().startsWith(searchText.toLowerCase());
    });
  }

  return (
    <div class="row">
      {fList.length == 0 ? (
        <NoRecords label="No business roles found." />
      ) : (
        fList.map(item => {
          const { businessSector, id, name } = item;

          return (
            <Card2
              key={id}
              title={name}
              description={getLabelItem(utils.serviceTypes, businessSector) + ", " + plural(rolePeople ? (rolePeople[id] ? rolePeople[id] : 0) : 0, "person")}
              onPress={() => {
                onSelect(item);
              }}
            />
          );
        })
      )}
    </div>
  );
}

/*
export function BusinessRoles() {
  const {sectionId, category} = useParams();
  const history = useHistory();
  const [state, setState] = useState({isLoading: true});
  const {rolePeople, sectorPeople, isLoading, list, searchText} = state;

  var foundCategory;
  if (category) {
        foundCategory = utils.serviceTypes.find(item => item.value == category);
  }

  useEffect(() => {
        dL.getBusinessRoles().then(function (roles) {
          return dL.getPeoplePerSector().then(function (sectorPeople) {
            return dL.getPeoplePerBusinessRole().then(function (rolePeople) {
              setState({ ...state, isLoading: false, list: roles, rolePeople, sectorPeople });
            });
          });
        });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var fList = list;
  if (category) {
        fList = fList.filter(item => item.businessSector == foundCategory.value);
  }

  const getBusinesRolesInSector = function(sectorValue) {
    return list.filter(item => item.businessSector == sectorValue).length;
  };

  return (
      <View>
        <HeaderText
          label={foundCategory ? foundCategory.label + " Roles" : "Business Roles"}
          description="Search for a business role."
          style={{ marginBottom: 10 }}
          rightRender={foundCategory ? <BackButton /> : null}
          onButtonPress={() => {
            history.push(`/${sectionId}/business-role/add`);
          }}
        />

        <MyInput
          placeholder="Search..."
          value={searchText}
          onChange={text => {
            setState({ ...state, searchText: text });
          }}
        />

        {searchText || foundCategory ? (
          <BusinessRoleList
            list={fList}
            rolePeople={rolePeople}
            searchText={searchText}
            onSelect={item => {
              history.push(`/${sectionId}/business-role/${item.id}`);
            }}
          />
        ) : (
          <ServiceCatList
            onGetDescription={sectorValue => {
              return plural(getBusinesRolesInSector(sectorValue), "role") + ", " + plural(sectorPeople ? (sectorPeople[sectorValue] ? sectorPeople[sectorValue] : 0) : 0, "people", "person");
            }}
            onSelect={item => {
              history.push(`/${sectionId}/${item.value}/business-roles`);
            }}
          />
        )}
      </View>
  );
}
*/

export function ViewBusinessRole() {
  const { sectionId, roleId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model } = state;
  const { createMode, viewMode, editMode, itemIndex, deleteMode } = state

  useEffect(() => {
    dL.getQuery("BusinessRole")
      .include("tasks")
      .get(roleId)
      .then(function (obj) {
        const role = dL.loadBusinessRole(obj);

        setState({ ...state, isLoading: false, model: role });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { jobDescription, rates, status, name, description, businessSector, taskListText, tasks } = model;

  const saveTasks = function (tasks) {
    return db.getObj("BusinessRole", model.id)
      .set("tasks", tasks.map((item) => db.getObj("BusinessRoleTask", item.id)))
      .save()
  }

  const saveTaskSeq = function (tasks) {
    return db.getObj("BusinessRole", model.id)
      .set("tasksSeq", tasks.map((item) => item.id))
      .save()
  }

  return (
    <View>
      <HeaderText
        label={name}
        description={description}
        buttons={[
          {
            label: "Edit Role",
            onPress: () => {
              history.push(`/${sectionId}/business-role/${roleId}/edit`);
            }
          }
        ]}
      />

      <Section>
        <FlexRow>
          <LabelItem label="Business Sector:" value={getLabelItem(utils.serviceTypes, businessSector)} />
          <LabelItem label="Status:" value={getLabelItem(utils.businessRoleStatuses, status)} />
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="Hourly Rates:" description="Set the minimum hourly rate and then set the maximum rates for different skill levels." />

        <FlexRow>
          <LabelItem label="Min. Rate:" description="Minimum hourly rate for this role." value={money(rates.lowest)} />
          <LabelItem label="Junior Rate:" description="Average hourly for 1-3 years of experience." value={money(rates.junior)} />
          <LabelItem label="Expert Rate:" description="Average hourly rate for 3-10 years of experience." value={money(rates.expert)} />
          <LabelItem label="Master Rate:" description="Average hourly rate for 10+ years of experience." value={money(rates.master)} />
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="Role Tasks:" description="Task that this role could complete. These tasks will be display when to clients when tasks are being assigned to this role during staff augmentation services." />

        {taskListText ? (
          <ul>
            {taskListText.split("\n").map(item => (
              <li>{item}</li>
            ))}
          </ul>
        ) : (
          <NoRecords label="No tasks added." />
        )}
      </Section>

      <Section>
        <HeaderText subHeader label="Business Role Tasks:" description="These tasks will be display when to clients when tasks are being assigned to this role during staff augmentation services."
          onButtonPress={() => {
            setState({ ...state, createMode: true });
          }}
        />

        <SortableList
          emptyRender={<NoRecords label="No tasks added." />}
          items={tasks}
          onChange={(list) => {
            model.tasks = list;
            saveTaskSeq(list)
            setState({ ...state, model });
          }}
          renderItem={(task, index) => {
            const { id, title, description, skillLevel, workHours, deliveryDays, formItems } = task;

            return (
              <BoxItem
                key={id}
                onPress={() => {
                  setState({ ...state, viewMode: task });
                }}
                name={title}
                description={description}
                hasMove
                onEdit={() => {
                  setState({ ...state, editMode: task, itemIndex: index });
                }}
                onDelete={() => {
                  setState({ ...state, deleteMode: task, itemIndex: index });
                }} rightRender={
                  <View right={15} alignRight>
                    <TextLine size={18} value={plural(workHours, "hr")} />
                    <TextLine size={14} value={plural(deliveryDays, "day")} />
                  </View>
                }>
                <FlexRow top={10}>
                  <TextLine size={14} label="Skill Level:" value={getLabelItem(skillLevelsList, skillLevel)} spacer />
                  {formItems.length > 0 ? <TextLine size={14} label="Inputs:" value={plural(formItems.length, "Question")} /> : null}
                </FlexRow>
              </BoxItem>
            );
          }}
        />

        {createMode ? <EditBusinessRoleTask
          businessRole={model}
          onCancel={() => {
            setState({ ...state, createMode: null });
          }}
          onSave={(editItem) => {
            tasks.push(editItem)
            saveTasks(tasks)
            setState({ ...state, createMode: null });
          }}
        /> : editMode ? <EditBusinessRoleTask
          editItem={editMode}
          onCancel={() => {
            setState({ ...state, editMode: null });
          }}
          onSave={(editItem) => {
            tasks[itemIndex] = editItem
            saveTasks(tasks)
            setState({ ...state, editMode: null });
          }}
        /> : viewMode ? <ViewBusinessRoleTask
          item={viewMode}
          onClose={() => {
            setState({ ...state, viewMode: null });
          }}
        /> : deleteMode ? (
          <DeleteConfirm
            onCancel={() => {
              setState({ ...state, deleteMode: null });
            }}
            onConfirm={() => {
              tasks.splice(itemIndex, 1)
              dL.getObj("BusinessRoleTask", deleteMode.id).set("removed", true).save()
              saveTasks(tasks)
              setState({ ...state, deleteMode: null });
            }}
          />
        ) : null}
      </Section>

      <Section>
        <HeaderText subHeader label="Job Description:" description="Provide an overview of a job description for this role for recruiting purposes." />

        {jobDescription ? renderHTML(jobDescription.replace(/\n/g, "<br>")) : <NoRecords label="No job description added." />}
      </Section>
    </View>
  );
}

/*
export function ViewBusinessRoleBuyer() {
  const {sectionId, roleId} = useParams();
  const history = useHistory();
  const [state, setState] = useState({isLoading: true});
  const {isLoading, model} = state;

  useEffect(() => {
        dL.getQuery("BusinessRole")
          .get(roleId)
          .then(function (obj) {
            const role = dL.loadBusinessRole(obj);

            setState({ ...state, isLoading: false, model: role });
          });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const {jobDescription, rates, status, name, description, businessSector, services, tasks} = model;

  return (
      <View>
        <HeaderText
          label={name}
          description={description}
          buttons={[
            {
              label: "Add Staff",
              onPress: () => {

              }
            }
          ]}
        />

        <Section>
          <FlexRow>
            <LabelItem label="Business Sector:" value={getLabelItem(utils.serviceTypes, businessSector)} />
            <LabelItem label="Hourly Rate Ranger:" value={moneyRange(1, 100)} />
          </FlexRow>
        </Section>

        <Section>
          <HeaderText subHeader label="Role Tasks:" description="Task that this role could complete. These tasks will be display when to clients when tasks are being assigned to this role during staff augmentation services." />

          {tasks ? (
            <ul>
              {tasks.split("\n").map(item => (
                <li>{item}</li>
              ))}
            </ul>
          ) : (
            <NoRecords label="No tasks added." />
          )}
        </Section>

        <Section>
          <HeaderText subHeader label="Job Description:" description="Provide an overview of a job description for this role for recruiting purposes." />

          {jobDescription ? renderHTML(jobDescription.replace(/\n/g, "<br>")) : <NoRecords label="No job description added." />}
        </Section>
      </View>
  );
}

export function ViewBusinessRoleWorker() {
  const {sectionId, roleId} = useParams();
  const history = useHistory();
  const [state, setState] = useState({isLoading: true});
  const {isLoading, model} = state;

  useEffect(() => {
        dL.getQuery("BusinessRole")
          .get(roleId)
          .then(function (obj) {
            const role = dL.loadBusinessRole(obj);

            setState({ ...state, isLoading: false, model: role });
          });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const {jobDescription, rates, status, name, description, businessSector, services, tasks} = model;

  return (
      <View>
        <HeaderText
          label={name}
          description={description}
          buttons={[
            {
              label: "Edit Role",
              onPress: () => {
                history.push(`/${sectionId}/business-role/${roleId}/edit`);
              }
            }
          ]}
        />

        <Section>
          <FlexRow>
            <LabelItem label="Business Sector:" value={getLabelItem(utils.serviceTypes, businessSector)} />
            <LabelItem label="Status:" value={getLabelItem(utils.businessRoleStatuses, status)} />
          </FlexRow>
        </Section>

        <Section>
          <HeaderText subHeader label="Hourly Rates:" description="Set the minimum hourly rate and then set the maximum rates for different skill levels." />

          <FlexRow>
            <LabelItem label="Min. Rate:" description="Minimum hourly rate for this role." value={money(rates.lowest)} />
            <LabelItem label="Junior Rate:" description="Average hourly for 1-3 years of experience." value={money(rates.junior)} />
            <LabelItem label="Expert Rate:" description="Average hourly rate for 3-10 years of experience." value={money(rates.expert)} />
            <LabelItem label="Master Rate:" description="Average hourly rate for 10+ years of experience." value={money(rates.master)} />
          </FlexRow>
        </Section>

        <Section>
          <HeaderText subHeader label="Role Tasks:" description="Task that this role could complete. These tasks will be display when to clients when tasks are being assigned to this role during staff augmentation services." />

          {tasks ? (
            <ul>
              {tasks.split("\n").map(item => (
                <li>{item}</li>
              ))}
            </ul>
          ) : (
            <NoRecords label="No tasks added." />
          )}
        </Section>

        <Section>
          <HeaderText subHeader label="Job Description:" description="Provide an overview of a job description for this role for recruiting purposes." />

          {jobDescription ? renderHTML(jobDescription.replace(/\n/g, "<br>")) : <NoRecords label="No job description added." />}
        </Section>
      </View>
  );
}
*/

export function BusinessRolesAdminPeople() {
  const { sectionId, roleId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, list, searchText, role } = state;

  useEffect(() => {
    dL.getQuery("BusinessRole")
      .get(roleId)
      .then(function (obj) {
        const role = dL.loadBusinessRole(obj);

        dL.getUsers({ businessRoleId: roleId }).then(function (users) {
          setState({ ...state, isLoading: false, role, list: users });
        });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <View>
      <HeaderText
        label={role.name + " : " + plural(list.length, "User")}
        description="View users on the platform in this business role."
        style={{ marginBottom: 10 }}
        buttons={[
          {
            label: "Edit Role",
            onPress: () => {
              history.push(`/${sectionId}/business-role/${roleId}/edit`);
            }
          }, {
            label: "View Role",
            onPress: () => {
              history.push(`/${sectionId}/business-role/${roleId}`);
            }
          }
        ]}
      />

      <MyInput
        placeholder="Search..."
        value={searchText}
        onChange={text => {
          setState({ ...state, searchText: text });
        }}
      />

      <UserList
        list={list}
        searchText={searchText}
        onSelect={item => {
          history.push(`/${sectionId}/people/${item.id}`);
        }}
      />
    </View>
  );
}

export function BusinessRolesAdminSectors() {
  const { sectionId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ isLoading: true, list: [] });
  const [searchText, setSearchText] = useState();
  const [tabValue, setTabValue] = useState("active");
  const { isLoading, list, sectorPeople, rolePeople, sectorRole } = state;

  useEffect(() => {
    return dL.getRolesPerSector({ status: tabValue }).then(function (sectorRole) {
      return dL.getPeoplePerSector().then(function (sectorPeople) {
        return dL.getPeoplePerBusinessRole().then(function (rolePeople) {
          setState({ ...state, isLoading: false, sectorRole, rolePeople, sectorPeople });
        });
      });
    });
  }, []);

  useEffect(() => {
    return dL.getRolesPerSector({ status: tabValue }).then(function (sectorRole) {
      setState({ ...state, sectorRole });
    });
  }, [tabValue]);

  useEffect(() => {
    dL.getQuery("BusinessRole")
      .startsWith("searchText", searchText)
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const list = dL.loadObjects("BusinessRole", objs);
        setState({ ...state, isSearching: false, list });
      });
  }, [searchText]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <View>
      <HeaderText
        label="Business Roles"
        description="Manage business roles on the platform."
        onButtonPress={() => {
          history.push(`/${sectionId}/business-role/add`);
        }}
        rightRender={<TabBar
          queryId="tb"
          options={[
            {
              label: "Active",
              value: "active",
              description: "All active business roles."
            },
            {
              label: "Archived",
              value: "archived",
              description: "All archived business roles."
            }
          ]}
          onChange={item => {
            setTabValue(item.value);
          }}
          value={tabValue}
        />}
      />

      <MyInput
        placeholder="Search..."
        value={searchText}
        onChange={text => {
          setSearchText(text);
          setState({ ...state, isSearching: true });
        }}
      />

      {searchText ? (
        <FlexRow style={{ flexWrap: "wrap" }}>
          {list.length == 0 ? (
            <NoRecords label="No business roles found." />
          ) : (
            list.map(item => {
              const { businessSector, id, name } = item;

              const peopleCount = rolePeople ? (rolePeople[id] ? rolePeople[id] : 0) : 0;
              var description = getLabelItem(serviceTypes, businessSector);
              if (peopleCount) {
                description += ", " + plural(peopleCount, "person");
              }

              return (
                <Card2
                  bodyStyle={{ minHeight: 100 }}
                  key={id}
                  title={name}
                  description={description}
                  onPress={() => {
                    history.push(`/${sectionId}/business-roles/${id}`);
                  }}
                />
              );
            })
          )}
        </FlexRow>
      ) : (
        <ServiceCatList
          onGetDescription={sectorValue => {
            var str = "";
            const roleCount = sectorRole[sectorValue] ? sectorRole[sectorValue] : 0;
            const peopleCount = sectorPeople[sectorValue] ? sectorPeople[sectorValue] : 0;
            if (roleCount) {
              str = plural(roleCount, "role");
            }
            if (peopleCount) {
              if (str) {
                str += ", ";
              }
              str += plural(peopleCount, "person", "people");
            }
            return str;
          }}
          onSelect={item => {
            history.push(`/${sectionId}/${item.value}/business-roles`);
          }}
        />
      )}
    </View>
  );
}

export function BusinessRolesAdmin() {
  const { sectionId, category } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { rolePeople, isLoading } = state;

  useEffect(() => {
    return dL.getPeoplePerBusinessRole().then(function (rolePeople) {
      setState({ ...state, isLoading: false, rolePeople });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var foundCategory;
  if (category) {
    foundCategory = utils.serviceTypes.find(item => item.value == category);
  }

  return (
    <ListRender
      columns
      searchFields={[{ field: "searchText" }]}
      defaultSort={{ field: "searchText" }}
      filters={[
        {
          label: "Active",
          value: "active",
          description: "All active business roles."
        },
        {
          label: "Need Review",
          value: "need-review",
          description: "Business roles that need to be approved.",
          onQuery: (query) => {
            query.equalTo("needsReview", true)
          }
        },
        {
          label: "Archived",
          value: "archived",
          description: "All archived business roles."
        }
      ]}
      onButtonPress={() => {
        history.push(`/${sectionId}/business-role/add`);
      }}
      onWhere={query => {
        if (category) {
          query.equalTo("businessSector", category);
        }
      }}
      type="BusinessRole"
      emptyLabel="No business roles found."
      includes={[]}
      title={foundCategory ? "Business Roles: " + foundCategory.label : "Business Roles"}
      description="Manage business roles on the platform."
      renderItem={item => {
        const { id, name } = item;

        const peopleCount = rolePeople ? (rolePeople[id] ? rolePeople[id] : 0) : 0;
        var description; //getLabelItem(serviceTypes, businessSector);
        if (peopleCount) {
          description = plural(peopleCount, "person");
        }

        return (
          <Card2
            bodyStyle={{ minHeight: 100 }}
            key={id}
            title={name}
            description={description}
            onPress={() => {
              history.push(`/${sectionId}/business-roles/${id}`);
            }}
          />
        );
      }}
    />
  );
}

function ViewBusinessRoleTask({ item, onClose }) {
  const { title, description, deliverableNotes, workHours, deliveryDays, formItems, skillLevel } = item;

  return (
    <ModalBasic
      large
      title="View Task:"
      okText="Close"
      onOk={() => {
        onClose();
      }}>

      <Section>
        <FlexRow>
          <FlexExpand>
            <TextLine size={18} value={title} />
            <TextLine value={description} />
          </FlexExpand>

          <View left={25} alignRight>
            <TextLine size={18} value={plural(workHours, "hr")} />
            <TextLine size={14} value={plural(deliveryDays, "day")} />
          </View>
        </FlexRow>

        <FlexRow top={10}>
          <TextLine label="Skill Level:" value={getLabelItem(skillLevelsList, skillLevel)} spacer />
        </FlexRow>
      </Section>

      <Section>
        <TextLine size={18} bottom={10} bold value="Deliverables:" />
        <LabelItem value={deliverableNotes ? deliverableNotes : "Empty"} />
      </Section>

      <Section>
        <TextLine size={18} bottom={10} bold value="Required inputs when creating task:" />
        <FormEditor
          formItems={formItems}
          formData={{}}
        />
      </Section>
    </ModalBasic>
  );
}

function EditBusinessRoleTask({ company, team, businessRole, editItem, onSave, onCancel }) {
  const [state, setState] = useState({ model: editItem ? editItem : { formItems: [], company, team, businessRole }, isLoading: true });
  const { model } = state;

  const { title, description, deliverableNotes, workHours, deliveryDays, formItems, skillLevel } = model;

  return (
    <ModalBasic
      title={(model.id ? "Edit" : "Add") + " Task"}
      okText="Save"
      onCancel={onCancel}
      onOk={() => {
        if (!title) {
          alert("Must enter title.");
          return;
        }
        if (!deliverableNotes) {
          alert("Must enter deliverables.");
          return;
        }
        if (!utils.isNumber(workHours)) {
          alert("Must enter valid not to exceed hours.");
          return;
        }
        if (!utils.isNumber(deliveryDays)) {
          alert("Must enter valid delivery days.");
          return;
        }

        const isNew = !model.id
        model.deliveryDays = Number(deliveryDays);
        model.workHours = Number(workHours);
        return dL.saveBusinessRoleTask({ recordId: model.id, isNew, data: model }).then(function (id) {
          model.id = id
          onSave(model);
        });
      }}>

      <MyInput
        required
        label="Title:"
        value={title}
        onChange={value => {
          model.title = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        required
        multiline
        label="Description:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <FlexRow>
        <SelectBox
          label="Skill Level:"
          value={skillLevelsList.find(item => item.value == skillLevel)}
          options={skillLevelsList}
          onChange={item => {
            model.skillLevel = item.value;
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 25 }} />
        <MyInput
          style={{ maxWidth: 125 }}
          inputType="integer"
          minValue={0}
          label="Hours:"
          description="Hours to completed this task."
          value={workHours}
          onChange={value => {
            model.workHours = value;
            setState({ ...state, model });
          }}
        />

        <View style={{ width: 25 }} />
        <MyInput
          style={{ maxWidth: 125 }}
          inputType="integer"
          minValue={0}
          label="Delivery Days:"
          description="Requested days to complete."
          value={deliveryDays}
          onChange={value => {
            model.deliveryDays = value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      <MyInput
        required
        multiline
        label="Deliverables:"
        value={deliverableNotes}
        onChange={value => {
          model.deliverableNotes = value;
          setState({ ...state, model });
        }}
      />

      <FormBuilder
        smallHeader
        label="Required inputs when creating task:"
        formItems={formItems}
        onUpdate={(items) => {
          model.formItems = items;

          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}