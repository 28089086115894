import "./App.css";
import { TouchableOpacity} from "react-native";
import React, {} from "react";
import http from "root/utils/http";
import utils from "root/utils/functions";
import session from "root/utils/session";
import colors from "root/colors";
import Moment from "moment-business-days";
import "root/App.css";
import {__DEV__} from "root/dev";
import {ModalBasic, FlexExpand, FlexRow, Text, View, Toggle, FormItem, MyInput, SelectBox} from "root/pack-1";

export class SelectCreditCard extends React.Component {
  constructor(props) {
    super(props);

    var months = [];
    var years = [];
    for (var i = 1; i <= 12; i++) {
      months.push({label: i, value: i});
    }

    var dt = Moment();
    for (var i = 1; i <= 8; i++) {
      years.push({label: dt.year(), value: dt.year()});
      dt = dt.add(1, "year");
    }

    const user = session.user;
    this.state = {
      creditCards: [],
      months,
      years,
      cardName: user.firstName + " " + user.lastName
    };

    if (__DEV__ || session.user.testMode) {
      var state = this.state;
      state.cardNumber = "4111111111111111";
      state.ccv = "123";
      state.expireMonth = 12;
      state.expireYear = 2020;
    }
  }

  componentDidMount() {
    const th = this;
    th.refresh();
  }

  refresh() {
    const th = this;
    const props = th.props;

    const {referenceId} = props;

    if (referenceId) {
      http
        .run("getCreditCards", {testMode: session.user.testMode, referenceId})
        .then(function(results) {
          const {creditCards, err} = results;

          if (err) {
            if (err.message) {
              alert("Error in booking: " + err.message);
            } else {
              alert("Error in booking: " + err);
            }
          } else {
            const arr = creditCards
              ? creditCards.map(item => {
                  const {cardType, last4, expireDate} = item;
                  return {label: cardType + " ending in " + last4 + " Expires: " + expireDate, value: item};
                })
              : [];

            if (arr.length > 0) {
              arr.unshift({label: "New Card", value: null});
            }
            th.setState({creditCards: arr});
          }
        })
        .catch(function(err) {
          alert("Error in loading:" + err.message);
        });
    }
  }

  componentDidUpdate(prevProps) {
    const th = this;
    const {props} = th;
    const {referenceId} = props;

    if (referenceId && prevProps.referenceId && referenceId != prevProps.referenceId) {
      th.refresh();
    }
  }

  render() {
    const th = this;
    const {state, props} = th;

    const {allowClear, value, placeholder, onChange, referenceId, boxStyle} = props;

    const {saveCardForFutureUse, expireMonth, ccv, expireYear, cardNumber, cardName, modelVisible, months, years, creditCards, creditCard} = state;

    const rect = {overflow: "hidden", borderRadius: 6.7, borderStyle: "solid", borderWidth: 1, borderColor: colors.lightBlueGreyThree};
    const textS3 = {fontSize: 13.9, color: "#202632"};
    const placeholderS = {color: "#c0c0c0"};
    const labelS = {fontSize: 16.7, color: colors.slateGrey};
    const rect2 = {padding: 16, flexDirection: "row", alignItems: "center"};
    const detailS = {ffontSize: 14.7, lineHeight: 20, color: colors.coolGrey, marginTop: 2};

    return (
      <React.Fragment>
        <FlexRow
          onPress={() => {
            th.setState({modelVisible: true});
          }}
          style={{width: "100%", borderWidth: 1, borderStyle: "solid", borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7}}>
          <FlexExpand>
            {value ? (
              <Text style={textS3}>
                {value.cardType} ending in {value.last4}
              </Text>
            ) : (
              <Text style={[textS3, placeholderS]}>{placeholder ? placeholder : "Select credit card"}</Text>
            )}
          </FlexExpand>

          {allowClear && value ? (
            <TouchableOpacity
              hitSlop={{top: 8, bottom: 8, left: 8, right: 8}}
              onPress={() => {
                if (onChange) {
                  onChange(null);
                }
              }}
              style={{marginRight: 10}}>
              X
            </TouchableOpacity>
          ) : null}
        </FlexRow>

        {modelVisible ? (
          <ModalBasic
            title="Credit Card"
            description="Enter or select your credit card."
            okText="Save"
            onCancel={() => {
              th.setState({modelVisible: false});
            }}
            onOk={() => {
              if (creditCard) {
                onChange(creditCard);
                th.setState({modelVisible: false});
              } else {
                return http
                  .run("saveCreditCard", {
                    testMode: session.user.testMode,
                    saveCardForFutureUse,
                    expireMonth,
                    ccv,
                    expireYear,
                    cardNumber,
                    cardName,
                    referenceId,
                    createdById: session.user.id
                  })
                  .then(function(results) {
                    const {card, err} = results;

                    if (err) {
                      if (err.message) {
                        alert("Error in booking: " + err.message);
                      } else {
                        alert("Error in booking: " + err);
                      }
                    } else {
                      if (saveCardForFutureUse) {
                        creditCards.push({label: card.cardType + " ending in " + card.last4, value: card});
                      }
                      onChange(card);
                      th.setState({modelVisible: false});
                    }
                  });
              }
            }}>
            <View>
              {creditCards && creditCards.length > 0 ? (
                <SelectBox
                  label="Saved Credit Cards:"
                  placeholer="Select existing card"
                  options={creditCards}
                  value={utils.getValueItem(creditCards, creditCard)}
                  onChange={item => {
                    th.setState({creditCard: item.value});
                  }}
                  style={{marginBottom: 23}}
                />
              ) : null}

              {!creditCard ? (
                <React.Fragment>
                  <FormItem label="Name on Card:">
                    <MyInput
                      placeholder="John Smith"
                      value={cardName}
                      onChange={value => {
                        th.setState({cardName: value});
                      }}
                      style={{marginBottom: 23}}
                    />
                  </FormItem>

                  <FormItem label="Card Number:">
                    <MyInput
                      placeholder="Enter number"
                      keyboardType="number-pad"
                      value={cardNumber}
                      onChange={value => {
                        th.setState({cardNumber: value});
                      }}
                      style={{marginBottom: 23}}
                    />
                  </FormItem>

                  <View style={{flexDirection: "row"}}>
                    <View style={{marginRight: 32, minWidth: 100}}>
                      <FormItem label="CCV:">
                        <MyInput
                          placeholder="123"
                          keyboardType="number-pad"
                          value={ccv}
                          onChange={value => {
                            th.setState({ccv: value});
                          }}
                        />
                      </FormItem>
                    </View>

                    <View style={{flex: 1}}>
                      <FormItem label="Expire Date:">
                        <View style={{flexDirection: "row"}}>
                          <SelectBox
                            placeholder="MM"
                            style={{marginRight: 8.3, minWidth: 45, flex: 1}}
                            options={months}
                            value={utils.getValueItem(months, expireMonth)}
                            onChange={value => {
                              th.setState({expireMonth: value.value});
                            }}
                          />
                          <SelectBox
                            placeholder="YY"
                            style={{minWidth: 75, flex: 1}}
                            options={years}
                            value={utils.getValueItem(years, expireYear)}
                            onChange={value => {
                              th.setState({expireYear: value.value});
                            }}
                          />
                        </View>
                      </FormItem>
                    </View>
                  </View>

                  {referenceId ? (
                    <Toggle
                      value={saveCardForFutureUse}
                      onChange={value => {
                        th.setState({saveCardForFutureUse: value});
                      }}
                      label="Save card for later use"
                      style={{marginBottom: 23}}
                    />
                  ) : null}
                </React.Fragment>
              ) : null}
            </View>
          </ModalBasic>
        ) : null}
      </React.Fragment>
    );
  }
}
