import "./App.css";
import { Container, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import http from "root/utils/http";
import utils from "root/utils/functions";
import session from "root/utils/session";
import PromiseA from "root/utils/promise";
import ScriptTag from "react-script-tag";
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "root/App.css";
import { ViewTrainingProfile } from "root/training"
import { ServiceBuyer, ServicesBuyerAdmin } from "root/services-buyer"
import { MyServices, ServicesWorkerAdmin, ServiceWorker } from "root/services-worker";
import { EditService, ServiceAdmin } from "root/services";
import { TaskRecord, TasksAdmin, TasksWorker, EditTaskRecord } from "root/tasks";
import { EditTimeRecord, TimeRecord, TimeRecords, MyTimeRecords } from "root/time-logs";
import { ServiceRequestWorker, ServiceRequestBuyer, EditServiceRequest, ServiceRequestsWorker, ServiceRequestsAdmin, ServiceRequestsBuyer } from "root/service-requests";
import { Cart } from "root/cart";
import { getNewChatCount } from "root/chat"
import { BonusPool, BonusList, Earnings, IssueBonus } from "root/earnings";
import { useChatCount, MyChats, ChatDock } from "root/chat";
import { ViewTeamRolePosition, MyTeams, TeamAdmin, TeamsWorker, SearchForTeams, TeamPublic } from "root/teams";
import { Project, Projects } from "root/projects";
import {  ProposalEditorView } from "root/proposals-2";
import {  ProposalBuyerView } from "root/proposals-4";
import { EditProposalSimple, EditProposal } from "root/proposals-1";
import { ProposalsWorker, ProposalsBuyer } from "root/proposals-3";
import { OrdersAdmin, OrdersBuyer, OrdersWorker, OrderWorker, OrderBuyer } from "root/orders";
import { ServiceOrderWorker, ServiceOrderBuyer, ServiceOrdersAdmin, ServiceOrdersWorker, ServiceOrdersBuyer } from "root/service-orders";
import { RenderServiceItemBuyer, RenderServiceItemWorker, ServiceCatList } from "root/pack-3";
import { ExpertiseSelector, WorkRequest, WorkRequestsWorker, WorkRequests, EditUserRole, UserProfileAdmin, EditUserWorker, EditUserAdmin, UserProfileWorker, UserProfileBuyer } from "root/worker";
import { FaqItem, StatItem, ActionItem, DashBoxItem2, BoxItem2, BoxRowItem, HeaderText } from "root/pack-2";
import { faBuilding, faBook } from "@fortawesome/free-solid-svg-icons";
import {
  Counter,
  Section,
  ModalBasic,
  BoxItem,
  LabelItem,
  Hide,
  FlexExpand,
  FlexRow,
  TabBar,
  Text,
  TextLine,
  View,
  Toggle,
  FormItem,
  MyInput,
  TouchButtonOnOff,
  TouchButton,
  SelectBox,
  ImageElement,
  Avatar,
  NoRecords,
  BackButton,
  ScrollToTop,
  LocationSearchInput, SortableList, LinkButton
} from "root/pack-1";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-widgets/dist/css/react-widgets.css";
import "react-datetime/css/react-datetime.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddStaffServiceWorker, AddStaffService, EditStaffAugShare, StaffServiceBuyer, StaffServiceWorker, StaffServiceAdmin, StaffServiceShare, StaffAugShares, StaffServicesAdminBilling, StaffServicesAdmin, StaffServicesWorker, StaffServicesBuyer } from "root/staff-aug";
import { __DEV__ } from "root/dev";
import { BusinessInitiativesAdminSectors, ViewBusinessInitiative, EditBusinessInitiative, BusinessInitiativesAdmin } from "root/business-initiatives";
import { EditBusinessRole, ViewBusinessRole, BusinessRolesAdminPeople, BusinessRolesAdminSectors, BusinessRolesAdmin } from "root/business-roles";
import { RenderInvitations, UsersAdmin, ServicesUsers } from "root/users";
import { Companies, Company } from "root/companies";
import Moment from "moment-business-days";

const { getGroupedItems, isEmail, getHasExpertise, pluralTab, serviceCores, moneyRange, userStatuses, deliverableTypes, plural, getLabelItem, serviceTypes, money, roundMoney, skillLevels } = utils;

Sentry.init({
  dsn: "https://ee402100b677435faa848d7f6d5d59bf@o336149.ingest.sentry.io/5243277"
});

function Login({ onLogin }) {
  const [state, setState] = useState(__DEV__ ? { email: "employee1@gmail.com", password: "aaaaaa" } : { email: "", password: "" });
  const { email, password } = state;

  useEffect(() => {
    eval("$('.js-validate').each(function () {var validation = $.HSCore.components.HSValidation.init($(this));});");
  }, []);

  return (
    <Container>
      <Row noGutters>
        <div class="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-2">
          <Title title="Welcome back" description="Login to manage your account." />
          <form class="js-validate">
            <MyInput
              id="email"
              hideRequired
              required
              type="email"
              label="Email:"
              value={email}
              onChange={text => {
                setState({ ...state, email: text });
              }}
              validation="Please enter a valid email address."
            />

            <MyInput
              id="password"
              hideRequired
              required
              type="password"
              validation="Your password is invalid. Please try again."
              label="Password:"
              value={password}
              onChange={text => {
                setState({ ...state, password: text });
              }}
              placeholder="********"
              rightLabelRender={<a class="link-underline text-capitalize font-weight-normal" href="recover-account.html">
                Forgot Password?
              </a>}
            />
          </form>

          <div class="row align-items-center mb-5">
            <div class="col-sm-6 mb-3 mb-sm-0">
              <span class="font-size-1 text-muted">Don't have an account?</span>
              <Link to="/register" className="font-size-1 font-weight-bold" style={{ marginLeft: 10 }}>
                Register
              </Link>
            </div>

            <div class="col-sm-6 text-sm-right">
              <TouchButton
                onPress={() => {
                  if (!utils.isEmail(email)) {
                    alert("Must enter valid email.");
                    return;
                  }
                  if (!password) {
                    alert("Must enter password.");
                    return;
                  }

                  return utils
                    .login({ email, password })
                    .then(function () {
                      onLogin();
                    })
                    .catch(function (err) {
                      alert("Error: " + err);
                    });
                }} label="Login" />
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}

function Title({ title, description }) {
  return (
    <div class="mb-5 mb-md-7">
      <h1 class="h2">{title}</h1>
      <p>{description}</p>
    </div>
  );
}

function Register({ onLogin }) {
  const { eid } = useParams()
  const [state, setState] = useState({ isLoading: eid ? true : false });
  const { isLoading, firstName, lastName, email, password, inviteCode } = state;

  useEffect(() => {
    if (eid) {
      dL.getQuery("Invitation")
        .equalTo("inviteCode", eid)
        .first()
        .then(function (obj) {
          if (!obj) {
            alert("No invitation found.")
          } else {
            setState({ ...state, inviteCode: obj.get("inviteCode") })
          }
        })
    }
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <div class="container">
      <div class="row no-gutters">
        <div class="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-2">
          <Title title="Welcome to Growly" description="Fill out the form to get started." />

          <Row sm={2} xs={1}>
            <Col>
              <MyInput
                label="First Name:"
                value={firstName}
                onChange={text => {
                  setState({ ...state, firstName: text });
                }}
              />
            </Col>
            <Col>
              <MyInput
                label="Last Name:"
                value={lastName}
                onChange={text => {
                  setState({ ...state, lastName: text });
                }}
              />
            </Col>
          </Row>

          <MyInput
            label="Email:"
            value={email}
            onChange={text => {
              setState({ ...state, email: text });
            }}
          />
          <MyInput
            type="password"
            label="Password:"
            value={password}
            onChange={text => {
              setState({ ...state, password: text });
            }}
          />

          <MyInput
            label="Invite Code:"
            value={inviteCode}
            onChange={text => {
              setState({ ...state, inviteCode: text });
            }}
          />
          <div class="js-form-message mb-5">
            <div class="custom-control custom-checkbox d-flex align-items-center text-muted">
              <input type="checkbox" class="custom-control-input" id="termsCheckboxExample2" name="termsCheckboxExample2" required data-msg="Please accept our Terms and Conditions." />
              <label class="custom-control-label" for="termsCheckboxExample2">
                <small>
                  I agree to the
                  <a class="link-underline" href="#" style={{ marginLeft: 10 }}>
                    Terms and Conditions
                  </a>
                </small>
              </label>
            </div>
          </div>

          <div class="row align-items-center mb-5">
            <div class="col-sm-6 mb-3 mb-sm-0">
              <span class="font-size-1 text-muted">Already have an account?</span>
              <Link to="/register" className="font-size-1 font-weight-bold" to="/login" style={{ marginLeft: 10 }}>
                Login
              </Link>
            </div>

            <div class="col-sm-6 text-sm-right">
              <TouchButton
                label="Register"
                onPress={() => {
                  if (!firstName) {
                    alert("Must enter first name.");
                    return;
                  }
                  if (!lastName) {
                    alert("Must enter last name.");
                    return;
                  }
                  if (!utils.isEmail(email)) {
                    alert("Must enter valid email.");
                    return;
                  }
                  if (!password) {
                    alert("Must enter password with at least 6 characters.");
                    return;
                  }
                  if (password.length < 6) {
                    alert("Must enter password with at least 6 characters.");
                    return;
                  }
                  if (!inviteCode) {
                    alert("Must enter invite code to register.");
                    return;
                  }

                  return utils
                    .register({ firstName, lastName, password, email, inviteCode })
                    .then(function () {
                      onLogin();
                    })
                    .catch(function (err) {
                      alert("Error: " + err);
                    });
                }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function checkLogin() {
  const promise = new PromiseA();

  var str = localStorage.getItem("loginData");
  if (str) {
    var loginData = JSON.parse(str);

    if (loginData.sessionId) {
      http
        .run("loginToken", {
          sessionId: loginData.sessionId,
          userId: loginData.userId
        })
        .then(function (data) {
          if (data.sessionId) {
            session.verifyCode = data.verifyCode;
            session.sessionId = data.sessionId;
            session.user = data.user;

            return utils.doLogin().then(function () {
              promise.resolve();
            });
          } else {
            promise.resolve();
          }
        })
        .catch(function (err) {
          alert(err);
          //alert("Error in error login:" + err.message);
          promise.resolve();
        });
    } else {
      promise.resolve();
    }
  } else {
    promise.resolve();
  }

  return promise;
}

function ServiceList({ searchText, list, onSelect, RenderServiceItem }) {
  var fList = list;
  if (searchText) {
    fList = fList.filter(item => {
      return item.name.toLowerCase().startsWith(searchText.toLowerCase());
    });
  }
  return (
    <View>
      {fList.length == 0 ? (
        <NoRecords label="No services found." />
      ) : (
        fList.map(item => {
          const { value } = item;

          return (
            <RenderServiceItem
              key={value}
              onPress={() => {
                onSelect(item);
              }}
              item={item}
            />
          );
        })
      )}
    </View>
  );
}

const getServicesPerSector = function (list, sectorValue) {
  return list.filter(item => item.serviceType == sectorValue).length;
};

/*
Ideation
Market Research
Product Design
Product Market/Fit
Product Development
Beta Customers
User Support/Product Support
Fulfillment Team
Team Building
Funding
Legal
HR

expertise:[
        {value:"Concept Development",value:"Concept Development"},
        {value:"Business Setup",value:"Business Setup"},
        {value:"Fundraising",value:"Fundraising"},
        {value:"People",value:"People"},
        {value:"Operations",value:"Operations"},
        {value:"Sales",value:"Sales"},
        {value:"Business/Partner Development",value:"Business Development"},
        {value:"Design",value:"Design"},
        {value:"Finance",value:"Finance"},
        {value:"Accounting/Back-Office Operations",value:"Accounting/Back-End Operations"},
        {value:"Marketing",value:"Marketing"},
        {value:"Fulfillment",value:"Fulfillment"},
        {value:"Product Development",value:"Product Development"},
        {value:"Product Management",value:"Product Management"},
        {value:"Technical",value:"Technical"},
        {value:"User Experience",value:"User Experience"},
        {value:"Strategy",value:"Strategy"},
        {value:"Manufacturing",value:"Manufacturing"},
        {value:"HR/Recruitment",value:"HR/Recruitment"},
        {value:"Legal",value:"Legal"}
    ],

Operational/HR/PM: responsible for managing operational goals, budgets, team hiring, project timelines, and alignment of resources.

Fulfillment, User Covert, Retention, Feedback, Support: responsible for converting acquired users into paying users and increasing retention rates, along with developing a strategy for product feedback and user support. 

PR/Media Outreach, Influencers: responsible for outreach with for new exposure opportunities, working with marketing team for high growth exposure, reach out with the other influencers for exposure.

Business Dev/Partner Development and Outreach: work to develop strategic partners for growth and revenue development.

Product Roadmap and Development: identify the product roadmap, work with UX/UI team and dev teams for product development.

Marketing/User Growth/Acquisition, Social Media: identify SEO, SEM, and other growth marketing strategies for user acquisition, manage social media brand presence.

Bookkeeper/Finance: responsible for bookkeeping, financial reports, 

Investor Relations/Fund Raising: responsible for the management and development of investor relations and fundraising.
*/

function ServicesBuyer() {
  const { serviceCategory } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, list, searchText } = state;
  const [tabValue, setTabValue] = useState("all")

  useEffect(() => {
    dL.getServices({ type: tabValue }).then(function (services) {
      setState({ ...state, isLoading: false, list: services });
    });
  }, [tabValue]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var fList = list;
  var foundCategory;
  if (serviceCategory) {
    foundCategory = serviceTypes.find(item => item.value == serviceCategory);

    fList = fList.filter(item => item.serviceType == serviceCategory);
  }

  return (
    <View>
      <HeaderText label={foundCategory ? foundCategory.label + " Services:" : "Search for Services:"} description="Search for a Growly consulting micro-services catalog to find the right combination of growth services for your business." style={{ marginBottom: 10 }} rightRender={foundCategory ? <BackButton /> : null} />

      <FlexRow bottom={15}>
        <FlexExpand>
          <TabBar
            queryId="tb"
            style={{ marginRight: 35 }}
            options={[searchText ? { label: "Search", value: "search" } : null, { label: "All", value: "all" }, { label: "Recently Added", value: "recent" }, { label: "Most Popular", value: "popular" }, { label: "Recently Updated", value: "updated" }]}
            onChange={item => {
              setTabValue(item.value)
            }}
            value={searchText ? "search" : tabValue}
          />
        </FlexExpand>
        <MyInput
          boxStyle={{ marginBottom: 0 }}
          placeholder="Search..."
          value={searchText}
          onChange={text => {
            setState({ ...state, searchText: text });
          }}
        />
      </FlexRow>

      {searchText || foundCategory ? (
        <ServiceList
          RenderServiceItem={RenderServiceItemBuyer}
          list={fList}
          searchText={searchText}
          onSelect={item => {
            history.push("/user/service/" + item.id + "?tb=" + tabValue);
          }}
        />
      ) : (
        <ServiceCatList
          onGetDescription={sectorValue => {
            return plural(getServicesPerSector(list, sectorValue), "service");
          }}
          onSelect={item => {
            history.push("/user/services/" + item.value + "?tb=" + tabValue);
          }}
        />
      )}
    </View>
  );
}

function ServicesWorker() {
  const { serviceCategory } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, list, searchText } = state;
  const [tabValue, setTabValue] = useState("all")

  useEffect(() => {
    dL.getUserServices({ type: tabValue }).then(function (services) {
      setState({ ...state, isLoading: false, list: services });
    });
  }, [tabValue]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var fList = list;
  var foundCategory;
  if (serviceCategory) {
    foundCategory = serviceTypes.find(item => item.value == serviceCategory);

    fList = fList.filter(item => item.serviceType == serviceCategory);
  }

  return (
    <View>
      <HeaderText label={foundCategory ? foundCategory.label + " Services:" : "Search for Services:"} description="Search the Growly consulting micro-services catalog to find other micro-services to bundle by other consultants." style={{ marginBottom: 10 }} rightRender={foundCategory ? <BackButton /> : null} />

      <FlexRow bottom={15}>
        <FlexExpand>
          <TabBar
            queryId="tb"
            style={{ marginRight: 35 }}
            options={[searchText ? { label: "Search", value: "search" } : null, { label: "All", value: "all" }, { label: "Recently Added", value: "recent" }, { label: "Most Popular", value: "popular" }, { label: "Recently Updated", value: "updated" }]}
            onChange={item => {
              setTabValue(item.value)
            }}
            value={searchText ? "search" : tabValue}
          />
        </FlexExpand>
        <MyInput
          boxStyle={{ marginBottom: 0 }}
          placeholder="Search..."
          value={searchText}
          onChange={text => {
            setState({ ...state, searchText: text });
          }}
        />
      </FlexRow>

      {searchText || foundCategory ? (
        <ServiceList
          RenderServiceItem={RenderServiceItemWorker}
          list={fList}
          searchText={searchText}
          onSelect={item => {
            history.push("/worker/service/" + item.id + "?tb=" + tabValue);
          }}
        />
      ) : (
        <ServiceCatList
          onGetDescription={sectorValue => {
            return plural(getServicesPerSector(list, sectorValue), "service");
          }}
          onSelect={item => {
            history.push("/worker/services/" + item.value + "?tb=" + tabValue);
          }}
        />
      )}

      <FlexRow style={{ marginTop: 35 }}>
        <BoxRowItem style={{ marginRight: 35 }}>
          <TextLine bottom={15} value="Open Source Consulting:" bold size={22} />
          <TextLine value="Growly support best practice open source business processes and workflows. As a community all our our consultants contribute to making and delivery greater business value with contribution from community best practice and learning through the Open Source initiative." />
        </BoxRowItem>

        <BoxRowItem>
          <TextLine bottom={15} value="Micro-Service Academy:" bold size={22} />
          <TextLine top={10} value="There is open source training available and their is an open source micro service academy that allows any one of our consultant to enhance their learning on the latest technologies and best practices learnt across on platform." />
        </BoxRowItem>
      </FlexRow>
    </View>
  );
}

/*
function EditServiceDeliveryItem({model, onCancel, onSave}) {
  const [state, setState] = useState({model: model ? model : {formData: {}}});

  const {serviceDeliverable, formData, comments, files} = model;
  const {deliveryType, form} = serviceDeliverable;

  return (
    <ModalBasic
      title="Edit Delivery Item"
      okText="Send Item"
      onCancel={onCancel}
      onOk={() => {
        onSave({
          deliverableType,
          formData,
          files,
          comments
        });
      }}>
      <SelectBox
        label="Deliverable Types:"
        value={deliverableTypes.find(item => item.value == deliverableType)}
        options={deliverableTypes}
        onChange={item => {
          setState({...state, deliverableId: item.value});
        }}
      />

      {deliveryType == "form" ? (
        <View>FORM to DISPLAY</View>
      ) : (
        <View>
          <FileElements
            label="Deliverable Files:"
            value={files}
            onChange={value => {
              setState({...state, files: value});
            }}
          />

          <MyInput
            style={{marginBottom: 0}}
            multiline
            label="Comments:"
            value={comments}
            onChange={value => {
              setState({...state, comments: value});
            }}
          />
        </View>
      )}
    </ModalBasic>
  );
}
*/

function ServiceWizard1() {
  return <View>
    <TextLine value="What services can you provide?" />
(quickly add a service in 4 easy steps)

    <Section>
      (Enter the name?)

      (Give a short description? 144 chars)
</Section>

    <Section>
      (What will you be delivering?)
      (Share exactly what you will be delivering and give an example document)

      (add deliverable)
</Section>

    <Section>
      (What are the steps for delivering this service?)
      (Provide the step to deliver this service with a short description of each step.)

      (add step)
</Section>

    <Section>
      (What information do you need to complete this service?)
      Provide as detailed a question set of information to quickly get the needed documents to start your service.

      (add input)
</Section>

    <Section>
      (What is the price and delivery days?)

      Price:

      Delivery Days:
</Section>
  </View>
}

function AccountSetup() {
  const params = new URLSearchParams(document.location.search.substring(1));
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { addBIVisible, teams, model, isLoading } = state

  useEffect(() => {
    if (params.stepNum) {
      setStepNum(params.stepNum)
      alert("change step to :" + params.stepNum)
    }
  }, [params.stepNum]);

  useEffect(() => {
    const { user } = session

    dL.getQuery("User")
      .select(["wizardData"])
      .get(session.user.id)
      .then(function (obj) {
        var str = obj.get("wizardData")
        const { firstName, lastName } = user
        var wizardData = {
          stepNum: 1,
          firstName,
          lastName
        }
        if (str) {
          wizardData = JSON.parse(str)
        }
        if (!wizardData.expertise) {
          wizardData.expertise = {}
        }

        dL.getQuery("Team")
          //.equalTo("isPublic", true)
          .containedIn("removed", [undefined, false])
          .ascending("name")
          .find().then(function (objs) {
            const teams = dL.loadObjects("Team", objs)

            setState({ ...state, isLoading: false, teams, model: wizardData });
          });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { likedTeams, helpWithes, firstName, lastName, companyName, title: companyTitle, stepNum, type } = model
  const { expertise, roles, industries, shortBio, website, yearsOfExp, homeCity, country, countryRegion } = model

  const { companyGoals, employeeSize, revenueSize, companyShortBio, companyStage, companyCity, companyIndustry } = model

  var buttons = []

  var title, description

  const saveProgress = function () {
    return dL.getObj("User", session.user.id).set("wizardData", JSON.stringify(model)).save()
  }

  const setStepNum = function (stepNum) {
    model.stepNum = stepNum
    saveProgress()
    setState({ ...state, model })
  }

  if (stepNum == 1) {
    title = "1) Account Setup:"
    description = "This information will help setup your account."
  } else if (type == "worker") {
    if (stepNum == 2) {
      title = "2) User Profile:"
      description = "Tell us some basic information about yourself."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(1)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          if (!firstName) {
            alert("Must enter first name.");
            return;
          }
          if (!lastName) {
            alert("Must enter last name.");
            return;
          }
          if (!companyName) {
            alert("Must enter company.");
            return;
          }
          if (!companyTitle) {
            alert("Must enter title.");
            return;
          }
          setStepNum(3)
        }
      })

    } else if (stepNum == 3) {
      title = "3) Detailed Bio:"
      description = "Tell us more about yourself?"
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(2)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          if (!homeCity) {
            alert("Must enter city.");
            return;
          }
          if (!country || !countryRegion) {
            alert("Must select country.");
            return;
          }
          if (!yearsOfExp) {
            alert("Must enter years of experience.");
            return;
          }
          if (!shortBio) {
            alert("Must enter short bio description.");
            return;
          }
          setStepNum(4)
        }
      })

    } else if (stepNum == 4) {
      title = "3) Expertise:"
      description = "Tell us more about your experience and skills?"
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(3)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(5)
        }
      })
    } else if (stepNum == 5) {
      title = "4) Engage:"
      description = "Tell us more about where you can engage with user?"
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(4)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(6)
        }
      })
    } else if (stepNum == 6) {
      title = "6) Teams:"
      description = "Tell us about teams that you are interested in joining."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(5)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(7)
        }
      })
    } else if (stepNum == 7) {
      title = "7) Services:"
      description = "Enter some services that you can provide."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(5)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(7)
        }
      })
    } else if (stepNum == 8) {
      title = "8) Survey:"
      description = "Help us create a better platform."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(6)
        }
      })
      buttons.push({
        label: "Finish", onPress: () => {
          if (!roles || roles.length == 0) {
            alert("Must select at least one role.");
            return;
          }
          if (!industries || industries.length == 0) {
            alert("Must select at least one industry.");
            return;
          }
          var hasExp = false
          for (var key in expertise) {
            hasExp = true
            break
          }
          if (!hasExp) {
            alert("Must select at least one expertise.");
            return;
          }

          const flags = session.user.flags
          flags.wizardCompleted = true
          dL.getObj("User", session.user.id)
            .set("firstName", firstName)
            .set("lastName", lastName)
            .set("name", firstName + " " + lastName)
            .set("companyName", companyName)
            .set("companyTitle", companyTitle)
            .set("shortBio", shortBio)
            .set("website", website)

            .set("roles", roles)
            .set("industries", industries)

            .set("homeCity", homeCity)
            .set("countryRegion", countryRegion)
            .set("country", country)

            .set("yearsOfExp", yearsOfExp)
            .set("expertise", expertise)

            .set("isWorker", true)
            .set("flags", flags)
            .save()

          history.replace("/worker")
        }
      })
    }

  } else if (type == "company") {
    if (stepNum == 2) {
      title = "2) User Profile:"
      description = "Tell us some basic information about yourself."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(1)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(3)
        }
      })

    } else if (stepNum == 3) {
      title = "3) Your Company:"
      description = "Let's start with basic information about your company."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(2)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(4)
        }
      })


    } else if (stepNum == 4) {
      title = "4) Company Details:"
      description = "Tell us more about your company? This will help match you with services and consultants."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(3)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(5)
        }
      })


    } else if (stepNum == 5) {
      title = "5) Business Initiatives:"
      description = "Tell us more about your current needs? This will help us match you with resources and services."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(4)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(6)
        }
      })

    } else if (stepNum == 6) {
      title = "6) Teams:"
      description = "Tell us about teams that you are interested in working with."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(4)
        }
      })
      buttons.push({
        label: "Next", onPress: () => {
          setStepNum(7)
        }
      })
    } else if (stepNum == 7) {
      title = "7) Survey:"
      description = "Help us create a better platform."
      buttons.push({
        label: "Prev", onPress: () => {
          setStepNum(6)
        }
      })
      buttons.push({
        label: "Finish", onPress: () => {
          const flags = session.user.flags
          flags.wizardCompleted = true

          dL.getObj("User", session.user.id)
            .set("firstName", firstName)
            .set("lastName", lastName)
            .set("name", firstName + " " + lastName)
            .set("companyName", companyName)
            .set("companyTitle", companyTitle)
            .set("flags", flags)
            .save()

          dL.getObj("Company", session.company.id)
            .set("shortBio", companyShortBio)
            .set("companyGoals", companyGoals)
            .set("website", website)
            .set("companyStage", companyStage)
            .set("companyIndustry", companyIndustry)
            .set("companyCity", companyCity)
            .set("employeeSize", employeeSize)
            .set("revenueSize", revenueSize)
            .save()

          history.replace("/user")
        }
      })
    }
  }

  return <ModalBasic
    large
    notCentered
    title={title}
    description={description} buttons={buttons}>
    <ScriptTag
      src="//maps.googleapis.com/maps/api/js?key=AIzaSyCuHtEOEHrsv6Kshy0LyodwjOJ81KhhNJ0&libraries=places"
      onLoad={() => {
        setState({ ...state, isLoaded2: true });
      }}
    />

    {stepNum == 1 ? <View>
      <TextLine value="Why do you want to setup an account?" />
      <BoxItem onBoxPress={() => {
        history.push("/wizard?step=2");
        model.type = "company"
        setStepNum(2)
      }}>
        <FontAwesomeIcon icon={faBuilding} style={{ fontSize: 32, color: "grey" }} />
        <TextLine value="Get help for your business" />
      </BoxItem>

      <BoxItem onBoxPress={() => {
        history.push("/wizard?step=2");
        model.type = "worker"
        setStepNum(2)
      }}>
        <FontAwesomeIcon icon={faBook} style={{ fontSize: 32, color: "grey" }} />
        <TextLine value="Help others with their business" />
      </BoxItem>
    </View> : null}

    {stepNum == 2 && type == "worker" ? <View>
      <FlexRow>
        <MyInput
          label="First Name:"
          value={firstName}
          onChange={value => {
            model.firstName = value
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 25 }} />
        <MyInput
          label="Last Name:"
          value={lastName}
          onChange={value => {
            model.lastName = value
            setState({ ...state, model });
          }}
        />
      </FlexRow>
      <MyInput
        style={{ maxWidth: 350 }}
        label="Company Name:"
        value={companyName}
        onChange={value => {
          model.companyName = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        label="Title:"
        style={{ maxWidth: 250 }}
        value={companyTitle}
        onChange={value => {
          model.companyTitle = value;
          setState({ ...state, model });
        }}
      />
    </View> : null}

    {stepNum == 3 && type == "worker" ? <View>
      <FormItem label="What city are you located?">
        {state.isLoaded2 ? (
          <LocationSearchInput
            defaultValue={homeCity}
            onChange={value => {
              model.homeCity = value;
              setState({ ...state, model });
            }}
          />
        ) : null}
      </FormItem>

      <FormItem label="What country/region are you in?">
        <FlexRow>
          <CountryDropdown className="form-control" value={country} onChange={(val) => {
            model.country = val;
            setState({ ...state, model });
          }} />
          <View style={{ width: 15 }} />
          {country ? <RegionDropdown className="form-control" country={country} value={countryRegion} onChange={val => {
            model.countryRegion = val;
            setState({ ...state, model });
          }} /> : null}
        </FlexRow>
      </FormItem>

      <FormItem label="How many years of experience do you have?">
        <SelectBox
          style={{ maxWidth: 175 }}
          value={yearsOfExp}
          options={utils.years}
          onChange={value => {
            model.yearsOfExp = value;
            setState({ ...state, model });
          }}
        />
      </FormItem>

      <MyInput
        label="Your Website: (optional)"
        value={website}
        onChange={value => {
          model.website = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        maxLength={144}
        label="About You:"
        value={shortBio}
        onChange={value => {
          model.shortBio = value;
          setState({ ...state, model });
        }}
      />
    </View> : null}

    {stepNum == 4 && type == "worker" ? <View>
      <FormItem label="Share a till more about your expertise?">
        <ExpertiseSelector value={expertise} onChange={(value) => {
          model.expertise = value
          setState({ ...state, model });
        }} />
      </FormItem>
    </View> : null}

    {stepNum == 5 && type == "worker" ? <View>
      <SelectBox
        multiple
        maxItems={3}
        label="What role or capacity do you prefer to help others?"
        value={roles}
        options={utils.roles}
        onChange={items => {
          model.roles = items;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        multiple
        maxItems={3}
        label="What industries do you have experience in?"
        description="Pick up to 3 items."
        value={industries}
        options={utils.industries}
        onChange={items => {
          model.industries = items;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        multiple
        maxItems={7}
        label="Can help with the following business initiatives:"
        description="Pick up to 7 items."
        value={helpWithes}
        options={getGroupedItems({ list: utils.goals, groupBy: "category" })}
        onChange={items => {
          model.helpWithes = items;
          setState({ ...state, model });
        }}
      />

      <LinkButton style={{ marginTop: 10 }} label="Don't see a business initiative that fits you? Add one yourself." onPress={() => {
        setState({ ...state, addBIVisible: true })
      }} />

      {addBIVisible ? <AddNewBusinessInitiative onSave={(item) => {
        if (!model.helpWithes) { model.helpWithes = [] }
        model.helpWithes.push({ label: item.name, value: item.id })
        setState({ ...state, model, addBIVisible: false })
      }} onCancel={() => {
        setState({ ...state, addBIVisible: false })
      }} /> : null}
    </View> : null}

    {stepNum == 6 && type == "worker" ? <View>
      <SelectBox
        groupBy="category"
        multiple
        maxItems={7}
        label="Select some teams that you would be interested in joining?"
        description="Pick up to 3 teams."
        value={likedTeams}
        options={teams.map(item => {
          return { label: item.name, value: item.id }
        })}
        onChange={items => {
          model.likedTeams = items;
          setState({ ...state, model });
        }}
      />

      <LinkButton style={{ marginTop: 10 }} label="Don't see a team, lead a new team?" onPress={() => {
        setState({ ...state, addBIVisible: true })
      }} />

      {addBIVisible ? <AddNewTeam onSave={(item) => {
        if (!model.likedTeams) { model.likedTeams = [] }
        model.likedTeams.push({ label: item.name, value: item.id })
        setState({ ...state, model, addBIVisible: false })
      }} onCancel={() => {
        setState({ ...state, addBIVisible: false })
      }} /> : null}
    </View> : null}

    {stepNum == 7 && type == "worker" ? <View>
      //what services do you provide or skip/fill our later
    </View> : null}

    {stepNum == 8 && type == "worker" ? <View>
      //survey:
      //what are your work preferences of top important (flexability, etc...)
      //anything else you would like to see?
    </View> : null}

    {stepNum == 2 && type == "company" ? <View>
      <FlexRow>
        <MyInput
          label="First Name:"
          value={firstName}
          onChange={value => {
            model.firstName = value
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 25 }} />
        <MyInput
          label="Last Name:"
          value={lastName}
          onChange={value => {
            model.lastName = value
            setState({ ...state, model });
          }}
        />
      </FlexRow>
      <MyInput
        style={{ maxWidth: 350 }}
        label="Company Name:"
        value={companyName}
        onChange={value => {
          model.companyName = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        label="Title:"
        style={{ maxWidth: 250 }}
        value={companyTitle}
        onChange={value => {
          model.companyTitle = value;
          setState({ ...state, model });
        }}
      />
    </View> : null}

    {stepNum == 3 && type == "company" ? <View>
      <FlexRow>
        <FormItem label="Business Stage:">
          <SelectBox
            value={companyStage}
            style={{ maxWidth: 250 }}
            options={utils.companyStages}
            onChange={items => {
              model.companyStage = items;
              setState({ ...state, model });
            }}
          />
        </FormItem>
        <View style={{ width: 25 }} />
        <FormItem label="Industry:">
          <SelectBox
            style={{ maxWidth: 350 }}
            value={companyIndustry}
            options={utils.industries}
            onChange={items => {
              model.companyIndustry = items;
              setState({ ...state, model });
            }}
          />
        </FormItem>
      </FlexRow>

      <FormItem label="Primary location of your company:">
        {state.isLoaded2 ? (
          <LocationSearchInput
            style={{ maxWidth: 350 }}
            defaultValue={companyCity}
            onChange={value => {
              model.companyCity = value;
              setState({ ...state, model });
            }}
          />
        ) : null}
      </FormItem>
    </View> : null}

    {stepNum == 4 && type == "company" ? <View>
      <FlexRow>
        <FormItem label="Employee Size:">
          <SelectBox
            value={employeeSize}
            options={utils.employeeSizes}
            onChange={items => {
              model.employeeSize = items;
              setState({ ...state, model });
            }}
          />
        </FormItem>
        <View style={{ width: 25 }} />
        <FormItem label="Revenue Size:">
          <SelectBox
            value={revenueSize}
            options={utils.revenueSizes}
            onChange={items => {
              model.revenueSize = items;
              setState({ ...state, model });
            }}
          />
        </FormItem>
      </FlexRow>

      <MyInput
        label="Company Website:"
        value={website}
        onChange={value => {
          model.website = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        maxLength={144}
        label="Company Description:"
        value={companyShortBio}
        onChange={value => {
          model.companyShortBio = value;
          setState({ ...state, model });
        }}
      />
    </View> : null}

    {stepNum == 5 && type == "company" ? <View>
      {companyGoals && companyGoals.length > 0 ? <View bottom={25}>
        <TextLine bold value="Prioritize these in terms of importance to you:" />
        <TextLine size={12} bottom={10} value="Drag to re-order the priority. Max 7 items." />

        <SortableList
          items={companyGoals}
          onChange={(list) => {
            model.companyGoals = list;
            saveProgress(model)
            setState({ ...state, model });
          }}
          renderItem={(item, index) => {
            return <BoxItem bold name={(index + 1) + ": " + item.label} hasMove onDelete={() => {
              companyGoals.splice(index, 1)
              saveProgress(model)
              setState({ ...state, model });
            }} />
          }} />
      </View> : null}

      <FormItem>
        <TextLine bold bottom={10} size={18} value="Need help with some business initiatives?"></TextLine>
        <TextLine bottom={15} value="Select from the list of business initiatives to share what could help with your growth."></TextLine>

        <SelectBox
          placeholder="Select up to 7 items"
          value={null}
          options={getGroupedItems({ groupBy: "category", list: utils.goals.filter(item => !companyGoals || !companyGoals.find(item2 => item2.value == item.value)) })}
          onChange={value => {
            if (!companyGoals) { model.companyGoals = [] }
            if (companyGoals.length == 7) {
              alert("You are limited to 7 items.")
              return
            }
            model.companyGoals.push(value)
            saveProgress(model)
            setState({ ...state, model });
          }}
        />

        <LinkButton style={{ marginTop: 10 }} label="Don't see a business initiative? Add a new initiative." onPress={() => {
          setState({ ...state, addBIVisible: true })
        }} />

        {addBIVisible ? <AddNewBusinessInitiative onSave={(item) => {
          model.companyGoals.push({ label: item.name, value: item.id })
          saveProgress(model)
          setState({ ...state, model, addBIVisible: false })
        }} onCancel={() => {
          setState({ ...state, addBIVisible: false })
        }} /> : null}
      </FormItem>
    </View> : null}

    {stepNum == 6 && type == "company" ? <View>
      <SelectBox
        groupBy="category"
        multiple
        maxItems={7}
        label="Select some teams that you would be interested in working with?"
        description="Pick up to 3 teams."
        value={likedTeams}
        options={teams}
        onChange={items => {
          model.likedTeams = items;
          setState({ ...state, model });
        }}
      />
    </View> : null}

    {stepNum == 7 && type == "company" ? <View>
      //survey:
      //biggest hurdles in the past when buying staff aug or consulting services
      //anything else you would like to see?
    </View> : null}
  </ModalBasic>
}

function AddWorkerService({ onCancel, onSave }) {
  const [state, setState] = useState({ model: {} });

  const { model } = state;

  const { name, shortDescription, price, deliveryDays } = model;

  const buttons = [];

  //—icon, name, description
  //-steps (step name, description)
  //-price, delivery days
  //—inputs needed (single form)
  //—deliverables (that you are going to deliver) (with examples)

  buttons.push({
    label: "Create Service",
    onPress: () => {
      if (!name) {
        alert("Must enter service name.");
        return;
      }
      return dL.newService(null, model).then(function () {
        onSave(model);
      });
    }
  });

  return (
    <ModalBasic title="Add a Service" onCancel={onCancel} buttons={buttons}>
      <Section>
        <MyInput
          label="Enter a service name:"
          placeholder="Enter name"
          value={name}
          onChange={value => {
            model.name = value;
            setState({ ...state, model });
          }}
        />

        <MyInput
          maxLength={144}
          multiline
          label="Enter a short description of the team:"
          value={shortDescription}
          onChange={value => {
            model.shortDescription = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <Section>
        <HeaderText subHeader label="Add some steps:" description="What is required to completed this service? Add some steps to share how you will complete this service."></HeaderText>

//steps with ability to
</Section>

      <Section>
        <MyInput
          label="What is price you want to set for this service?"
          style={{ maxWidth: 125 }}
          placeholder="25.00"
          inputType="money"
          minValue={0}
          value={price}
          onChange={value => {
            setState({ ...state, price: value });
          }}
        />

        <MyInput
          label="What is delivery days for this service?"
          style={{ maxWidth: 125 }}
          inputType="integer"
          minValue={0}
          value={deliveryDays}
          onChange={value => {
            setState({ ...state, deliveryDays: value });
          }}
        />
      </Section>

      <Section>
        <HeaderText subHeader label="Add some questions:" description="Get some input requirements that you need to complete this service."></HeaderText>

//add form inputs here
</Section>

      <Section>
        <HeaderText subHeader label="Add some deliverables:" description="Add what will be delivered from this service. Provide examples and be clear about what is offered."></HeaderText>

//add deliverables here
</Section>
    </ModalBasic>
  );
}

function AddNewTeam({ onCancel, onSave }) {
  const [state, setState] = useState({ model: {} });

  const { model } = state;

  const { name, businessSectors, shortDescription } = model;

  const buttons = [];

  buttons.push({
    label: "Create Team",
    onPress: () => {
      if (!name) {
        alert("Must enter team name.");
        return;
      }
      if (!businessSectors || businessSectors.length == 0) {
        alert("Must select a business sector.");
        return;
      }
      return dL.saveTeam(null, model).then(function () {
        onSave(model);
      });
    }
  });

  return (
    <ModalBasic title="Add a Team" onCancel={onCancel} buttons={buttons}>
      <MyInput
        label="Enter a team name:"
        placeholder="Enter name"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        multiple
        maxItems={3}
        label="What business sector?"
        value={businessSectors ? serviceTypes.filter(item => businessSectors.find(id => id == item.value)) : null}
        options={serviceTypes}
        onChange={item => {
          model.businessSectors = item.map(item => item.value)
          setState({ ...state, model });
        }}
      />

      <MyInput
        maxLength={144}
        multiline
        label="Enter a short description of the team:"
        value={shortDescription}
        onChange={value => {
          model.shortDescription = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

function AddNewBusinessInitiative({ onCancel, onSave }) {
  //add this to the company business initiative list (also mark as custom for us)

  //Help with:  (40 chars)
  //Business Sector: (required)
  //Enter a short description (optional)
  //Type: (optional)

  const [state, setState] = useState({ model: {} });

  const { model } = state;

  const { name, businessSector, shortDescription } = model;

  const buttons = [];

  buttons.push({
    label: "Save Initiative",
    onPress: () => {
      if (!name) {
        alert("Must enter helps with.");
        return;
      }
      if (!businessSector) {
        alert("Must select a business sector.");
        return;
      }
      return dL.saveBusinessInitiative(null, model).then(function () {
        onSave(model);
      });
    }
  });

  return (
    <ModalBasic title="Add an Initiative" onCancel={onCancel} buttons={buttons}>
      <MyInput
        maxLength={40}
        label="Help with:"
        placeholder="Enter name"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        label="What business sector?"
        value={businessSector ? serviceTypes.find(item => item.value == businessSector) : null}
        options={serviceTypes}
        onChange={item => {
          model.businessSector = item.value
          setState({ ...state, model });
        }}
      />

      <MyInput
        maxLength={144}
        multiline
        label="Enter a short description:"
        value={shortDescription}
        onChange={value => {
          model.shortDescription = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

/*
    Business Initiative: share with anyone using the link (can register and auto adds the user to the company) (able to add ideas and stream chat for the business initiatives) (add service request, add suggestions and ideas)

    Company Profile:
    -Build your company profile to build custom strategy maps
    -1) business types (startup, small business, medium business, enterprise)
    -2) statups: (select initiatives, defined current roles, ask for roles you need help on, create a service request)

    what are some of your business initiatives?
    1) select one based on search, not found, enter you goals/initiative (title, short description), a) looking for solution options  b) know the solution have an RFP  c) talk with an advisory council of industry expert (what is this?)

    -based on the business industry, create initiatives

    Advisory Councils:

    Consultants:
    -hours per month, hours per Week
    -location
    -pay by equity: yes, partial, none
    -thoughts on open source consulting, team leader
    -thoughts on future of work
    -thoughts on thought leadership
    */

/*
function ServicePackage() {
  const {serviceId, packageId} = useParams();
  const history = useHistory();
  const {path, url} = useRouteMatch();
  const [state, setState] = useState({isLoading: true, deliveryType: "standard", serviceOptionIds: { }});
  const {service, isLoading, model, deliveryType, serviceOptionIds} = state;

  useEffect(() => {
    var service;
    dL.get("Service", serviceId, ["deliverables", "packages"])
      .then(function(_service) {
        service = _service;
        return dL.get("ServicePackage", packageId);
      })
      .then(function(vPackage) {
        setState({ ...state, isLoading: false, service, model: vPackage });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const {hours, name, shortDescription, description, price, deliveryDays, hasExtraFastDelivery, extraFastDeliveryDays, extraFastPrice} = model;

  const {deliverables, serviceOptions, inputs} = service;
  const seeDeliverables = getDeliverablesAll({deliverables, packageId, serviceOptionIds});

  const {days, total} = getPriceDays({item: model, deliveryType, serviceOptions, serviceOptionIds});

  return (
    <div class="row">
        <div class="col-8">
          <BasicTop title={name} shortDescription={shortDescription} description={description} />

          <RenderServiceOptions
            service={service}
            packageId={packageId}
            serviceOptionIds={serviceOptionIds}
            onChange={value => {
              setState({ ...state, serviceOptionIds: value });
            }}
          />

          <RenderDeliverables service={service} displayAll={true} deliverables={deliverables} packageId={packageId} serviceOptionIds={serviceOptionIds} />

          <RenderDeliveryOptions
            value={deliveryType}
            service={service}
            packageId={packageId}
            serviceOptionIds={serviceOptionIds}
            onChange={value => {
              setState({ ...state, deliveryType: value });
            }}
          />
        </div>

        <div class="col-4">
          <div class="card-bordered">
            <div class="card-body">
              <div>
                <span class="h2 text-lh-sm mr-1 mb-0">
                  <Price price={total} days={days} deliveryText="Delivery in " />
                </span>
              </div>

              <div class="mb-2">
                <a
                  class="btn btn-block btn-primary transition-3d-hover"
                  onClick={() => {
                    var arr = [];
                    for (var key in serviceOptionIds) {
                      arr.push(serviceOptions.find(item => item.option.id == key));
                    }

                    const itemData = {
                      deliveryType,
                      deliveryDays: days,
                      service,
                      servicePackage: model,
                      serviceOptions: arr
                    };

                    utils.updateCartItem({
                      id: utils.guid(),
                      itemData,
                      quantity: 1,
                      price: total
                    });

                    session.dispatch("update-cart");

                    alert("Added to cart.");
                  }}>
                  Add to Cart
              </a>
              </div>

              {seeDeliverables.length > 0 ? (
                <React.Fragment>
                  <h2 class="h4">Service will includes:</h2>

                  {seeDeliverables.map(item => {
                    const { name, description, id } = item;

                    return (
                      <div key={id} class="media text-body font-size-1 mb-2">
                        <div class="min-w-3rem text-center mr-3">
                          <i class="fa fa-check" style={{ color: "green" }} />
                        </div>
                        <div class="media-body">{name}</div>
                      </div>
                    );
                  })}
                </React.Fragment>
              ) : null}
            </div>

            <a
              class="card-footer text-center font-weight-bold py-3"
              href="javascript:;"
              onClick={() => {
                history.push("/admin/service/" + serviceId + "/admin-edit");
              }}>
              <i class="fa fa-pencil mr-1" />
            Edit Service
          </a>
          </div>
        </div>
      </div>
  );
}

function ServicePackageAdmin() {
  const {serviceId, packageId} = useParams();
  const history = useHistory();
  const {path, url} = useRouteMatch();
  const [state, setState] = useState({isLoading: true});
  const [viewMode, setViewMode] = useState();
  const {isNew, service, editItem, showDelete, showDelete2, deleteId, deleteIndex, isLoading, model} = state;

  useEffect(() => {
    var service;
    dL.get("Service", serviceId, ["deliverables", "packages"])
      .then(function(_service) {
        service = _service;
        return dL.getQuery("ServicePackage").get(packageId);
      })
      .then(function(obj) {
        setState({ ...state, isLoading: false, service, model: dL.loadServicePackage(obj) });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const {hours, name, shortDescription, description, price, deliveryDays, hasExtraFastDelivery, extraFastDeliveryDays, extraFastPrice} = model;

  const deliverables = model.deliverables ? model.deliverables : [];
  const serviceOptions = model.serviceOptions ? model.serviceOptions : [];
  const inputs = model.inputs ? model.inputs : [];

  return (
    <View>
        <BasicTop
          title={name}
          shortDescription={shortDescription}
          onEditPress={() => {
            history.push("/admin/service/" + serviceId + "/admin-edit/package/" + packageId + "/edit");
          }}
          rightRender=<Price price={price} days={deliveryDays} deliveryText="Delivery in ">
          {hasExtraFastDelivery ? <TextLine value={"Fast: " + utils.money(extraFastPrice) + " in " + plural(extraFastDeliveryDays, "day")} /> : null}
        </Price>
        description={description}
      />
    </View>
  );
}
*/

function WorkerRegister() { }
function AdvisorRegister() { }
function CompanyRegister() { }
function CompanyProfile() { }

function EditProfile() {
  const [state, setState] = useState({});

  useEffect(() => {
    const { firstName, lastName, avatar } = session.user;
    setState({ firstName, lastName, avatar });
  }, []);

  const { firstName, lastName, avatar } = state;
  const { testMode, isSystemAdmin } = session.user;

  return (
    <View style={{ width: 350, marginHorizontal: 23 }}>
      <HeaderText label="Profile" description="Edit your profile." />

      <ImageElement
        label="Avatar:"
        value={avatar}
        onChange={value => {
          setState({ ...state, avatar: value });
        }}
      />

      <FlexRow>
        <MyInput
          label="First Name:"
          value={firstName}
          onChange={text => {
            setState({ ...state, firstName: text });
          }}
        />
        <View style={{ width: 15 }} />
        <MyInput
          label="Last Name:"
          value={lastName}
          onChange={text => {
            setState({ ...state, lastName: text });
          }}
        />
      </FlexRow>

      {isSystemAdmin ? (
        <Toggle
          label="Test Mode:"
          value={testMode}
          onChange={value => {
            session.user.testMode = value;
            setState({ ...state });
          }}
        />
      ) : null}

      <TouchButton
        label="Save Profile"
        onPress={() => {
          const user = session.user;
          user.firstName = firstName;
          user.lastName = lastName;
          user.avatar = avatar;

          alert("Saved.");
          return dL.saveUserProfile(session.user.id, { firstName, lastName, avatar, testMode });
        }}
      />
    </View>
  );
}

function Dash({ sectionId, menuItems, children }) {
  const [state, setState] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0);
  const history = useHistory();
  const chatNewCount = useChatCount();

  useEffect(() => {
    const updateCartCount = function () {
      dL.getQuery("ServiceProposal")
        .equalTo("user", dL.getObj("User", session.user.id))
        .greaterThan("buyerItemsInCart", 0)
        .containedIn("removed", [undefined, false])
        .find()
        .then(function (objs) {
          const proposals = dL.loadObjects("ServiceProposal", objs)
          var totalItems = 0
          proposals.forEach(item => {
            const { buyerItemsInCart } = item
            totalItems += buyerItemsInCart
          })
          setCartItemCount(totalItems)
        })
    }

    updateCartCount()

    eval("var unfold = new HSUnfold('.js-hs-unfold-invoker').init();");

    return session.watch("update-cart", function () {
      updateCartCount()
      setState({});
    });
  }, []);


  return (
    <HeaderTop2
      menuItems={menuItems}
      onMenuClick={item => {
        if (item.onClick) {
          item.onClick();
        } else {
          history.push(item.url);
        }
      }}
      rightRender={<React.Fragment>
        <li class="list-inline-item">
          <Link class="btn btn-sm btn-icon btn-ghost-secondary" href="javascript:;" to={`/${sectionId}/chats`}>
            <i class="fas fa-comments" />
            {chatNewCount ? (
              <span class="badge badge-pill badge-primary" style={{ position: "absolute", top: -3, right: -3 }}>
                {chatNewCount}
              </span>
            ) : null}
          </Link>
        </li>

        {sectionId == "user" ? (
          <li class="list-inline-item">
            <Link class="btn btn-sm btn-icon btn-ghost-secondary" href="javascript:;" to={`/${sectionId}/cart`}>
              <i class="fas fa-shopping-cart" />
              <span class="badge badge-pill badge-primary" style={{ position: "absolute", top: -3, right: -3 }}>
                {cartItemCount}
              </span>
            </Link>
          </li>
        ) : null}

        <li class="list-inline-item">
          <div class="hs-unfold">
            <a
              class="js-hs-unfold-invoker"
              href="javascript:;"
              data-hs-unfold-options='{
                    "target": "#accountDropdown",
                    "type": "css-animation",
                    "event": "hover"
                   }'
              data-hs-unfold-target="#accountDropdown"
              data-hs-unfold-invoker="">
              <span class="avatar avatar-sm avatar-centered avatar-circle avatar-border-sm">
                <img class="avatar-img" src={session.user.avatar ? utils.getImageUrl(session.user.avatar) : "../assets/img/160x160/img2.jpg"} alt="Image Description" />
              </span>
            </a>

            <div id="accountDropdown" class="hs-unfold-content dropdown-menu dropdown-menu-right hs-unfold-content-initialized hs-unfold-css-animation animated hs-unfold-hidden" data-hs-target-height="134" data-hs-unfold-content="" data-hs-unfold-content-animation-in="slideInUp" data-hs-unfold-content-animation-out="fadeOut" style={{ animationDuration: 300 }}>
              <Link class="dropdown-item" to={`/${sectionId}/profile`}>
                <i class="fas fa-user-circle dropdown-item-icon" />
                Profile
              </Link>
              <a
                class="dropdown-item"
                href="javascript:;"
                onClick={() => {
                  session.user = null;
                  session.sessionId = null;
                  session.logout();
                }}>
                <i class="fas fa-sign-out-alt dropdown-item-icon" />
                Logout
              </a>
            </div>
          </div>
        </li>
      </React.Fragment>}>
      <Container style={{ minHeight: 550 }}>{children}</Container>
    </HeaderTop2 >
  );
}

function DashBoxItem({ title, description, value, roleName, onChange, children }) {
  return (
    <BoxItem2 title={title} description={description} rightRender={<TouchButtonOnOff
      ONLabel="Disable"
      OFFLabel="Enable"
      micro
      value={value}
      onPress={() => {
        const enabledRoles = session.user.enabledRoles;
        var newValue;
        if (!value) {
          newValue = true;
        } else {
          newValue = false;
        }
        enabledRoles[roleName] = newValue;
        db.getObj("User", session.user.id)
          .set("enabledRoles", enabledRoles)
          .save();
        onChange();
      }}
    />}>
      {value ? children : null}
    </BoxItem2>
  );
}

function SetupStaffAugService({ onClose }) {
  const [state, setState] = useState({});

  const { hasStaffServices, hasStaffAugServices, hasAdvisoryServices, maxHoursPerMonth, maxHoursPerWeek, maxHoursPerDay, minHourlyRate, limitHoursPerWeek, limitHoursPerDay } = state;

  const hoursInMonth = [];
  for (var i = 1; i <= 10; i++) {
    hoursInMonth.push({ label: plural(i, "hr"), value: i });
  }
  for (var i = 15; i < 250; i = i + 5) {
    hoursInMonth.push({ label: plural(i, "hr"), value: i });
  }

  const hoursPerWeek = [];
  hoursPerWeek.push({ label: plural(1, "hr"), value: 1 });
  for (var i = 5; i < maxHoursPerMonth; i++) {
    hoursPerWeek.push({ label: plural(i, "hr"), value: i });
  }

  const hoursPerDay = [];
  for (var i = 1; i < maxHoursPerWeek; i++) {
    hoursPerDay.push({ label: plural(i, "hr"), value: i });
  }

  return (
    <ModalBasic
      title="Setup Staff Service"
      description="Setup and enable staff services."
      okText="Save"
      onCancel={() => {
        onClose();
      }}
      onOk={() => {
        onClose();
      }}>
      <TextLine size={16} bottom={15}>
        Become a provide of staff augmentation services. Setup how you would like to be hired and engaged with various clients from our platform.
      </TextLine>

      <Toggle
        label="Turn on Staff Services:"
        value={hasStaffServices}
        onChange={value => {
          setState({ ...state, hasStaffServices: value });
        }}
      />

      {hasStaffServices ? (
        <React.Fragment>
          <FormItem label="What type of staff services do you want to provide?">
            <BoxItem
              name="Staff Augmentation:"
              description=""
              hasCheck
              checked={hasStaffAugServices}
              onPress={() => {
                var vle;
                if (hasStaffAugServices) {
                  vle = false;
                } else {
                  vle = true;
                }
                setState({ ...state, hasStaffAugServices: vle });
              }}
            />
            <BoxItem
              name="Advisory:"
              description=""
              hasCheck
              checked={hasAdvisoryServices}
              onPress={() => {
                var vle;
                if (hasAdvisoryServices) {
                  vle = false;
                } else {
                  vle = true;
                }
                setState({ ...state, hasAdvisoryServices: vle });
              }}
            />
          </FormItem>

          <SelectBox
            label="How many hours will you be available per month?"
            value={hoursInMonth.find(item => item.value == maxHoursPerMonth)}
            options={hoursInMonth}
            onChange={item => {
              setState({ ...state, maxHoursPerMonth: item.value });
            }}
          />

          {hoursInMonth ? (
            <React.Fragment>
              <Toggle
                label="Do you want to set a maximum number of hours you can work per week?"
                value={limitHoursPerWeek}
                onChange={value => {
                  setState({ ...state, limitHoursPerWeek: value });
                }}
              />

              {limitHoursPerWeek ? (
                <React.Fragment>
                  <SelectBox
                    label="Maximum work hours per week:"
                    value={hoursPerWeek.find(item => item.value == maxHoursPerWeek)}
                    options={hoursPerWeek}
                    onChange={item => {
                      setState({ ...state, maxHoursPerWeek: item.value });
                    }}
                  />

                  <Toggle
                    label="Limit hours per day?"
                    value={limitHoursPerDay}
                    onChange={value => {
                      setState({ ...state, limitHoursPerDay: value });
                    }}
                  />

                  {limitHoursPerDay ? (
                    <SelectBox
                      label="Maximum work hours per day:"
                      value={utils.userRoles.find(item => item.value == maxHoursPerDay)}
                      options={hoursPerDay}
                      onChange={item => {
                        setState({ ...state, maxHoursPerDay: item.value });
                      }}
                    />
                  ) : null}
                </React.Fragment>
              ) : null}

              <MyInput
                label="What is the hourly rate that you would like to earn?"
                style={{ maxWidth: 125 }}
                placeholder="25.00"
                inputType="money"
                minValue={0}
                value={minHourlyRate}
                onChange={value => {
                  setState({ ...state, minHourlyRate: value });
                }}
              />
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </ModalBasic>
  );
}

function WorkerApplication({ onCancel, onSave }) {
  const [state, setState] = useState({ model: {} });
  const { model } = state
  const { workedAtConsultingCompany } = model

  return <ModalBasic
    large
    notCentered title="Apply" onCancel={onCancel} onOk={() => {
      onSave()
    }}>
    <FormItem label="Interested in the following roles:">
      // biz dev, strategy consultant, process consultant, specialist consultant, recruiter (?)
      </FormItem>

    <FormItem label="What type of work are you looking for?">
      (consulting,  contract work, staff aug, advisory)
      </FormItem>

    <FormItem label="How much are you available?">

      //(part-time: 20 hours, 40hr, 60, 80, 100, 120, 140, 160 hours per month), full-time?
      </FormItem>

    <Toggle
      label="Have worked at a consulting company?"
      value={workedAtConsultingCompany}
      onChange={value => {
        model.workedAtConsultingCompany = workedAtConsultingCompany
        setState({ ...state });
      }}
    />
    (which one, how many years?)


    <FormItem label="Tell us what teams you are interested in?">

    </FormItem>

    //Enter your Linked In profile:

    //Do you want a resume to attach? (optional)

    //Why do you want to join the growly network?

    //What is most important to you?  (Select three) balance for lifestyle, bonus and compensation, freedom of hours, vacation time available, team interactions, remote work capability, upward movement, growth and learning opportunities
</ModalBasic>
}

function WorkerDashboard() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState();
  const [earnings, setEarnings] = useState();
  const [bonus, setBonus] = useState();
  const [modelVisible, setModelVisible] = useState();
  const [state, setState] = useState({});

  const [refresh, setRefresh] = useState(new Date());
  const isBD = dL.isBD();
  const isPM = dL.isPM();
  const isSC = dL.isSC();
  const isSS = dL.isSS();

  const user = session.user;
  const { hasStaffServices, hasAdvisoryServices } = user;

  useEffect(() => {
    const promises = [];
    const stats = {};
    const userObj = db.getObj("User", session.user.id);

    const adminServiceTypes = session.user.adminServiceTypes

    promises[promises.length] = getNewChatCount()
      .then(function (count) {
        stats.newChatCount = count;
      });

    const getHours = function (startDate, endDate) {
      return dL.getTimeRecordHours({ startDate, endDate })
    }

    promises[promises.length] = getHours({ startDate: Moment().startOf("day").toDate(), endDate: Moment().endOf("day").toDate() }).then(function (hours) {
      stats.workedHoursToday = hours;
    })

    promises[promises.length] = getHours({ startDate: Moment().startOf("week").toDate(), endDate: Moment().endOf("day").toDate() }).then(function (hours) {
      stats.workedHoursThisWeek = hours;
    })

    promises[promises.length] = getHours({ startDate: Moment().startOf("month").toDate(), endDate: Moment().endOf("day").toDate() }).then(function (hours) {
      stats.workedHoursThisMonth = hours;
    })

    const userId = session.user.id

    promises[promises.length] = db.getQuery("ServiceOrder")
      .equalTo("assignedTo", dL.getObj("User", userId))
      .notContainedIn("status", ["canceled", "completed"])
      .containedIn("removed", [undefined, false])
      .select([])
      .find()
      .then(function (objs) {
        const serviceOrders = dL.loadObjects("ServiceOrder", objs)

        const ids = serviceOrders.map(item => item.id)
        //get all service order without questions answered
        //canStart=true, hasInputs=true, hasInputsComplete=false
        const promises = []
        promises[promises.length] = db
          .getQuery("ServiceOrder")
          .containedIn("_id", ids)
          .equalTo("canStart", true)
          .equalTo("hasInputs", true)
          .equalTo("hasInputsComplete", false)
          .containedIn("removed", [undefined, false])
          .count()
          .then(function (count) {
            stats.serviceOrdersThatNeedInputs = count;
          });

        //get all service deliveries for these service orders that need to be reviewed
        promises[promises.length] = db
          .getQuery("ServiceDelivery")
          .containedIn("serviceOrder", ids.map(id => db.getObj("ServiceOrder", id)))
          .equalTo("status", "pending")
          .containedIn("removed", [undefined, false])
          .count()
          .then(function (count) {
            stats.serviceDeliveriesThatNeedReview = count;
          });

        return Promise.all(promises)
      })

    promises[promises.length] = db
      .getQuery("ServiceOrder")
      .equalTo("deliveryToUser", session.user.id)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceOrdersAccessUserCount = count;
      });
    promises[promises.length] = db
      .getQuery("ServiceOrder")
      .equalTo("accessUsers.userId", session.user.id)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceOrdersAccessUserCount = count;
      });

    promises[promises.length] = db
      .getQuery("ServiceProposal")
      .equalTo("accessUsers.userId", session.user.id)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceProposalAccessUserCount = count;
      });

    promises[promises.length] = db
      .getQuery("TaskRecord")
      .equalTo("needConfirm", true)
      .existsObj("staffAugService", false)
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.tasksNeedingConfirmation = count;
      });

    promises[promises.length] = db
      .getQuery("StaffAugService")
      .equalTo("status", "open")
      .greaterThan("endDate", new Date())
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.staffRolesYouAssignedTo = count;
      });

    promises[promises.length] = db
      .getQuery("WorkerRequest")
      .equalTo("status", "pending")
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.myOpenWorkerRequests = count;
      });

    promises[promises.length] = db
      .getQuery("ServiceOrder")
      .equalTo("openWorkerRequest", 0)
      .equalTo("projectManager", db.getObj("User", session.user.id))
      .existsObj("assignedTo", false)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceOrdersWithNeedToSendWorkerRequests = count;
      });

    if (adminServiceTypes) {
      promises[promises.length] = db
        .getQuery("Service")
        .equalTo("status", "pending")
        .containedIn("serviceType", adminServiceTypes)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.servicesPendingReview = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceRequest")
        .equalTo("status", "open")
        .existsObj("strategyConsultant", false)
        .containedIn("serviceType", adminServiceTypes)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.serviceRequestsWithNoSC = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceRequest")
        .equalTo("status", "open")
        .equalTo("proposalCount", 0)
        .containedIn("serviceType", adminServiceTypes)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.serviceRequestsWithNoProposals = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceOrder")
        .notContainedIn("status", ["completed", "canceled"])
        .existsObj("projectManager", false)
        .containedIn("serviceType", adminServiceTypes)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.serviceOrdersWithNoPM = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceOrder")
        .equalTo("status", "open")
        .existsObj("assignedTo", false)
        .containedIn("serviceType", adminServiceTypes)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.serviceOrdersNotAssigned = count;
        });
    }

    promises[promises.length] = db
      .getQuery("TaskRecord")
      .notContainedIn("status", ["started", "working"])
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.tasksStarted = count;
      });

    promises[promises.length] = db
      .getQuery("TaskRecord")
      .notContainedIn("status", ["ready"])
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.tasksReadyToStart = count;
      });

    promises[promises.length] = db
      .getQuery("TaskRecord")
      .notContainedIn("status", ["completed", "canceled"])
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.tasksAssignedToYou = count;
      });

    promises[promises.length] = db
      .getQuery("TaskRecord")
      .notContainedIn("status", ["completed", "canceled"])
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .exists("dueDate", true)
      .lessThan("dueDate", new Date())
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.tasksPastDue = count;
      });

    if (isBD) {
      promises[promises.length] = db
        .getQuery("ServiceRequest")
        .equalTo("businessDevelopment", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.BDServiceRequestsAssigned = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceProposal")
        .equalTo("businessDevelopment", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.BDProposalsAssigned = count;
        });
    }

    if (isPM) {
      promises[promises.length] = db
        .getQuery("ServiceProposal")
        .equalTo("projectManager", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.PMProposalsAssigned = count;
        });

      promises[promises.length] = db
        .getQuery("Order")
        .equalTo("projectManager", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.PMOrdersAssigned = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceOrder")
        .equalTo("projectManager", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.PMServiceOrdersAssigned = count;
        });
    }

    if (isSC) {
      promises[promises.length] = db
        .getQuery("ServiceRequest")
        .equalTo("strategyConsultant", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.SCServiceRequestsAssigned = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceProposal")
        .equalTo("strategyConsultant", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.SCProposalsAssigned = count;
        });

      promises[promises.length] = db
        .getQuery("Order")
        .equalTo("strategyConsultant", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.SCOrdersAssigned = count;
        });

      promises[promises.length] = db
        .getQuery("ServiceOrder")
        .equalTo("strategyConsultant", userObj)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          stats.SCServiceOrdersAssigned = count;
        });
    }

    promises[promises.length] = dL.getEarningStats(session.user.id).then(function (stats) {
      setEarnings(stats);
    });
    promises[promises.length] = dL.getBonusStats(session.user.id).then(function (stats) {
      setBonus(stats);
    });

    Promise.all(promises).then(function () {
      setStats(stats);

      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { joinVisible } = state
  const { BDServiceRequestsAssigned, BDProposalsAssigned, PMProposalsAssigned, PMOrdersAssigned, PMServiceOrdersAssigned, SCServiceRequestsAssigned, SCProposalsAssigned, SCOrdersAssigned, SCServiceOrdersAssigned } = stats;

  const { newChatCount, serviceRequestsWithNoSC, servicesPendingReview, serviceOrdersWithNoPM, serviceRequestsWithNoProposals, serviceOrdersNotAssigned, tasksReadyToStart, tasksStarted } = stats

  const { tasksNeedingConfirmation, staffRolesYouAssignedTo, myOpenWorkerRequests, serviceOrdersWithNeedToSendWorkerRequests } = stats

  const { workedHoursToday, workedHoursThisWeek, workedHoursThisMonth, serviceOrdersAccessUserCount, serviceProposalAccessUserCount } = stats

  const { serviceOrdersThatNeedInputs, serviceDeliveriesThatNeedReview } = stats

  return (
    <View>
      <HeaderText label="Dashboard:" />

      <FlexRow alignTop>
        <FlexExpand>
          <HeaderText subHeader label="Getting Started:" description="Select and setup different working roles that you can take within the Growly platform." />

          <BoxItem spacer>
            <HeaderText subHeader style={{ marginBottom: 0 }} label="Work Interest:" description="Join the Growly network." />

            <BoxItem name="Become an Advisor:" description="Join our network and become a part-time or full-time advisor." rightRender={<TouchButtonOnOff
              ONLabel="Disable"
              OFFLabel="Enable"
              micro
            />} />

            <BoxItem name="Become an Consultant:" description="Join our network and become a part-time or full-time consultant." rightRender={<TouchButtonOnOff
              ONLabel="Disable"
              OFFLabel="Enable"
              micro
            />} >
              <FlexRow wrap>
                <BoxItem smallHeader name="Business Development (Bd):" description="As a business development specialist, work with strategy consultants and project managers to develop proposals and build/deliver solutions. Build your sales business on your time in your specialized area and get the support from our team members." />

                <BoxItem smallHeader name="Project Manager (Pm)" description="As a project manager, work with strategy consultants and other solution experts to deliver solutions. Build an execution driven team of specialist to deliver catered solutions." />

                <BoxItem smallHeader name="Strategy Consultant (Sc)" description="As a strategy consultant, work with business development, project manager, solution specialist to define and delivery service and solutions. Build a core niche expertise to delivery industry best practice solutions." />

                <BoxItem smallHeader name="Solution Specialist (Se)" description="As a solution specialist, delivery solutions defined by strategy consultants. Build and deliver on our vast catalog of micro-services." />


                <DashBoxItem
                  title="Business Development:"
                  description="As a business development specialist, work with strategy consultants and project managers to develop proposals and build/deliver solutions. Build your sales business on your time in your specialized area and get the support from our team members."
                  value={isBD}
                  roleName="businessDevelopment"
                  onChange={() => {
                    setRefresh(new Date());
                  }}>
                  <FlexRow>
                    <LabelItem label="Service Requests:" description="" value={BDServiceRequestsAssigned} onPress={() => { }} />
                    <LabelItem label="Proposals:" description="" value={BDProposalsAssigned} onPress={() => { }} />
                  </FlexRow>
                </DashBoxItem>

                <DashBoxItem
                  title="Project Manager:"
                  description="As a project manager, work with strategy consultants and other solution experts to deliver solutions. Build an execution driven team of specialist to deliver catered solutions."
                  value={isPM}
                  roleName="projectManager"
                  onChange={() => {
                    setRefresh(new Date());
                  }}>
                  <FlexRow>
                    <LabelItem label="Proposals:" description="" value={PMProposalsAssigned} onPress={() => { }} />
                    <LabelItem label="Orders:" description="" value={PMOrdersAssigned} onPress={() => { }} />
                    <LabelItem label="Service Orders:" description="" value={PMServiceOrdersAssigned} onPress={() => { }} />
                  </FlexRow>

                  <FlexRow>
                    <LabelItem label="Service Requests - Not Assigned:" description="" value="0" onPress={() => { }} />
                    <LabelItem label="Orders - Not Assigned:" description="" value="0" onPress={() => { }} />
                  </FlexRow>
                </DashBoxItem>

                <DashBoxItem
                  title="Strategy Consultant:"
                  description="As a strategy consultant, work with business development, project manager, solution specialist to define and delivery service and solutions. Build a core niche expertise to delivery industry best practice solutions."
                  value={isSC}
                  roleName="strategyConsultant"
                  onChange={() => {
                    setRefresh(new Date());
                  }}>
                  <FlexRow>
                    <LabelItem label="Service Requests:" description="" value={SCServiceRequestsAssigned} onPress={() => { }} />
                    <LabelItem label="Proposals:" description="" value={SCProposalsAssigned} onPress={() => { }} />
                    <LabelItem label="Orders:" description="" value={SCOrdersAssigned} onPress={() => { }} />
                    <LabelItem label="Service Orders:" description="" value={SCServiceOrdersAssigned} onPress={() => { }} />
                  </FlexRow>
                  <FlexRow>
                    <LabelItem label="Service Requests - Not Assigned:" description="" value="0" onPress={() => { }} />
                    <LabelItem label="Orders - Not Assigned:" description="" value="0" onPress={() => { }} />
                  </FlexRow>
                </DashBoxItem>

                <DashBoxItem
                  title="Solution Specialist:"
                  description="As a solution specialist, delivery solutions defined by strategy consultants. Build and deliver on our vast catalog of micro-services."
                  value={isSS}
                  roleName="solutionSpecialist"
                  onChange={() => {
                    setRefresh(new Date());
                  }}>
                  <FlexRow>
                    <LabelItem label="Service Orders:" description="" value="0" onPress={() => { }} />
                    <LabelItem label="Tasks:" description="" value="0" onPress={() => { }} />
                  </FlexRow>
                </DashBoxItem>

                <DashBoxItem2
                  title="Staff Services:"
                  description="Be hired as part of a client team as a staff augmentation member."
                  onChange={() => {
                    setModelVisible(true);
                  }}>
                  //setup for the following staff roles //x open services //This Month: total hours/month //Next Month: total hours/month
          </DashBoxItem2>

                <DashBoxItem2
                  title="Advisory Services:"
                  description="Become an advisory on our platform."
                  onChange={() => {
                    setModelVisible(true);
                  }}>
                  //setup for the following advisory roles //x open services
          </DashBoxItem2>
              </FlexRow>
            </BoxItem>
          </BoxItem>

          <BoxItem>
            <TextLine bold bottom={10} size={18} value="Limited consultants and advisors openings available." />
            <TextLine bottom={15} value="Be part of enterprise, growth, startup clients team." />

            <TextLine bottom={8} value="Apply to join the Growly network:" />
            <TouchButton micro label="Join Now" onPress={() => { }} />
          </BoxItem>

        </FlexExpand>

        <BoxItem style={{ maxWidth: 450 }}>
          <HeaderText subHeader label="125 new service requests are waiting" description="for services in categories of interest to you." />

          <TextLine bottom={10} value="Complete your profile and get approved to start building your service catalog and submit of your own strategy maps or join others consultants and create broader strategy maps" />

          <BoxItem name="What is a service requests?" description="Service requests are submitted by our companies that required services from our consultants or advisors." />
          <BoxItem name="What is a strategy map?" description="Strategy maps are created and submitted to companies by our consultants that can envelope one or multiple services as a solution for a business initiative." />
        </BoxItem>
      </FlexRow>

      <FlexRow alignTop>
        <FlexExpand>
          <Section>
            <HeaderText subHeader label="Action Tasks:" description="There are some items that need your attention." />

            <ActionItem label="new chat messages" count={newChatCount} onPress={() => {
              history.push(`/${sectionId}/chats`)
            }} />

            <ActionItem label="new tasks issued as part of staff roles that need to be reviewed and confirmed" count={tasksNeedingConfirmation} onPress={() => {
            }} />

            <ActionItem label="service orders that need work requests issued" count={serviceOrdersWithNeedToSendWorkerRequests} onPress={() => {
            }} />

            <ActionItem label="service orders need to be assigned to a project manager" count={serviceOrdersWithNoPM} onPress={() => {
              //go to service orders not assigned
              history.push(`/${sectionId}/service-orders`)
            }} />

            <ActionItem label="service requests need to be assigned to a strategy consultant" count={serviceRequestsWithNoSC} onPress={() => {
              //go to service request not assigned SC
              history.push(`/${sectionId}/service-requests`)
            }} />

            <ActionItem label="service requests that need a submitted proposal" count={serviceRequestsWithNoProposals} onPress={() => {
              history.push(`/${sectionId}/service-requests`)
            }} />

            <ActionItem label="service orders need to be assigned to a worker" count={serviceOrdersNotAssigned} onPress={() => {
              //go to service order not assigned PM
              history.push(`/${sectionId}/service-requests`)
            }} />

            <ActionItem label="services need to be reviewed to be added into the service catalog" count={servicesPendingReview} onPress={() => {
              //go to service order not assigned PM
              history.push(`/${sectionId}/services`)
            }} />

            <ActionItem label="tasks have been started and need to be completed" count={tasksStarted} onPress={() => {
              //go to service order not assigned PM
              history.push(`/${sectionId}/tasks?tb=started`)
            }} />

            <ActionItem label="tasks are ready to be worked on" count={tasksReadyToStart} onPress={() => {
              //go to service order not assigned PM
              history.push(`/${sectionId}/tasks?tb=ready`)
            }} />

            <ActionItem label="new service orders that need questions answered to start work" count={serviceOrdersThatNeedInputs} onPress={() => {
              history.push(`/${sectionId}/service-orders`)
            }} />

            <ActionItem label="new service order deliveries that needs to be reviewed" count={serviceDeliveriesThatNeedReview} onPress={() => {
              history.push(`/${sectionId}/service-orders`)
            }} />
          </Section>
        </FlexExpand>

        <View style={{ width: 350, marginLeft: 20 }}>
          <BoxItem2 title="My Stats:" >
            <StatItem label="Hours Worked Today:" description="hours worked today" count={workedHoursToday} />
            <StatItem label="Hours Worked This Week:" description="hours worked this week" count={workedHoursThisWeek} />
            <StatItem label="Hours Worked This Month:" description="hours worked this month" count={workedHoursThisMonth} />
            <StatItem label="Staff Roles:" description="staff roles that you are part of" count={staffRolesYouAssignedTo} />
            <StatItem label="Open Worker Requests:" description="open worker requests that you need to answer" count={myOpenWorkerRequests} />

            <StatItem label="Service Orders:" description="service orders that you are managing" count={serviceOrdersAccessUserCount} />

            <StatItem label="Strategy Maps:" description="strategy map that you are managing" count={serviceProposalAccessUserCount} />
          </BoxItem2>

          <Section>
            <RenderInvitations />
          </Section>

          <Hide>
            <BoxItem2 title="Earnings:" description="See your earnings below.">
              <Counter label="Pending" value={earnings.pending} type="money" />
              <Counter label="Available" value={earnings.available} type="money" />
              <Counter label="Withdrawn" value={earnings.withdrawn} type="money" />
            </BoxItem2>

            <BoxItem2 title="Bonus Pool:" description="Join on our platform and get direct access to your bonus pool.">
              <TextLine bold label="Vested:" value={utils.money(bonus.available)} />
              <TextLine bold label="Pending:" value={utils.money(bonus.pending)} />
              <TextLine bold label="Total:" value={utils.money(bonus.available + bonus.pending)} />
            </BoxItem2>

            <BoxItem2 title="Available Work:" description="View available service requests.">
              <TextLine value="Connect with clients looking for services." />
              <TextLine value="Setup your services or create new custom services." />
              <TextLine value="Submit strategy maps/proposals quickly and easily on our platform, combine your services with others on the platform to create more value." />

              <TextLine bold value="25 service requests available."></TextLine>

              <TouchButton label="Setup Notifications" onPress={() => { }} />
            </BoxItem2>
            <BoxItem2 title="Settings:" description="Change other settings regarding your work and role.">
              <Avatar value={session.user.avatar} />
              <LabelItem inline label="Primary Role:" value={session.user.userRole} />
              <LabelItem inline label="Logged In As:" value={session.user.firstName + " " + session.user.lastName} />

              <TouchButton label="Change Settings" onPress={() => { }} />
            </BoxItem2>
          </Hide>
        </View>
      </FlexRow>

      {modelVisible ? (
        <SetupStaffAugService
          onClose={() => {
            setModelVisible(false);
          }}
        />
      ) : null}

      {joinVisible ? (
        <WorkerApplication
          onCancel={() => {
            setState({ ...state, joinVisible: null })
          }}
          onSave={() => {
            setState({ ...state, joinVisible: null })
          }}
        />
      ) : null}

      <Section>
        <BoxItem>
          <TextLine bold value="Consultant Application:" />
          <TextLine value="Growth Consulting Application Submitted:" />
          <TextLine value="Consultant Type: Advisor" />
          <TextLine value="Available for Work: as of" />
          <TextLine value="Hours of Work:" />

          <TextLine value="Most important to you in selecting the right work partner: balance for lifestyle, bonus and compensation, freedom of hours, vacation time available, team interactions, remote work capability, upward movement, growth and learning opportunities" />

          <TextLine value="There are currently 103 pending new consultant applications for the growth clients team. To delivery the highest quality of services and to provide the best fit we interview each consultant to see what their needs are and see if it is s good match for our services, clients and delivery model." />
        </BoxItem>

        <BoxItem>
          <TextLine bold size={18} value="Our network stats:" />

          <FlexRow wrap>
            <StatItem label="Number of applied consultants:" value={"8,453"} />
            <StatItem label="Number of consultants accepted:" value={"225 (10.2%)"} />

            <StatItem label="Number of active consultants in our network:" value={"274"} />
            <StatItem label="Number of advisors in our network:" value={"343"} />
            <StatItem label="Number of specialized consulting teams:" value={"43"} />

            <StatItem label="Average years of experience for a consultant:" value={"5.4 yrs"} />
            <StatItem label="Number of countries our consultants work:" value={"25 countries"} />
            <StatItem label="Number of time-zones our consultants work:" value={"15 timezones"} />

            <StatItem label="Number of micro-services completed this year:" value={"12,423 services"} />
            <StatItem label="Number of micro-services completed in the last 30 days:" value={"5,234 services"} />
            <StatItem label="Average new micro-services per month:" value={"252 services"} />

            <StatItem label="Average first year earnings for a consultants:" value={"$7,233/Month"} />
            <StatItem label="Average monthly earnings for a senior consultants:" value={"$10,534/month"} />
            <StatItem label="Average monthly earnings for a top consultants:" value={"$15,534/month"} />
            <StatItem label="Average hours for top consultants per month:" value={"185 hrs"} />

            <StatItem label="Number of clients:" value={"225"} />
            <StatItem label="Average enterprise client spend:" value={"$250,423/year"} />
            <StatItem label="Average growth client spend:" value={"$62,432/year"} />
            <StatItem label="Average startup client spend:" value={"$15,432/year"} />
            <StatItem label="Number of countries we have clients:" value={"3 countries"} />
          </FlexRow>
        </BoxItem>
      </Section>

      <Hide>
        <SelectBox
          label="User Role:"
          value={utils.userRoles.find(item => item.value == session.user.userRole)}
          options={utils.userRoles}
          onChange={() => {
            //session.user.userRole = item.value;
            //setState({...state});
          }}
        />

        <View>
          <TextLine value="BD:" />
          <TextLine>1) See all service requests with proposals and assigned BD for follow-up.</TextLine>
          <TextLine>2) Create new service requests for new client (?). (This will force PM to assign SC)</TextLine>
        </View>
        <View>
          <TextLine value="SC:" />
          <TextLine>1) Check for any open service requests to follow-up on and create a proposal (show only orders that have you business sector).</TextLine>
          <TextLine>2) Assign BD for proposal (show only BD within the save business sector).</TextLine>
          <TextLine>3) Check for open orders that have not been assigned a SC and self assign? (show only orders that have you business sector).</TextLine>
          <TextLine>4) Create proposal and optionally assign workers to proposals, fill out client requirements.</TextLine>
        </View>
        <View>
          <TextLine value="PM:" />
          <TextLine>0) Check open service requests and assign a relevant SC/BD.</TextLine>
          <TextLine>1) Check for any open orders with no PM, self assign or auto assigned it set by SC in proposal? (show only orders that have you business sector).</TextLine>
          <TextLine>2) Check for client side requirements gathering, schedule meetings with team.</TextLine>
          <TextLine>3) Assign workers for service orders.</TextLine>
        </View>
        <View>
          <TextLine value="Consultant:" />
          <TextLine>1) Check for work requests to accept.</TextLine>
          <TextLine>2) Check for new service orders that are assigned, require client input, ready to work.</TextLine>
        </View>

        <Text>Potential Earnings: (Proposals)</Text>
        <Text>Potential Earnings: (Orders)</Text>
        <Text>Potential Earnings: (Service Orders)</Text>

        <Text>Referral Links: (clicked, ordered)</Text>

        <Text>Earning for Recruits: (assigned to $, worked on, sign up date)</Text>

        <Text>Bonus Pool:</Text>
      </Hide>
    </View>
  );
}

function BuyerWaitingScreen() {
  //thank for signing up for Growly
  //start by telling us a little about your company and growth needs
}

function WorkerWaitingScreen() {
  //thank for signing up for Growly
  //start by telling us a little about your company and growth needs
}

function RegisterWizard() {
  //getting started wizard
  //step 1 of 3
  //save for later option
  //next, prev, finished

  //show waiting screen once completed for our launch date
  //show number of exclusive clients, number of micro-services
  //show number of expert consultants

  //step number
  return <View>

  </View>
}

function BuyerDashboard() {
  //find services, find people

  //action tasks:
  //2 new messages to review
  //5 service orders need you to answer some questions before they are started.
  //2 deliverables are waiting for you to review
  //10 open tasks are waiting for you to complete
  //4 new proposals that need to be reviewed

  //stats: open orders, open service orders, open service requests

  const { sectionId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState();

  const user = session.user;
  const userId = user.id
  const userObj = db.getObj("User", userId);

  useEffect(() => {
    const promises = [];
    const stats = {};

    promises[promises.length] = getNewChatCount()
      .then(function (count) {
        stats.newChatCount = count;
      });

    promises[promises.length] = db.getQuery("ServiceOrder")
      .equalTo("user", db.getObj("User", session.user.id))
      .notContainedIn("status", ["canceled", "completed"])
      .containedIn("removed", [undefined, false])
      .select([])
      .find()
      .then(function (objs) {
        const serviceOrders = dL.loadObjects("ServiceOrder", objs)
        stats.openServiceOrders = serviceOrders.length

        const ids = serviceOrders.map(item => item.id)
        //get all service order without questions answered
        //canStart=true, hasInputs=true, hasInputsComplete=false
        const promises = []
        promises[promises.length] = db
          .getQuery("ServiceOrder")
          .containedIn("_id", ids)
          .equalTo("canStart", true)
          .equalTo("hasInputs", true)
          .equalTo("hasInputsComplete", false)
          .containedIn("removed", [undefined, false])
          .count()
          .then(function (count) {
            stats.serviceOrdersThatNeedInputs = count;
          });

        //get all service deliveries for these service orders that need to be reviewed
        promises[promises.length] = db
          .getQuery("ServiceDelivery")
          .containedIn("serviceOrder", ids.map(id => db.getObj("ServiceOrder", id)))
          .equalTo("status", "pending")
          .containedIn("removed", [undefined, false])
          .count()
          .then(function (count) {
            stats.serviceDeliveriesThatNeedReview = count;
          });

        return Promise.all(promises)
      })

    promises[promises.length] = db
      .getQuery("ServiceRequest")
      .equalTo("createdBy", userObj)
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.openServiceRequests = count;
      });

    promises[promises.length] = db
      .getQuery("ServiceRequest")
      .equalTo("createdBy", userObj)
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .select([])
      .find()
      .then(function (objs) {
        //get all proposal for these service requests that are new
        return db
          .getQuery("ServiceProposal")
          .equalTo("serviceRequest", objs)
          .equalTo("isNew", true)
          .containedIn("removed", [undefined, false])
          .count()
          .then(function (count) {
            stats.newServiceProposalsToReview = count;
          });
      });

    promises[promises.length] = db
      .getQuery("Member")
      .equalTo("type", "project")
      .equalTo("user", userObj)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.partOfProjects = count;
      });

    Promise.all(promises).then(function () {
      setStats(stats);

      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { newChatCount, newServiceProposalsToReview, serviceDeliveriesThatNeedReview, serviceOrdersThatNeedInputs } = stats

  const { openServiceRequests, openServiceOrders, partOfProjects } = stats

  return <View>
    <HeaderText label="Dashboard:" />

    <FlexRow alignTop>
      <FlexExpand>
        <Section>
          <HeaderText subHeader label="Action Tasks:" description="There are some items that need your attention." />

          <ActionItem label="new chat messages" count={newChatCount} onPress={() => {
            history.push(`/${sectionId}/chats`)
          }} />

          <ActionItem label="new service orders that needs questions answered to start work" count={newServiceProposalsToReview} onPress={() => {
            history.push(`/${sectionId}/service-orders?tb=pending`)
          }} />

          <ActionItem label="new service order deliveries that needs to be reviewed" count={serviceDeliveriesThatNeedReview} onPress={() => {
            history.push(`/${sectionId}/service-orders?tb=review`)
          }} />

          <ActionItem label="new strategy map to review" count={serviceOrdersThatNeedInputs} onPress={() => {
            history.push(`/${sectionId}/service-requests?tb=open`)
          }} />
        </Section>

        <Section>
          <HeaderText subHeader label="Getting Started:" description="Start addressing your business initiatives by tell us more about your company and current needs." />

          <BoxItem name="1) Share a little about your current business goals." />
          <BoxItem name="2) Submit a service request/RFP with a specific service requirements or search our list of services to start ordering." />
          <BoxItem name="3) Invite other team members." />
        </Section>

        <BoxItem>
          <FlexRow wrap>
            <BoxItem boxHeight={250} name="Create your company profile" spacer />
            <BoxItem boxHeight={250} name="Add business initiative blocks" spacer />
            <BoxItem boxHeight={250} name="Advisory council service" spacer />
            <BoxItem boxHeight={250} name="Find staff augmentation services" spacer />
            <BoxItem boxHeight={250} name="Add growth consulting services" spacer />
          </FlexRow>
        </BoxItem>

        <Section>
          <HeaderText subHeader label="Dashboard:" />

          <BoxItem>
            <TextLine value="Company Profile:" />
            <TextLine value="Build your company profile to get custom strategy maps." />
            <TextLine value="What is a strategy map?" />

            (currently have the following roles)
            (looking for business roles)
            (current initiatives)
          </BoxItem>

          <BoxItem>
            <TextLine value="Ask for a solution?" />
            <TextLine value="Submit a service request and ask for a custom strategy map and solution for your business growth." />
            <TextLine value="What is a service request?" />
            <TextLine value="25 open service requests" />
            <TextLine value="5 available strategy maps" />
          </BoxItem>

          <BoxItem>
            <TextLine value="What are some of your business initiatives?" />
            (show each initiative in a box item)

            (a) looking for solution options  b) know the solution have an RFP  c) talk with an advisory council of industry expert (what is this?))
          </BoxItem>

          <BoxItem>
            <TextLine value="Client Application:" />
            <TextLine value="Growth Client Application Submitted:" />
            <TextLine value="Monthly Budget:" />
            <TextLine value="Primary Objectives/Business Initiatives:" />
            <TextLine value="Time Urgency:" />

            <TextLine value=" Most important to you in selecting a service partner: flexibility to change scope, ability to handle a broad set of requirements, scalability of delivery, cost sensitive, urgency and time to deliver, innovativeness of solution, transparency of solution" />

            <TextLine value="There are currently 103 pending new client applications for growth services. To delivery the highest quality of services and to provide the best fit we interview each client to see what their needs are and see if it is s good match for our services and delivery model." />
          </BoxItem>

          <BoxItem>
            <TextLine bold size={18} value="Our network stats:" />
            <FlexRow wrap>
              <StatItem label="Number of consultants in our network:" value={"274"} />
              <StatItem label="Number of advisors in our network:" value={"343"} />
              <StatItem label="Number of specialized consulting teams:" value={"43"} />
              <StatItem label="Average years of experience for a senior consultant:" value={"15.4 yrs"} />
              <StatItem label="Number of micro-services completed this year:" value={"12,423 services"} />
              <StatItem label="Number of micro-services completed in the last 30 days:" value={"5,234 services"} />
              <StatItem label="Average quality rating for our services:" value={"4.95 stars"} />
              <StatItem label="Number of repeat clients:" value={"95%"} />
              <StatItem label="Average increase in client spend:" value={"24.5%"} />
              <StatItem label="Number of countries our consultants work:" value={"25 countries"} />
              <StatItem label="Number of time-zones our consultants work:" value={"15 timezones"} />
            </FlexRow>
          </BoxItem>

          <FaqItem name="What is the difference? How do I select which client group to join?"></FaqItem>
          <FaqItem name="What are micro-services?"></FaqItem>
          <FaqItem name="What is scalable consulting?"></FaqItem>
          <FaqItem name="What is open source consulting?"></FaqItem>

          <BoxItem>
            <TextLine bold size={18} value="Become a Growly enterprise client:" />
            <FlexRow wrap>
              <StatItem label="Number of enterprise clients:" value={"25"} />
              <StatItem label="Average client spend:" value={"$250,423/year"} />
              <StatItem label="Average client spend in last 30 days:" value={"$25,324"} />
              <StatItem label="Average number of micro-services:" value={"126.4 service per client/per month"} />
              <StatItem label="Number of countries we have clients:" value={"6 countries"} />
            </FlexRow>
          </BoxItem>

          <BoxItem>
            <TextLine bold size={18} value="Become a Growly growth client:" />
            <FlexRow wrap>
              <StatItem label="Number of applied clients for growth services:" value={"2,453"} />
              <StatItem label="Number of clients accepted:" value={"125"} />
              <StatItem label="Average client spend:" value={"$62,432/year"} />
              <StatItem label="Average client spend in last 30 days:" value={"$5,412"} />
              <StatItem label="Average number of micro-services:" value={"26.4 service per client/per month"} />
              <StatItem label="Number of countries we have clients:" value={"3 countries"} />
            </FlexRow>
          </BoxItem>

          <BoxItem>
            <TextLine bold size={18} value="Become a Growly start-up client:" />
            <FlexRow wrap>
              <StatItem label="Number of applied clients for growth services:" value={"1,453"} />
              <StatItem label="Number of clients accepted:" value={"225"} />
              <StatItem label="Average client spend:" value={"$15,432/year"} />
              <StatItem label="Average client spend in last 30 days:" value={"$2,412"} />
              <StatItem label="Average number of micro-services:" value={"10.1 service per client/per month"} />
              <StatItem label="Number of countries we have clients:" value={"6 countries"} />
            </FlexRow>
          </BoxItem>
        </Section>

      </FlexExpand>

      <View style={{ width: 350, marginLeft: 20 }}>
        <Section>
          <HeaderText subHeader label="My Stats:" />
          <StatItem label="Part of Projects:" count={partOfProjects} />
          <StatItem label="Open Service Requests:" count={openServiceRequests} />
          <StatItem label="Open Service Orders:" count={openServiceOrders} />
        </Section>

        <Section>
          <RenderInvitations />
        </Section>
      </View>
    </FlexRow>
  </View>
}

function SystemDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState();

  useEffect(() => {
    const promises = [];
    const stats = {};
    promises[promises.length] = db
      .getQuery("ServiceRequest")
      .existsObj("strategyConsultant", false)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceRequestsUnAssigned = count;
      });

    promises[promises.length] = db
      .getQuery("Order")
      .existsObj("projectManager", false)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.ordersUnAssigned = count;
      });

    promises[promises.length] = db
      .getQuery("Order")
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.ordersOpen = count;
      });

    promises[promises.length] = db
      .getQuery("ServiceOrder")
      .existsObj("projectManager", false)
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceOrdersUnAssigned = count;
      });

    promises[promises.length] = db
      .getQuery("ServiceOrder")
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceOrdersOpen = count;
      });

    promises[promises.length] = db
      .getQuery("ServiceOrder")
      .lessThan("dueDate", new Date())
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.serviceOrdersPastDue = count;
      });

    promises[promises.length] = db
      .getQuery("Service")
      .equalTo("status", "pending")
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.servicePendingApproval = count;
      });

    promises[promises.length] = db
      .getQuery("User")
      .equalTo("status", "pending")
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.usersPendingApproval = count;
      });

    promises[promises.length] = db
      .getQuery("Task")
      .exists("dueDate")
      .lessThan("dueDate", new Date())
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        stats.tasksPastDue = count;
      });

    Promise.all(promises).then(function () {
      setStats(stats);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { serviceRequestsUnAssigned, ordersUnAssigned, ordersOpen, serviceOrdersUnAssigned, serviceOrdersOpen, serviceOrdersPastDue, tasksPastDue, servicePendingApproval, usersPendingApproval } = stats;

  const { serviceRequestAssignment, editServiceDefinitions } = session.user.permissions;

  return (
    <View>
      <HeaderText label="System Dashboard" />
      <FlexRow>{serviceRequestAssignment ? <LabelItem label="Service Requests - Un-Assigned:" value={serviceRequestsUnAssigned} /> : null}</FlexRow>
      <FlexRow>
        {serviceRequestAssignment ? <LabelItem label="Orders - Un-Assigned:" value={ordersUnAssigned} /> : null}
        <LabelItem label="Orders - Open:" value={ordersOpen} />
      </FlexRow>
      <FlexRow>
        {serviceRequestAssignment ? <LabelItem label="Service Orders - Un-Assigned:" value={serviceOrdersUnAssigned} /> : null}
        <LabelItem label="Service Orders - Open:" value={serviceOrdersOpen} />
        <LabelItem label="Service Orders - Past Due:" value={serviceOrdersPastDue} />
      </FlexRow>
      <FlexRow>
        <LabelItem label="Tasks - Past Due:" value={tasksPastDue} />
      </FlexRow>
      <FlexRow>
        {editServiceDefinitions ? <LabelItem label="Services - Pending Approval:" value={servicePendingApproval} /> : null}
        {editServiceDefinitions ? <LabelItem label="User Profiles - Pending Approval:" value={usersPendingApproval} /> : null}
      </FlexRow>
    </View>
  );
}

function ServiceOrderWorkerTT() {
  const { orderId, serviceOrderId } = useParams();
  return <ServiceOrderWorker key={orderId + serviceOrderId} />;
}

function ServiceAdminTT() {
  const { serviceId, userId } = useParams();
  return <ServiceAdmin key={serviceId + userId} />;
}

function ServiceBuyerTT() {
  const { serviceId, userId } = useParams();
  return <ServiceBuyer key={serviceId + userId} />;
}

function ServiceWorkerTT() {
  const { serviceId, userId } = useParams();
  return <ServiceWorker key={serviceId + userId} />;
}

function WorkerRouter() {
  const sectionId = "worker";
  const [state, setState] = useState({});
  const { url } = useRouteMatch();

  const isBD = dL.isBD();
  const isPM = dL.isPM();
  const isSC = dL.isSC();
  const isSS = dL.isSS();

  const options = [{ label: "Dashboard", value: "dashboard", url: `/${sectionId}/dashboard` }];

  options.push({
    label: "Search",
    items: [
      { label: "Services", url: `/${sectionId}/services`, value: "services" },
      { label: "People", url: `/${sectionId}/people` },
      { label: "Teams", value: "teams", url: `${url}/teams` }
    ]
  });

  const { userRole } = session.user;
  const workItems = [];

  if (isBD) {
    workItems.push({ label: "Service Requests", value: "service-requests", url: `/${sectionId}/service-requests` });
    workItems.push({ label: "Strategy Maps", value: "proposals", url: `/${sectionId}/proposals` });
    workItems.push({ label: "Service Contracts", value: "orders", url: `/${sectionId}/orders` });
  }
  if (isSC || isPM) {
    workItems.push({ label: "Service Orders", value: "service-orders", url: `/${sectionId}/service-orders` });
  }

  if (isSS) {
    workItems.push({ label: "Work Requests", value: "work-requests", url: `/${sectionId}/work-requests` });
  }

  workItems.push({ label: "Staff Services", value: "staff-aug", url: `/${sectionId}/staff-services` });
  workItems.push({ label: "Work Calendar", value: "task-record", url: `/${sectionId}/tasks` });
  workItems.push({ label: "Time Log", value: "time-record", url: `/${sectionId}/times` });

  options.push({ label: "Work", items: workItems });

  options.push({
    label: "Earnings",
    items: [{ label: "Earnings", value: "earnings", url: `/${sectionId}/earnings` }, { label: "Bonus Pool", value: "bonus-pool", url: `/${sectionId}/bonus-pool` }]
  });

  options.push({
    label: "Setup",
    items: [{ label: "My Profile", value: "profile", url: `/${sectionId}/profile` }, { label: "My Service Catalog", value: "my-services", url: `/${sectionId}/my-services` }, { label: "My Teams", value: "teams", url: `${url}/teams/mine` }]
  });

  const itemGoTo = [];

  const { isSystemAdmin } = session.user;

  itemGoTo.push({ label: "Go to Buyer", url: `/user/dashboard` });

  if (isSystemAdmin) {
    itemGoTo.push({ label: "Go to Admin", url: `/admin/dashboard` });
  }

  if (itemGoTo.length > 0) {
    options.push({ label: "Go to", items: itemGoTo });
  }

  return (
    <Dash title="Growly - Worker" menuItems={options} sectionId={sectionId}>
      <Switch>
        <Route path={`/:sectionId/dashboard`}>
          <WorkerDashboard />
        </Route>
        <Route path={`/:sectionId/service/add`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/trainer/:trainerId`}>
          <ViewTrainingProfile />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/:userServiceId/admin-edit`}>
          <ServiceAdminTT />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/admin-edit`}>
          <ServiceAdminTT />
        </Route>
        <Route exact path={`/:sectionId/service/:serviceId/action/:action`}>
          <EditService />
        </Route>
        <Route exact path={`/:sectionId/service/:serviceId/:userServiceId/edit`}>
          <EditService />
        </Route>
        <Route exact path={`/:sectionId/service/:serviceId/:userServiceId/action/:action`}>
          <EditService />
        </Route>
        <Route exact path={`/:sectionId/service/:serviceId/edit`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/:userServiceId?`}>
          <ServiceWorkerTT />
        </Route>
        <Route path={`/:sectionId/services/:serviceCategory/add`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/services/:serviceCategory?`}>
          <ServicesWorker />
        </Route>
        <Route path={`/:sectionId/profile/user-role/add`}>
          <EditUserRole />
        </Route>
        <Route path={`/:sectionId/profile/user-role/:userRoleId`}>
          <EditUserRole />
        </Route>
        <Route path={`/:sectionId/people/:userId`}>
          <UserProfileWorker />
        </Route>
        <Route path={`/:sectionId/people`}>
          <ServicesUsers />
        </Route>
        <Route path={`/:sectionId/profile`}>
          <UserProfileAdmin />
        </Route>
        <Route path={`/:sectionId/my-services`}>
          <MyServices />
        </Route>
        <Route path={`/:sectionId/tasks/:recordId`}>
          <TaskRecord />
        </Route>
        <Route path={`/:sectionId/times`}>
          <MyTimeRecords />
        </Route>
        <Route path={`/:sectionId/tasks`}>
          <TasksWorker />
        </Route>
        <Route path={`/:sectionId/staff-services`}>
          <StaffServicesWorker />
        </Route>
        <Route path={`/:sectionId/staff-service/:recordId`}>
          <StaffServiceWorker />
        </Route>
        <Route path={`/:sectionId/order/:orderId/service-order/:serviceOrderId`}>
          <ServiceOrderWorkerTT />
        </Route>
        <Route path={`/:sectionId/service-request/edit/:serviceRequestId?`}>
          <EditServiceRequest />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/add-simple`}>
          <EditProposalSimple />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/:proposalId`}>
          <EditProposalSimple />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/add`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/:proposalId/edit`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/:proposalId/preview`}>
          <ProposalBuyerView />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/:proposalId`}>
          <ProposalEditorView />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId`}>
          <ServiceRequestWorker />
        </Route>
        <Route path={`/:sectionId/service-requests`}>
          <ServiceRequestsWorker />
        </Route>
        <Route path={`/:sectionId/proposal/add`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/proposal/:proposalId/edit`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/proposal/:proposalId/preview`}>
          <ProposalBuyerView />
        </Route>
        <Route path={`/:sectionId/proposal/:proposalId`}>
          <ProposalEditorView />
        </Route>
        <Route path={`/:sectionId/proposals`}>
          <ProposalsWorker />
        </Route>
        <Route path={`/:sectionId/order/:orderId`}>
          <OrderWorker />
        </Route>
        <Route path={`/:sectionId/orders`}>
          <OrdersWorker />
        </Route>
        <Route path={`/:sectionId/work-request/:workRequestId`}>
          <WorkRequest />
        </Route>
        <Route path={`/:sectionId/work-requests`}>
          <WorkRequestsWorker />
        </Route>
        <Route path={`/:sectionId/service-order/:serviceOrderId`}>
          <ServiceOrderWorkerTT />
        </Route>
        <Route path={`/:sectionId/service-orders`}>
          <ServiceOrdersWorker />
        </Route>
        <Route path={`/:sectionId/team/:teamId/member`}>
          <TeamAdmin />
        </Route>
        <Route path={`/:sectionId/team/:teamId/admin/service/add/`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/team/:teamId/admin/service/:serviceId/:userServiceId/action/:action`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/team/:teamId/admin/service/:serviceId/action/:action`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/team/:teamId/position/:positionId`}>
          <ViewTeamRolePosition />
        </Route>
        <Route path={`/:sectionId/team/:teamId/admin`}>
          <TeamAdmin />
        </Route>
        <Route path={`/:sectionId/team/:teamId/user/:userId?`}>
          <UserProfileWorker />
        </Route>
        <Route path={`/:sectionId/team/:teamId/:eid?`}>
          <TeamPublic />
        </Route>
        <Route path={`/:sectionId/teams/public`}>
          <SearchForTeams />
        </Route>
        <Route path={`/:sectionId/teams/mine`}>
          <MyTeams />
        </Route>
        <Route path={`/:sectionId/teams`}>
          <TeamsWorker />
        </Route>
        <Route path={`/:sectionId/company/:companyId`}>
          <Company />
        </Route>
        <Route path={`/:sectionId/companies`}>
          <Companies />
        </Route>
        <Route path={`/:sectionId/bonus-pool`}>
          <BonusPool />
        </Route>
        <Route path={`/:sectionId/earnings`}>
          <Earnings />
        </Route>
        <Route path={`/:sectionId/chats/:chatId?/:eid?`}>
          <MyChats />
        </Route>
      </Switch>
    </Dash>
  );
}

function BuyerRouter() {
  const [state, setState] = useState({ isLoading: false });
  const { showProposals } = state

  useEffect(() => {
    dL.getQuery("ServiceProposal")
      .equalTo("user", dL.getObj("User", session.user.id))
      .existsObj("serviceRequest", false)
      .containedIn("removed", [undefined, false])
      .count().then(function (count) {
        var showProposals
        if (count > 0) {
          showProposals = true
        }
        setState({ ...state, isLoading: false, showProposals })
      })
  }, [])

  const sectionId = "user";
  const { url } = useRouteMatch();
  const stripePromise = loadStripe("pk_test_iPJHwwuDw7mSoLuVlBVei5rR");

  const items = [
    //{label: "Service Orders", value: "service-orders", url: `${url}/service-orders` },
    { label: "My Projects", value: "projects", url: `${url}/projects` }
  ];

  if (showProposals) {
    items.push({ label: "My Strategy Maps", value: "proposals", url: `${url}/proposals` })
  }

  items.push({ label: "My Staff", value: "staff-services", url: `${url}/staff-services` })
  items.push({ label: "My Companies", value: "companies", url: `${url}/companies` })

  const itemGoTo = [];

  const { isSystemAdmin, isWorker } = session.user;
  if (isWorker) {
    itemGoTo.push({ label: "Go to Worker", url: `/worker/dashboard` });
  }

  if (isSystemAdmin) {
    itemGoTo.push({ label: "Go to Admin", url: `/admin/dashboard` });
  }

  const options = [{ label: "Dashboard", value: "dashboard", url: `/${sectionId}/dashboard` }];

  options.push({
    label: "Search",
    items: [{ label: "Services", url: `${url}/services`, value: "services" },
    { label: "People", url: `${url}/people` }]
  });

  options.push({ label: "Orders", value: "orders", url: `${url}/service-orders` });
  options.push({ label: "Requests", value: "service-requests", url: `${url}/service-requests` })
  options.push({ label: "More", items });

  if (itemGoTo.length > 0) {
    options.push({ label: "Go to", items: itemGoTo });
  }

  return (
    <Dash title="Growly - Buyer" menuItems={options} sectionId={sectionId}>
      <Switch>
        <Route path={`/:sectionId/dashboard`}>
          <BuyerDashboard />
        </Route>
        <Route exact path={`/:sectionId`}>
          <BuyerDashboard />
        </Route>
        <Route path={`/:sectionId/profile`}>
          <EditProfile />
        </Route>
        <Route path={`/:sectionId/cart`}>
          <Elements stripe={stripePromise}>
            <Cart />
          </Elements>
        </Route>
        <Route path={`/:sectionId/people/:userId`}>
          <UserProfileBuyer />
        </Route>
        <Route path={`/:sectionId/people`}>
          <ServicesUsers />
        </Route>
        <Route path={`/:sectionId/services/:serviceCategory?`}>
          <ServicesBuyer />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/:userServiceId?`}>
          <ServiceBuyerTT />
        </Route>
        <Route path={`/:sectionId/project/:projectId/service-request/:serviceRequestId?`}>
          <EditServiceRequest />
        </Route>
        <Route path={`/:sectionId/project/:projectId/:eid?`}>
          <Project />
        </Route>
        <Route path={`/:sectionId/projects`}>
          <Projects />
        </Route>
        <Route path={`/:sectionId/company/:companyId`}>
          <Company />
        </Route>
        <Route path={`/:sectionId/companies`}>
          <Companies />
        </Route>
        <Route path={`/:sectionId/proposal/:proposalId`}>
          <ProposalBuyerView />
        </Route>
        <Route path={`/:sectionId/proposals`}>
          <ProposalsBuyer />
        </Route>
        <Route path={`/:sectionId/order/:orderId/service-order/:serviceOrderId`}>
          <ServiceOrderBuyer />
        </Route>
        <Route path={`/:sectionId/service-order/:serviceOrderId`}>
          <ServiceOrderBuyer />
        </Route>
        <Route path={`/:sectionId/service-orders`}>
          <ServiceOrdersBuyer />
        </Route>
        <Route path={`/:sectionId/service-request/edit/:serviceRequestId?`}>
          <EditServiceRequest />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId`}>
          <ServiceRequestBuyer />
        </Route>
        <Route path={`/:sectionId/service-requests`}>
          <ServiceRequestsBuyer />
        </Route>
        <Route path={`/:sectionId/order/:orderId`}>
          <OrderBuyer />
        </Route>
        <Route path={`/:sectionId/orders`}>
          <OrdersBuyer />
        </Route>
        <Route exact path={`/:sectionId/staff-services/add-staff/:userId/:roleId`}>
          <AddStaffServiceWorker />
        </Route>
        <Route path={`/:sectionId/staff-services`}>
          <StaffServicesBuyer />
        </Route>
        <Route path={`/:sectionId/staff-service/:recordId`}>
          <StaffServiceBuyer />
        </Route>
        <Route path={`/:sectionId/:staffAugServiceId/staff-share/:recordId`}>
          <StaffServiceShare />
        </Route>
        <Route path={`/:sectionId/tasks/:recordId`}>
          <TaskRecord />
        </Route>
        <Route path={`/:sectionId/chats/:chatId?`}>
          <MyChats />
        </Route>
      </Switch>
    </Dash>
  );
}

function SystemRouter() {
  const sectionId = "admin";
  const { url } = useRouteMatch();

  const stripePromise = loadStripe("pk_test_iPJHwwuDw7mSoLuVlBVei5rR");

  const options = [
    { label: "Dashboard", value: "dashboard", url: `/${sectionId}/dashboard` },
    {
      label: "Operations",
      items: [
        { label: "Service Requests", value: "service-requests", url: `${url}/service-requests` },
        { label: "Strategy Maps", value: "proposals", url: `${url}/proposals` },
        { label: "Service Contracts", value: "orders", url: `${url}/orders` },
        { label: "Staff Services", value: "staff-aug", url: `${url}/staff-services` },
        { label: "Work Requests", value: "work-requests", url: `${url}/work-requests` },
        { label: "Service Orders", value: "service-orders", url: `${url}/service-orders` },
        { label: "Tasks", value: "task-record", url: `${url}/tasks` },
        { label: "Time Log", value: "time-record", url: `${url}/times` }
      ]
    },
    {
      label: "Payments",
      items: [{ label: "Earnings", value: "earnings", url: `${url}/earnings` }, { label: "Bonus List", value: "bonus-list", url: `${url}/bonus-list` }, { label: "Bonus Pool", value: "bonus-pool", url: `${url}/bonus-pool` }]
    },
    {
      label: "Setup",
      items: [
        { label: "Buyer Catalog", value: "services-buyer", url: `${url}/buyer/services` },
        { label: "Worker Catalog", value: "services-worker", url: `${url}/worker/services` },
        { label: "Users", value: "people", url: `${url}/people` },
        { label: "Business Roles", value: "roles", url: `${url}/business-roles` },
        { label: "Business Initiatives", value: "initiatives", url: `${url}/business-initiatives` }
      ]
    }
  ];

  const itemGoTo = [];

  const { isWorker } = session.user;

  itemGoTo.push({
    label: "Go to Buyer",
    url: `/user/dashboard`
  });

  if (isWorker) {
    itemGoTo.push({ label: "Go to Worker", url: `/worker/dashboard` });
  }

  if (itemGoTo.length > 0) {
    options.push({
      label: "Go to",
      items: itemGoTo
    });
  }

  return (
    <Dash title="Growly - Admin" menuItems={options} sectionId={sectionId}>
      <Switch>
        <Route exact path={`/:sectionId/profile`}>
          <EditProfile />
        </Route>
        <Route exact path={`/:sectionId/cart`}>
          <Elements stripe={stripePromise}>
            <Cart />
          </Elements>
        </Route>
        <Route path={`/:sectionId/dashboard`}>
          <SystemDashboard />
        </Route>
        <Route path={`/:sectionId/times/add`}>
          <EditTimeRecord />
        </Route>
        <Route path={`/:sectionId/times/:recordId/edit`}>
          <EditTimeRecord />
        </Route>
        <Route path={`/:sectionId/times/:recordId`}>
          <TimeRecord />
        </Route>
        <Route path={`/:sectionId/timess`}>
          <TimeRecords />
        </Route>
        <Route exact path={`/:sectionId/tasks/add`}>
          <EditTaskRecord />
        </Route>
        <Route exact path={`/:sectionId/tasks/:recordId/edit`}>
          <EditTaskRecord />
        </Route>
        <Route path={`/:sectionId/tasks/:recordId`}>
          <TaskRecord />
        </Route>
        <Route exact path={`/:sectionId/staff-service/:staffAugServiceId/tasks/add`}>
          <EditTaskRecord />
        </Route>
        <Route exact path={`/:sectionId/staff-service/:staffAugServiceId/tasks/:recordId/edit`}>
          <EditTaskRecord />
        </Route>
        <Route path={`/:sectionId/tasks`}>
          <TasksAdmin />
        </Route>
        <Route exact path={`/:sectionId/:staffAugServiceId/staff-share/add`}>
          <EditStaffAugShare />
        </Route>
        <Route exact path={`/:sectionId/:staffAugServiceId/staff-share/:recordId/edit`}>
          <EditStaffAugShare />
        </Route>
        <Route path={`/:sectionId/:staffAugServiceId/staff-share/:recordId`}>
          <StaffServiceShare />
        </Route>
        <Route path={`/:sectionId/staff-shares`}>
          <StaffAugShares />
        </Route>
        <Route exact path={`/:sectionId/staff-services/add-staff`}>
          <AddStaffService />
        </Route>
        <Route path={`/:sectionId/staff-services/billing`}>
          <StaffServicesAdminBilling />
        </Route>
        <Route path={`/:sectionId/staff-services`}>
          <StaffServicesAdmin />
        </Route>
        <Route path={`/:sectionId/staff-service/:recordId`}>
          <StaffServiceAdmin />
        </Route>
        <Route path={`/:sectionId/business-initiative/add`}>
          <EditBusinessInitiative />
        </Route>
        <Route path={`/:sectionId/business-initiative/:recordId/edit`}>
          <EditBusinessInitiative />
        </Route>
        <Route path={`/:sectionId/business-initiative/:recordId`}>
          <ViewBusinessInitiative />
        </Route>
        <Route path={`/:sectionId/:category/business-initiatives`}>
          <BusinessInitiativesAdmin />
        </Route>
        <Route path={`/:sectionId/business-initiatives`}>
          <BusinessInitiativesAdminSectors />
        </Route>
        <Route path={`/:sectionId/business-role/add`}>
          <EditBusinessRole />
        </Route>
        <Route path={`/:sectionId/business-role/:roleId/edit`}>
          <EditBusinessRole />
        </Route>
        <Route path={`/:sectionId/business-role/:roleId`}>
          <ViewBusinessRole />
        </Route>
        <Route path={`/:sectionId/:category/business-roles`}>
          <BusinessRolesAdmin />
        </Route>
        <Route path={`/:sectionId/business-roles/:roleId`}>
          <BusinessRolesAdminPeople />
        </Route>
        <Route path={`/:sectionId/business-roles`}>
          <BusinessRolesAdminSectors />
        </Route>
        <Route path={`/:sectionId/bonus-list/add`}>
          <IssueBonus />
        </Route>
        <Route path={`/:sectionId/bonus-list`}>
          <BonusList />
        </Route>
        <Route path={`/:sectionId/bonus-pool`}>
          <BonusPool />
        </Route>
        <Route path={`/:sectionId/earnings`}>
          <Earnings />
        </Route>
        <Route path={`/:sectionId/service/add`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/services/:serviceCategory/add`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/edit`}>
          <EditService />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/admin-edit`}>
          <ServiceAdminTT />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/:userServiceId?`}>
          <ServiceWorkerTT />
        </Route>
        <Route path={`/:sectionId/buyer/services/:serviceCategory?`}>
          <ServicesBuyerAdmin />
        </Route>
        <Route path={`/:sectionId/worker/services/:serviceCategory?`}>
          <ServicesWorkerAdmin />
        </Route>
        <Route path={`/:sectionId/service/:serviceId/trainer/:trainerId`}>
          <ViewTrainingProfile />
        </Route>
        <Route path={`/:sectionId/people/:userId/user-role/add`}>
          <EditUserRole />
        </Route>
        <Route path={`/:sectionId/people/:userId/user-role/:userRoleId`}>
          <EditUserRole />
        </Route>
        <Route path={`/:sectionId/people/add`}>
          <EditUserWorker />
        </Route>
        <Route path={`/:sectionId/people/:userId/edit-admin`}>
          <EditUserAdmin />
        </Route>
        <Route path={`/:sectionId/people/:userId/edit`}>
          <EditUserWorker />
        </Route>
        <Route path={`/:sectionId/people/:userId`}>
          <UserProfileAdmin />
        </Route>
        <Route path={`/:sectionId/people`}>
          <UsersAdmin />
        </Route>
        <Route path={`/:sectionId/service-request/edit/:serviceRequestId?`}>
          <EditServiceRequest />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/add`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/:proposalId/edit`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/:proposalId/preview`}>
          <ProposalBuyerView />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId/proposal/:proposalId`}>
          <ProposalEditorView />
        </Route>
        <Route path={`/:sectionId/service-request/:serviceRequestId`}>
          <ServiceRequestWorker />
        </Route>
        <Route path={`/:sectionId/service-requests`}>
          <ServiceRequestsAdmin />
        </Route>
        <Route path={`/:sectionId/proposal/add`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/proposal/:proposalId/edit`}>
          <EditProposal />
        </Route>
        <Route path={`/:sectionId/proposal/:proposalId/preview`}>
          <ProposalBuyerView />
        </Route>
        <Route path={`/:sectionId/proposal/:proposalId`}>
          <ProposalEditorView />
        </Route>
        <Route path={`/:sectionId/proposals`}>
          <ProposalsWorker />
        </Route>
        <Route path={`/:sectionId/order/:orderId/service-order/:serviceOrderId`}>
          <ServiceOrderWorkerTT />
        </Route>
        <Route path={`/:sectionId/order/:orderId`}>
          <OrderWorker />
        </Route>
        <Route path={`/:sectionId/orders`}>
          <OrdersAdmin />
        </Route>
        <Route path={`/:sectionId/work-request/:workRequestId`}>
          <WorkRequest />
        </Route>
        <Route path={`/:sectionId/work-requests`}>
          <WorkRequests />
        </Route>
        <Route path={`/:sectionId/service-order/:serviceOrderId`}>
          <ServiceOrderWorkerTT />
        </Route>
        <Route path={`/:sectionId/service-orders`}>
          <ServiceOrdersAdmin />
        </Route>
        <Route path={`/:sectionId/chats/:chatId?`}>
          <MyChats />
        </Route>
      </Switch>
    </Dash>
  );
}

function App() {
  return (
    <Router>
      <App2 />
      <ScrollToTop />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </Router>
  );
}

function HeaderTop2({ rightRender, children, menuItems, onMenuClick }) {
  useEffect(() => {
    eval("var megaMenu = new HSMegaMenu($('.js-mega-menu')).init();");
  }, []);

  const renderItem = function (item) {
    const { label } = item;
    return (
      <li class="navbar-nav-item">
        <a
          class="nav-link"
          onClick={() => {
            onMenuClick && onMenuClick(item);
          }}>
          {label}
        </a>
      </li>
    );
  };

  const renderSubItem = function (item) {
    const { label, items } = item;
    return (
      <li class="hs-has-sub-menu navbar-nav-item">
        <a id="blogMegaMenu" class="hs-mega-menu-invoker nav-link nav-link-toggle " href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="blogSubMenu">
          {label}
        </a>

        <div id="blogSubMenu" class="hs-sub-menu dropdown-menu hs-sub-menu-desktop-lg animated fadeOut" aria-labelledby="blogMegaMenu" style={{ minWidth: 230, animationDuration: 300, display: "none" }}>
          {items.map(item => {
            const { label, url, isDivider } = item;
            if (isDivider) {
              return <div class="dropdown-divider" />;
            } else {
              return (
                <Link class="dropdown-item" to={url}>
                  {label}
                </Link>
              );
            }
          })}
        </div>
      </li>
    );
  };

  return (
    <React.Fragment>
      <header id="header" class="header">
        <div class="header-section">
          <div id="logoAndNav" class="container">
            <nav class="js-mega-menu navbar navbar-expand-lg">
              <a class="navbar-brand" href="/" aria-label="Front">
                <img src="../../assets2/logo-corp.png" alt="Logo" />
              </a>

              <button type="button" class="navbar-toggler btn btn-icon btn-sm rounded-circle" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse" data-target="#navBar">
                <span class="navbar-toggler-default">
                  <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
                  </svg>
                </span>
                <span class="navbar-toggler-toggled">
                  <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                  </svg>
                </span>
              </button>

              <div id="navBar" class="collapse navbar-collapse">
                <div class="navbar-body header-abs-top-inner">
                  <ul class="navbar-nav">
                    {menuItems
                      ? menuItems.map(item => {
                        const { items } = item;
                        if (items) {
                          return renderSubItem(item);
                        } else {
                          return renderItem(item);
                        }
                      })
                      : null}
                    {rightRender}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {children}
    </React.Fragment>
  );
}

function Footer() {
  return (
    <footer class="bg-dark" style={{ marginTop: 25 }}>
      <div class="container">
        <div class="space-top-2 space-bottom-1 space-bottom-lg-2">
          <div class="row justify-content-lg-between">
            <div class="col-lg-3 ml-lg-auto mb-5 mb-lg-0">
              <div class="mb-4">
                <a href="#" aria-label="Growly">
                  <img class="brand" src="../../assets2/logo-corp.png" alt="Logo" />
                </a>
              </div>
            </div>

            <div class="col-6 col-md-3 col-lg mb-5 mb-lg-0">
              <h5 class="text-white">About</h5>

              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Our Purpose
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Careers <span class="badge badge-primary ml-1">We're hiring</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Partnerships
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    On the agenda
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3 col-lg mb-5 mb-lg-0">
              <h5 class="text-white">Support</h5>

              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Help &amp; Support
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3 col-lg">
              <h5 class="text-white">Community</h5>

              <ul class="nav nav-sm nav-x-0 nav-white flex-column">
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Become a Consultant
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Blog
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Affiliates
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Podcast
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr class="opacity-xs my-0" />

        <div class="space-1">
          <div class="row align-items-md-center">
            <div class="col-md-6 mb-4 mb-md-0">
              <ul class="nav nav-sm nav-white nav-x-sm align-items-center">
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Privacy &amp; Policy
                  </a>
                </li>
                <li class="nav-item opacity mx-3">/</li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Terms and Conditions
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-md-6 text-md-right">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <a class="btn btn-xs btn-icon btn-soft-light" href="#">
                    <i class="fab fa-facebook-f" />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="btn btn-xs btn-icon btn-soft-light" href="#">
                    <i class="fab fa-google" />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="btn btn-xs btn-icon btn-soft-light" href="#">
                    <i class="fab fa-twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="w-md-75 text-lg-center mx-lg-auto">
            <p class="text-white opacity-sm small">© Growly. 2020 Growly. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

function Services1() {
  return (
    <div class="container space-2">
      <div class="w-lg-65 text-center mx-auto mb-5 mb-sm-9">
        <h2 class="h1">150+ micro services</h2>
        <p>Best of breed services designed to help your business grow. Find the right combination of services from 12 different business sectors.</p>
      </div>

      <div class="row">
        <div class="col-md-4 mb-7">
          <div class="text-center px-lg-3">
            <figure class="max-w-8rem mx-auto mb-4">
              <img class="img-fluid" src="../assets/svg/icons/icon-45.svg" alt="SVG" />
            </figure>
            <h3>Easy to Buy</h3>
            <p>Achieve virtually any business objective with our strategy maps, buy as much or as little as you want.</p>
          </div>
        </div>

        <div class="col-md-4 mb-7">
          <div class="text-center px-lg-3">
            <figure class="max-w-8rem mx-auto mb-4">
              <img class="img-fluid" src="../assets/svg/icons/icon-14.svg" alt="SVG" />
            </figure>
            <h3>Transparent, Clear Deliverables</h3>
            <p>Whether you're a startup or a global enterprise, learn how to integrate with Front.</p>
          </div>
        </div>

        <div class="col-md-4 mb-7">
          <div class="text-center px-lg-3">
            <figure class="max-w-8rem mx-auto mb-4">
              <img class="img-fluid" src="../assets/svg/icons/icon-23.svg" alt="SVG" />
            </figure>
            <h3>Timely Execution</h3>
            <p>We help power thousands of businesses to built and run smoothly.</p>
          </div>
        </div>
      </div>

      <img class="img-fluid d-none d-md-block w-75 mx-auto mb-7" src="../assets/svg/components/three-pointers.svg" alt="SVG Arrow" />

      <div class="w-md-60 w-lg-50 text-center mx-auto mb-7">
        <p class="text-dark">
          <span class="font-weight-bold">It is fast and easy.</span> Build your first strategy map with Growly.
        </p>
      </div>

      <div class="position-relative w-lg-75 mx-lg-auto mb-4">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1109.8 797.1">
            <path fill="#f9fbff" d="M105.1,267.1C35.5,331.5-3.5,423,0.3,517.7C6.1,663,111,831.9,588.3,790.8c753-64.7,481.3-358.3,440.4-398.3c-4-3.9-7.9-7.9-11.7-12L761.9,104.8C639.4-27.6,432.5-35.6,299.9,87L105.1,267.1z" />
          </svg>
        </figure>

        <div class="device device-browser">
          <img class="img-fluid" src="../assets/svg/components/browser.svg" alt="Image Description" />
          <img class="device-browser-screen" src="../assets/img/855x665/img1.jpg" alt="Image Description" />
        </div>
      </div>

      <div class="w-50 w-md-40 w-lg-30 text-center mx-auto">
        <p class="small">We are launching soon. Join the priority list for information and early access.</p>
      </div>
    </div>
  );
}

function Hero1() {
  return (
    <div class="d-lg-flex position-relative">
      <div class="container d-lg-flex align-items-lg-center space-top-2 space-lg-0 min-vh-lg-100">
        <div class="w-md-100">
          <div class="row">
            <div class="col-lg-5">
              <div class="mb-5 mt-11">
                <h1 class="display-4 mb-3">Are you looking to grow your business?</h1>
                <p class="lead">Find micro-services designed for business growth delivered by vetted experts.</p>
              </div>

              <a class="btn btn-primary btn-wide transition-3d-hover" href="#">
                Get Started
              </a>
              <a class="btn btn-link btn-wide" href="#">
                Learn More <i class="fas fa-angle-right fa-sm ml-1" />
              </a>
            </div>
          </div>
        </div>

        <figure class="col-lg-7 col-xl-6 d-none d-lg-block position-absolute top-0 right-0 pr-0 ie-main-hero" style={{ marginTop: 6.75 }}>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1137.5 979.2">
            <path
              fill="#F9FBFF"
              d="M565.5,957.4c81.1-7.4,155.5-49.3,202.4-115.7C840,739.8,857,570,510.7,348.3C-35.5-1.5-4.2,340.3,2.7,389
          c0.7,4.7,1.2,9.5,1.7,14.2l29.3,321c14,154.2,150.6,267.8,304.9,253.8L565.5,957.4z"
            />
            <defs>
              <path id="mainHeroSVG1" d="M1137.5,0H450.4l-278,279.7C22.4,430.6,24.3,675,176.8,823.5l0,0C316.9,960,537.7,968.7,688.2,843.6l449.3-373.4V0z" />
            </defs>
            <clipPath id="mainHeroSVG2">
              <use xlinkHref="#mainHeroSVG1" />
            </clipPath>
            <g transform="matrix(1 0 0 1 0 0)" clip-path="url(#mainHeroSVG2)">
              <image width="750" height="750" xlinkHref="../../assets/img/750x750/img2.jpg" transform="matrix(1.4462 0 0 1.4448 52.8755 0)" />
            </g>
          </svg>
        </figure>
      </div>
    </div>
  );
}

function App2() {
  const [state, setState] = useState({});
  const history = useHistory();

  useEffect(() => {
    const w1 = session.watch("on-logout", function () {
      setState({});
    });
    return function () {
      w1();
    };
  }, []);

  const goDefault = function () {
    if (session.goToUrl) {
      history.push(session.goToUrl);
    } else {
      history.push("/user");
    }
  };

  const renderRegister = function () {
    return (
      <li class="navbar-nav-last-item">
        <Link to="/register" class="btn btn-sm btn-primary transition-3d-hover">
          Register
        </Link>
      </li>
    );
  };

  const renderLogin = function () {
    return (
      <li class="navbar-nav-last-item">
        <Link to="/login" class="btn btn-sm btn-primary transition-3d-hover">
          Login
        </Link>
      </li>
    );
  };

  const menuItems = [
    {
      label: "Explore",
      value: "explore",
      items: [{ label: "Services", url: "/user/services" }, { label: "People", url: "/user/people" }, { label: "Insights", url: "/insights" }, { label: "Podcast", url: "/podcast" }, { label: "Industries", url: "/industries" }, { label: "Business Sectors", url: "/sectors" }, { label: "On the agenda", url: "/agenda" }]
    },
    { label: "Become a consultant", url: "/become/consultant" }
  ];

  //Services: Micro-services, strategy map, staff augmentation
  //People: Consultants, Staff Augmentation/Inter-rim, Advisors, Investors
  //Insights:
  //Podcast:
  //Industries:
  //Business Sectors:
  //On the agenda/what we think (Trust, Growth, Global Trade, Risk, People and workforce, Cybersecurity, Digital, Blockchain)
  //Case Studies

  //Our purpose, who we are?
  //Work with us

  //About:  Our Purpose, Careers, Partnerships, Privacy Policy, Terms of Service
  //Support: Help & Support, FAQ
  //Community: Become a Consultant, Blog, Affiliates, Podcast

  //250+ micro services, 50+ consulting teams
  //Consulting service

  return (
    <Switch>
      <Route exact path="/">
        <HeaderTop2
          menuItems={menuItems.concat({ label: "Sign up", value: "sign-up", url: "/register" })}
          rightRender={renderLogin()}
          onMenuClick={item => {
            if (item.onClick) {
              item.onClick();
            } else {
              history.push(item.url);
            }
          }}
        />
        <Hero1 />
        <Services1 />

        <BoxItem>
          <TextLine value="Open Source Consulting:" />
          <TextLine value="Open source, transparent delivery of consulting services. We share and improve on the best practices and knowledge for creating hyper growth businesses. Growly consultants are part of an ever growing and adapting knowledge base of the best management practices for businesses." />
        </BoxItem>

        <BoxItem>
          <TextLine value="Scalable Consulting:" />
          <TextLine value="Timely execution of service" />
          <TextLine value="Transparent roadmap" />
          <TextLine value="Easy to plan and buy services" />
          <TextLine value="Change scope anytime" />
          <TextLine value="Cost sensitive and adjust to meet your needs" />
          <TextLine value="Top quality and always consistant" />
        </BoxItem>

        <BoxItem>
          <TextLine value="Advisors and cCnsultants:" />
          <TextLine value="Become a valued advisor or leading edge consultant. As a consultant join and have access to various teams as part of the Growly network. Gain access to equity opportunities and curated startups." />
        </BoxItem>

        <BoxItem>
          <TextLine value="Catering to enterprise, growth, startup consulting:" />
          <TextLine value="Access to experts, 250+ specialized growth services, custom designed strategy maps to deliver timely hyper growth services" />
        </BoxItem>

        <BoxItem>
          <TextLine value="Niche specialized teams catering to your industry:" />
          <TextLine value="43 teams with industry specific knowledge and execution experience. find a specialized team to address your business initiatives, technical, creative, staffing or business consulting needs." />
        </BoxItem>

        <BoxItem>
          <TextLine value="Community Startup:" />
          <TextLine value="Get matched with staff that can help you building your startup. Share, track and manage equity easily with work and tasks models designed to quickly create focused teams of consultant to delivery value. All legal and valuation requirements are managed by the platform and create transparent visibility for both startup founders and team members." />
        </BoxItem>

        <BoxItem>
          <TextLine value="Open Source University:" />
          <TextLine value="Teacher join to share their business consulting knowledge. Students join to quickly build marketing, income generating skill sets. Then use our platform to start earning money by joining teams. Focus on bootcamp style education model to drive knowledge the keep people moving forward to creating value add impact in business." />
        </BoxItem>

        <Footer />
      </Route>

      <Route path="/login">
        <HeaderTop2 menuItems={menuItems} rightRender={renderRegister()}>
          <Login
            onLogin={() => {
              goDefault();
            }}
          />
        </HeaderTop2>
        <Footer />
      </Route>
      <Route path="/register/:eid?">
        <HeaderTop2 menuItems={menuItems} rightRender={renderLogin()}>
          <Register
            onLogin={() => {
              goDefault();
            }}
          />
        </HeaderTop2>
        <Footer />
      </Route>
      <ProtectedRoute path={`/wizard`}>
        <AccountSetup />
      </ProtectedRoute>
      <ProtectedRoute path="/user">
        <BuyerRouter />
        <Footer />
        <ChatDock />
      </ProtectedRoute>
      <ProtectedRoute path="/worker">
        <WorkerRouter />
        <Footer />
        <ChatDock />
      </ProtectedRoute>
      <ProtectedRoute path="/admin">
        <SystemRouter />
        <Footer />
        <ChatDock />
      </ProtectedRoute>
    </Switch>
  );
}

function ProtectedRoute({ path, children }) {
  const history = useHistory();
  const location = useLocation();
  const [isFirst, setIsFirst] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    checkLogin().then(function () {
      setIsFirst(false);
      if (session.user) {
        if (!session.user.flags.wizardCompleted) {
          history.push("/wizard")
        }
        setLoggedIn(true);
        setIsLoading(false);
      } else {
        session.goToUrl = location.pathname;
        history.push("/login");
      }
    });
  }, []);

  if (!isFirst && !session.user) {
    history.push("/login");
    return <View />
  }

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (session.user) {
    return <Route path={path}>{children}</Route>;
  } else {
    return <View />;
  }
}

export default App;