import "./App.css";
import { TouchableOpacity } from "react-native";
import React, { useRef, useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import Moment from "moment-business-days";
import { Switch, Route, useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from "react-bootstrap-typeahead";
import { RenderChecklist, RenderLimitedOptions, RenderLimitedPackages, LimitServiceOptionsBox, LimitPackageBox, Price } from "root/pack-3";
import { SelectWorker, SelectWorkerDialog } from "root/worker";
import { SelectOptions, StatItem, BoxRowItem, BasicTop, UserItem, HeaderText } from "root/pack-2";
import { SortableList, MicroButton, ItemExpandSection, Loading, CenterBox, TrainingIcon, RenderIcon, IconPicker, Tags, DateTimeItem, DeleteConfirm, EditDialog, Section, RenderArrayItems, ModalBasic, BoxItem, LabelItem, LinkButton2, LinkButton, Hide, FlexExpand, FlexRow, TabBar, Text, TextLine, View, TrashIcon, Toggle, FormItem, MyInput, TouchButton, SelectBox, FileElement, NoRecords, BackButton } from "root/pack-1";
import { HashTable } from "root/hashtable";
import { reactLoading } from "root/loading";
import "root/loading.css";
import Datetime from "react-datetime";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { UserProfileBuyer } from "root/worker";
import { getMainServiceModel } from "root/service-model"
import {
  getDeliveryDaysForSubServices,
  createSubServicePriceMap,
  getFinalPriceDays2,
  loadSubServiceVersion_Worker,
  getWorkerHours,
  getSubServiceCost,
  getWorkHours,
  getServicePrice2,
  getSubServicesForService,
  getTasksForService,
  getServicePackageData,
  getServiceOptionData,
  getVisiblePackage,
  getMinDeliveryDays,
  getVersionData,
  getServicePreReqItems,
  getWorkerRate, clearCache, getServicePriceAndDays2, createServicePriceMap, getSubServiceCost2, getDataFromPriceMap, getPriceMapFromPriceMap, getDoneDate, getStartDate, calcStartDayIndex
} from "root/service-functions";
import { FormBuilder } from "root/forms";
import { SelectBusinessRole } from "root/business-roles";
import { EditTrainingNote, RenderSummaryItems, getSummaryItems } from "root/training"
import { RenderUserItem } from "root/users"
import { EditUserRoleDialog } from "root/worker"
import { ServiceViewDialog, ViewServiceTask, ViewSubService, ViewServiceInput, ViewServiceDeliverable, ViewServicePackage, ViewServiceOption, ServiceDetails1, DisplayDeliverables, DisplayOptions } from "root/services-worker"
import { SelectService, SelectServiceDialog, AddInputsButton, ServiceEdit } from "root/services-select"
import { getInputsForService } from "./service-functions";

const { getGroupedItems, delayTypes, valueRange, sortByString, moneyRange, plural, deliverableTypes, yesNo, skillLevels, serviceCores, money, pluralTab, getLabelItem } = utils;

export function RenderServiceNeedsUpdate({ configData, style, usingVersion, currentVersion, onUpdateNewest, price, onGetVersionPriceDays }) {
  const oldTitle = "Current Version"
  const newTitle = "New Version"

  const { packageId } = configData

  if (currentVersion.id != usingVersion.id) {
    const { total: newPrice } = onGetVersionPriceDays(currentVersion)

    var reConfig

    if ((currentVersion.enablePackages || usingVersion.enablePackages) && !packageId) {
      reConfig = true
    } else if (currentVersion.enablePackages && !usingVersion.enablePackages && packageId) {
      reConfig = true
    } else if (!currentVersion.enablePackages && usingVersion.enablePackages && !packageId) {
      reConfig = true
    }

    if (usingVersion.enablePackages) {
      const { packages } = usingVersion
      const servicePackage = packageId ? packages.find(item => item.package.id == packageId) : null
      if (!servicePackage) {
        reConfig = true
      }
    }

    return <FlexRow top={10} style={style}>
      <TextLine color="red" size={12} value={`SERVICE UPDATE: ${oldTitle} #:${usingVersion.versionNum}  >>  ${newTitle} #: ${currentVersion.versionNum} ${reConfig ? "Re-Config Needed" : formatMoneyChange(price, newPrice)}`} bold spacer />

      <LinkButton label="Update" onPress={() => {
        onUpdateNewest()
      }} />
    </FlexRow>
  } else {
    if (currentVersion.enablePackages) {
      const { packages } = currentVersion
      const servicePackage = packageId ? packages.find(item => item.package.id == packageId) : null

      if (!servicePackage) {
        return <FlexRow top={10} style={style}>
          <TextLine color="red" size={12} value={`SERVICE UPDATE: ${oldTitle} #:${usingVersion.versionNum} Re-Config Needed`} bold spacer />

          <LinkButton label="Update" onPress={() => {
            onUpdateNewest()
          }} />
        </FlexRow>
      }
    }
    return <View />
  }
}

const formatMoneyChange = function (oldValue, newValue) {
  if (newValue == oldValue) { return "" }
  return "(" + (newValue > oldValue ? "+" : "-") + money(Math.abs(newValue - oldValue)) + ")"
}

const getFirstLetterLower = function (value) {
  return value[0].toLowerCase() + value.slice(1)
}

const deleteServiceItem = function ({ type, service, userService, serviceVersion, deleteIndex }) {
  const typeLower = getFirstLetterLower(type)
  const list = serviceVersion[typeLower + "s"]
  const deleteItem = list[deleteIndex]
  list.splice(deleteIndex, 1);
  dL["removeService" + type]({ type, serviceVersion, versionItem: deleteItem })
  dL.doUpdateService({ service, userService, serviceVersion });
  dL["saveService" + type + "s"]({ serviceVersion });
}

function RenderTrainers({ service }) {
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true })
  const match = useRouteMatch("/:sectionId/*");
  const { isLoading, trainers, trainingNotes } = state

  useEffect(() => {
    dL.getQuery("Trainer")
      .equalTo("service", dL.getObj("Service", service.id))
      .containedIn("removed", [undefined, false])
      .include("user")
      .descending("lastNoteUpdatedAt")
      .find()
      .then(function (objs) {
        const trainers = dL.loadObjects("Trainer", objs)

        return dL.getQuery("TrainingNote")
          .equalTo("status", "active")
          .equalTo("service", dL.getObj("Service", service.id))
          .containedIn("removed", [undefined, false])
          .include("createdBy")
          .include("taskVersion")
          .include("deliverableVersion")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            const trainingNotes = dL.loadObjects("TrainingNote", objs)

            setState({ ...state, trainers, trainingNotes, isLoading: false })
          })
      })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return <Section>
    <HeaderText subHeader label={(trainers.length ? plural(trainers.length, "Trainer") : "Trainers") + ":"} description="See the trainers for this service." />

    {trainers.length == 0 ? (
      <NoRecords label="No trainers found." />
    ) : (
      trainers.map(trainer => {
        const { user, lastNoteUpdatedAt, noteCount } = trainer;

        const notes = trainingNotes.filter(item => item.createdBy.id == user.id)

        const summaryItems = getSummaryItems({ trainingNotes: notes })

        return (
          <FlexRow alignTop>
            <RenderUserItem item={user} onPress={() => {
              history.push(`/${match.params.sectionId}/service/${service.id}/trainer/${user.id}`)
            }} />
            <FlexExpand style={{ marginLeft: 10 }}>
              <BoxRowItem>
                <TextLine label="Training Count:" value={plural(noteCount, "note")} />
                <TextLine label="Last Note Added:" value={Moment(lastNoteUpdatedAt).fromNow()} />
              </BoxRowItem>

              <RenderSummaryItems summaryItems={summaryItems} />
            </FlexExpand>
          </FlexRow>
        );
      })
    )}
  </Section>
}

function RenderIsPublished2({ item, serviceVersion }) {
  return item.parentServiceVersion.id == serviceVersion.id ? <TextLine top={10} color="red" size={12} value="CHANGES NOT PUBLISHED" bold /> : null
}

function RenderIsPublished({ item, serviceVersion }) {
  return item.serviceVersion.id == serviceVersion.id ? <TextLine top={10} color="red" size={12} value="CHANGES NOT PUBLISHED" bold /> : null
}

function RenderServiceTasks({ serviceVersion, historicalServiceVersion, preReqItems, service, userService, onButtonPress, onUpdate, onEdit }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [state, setState] = useState({ tabValueM: "details" });
  const { showDelete, deleteIndex, taskVisible } = state;

  const { enablePackages, tasks, packages, options } = serviceVersion

  return (
    <React.Fragment>
      <Section>
        <HeaderText subHeader label={(tasks.length ? plural(tasks.length, "Task") : "Task") + ":"} description="Manage tasks required for completion of this service." onButtonPress={!historicalServiceVersion ? onButtonPress : null} />

        {tasks.length == 0 ? (
          <NoRecords label="No tasks added." />
        ) : (
          <SortableList
            disabled={historicalServiceVersion != null}
            renderItem={(item, index) => {
              const { trainingNoteCount, icon, requiredPreServices, limitedToOptions, name, description, id, hours, limitedToPackages, task } = item;

              return (
                <BoxItem key={id} icon={icon} name={name} description={description}
                  rightText={plural(hours, "hr")}
                  hasMove={!historicalServiceVersion}
                  onPress={() => {
                    setState({ ...state, taskVisible: item });
                  }}
                  onEdit={
                    !historicalServiceVersion
                      ? () => {
                        onEdit(item);
                      }
                      : null
                  }
                  onDelete={
                    !historicalServiceVersion
                      ? () => {
                        setState({ ...state, showDelete: "task", deleteId: id, deleteIndex: index });
                      }
                      : null
                  } icons={
                    <TrainingIcon count={trainingNoteCount} tooltip="Add a training note to this task." onPress={() => {
                      history.push(`${url}/training-note/type/task/${id}/${task.id}`)
                    }} />}>
                  <RenderArrayItems
                    label="Pre-Required:"
                    value={requiredPreServices}
                    list={preReqItems}
                    onGetText={(item) => {
                      const { task, subService } = item;
                      if (task) {
                        return task.name;
                      } else if (subService) {
                        return subService.service.name;
                      }
                    }}
                  />
                  {enablePackages ? <RenderLimitedPackages packages={packages} value={limitedToPackages} /> : null}
                  <RenderLimitedOptions options={options} value={limitedToOptions} />
                  <RenderIsPublished item={item} serviceVersion={serviceVersion} />
                </BoxItem>
              );
            }}
            items={tasks}
            onChange={(tasks) => {
              serviceVersion.tasks = tasks;
              dL.saveServiceTasks({ serviceVersion });
              onUpdate && onUpdate(tasks);
            }}
          />
        )}

        {showDelete == "task" ? (
          <DeleteConfirm
            onCancel={() => {
              setState({ ...state, showDelete: null });
            }}
            onConfirm={() => {
              deleteServiceItem({ type: "Task", service, userService, serviceVersion, deleteIndex })
              setState({ ...state, showDelete: null });
              onUpdate && onUpdate();
            }}
          />
        ) : null}
      </Section>

      {taskVisible ? (
        <ViewServiceTask
          item={taskVisible}
          onClose={() => {
            setState({ ...state, taskVisible: null });
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

function canSubmitServiceForApproval({ service, userService, serviceVersion }) {
  const { primaryCategory, secondaryCategory, name, shortDescription, businessRole, deliveryDays, price, serviceType, companyStages, skillLevel, isFixedPriced, enablePackages, deliverables, tasks, packages } = getVersionData({ service, userService, serviceVersion });

  if (!primaryCategory || !secondaryCategory) {
    alert("Must select primary and secondary categories for service.");
    return;
  }
  if (enablePackages && packages.length == 0) {
    alert("Must at least add one bundled package.");
    return;
  }
  if (deliverables.length == 0) {
    alert("Must at least add one deliverable.");
    return;
  }
  if (tasks.length == 0) {
    alert("Must at least add one task.");
    return;
  }

  if (!name || !shortDescription || !serviceType || !companyStages || companyStages.length == 0 || !businessRole || !skillLevel) {
    alert("Must enter basic information for service.");
    return;
  }
  if (!enablePackages) {
    if (!deliveryDays) {
      alert("Must enter delivery days.");
      return;
    }
  }
  if (isFixedPriced) {
    if (!price) {
      alert("Must enter price.");
      return;
    }
  }

  //alert("Some error in submit for approval.");
  return true;
}

function getServiceButtons({ service, userService, serviceVersion, isAdmin, onChange, onDelete, onGoBack, onLoading }) {
  const { status, id } = userService ? userService : service;

  const buttons = [];
  if (status == "draft") {
    if (isAdmin) {
      buttons.push({
        label: "Delete Service",
        onPress: () => {
          onLoading(true)
          if (userService) {
            dL.userServiceRemove(id).then(function (error) {
              if (error) {
                alert(error);
              }
              onDelete();
              onLoading(false)
            });
          } else {
            dL.serviceRemove(id).then(function (error) {
              if (error) {
                alert(error);
              }
              onDelete();
              onLoading(false)
            });
          }
        },
      });

      buttons.push({
        label: "Publish Service",
        onPress: () => {
          if (!canSubmitServiceForApproval({ service, userService, serviceVersion })) {
            return;
          }

          onLoading(true)
          if (userService) {
            return dL.publishUserService(id).then(function () {
              alert("Published.");
              onChange();
              onLoading(false)
            });
          } else {
            return dL.publishService(id).then(function () {
              alert("Published.");
              onChange();
              onLoading(false)
            });
          }
        },
      });
    }
  } else if (status == "active") {
    buttons.push({
      label: "Publish Service",
      onPress: () => {
        if (!canSubmitServiceForApproval({ service, userService, serviceVersion })) {
          return;
        }

        onLoading(true)
        if (userService) {
          return dL.publishUserService(id).then(function () {
            alert("Published.");
            onChange();
            onLoading(false)
          });
        } else {
          return dL.publishService(id).then(function () {
            alert("Published.");
            onChange();
            onLoading(false)
          });
        }
      },
    });
    buttons.push({
      label: "Archive Service",
      onPress: () => {
        onLoading(true)
        if (userService) {
          return dL.userServiceSetStatus({ serviceId: service.id, userServiceId: id, status: "archived" }).then(function () {
            onLoading(false)
            onGoBack()
          });
        } else {
          return dL.serviceSetStatus({ serviceId: id, status: "archived" }).then(function () {
            onLoading(false)
            onGoBack()
          });
        }
      }
    });
  } else if (status == "archived") {
    buttons.push({
      label: "Un-Archive Service",
      onPress: () => {
        onLoading(true)
        if (userService) {
          return dL.userServiceSetStatus({ serviceId: service.id, userServiceId: id, status: "active" }).then(function () {
            alert("Restored.");
            onLoading(false)
            onChange();
          });
        } else {
          return dL.serviceSetStatus({ serviceId: id, status: "active" }).then(function () {
            alert("Restored.");
            onLoading(false)
            onChange();
          });
        }
      }
    });
  }
  return buttons;
}

function RenderDelayOptions2({ delayOptions }) {
  return delayOptions && delayOptions.delayStart ? <FlexRow>
    <TextLine size={14} label="Delay:" value={getLabelItem(delayTypes, delayOptions.delayType) + " (" + plural(delayOptions.delayDays, "day") + ")"} spacer />
  </FlexRow> : <View />
}

function getServiceStats({ service, userService, serviceVersion }) {
  const { skillLevel, businessRole, deliveryDays } = getVersionData({ service, userService, serviceVersion });

  const subServices = getSubServicesForService({ service, serviceVersion });
  const tasks = getTasksForService({ service, serviceVersion });
  const workHours = getWorkHours({ tasks });

  var costPrice = getSubServiceCost({ subServices });
  if (businessRole) {
    costPrice += businessRole.rates[skillLevel] * workHours;
  }

  return { costPrice, workerHours: workHours, totalDeliveryDays: deliveryDays + getDeliveryDaysForSubServices({ subServices }) };
}

export function getTimelineBasicData(items) {
  const workers = {}
  const roles = {}
  const weeks = {}
  var maxDate
  items.forEach(item => {
    const { path, configData } = item
    const { actualServiceVersion } = configData
    const { businessRole, userService } = actualServiceVersion
    const businessRoleId = businessRole.id
    const assignedToId = userService ? userService.user.id : null
    const startDate = item.getStartDate()
    if (!roles[businessRoleId]) {
      roles[businessRoleId] = {
        businessRoleId,
        items: []
      }
    }
    roles[businessRoleId].items.push(item)

    if (!workers[assignedToId]) {
      workers[assignedToId] = {
        assignedToId,
        items: []
      }
    }
    workers[assignedToId].items.push(item)

    var date = Moment(startDate).startOf("week").startOf("day")
    const dt = date.format("M-D-YY")
    if (!maxDate || date > maxDate) {
      maxDate = date
    }
    if (!weeks[dt]) {
      weeks[dt] = {
        dt,
        date: date.toDate(),
        items: []
      }
    }

    weeks[dt].items.push(item)
  })

  const roleArr = []
  for (var key in roles) {
    roleArr.push(roles[key])
  }

  const workerArr = []
  for (var key in workers) {
    workerArr.push(workers[key])
  }

  const weekNum = Moment(maxDate).diff(new Date(), "days") / 7 + 1
  const weekArr = []

  var date = Moment().startOf("week").startOf("day")
  for (var i = 0; i < weekNum; i++) {
    const dt = date.format("M-D-YY")
    if (weeks[dt]) {
      weekArr.push(weeks[dt])
    } else {
      weekArr.push({
        dt,
        date: date.toDate(),
        items: []
      })
    }
    date = date.add(1, "week")
  }
  return { weekArr, roleArr, workerArr, workers }
}

export function getTimelineData(items) {
  const { weekArr, roleArr, workerArr, workers } = getTimelineBasicData(items)
  return dL.getQuery("BusinessRole").containedIn("_id", roleArr.map(item => item.businessRoleId)).find().then(function (objs) {
    const businessRoles = dL.loadObjects("BusinessRole", objs)
    roleArr.forEach(item => {
      const businessRole = businessRoles.find(item2 => item2.id == item.businessRoleId)
      item.businessRole = businessRole
    })

    return dL.getQuery("User").containedIn("_id", workerArr.map(item => item.assignedToId)).find().then(function (objs) {
      const users = dL.loadObjects("User", objs)
      workerArr.forEach(item => {
        const user = users.find(item2 => item2.id == item.assignedToId)
        item.assignedTo = user
      })

      return { weekArr, roleArr, workerArr, workers }
    })
  })
}

export   function getTotalTimeline (items) {
  var total = 0
  items.forEach(item => {
    const price = item.getServicePrice()
    total += price
  })
  return total
}

export function RenderVisualTimeline({ totalDays, items, deliveryType }) {
  const [state, setState] = useState({ isLoading: true });

  const { isLoading } = state

  useEffect(() => {
    getTimelineData(items).then(function ({ weekArr, roleArr, workerArr, workers }) {
      setState({ ...state, weekArr, roleArr, workerArr, workers, isLoading: false })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { weekArr, roleArr, workerArr, workers } = state

  return <Section>
    <HeaderText subHeader label="Visual Timeline:" />

    <Section>
      <HeaderText smallHeader style={{ marginBottom: 0 }} label="Weekly Timeline:" />

      {weekArr.map((week, index) => {
        const { date, items } = week
        const mDt = Moment(date)
        const total = getTotalTimeline(items)
        return <View>
          <TextLine label={`Week #${index + 1} - ${mDt.format("M-D-YYYY")}:`} value={`${money(total)} - ${plural(items.length, "micro service")}`} />
        </View>
      })}
    </Section>

    <Section>
      <HeaderText smallHeader style={{ marginBottom: 0 }} label="Business Roles:" />
      {roleArr.map((role, index) => {
        const { businessRole, items } = role
        const { name } = businessRole ? businessRole : {}
        const total = getTotalTimeline(items)
        return <View>
          <TextLine label={((name ? name : "Empty") + ":")} value={`${money(total)} - ${plural(items.length, "micro service")}`} />
        </View>
      })}
    </Section>

    <Section>
      <HeaderText smallHeader style={{ marginBottom: 0 }} label="Consultants:" />

      {workerArr.map((worker, index) => {
        const { assignedTo, items } = worker
        const total = getTotalTimeline(items)

        if (assignedTo) {
          const { firstName, lastName } = assignedTo

          return <View>
            <TextLine label={firstName + " " + lastName + ":"} value={`${money(total)} - ${plural(items.length, "micro service")}`} />
          </View>
        } else {
          return <View>
            <TextLine label={"Not Assigned" + ":"} value={`${money(total)} - ${plural(items.length, "micro service")}`} />
          </View>
        }
      })}

      {workers[null] ? <TextLine top={10} value={plural(workers[null].items.length, "micro service") + " with no assigned consultant"} /> : null}
    </Section>

    {items.map(itemG => {
      const { path, configData } = itemG
      const { delayOptions, isRemoved, actualServiceVersion } = configData
      const { name, userService, businessRole } = actualServiceVersion

      //const indexSeq = path ? path.split(".").length : 0
      var indexSeq = 0
      var itemGB = itemG
      do {
        itemGB = itemGB.parentItem
        if (itemGB) {
          indexSeq++
        }
      } while (itemGB)

      const totalPrice = (deliveryType == "fast" ? itemG.getTotalFastPrice() : itemG.getTotalPrice())
      const totalDeliveryDays = itemG.getTotalDeliveryDays()
      const deliveryDays = itemG.getServiceDeliveryDays()
      const startDate = itemG.getStartDate({})
      const doneDate = itemG.getDoneDate({})

      const skip = Moment(itemG.getStartDate({ noBusinessDays: true })).diff(Moment().startOf("day"), "days")
      const endCount = totalDays - totalDeliveryDays - skip

      if (!isRemoved) {
        return <View>
          <View style={{ marginLeft: indexSeq * 25 }}>
            <BoxItem name={name} rightRender={<Price strikeThrough={isRemoved} price={totalPrice} days={totalDeliveryDays} />}>
              <RenderStartDoneDates deliveryType={deliveryType} startDate={startDate} doneDate={doneDate} />

              <RenderDelayOptions2 delayOptions={delayOptions} />

              <FlexRow>
                {userService ? <TextLine size={14} label="Consultant:" value={userService.user.firstName + " " + userService.user.lastName} spacer /> : null}

                <TextLine size={14} label="Business Role:" value={businessRole.name} />
              </FlexRow>
            </BoxItem>
          </View>

          {renderBoxes({ skip, count: totalDeliveryDays, endCount })}
        </View>
      }
    })}
  </Section>
}

const renderBoxes = function ({ skip, count, endCount }) {
  var arr = []
  const styleEmpty = { flex: 1, backgroundColor: "white", border: "1px solid white", height: "15px" }
  const styleFull = { flex: 1, backgroundColor: "red", border: "1px solid white", height: "15px" }
  if (skip) {
    for (var i = 0; i < skip; i++) {
      arr.push(<div style={styleEmpty}></div>)
    }
  }
  for (var i = 0; i < count; i++) {
    arr.push(<div style={styleFull}></div>)
  }
  if (endCount) {
    for (var i = 0; i < endCount; i++) {
      arr.push(<div style={styleEmpty}></div>)
    }
  }
  return <div style={{ width: "100%", display: "flex" }}>{arr}</div>
}

function ServicePurchaseTest({ service, userService, serviceVersion, historicalServiceVersion, onUpdateOverrides }) {
  const defaultServicePackage = getVisiblePackage({ serviceData: serviceVersion })
  const serviceData = getVersionData({ service, userService, serviceVersion })
  const history = useHistory();
  const [state, setState] = useState({
    model: {
      service, deliveryType: "standard", serviceOptionIds: {},
      packageId: defaultServicePackage ? defaultServicePackage.package.id : null,
      servicePackage: defaultServicePackage
    },
  });

  const { enablePackages, name } = serviceVersion
  const { taskVisible, subServiceVisible, inputVisible, deliverableVisible, model } = state;
  const { deliveryType, serviceOptionIds, packageId, servicePackage } = model;

  const rootServiceVersion = serviceVersion
  const rootService = service

  const { hasExtraFastDelivery } = servicePackage ? servicePackage : serviceData;

  const inputs = getInputsForService({ serviceVersion, packageId, serviceOptionIds });
  const tasks = getTasksForService({ serviceVersion, packageId, serviceOptionIds });
  const subServices = getSubServicesForService({ serviceVersion, packageId, serviceOptionIds });

  const { priceMap } = serviceVersion

  const rtn = getMainServiceModel({
    configData: {
      packageId,
      serviceOptionIds,
      deliveryType,
      actualServiceVersion: serviceVersion
    }
  })

  const serviceDeliveryDays = rtn.getServiceDeliveryDays()
  const totalDeliveryDays = rtn.getTotalDeliveryDays()
  const totalPrice = rtn.getTotalPrice()
  const mainStartDate = rtn.getStartDate()
  const mainDoneDate = rtn.getDoneDate()
  const servicePrice = rtn.getServicePrice()
  const subServiceDays = totalDeliveryDays - serviceDeliveryDays
  const days = totalDeliveryDays
  const total = totalPrice
  const subServiceCost = total - servicePrice
  const totalDays = totalDeliveryDays

  const workerHours = getWorkerHours({ service, serviceVersion, serviceOptionIds, packageId });
  const workerRate = servicePrice / workerHours;
  const { minDeliveryDays, minFastDeliveryDays } = getMinDeliveryDays({ workerHours: workerHours })
  const items3 = rtn.getItems()

  return (
    <View>
      <HeaderText subHeader label="Service Configuration Test:" description="Test this service for price/cost, delivery days and tasks that will be generated based on the various options you have selected." />

      <Section>
        <HeaderText subHeader label="Service Details:" rightRender={<Price price={totalPrice} days={totalDeliveryDays} />} />

        <FlexRow>
          <LabelItem label="Service Price:" value={money(servicePrice)} description="Service price NOT including cost for sub-services." />
          <LabelItem label="Total Price:" value={money(totalPrice)} description="Total price include cost for sub-services." />
          <LabelItem label="Delivery Days:" value={plural(days, "day")} description="Total business days to delivery the service." />
          <LabelItem label="Delivery Date:" description="This is the actual delivery date that take into account business days only." value={Moment().businessAdd(days).format("M/D/YYYY")} />
          <LabelItem label="Delivery Date:" description="This is the actual delivery date that take into account business days only." value={Moment(rtn.getDoneDate()).format("M/D/YYYY")} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Worker Hours:" value={plural(workerHours, "hr")} />
          <LabelItem label="Worker Rate:" value={money(workerRate) + "/hr"} />
          <LabelItem label="Sub-Service Cost:" value={money(subServiceCost)} />
        </FlexRow>

        {!enablePackages ? <FlexRow top={10}>
          <LabelItem label="Min. Delivery Days:" value={plural(minDeliveryDays + subServiceDays, "day")} />
          {hasExtraFastDelivery ? <LabelItem label="Min. Fast Delivery Days:" value={plural(minFastDeliveryDays + subServiceDays, "day")} /> : null}
        </FlexRow> : null}
      </Section>

      <RenderVisualTimeline items={items3} deliveryType={deliveryType} totalDays={totalDays} />

      <ServiceEdit
        allowSelect
        model={model}
        serviceVersion={serviceVersion}
        onUpdate={(model) => {
          setState({ ...state, model });
        }}
        onPressDeliverables={(item) => {
          setState({ ...state, deliverableVisible: item });
        }}
        renderServicePackage={({ servicePrice, subServiceDays, workHours }) => {
          const workerRate = servicePrice / workHours;
          const { minDeliveryDays, minFastDeliveryDays } = getMinDeliveryDays({ workerHours: workHours });
          return <React.Fragment>
            <FlexRow>
              <LabelItem inline label="Work Hours:" value={plural(workHours, "hr")} />
              <LabelItem inline label="Worker Rate:" value={money(workerRate) + "/hr"} />
            </FlexRow>

            {minDeliveryDays > 0 ? <FlexRow top={10}>
              <LabelItem inline label="Min. Delivery Days:" value={plural(minDeliveryDays + subServiceDays, "day")} />
              {hasExtraFastDelivery ? <LabelItem inline label="Min. Fast Delivery Days:" value={plural(minFastDeliveryDays + subServiceDays, "day")} /> : null}
            </FlexRow> : null}
          </React.Fragment>
        }}
        renderServiceOption={({ servicePrice, subServiceDays, workHours }) => {
          const workerRate = servicePrice / workHours;
          const { minDeliveryDays, minFastDeliveryDays } = getMinDeliveryDays({ workerHours: workHours });

          return <React.Fragment>
            <FlexRow>
              <LabelItem inline label="Work Hours:" value={plural(workHours, "hr")} />
              <LabelItem inline label="Worker Rate:" value={money(workerRate) + "/hr"} />
            </FlexRow>

            {minDeliveryDays > 0 ? <FlexRow top={10}>
              <LabelItem inline label="Min. Delivery Days:" value={plural(minDeliveryDays + subServiceDays, "day")} />
              {hasExtraFastDelivery ? <LabelItem inline label="Min. Fast Delivery Days:" value={plural(minFastDeliveryDays + subServiceDays, "day")} /> : null}
            </FlexRow> : null}
          </React.Fragment>
        }}
      />

      <Section>
        <HeaderText subHeader label="Questions:" description="Questions that need to be answered to start the service." />

        {inputs.length == 0 ? (
          <NoRecords label="No questions added." />
        ) : (
          inputs.map((item) => {
            const { formItems, name, description, similarServices, required } = item;

            return (
              <BoxItem
                name={name}
                nameSmall={plural(formItems ? formItems.length : 0, "question")}
                description={description}
                required={required}
                onPress={() => {
                  setState({ ...state, inputVisible: item });
                }}
                render={
                  <View>
                    {similarServices && similarServices.length > 0 ? (
                      <FlexRow top={10}>
                        <TextLine right={10} bold value="Alternative Services:" />

                        {similarServices.map((item) => (
                          <TouchableOpacity
                            onPress={() => {
                              history.push("/admin/service/" + item.id);
                            }}
                            style={{ marginRight: 10 }}>
                            <TextLine underline value={item.name} />
                          </TouchableOpacity>
                        ))}
                      </FlexRow>
                    ) : null}
                  </View>
                }
              />
            );
          })
        )}
      </Section>

      <Section>
        <HeaderText subHeader label="Tasks:" description="Tasks that need to be completed." />
        {tasks.length == 0 ? (
          <NoRecords label="No tasks added." />
        ) : (
          tasks.map((item) => {
            const { name, description, hours } = item;
            return (
              <BoxItem
                onPress={() => {
                  setState({ ...state, taskVisible: item });
                }}
                name={name}
                description={description}
                rightText={plural(hours, "hr")}
              />
            );
          })
        )}
      </Section>

      <Section>
        <HeaderText subHeader label="Sub-Services:" description="Sub services that need to be completed." />
        {subServices.length == 0 ? (
          <NoRecords label="No sub services added." />
        ) : (
          subServices.map((subServiceVersion) => {
            const { delayOptions, packageId, serviceOptionIds, actualVersionType, subService, requiredPreServices, servicePackage, actualServiceVersion } = subServiceVersion;

            const path = "service[" + subService.id + "]"

            const { name, shortDescription } = actualServiceVersion

            const circularIds = {}
            circularIds[rootService.id] = true

            const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

            const { price: total, days: deliveryDays, startDayIndex } = getDataFromPriceMap({ priceMap: rootServiceVersion.priceMap, path })
            const startDate = Moment().businessAdd(startDayIndex)
            const doneDate = Moment(startDate).businessAdd(deliveryDays)
            return (
              <BoxItem
                onPress={() => {
                  setState({ ...state, subServiceVisible: subServiceVersion });
                }}
                name={name + (servicePackage ? " - " + servicePackage.name : "")}
                description={shortDescription}
                rightRender={<Price price={total} days={deliveryDays} />}
                render={
                  <View>
                    <RenderDelayOptions2 delayOptions={delayOptions} />

                    <RenderArrayItems
                      label="Pre-Required Services:"
                      value={requiredPreServices}
                      list={subServices}
                      onGetText={(item) => {
                        return item.service.name;
                      }}
                    />

                    <RenderStartDoneDates deliveryType={deliveryType} startDate={startDate} doneDate={doneDate} />

                    {actualVersionType == "worker" ?
                      <RenderWorkConfig subServiceVersion={subServiceVersion} />
                      : null}

                    {subServices.length > 0 ? <View top={15}>
                      <ItemExpandSection label={plural(subServices.length, "Sub-Service") + ":"}>
                        {subServices.map(item => {
                          const { id } = item

                          return <DisplaySubServiceItem
                            key={id}
                            rtn={rtn}
                            circularIds={{ ...circularIds }}
                            rootServiceVersion={rootServiceVersion}
                            historicalServiceVersion={historicalServiceVersion}
                            subServiceVersion={item}
                            path={path}
                            displayTestMode={true}
                            onUpdateOverrides={onUpdateOverrides} />
                        })}
                      </ItemExpandSection>
                    </View> : null}
                  </View>
                } />
            );
          })
        )}
      </Section>

      <Hide>
        <Section>
          <HeaderText subHeader label="Cost Analysis:" description="See how cost is calculated." />
          <FlexRow>
            <LabelItem label="Base Cost:" value={utils.money(0)} />
          </FlexRow>
        </Section>
      </Hide>

      {taskVisible ? (
        <ViewServiceTask
          item={taskVisible}
          onClose={() => {
            setState({ ...state, taskVisible: null });
          }}
        />
      ) : subServiceVisible ? (
        <ViewSubService
          service={model}
          item={subServiceVisible}
          onClose={() => {
            setState({ ...state, subServiceVisible: null });
          }}
        />
      ) : inputVisible ? (
        <ViewServiceInput
          item={inputVisible}
          value={inputVisible}
          onClose={() => {
            setState({ ...state, inputVisible: null });
          }}
        />
      ) : deliverableVisible ? (
        <ViewServiceDeliverable
          item={deliverableVisible}
          onClose={() => {
            setState({ ...state, deliverableVisible: null });
          }}
        />
      ) : null}
    </View>
  );
}

function DependentOnTaskService({ id, requiredPreServices, preReqItems, value, onChange }) {
  const refC1 = useRef();

  return (
    <Section>
      <HeaderText subHeader label="Dependencies:" />

      <FormItem label="Need these tasks/services to be completed before start of this service:">
        <View style={{ marginBottom: 15, marginTop: 15 }}>
          {requiredPreServices
            ? requiredPreServices.map((pId, index) => {
              const item = preReqItems.find((item2) => item2.id == pId);
              if (item) {
                const { subService, task } = item;

                return (
                  <FlexRow style={{ marginBottom: 10 }}>
                    <TextLine value={task ? "Task: " + task.name : "Service: " + subService.service.name} />

                    <TrashIcon
                      style={{ marginLeft: 15 }}
                      onPress={() => {
                        value.splice(index, 1);
                        onChange(value);
                      }}
                    />
                  </FlexRow>
                );
              }
            })
            : null}
        </View>

        <Typeahead
          options={preReqItems.filter((item) => {
            if (requiredPreServices && requiredPreServices.find((pId) => item.id == pId)) {
              return false;
            }

            if ((item.subService ? item.subService : item.task).id == id) {
              return false;
            }

            const m1 = function (item) {
              const { task, subService } = item;
              const requiredPreServices = task ? task.requiredPreServices : subService.requiredPreServices;

              if (requiredPreServices) {
                if (requiredPreServices.find((pId) => pId == id)) {
                  return true;
                }

                for (var i = 0; i < requiredPreServices.length; i++) {
                  const item3 = preReqItems.find((item) => item.id == requiredPreServices[i]);
                  if (item3) {
                    if (m1(item3)) {
                      return true;
                    }
                  }
                }
              }
            };

            if (m1(item)) {
              return false;
            }

            return true;
          })}
          ref={(ref) => {
            refC1.current = ref;
          }}
          onChange={(item) => {
            var arr = value;
            if (!arr) {
              arr = [];
            }
            arr.push(item[0].id);

            refC1.current.clear();
            onChange(arr);
          }}
          labelKey="id"
          placeholder="Add pre-required task or service..."
          renderMenuItemChildren={(item) => {
            const { type, task, subService, requiredPreServices } = item;
            if (type == "sub-service") {
              return (
                <View>
                  <TextLine value={"Service: " + subService.service.name} />
                  {requiredPreServices ? utils.renderArray(requiredPreServices) : ""}
                </View>
              );
            } else if (type == "task") {
              return (
                <View>
                  <TextLine value={"Task: " + task.name} />
                  {requiredPreServices ? utils.renderArray(requiredPreServices) : ""}
                </View>
              );
            }
          }}
        />
      </FormItem>
    </Section>
  );
}

const setServiceItemModel = function ({ model, service, userService, serviceVersion }) {
  var isNewVersion = false
  if (!model.service) {
    model.service = service;
    model.userService = userService;
    model.serviceVersion = serviceVersion
  } else {
    if (model.serviceVersion.id != serviceVersion.id) {
      isNewVersion = true
    }
    model.serviceVersion = serviceVersion
  }
  return isNewVersion
}

function EditServiceTask({ service, editItem, onDone, onCancel, preReqItems, userService, serviceVersion }) {
  const [state, setState] = useState({ model: Object.assign({}, editItem) });
  const { model } = state;

  const { icon, taskInstructions, requiredPreServices, limitedToOptions, name, description, hours, limitedToPackages } = model;
  const { enablePackages, enableServiceOptions, serviceOptions, packages } = serviceVersion

  return (
    <View style={{ maxWidth: 550, marginHorizontal: 23 }}>
      <HeaderText
        label="Edit Task"
        description="Ask task to service."
        rightRender={
          <BackButton
            onPress={() => {
              onCancel();
            }}
          />
        }
      />

      <MyInput
        required
        label="Enter name or title for task:"
        value={name}
        onChange={(value) => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        maxLength={144}
        label="Short description of task:"
        value={description}
        onChange={(value) => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <FlexRow>
        <MyInput
          required
          style={{ maxWidth: 125 }}
          inputType="integer"
          minValue={0}
          label="Estimated Hours:"
          description="Enter ideal hours for completion of this task."
          value={hours}
          onChange={(value) => {
            model.hours = value;
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 25 }} />
        <IconPicker
          label="Optionally, select an icon for this task:"
          value={icon}
          onChange={(value) => {
            model.icon = value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      <Section>
        <HeaderText subHeader label="Task instructions to complete:" description="Provide a details explanation and resources on how to complete this task." />

        <MyInput
          style={{ height: 350 }}
          multiline
          placeholder="Enter task instructions"
          value={taskInstructions}
          onChange={(value) => {
            model.taskInstructions = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <Section>
        <DependentOnTaskService
          id={model.id}
          requiredPreServices={requiredPreServices}
          preReqItems={preReqItems}
          value={model.requiredPreServices}
          onChange={(value) => {
            model.requiredPreServices = value;
            setState({ ...state, model });
          }}
        />

        {enableServiceOptions ? (
          <LimitServiceOptionsBox
            value={limitedToOptions}
            options={serviceOptions}
            onChange={(value) => {
              model.limitedToOptions = value;
              setState({ ...state, model });
            }}
          />
        ) : null}

        {enablePackages ? (
          <LimitPackageBox
            value={limitedToPackages}
            packages={enablePackages ? packages : null}
            onChange={(limitedToPackages) => {
              model.limitedToPackages = limitedToPackages;
              setState({ ...state, model });
            }}
          />
        ) : null}
      </Section>

      <TouchButton
        label="Save Task"
        onPress={() => {
          if (!name) {
            alert("Must enter title.");
            return;
          }
          if (!utils.isNumber(hours)) {
            alert("Must enter valid hours.");
            return;
          }

          const isNewVersion = setServiceItemModel({ model, service, userService, serviceVersion })

          model.hours = Number(hours);

          return dL
            .saveServiceTask({ recordId: model.id, data: model, isNewVersion })
            .then(function (id) {
              return db.getQuery("ServiceTaskVersion").get(id)
            }).then(function (obj) {
              const model = dL.loadServiceTaskVersion(obj)
              return onDone(model);
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </View>
  );
}

function EditServiceDeliverable({ service, editItem, onDone, userService, serviceVersion }) {
  const [state, setState] = useState({ model: Object.assign({ deliverableType: "file", reviewPeriod: "" }, editItem) });
  const { model } = state;

  const { icon, reviewPeriod, formItems, deliverableType, limitedToOptions, limitedToPackages, name, description, file } = model;
  const { enablePackages, serviceOptions, packages, enableServiceOptions } = serviceVersion

  return (
    <EditDialog>
      <HeaderText label="Edit Deliverable" description="Edit this service deliverable." />
      <MyInput
        required
        label="Name of deliverable:"
        value={name}
        onChange={(value) => {
          model.name = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        maxLength={144}
        label="Short description of deliverable:"
        value={description}
        onChange={(value) => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this deliverable:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />

      <FlexRow>
        <SelectBox
          label="Deliverable Format:"
          value={deliverableTypes.find((item) => item.value == deliverableType)}
          options={deliverableTypes}
          onChange={(item) => {
            model.deliverableType = item.value;
            setState({ ...state, model });
          }}
        />

        <View style={{ width: 25 }} />
        <SelectBox
          label="Review Period:"
          description="Define the length of time the buyer will have review this service."
          value={utils.deliverableReviewPeriods.find((item) => item.value == reviewPeriod)}
          options={utils.deliverableReviewPeriods}
          onChange={(item) => {
            model.reviewPeriod = item.value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      {deliverableType == "file" ? (
        <FileElement
          label="Example File/Template:"
          value={file}
          onChange={(value) => {
            model.file = value;
            setState({ ...state, model });
          }}
        />
      ) : (
        <FormBuilder
          subHeader
          label="Fill out the following questions:"
          formItems={formItems}
          onUpdate={(items) => {
            model.formItems = items;

            setState({ ...state, model });
          }}
        />
      )}

      {enableServiceOptions || enablePackages ? (
        <Section>
          {enableServiceOptions ? (
            <LimitServiceOptionsBox
              value={limitedToOptions}
              options={serviceOptions}
              onChange={(value) => {
                model.limitedToOptions = value;
                setState({ ...state, model });
              }}
            />
          ) : null}

          {enablePackages ? (
            <LimitPackageBox
              value={limitedToPackages}
              packages={enablePackages ? packages : null}
              onChange={(limitedToPackages) => {
                model.limitedToPackages = limitedToPackages;
                setState({ ...state, model });
              }}
            />
          ) : null}
        </Section>
      ) : null}

      <TouchButton
        label="Save Deliverable"
        onPress={() => {
          if (!name) {
            alert("Must enter name.");
            return;
          }

          const isNewVersion = setServiceItemModel({ model, service, userService, serviceVersion })

          return dL
            .saveServiceDeliverable({ recordId: model.id, data: model, isNewVersion })
            .then(function (id) {
              return db.getQuery("ServiceDeliverableVersion").get(id)
            }).then(function (obj) {
              const model = dL.loadServiceDeliverableVersion(obj)
              return onDone(model);
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </EditDialog>
  );
}

function EditServicePackage({ service, editItem, onDone, userService, serviceVersion }) {
  const [state, setState] = useState({ model: Object.assign({}, editItem) });
  const { model } = state;

  const { icon, isFixedPriced, fastDeliveryPercent, allowedRevisions, name, shortDescription, description, price, deliveryDays, hasExtraFastDelivery, extraFastPrice, extraFastDeliveryDays } = model;

  return (
    <EditDialog>
      <HeaderText label="Edit Bundled Package" description="Edit this bundled service package." />

      <MyInput
        required
        label="Enter an title or name for the package:"
        value={name}
        onChange={(value) => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        maxLength={144}
        label="Give the package a short description:"
        value={shortDescription}
        onChange={(value) => {
          model.shortDescription = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        label="Add a longer description:"
        value={description}
        onChange={(value) => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <Section>
        <HeaderText subHeader label="Price and Delivery:" />

        <Toggle
          value={isFixedPriced}
          onChange={(value) => {
            model.isFixedPriced = value;
            setState({ ...state, model });
          }}
          label="Make fixed price:"
          description="Make this option fixed price, instead of using hourly costing."
          style={{ marginBottom: 23 }}
        />

        <FlexRow>
          {isFixedPriced ? (
            <React.Fragment>
              <MyInput
                style={{ maxWidth: 125 }}
                inputType="money"
                minValue={0}
                label="Bundled Price:"
                description="Purchase price for this bundled package."
                value={price}
                onChange={(value) => {
                  model.price = value;
                  setState({ ...state, model });
                }}
              />
              <View style={{ width: 25 }} />
            </React.Fragment>
          ) : null}

          <MyInput
            style={{ maxWidth: 125 }}
            inputType="integer"
            minValue={0}
            label="Delivery Days:"
            description="Enter the number of standard business days for delivery of this bundled package."
            value={deliveryDays}
            onChange={(value) => {
              model.deliveryDays = value;
              setState({ ...state, model });
            }}
          />

          <View style={{ width: 25 }} />

          <SelectBox
            style={{ maxWidth: 165 }}
            label="Revisions:"
            description="Number of revisions that will be allowed for deliverables."
            options={utils.revisionOptions}
            value={utils.revisionOptions.find((item) => item.value == allowedRevisions)}
            onChange={(item) => {
              model.allowedRevisions = item.value;
              setState({ ...state, model });
            }}
          />
        </FlexRow>

        <IconPicker
          label="Optionally, select an icon for this package:"
          value={icon}
          onChange={(value) => {
            model.icon = value;
            setState({ ...state, model });
          }}
        />

        <Toggle
          label="Has extra fast delivery option:"
          value={hasExtraFastDelivery}
          onChange={(value) => {
            model.hasExtraFastDelivery = value;
            setState({ ...state, model });
          }}
        />

        {hasExtraFastDelivery ? (
          <View style={{ flexDirection: "row" }}>
            {isFixedPriced ? (
              <React.Fragment>
                <MyInput
                  style={{ maxWidth: 125 }}
                  inputType="money"
                  minValue={0}
                  label="Fast Price:"
                  description="Enter the price charge for extra fast delivery."
                  value={extraFastPrice}
                  onChange={(value) => {
                    model.extraFastPrice = value;
                    setState({ ...state, model });
                  }}
                />
                <View style={{ width: 25 }} />
              </React.Fragment>
            ) : null}

            <MyInput
              style={{ maxWidth: 165 }}
              inputType="integer"
              minValue={0}
              label="Fast Delivery Days:"
              description="Enter the days this service will be delivered in for extra fast delivery."
              value={extraFastDeliveryDays}
              onChange={(value) => {
                model.extraFastDeliveryDays = value;
                setState({ ...state, model });
              }}
            />
            <View style={{ width: 25 }} />
            <MyInput
              style={{ maxWidth: 125 }}
              inputType="integer"
              minValue={0}
              label="Up-Charge (%):"
              description="Enter the percentage to up-charge for fast delivery."
              value={fastDeliveryPercent}
              onChange={(value) => {
                model.fastDeliveryPercent = value;
                setState({ ...state, model });
              }}
            />
          </View>
        ) : null}
      </Section>

      <TouchButton
        label="Save Package"
        onPress={() => {
          if (!name) {
            alert("Must enter name.");
            return;
          }

          if (isFixedPriced) {
            if (!utils.isMoney(price)) {
              alert("Must enter valid price.");
              return;
            }
          }

          if (!utils.isInteger(deliveryDays)) {
            alert("Must enter valid delivery days.");
            return;
          }

          if (Number(deliveryDays) <= 0) {
            alert("Must enter valid delivery days.");
            return;
          }

          if (hasExtraFastDelivery) {
            if (isFixedPriced) {
              if (!utils.isMoney(extraFastPrice)) {
                alert("Must enter valid extra fast price.");
                return;
              }
            }
            if (!utils.isInteger(extraFastDeliveryDays)) {
              alert("Must enter valid extra fast delivery days.");
              return;
            }

            if (Number(extraFastDeliveryDays) <= 0) {
              alert("Must enter valid extra fast delivery days.");
              return;
            }
            if (Number(fastDeliveryPercent) < 0) {
              alert("Must enter valid fast delivery percent up-charge.");
              return;
            }
          }

          const isNewVersion = setServiceItemModel({ model, service, userService, serviceVersion })

          model.extraFastDeliveryDays = extraFastDeliveryDays ? Number(extraFastDeliveryDays) : null;
          model.extraFastPrice = extraFastPrice ? Number(extraFastPrice) : null;
          model.price = price ? Number(price) : null;
          model.deliveryDays = Number(deliveryDays);
          model.fastDeliveryPercent = Number(fastDeliveryPercent);

          return dL
            .saveServicePackage({ recordId: model.id, data: model, isNewVersion })
            .then(function (id) {
              return db.getQuery("ServicePackageVersion").get(id)
            }).then(function (obj) {
              const model = dL.loadServicePackageVersion(obj)
              return onDone(model);
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </EditDialog>
  );
}

function RenderServiceSelection1({ setRef, model, readonly, calculate, total, days, getPriceDays, path, rootServiceVersion }) {
  const [state, setState] = useState({ assignedToUser: model.userService ? true : false });
  const { workerVisible, assignedToUser } = state;

  const { actualServiceVersion, workerUserServices, delayOptions, limitedToOptions, limitedToPackages, requiredPreServices, service, servicePackage, serviceOptionIds, inputData, deliveryType, assignedTo, serviceVersion, userService, userServiceVersion, subService, packageId } = model;

  const { enablePackages, inputs } = actualServiceVersion ? actualServiceVersion : {}

  const { days: uDays, total: uTotal } = userService ? getPriceDays({ serviceVersion: userServiceVersion }) : {}

  useEffect(() => {
    if (setRef) {
      setRef.current = {
        validate: () => {
          if (assignedToUser) {
            if (!userService) {
              alert("Must select recommended consultant.");
              return;
            }
          }
          return true
        }
      }
    }
  }, [assignedToUser, userService])

  return <React.Fragment>
    <Section>
      <HeaderText label="Base Service:" subHeader description="Service that will be added to the parent service required for delivery." />

      <FormItem box>
        <SelectService readonly={readonly} onChange={({ service, userService }) => {
          reactLoading({
            onLoading:
              () => {
                return dL.getService2({ serviceId: service.id }).then(function (service) {
                  var assignedToUser

                  const { currentVersion, serviceType } = service

                  return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                    const servicePackage = getVisiblePackage({ serviceData: serviceVersion });

                    model.service = service;
                    model.serviceVersion = serviceVersion

                    model.serviceType = serviceType;
                    model.servicePackage = servicePackage;
                    model.serviceOptionIds = {};
                    model.deliveryType = "standard";
                    model.inputData = {};

                    if (userService) {
                      const { currentVersion } = userService
                      return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
                        assignedToUser = true
                        model.userService = userService;
                        model.userServiceVersion = serviceVersion
                        model.assignedTo = userService.user;

                        model.actualServiceVersion = serviceVersion
                        model.actualVersionType = "worker"

                        calculate(model)
                      })
                    } else {
                      assignedToUser = false
                      model.userService = null;
                      model.userServiceVersion = null
                      model.assignedTo = null;

                      model.actualServiceVersion = serviceVersion
                      model.actualVersionType = "service"

                      calculate(model)
                    }
                  }).then(function () {
                    setState({ ...state, model, assignedToUser });
                  })
                })
              }
          })
        }}
          value={service}
          rightRender={service && (!enablePackages || servicePackage) ? <Price price={total} days={days} deliveryText="Delivery in " /> : null}
        />

        {service ? <RenderServiceNeedsUpdate
          configData={model}
          usingVersion={serviceVersion}
          currentVersion={service.currentVersion}
          price={total}
          days={days}
          onGetVersionPriceDays={(serviceVersion) => {
            return getPriceDays({ serviceVersion })
          }}
          onUpdateNewest={() => {
            model.serviceVersion = service.currentVersion

            if (model.actualVersionType = "service") {
              model.actualServiceVersion = model.serviceVersion

              calculate(model)
            }

            setState({ ...state, model });
            alert("Updated.")
          }}
        /> : null}
      </FormItem>
    </Section>

    {service ? (
      <Section>
        <HeaderText label="Consultant Selection:" subHeader />

        <Toggle
          label="Always assign to a specific consultant:"
          value={assignedToUser}
          onChange={(value) => {
            if (!value) {
              model.userService = null;
              model.userServiceVersion = null
              model.assignedTo = null;

              model.actualServiceVersion = model.serviceVersion
              model.actualVersionType = "service"

              calculate(model)
            }
            setState({ ...state, model, assignedToUser: value });
          }}
        />

        {assignedToUser ? (
          <React.Fragment>
            <FormItem label="Assigned Consultant:" box>
              <SelectWorker
                hideMySelf
                onGetVersionPriceDays={(serviceVersion) => {
                  return getPriceDays({ serviceVersion })
                }}
                serviceId={service ? service.id : null}
                onChange={(value) => {
                  const { userService } = value ? value : {};

                  reactLoading({
                    onLoading:
                      () => {
                        if (userService) {
                          return dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
                            model.userService = userService;
                            model.userServiceVersion = serviceVersion
                            model.assignedTo = userService.user;

                            model.actualServiceVersion = serviceVersion
                            model.actualVersionType = "worker"

                            calculate(model)

                            setState({ ...state, model, assignedToUser: true });
                          })
                        } else {
                          model.userService = null;
                          model.userServiceVersion = null
                          model.assignedTo = null;

                          model.actualServiceVersion = model.serviceVersion
                          model.actualVersionType = "service"

                          calculate(model)

                          setState({ ...state, model });
                        }
                      }
                  })
                }}
                value={userService ? userService.user : null}
                rightRender={userService ? <Price price={uTotal} days={uDays} deliveryText="Delivery in " /> : null}
              />

              {userService && (userService.name != service.name || userService.shortDescription != service.shortDescription) ? <BoxItem name={userService.name} description={userService.shortDescription} /> : null}

              {userService ? <RenderServiceNeedsUpdate
                configData={model}
                usingVersion={userServiceVersion}
                currentVersion={userService.currentVersion}
                price={uTotal}
                days={uDays}
                onGetVersionPriceDays={(serviceVersion) => {
                  return getPriceDays({ serviceVersion })
                }}
                price={total}
                onUpdateNewest={() => {
                  model.userServiceVersion = userService.currentVersion

                  if (model.actualVersionType = "worker") {
                    model.actualServiceVersion = model.userServiceVersion

                    calculate(model)
                  }

                  setState({ ...state, model });
                  alert("Updated.")
                }}
              /> : null}
            </FormItem>

            {assignedTo ? <FormItem label="Other Preferred Consultants:" description="Add additional preferred consultants." style={{ marginRight: 0 }}>
              <View bottom={15}>
                {!workerUserServices || workerUserServices.length == 0 ? (
                  <NoRecords label="No other preferred workers." />
                ) : (
                  workerUserServices.map(({ userService }, index) => {
                    return <RenderWorkerUserService
                      deliveryType={deliveryType}
                      packageId={packageId}
                      serviceOptionIds={serviceOptionIds}
                      path={path}
                      rootServiceVersion={rootServiceVersion}
                      deliveryType={deliveryType}
                      servicePackage={servicePackage}
                      packageId={packageId}
                      serviceOptionIds={serviceOptionIds}
                      service={service}
                      userService={userService}
                      onDelete={() => {
                        workerUserServices.splice(index, 1);
                        setState({ ...state, model });
                      }}
                    />
                  })
                )}
              </View>

              <MicroButton
                onPress={() => {
                  setState({ ...state, workerVisible: true });
                }}
                label="Add Consultant"
              />

              {workerVisible ? (
                <SelectWorkerDialog
                  onGetVersionPriceDays={(serviceVersion) => {
                    return getPriceDays({ serviceVersion })
                  }}
                  serviceId={service.id}
                  onCancel={() => {
                    setState({ ...state, workerVisible: false });
                  }}
                  onSelect={({ user }) => {
                    dL.getUserService({ userId: user.id, serviceId: service.id }).then(function (userService) {
                      if (!userService) {
                        alert("Consultant can't be added. Consultant service not found.")
                        return
                      }
                      if (!model.workerUserServices) {
                        model.workerUserServices = [];
                      }

                      model.workerUserServices.push({ userService, userServiceVersion: userService.currentVersion });
                      setState({ ...state, model, workerVisible: false });
                    });
                  }}
                />
              ) : null}
            </FormItem> : null}
          </React.Fragment>
        ) : null}
      </Section>
    ) : null}
  </React.Fragment>
}

function EditServiceSubService({ parentService, editItem, onDone, preReqItems, parentUserService, parentServiceVersion, rootServiceVersion }) {
  const [state, setState] = useState({ isLoading: true, model: Object.assign({}, editItem) });
  const { isLoading, model } = state;
  const refC = useRef()

  if (!model.delayOptions) { model.delayOptions = {} }
  const { actualServiceVersion, workerUserServices, delayOptions, limitedToOptions, limitedToPackages, requiredPreServices, service, servicePackage, serviceOptionIds, inputData, deliveryType, assignedTo, serviceVersion, userService, userServiceVersion, subService, packageId } = model;

  useEffect(() => {
    const promises = []
    if (service) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: service.currentVersion.id }).then(function (serviceVersion) {
        service.currentVersion = serviceVersion
      })
    }
    if (userService) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
        userService.currentVersion = serviceVersion
      })
    }
    Promise.all(promises).then(function () {
      setState({ ...state, isLoading: false })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  //const rootPath = subService ? `service[${subService.id}]` : null
  //const path = editItem.path ? editItem.path : "service[" + editItem.id + "]"
  const { delayType, delayDays, delayStart, delayStartDate } = delayOptions;

  const { enablePackages, inputs } = actualServiceVersion ? actualServiceVersion : {}

  const circularIds = {}
  circularIds[parentService.id] = true

  //const rtn = actualServiceVersion ? getMainServiceModel({ configData: model }) : {}

  const getPriceDays = function ({ serviceVersion }) {
    const rtn = getMainServiceModel({ configData: { ...model, actualServiceVersion: serviceVersion } })
    const days = rtn.getTotalDeliveryDays()
    const total = rtn.getTotalPrice()
    return { total, days }
  }

  const { days, total } = service ? getPriceDays({ serviceVersion }) : {}

  const calculate = function (model) {
    calcStartDayIndex(model)

    const rtn = getMainServiceModel({ configData: model })
    const days = rtn.getTotalDeliveryDays()
    const price = rtn.getTotalPrice()

    model.unitPrice = price
    model.total = price
    model.deliveryDays = days
  }

  return (
    <EditDialog>
      <HeaderText label={model.id ? "Edit Sub-Service" : "Add Sub-Service"} description="Setup a sub-service that is required to be completed for this service." />

      <RenderServiceSelection1 setRef={refC} model={model} calculate={calculate} total={total} days={days} getPriceDays={getPriceDays} rootServiceVersion={rootServiceVersion} />

      {service ? (
        <React.Fragment>
          <RenderDelayOptions
            delayOptions={delayOptions}
            onUpdate={() => {
              setState({ ...state, model });
            }}
          />

          <ServiceEdit
            allowSelect
            model={model}
            onUpdate={(model) => {
              calculate(model)
              setState({ ...state, model });
            }}
          />

          {inputs && inputs.length > 0 ? (
            <Section>
              <HeaderText label="Questions:" subHeader description="Set pre-answers to these questions to start this service. If not answer here they will be required before the service can be started." />
              <AddInputsButton
                label="Enter Answers"
                inputs={inputs}
                inputData={inputData}
                onChange={(value) => {
                  model.inputData = value;
                  setState({ ...state, model });
                }}
              />
            </Section>
          ) : null}
        </React.Fragment>
      ) : null}

      <Section>
        <DependentOnTaskService
          id={model.id}
          requiredPreServices={requiredPreServices}
          preReqItems={preReqItems}
          value={model.requiredPreServices}
          onChange={(value) => {
            model.requiredPreServices = value;
            setState({ ...state, model });
          }}
        />

        {parentServiceVersion.enableServiceOptions ? (
          <LimitServiceOptionsBox
            value={limitedToOptions}
            options={parentServiceVersion.serviceOptions}
            onChange={(value) => {
              model.limitedToOptions = value;
              setState({ ...state, model });
            }}
          />
        ) : null}

        {parentServiceVersion.enablePackages ? (
          <LimitPackageBox
            value={limitedToPackages}
            packages={parentServiceVersion.packages}
            onChange={(limitedToPackages) => {
              model.limitedToPackages = limitedToPackages;
              setState({ ...state, model });
            }}
          />
        ) : null}
      </Section>

      <TouchButton
        label="Save Service"
        onPress={() => {
          if (!service) {
            alert("Must select service.");
            return;
          }
          if (enablePackages && !servicePackage) {
            alert("Must enter service package.");
            return;
          }
          if (delayStart) {
            if (!delayType) {
              alert("Must select delay type.");
              return;
            }
            if (delayType == "start-date") {
              if (!delayStartDate) {
                alert("Must enter start date.");
                return;
              }
            } else if (delayType == "start-of-order" || delayType == "days-from-pre-req") {
              if (!utils.isInteger(delayDays)) {
                alert("Must enter valid delay days.");
                return;
              }
            }
          }
          if (!refC.current.validate()) {
            return
          }
          var isNewVersion = false
          if (!model.parentService) {
            model.parentService = parentService;
            model.parentUserService = parentUserService;
            model.parentServiceVersion = parentServiceVersion
          } else {
            if (model.parentServiceVersion.id != parentServiceVersion.id) {
              isNewVersion = true
            }
            model.parentServiceVersion = parentServiceVersion
          }

          model.deliveryType = deliveryType ? deliveryType : "standard";

          if (delayDays) {
            delayOptions.delayDays = Number(delayDays);
          }

          calcStartDayIndex(model)

          model.priceMap = createSubServicePriceMap({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds, delayOptions })

          return dL
            .saveServiceSubService({ recordId: model.id, data: model, isNewVersion })
            .then(function (id) {
              return db.getQuery("ServiceSubServiceVersion").get(id)
            }).then(function (obj) {
              const finalM = dL.loadServiceSubServiceVersion(obj)
              return loadSubServiceVersion_Worker({ subServiceVersion: finalM }).then(function () {
                return onDone(finalM);
              })
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </EditDialog>
  );
}

export function EditServiceSubServiceConfig({ rtn, rootServiceVersion, path, hasOverride, editItem, onCancel, onSave, onReset }) {
  const [state, setState] = useState({ isLoading: true, model: editItem });
  const { isLoading, model } = state;
  const refC = useRef();

  if (!model.delayOptions) { model.delayOptions = {} }
  const { actualServiceVersion, workerUserServices, delayOptions, service, servicePackage, serviceOptionIds, inputData, deliveryType, serviceVersion, userService, userServiceVersion, packageId } = model

  useEffect(() => {
    const promises = []
    promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: service.currentVersion.id }).then(function (serviceVersion) {
      service.currentVersion = serviceVersion
    })
    if (userService) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
        userService.currentVersion = serviceVersion
      })
    }
    Promise.all(promises).then(function () {
      setState({ ...state, isLoading: false })
    })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { delayType, delayDays, delayStart, delayStartDate } = delayOptions;
  const { enablePackages, inputs } = actualServiceVersion

  const getPriceDays = function ({ serviceVersion }) {
    const itemG = rtn.setOverride({ path, masterOverride: { ...model, actualServiceVersion: serviceVersion } })
    const days = itemG.getTotalDeliveryDays()
    const total = itemG.getTotalPrice()
    return { total, days }
  }

  const calculate = function (model) {
    calcStartDayIndex(model)

    const itemG = rtn.setOverride({ path, masterOverride: model })
    const days = itemG.getTotalDeliveryDays()
    const price = itemG.getTotalPrice()

    model.unitPrice = price
    model.total = price
    model.deliveryDays = days
  }

  const { days, total } = getPriceDays({ serviceVersion })
  const { days: uDays, total: uTotal } = userService ? getPriceDays({ serviceVersion: userServiceVersion }) : {}

  const buttons = []

  if (hasOverride) {
    buttons.push({
      label: "Remove Overrides", onPress: () => {
        onReset()
      }
    })
  }

  return (
    <ModalBasic large title="Edit Configuration:" onCancel={onCancel} okText="Save" buttons={buttons} onOk={() => {
      if (enablePackages && !servicePackage) {
        alert("Must enter service package.");
        return;
      }
      if (delayStart) {
        if (!delayType) {
          alert("Must select delay type.");
          return;
        }
        if (delayType == "start-date") {
          if (!delayStartDate) {
            alert("Must enter start date.");
            return;
          }
        } else if (delayType == "start-of-order" || delayType == "days-from-pre-req") {
          if (!utils.isInteger(delayDays)) {
            alert("Must enter valid delay days.");
            return;
          }
        }
      }

      if (!refC.current.validate()) {
        return
      }

      model.deliveryType = deliveryType ? deliveryType : "standard";

      if (delayDays) {
        delayOptions.delayDays = Number(delayDays);
      }

      calcStartDayIndex(model)

      model.priceMap = createSubServicePriceMap({ serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds, delayOptions })

      onSave(model)
    }}>
      <RenderServiceSelection1 readonly setRef={refC} model={model} calculate={calculate} total={total} days={days} getPriceDays={getPriceDays} path={path} rootServiceVersion={rootServiceVersion} />

      {service ? (
        <React.Fragment>
          <RenderDelayOptions
            delayOptions={delayOptions}
            onUpdate={() => {
              setState({ ...state, model });
            }}
          />

          <ServiceEdit
            allowSelect
            model={model}
            onUpdate={(model) => {
              calculate(model)
              setState({ ...state, model });
            }}
          />

          {inputs && inputs.length > 0 ? (
            <Section>
              <HeaderText label="Questions:" subHeader description="Set pre-answers to these questions to start this service. If not answer here they will be required before the service can be started." />
              <AddInputsButton
                label="Enter Answers"
                inputs={inputs}
                inputData={inputData}
                onChange={(value) => {
                  model.inputData = value;
                  setState({ ...state, model });
                }}
              />
            </Section>
          ) : null}
        </React.Fragment>
      ) : null}

    </ModalBasic>
  );
}

function RecWorker({ user, userRole, businessRole }) {
  const [visibleUserId, setVisibleUserId] = useState();
  const { skillLevel } = userRole

  return <React.Fragment>
    <BoxRowItem
      onPress={() => {
        setVisibleUserId(user.id);
      }}>
      <FlexRow>
        <FlexExpand>
          <UserItem user={user}>
            <TextLine grey size={14} value={businessRole.name} spacer />
            <TextLine grey size={14} label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} />
          </UserItem>
        </FlexExpand>
      </FlexRow>
    </BoxRowItem>

    {visibleUserId ? (
      <ModalBasic
        full
        notCentered
        onCancel={() => {
          setVisibleUserId();
        }}>
        <UserProfileBuyer userId={user.id} />
      </ModalBasic>
    ) : null}
  </React.Fragment>
}

export function EditServiceSubServiceConfigBuyer({ rootServiceVersion, path, hasOverride, editItem, onCancel, onSave, onReset }) {
  const [state, setState] = useState({ isLoading: true, model: editItem });
  const { isLoading, model } = state;

  const { service, userService, total, deliveryDays } = model

  useEffect(() => {
    const promises = []

    promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: service.currentVersion.id }).then(function (serviceVersion) {
      service.currentVersion = serviceVersion
    })

    if (userService) {
      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id }).then(function (serviceVersion) {
        userService.currentVersion = serviceVersion
      })
    }

    Promise.all(promises).then(function () {
      setState({ ...state, isLoading: false })
    })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const calculate = function (model) {
    const { actualServiceVersion, deliveryType, packageId, serviceOptionIds } = model

    const { price, days } = getFinalPriceDays2({ rootPriceMap: rootServiceVersion.priceMap, rootPath: path, serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds })

    model.unitPrice = price
    model.total = model.unitPrice * model.quantity
    model.deliveryDays = days
  }

  const buttons = []

  if (hasOverride) {
    buttons.push({
      label: "Remove Overrides", onPress: () => {
        onReset()
      }
    })
  }

  return (
    <ModalBasic large title="Edit Service:" onCancel={onCancel} okText="Save" buttons={buttons} onOk={() => {
      onSave(model)
    }}>
      <Section>
        <HeaderText label="Service:" subHeader />

        <FormItem box>
          <FlexRow>
            <FlexExpand>
              <TextLine size={18} value={service.name} />
              <TextLine size={14} value={service.shortDescription} />
            </FlexExpand>

            <Price price={total} days={deliveryDays} deliveryText="Delivery in " />
          </FlexRow>
        </FormItem>
      </Section>

      {userService ? (
        <Section>
          <HeaderText label="Recommended Consultant:" subHeader />

          <RecWorker user={userService.user} businessRole={userService.businessRole} userRole={userService.userRole} />
        </Section>
      ) : null}

      <ServiceEdit
        allowSelect
        model={model}
        onUpdate={(model) => {
          calculate(model)
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

function EditServiceInput({ service, editItem, onDone, onCancel, userService, serviceVersion }) {
  const [state, setState] = useState({ model: Object.assign({}, editItem) });
  const { model } = state;
  const [visible, setVisible] = useState(false);

  const { icon, formItems, limitedToOptions, limitedToPackages, name, description } = model;
  const { enableServiceOptions, serviceOptions, packages, enablePackages } = serviceVersion

  const similarServices = model.similarServices ? model.similarServices : [];

  return (
    <View style={{ maxWidth: 550, marginHorizontal: 23 }}>
      <HeaderText
        label="Edit Question Form"
        description="Add one or multiple question forms that can be required to be filled out when starting this service."
        rightRender={
          <BackButton
            onPress={() => {
              onCancel();
            }}
          />
        }
      />
      <MyInput
        required
        label="Title for this form:"
        value={name}
        onChange={(value) => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        label="Additional description:"
        value={description}
        onChange={(value) => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this question set:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />

      <FormBuilder
        subHeader
        label="Ask the following questions:"
        formItems={formItems}
        onUpdate={(items) => {
          model.formItems = items;

          setState({ ...state, model });
        }}
      />

      {enableServiceOptions || enablePackages ? (
        <Section>
          <HeaderText subHeader label="Limit Questions:" description="Limit the requirement of these questions based on service options selected or bundled package selected." />

          {enableServiceOptions ? (
            <LimitServiceOptionsBox
              value={limitedToOptions}
              options={serviceOptions}
              onChange={(value) => {
                model.limitedToOptions = value;
                setState({ ...state, model });
              }}
            />
          ) : null}

          {enablePackages ? (
            <LimitPackageBox
              value={limitedToPackages}
              packages={enablePackages ? packages : null}
              onChange={(limitedToPackages) => {
                model.limitedToPackages = limitedToPackages;
                setState({ ...state, model });
              }}
            />
          ) : null}
        </Section>
      ) : null}

      <Section>
        <HeaderText
          subHeader
          label="Helper Services:"
          description="Add services that can help to prepare the requirements of this form."
          onButtonPress={() => {
            setVisible(true);
          }}
        />
        {similarServices && similarServices.length == 0 ? (
          <NoRecords label="No helper services added." />
        ) : (
          similarServices.map((similarService, index) => {
            const { shortDescription, name } = similarService;

            return (
              <BoxItem
                name={name}
                description={shortDescription}
                onDelete={() => {
                  similarServices.splice(index, 1);
                  setState({ ...state, model });
                }}
              />
            );
          })
        )}
        {visible ? (
          <SelectServiceDialog
            onCancel={() => {
              setVisible(false);
            }}
            onSelect={({ service }) => {
              if (!model.similarServices) {
                model.similarServices = [];
              }

              model.similarServices.push(service);
              setState({ ...state, model });
              setVisible(false);
            }}
          />
        ) : null}
      </Section>

      <TouchButton
        label="Save Question Form"
        onPress={() => {
          if (!name) {
            alert("Must enter name.");
            return;
          }

          const isNewVersion = setServiceItemModel({ model, service, userService, serviceVersion })

          return dL
            .saveServiceInput({ recordId: model.id, data: model, isNewVersion })
            .then(function (id) {
              return db.getQuery("ServiceInputVersion").get(id)
            }).then(function (obj) {
              const model = dL.loadServiceInputVersion(obj)
              return onDone(model);
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </View>
  );
}

function EditServiceOption({ service, editItem, onDone, userService, serviceVersion }) {
  const [state, setState] = useState({ model: Object.assign({}, editItem) });
  const { model } = state;

  const { icon, isFixedPriced, limitedToPackages, name, description, price, deliveryDays } = model;
  const { enablePackages, packages } = serviceVersion

  return (
    <View style={{ maxWidth: 550, marginHorizontal: 23 }}>
      <HeaderText label="Edit Service Option" description="This service option can be used to provide options for service purchase." />

      <MyInput
        required
        label="Name or title of option:"
        value={name}
        onChange={(value) => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        maxLength={144}
        label="Short Description:"
        value={description}
        onChange={(value) => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this option:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />

      <Toggle
        value={isFixedPriced}
        onChange={(value) => {
          model.isFixedPriced = value;
          setState({ ...state, model });
        }}
        label="Make fixed price:"
        description="Make this option fixed price, instead of using hourly costing."
        style={{ marginBottom: 23 }}
      />

      <FlexRow>
        {isFixedPriced ? (
          <React.Fragment>
            <MyInput
              style={{ maxWidth: 125 }}
              inputType="money"
              minValue={0}
              label="Price:"
              description="Additional price that will be charged for this option."
              value={price}
              onChange={(value) => {
                model.price = value;
                setState({ ...state, model });
              }}
            />
            <View style={{ width: 25 }} />
          </React.Fragment>
        ) : null}

        <MyInput
          style={{ maxWidth: 125 }}
          inputType="integer"
          minValue={0}
          label="Delivery Days:"
          description="Additional delivery days that will be added for this option."
          value={deliveryDays}
          onChange={(value) => {
            model.deliveryDays = value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      <LimitPackageBox
        label="Included in Bundled Packages:"
        value={limitedToPackages}
        packages={enablePackages ? packages : null}
        onChange={(limitedToPackages) => {
          model.limitedToPackages = limitedToPackages;
          setState({ ...state, model });
        }}
      />

      <TouchButton
        label="Save Option"
        onPress={() => {
          if (!name) {
            alert("Must enter name.");
            return;
          }

          if (isFixedPriced) {
            if (!utils.isMoney(price)) {
              alert("Must enter valid price.");
              return;
            }
            model.price = Number(price);
          }

          if (deliveryDays) {
            if (!utils.isInteger(deliveryDays)) {
              alert("Must enter valid delivery days.");
              return;
            }

            if (Number(deliveryDays) <= 0) {
              alert("Must enter valid delivery days.");
              return;
            }
            model.deliveryDays = Number(deliveryDays);
          }

          const isNewVersion = setServiceItemModel({ model, service, userService, serviceVersion })

          return dL
            .saveServiceOption({ recordId: model.id, data: model, isNewVersion })
            .then(function (id) {
              return db.getQuery("ServiceOptionVersion").get(id)
            }).then(function (obj) {
              const model = dL.loadServiceOptionVersion(obj)
              return onDone(model);
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </View>
  );
}

function RenderWorkerUserService({ service, servicePackage, userService, onDelete, path, rootServiceVersion, deliveryType, packageId, serviceOptionIds }) {
  const [state, setState] = useState({ isLoading: true })
  const { user, isLoading, userServiceVersion } = state

  useEffect(() => {
    const { user, currentVersion } = userService
    dL.getQuery("User").include("userRoles").get(user.id).then(function (obj) {
      const user = dL.loadUser(obj)

      return dL.getServiceVersion2({ serviceVersionId: currentVersion.id }).then(function (serviceVersion) {
        setState({ ...state, user, isLoading: false, userServiceVersion: serviceVersion })
      })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { userRoles } = user;
  const { enablePackages } = userServiceVersion
  const userRole = userRoles.find(item => item.businessRole.id == service.businessRole.id);

  const { skillLevel } = userRole;
  const { baseBillRate } = getWorkerRate({ userRole, businessRole: service.businessRole, assignedTo: user, skillLevel });

  const getPriceDays = function ({ serviceVersion }) {
    return getFinalPriceDays2({ rootPriceMap: rootServiceVersion.priceMap, rootPath: path, serviceVersion, deliveryType, packageId, serviceOptionIds })
  }

  const { days, price: total } = getPriceDays({ serviceVersion: userServiceVersion })

  return (
    <BoxRowItem>
      <FlexRow>
        <FlexExpand>
          <UserItem user={user} avatarSize={55}>
            <TextLine grey size={13} value={"Skill Level: " + getLabelItem(utils.skillLevels, skillLevel)} />
            <TextLine grey size={13} value={"Hourly Rate: " + money(baseBillRate)} />
          </UserItem>
        </FlexExpand>

        {service && (!enablePackages || servicePackage) ? <Price price={total} days={days} deliveryText="Delivery in " /> : null}

        <TrashIcon style={{ marginLeft: 15 }}
          onPress={onDelete}
        />
      </FlexRow>
    </BoxRowItem>
  );
}

function RenderLimitOptions({ service, userService, serviceVersion, item }) {
  const { enablePackages, serviceOptions, packages } = getVersionData({ service, userService, serviceVersion })
  const { limitedToOptions, limitedToPackages } = item;

  return (
    <React.Fragment>
      {enablePackages ? <RenderLimitedPackages packages={packages} value={limitedToPackages} /> : null}
      <RenderLimitedOptions options={serviceOptions} value={limitedToOptions} />
    </React.Fragment>
  );
}

const getData1 = function ({ serviceVersion }) {
  const arr = []
  const removedServices = []
  const { masterOverrides } = serviceVersion

  const circularIds = {}
  const processServiceVersion = function ({ serviceVersion, circularIds, path: startPath }) {
    const { subServices, service } = serviceVersion
    circularIds[service.id] = true

    subServices.forEach((subServiceVersion) => {
      const { subService, service } = subServiceVersion
      const path = startPath + (startPath ? "." : "") + "service[" + subService.id + "]"

      const override = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

      const { actualServiceVersion, serviceVersion, userServiceVersion, packageId, serviceOptionIds, deliveryType } = override ? override : subServiceVersion

      const useVersion = userServiceVersion ? userServiceVersion : serviceVersion

      const { businessRole } = useVersion
      const tasks = getTasksForService({ serviceVersion: useVersion, packageId, serviceOptionIds });
      const workHours = getWorkHours({ tasks });
      const { price } = getServicePrice2({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds, deliveryType })

      const data = {
        path,
        subServiceVersion,
        service,
        serviceVersion: useVersion,
        businessRole,
        workHours,
        price,
        tasks, packageId, serviceOptionIds, deliveryType
      }

      var cont = true
      if (override) {
        const { isRemoved } = override
        if (isRemoved) {
          removedServices.push(data)
          cont = false
        }
      }
      if (cont) {
        arr.push(data)
        processServiceVersion({ serviceVersion: useVersion, path, circularIds })
      }
    })
  }

  processServiceVersion({ serviceVersion, circularIds, path: "" })

  //summarize into one
  const noWorker = []
  const workers = new HashTable()
  const roles = new HashTable()
  arr.forEach(item => {
    const { businessRole, workHours, subServiceVersion } = item
    const { userService } = subServiceVersion

    if (userService) {
      const { user } = userService
      const { id } = user

      if (!workers.has(id)) {
        workers.set(id, {
          workHours,
          user,
          items: []
        })
      } else {
        const worker = workers.get(id)
        const { items } = worker
        items.push(item)

        worker.workHours += workHours
      }
    } else {
      noWorker.push(item)
    }

    const { id } = businessRole
    if (!roles.has(id)) {
      roles.set(id, {
        workHours,
        businessRole,
        items: []
      })
    } else {
      const role = roles.get(id)
      const { items } = role
      items.push(item)

      role.workHours += workHours
    }
  })

  return { arr, roles: roles.values(), workers: workers.values(), noWorker, removedServices }
}


export function ServiceAdmin() {
  const { sectionId, serviceId, userServiceId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [state, setState] = useState({ tabValueM2: "checklist", tabValueM: "details", isLoading: true });
  const [refresh, setRefresh] = useState(new Date());
  const { isProcessing, tabValueM2, forkedUserServices, workerVisible, serviceVersions, userService, similarServiceView, tabValueM, editItem, isNew, showDeleteN, deleteIndex, isLoading, subServiceVisible, packageVisible, taskVisible, optionVisible, deliverableVisible, inputVisible, dialogType, historicalServiceVersion, service } = state;

  const isSubUrl = useRouteMatch(`${url}/:type/:recordId?`);

  useEffect(() => {
    var service;
    var userService;
    var forkedUserServices
    var serviceVersions

    clearCache()
    var includes = []
    if (sectionId == "admin") {
      includes.push("ownerUserService")
      includes.push("ownerUserService.user")
    }
    dL.getService2({ serviceId, includes }).then(function (_service) {
      service = _service;

      if (sectionId == "worker") {
        return dL.getUserService2({ userServiceId }).then(function (_userService) {
          userService = _userService;
        });
      }
    }).then(function () {
      const promises = []

      if (!userService) {
        promises[promises.length] = dL.getForkedUserServiceForService({ serviceId })
          .then(function (_forkedUserServices) {
            forkedUserServices = _forkedUserServices
          });
      }
      promises[promises.length] = dL.getServiceVersionHistory({ serviceId, userServiceId: userService ? userService.id : null }).then(function (_serviceVersions) {
        serviceVersions = _serviceVersions
      })

      return Promise.all(promises)
    }).then(function () {
      const serviceVersion = (userService ? userService : service).draftVersion
      const { deliverables, options, inputs, tasks, subServices, packages } = serviceVersion

      var editItem, isNew;

      if (isSubUrl) {
        const { recordId, type } = isSubUrl.params;

        if (!recordId) {
          isNew = true;
          editItem = {};
        } else {
          if (type == "sub-service") {
            editItem = subServices.find((item) => item.id == recordId);
          } else if (type == "task") {
            editItem = tasks.find((item) => item.id == recordId);
          } else if (type == "option") {
            editItem = options.find((item) => item.id == recordId);
          } else if (type == "input") {
            editItem = inputs.find((item) => item.id == recordId);
          } else if (type == "deliverable") {
            editItem = deliverables.find((item) => item.id == recordId);
          } else if (type == "package") {
            editItem = packages.find((item) => item.id == recordId);
          }
        }
      }

      setState({ ...state, isProcessing: false, forkedUserServices, service, serviceVersions, isLoading: false, userService, service, editItem, isNew });
    })
    /*.catch(function (err) {
      alert("Error: " + err);
    });
    */
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { workerUserServices, isBuyerPublic } = service;

  const { isWorkerPublic, userRole } = userService ? userService : {};
  const { team, status, currentVersion, draftVersion } = userService ? userService : service;
  const serviceVersion = historicalServiceVersion ? historicalServiceVersion : draftVersion

  const { masterOverrides, skillLevel, icon, tags, companyStages, enableServiceOptions, isFixedPriced, maxPrice, allowedRevisions, businessRole, name, shortDescription, description, serviceType, minPrice, price, deliveryDays, hasExtraFastDelivery, extraFastPrice, extraFastDeliveryDays } = serviceVersion
  const { enablePackages, packages, deliverables, options, inputs, tasks, subServices } = serviceVersion;

  const rootService = service
  const rootServiceVersion = serviceVersion

  const vIsSystemAdmin = session.user.isSystemAdmin && sectionId == "admin";

  const doAddTask = function () {
    setState({ ...state, isNew: true, editItem: {} });
    history.push(`${url}/task`);
  };

  const doEditService = function () {
    if (userService) {
      history.push(`/${sectionId}/service/${serviceId}/${userService.id}/edit`);
    } else {
      history.push(`/${sectionId}/service/${serviceId}/edit`);
    }
  };

  const doAddDeliverable = function () {
    history.push(`${url}/deliverable`);

    setState({ ...state, isNew: true, editItem: {} });
  };

  const doAddBundlePackage = function () {
    history.push(`${url}/package`);
    setState({ ...state, isNew: true, editItem: {} });
  };

  const getChecklistItems = function () {
    const checklistItems = [];
    if (sectionId == "worker") {
      checklistItems.push({
        label: "Complete basic service details.",
        value: "details",
        buttonText: "Edit Details",
        onButtonPress: () => {
          doEditService();
        },
        isComplete: () => {
          if (name && shortDescription && serviceType && companyStages && companyStages.length > 0 && businessRole && skillLevel) {
            return true;
          }
        },
      });
      checklistItems.push({
        label: "Enter tasks and estimate hours.",
        value: "tasks",
        buttonText: "Add Task",
        onButtonPress: () => {
          doAddTask();
        },
        isComplete: () => {
          if (tasks.length > 0) {
            return true;
          }
        },
      });
      checklistItems.push({
        label: "Enter deliverables.",
        value: "deliverables",
        buttonText: "Add Deliverable",
        onButtonPress: () => {
          doAddDeliverable();
        },
        isComplete: () => {
          if (deliverables.length > 0) {
            return true;
          }
        },
      });
      checklistItems.push({
        label: "Optional, enter questions for service.",
        value: "questions",
        optional: true,
        buttonText: "Edit Questions",
        onButtonPress: () => {
          setState({ ...state, tabValueM: "inputs" });
        },
        isComplete: () => {
          if (inputs.length > 0) {
            return true;
          }
        },
      });

      if (enableServiceOptions) {
        checklistItems.push({
          label: "Optional, enter service options.",
          value: "options",
          optional: true,
          buttonText: "Edit Service Options",
          onButtonPress: () => {
            setState({ ...state, tabValueM: "options" });
          },
          isComplete: () => {
            if (options.length > 0) {
              return true;
            }
          },
        });
      }
      if (enablePackages) {
        checklistItems.push({
          label: "Setup bundled packages.",
          value: "bundled",
          buttonText: "Add Bundle",
          onButtonPress: () => {
            doAddBundlePackage();
          },
          isComplete: () => {
            if (packages.length > 0) {
              return true;
            }
          },
        });
      } else {
        checklistItems.push({
          label: "Enter delivery days.",
          value: "delivery",
          buttonText: "Edit Delivery",
          onButtonPress: () => {
            setState({ ...state, dialogType: "delivery" });
          },
          isComplete: () => {
            if (deliveryDays != null) {
              return true;
            }
          },
        });
        if (isFixedPriced) {
          checklistItems.push({
            label: "Set service price information.",
            value: "price",
            buttonText: "Edit Price",
            onButtonPress: () => {
              setState({ ...state, dialogType: "price" });
            },
            isComplete: () => {
              if (price != null) {
                return true;
              }
            },
          });
        }
      }
      checklistItems.push({
        label: "Publish Service.",
        value: "publish",
        buttonText: "Publish Service",
        description: "Publish this service to your service catalog.",
        onButtonPress: () => {
          if (!canSubmitServiceForApproval({ service, userService, serviceVersion })) {
            return;
          }

          setState({ ...state, dialogType: "visibility" });
        },
        isComplete: () => {
          if (userService.status == "active") {
            return true;
          }
        },
      });
    } else if (sectionId == "admin") {
      const { isBuyerPublic } = service;

      if (service.needsReview) {
        checklistItems.push({
          label: "Needs Review",
          value: "needs-review",
          buttonText: "Mark Reviewed",
          description: "Review this service and mark reviewed.",
          onButtonPress: () => {
            service.needsReview = false
            service.lastReviewedAt = new Date
            service.lastReviewedVersionNum = service.currentVersion.version
            setState({ ...state, service })
            return dL.getObj("Service", serviceId)
              .set("needsReview", false)
              .set("lastReviewedAt", new Date())
              .set("lastReviewedVersionNum", service.currentVersion.versionNum)
              .save()
          },
          isComplete: () => {

          },
        });
      }

      if (userService && userService.needsReview) {
        checklistItems.push({
          label: "Needs Review",
          value: "needs-review",
          buttonText: "Mark Reviewed",
          description: "Review this service and mark reviewed.",
          onButtonPress: () => {
            return dL.getObj("UserService", userService.id)
              .set("needsReview", false)
              .set("lastReviewedAt", new Date())
              .set("lastReviewedVersionNum", userService.currentVersion.versionNum)
              .save()
          },
          isComplete: () => {

          },
        });
      }

      checklistItems.push({
        label: "Publish Service",
        value: "publish-buyer",
        buttonText: "Publish Service",
        description: "Make this service visible to buyer in the service catalog.",
        onButtonPress: () => {
          if (!canSubmitServiceForApproval({ service, userService, serviceVersion })) {
            return;
          }

          setState({ ...state, isProcessing: true })
          return dL.publishService(service.id).then(function () {
            alert("Published.");
            setRefresh(new Date())
          });
        },
        isComplete: () => {
          if (isBuyerPublic) {
            return true;
          }
        },
      });
    }
    return checklistItems;
  };

  const checklistItems = getChecklistItems();

  const alerts = []//getAllAlerts({ sectionId, service, serviceVersion })

  const tabs = [{ label: "Overview", value: "details" }];

  if (enablePackages) {
    tabs.push({ label: pluralTab({ list: packages, label: "Package" }), value: "packages" });
  }
  tabs.push({ label: pluralTab({ list: inputs, label: "Question" }), value: "inputs", description: "Add question forms to ask user certain questions when requesting this service." });
  if (enableServiceOptions) {
    tabs.push({ label: pluralTab({ list: options, label: "Option" }), value: "options" });
  }
  tabs.push({ label: pluralTab({ list: tasks, label: "Task" }), value: "tasks", description: "Add tasks required for the completion of this service." });
  tabs.push({ label: pluralTab({ list: subServices, label: "Sub-Service" }), value: "sub-services", description: "Add additional sub-services that are required for completion of this service." });
  tabs.push({ label: pluralTab({ list: deliverables, label: "Deliverable" }), value: "deliverables", description: "Add deliverable forms that will be completed and delivered for this service." });

  if (serviceVersion.priceMap) {
    tabs.push({ label: "Price List", value: "price-list" });
  }

  tabs.push({ label: "Trainers", value: "trainers" });

  if (sectionId == "admin") {
    tabs.push({ label: "Workers", value: "workers" });
  }

  if (serviceVersions.length > 1) {
    tabs.push({ label: "Versions", value: "versions" });
  }

  tabs.push({ label: "Test", value: "test" });

  const { costPrice, workerHours } = getServiceStats({ service, userService, serviceVersion });

  const preReqItems = getServicePreReqItems({ serviceData: serviceVersion });
  const buttons = getServiceButtons({
    onLoading: function (value) {
      setState({ ...state, isProcessing: value })
    },
    onGoBack: function () {
      history.goBack()
    },
    service,
    userService,
    serviceVersion,
    isAdmin: vIsSystemAdmin,
    onChange: function () {
      setRefresh(new Date());
    },
    onDelete: function () {
      history.goBack();
    },
  });

  if (status == "active") {
    buttons.unshift({
      label: "Edit Service",
      onPress: () => {
        doEditService();
      },
    });
  }

  const onDialogClose = () => {
    setState({ ...state, dialogType: null });
  };

  const getPrice = function ({ deliveryType }) {
    return getServicePrice2({ serviceVersion, deliveryType }).price
  };

  const doSaveServiceItem = function ({ type, editItem }) {
    const typeLower = getFirstLetterLower(type)
    const listName = typeLower + "s"

    const list = serviceVersion[listName]
    if (isNew) {
      list.push(editItem);
    } else {
      const editIndex = list.findIndex((item) => item[typeLower].id == editItem[typeLower].id);
      list[editIndex] = editItem;
    }

    setState({ ...state, userService, service });

    return dL["saveService" + type + "s"]({ serviceVersion }).then(function () {
      dL.doUpdateService({ service, userService, serviceVersion });
      setRefresh(new Date());
      history.goBack();
    });
  }

  const renderAllAlerts = function () {
    if (alerts.length == 0) {
      return <NoRecords label="No alerts found." />
    }
    return alerts.map(err => {
      const { message } = err
      return <BoxItem>
        <TextLine value={message} />
      </BoxItem>
    })
  }

  const calculatePriceMap = function () {
    return dL.doUpdateService({ updateObj: db.getObj("ServiceVersion", serviceVersion.id), service, userService, serviceVersion }).then(function () {
      const priceMap = createServicePriceMap({ serviceVersion })
      serviceVersion.priceMap = priceMap

      setState({ ...state })

      return dL.getObj("ServiceVersion", serviceVersion.id)
        .set("priceMap", priceMap)
        .save()
    })
  }

  const renderPublishedChanges = function () {
    const hasUpdates = draftVersion.lastSavedAt != null

    const { minPrice: currentMinPrice, maxPrice: currentMaxPrice, actualDeliveryDays: currentMinDeliveryDays, versionNum: currentVersionNum } = currentVersion
    const { minPrice, maxPrice, actualDeliveryDays, versionNum } = draftVersion

    if (!hasUpdates) {
      return <View />
    }

    return <FlexRow>
      <TextLine color="red" bold size={12} value="Un-Published Changes!" spacer />
      <TextLine size={12} value={`Current Version #${currentVersionNum}: ${moneyRange(currentMinPrice, currentMaxPrice)} (${plural(currentMinDeliveryDays, "day")})`} spacer />
      <TextLine size={12} value=" >> " spacer />
      <TextLine bold size={12} value={`New Version #${versionNum}: ${moneyRange(minPrice, maxPrice)} (${plural(actualDeliveryDays, "day")})`} />
    </FlexRow>
  }

  const getPackageMixMaxHours = function () {
    var minHours, maxHours
    if (enablePackages && packages.length > 0) {
      minHours = 99
      maxHours = 0
      packages.forEach(servicePackage => {
        const { workHours } = servicePackage
        if (workHours < minHours) {
          minHours = workHours
        }
        if (workHours > maxHours) {
          maxHours = workHours
        }
      })
    }
    return { minHours, maxHours }
  }

  const { minHours, maxHours } = getPackageMixMaxHours()

  return (
    <Switch>
      <Route exact path={`${url}/training-note/:recordId/edit`}>
        <EditTrainingNote
          service={service}
          serviceVersion={serviceVersion}
          userService={userService}
          onSave={() => {
            history.goBack()
          }} />
      </Route>
      <Route path={`${url}/training-note/type/:type/:versionId/:linkRecordId`}>
        <EditTrainingNote
          service={service}
          serviceVersion={serviceVersion}
          userService={userService}
          onSave={() => {
            history.goBack()
          }} />
      </Route>
      <Route path={`${url}/sub-service/:recordId?`}>
        <EditServiceSubService
          rootServiceVersion={rootServiceVersion}
          masterOverrides={masterOverrides}
          parentService={service}
          parentServiceVersion={serviceVersion}
          preReqItems={preReqItems}
          editItem={editItem}
          parentUserService={userService}
          onCancel={() => {
            history.goBack();
          }}
          onDone={(editItem) => {
            doSaveServiceItem({ type: "SubService", editItem })
            calculatePriceMap()
          }}
        />
      </Route>
      <Route path={`${url}/task/:recordId?`}>
        <EditServiceTask
          service={service}
          serviceVersion={serviceVersion}
          userService={userService}
          editItem={editItem}
          preReqItems={preReqItems}
          onCancel={() => {
            history.goBack();
          }}
          onDone={(editItem) => {
            doSaveServiceItem({ type: "Task", editItem })
          }}
        />
      </Route>
      <Route path={`${url}/input/:recordId?`}>
        <EditServiceInput
          service={service}
          serviceVersion={serviceVersion}
          userService={userService}
          editItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={(editItem) => {
            doSaveServiceItem({ type: "Input", editItem })
          }}
        />
      </Route>
      <Route path={`${url}/option/:recordId?`}>
        <EditServiceOption
          service={service}
          serviceVersion={serviceVersion}
          userService={userService}
          editItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={(editItem) => {
            doSaveServiceItem({ type: "Option", editItem })
          }}
        />
      </Route>
      <Route path={`${url}/deliverable/:deliverableId?`}>
        <EditServiceDeliverable
          service={service}
          serviceVersion={serviceVersion}
          userService={userService}
          editItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={(editItem) => {
            doSaveServiceItem({ type: "Deliverable", editItem })
          }}
        />
      </Route>
      <Route path={`${url}/package/:packageId?`}>
        <EditServicePackage
          service={service}
          serviceVersion={serviceVersion}
          userService={userService}
          editItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={(editItem) => {
            doSaveServiceItem({ type: "Package", editItem })
          }}
        />
      </Route>
      <Route path={`${url}`}>
        <View>
          {isProcessing ? <Loading /> : null}
          <BasicTop title={name} shortDescription={shortDescription} buttons={buttons} rightRender={<TabBar
            queryId="tb2"
            style={{ marginLeft: 15 }}
            options={[{ label: "Checklist", value: "checklist" }, { label: "Stats", value: "stats" }, { label: plural(alerts.length, "Alert"), value: "alerts" }]}
            onChange={(item) => {
              setState({ ...state, tabValueM2: item.value });
            }}
            value={tabValueM2}
          />}
            leftRender={
              icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} /> : null
            }>

            {renderPublishedChanges()}
          </BasicTop>

          {historicalServiceVersion ? (
            <BoxItem>
              <FlexRow>
                <FlexExpand>
                  <TextLine bold value={"Viewing Previous Service Version: #" + historicalServiceVersion.versionNum}></TextLine>
                </FlexExpand>
                <MicroButton
                  description="Click to go back to the most current service version."
                  label="Remove"
                  onPress={() => {
                    setState({ ...state, historicalServiceVersion: null });
                  }}
                />
              </FlexRow>
            </BoxItem>
          ) : null}

          <FlexRow alignTop>
            <FlexExpand>
              <TabBar
                queryId="tb"
                style={{ marginBottom: 25 }}
                options={tabs}
                onChange={(item) => {
                  setState({ ...state, tabValueM: item.value });
                }}
                value={tabValueM}
              />
              {tabValueM == "price-list" ?
                <Section>
                  <HeaderText subHeader label="Price List:" description="See the complete price list for this service which includes all sub-services." />

                  {rootServiceVersion.priceMap.length == 0 ? <NoRecords label="No price list exists without sub-services." /> : null}

                  {rootServiceVersion.priceMap.map(mapItem => {
                    const { path, item, isOverride } = mapItem
                    const { name, actualVersionType, price, days, isRemoved, actualVersionNum, actualServiceVersionId, startDayIndex, doneDayIndex, delayOptions } = item

                    const indexSeq = path.split(".").length - 1

                    const { days: pDays } = getDataFromPriceMap({ priceMap: rootServiceVersion.priceMap, path })

                    //include the start/end dates
                    //include the deliverable days here as well

                    return <View style={{ marginLeft: indexSeq * 25 }}>
                      <BoxItem name={name} rightRender={<Price strikeThrough={isRemoved} price={price} days={days} />}>
                        <FlexRow>
                          <TextLine size={14} label="Index:" value={indexSeq} spacer />
                          {isOverride ? <TextLine size={14} value={"OVERRIDE"} spacer /> : null}
                          {isRemoved ? <TextLine size={14} value={"REMOVED"} spacer /> : null}
                          <TextLine size={14} label="pDays:" value={pDays} spacer />
                          <TextLine size={14} label="Start Index:" value={startDayIndex} spacer />
                          <TextLine size={14} label="Done Index:" value={doneDayIndex} spacer />
                        </FlexRow>

                        <RenderDelayOptions2 delayOptions={delayOptions} />

                        {session.user.testMode ?
                          <React.Fragment>
                            <TextLine size={12} top={10} label="Path:" value={path} />
                            <View top={10} />
                            <TextLine size={12} label="Version ID:" value={actualServiceVersionId} spacer />

                            <FlexRow>
                              <TextLine size={12} label="Version Type:" value={actualVersionType} spacer />
                              <TextLine size={12} label="Version Num:" value={actualVersionNum} />
                            </FlexRow>
                          </React.Fragment> : null}
                      </BoxItem>
                    </View>
                  })}
                </Section> : null}

              {tabValueM == "test" ? (
                <Section>
                  <ServicePurchaseTest historicalServiceVersion={historicalServiceVersion} serviceVersion={serviceVersion} userService={userService} service={service} onUpdateOverrides={() => {
                    calculatePriceMap()
                    setState({ ...state })
                  }} />
                </Section>
              ) : null}

              {tabValueM == "details" ? (
                <React.Fragment>
                  <Section>
                    <HeaderText
                      subHeader
                      label="Overview:"
                      buttonClass="btn-mn"
                      buttonIcon="pencil-alt"
                      buttonText="Edit"
                      onButtonPress={() => {
                        doEditService();
                      }}
                    />

                    <Hide><FlexRow>
                      {serviceVersion.enablePackages ? <LabelItem label="Price Range:" value={moneyRange(serviceVersion.minPrice, serviceVersion.maxPrice)} /> : <LabelItem label="Price:" value={money(serviceVersion.actualPrice)} />}
                    </FlexRow></Hide>

                    <FlexRow>
                      <ServiceDetails1 service={service} userService={userService} serviceVersion={serviceVersion} />

                      <LabelItem label="Status:" value={getLabelItem(utils.serviceStatuses, status)} />

                      <Hide>
                        {userService ? <LabelItem label="Status:" value={getLabelItem(utils.serviceStatuses, userService.status)} /> : <LabelItem label="Status:" value={getLabelItem(utils.serviceStatuses, status)} />}
                      </Hide>
                    </FlexRow>

                    <FlexRow>
                      {tags ? <LabelItem label="Tags:" style={{ maxWidth: 250 }} onValue={() => {
                        var str = ""
                        tags.forEach(item => {
                          if (str) { str += ", " }
                          str += item.name
                        })
                        return str ? str : null
                      }} /> : null}

                      {team ? <LabelItem label="Team:" value={team.name} /> : null}
                    </FlexRow>
                  </Section>

                  {!enablePackages ? (
                    <Section>
                      <HeaderText
                        subHeader
                        label="Service Delivery:"
                        description="Setup standard delivery days and allowed number of deliverable revisions."
                        buttonClass="btn-mn"
                        buttonIcon="pencil-alt"
                        buttonText="Edit"
                        onButtonPress={() => {
                          setState({ ...state, dialogType: "delivery" });
                        }}
                      />

                      <FlexRow>
                        <LabelItem label="Delivery Days:" description="Number of standard business days for delivery of this service. This includes delivery time for any sub serviced added." value={deliveryDays ? plural(deliveryDays, "day") : null} />

                        {hasExtraFastDelivery ? (
                          <React.Fragment>
                            <LabelItem label="Fast Delivery:" description="Enter the days this service will be delivered in for extra fast delivery." value={extraFastDeliveryDays ? plural(extraFastDeliveryDays, "day") : null} />
                          </React.Fragment>
                        ) : null}

                        {allowedRevisions ? <LabelItem label="Revisions:" description="Number of revisions that will be allowed for deliverables." value={getLabelItem(utils.revisionOptions, allowedRevisions)} /> : null}
                      </FlexRow>
                    </Section>
                  ) : null}

                  <Section>
                    <HeaderText subHeader label="Service Price and Work Hours:" description="View the business role and see the work hours involved for the service"
                      buttonClass="btn-mn"
                      buttonIcon="pencil-alt"
                      buttonText="Edit"
                      onButtonPress={() => {
                        setState({ ...state, dialogType: "price" });
                      }} />

                    <FlexRow>
                      {userRole ? <React.Fragment>
                        <LabelItem label="Business Role:" description="Business role for this service." value={businessRole.name} />

                        <LabelItem label="Skill Level:" description="Skill level for the business role required for this service." value={getLabelItem(utils.priceTiers, userRole.skillLevel)} />

                        <LabelItem label="Consulting Rate:" description="Hourly consulting rate for the business role and skill level selected." value={money(userRole.hourlyRate) + "/hr"} />
                      </React.Fragment> :
                        businessRole ?
                          <React.Fragment>
                            <LabelItem label="Business Role:" description="Business role for this service." value={businessRole.name} />

                            {skillLevel ? <LabelItem label="Skill Level:" description="Skill level for the business role required for this service." value={getLabelItem(utils.priceTiers, skillLevel)} /> : null}

                            <LabelItem label="Consulting Rate:" description="Hourly consulting rate for the business role and skill level selected." value={money(businessRole.rates[skillLevel]) + "/hr"} />
                          </React.Fragment> : null}
                    </FlexRow>

                    <FlexRow>
                      {enablePackages ? <LabelItem label="Price Range:" value={moneyRange(minPrice, maxPrice)} /> : <LabelItem label="Standard Price:" description="Price charged for this service." value={isFixedPriced ? (price ? money(price) : null) : money(getPrice({}))} />}

                      <LabelItem label="Work Hours:" description="Number of hour of work for the base service not including any service options." value={!enablePackages ? plural(workerHours, "hr") : valueRange(minHours, maxHours)} />
                    </FlexRow>
                  </Section>

                  <Hide>
                    {enablePackages ? (
                      <Section>
                        <HeaderText
                          subHeader
                          label="Price:"
                          description="Price range for this service that will be visible to buyers."
                          buttonClass="btn-mn"
                          buttonIcon="pencil-alt"
                          buttonText="Edit"
                          onButtonPress={() => {
                            setState({ ...state, dialogType: "price" });
                          }}
                        />
                        <FlexRow>
                          <LabelItem label="Price Range:" value={isFixedPriced ? moneyRange(minPrice, maxPrice) : "Based on Tasks"} />

                          <LabelItem label="Base Cost:" description="Base cost for the service based on the worker rate, work hours, plus any cost related to sub-services. This does NOT included any service options selected." value={!enablePackages ? money(costPrice) : "RANGE OF COST"} />

                          <LabelItem value={yesNo(enablePackages)} label="Bundled Packages:" description="Add bundled packages for different packages prices, set different delivery days and price for extra fast delivery." />
                        </FlexRow>
                      </Section>
                    ) : null}

                    {!enablePackages ? (
                      <Section>
                        <HeaderText
                          subHeader
                          label="Purchase Price:"
                          description="Setup the pricing options for this service."
                          buttonClass="btn-mn"
                          buttonIcon="pencil-alt"
                          buttonText="Edit"
                          onButtonPress={() => {
                            setState({ ...state, dialogType: "price" });
                          }}
                        />

                        <FlexRow>
                          {isFixedPriced ? <LabelItem value={yesNo(isFixedPriced)} label="Fixed Price:" description="Set the exact price for each of the service and service options instead of calculating by the hourly rate." /> : null}

                          <LabelItem label="Standard Price:" description="Price charged for this service." value={isFixedPriced ? (price ? money(price) : null) : money(getPrice({}))} />

                          {hasExtraFastDelivery ? (
                            <React.Fragment>
                              <LabelItem label="Fast Price:" description="Enter the price charge for extra fast delivery." value={isFixedPriced ? money(extraFastPrice) : money(getPrice({ deliveryType: "fast" }))} />

                              <LabelItem label="Fast Delivery:" description="Enter the days this service will be delivered in for extra fast delivery." value={plural(extraFastDeliveryDays, "day")} />
                            </React.Fragment>
                          ) : null}

                          <LabelItem label="Base Cost:" description="Base cost for the service based on the worker rate, work hours, plus any cost related to sub-services. This does NOT included any service options selected." value={!enablePackages ? money(costPrice) : "RANGE OF COST"} />

                          <LabelItem value={yesNo(enablePackages)} label="Bundled Packages:" description="Add bundled packages for different packages prices, set different delivery days and price for extra fast delivery." />
                        </FlexRow>
                      </Section>
                    ) : null}
                  </Hide>

                  <Section>
                    <HeaderText
                      subHeader
                      label="Visibility:"
                      description="Change who can view and access this service."
                      buttonClass="btn-mn"
                      buttonIcon="pencil-alt"
                      buttonText="Edit"
                      onButtonPress={() => {
                        if (userService) {
                          setState({ ...state, dialogType: "visibility" });
                        } else {
                          setState({ ...state, dialogType: "admin" });
                        }
                      }}
                    />

                    <FlexRow>
                      {userService ? <LabelItem value={yesNo(isWorkerPublic)} label="Marketplace:" description="Service will be visible in other consultants." /> : null}

                      {vIsSystemAdmin ? <LabelItem value={yesNo(isBuyerPublic)} label="Visible in buyer service catalog:" description="Service will be visible in the buyer service catalog." /> : null}

                      {currentVersion ? <LabelItem label="Published Version:" value={"#" + currentVersion.versionNum} /> : null}
                    </FlexRow>
                  </Section>

                  <Hide>
                    <Section>
                      <HeaderText
                        subHeader
                        label="Service Settings:"
                        description="Various advanced settings that are availale for this service."
                        buttonClass="btn-mn"
                        buttonIcon="pencil-alt"
                        buttonText="Edit"
                        onButtonPress={() => {
                          setState({ ...state, dialogType: "options" });
                        }}
                      />

                      <FlexRow>
                        <LabelItem value={yesNo(enableServiceOptions)} label="Advanced Options:" description="Enable the ability to add different service options to change the tasks required, price for the service, deliverables returned and questions needed to be asked." />
                      </FlexRow>
                    </Section>
                  </Hide>

                  {description ? (
                    <Section>
                      <HeaderText subHeader label="Description:" />
                      <TextLine>{description}</TextLine>
                    </Section>
                  ) : null}
                </React.Fragment>
              ) : null}

              {tabValueM == "deliverables" ? (
                <Section>
                  <HeaderText
                    subHeader
                    label={(deliverables.length > 0 ? plural(deliverables.length, "Deliverable") : "Deliverables") + ":"}
                    description="Add deliverables for this service. You can select which packages these deliverables are assigned too."
                    onButtonPress={!historicalServiceVersion ? () => {
                      doAddDeliverable();
                    } : null}
                  />
                  <SortableList
                    disabled={historicalServiceVersion}
                    emptyRender={<NoRecords label="No deliverables added." />}
                    items={deliverables}
                    onChange={(list) => {
                      serviceVersion.deliverables = list;
                      dL.saveServiceDeliverables({ serviceVersion });
                      setState({ ...state });
                    }}
                    renderItem={(item, index) => {
                      const { id } = item
                      return <RenderDeliverable key={id} item={item} service={service} userService={userService} serviceVersion={serviceVersion}
                        onPress={() => {
                          setState({ ...state, deliverableVisible: item });
                        }}
                        onEdit={() => {
                          history.push(url + "/deliverable/" + id);
                          setState({ ...state, isNew: false, editItem: item });
                        }}
                        onDelete={() => {
                          setState({ ...state, showDeleteN: "deliverable", deleteId: id, deleteIndex: index });
                        }} />
                    }}
                  />

                  {showDeleteN == "deliverable" ? (
                    <DeleteConfirm
                      onCancel={() => {
                        setState({ ...state, showDeleteN: null });
                      }}
                      onConfirm={() => {
                        deleteServiceItem({ type: "Deliverable", service, userService, serviceVersion, deleteIndex })
                        setState({ ...state, showDeleteN: null });
                      }}
                    />
                  ) : null}
                </Section>
              ) : null}

              {tabValueM == "trainers" ? <RenderTrainers
                service={service}
                userService={userService}
                serviceVersion={serviceVersion} /> : null}

              {tabValueM == "tasks" ? (
                <RenderServiceTasks
                  userService={userService}
                  serviceVersion={serviceVersion}
                  historicalServiceVersion={historicalServiceVersion}
                  preReqItems={preReqItems}
                  service={service}
                  onButtonPress={() => {
                    doAddTask();
                  }}
                  onEdit={(item) => {
                    setState({ ...state, isNew: false, editItem: item });
                    history.push(url + "/task/" + item.id);
                  }}
                  onUpdate={() => {
                    setState({ ...state });
                  }}
                />
              ) : null}

              {tabValueM == "sub-services" ? (
                <Section>
                  <HeaderText
                    subHeader
                    label={(subServices.length > 0 ? plural(subServices.length, "Sub Service") : "Sub Services") + ":"}
                    description="Add additional services required for completion of this main service."
                    onButtonPress={!historicalServiceVersion ? () => {
                      history.push(url + "/sub-service");
                      setState({ ...state, isNew: true, editItem: { deliveryType: "standard", serviceOptionIds: {} } });
                    } : null}
                  />
                  <SortableList
                    disabled={historicalServiceVersion}
                    emptyRender={<NoRecords label="No sub-services added." />}
                    items={subServices}
                    onChange={(list) => {
                      serviceVersion.subServices = list;
                      dL.saveServiceSubServices({ serviceVersion });
                      setState({ ...state });
                    }}
                    renderItem={(subServiceVersion, index) => {
                      const { deliveryType, delayOptions, actualVersionType, id, subService, service, requiredPreServices, userService, servicePackage, actualServiceVersion, packageId, serviceOptionIds } = subServiceVersion;

                      const path = "service[" + subService.id + "]"

                      const { name, shortDescription, businessRole, skillLevel } = actualServiceVersion

                      const circularIds = {}
                      circularIds[rootService.id] = true

                      const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

                      const getPriceDays = function ({ serviceVersion }) {
                        const { deliveryType, packageId, serviceOptionIds } = subServiceVersion

                        return getFinalPriceDays2({ rootPriceMap: rootServiceVersion.priceMap, rootPath: path, serviceVersion, deliveryType, packageId, serviceOptionIds })
                      }

                      const { price: total, days, startDayIndex } = getDataFromPriceMap({ priceMap: rootServiceVersion.priceMap, path })
                      const startDate = Moment().businessAdd(startDayIndex)
                      const doneDate = Moment(startDate).businessAdd(days)

                      return (
                        <BoxItem
                          verticalIcons
                          onPress={() => {
                            setState({ ...state, subServiceVisible: subServiceVersion });
                          }}
                          name={name}
                          nameSmall={servicePackage ? "Bundled Package: " + servicePackage.name : ""}
                          description={shortDescription}
                          rightRender={
                            <Price price={total} days={days} />
                          }
                          hasMove
                          onEdit={() => {
                            history.push(url + "/sub-service/" + id);
                            setState({ ...state, isNew: false, editItem: subServiceVersion });
                          }}
                          onDelete={() => {
                            setState({ ...state, showDeleteN: "sub-service", deleteId: id, deleteIndex: index });
                          }}
                          render={
                            <View>
                              <RenderWorkConfig subServiceVersion={subServiceVersion} />

                              {subServices.length > 0 ? <View top={15}>
                                <ItemExpandSection label={plural(subServices.length, "Sub-Service") + ":"}>
                                  {subServices.map(item => {
                                    const { id } = item

                                    return <DisplaySubServiceItem
                                      key={id}
                                      circularIds={{ ...circularIds }}
                                      rootServiceVersion={rootServiceVersion}
                                      historicalServiceVersion={historicalServiceVersion}
                                      subServiceVersion={item}
                                      path={path}
                                      onUpdateOverrides={() => {
                                        calculatePriceMap()
                                        setState({ ...state })
                                      }} />
                                  })}
                                </ItemExpandSection>
                              </View> : null}
                            </View>
                          }>

                          <RenderBusinessRole skillLevel={skillLevel} businessRole={businessRole} />
                          <RenderStartDoneDates deliveryType={deliveryType} startDate={startDate} doneDate={doneDate} />
                          <RenderDelayOptions2 delayOptions={delayOptions} />

                          <RenderServiceNeedsUpdate
                            configData={subServiceVersion}
                            usingVersion={actualServiceVersion}
                            currentVersion={actualVersionType == "worker" ? userService.currentVersion : service.currentVersion}
                            price={total}
                            days={days}
                            onGetVersionPriceDays={(serviceVersion) => {
                              return getPriceDays({ serviceVersion })
                            }}
                            onUpdateNewest={() => {
                              history.push(url + "/sub-service/" + id);
                              setState({ ...state, isNew: false, editItem: subServiceVersion });
                            }}
                          />
                          <RenderIsPublished2 item={subServiceVersion} serviceVersion={serviceVersion} />

                          {session.user.testMode ? <TextLine size={10} top={10} label="Path:" value={path} /> : null}

                          <RenderArrayItems
                            label="Pre-Required:"
                            value={requiredPreServices}
                            list={preReqItems}
                            onGetText={(item) => {
                              const { task, subService } = item;
                              if (task) {
                                return task.name;
                              } else if (subService) {
                                return subService.service.name;
                              }
                            }}
                          />
                          <RenderLimitOptions service={service} userService={userService} serviceVersion={rootServiceVersion} item={subServiceVersion} />
                        </BoxItem>
                      );
                    }}
                  />

                  {showDeleteN == "sub-service" ? (
                    <DeleteConfirm
                      onCancel={() => {
                        setState({ ...state, showDeleteN: null });
                      }}
                      onConfirm={() => {
                        deleteServiceItem({ type: "SubService", service, userService, serviceVersion, deleteIndex })
                        calculatePriceMap()
                        setState({ ...state, showDeleteN: null });
                      }}
                    />
                  ) : null}
                </Section>
              ) : null}

              {tabValueM == "inputs" ? (
                <Section>
                  <HeaderText
                    subHeader
                    label={(inputs.length > 0 ? plural(inputs.length, "Question Form") : "Question Forms") + ":"}
                    description="Question forms that need to be answered before starting this service."
                    onButtonPress={!historicalServiceVersion ? () => {
                      history.push(url + "/input");
                      setState({ ...state, isNew: true, editItem: {} });
                    } : null}
                  />
                  <SortableList
                    disabled={historicalServiceVersion}
                    emptyRender={<NoRecords label="No questions forms added." />}
                    items={inputs}
                    onChange={(list) => {
                      serviceVersion.inputs = list;
                      dL.saveServiceInputs({ serviceVersion });
                      setState({ ...state });
                    }}
                    renderItem={(item, index) => {
                      const { icon, name, description, id, similarServices, formItems } = item;

                      return (
                        <BoxItem

                          icon={icon}
                          name={name}
                          nameSmall={plural(formItems ? formItems.length : 0, "question")}
                          description={description}
                          hasMove
                          onPress={() => {
                            setState({ ...state, inputVisible: item });
                          }}
                          onEdit={() => {
                            history.push(url + "/input/" + item.id);
                            setState({ ...state, isNew: false, editItem: item });
                          }}
                          onDelete={() => {
                            setState({ ...state, showDeleteN: "input", deleteId: id, deleteIndex: index });
                          }}>
                          {similarServices && similarServices.length > 0 ? (
                            <View style={{ marginTop: 25 }}>
                              <TextLine bold>Helper Services:</TextLine>

                              <ul>
                                {similarServices.map((similarService) => {
                                  const { id, shortDescription, name } = similarService;

                                  return (
                                    <li key={id}>
                                      <LinkButton2
                                        onPress={() => {
                                          setState({ ...state, similarServiceView: similarService });
                                        }}
                                        description={shortDescription}>
                                        {name}
                                      </LinkButton2>
                                    </li>
                                  );
                                })}
                              </ul>
                            </View>
                          ) : null}

                          <RenderLimitOptions service={service} userService={userService} serviceVersion={rootServiceVersion} item={item} />
                          <RenderIsPublished item={item} serviceVersion={serviceVersion} />
                        </BoxItem>
                      );
                    }}
                  />

                  {showDeleteN == "input" ? (
                    <DeleteConfirm
                      onCancel={() => {
                        setState({ ...state, showDeleteN: null });
                      }}
                      onConfirm={() => {
                        deleteServiceItem({ type: "Input", service, userService, serviceVersion, deleteIndex })
                        setState({ ...state, showDeleteN: null });
                      }}
                    />
                  ) : null}
                </Section>
              ) : null}

              {tabValueM == "versions" ? (
                <RenderServiceVersions
                  description="See the history of service versions submitted to you."
                  historicalServiceVersion={historicalServiceVersion}
                  draftVersion={draftVersion}
                  service={service}
                  userService={userService}
                  serviceVersions={serviceVersions}
                  forkedUserServices={forkedUserServices}
                  onSelect={(serviceVersion) => {
                    if (serviceVersion.versionNum == draftVersion.versionNum) {
                      setState({ ...state, tabValueM: "tasks", historicalServiceVersion: null });
                    } else {
                      return dL.getServiceVersion2({ serviceVersionId: serviceVersion.id })
                        .then(function (serviceVersion) {
                          setState({ ...state, tabValueM: "tasks", historicalServiceVersion: serviceVersion });
                        });

                    }
                  }}
                />
              ) : null}

              {tabValueM == "workers" ? (
                <Section>
                  <HeaderText
                    subHeader
                    label="Preferred Workers:"
                    description="Add a list of preferred workers for this service." />

                  <View bottom={15}>
                    {!workerUserServices || workerUserServices.length == 0 ? (
                      <NoRecords label="No preferred workers added." />
                    ) : (
                      workerUserServices.map(({ userService }, index) => {
                        const { user } = userService;
                        const { userRoles } = user;

                        //const userRole = userRoles.find(item => item.businessRole.id == service.businessRole.id);
                        //const { skillLevel } = userRole;

                        const { baseBillRate } = getWorkerRate({ businessRole, assignedTo: user, skillLevel });

                        return (
                          <BoxRowItem>
                            <FlexRow>
                              <FlexExpand>
                                <UserItem user={user} />
                                <View style={{ marginLeft: 40 }}>
                                  <TextLine grey size={14} top={-4} value={"Skill Level: " + getLabelItem(skillLevels, skillLevel)} />
                                  <TextLine grey size={14} top={-4} value={"Hourly Rate: " + money(baseBillRate)} />
                                </View>
                              </FlexExpand>

                              <TrashIcon
                                style={{ marginLeft: 15 }}
                                onPress={() => {
                                  workerUserServices.splice(index, 1);
                                  dL.saveServiceWorkerServices({ service })
                                  setState({ ...state });
                                }}
                              />
                            </FlexRow>
                          </BoxRowItem>
                        );
                      })
                    )}
                  </View>

                  <MicroButton
                    onPress={() => {
                      setState({ ...state, workerVisible: true });
                    }}
                    label="Add Consultant"
                  />

                  {workerVisible ? (
                    <SelectWorkerDialog
                      serviceId={service.id}
                      onCancel={() => {
                        setState({ ...state, workerVisible: false });
                      }}
                      onSelect={({ user }) => {
                        dL.getUserService({ userId: user.id, serviceId: service.id }).then(function (userService) {
                          if (!userService) {
                            alert("Consultant can't be added. Consultant service not found.")
                            return
                          }
                          if (!service.workerUserServices) {
                            service.workerUserServices = [];
                          }

                          service.workerUserServices.push({ userService, userServiceVersion: userService.currentVersion });
                          dL.saveServiceWorkerServices({ service })

                          setState({ ...state, service, workerVisible: false });
                        });
                      }}
                    />
                  ) : null}
                </Section>
              ) : null}

              {tabValueM == "options" ? (
                <Section>
                  <HeaderText
                    subHeader
                    label={(options.length ? plural(options.length, "Service Option") : "Service Options") + ":"}
                    description="Manage multiple service options to add optional tasks, sub-services and deliverables based on if an options is selected for delivery."
                    onButtonPress={!historicalServiceVersion ? () => {
                      history.push(url + "/option");
                      setState({ ...state, isNew: true, editItem: {} });
                    } : null}
                  />

                  <SortableList
                    disabled={historicalServiceVersion}
                    emptyRender={<NoRecords label="No service options added." />}
                    items={options}
                    onChange={(list) => {
                      serviceVersion.options = list;
                      dL.saveServiceOptions({ serviceVersion });
                      setState({ ...state });
                    }}
                    renderItem={(item, index) => {
                      const { icon, isFixedPriced, hours, name, description, id, price, deliveryDays } = item;

                      const { dels, workHours, hourlyRate, standardCost, subServiceCost } = getServiceOptionData({ service, userService, serviceVersion, serviceOption: item });

                      var ssCostStr = "";
                      if (subServiceCost) {
                        ssCostStr = " + " + money(subServiceCost);
                      }

                      return (
                        <BoxItem icon={icon} name={name} description={description} hasMove
                          rightRender={
                            <Price price={isFixedPriced ? price : (standardCost + subServiceCost)} days={deliveryDays} deliveryText="+" priceText="+">
                              {hours ? <TextLine color="grey" top={4} value={"+" + hours + " hrs"} /> : null}
                            </Price>
                          }
                          onPress={() => {
                            setState({ ...state, optionVisible: item });
                          }}
                          onEdit={() => {
                            history.push(url + "/option/" + item.id);
                            setState({ ...state, isNew: false, editItem: item });
                          }}
                          onDelete={() => {
                            setState({ ...state, showDeleteN: "option", deleteId: id, deleteIndex: index });
                          }}>
                          <FlexRow>
                            <TextLine size={14} value={"Work Hours: " + plural(workHours, "hr")} spacer />
                            <Hide>
                              <TextLine size={14} value={"Worker Rate: " + money(hourlyRate) + "/hr"} spacer />
                              <TextLine size={14} value={"Standard Cost: " + money(standardCost) + ssCostStr} />
                            </Hide>
                          </FlexRow>

                          { dels.length > 0 ? <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} /> : null}

                          <RenderLimitOptions service={service} userService={userService} serviceVersion={rootServiceVersion} item={item} />
                          <RenderIsPublished item={item} serviceVersion={serviceVersion} />
                        </BoxItem>
                      );
                    }}
                  />

                  {showDeleteN == "option" ? (
                    <DeleteConfirm
                      onCancel={() => {
                        setState({ ...state, showDeleteN: null });
                      }}
                      onConfirm={() => {
                        deleteServiceItem({ type: "Option", service, userService, serviceVersion, deleteIndex })
                        setState({ ...state, showDeleteN: null });
                      }}
                    />
                  ) : null}
                </Section>
              ) : null}

              {tabValueM == "packages" ? (
                enablePackages ? (
                  <Section>
                    <HeaderText
                      subHeader
                      label={(packages.length ? plural(packages.length, "Bundled Package") : "Bundled Packages") + ":"}
                      description="Manage different bundled packages that combine tasks and sub-services with a discounted price for the package."
                      onButtonPress={!historicalServiceVersion ? () => {
                        doAddBundlePackage();
                      } : null}
                    />
                    <SortableList
                      disabled={historicalServiceVersion}
                      emptyRender={<NoRecords label="No bundled packages added." />}
                      items={packages}
                      onChange={(list) => {
                        serviceVersion.packages = list;
                        dL.saveServicePackages({ serviceVersion });
                        setState({ ...state });
                      }}
                      renderItem={(item, index) => {
                        const { icon, isFixedPriced, name, shortDescription, id, price, deliveryDays } = item;

                        const { dels, opts, optOpts, workHours, hourlyRate, standardCost, subServiceCost } = getServicePackageData({ service, userService, serviceVersion, servicePackage: item });

                        var ssCostStr = "";
                        if (subServiceCost) {
                          ssCostStr = " + " + money(subServiceCost);
                        }

                        return (
                          <BoxItem icon={icon} name={name} description={shortDescription}
                            rightRender={<Price price={isFixedPriced ? price : standardCost + subServiceCost} days={deliveryDays} />}
                            hasMove
                            onPress={() => {
                              setState({ ...state, packageVisible: item });
                            }}
                            onEdit={() => {
                              history.push(url + "/package/" + id);
                              setState({ ...state, isNew: false, editItem: item });
                            }}
                            onDelete={() => {
                              setState({ ...state, showDeleteN: "package", deleteId: id, deleteIndex: index });
                            }}>
                            <FlexRow>
                              <TextLine size={14} value={"Work Hours: " + plural(workHours, "hr")} spacer />
                              <Hide>
                                <TextLine size={14} value={"Worker Rate: " + money(hourlyRate) + "/hr"} spacer />
                                <TextLine size={14} value={"Standard Cost: " + money(standardCost) + ssCostStr} />
                              </Hide>
                            </FlexRow>

                            <FlexRow top={12} alignTop>
                              <DisplayDeliverables deliverables={dels} />
                              {dels.length > 0 ? <View style={{ width: 35 }} /> : null}
                              <DisplayOptions service={service} options={opts} otherOptions={optOpts} hidePrice={!isFixedPriced} />
                            </FlexRow>

                            <RenderIsPublished item={item} serviceVersion={serviceVersion} />
                          </BoxItem>
                        );
                      }}
                    />

                    {showDeleteN == "package" ? (
                      <DeleteConfirm
                        onCancel={() => {
                          setState({ ...state, showDeleteN: null });
                        }}
                        onConfirm={() => {
                          deleteServiceItem({ type: "Package", service, userService, serviceVersion, deleteIndex })
                          setState({ ...state, showDeleteN: null });
                        }}
                      />
                    ) : null}
                  </Section>
                ) : null
              ) : null}
            </FlexExpand>

            <View style={{ paddingLeft: 35 }}>
              {tabValueM2 == "checklist" ? <React.Fragment>
                {sectionId == "worker" ? <RenderChecklist label="Steps to publish service:" description="Complete this checklist to publish this service to your service catalog." items={checklistItems} /> : sectionId == "admin" ? <RenderChecklist label="Service Actions:" description="Some service actions available or required on this service." items={checklistItems} /> : null}
              </React.Fragment> : null}

              {tabValueM2 == "stats" ? <RenderStats forkedUserServices={forkedUserServices} workerUserServices={workerUserServices} service={service} userService={userService} serviceVersion={serviceVersion} /> : null}

              {tabValueM2 == "alerts" ?
                <Section style={{ minWidth: 350, maxWidth: 350 }}>
                  <HeaderText subHeader label="Alerts:" style={{ marginBottom: 0 }} />

                  {renderAllAlerts()}
                </Section>
                : null}
            </View>
          </FlexRow>
        </View>

        {dialogType == "delivery" ? (
          <EditServiceDeliveryOptions
            service={service}
            serviceVersion={serviceVersion}
            onCancel={onDialogClose}
            onSave={(value) => {
              const item = (userService ? userService : service)
              item.draftVersion = Object.assign(item.draftVersion, value)
              setState({ ...state, service, userService, dialogType: null });
            }}
          />
        ) : dialogType == "price" ? (
          <EditServicePriceOptions
            service={service}
            serviceVersion={serviceVersion}
            onCancel={onDialogClose}
            onSave={(value) => {
              const item = (userService ? userService : service)
              item.draftVersion = Object.assign(item.draftVersion, value)
              setState({ ...state, service, userService, dialogType: null });
              dL.doUpdateService({ service, userService, serviceVersion });
            }}
          />
        ) : dialogType == "options" ? (
          <EditServiceOptions
            service={service}
            serviceVersion={serviceVersion}
            onCancel={onDialogClose}
            onSave={(value) => {
              const item = (userService ? userService : service)
              item.draftVersion = Object.assign(item.draftVersion, value)
              setState({ ...state, service, userService, dialogType: null });
            }}
          />
        ) : dialogType == "visibility" ? (
          <EditServiceVisibility
            service={service}
            serviceVersion={serviceVersion}
            userService={userService}
            onCancel={onDialogClose}
            onSave={(data) => {
              setState({ ...state, dialogType: null, service: Object.assign(service, data.service), userService: Object.assign(userService, data.userService) });
              setRefresh(new Date())
            }}
          />
        ) : dialogType == "admin" ? (
          <EditServiceAdmin
            service={service}
            serviceVersion={serviceVersion}
            userService={userService}
            onCancel={onDialogClose}
            onSave={(data) => {
              setState({ ...state, dialogType: null, service: Object.assign(service, data.service), dialogType: null });
            }}
          />
        ) : null}

        {similarServiceView ? (
          <ServiceViewDialog
            serviceId={similarServiceView.id}
            onClose={() => {
              setState({ ...state, similarServiceView: null });
            }}
          />
        ) : null}

        {packageVisible ? (
          <ViewServicePackage
            service={service}
            item={packageVisible}
            onClose={() => {
              setState({ ...state, packageVisible: null });
            }}
          />
        ) : taskVisible ? (
          <ViewServiceTask
            item={taskVisible}
            onClose={() => {
              setState({ ...state, taskVisible: null });
            }}
          />
        ) : optionVisible ? (
          <ViewServiceOption
            service={service}
            item={optionVisible}
            onClose={() => {
              setState({ ...state, optionVisible: null });
            }}
          />
        ) : subServiceVisible ? (
          <ViewSubService
            service={service}
            item={subServiceVisible}
            onClose={() => {
              setState({ ...state, subServiceVisible: null });
            }}
          />
        ) : inputVisible ? (
          <ViewServiceInput
            item={inputVisible}
            value={inputVisible}
            onClose={() => {
              setState({ ...state, inputVisible: null });
            }}
          />
        ) : null}

        {deliverableVisible ? (
          <ViewServiceDeliverable
            item={deliverableVisible}
            onClose={() => {
              setState({ ...state, deliverableVisible: null });
            }}
          />
        ) : null}
      </Route>
    </Switch>
  );
}

export function RenderWorkConfig({ subServiceVersion }) {
  const { workerUserServices, userService } = subServiceVersion;

  if (!userService) {
    return <View />
  }

  return <View>
    {userService ? <BoxItem >
      <UserItem label="Assigned Consultant:" user={userService.user} />
    </BoxItem> : null}

    {workerUserServices && workerUserServices.length > 0 ? <TextLine size={12} top={10} value={plural(workerUserServices.length, "additional preferred consultant")} /> : null}
  </View>
}

function RenderBusinessRole({ businessRole, skillLevel }) {
  return businessRole ? <TextLine grey size={14} top={4} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : <View />
}

export function RenderStartDoneDates({ startDate, doneDate, deliveryType }) {
  return <FlexRow top={4}>
    <TextLine right={15} size={14} value={"Start: " + Moment(startDate).format("M/D/YYYY")} />
    <TextLine right={15} size={14} value={(deliveryType == "fast" ? "FAST " : "") + "Delivery: " + Moment(doneDate).format("M/D/YYYY") + " (" + plural(Moment(doneDate).diff(startDate, "days"), "total day") + ")"} />
  </FlexRow>
}

function DisplaySubServiceItem({ circularIds: startC1, rootServiceVersion, historicalServiceVersion, subServiceVersion: d1, path: startPath, onUpdateOverrides, displayTestMode, rtn }) {
  const [state, setState] = useState({ isLoading: true });
  const { subServiceVersion, isLoading, visibleConfig, subServiceVisible, actualServiceVersion } = state;

  const { subService } = d1
  const path = startPath + ".service[" + subService.id + "]"
  const { masterOverrides, priceMap } = rootServiceVersion
  const mapItem = priceMap.find(item => item.path == path)

  const overrideRecord = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")
  const configData = overrideRecord ? overrideRecord : d1

  useEffect(() => {
    const { actualServiceVersionId } = mapItem.item

    var actualServiceVersion
    dL.getSubServiceVersion({ subServiceVersionId: d1.id }).then(function (subServiceVersion) {
      return dL.getServiceVersion2({ serviceVersionId: actualServiceVersionId })
        .then(function (_actualServiceVersion) {
          actualServiceVersion = _actualServiceVersion

          const record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

          if (record) {
            return loadSubServiceVersion_Worker({ subServiceVersion: record })
          }
        }).then(function () {
          setState({ ...state, actualServiceVersion, subServiceVersion, isLoading: false })
        })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const isOverride = overrideRecord != null
  const { deliveryType, delayOptions, isRemoved, actualVersionType, serviceId, packageId, serviceOptionIds } = configData

  const isCircularRef = startC1[serviceId] != null
  const circularIds = { ...startC1 }
  circularIds[serviceId] = true

  const getCopyObj = function () {
    const rtn = Object.assign({}, configData)
    delete rtn.id
    rtn.delayOptions = Object.assign({}, rtn.delayOptions)
    return rtn
  }

  const { businessRole, skillLevel, name, icon, shortDescription, service, userService } = actualServiceVersion
  const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

  const getPriceDays = function ({ serviceVersion }) {
    const itemG = rtn.setOverride({ path, masterOverride: { ...mapItem.item, actualServiceVersion: serviceVersion } })
    const days = itemG.getTotalDeliveryDays()
    const total = itemG.getTotalPrice()
    return { total, days }
  }

  const doReset = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    if (record) {
      const index = masterOverrides.findIndex(item => item.id == record.id)
      masterOverrides.splice(index, 1)
      dL.removeMasterOverrideRecord({ recordId: record.id, rootServiceVersion })
    }

    onUpdateOverrides()
    setState({ ...state, visibleConfig: null })
  }

  const getOverrideRecord = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    const isNew = !record
    if (isNew) {
      record = createNewOverrideRecord()
      record._isNew = true
    }

    return record
  }

  const createNewOverrideRecord = function () {
    const record = getCopyObj()
    record.id = utils.guid()
    record.rootService = rootServiceVersion.service
    record.rootServiceVersion = rootServiceVersion
    record.subService = subService
    record.subServiceVersion = subServiceVersion
    record.path = path
    record.overrideType = "sub-service"
    masterOverrides.push(record)
    return record
  }

  const saveOverrideRecord = function ({ record }) {
    dL.saveMasterOverrideRecord({ isNew: record._isNew, recordId: record.id, data: record })
    onUpdateOverrides()
  }

  const itemG = rtn.getItem(path)
  const totalPrice = itemG.getTotalPrice()
  const totalDeliveryDays = itemG.getTotalDeliveryDays()
  const deliveryDays = itemG.getServiceDeliveryDays()
  const startDate = itemG.getStartDate()
  const doneDate = itemG.getDoneDate()

  return <React.Fragment>
    {isCircularRef ? <TextLine color="red" size={14} bold value="The below service is a CIRCULAR REFERENCE: It will removed automatically." /> : null}

    <BoxItem strikeThrough={isCircularRef || isRemoved} name={name} icon={icon} description={shortDescription} onPress={() => {
      setState({ ...state, subServiceVisible: true });
    }} rightRender={
      <Price strikeThrough={isCircularRef || isRemoved} price={totalPrice} days={totalDeliveryDays} />
    } render={
      !isCircularRef ?
        <View>
          <RenderDelayOptions2 delayOptions={delayOptions} />

          {!isRemoved && actualVersionType == "worker" ?
            <RenderWorkConfig subServiceVersion={subServiceVersion} />
            : null}

          <FlexRow>
            {isRemoved ? <TextLine top={10} size={12} color="red" bold value="Service Removed" spacer /> : null}

            {userService && !userService.isWorkerPublic ? <TextLine top={10} size={12} value="Private Service (not configurable)" /> : null}

            {!historicalServiceVersion ?
              <SelectOptions
                options={[!isRemoved ? {
                  label: "Edit Overrides", value: "config"
                } : null, isOverride ? {
                  label: "Remove Overrides", value: "remove-overides"
                } : null, !isRemoved ? {
                  label: "Remove Service", value: "remove"
                } : {
                  label: "Add Service Back", value: "add"
                }]}
                onChange={selItem => {
                  const { value } = selItem
                  if (value == "config") {
                    setState({ ...state, visibleConfig: true })
                  } else if (value == "remove") {
                    const record = getOverrideRecord()

                    record.isRemoved = true

                    saveOverrideRecord({ record })

                    setState({ ...state, visibleConfig: null })
                  } else if (value == "add") {
                    doReset()
                  } else if (value == "remove-overides") {
                    doReset()
                  }
                }}>
                <View style={{ marginTop: 10, marginRight: 10 }}>
                  <TextLine size={12} value="Edit Configuration" underline />
                </View>
              </SelectOptions>
              : null}

            {isOverride && !isRemoved ? <TextLine top={10} size={12} value="(has overrides)" /> : null}
          </FlexRow>

          {!isRemoved && subServices.length > 0 ?
            <View top={15}>
              <ItemExpandSection label={plural(subServices.length, "Sub-Service") + ":"}>
                {subServices.map(subServiceVersion => {
                  const { id } = subServiceVersion

                  return <DisplaySubServiceItem
                    key={id}
                    rtn={rtn}
                    displayTestMode={displayTestMode}
                    circularIds={{ ...circularIds }}
                    rootServiceVersion={rootServiceVersion}
                    historicalServiceVersion={historicalServiceVersion}
                    onUpdateOverrides={onUpdateOverrides}
                    subServiceVersion={subServiceVersion}
                    path={path} />
                })}
              </ItemExpandSection>
            </View>
            : null}
        </View> : null
    }>
      <RenderBusinessRole skillLevel={skillLevel} businessRole={businessRole} />
      <RenderStartDoneDates deliveryType={deliveryType} startDate={startDate} doneDate={doneDate} />

      {session.user.testMode ? <TextLine size={10} top={10} label="Path:" value={path} /> : null}

      {!isCircularRef && !isRemoved ?
        <RenderServiceNeedsUpdate
          style={{ marginTop: 10 }}
          configData={configData}
          usingVersion={actualServiceVersion}
          currentVersion={actualVersionType == "worker" ? userService.currentVersion : service.currentVersion}
          price={totalPrice}
          days={totalDeliveryDays}
          onGetVersionPriceDays={(serviceVersion) => {
            return getPriceDays({ serviceVersion })
          }}
          onUpdateNewest={() => {
            setState({ ...state, visibleConfig: true })
          }}
        /> : null}
    </BoxItem>

    {subServiceVisible ? (
      <ViewSubService
        hidePrice={true}
        item={subServiceVersion}
        onClose={() => {
          setState({ ...state, subServiceVisible: null });
        }}
      />
    ) : null}

    {visibleConfig ? <EditServiceSubServiceConfig
      rtn={rtn}
      rootServiceVersion={rootServiceVersion}
      path={path}
      editItem={getCopyObj()}
      onCancel={() => {
        setState({ ...state, visibleConfig: null })
      }}
      hasOverride={isOverride}
      onReset={() => {
        doReset()
      }}
      onSave={(newRecord) => {
        const record = getOverrideRecord()
        Object.assign(record, newRecord)
        record.isRemoved = false
        saveOverrideRecord({ record })
        setState({ ...state, visibleConfig: null })
      }} /> : null}
  </React.Fragment>
}

function RenderDeliverable({ item, service, userService, serviceVersion, onPress, onEdit, onDelete }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [state, setState] = useState({})
  const { trainingNoteCount, icon, name, description, deliverableType, formItems } = item;

  return <BoxItem
    hasMove
    horiontalIcons
    icon={icon}
    name={name}
    nameSmall={" (" + getLabelItem(deliverableTypes, deliverableType) + ") " + (deliverableType == "form" ? "(" + plural(formItems ? formItems.length : 0, "question") + ")" : "")}
    description={description}
    onPress={onPress}
    onEdit={onEdit}
    onDelete={onDelete}
    icons={
      <TrainingIcon count={trainingNoteCount} tooltip="Add a training note to this deliverable." onPress={() => {
        history.push(`${url}/training-note/type/deliverable/${item.id}/${item.deliverable.id}`)
      }} />}>
    <RenderLimitOptions service={service} userService={userService} serviceVersion={serviceVersion} item={item} />
  </BoxItem>
}

function RenderServiceVersions({ service, forkedUserServices, draftVersion, historicalServiceVersion, serviceVersions, onSelect, description }) {
  const { ownerUserService } = service
  return (
    <React.Fragment>
      {forkedUserServices && forkedUserServices.length > 0 ?
        <Section>
          <HeaderText subHeader label="Forked Versions:" description="These versions of user service exists and are forked." />

          {forkedUserServices.map((userService) => {
            const { minPrice, maxPrice, actualDeliveryDays, id, isWorkerPublic, user, forkedFromServiceVersion, currentVersion } = userService;

            return (
              <BoxRowItem key={id}>
                <FlexRow>
                  <FlexExpand>
                    <UserItem label="Created By:" user={user}>
                      {ownerUserService && ownerUserService.id == id ? <TextLine size={12} value={"Service Master"} /> : null}
                    </UserItem>
                  </FlexExpand>
                  <View alignRight>
                    <TextLine value={isWorkerPublic ? "Public" : "Private"} />
                    <TextLine label="Forked from Version:" value={"#" + forkedFromServiceVersion.versionNum} />
                    <TextLine label="Current Version:" value={"#" + currentVersion.versionNum} />
                    <TextLine label="Last Updated:" value={Moment(currentVersion.createdAt).format("M/D/YY")} />
                  </View>

                  <View style={{ width: 125 }}>
                    <Price priceText2={moneyRange(minPrice, maxPrice)} days={actualDeliveryDays} />
                  </View>
                </FlexRow>
              </BoxRowItem>
            );
          })}
        </Section> : null}

      <Section>
        <HeaderText subHeader label="Service Versions:" description={description} />

        {serviceVersions.map((item) => {
          const { id } = item;
          return (
            <RenderServiceVersion
              historicalServiceVersion={historicalServiceVersion}
              draftVersion={draftVersion}
              key={id}
              item={item}
              onSelect={() => {
                onSelect(item);
              }}
            />
          );
        })}
      </Section>
    </React.Fragment>
  );
}

function RenderServiceVersion({ historicalServiceVersion, draftVersion, item, onSelect, onRemove }) {
  const { versionNum, createdAt, createdBy, minPrice, maxPrice, actualDeliveryDays } = item;

  return (
    <BoxRowItem>
      <FlexRow>
        <FlexExpand>
          <FlexRow>
            <TextLine size={22} value={"#" + versionNum} />
            <UserItem label="Created By:" user={createdBy} style={{ marginLeft: 12 }} />
          </FlexRow>
        </FlexExpand>

        <View right={15}>
          <Price alignRight priceText2={moneyRange(minPrice, maxPrice)} days={actualDeliveryDays} />
          <DateTimeItem alignRight value={createdAt} fromNow />
        </View>

        {onSelect ? (
          <View style={{ width: 55 }}>
            {(!historicalServiceVersion && versionNum != draftVersion.versionNum) || (historicalServiceVersion && historicalServiceVersion.versionNum != versionNum) ? (
              <MicroButton
                description="Click to go view this service version."
                onPress={() => {
                  onSelect(item);
                }}
                label="View"
              />
            ) : null}
          </View>
        ) : onRemove ? (
          <MicroButton
            description="Click to go back to the most current service version."
            onPress={() => {
              onRemove(item);
            }}
            label="Remove"
          />
        ) : null}
      </FlexRow>
    </BoxRowItem>
  );
}

function RenderStats({ workerUserServices, forkedUserServices, sectionId, service, userService, serviceVersion }) {
  const { ownerUserService } = service
  const { user, createdBy, lastReviewedAt, lastReviewedVersionNum, currentVersion, team } = userService ? userService : service

  /*
  -transparency index for services based in breakdown of tasks (tasks at 1 hour level is most transparent and 20 hour level least) with exponential curve
  -scalability index: delivery days versus actual hours for service (runs for base/bundles and for each service options or runs for every combination of service options)
  -flexibility index: based on total hours in service (minimum scalability at 40 hours maximum at 2 hours)
  */

  const getTransparencyIndex = function () {
    const { tasks } = serviceVersion
    var score = 0
    const minScoreHours = 20, maxScoreHours = 2
    tasks.forEach(task => {
      const { hours } = task
      const p1 = 100 / (minScoreHours - maxScoreHours)
      const diff = (hours - maxScoreHours)
      score = 100 - (diff * p1)
    })
    return Math.round(score / tasks.length)
  }

  const getScalabilityIndex = function () {
    const { deliveryDays } = serviceVersion
    const tasks = getTasksForService({ service, serviceVersion });
    const workHours = getWorkHours({ tasks });
    const { minDeliveryDays } = getMinDeliveryDays({ workerHours: workHours });

    const score = 100 - Math.round((deliveryDays - minDeliveryDays) / minDeliveryDays)
    return score < 0 ? 0 : score
  }

  const getFlexibilityIndex = function () {
    const tasks = getTasksForService({ service, serviceVersion });
    const hours = getWorkHours({ tasks });
    const minScoreHours = 40, maxScoreHours = 2
    const p1 = 100 / (minScoreHours - maxScoreHours)
    const diff = (hours - maxScoreHours)
    const score = Math.round(100 - (diff * p1))
    return score
  }

  return <Section style={{ maxWidth: 350 }}>
    <HeaderText subHeader label="Service Stats:" style={{ marginBottom: 0 }} />

    <StatItem label="Transparency Index:" value={getTransparencyIndex() + " of 100"} description="Reduce the work hours per task to increase execution transparency. Target maximum of 1-2 hours per task for highest score." />
    <StatItem label="Scalability Index:" value={getScalabilityIndex() + " of 100"} description="Reduce delivery days to better match hours of work to increase scalability of the service. Target minimum of 6 hours of work per delivery day for highest score." />
    <StatItem label="Flexibility Index:" value={getFlexibilityIndex() + " of 100"} description="Reduce total work hours for the service to create a more flexible service. Target maximum of 40 hours per work per service but closer to 2-4 hours for a higher score." />

    {sectionId == "admin" ? <React.Fragment>
      <StatItem label="Current Version:" value={"#" + currentVersion.versionNum} />
      <StatItem label="Preferred Workers:" value={workerUserServices.length} />
      <StatItem label="Public User Services:" value={forkedUserServices.filter(item => item.isWorkerPublic).length} />
      <StatItem label="Available User Services:" value={forkedUserServices.length} />
      {lastReviewedAt ? <StatItem label="Last Reviewed:" value={Moment(lastReviewedAt).fromNow()} /> : null}
      {lastReviewedAt ? <StatItem label="Last Reviewed Version:" value={"#" + lastReviewedVersionNum} /> : null}
      {createdBy ? <StatItem label="Created By:"><UserItem user={createdBy} /></StatItem> : null}
      {user ? <StatItem label="Created By:"><UserItem user={user} /></StatItem> : null}
    </React.Fragment>
      : null}

    {createdBy ? <StatItem label="Owner:"><UserItem user={createdBy} /></StatItem> : user ? <StatItem label="Owner:"><UserItem user={user} /></StatItem> : null}
    {ownerUserService ? <StatItem label="Service Owner:">
      <UserItem user={ownerUserService.user} />
      <TextLine size={12} value="This owner will update the service in the consulting buyer catalog." />
    </StatItem> : null}

    {team ? <StatItem label="Team:" value={team.name} /> : null}
  </Section>
}

const loadServiceVersion_View2 = function ({ serviceVersionId }) {
  return dL.getQuery("ServiceVersion")
    .include("tags")
    .include("primaryCategory")
    .include("secondaryCategory")
    .include("userRole")
    .get(serviceVersionId).then(function (obj) {
      return dL.loadServiceVersion(obj)
    })
}

const loadServiceVersion_View1 = function ({ serviceVersionId }) {
  return dL.getQuery("ServiceVersion")
    .include("tags")
    .include("primaryCategory")
    .include("secondaryCategory")
    .include("businessRole")
    .get(serviceVersionId).then(function (obj) {
      return dL.loadServiceVersion(obj)
    })
}

export function getForkData({ serviceId, userServiceId }) {
  if (userServiceId) {
    return dL.getQuery("UserService")
      .include("service")
      .get(userServiceId).then(function (obj) {
        const userService = dL.loadUserService(obj)

        const { currentVersion } = userService
        return loadServiceVersion_View2({ serviceVersionId: currentVersion.id }).then(function (currentVersion) {
          userService.currentVersion = currentVersion
          const { service } = userService
          const { businessRole } = currentVersion

          const model = currentVersion
          const userRoles = getUserRoles({ businessRoleId: businessRole.id })
          if (userRoles.length > 0) { model.userRole = userRoles[0] }

          return { userRoles, model, userService, service }
        })
      })
  } else {
    return dL.getQuery("Service")
      .get(serviceId).then(function (obj) {
        const service = dL.loadService(obj)

        const { currentVersion } = service
        return loadServiceVersion_View1({ serviceVersionId: currentVersion.id }).then(function (currentVersion) {
          service.currentVersion = currentVersion
          const { businessRole } = currentVersion

          const model = currentVersion
          const userRoles = getUserRoles({ businessRoleId: businessRole.id })
          if (userRoles.length > 0) { model.userRole = userRoles[0] }

          return { userRoles, model, service }
        })
      })
  }
}

const getUserRoles = function ({ businessRoleId }) {
  return session.user.userRoles.filter(item => item.businessRole.id == businessRoleId)
}

export function EditService() {
  const { teamId, serviceId, sectionId, action, userServiceId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    service: { status: "draft" },
    model: {},
    isLoading: true
  });
  const { userRoles, isLoading, model, userService, service } = state;

  useEffect(() => {
    const newState = { ...state }

    if (action == "fork") {
      //if userServiceId then get userServiceId, get the currentVersion (get the businessRole from this currentVersion)
      //else get the basic service, then get the currentVersion then get the businessRole from the currentVersion
      return getForkData({ serviceId, userServiceId }).then(function ({ model, service, userService, userRoles }) {
        setState({ isLoading: false, model, service, userService, userRoles });
      })
    } else if (serviceId) {
      //if userServiceId then get the userService.draftVersion and get the status from the userService
      //if not userServiceId then get the service.draftVersion and get the status from the service
      if (userServiceId) {
        return dL.getQuery("UserService")
          .include("service")
          .get(userServiceId).then(function (obj) {
            const userService = dL.loadUserService(obj)

            const { draftVersion } = userService
            return loadServiceVersion_View2({ serviceVersionId: draftVersion.id }).then(function (draftVersion) {
              userService.draftVersion = draftVersion
              const { service } = userService
              const { businessRole } = draftVersion

              newState.model = draftVersion
              newState.service = service
              newState.userService = userService
              newState.userRoles = getUserRoles({ businessRoleId: businessRole.id })
              newState.isLoading = false
              setState(newState);
            })
          })
      } else {
        return dL.getQuery("Service")
          .get(serviceId).then(function (obj) {
            const service = dL.loadService(obj)

            const { draftVersion } = service
            return loadServiceVersion_View1({ serviceVersionId: draftVersion.id }).then(function (draftVersion) {
              service.draftVersion = draftVersion
              newState.service = service
              newState.model = draftVersion
              newState.isLoading = false
              setState(newState);
            })
          })
      }
    } else {
      if (sectionId == "worker") {
        const userRoles = session.user.userRoles
        newState.userRoles = userRoles
      }
      newState.isLoading = false
      setState(newState);
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { status } = userService ? userService : service
  const { userRole, primaryCategory, secondaryCategory, icon, tags, skillLevel, businessRole, serviceCore, name, shortDescription, description, serviceType, companyStages, businessInitiatives } = model;

  const saveService = function () {
    if (!name) {
      alert("Must enter service name.");
      return;
    }
    if (action == "fork") {
      if (!userRole) {
        alert("Must enter business role.");
        return;
      }
    } else {
      if (!businessRole) {
        alert("Must enter business role.");
        return;
      }
      if (!skillLevel) {
        alert("Must enter skill level.");
        return;
      }
    }

    const team = teamId ? { id: teamId } : null

    if (action == "fork") {
      return dL.addUserService({ team, forkUserServiceId: userServiceId, service, data: model }).then(function ({ userServiceId }) {
        history.replace(`/${sectionId}/service/${serviceId}/${userServiceId}/admin-edit`);
      }).catch(function (err) {
        alert("Error: " + err);
      });

    } else if (!serviceId) {
      return dL.saveService(null, { ...service, ...model }).then(function ({ serviceId }) {
        if (sectionId == "admin") {
          history.replace(`/${sectionId}/service/${serviceId}/admin-edit`);
        } else {
          return dL.getService2(serviceId).then(function (service) {
            return dL.addUserService({ team, service, data: model }).then(function ({ userServiceId }) {
              return dL.getObj("Service", serviceId)
                .set("ownerUserService", db.getObj("UserService", userServiceId))
                .save().then(function () {
                  history.replace(`/${sectionId}/service/${serviceId}/${userServiceId}/admin-edit`);
                });
            })
          })
        }
      })
      /*.catch(function (err) {
        alert("Error: " + err);
      });
      */
    } else {
      return dL.saveServiceVersion(model.id, model).then(function () {
        if (status == "draft") {
          if (userServiceId) {
            return dL.saveUserService(userService.id, { ...userService, model })
          } else {
            return dL.saveService(service.id, { ...service, model })
          }
        }
      }).then(function () {
        history.goBack();
      }).catch(function (err) {
        alert("Error: " + err);
      });
    }
  };

  return (
    <EditDialog>
      <HeaderText label="Edit Service" />

      <Section>
        <HeaderText subHeader label="Service Basics:" description="Enter basic description information for the service." />
        <MyInput
          required
          label="Enter a service name or title:"
          value={name}
          onChange={(value) => {
            model.name = value;
            setState({ ...state, model });
          }}
        />
        <MyInput
          maxLength={144}
          label="Create a short description of this service:"
          value={shortDescription}
          onChange={(value) => {
            model.shortDescription = value;
            setState({ ...state, model });
          }}
        />
        <MyInput
          multiline
          style={{ height: 250 }}
          label="Optionally, provide a more longer description:"
          value={description}
          onChange={(value) => {
            model.description = value;
            setState({ ...state, model });
          }}
        />
        <IconPicker
          label="Optionally, select an icon for this service:"
          value={icon}
          onChange={(value) => {
            model.icon = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <Section>
        <HeaderText subHeader label="Service Details:" description="Share more to categorize the service and about who would be best suited for this service." />
        <FlexRow alignTop>
          <SelectBox
            label="Business Sector:"
            description="Select the business sector that most reflects this service."
            value={utils.serviceTypes.find((item) => item.value == serviceType)}
            options={utils.serviceTypes}
            onChange={(item) => {
              model.serviceType = item.value;
              setState({ ...state, model });
            }}
          />
          <View style={{ width: 25 }} />
          <SelectBox
            multiple
            label="Company Stage/s:"
            description="Select what company stages would best apply for this service."
            value={utils.companyStages.filter((item) => (companyStages ? companyStages.find((value) => value == item.value) : null))}
            options={utils.companyStages}
            onChange={(items) => {
              model.companyStages = items.map((item) => item.value);
              setState({ ...state, model });
            }}
          />
        </FlexRow>

        <FlexRow>
          <SelectBox
            style={{ flex: 1 }}
            allowCreate
            label="Primary Category:"
            value={primaryCategory}
            textField="name"
            valueField="id"
            onGetOptions={(inputText) => {
              return dL.getQuery("ServiceCategory")
                .existsObj("primaryCategory", false)
                .startsWith("searchText", inputText)
                .containedIn("removed", [undefined, false])
                .find()
                .then(function (objs) {
                  return sortByString(dL.loadObjects("ServiceCategory", objs), "name")
                })
            }}
            onCreate={(name) => {
              const id = utils.guid()
              model.primaryCategory = { name, id }
              model.secondaryCategory = null;
              dL.getObj("ServiceCategory", id)
                .set("name", name)
                .set("searchText", [...name.toLowerCase().split(" "), name.toLowerCase()])
                .set("createdBy", db.getObj("User", session.user.id))
                .set("createdAt", new Date())
                .save()
              setState({ ...state, service });
            }}
            onChange={(item) => {
              model.primaryCategory = item;
              model.secondaryCategory = null;
              setState({ ...state, model });
            }}
          />

          <SelectBox
            multiple
            maxItems={7}
            label="Can help with the following business initiatives:"
            description="Pick up to 7 items."
            value={businessInitiatives}
            options={getGroupedItems({ list: utils.goals, groupBy: "category" })}
            onChange={items => {
              model.businessInitiatives = items;
              setState({ ...state, model });
            }}
          />

          <View style={{ width: 25 }} />

          <View style={{ flex: 1 }}>
            {primaryCategory ? <SelectBox
              key={primaryCategory.id}
              allowCreate
              label="Secondary Category:"
              value={secondaryCategory}
              textField="name"
              valueField="id"
              onGetOptions={(inputText) => {
                return dL.getQuery("ServiceCategory")
                  .startsWith("searchText", inputText)
                  .equalTo("primaryCategory", db.getObj("ServiceCategory", primaryCategory.id))
                  .containedIn("removed", [undefined, false])
                  .find()
                  .then(function (objs) {
                    return sortByString(dL.loadObjects("ServiceCategory", objs), "name")
                  })
              }}
              onCreate={(name) => {
                const id = utils.guid()
                model.secondaryCategory = { name, id }
                dL.getObj("ServiceCategory", id)
                  .set("name", name)
                  .set("primaryCategory", db.getObj("ServiceCategory", primaryCategory.id))
                  .set("searchText", [...name.toLowerCase().split(" "), name.toLowerCase()])
                  .set("createdBy", db.getObj("User", session.user.id))
                  .set("createdAt", new Date())
                  .save()
                setState({ ...state, model });
              }}
              onChange={(item) => {
                model.secondaryCategory = item;
                setState({ ...state, model });
              }}
            /> : null}
          </View>
        </FlexRow>

        <SelectBox
          label="Type of Service:"
          description="Select what type of action this service represents."
          value={serviceCores.find((item) => item.value == serviceCore)}
          options={serviceCores}
          onChange={(item) => {
            model.serviceCore = item.value;
            setState({ ...state, model });
          }}
        />

        <FormItem label="Add tags to describe service:" description="">
          <Tags value={tags} onChange={(tags) => {
            model.tags = tags;
            setState({ ...state, model });
          }} />
        </FormItem>

        {sectionId == "admin" ? <FlexRow>
          <FormItem
            required
            label="Business Role:" description="Select what business role will fulfill this service.">
            <SelectBusinessRole
              onChange={(item) => {
                model.businessRole = item;
                setState({ ...state, model });
              }}
              value={businessRole}
            />
          </FormItem>
          <View style={{ width: 25 }} />
          <SelectBox
            required
            label="Skill Level:"
            description="Select the ideal skill level required for the business role."
            value={utils.priceTiers.find((item) => item.value == skillLevel)}
            options={utils.priceTiers}
            onChange={(item) => {
              model.skillLevel = item.value;
              setState({ ...state, model });
            }}
          />
        </FlexRow> : <RenderSelectUserRole
          businessRole={businessRole}
          userRole={userRole}
          defaultUserRoles={userRoles}
          onChange={(userRole) => {
            const { businessRole, skillLevel } = userRole
            model.businessRole = businessRole;
            model.skillLevel = skillLevel;
            model.userRole = userRole;
            setState({ ...state, model });
          }} />}
      </Section>

      {status == "draft" ? (
        <TouchButton
          style={{ marginRight: 10 }}
          label="Save Draft"
          onPress={() => {
            return saveService({});
          }}
        />
      ) : (
        <TouchButton
          label="Save Service"
          onPress={() => {
            return saveService({ validateAll: true });
          }}
        />
      )}
    </EditDialog>
  );
}

export function RenderSelectUserRole({ defaultUserRoles, businessRole, userRole, onChange }) {
  const [state, setState] = useState({ userRoles: defaultUserRoles });
  const { addBusinessRole, userRoles } = state;

  return <React.Fragment>
    <FlexRow>
      {userRoles.length == 0 ?
        <CenterBox style={{ flex: 1, paddingTop: 35, paddingBottom: 35 }}>
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: 75, color: "grey" }} />
          <TextLine top={25} size={22} bold value="Add business role in your profile" />
          <TextLine top={8} value={"This service requires the business role '" + businessRole.name + "' to be added to your profile."} />
          <View style={{ height: 35 }} />
          <TouchButton label="Add Business Role" onPress={() => {
            setState({ ...state, addBusinessRole: true })
          }} />
        </CenterBox> : <SelectBox
          label="Business Role:"
          description="Select what business role that will fulfill this service."
          value={userRole ? userRoles.find((item) => item.id == userRole.id) : null}
          options={userRoles}
          onGetText={(item) => {
            const { hourlyRate, businessRole } = item
            const { name } = businessRole
            return name + " (" + money(hourlyRate) + "/hr)"
          }}
          valueField="id"
          onChange={(userRole) => {
            onChange(userRole)
            setState({ ...state });
          }}
        />}
    </FlexRow>

    {addBusinessRole ? <EditUserRoleDialog businessRole={businessRole} onCancel={() => {
      setState({ ...state, addBusinessRole: null })
    }} onSave={(userRole) => {
      userRoles.push(userRole)
      session.user.userRoles.push(userRole)
      onChange(userRole)
      setState({ ...state, userRoles });
    }} /> : null}
  </React.Fragment>
}

function EditServiceVisibility({ service, serviceVersion, userService, onCancel, onSave }) {
  const [state, setState] = useState({
    model: Object.assign({}, service),
    userModel: Object.assign({}, userService),
  });
  const { isProcessing, model, userModel } = state;

  const userServiceId = userService.id;
  const { isWorkerPublic, status } = userModel;

  return (
    <ModalBasic
      title="Publish Service:"
      description="Edit the visibility of this service."
      okText={status == "draft" ? "Publish" : "Save"}
      onCancel={onCancel}
      onOk={() => {
        if (isWorkerPublic && !canSubmitServiceForApproval({ service, userService, serviceVersion })) {
          return;
        }
        setState({ ...state, isProcessing: true })

        const serviceId = service.id
        return dL
          .saveUserService(userServiceId, userModel)
          .then(function () {
            var incrementValue
            if (!userService.isWorkerPublic && isWorkerPublic) {
              incrementValue = 1
            } else if (userService.isWorkerPublic && !isWorkerPublic) {
              incrementValue = -1
            }
            if (incrementValue != null) {
              return dL.getObj("Service", serviceId).increment("publicUserServices", incrementValue).save()
            }
          }).then(function () {
            if (status == "draft") {
              return dL.publishUserService(userServiceId)
            }
          }).then(function () {
            onSave({ service: model, userService: userModel });
            setState({ ...state, isProcessing: false })
          });
      }}>

      <Toggle
        value={isWorkerPublic}
        onChange={(value) => {
          userModel.isWorkerPublic = value;
          setState({ ...state, model });
        }}
        label="Make public in service marketplace:"
        description="Service will be visible to other consultants."
        style={{ marginBottom: 23 }}
      />

      {isProcessing ? <Loading /> : null}
    </ModalBasic>
  );
}

function EditServiceAdmin({ service, userService, serviceVersion, onCancel, onSave }) {
  const [state, setState] = useState({
    model: Object.assign({}, service),
  });
  const { model } = state;

  const { ownerUserService } = model

  const serviceId = model.id;
  const { isBuyerPublic } = model;

  return (
    <ModalBasic
      title="Publish Service:"
      description="Set the visibility of this service."
      okText="Save"
      onCancel={onCancel}
      onOk={() => {
        if (isBuyerPublic && !canSubmitServiceForApproval({ service, userService, serviceVersion })) {
          return;
        }

        return dL.saveService(serviceId, model).then(function () {
          onSave({ service: model });
        });
      }}>
      <FormItem label="Owner Consultant:" description="Select the consultant that will be publishing changes to this master service in the buyer service catalog." box>
        <SelectWorker
          hideMySelf
          serviceId={serviceId}
          onChange={({ userService }) => {
            model.ownerUserService = userService
            setState({ ...state, model })
          }}
          value={ownerUserService ? ownerUserService.user : null} />
      </FormItem>

      <Toggle
        value={isBuyerPublic}
        onChange={(value) => {
          model.isBuyerPublic = value;
          setState({ ...state, model });
        }}
        label="Publish to service catalog:"
        description="Service will be visible in the buyer service catalog."
      />
    </ModalBasic>
  );
}

function EditServiceOptions({ serviceVersion, onCancel, onSave }) {
  const [state, setState] = useState({ model: Object.assign({}, serviceVersion) });
  const { model } = state;

  const { enablePackages, enableServiceOptions } = model

  return (
    <ModalBasic
      title="Edit Service Options:"
      description="Select various options that are availale for services."
      okText="Save"
      onCancel={onCancel}
      onOk={() => {
        if (enablePackages) {
          //add the bundled package here
        }

        return dL.saveServiceVersion(model.id, model).then(function () {
          onSave(model);
        });
      }}>
      <Toggle
        value={enableServiceOptions}
        onChange={(value) => {
          model.enableServiceOptions = value;
          setState({ ...state, model });
        }}
        label="Enable advanced options:"
        description="This will enable service options to change the tasks required, price for the service, deliverables returned and questions needed to be asked."
        style={{ marginBottom: 23 }}
      />
    </ModalBasic>
  );
}

function EditServiceDeliveryOptions({ serviceVersion, onCancel, onSave }) {
  const [state, setState] = useState({ model: Object.assign({}, serviceVersion) });
  const { model } = state;
  const { fastDeliveryPercent, deliveryDays, allowedRevisions, hasExtraFastDelivery, extraFastDeliveryDays } = model;

  return (
    <ModalBasic
      title="Edit Service Delivery:"
      description="Setup standard and fast delivery days and allowed number of deliverable revisions."
      okText="Save"
      onCancel={onCancel}
      onOk={() => {
        model.deliveryDays = Number(deliveryDays);

        if (hasExtraFastDelivery) {
          if (!utils.isInteger(extraFastDeliveryDays)) {
            alert("Must enter valid extra fast delivery days.");
            return;
          }

          if (Number(extraFastDeliveryDays) <= 0) {
            alert("Must enter valid extra fast delivery days.");
            return;
          }

          if (Number(fastDeliveryPercent) < 0) {
            alert("Must enter valid fast delivery percent up-charge.");
            return;
          }
          model.extraFastDeliveryDays = Number(extraFastDeliveryDays);
          model.fastDeliveryPercent = Number(fastDeliveryPercent);
        }

        return dL.saveServiceVersion(model.id, model).then(function () {
          onSave(model);
        });
      }}>
      <FlexRow>
        <MyInput
          style={{ maxWidth: 165 }}
          inputType="integer"
          minValue={0}
          label="Delivery Days:"
          description="Enter the number of standard business days for delivery of this service."
          value={deliveryDays}
          onChange={(value) => {
            model.deliveryDays = value;
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 25 }} />

        <SelectBox
          style={{ maxWidth: 165 }}
          label="Revisions:"
          description="Number of revisions that will be allowed for deliverables."
          options={utils.revisionOptions}
          value={utils.revisionOptions.find((item) => item.value == allowedRevisions)}
          onChange={(item) => {
            model.allowedRevisions = item.value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      <Toggle
        label="Enable extra fast delivery option:"
        description="Create an option to speed of the delivery of this service when purchasing."
        value={hasExtraFastDelivery}
        onChange={(value) => {
          model.hasExtraFastDelivery = value;
          setState({ ...state, model });
        }}
      />

      {hasExtraFastDelivery ? (
        <FlexRow>
          <MyInput
            style={{ maxWidth: 165 }}
            inputType="integer"
            minValue={0}
            label="Fast Delivery Days:"
            description="Enter the days this service will be delivered in for extra fast delivery."
            value={extraFastDeliveryDays}
            onChange={(value) => {
              model.extraFastDeliveryDays = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ width: 25 }} />
          <MyInput
            style={{ maxWidth: 125 }}
            inputType="integer"
            minValue={0}
            label="Up-Charge (%):"
            description="Enter the percentage to up-charge for fast delivery."
            value={fastDeliveryPercent}
            onChange={(value) => {
              model.fastDeliveryPercent = value;
              setState({ ...state, model });
            }}
          />
        </FlexRow>
      ) : null}
    </ModalBasic>
  );
}

function EditServicePriceOptions({ serviceVersion, onCancel, onSave }) {
  const [state, setState] = useState({ model: Object.assign({}, serviceVersion) });
  const { model } = state;
  const { fastDeliveryPercent, isFixedPriced, enablePackages, price, hasExtraFastDelivery, extraFastPrice, extraFastDeliveryDays } = model;

  return (
    <ModalBasic
      title="Edit Purchase Price"
      description="Setup the pricing options for this service."
      okText="Save"
      onCancel={onCancel}
      onOk={() => {
        if (isFixedPriced) {
          if (!utils.isMoney(price)) {
            alert("Must enter valid price.");
            return;
          }

          model.price = Number(price);

          if (hasExtraFastDelivery) {
            if (!utils.isMoney(extraFastPrice)) {
              alert("Must enter valid extra fast price.");
              return;
            }
            if (!utils.isInteger(extraFastDeliveryDays)) {
              alert("Must enter valid extra fast delivery days.");
              return;
            }

            if (Number(extraFastDeliveryDays) <= 0) {
              alert("Must enter valid extra fast delivery days.");
              return;
            }

            if (Number(fastDeliveryPercent) < 0) {
              alert("Must enter valid fast delivery percent up-charge.");
              return;
            }
            model.extraFastDeliveryDays = Number(extraFastDeliveryDays);
            model.extraFastPrice = Number(extraFastPrice);
            model.fastDeliveryPercent = Number(fastDeliveryPercent);
          }
        }

        return dL.saveServiceVersion(model.id, model).then(function () {
          onSave(model);
        });
      }}>
      <Toggle
        value={enablePackages}
        onChange={(value) => {
          model.enablePackages = value;
          setState({ ...state, model });
        }}
        label="Enable bundled packages:"
        description="Add bundled packages for different bundled package price, set different delivery days and price for extra fast)."
      />

      {!enablePackages ? (
        <React.Fragment>
          <Toggle
            value={isFixedPriced}
            onChange={(value) => {
              model.isFixedPriced = value;
              setState({ ...state, model });
            }}
            label="Make service fixed price:"
            description="Make this a fixed price service, instead of using hourly costing."
            style={{ marginBottom: 23 }}
          />

          {isFixedPriced ? (
            <React.Fragment>
              <FlexRow>
                <MyInput
                  style={{ maxWidth: 125 }}
                  inputType="money"
                  minValue={0}
                  label="Price:"
                  description="Enter the price charged for this service in the public service catalog."
                  value={price}
                  onChange={(value) => {
                    model.price = value;
                    setState({ ...state, model });
                  }}
                />
              </FlexRow>

              <Toggle
                label="Enable extra fast delivery option:"
                description="Create an option to speed of the delivery of this service when purchasing."
                value={hasExtraFastDelivery}
                onChange={(value) => {
                  model.hasExtraFastDelivery = value;
                  setState({ ...state, model });
                }}
              />

              {hasExtraFastDelivery ? (
                <View style={{ flexDirection: "row" }}>
                  <MyInput
                    style={{ maxWidth: 125 }}
                    inputType="money"
                    minValue={0}
                    label="Fast Price:"
                    description="Enter the price charge for extra fast delivery."
                    value={extraFastPrice}
                    onChange={(value) => {
                      model.extraFastPrice = value;
                      setState({ ...state, model });
                    }}
                  />

                  <View style={{ width: 25 }} />
                  <MyInput
                    style={{ maxWidth: 165 }}
                    inputType="integer"
                    minValue={0}
                    label="Fast Delivery Days:"
                    description="Enter the days this service will be delivered in for extra fast delivery."
                    value={extraFastDeliveryDays}
                    onChange={(value) => {
                      model.extraFastDeliveryDays = value;
                      setState({ ...state, model });
                    }}
                  />
                  <View style={{ width: 25 }} />
                  <MyInput
                    style={{ maxWidth: 125 }}
                    inputType="integer"
                    minValue={0}
                    label="Up-Charge (%):"
                    description="Enter the percentage to up-charge for fast delivery."
                    value={fastDeliveryPercent}
                    onChange={(value) => {
                      model.fastDeliveryPercent = value;
                      setState({ ...state, model });
                    }}
                  />
                </View>
              ) : null}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </ModalBasic>
  );
}

export function getServiceFull({ serviceId }) {
  var service;
  var relatedServices = [];
  return dL.getService2({ serviceId }).then(function (_service) {
    service = _service;
    const relatedServiceIds = {};

    const { inputs } = service;

    if (inputs) {
      inputs.forEach((input) => {
        const { similarServices } = input;

        if (similarServices) {
          similarServices.forEach((service) => {
            relatedServiceIds[service.id] = service;
          });
        }
      });
    }

    const serviceIds = [];
    for (var key in relatedServiceIds) {
      if (key != service.id) {
        serviceIds.push(key);
      }
    }

    if (serviceIds.length > 0) {
      return db
        .getQuery("Service")
        .containedIn("_id", serviceIds)
        .containedIn("removed", [undefined, false])
        .include("deliverables")
        .include("packages")
        .find()
        .then(function (objs) {
          relatedServices = dL.loadObjects("Service", objs);
        });
    }
  }).then(function () {
    return dL.getServicePeople({ serviceId: service.id });
  }).then(function (servicePeople) {
    return { servicePeople, relatedServices, service };
  });
}

export function RenderDelayOptions({ delayOptions, onUpdate }) {
  const { delayType, delayDays, delayStart, delayStartDate } = delayOptions;

  return (
    <React.Fragment>
      <Toggle
        label="Delay start of service:"
        description="Force the delay of the start of this service by a certain number of days."
        value={delayStart}
        onChange={(value) => {
          delayOptions.delayStart = value;
          onUpdate();
        }}
      />

      {delayStart ? (
        <FlexRow>
          <SelectBox
            label="Delay Type:"
            value={delayTypes.find((item) => item.value == delayType)}
            options={delayTypes}
            onChange={(item) => {
              delayOptions.delayType = item.value;
              onUpdate();
            }}
          />
          <View style={{ width: 25 }} />
          {delayType == "start-date" ? (
            <FormItem label="Start Date:">
              <Datetime
                style={{ maxWidth: 155 }}
                closeOnClickOutside={true}
                closeOnSelect={true}
                value={delayStartDate}
                timeFormat={false}
                onChange={(value) => {
                  delayOptions.delayStartDate = value.toDate();
                  onUpdate();
                }}
              />
            </FormItem>
          ) : delayType == "start-of-order" || delayType == "days-from-pre-req" ? (
            <MyInput
              style={{ maxWidth: 155 }}
              inputType="integer"
              minValue={0}
              label="Delay Days:"
              value={delayDays}
              onChange={(value) => {
                delayOptions.delayDays = value;
                onUpdate();
              }}
            />
          ) : null}
        </FlexRow>
      ) : null}
    </React.Fragment>
  );
}