import "./App.css";
import { TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import "root/App.css";
import { __DEV__ } from "root/dev";
import { ReactSortable } from "react-sortablejs";
import { HeaderText } from "root/pack-2";
import { DeleteConfirm, Section, ModalBasic, BoxItem, FlexExpand, FlexRow, Text, TextLine, View, TrashIcon, MoveIcon, EditIcon, Toggle, MyInput, SelectBox, FileElement, NoRecords } from "root/pack-1";

const itemTypes = [{ label: "Free-Form", value: "free-form" }, { label: "Multiple  Choice", value: "multiple-choice" }, { label: "File Upload", value: "file-upload" }];

export function validateForm({ formItems, formData }) {
  for (var k = 0; k < formItems.length; k++) {
    const formItem = formItems[k];
    const { required, name, id } = formItem;
    if (required && formData[id] == null) {
      alert("Must enter value for " + name + ".");
      return;
    }
  }
  return true;
}

export function DisplayFormItems({ formItems, formData }) {
  return formItems.length == 0 ? (
    <NoRecords label="No questions added." />
  ) : (
    formItems.map(item => {
      const { name, description, itemType, required, options, id } = item;
      const value = formData[id];

      return (
        <View style={{ marginBottom: 15 }}>
          <View style={{ marginBottom: 10 }}>
            <TextLine size={16} value={name} />
            {description ? <TextLine size={12} color="grey" value={description} /> : null}
          </View>

          {itemType == "free-form" ? value : null}

          {itemType == "multiple-choice" ? (value && value.label ? value.label : value) : null}

          {itemType == "file-upload" ? <FileElement readonly={true} required={required} label={name} description={description} value={value} /> : null}
        </View>
      );
    })
  );
}

export function DisplayInputs({ inputs, inputData }) {
  return inputs.map(item => {
    if (!item.formItems) {
      item.formItems = [];
    }
    const { name, description, formItems, id } = item;
    const formData = inputData[id] ? inputData[id] : {};

    return (
      <BoxItem>
        <View style={{ marginBottom: 10 }}>
          <TextLine size={18} value={name} />
          <TextLine size={14} color="grey" value={description} />
        </View>

        {formItems.length == 0 ? (
          <NoRecords label="No questions added." />
        ) : (
          formItems.map(item => {
            const { name, description, itemType, required, options, id } = item;
            const value = formData[id];

            return (
              <View>
                <View style={{ marginBottom: 10 }}>
                  <TextLine size={16} value={name} />
                  <TextLine size={12} color="grey" value={description} />
                </View>

                {itemType == "free-form" ? value : null}

                {itemType == "multiple-choice" ? value : null}

                {itemType == "file-upload" ? <FileElement readonly={true} required={required} label={name} description={description} value={value} /> : null}
              </View>
            );
          })
        )}
      </BoxItem>
    );
  });
}

export function RenderClientInputRequirements({ inputs, inputData, onDone }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, relatedServices } = state;

  useEffect(() => {
    var relatedServices = [];
    const relatedServiceIds = {};

    inputs.forEach(input => {
      const { similarServices } = input;
      if (similarServices) {
        similarServices.forEach(service => {
          relatedServiceIds[service.id] = service;
        });
      }
    });

    const serviceIds = [];
    for (var key in relatedServiceIds) {
      serviceIds.push(key);
    }

    if (serviceIds.length > 0) {
      return db
        .getQuery("Service")
        .containedIn("_id", serviceIds)
        .containedIn("removed", [undefined, false])
        .include("deliverables")
        .include("packages")
        .find()
        .then(function (objs) {
          relatedServices = dL.loadObjects("Service", objs);
        });
    }

    setState({ ...state, relatedServices, isLoading: false });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  const ssTextS = { fontSize: 16 };
  const ssTextS2 = { fontSize: 16, textDecoration: "underline" };

  return (
    <ModalBasic
      large
      title="Questions"
      okText="Save"
      onCancel={() => {
        onDone();
      }}
      onOk={() => {
        for (var i = 0; i < inputs.length; i++) {
          const item = inputs[i];

          const { formItems, id } = item;
          const formData = inputData[id];

          if (!validateForm({ formItems, formData })) {
            return;
          }
        }
        return onDone();
      }}>
      <Section>
        <HeaderText subHeader description="Fill out these requirements to start work on this service order." />

        {!inputs ? <NoRecords label="No questions found." /> : null}

        {inputs &&
          inputs.map(item => {
            const { formItems, similarServices, name, description, required, id } = item;

            if (!inputData[id]) {
              inputData[id] = {};
            }

            const formData = inputData[id];

            return (
              <Section>
                <HeaderText subHeader label={name} description={description} />
                {!formItems || formItems.length == 0 ? (
                  <NoRecords label="No questions added." />
                ) : (
                  formItems.map(item => {
                    const { name, description, itemType, options, id, required } = item;

                    return (
                      <View>
                        {itemType == "free-form" ? (
                          <MyInput
                            required={required}
                            label={name}
                            description={description}
                            value={formData[id]}
                            onChange={value => {
                              formData[id] = value;
                              setState({ ...state });
                            }}
                          />
                        ) : null}

                        {itemType == "multiple-choice" ? (
                          <SelectBox
                            required={required}
                            label={name}
                            description={description}
                            value={formData[id]}
                            options={
                              options
                                ? options.split("\n").map(item => {
                                  return { label: item, value: item };
                                })
                                : []
                            }
                            onChange={value => {
                              formData[id] = value;
                              setState({ ...state });
                            }}
                          />
                        ) : null}

                        {itemType == "file-upload" ? (
                          <FileElement
                            required={required}
                            label={name}
                            description={description}
                            value={formData[id]}
                            onChange={value => {
                              formData[id] = value;
                              setState({ ...state });
                            }}
                          />
                        ) : null}

                        {similarServices ? (
                          <View style={{ marginBottom: 25, marginTop: -10, flexDirection: "row", alignItems: "center" }}>
                            <View style={{ marginRight: 10 }}>
                              <Text style={ssTextS}>Helper Services:</Text>
                              <Text>Don't want or know the information on this form. Purchase a helper service for skill expert to gather the information required or research and provide you with requirements.</Text>
                            </View>

                            {similarServices.map(item => (
                              <TouchableOpacity
                                onPress={() => {
                                  window.open("http://localhost:3000/admin/service/" + item.id);
                                }}
                                style={{ marginRight: 10 }}>
                                <Text style={ssTextS2}>{item.name}</Text>
                              </TouchableOpacity>
                            ))}
                          </View>
                        ) : null}
                      </View>
                    );
                  })
                )}
              </Section>
            );
          })}
      </Section>
    </ModalBasic>
  );
}

export function RenderClientInputRequirementsBuyer({ inputs, inputData, onCancel,onSave,onSaveDraft }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, relatedServices } = state;

  useEffect(() => {
    var relatedServices = [];
    const relatedServiceIds = {};

    inputs.forEach(input => {
      const { similarServices } = input;
      if (similarServices) {
        similarServices.forEach(service => {
          relatedServiceIds[service.id] = service;
        });
      }
    });

    const serviceIds = [];
    for (var key in relatedServiceIds) {
      serviceIds.push(key);
    }

    if (serviceIds.length > 0) {
      return db
        .getQuery("Service")
        .containedIn("_id", serviceIds)
        .containedIn("removed", [undefined, false])
        .include("deliverables")
        .include("packages")
        .find()
        .then(function (objs) {
          relatedServices = dL.loadObjects("Service", objs);
        });
    }

    setState({ ...state, relatedServices, isLoading: false });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  const ssTextS = { fontSize: 16 };
  const ssTextS2 = { fontSize: 16, textDecoration: "underline" };

  const buttons = [
    {
      label: "Save Draft", onPress: () => {
        return onSaveDraft(inputData);
      }
    },
    {
      label: "Submit to Start", onPress: () => {
        for (var i = 0; i < inputs.length; i++) {
          const item = inputs[i];

          const { formItems, id } = item;
          const formData = inputData[id];

          if (!validateForm({ formItems, formData })) {
            return;
          }
        }
        return onSave(inputData);
      }
    }
  ]

  return (
    <ModalBasic
      large
      title="Questions"
      buttons={buttons}
      onCancel={onCancel}>
      <Section>
        <HeaderText subHeader description="Fill out these requirements to start work on this service order." />

        {!inputs ? <NoRecords label="No questions found." /> : null}

        {inputs &&
          inputs.map(item => {
            const { formItems, similarServices, name, description, required, id } = item;

            if (!inputData[id]) {
              inputData[id] = {};
            }

            const formData = inputData[id];

            return (
              <Section>
                <HeaderText subHeader label={name} description={description} />
                {!formItems || formItems.length == 0 ? (
                  <NoRecords label="No questions added." />
                ) : (
                  formItems.map(item => {
                    const { name, description, itemType, options, id, required } = item;

                    return (
                      <View>
                        {itemType == "free-form" ? (
                          <MyInput
                            required={required}
                            label={name}
                            description={description}
                            value={formData[id]}
                            onChange={value => {
                              formData[id] = value;
                              setState({ ...state });
                            }}
                          />
                        ) : null}

                        {itemType == "multiple-choice" ? (
                          <SelectBox
                            required={required}
                            label={name}
                            description={description}
                            value={formData[id]}
                            options={
                              options
                                ? options.split("\n").map(item => {
                                  return { label: item, value: item };
                                })
                                : []
                            }
                            onChange={value => {
                              formData[id] = value;
                              setState({ ...state });
                            }}
                          />
                        ) : null}

                        {itemType == "file-upload" ? (
                          <FileElement
                            required={required}
                            label={name}
                            description={description}
                            value={formData[id]}
                            onChange={value => {
                              formData[id] = value;
                              setState({ ...state });
                            }}
                          />
                        ) : null}

                        {similarServices ? (
                          <View style={{ marginBottom: 25, marginTop: -10, flexDirection: "row", alignItems: "center" }}>
                            <View style={{ marginRight: 10 }}>
                              <Text style={ssTextS}>Helper Services:</Text>
                              <Text>Don't want or know the information on this form. Purchase a helper service for skill expert to gather the information required or research and provide you with requirements.</Text>
                            </View>

                            {similarServices.map(item => (
                              <TouchableOpacity
                                onPress={() => {
                                  window.open("http://localhost:3000/admin/service/" + item.id);
                                }}
                                style={{ marginRight: 10 }}>
                                <Text style={ssTextS2}>{item.name}</Text>
                              </TouchableOpacity>
                            ))}
                          </View>
                        ) : null}
                      </View>
                    );
                  })
                )}
              </Section>
            );
          })}
      </Section>
    </ModalBasic>
  );
}

export function FormEditor(props) {
  const [state, setState] = useState({});
  var { formItems, onChange, formData } = props;

  if (!formItems) {
    formItems = [];
  }
  if (!formData) {
    formData = {};
  }

  return (
    <View>
      {formItems.length == 0 ? (
        <NoRecords label="No questions added." />
      ) : (
        formItems.map(item => {
          const { id } = item;
          const value = formData[id];
          return (
            <RenderFormItem
              item={item}
              value={value}
              onChange={value => {
                formData[id] = value;
                setState({ ...state });
                onChange && onChange(formData);
              }}
            />
          );
        })
      )}
    </View>
  );
}

function RenderFormItem({ item, onChange, value }) {
  const { name, description, itemType, options, id, required } = item;

  return (
    <View>
      {itemType == "free-form" ? (
        <MyInput
          placeholder="Enter text..."
          required={required}
          label={name}
          description={description}
          value={value}
          onChange={value => {
            onChange(value);
          }}
        />
      ) : itemType == "multiple-choice" ? (
        <SelectBox
          required={required}
          label={name}
          description={description}
          value={value}
          options={
            options
              ? options.split("\n").map(item => {
                return { label: item, value: item };
              })
              : []
          }
          onChange={value => {
            onChange(value);
          }}
        />
      ) : itemType == "file-upload" ? (
        <FileElement
          required={required}
          label={name}
          description={description}
          value={value}
          onChange={value => {
            onChange(value);
          }}
        />
      ) : null}
    </View>
  );
}

function EditFormItem({ model, onCancel, onDone }) {
  const [state, setState] = useState({});
  const { name, description, options, itemType, required } = model;

  return (
    <ModalBasic
      title="Form Item:"
      onCancel={onCancel}
      onOk={() => {
        if (!name) {
          alert("Must enter name.");
          return;
        }

        if (!model.id) {
          model.id = utils.guid();
        }

        return onDone(model);
      }}>
      <SelectBox
        label="Item Type:"
        value={itemTypes.find(item => item.value == itemType)}
        options={itemTypes}
        onChange={item => {
          model.itemType = item.value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        required
        label="Question:"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        maxLength={144}
        label="Additional information:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      {itemType == "multiple-choice" ? (
        <MyInput
          multiline
          label="Choices:"
          description="One choice per line."
          value={options}
          onChange={value => {
            model.options = value;
            setState({ ...state, model });
          }}
        />
      ) : null}

      <Toggle
        label="Required to answer:"
        value={required}
        onChange={value => {
          model.required = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

export function FormBuilder(props) {
  const { label, onUpdate,smallHeader,subHeader } = props;
  var { formItems } = props;
  if (!formItems) {
    formItems = [];
  }
  const [state, setState] = useState({});
  const { editItem, isNew, viewMode, showDelete, deleteIndex } = state;

  return (
    <Section>
      <HeaderText
        subHeader={subHeader}
        smallHeader={smallHeader}
        label={label}
        onButtonPress={() => {
          setState({ ...state, viewMode: true, isNew: true, editItem: { itemType: "free-form" } });
        }}
      />

      {formItems.length == 0 ? (
        <NoRecords label="No questions added." />
      ) : (
        <ReactSortable
          disabled={false}
          handle=".my-handle"
          list={formItems}
          setList={list => {
            onUpdate(list);
            setState({ ...state });
          }}>
          {formItems.map((item, index) => {
            return (
              <FlexRow>
                <FlexExpand>
                  <RenderFormItem item={item} />
                </FlexExpand>

                <View style={{ marginLeft: 10, flexDirection: "row", width: 50, textAlign: "right" }}>
                  <MoveIcon className="my-handle" style={{ marginRight: 8 }} />
                  <EditIcon
                    style={{ marginRight: 8 }}
                    onPress={() => {
                      setState({ ...state, isNew: false, viewMode: true, editItem: item });
                    }}
                  />
                  <TrashIcon
                    onPress={() => {
                      setState({ ...state, showDelete: true, deleteIndex: index });
                    }}
                  />
                </View>
              </FlexRow>
            );

            /*
            const headerS = {fontSize: 18};
            const detailS = {fontSize: 14, color: "grey"};

            return (
              <View style={{borderStyle: "solid", borderWidth: 2, borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7, marginTop: 12, marginBottom: 12, flexDirection: "row"}}>
                <View style={{flex: 1, flexDirection: "row"}}>
                  <View style={{flex: 1, marginRight: 10}}>
                    <Text style={headerS}>{name}</Text>
                    {description ? (
                      <View style={{marginTop: 4}}>
                        <Text style={detailS}>{description}</Text>
                      </View>
                    ) : null}
                  </View>
                  {required ? <Text style={requiredS}>Required</Text> : null}
                </View>

                <View style={{marginLeft: 10, flexDirection: "row"}}>
                  <MoveIcon className="my-handle" style={{marginRight: 8}} />
                  <EditIcon
                    style={{marginRight: 8}}
                    onPress={() => {
                      setState({...state, isNew: false, viewMode: true, editItem: item});
                    }}
                  />
                  <TrashIcon
                    onPress={() => {
                      setState({...state, showDelete: true, deleteId: id, deleteIndex: index});
                    }}
                  />
                </View>
              </View>
            );
            */
          })}
        </ReactSortable>
      )}

      {showDelete ? (
        <DeleteConfirm
          onCancel={() => {
            setState({ ...state, showDelete: false });
          }}
          onConfirm={() => {
            formItems.splice(deleteIndex, 1);
            onUpdate(formItems);
            setState({ ...state, showDelete: false });
          }}
        />
      ) : null}

      {viewMode ? (
        <EditFormItem
          model={editItem}
          onCancel={() => {
            setState({ ...state, viewMode: false });
          }}
          onDone={editItem => {
            if (isNew) {
              formItems.push(editItem);
            }

            onUpdate(formItems);
            setState({ ...state, viewMode: false });
          }}
        />
      ) : null}
    </Section>
  );
}
