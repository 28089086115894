import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { render, unmountComponentAtNode } from 'react-dom'
import { Spinner } from "react-bootstrap"
import { Portal } from "react-portal";

export default class ReactLoading extends Component {
    static propTypes = {
        onLoading: PropTypes.func,

        title: PropTypes.string,
        message: PropTypes.string,
        buttons: PropTypes.array.isRequired,
        childrenElement: PropTypes.func,
        customUI: PropTypes.func,
        closeOnClickOutside: PropTypes.bool,
        closeOnEscape: PropTypes.bool,
        willUnmount: PropTypes.func,
        afterClose: PropTypes.func,
        onClickOutside: PropTypes.func,
        onKeypressEscape: PropTypes.func,
        overlayClassName: PropTypes.string
    }

    static defaultProps = {
        onLoading: () => null,

        buttons: [
            {
                label: 'Cancel',
                onClick: () => null,
                className: null
            },
            {
                label: 'Confirm',
                onClick: () => null,
                className: null
            }
        ],
        childrenElement: () => null,
        closeOnClickOutside: true,
        closeOnEscape: true,
        willUnmount: () => null,
        afterClose: () => null,
        onClickOutside: () => null,
        onKeypressEscape: () => null
    }

    constructor(props) {
        super(props);

        this.state = {
            hideClass: false
        };
    }

    handleClickButton = button => {
        if (button.onClick) button.onClick()
        this.close()
    }

    handleClickOverlay = e => {
        const { closeOnClickOutside, onClickOutside } = this.props
        const isClickOutside = e.target === this.overlay

        if (closeOnClickOutside && isClickOutside) {
            onClickOutside()
            this.close()
        }
    }

    close = () => {
        const { afterClose } = this.props
        removeBodyClass()
        removeElementReconfirm()
        removeSVGBlurReconfirm(afterClose)
    }

    keyboardClose = event => {
        const { closeOnEscape, onKeypressEscape } = this.props
        const isKeyCodeEscape = event.keyCode === 27

        if (closeOnEscape && isKeyCodeEscape) {
            onKeypressEscape(event)
            this.close()
        }
    }

    componentDidMount = () => {
        document.addEventListener('keydown', this.keyboardClose, false)

        const promise = this.props.onLoading()
        if (promise) {
            promise.then(() => {
                this.setState({ hideClass: true }, () => {
                    setTimeout(() => {
                        this.close()
                    }, 500)
                })
            })
        } else {
            this.close()
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this.keyboardClose, false)
        this.props.willUnmount()
    }

    renderCustomUI = () => {
        const { title, message, buttons, customUI } = this.props
        const dataCustomUI = {
            title,
            message,
            buttons,
            onClose: this.close
        }

        return customUI(dataCustomUI)
    }

    render() {
        const { overlayClassName } = this.props
        
        return (
            <Portal>
                <div
                    className={`${overlayClassName ? overlayClassName : ""} ${this.state.hideClass ? "react-confirm-alert-overlay-out" : "react-confirm-alert-overlay-2"}`}
                    ref={dom => (this.overlay = dom)}>
                    <div className='react-confirm-alert'>
                        <Spinner variant="light" animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            </Portal>
        )
    }
}

function createSVGBlurReconfirm() {
    // If has svg ignore to create the svg
    const svg = document.getElementById('react-confirm-alert-firm-svg')
    if (svg) return
    const svgNS = 'http://www.w3.org/2000/svg'
    const feGaussianBlur = document.createElementNS(svgNS, 'feGaussianBlur')
    feGaussianBlur.setAttribute('stdDeviation', '0.3')

    const filter = document.createElementNS(svgNS, 'filter')
    filter.setAttribute('id', 'gaussian-blur')
    filter.appendChild(feGaussianBlur)

    const svgElem = document.createElementNS(svgNS, 'svg')
    svgElem.setAttribute('id', 'react-confirm-alert-firm-svg')
    svgElem.setAttribute('class', 'react-confirm-alert-svg')
    svgElem.appendChild(filter)

    document.body.appendChild(svgElem)
}

function removeSVGBlurReconfirm(afterClose) {
    const svg = document.getElementById('react-confirm-alert-firm-svg')
    if (svg) {
        svg.parentNode.removeChild(svg)
    }
    document.body.children[0].classList.remove('react-confirm-alert-blur')
    afterClose()
}

function createElementReconfirm(properties) {
    let divTarget = document.getElementById('react-confirm-alert')
    if (divTarget) {
        // Rerender - the mounted ReactConfirmAlert
        render(<ReactLoading {...properties} />, divTarget)
    } else {
        // Mount the ReactConfirmAlert component
        document.body.children[0].classList.add('react-confirm-alert-blur')
        divTarget = document.createElement('div')
        divTarget.id = 'react-confirm-alert'
        document.body.appendChild(divTarget)
        render(<ReactLoading {...properties} />, divTarget)
    }
}

function removeElementReconfirm() {
    const target = document.getElementById('react-confirm-alert')
    if (target) {
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
    }
}

function addBodyClass() {
    document.body.classList.add('react-confirm-alert-body-element')
}

function removeBodyClass() {
    document.body.classList.remove('react-confirm-alert-body-element')
}

export function reactLoading(properties) {
    addBodyClass()
    createSVGBlurReconfirm()
    createElementReconfirm(properties)
}