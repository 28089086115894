import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import Moment from "moment-business-days";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css"; import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { ViewServiceOption, ViewServiceDeliverable } from "root/services-worker";
import { ViewServicePurchased } from "root/services-buyer";
import { validateForm, DisplayInputs, DisplayFormItems } from "root/forms";
import { EditKeyUser, ListRender2, RenderUser } from "root/pack-3";
import { __DEV__ } from "root/dev";
import { confirmAlert } from "react-confirm-alert";
import { BasicTop, SelectUsers, SelectUser, ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { ItemExpandSection, Loading, NoRecordsBox, CenterBox, Circle2, Countdown, DateTimeItem, Section, Columns, ModalBasic, BoxItem, LabelItem, LinkButton, FlexExpand, FlexRow, TabBar, Text, TextLine, View, TrashIcon, FormItem, MyInput, TouchButton, SelectBox, FileElements, NoRecords } from "root/pack-1";
import { ChatWindow, getChatRoomsWorker, getChatRoomsBuyer } from "root/chat";
import { FormEditor } from "root/forms";
import { getDeliverablesForService } from "root/service-functions";
import { AddInputsButtonBuyer } from "root/services-buyer"
import { RestOfTeam } from "root/users"
import { RenderTasks } from "root/tasks"

const { money, yesNo, getCountItem, plural, getLabelItem, serviceTypes } = utils;

function RenderServiceOrderItem2({ item }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const { serviceType, id, assignedTo, service, servicePackage, orderDate, orderNumber, user, status, total, projectManager, strategyConsultant, parentServiceOrder } = item;
  const { serviceCore } = service;

  return (
    <BoxRowItem>
      <FlexRow>
        <FlexExpand>
          <TextLine bold label="Ref #:" value={orderNumber} />

          <TextLine
            size={22}
            value={service.name}
            onPress={() => {
              history.push(`/${sectionId}/service-order/${id}`);
            }}
          />
          {servicePackage ? <TextLine grey label="Package:" value={servicePackage.name} /> : null}
          {parentServiceOrder ? <TextLine grey label="Parent SO: #" value={parentServiceOrder.orderNumber + " - " + parentServiceOrder.service.name} onPress={() => {
            history.push(`/${sectionId}/service-order/${parentServiceOrder.id}`);
          }} /> : null}

          <FlexRow top={12}>
            {user ? <UserItem label="Client:" user={user} style={{ marginRight: 25 }} /> : null}

            {assignedTo ? <UserItem label="Consultant:" user={assignedTo} style={{ marginRight: 25 }} /> : null}
            {projectManager ? <UserItem label="PM:" user={projectManager} style={{ marginRight: 25 }} /> : null}
            {strategyConsultant ? <UserItem label="SC:" user={strategyConsultant} /> : null}
          </FlexRow>
        </FlexExpand>

        <View alignRight>
          <TextLine size={22} value={money(total)} />
          <TextLine size={12} uppercase color="grey" value={utils.getServiceOrderStatus(status)} />

          <View style={{ height: 12 }} />
          <DateTimeItem value={orderDate} fromNow />
        </View>
      </FlexRow>

      {serviceType ? <TextLine size={14} color="grey" top={10} value={getLabelItem(serviceTypes, serviceType) + ", " + getLabelItem(utils.serviceCores, serviceCore)} /> : null}
    </BoxRowItem>
  );
}

export function RenderServiceOrderItem({ showViewButton, hideServiceOrderNumber, hideOrderNumber, item, hideDate }) {
  const [state, setState] = useState({});
  const { optionVisible } = state;
  const { sectionId } = useParams();
  const history = useHistory();

  const { id, childServiceOrders, serviceVersion, parentServiceOrder, serviceOptionIds, allowedRevisions, canStart, orderDate, orderNumber, serviceOptions, status, service, servicePackage, deliveryType, total, deliveryDays, startDate, dueDate, requiredPreServices, order } = item;

  return (
    <FlexExpand>
      {!hideDate ? <LabelItem inline label="Order Date:" value={Moment(orderDate).format("M/D/YYYY [at] h:mm a")} /> : null}
      <FlexRow>
        <FlexExpand>
          <FlexRow>
            {!hideServiceOrderNumber ? <LabelItem label="Ref #:" value={orderNumber} /> : null}

            {!hideOrderNumber ? (
              <LabelItem
                label="Order #:"
                onPress={() => {
                  history.push(`/${sectionId}/order/${order.id}`);
                }}>
                {order.orderNumber}
              </LabelItem>
            ) : null}

            <FlexExpand>
              <ServicePackageItem service={service} serviceVersion={serviceVersion} servicePackage={servicePackage} serviceOptionIds={serviceOptionIds} deliveryType={deliveryType} size={16} />
            </FlexExpand>
          </FlexRow>
        </FlexExpand>

        {showViewButton ? <TouchButton
          micro
          style={{ marginLeft: 8 }}
          onPress={() => {
            history.push(`/${sectionId}/service-order/${id}`);
          }}
          label="View"
        /> : null}
      </FlexRow>

      {serviceOptions && serviceOptions.length > 0 ? (
        <LabelItem label="Service Options:">
          {serviceOptions && serviceOptions.length > 0 ? (
            <ul>
              {serviceOptions.map(item => {
                return (
                  <li>
                    <TextLine
                      size={16}
                      value={item.name}
                      onPress={() => {
                        setState({ ...state, optionVisible: item });
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          ) : null}
        </LabelItem>
      ) : null}

      {optionVisible ? (
        <ViewServiceOption
          hidePrice={true}
          service={service}
          item={optionVisible}
          onClose={() => {
            setState({ ...state, optionVisible: null });
          }}
        />
      ) : null}

      <Columns
        left={<LabelItem inline label="Status:" value={utils.getServiceOrderStatus(status)} />}
        middle={<LabelItem inline label="Total:" value={money(total)} />}
        right={
          status != "completed" ? (
            <LabelItem inline label="Delivery:">
              {utils.getDeliveryType(deliveryType)} ({plural(deliveryDays, "day")})
            </LabelItem>
          ) : null
        }
      />

      <FlexRow>
        {startDate > new Date() ? <LabelItem inline label="Start Date:" value={Moment(startDate).format("M/D/YY [at] h:mm a")} /> : null}

        {status == "working" || status == "ready" ? <LabelItem inline label="Delivery Date:" value={Moment(dueDate).format("M/D/YY")} /> : null}

        <LabelItem inline label="Can Start:" value={canStart ? yesNo(canStart) : "Waiting on other services"} />
      </FlexRow>

      <FlexRow>
        {parentServiceOrder ? <LabelItem label="Parent Service Order:">
          <TextLine grey value={"#" + parentServiceOrder.orderNumber + " - " + parentServiceOrder.service.name} onPress={() => {
            history.push(`/${sectionId}/service-order/${parentServiceOrder.id}`);
          }} />
        </LabelItem> : null}

        {allowedRevisions ? <LabelItem label="Revisions:" value={getLabelItem(utils.revisionOptions, allowedRevisions)} /> : null}
      </FlexRow>

      <RenderAssignedTo readonly item={item} />

      {requiredPreServices && childServiceOrders ? (
        <LabelItem label="Pre-Required Services:">
          {requiredPreServices.map(pId => {
            //this will be other services that are required for the completion of this service
            const serviceOrderItem = childServiceOrders.find(item => item.id == pId);
            const { service, servicePackage } = serviceOrderItem;

            return <ServicePackageItem service={service} servicePackage={servicePackage} size={16} />;
          })}
        </LabelItem>
      ) : null}
    </FlexExpand>
  );
}

export function RenderServiceOrdersBuyer({ serviceOrders }) {
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ tabValue: "all" });
  const [visible, setVisible] = useState(false);
  const [editItem, setEditItem] = useState();
  const { tabValue } = state;

  const serviceOrderTabs = [];

  const inputSOs = serviceOrders.filter(item => item.status == "pending");
  const clientPendingSOs = serviceOrders.filter(item => item.status == "review");
  const readySOs = serviceOrders.filter(item => item.status == "ready");
  const workingSOs = serviceOrders.filter(item => item.status == "working");
  const completedSOs = serviceOrders.filter(item => item.status == "completed");

  if (serviceOrders.length > 0) {
    serviceOrderTabs.push({ label: "All (" + serviceOrders.length + ")", value: "all" });
  }

  if (inputSOs.length > 0) {
    serviceOrderTabs.push({ label: "Pending Questions (" + inputSOs.length + ")", value: "pending", description: "The following order items require input from you to get started." });
  }

  if (readySOs.length > 0) {
    serviceOrderTabs.push({ label: "Ready (" + readySOs.length + ")", value: "ready", description: "The following order items are ready to start work." });
  }

  if (workingSOs.length > 0) {
    serviceOrderTabs.push({ label: "Working (" + workingSOs.length + ")", value: "working", description: "The following order items are being worked on." });
  }

  if (clientPendingSOs.length > 0) {
    serviceOrderTabs.push({ label: "Pending Review (" + clientPendingSOs.length + ")", value: "review", description: "The following order items need to be reviewed by you." });
  }

  if (completedSOs.length > 0) {
    serviceOrderTabs.push({ label: "Completed (" + completedSOs.length + ")", value: "completed" });
  }

  var fList = serviceOrders;

  if (tabValue == "pending") {
    fList = fList.filter(item => item.status == "pending");
  } else if (tabValue == "ready") {
    fList = fList.filter(item => item.status == "ready");
  } else if (tabValue == "working") {
    fList = fList.filter(item => item.status == "working");
  } else if (tabValue == "review") {
    fList = fList.filter(item => item.status == "review");
  } else if (tabValue == "completed") {
    fList = fList.filter(item => item.status == "completed");
  }

  return (
    <Section>
      <TabBar
        queryId="tbs"
        style={{ marginBottom: 15 }}
        options={serviceOrderTabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {fList.map(serviceOrder => {
        return <RenderServiceOrderRowItem key={serviceOrder.id} serviceOrder={serviceOrder} />
      })}
    </Section>
  );
}

function RenderServiceOrderRowItemLoad({ serviceOrderId }) {
  const [state, setState] = useState({ isLoading: true })
  const { isLoading, serviceOrder } = state

  useEffect(() => {
    dL.getServiceOrder2(serviceOrderId).then(function (serviceOrder) {
      setState({ ...state, isLoading: false, serviceOrder })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return <RenderServiceOrderRowItem serviceOrder={serviceOrder} />
}

const hasAccess = function ({ parentServiceOrder }) {
  const userIds = {}

  if (parentServiceOrder) {
    const { assignedTo, projectManager, strategyConsultant } = parentServiceOrder
    if (assignedTo) { userIds[assignedTo.id] = true }
    if (projectManager) { userIds[projectManager.id] = true }
    if (strategyConsultant) { userIds[strategyConsultant.id] = true }

  }
  return userIds[session.user.id] != null
}


function RenderServiceOrderRowItem({ serviceOrder, onUpdate }) {
  const [state, setState] = useState({})
  const { sectionId } = useParams();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [editItem, setEditItem] = useState();

  const { parentServiceOrder, order, inputData, clientReviewDueDate, status, lastServiceDelivery, dueDate, serviceVersion, id, childServiceOrders } = serviceOrder;
  const { inputs } = serviceVersion
  return (
    <BoxRowItem>
      <RenderServiceOrderDetailItem disableView item={serviceOrder} hideOrderNumber hideDate onPress={() => {
        history.push(`/${sectionId}/service-order/${id}`);
      }} />

      {(!parentServiceOrder && sectionId == "user") || (sectionId == "worker" && hasAccess({ parentServiceOrder })) ? <React.Fragment>
        {status == "pending" ? (
          <BoxItem>
            <TextLine color="red" value="Service order requires you to answer questions before starting work." bottom={10} />

            <AddInputsButtonBuyer
              label="Answer Questions"
              inputs={inputs}
              inputData={inputData}
              onSave={value => {
                serviceOrder.inputData = value
                return dL.submitInputs({ serviceOrderId: id, inputData: value }).then(function () {
                  serviceOrder.hasInputsComplete = true;
                  serviceOrder.status = "ready";
                  alert("Submitted.");
                  setState({ ...state });
                }).then(function () {
                  onUpdate()
                });
              }}
              onSaveDraft={value => {
                serviceOrder.inputData = value
                dL.getObj("ServiceOrder", id)
                  .set("inputData", JSON.stringify(value))
                  .save()
                setState({ ...state });
              }}
            />
          </BoxItem>
        ) : status == "working" || status == "ready" ? (
          <LabelItem label="Time left till delivery:">
            <Countdown date={dueDate} />
          </LabelItem>
        ) : status == "review" ? (
          <BoxRowItem>
            <HeaderText subHeader label="Deliveries:" description="Recent delivery sent for review and approval." />

            <Section>
              <ServiceDeliveryItem
                item={lastServiceDelivery}
                onUpdate={() => {
                  setState({ ...state, refresh: new Date() });
                }}
              />
            </Section>

            <LabelItem style={{ marginBottom: 25 }} label="Time left for review:">
              <Countdown date={clientReviewDueDate} />
            </LabelItem>

            <Columns
              left={<TouchButton
                onPress={() => {
                  return dL
                    .approveDelivery(lastServiceDelivery.id, {
                      serviceOrderId: id,
                      orderId: order ? order.id : null,
                      userId: session.user.id
                    })
                    .then(function () {
                      onUpdate();
                    });
                }}
                label="Accept Delivery"
              />}
              right={<TouchButton
                variant="secondary"
                onPress={() => {
                  setVisible(true);
                  setEditItem({});
                  onUpdate();
                }}
                label="Request Modifications"
              />}
            />

            {visible ? (
              <RenderRequestModifications
                onCancel={() => {
                  setVisible(false);
                }}
                onOk={editItem => {
                  return dL
                    .requestModifyDelivery(lastServiceDelivery.id, {
                      orderId: order ? order.id : null,
                      serviceOrderId: id,
                      comments: editItem.comments,
                      files: editItem.files,
                      userId: session.user.id
                    })
                    .then(function () {
                      setVisible(false);
                      onUpdate();
                    });
                }}
                editItem={editItem}
              />
            ) : null}
          </BoxRowItem>
        ) : status == "completed" ? (
          <View>
            <FileElements readonly label="Final Deliverables:" value={lastServiceDelivery.files} />
          </View>
        ) : null}
      </React.Fragment> : null}

      {childServiceOrders ? <ItemExpandSection label={plural(childServiceOrders.length, "Sub-Service")}>
        {childServiceOrders.map(item => {
          return <RenderServiceOrderRowItemLoad serviceOrderId={item.id} onUpdate={onUpdate} />
        })}
      </ItemExpandSection> : null}
    </BoxRowItem>
  );
}

export function RenderServiceOrdersWorker({ isSubOrders, orderId, serviceOrders, onUpdate }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ tabValue: "all" });
  const [visible, setVisible] = useState(false);
  const [editItem, setEditItem] = useState();
  const { tabValue } = state;

  const serviceOrderTabs = [];

  const inputSOs = serviceOrders.filter(item => item.status == "pending");
  const clientPendingSOs = serviceOrders.filter(item => item.status == "review");
  const readySOs = serviceOrders.filter(item => item.status == "ready");
  const workingSOs = serviceOrders.filter(item => item.status == "working");
  const completedSOs = serviceOrders.filter(item => item.status == "completed");

  if (serviceOrders.length > 0) {
    serviceOrderTabs.push({ label: "All (" + serviceOrders.length + ")", value: "all" });
  }

  if (inputSOs.length > 0) {
    serviceOrderTabs.push({ label: "Pending Questions (" + inputSOs.length + ")", value: "pending", description: "The following order items require input from the client." });
  }

  if (readySOs.length > 0) {
    serviceOrderTabs.push({ label: "Ready to Work (" + readySOs.length + ")", value: "ready", description: "The following order items are ready to be worked on." });
  }

  if (workingSOs.length > 0) {
    serviceOrderTabs.push({ label: "Working (" + workingSOs.length + ")", value: "working", description: "The following order items are being worked on." });
  }

  if (clientPendingSOs.length > 0) {
    serviceOrderTabs.push({ label: "Pending Review (" + clientPendingSOs.length + ")", value: "review", description: "The following order items are pending review by the client." });
  }

  if (completedSOs.length > 0) {
    serviceOrderTabs.push({ label: "Completed (" + completedSOs.length + ")", value: "completed" });
  }

  var fList = serviceOrders;

  if (tabValue == "pending") {
    fList = fList.filter(item => item.status == "pending");
  } else if (tabValue == "ready") {
    fList = fList.filter(item => item.status == "ready");
  } else if (tabValue == "working") {
    fList = fList.filter(item => item.status == "working");
  } else if (tabValue == "review") {
    fList = fList.filter(item => item.status == "review");
  } else if (tabValue == "completed") {
    fList = fList.filter(item => item.status == "completed");
  }

  //service orders (number)
  //service, package selected, total, status, delivery date (time till delivery?), bundle selected, options selected, included deliverables, start date, can start (need service orders to complete before start)
  //service order: if project manager: show the work requests (new (submitted by workers), pending, declined, rejected)
  //change in

  return (
    <Section>
      <HeaderText subHeader label={isSubOrders ? "Sub-Service Orders" : "Service Orders"} description="Separate services that need to be completed for this order." />

      <TabBar
        queryId="tbs"
        style={{ marginBottom: 15 }}
        options={serviceOrderTabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {fList.map(item => {
        const { parentServiceOrder, childServiceOrders, clientReviewDueDate, id, status, lastServiceDelivery, dueDate, serviceVersion } = item;

        return (
          <BoxRowItem>
            <RenderServiceOrderItem
              item={item}
              hideDate
              showViewButton
            />

            {status == "pending" ? (
              (sectionId == "worker" && hasAccess({ parentServiceOrder }) ? <TouchButton
                onPress={() => {
                  history.push(`/${sectionId}/service-order/${id}`);
                }}
                label="Submit Questions"
              /> : null)
            ) : status == "working" || status == "ready" ? (
              <LabelItem label="Time left till delivery:">
                <Countdown date={dueDate} />
              </LabelItem>
            ) : status == "review" ? (
              <BoxRowItem>
                <HeaderText subHeader label="Delivery:" description="Recent delivery sent for review and approval." />

                <Section>
                  <ServiceDeliveryItem
                    item={lastServiceDelivery}
                    onUpdate={() => {
                      setState({ ...state, refresh: new Date() });
                    }}
                  />
                </Section>

                <LabelItem style={{ marginBottom: 25 }} label="Time left for review:">
                  <Countdown date={clientReviewDueDate} />
                </LabelItem>

                <Columns
                  left={<TouchButton
                    onPress={() => {
                      return dL
                        .approveDelivery(lastServiceDelivery.id, {
                          serviceOrderId: id,
                          orderId,
                          userId: session.user.id
                        })
                        .then(function () {
                          onUpdate();
                        });
                    }}
                    label="Accept Delivery"
                  />}

                  right={<TouchButton
                    variant="secondary"
                    onPress={() => {
                      setVisible(true);
                      setEditItem({});
                      onUpdate();
                    }}
                    label="Request Modifications"
                  />}
                />

                {visible ? (
                  <RenderRequestModifications
                    onCancel={() => {
                      setVisible(false);
                    }}
                    onOk={editItem => {
                      return dL
                        .requestModifyDelivery(lastServiceDelivery.id, {
                          orderId,
                          serviceOrderId: id,
                          comments: editItem.comments,
                          files: editItem.files,
                          userId: session.user.id
                        })
                        .then(function () {
                          setVisible(false);
                          onUpdate();
                        });
                    }}
                    editItem={editItem}
                  />
                ) : null}
              </BoxRowItem>
            ) : status == "completed" ? (
              <View>
                All Final Deliveries
                <FileElements readonly label="Final Deliverables:" value={lastServiceDelivery.files} />
              </View>
            ) : null}

            {childServiceOrders ? <ItemExpandSection label={plural(childServiceOrders.length, "Sub-Service")}>
              {childServiceOrders.map(item => {
                return <RenderServiceOrderRowItemLoad serviceOrderId={item.id} onUpdate={onUpdate} />
              })}
            </ItemExpandSection> : null}
          </BoxRowItem>
        );
      })}
    </Section>
  );
}

export function RenderServiceOrderDetailItem({ onPress, hideServiceOrderNumber, hideOrderNumber, item, hideDate }) {
  const [state, setState] = useState({});
  const { optionVisible } = state;
  const { sectionId } = useParams();
  const history = useHistory();

  const { childServiceOrders, assignedTo, serviceVersion, serviceOptionIds, allowedRevisions, orderDate, orderNumber, serviceOptions, status, service, servicePackage, deliveryType, total, deliveryDays, dueDate, requiredPreServices, order } = item;

  return (
    <FlexExpand>
      {!hideDate ? <LabelItem inline label="Order Date:" value={Moment(orderDate).format("M/D/YYYY [at] h:mm a")} /> : null}
      <FlexRow>
        {!hideServiceOrderNumber ? <LabelItem label="Ref #:" value={orderNumber} /> : null}

        {!hideOrderNumber ? (
          <LabelItem
            label="Order #:"
            onPress={() => {
              history.push(`/${sectionId}/order/${order.id}`);
            }}>
            {order.orderNumber}
          </LabelItem>
        ) : null}

        <FlexExpand>
          <ServicePackageItem service={service} serviceVersion={serviceVersion} ervicePackage={servicePackage} serviceOptionIds={serviceOptionIds} deliveryType={deliveryType} size={16} />
        </FlexExpand>

        <LabelItem label="Delivery Date:" value={Moment(dueDate).format("M/D/YY")} />

        {onPress ? <TouchButton micro label="View" onPress={onPress} /> : null}
      </FlexRow>

      {serviceOptions && serviceOptions.length > 0 ? (
        <LabelItem label="Service Options:">
          {serviceOptions && serviceOptions.length > 0 ? (
            <ul>
              {serviceOptions.map(item => {
                return (
                  <li>
                    <TextLine
                      size={16}
                      value={item.name}
                      onPress={() => {
                        setState({ ...state, optionVisible: item });
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          ) : null}
        </LabelItem>
      ) : null}

      {optionVisible ? (
        <ViewServiceOption
          hidePrice={true}
          service={service}
          item={optionVisible}
          onClose={() => {
            setState({ ...state, optionVisible: null });
          }}
        />
      ) : null}

      <Columns
        left={<LabelItem inline label="Status:" value={utils.getServiceOrderStatus(status)} />}
        middle={<LabelItem inline label="Total:" value={utils.money(total)} />}
        right={
          status != "completed" ? (
            <LabelItem inline label="Delivery:">
              {utils.getDeliveryType(deliveryType)} ({plural(deliveryDays, "day")})
            </LabelItem>
          ) : null
        }
      />

      <FlexRow>
        {allowedRevisions ? <LabelItem label="Revisions:" value={getLabelItem(utils.revisionOptions, allowedRevisions)} /> : null}
      </FlexRow>

      {requiredPreServices && childServiceOrders ? (
        <LabelItem label="Pre-Required Services:">
          {utils.renderArray(
            requiredPreServices.map(pId => {
              const serviceOrderItem = childServiceOrders.find(item => item.id == pId);
              const { service, servicePackage } = serviceOrderItem;

              return { label: service.name + (servicePackage ? " - " + servicePackage.name : "") };
            })
          )}
        </LabelItem>
      ) : null}

      {assignedTo ? <BoxItem >
        <UserItem label="Assigned Consultant:" user={assignedTo} commentIcon showProfileButton />
      </BoxItem> : null}
    </FlexExpand>
  );
}

export function ServiceOrdersAdmin() {
  const { sectionId } = useParams();
  const { path, url } = useRouteMatch();

  return (
    <ListRender2
      showSectorsWithItemsOnly={true}
      searchFields={[{ field: "orderNumber", prefix: "SO" }, { field: "email" }]}
      defaultSort={{ field: "orderDate", desc: true }}
      filters={[
        { label: "All", value: "all", disableStat: true },
        {
          label: "Pending Questions",
          value: "pending",
          onQuery: function (query) {
            query.equalTo("status", "pending");
          }
        },
        {
          label: "Waiting",
          value: "waiting",
          description: "These orders are waiting for other orders to completed before starting or have been delayed in execution.",
          onQuery: function (query) {
            query.equalTo("canStart", false);
          }
        },
        {
          label: "Ready to Start",
          value: "ready",
          onQuery: function (query) {
            query.equalTo("canStart", true);
            query.equalTo("status", "ready");
          }
        },
        {
          label: "Not Assigned",
          value: "not-assigned",
          onQuery: function (query) {
            query.existsObj("assignedTo", false);
          }
        },
        {
          label: "Working",
          value: "working",
          onQuery: function (query) {
            query.equalTo("status", "working");
          }
        },
        {
          label: "Pending Review",
          value: "review",
          onQuery: function (query) {
            query.equalTo("status", "review");
          }
        },
        {
          label: "Completed",
          value: "completed",
          onQuery: function (query) {
            query.equalTo("status", "completed");
          }
        },
        {
          label: "Reviewed",
          value: "reviewed",
          onQuery: function (query) {
            query.equalTo("hasReviewed", true);
          }
        }
      ]}
      onWhere={query => {
        const userId = session.user.id;
        if (sectionId == "worker") {
          query.equalTo("accessUsers.userId", userId);
        } else if (sectionId == "user") {
          query.equalTo("user", dL.getObj("User", userId));
        }
      }}
      type="ServiceOrder"
      emptyLabel="No service orders found."
      includes={["user", "assignedTo", "service", "servicePackage", "parentServiceOrder", "parentServiceOrder.service"]}
      title="Service Orders"
      description="Manage service orders."
      statuses={utils.serviceOrderStatuses}
      renderItem={item => {
        return <RenderServiceOrderItem2 item={item} />;
      }}
    />
  );
}

export function ServiceOrdersWorker({ teamId }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true, tabValue: "assigned" })
  const { tabValue, serviceOrders, isLoading } = state

  const userId = session.user.id;

  useEffect(() => {
    const query = db.getQuery("ServiceOrder")
    if (teamId) {
      query.equalTo("team", dL.getObj("Team", teamId));
    }
    query
      .equalTo("assignedTo", dL.getObj("User", userId))
      .containedIn("removed", [undefined, false])
      .select([])
      .find()
      .then(function (objs) {
        const serviceOrders = dL.loadObjects("ServiceOrder", objs)

        setState({ ...state, isLoading: false, serviceOrders })
      })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const renderTop = function () {
    return <TabBar
      queryId="tbB"
      style={{ marginRight: 25 }}
      options={[{ label: teamId ? "Assigned To Team" : "Assigned To You", value: "assigned" }, { label: "Sub-Services", value: "sub-services" }]}
      onChange={item => {
        setState({ ...state, tabValue: item.value });
      }}
      value={tabValue}
    />
  }

  return <React.Fragment>
    {tabValue == "sub-services" ? <ListRender
      renderTop={renderTop()}
      subHeader={teamId != null}
      searchFields={[{ field: "orderNumber", prefix: "SO" }]}
      defaultSort={{ field: "orderDate", desc: true }}
      filters={[
        {
          label: "Pending Questions",
          value: "pending",
          description: "These orders need input from you before they can be started.",
          onQuery: function (query) {
            query.equalTo("status", "pending");
          }
        },
        {
          label: "Waiting",
          value: "waiting",
          description: "These orders are waiting for other orders to completed before starting or have been delayed in execution.",
          onQuery: function (query) {
            query.equalTo("canStart", false);
          }
        },
        {
          label: "Ready to Start",
          value: "ready",
          description: "These orders are getting ready to be worked on.",
          onQuery: function (query) {
            query.equalTo("canStart", true);
            query.equalTo("status", "ready");
            query.containedIn("hasReviewed", [undefined, false]);
          }
        },
        {
          label: "Working",
          value: "working",
          description: "These orders are being worked on.",
          onQuery: function (query) {
            query.equalTo("status", "working");
            query.containedIn("hasReviewed", [undefined, false]);
          }
        },
        {
          label: "Review Deliverables",
          value: "review",
          description: "These orders have deliveries that have been submitted for review.",
          onQuery: function (query) {
            query.equalTo("status", "review");
          }
        },
        {
          label: "Completed",
          value: "completed",
          description: "These orders have been completed.",
          onQuery: function (query) {
            query.equalTo("status", "completed");
          }
        },
        {
          label: "Review Worker",
          value: "reviewed",
          description: "These orders are waiting for worker reviews to be completed. Help us improve our staff and quality of service.",
          onQuery: function (query) {
            query.equalTo("status", "completed");
            query.containedIn("hasReviewed", [undefined, false]);
          }
        }
      ]}
      onWhere={query => {
        query.containedInO("parentServiceOrder", serviceOrders);
      }}
      type="ServiceOrder"
      emptyLabel="No service orders found."
      includes={["user", "assignedTo", "service", "servicePackage"]}
      title="Sub Service: Service Orders"
      description="View and manage service orders that have been placed. Service orders are part of larger orders that you have placed."
      renderItem={item => {
        const { serviceType, id, assignedTo, service, servicePackage, orderDate, orderNumber, status, total } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/service-order/${id}`);
            }}>
            <FlexRow alignTop>
              <FlexExpand>
                <DateTimeItem value={orderDate} fromNow />
                <TextLine
                  bold
                  top={6}
                  onPress={() => {
                    history.push(`/${sectionId}/service-order/${id}`);
                  }}>
                  Ref #: {orderNumber}
                </TextLine>
                <TextLine size={22} value={service.name} />
                {servicePackage ? <TextLine grey value={"Package: " + servicePackage.name} /> : null}
                {serviceType ? <TextLine size={14} color="grey" value={getLabelItem(serviceTypes, serviceType)} /> : null}
              </FlexExpand>

              <View alignRight>
                <TextLine size={22} value={utils.money(total)} />
                <TextLine size={12} uppercase color="grey" value={utils.getServiceOrderStatus(status)} />

                {assignedTo ? <UserItem label="Consultant:" user={assignedTo} style={{ marginTop: 8 }} /> : <TextLine color="grey" value="Not assigned" />}
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    /> : tabValue == "assigned" ?
      <ListRender
        renderTop={renderTop()}
        subHeader={teamId != null}
        searchFields={[{ field: "orderNumber", prefix: "SO" }, { field: "email" }]}
        defaultSort={{ field: "orderDate", desc: true }}
        filters={[
          {
            label: "Pending Questions",
            value: "pending",
            onQuery: query => {
              query.equalTo("status", "pending");
            }
          },
          {
            label: "Waiting",
            value: "waiting",
            description: "These orders are waiting for other orders to completed before starting or have been delayed in execution.",
            onQuery: function (query) {
              query.equalTo("canStart", false);
            }
          },
          {
            label: "Ready to Start",
            value: "ready",
            onQuery: query => {
              query.equalTo("canStart", true);
              query.equalTo("status", "ready");
            }
          },
          {
            label: "Working",
            value: "working",
            onQuery: query => {
              query.equalTo("status", "working");
            }
          },
          {
            label: "Pending Review",
            value: "review",
            onQuery: query => {
              query.equalTo("status", "review");
            }
          },
          {
            label: "Completed",
            value: "completed",
            onQuery: query => {
              query.equalTo("status", "completed");
            }
          }
        ]}
        topFilters={[
          {
            label: "All",
            value: "all",
            onQuery: query => {
              query.equalTo("accessUsers.userId", userId);
            },
            onQueryStats: query => {
              query.equalTo("accessUsers.userId", userId);
              query.notContainedIn("status", ["completed", "review"]);
            }
          },
          {
            label: "PM",
            value: "pm",
            onQuery: query => {
              query.equalTo("projectManager", dL.getObj("User", userId));
            },
            onQueryStats: query => {
              query.notContainedIn("status", ["completed", "review"]);
            },
            autoHide: true
          },
          {
            label: "SC",
            value: "sc",
            onQuery: query => {
              query.equalTo("strategyConsultant", dL.getObj("User", userId));
            },
            onQueryStats: query => {
              query.notContainedIn("status", ["completed", "review"]);
            },
            autoHide: true
          },
          {
            label: "Worker",
            value: "worker",
            onQuery: query => {
              query.equalTo("assignedTo", dL.getObj("User", userId));
            },
            onQueryStats: query => {
              query.notContainedIn("status", ["completed", "review"]);
            },
            autoHide: true
          }
        ]}
        onWhere={query => {
          if (teamId) {
            query.equalTo("team", dL.getObj("Team", teamId));
          }
        }}
        type="ServiceOrder"
        emptyLabel="No service orders found."
        includes={["user", "assignedTo", "service", "servicePackage", "projectManager", "strategyConsultant", "parentServiceOrder", "parentServiceOrder.service"]}
        title="Service Orders"
        description="Manage service orders."
        renderItem={item => {
          return <RenderServiceOrderItem2 item={item} />;
        }}
      /> : "Unknown"
    }
  </React.Fragment>
}

export function ServiceOrdersBuyer({ projectId }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const [state, setState] = useState({ isLoading: true });
  const { isLoading, isEmptyRender } = state;
  const userId = session.user.id

  useEffect(() => {
    if (!projectId) {
      dL.getQuery("ServiceOrder")
        .equalTo("user", dL.getObj("User", userId))
        .existsObj("parentServiceOrder", false)
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          setState({ ...state, isLoading: false, isEmptyRender: count == 0 })
        })
    } else {
      setState({ ...state, isLoading: false })
    }
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isEmptyRender) {
    return <View>
      <HeaderText label="Orders"
        description="View and manage orders that have been placed." />

      <NoRecordsBox label="No orders have been placed." style={{ marginBottom: 10 }} />

      <FlexRow>
        <CenterBox style={{ flex: 1, paddingTop: 35, paddingBottom: 35 }}>
          <FontAwesomeIcon icon={faSearch} style={{ fontSize: 75, color: "grey" }} />
          <TextLine top={25} size={22} bold value="Search for new services" />
          <TextLine top={8} value="Over 250+ services in our service catalog to select from." />
          <View style={{ height: 35 }} />
          <TouchButton label="Search for Services" onPress={() => {
            history.push("/user/services")
          }} />
        </CenterBox>
        <View style={{ width: 25 }} />
        <CenterBox style={{ flex: 1, paddingTop: 35, paddingBottom: 35 }}>
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: 75, color: "grey" }} />
          <TextLine top={25} size={22} bold value="Open a new service request" />
          <TextLine top={8} value="Start a request for a certain services, ask us anything!" />
          <View style={{ height: 35 }} />
          <TouchButton label="New Request" onPress={() => {
            history.push("/user/service-request/edit")
          }} />
        </CenterBox>
      </ FlexRow>
    </View>
  }

  return (
    <ListRender
      searchFields={[{ field: "orderNumber", prefix: "SO" }]}
      defaultSort={{ field: "orderDate", desc: true }}
      filters={[
        {
          label: "Pending Questions",
          value: "pending",
          description: "These orders need input from you before they can be started.",
          onQuery: function (query) {
            query.equalTo("status", "pending");
          }
        },
        {
          label: "Waiting",
          value: "waiting",
          description: "These orders are waiting for other orders to completed before starting or have been delayed in execution.",
          onQuery: function (query) {
            query.equalTo("canStart", false);
          }
        },
        {
          label: "Ready to Start",
          value: "ready",
          description: "These orders are getting ready to be worked on.",
          onQuery: function (query) {
            query.equalTo("canStart", true);
            query.equalTo("status", "ready");
            query.containedIn("hasReviewed", [undefined, false]);
          }
        },
        {
          label: "Working",
          value: "working",
          description: "These orders are being worked on.",
          onQuery: function (query) {
            query.equalTo("status", "working");
            query.containedIn("hasReviewed", [undefined, false]);
          }
        },
        {
          label: "Review Deliverables",
          value: "review",
          description: "These orders have deliveries that have been submitted for review.",
          onQuery: function (query) {
            query.equalTo("status", "review");
          }
        },
        {
          label: "Completed",
          value: "completed",
          description: "These orders have been completed.",
          onQuery: function (query) {
            query.equalTo("status", "completed");
          }
        },
        {
          label: "Review Worker",
          value: "reviewed",
          description: "These orders are waiting for worker reviews to be completed. Help us improve our staff and quality of service.",
          onQuery: function (query) {
            query.equalTo("status", "completed");
            query.containedIn("hasReviewed", [undefined, false]);
          }
        }
      ]}
      onWhere={query => {
        const userId = session.user.id;
        if (projectId) {
          query.equalTo("project", dL.getObj("Project", projectId));
        } else {
          query.equalTo("user", dL.getObj("User", userId));
        }
        query.existsObj("parentServiceOrder", false);
      }}
      type="ServiceOrder"
      emptyLabel="No orders found."
      includes={["user", "assignedTo", "service", "servicePackage"]}
      subHeader={projectId != null}
      title="Orders"
      description="View and manage orders that have been placed."
      renderItem={item => {
        const { serviceType, id, assignedTo, service, servicePackage, orderDate, orderNumber, status, total } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/service-order/${id}`);
            }}>
            <FlexRow alignTop>
              <FlexExpand>
                <DateTimeItem value={orderDate} fromNow />
                <TextLine
                  bold
                  top={6}
                  onPress={() => {
                    history.push(`/${sectionId}/service-order/${id}`);
                  }}>
                  Ref #: {orderNumber}
                </TextLine>
                <TextLine size={22} value={service.name} />
                {servicePackage ? <TextLine grey value={"Package: " + servicePackage.name} /> : null}
                {serviceType ? <TextLine size={14} color="grey" value={getLabelItem(serviceTypes, serviceType)} /> : null}
              </FlexExpand>

              <View alignRight>
                <TextLine size={22} value={utils.money(total)} />
                <TextLine size={12} uppercase color="grey" value={utils.getServiceOrderStatus(status)} />

                {assignedTo ? <UserItem label="Consultant:" user={assignedTo} style={{ marginTop: 8 }} /> : <TextLine color="grey" value="Not assigned" />}
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function ServiceDeliveryItem({ isBuyer, hideName, item, onUpdate }) {
  const [state, setState] = useState({});
  const { deliveryVisible, deliverableVisible } = state;

  const { createdAt, status, createdBy, responseComments, responseFiles, responseBy, serviceDeliverable } = item;
  const { name } = serviceDeliverable;

  var statusText = ""
  if (status == "accept") {
    statusText = "Accepted"
  } else if (status == "decline") {
    statusText = "Request Modifications"
  } else if (status == "withdrawn") {
    statusText = "Withdrawn"
  } else if (status == "draft") {
    statusText = "Draft"
  } else if (status == "pending") {
    statusText = "Pending Review"
  } else {
    statusText = "Unknown: " + status
  }

  return (
    <FlexRow alignTop>
      <FlexExpand>
        {!hideName ? (
          <TextLine
            bold
            value={name}
            onPress={() => {
              setState({ ...state, deliveryVisible: true });
            }}
            bottom={15}
          />
        ) : null}
        <Columns left={<LabelItem label="Delivery Date:" value={Moment(createdAt).fromNow()} />} middle={<LabelItem label="Delivery By:" value={createdBy.firstName + " " + createdBy.lastName} />} right={<LabelItem label="Status:" value={statusText} />} />

        {status == "declined" ? (
          <React.Fragment>
            {responseBy ? <LabelItem label="Response By:" value={responseBy.firstName + " " + responseBy.lastName} /> : null}

            {responseComments ? <LabelItem label="Client Comments:" value={responseComments} /> : null}
            {responseFiles && responseFiles.length > 0 ? <FileElements style={{ marginBottom: 0 }} readonly label="Client Additional Files:" value={responseFiles} /> : null}
          </React.Fragment>
        ) : null}
      </FlexExpand>

      <TouchButton
        style={{ marginRight: 8 }}
        onPress={() => {
          setState({ ...state, deliveryVisible: true });
        }}
        micro
        label="View"
      />

      {deliveryVisible ? (
        <ViewServiceDelivery
          isBuyer={isBuyer}
          serviceOrder={item.serviceOrder}
          item={item}
          onClose={() => {
            setState({ ...state, deliveryVisible: null });
          }}
          onUpdate={() => {
            onUpdate();
            setState({ ...state, deliveryVisible: null });
          }}
        />
      ) : null}

      {deliverableVisible ? (
        <ViewServiceDeliverable
          item={deliverableVisible}
          onClose={() => {
            setState({ ...state, deliverableVisible: null });
          }}
        />
      ) : null}
    </FlexRow>
  );
}

function ReviewServiceOrder({ review, onChange, onSubmit }) {
  const expSelect = function (item, value) {
    review[item.value] = value;
    onChange();
  };

  return (
    <Section>
      <HeaderText subHeader label="Review:" description="Please review our the work quality." />

      <div class="row">
        <div class="col-md-4" />
        <div class="col-md-2 row-item-header">None</div>
        <div class="col-md-2 row-item-header">A Little</div>
        <div class="col-md-2 row-item-header">Somewhat</div>
        <div class="col-md-2 row-item-header">Lots</div>
      </div>

      {utils.reviewCategories.map(item => {
        const { value, label } = item;
        const value1 = review[value];

        return (
          <div className="row expertise-row">
            <div className="col-md-4">
              <b>{label}:</b>
            </div>
            <div
              className="col-md-2 radio-sel"
              onClick={() => {
                expSelect(item, null);
              }}>
              <Circle2 selected={!value1} />
            </div>
            <div
              className="col-md-2 radio-sel"
              onClick={() => {
                expSelect(item, "little");
              }}>
              <Circle2 selected={value1 == "little"} />
            </div>
            <div
              className="col-md-2 radio-sel"
              onClick={() => {
                expSelect(item, "somewhat");
              }}>
              <Circle2 selected={value1 == "somewhat"} />
            </div>
            <div
              className="col-md-2 radio-sel"
              onClick={() => {
                expSelect(item, "lots");
              }}>
              <Circle2 selected={value1 == "lots"} />
            </div>
          </div>
        );
      })}

      <MyInput
        multiline
        label="Comments:"
        value={review.comments}
        onChange={value => {
          review.comments = value;
          onChange();
        }}
      />
      <TouchButton
        onPress={() => {
          onSubmit();
        }}
        label="Submit Review"
      />
    </Section>
  );
}

export function ServicePackageItem({ disableView, service, serviceVersion, servicePackage, serviceOptionIds, deliveryType, size }) {
  const [state, setState] = useState({});
  const { serviceVisible } = state;

  return (
    <FlexRow>
      <LabelItem label="Service:">
        <TextLine
          onPress={!disableView ? () => {
            setState({ ...state, serviceVisible: true });
          } : null}
          size={size}
          value={service.name}
        />
      </LabelItem>

      {servicePackage ? (
        <LabelItem label="Bundled Package:">
          <TextLine
            onPress={!disableView ? () => {
              setState({ ...state, serviceVisible: true });
            } : null}
            size={size}
            value={servicePackage.name}
          />
        </LabelItem>
      ) : null}

      {serviceVisible ? (
        <ViewServicePurchased
          hidePrice
          deliveryType={deliveryType}
          serviceId={service.id}
          serviceVersion={serviceVersion}
          packageId={servicePackage ? servicePackage.package.id : null}
          serviceOptionIds={serviceOptionIds}
          onClose={() => {
            setState({ ...state, serviceVisible: false });
          }}
        />
      ) : null}
    </FlexRow>
  );
}

function RenderAssignedTo({ readonly, item, onUpdate }) {
  const [updatedAt, setUpdatedAt] = useState(new Date());

  const { status, id, assignedTo, order } = item;

  const serviceOrderId = id;

  if (readonly) {
    return assignedTo ? <BoxItem >
      <UserItem label="Assigned Consultant:" user={assignedTo} commentIcon showProfileButton />
    </BoxItem> : <LabelItem label="Assigned Consultant:" value="Not assigned yet." />
  }

  return (
    <LabelItem label="Assigned Consultant:">
      {assignedTo ? (
        <FlexRow style={{ flex: 1 }}>
          <UserItem size={35} user={assignedTo} commentIcon />

          {status != "completed" ? (
            <LinkButton
              style={{ marginLeft: 12 }}
              onPress={() => {
                dL.setServiceOrderWorker({
                  orderId: order.id,
                  clear: true,
                  serviceOrderId,
                  userId: item.assignedTo.id
                }).then(function () {
                  item.assignedTo = null;
                  setUpdatedAt(new Date());
                  onUpdate();
                });
              }}
              label="Clear"
            />
          ) : null}
        </FlexRow>
      ) : (
        <SelectUser
          onChange={user => {
            item.assignedTo = user;

            dL.setWorkRequestAssigned(id, {
              orderId: order.id,
              serviceOrderId,
              userId: user.id
            }).then(function () {
              setUpdatedAt(new Date());
              onUpdate();
            });
          }}
        />
      )}
    </LabelItem>
  );
}

function RenderRequestModifications({ editItem, onCancel, onOk }) {
  const [state, setState] = useState({ model: editItem });
  const { model } = state;
  const { comments, files } = model;

  return (
    <ModalBasic
      title="Request Modifications"
      okText="Send Modification Request"
      onCancel={onCancel}
      onOk={() => {
        if (!comments) {
          alert("Must enter comments.");
          return;
        }

        return onOk(model);
      }}>
      <MyInput
        multiline
        label="Comments:"
        value={comments}
        onChange={value => {
          model.comments = value;
          setState({ ...state, model });
        }}
      />
      <FileElements
        style={{ marginBottom: 0 }}
        label="Additional Files:"
        value={files}
        onChange={value => {
          model.files = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

function ServiceOrderCancelDialog({ serviceOrderId, onCancel, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { returnAmount, isLoading } = state;

  useEffect(() => {
    dL.getServiceOrderCancelCost({ serviceOrderId })
      .then(function ({ amount }) {
        setState({ ...state, returnAmount: amount, isLoading: false });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalBasic
      title="Cancel Service Order"
      okText="Cancel Service"
      onCancel={onCancel}
      onOk={() => {
        confirmAlert({
          title: "Alert!",
          message: "Are you sure you want to cancel this service?",
          buttons: [
            {
              label: "Yes, cancel service.",
              onClick: () => {
                return dL.cancelServiceOrder({ serviceOrderId }).then(function () {
                  onSave()
                })
              }
            },
            {
              label: "No"
            }
          ]
        });
      }}>

      <LabelItem label="You will be credited the following amount:" value={money(returnAmount)} />
    </ModalBasic>
  );
}

export function ServiceOrderWorker() {
  const history = useHistory();
  const { serviceOrderId, sectionId } = useParams();
  const [state, setState] = useState({ tabValueDD: "draft", tabValueM: "details", tabValue2: "chat", isLoading: true });
  const [tabValue1, setTabValue1] = useState("current");
  const [refresh, setRefresh] = useState(new Date());
  const { cancelServiceVisible, allTeam, alerts, isLoading, tabValue2, model, deliveries } = state;

  const { tabValueM } = state;

  const getServiceOrderAlerts = function (serviceOrder) {
    const alerts = []
    const promises = []

    promises[promises.length] = dL.getQuery("ServiceOrder")
      .equalTo("parentSOIds", serviceOrderId)
      .notContainedIn("status", ["completed", "canceled"])
      .lessThan("dueDate", new Date())
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        if (objs.length > 0) {
          alerts.push({
            message: "Service orders past due.", data: {
              serviceOrders: dL.loadObjects("ServiceOrder", objs)
            }
          })
        }
      })

    const { requiredProposalItems, requiredServiceOrders, requiredTasks } = serviceOrder
    if (requiredProposalItems) {
      promises[promises.length] = dL.getQuery("ServiceOrder")
        .containedInAO("proposalItem", requiredProposalItems)
        .notContainedIn("status", ["completed", "canceled"])
        .containedIn("removed", [undefined, false])
        .find()
        .then(function (objs) {
          //pre-required service orders not completed
          if (objs.length > 0) {
            alerts.push({
              message: "Pre-required service orders for the proposal not completed.", data: {
                serviceOrders: dL.loadObjects("ServiceOrder", objs)
              }
            })
          }
        })
    }
    if (requiredServiceOrders) {
      promises[promises.length] = dL.getQuery("ServiceOrder")
        .containedIn("_id", requiredServiceOrders.map(obj => obj.id))
        .notContainedIn("status", ["completed", "canceled"])
        .containedIn("removed", [undefined, false])
        .find()
        .then(function (objs) {
          //pre-required service orders not completed
          if (objs.length > 0) {
            alerts.push({
              message: "Pre-required service orders not completed.", data: {
                serviceOrders: dL.loadObjects("ServiceOrder", objs)
              }
            })
          }
        })
    }

    if (requiredTasks) {
      promises[promises.length] = dL.getQuery("TaskRecord")
        .containedIn("_id", requiredTasks.map(obj => obj.id))
        .notContainedIn("status", ["completed", "canceled"])
        .containedIn("removed", [undefined, false])
        .find()
        .then(function (objs) {
          //pre-required tasks not completed
          if (objs.length > 0) {
            alerts.push({
              message: "Pre-required tasks not completed.", data: {
                tasks: dL.loadObjects("TaskRecord", objs)
              }
            })
          }
        })
    }

    return Promise.all(promises).then(function () { return alerts })
  }

  useEffect(() => {
    dL.getServiceOrderFull(serviceOrderId)
      .then(function ({ serviceOrder, deliveries, allTeam }) {
        return getServiceOrderAlerts(serviceOrder).then(function (alerts) {
          setState({ ...state, model: serviceOrder, deliveries, allTeam, isLoading: false, alerts });
        })
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  const inputData = model.inputData ? model.inputData : {};
  const { chargeAccount, requiredTasks, requiredServiceOrders, requiredProposalItems, user, projectManager, strategyConsultant, workRequests, serviceOrders, hasReviewed, review, orderNumber, status, hasInputsComplete, service, id, assignedTo, order, tasks } = model;
  const orderId = order ? order.id : null;

  const { inputs } = service;

  var deliveriesList = deliveries;
  if (tabValue1 == "current") {
    deliveriesList = deliveriesList.filter(item => item.status == "pending" || item.status == "accept");
  }

  const buttons = [];

  const tabs = [{ label: "Details", value: "details" }];

  tabs.push({ label: "Team", value: "team" });
  tabs.push(getCountItem({ label: "Work Requests", value: "work-requests" }, workRequests));

  if (status == "pending" || (inputs && inputs.length > 0)) {
    tabs.push(getCountItem({ label: "Questions", value: "inputs" }, inputs));
  }

  tabs.push(getCountItem({ label: "Tasks", value: "tasks" }, tasks));

  if ((status == "ready" || status == "working" || status == "review" || status == "completed")) {
    if (service.deliverables.length > 0) {
      tabs.push(getCountItem({ label: "Deliveries", value: "deliveries" }, deliveries));
    } else {
      buttons.push({
        label: "Mark Completed", onPress: () => {
          dL.serviceOrderComplete({ orderId, serviceOrderId: model.id }).then(function () {
            alert("Completed.")
          })
        }
      })
    }
  }
  if (serviceOrders.length > 0) {
    tabs.push(getCountItem({ label: "Sub-Services", value: "services" }, serviceOrders));
  }

  if (status != "completed" && status != "canceled") {
    buttons.push({
      label: "Cancel Service", onPress: () => {
        setState({ ...state, cancelServiceVisible: true })
      }
    })
  }

  if (status == "ready" && assignedTo) {
    buttons.push({
      label: "Start Work", onPress: () => {
        return dL.getIsAccountUpToDate({ chargeAccount }).then(function (status) {
          if (status) {
            alert(status)
            return
          }
          return dL
            .startWorkServiceOrder({
              orderId,
              serviceOrderId
            })
            .then(function () {
              setRefresh(new Date());
            });
        })
      }
    })
  }

  if (status == "completed") {
    tabs.push({ label: "Review", value: "review" });
  }

  const chatRoomName = "Ref #: " + orderNumber;
  const chatRooms = getChatRoomsWorker({ id, chatRoomName, clientUsers: [user], growlyUsers: [projectManager, strategyConsultant, assignedTo, session.user] })

  const renderPanel = function () {
    return <React.Fragment>
      {tabValue2 == "history" ? <OrderHistory orderId={orderId} /> : null}

      {tabValue2 == "alerts" ? <View>
        {alerts.map(item => {
          const { message } = item
          return <BoxItem name={message} />
        })}
      </View> : null}
    </React.Fragment>;
  };

  return (
    <ChatWindow
      chatRooms={chatRooms}
      tabPanel={renderPanel()}
      onTabChange={item => {
        setState({ ...state, tabValue2: item.value });
      }}
      tabOptions={[{ label: plural(alerts.length, "Alert"), value: "alerts" }, { label: "Chat", value: "chat" }, { label: "History", value: "history" }]}
      tabValue={tabValue2}>
      <BasicTop title={"Service Order #" + orderNumber} buttons={buttons}
      />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 25 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValueM: item.value });
        }}
        value={tabValueM}
      />

      {tabValueM == "team" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Team:" description="The team associated with the service order." />

            <FlexRow>
              <RenderAssignedTo readonly={!(session.user.isSystemAdmin || (projectManager && projectManager.id == session.user.id) || (strategyConsultant && strategyConsultant.id == session.user.id))} item={model} onUpdate={() => {
                setRefresh(new Date())
              }} />
              <View style={{ width: 25 }} />

              <EditKeyUser
                roleName="strategyConsultant"
                label="Strategy Consultant:"
                readonly={sectionId != "admin"}
                onChange={value => {
                  dL.setServiceOrderUserRole({ role: "sc", clear: value ? false : true, serviceOrderId: model.id, userId: value ? value.id : model.projectManager.id });
                  model.strategyConsultant = value;
                  setState({ ...state, model });
                }}
                value={strategyConsultant}
              />
              <View style={{ width: 25 }} />
              <EditKeyUser
                roleName="projectManager"
                label="Project Manager:"
                readonly={sectionId != "admin"}
                onChange={value => {
                  dL.setServiceOrderUserRole({ role: "pm", clear: value ? false : true, serviceOrderId: model.id, userId: value ? value.id : model.projectManager.id });

                  model.projectManager = value;

                  setState({ ...state, model });
                }}
                value={projectManager}
              />
            </FlexRow>
          </Section>

          <RestOfTeam allTeam={allTeam} />
        </React.Fragment>
      ) : null}

      {tabValueM == "details" ? (
        <Section>
          <HeaderText subHeader label="Overview:" description="Details about the service order." />

          <RenderServiceOrderItem
            item={model}
            onUpdate={() => {
              setRefresh(new Date());
            }}
          />

          <Section>
            {requiredTasks ? <TextLine value={plural(requiredTasks.length, "pre-required task")} /> : null}
            {requiredServiceOrders ? <TextLine value={plural(requiredServiceOrders.length, "pre-required service order")} /> : null}
            {requiredProposalItems ? <TextLine value={plural(requiredProposalItems.length, "pre-required proposal item")} /> : null}
          </Section>
        </Section>
      ) : null}

      {tabValueM == "review" ? (
        <Section>
          <HeaderText subHeader label="Review:" description="Review submitted by client." />
          {!hasReviewed ? <TextLine value="Not review yet." /> : <DisplayReview review={review} />}
        </Section>
      ) : null}

      {tabValueM == "tasks" ? (
        <Section>
          <RenderTasks tasks={tasks} />
        </Section>
      ) : null}

      {tabValueM == "work-requests" ? (
        <Section>
          <RenderWorkRequests
            item={model}
            onUpdate={() => {
              setRefresh(new Date());
            }}
          />
        </Section>
      ) : null}

      {tabValueM == "inputs" ? (
        <Section>
          <HeaderText subHeader label="Questions:" description="Fill out these questions to start work on this service order." />

          {!hasInputsComplete ? <TextLine value="Not yet filled out." /> : <DisplayInputs inputs={inputs} inputData={inputData} />}
        </Section>
      ) : null}

      {tabValueM == "services" ? (
        <RenderServiceOrdersWorker
          orderId={orderId}
          serviceOrders={serviceOrders}
          onUpdate={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValueM == "deliveries" ? <RenderDeliveriesWorker serviceOrder={model} /> : null}

      {cancelServiceVisible ? <ServiceOrderCancelDialog serviceOrderId={serviceOrderId} onCancel={() => {
        setState({ ...state, cancelServiceVisible: false })
      }} onSave={() => {
        history.goBack()
        setState({ ...state, cancelServiceVisible: false })
      }} /> : null}
    </ChatWindow>
  );
}

const getDeliverableStatus = function (lastDelivery) {
  if (!lastDelivery) {
    return "Pending Submit"
  } else {
    const { status } = lastDelivery

    if (status == "accept") {
      return "Accepted"
    } else if (status == "decline") {
      return "Request Modifications"
    } else if (status == "withdrawn") {
      return "Withdrawn"
    } else if (status == "draft") {
      return "Pending Submit"
    } else if (status == "pending") {
      return "Pending Review"
    } else {
      return "Unknown: " + status
    }
  }
}

function RenderDeliveriesWorker({ serviceOrder }) {
  const [state, setState] = useState({ tabValueDD: "draft", tabValue: "current", isLoading: true });
  const { recordId, tabValueDD, deliverableVisible, editServiceDeliveryVisible, deliveryVisible, isLoading, tabValue, deliveriesList, refresh } = state;

  useEffect(() => {
    db.getQuery("ServiceDelivery")
      .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrder.id))
      .containedIn("removed", [undefined, false])
      .include("createdBy")
      .include("responseBy")
      .include("serviceDeliverable")
      .descending("createdAt")
      .find()
      .then(function (objs) {
        const deliveries = dL.loadObjects("ServiceDelivery", objs);
        setState({ ...state, isLoading: false, deliveriesList: deliveries });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { servicePackage, serviceOptionIds, serviceVersion } = serviceOrder;

  const includedDeliverables = getDeliverablesForService({ serviceVersion, packageId: servicePackage ? servicePackage.package.id : null, serviceOptionIds });

  var deliveriesFiltered = deliveriesList;
  if (tabValueDD == "draft") {
    deliveriesFiltered = deliveriesList.filter(item => item.status == "draft");
  } else if (tabValueDD == "sent") {
    deliveriesFiltered = deliveriesList.filter(item => item.status == "pending");
  } else if (tabValueDD == "approved") {
    deliveriesFiltered = deliveriesList.filter(item => item.status == "accept");
  } else if (tabValueDD == "declined") {
    deliveriesFiltered = deliveriesList.filter(item => item.status == "decline");
  } else if (tabValueDD == "withdrawn") {
    deliveriesFiltered = deliveriesList.filter(item => item.status == "withdrawn");
  }

  return (
    <Section>
      <HeaderText
        subHeader
        label="Deliveries:"
        description="Create a delivery for this service order."
        rightRender={<TabBar
          options={[{ label: "Current", value: "current" }, { label: "All History", value: "all" }]}
          onChange={item => {
            setState({ ...state, tabValue: item.value });
          }}
          value={tabValue}
        />}
      />

      <Section>
        <HeaderText subHeader label="Required Deliverables:" description="The following is a list of required deliverables for this service order" />

        {includedDeliverables.length == 0 ? (
          <NoRecords label="No deliverables included." />
        ) : (
          includedDeliverables.map(item => {
            const { id, name, description } = item;

            const deliveries = deliveriesList.filter(item => item.serviceDeliverable.id == id);
            const lastDelivery = deliveries.length > 0 ? deliveries[0] : null

            const statusText = getDeliverableStatus(lastDelivery)

            return (
              <BoxRowItem>
                <FlexRow alignTop>
                  <FlexExpand>
                    <TextLine
                      bold
                      value={name}
                      onPress={() => {
                        setState({ ...state, deliverableVisible: item });
                      }}
                    />
                    <TextLine grey size={14} value={description} />

                    <FlexRow top={12}>
                      <TextLine spacer size={14} uppercase value={statusText} />

                      {deliveries.length ? <TextLine size={14}>Delivered: {plural(deliveries.length, "time")}</TextLine> : null}
                    </FlexRow>
                  </FlexExpand>

                  {lastDelivery ? (
                    lastDelivery.status == "draft" ? <TouchButton
                      onPress={() => {
                        setState({ ...state, editServiceDeliveryVisible: true, recordId: lastDelivery.id });
                      }}
                      micro
                      grey
                      label="Edit Delivery"
                    /> :
                      lastDelivery.status == "decline" ? <TouchButton
                        onPress={() => {
                          setState({ ...state, editServiceDeliveryVisible: true, recordId: null, deliverableType: item, reviseServiceDelivery: lastDelivery });
                        }}
                        micro
                        label="Revise Delivery"
                      /> : <TouchButton
                        onPress={() => {
                          setState({ ...state, deliveryVisible: lastDelivery });
                        }}
                        micro
                        label="View Delivery"
                      />
                  ) : <TouchButton
                    onPress={() => {
                      setState({ ...state, editServiceDeliveryVisible: true, recordId: null, deliverableType: item });
                    }}
                    micro
                    grey
                    label="New Delivery"
                  />}
                </FlexRow>

                {/*<BoxRowItem key={item.id}>
                      <SmallHeader bottom={10} label="Current Delivery:" />
                      <ServiceDeliveryItem item={currentDelivery} />
                      <TouchButton
                        style={{marginRight: 8}}
                        onPress={() => {
                          setState({...state, deliveryVisible: currentDelivery});
                        }}
                        micro
                        label="Review Delivery"
                      />
                    </BoxRowItem>
                    <FlexRow top={12}>
                  <TextLine spacer size={14}>
                    Status: {currentDelivery ? currentDelivery.status : "Pending"}
                  </TextLine>
                  {deliveries.length ? <TextLine size={14}>Delivered: {deliveries.length} times</TextLine> : null}
                </FlexRow>*/}

                {tabValue == "all" ? (
                  <Section style={{ marginTop: 15, marginBottom: 0 }}>
                    <TextLine bold>Delivery History:</TextLine>
                    {deliveries.map(item => {
                      return (
                        <BoxRowItem key={item.id}>
                          <ServiceDeliveryItem
                            hideName
                            item={item}
                            onUpdate={() => {
                              setState({ ...state, refresh: new Date() });
                            }}
                          />
                        </BoxRowItem>
                      );
                    })}
                  </Section>
                ) : null}
              </BoxRowItem>
            );
          })
        )}
      </Section>

      {deliveryVisible ? (
        <ViewServiceDelivery
          serviceOrder={serviceOrder}
          item={deliveryVisible}
          onClose={() => {
            setState({ ...state, deliveryVisible: null });
          }}
          onUpdate={() => {
            setState({ ...state, refresh: new Date(), deliveryVisible: null });
          }}
        />
      ) : null}

      {deliverableVisible ? (
        <ViewServiceDeliverable
          item={deliverableVisible}
          onClose={() => {
            setState({ ...state, deliverableVisible: null });
          }}
        />
      ) : null}

      <Section>
        <HeaderText
          subHeader
          label="Submitted Deliveries:"
          description="List of all deliveries create or sent."
          onButtonPress={() => {
            setState({ ...state, editServiceDeliveryVisible: true, recordId: null });
          }}
        />

        <TabBar
          queryId="tb2"
          style={{ marginBottom: 25 }}
          options={[{ label: "Draft", value: "draft" }, { label: "Sent", value: "sent" }, { label: "Approved", value: "approved" }, { label: "Declined", value: "declined" }, { label: "Withdrawn", value: "withdrawn" }]}
          onChange={item => {
            setState({ ...state, tabValueDD: item.value });
          }}
          value={tabValueDD}
        />

        {deliveriesFiltered.length == 0 ? (
          <NoRecords label="No deliveries added." />
        ) : (
          deliveriesFiltered.map(item => {
            const { status, id } = item;

            if (status == "draft") {
              return (
                <BoxRowItem
                  key={item.id}
                  onEdit={() => {
                    setState({ ...state, editServiceDeliveryVisible: true, recordId: item.id });
                  }}
                  onDelete={() => {
                    dL.getObj("ServiceDelivery", id)
                      .set("removed", true)
                      .save()
                      .then(function () {
                        setState({ ...state, refresh: new Date() });
                      });
                  }}>
                  <ServiceDeliveryItem
                    item={item}
                    onUpdate={() => {
                      setState({ ...state, refresh: new Date() });
                    }}
                  />
                </BoxRowItem>
              );
            } else {
              return (
                <BoxRowItem key={item.id}>
                  <ServiceDeliveryItem
                    item={item}
                    onUpdate={() => {
                      setState({ ...state, refresh: new Date() });
                    }}
                  />
                </BoxRowItem>
              );
            }
          })
        )}
      </Section>

      {editServiceDeliveryVisible ? (
        <EditServiceDelivery
          recordId={recordId}
          reviseServiceDelivery={state.reviseServiceDelivery}
          defaultServiceDeliverable={state.deliverableType}
          serviceOrderId={serviceOrder.id}
          onCancel={() => {
            setState({ ...state, editServiceDeliveryVisible: false });
          }}
          onSave={() => {
            setState({ ...state, editServiceDeliveryVisible: false, refresh: new Date() });
          }}
        />
      ) : null}
    </Section>
  );
}

function RenderDeliveriesBuyer({ serviceOrder }) {
  const [state, setState] = useState({ tabValue: "current", isLoading: true });
  const { deliveryVisible, isLoading, tabValue, deliveriesList, refresh } = state;

  useEffect(() => {
    db.getQuery("ServiceDelivery")
      .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrder.id))
      .containedIn("status", ["pending", "accept", "decline"])
      .containedIn("removed", [undefined, false])
      .include("createdBy")
      .include("responseBy")
      .include("serviceDeliverable")
      .descending("createdAt")
      .find()
      .then(function (objs) {
        const deliveries = dL.loadObjects("ServiceDelivery", objs);
        setState({ ...state, isLoading: false, deliveriesList: deliveries });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { status, dueDate, servicePackage, serviceOptionIds, serviceVersion } = serviceOrder;

  const includedDeliverables = getDeliverablesForService({ serviceVersion, packageId: servicePackage ? servicePackage.package.id : null, serviceOptionIds });

  return (
    <Section>
      {deliveriesList.length > 0 ? (
        <HeaderText
          subHeader
          label="Deliveries:"
          description="See all the deliveries for this service order."
          rightRender={
            deliveriesList.length > 0 ? (
              <TabBar
                options={[{ label: "Current", value: "current" }, { label: "All History", value: "all" }]}
                onChange={item => {
                  setState({ ...state, tabValue: item.value });
                }}
                value={tabValue}
              />
            ) : null
          }
        />
      ) : status == "working" || status == "ready" ? (
        <Section>
          <HeaderText subHeader label="Time left till delivery:" description="The first delivery for this service order has not yet been submitted." />
          <Countdown date={dueDate} />
        </Section>
      ) : null}

      <Section>
        <HeaderText subHeader label="Included Deliverables:" description="The following is a list of pending deliverables for this service order" />

        {includedDeliverables.length == 0 ? (
          <NoRecords label="No deliverables included." />
        ) : (
          includedDeliverables.map(item => {
            const { id, name, description } = item;

            const deliveries = deliveriesList.filter(item => item.serviceDeliverable.id == id);
            const lastDelivery = deliveries.length > 0 ? deliveries[0] : null

            const statusText = getDeliverableStatus(lastDelivery)

            return (
              <BoxRowItem>
                <FlexRow alignTop>
                  <FlexExpand>
                    <TextLine
                      bold
                      value={name}
                      onPress={() => {
                        setState({ ...state, deliverableVisible: item });
                      }}
                    />
                    <TextLine grey size={14} value={description} />

                    <FlexRow top={12}>
                      <TextLine spacer size={14} uppercase value={statusText} />
                      {deliveries.length ? <TextLine size={14}>Delivered: {plural(deliveries.length, "time")}</TextLine> : null}
                    </FlexRow>
                  </FlexExpand>

                  {lastDelivery ? (
                    lastDelivery.status == "pending" ? <TouchButton
                      style={{ marginRight: 8 }}
                      onPress={() => {
                        setState({ ...state, deliveryVisible: lastDelivery });
                      }}
                      micro
                      label="Review Delivery"
                    /> : <TouchButton
                      grey
                      style={{ marginRight: 8 }}
                      onPress={() => {
                        setState({ ...state, deliveryVisible: lastDelivery });
                      }}
                      micro
                      label="View Last Delivery"
                    />
                  ) : null}
                </FlexRow>

                {tabValue == "all" ? (
                  <Section style={{ marginTop: 15, marginBottom: 0 }}>
                    <TextLine bold>Delivery History:</TextLine>
                    {deliveries.map(item => {
                      return (
                        <BoxRowItem key={item.id}>
                          <ServiceDeliveryItem
                            isBuyer={true}
                            hideName
                            item={item}
                            onUpdate={() => {
                              setState({ ...state, refresh: new Date() });
                            }}
                          />
                        </BoxRowItem>
                      );
                    })}
                  </Section>
                ) : null}
              </BoxRowItem>
            );
          })
        )}
      </Section>

      {deliveryVisible ? (
        <ViewServiceDelivery
          isBuyer={true}
          serviceOrder={serviceOrder}
          item={deliveryVisible}
          onClose={() => {
            setState({ ...state, deliveryVisible: null });
          }}
          onUpdate={() => {
            setState({ ...state, refresh: new Date(), deliveryVisible: null });
          }}
        />
      ) : null}

      {deliveriesList.length > 0 ? (
        <Section>
          <HeaderText subHeader label="Received Deliveries:" description="List of all deliveries received." />

          {deliveriesList.length == 0 ? (
            <NoRecords label="No deliveries found." />
          ) : (
            deliveriesList.map(item => {
              return (
                <BoxRowItem key={item.id}>
                  <ServiceDeliveryItem
                    isBuyer={true}
                    item={item}
                    onUpdate={() => {
                      setState({ ...state, refresh: new Date() });
                    }}
                  />
                </BoxRowItem>
              );
            })
          )}
        </Section>
      ) : null}
    </Section>
  );
}

export function ServiceOrderBuyer() {
  const { serviceOrderId, sectionId } = useParams();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ tabValueM: "details", tabValue2: "chat", isLoading: true });
  const [tabValue1, setTabValue1] = useState("current");
  const [tabValue3, setTabValue3] = useState("details");

  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(new Date());
  const { isLoading, tabValue2, model, deliveries, allTeam } = state;

  const { tabValueM } = state;

  useEffect(() => {
    dL.getServiceOrderFull(serviceOrderId)
      .then(function ({ serviceOrder, deliveries, allTeam }) {
        setState({ ...state, isLoading: false, model: serviceOrder, allTeam, deliveries });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh, serviceOrderId]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { requiredTasks, requiredServiceOrders, requiredProposalItems, serviceOrders, user, projectManager, strategyConsultant, hasReviewed, review, orderNumber, status, hasInputsComplete, service, id, assignedTo, order, serviceVersion } = model;

  const orderId = order ? order.id : null;
  const inputData = model.inputData ? model.inputData : {};
  const { inputs } = serviceVersion;

  const currentDeliveries = deliveries.filter(item => item.status == "pending" || item.status == "accept");

  const buttons = [];

  const tabs = [{ label: "Details", value: "details" }];

  tabs.push({ label: "Team", value: "team" });
  if (status == "pending" || (inputs && inputs.length > 0)) {
    tabs.push(getCountItem({ label: "Questions", value: "inputs" }, inputs));
  }
  if (status == "ready" || status == "working" || status == "review" || status == "completed") {
    tabs.push(getCountItem({ label: "Deliveries", value: "deliveries" }, currentDeliveries));
  }
  if (serviceOrders.length > 0) {
    tabs.push(getCountItem({ label: "Sub-Services", value: "services" }, serviceOrders));
  }
  if (status == "completed") {
    tabs.push({ label: "Review", value: "review" });
  }

  const chatRoomName = "Ref #: " + orderNumber;
  const chatRooms = getChatRoomsBuyer({ id, chatRoomName, clientUsers: [user], growlyUsers: [projectManager, strategyConsultant, assignedTo, session.user] })

  const renderPanel = function () {
    return <React.Fragment>{tabValue2 == "history" ? <OrderHistory orderId={orderId} /> : null}</React.Fragment>;
  };

  const funct1 = utils.debounce(() => {
    dL.saveInputs({
      orderId,
      serviceOrderId: id,
      inputData
    });
  }, 250);

  return (
    <ChatWindow
      emptyRender={<View>EMPTY</View>}
      chatRooms={chatRooms}
      tabPanel={renderPanel()}
      onTabChange={item => {
        setState({ ...state, tabValue2: item.value });
      }}
      tabOptions={[{ label: "Chat", value: "chat" }, { label: "History", value: "history" }]}
      tabValue={tabValue2}>
      <BasicTop title={"Service Order #" + orderNumber} buttons={buttons} />

      {tabs.length > 1 ? (
        <TabBar
          queryId="tb"
          style={{ marginBottom: 25 }}
          options={tabs}
          onChange={item => {
            setState({ ...state, tabValueM: item.value });
          }}
          value={tabValueM}
        />
      ) : null}

      {tabValueM == "services" ? (
        <React.Fragment>
          <RenderSubServiceOrdersBuyer
            serviceOrders={serviceOrders}
            onUpdate={() => {
              setRefresh(new Date());
            }}
          />

          <Section>
            {requiredTasks ? <TextLine value={plural(requiredTasks.length, "pre-required task")} /> : null}
            {requiredServiceOrders ? <TextLine value={plural(requiredServiceOrders.length, "pre-required service order")} /> : null}
            {requiredProposalItems ? <TextLine value={plural(requiredProposalItems.length, "pre-required proposal item")} /> : null}
          </Section>
        </React.Fragment>
      ) : null}

      {tabValueM == "team" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Team:" description="The team associated with the service order." />
            <FlexRow>
              <RenderAssignedTo readonly item={model} />

              <View style={{ width: 25 }} />

              <EditKeyUser
                roleName="strategyConsultant"
                label="Strategy Consultant:"
                readonly={sectionId != "admin"}
                onChange={value => {
                  dL.setOrderStrategyConsultant({ clear: value ? false : true, orderId: model.id, userId: value ? value.id : model.strategyConsultant.id });
                  model.strategyConsultant = value;
                  setState({ ...state, model });
                }}
                value={strategyConsultant}
              />

              <View style={{ width: 25 }} />
              <EditKeyUser
                roleName="projectManager"
                label="Project Manager:"
                readonly={sectionId != "admin"}
                onChange={value => {
                  dL.setOrderProjectManager({ clear: value ? false : true, orderId: model.id, userId: value ? value.id : model.projectManager.id });
                  model.projectManager = value;

                  setState({ ...state, model });
                }}
                value={projectManager}
              />
            </FlexRow>
          </Section>

          <RestOfTeam allTeam={allTeam} />
        </React.Fragment>
      ) : null}

      {tabValueM == "details" ? (
        <Section>
          <HeaderText subHeader label="Overview:" description="Details about the service order." />

          <FlexRow alignTop>
            <RenderServiceOrderDetailItem hideDate hideServiceOrderNumber item={model} />
          </FlexRow>
        </Section>
      ) : null}

      {tabValueM == "review" ? (
        !hasReviewed ? (
          <ReviewServiceOrder
            review={review}
            onChange={() => {
              setState({ ...state, model });
            }}
            onSubmit={() => {
              return dL
                .submitReview(serviceOrderId, review)
                .then(function () {
                  model.hasReviewed = true;
                  alert("Submitted.");
                  setState({ ...state });
                })
                .then(function () {
                  setRefresh(new Date());
                });
            }}
          />
        ) : (
          <TextLine value="Thanks for your review." />
        )
      ) : null}

      {tabValueM == "inputs" ? (
        <Section>
          {!hasInputsComplete ? (
            <View>
              <HeaderText subHeader label="Questions:" description="Fill out these questions to start work on this service order." />
              {inputs.map(item => {
                const { name, description, formItems, id } = item;
                if (!inputData[id]) {
                  inputData[id] = {};
                }
                const formData = inputData[id];

                return (
                  <View>
                    <View style={{ marginBottom: 10 }}>
                      <TextLine size={18} value={name} />
                      <TextLine size={14} color="grey" value={description} />
                    </View>

                    <FormEditor
                      formItems={formItems}
                      formData={formData}
                      onChange={() => {
                        funct1();
                      }}
                    />
                  </View>
                );
              })}

              <TouchButton
                onPress={() => {
                  return dL.submitInputs({ serviceOrderId: id, inputData })
                    .then(function () {
                      model.hasInputsComplete = true;
                      model.status = "ready";
                      alert("Questions Submitted.");
                      setState({ ...state });
                    })
                    .then(function () {
                      setRefresh(new Date());
                    });
                }}
                label="Submit Questions"
              />
            </View>
          ) : (
            <View>
              <HeaderText subHeader label="Questions:" description="You have already submitted the below response." />
              <DisplayInputs inputs={inputs} inputData={inputData} />
            </View>
          )}
        </Section>
      ) : null}

      {tabValueM == "deliveries" ? <RenderDeliveriesBuyer serviceOrder={model} /> : null}
    </ChatWindow>
  );
}

function ViewServiceDelivery({ serviceOrder, isBuyer, item, onClose, onUpdate }) {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({});
  const [editItem, setEditItem] = useState({});

  const { responseAt, id, comments, files, createdAt, status, createdBy, responseComments, responseFiles, responseBy, serviceDeliverable, formItems, formData } = item;

  const { deliverableType } = serviceDeliverable;

  const buttons = [];

  if (status == "pending" && !isBuyer) {
    buttons.push({
      label: "Withdraw Delivery",
      onPress: () => {
        return dL.withdrawDelivery(id).then(function () {
          onUpdate();
        });
      }
    });
  }
  if (status == "pending" && isBuyer) {
    buttons.push({
      label: "Accept Delivery",
      onPress: () => {
        return dL
          .approveDelivery(id, {
            serviceOrderId: serviceOrder.id,
            orderId: serviceOrder.order ? serviceOrder.order.id : null,
            userId: session.user.id
          })
          .then(function () {
            onUpdate();
          });
      }
    });
    buttons.push({
      label: "Request Modifications",
      onPress: () => {
        setVisible(true);
        setEditItem({});
        onUpdate();
      }
    });
  }
  buttons.push({ label: "Ok", onPress: onClose });

  return (
    <ModalBasic large title="Service Delivery:" buttons={buttons}>
      <View style={{ marginBottom: -25 }}>
        <Section>
          <FlexRow>
            <LabelItem label="Delivered On:" value={Moment(createdAt).fromNow()} />
            <LabelItem label="Delivery By:" value={createdBy.firstName + " " + createdBy.lastName} />
            <LabelItem label="Status:" value={utils.getServiceDeliveryStatus(status)} />
          </FlexRow>

          <LabelItem label="Delivery Comments:" value={comments} hideIfNull />
        </Section>

        {status == "decline" ? (
          <Section>
            <TextLine bold size={18} bottom={10}>
              Buyer Response:
          </TextLine>
            <FlexRow>
              <LabelItem label="Response On:" value={Moment(responseAt).fromNow()} /><LabelItem label="Response By:" value={responseBy.firstName + " " + responseBy.lastName} />
            </FlexRow>
            <LabelItem label="Response Comments:" value={responseComments} hideIfNull />

            {responseFiles && responseFiles.length > 0 ? <FileElements style={{ marginBottom: 0 }} readonly label="Additional Files:" value={responseFiles} /> : null}
          </Section>
        ) : null}

        {deliverableType == "form" || files && files.length > 0 ? <Section>
          <TextLine size={18} bold bottom={10}>
            Deliverable Response:
        </TextLine>

          {deliverableType == "form" ? (
            <DisplayFormItems formItems={formItems} formData={formData} />
          ) : (
            <React.Fragment>
              {files && files.length > 0 ? <FileElements readonly label="Deliverable Files:" value={files} /> : null}
            </React.Fragment>
          )}
        </Section> : null}

        {visible ? (
          <RenderRequestModifications
            onCancel={() => {
              setVisible(false);
            }}
            onOk={editItem => {
              return dL
                .requestModifyDelivery(id, {
                  serviceOrderId: serviceOrder.id,
                  orderId: serviceOrder.order ? serviceOrder.order.id : null,
                  comments: editItem.comments,
                  files: editItem.files,
                  userId: session.user.id
                })
                .then(function () {
                  setVisible(false);
                  onUpdate();
                  onClose();
                });
            }}
            editItem={editItem}
          />
        ) : null}
      </View>
    </ModalBasic>
  );
}

function EditServiceDelivery({ reviseServiceDelivery, defaultServiceDeliverable, serviceOrderId, recordId, onCancel, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model } = state;

  useEffect(() => {
    var delivery;
    var serviceOrderS;
    dL.getServiceOrderFull(serviceOrderId)
      .then(function ({ serviceOrder }) {
        serviceOrderS = serviceOrder;

        if (recordId) {
          return dL
            .getQuery("ServiceDelivery")
            .include("serviceDeliverable")
            .include("serviceDeliverableVersion")
            .get(recordId)
            .then(function (obj) {
              delivery = dL.loadServiceDelivery(obj);
            });
        }
      })
      .then(function () {
        const { order, service, servicePackage } = serviceOrderS;
        setState({
          ...state,
          isLoading: false,
          model: recordId
            ? delivery
            : {
              serviceDeliverable: defaultServiceDeliverable,
              order,
              service,
              servicePackage,
              serviceOrder: serviceOrderS,
              status: "draft",
              comments: reviseServiceDelivery ? reviseServiceDelivery.comments : "",
              files: reviseServiceDelivery ? reviseServiceDelivery.files : [],
              formData: reviseServiceDelivery ? reviseServiceDelivery.formData : {}
            }
        });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const { serviceDeliverableVersion, status, comments, files, formData, serviceDeliverable, serviceOrder } = model;
  const { serviceDeliverables } = serviceOrder;
  const { formItems, deliverableType } = serviceDeliverable ? serviceDeliverable : {};

  return (
    <ModalBasic
      title="Create Delivery"
      okText="Send Delivery"
      onCancel={onCancel}
      buttons={
        status == "draft"
          ? [
            {
              label: "Save Draft",
              onPress: () => {
                return dL.saveServiceDelivery(model.id, model).then(function () {
                  onSave();
                });
              }
            }
          ]
          : null
      }
      onOk={() => {
        if (deliverableType == "form") {
          if (!validateForm({ formItems, formData })) {
            return;
          }
        }
        return dL
          .saveServiceDelivery(model.id, model)
          .then(function (serviceDeliveryId) {
            return dL.sendServiceDelivery({ serviceDeliveryId });
          })
          .then(function () {
            onSave();
          });
      }}>
      <SelectBox
        required
        label="Deliverable Type:"
        value={serviceDeliverableVersion}
        options={serviceDeliverables}
        textField="name"
        valueField="id"
        onChange={item => {
          model.serviceDeliverableVersion = item
          model.serviceDeliverable = item.deliverable
          setState({ ...state, model });
        }}
      />


      <MyInput
        multiline
        label="Delivery Comments:"
        value={comments}
        onChange={value => {
          model.comments = value;
          setState({ ...state, model });
        }}
      />

      {serviceDeliverable ? (
        <React.Fragment>
          {deliverableType == "form" ? (
            <Section>
              <FormEditor formItems={formItems} formData={formData} />
            </Section>
          ) : (
            <View>
              <FileElements
                label="Deliverable Files:"
                value={files}
                onChange={value => {
                  model.files = value;
                  setState({ ...state, model });
                }}
              />
            </View>
          )}
        </React.Fragment>
      ) : null}
    </ModalBasic>
  );
}

function DisplayReview({ review }) {
  return (
    <View>
      <div class="row">
        <div class="col-md-4" />
        <div class="col-md-2 row-item-header">None</div>
        <div class="col-md-2 row-item-header">A Little</div>
        <div class="col-md-2 row-item-header">Somewhat</div>
        <div class="col-md-2 row-item-header">Lots</div>
      </div>

      {utils.reviewCategories.map(item => {
        const { value, label } = item;
        const value1 = review[value];

        return (
          <div className="row expertise-row">
            <div className="col-md-4">
              <b>{label}:</b>
            </div>
            <div className="col-md-2 radio-sel">
              <Circle2 selected={!value1} />
            </div>
            <div className="col-md-2 radio-sel">
              <Circle2 selected={value1 == "little"} />
            </div>
            <div className="col-md-2 radio-sel">
              <Circle2 selected={value1 == "somewhat"} />
            </div>
            <div className="col-md-2 radio-sel">
              <Circle2 selected={value1 == "lots"} />
            </div>
          </div>
        );
      })}

      <LabelItem label="Comments:" value={review.comments} />
    </View>
  );
}

function RenderWorkRequests({ item, onUpdate }) {
  const { workRequests, status, id, assignedTo, order } = item;

  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [visible, setVisible] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [comments, setComments] = useState("");
  const [assignedTo1, setAssignedTo1] = useState(null);
  const [tabValue2, setTabValue2] = useState(assignedTo ? "hide" : "show");

  const serviceOrderId = id;

  return (
    <View>
      <HeaderText
        subHeader
        label="Work Requests:"
        description="Send request to consultants to accept this job."
        onButtonPress={() => {
          setVisible(true);
        }}
      />

      {workRequests && workRequests.length == 0 ? (
        <NoRecords label="No work requests found." />
      ) : (
        workRequests.map((workRequest, index) => {
          const { id, user, status } = workRequest;

          return (
            <View style={{ marginBottom: 12 }}>
              <FlexRow>
                <UserItem size={35} user={user} />

                <Text style={{ marginLeft: 15 }}>{status}</Text>

                {status == "pending" ? (
                  <TrashIcon
                    style={{ marginLeft: 10 }}
                    onPress={() => {
                      dL.trashWorkRequest(id, { serviceOrderId }).then(function () {
                        workRequests.splice(index, 1);
                        setUpdatedAt(new Date());
                      });
                    }}
                  />
                ) : null}

                {!assignedTo || user.id != assignedTo.id ? (
                  <LinkButton
                    label={assignedTo ? "Re-Assign" : "Assign"}
                    style={{ marginLeft: 10 }}
                    onPress={() => {
                      item.assignedTo = user;

                      dL.setWorkRequestAssigned(id, {
                        orderId: order.id,
                        serviceOrderId,
                        userId: user.id
                      }).then(function () {
                        setTabValue2("hide");
                        setUpdatedAt(new Date());
                        onUpdate();
                      });
                    }}
                  />
                ) : null}
              </FlexRow>
            </View>
          );
        })
      )}

      {visible ? (
        <ModalBasic
          title="Send Work Requests"
          okText="Send Request"
          onCancel={() => {
            setVisible(false);
          }}
          onOk={() => {
            if (!assignedTo1) {
              alert("Must select assign to user.");
              return;
            }

            return Promise.all(
              assignedTo1.map(assignedTo => {
                return dL.createWorkRequest({
                  serviceOrderId,
                  comments: editItem.comments,
                  userId: assignedTo.id
                });
              })
            ).then(function () {
              setVisible(false);
              setAssignedTo1(null);
              setComments("");
              onUpdate();
            });
          }}>
          <FormItem label="User/s:">
            <SelectUsers
              excludedValues={workRequests.map(item => item.user)}
              onChange={value => {
                setAssignedTo1(value);
              }}
              value={assignedTo1}
            />
          </FormItem>

          <MyInput
            multiline
            label="Message:"
            value={comments}
            onChange={value => {
              setComments(value);
            }}
          />
        </ModalBasic>
      ) : null}
    </View>
  );
}

export function OrderHistory({ orderId, serviceOrderId }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, list } = state;

  useEffect(() => {
    dL.getOrderHistory({
      orderId,
      serviceOrderId
    }).then(function (list) {
      setState({ ...state, isLoading: false, list });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <View>
      {list.length == 0 ? (
        <NoRecords label="No history found." />
      ) : (
        list.map(item => {
          const { createdAt, description, createdBy } = item;
          return (
            <BoxRowItem style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                <DateTimeItem value={createdAt} fromNow />

                <TextLine bold>{description}</TextLine>

                {createdBy ? <UserItem user={createdBy} style={{ marginTop: 6 }} /> : null}
              </View>
            </BoxRowItem>
          );
        })
      )}
    </View>
  );
}

export function RenderSO({ serviceOrderId }) {
  const [state, setState] = useState({ isLoading: true })
  const { isLoading, serviceOrder } = state

  useEffect(() => {
    dL.getServiceOrder2(serviceOrderId).then(function (serviceOrder) {
      setState({ ...state, isLoading: false, serviceOrder })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { childServiceOrders } = serviceOrder;

  return <BoxRowItem>
    <RenderServiceOrderDetailItem disableView item={serviceOrder} hideOrderNumber hideDate />

    {childServiceOrders ? <ItemExpandSection label={plural(childServiceOrders.length, "Sub-Service")}>
      {childServiceOrders.map(item => {
        return <RenderSO serviceOrderId={item.id} />
      })}
    </ItemExpandSection> : null}
  </BoxRowItem>
}

export function RenderSubServiceOrdersBuyer({ serviceOrders, onUpdate }) {
  return (
    <Section>
      {serviceOrders.map(item => {
        const { id } = item;

        return <RenderSO serviceOrderId={id} />
      })}
    </Section>
  );
}
