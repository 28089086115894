import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import PromiseA from "root/utils/promise";
import Moment from "moment-business-days";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { RenderUser, DisplayKeyUser, EditKeyUser } from "root/pack-3";
import { DisplayTasksWorker, DisplayTasks } from "root/tasks";
import { DisplayAccountPayments, DisplayAccountCharges } from "root/charge-account";
import { DisplayTimeLogs, RenderTimeLog } from "root/time-logs";
import { SelectWorker } from "root/worker";
import { __DEV__ } from "root/dev";
import { confirmAlert } from "react-confirm-alert";
import { SelectUser, ListRender, BoxRowItem, HeaderText, LabelUserItem, UserItem } from "root/pack-2";
import { ItemExpandSection, Loading, NoRecordsBox, DisplayCenterBox, DeleteConfirm, Section, EditDialog, Columns, ModalBasic, BoxItem, LabelItem, FlexExpand, FlexRow, TabBar, Text, TextLine, View, Toggle, FormItem, MyInput, TouchButton, SelectBox, NoRecords } from "root/pack-1";
import { ChatWindow, getChatRoomsWorker, getChatRoomsBuyer } from "root/chat";
import { SelectCreditCard } from "root/credit-card";
import { priorityLevelsList, getPriorityLevel, getServiceTotal, getWorkerRate, getHoursPerMonth, getRateList } from "root/service-functions";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { WorkerSelection } from "root/worker";
import { RenderUserItem } from "root/users"
import { AddToCartDialog } from "root/services-buyer"

const { getTaskStatus, delayTypes, yesNo, getCountItem, plural, getLabelItem, money, skillLevels, staffServiceTypes, priorityLevels } = utils;

const marginRate = 1.25;

export function StaffServiceRecordWorker({ item, hideAssigned, hideClient }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { balanceDue, chargeAmount } = state;

  const { projectManager, status, chargeStatus, startDate, serviceType, numberOfMonths, monthlyCost, skillLevel, priorityLevel, businessRole, user, id, hoursPerMonth, createdAt, assignedTo } = item;

  const userId = session.user.id;
  const role = assignedTo && assignedTo.id == userId ? "worker" : projectManager && projectManager.id == userId ? "pm" : "";
  const roleText = assignedTo && assignedTo.id == userId ? "Worker" : projectManager && projectManager.id == userId ? "Project Manager" : "";

  useEffect(() => {
    if (status != "cart") {
      dL.getAccountBalance(id).then(function (amount) {
        return dL.getChargeBalance(id).then(function ({ chargeAmount }) {
          setState({ ...state, balanceDue: amount, chargeAmount });
        });
      });
    }
  }, []);

  if (role == "worker") {
    return (
      <BoxRowItem
        onPress={() => {
          history.push(`/${sectionId}/staff-service/${id}`);
        }}>
        <FlexRow alignTop>
          <View style={{ flex: 1 }}>
            <TextLine>
              {Moment(createdAt).format("M/D/YY")}: {Moment(createdAt).fromNow()}
            </TextLine>

            <TextLine bold size={18} style={{ marginTop: 6 }}>
              {getLabelItem(staffServiceTypes, serviceType)}: {businessRole.name}
            </TextLine>

            <FlexRow style={{ marginTop: 14 }}>
              {!hideClient ? <UserItem label="Client:" user={user} style={{ marginRight: 25 }} fontSize={14} /> : null}
              <UserItem label="PM:" user={projectManager} fontSize={14} style={{ marginRight: 25 }} />
              <UserItem label="Consultant:" user={assignedTo} fontSize={14} />
            </FlexRow>
          </View>

          <View alignRight>
            <TextLine size={22} value={plural(hoursPerMonth, "hr") + "/month"} />

            <TextLine top={6} color="grey" value={getLabelItem(priorityLevels, priorityLevel) + " Priority: " + plural(numberOfMonths, "month")} />

            {startDate > Moment().startOf("day") ? <TextLine size={14} color="grey" value={"Start: " + Moment(startDate).format("M/D/YYYY") + " (" + Moment(startDate).diff(Moment().startOf("day"), "days") + "D)"} /> : null}
          </View>
        </FlexRow>

        <StaffOrderStats staffOrderId={id} />
      </BoxRowItem>
    );
  } else {
    return (
      <BoxRowItem
        onPress={() => {
          history.push(`/${sectionId}/staff-service/${id}`);
        }}>
        <FlexRow alignTop>
          <View style={{ flex: 1 }}>
            <TextLine>
              {Moment(createdAt).format("M/D/YY")}: {Moment(createdAt).fromNow()}
            </TextLine>

            <TextLine bold size={18} style={{ marginTop: 6 }}>
              {getLabelItem(skillLevels, skillLevel) + " " + getLabelItem(staffServiceTypes, serviceType)}: {businessRole.name}
            </TextLine>

            <FlexRow style={{ marginTop: 14 }}>
              {!hideClient ? <UserItem label="Client:" user={user} style={{ marginRight: 25 }} fontSize={14} /> : null}
              <UserItem label="PM:" user={projectManager} fontSize={14} />
              {!hideAssigned ? <UserItem label="Consultant:" user={assignedTo} fontSize={14} style={{ marginRight: 25 }} /> : null}
            </FlexRow>
          </View>

          <View alignRight>
            <TextLine size={22} value={plural(hoursPerMonth, "hr") + " @ " + money(monthlyCost, 0) + "/month"} />

            <TextLine top={6} color="grey" value={getLabelItem(priorityLevels, priorityLevel) + " Priority: " + plural(numberOfMonths, "month")} />

            {startDate > Moment().startOf("day") ? <TextLine size={14} color="grey" value={"Start: " + Moment(startDate).format("M/D/YYYY") + " (" + Moment(startDate).diff(Moment().startOf("day"), "days") + "D)"} /> : null}

            {roleText ? <TextLine top={6} color="grey" value={roleText} /> : null}
          </View>
        </FlexRow>

        {balanceDue ? <FlexRow top={4}>{chargeStatus == "failed" ? <TextLine value={"Balance Due: " + money(balanceDue)} spacer /> : <TextLine value={"Charge Amount: " + money(chargeAmount)} />}</FlexRow> : null}

        <StaffOrderStats staffOrderId={id} />
      </BoxRowItem>
    );
  }
}

export function StaffOrderStats({ staffOrderId }) {
  const [state, setState] = useState({});
  const { stats } = state;

  useEffect(() => {
    return dL.getChargeBalance(staffOrderId).then(function ({ chargeAmount, stats }) {
      setState({ ...state, chargeAmount, stats });
    });
  }, []);

  const { timeSeconds, openTaskCount, openTaskHours, availableHoursForMonth } = stats ? stats : {};

  return stats ? (
    <FlexRow top={8}>
      <TextLine value={"Tasks: " + plural(openTaskCount, "task") + (openTaskCount > 0 ? " (" + plural(openTaskHours, "hr") + ")" : "")} spacer />
      <TextLine value={"Used Hours: " + Math.round(timeSeconds / 60, 1) + " of " + plural(availableHoursForMonth, "hr")} />
    </FlexRow>
  ) : <View />
}

export function StaffServiceRecordBuyer({ item, hideAssigned, hideClient }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { stats, balanceDue, chargeAmount } = state;

  const { projectManager, status, chargeStatus, startDate, serviceType, numberOfMonths, monthlyCost, skillLevel, priorityLevel, businessRole, user, id, hoursPerMonth, createdAt, assignedTo } = item;

  const { timeSeconds, openTaskCount, openTaskHours, availableHoursForMonth } = stats ? stats : {};

  useEffect(() => {
    if (status != "cart") {
      dL.getAccountBalance(id).then(function (amount) {
        return dL.getChargeBalance(id).then(function ({ chargeAmount, stats }) {
          setState({ ...state, balanceDue: amount, chargeAmount, stats });
        });
      });
    }
  }, []);

  return (
    <FlexRow alignTop>
      {assignedTo ? <View style={{ marginRight: 25 }}><RenderUserItem item={assignedTo} /></View> : null}

      <FlexExpand>
        <BoxRowItem
          onPress={() => {
            history.push(`/${sectionId}/staff-service/${id}`);
          }}>
          <FlexRow alignTop>
            <View style={{ flex: 1 }}>
              <TextLine>
                {Moment(createdAt).format("M/D/YY")}: {Moment(createdAt).fromNow()}
              </TextLine>

              <TextLine bold size={18} style={{ marginTop: 6 }}>
                {getLabelItem(skillLevels, skillLevel) + " " + getLabelItem(staffServiceTypes, serviceType)}: {businessRole.name}
              </TextLine>

              <FlexRow style={{ marginTop: 14 }}>
                <UserItem label="PM:" user={projectManager} fontSize={14} />
              </FlexRow>
            </View>

            <View alignRight>
              <TextLine size={22} value={plural(hoursPerMonth, "hr") + " @ " + money(monthlyCost, 0) + "/month"} />

              <TextLine top={6} size={14} color="grey" value={getLabelItem(priorityLevels, priorityLevel) + " Priority: " + plural(numberOfMonths, "month")} />

              {startDate > Moment().startOf("day") ? <TextLine size={14} color="grey" value={"Start: " + Moment(startDate).format("M/D/YYYY") + " (" + Moment(startDate).diff(Moment().startOf("day"), "days") + "D)"} /> : null}
            </View>
          </FlexRow>

          {balanceDue ? <FlexRow top={4}>{chargeStatus == "failed" ? <TextLine value={"Balance Due: " + money(balanceDue)} spacer /> : <TextLine value={"Charge Amount: " + money(chargeAmount)} />}</FlexRow> : null}

          {stats ? (
            <FlexRow top={15}>
              <TextLine value={"Tasks: " + plural(openTaskCount, "task") + (openTaskCount > 0 ? " (" + plural(openTaskHours, "hr") + ")" : "")} spacer />
              <TextLine value={"Used: " + Math.round(timeSeconds / 60, 1) + " of " + plural(availableHoursForMonth, "hr")} />
            </FlexRow>
          ) : null}
        </BoxRowItem>
      </FlexExpand>
    </FlexRow>
  );
}

export function StaffServiceRecord({ item, hideAssigned, hideClient }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { stats, balanceDue, chargeAmount } = state;

  const { projectManager, status, chargeStatus, startDate, serviceType, numberOfMonths, monthlyCost, skillLevel, priorityLevel, businessRole, user, id, hoursPerMonth, createdAt, assignedTo } = item;

  const { timeSeconds, openTaskCount, openTaskHours, availableHoursForMonth } = stats ? stats : {};

  const userId = session.user.id;
  const role = assignedTo && assignedTo.id == userId ? "worker" : projectManager && projectManager.id == userId ? "pm" : "";
  const roleText = assignedTo && assignedTo.id == userId ? "Worker" : projectManager && projectManager.id == userId ? "Project Manager" : "";

  useEffect(() => {
    if (status != "cart") {
      dL.getAccountBalance(id).then(function (amount) {
        return dL.getChargeBalance(id).then(function ({ chargeAmount, stats }) {
          setState({ ...state, balanceDue: amount, chargeAmount, stats });
        });
      });
    }
  }, []);

  if (role == "worker") {
    return (
      <View>
        {assignedTo ? <RenderUserItem item={assignedTo} /> : null}

        <BoxRowItem
          onPress={() => {
            history.push(`/${sectionId}/staff-service/${id}`);
          }}>
          <FlexRow alignTop>
            <View style={{ flex: 1 }}>
              <TextLine>
                {Moment(createdAt).format("M/D/YY")}: {Moment(createdAt).fromNow()}
              </TextLine>

              <TextLine bold size={18} style={{ marginTop: 6 }}>
                {getLabelItem(staffServiceTypes, serviceType)}: {businessRole.name}
              </TextLine>

              <FlexRow style={{ marginTop: 14 }}>
                {!hideClient ? <UserItem label="Client:" user={user} style={{ marginRight: 25 }} fontSize={14} /> : null}
                <UserItem label="PM:" user={projectManager} fontSize={14} style={{ marginRight: 25 }} />
                <UserItem label="Consultant:" user={assignedTo} fontSize={14} />
              </FlexRow>
            </View>

            <View alignRight>
              <TextLine size={22} value={plural(hoursPerMonth, "hr") + "/month"} />

              <TextLine top={6} color="grey" value={getLabelItem(priorityLevels, priorityLevel) + " Priority for " + plural(numberOfMonths, "month")} />

              {startDate > Moment().startOf("day") ? <TextLine size={14} color="grey" value={"Start: " + Moment(startDate).format("M/D/YYYY") + " (" + Moment(startDate).diff(Moment().startOf("day"), "days") + "D)"} /> : null}
            </View>
          </FlexRow>

          {stats ? (
            <FlexRow top={8}>
              <TextLine value={"Tasks: " + plural(openTaskCount, "task") + (openTaskCount > 0 ? " (" + plural(openTaskHours, "hr") + ")" : "")} spacer />
              <TextLine value={"Used Hours: " + Math.round(timeSeconds / 60, 1) + " of " + plural(availableHoursForMonth, "hr")} />
            </FlexRow>
          ) : null}
        </BoxRowItem>
      </View>
    );
  } else {
    return (
      <BoxRowItem
        onPress={() => {
          history.push(`/${sectionId}/staff-service/${id}`);
        }}>
        <FlexRow alignTop>
          <View style={{ flex: 1 }}>
            <TextLine>
              {Moment(createdAt).format("M/D/YY")}: {Moment(createdAt).fromNow()}
            </TextLine>

            <TextLine bold size={18} style={{ marginTop: 6 }}>
              {getLabelItem(skillLevels, skillLevel) + " " + getLabelItem(staffServiceTypes, serviceType)}: {businessRole.name}
            </TextLine>

            <FlexRow style={{ marginTop: 14 }}>
              {!hideClient ? <UserItem label="Client:" user={user} style={{ marginRight: 25 }} fontSize={14} /> : null}
              <UserItem label="PM:" user={projectManager} fontSize={14} />
              {!hideAssigned ? <UserItem label="Consultant:" user={assignedTo} fontSize={14} style={{ marginRight: 25 }} /> : null}
            </FlexRow>
          </View>

          <View alignRight>
            <TextLine size={22} value={plural(hoursPerMonth, "hr") + " @ " + money(monthlyCost, 0) + "/month"} />

            <TextLine top={6} color="grey" value={getLabelItem(priorityLevels, priorityLevel) + " Priority for " + plural(numberOfMonths, "month")} />

            {startDate > Moment().startOf("day") ? <TextLine size={14} color="grey" value={"Start: " + Moment(startDate).format("M/D/YYYY") + " (" + Moment(startDate).diff(Moment().startOf("day"), "days") + "D)"} /> : null}

            {roleText ? <TextLine top={6} color="grey" value={roleText} /> : null}
          </View>
        </FlexRow>

        {balanceDue ? <FlexRow top={4}>{chargeStatus == "failed" ? <TextLine value={"Balance Due: " + money(balanceDue)} spacer /> : <TextLine value={"Charge Amount: " + money(chargeAmount)} />}</FlexRow> : null}

        {stats ? (
          <FlexRow top={8}>
            <TextLine value={"Tasks: " + plural(openTaskCount, "task") + (openTaskCount > 0 ? " (" + plural(openTaskHours, "hr") + ")" : "")} spacer />
            <TextLine value={"Used Hours: " + Math.round(timeSeconds / 60, 1) + " of " + plural(availableHoursForMonth, "hr")} />
          </FlexRow>
        ) : null}
      </BoxRowItem>
    );
  }
}

export function EditStaffAugShare({ serviceId, recordId, onClose, onSave, parentStaffAugShareId }) {
  const [state, setState] = useState({ model: {}, isLoading: true });
  const { isLoading, model } = state;

  useEffect(() => {
    var maxMonthlyHours = 0;
    var maxWeeklyHours = 0;
    var maxDailyHours = 0;
    var newState = {};
    dL.getQuery("StaffAugService")
      .get(serviceId)
      .then(function (obj) {
        newState.service = dL.loadStaffAugService(obj);
        maxMonthlyHours = newState.service.hoursPerMonth;
        maxWeeklyHours = maxMonthlyHours;
        maxDailyHours = maxMonthlyHours;
        if (maxWeeklyHours > 7 * 24) {
          maxDailyHours = 7 * 24;
        }
        if (maxDailyHours > 24) {
          maxDailyHours = 24;
        }

        var promise = new PromiseA();
        promise.resolve();
        if (parentStaffAugShareId) {
          promise = dL
            .getQuery("StaffAugShare")
            .get(parentStaffAugShareId)
            .then(function (obj) {
              newState.share = dL.loadStaffAugShare(obj);
              maxMonthlyHours = newState.share.hoursMonth;
              maxWeeklyHours = newState.share.hoursWeek;
              maxDailyHours = newState.share.hoursDay;
            });
        }
        promise
          .then(function () {
            if (recordId) {
              return dL
                .getQuery("StaffAugShare")
                .include("assignedTo")
                .get(recordId)
                .then(function (obj) {
                  newState.model = dL.loadStaffAugShare(obj);
                });
            }
          })
          .then(function () {
            newState.maxMonthlyHours = maxMonthlyHours;
            newState.maxWeeklyHours = maxWeeklyHours;
            newState.maxDailyHours = maxDailyHours;

            newState.isLoading = false;
            setState({ ...state, ...newState });
          });
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const { maxMonthlyHours, maxWeeklyHours, maxDailyHours } = state;

  const { requireApproval, assignedTo, hoursDay, hoursWeek, hoursMonth } = model;


  return (
    <ModalBasic
      title="Share Time with Others"
      okText={!recordId ? "Share" : "Update"}
      onCancel={() => {
        onClose();
      }}
      onOk={() => {
        if (!assignedTo) {
          alert("Must select user.");
          return;
        }

        if (!utils.isNumber(hoursDay)) {
          alert("Must enter valid hours.");
          return;
        }

        var hours = Number(hoursDay);
        if (hours > maxDailyHours) {
          alert("Must enter valid hours per day.");
          return;
        }

        if (!utils.isNumber(hoursWeek)) {
          alert("Must enter valid hours.");
          return;
        }
        hours = Number(hoursWeek);
        if (hours > maxWeeklyHours) {
          alert("Must enter valid hours per week.");
          return;
        }

        if (!utils.isNumber(hoursMonth)) {
          alert("Must enter valid hours.");
          return;
        }
        hours = Number(hoursMonth);
        if (hours > maxMonthlyHours) {
          alert("Must enter valid hours per month.");
          return;
        }

        if (Number(hoursDay) > Number(hoursWeek)) {
          alert("Daily hours need to be less than weekly hours.");
          return;
        }
        if (Number(hoursWeek) > Number(hoursMonth)) {
          alert("Weekly hours need to be less than monthly hours.");
          return;
        }

        return dL
          .saveStaffAugShare(recordId, {
            requireApproval,
            parentStaffAugShareId,
            staffAugServiceId: serviceId,
            assignedToId: assignedTo.id,
            hoursDay: hoursDay ? Number(hoursDay) : null,
            hoursWeek: hoursWeek ? Number(hoursWeek) : null,
            hoursMonth: hoursMonth ? Number(hoursMonth) : null
          })
          .then(function () {
            onSave();
          });
      }}>
      <Section>
        <HeaderText subHeader label="Share Time With:" description="Share task time with another staff member." />
        <SelectUser
          readonly={recordId}
          hideMySelf
          onChange={item => {
            model.assignedTo = item;
            setState({ ...state, model });
          }}
          value={assignedTo}
        />
      </Section>

      <Section>
        <HeaderText subHeader label="Limit the amount of shared time:" description="Control the amount of tasks that can be delegated to this staff resource." />
        <FlexRow>
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="integer"
            minValue={0}
            label={"Hours/Day (Max. " + plural(maxDailyHours, "hr") + "):"}
            value={hoursDay}
            description="Maximum hours the user can delegate to this staffer per day."
            onChange={value => {
              model.hoursDay = value;
              setState({ ...state, model });
            }}
          />
          <View style={{ width: 35 }} />
          <MyInput
            style={{ maxWidth: 155 }}
            inputType="integer"
            minValue={0}
            label={"Hours/Week (Max. " + plural(maxWeeklyHours, "hr") + "):"}
            value={hoursWeek}
            description="Maximum hours the user can delegate to this staffer per week."
            onChange={value => {
              model.hoursWeek = value;
              setState({ ...state, model });
            }}
          />
        </FlexRow>
        <MyInput
          style={{ maxWidth: 155 }}
          inputType="integer"
          minValue={0}
          label={"Hours/Month (Max. " + plural(maxMonthlyHours, "hr") + "):"}
          value={hoursMonth}
          description="Maximum hours the user can delegate to this staffer per month."
          onChange={value => {
            model.hoursMonth = value;
            setState({ ...state, model });
          }}
        />
      </Section>

      <Toggle
        label="Require Approval:"
        description="Requires your approval of all tasks delegated before they are started."
        value={requireApproval}
        onChange={value => {
          model.requireApproval = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

export function getData({ model }) {
  const { staffServiceType, baseBillRate, hoursPerMonth, priorityLevel, numberOfMonths } = model
  var priorityItems;

  if (hoursPerMonth) {
    priorityItems = priorityLevelsList.map(item => {
      const { label, value } = item;
      const price = baseBillRate * getHoursPerMonth(staffServiceType, hoursPerMonth).rateM * item.rateM;
      const { finalBillRate } = getStaffTotal({ model: { ...model, priorityLevel: value } })
      return { label: label + " (" + money(numberOfMonths ? finalBillRate : price) + "/hr)", value, item };
    });
  }

  const baseNumberOfMonthsList = [{ label: "1 Month", value: 1 }, { label: "3 Months", value: 3 }, { label: "4 Months", value: 4 }, { label: "6 Months", value: 6 }, { label: "12 Months", value: 12 }];

  const numberOfMonthsList = hoursPerMonth ? baseNumberOfMonthsList.map(item => {
    const { label, value } = item
    const { monthlySubTotal } = getStaffTotal({ model: { ...model, numberOfMonths: value } })
    return { label: `${label} (${money(monthlySubTotal)} / month)`, value }
  }) : baseNumberOfMonthsList

  var hoursPerMonthItems;

  if (priorityLevel) {
    const rateList = getRateList(staffServiceType);
    rateList.forEach(item => {
      const { label, value } = item
      item.label = label + " (" + plural(value, "hr") + ")";
    });

    hoursPerMonthItems = rateList.map(item => {
      const { label2, rateM, value } = item
      const price = baseBillRate * rateM * getPriorityLevel(priorityLevel).rateM
      const { finalBillRate } = getStaffTotal({ model: { ...model, hoursPerMonth: value } })

      item.label = `${label2} (${plural(value, "hr") + ") (" + money(numberOfMonths ? finalBillRate : price)}/hr)`
      return item;
    });
  }

  return { priorityItems, hoursPerMonthItems, numberOfMonthsList };
}

export function getStaffTotal({ model }) {
  const { startDate, staffServiceType, skillLevel, priorityLevel, allowPullForward, allowRollover, businessRole, hoursPerMonth, assignedTo, numberOfMonths, userRole } = model;

  var maxPullForwardHours, maxRollOverHours, maxRolloverPerMonth;
  var rolloverRate = 0.2;
  if (hoursPerMonth) {
    maxPullForwardHours = Math.ceil(hoursPerMonth * rolloverRate);
    maxRolloverPerMonth = Math.ceil(hoursPerMonth * rolloverRate);
    maxRollOverHours = maxRolloverPerMonth * 2;
  }

  const { baseBillRate, workerRate } = getWorkerRate({ userRole, businessRole, assignedTo, skillLevel: userRole ? userRole.skillLevel : skillLevel });

  const { total, overageBillRate, monthlyCost, monthlySubTotal, finalBillRate } = getServiceTotal({ staffServiceType, baseBillRate, hoursPerMonth, numberOfMonths, priorityLevel, allowPullForward, allowRollover });

  var actualStartDate = startDate;
  if (!actualStartDate) {
    actualStartDate = new Date();
  }
  const endDate = Moment(new Date(actualStartDate)).add(numberOfMonths, "months");

  return { workerRate, baseBillRate, actualStartDate, monthlySubTotal, endDate, total, overageBillRate, monthlyCost, finalBillRate, maxPullForwardHours, maxRolloverPerMonth, maxRollOverHours };
}

export function validateStaffEdit({ staffServiceData }) {
  const { assignedTo, businessRole, hoursPerMonth, numberOfMonths, priorityLevel } = staffServiceData;

  if (!hoursPerMonth || !numberOfMonths || !priorityLevel) {
    alert("Must select service options.");
    return;
  }

  if (!assignedTo) {
    if (!businessRole) {
      alert("Must select business role.");
      return;
    }
  }

  return true;
}

function StaffOrderCancelDialog({ staffAugServiceId, onCancel, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { returnAmount, isLoading } = state;

  useEffect(() => {
    dL.getStaffOrderCancellationCost({ staffAugServiceId })
      .then(function ({ amount }) {
        setState({ ...state, returnAmount: amount, isLoading: false });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalBasic
      title="Cancel Staff Order"
      okText="Cancel Staff"
      onCancel={onCancel}
      onOk={() => {
        confirmAlert({
          title: "Alert!",
          message: "Are you sure you want to cancel this staff?",
          buttons: [
            {
              label: "Yes, cancel service.",
              onClick: () => {
                return dL.cancelStaffOrder({ staffAugServiceId }).then(function () {
                  onSave()
                })
              }
            },
            {
              label: "No"
            }
          ]
        });
      }}>

      <LabelItem label="You will be credited the following amount:" value={money(returnAmount)} />
    </ModalBasic>
  );
}

export function StaffEdit({ staffServiceData, onUpdate }) {
  const { sectionId } = useParams();
  const [state, setState] = useState({});

  const model = staffServiceData;

  if (!model.delayOptions) {
    model.delayOptions = {};
  }
  const { turboBoostsOn, recommendedUser, staffServiceType, assignedToUser, userRole, priorityLevel, allowPullForward, allowRollover, businessRole, hoursPerMonth, assignedTo, numberOfMonths, delayOptions } = model;
  const { delayType, delayDays, delayStart, delayStartDate } = delayOptions;

  const skillLevel = recommendedUser ? recommendedUser.userRole.skillLevel : userRole ? userRole.skillLevel : model.skillLevel;

  const { workerRate, monthlySubTotal, baseBillRate, actualStartDate, endDate, total: contractTotal, overageBillRate, monthlyCost: monthlyTotal, finalBillRate, maxPullForwardHours, maxRolloverPerMonth, maxRollOverHours } = getStaffTotal({ model });

  const { priorityItems, hoursPerMonthItems, numberOfMonthsList } = getData({ model });

  model.overageBillRate = overageBillRate
  model.monthlySubTotal = monthlySubTotal
  model.baseBillRate = baseBillRate;
  model.monthlyCost = monthlyTotal;
  model.finalBillRate = finalBillRate;
  model.workerRate = workerRate;
  model.actualStartDate = actualStartDate;
  model.endDate = endDate;
  model.total = contractTotal;
  model.contractTotal = contractTotal
  model.monthlyTotal = monthlyTotal

  var delayTypes1 = [{ label: "Exact Start Date", value: "start-date" }]

  return (
    <React.Fragment>
      {(assignedTo && userRole) || (businessRole && ((!assignedToUser && skillLevel) || (assignedToUser && assignedTo))) ? (
        <Section>
          <HeaderText subHeader label="Service Details:" />
          {sectionId == "worker" ? <Columns left={<LabelItem label="Skill Level:" value={getLabelItem(utils.skillLevels, skillLevel)} />} middle={<LabelItem label="Bill Rate:" value={money(workerRate * marginRate) + "/hr"} />} right={<LabelItem label="Cost Rate:" value={money(workerRate) + "/hr"} />} /> : null}

          <SelectBox
            required
            label="Hours Per Month:"
            placeholder="Select hours per month"
            value={hoursPerMonth ? hoursPerMonthItems.find(item => item.value == hoursPerMonth) : null}
            options={hoursPerMonthItems}
            onChange={item => {
              model.hoursPerMonth = item.value;
              setState({ ...state, model });
              onUpdate(model);
            }}
          />

          {hoursPerMonth ? (
            <React.Fragment>
              <SelectBox
                required
                label="Priority Level:"
                placeholder="Select priority level"
                description="Consultant will start work on tasks within 1-2 days for high priority tasks, 3-4 days for normal and 4-6 days for low priority tasks. Select your default level but you can always turbo boost to get tasks done sooner."
                value={priorityLevel ? priorityItems.find(item => item.value == priorityLevel) : null}
                options={priorityItems}
                onChange={item => {
                  model.priorityLevel = item.value;
                  setState({ ...state, model });
                  onUpdate(model);
                }}
              />

              <SelectBox
                required
                placeholder="Select contract length"
                label="Contact Length:"
                description="Select the contract length you can cancel at anytime with a limited cancelation cost."
                value={numberOfMonths ? numberOfMonthsList.find(item => item.value == numberOfMonths) : null}
                options={numberOfMonthsList}
                onChange={item => {
                  model.numberOfMonths = item.value;
                  setState({ ...state, model });
                  onUpdate(model);
                }}
              />
            </React.Fragment>) : null}

          {hoursPerMonth && numberOfMonths ? (
            <FlexRow>
              <LabelItem label="Monthly Rate:" value={utils.money(monthlySubTotal) + " (" + utils.money(finalBillRate) + "/hr)"} />
              <LabelItem label="Hours (Monthly):" value={plural(hoursPerMonth, "hr")} />
              <LabelItem label="Overage Rate:" value={utils.money(overageBillRate) + "/hr"} />
            </FlexRow>
          ) : null}
        </Section>
      ) : null}

      {hoursPerMonth && numberOfMonths > 1 ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Other Options:" />
            <Toggle
              label={"Include FREE 10 turbo boosts."}
              description="This will boost your priority level for Extra High priority for tasks."
              value={turboBoostsOn}
              onChange={value => {
                model.turboBoostsOn = value;
                setState({ ...state, model });
                onUpdate(model);
              }}
            />

            <Toggle
              label={"Allow rollover of " + maxRolloverPerMonth + " hours per month with a maximum of a total of " + maxRollOverHours + " rollover hours (+ $25/month):"}
              description="If you do not use all your hours for a month allow rollover of certain hours to the next month."
              value={allowRollover}
              onChange={value => {
                model.allowRollover = value;
                setState({ ...state, model });
                onUpdate(model);
              }}
            />

            <Toggle
              label={"Allow pull forward of a maximum of " + maxPullForwardHours + " hours from the next month (+ $25/month):"}
              description="If you have a busy month pull forward hours from the next month."
              value={allowPullForward}
              onChange={value => {
                model.allowPullForward = value;
                setState({ ...state, model });
                onUpdate(model);
              }}
            />
          </Section>

          <Section>
            <HeaderText subHeader label="Service Length:" />
            <Toggle
              label="Delay start of service:"
              description="Force the delay of the start of on-boarding this staff member by a certain number of days or exact date."
              value={delayStart}
              onChange={value => {
                delayOptions.delayStart = value;
                onUpdate(model);
              }}
            />
            {delayStart ? (
              <FlexRow>
                <SelectBox
                  label="Delay Type:"
                  value={delayTypes1.find((item) => item.value == delayType)}
                  options={delayTypes1}
                  onChange={(item) => {
                    delayOptions.delayType = item.value;
                    onUpdate(model);
                  }}
                />
                <View style={{ width: 25 }} />
                {delayType == "start-date" ? (
                  <FormItem label="Start Date:">
                    <Datetime
                      style={{ maxWidth: 155 }}
                      closeOnClickOutside={true}
                      closeOnSelect={true}
                      value={delayStartDate}
                      timeFormat={false}
                      onChange={(value) => {
                        delayOptions.delayStartDate = value.toDate();
                        onUpdate(model);
                      }}
                    />
                  </FormItem>
                ) : delayType == "start-of-order" || delayType == "days-from-pre-req" ? (
                  <MyInput
                    style={{ maxWidth: 155 }}
                    inputType="integer"
                    minValue={0}
                    label="Delay Days:"
                    value={delayDays}
                    onChange={(value) => {
                      delayOptions.delayDays = value;
                      onUpdate(model);
                    }}
                  />
                ) : null}

                {delayType == "start-date" ? <LabelItem label="End Date:" style={{ maxWidth: 155 }} value={Moment(endDate).format("M/D/YYYY")} /> : null}
              </FlexRow>
            ) : (
              <FlexRow>
                <LabelItem label="Start Date:" style={{ maxWidth: 155 }} value={Moment(actualStartDate).format("M/D/YYYY")} />
                <LabelItem label="End Date:" style={{ maxWidth: 155, marginLeft: 15 }} value={Moment(endDate).format("M/D/YYYY")} />
              </FlexRow>
            )}
          </Section>
        </React.Fragment>
      ) : null}

      {hoursPerMonth && numberOfMonths ? (
        <Section>
          <HeaderText subHeader label="Monthly Total:" rightRender={<LabelItem inline fontSize={24} value={money(monthlyTotal) + "/month"} />} />
          <HeaderText subHeader label="Contract Total:" rightRender={<LabelItem inline fontSize={24} value={money(contractTotal)} />} />
        </Section>
      ) : null}
    </React.Fragment>
  );
}

export function StaffView({ staffServiceData }) {
  const [state, setState] = useState({});

  const model = staffServiceData;

  const { staffServiceType, skillLevel, priorityLevel, allowPullForward, allowRollover, hoursPerMonth, numberOfMonths } = model;

  const { workerRate, baseBillRate, actualStartDate, endDate, total, overageBillRate, monthlyCost, finalBillRate, maxPullForwardHours, maxRolloverPerMonth, maxRollOverHours } = getStaffTotal({ model });

  const { priorityItems, hoursPerMonthItems, numberOfMonthsList } = getData({ model });

  model.baseBillRate = baseBillRate;
  model.monthlyCost = monthlyCost;
  model.finalBillRate = finalBillRate;
  model.workerRate = workerRate;
  model.actualStartDate = actualStartDate;
  model.endDate = endDate;
  model.total = total;

  return (
    <React.Fragment>
      <Section>
        <HeaderText subHeader label="Service Details:" />
        <Columns left={<LabelItem label="Skill Level:" value={getLabelItem(utils.skillLevels, skillLevel)} />} middle={<LabelItem label="Bill Rate:" value={money(workerRate * marginRate) + "/hr"} />} right={<LabelItem label="Cost Rate:" value={money(workerRate) + "/hr"} />} />

        <FlexRow>
          <LabelItem label="Hours (Monthly):" value={getLabelItem(hoursPerMonthItems, hoursPerMonth)} />
          <LabelItem label="Contact Length:" value={getLabelItem(numberOfMonthsList, numberOfMonths)} />
          <LabelItem label="Priority Level:" value={getLabelItem(priorityItems, priorityLevel)} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Monthly Rate:" value={utils.money(monthlyCost) + " (" + utils.money(finalBillRate) + "/hr)"} />
          <LabelItem label="Hours (Monthly):" value={plural(hoursPerMonth, "hr")} />
          <LabelItem label="Overage Rate:" value={utils.money(overageBillRate) + "/hr"} description="This rate will be charged for hours used beyond your monthly defaults include any pull forward hours." />
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="Other Options:" />
        <LabelItem label={"Allow rollover of " + maxRolloverPerMonth + " hours per month with a cap of " + maxRollOverHours + " rollover hours (+$25/month):"} description="If you do not use all your hours for a month allow rollover of certain hours to the next month." value={yesNo(allowRollover)} />

        <LabelItem label={"Allow pull forward maximum of " + maxPullForwardHours + " hours from the next month (+$25/month):"} description="If you have a busy month pull forward hours from the next month." value={yesNo(allowPullForward)} />
      </Section>

      <Section>
        <HeaderText subHeader label="Service Length:" />
        <FlexRow>
          <LabelItem label="Start Date:" value={Moment(actualStartDate).format("M/D/YYYY")} />
          <LabelItem label="End Date:" value={Moment(endDate).format("M/D/YYYY")} />
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="Service Total:" rightRender={<LabelItem inline fontSize={24} value={money(total) + "/month"} />} />
      </Section>
    </React.Fragment>
  );
}

export function AddStaffService() {
  //Select User:  Bill Rate: +25% (cost rate)  Cost Rate: $23.50
  //Priority Type: priority (+10%), normal, reduce cost (-10%)
  //Number of Months: 1, 3, 4, 6, 12 (no price change just locked in time)
  //Start Date:   Till Date:
  //Hours per Month: 25  Monthly Cost: $245 ($19/hour)  Rate per hour above allowed hours: $24/hr
  //Allow up to 10 hours/month up to total of 50 rollover hours: $25/month
  //Allow pull forward of 10 hours: $25/month
  //Credit Card
  //Pay: charge credit card first months, add staffservice, staffshare record, go to service (see the service details, see the charges, shared with, see the tasks, task history, time records)
  //add number of months (?)

  //client, role, assigned to, bill rate, worker rate, monthly block (1/8,1/4,1/2,3/4,1), hours per month, max roll over hours, pull forward hours, outside block bill rate/worker rate

  const { sectionId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ staffServiceData: { staffServiceType: "staff", id: utils.guid(), priorityLevel: priorityLevels[1].value } });
  const { staffServiceData, creditCard, isSaved } = state;

  const model = staffServiceData;

  const { overageBillRate, maxRolloverPerMonth, maxPullForwardHours, maxRollOverHours, endDate, baseBillRate, monthlyCost, finalBillRate, workerRate, staffServiceType, skillLevel, priorityLevel, allowPullForward, allowRollover, user, businessRole, hoursPerMonth, assignedTo, numberOfMonths, actualStartDate } = staffServiceData;

  return (
    <EditDialog>
      <HeaderText
        label="Add Staff Service"
        description="Add staff augmentation service."
        rightRender={<TabBar
          options={utils.staffServiceTypes}
          onChange={item => {
            model.staffServiceType = item.value
            setState({ ...state, model });
          }}
          value={staffServiceType}
        />}
      />

      <Section>
        <HeaderText subHeader label="Client:" />
        <SelectUser
          onChange={item => {
            model.user = item;
            setState({ ...state, model });
          }}
          value={user}
        />
      </Section>

      <WorkerSelection
        model={model}
        onChange={model => {
          setState({ ...state, model });
        }}
      />

      <StaffEdit
        staffServiceData={staffServiceData}
        onUpdate={staffServiceData => {
          setState({ ...state, staffServiceData });
        }}
      />

      {numberOfMonths > 1 ? (
        <React.Fragment>
          {user ? (
            <Section>
              <HeaderText subHeader label="Payment:" />
              <SelectCreditCard
                referenceId={user.id}
                value={creditCard}
                onChange={value => {
                  setState({ ...state, creditCard: value });
                }}
              />
            </Section>
          ) : null}
        </React.Fragment>
      ) : null}

      <TouchButton
        label="Place Service Order"
        onPress={() => {
          if (!user) {
            alert("Must select client.");
            return;
          }
          if (!validateStaffEdit({ staffServiceData })) {
            return;
          }

          if (!creditCard) {
            alert("Must select credit card.");
            return;
          }

          const testMode = session.user.testMode;

          const processCard = function () {
            const serviceId = model.id;
            return dL.getStaffAugServiceFull(serviceId).then(function ({ staffService }) {
              const { chargeAccount } = staffService;

              return dL.processAccountCharges({ referenceId: user.id, creditCard, testMode, accountId: chargeAccount.id }).then(function (results) {
                const { err } = results;

                if (err) {
                  if (err.message) {
                    alert("Error in credit card charge: " + err.message);
                  } else {
                    alert("Error in credit card charge: " + err);
                  }
                  return dL.setStaffServiceChargeStatus(serviceId, { chargeStatus: "failed" });
                } else {
                  return dL
                    .setStaffServiceChargeStatus(serviceId, {
                      status: "open",
                      chargeStatus: "success"
                    })
                    .then(function () {
                      session.cart = { items: [] };
                      history.replace("/admin/staff-service/" + serviceId);
                      alert("DONE PURCHASED!");
                    });
                }
              });
            });
          };

          if (isSaved) {
            return processCard().catch(function (err) {
              alert("Error in purchase:" + err);
            });
          } else {
            return dL
              .AddStaffService(model.id, {
                serviceType: staffServiceType,
                userId: user.id,
                skillLevel,
                businessRoleId: businessRole.id,
                assignedToId: assignedTo ? assignedTo.id : null,
                hoursPerMonth,
                monthlyCost,
                finalBillRate,
                baseBillRate,
                overageBillRate,
                workerRate,
                maxRolloverPerMonth,
                maxPullForwardHours,
                maxRollOverHours,
                numberOfMonths,
                startDate: actualStartDate,
                endDate,
                priorityLevel,
                allowPullForward,
                allowRollover,
                status: "cart"
              })
              .then(function (serviceId) {
                setState({ ...state, isSaved: true });

                return processCard(serviceId);
              })
              .catch(function (err) {
                alert("Error in purchase:" + err);
              });
          }
        }}
      />
    </EditDialog>
  );
}

export function AddStaffServiceWorker() {
  const { sectionId, userId, roleId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });

  const { addToCartVisible, staffServiceData, userRoles, isLoading } = state;

  useEffect(() => {
    const staffServiceData = { staffServiceType: "staff", priorityLevel: priorityLevels[1].value };

    dL.getUser(userId).then(function (user) {
      staffServiceData.assignedTo = user;
      return dL.getUserRoles({ userId });
    }).then(function (userRoles) {
      staffServiceData.assignedTo.userRoles = userRoles

      if (roleId) {
        const userRole = userRoles.find(item => item.businessRole.id == roleId);
        const { businessRole, skillLevel } = userRole;
        staffServiceData.businessRole = businessRole;
        staffServiceData.userRole = userRole;
        staffServiceData.skillLevel = skillLevel;
      }

      setState({ ...state, isLoading: false, staffServiceData, userRoles });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { staffServiceType, userRole, assignedTo } = staffServiceData;

  var finalUserRoles = [];
  if (finalUserRoles && userRoles) {
    finalUserRoles = userRoles.map(item => {
      const { businessRole, id, skillLevel, hourlyRate } = item;
      return { item, label: businessRole.name + " (" + getLabelItem(utils.skillLevels, skillLevel) + ") (" + money(hourlyRate) + "/hr)", value: id };
    });
  }

  return (
    <EditDialog>
      <HeaderText label="Add Staff Service" description="Add staff augmentation service." />

      <Section>
        <LabelItem label="Consultant:" style={{ marginRight: 0 }}>
          <BoxRowItem>
            <FlexRow>
              <FlexExpand>
                <UserItem avatarSize={45} user={assignedTo} />
              </FlexExpand>
              <TabBar
                options={utils.staffServiceTypes}
                onChange={item => {
                  staffServiceData.userRole = item.value
                  setState({ ...state, staffServiceData });
                }}
                value={staffServiceType}
              />
            </FlexRow>
          </BoxRowItem>
        </LabelItem>

        <SelectBox
          label="Business Role:"
          value={userRole ? finalUserRoles.find(item => item.value == userRole.id) : null}
          options={finalUserRoles}
          onChange={item => {
            const userRole = item.item;
            staffServiceData.userRole = userRole;
            staffServiceData.skillLevel = userRole.skillLevel;
            setState({ ...state, staffServiceData });
          }}
        />
      </Section>

      <StaffEdit
        staffServiceData={staffServiceData}
        onUpdate={staffServiceData => {
          setState({ ...state, staffServiceData });
        }}
      />

      {addToCartVisible ? <AddToCartDialog total={staffServiceData.contractTotal} itemType="staff" configData={staffServiceData} onSave={() => {
        setState({ ...state, addToCartVisible: false })
      }} onCancel={() => {
        setState({ ...state, addToCartVisible: false })
      }} /> : null}

      <TouchButton
        label="Add to Cart"
        onPress={() => {
          if (!validateStaffEdit({ staffServiceData })) {
            return;
          }

          setState({ ...state, addToCartVisible: true })
          return

          const { contractTotal, monthlyTotal } = staffServiceData;
          const cartItem = Object.assign({}, staffServiceData);
          cartItem.id = utils.guid();
          cartItem.itemType = "staff";
          cartItem.quantity = 1;
          cartItem.monthlyTotal = monthlyTotal;
          cartItem.contractTotal = contractTotal;
          cartItem.unitPrice = contractTotal;
          cartItem.total = contractTotal;

          utils.updateCartItem(cartItem);

          dL.saveCart();
          alert("Added to cart.");

          history.push(`/${sectionId}/cart`);
        }}
      />
    </EditDialog>
  );
}

export function StaffAugShares() {
  const { sectionId, staffAugServiceId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      searchFields={[{ field: "name" }]}
      defaultSort={{ field: "name" }}
      type="StaffAugShare"
      emptyLabel="No shares found."
      includes={["user", "assignedTo", "createdBy"]}
      onButtonPress={() => {
        history.push(`/${sectionId}/${staffAugServiceId}/staff-share/add`);
      }}
      title="Staff Aug Share"
      description="Manage staff aug shares."
      renderItem={item => {
        const { createdBy, user, id, hours, assignedTo } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/staff-service/${id}`);
            }}>
            <FlexRow>
              <View style={{ flex: 1 }}>
                <UserItem user={user} style={{ marginTop: 8 }} />
                <UserItem user={assignedTo} style={{ marginTop: 8 }} />
                <UserItem user={createdBy} style={{ marginTop: 8 }} />
              </View>
              <View alignRight>
                <TextLine color="grey" value={plural(hours, "hr")} />
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function StaffServicesAdmin() {
  const { sectionId } = useParams();
  const history = useHistory();

  const buttons = [
    {
      label: "Add Service",
      onPress: () => {
        history.push(`/${sectionId}/staff-services/add-staff`);
      }
    },
    {
      label: "Billing",
      onPress: () => {
        history.push(`/${sectionId}/staff-services/billing`);
      }
    }
  ];
  return (
    <ListRender
      searchFields={[{ field: "name" }]}
      defaultSort={{ field: "createdAt", desc: true }}
      type="StaffAugService"
      emptyLabel="No services found."
      includes={["user", "businessRole", "assignedTo", "projectManager"]}
      buttons={buttons}
      filters={[
        {
          label: "Cart",
          value: "cart",
          onQuery: function (query) {
            query.equalTo("status", "cart");
            query.descending("createdAt");
          }
        },
        {
          label: "Not Assigned",
          value: "not-assigned",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.existsObj("assignedTo", false);
          }
        },
        {
          label: "Open",
          value: "open",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.existsObj("assignedTo", true);
            query.greaterThan("endDate", new Date());
          }
        },
        {
          label: "Completed",
          value: "completed",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.lessThan("endDate", new Date());
          }
        }
      ]}
      title="Staff Services"
      description="Manage staff services."
      renderItem={item => {
        return <StaffServiceRecord key={item.id} item={item} />;
      }}
    />
  );
}

export function StaffServicesAdminBilling() {
  const processRetries = function () {
    //process retry for those that nextRetryAt
    return dL
      .getQuery("StaffAugService")
      .equalTo("status", "open")
      .lessThan("nextRetryAt", new Date())
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const items = dL.loadObjects("StaffAugService", objs);
        return Promise.all(items.map(item => utils.processRetry(item))).then(function () {
          alert("Processed " + items.length + " items.");
        });
      });
  };

  const processCharges = function () {
    return dL
      .getQuery("StaffAugService")
      .equalTo("status", "open")
      .lessThan("nextChargeDate", new Date())
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const items = dL.loadObjects("StaffAugService", objs);
        return Promise.all(items.map(item => utils.processCharge(item))).then(function () {
          alert("Processed " + items.length + " items.");
        });
      });
  };

  return (
    <ListRender
      type="StaffAugService"
      emptyLabel="No services found."
      includes={["user", "businessRole", "assignedTo", "chargeAccount"]}
      rightRender={
        <React.Fragment>
          <TouchButton
            spacer
            onPress={() => {
              processCharges();
            }}
            label="Process Charges"
          />
          <TouchButton
            spacer
            onPress={() => {
              processRetries();
            }}
            label="Process Retry"
          />
        </React.Fragment>
      }
      filters={[
        {
          label: "Process",
          value: "process",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.lessThan("nextChargeDate", new Date());
            query.ascending("nextChargeDate");
          }
        },
        {
          label: "Failed",
          value: "failed",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.equalTo("chargeStatus", "failed");
            query.ascending("nextRetryAt");
          }
        }
      ]}
      title="Billing: Staff Services"
      description="Manage and process billing for staff services."
      renderItem={item => {
        return <StaffServiceRecord key={item.id} item={item} />;
      }}
    />
  );
}

export function StaffServicesWorker() {
  const userId = session.user.id;
  const filters = [];

  if (dL.isPM()) {
    filters.push({
      label: "Project Manager",
      value: "pm",
      onQuery: function (query) {
        query.equalTo("projectManager", dL.getObj("User", userId));
        query.greaterThan("endDate", new Date());
      }
    });
  }

  filters.push({
    label: "Consultant",
    value: "worker",
    onQuery: function (query) {
      query.equalTo("assignedTo", dL.getObj("User", userId));
      query.greaterThan("endDate", new Date());
    }
  });

  filters.push({
    label: "Closed",
    value: "closed",
    onQuery: function (query) {
      query.lessThan("endDate", new Date());
    }
  });

  return (
    <ListRender
      defaultSort={{ field: "createdAt", desc: true }}
      filters={filters}
      type="StaffAugService"
      emptyLabel="No staffing services found."
      includes={["user", "businessRole", "assignedTo", "projectManager"]}
      title="Staff Services"
      renderItem={item => {
        return <StaffServiceRecordWorker key={item.id} hideAssigned item={item} />;
      }}
    />
  );
}

export function StaffServicesBuyer() {
  const { sectionId } = useParams();
  const history = useHistory();

  const [state, setState] = useState({ tabValue: "created", isLoading: true });
  const { teamMembers, isEmptyRender, isLoading } = state;

  const getMyStaffContracts = function () {
    return db.getQuery("StaffAugService")
      .equalTo("createdBy", db.getObj("User", session.user.id))
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .descending("createdAt")
      .include("businessRole")
      .include("projectManager")
      .include("assignedTo")
      .find()
      .then(function (objs) {
        return dL.loadObjects("StaffAugService", objs)
      });
  }

  const getMySharedContracts = function () {
    return db.getQuery("StaffAugShare")
      .equalTo("assignedTo", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .descending("createdAt")
      .include("createdBy")
      .include("staffAugService")
      .include("staffAugService.assignedTo")
      .include("staffAugService.businessRole")
      .include("staffAugService.projectManager")
      .find()
      .then(function (objs) {
        return dL.loadObjects("StaffAugShare", objs)
          .filter(item => item.staffAugService.status == "open")
      });
  }

  useEffect(() => {
    const promises = []
    const newState = { ...state }
    promises[promises.length] = getMyStaffContracts().then(function (list) {
      newState.openList = list.filter(item => item.endDate <= new Date())
      newState.expiredList = list.filter(item => item.endDate > new Date())
    })

    promises[promises.length] = getMySharedContracts().then(function (list) {
      newState.sharedList = list
    })

    promises[promises.length] = dL.getQuery("Member")
      .equalTo("company", session.company.id)
      .equalTo("status", "active")
      .containedIn("removed", [undefined, false])
      .find().then(function (objs) {
        newState.teamMembers = dL.loadObjects("Member", objs)
      })

    return Promise.all(promises).then(function () {
      if (newState.openList.length == 0 && newState.expiredList.length == 0 && newState.sharedList.length == 0) {
        newState.isEmptyRender = true
      }
      newState.isLoading = false
      setState(newState)
    })
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isEmptyRender) {
    return <View>
      <HeaderText label="My Staff"
        description="Ask our consultants to prepare a proposal for service solution." />

      <NoRecordsBox label="No hired staff." style={{ marginBottom: 10 }} />

      <FlexRow>
        <DisplayCenterBox icon={faPlus} label="Invite Team Members" description="Invite others team members to the company." buttonLabel="Invite Team" onPress={() => {
          history.push("/user/services")
        }} />

        <View style={{ width: 25 }} />

        <DisplayCenterBox icon={faSearch} label="Search for staff to hire" description="Can’t find the right staff members or don’t need a full-time staff, quickly and easily hire a trained staff members with all the skill ready to go!" buttonLabel="Search for People" onPress={() => {
          history.push("/user/people")
        }} />
      </ FlexRow>
    </View>
  }


  const { openList, sharedList, expiredList } = state

  return (
    <View>
      <HeaderText label="My Staff" description="Manage your staff services, add tasks, shared time with others team members." />

      <Section>
        <HeaderText subHeader label="My Team Members:" description="Manage team members that can share the hired staff time." />
        <FlexRow>
          {teamMembers.length == 0 ? <NoRecords label="No team members added." /> : null}
          {teamMembers.map((item) => {
            const { user } = item;
            const { id } = user
            return (
              <RenderUser
                key={id}
                style={{ position: "relative" }}
                onPress={() => {
                  history.push(`/${sectionId}/user/${id}`);
                  setState({ ...state });
                }}
                item={user}>
              </RenderUser>
            );
          })}
        </FlexRow>
      </Section>

      {openList.length > 0 ? <Section>
        <HeaderText subHeader label="Staff that you have hired:" />
        {openList.map(item => {
          return <StaffServiceRecordBuyer key={item.id} hideClient item={item} />;
        })}
      </Section> : null}

      {sharedList.length > 0 ? <Section>
        <HeaderText subHeader label="Staff that is shared with you:" />
        {sharedList.map(item => {
          return <StaffServiceShareRecord key={item.id} item={item} />;
        })}
      </Section> : null}

      {expiredList.length > 0 ? <Section>
        <HeaderText subHeader label="Staff contracts that have expired:" />
        {expiredList.map(item => {
          return <StaffServiceRecordBuyer key={item.id} hideClient item={item} />;
        })}
      </Section> : null}
    </View>
  );
}

export function StaffServiceShareRecord2({ item }) {
  const [state, setState] = useState({});

  const { stats } = state;
  const { requireApproval, id, hoursDay, hoursWeek, hoursMonth, staffAugService } = item;

  const { businessRole } = staffAugService;
  const { openTaskCount, openTaskHours } = stats ? stats : {};

  useEffect(() => {
    dL.getQuery("TaskRecord")
      .equalTo("staffAugService", db.getObj("StaffAugService", id))
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        var items = dL.loadObjects("TaskRecord", objs);

        const stats = {};
        stats.openTaskCount = items.length;
        var hours = 0;
        items.forEach(item => {
          hours += item.hours;
        });
        stats.openTaskHours = hours;

        //allocated hours for this week (based on dueDate)
        //allocated hours for this month (based on dueDate)

        setState({ ...state, stats });
      });
  }, []);

  return (
    <View>
      <TextLine size={22} value={businessRole.name} bottom={10} />

      <FlexRow>
        {hoursDay ? <LabelItem label="Hrs/Day:" value={hoursDay} /> : null}
        {hoursWeek ? <LabelItem label="Hrs/Week:" value={hoursWeek} /> : null}
        {hoursMonth ? <LabelItem label="Hrs/Month:" value={hoursMonth} /> : null}

        <LabelItem label="Require Approval:" value={requireApproval ? "YES" : "NO"} />
      </FlexRow>

      {stats ? (
        <FlexRow top={10}>
          <LabelItem label="Open Tasks:" value={plural(openTaskCount, "Task") + " (" + plural(openTaskHours, "hr") + ")"} />
          <LabelItem label="Hours Left (this week):" value={"10 hrs"} />
          <LabelItem label="Hours Left (this month):" value={"25 hrs"} />
        </FlexRow>
      ) : null}
    </View>
  );
}

export function StaffServiceShareRecord({ item }) {
  const { sectionId, staffAugServiceId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});

  const { stats } = state;
  const { requireApproval, id, createdBy, hoursDay, hoursWeek, hoursMonth, staffAugService } = item;

  const { businessRole, assignedTo } = staffAugService;
  const { openTaskCount, openTaskHours } = stats ? stats : {};

  useEffect(() => {
    dL.getQuery("TaskRecord")
      .equalTo("staffAugService", db.getObj("StaffAugService", id))
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        var items = dL.loadObjects("TaskRecord", objs);

        const stats = {};
        stats.openTaskCount = items.length;
        var hours = 0;
        items.forEach(item => {
          hours += item.hours;
        });
        stats.openTaskHours = hours;

        //allocated hours for this week (based on dueDate)
        //allocated hours for this month (based on dueDate)

        setState({ ...state, stats });
      });
  }, []);

  return (
    <View>
      {assignedTo ? <RenderUserItem item={assignedTo} /> : null}

      <BoxRowItem
        onPress={() => {
          history.push(`/${sectionId}/${staffAugServiceId}/staff-share/${id}`);
        }}>
        <TextLine size={22} value={businessRole.name} bottom={10} />

        <FlexRow>
          <LabelUserItem label="Shared By:" user={createdBy} />

          {hoursDay ? <LabelItem label="Hrs/Day:" value={hoursDay} /> : null}
          {hoursWeek ? <LabelItem label="Hrs/Week:" value={hoursWeek} /> : null}
          {hoursMonth ? <LabelItem label="Hrs/Month:" value={hoursMonth} /> : null}

          <LabelItem label="Require Approval:" value={requireApproval ? "YES" : "NO"} />

          {assignedTo ? (
            <LabelItem label="Consultant:">
              <UserItem user={assignedTo} />
            </LabelItem>
          ) : null}
        </FlexRow>

        {stats ? (
          <FlexRow top={10}>
            <LabelItem label="Open Tasks:" value={plural(openTaskCount, "Task") + " (" + plural(openTaskHours, "hr") + ")"} />
            <LabelItem label="Hours Left (this week):" value={"10 hrs"} />
            <LabelItem label="Hours Left (this month):" value={"25 hrs"} />
          </FlexRow>
        ) : null}
      </BoxRowItem>
    </View>
  );
}

export function StaffServiceBuyer() {
  const history = useHistory();
  const { recordId } = useParams();
  const [refresh, setRefresh] = useState(new Date());
  const [state, setState] = useState({ tabValue: "details", isLoading: true });
  const { cancelServiceVisible, tabValue, isLoading, staffService, charges, shares, tasks, times, payments } = state;

  useEffect(() => {
    dL.getStaffAugServiceFull(recordId).then(function ({ tasks, times, shares, charges, staffService, payments }) {
      setState({ ...state, isLoading: false, tasks, times, shares, charges, staffService, payments });
    });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { status, chargeAccount, projectManager, user, assignedTo, id, endDate } = staffService;

  const tabs = [
    { label: "Details", value: "details" },
    getCountItem({ label: "Shared With", value: "hours" }, shares),
    getCountItem({ label: "Tasks", value: "tasks" }, tasks),
    getCountItem({ label: "Time Log", value: "time-log" }, times),
    getCountItem({ label: "Charges", value: "charges" }, charges),
    payments.length > 0 ? getCountItem({ label: "Payments", value: "payments" }, payments) : null];

  const chatRoomName = "Staff Contract"
  const chatRooms = getChatRoomsBuyer({ id, chatRoomName, clientUsers: [user], growlyUsers: [assignedTo, session.user] })

  const buttons = []

  if (status != "canceled" && endDate > new Date()) {
    buttons.push({
      label: "Cancel Service", onPress: () => {
        setState({ ...state, cancelServiceVisible: true })
      }
    })
  }

  return (
    <ChatWindow
      chatRooms={chatRooms}>
      <HeaderText label="Staff Contract" buttons={buttons} />
      <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {cancelServiceVisible ? <StaffOrderCancelDialog staffAugServiceId={staffService.id} onCancel={() => {
        setState({ ...state, cancelServiceVisible: false })
      }} onSave={() => {
        history.goBack()
        setState({ ...state, cancelServiceVisible: false })
      }} /> : null}

      {tabValue == "details" ? (
        <React.Fragment>
          <StaffAugServiceDetails model={staffService} />

          <Section>
            <HeaderText subHeader label="Support Team:" />
            <FlexRow>
              <UserItem
                label="Project Manager:"
                user={projectManager}
                commentIcon
              />
              <View style={{ width: 35 }} />
              <UserItem
                label="Consultant:"
                user={assignedTo}
                commentIcon
              />
            </FlexRow>
          </Section>
        </React.Fragment>
      ) : null}

      {tabValue == "hours" ? (
        <DisplaySharedWith
          shares={shares}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "tasks" ? (
        <DisplayTasks
          staffService={staffService}
          tasks={tasks}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "time-log" ? (
        <DisplayTimeLogs
          times={times}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "charges" ? <DisplayAccountCharges model={staffService} account={chargeAccount} charges={charges} /> : null}
      {tabValue == "payments" ? <DisplayAccountPayments payments={payments} /> : null}
    </ChatWindow>
  );
}

export function StaffServiceWorker() {
  const { recordId, sectionId } = useParams();
  const [refresh, setRefresh] = useState(new Date());
  const [state, setState] = useState({ tabValue: "details", isLoading: true });
  const { tabValue, isLoading, staffService, allShares, tasks, times } = state;

  useEffect(() => {
    dL.getStaffAugServiceFull(recordId).then(function ({ tasks, allShares, times, staffService }) {
      setState({ ...state, isLoading: false, tasks, times, allShares, staffService });
    });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { projectManager, assignedTo, createdAt, user, workerRate, id } = staffService;

  const tabs = [{ label: "Details", value: "details" }];
  tabs.push(getCountItem({ label: "People", value: "people" }, allShares));
  tabs.push(getCountItem({ label: "Tasks", value: "tasks" }, tasks));
  tabs.push(getCountItem({ label: "Time Log", value: "time-log" }, times));

  const chatRoomName = "Staff Contract"
  const chatRooms = getChatRoomsWorker({ id, chatRoomName, clientUsers: [user], growlyUsers: [projectManager, assignedTo, session.user] })

  return (
    <ChatWindow
      chatRooms={chatRooms}>
      <HeaderText label="Staff Contract" />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Service Overview:" />

            <LabelItem inline label="Created At:" value={Moment(createdAt).format("M/D/YYYY")} />

            <FlexRow>
              <DisplayKeyUser
                style={{ flex: 1 }}
                label="Client:"
                value={user}
              />

              <DisplayKeyUser
                style={{ flex: 1 }}
                label="Project Manager:"
                readonly={sectionId != "admin"}
                value={projectManager}
              />
            </FlexRow>
          </Section>

          <StaffServiceOverview model={staffService} />

          <Section>
            <HeaderText subHeader label="Worker Details:" />

            <FlexRow>
              <LabelItem label="Worker Pay Rate:" description="This is the pay rate that is attached to this service contract." value={utils.money(workerRate)} />
            </FlexRow>
          </Section>
        </React.Fragment>
      ) : null}

      {tabValue == "people" ? (
        <Section>
          <HeaderText subHeader label="People:" description="Team members on the client side that have issued you tasks." />
          {allShares.length == 0 ? (
            <NoRecords label="No people found." />
          ) : (
            allShares.map((item) => {
              const { id } = item;

              return <PeopleShare item={item} />;
            })
          )}
        </Section>
      ) : null}

      {tabValue == "tasks" ? (
        <DisplayTasksWorker
          shares={allShares}
          tasks={tasks}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "time-log" ? (
        <Section>
          <HeaderText subHeader label="Time Log:" description="Your time log for this service." />

          {times.length == 0 ? (
            <NoRecords label="No time records found." />
          ) : (
            times.map(item => {
              return (
                <RenderTimeLog
                  item={item}
                  onUpdate={() => {
                    setRefresh(new Date());
                  }}
                />
              );
            })
          )}
        </Section>
      ) : null}
    </ChatWindow>
  );
}

export function StaffServiceAdmin() {
  const { recordId, sectionId } = useParams();
  const [refresh, setRefresh] = useState(new Date());
  const [state, setState] = useState({ tabValue: "details", isLoading: true });
  const { tabValue, isLoading, model, charges, shares, allShares, tasks, times, payments } = state;

  useEffect(() => {
    dL.getStaffAugServiceFull(recordId).then(function ({ tasks, times, allShares, shares, charges, staffService, payments }) {
      setState({ ...state, isLoading: false, tasks, times, allShares, shares, charges, model: staffService, payments });
    });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { status, chargeAccount, id, projectManager, user, businessRole, assignedTo, workerRate } = model;

  const tabs = [{ label: "Details", value: "details" }];

  if (status == "open") {
    tabs.push(getCountItem({ label: "Shared With", value: "shares" }, allShares));
    tabs.push(getCountItem({ label: "Tasks", value: "tasks" }, tasks));
    tabs.push(getCountItem({ label: "Time Log", value: "time-log" }, times));
  }

  tabs.push(getCountItem({ label: "Charges", value: "charges" }, charges));
  tabs.push(getCountItem({ label: "Payments", value: "payments" }, payments));

  const chatRoomName = "Service Contract"
  const chatRooms = getChatRoomsWorker({ id, chatRoomName, clientUsers: [user], growlyUsers: [projectManager, assignedTo, session.user] })

  return (
    <ChatWindow
      chatRooms={chatRooms}>
      <HeaderText label="Service Contract" />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ? (
        <Section>
          <Section>
            <FlexRow>
              <DisplayKeyUser
                style={{ flex: 1 }}
                label="Client:"
                value={user}
              />

              <EditKeyUser
                roleName="projectManager"
                style={{ flex: 1 }}
                label="Project Manager:"
                readonly={sectionId != "admin"}
                onChange={value => {
                  dL.setStaffServiceProjectManager(id, value ? value.id : null);
                  model.projectManager = value;

                  setState({ ...state, model });
                }}
                value={projectManager}
              />
            </FlexRow>

            <LabelItem label="Consultant:" box>
              <SelectWorker
                businessRoleId={businessRole ? businessRole.id : null}
                onChange={value => {
                  const { user } = value ? value : {};
                  dL.setAssignedToStaffService(id, user ? user.id : null);
                  model.assignedTo = user;

                  setState({ ...state, model });
                }}
                value={assignedTo}
              />
            </LabelItem>
          </Section>

          <StaffAugServiceDetails model={model} />

          <FlexRow>
            <LabelItem label="Worker Pay Rate:" value={utils.money(workerRate)} />
          </FlexRow>
        </Section>
      ) : null}

      {tabValue == "shares" ? (
        <DisplaySharedWith
          shares={shares}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "tasks" ? (
        <DisplayTasks
          staffService={model}
          tasks={tasks}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "time-log" ? (
        <DisplayTimeLogs
          times={times}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "charges" ? <DisplayAccountCharges model={model} account={chargeAccount} charges={charges} /> : null}
      {tabValue == "payments" ? <DisplayAccountPayments payments={payments} /> : null}
    </ChatWindow>
  );
}

export function StaffServiceShare() {
  const { recordId } = useParams();
  const [refresh, setRefresh] = useState(new Date());
  const [state, setState] = useState({ tabValue: "details", tabValue2: "open", isLoading: true });
  const { share, tabValue, isLoading, staffService, shares, tasks, times } = state;

  useEffect(() => {
    dL.getQuery("StaffAugShare")
      .include("staffAugService")
      .include("staffAugService.businessRole")
      .include("parentStaffAugShare")
      .include("parentStaffAugShare.assignedTo")
      .include("assignedTo")
      .get(recordId)
      .then(function (obj) {
        const share = dL.loadStaffAugShare(obj);

        dL.getStaffAugServiceFull(share.staffAugService.id).then(function ({ tasks, times, staffService, allShares }) {
          setState({ ...state, isLoading: false, share, tasks: tasks.filter(task => task.staffAugShare && task.staffAugShare.id == recordId), times, shares: allShares.filter(share => share.parentStaffAugShare && share.parentStaffAugShare.id == recordId), staffService });
        });
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { projectManager, assignedTo } = staffService;
  const { id, parentStaffAugShare } = share

  const tabs = [{ label: "Details", value: "details" }, { label: "Shared With", value: "hours" }, { label: "Tasks", value: "tasks" }, { label: "Time Log", value: "time-log" }];

  const chatRoomName = "Staff Time Share"
  const chatRooms = getChatRoomsBuyer({ id, chatRoomName, clientUsers: [session.user, share.assignedTo, parentStaffAugShare ? parentStaffAugShare.assignedTo : null], growlyUsers: [share.assignedTo, assignedTo, session.user] })

  return (
    <ChatWindow
      chatRooms={chatRooms}>
      <HeaderText label="Staff Time Share" />
      <TabBar
        queryId="tb"
        style={{ marginBottom: 20 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Staff Details:" />
            <FlexRow>
              <UserItem
                label="Project Manager:"
                user={projectManager}
                commentIcon
              />
              <View style={{ width: 35 }} />
              <UserItem
                label="Consultant:"
                user={assignedTo}
                commentIcon
              />
            </FlexRow>

            <StaffServiceShareRecord2 item={share} />
          </Section>
        </React.Fragment>
      ) : null}

      {tabValue == "hours" ? (
        <DisplaySharedWith
          shares={shares}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "tasks" ? (
        <DisplayTasks
          staffService={share.staffAugService}
          staffServiceShare={share}
          tasks={tasks}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      {tabValue == "time-log" ? (
        <DisplayTimeLogs
          times={times}
          onRefresh={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}
    </ChatWindow>
  );
}

function DisplaySharedWith({ shares, onRefresh }) {
  const { recordId, sectionId, staffAugServiceId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { deleteIndex, deleteId, showDelete, editId, editMode } = state;

  return (
    <Section>
      <HeaderText
        subHeader
        label="Shared With:"
        description="Share the hours of this service contract with our team members."
        onButtonPress={() => {
          setState({ ...state, editId: null, editMode: true });
        }}
      />
      {shares.length == 0 ? (
        <NoRecords label="Not shared with any users." />
      ) : (
        shares.map((item, index) => {
          const { id } = item;

          return (
            <RenderStaffAugShare
              onPress={() => {
                history.push(`/${sectionId}/${staffAugServiceId}/staff-share/${id}`);
              }}
              item={item}
              onEdit={() => {
                setState({ ...state, editId: id, editMode: true });
              }}
              onDelete={() => {
                setState({ ...state, showDelete1: true, deleteId: id, deleteIndex: index });
              }}
            />
          );
        })
      )}

      {editMode ? (
        <EditStaffAugShare
          serviceId={recordId}
          recordId={editId}
          onClose={() => {
            setState({ ...state, editMode: false });
          }}
          onSave={() => {
            setState({ ...state, editMode: false });
            onRefresh();
          }}
        />
      ) : null}

      {showDelete ? (
        <DeleteConfirm
          onCancel={() => {
            setState({ ...state, showDelete: false });
          }}
          onConfirm={() => {
            dL.removeStaffAugShare({ serviceId: recordId, recordId: deleteId });
            shares.splice(deleteIndex, 1);
            setState({ ...state, showDelete: false });
          }}
        />
      ) : null}
    </Section>
  );
}

function StaffAugServiceDetails({ model }) {
  const { id, allowPullForward, turboBoostsPerMonth, numberOfMonths, allowRollover, nextChargeDate, serviceType, hoursPerMonth, priorityLevel, skillLevel, startDate, endDate, monthlyCost, createdAt, businessRole, createdBy, finalBillRate, overageBillRate, maxRolloverPerMonth, maxPullForwardHours, maxRollOverHours, rolloverHours, pullForwardHours, turboBoostsLeft } = model;

  const [state, setState] = useState({});
  const { stats, balanceDue, chargeAmount } = state;

  useEffect(() => {
    dL.getAccountBalance(id).then(function (amount) {
      return dL.getChargeBalance(id).then(function ({ chargeAmount, stats }) {
        setState({ ...state, balanceDue: amount, chargeAmount, stats });
      });
    });
  }, []);

  const { timeSeconds, openTaskCount, openTaskHours, availableHoursForMonth } = stats ? stats : {};

  return (
    <React.Fragment>
      <Section>
        <HeaderText subHeader label="Contract Details:" />
        <FlexRow>
          <LabelItem label="Purchased At:" value={Moment(createdAt).format("M/D/YYYY")} />
          <LabelItem label="Purchased By:">
            <UserItem user={createdBy} />
          </LabelItem>
        </FlexRow>

        <FlexRow>
          <LabelItem label="Contract Length:" value={plural(numberOfMonths, "month")} />
          <LabelItem label="Start Date:" value={Moment(startDate).format("M/D/YYYY")} />
          <LabelItem label="End Date:" value={Moment(endDate).format("M/D/YYYY")} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Monthly Rate:" value={money(monthlyCost)} />
          <LabelItem label="Next Billing Date:" value={Moment(nextChargeDate).format("M/D/YYYY")} />
        </FlexRow>

        {balanceDue ? (
          <FlexRow top={4}>
            <TextLine label="Balance Due:" value={money(balanceDue)} />
            <TextLine label="Next Billing Amount:" value={money(chargeAmount)} />
          </FlexRow>
        ) : null}
      </Section>

      <Section>
        <HeaderText subHeader label="Consultant Details:" />
        <FlexRow>
          <LabelItem label="Service Type:" value={getLabelItem(utils.staffServiceTypes, serviceType)} />
          <LabelItem label="Business Role:" value={businessRole.name} />
          <LabelItem label="Skill Level:" value={getLabelItem(utils.skillLevels, skillLevel)} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Priority Level:" value={getLabelItem(utils.priorityLevels, priorityLevel)} />
          <LabelItem label="Hours (Monthly):" value={plural(hoursPerMonth, "hr")} />
          <LabelItem label="Bill Rate:" value={money(finalBillRate) + "/hr"} />
          <LabelItem label="Overage Rate:" value={money(overageBillRate) + "/hr"} />
        </FlexRow>


        <FlexRow>
          {allowRollover ? (
            <React.Fragment>
              <LabelItem label="Rollover Hours (Total):" value={plural(maxRollOverHours, "hr")} />
              <LabelItem label="Rollover Hours (Monthly):" value={plural(maxRolloverPerMonth, "hr")} />
            </React.Fragment>
          ) : null}

          {allowPullForward ? <LabelItem label="Pulled Forward Hours (Total):" value={plural(maxPullForwardHours, "hr")} /> : null}

          {turboBoostsPerMonth && turboBoostsPerMonth > 0 ? <LabelItem label="Turbo Boosts (Monthly):" description="Turbo boosts allow you or any team member to accelerate the due date." value={plural(turboBoostsPerMonth, "boost")} /> : null}
        </FlexRow>


      </Section>

      <Section>
        <HeaderText subHeader label="Contract Usage:" />

        {stats ? (
          <FlexRow>
            <LabelItem label="Open Tasks:" value={plural(openTaskCount, "task") + (openTaskCount > 0 ? " (" + plural(openTaskHours, "hr") + ")" : "")} />
            <LabelItem label="Used Hours:" value={Math.round(timeSeconds / 60, 1) + " of " + plural(availableHoursForMonth, "hr")} />
            <LabelItem label="Overage Hours:" value={money(overageBillRate) + "/hr"} />
          </FlexRow>
        ) : null}

        <FlexRow>
          {allowRollover ? <LabelItem label="Current Rolled Over Hours:" value={plural(rolloverHours, "hr")} /> : null}
          {allowPullForward ? <LabelItem label="Pulled Forward Hours:" value={plural(pullForwardHours, "hr")} /> : null}
          {turboBoostsPerMonth && turboBoostsPerMonth > 0 ? <LabelItem label="Turbo Boosts Left:" value={plural(turboBoostsLeft, "boost")} /> : null}
        </FlexRow>
      </Section>
    </React.Fragment>
  );
}

function StaffServiceOverview({ model }) {
  const { id, numberOfMonths, serviceType, hoursPerMonth, priorityLevel, startDate, endDate, businessRole } = model;

  const [state, setState] = useState({});
  const { stats } = state;

  useEffect(() => {
    dL.getAccountBalance(id).then(function (amount) {
      return dL.getChargeBalance(id).then(function ({ chargeAmount, stats }) {
        setState({ ...state, balanceDue: amount, chargeAmount, stats });
      });
    });
  }, []);

  const { timeSeconds, openTaskCount, openTaskHours, availableHoursForMonth } = stats ? stats : {};

  return (
    <React.Fragment>
      <Section>
        <HeaderText subHeader label="Contact Details:" />
        <FlexRow>
          <LabelItem label="Service Type:" value={getLabelItem(utils.staffServiceTypes, serviceType)} />
          <LabelItem label="Business Role:" description="Business role contracted for this service" value={businessRole.name} />
          <LabelItem label="Priority Level:" description="Priority level that you tasks will get with the business role." value={getLabelItem(utils.priorityLevels, priorityLevel)} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Contract Length:" value={plural(numberOfMonths, "month")} />
          <LabelItem label="Hours (Monthly):" description="Number of include hours per month of the business role." value={plural(hoursPerMonth, "hr")} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Start Date:" value={Moment(startDate).format("M/D/YYYY")} />
          <LabelItem label="End Date:" value={Moment(endDate).format("M/D/YYYY")} />
        </FlexRow>
      </Section>

      {stats ? (
        <Section>
          <HeaderText subHeader label="Usage Stats:" />
          <FlexRow>
            <LabelItem label="Tasks:" value={plural(openTaskCount, "task") + (openTaskCount > 0 ? " (" + plural(openTaskHours, "hr") + ")" : "")} />
            <LabelItem label="Used Hours:" value={Math.round(timeSeconds / 60, 1) + " of " + plural(availableHoursForMonth, "hr")} />
          </FlexRow>
        </Section>
      ) : null}
    </React.Fragment>
  );
}

function RenderStaffAugShare({ item, onPress, onEdit, onDelete }) {
  const { sectionId, staffAugServiceId } = useParams();
  const history = useHistory();

  const { requireApproval, tasks, subShares, assignedTo, hoursDay, hoursWeek, hoursMonth } = item;

  return (
    <BoxItem onEdit={onEdit} onDelete={onDelete} rightSide={<TouchButton style={{ marginTop: 15 }} label="View" micro onPress={onPress} />}
      render={
        <View>
          {tasks.length > 0 ? (
            <ItemExpandSection style={{ marginTop: 10, marginleft: 25 }} label={plural(tasks.length, "Open Task") + ":"}>
              {tasks.map(task => {
                const { title, status, hours, id } = task

                return <BoxItem onBoxPress={() => {
                  history.push(`/${sectionId}/tasks/${id}`)
                }}>
                  <FlexRow>
                    <FlexExpand>
                      <FlexRow>
                        <TextLine value={title} />
                        {hours ? <TextLine size={14} left={8} value={`(${plural(hours, "hr")})`} /> : null}
                      </FlexRow>
                    </FlexExpand>
                    <TextLine value={getTaskStatus(status)} />
                  </FlexRow>
                </BoxItem>;
              })}
            </ItemExpandSection>
          ) : null}

          {subShares.length > 0 ? (
            <ItemExpandSection style={{ marginTop: 10, marginleft: 25 }} label={"Shared With: " + plural(subShares.length, "user") + ":"}>

              {subShares.map(share => {
                return <RenderStaffAugShare
                  onPress={() => {
                    history.push(`/${sectionId}/${staffAugServiceId}/staff-share/${share.id}`);
                  }} item={share} />;
              })}
            </ItemExpandSection>
          ) : null}
        </View>}>
      <FlexRow>
        <LabelUserItem label="Shared With:" user={assignedTo} />

        {hoursDay ? <LabelItem label="Hrs/Day:" value={hoursDay} /> : null}
        {hoursWeek ? <LabelItem label="Hrs/Week:" value={hoursWeek} /> : null}
        {hoursMonth ? <LabelItem label="Hrs/Month:" value={hoursMonth} /> : null}

        <LabelItem label="Require Approval:" value={requireApproval ? "YES" : "NO"} />
      </FlexRow>
    </BoxItem>
  );
}

function PeopleShare({ item }) {
  const history = useHistory();

  const { tasks, assignedTo } = item;

  var openTaskHours = 0;
  const openTaskCount = tasks.length;
  tasks.forEach(task => {
    openTaskHours += task.hours;
  });

  return (
    <BoxItem>
      <FlexRow>
        <LabelUserItem label="Team Member:" user={assignedTo} />

        <LabelItem label="Open Tasks:" value={plural(openTaskCount, "task") + (openTaskCount > 0 ? " (" + plural(openTaskHours, "hr") + ")" : "")} />
      </FlexRow>

      {tasks.length > 0 ? (
        <View style={{ marginleft: 25 }}>
          <LabelItem inline label="Tasks:" value={plural(tasks.length, "open task")} />
          {tasks.map(task => {
            const { title, hours, id } = task

            return <BoxRowItem onPress={() => {
              history.push("/user/tasks/" + id)
            }}>
              <FlexRow >
                <FlexExpand>
                  {title}
                </FlexExpand>
                {plural(hours, "hr")}
              </FlexRow>
            </BoxRowItem>;
          })}
        </View>
      ) : null}
    </BoxItem>
  );
}
