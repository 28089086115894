import "./App.css";
import { TouchableOpacity } from "react-native";
import React, { useState, useEffect } from "react";
import dL from "root/utils/dl";
import { Spinner } from "react-bootstrap"
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useHistory } from "react-router-dom";
import "root/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { SelectUser, Card1, Card2, HeaderText } from "root/pack-2";
import { useDebounce, BoxItem3, RenderArrayItems, ModalBasic, FlexExpand, FlexRow, TabBar, Text, TextLine, View, MyInput, TouchButtonOnOff, TouchButton, SelectBox, Avatar, NoRecords } from "root/pack-1";

const { debounce, moneyRange, getLabelItems, money, skillLevels, plural, getLabelItem, serviceTypes } = utils;

export function RenderChecklist({ label, description, items }) {
  var nextChecklistIndex = 0;
  var completed = false;
  for (var i = 0; i < items.length; i++) {
    const { isComplete, optional } = items[i];

    if (!optional) {
      if (isComplete && !isComplete()) {
        nextChecklistIndex = i;
        completed = false;
        break;
      }
    }
  }

  return !completed ? (
    <View style={{ width: 350 }}>
      <HeaderText subHeader label={label} description={description} />

      {items.map((item, index) => {
        const { optional, type, label, description, buttonText, onButtonPress, isComplete } = item;
        const isCompleted = isComplete && isComplete();

        if (type == "button") {
          return <TouchButton style={{ width: "100%" }} size="sm" onPress={onButtonPress} label={buttonText ? buttonText : "GO"} />;
        } else {
          return (
            <BoxItem3 checked={isCompleted} style={{ padding: 12 }} hasCheck={true}>
              <FlexRow alignTop>
                {label ? (
                  <FlexExpand>
                    <TextLine value={"#" + (index + 1) + ": " + label} size={14} />
                    {description ? <TextLine value={description} grey size={12} style={{ lineHeight: "16px" }} /> : null}
                  </FlexExpand>
                ) : null}
              </FlexRow>

              {isCompleted ? <TextLine top={10} value="COMPLETED" size={12} bold /> : null}

              {onButtonPress && !isCompleted ? <TouchButton fontStyle={{ fontSize: 12 }} className="btn-mn" grey={index > nextChecklistIndex || optional} style={{ padding: 4, paddingLeft: 8, paddingRight: 8, marginTop: 6 }} size="sm" onPress={onButtonPress} label={buttonText ? buttonText : "GO"} /> : null}
            </BoxItem3>
          );
        }
      })}
    </View>
  ) : (
    <View />
  );
}

export function EditKeyUser({ roleName, style, readonly, label, value, isChatHilight, onCommentPress, onChange }) {
  return (
    <View style={[{ marginBottom: 18 }, style]}>
      <TextLine value={label} bottom={8} />
      <SelectUser roleName={roleName} readonly={readonly} isChatHilight={isChatHilight} onCommentPress={onCommentPress} onChange={onChange} value={value} />
    </View>
  );
}

export function RenderUser({ style, children, item, onPress }) {
  const rect = { overflow: "hidden", width: 225, height: 300, borderRadius: 6.7, borderWidth: 2, borderColor: "#c0c0c0", paddingTop: 25, paddingBottom: 12, paddingHorizontal: 12, marginRight: 12, marginBottom: 15 };
  const rect2 = { width: "100%", textAlign: "center", height: 65, alignItems: "center", borderWidth: 1, justifyContent: "flex-end" };
  const rect1 = { marginTop: 12, width: "100%", textAlign: "center", flex: 1, alignItems: "center", borderWidth: 1, justifyContent: "center" };

  const { firstName, lastName, avatar, companyName, title, serviceCore, serviceTypes } = item;

  return (
    <TouchableOpacity onPress={onPress} style={[rect, style]}>
      <View style={rect1} alignCenter>
        <Avatar value={avatar} />
        <TextLine top={12} size={18} value={utils.concatString(firstName, " ", lastName)} />
        <TextLine top={4} size={14} value={utils.concatString(title, " at ", companyName)} />
      </View>

      <View style={rect2}>
        {serviceTypes ? <TextLine maxLines={2} color="grey" size={12} value={utils.renderArray(serviceTypes)} /> : null}
        {serviceCore ? <TextLine top={6} color="grey" size={12} value={utils.renderArray(serviceCore)} /> : null}
      </View>
      {children}
    </TouchableOpacity>
  );
}

export function DisplayKeyUser({ style, label, value, isChatHilight, onCommentPress }) {
  return (
    <View style={[{ marginBottom: 18 }, style]}>
      <TextLine value={label} bottom={8} />
      <SelectUser readonly isChatHilight={isChatHilight} onCommentPress={onCommentPress} value={value} />
    </View>
  );
}

export function LimitServiceOptionsBox({ label, value, options, onChange }) {
  if (options) {
    const opts = options.map(item => {
      return { label: item.name, value: item.option.id };
    });
    return (
      <SelectBox
        multiple
        label={label ? label : "Limit to Service Options:"}
        value={value ? value.map(value => opts.find(item => item.value == value)) : null}
        options={opts}
        onChange={items => {
          onChange(items.map(item => item.value));
        }}
      />
    );
  } else {
    return <View />;
  }
}

export function LimitProposalOptionsBox({ label, value, options, onChange }) {
  if (options) {
    const opts = options.map(item => {
      return { label: item.name, value: item.id };
    });
    return (
      <SelectBox
        multiple
        label={label ? label : "Limit to Proposal Options:"}
        value={value ? value.map(value => opts.find(item => item.value == value)) : null}
        options={opts}
        onChange={items => {
          onChange(items.map(item => item.value));
        }}
      />
    );
  } else {
    return <View />;
  }
}

export function LimitProposalPackageBox({ label, value, packages, onChange }) {
  if (packages) {
    const opts = packages.map(item => {
      return { label: item.name, value: item.id };
    });
    return (
      <SelectBox
        multiple
        label={label ? label : "Limit to Bundled Packages:"}
        value={value ? value.map(value => opts.find(item => item.value == value)) : null}
        options={opts}
        onChange={items => {
          onChange(items.map(item => item.value));
        }}
      />
    );
  } else {
    return <View />;
  }
}

export function LimitPackageBox({ label, value, packages, onChange }) {
  if (packages) {
    const opts = packages.map(item => {
      return { label: item.name, value: item.package.id };
    });
    return (
      <SelectBox
        multiple
        label={label ? label : "Limit to Bundled Packages:"}
        value={value ? value.map(value => opts.find(item => item.value == value)) : null}
        options={opts}
        onChange={items => {
          onChange(items.map(item => item.value));
        }}
      />
    );
  } else {
    return <View />;
  }
}

export function RenderLimitedOptions({ value, options }) {
  if (options) {
    return <RenderArrayItems label="Include for Options:" value={value} list={options} onGetValue={(item) => { return item.option.id }} />;
  } else {
    return <View />
  }
}

export function RenderLimitedPackages({ value, packages }) {
  return <RenderArrayItems label="Included in Packages:" value={value} list={packages} onGetValue={(item) => { return item.package.id }} />;
}

export function RenderServiceItemBuyer({ onPress, item }) {
  //const [state, setState] = useState({})
  //const { deliveryDays, minPrice, maxPrice } = state

  const { icon, businessRole, skillLevel, name, shortDescription, serviceType, companyStages, minPrice, maxPrice, actualDeliveryDays: deliveryDays } = item;

  const serviceTypeItem = utils.serviceTypes.find(item => item.value == serviceType);
  const companyStagesText = getLabelItems(utils.companyStages, companyStages);

  /*
    useEffect(() => {
      dL.getService(id, true).then(function (service) {
        const { enablePackages, packages, options } = service
        var fDeliveryDays, fMinPrice = 999999, fMaxPrice = 0
  
        if (enablePackages) {
          packages.forEach(servicePackage => {
            const { id, deliveryDays, minPrice, maxPrice } = servicePackage
  
            const optOpts = getOptionalServiceOptionsForBundledPackage({ serviceOptions: options, packageId: id });
  
            const combos = utils.combineArray(optOpts)
            combos.forEach(combo => {
              const dt = {}
              combo.forEach(item => {
                dt[item.id] = true
              })
              const price = getServicePrice({ service, packageId: id, serviceOptionIds: dt })
              if (price < fMinPrice) {
                fMinPrice = price
              }
              if (price > fMaxPrice) {
                fMaxPrice = price
              }
            })
  
            if (deliveryDays < fDeliveryDays) {
              fDeliveryDays = deliveryDays
              if (minPrice < fMinPrice) {
                fMinPrice = minPrice
              }
              if (maxPrice > fMaxPrice) {
                fMaxPrice = maxPrice
              }
            }
          })
        } else {
          var fMinPrice = 999999, fMaxPrice = 0
          const combos = utils.combineArray(options)
          combos.forEach(combo => {
            const dt = {}
            combo.forEach(item => {
              dt[item.id] = true
            })
            const price = getServicePrice({ service, serviceOptionIds: dt })
            if (price < fMinPrice) {
              fMinPrice = price
            }
            if (price > fMaxPrice) {
              fMaxPrice = price
            }
          })
  
          const { deliveryDays } = service
          fDeliveryDays = deliveryDays
          fMinPrice = fMinPrice
          fMaxPrice = fMaxPrice
        }
  
        setState({ ...state, deliveryDays: fDeliveryDays, minPrice: fMinPrice, maxPrice: fMaxPrice })
      })
    }, [])
  */

  return (
    <Card1
      icon={icon}
      title={name}
      onPress={onPress}
      description={shortDescription}
      footer={<View>
        {serviceTypeItem ? serviceTypeItem.label : null}
        {companyStagesText ? " for " + companyStagesText : null}
      </View>}
      rightRender={<Price priceText2={moneyRange(minPrice, maxPrice)} days={deliveryDays} />}>
      {businessRole ? <TextLine grey size={14} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : null}
    </Card1>
  );
}

export function RenderServiceItemWorker({ onPress, item }) {
  const { icon, businessRole, skillLevel, name, shortDescription, serviceType, companyStages, actualDeliveryDays: deliveryDays, minPrice, maxPrice } = item;

  const serviceTypeItem = utils.serviceTypes.find(item => item.value == serviceType);
  const companyStagesText = getLabelItems(utils.companyStages, companyStages);

  return (
    <Card1
      icon={icon}
      title={name}
      onPress={onPress}
      description={shortDescription}
      footer={<View>
        {serviceTypeItem ? serviceTypeItem.label : null}
        {companyStagesText ? " for " + companyStagesText : null}
      </View>}
      rightRender={<Price priceText2={moneyRange(minPrice, maxPrice)} days={deliveryDays} />}>
      {businessRole ? <TextLine grey size={14} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : null}
    </Card1>
  );
}

export function RenderUserServiceWorker({ onPress, userService, onEdit, onDelete }) {
  const { icon, id, minPrice, maxPrice, actualDeliveryDays, workHours } = userService;
  const { name, serviceType, companyStages, skillLevel } = userService;
  const { businessRole } = userService;

  const companyStagesText = getLabelItems(utils.companyStages, companyStages);

  return (
    <Card2 icon={icon} cardSize="col-sm-12 mb-2 mb-sm-3" key={id} title={name} onPress={onPress} onEdit={onEdit} onDelete={onDelete} aboveEdit={<View style={{ textAlign: "right", marginBottom: 10 }}>
      <Price priceText2={moneyRange(minPrice, maxPrice)} days={actualDeliveryDays} />
    </View>}>
      <FlexRow alignTop>
        <FlexExpand>
          {businessRole ? <TextLine grey size={14} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : null}

          <FlexRow>
            {workHours ? <TextLine grey size={14} value={"Work Hours: " + plural(workHours, "hr")} /> : null}
          </FlexRow>

          <TextLine color="grey" top={8} size={12} value={serviceType ? getLabelItem(utils.serviceTypes, serviceType) : "" + companyStagesText ? (serviceType ? " for " : "") + companyStagesText : null} />
        </FlexExpand>
      </FlexRow>
    </Card2>
  );
}

/*
export function RenderServiceItemWorker2({ onPress, item }) {
  const { businessRole, skillLevel, name, shortDescription, serviceType, companyStages } = item;

  const serviceTypeItem = utils.serviceTypes.find(item => item.value == serviceType);
  const companyStagesText = getLabelItems(utils.companyStages, companyStages);

  const hourlyRate = businessRole && businessRole.rates ? businessRole.rates[skillLevel] : 0;

  const tasks = getTasksForService({ service: item });
  const workHours = getWorkHours({ tasks });

  const deliveryDays = getServiceDeliveryDays({ service: item });

  const priceText = getServicePriceText({ includeSubServiceCost: true, service: item, workerHourlyRate: hourlyRate });

  return (
    <Card1
      title={name}
      onPress={onPress}
      description={shortDescription}
      footer={<View>
        {serviceTypeItem ? serviceTypeItem.label : null}
        {companyStagesText ? " for " + companyStagesText : null}
      </View>}
      rightRender={<span class="d-block h4 text-primary text-lh-sm mb-0">{priceText}</span>} >
      { businessRole ? <TextLine grey size={14} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} /> : null
      }

      <FlexRow style={{ marginBottom: 10 }}>
        <TextLine grey size={14} value={"Delivery: " + plural(deliveryDays, "day")} spacer />

        <TextLine grey size={14} value={"Work Hours: " + plural(workHours, "hr")} spacer />
      </FlexRow>
    </Card1 >
  );
}
*/

export function Quantity({ style, value, children, onUpdate, allowAdjust }) {
  return (
    <View style={[{ textAlign: "center", marginLeft: 25 }, style]}>
      <FlexRow style={{ justifyContent: "center" }}>
        {allowAdjust ? (
          <TouchableOpacity
            style={{ marginRight: 8 }}
            onPress={() => {
              if (value - 1 >= 0) {
                onUpdate(value - 1);
              }
            }}>
            <FontAwesomeIcon icon={faMinus} style={{ fontSize: 18, color: "#c0c0c0" }} />
          </TouchableOpacity>
        ) : null}
        <TextLine size={18} value={String(value)} />
        {allowAdjust ? (
          <TouchableOpacity
            style={{ marginLeft: 8 }}
            onPress={() => {
              onUpdate(value + 1);
            }}>
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: 18, color: "#c0c0c0" }} />
          </TouchableOpacity>
        ) : null}
      </FlexRow>

      <TextLine size={12} grey>
        Quantity
      </TextLine>
      {children}
    </View>
  );
}

export function Price({ strikeThrough, allowedRevisions, size, size2, style, price, label, priceText2, days, deliveryText, priceText, children, hasOverride }) {
  return (
    <View alignRight style={[{ marginLeft: 25 }, style]}>
      {price != null ? (
        <FlexRow alignRight>
          {label ? <TextLine grey size={size2 ? size2 : 14} value={label} right={10} /> : null}
          <TextLine strikeThrough={strikeThrough} size={size ? size : 24} >
            {priceText}
            {price != null ? money(price) : priceText}
          </TextLine>
          {hasOverride ? " **" : null}
        </FlexRow>
      ) : null}
      {priceText2 ? <TextLine strikeThrough={strikeThrough} size={size ? size : 24}>{priceText2}</TextLine> : null}
      {days ? (
        <TextLine size={size2 ? size2 : 14} color="grey" alignRight>
          {deliveryText}
          {plural(days, "day")}
        </TextLine>
      ) : null}
      {allowedRevisions ? <TextLine alignRight value={"Revisions: " + getLabelItem(utils.revisionOptions, allowedRevisions)} /> : null}
      {children}
    </View>
  );
}

export function ServiceCatList({ displayNoCategory, emptyLabel, onSelect, onGetDescription, onShouldDisplay }) {
  const serviceTypesList = displayNoCategory ? [{ label: "No Category", value: null }, ...serviceTypes] : serviceTypes
  const items = serviceTypesList.filter(item => {
    const { value } = item;
    var visible = true;
    if (onShouldDisplay) {
      visible = onShouldDisplay(value);
    }
    return visible;
  });

  if (items.length == 0) {
    return <NoRecords label={emptyLabel ? emptyLabel : "No records found."} />;
  }

  return (
    <div class="row">
      {items.map(item => {
        const { value, label } = item;
        return (
          <Card2
            bodyStyle={{ cursor: "pointer" }}
            key={value}
            title={label}
            description={onGetDescription ? onGetDescription(value) : null}
            onPress={() => {
              onSelect(item);
            }}
          />
        );
      })}
    </div>
  );
}

export function ListRender2({ renderTop, defaultTabValue, onFilterChange, displayNoCategory, queryId, subHeader, showSectorsWithItemsOnly, topFilters, onResetFilter, filterValues, columns, defaultSearchText, onRefresh, rightRender, onWhere, filters, onButtonPress, where, type, emptyLabel, includes, title, description, statuses, renderItem, searchFields, searchNumber, defaultSort, renderFilter, onFilter }) {
  const history = useHistory();
  const params = new URLSearchParams(document.location.search.substring(1));
  const [state, setState] = useState({ isLoading: true, filterVisible: false });
  const [status, setStatus] = useState(defaultTabValue ? defaultTabValue : filters && filters.length > 0 ? filters[0].value : null);
  const [searchText, setSearchText] = useState(queryId && params.get(queryId) ? params.get(queryId) : defaultSearchText);
  const [isSearching, setIsSearching] = useState(false);
  const [stats, setStats] = useState();
  const [topStats, setTopStats] = useState();
  const [refresh, setRefresh] = useState(new Date());
  const [tabTopValue, setTabTopValue] = useState(topFilters ? topFilters[0].value : null);
  const [tabGridValue, setTabGridValue] = useState("grid");
  const { isLoading, list, filterVisible } = state;
  const [servicesForSector, setServicesForSector] = useState();
  const [businessSector, setBusinessSector] = useState();
  const debouncedSearchText = useDebounce(searchText, 250);

  var onQuery;
  var filter;
  if (filters) {
    filter = filters.find(item => item.value == status);
    onQuery = filter.onQuery;
  }
  var topFilter;
  if (tabTopValue) {
    topFilter = topFilters.find(item => item.value == tabTopValue);
  }

  useEffect(() => {
    const filter = filters.find(item => item.value == status);

    var match;
    if (filter.onQuery) {
      const mquery = dL.getQuery();
      onWhere && onWhere(mquery);
      if (topFilter && topFilter.onQuery) {
        topFilter.onQuery(mquery);
      }
      if (filter && filter.onQuery) {
        filter.onQuery(mquery);
      }
      match = mquery.query;
    }

    dL.getListRenderStatsPerSector({ match, collection: type }).then(function (stats) {
      setServicesForSector(stats);
    });
  }, [status, tabTopValue]);

  useEffect(() => {
    if (filters) {
      dL.getStats({ type, filters, onWhere, where, onFilter, topFilter }).then(function (stats) {
        setStats(stats);
      });
    }

    if (topFilters) {
      dL.getTopStats({ type, onWhere, where, topFilters }).then(function (stats) {
        setTopStats(stats);
      });
    }
  }, [refresh, tabTopValue]);

  useEffect(() => {
    dL.getList({
      onWhere,
      searchFields,
      searchNumber,
      defaultSort,
      type,
      filter,
      onQuery,
      where,
      searchText,
      status: status != "all" ? status : null,
      includes,
      onFilter,
      topFilter
    }).then(function (list) {
      onRefresh && onRefresh({ list, status, searchText });
      setIsSearching(false);
      setState({ ...state, isLoading: false, list });
    });
  }, [status, debouncedSearchText, status, refresh, tabTopValue]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const tabItems = filters ? filters : statuses;

  var options;

  if (tabItems) {
    options = [];

    tabItems.forEach(item => {
      const { label, value, autoHide, description } = item;
      if (!autoHide || !stats || stats[value] > 0) {
        options.push({ label: label + (stats && stats[value] ? " (" + stats[value] + ")" : ""), value, description });
      }
    });
  }

  if (searchText && options) {
    options.unshift({ label: "Search", value: "search" });
  }

  var topOptions;
  if (topFilters) {
    topOptions = [];
    topFilters.forEach(item => {
      const { label, value, autoHide, description } = item;
      if (!autoHide || !topStats || topStats[value] > 0) {
        topOptions.push({ label: label + (topStats && topStats[value] ? " (" + topStats[value] + ")" : ""), value, description });
      }
    });
  }

  const optionItem = tabItems ? tabItems.find(item => item.value == status) : null;
  const topOptionItem = topOptions ? topOptions.find(item => item.value == tabTopValue) : null;

  const render = function () {
    var fList = list;
    if (businessSector) {
      fList = list.filter(item => item.serviceType == businessSector);
    }
    return fList.length == 0 ? (
      <NoRecords label={emptyLabel} />
    ) : (
      fList.map(item => {
        return renderItem(item);
      })
    );
  };

  const renderSearch = function () {
    var hasFilter = false;
    for (var { } in filterValues) {
      hasFilter = true;
      break;
    }
    return (
      <FlexRow alignTop>
        <TabBar
          queryId="tl"
          style={{ marginRight: 10 }}
          options={[
            {
              label: "Grid",
              value: "grid"
            },
            {
              label: "List",
              value: "list"
            }
          ]}
          onChange={item => {
            setTabGridValue(item.value);
          }}
          value={tabGridValue}
        />

        {searchFields ? (
          <FlexRow style={{ flex: 1, position: "relative" }}>
            <MyInput
              boxStyle={{ flex: 1, marginBottom: 0 }}
              isLoading={isSearching}
              placeholder="Search..."
              value={searchText}
              onChange={text => {
                setIsSearching(true);
                setSearchText(text);

                if (queryId) {
                  if (text) {
                    params.set(queryId, text);
                  } else {
                    params.delete(queryId);
                  }
                  history.replace({ search: params.toString() });
                }
              }}
            />
            {isSearching ? <View style={{ position: "absolute", right: 10, top: 10 }}><Spinner animation="border" /></View> : null}
          </FlexRow>
        ) : null}

        {renderFilter ? (
          <TouchButtonOnOff
            style={{ marginLeft: 15 }}
            ONLabel="Filter ON"
            OFFLabel="Filter OFF"
            value={hasFilter}
            onPress={() => {
              setState({ ...state, filterVisible: true });
            }}
          />
        ) : null}

        {filterVisible ? (
          <ModalBasic
            title="Filter"
            okText="Filter"
            onCancel={() => {
              setState({ ...state, filterVisible: false });
            }}
            buttons={[
              {
                label: "Reset",
                onPress: () => {
                  onResetFilter();
                  setRefresh(new Date());
                  setState({ ...state, filterVisible: false });
                }
              }
            ]}
            onOk={() => {
              setRefresh(new Date());
              setState({ ...state, filterVisible: false });
            }}>
            {renderFilter()}
          </ModalBasic>
        ) : null}
      </FlexRow>
    );
  };

  return (
    <View>
      {title ? (
        <HeaderText
          subHeader={subHeader}
          onButtonPress={onButtonPress}
          label={title}
          description={description}
          style={{ marginBottom: 10 }}
          rightRender={<View>
            {renderSearch()}
            {rightRender}
          </View>}
        />
      ) : (
        <View style={{ marginBottom: 20 }}>{renderSearch()}</View>
      )}
      {renderTop}

      {topFilters || filters ? (
        <FlexRow style={{ marginBottom: 20 }} alignTop>
          {topFilters && topOptions.length > 1 ? (
            <View>
              <TabBar
                queryId="tl"
                style={{ marginRight: 10 }}
                options={topOptions}
                onChange={item => {
                  setSearchText(null);
                  setTabTopValue(item.value);
                }}
                value={tabTopValue}
              />

              {topOptionItem && topOptionItem.description && !searchText ? <TextLine left={12} top={8} color="grey" size={14} value={topOptionItem.description} /> : null}
            </View>
          ) : null}

          {filters ? (
            <View>
              {options.length > 1 ? (
                <TabBar
                  queryId="tb"
                  options={options}
                  onChange={item => {
                    onFilterChange && onFilterChange(item)
                    setSearchText("");
                    setStatus(item.value);
                    setBusinessSector();
                  }}
                  value={searchText ? "search" : status}
                />
              ) : null}
              {optionItem.description && !searchText ? <TextLine left={12} top={8} color="grey" size={14} value={optionItem.description} /> : null}
            </View>
          ) : null}
        </FlexRow>
      ) : null}

      {tabGridValue == "grid" && !businessSector && !searchText ? (
        <ServiceCatList
          displayNoCategory={displayNoCategory}
          emptyLabel={emptyLabel}
          onShouldDisplay={
            showSectorsWithItemsOnly
              ? sectorValue => {
                if (servicesForSector && servicesForSector[sectorValue] > 0) {
                  return true;
                }
                return false;
              }
              : null
          }
          onGetDescription={sectorValue => {
            return servicesForSector && servicesForSector[sectorValue] ? plural(servicesForSector[sectorValue], "service") : "";
          }}
          onSelect={item => {
            setBusinessSector(item.value);
          }}
        />
      ) : null}

      {tabGridValue == "grid" && businessSector && !searchText ? (
        <View>
          <HeaderText
            subHeader={true}
            label={getLabelItem(serviceTypes, businessSector) + ":"}
            rightRender={<TouchButton
              size="sm"
              onPress={() => {
                setBusinessSector();
              }}
              className="btn-mn"
              grey
              label="Back"
            />}
          />
        </View>
      ) : null}

      {businessSector || searchText || tabGridValue == "list" ? columns ? <FlexRow>{render()}</FlexRow> : render() : null}
    </View>
  );
}

export function ListRenderBS({ queryId, userServices, topFilters, onResetFilter, filterValues, columns, defaultSearchText, onRefresh, rightRender, onWhere, filters, onButtonPress, where, type, emptyLabel, includes, title, description, statuses, renderItem, searchFields, searchNumber, defaultSort, renderFilter, onFilter }) {
  const history = useHistory();
  const params = new URLSearchParams(document.location.search.substring(1));
  const [state, setState] = useState({ isLoading: true, filterVisible: false });
  const [status, setStatus] = useState(filters && filters.length > 0 ? filters[0].value : null);
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [isSearching, setIsSearching] = useState(false);
  const [stats, setStats] = useState();
  const [topStats, setTopStats] = useState();
  const [refresh, setRefresh] = useState(new Date());
  const [tabTopValue, setTabTopValue] = useState(topFilters ? topFilters[0].value : null);
  const { isLoading, list, filterVisible } = state;
  const [servicesForSector, setServicesForSector] = useState();
  const [businessSector, setBusinessSector] = useState();

  var onQuery;
  var filter;
  if (filters) {
    filter = filters.find(item => item.value == status);
    onQuery = filter.onQuery;
  }
  var topFilter;
  if (tabTopValue) {
    topFilter = topFilters.find(item => item.value == tabTopValue);
  }

  useEffect(() => {
    dL.getServicesPerSector2({ userServices, status }).then(function (stats) {
      setServicesForSector(stats);
    });
  }, [status]);

  useEffect(() => {
    if (filters) {
      dL.getStats({ type, filters, onWhere, where, onFilter, topFilter }).then(function (stats) {
        setStats(stats);
      });
    }

    if (topFilters) {
      dL.getTopStats({ type, onWhere, where, topFilters }).then(function (stats) {
        setTopStats(stats);
      });
    }
  }, [refresh, tabTopValue]);

  useEffect(() => {
    dL.getList({
      onWhere,
      searchFields,
      searchNumber,
      defaultSort,
      type,
      filter,
      onQuery,
      where,
      searchText,
      status: status != "all" ? status : null,
      includes,
      onFilter,
      topFilter
    }).then(function (list) {
      onRefresh && onRefresh({ list, status, searchText });
      setIsSearching(false);
      setState({ ...state, isLoading: false, list });
    });
  }, [status, searchText, status, refresh, tabTopValue]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const tabItems = filters ? filters : statuses;

  var options;

  if (tabItems) {
    options = [];

    tabItems.forEach(item => {
      const { label, value, autoHide } = item;
      if (!autoHide || !stats || stats[value] > 0) {
        options.push({ label: label + (stats && stats[value] ? " (" + stats[value] + ")" : ""), value });
      }
    });
  }

  if (searchText && options) {
    options.unshift({ label: "Search", value: "search" });
  }

  var topOptions;
  if (topFilters) {
    topOptions = [];
    topFilters.forEach(item => {
      const { label, value, autoHide } = item;
      if (!autoHide || !topStats || topStats[value] > 0) {
        topOptions.push({ label: label + (topStats && topStats[value] ? " (" + topStats[value] + ")" : ""), value });
      }
    });
  }

  const optionItem = tabItems ? tabItems.find(item => item.value == status) : null;
  const topOptionItem = topOptions ? topOptions.find(item => item.value == tabTopValue) : null;

  const render = function () {
    var fList = list;
    if (businessSector) {
      fList = list.filter(item => item.serviceType == businessSector);
    }
    return fList.length == 0 ? (
      <NoRecords label={emptyLabel} />
    ) : (
      fList.map(item => {
        return renderItem(item);
      })
    );
  };

  const renderSearch = function () {
    var hasFilter = false;
    for (var { } in filterValues) {
      hasFilter = true;
      break;
    }
    return (
      <FlexRow alignTop>
        {searchFields ? (
          <MyInput
            boxStyle={{ flex: 1, marginBottom: 0 }}
            isLoading={isSearching}
            placeholder="Search..."
            value={searchText}
            onChange={text => {
              setIsSearching(true);
              setSearchText(text);

              if (queryId) {
                if (text) {
                  params.set(queryId, text);
                } else {
                  params.delete(queryId);
                }
                history.replace({ search: params.toString() });
              }
            }}
          />
        ) : null}

        {renderFilter ? (
          <TouchButtonOnOff
            style={{ marginRight: 15 }}
            ONLabel="Filter ON"
            OFFLabel="Filter OFF"
            value={hasFilter}
            onPress={() => {
              setState({ ...state, filterVisible: true });
            }}
          />
        ) : null}

        {filterVisible ? (
          <ModalBasic
            title="Filter"
            okText="Filter"
            onCancel={() => {
              setState({ ...state, filterVisible: false });
            }}
            buttons={[
              {
                label: "Reset",
                onPress: () => {
                  onResetFilter();
                  setRefresh(new Date());
                  setState({ ...state, filterVisible: false });
                }
              }
            ]}
            onOk={() => {
              setRefresh(new Date());
              setState({ ...state, filterVisible: false });
            }}>
            {renderFilter()}
          </ModalBasic>
        ) : null}
      </FlexRow>
    );
  };

  return (
    <View>
      {title ? <HeaderText onButtonPress={onButtonPress} label={title} description={description} style={{ marginBottom: 10 }} rightRender={<View>{rightRender}</View>} /> : null}

      {topFilters || filters ? (
        <FlexRow style={{ marginBottom: 20 }} alignTop>
          <FlexExpand>
            {topFilters && topOptions.length > 1 ? (
              <View>
                <TabBar
                  queryId="tl"
                  style={{ marginRight: 10 }}
                  options={topOptions}
                  onChange={item => {
                    setTabTopValue(item.value);
                  }}
                  value={tabTopValue}
                />

                {topOptionItem && topOptionItem.description && !searchText ? <TextLine left={12} top={8} color="grey" size={14} value={topOptionItem.description} /> : null}
              </View>
            ) : null}

            {filters ? (
              <View>
                {options.length > 1 ? (
                  <TabBar
                    queryId="tb"
                    options={options}
                    onChange={item => {
                      setStatus(item.value);
                      setBusinessSector();
                    }}
                    value={searchText ? "search" : status}
                  />
                ) : null}
                {optionItem.description && !searchText ? <TextLine left={12} top={8} color="grey" size={14} value={optionItem.description} /> : null}
              </View>
            ) : null}
          </FlexExpand>
          {renderSearch()}
        </FlexRow>
      ) : null}

      {!businessSector && !searchText ? (
        <ServiceCatList
          emptyLabel={emptyLabel}
          onShouldDisplay={sectorValue => {
            if (servicesForSector && servicesForSector[sectorValue] > 0) {
              return true;
            }
            return false;
          }}
          onGetDescription={sectorValue => {
            return servicesForSector && servicesForSector[sectorValue] ? plural(servicesForSector[sectorValue], "service") : "";
          }}
          onSelect={item => {
            setBusinessSector(item.value);
          }}
        />
      ) : null}

      {businessSector && !searchText ? (
        <View>
          <HeaderText
            subHeader
            label={getLabelItem(serviceTypes, businessSector) + ":"}
            rightRender={<TouchButton
              size="sm"
              className="btn-mn"
              onPress={() => {
                setBusinessSector();
              }}
              grey
              label="Back"
            />}
          />
        </View>
      ) : null}

      {businessSector || searchText ? columns ? <FlexRow>{render()}</FlexRow> : render() : null}
    </View>
  );
}
