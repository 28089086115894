import http from "root/utils/http";
import session from "root/utils/session";
import db from "root/utils/db";
import fs from "root/utils/firestore";
import Moment from "moment-business-days";
import utils from "root/utils/global-utils";
import PromiseA from "root/utils/promise";
import load from "root/utils/dl-load";
import { getDataFromPriceMap, getDeliverablesForService, getDoneDate, getStartDate, getFinalPriceDays2, createProposalPriceMapBuyer, clearCache, getOptionalServiceOptionsForBundledPackage, getServicePrice2, getMinDeliveryDays, getServicePreReqItems, getVersionData, loadSubServiceVersion_Worker } from "root/service-functions";
import { getStaffTotal } from "root/staff-aug"
import { HashTable } from "root/hashtable";
import { IService, IServiceCategory } from "root/p1";

const cache = {}

const { roundNumber, isRequiredItem, getCircularIds, getAllItemsForVersion } = utils;

const getFirstLetterLower = function (value) {
  return value[0].toLowerCase() + value.slice(1)
}

function toLowerCase(value) {
  if (value == null) {
    return ""
  }
  return value.toLowerCase()
}

function getDate(dtStr) {
  if (dtStr) {
    return new Date(dtStr);
  }
}

function loadJSON(rtn, obj, field, defaultValue) {
  const obj2 = obj.get(field);
  if (obj2) {
    rtn[field] = utils.jsonParse(obj2);
  } else {
    rtn[field] = defaultValue;
  }
}

function loadObj(rtn, obj, type, field) {
  const obj2 = obj.get(field);
  if (obj2) {
    rtn[field] = dL["load" + type](obj2);
  }
}

const dL = {
  ...load,
  getTimeRecordHours: function ({ startDate, endDate }) {
    return db
      .getQuery("TimeRecord")
      .aggregate([
        {
          $match: {
            _p_user: "User$" + session.user.id,
            endDate: { $exists: true },
            startDate: { $gt: startDate },
            endDate: { $lte: endDate },
            removed: { $in: [undefined, false] }
          },
        },
        {
          $group: {
            _id: null,
            total: { $sum: "$seconds" }
          }
        }
      ])
      .then(function (obj) {
        if (obj.length > 0) {
          return roundNumber(obj[0].total / 60 / 60, 2)
        } else {
          return 0
        }
      });
  },
  getCartItems: function ({ userId }) {
    var items;
    return dL
      .getQuery("CartItem")
      .equalTo("user", db.getObj("User", userId ? userId : session.user.id))
      .containedIn("removed", [undefined, false])
      .include("service")
      .include("serviceVersion")
      .include("serviceOptions")
      .include("servicePackage")
      .include("userRole")
      .include("userRole.user")
      .include("businessRole")
      .include("assignedTo")
      .include("userService")
      .include("userService.user")
      .include("proposal")
      .include("proposalItem")
      .include("proposalVersion")
      .ascending("createdAt")
      .find()
      .then(function (objs) {
        items = dL.loadObjects("CartItem", objs);

        return Promise.all(
          items.map(item => {
            var promises = []

            const { service, userService, serviceVersion, userServiceVersion } = item

            if (service) {
              promises[promises.length] = dL.getService2({ serviceId: service.id }).then(function (service) {
                item.service = service;
              });
            }

            if (userService) {
              promises[promises.length] = dL.getUserService2({ userServiceId: userService.id }).then(function (userService) {
                item.userService = userService;
              });
            }

            if (serviceVersion) {
              promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: serviceVersion.id }).then(function (serviceVersion) {
                item.serviceVersion = serviceVersion;
              });
            }

            if (userServiceVersion) {
              promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userServiceVersion.id }).then(function (serviceVersion) {
                item.userServiceVersion = serviceVersion;
              });
            }

            return Promise.all(promises)
          })
        );
      })
      .then(function () {
        return Promise.all(
          items.map(item => {
            const { workerUsers, recommendedUser, assignedUser } = item;
            const promises = []
            if (recommendedUser) {
              promises[promises.length] = db
                .getQuery("User")
                .include("userRoles")
                .get(recommendedUser.id)
                .then(function (obj) {
                  item.recommendedUser = dL.loadUser(obj);
                });
            }
            if (assignedUser) {
              promises[promises.length] = db
                .getQuery("User")
                .include("userRoles")
                .get(assignedUser.id)
                .then(function (obj) {
                  item.assignedUser = dL.loadUser(obj);
                });
            }
            if (workerUsers) {
              promises[promises.length] = db
                .getQuery("User")
                .containedIn("_id", workerUsers.map(item => item.id))
                .containedIn("removed", [undefined, false])
                .include("userRoles")
                .find()
                .then(function (objs) {
                  item.workerUsers = dL.loadObjects("User", objs);
                });
            }
            return Promise.all(promises)
          })
        );
      }).then(function () {
        return items
      })
  },
  loadCart: function ({ userId }) {
    return dL.getCartItems({ userId }).then(function (items) {
      session.cart.items = items;
    });
  },
  getUserRoles: function ({ userId }) {
    return dL
      .getQuery("UserRole")
      .equalTo("user", dL.getObj("User", userId))
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .include("user")
      .find()
      .then(function (objs) {
        return dL.loadObjects("UserRole", objs);
      });
  },
  getObj: function (type, recordId) {
    return db.getObj(type, recordId);
  },
  getQuery: function (type) {
    return db.getQuery(type);
  },
  find: function (type, params) {
    const { where, includes } = params ? params : {};
    const query = db.getQuery(type);

    if (includes) {
      includes.forEach((item) => {
        query.include(item);
      });
    }

    return query.find().then(function (objs) {
      return dL.loadObject(type, objs);
    });
  },
  get: function (type, recordId, includes) {
    const query = db.getQuery(type);

    if (includes) {
      includes.forEach((item) => {
        query.include(item);
      });
    }

    return query.get(recordId).then(function (obj) {
      return dL["load" + type](obj);
    });
  },
  getAllProposalItems: function (items) {
    const arr = [];
    items.forEach((proposalItem) => {
      const { itemType } = proposalItem;
      arr.push(proposalItem);

      if (itemType == "stage") {
        const { items } = proposalItem;
        items.forEach((stepItem) => {
          const { items } = stepItem;
          arr.push(stepItem);

          items.forEach((item) => {
            arr.push(item);
          });
        });
      }
    });
    return arr;
  },
  getAllServiceProposalItems: function ({ proposal }) {
    const { allItems, currentProposalVersion } = proposal;
    const { items } = currentProposalVersion;

    const arr = [];
    items.forEach((item) => {
      const proposalItem = allItems.get(item.id);
      const { itemType } = proposalItem;
      if (itemType == "service") {
        arr.push(proposalItem);
      } else if (itemType == "staff") {
      } else if (itemType == "stage") {
        const { items } = proposalItem;
        item.items.forEach((item) => {
          const stageItem = allItems.get(item.id);
          const { items } = stageItem;
          stageItem.stageId = proposalItem.id;
          item.items.forEach((item) => {
            const serviceItem = allItems.get(item.id);
            const { itemType } = serviceItem;
            if (itemType == "service") {
              serviceItem.stageId = proposalItem.id;
              serviceItem.stageItemId = stageItem.id;
              arr.push(serviceItem);
            }
          });
        });
      }
    });
    return arr;
  },
  issueWorkerEarning: function ({ orderId, serviceOrderId, accountId, amount, releaseDate, userId }) {
    return db
      .getObj("Earning")
      .set("withdrawn", false)
      .set("amount", amount)
      .set("testMode", session.user.testMode)
      .set("earningDate", new Date())
      .set("releaseDate", releaseDate)
      .set("order", db.getObj("Order", orderId))
      .set("chargeAccount", accountId ? db.getObj("Account", accountId) : null)
      .set("serviceOrder", db.getObj("ServiceOrder", serviceOrderId))
      .set("user", db.getObj("User", userId))
      .save();
  },
  issueBonusWorker: function ({ relatedIds, description, userId, vesting }) {
    var totalAmount = 0;
    vesting.forEach((item) => {
      totalAmount += item.amount;
    });
    return dL.issueBonus({ relatedIds, users: [{ id: userId }], items: vesting, totalAmount, amountPerUser: totalAmount, description, bonusType: "individual" });
  },
  issueBonus: function ({ relatedIds, users, items, totalAmount, amountPerUser, description, bonusType }) {
    const bonusIssueDate = new Date();
    return db
      .getObj("Bonus")
      .set("relatedIds", relatedIds)
      .set("issueDate", bonusIssueDate)
      .set(
        "users",
        users.map((item) => db.getObj("User", item.id))
      )
      .set("totalAmount", totalAmount)
      .set("amountPerUser", amountPerUser)
      .set("items", items)
      .set("description", description)
      .set("bonusType", bonusType)
      .set("createdBy", db.getObj("User", session.user.id))
      .save()
      .then(function (obj) {
        const bonusId = obj.id;
        const promises = [];
        users.forEach((user) => {
          items.forEach((item) => {
            const { issueDate, amount } = item;

            promises[promises.length] = db.getObj("BonusItem").set("relatedIds", relatedIds).set("bonus", db.getObj("Bonus", bonusId)).set("user", db.getObj("User", user.id)).set("createdBy", db.getObj("User", session.user.id)).set("issueDate", bonusIssueDate).set("vestingDate", issueDate).set("amount", amount).set("description", description).set("bonusType", bonusType).set("withdrawn", false).save();
          });
        });
        return Promise.all(promises);
      });
  },
  trashWorkRequest: function (workRequestId, { serviceOrderId }) {
    return db
      .getObj("WorkRequest", workRequestId)
      .set("removed", true)
      .save()
      .then(function () {
        return db.getObj("ServiceOrder", serviceOrderId)
          .remove("workRequests", db.getObj("WorkRequest", workRequestId))
          .save();
      });
  },
  setWorkRequestAssigned: function (workRequestId, { orderId, serviceOrderId, userId }) {
    return db
      .getObj("WorkRequest", workRequestId)
      .set("isAssigned", true)
      .set("assignedAt", new Date())
      .save()
      .then(function () {
        return dL.setServiceOrderWorker({ orderId, serviceOrderId, userId });
      });
  },
  withdrawServiceProposal: function (proposalId) {
    return db
      .getQuery("ServiceProposal")
      .get(proposalId)
      .then(function (obj) {
        const proposal = dL.loadServiceProposal(obj);

        return db
          .getObj("ServiceProposal", proposalId)
          .set("status", "withdrawn")
          .set("withdrawnAt", new Date())
          .save()
          .then(function () {
            const { serviceRequest, createdBy } = proposal;

            if (serviceRequest) {
              return db.getObj("ServiceRequest", serviceRequest.id).increment("proposalCount", -1).add("proposalsWithdrawn", { userId: createdBy.id, proposalId }).save();
            }
          });
      });
  },
  saveProposalItem: function ({ isNew, proposalId, item }) {
    const { createdBy, parentProposalItem, inCart, startDayIndex, doneDayIndex, packageId, priceMap, actualServiceVersion, actualVersionType, originalPrice, overridePrice, assignedTo, service, serviceVersion, servicePackage, userRole, businessRole, userService, proposalVersion, proposal, total, quantity, unitPrice, deliveryDays, userServiceVersion } = item;

    const { icon, name, description, limitedToOptions, limitedToPackages, projectManager, strategyConsultant } = item;

    const { recommended, deliveryType, delayOptions, serviceOptionIds } = item;
    const { itemType, requiredPreServices } = item;

    const { recommendedUser, monthlyCost, workerSelectionType, workerUsers, hoursPerMonth, staffServiceType, startDate, hasStartDate, numberOfMonths, priorityLevel, skillLevel, allowPullForward, allowRollover } = item;

    const obj = dL.getObj("ServiceProposalItem", item.id);
    if (isNew) {
      obj.set("createdBy", dL.getObj("User", session.user.id)).set("createdAt", new Date());
    }

    if (serviceOptionIds) {
      var arr = [];
      for (var key in serviceOptionIds) {
        arr.push(dL.getObj("ServiceOption", key));
      }
      obj.set("serviceOptions", arr);
    }

    return obj
      .set("inCart", inCart)
      .set("startDayIndex", startDayIndex)
      .set("doneDayIndex", doneDayIndex)
      .set("packageId", packageId)
      .set("priceMap", priceMap)
      .set("actualVersionType", actualVersionType)
      .set("actualServiceVersion", actualServiceVersion ? dL.getObj("ServiceVersion", actualServiceVersion.id) : null)

      .set("parentProposalItem", parentProposalItem ? dL.getObj("ServiceProposalItem", parentProposalItem.id) : null)
      .set("service", service ? dL.getObj("Service", service.id) : null)
      .set("serviceVersion", serviceVersion ? dL.getObj("ServiceVersion", serviceVersion.id) : null)
      .set("userServiceVersion", userServiceVersion ? dL.getObj("ServiceVersion", userServiceVersion.id) : null)
      .set("servicePackage", servicePackage ? dL.getObj("ServicePackage", servicePackage.package.id) : null)
      .set("servicePackageVersion", servicePackage ? dL.getObj("ServicePackageVersion", servicePackage.id) : null)
      .set("userService", userService ? dL.getObj("UserService", userService.id) : null)
      .set("businessRole", businessRole ? dL.getObj("BusinessRole", businessRole.id) : null)
      .set("userRole", userRole ? dL.getObj("UserRole", userRole.id) : null)
      .set("assignedTo", assignedTo ? dL.getObj("User", assignedTo.id) : null)
      .set("proposal", proposalId ? dL.getObj("ServiceProposal", proposalId) : proposal ? dL.getObj("ServiceProposal", proposal.id) : null)
      .set("proposalVersion", proposalVersion ? dL.getObj("ServiceProposalVersion", proposalVersion.id) : null)
      .set("projectManager", projectManager ? dL.getObj("User", projectManager.id) : null)
      .set("strategyConsultant", strategyConsultant ? dL.getObj("User", strategyConsultant.id) : null)

      .set("workerUsers", workerUsers ? workerUsers.map((item) => dL.getObj("User", item.id)) : null)
      .set("deliveryType", deliveryType)
      .set("delayOptions", delayOptions)
      .set("serviceOptionIds", serviceOptionIds)

      .forceSet("originalPrice", originalPrice)
      .forceSet("overridePrice", overridePrice)

      .set("recommendedUser", recommendedUser ? dL.getObj("User", recommendedUser.id) : null)
      .set("monthlyCost", monthlyCost)
      .set("workerSelectionType", workerSelectionType)
      .set("hoursPerMonth", hoursPerMonth)
      .set("staffServiceType", staffServiceType)
      .set("startDate", startDate)
      .set("hasStartDate", hasStartDate)
      .set("numberOfMonths", numberOfMonths)
      .set("priorityLevel", priorityLevel)
      .set("skillLevel", skillLevel)
      .set("allowPullForward", allowPullForward)
      .set("allowRollover", allowRollover)

      .set("recommended", recommended)
      .set("requiredPreServices", requiredPreServices)
      .set("itemType", itemType)
      .set("name", name)
      .set("description", description)
      .set("icon", icon)
      .set("limitedToOptions", limitedToOptions)
      .set("limitedToPackages", limitedToPackages)
      .set("quantity", quantity)
      .set("unitPrice", unitPrice)
      .set("deliveryDays", deliveryDays)
      .set("total", total)
      .save();
  },
  saveCartItem: function (cartId, item) {
    const { monthlyTotal, contractTotal, startDayIndex, doneDayIndex, packageId, priceMap, actualServiceVersion, actualVersionType, assignedTo, service, servicePackage, serviceVersion, userRole, businessRole, userService, proposalVersion, proposal, total, quantity, unitPrice, deliveryDays } = item;

    const { itemType, proposalItem, assignedToUser, delayOptions, deliveryType, serviceOptionIds } = item;

    const { monthlyCost, workerSelectionType, workerUsers, hoursPerMonth, staffServiceType, startDate, hasStartDate, numberOfMonths, priorityLevel, skillLevel, allowPullForward, allowRollover } = item;

    const itemObj = dL
      .getObj("CartItem", cartId)
      .set("user", dL.getObj("User", session.user.id))
      .set("company", dL.getObj("Company", session.company.id))

      .set("monthlyTotal", monthlyTotal)
      .set("contractTotal", contractTotal)

      .set("startDayIndex", startDayIndex)
      .set("doneDayIndex", doneDayIndex)
      .set("packageId", packageId)
      .set("priceMap", priceMap)

      .set("proposal", proposal ? dL.getObj("ServiceProposal", proposal.id) : null)
      .set("proposalItem", proposalItem ? dL.getObj("ServiceProposalItem", proposalItem.id) : null)
      .set("proposalVersion", proposalVersion ? dL.getObj("ServiceProposalVersion", proposalVersion.id) : null)

      .set("actualVersionType", actualVersionType)
      .set("actualServiceVersion", actualServiceVersion ? dL.getObj("ServiceVersion", actualServiceVersion.id) : null)

      .set("service", service ? dL.getObj("Service", service.id) : null)
      .set("serviceVersion", serviceVersion ? dL.getObj("ServiceVersion", serviceVersion.id) : null)
      .set("servicePackage", servicePackage ? dL.getObj("ServicePackage", servicePackage.package.id) : null)
      .set("servicePackageVersion", servicePackage ? dL.getObj("ServicePackageVersion", servicePackage.id) : null)
      .set("userService", userService ? dL.getObj("UserService", userService.id) : null)
      .set("businessRole", businessRole ? dL.getObj("BusinessRole", businessRole.id) : null)
      .set("userRole", userRole ? dL.getObj("UserRole", userRole.id) : null)
      .set("assignedTo", assignedTo ? dL.getObj("User", assignedTo.id) : null)

      .set("workerUsers", workerUsers ? workerUsers.map((item) => dL.getObj("User", item.id)) : null)
      .set("deliveryType", deliveryType)
      .set("assignedToUser", assignedToUser)
      .set("delayOptions", delayOptions);

    if (serviceOptionIds) {
      var arr = [];
      for (var key in serviceOptionIds) {
        arr.push(dL.getObj("ServiceOption", key));
      }
      itemObj.set("serviceOptions", arr);
    }

    itemObj
      .set("serviceOptionIds", serviceOptionIds)

      .set("monthlyCost", monthlyCost)
      .set("workerSelectionType", workerSelectionType)
      .set("hoursPerMonth", hoursPerMonth)
      .set("staffServiceType", staffServiceType)
      .set("startDate", startDate)
      .set("hasStartDate", hasStartDate)
      .set("numberOfMonths", numberOfMonths)
      .set("priorityLevel", priorityLevel)
      .set("skillLevel", skillLevel)
      .set("allowPullForward", allowPullForward)
      .set("allowRollover", allowRollover)

      .set("itemType", itemType)
      .set("quantity", quantity)
      .set("unitPrice", unitPrice)
      .set("deliveryDays", deliveryDays)
      .set("total", total);

    return itemObj.save();
  },
  sendServiceProposal: function (proposalId) {
    const ed = Moment().add(1, "month").toDate();

    const currentUserId = session.user.id;
    return db
      .getQuery("ServiceProposal")
      .include("currentProposalVersion")
      .get(proposalId)
      .then(function (obj) {
        const proposal = dL.loadServiceProposal(obj);

        const { currentProposalVersion, total, deliveryDays } = proposal;
        const { items, overrides, options, packages, priceMap, masterOverrides } = currentProposalVersion;
        const newProposalVersionId = utils.guid();

        return dL.getProposalVersionNum(proposalId).then(function (versionNum) {
          return db
            .getObj("ServiceProposalVersion", newProposalVersionId)
            .set("versionNum", versionNum)
            .set("status", "draft")
            .set("createdBy", db.getObj("User", currentUserId))
            .set("proposal", db.getObj("ServiceProposal", proposalId))
            .set("items", JSON.stringify(items))
            .set("options", JSON.stringify(options))
            .set("packages", JSON.stringify(packages))
            .set("overrides", JSON.stringify(overrides))
            .set("createdAt", new Date())
            .set("priceMap", priceMap)
            .set("masterOverrides", masterOverrides ? masterOverrides.map(item => db.getObj("MasterOverrideRecord", item.id)) : null)
            .save()
            .then(function () {
              return db
                .getObj("ServiceProposalVersion", currentProposalVersion.id)
                .set("status", "open")
                .set("sentBy", db.getObj("User", currentUserId))
                .set("sentAt", new Date())
                .set("expireDate", ed)
                .set("deliveryDays", deliveryDays)
                .set("total", total)
                .save()
                .then(function () {
                  return db
                    .getObj("ServiceProposal", proposalId)
                    .set("lastSentProposalVersion", db.getObj("ServiceProposalVersion", currentProposalVersion.id))
                    .set("currentProposalVersion", db.getObj("ServiceProposalVersion", newProposalVersionId))
                    .set("isNew", true)
                    .set("status", "open")
                    .set("proposalDate", new Date())
                    .set("sentAt", new Date())
                    .set("expireDate", ed)
                    .save()
                    .then(function () {
                      const { serviceRequest, createdBy, total } = proposal;

                      if (serviceRequest) {
                        return db.getObj("ServiceRequest", serviceRequest.id)
                          .increment("proposalCount", 1)
                          .add("proposals", { userId: createdBy.id, proposalId, total })
                          .set("lastProposalAt", new Date())
                          .save();
                      }
                    });
                });
            });
        });
      });
  },
  doUpdateService: function ({ updateObj: defaultUpdateObj, service, userService, serviceVersion }) {
    const updateObj = defaultUpdateObj ? defaultUpdateObj : serviceVersion ? dL.getObj("ServiceVersion", serviceVersion.id) : userService ? dL.getObj("UserService", userService.id) : service ? dL.getObj("Service", service.id) : null

    const { enablePackages, packages, options } = getVersionData({ service, userService, serviceVersion })
    var fDeliveryDays = 999, fMinPrice = 999999, fMaxPrice = 0

    const promises = []

    promises[promises.length] = Promise.all(
      options.map((serviceOption) => {
        const { option } = serviceOption;
        const { id } = option
        const serviceOptionIds = {}
        serviceOptionIds[id] = true

        const { total: actualPrice, workHours, days: actualDeliveryDays } = getFinalPriceDays2({ serviceVersion, serviceOptionIds })

        const { minDeliveryDays, minFastDeliveryDays } = getMinDeliveryDays({ workerHours: workHours });

        return dL.getObj("ServiceOptionVersion", id)
          .set("minDeliveryDays", minDeliveryDays)
          .set("minFastDeliveryDays", minFastDeliveryDays)
          .set("actualPrice", actualPrice)
          .set("actualDeliveryDays", actualDeliveryDays)
          .set("workHours", workHours).save();
      })
    );

    if (enablePackages) {
      packages.forEach(servicePackage => {
        const { package: sPackage } = servicePackage
        const { id } = sPackage

        const optOpts = getOptionalServiceOptionsForBundledPackage({ serviceOptions: options, packageId: id });

        var pDeliveryDays = 999, pMinPrice = 999999, pMaxPrice = 0
        const combos = utils.combineArray(optOpts, 0)
        combos.forEach(combo => {
          if (combo.length > 0) {
            const dt = {}
            combo.forEach(item => {
              dt[item.option.id] = true
            })

            const { total: price, workHours } = getFinalPriceDays2({ serviceVersion, packageId: id, serviceOptionIds: dt })

            const { minDeliveryDays: deliveryDays, minFastDeliveryDays } = getMinDeliveryDays({ workerHours: workHours });

            if (price < pMinPrice) {
              pMinPrice = price
            }
            if (price > pMaxPrice) {
              pMaxPrice = price
            }
            if (deliveryDays < pDeliveryDays) {
              pDeliveryDays = deliveryDays
            }
          }
        })

        if (pDeliveryDays < fDeliveryDays) {
          fDeliveryDays = pDeliveryDays
        }
        if (pMinPrice < fMinPrice) {
          fMinPrice = pMinPrice
        }
        if (pMaxPrice > fMaxPrice) {
          fMaxPrice = pMaxPrice
        }

        const { total: actualPrice, workHours, days: actualDeliveryDays } = getFinalPriceDays2({ serviceVersion, packageId: id })

        return dL.getObj("ServicePackageVersion", id)
          .set("comboOptions", combos.length)
          .set("minDeliveryDays", pDeliveryDays)
          .set("actualPrice", actualPrice)
          .set("actualDeliveryDays", actualDeliveryDays)
          .set("workHours", workHours)
          .set("minPrice", pMinPrice)
          .set("maxPrice", pMaxPrice)
          .save();
      })

      promises[promises.length] = updateObj
        .set("minDeliveryDays", fDeliveryDays)
        .set("minPrice", fMinPrice)
        .set("maxPrice", fMaxPrice)
        .save();

    } else {
      var fMinPrice = 999999, fMaxPrice = 0
      const combos = utils.combineArray(options, 0)
      combos.forEach(combo => {
        if (combo.length > 0) {
          const dt = {}
          combo.forEach(item => {
            dt[item.option.id] = true
          })
          const { price } = getServicePrice2({ serviceVersion, serviceOptionIds: dt })
          if (price < fMinPrice) {
            fMinPrice = price
          }
          if (price > fMaxPrice) {
            fMaxPrice = price
          }
        }
      })

      const { total: actualPrice, workHours, days: deliveryDays } = getFinalPriceDays2({ serviceVersion })

      const { minDeliveryDays, minFastDeliveryDays } = getMinDeliveryDays({ workerHours: workHours });

      if (actualPrice < fMinPrice) {
        fMinPrice = actualPrice
      }
      if (actualPrice > fMaxPrice) {
        fMaxPrice = actualPrice
      }

      promises[promises.length] = updateObj
        .set("comboOptions", combos.length)
        .set("minDeliveryDays", minDeliveryDays)
        .set("actualPrice", actualPrice)
        .set("actualDeliveryDays", deliveryDays)
        .set("workHours", workHours)
        .set("minPrice", fMinPrice)
        .set("maxPrice", fMaxPrice)
        .save();
    }

    return Promise.all(promises);
  },
  publishUserService: function (userServiceId) {
    var userService
    var newVersionId = utils.guid()
    return dL.getUserService2({ userServiceId }).then(function (_userService) {
      userService = _userService
      return dL.userServiceSetStatus({ serviceId: userService.service.id, userServiceId, status: "active" })
    }).then(function () {
      const { user, service, id } = userService
      return db.getObj("User", user.id)
        .add("services", db.getObj("Service", service.id))
        .add("userServices", db.getObj("UserService", id))
        .save();
    }).then(function () {
      const { draftVersion, service, team, userRole } = userService;

      return dL.createNewVersion({ userRole, team, newVersionId, serviceVersionId: draftVersion.id, serviceId: service.id, userServiceId })
    }).then(function () {
      return db.getQuery("Service")
        .equalTo("ownerUserService", db.getObj("UserService", userServiceId))
        .first().then(function (obj) {
          if (obj) {
            return dL.createNewVersion({ newVersionId: utils.guid(), serviceVersionId: newVersionId, serviceId: obj.id, removeUserService: true })
          }
        })
    })
  },
  publishService: function (serviceId) {
    var service
    return dL.getService2({ serviceId }).then(function (_service) {
      service = _service
      const { status, id } = service;
      if (status == "draft") {
        return dL
          .getObj("Service", id)
          .set("status", "active")
          .save()
      }
    }).then(function () {
      const { draftVersion, team } = service;
      return dL.createNewVersion({ team, serviceId, newVersionId: utils.guid(), serviceVersionId: draftVersion.id, serviceId })
    });
  },
  getOrderHistory: function ({ orderId, serviceOrderId }) {
    var query = db.getQuery("OrderHistory").equalTo("order", db.getObj("Order", orderId));

    if (serviceOrderId) {
      query.equalTo("serviceOrder", db.getObj("ServiceOrder", serviceOrderId));
    }

    return query
      .include("order")
      .include("serviceOrder")
      .include("createdBy")
      .find()
      .then(function (objs) {
        return dL.loadObjects("OrderHistory", objs);
      });
  },
  acceptWorkRequest: function (id, { orderId, serviceOrderId }) {
    return db
      .getObj("WorkRequest", id)
      .set("status", "accept")
      .set("responseAt", new Date())
      .save()
      .then(function () {
        return dL.addOrderHistory({ orderId, serviceOrderId, type: "work-request-accept", description: "Work request accepted." });
      });
  },
  declineWorkRequest: function (id, { orderId, serviceOrderId }) {
    return db
      .getObj("WorkRequest", id)
      .set("status", "decline")
      .set("responseAt", new Date())
      .save()
      .then(function () {
        return dL.addOrderHistory({ orderId, serviceOrderId, type: "work-request-decline", description: "Work request declined." });
      });
  },
  addOrderHistory: function ({ orderId, serviceOrderId, type, description }) {
    return db
      .getObj("OrderHistory")
      .set("createdBy", db.getObj("User", session.user.id))
      .set("order", db.getObj("Order", orderId))
      .set("serviceOrder", serviceOrderId ? db.getObj("ServiceOrder", serviceOrderId) : null)
      .set("type", type)
      .set("description", description)
      .save();
  },
  startWorkServiceOrder: function ({ serviceOrderId, orderId }) {
    return db
      .getObj("ServiceOrder", serviceOrderId)
      .set("status", "working")
      .set("workStartAt", new Date())
      .save()
      .then(function () {
        return db.getObj("Order", orderId).set("status", "working").save();
      })
      .then(function () {
        return dL.addOrderHistory({ orderId, serviceOrderId, type: "so-start-work", description: "Work started on service order." });
      });
  },
  createWorkRequest2: function ({ serviceOrder, userId, comments, tier: defaultTier }) {
    const { accessUsers, order, service, servicePackage, id: serviceOrderId } = serviceOrder;
    const { serviceType } = service;
    const tier = defaultTier ? defaultTier : 0

    var visibleAt = new Date()
    var prefExpiresAt
    if (tier) {
      const prefHours = 4
      visibleAt = Moment().add(prefHours * (tier - 1), "hours").toDate()
      prefExpiresAt = Moment(visibleAt).add(prefHours, "hours").toDate()
    }

    var arr = [{ role: "user", userId: userId }];
    if (accessUsers) {
      arr = arr.concat(accessUsers);
    }
    return db
      .getObj("WorkRequest")
      .set("tier", tier)
      .set("visibleAt", visibleAt)
      .set("prefExpiresAt", prefExpiresAt)
      .set("serviceType", serviceType)
      .set("comments", comments)
      .set("user", db.getObj("User", userId))
      .set("accessUsers", arr)
      .set("createdBy", db.getObj("User", session.user.id))
      .set("status", "pending")
      .set("serviceOrder", db.getObj("ServiceOrder", serviceOrderId))
      .set("order", db.getObj("Order", order.id))
      .set("service", db.getObj("Service", service.id))
      .set("servicePackage", servicePackage ? db.getObj("ServicePackage", servicePackage.id) : null)
      .save()
      .then(function (sObj) {
        return db.getObj("ServiceOrder", serviceOrderId)
          .add("workRequests", db.getObj("WorkRequest", sObj.id))
          .save();
      })
      .then(function () {
        return dL.addOrderHistory({ orderId: order.id, serviceOrderId, type: "so-work-request-create", description: "Work request created." });
      });
  },
  createWorkRequest: function ({ serviceOrderId, userId, comments, tier }) {
    return dL.getServiceOrder(serviceOrderId).then(function (serviceOrder) {
      return dL.createWorkRequest2({ serviceOrder, userId, comments, tier })
    });
  },
  withdrawDelivery: function (serviceDeliveryId) {
    return db
      .getQuery("ServiceDelivery")
      .get(serviceDeliveryId)
      .then(function (obj) {
        const serviceDelivery = dL.loadServiceDelivery(obj);
        return db
          .getObj("ServiceDelivery", serviceDeliveryId)
          .set("status", "withdrawn")
          .save()
          .then(function () {
            return dL.addOrderHistory({ orderId: serviceDelivery.order ? serviceDelivery.order.id : null, serviceOrderId: serviceDelivery.serviceOrder.id, type: "so-delivery-withdrawn", description: "Service delivery withdrawn." });
          });
      });
  },
  requestModifyDelivery: function (serviceDeliveryId, { orderId, serviceOrderId, comments, files, userId }) {
    return db
      .getObj("ServiceDelivery", serviceDeliveryId)
      .set("responseAt", new Date())
      .set("responseComments", comments)
      .set("responseFiles", files)
      .set("status", "decline")
      .set("responseBy", db.getObj("User", userId))
      .save()
      .then(function () {
        return dL.getServiceOrderFull(serviceOrderId).then(function ({ serviceOrder, deliveries }) {
          const list = deliveries.filter((item) => item.status == "pending");
          if (list.length == 0) {
            return db.getObj("ServiceOrder", serviceOrderId).set("status", "working").set("dueDate", Moment().businessAdd(2).toDate()).save();
          }
        });
      })
      .then(function () {
        return dL.addOrderHistory({ orderId, serviceOrderId, type: "so-delivery-decline", description: "Service delivery modifications requested." });
      })
      .then(function () {
        return fs.sendChatMessageAPI({
          roomId: serviceOrderId,
          roomUserId: serviceOrderId + session.user.id,
          message: "Request Modification on Delivery.",
        });
      })
      .then(function () {
        return dL.getQuery("ServiceDelivery")
          .include("serviceDeliverable")
          .get(serviceDeliveryId)
          .then(function (obj) {
            const serviceDelivery = dL.loadServiceDelivery(obj)

            const { serviceDeliverable, order, serviceOrder, project } = serviceDelivery
            const { name } = serviceDeliverable

            return dL.createTaskRecord({ title: "Modifications Requested: " + name, hours: 1, dueDate: Moment().businessAdd(2).toDate(), order, serviceOrder, project })
          })
      })
  },
  getServiceOrder: function (serviceOrderId) {
    return db
      .getQuery("ServiceOrder")
      .get(serviceOrderId)
      .then(function (obj) {
        return dL.loadServiceOrder(obj);
      });
  },
  calcEarningsServiceOrder: function ({ serviceOrderId }) {
    return dL.getServiceOrder(serviceOrderId).then(function (serviceOrder) {
      const { total, workerBonus, workerEarning, order, assignedTo, chargeAccount, orderNumber } = serviceOrder;
      const releaseDate = Moment().add(7, "days").toDate();

      const promises = [];
      promises[promises.length] = dL
        .issueWorkerEarning({
          orderId: order.id,
          serviceOrderId: serviceOrder.id,
          accountId: chargeAccount ? chargeAccount.id : null,
          releaseDate,
          userId: assignedTo.id,
          amount: workerEarning,
        })
        .then(function () {
          if (workerBonus && workerBonus > 0) {
            const issue1Amount = utils.roundMoney(workerBonus * 0.25);
            const issue2Amount = utils.roundMoney(workerBonus * 0.55);
            const issue3Amount = workerBonus - issue1Amount - issue2Amount;
            const issue1 = {
              amount: issue1Amount,
              issueDate: Moment().add(1, "month").toDate(),
            };
            const issue2 = {
              amount: issue2Amount,
              issueDate: Moment().add(6, "months").toDate(),
            };
            const issue3 = {
              amount: issue3Amount,
              issueDate: Moment().add(12, "months").toDate(),
            };
            return dL.issueBonusWorker({
              relatedIds: [order.id, serviceOrder.id, assignedTo.id],
              description: "Bonus for Service Order #" + orderNumber,
              userId: assignedTo.id,
              vesting: [issue1, issue2, issue3],
            });
          }

          //issue bs/sc/pm earnings
          const { businessDevelopment, strategyConsultant, projectManager } = order;
          if (businessDevelopment) {
            const amount = utils.roundMoney(total * 0.1);

            promises[promises.length] = dL.issueWorkerEarning({
              orderId: order.id,
              serviceOrderId: serviceOrder.id,
              accountId: chargeAccount ? chargeAccount.id : null,
              releaseDate,
              userId: businessDevelopment.id,
              amount,
            });
          }
          if (strategyConsultant) {
            const amount = utils.roundMoney(total * 0.7);
            promises[promises.length] = dL.issueWorkerEarning({
              orderId: order.id,
              serviceOrderId: serviceOrder.id,
              accountId: chargeAccount ? chargeAccount.id : null,
              releaseDate,
              userId: strategyConsultant.id,
              amount,
            });
          }
          if (projectManager) {
            const amount = utils.roundMoney(total * 0.5);
            promises[promises.length] = dL.issueWorkerEarning({
              orderId: order.id,
              serviceOrderId: serviceOrder.id,
              accountId: chargeAccount ? chargeAccount.id : null,
              releaseDate,
              userId: projectManager.id,
              amount,
            });
          }

          return Promise.all(promises);
        });
    });
  },
  serviceOrderComplete: function ({ orderId, serviceOrderId }) {
    return db
      .getObj("ServiceOrder", serviceOrderId)
      .set("status", "completed")
      .set("completedAt", new Date())
      .save()
      .then(function () {
        return dL.calcEarningsServiceOrder({ serviceOrderId })
      })
      .then(function () {
        return dL.addOrderHistory({ orderId, serviceOrderId, type: "so-completed", description: "Service order completed." });
      })
      .then(function () {
        if (orderId) {
          return db
            .getQuery("ServiceOrder")
            .equalTo("order", db.getObj("Order", orderId))
            .notEqualTo("status", "completed")
            .containedIn("removed", [undefined, false])
            .count()
            .then(function (count) {
              if (count == 0) {
                return dL.orderCompleted({ orderId });
              }
            });
        }
      })
      .then(function () {
        return dL.executePreReqItems(serviceOrderId);
      });
  },
  orderCompleted: function ({ orderId }) {
    return db
      .getObj("Order", orderId)
      .set("status", "completed")
      .set("completedAt", new Date())
      .save()
      .then(function () {
        //order completed
        return db
          .getQuery("ServiceOrder")
          .equalTo("order", db.getObj("Order", orderId))
          .containedIn("removed", [undefined, false])
          .equalTo("status", "completed")
          .find()
          .then(function (objs) {
            const serviceOrders = dL.loadObjects("ServiceOrder", objs);
            const users = {};
            var allTotal = 0;
            var allTotalProfit = 0;
            serviceOrders.forEach((item) => {
              allTotal += item.total;
              allTotalProfit += item.netProfit;

              const userId = item.assignedTo.id;
              if (!users[userId]) {
                users[userId] = {
                  total: 0,
                  count: 0,
                  vestFactor: 1,
                };
              }
              users[userId].total += item.total;
              users[userId].count++;
            });

            const totalBonusAmount = utils.roundMoney(allTotalProfit * 0.1);
            for (var key in users) {
              const user = users[key];
              user.bonus = utils.roundMoney((user.total / allTotal) * totalBonusAmount);
            }

            //need to issue bonus for each of these users (with the same vesting schedule )
            const issueB2 = function ({ workerBonus, userId }) {
              const issue1Amount = utils.roundMoney(workerBonus * 0.25);
              const issue2Amount = utils.roundMoney(workerBonus * 0.55);
              const issue3Amount = workerBonus - issue1Amount - issue2Amount;
              const issue1 = {
                amount: issue1Amount,
                issueDate: Moment().add(1, "month").toDate(),
              };
              const issue2 = {
                amount: issue2Amount,
                issueDate: Moment().add(6, "months").toDate(),
              };
              const issue3 = {
                amount: issue3Amount,
                issueDate: Moment().add(12, "months").toDate(),
              };
              return dL.issueBonusWorker({
                relatedIds: [orderId, userId],
                description: "Bonus for Order #" + orderId,
                userId: userId,
                vesting: [issue1, issue2, issue3],
              });
            };

            const promises2 = [];
            for (var key in users) {
              const user = users[key];
              promises2[promises2.length] = issueB2({ userId: key, workerBonus: user.bonus });
            }
            return Promise.all(promises2);
          });
      })
      .then(function () {
        return dL.addOrderHistory({ orderId, type: "o-completed", description: "Order completed." });
      });
  },
  approveDelivery: function (serviceDeliveryId, { orderId, serviceOrderId, userId }) {
    return db
      .getObj("ServiceDelivery", serviceDeliveryId)
      .set("status", "accept")
      .set("approvedAt", new Date())
      .set("responseBy", db.getObj("User", userId))
      .save()
      .then(function (obj) {
        return dL.getServiceOrderFull(serviceOrderId).then(function ({ serviceOrder, deliveries }) {
          const { serviceDeliverables } = serviceOrder;

          var completed = true;
          for (var i = 0; i < serviceDeliverables.length; i++) {
            const { deliverable } = serviceDeliverables[i];
            const { id } = deliverable
            const deliveriesAccepted = deliveries.filter((item) => item.serviceDeliverable.id == id && item.status == "accept");

            if (deliveriesAccepted.length == 0) {
              completed = false;
              break;
            }
          }
          if (completed) {
            return dL.serviceOrderComplete({ orderId, serviceOrderId });
          }
        });
      })
      .then(function () {
        return dL.addOrderHistory({ orderId, serviceOrderId, type: "so-delivery-approved", description: "Service delivery approved." });
      })
      .then(function () {
        return fs.sendChatMessageAPI({
          roomId: serviceOrderId,
          roomUserId: serviceOrderId + session.user.id,
          message: "Delivery Approved.",
        });
      });
  },
  executePreReqItems: function (serviceOrderId) {
    //check to see of the parentServiceOrder has any other sub-services that should be started
    //check to see if any other tasks should be started when this service order is completed
    //get all service orders with this serviceOrderId is in the requiredPreServices
    //then check that serviceOrder to see if the other requiredPreServices (either tasks or serviceOrders are completed)

    var serviceOrder
    var proposalItemsOpen = {}
    return dL.getServiceOrder(serviceOrderId).then(function (_serviceOrder) {
      serviceOrder = _serviceOrder
      const { proposal } = serviceOrder;

      //get all serviceOrders for this proposal it exists
      if (proposal) {
        //get only the open serviceOrders (this can be used in the details below)
        return dL
          .getQuery("ServiceOrder")
          .equalTo("proposal", db.getObj("ServiceProposal", proposal.id))
          .containedIn("removed", [undefined, false])
          .notContainedIn("status", ["completed", "canceled"])
          .select(["proposalItem"])
          .find()
          .then(function (objs) {
            const items = dL.loadObjects("ServiceOrder", objs);
            items.forEach(serviceOrder => {
              proposalItemsOpen[serviceOrder.proposalItem.id] = true
            })
          });
      }
    }).then(function () {
      const { parentServiceOrder } = serviceOrder;
      if (parentServiceOrder) {
        const promises = [];

        var serviceOrders;
        var tasks;

        promises[promises.length] = dL
          .getQuery("ServiceOrder")
          .equalTo("parentServiceOrder", db.getObj("ServiceOrder", parentServiceOrder.id))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            serviceOrders = dL.loadObjects("ServiceOrder", objs);
          });

        promises[promises.length] = dL
          .getQuery("TaskRecord")
          .equalTo("serviceOrder", db.getObj("ServiceOrder", parentServiceOrder.id))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            tasks = dL.loadObjects("TaskRecord", objs);
          });

        //check requiredTasks, requiredServiceOrders, requiredProposalItems here
        return Promise.all(promises).then(function () {
          const preReqItems = [...serviceOrders, ...tasks];

          const arr1 = tasks.filter((task) => {
            const { canStart, requiredPreServices, requiredProposalItems } = task;
            if (!canStart && requiredPreServices && requiredPreServices.length > 0 && (!requiredProposalItems || requiredProposalItems.filter(item => proposalItemsOpen[item.id]).length == 0)) {
              return (
                requiredPreServices.filter((pId) => {
                  const fItem = preReqItems.find((item) => item.id == pId);

                  if (fItem.status == "completed" || fItem.status == "canceled") {
                    return false;
                  }
                }).length == 0
              );
            }
          });

          const arr2 = serviceOrders.filter((serviceOrder) => {
            const { canStart, requiredPreServices, requiredProposalItems } = serviceOrder;

            if (!canStart && requiredPreServices && requiredPreServices.length > 0 && (!requiredProposalItems || requiredProposalItems.filter(item => proposalItemsOpen[item.id]).length == 0)) {
              return (
                requiredPreServices.filter((pId) => {
                  const fItem = preReqItems.find((item) => item.id == pId);

                  if (fItem.status == "completed" || fItem.status == "canceled") {
                    return false;
                  }
                }).length == 0
              );
            }
          });

          const p1 = Promise.all(arr1.map((task) => dL.getObj("TaskRecord", task.id).set("canStart", true).set("canStartAt", new Date()).save()));

          const p2 = Promise.all(arr2.map((serviceOrder) => dL.getObj("ServiceOrder", serviceOrder.id).set("canStart", true).set("canStartAt", new Date()).save()));

          return Promise.all([p1, p2])
        });
      }
    });
  },
  saveUserRole: function (userRoleId, { userId, businessRoleId, businessSector, hourlyRate, yearsOfExp, skillLevel }) {
    const obj = db.getObj("UserRole", userRoleId).set("businessRole", dL.getObj("BusinessRole", businessRoleId)).set("businessSector", businessSector).set("hourlyRate", hourlyRate).set("skillLevel", skillLevel).set("yearsOfExp", yearsOfExp);

    if (!userRoleId) {
      obj.set("user", userId ? db.getObj("User", userId) : null);
    }

    return obj.save().then(function (obj) {
      if (!userRoleId) {
        return db.getObj("User", userId).add("userRoles", db.getObj("UserRole", obj.id)).add("businessRoles", db.getObj("BusinessRole", businessRoleId)).save();
      }
    }).then(function () {
      return obj.id
    })
  },
  saveUserServiceOverrides: function (userServiceId, overrides) {
    return db.getObj("UserService", userServiceId).set("overrides", overrides).save();
  },
  deleteServiceProposal: function (proposalId) {
    return db.getObj("ServiceProposal", proposalId).set("removed", true).save();
  },
  createNewVersion: function ({ userRole, team, newVersionId, serviceVersionId, serviceId, userServiceId }) {
    const onGetNewVersion = function () {
      if (userServiceId) {
        return dL.getUserServiceVersionNum(userServiceId)
      } else {
        return dL.getServiceVersionNum(serviceId)
      }
    }

    const currentUserId = session.user.id;

    return dL.getServiceVersion2({ serviceVersionId }).then(function (serviceVersion) {
      const { minPrice, maxPrice, actualDeliveryDays, minDeliveryDays, actualPrice, workHours } = serviceVersion

      const { businessInitiatives, priceMap, masterOverrides, primaryCategory, secondaryCategory, tags, packages, inputs, subServices, deliverables, options, tasks, overrides, packagesSeq, inputsSeq, subServicesSeq, deliverablesSeq, optionsSeq, tasksSeq, forkedFromServiceVersion, forkedFromUserService } = serviceVersion;

      const { serviceType, companyStages, skillLevel, requestType, icon, name, shortDescription, description, enableServiceOptions, isFixedPriced, enablePackages, price, deliveryDays, hasExtraFastDelivery, extraFastPrice, extraFastDeliveryDays, allowedRevisions, serviceCore, businessRole, fastDeliveryPercent } = serviceVersion

      const copyObj = function (obj) {
        obj
          .set("name", name)
          .set("icon", icon)
          .set("shortDescription", shortDescription)
          .set("description", description)
          .set("serviceType", serviceType)
          .set("companyStages", companyStages)
          .set("serviceCore", serviceCore)
          .set("requestType", requestType)
          .set("enableServiceOptions", enableServiceOptions)
          .set("isFixedPriced", isFixedPriced)
          .set("enablePackages", enablePackages)
          .set("price", price)
          .set("deliveryDays", deliveryDays)
          .set("hasExtraFastDelivery", hasExtraFastDelivery)
          .set("extraFastPrice", extraFastPrice)
          .set("extraFastDeliveryDays", extraFastDeliveryDays)
          .set("fastDeliveryPercent", fastDeliveryPercent)
          .set("businessRole", businessRole ? dL.getObj("BusinessRole", businessRole.id) : null)
          .set("userRole", userRole ? dL.getObj("UserRole", userRole.id) : null)
          .set("skillLevel", skillLevel)
          .set("allowedRevisions", allowedRevisions)
          .set("priceMap", priceMap)
          .set("businessInitiatives", businessInitiatives ? businessInitiatives.map(item => db.getObj("BusinessInitiative", item.id)) : null)
          .set("masterOverrides", masterOverrides ? masterOverrides.map(item => db.getObj("MasterOverrideRecord", item.id)) : null)
          .set("primaryCategory", primaryCategory ? db.getObj("ServiceCategory", primaryCategory.id) : null)
          .set("secondaryCategory", secondaryCategory ? db.getObj("ServiceCategory", secondaryCategory.id) : null)
          .set("tags", tags ? tags.map(item => db.getObj("Tag", item.id)) : null)
          .set("searchText", [...(name + " " + shortDescription + " " + description).toLowerCase().split(" "), name.toLowerCase(), ...tags ? tags.map(item => item.name) : []])

          .set("minDeliveryDays", minDeliveryDays)
          .set("actualDeliveryDays", actualDeliveryDays)
          .set("actualPrice", actualPrice)
          .set("workHours", workHours)
          .set("minPrice", minPrice)
          .set("maxPrice", maxPrice)

        return obj
          .set("enableServiceOptions", enableServiceOptions)
          .set("enablePackages", enablePackages)
          .set(
            "masterOverrides",
            masterOverrides.map((item) => db.getObj("MasterOverrideRecord", item.id))
          )
          .set(
            "inputs",
            inputs.map((item) => db.getObj("ServiceInputVersion", item.id))
          )
          .set(
            "subServices",
            subServices.map((item) => db.getObj("ServiceSubServiceVersion", item.id))
          )
          .set(
            "deliverables",
            deliverables.map((item) => db.getObj("ServiceDeliverableVersion", item.id))
          )
          .set(
            "options",
            options.map((item) => db.getObj("ServiceOptionVersion", item.id))
          )
          .set(
            "tasks",
            tasks.map((item) => db.getObj("ServiceTaskVersion", item.id))
          )
          .set(
            "packages",
            packages.map((item) => db.getObj("ServicePackageVersion", item.id))
          )
          .set("packagesSeq", packagesSeq)
          .set("inputsSeq", inputsSeq)
          .set("subServicesSeq", subServicesSeq)
          .set("deliverablesSeq", deliverablesSeq)
          .set("optionsSeq", optionsSeq)
          .set("tasksSeq", tasksSeq);
      };

      return onGetNewVersion().then(function (versionNum) {
        const newVersionObj = db.getObj("ServiceVersion", newVersionId);
        return copyObj(newVersionObj)
          .set("versionNum", versionNum)
          .set("status", "draft")
          .set("userRole", userRole ? db.getObj("UserRole", userRole.id) : null)
          .set("team", team ? db.getObj("Team", team.id) : null)
          .set("forkedFromUserService", forkedFromUserService ? db.getObj("UserService", forkedFromUserService.id) : null)
          .set("forkedFromServiceVersion", forkedFromServiceVersion ? db.getObj("ServiceVersion", forkedFromServiceVersion.id) : null)
          .set("createdBy", db.getObj("User", currentUserId))
          .set("service", db.getObj("Service", serviceId))
          .set("userService", userServiceId ? db.getObj("UserService", userServiceId) : null)
          .set("overrides", JSON.stringify(overrides))
          .set("createdAt", new Date())
          .save()
          .then(function () {
            return db
              .getObj("ServiceVersion", serviceVersion.id)
              .set("status", "open")
              .save()
              .then(function () {
                const serviceObj = userServiceId ? db.getObj("UserService", userServiceId) : db.getObj("Service", serviceId);
                return copyObj(serviceObj)
                  .set("needsReview", true)
                  .set("lastVersionUpdatedAt", new Date())
                  .set("currentVersion", db.getObj("ServiceVersion", serviceVersion.id))
                  .set("draftVersion", db.getObj("ServiceVersion", newVersionId))
                  .set("lastServiceVersionUpdateAt", new Date())
                  .save()
                  .then(function () {
                    return dL.doUpdate1({ serviceObj, serviceId, userServiceId, serviceVersionId: newVersionId })
                  });
              });
          });
      }).then(function () {
        return newVersionId
      })
    });
  },
  doUpdate1: function ({ serviceObj, serviceId, userServiceId, serviceVersionId }) {
    var userService
    var service
    var serviceVersion
    return dL.getService2({ serviceId }).then(function (_service) {
      service = _service

      if (userServiceId) {
        return dL.getUserService2({ userServiceId }).then(function (_userService) {
          userService = _userService
        });
      }
    }).then(function () {
      if (serviceVersionId) {
        return dL.getServiceVersion2({ serviceVersionId }).then(function (_serviceVersion) {
          serviceVersion = _serviceVersion
        });
      }
    }).then(function () {
      return dL.doUpdateService({ updateObj: serviceObj, service, userService, serviceVersion });
    })
  },
  addUserService: function ({ team, forkUserServiceId, service, serviceVersion: defaultServiceVersion, data }) {
    var serviceVersion = defaultServiceVersion
    if (!serviceVersion) {
      const { currentVersion, draftVersion } = service
      serviceVersion = currentVersion ? currentVersion : draftVersion
    }
    const { userRole, businessRole } = data
    const userId = session.user.id
    const serviceVersionId = serviceVersion.id
    const serviceId = service.id
    const newServiceVersionId = utils.guid()
    var userServiceId
    return db
      .getObj("UserService")
      .set("service", db.getObj("Service", serviceId))
      .set("forkedFromUserService", forkUserServiceId ? db.getObj("UserService", forkUserServiceId) : null)
      .set("forkedFromServiceVersion", db.getObj("ServiceVersion", serviceVersionId))
      .set("currentVersion", db.getObj("ServiceVersion", serviceVersionId))
      .set("draftVersion", db.getObj("ServiceVersion", newServiceVersionId))
      .set("user", db.getObj("User", userId))
      .set("createdBy", db.getObj("User", userId))
      .set("team", team ? db.getObj("Team", team.id) : null)
      .set("userRole", userRole ? db.getObj("UserRole", userRole.id) : null)
      .set("businessRole", businessRole ? dL.getObj("BusinessRole", businessRole.id) : null)
      .set("status", "draft")
      .save()
      .then(function (obj) {
        userServiceId = obj.id
        return dL.createNewVersion({ userRole, team, serviceId, newVersionId: newServiceVersionId, userServiceId, serviceVersionId: serviceVersion.id })
      }).then(function () {
        return dL.saveServiceVersion(newServiceVersionId, data)
      }).then(function () {
        return { userServiceId, serviceVersionId: newServiceVersionId }
      })
  },
  saveUserService: function (userServiceId, data) {
    const { currentVersion, isWorkerPublic } = data

    const obj = db.getObj("UserService", userServiceId)
      .set("isWorkerPublic", isWorkerPublic)

    if (!currentVersion) {
      dL._saveServiceObj(obj, data)
    }

    return obj.save().then(function () {

    })
  },
  newUserService: function (userServiceId, { rateType, isWorkerPublic, makeServiceSellable, overrides, userId, service, serviceVersion }) {
    const obj = db.getObj("UserService", userServiceId)
      .set("service", db.getObj("Service", service.id))
      .set("serviceVersion", db.getObj("ServiceVersion", serviceVersion.id))
      .set("makeServiceSellable", makeServiceSellable).set("isWorkerPublic", isWorkerPublic)
      .set("rateType", rateType)
      .set("overrides", overrides);

    if (!userServiceId) {
      obj.set("user", userId ? db.getObj("User", userId) : null)
        .set("status", "draft");
    }

    return obj.save().then(function (obj) {
      if (!userServiceId) {
        return db.getObj("User", userId)
          .add("services", db.getObj("Service", service.id))
          .add("userServices", db.getObj("UserService", obj.id))
          .save();
      }
    });
  },
  sendServiceDelivery: function ({ serviceDeliveryId }) {
    return db
      .getQuery("ServiceDelivery")
      .include("order")
      .include("serviceOrder")
      .include("serviceOrder.parentServiceOrder")
      .include("serviceDeliverable")
      .get(serviceDeliveryId)
      .then(function (obj) {
        const serviceDelivery = dL.loadServiceDelivery(obj);
        const { comments, files, order, serviceOrder, serviceDeliverable } = serviceDelivery;

        return db
          .getQuery("ServiceDelivery")
          .equalTo("serviceDeliverable", db.getObj("ServiceDeliverable", serviceDeliverable.id))
          .equalTo("serviceOrder", db.getObj("ServiceOrder", serviceOrder.id))
          .containedIn("removed", [undefined, false])
          .equalTo("status", "pending")
          .find()
          .then(function (objs) {
            return Promise.all(objs.map((obj) => obj.set("status", "withdrawn").save()));
          })
          .then(function () {
            const reviewDueDate = Moment().businessAdd(3).toDate();

            return db
              .getObj("ServiceDelivery", serviceDeliveryId)
              .set("clientReviewDueDate", reviewDueDate)
              .set("status", "pending")
              .save()
              .then(function (obj) {
                return (
                  db
                    .getObj("ServiceOrder", serviceOrder.id)
                    .set("status", "review")
                    .set("lastServiceDeliveryAt", new Date())
                    //.set("lastServiceDelivery", db.getObj("ServiceDelivery", obj.id))
                    //.set("clientReviewDueDate", reviewDueDate)
                    .save()
                );
              });
          })
          .then(function () {
            return dL.addOrderHistory({ orderId: order.id, serviceOrderId: serviceOrder.id, type: "so-delivery-create", description: "Service delivery created." });
          })
          .then(function () {
            return fs.sendChatMessageAPI({
              roomId: serviceOrder.id,
              roomUserId: serviceOrder.id + session.user.id,
              message: "New Delivery: " + comments,
              files,
            });
          })
          .then(function () {
            const { serviceDeliverable, order, serviceOrder, project } = serviceDelivery
            const { parentServiceOrder } = serviceOrder ? serviceOrder : {}
            const { name } = serviceDeliverable

            var assignedTo = serviceOrder.user
            if (parentServiceOrder) {
              assignedTo = parentServiceOrder.assignedTo
            }

            return dL.saveTaskRecord(null, { title: "Review Deliverable: " + name, hours: 1, dueDate: Moment().businessAdd(3).toDate(), order, serviceOrder, project, assignedTo })
          })
      })
      .catch(function (err) {
        alert("Error:" + err.message);
      });
  },
  saveTeamRoleRequestStatus: function (recordId, { status, shortlisted }) {
    const obj = db.getObj("TeamRoleRequest", recordId);

    return obj
      .set("shortlisted", shortlisted)
      .set("status", status)
      .save()
      .then(function () {
        return obj.id;
      });
  },
  newTeamRoleRequest: function ({ teamRolePosition, description, skillLevel, hoursPerMonth }) {
    const { team, teamRole, businessRole } = teamRolePosition

    const obj = db.getObj("TeamRoleRequest");
    return obj
      .set("createdBy", db.getObj("User", session.user.id))
      .set("team", dL.getObj("Team", team.id))
      .set("teamRole", dL.getObj("TeamRole", teamRole.id))
      .set("teamRolePosition", dL.getObj("TeamRolePosition", teamRolePosition.id))
      .set("businessRole", dL.getObj("BusinessRole", businessRole.id))
      .set("status", "new")
      .set("skillLevel", skillLevel)
      .set("description", description)
      .set("hoursPerMonth", hoursPerMonth)
      .save()
      .then(function () {
        return obj.id;
      });
  },
  saveTeamRolePosition: function (recordId, { team, description, teamRole, hoursPerMonth, country, countryRegion, locationCity, remoteOnly }) {
    const obj = db.getObj("TeamRolePosition", recordId);

    if (!recordId) {
      obj.set("createdBy", db.getObj("User", session.user.id))
        .set("team", team ? dL.getObj("Team", team.id) : null)
        .set("status", "open")
    }

    return obj
      .set("teamRole", dL.getObj("TeamRole", teamRole.id))
      .set("businessRole", dL.getObj("BusinessRole", teamRole.businessRole.id))
      .set("description", description)
      .set("hoursPerMonth", hoursPerMonth)
      .set("country", country)
      .set("countryRegion", countryRegion)
      .set("locationCity", locationCity)
      .set("remoteOnly", remoteOnly)
      .save()
      .then(function () {
        return obj.id;
      });
  },
  newTeamMember: function ({ teamRolePosition, teamRoleRequest, team, email, user, displayInPublicProfile, teamRoles, isLeadership }) {
    const obj = db.getObj("Member");

    return obj
      .set("team", db.getObj("Team", team.id))
      .set("createdBy", db.getObj("User", session.user.id))
      .set("user", user ? db.getObj("User", user.id) : null)
      .set("teamRolePosition", teamRolePosition ? db.getObj("TeamRolePosition", teamRolePosition.id) : null)
      .set("teamRoleRequest", teamRoleRequest ? db.getObj("TeamRoleRequest", teamRoleRequest.id) : null)
      .set("email", email)
      .set("status", "pending")
      .set("teamRoles", teamRoles ? teamRoles.map(item => dL.getObj("TeamRole", item.id)) : null)
      .set("businessRoles", teamRoles ? teamRoles.map(item => dL.getObj("BusinessRole", item.businessRole.id)) : null)
      .set("displayInPublicProfile", displayInPublicProfile)
      .set("isLeadership", isLeadership)
      .save()
      .then(function () {
        return obj.id;
      });
  },
  saveTeamMember: function (recordId, { displayInPublicProfile, teamRoles, isLeadership }) {
    const obj = db.getObj("Member", recordId);

    return obj
      .set("teamRoles", teamRoles ? teamRoles.map(item => dL.getObj("TeamRole", item.id)) : null)
      .set("businessRoles", teamRoles ? teamRoles.map(item => dL.getObj("BusinessRole", item.businessRole.id)) : null)
      .set("displayInPublicProfile", displayInPublicProfile)
      .set("isLeadership", isLeadership)
      .save()
      .then(function () {
        return obj.id;
      });
  },
  saveTeamRole: function (recordId, { team, staffType, businessRole, skillLevel, description }) {
    const obj = db.getObj("TeamRole", recordId);

    if (!recordId) {
      obj.set("createdBy", db.getObj("User", session.user.id))
        .set("team", team ? dL.getObj("Team", team.id) : null)
    }

    return obj
      .set("businessRole", businessRole ? dL.getObj("BusinessRole", businessRole.id) : null)
      .set("skillLevel", skillLevel)
      .set("description", description)
      .set("staffType", staffType)
      .save()
      .then(function () {
        return obj.id;
      });
  },
  saveServiceDelivery: function (serviceDeliveryId, { service, servicePackage, order, serviceOrder, comments, files, formData, formItems, serviceDeliverable, serviceDeliverableVersion }) {
    const obj = db.getObj("ServiceDelivery", serviceDeliveryId);

    if (!serviceDeliveryId) {
      obj
        .set("service", db.getObj("Service", service.id))
        .set("servicePackage", servicePackage ? db.getObj("ServicePackage", servicePackage.package.id) : null)
        .set("servicePackageVersion", servicePackage ? db.getObj("ServicePackageVersion", servicePackage.id) : null)
        .set("serviceDeliverable", db.getObj("ServiceDeliverable", serviceDeliverable.id))
        .set("serviceDeliverableVersion", db.getObj("ServiceDeliverableVersion", serviceDeliverableVersion.id))
        .set("order", db.getObj("Order", order.id))
        .set("serviceOrder", db.getObj("ServiceOrder", serviceOrder.id))
        .set("createdBy", db.getObj("User", session.user.id))
        .set("status", "draft");
    }

    return obj
      .set("formData", JSON.stringify(formData))
      .set("formItems", JSON.stringify(serviceDeliverable.formItems))
      .set("comments", comments)
      .set("files", files)
      .save()
      .then(function () {
        return obj.id;
      });
  },
  saveUserAdmin: function (userId, { userRole }) {
    return db.getObj("User", userId).set("userRole", userRole).save();
  },
  saveUser: function (userId, { isPublicBuyerDirectory, isPublicWorkerDirectory, workerLevel, adminServiceTypes, availableToStart, status, enabledRoles, isSystemAdmin, isWorker, userRole, website, linkedInUrl, companyName, companyTitle, serviceCore, firstName, lastName, avatar, shortDescription, description, serviceTypes, companyStages, industries, employeeSizes, revenueSizes, goals, roles, expertise, availableInCities, maxHoursPerMonth, maxHoursPerWeek, remoteOnly, hasStaffServices, hasAdvisoryServices, minHourlyRate, homeCity, country, countryRegion, maxHoursPerDay }) {
    return db
      .getObj("User", userId)
      .set("isPublicBuyerDirectory", isPublicBuyerDirectory)
      .set("isPublicWorkerDirectory", isPublicWorkerDirectory)

      .set("workerLevel", workerLevel)
      .set("adminServiceTypes", adminServiceTypes)
      .set("roles", roles)
      .set("isWorker", isWorker)
      .set("isSystemAdmin", isSystemAdmin)

      .set("availableToStart", availableToStart)
      .set("status", status)
      .set("firstName", firstName)
      .set("lastName", lastName)
      .set("avatar", avatar)

      .set("userRole", userRole)
      .set("website", website)
      .set("linkedInUrl", linkedInUrl)
      .set("companyName", companyName)
      .set("companyTitle", companyTitle)
      .set("shortDescription", shortDescription)
      .set("description", description)
      .set("serviceTypes", serviceTypes)
      .set("serviceCore", serviceCore)
      .set("companyStages", companyStages)
      .set("industries", industries)
      .set("employeeSizes", employeeSizes)
      .set("revenueSizes", revenueSizes)
      .set("goals", goals)
      .set("roles", roles)
      .set("expertise", expertise)
      .set("availableInCities", availableInCities)
      .set("minHourlyRate", minHourlyRate)
      .set("maxHoursPerDay", maxHoursPerDay)
      .set("maxHoursPerMonth", maxHoursPerMonth)
      .set("maxHoursPerWeek", maxHoursPerWeek)
      .set("remoteOnly", remoteOnly)
      .set("hasStaffServices", hasStaffServices)
      .set("hasAdvisoryServices", hasAdvisoryServices)

      .set("homeCity", homeCity)
      .set("country", country)
      .set("countryRegion", countryRegion)

      .set("searchText", [...(firstName + " " + lastName + " " + companyName + " " + companyTitle).toLowerCase().split(" "), toLowerCase(firstName), toLowerCase(lastName), toLowerCase(companyName), toLowerCase(companyTitle)])
      .save();
  },
  saveUserProfile: function (userId, { testMode, firstName, lastName, avatar }) {
    return (
      db
        .getObj("User", userId)
        .set("testMode", testMode)
        .set("firstName", firstName)
        .set("lastName", lastName)
        .set("avatar", avatar)
        //.set("searchText", [...(firstName + " " + lastName).toLowerCase().split(" "), firstName.toLowerCase(), lastName.toLowerCase()])
        .save()
    );
  },
  saveServiceRequest: function (serviceRequestId, { hasRFP, projectId, requestType, status, title, shortDescription, description, serviceType, companyStage, budget, urgency, companySize, userId, deliverables }) {
    var promise = new PromiseA();
    if (!serviceRequestId) {
      promise = dL.getNum("serviceRequestIndexNum");
    } else {
      promise.resolve();
    }
    return promise.then(function (num) {
      const ed = Moment().add(1, "month").toDate();

      const obj = db.getObj("ServiceRequest", serviceRequestId);
      if (!serviceRequestId) {
        obj
          .set("requestNumber", "SR" + num)
          .set("createdBy", db.getObj("User", session.user.id))
          .set("company", dL.getObj("Company", session.company.id))
          .set("proposalCount", 0);
      }

      return obj
        .set("hasRFP", hasRFP)
        .set("title", title)
        .set("shortDescription", shortDescription)
        .set("deliverables", deliverables)
        .set("description", description)
        .set("requestType", requestType)
        .set("serviceType", serviceType)
        .set("companyStage", companyStage)
        .set("budget", budget)
        .set("urgency", urgency)
        .set("companySize", companySize)
        .set("user", db.getObj("User", userId))
        .set("requestDate", new Date())
        .set("lastProposalAt", new Date())
        .set("expireDate", ed)
        .set("status", status)
        .set("project", projectId ? db.getObj("Project", projectId) : null)
        .set("proposalCount", 0)
        .save();
    });
  },
  submitReview: function (serviceOrderId, review) {
    return db.getObj("ServiceOrder", serviceOrderId).set("review", review).set("reviewAt", new Date()).set("hasReviewed", true).save();
  },
  stopTimeRecord: function (recordId) {
    var seconds;
    return db
      .getQuery("TimeRecord")
      .select(["startDate"])
      .get(recordId)
      .then(function (obj) {
        const startDate = getDate(obj.get("startDate"));
        seconds = Moment(new Date()).diff(startDate, "seconds");
        return db.getObj("TimeRecord", recordId).set("endDate", new Date()).set("seconds", seconds).save();
      })
      .then(function () {
        const userId = session.user.id;

        return db
          .getQuery("User")
          .select(["timeRecord"])
          .get(userId)
          .then(function (obj) {
            if (obj.get("timeRecord") && obj.get("timeRecord").id == recordId) {
              return obj.unsetObj("timeRecord").save();
            }
          });
      })
      .then(function () {
        return { recordId, seconds };
      });
  },
  cancelTaskRecord: function ({ recordId }) {
    return db
      .getQuery("TaskRecord")
      .get(recordId)
      .then(function (obj) {
        const task = dL.loadTaskRecord(obj);
        const { serviceOrder } = task;
        obj.set("status", "canceled");
        obj.set("canceledAt", new Date());
        return obj.save().then(function () {
          if (serviceOrder) {
            return dL.executePreReqItems(serviceOrder.id);
          }
        });
      });
  },
  markCompleted: function ({ recordId, completed }) {
    return db
      .getQuery("TaskRecord")
      .get(recordId)
      .then(function (obj) {
        const task = dL.loadTaskRecord(obj);

        const { status, serviceOrder } = task;

        if (completed) {
          if (status == "working") {
            throw "Can't complete working task.";
          } else if (status == "completed") {
            throw "Can't complete completed task.";
          } else if (status == "canceled") {
            throw "Can't complete canceled task.";
          }
        } else {
          if (status == "canceled") {
            throw "Can't un-complete canceled task.";
          }
        }

        if (completed) {
          obj.set("status", "completed");
          obj.set("completedAt", new Date());
        } else {
          obj.set("status", "started");
        }
        return obj.save().then(function () {
          if (completed && serviceOrder) {
            return dL.executePreReqItems(serviceOrder.id);
          }
        });
      });
  },
  stopTaskRecord: function ({ recordId, completed }) {
    //stop the timelog here
    //make the task record as started
    //get the timeLog for this task record that is open
    var addSeconds = 0;
    return db
      .getQuery("TimeRecord")
      .equalTo("task", dL.getObj("TaskRecord", recordId))
      .doesNotExist("endDate")
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        return Promise.all(
          objs.map((obj) =>
            dL.stopTimeRecord(obj.id).then(function ({ seconds }) {
              addSeconds += seconds;
            })
          )
        );
      })
      .then(function () {
        return db
          .getQuery("TaskRecord")
          .get(recordId)
          .then(function (obj) {
            const task = dL.loadTaskRecord(obj);

            const { startedAt, status, serviceOrder } = task;

            if (status != "working") {
              throw "Can't stop task that is not being worked on.";
            }

            if (completed) {
              obj.set("status", "completed");
            } else {
              obj.set("status", "started");

              if (!startedAt) {
                obj.set("startedAt", new Date());
              }
              obj.set("lastStartedAt", new Date());
            }

            return obj
              .increment("timeLogSeconds", addSeconds)
              .save()
              .then(function () {
                if (completed && serviceOrder) {
                  return dL.executePreReqItems(serviceOrder.id);
                }
              });
          });
      });
  },
  startTaskRecord: function ({ recordId }) {
    const userId = session.user.id;

    return db
      .getQuery("User")
      .select(["timeRecord"])
      .include("timeRecord")
      .get(userId)
      .then(function (obj) {
        const timeRecord = obj.get("timeRecord") ? dL.loadTimeRecord(obj.get("timeRecord")) : null;

        if (timeRecord) {
          //need to stop this time record
          if (timeRecord.task) {
            return dL.stopTaskRecord({ recordId: timeRecord.task.id });
          } else {
            return dL.stopTimeRecord(timeRecord.id);
          }
        }
      })
      .then(function () {
        return db
          .getQuery("TaskRecord")
          .get(recordId)
          .then(function (obj) {
            const task = dL.loadTaskRecord(obj);
            const { status, staffAugService, staffAugShare } = task;

            return db
              .getObj("TimeRecord")
              .set("user", userId ? db.getObj("User", userId) : null)
              .set("createdBy", db.getObj("User", session.user.id))
              .set("staffAugShare", staffAugShare ? db.getObj("StaffAugShare", staffAugShare.id) : null)
              .set("staffAugService", staffAugService ? db.getObj("StaffAugService", staffAugService.id) : null)
              .set("startDate", new Date())
              .set("task", db.getObj("TaskRecord", recordId))
              .save()
              .then(function (obj) {
                return db.getObj("User", userId).set("timeRecord", db.getObj("TimeRecord", obj.id)).save();
              })
              .then(function () {
                if (status != "working") {
                  return db.getObj("TaskRecord", recordId).set("status", "working").set("startedAt", new Date()).save();
                }
              });
          });
      })
      .catch(function (err) {
        alert("Error:" + err.message);
      });
  },
  createTaskRecordDeliverable: function (recordId) {
    //this will create a task record deliverable
  },
  feedbackTaskRecordDeliverable: function (recordId) {
    //this will create approve/request modifications to task record deliverable
  },
  getWorkerTaskEstimated: function ({ priority }) {
    //get all the tasks assigned to the worker, grouped by priority bucket
    //get the max hours per day/week/month for the worker
    //based on maxDueDate working backwards assign the worker the work based on work load and identify the earliest time the current priority work can be completed
  },
  saveTaskRecord: function (recordId, { blockingTask, taskType, notes, delayStart, title, description, hours, dueDate, startDate, deliverableNotes }) {
    const obj = db.getObj("TaskRecord", recordId);
    return obj
      .set("blockingTask", blockingTask ? db.getObj("TaskRecord", blockingTask.id) : null)
      .set("taskType", taskType)
      .set("title", title)
      .set("description", description)
      .set("deliverableNotes", deliverableNotes)
      .set("hours", hours)
      .set("dueDate", dueDate)
      .set("notes", notes)
      .set("delayStart", delayStart)
      .set("startDate", startDate ? startDate : new Date())
      .save().then(function () {
        if (blockingTask) {
          return dL.getObj("TaskRecord", blockingTask.id)
            .add("blockingTasks", db.getObj("TaskRecord", obj.id))
            .save()
        }
      }).then(function () {
        return obj.id
      })
  },
  createTaskRecord: function ({ priority, taskType, notes, turboCharge, delayStart, staffAugShare, staffAugService, title, description, hours, dueDate, startDate, deliverableNotes, service, servicePackage, serviceOptionIds, serviceOptions, deliveryType, inputData, userService, order, serviceOrder, project, assignedTo, team, chargeAccount }) {
    return dL.getNum("taskRecordIndexNum").then(function (num) {
      const obj = db.getObj("TaskRecord");
      if (service) {
        obj
          .set("serviceOptions", serviceOptions ? serviceOptions.map((item) => db.getObj("ServiceOption", item.id)) : null)
          .set("inputData", inputData ? JSON.stringify(inputData) : null)
          .set("serviceOptionIds", serviceOptionIds)
          .set("deliveryType", deliveryType)
          .set("service", service ? db.getObj("Service", service.id) : null)
          .set("serviceVersion", db.getObj("ServiceVersion", (userService ? userService : service).currentVersion.id))
          .set("servicePackage", servicePackage ? db.getObj("ServicePackage", servicePackage.id) : null)
          .set("userService", userService ? db.getObj("UserService", userService.id) : null)
          .set("order", order ? db.getObj("Order", order.id) : null)
          .set("serviceOrder", order ? db.getObj("ServiceOrder", serviceOrder.id) : null)
          .set("project", order ? db.getObj("Project", project.id) : null)
          .set("assignedTo", assignedTo ? db.getObj("User", assignedTo.id) : null)
          .set("team", team ? db.getObj("Team", team.id) : null)
          .set("chargeAccount", chargeAccount ? db.getObj("Account", chargeAccount.id) : null)
      }
      return obj
        .set("priority", priority)
        .set("taskType", taskType)
        .set("assignedTo", staffAugService.assignedTo ? db.getObj("User", staffAugService.assignedTo.id) : null)
        .set("createdBy", db.getObj("User", session.user.id))
        .set("staffAugShare", staffAugShare ? db.getObj("StaffAugShare", staffAugShare.id) : null)
        .set("staffAugService", staffAugService ? db.getObj("StaffAugService", staffAugService.id) : null)
        .set("taskNumber", num)
        .set("title", title)
        .set("description", description)
        .set("deliverableNotes", deliverableNotes)
        .set("hours", hours)
        .set("dueDate", dueDate)
        .set("turboCharge", turboCharge)
        .set("notes", notes)
        .set("delayStart", delayStart)
        .set("startDate", startDate ? startDate : new Date())
        .set("status", "ready")
        .set("canStart", true)
        .save()
        .then(function (obj) {
          if (turboCharge) {
            return dL.saveTurboChargeUsage({ task: { id: obj.id }, staffAugShare, staffAugService })
          }
        }).then(function () {
          return obj.id
        })
    })
  },
  getStaffServiceWorkerStats: function (item) {
    const stats = {};
    var promises = [];

    const { maxHoursPerMonth } = item;
    stats.totalPriorityHours = Math.ceil(maxHoursPerMonth * 0.35);
    stats.totalHours = maxHoursPerMonth;

    promises[promises.length] = db
      .getQuery("StaffAugService")
      .equalTo("status", "open")
      .equalTo("assignedTo", dL.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const services = dL.loadObjects("StaffAugService", objs);
        var monthlyHours = 0;
        var priorityHours = 0;
        services.forEach((service) => {
          const { hoursPerMonth, priorityLevel } = service;
          monthlyHours += hoursPerMonth;
          if (priorityLevel == "high") {
            priorityHours += hoursPerMonth;
          }
        });
        stats.bookedTotalHours = monthlyHours;
        stats.bookedPriorityHours = priorityHours;
      });

    return Promise.all(promises).then(function () {
      return stats;
    });
  },
  getStaffAugMonthlyStats: function (recordId) {
    const stats = {};

    return dL
      .getQuery("StaffAugService")
      .get(recordId)
      .then(function (obj) {
        const staffService = dL.loadStaffAugService(obj);

        const { overageBillRate, allowPullForward, maxPullForwardHours, hoursPerMonth, pullForwardHours, rolloverHours, nextChargeDate, endDate } = staffService;

        var availableHoursForMonth = hoursPerMonth + rolloverHours;

        if (allowPullForward) {
          const numberOfMonthsLeftOnContract = Moment(nextChargeDate).diff(endDate, "months");
          const hoursLeftOnContract = numberOfMonthsLeftOnContract * hoursPerMonth;
          const availablePullForwardHours = maxPullForwardHours - pullForwardHours;
          if (availablePullForwardHours > hoursLeftOnContract) {
            availablePullForwardHours = hoursLeftOnContract;
          }
          availableHoursForMonth += availablePullForwardHours;
        }

        stats.availableHoursForMonth = availableHoursForMonth;

        const promises = [];

        promises[promises.length] = dL
          .getQuery("TaskRecord")
          .equalTo("staffAugService", dL.getObj("StaffAugService", recordId))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            const tasks = dL.loadObjects("TaskRecord", objs);
            const openTasks = tasks.filter((item) => (item.status != "completed" && item.status != "canceled"));

            stats.taskCount = tasks.length;
            stats.openTaskCount = openTasks.length;

            var openTaskHours = 0;
            openTasks.forEach((item) => {
              const { hours } = item
              openTaskHours += hours ? hours : 0;
            });

            stats.openTaskHours = openTaskHours;
          });

        const st = Moment(nextChargeDate).add(-1, "month").startOf("day").toDate();
        const et = Moment(nextChargeDate).startOf("day").toDate();

        promises[promises.length] = dL
          .getQuery("TimeRecord")
          .equalTo("staffAugService", dL.getObj("StaffAugService", recordId))
          .greaterThan("startDate", st)
          .lessThan("endDate", et)
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            const arr = dL.loadObjects("TimeRecord", objs);
            var seconds = 0;
            arr.forEach((item) => {
              seconds += item.seconds;
            });
            stats.timeSeconds = seconds;
          });

        promises[promises.length] = dL
          .getQuery("StaffAugShare")
          .equalTo("staffAugService", dL.getObj("StaffAugService", recordId))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            const arr = dL.loadObjects("StaffAugShare", objs);
            stats.sharedWithCount = arr.length;
          });

        return Promise.all(promises).then(function () {
          const { timeSeconds, availableHoursForMonth } = stats;
          const hoursUsed = timeSeconds / 60;

          var overageHours = 0;
          if (hoursUsed > availableHoursForMonth) {
            overageHours = hoursUsed - availableHoursForMonth;
          }
          const overageCosts = overageBillRate * overageHours;

          stats.overageHours = overageHours;
          stats.overageCosts = overageCosts;

          return stats;
        });
      });

    //rolled over hours (in the service)
    //total hours used this month = time logs (hours)
    //pull forward hours=max pull forward-pull forwarded (in this service) (ensure future hours available exists)
    //max hours for this month=hours for this month+rolled over hours+pull forward hours
    //overage hours this month= hours beyond max hours for this month, total cost for overage (?)
  },
  getAccount: function (accountId) {
    return db
      .getQuery("Account")
      .get(accountId)
      .then(function (obj) {
        return dL.loadAccount(obj);
      });
  },
  getChargeBalance: function (recordId) {
    return dL.getStaffAugService(recordId).then(function ({ monthlyCost, overageBillRate }) {
      var total = monthlyCost;

      return dL.getStaffAugMonthlyStats(recordId).then(function (stats) {
        const { overrageHours } = stats;
        const overageAmount = utils.roundMoney(overrageHours * overageBillRate);
        total += overageAmount;

        return { chargeAmount: total, stats };
      });
    });
  },
  getAccountBalance: function (accountId) {
    return Promise.all([dL.getAccountPayments(accountId), dL.getAccountCharges(accountId)]).then(function (arr) {
      return arr[1] - arr[0];
    });
  },
  getAccountPayments: function (accountId) {
    return db
      .getQuery("AccountPayment")
      .equalTo("account", dL.getObj("Account", accountId))
      .equalTo("status", "success")
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const items = dL.loadObjects("AccountPayment", objs);
        var total = 0;
        items.forEach((item) => {
          total += item.amount;
        });
        return total;
      });
  },
  getAccountCharges: function (accountId) {
    return db
      .getQuery("AccountCharge")
      .equalTo("account", dL.getObj("Account", accountId))
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const items = dL.loadObjects("AccountCharge", objs);
        var total = 0;
        items.forEach((item) => {
          total += item.amount;
        });
        return total;
      });
  },
  setAccountCreditCard: function ({ accountId, card }) {
    return db.getObj("Account", accountId).set("creditCard", card).save();
  },
  processAccountCharges: function ({ referenceId, creditCard, testMode, accountId }) {
    //get all the charges
    //get all the payments
    //see what the balance is, process the credit card for the balance
    //add payment record for the process of the card
    //if success return success for the account status, else return fail and set the retry options for the account

    return dL.getAccount(accountId).then(function (account) {
      return dL.getAccountBalance(accountId).then(function (amount) {
        if (amount > 0) {
          const promiseCharge = new PromiseA();
          http
            .run("chargeCard2", {
              testMode,
              accountId,
              creditCard,
              referenceId,
              createdById: session.user.id,
              amount,
            })
            .then(function (results) {
              const { card, err, paymentId } = results;

              const obj = db.getObj("Account", accountId);

              var nextRetryAt;
              var chargeStatus;

              if (err) {
                var retryCount = account.retryCount;
                if (!retryCount) {
                  retryCount = 0;
                }
                chargeStatus = "failed";
                retryCount++;

                var hours = 0;
                const retryCounts = [4, 24, 48, 72];
                if (retryCount > retryCounts.length) {
                  hours = retryCounts[retryCounts.length - 1];
                } else {
                  hours = retryCounts[retryCount - 1];
                }
                nextRetryAt = Moment().add(hours, "hours").toDate();
              } else {
                obj.set("lastSuccessAt", new Date());
                chargeStatus = "success";
              }

              return obj
                .set("lastProcessedAt", new Date())
                .set("chargeStatus", chargeStatus)
                .set("creditCard", card)
                .save()
                .then(function () {
                  promiseCharge.resolve({ ...results, chargeStatus, retryCount, nextRetryAt });
                });
            });
          return promiseCharge;
        } else {
          return { chargeStatus: "success" }
        }
      });
    });
  },
  saveStaffAugShare: function (recordId, { parentStaffAugShareId, requireApproval, staffAugServiceId, assignedToId, userId, hoursDay, hoursWeek, hoursMonth }) {
    return db
      .getObj("StaffAugShare", recordId)
      .set("createdBy", db.getObj("User", session.user.id))
      .set("assignedTo", assignedToId ? db.getObj("User", assignedToId) : null)
      .set("user", userId ? db.getObj("User", userId) : null)
      .set("staffAugService", db.getObj("StaffAugService", staffAugServiceId))
      .set("parentStaffAugShare", parentStaffAugShareId ? db.getObj("StaffAugShare", parentStaffAugShareId) : null)
      .set("requireApproval", requireApproval)
      .set("hoursDay", hoursDay)
      .set("hoursWeek", hoursWeek)
      .set("hoursMonth", hoursMonth)
      .save();
  },
  createAccountCharge: function ({ accountId, description, amount, recordId }) {
    return db
      .getObj("AccountCharge")
      .set("createdBy", db.getObj("User", session.user.id))
      .set("company", dL.getObj("Company", session.company.id))
      .set("account", db.getObj("Account", accountId))
      .set("date", new Date())
      .set("description", description)
      .set("amount", amount)
      .set("recordId", recordId)
      .save()
      .then(function (obj) {
        return obj.id;
      });
  },
  createAccount: function ({ }) {
    return db
      .getObj("Account")
      .set("createdBy", db.getObj("User", session.user.id))
      .set("company", dL.getObj("Company", session.company.id))
      .save()
      .then(function (obj) {
        return obj.id;
      });
  },
  getServicePeople: function ({ serviceId }) {
    return db
      .getQuery("UserService")
      .equalTo("status", "active")
      .equalTo("isWorkerPublic", true)
      .equalTo("service", dL.getObj("Service", serviceId))
      .containedIn("removed", [undefined, false])
      .include("user")
      .include("userRole")
      .find()
      .then(function (objs) {
        return dL.loadObjects("UserService", objs);
      });
  },
  AddStaffService: function (recordId, data) {
    return dL.createAccount({}).then(function (accountId) {
      return dL._addStaffService(recordId, accountId, data).then(function () {
        const { monthlyCost } = data;
        return dL.createAccountCharge({ accountId, description: "Monthly Service Charge", amount: monthlyCost, recordId });
      });
    });
  },
  _addStaffService: function (recordId, accountId, { orderId, projectId, userRoleId, serviceType, skillLevel, monthlyCost, userId, businessRoleId, hoursPerMonth, finalBillRate, baseBillRate, overageBillRate, workerRate, maxPullForwardHours, maxRollOverHours, assignedToId, numberOfMonths, startDate, priorityLevel, endDate, allowPullForward, allowRollover, maxRolloverPerMonth, turboBoostsPerMonth, chargeAccount, order, project, user, userRole, assignedTo, businessRole, staffServiceType, proposal, proposalItem }) {
    return dL.getNum("staffServiceIndexNum").then(function (num) {
      const nextChargeDate = Moment(startDate).add(1, "month").startOf("day").toDate();

      var recordId;
      return db
        .getObj("StaffAugService", recordId)
        .set("createdAt", new Date())
        .set("createdBy", db.getObj("User", session.user.id))
        .set("company", dL.getObj("Company", session.company.id))
        .set("status", "open")

        .set("proposal", proposal ? db.getObj("ServiceProposal", proposal.id) : null)
        .set("proposalItem", proposalItem ? db.getObj("ServiceProposalItem", proposalItem.id) : null)

        .set("chargeAccount", chargeAccount ? db.getObj("Account", chargeAccount.id) : db.getObj("Account", accountId))
        .set("order", order ? db.getObj("Order", order.id) : orderId ? db.getObj("Order", orderId) : null)
        .set("project", project ? db.getObj("Project", project.id) : projectId ? db.getObj("Project", projectId) : null)
        .set("user", user ? db.getObj("User", user.id) : userId ? db.getObj("User", userId) : null)
        .set("userRole", userRole ? db.getObj("UserRole", userRole.id) : userRoleId ? db.getObj("UserRole", userRoleId) : null)
        .set("assignedTo", assignedTo ? db.getObj("User", assignedTo.id) : assignedToId ? db.getObj("User", assignedToId) : null)
        .set("businessRole", businessRole ? db.getObj("BusinessRole", businessRole.id) : businessRoleId ? db.getObj("BusinessRole", businessRoleId) : null)

        .set("serviceNumber", "ST" + num)
        .set("monthlyCost", monthlyCost)
        .set("skillLevel", skillLevel)
        .set("serviceType", staffServiceType ? staffServiceType : serviceType)
        .set("priorityLevel", priorityLevel)
        .set("hoursPerMonth", hoursPerMonth)
        .set("baseBillRate", baseBillRate)
        .set("finalBillRate", finalBillRate)
        .set("overageBillRate", overageBillRate)
        .set("workerRate", workerRate)
        .set("priorityLevel", priorityLevel)
        .set("allowPullForward", allowPullForward)
        .set("allowRollover", allowRollover)
        .set("maxPullForwardHours", maxPullForwardHours)
        .set("maxRolloverPerMonth", maxRolloverPerMonth)
        .set("maxRollOverHours", maxRollOverHours)
        .set("numberOfMonths", numberOfMonths)
        .set("turboBoostsPerMonth", turboBoostsPerMonth)
        .set("startDate", startDate)
        .set("endDate", endDate)

        .set("turboBoostsLeft", turboBoostsPerMonth)
        .set("nextChargeDate", nextChargeDate)
        .save()
        .then(function (obj) {
          recordId = obj.id;
          return recordId;
        });
    });
  },
  setStaffServiceChargeStatus: function (recordId, { status, chargeStatus }) {
    const obj = db.getObj("StaffAugService", recordId);
    if (status) {
      obj.set("status", status);
    }
    if (chargeStatus) {
      obj.set("chargeStatus", chargeStatus);
    }

    return obj.save();
  },
  saveStaffAugService: function (recordId, { userId, businessRoleId, hoursPerMonth, billRate, overageBillRate, workerRate, maxPullForwardHours, maxRollOverHours, assignedToId, numberOfMonths, startDate, turboBoostsPerMonth }) {
    return db
      .getObj("StaffAugService", recordId)
      .set("createdBy", db.getObj("User", session.user.id))
      .set("user", userId ? db.getObj("User", userId) : null)
      .set("assignedTo", assignedToId ? db.getObj("User", assignedToId) : null)
      .set("businessRole", businessRoleId ? db.getObj("BusinessRole", businessRoleId) : null)
      .set("hoursPerMonth", hoursPerMonth)
      .set("billRate", billRate)
      .set("overageBillRate", overageBillRate)
      .set("workerRate", workerRate)
      .set("maxPullForwardHours", maxPullForwardHours)
      .set("maxRollOverHours", maxRollOverHours)
      .set("numberOfMonths", numberOfMonths)
      .set("startDate", startDate)
      .set("turboBoostsPerMonth", turboBoostsPerMonth)
      .set("status", "open")
      .save();
  },
  saveTrainingNote: function ({ newlyActive, isNew, data: { trainer, status, id, service, userService, serviceVersion, task, deliverable, name, shortDescription, noteType, content } }) {
    const obj = db.getObj("TrainingNote", id)
    return obj
      .set("name", name)
      .set("shortDescription", shortDescription)
      .set("noteType", noteType)
      .set("status", status)
      .set("content", JSON.stringify(content))
      .set("createdBy", db.getObj("User", session.user.id))
      .set("service", db.getObj("Service", service.id))
      .set("userService", userService ? db.getObj("UserService", userService.id) : null)
      .set("serviceVersion", serviceVersion ? db.getObj("ServiceVersion", serviceVersion.id) : null)
      .set("task", task ? db.getObj("ServiceTask", task.task.id) : null)
      .set("deliverable", deliverable ? db.getObj("ServiceDeliverable", deliverable.deliverable.id) : null)
      .set("taskVersion", task ? db.getObj("ServiceTaskVersion", task.id) : null)
      .set("deliverableVersion", deliverable ? db.getObj("ServiceDeliverableVersion", deliverable.id) : null)
      .save()
      .then(function () {
        if (newlyActive) {
          const doIncrementCount = function (type, id, versionId) {
            const collection = "Service" + type
            return dL.getObj(collection, id).increment("trainingNoteCount").save().then(function () {
              return dL.getObj(collection + "Version", versionId).increment("trainingNoteCount").save()
            })
          }

          if (task) {
            return doIncrementCount("Task", task.task.id, task.id)
          } else if (deliverable) {
            return doIncrementCount("Deliverable", deliverable.deliverable.id, deliverable.id)
          }
        }
      }).then(function () {
        return dL.getQuery("Trainer")
          .containedIn("removed", [undefined, false])
          .equalTo("user", db.getObj("User", session.user.id))
          .equalTo("service", db.getObj("Service", service.id))
          .first().then(function (obj) {
            if (!obj) {
              obj = dL.getObj("Trainer")
              if (userService) {
                obj.add("userServices", db.getObj("UserService", userService.id))
              }
              return obj
                .set("user", db.getObj("User", session.user.id))
                .set("service", db.getObj("Service", service.id))
                .set("lastNoteUpdatedAt", new Date())
                .set("noteCount", 1)
                .save()
            } else {
              if (isNew) {
                obj.increment("noteCount")
              }
              if (userService) {
                obj.add("userServices", db.getObj("UserService", userService.id))
              }
              return obj
                .set("lastNoteUpdatedAt", new Date())
                .save()
            }
          })
      }).then(function () {
        return obj.id
      })
  },
  saveBusinessRole: function (roleId, { enableRates, jobDescription, name, description, businessSector, rates, taskListText, status, needsReview }) {
    const obj = db.getObj("BusinessRole", roleId)
    return obj
      .set("name", name)
      .set("description", description)
      .set("businessSector", businessSector)
      .set("rates", rates)
      .set("jobDescription", jobDescription)
      .set("taskListText", taskListText)
      .set("status", status)
      .set("enableRates", enableRates)
      .set("needsReview", needsReview)
      .set("searchText", [...name.toLowerCase().split(" "), name.toLowerCase()])
      .save()
      .then(function () {
        return dL.getQuery("UserRole")
          .updateMany({
            _p_businessRole: "BusinessRole$" + roleId, businessSector: { $ne: businessSector }
          }, {
            $set: { businessSector }
          });
      }).then(function () {
        return obj.id
      })
  },
  saveBusinessRoleTask: function ({ recordId, isNew, data: { businessRole, team, company, title, description, skillLevel, workHours, deliveryDays, deliverableNotes, formItems } }) {
    const obj = db.getObj("BusinessRoleTask", recordId)
    if (isNew) {
      obj.set("businessRole", dL.getObj("BusinessRole", businessRole.id))
        .set("company", company ? dL.getObj("Company", company.id) : null)
        .set("team", team ? dL.getObj("Team", team.id) : null)
        .set("createdBy", db.getObj("User", session.user.id))
    }
    return obj
      .set("title", title)
      .set("description", description)
      .set("skillLevel", skillLevel)
      .set("workHours", workHours)
      .set("deliveryDays", deliveryDays)
      .set("deliverableNotes", deliverableNotes)
      .set("formItems", JSON.stringify(formItems))
      .set("searchText", [...(title + " " + description).toLowerCase().split(" "), title.toLowerCase()])
      .save()
      .then(function () {
        return obj.id
      })
  },
  saveTurboChargeUsage: function ({ task, staffAugShare, staffAugService }) {
    return db.getObj("TaskTurboUsage")
      .set("task", task ? db.getObj("TaskRecord", task.id) : null)
      .set("createdBy", db.getObj("User", session.user.id))
      .set("staffAugService", db.getObj("StaffAugService", staffAugService.id))
      .set("staffAugShare", staffAugShare ? db.getObj("StaffAugShare", staffAugShare.id) : null)
      .save()
      .then(function () {
        return db.getObj("StaffAugService", staffAugService.id)
          .increment("turboBoostsLeft", -1)
          .save()
      })
  },
  _saveServiceObj: function (obj, { businessInitiatives, priceMap, masterOverrides, primaryCategory, secondaryCategory, icon, tags, serviceType, companyStages, skillLevel, requestType, name, shortDescription, description, enableServiceOptions, isFixedPriced, enablePackages, price, deliveryDays, hasExtraFastDelivery, extraFastPrice, extraFastDeliveryDays, allowedRevisions, serviceCore, businessRole, userRole, fastDeliveryPercent }) {
    return obj
      .set("name", name)
      .set("icon", icon)
      .set("shortDescription", shortDescription)
      .set("description", description)
      .set("serviceType", serviceType)
      .set("companyStages", companyStages)
      .set("serviceCore", serviceCore)
      .set("requestType", requestType)
      .set("enableServiceOptions", enableServiceOptions)
      .set("isFixedPriced", isFixedPriced)
      .set("enablePackages", enablePackages)
      .set("price", price)
      .set("deliveryDays", deliveryDays)
      .set("hasExtraFastDelivery", hasExtraFastDelivery)
      .set("extraFastPrice", extraFastPrice)
      .set("extraFastDeliveryDays", extraFastDeliveryDays)
      .set("fastDeliveryPercent", fastDeliveryPercent)
      .set("priceMap", priceMap)
      .set("businessInitiatives", businessInitiatives ? businessInitiatives.map(item => db.getObj("BusinessInitiative", item.id)) : null)
      .set("masterOverrides", masterOverrides ? masterOverrides.map(item => db.getObj("MasterOverrideRecord", item.id)) : null)
      .set("primaryCategory", primaryCategory ? db.getObj("ServiceCategory", primaryCategory.id) : null)
      .set("secondaryCategory", secondaryCategory ? db.getObj("ServiceCategory", secondaryCategory.id) : null)
      .set("businessRole", businessRole ? dL.getObj("BusinessRole", businessRole.id) : null)
      .set("userRole", userRole ? dL.getObj("UserRole", userRole.id) : null)
      .set("skillLevel", skillLevel)
      .set("allowedRevisions", allowedRevisions)
      .set("tags", tags ? tags.map(item => db.getObj("Tag", item.id)) : null)
      .set("searchText", [...(name + " " + shortDescription + " " + description).toLowerCase().split(" "), name.toLowerCase(), ...tags ? tags.map(item => item.name) : []])
  },
  saveServiceVersion: function (recordId, data) {
    const { service, userService } = data
    return dL._saveServiceObj(db.getObj("ServiceVersion", recordId), data).save().then(function () {
      return dL.doUpdate1({ serviceObj: db.getObj("ServiceVersion", recordId), serviceId: service.id, userServiceId: userService ? userService.id : null, serviceVersionId: recordId })
    })
  },
  saveService: function (serviceId, data) {
    const { ownerUserService, currentVersion, isBuyerPublic, user, status, ...serviceData } = data

    const isNew = !serviceId;

    const versionId = utils.guid();
    const obj = db.getObj("Service", serviceId);

    if (isNew) {
      obj.set("createdBy", dL.getObj("User", session.user.id))
        .set("user", user ? dL.getObj("User", user.id) : null)
        .set("draftVersion", db.getObj("ServiceVersion", versionId))
        .set("versionNum", 1)
    }

    obj.set("status", status)
      .set("isBuyerPublic", isBuyerPublic)
      .set("ownerUserService", ownerUserService ? dL.getObj("UserService", ownerUserService.id) : null)


    if (!currentVersion) {
      dL._saveServiceObj(obj, serviceData)
    }

    return obj
      .save()
      .then(function () {
        if (isNew) {
          return dL._saveServiceObj(db.getObj("ServiceVersion", versionId), serviceData)
            .set("createdAt", new Date())
            .set("versionNum", 1)
            .set("status", "draft")
            .set("createdBy", db.getObj("User", session.user.id))
            .set("service", db.getObj("Service", obj.id))
            .save();
        }
      })
      .then(function () {
        return { serviceId: obj.id };
      });
  },
  saveInputs: function ({ serviceOrderId, inputData }) {
    return db.getObj("ServiceOrder", serviceOrderId)
      .set("inputData", JSON.stringify(inputData))
      .save();
  },
  submitInputs: function ({ serviceOrderId, inputData }) {
    return db.getQuery("ServiceOrder").get(serviceOrderId).then(function (obj) {
      const serviceOrder = dL.loadServiceOrder(obj)

      const { order, deliveryDays } = serviceOrder

      return db
        .getObj("ServiceOrder", serviceOrderId)
        .set("inputData", JSON.stringify(inputData))
        .set("status", "ready")
        .set("workStartAt", new Date())
        .set("dueDate", Moment().businessAdd(deliveryDays).toDate())
        .set("hasInputsComplete", true)
        .save()
        .then(function () {
          return dL.addOrderHistory({ orderId: order.id, serviceOrderId, type: "so-client-input", description: "Service order client input completed." });
        });
    })
  },
  setServiceRequestUserRole: function ({ clear, role, serviceRequestId, userId }) {
    if (clear) {
      return db.getObj("ServiceRequest", serviceRequestId).set(role, null).remove("accessUsers", { role, userId }).save();
    } else {
      return db.getObj("ServiceRequest", serviceRequestId).set(role, db.getObj("User", userId)).add("accessUsers", { role, userId }).save();
    }
  },
  setServiceProposalUser: function ({ role, clear, proposalId, userId }) {
    if (clear) {
      return db.getObj("ServiceProposal", proposalId).set(role, null).remove("accessUsers", { role, userId }).save();
    } else {
      return db.getObj("ServiceProposal", proposalId).set(role, db.getObj("User", userId)).add("accessUsers", { role, userId }).save();
    }
  },
  setServiceRequestBusinessDevelopment: function ({ clear, serviceRequestId, userId }) {
    return dL.setServiceRequestUserRole({ clear, role: "businessDevelopment", serviceRequestId, userId });
  },
  setServiceRequestStrategyConsultant: function ({ clear, serviceRequestId, userId }) {
    return dL.setServiceRequestUserRole({ clear, role: "strategyConsultant", serviceRequestId, userId });
  },
  setServiceOrderUserRole: function ({ clear, role, serviceOrderId, userId }) {
    if (clear) {
      return db
        .getObj("ServiceOrder", serviceOrderId)
        .remove("accessUsers", { role, userId })
        .save()
        .then(function () {
          return db
            .getQuery("WorkRequest")
            .equalTo("serviceOrder", db.getObj("ServiceOrder", serviceOrderId))
            .find()
            .then(function (objs) {
              return Promise.all(
                objs.map((obj) => {
                  return obj.remove("accessUsers", { role, userId }).save();
                })
              );
            });
        })
        .catch(function (err) {
          alert("Error:" + err.message);
        });
    } else {
      return db
        .getObj("ServiceOrder", serviceOrderId)
        .set(role, db.getObj("User", userId))
        .add("accessUsers", { role, userId })
        .save()
        .then(function () {
          return db
            .getQuery("WorkRequest")
            .equalTo("serviceOrder", db.getObj("ServiceOrder", serviceOrderId))
            .find()
            .then(function (objs) {
              return Promise.all(
                objs.map((obj) => {
                  return obj.add("accessUsers", { role, userId }).save();
                })
              );
            });
        })
        .catch(function (err) {
          alert("Error:" + err.message);
        });
    }
  },
  setOrderUserRole: function ({ clear, role, orderId, userId }) {
    if (clear) {
      return db
        .getObj("Order", orderId)
        .set(role, null)
        .remove("accessUsers", { role, userId })
        .save()
        .then(function () {
          return db
            .getQuery("ServiceOrder")
            .equalTo("order", db.getObj("Order", orderId))
            .find()
            .then(function (objs) {
              return Promise.all(
                objs.map((obj) => {
                  return obj
                    .remove("accessUsers", { role, userId })
                    .save()
                    .then(function () {
                      return db
                        .getQuery("WorkRequest")
                        .equalTo("order", db.getObj("Order", orderId))
                        .find()
                        .then(function (objs) {
                          return Promise.all(
                            objs.map((obj) => {
                              return obj.remove("accessUsers", { role, userId }).save();
                            })
                          );
                        });
                    });
                })
              );
            });
        })
        .catch(function (err) {
          alert("Error:" + err.message);
        });
    } else {
      return db
        .getObj("Order", orderId)
        .set(role, db.getObj("User", userId))
        .add("accessUsers", { role, userId })
        .save()
        .then(function () {
          return db
            .getQuery("ServiceOrder")
            .equalTo("order", db.getObj("Order", orderId))
            .find()
            .then(function (objs) {
              return Promise.all(
                objs.map((obj) => {
                  return obj
                    .add("accessUsers", { role, userId })
                    .save()
                    .then(function () {
                      return db
                        .getQuery("WorkRequest")
                        .equalTo("order", db.getObj("Order", orderId))
                        .find()
                        .then(function (objs) {
                          return Promise.all(
                            objs.map((obj) => {
                              return obj.add("accessUsers", { role, userId }).save();
                            })
                          );
                        });
                    });
                })
              );
            });
        })
        .catch(function (err) {
          alert("Error:" + err.message);
        });
    }
  },
  setStaffServiceProjectManager: function (recordId, userId) {
    return db
      .getObj("StaffAugService", recordId)
      .set("projectManager", userId ? db.getObj("User", userId) : null)
      .save();
  },
  setAssignedToStaffService: function (recordId, userId) {
    return db
      .getObj("StaffAugService", recordId)
      .set("assignedTo", userId ? db.getObj("User", userId) : null)
      .set("assignedAt", new Date())
      .save();
  },
  setOrderProjectManager: function ({ clear, orderId, userId }) {
    return dL.setOrderUserRole({ clear, role: "pm", orderId, userId });
  },
  setOrderStrategyConsultant: function ({ clear, orderId, userId }) {
    return dL.setOrderUserRole({ clear, role: "sc", orderId, userId });
  },
  setServiceOrderWorker: function ({ orderId, clear, serviceOrderId, userId }) {
    if (clear) {
      return db
        .getObj("ServiceOrder", serviceOrderId)
        .set("assignedTo", null)
        .remove("accessUsers", { role: "assignedTo", userId })
        .save()
        .then(function () {
          //remove all tasks assigned for this service order if not completed
          return db
            .getQuery("TaskRecord")
            .existsObj("assignedTo", true)
            .containedIn("removed", [undefined, false])
            .notContainedIn("status", ["completed", "canceled", "working"])
            .equalTo("serviceOrder", db.getObj("ServiceOrder", serviceOrderId))
            .find()
            .then(function (objs) {
              return Promise.all(
                objs.map((obj) => {
                  return obj.set("assignedTo", null).save();
                })
              );
            });
        })
        .then(function () {
          return db
            .getQuery("WorkRequest")
            .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId))
            .equalTo("status", "pending")
            .containedIn("removed", [undefined, false])
            .select([])
            .find()
            .then(function (objs) {
              return Promise.all(
                objs.map((obj) => {
                  return obj.set("status", "canceled").save();
                })
              );
            });
        })
        .then(function () {
          return dL.addOrderHistory({ orderId, serviceOrderId, type: "so-assigned-clear", description: "Service order un-assigned." });
        });
    } else {
      var total;
      var percentage;
      const promises = [];
      promises[promises.length] = db
        .getQuery("ServiceOrder")
        .select(["total"])
        .get(serviceOrderId)
        .then(function (obj) {
          total = obj.get("total");
        });
      promises[promises.length] = db
        .getQuery("User")
        .select(["earnPercentage"])
        .get(userId)
        .then(function (obj) {
          percentage = obj.get("earnPercentage");
          if (!percentage) {
            percentage = 0.75;
          }
        });
      return Promise.all(promises).then(function () {
        //load the serviceOrder to
        const workerEarning = utils.roundMoney(total * percentage);
        const grossProfit = total - workerEarning;
        const bonusPercentage = 0.1;
        const workerBonus = utils.roundMoney(grossProfit * bonusPercentage);
        const netProfit = grossProfit - workerBonus;

        /*
        const {businessDevelopment, strategyConsultant, projectManager} = order;
        if (businessDevelopment) {
          const amount = utils.roundNumber(total * 0.1, 2);
        }
        if (strategyConsultant) {
          const amount = utils.roundNumber(total * 0.7, 2);
        }
        if (projectManager) {
          const amount = utils.roundNumber(total * 0.5, 2);
        }
        */

        return db
          .getObj("ServiceOrder", serviceOrderId)
          .set("assignedTo", db.getObj("User", userId))
          .set("assignedAt", new Date())
          .add("accessUsers", { role: "assignedTo", userId })
          .set("revenue", total)
          .set("grossProfit", grossProfit)
          .set("netProfit", netProfit)
          .set("workerBonus", workerBonus)
          .set("workerEarning", workerEarning)
          .set("workerPayType", "fixed")
          .save()
          .then(function () {
            //set all tasks assigned for this service order if not completed
            return db
              .getQuery("TaskRecord")
              .containedIn("removed", [undefined, false])
              .notContainedIn("status", ["completed", "canceled"])
              .equalTo("serviceOrder", db.getObj("ServiceOrder", serviceOrderId))
              .find()
              .then(function (objs) {
                return Promise.all(
                  objs.map((obj) => {
                    return obj.set("assignedTo", db.getObj("User", userId)).save();
                  })
                );
              });
          })
          .then(function () {
            return dL.addOrderHistory({ orderId, serviceOrderId, type: "so-assigned-set", description: "Service order assigned." });
          });
      });
    }
  },
  getUserServiceVersionNum: function (userServiceId) {
    return db
      .getObj("UserService", userServiceId)
      .increment("versionNum")
      .save()
      .then(function (settingObj) {
        return settingObj.get("versionNum");
      });
  },
  getServiceVersionNum: function (serviceId) {
    return db
      .getObj("Service", serviceId)
      .increment("versionNum")
      .save()
      .then(function (settingObj) {
        return settingObj.get("versionNum");
      });
  },
  getProposalVersionNum: function (proposalId) {
    return db
      .getObj("ServiceProposal", proposalId)
      .increment("proposalVersionNum")
      .save()
      .then(function (settingObj) {
        return settingObj.get("proposalVersionNum");
      });
  },
  getNum: function (name) {
    return db
      .getObj("SystemSetting", "MASTER")
      .increment(name)
      .save()
      .then(function (settingObj) {
        return settingObj.get(name);
      });
  },
  updateServiceProposalAfterPurchase: function ({ proposalId }) {
    return dL.getServiceProposal({ proposalId })
      .then(function ({ proposal, serviceOrders, staffOrders }) {
        const { buyerMasterOverrides, lastSentProposalVersion, allItems } = proposal
        const allItemsV = getAllItemsForVersion(lastSentProposalVersion);
        const values = allItemsV.values()
        const promises = []
        values.forEach(item => {
          const proposalItem = allItems.get(item.id)
          const { id, itemType } = proposalItem

          var purchased
          if (itemType == "service") {
            purchased = serviceOrders.filter(item => item.proposalItem.id == id)
          } else if (itemType == "staff") {
            purchased = staffOrders.filter(item => item.proposalItem.id == id)
          }

          if (purchased && purchased.length > 0) {
            var leftQty = proposalItem.quantity - purchased.length
            if (leftQty < 0) { leftQty = 0 }

            if (buyerMasterOverrides) {
              const item = buyerMasterOverrides.find(item => item.proposalItem.id == id)
              if (item) {
                item.quantity = leftQty
                //save this masterOverride record
                promises[promises.length] = dL.getObj("MasterOverrideRecord", item.id).set("quantity", leftQty).save()
              }
            }
          }
        })

        return Promise.all(promises).then(function () {
          const priceMap = createProposalPriceMapBuyer({ proposal, proposalVersion: lastSentProposalVersion })

          return dL.getObj("ServiceProposal", proposal.id)
            .set("buyerPriceMap", priceMap)
            .save()
        })
      })
  },
  getWorkerUsers: function ({ ids }) {
    return db
      .getQuery("User")
      .containedIn("_id", ids)
      .containedIn("removed", [undefined, false])
      .include("userRoles")
      .find()
      .then(function (objs) {
        return dL.loadObjects("User", objs);
      });
  },
  getServiceProposal: function ({ proposalId }) {
    var proposal;
    var serviceOrders;
    var staffOrders

    return dL
      .getQuery("ServiceProposal")
      .include("user")
      .include("createdBy")
      .include("businessDevelopment")
      .include("strategyConsultant")
      .include("projectManager")
      .include("serviceRequest")
      .include("currentProposalVersion")
      .include("lastSentProposalVersion")
      .include("buyerMasterOverrides")
      .get(proposalId)
      .then(function (obj) {
        proposal = dL.loadServiceProposal(obj);

        clearCache()

        const { buyerMasterOverrides, currentProposalVersion, lastSentProposalVersion } = proposal

        const services = {}
        const userServices = {}
        const serviceVersions = {}

        const process1 = function (priceMap) {
          priceMap.forEach(mapItem => {
            const { item } = mapItem
            const { actualServiceVersionId, serviceId, userServiceId, serviceVersionId, userServiceVersionId } = item
            services[serviceId] = true
            serviceVersions[actualServiceVersionId] = true
            serviceVersions[serviceVersionId] = true
            if (userServiceId) { userServices[userServiceId] = true }
            if (userServiceVersionId) { serviceVersions[userServiceVersionId] = true }
          })
        }

        if (buyerMasterOverrides) {
          buyerMasterOverrides.forEach(masterOverride => {
            const { actualServiceVersion, service, userService, serviceVersion, userServiceVersion } = masterOverride
            services[service.id] = true
            serviceVersions[actualServiceVersion.kid] = true
            serviceVersions[serviceVersion.id] = true
            if (userService) { userServices[userService.id] = true }
            if (userServiceVersion) { serviceVersions[userServiceVersion.id] = true }
          })
        }

        if (currentProposalVersion) {
          const { priceMap } = currentProposalVersion
          process1(priceMap)
        }

        if (lastSentProposalVersion) {
          const { priceMap } = lastSentProposalVersion
          process1(priceMap)
        }

      }).then(function () {
        const { buyerMasterOverrides, currentProposalVersion, lastSentProposalVersion } = proposal

        const promises = [];

        if (buyerMasterOverrides) {
          promises[promises.length] = Promise.all(buyerMasterOverrides.map(masterOverride => {
            return loadSubServiceVersion_Worker({ subServiceVersion: masterOverride, dontClearCache: true })
          }))
        }

        const loadOverrides = function (masterOverrides) {
          var arr
          return dL.getQuery("MasterOverrideRecord")
            .containedIn("_id", masterOverrides.map(item => item.id))
            .containedIn("removed", [undefined, false])
            .find().then(function (objs) {
              arr = dL.loadObjects("MasterOverrideRecord", objs)
              return Promise.all(arr.map(masterOverride => {
                return loadSubServiceVersion_Worker({ subServiceVersion: masterOverride, dontClearCache: true })
              })).then(function () {
                return arr
              })
            })
        }

        if (currentProposalVersion) {
          const { masterOverrides } = currentProposalVersion

          if (masterOverrides) {
            promises[promises.length] = loadOverrides(masterOverrides).then(function (masterOverrides) {
              currentProposalVersion.masterOverrides = masterOverrides
            })
          }
        }

        if (lastSentProposalVersion) {
          const { masterOverrides } = lastSentProposalVersion

          if (masterOverrides) {
            promises[promises.length] = loadOverrides(masterOverrides).then(function (masterOverrides) {
              lastSentProposalVersion.masterOverrides = masterOverrides
            })
          }
        }

        var items
        promises[promises.length] = dL
          .getQuery("ServiceProposalItem")
          .equalTo("proposal", dL.getObj("ServiceProposal", proposalId))
          .containedIn("removed", [undefined, false])
          .include("servicePackage")
          .include("userRole")
          .include("businessRole")
          .include("assignedTo")
          .include("proposalVersion")
          .include("service")
          .include("userService")
          .include("userService.user")
          .include("serviceVersion")
          .include("userServiceVersion")
          .include("actualServiceVersion")
          .include("createdBy")
          .find()
          .then(function (objs) {
            items = dL.loadObjects("ServiceProposalItem", objs);

            return Promise.all(
              items.map(proposalItem => {
                const { userService, service, servicePackage, serviceVersion, userServiceVersion, workerUserServices, actualServiceVersion } = proposalItem;

                const promises = []
                if (workerUserServices) {
                  promises[promises.length] = dL._loadWorkerUserServices(workerUserServices)
                }

                if (actualServiceVersion) {
                  promises[promises.length] = dL.getServiceVersion4({ serviceVersionId: actualServiceVersion.id }).then(function (serviceVersion) {
                    proposalItem.actualServiceVersion = serviceVersion;
                  });

                  promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: serviceVersion.id, disableSub: true }).then(function (serviceVersion) {
                    proposalItem.serviceVersion = serviceVersion;
                  });

                  if (userService) {
                    promises[promises.length] = dL.getUserService2({ userServiceId: userService.id }).then(function (userService) {
                      proposalItem.userService = userService;
                    });
                  }

                  if (userServiceVersion) {
                    promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userServiceVersion.id, disableSub: true }).then(function (serviceVersion) {
                      proposalItem.userServiceVersion = serviceVersion;
                    });
                  }

                  promises[promises.length] = dL.getService2({ serviceId: service.id }).then(function (service) {
                    proposalItem.service = service;

                    if (servicePackage) {
                      proposalItem.servicePackage = service.packages.find((item) => item.package.id == servicePackage.id);
                    }
                  });
                }

                return Promise.all(promises)
              })
            );
          }).then(function () {
            proposal.proposalItems = items
            proposal.allItems = new HashTable();

            items.forEach(item => {
              proposal.allItems.set(item.id, item);
            });

            return Promise.all(
              items.map(item => {
                const { workerUsers } = item;
                if (workerUsers) {
                  return dL.getWorkerUsers({ ids: workerUsers.map(item => item.id) })
                    .then(function (workerUsers) {
                      item.workerUsers = workerUsers
                    });
                }
              })
            );
          });

        promises[promises.length] = dL
          .getQuery("ServiceOrder")
          .equalTo("proposal", dL.getObj("ServiceProposal", proposalId))
          .containedIn("removed", [undefined, false])
          .include("proposalItem")
          .find()
          .then(function (objs) {
            serviceOrders = dL.loadObjects("ServiceOrder", objs);
          });

        promises[promises.length] = dL
          .getQuery("StaffAugService")
          .equalTo("proposal", dL.getObj("ServiceProposal", proposalId))
          .containedIn("removed", [undefined, false])
          .include("proposalItem")
          .find()
          .then(function (objs) {
            staffOrders = dL.loadObjects("StaffAugService", objs);
          });

        return Promise.all(promises);
      })
      .then(function () {
        return { proposal, serviceOrders, staffOrders };
      });
  },
  createOrder: function ({
    projectId, proposalId, accountId, chargeId, userId, email, createdById, total, items, paymentMethod, contractTotal,
    paymentTotal,
    paymentTermType,
    totalMonths, monthlyPayments
  }) {
    //assign project manager
    //assign strategy consultant
    //assign workers

    //set strategy consultant for user
    //set project manager for user
    //set workers for service (sequence of priority?)

    const getServiceTypes = function (items) {
      const serviceTypes = {};
      items.forEach((item) => {
        const { service } = item;
        if (service) {
          const { serviceType } = service;
          serviceTypes[serviceType] = true;
        }
      });

      const arr = [];
      for (var key in serviceTypes) {
        arr.push(key);
      }
      return arr;
    };

    var cAccountId = accountId;
    var promise = new PromiseA();
    if (!accountId) {
      promise = dL.createAccount({}).then(function (accountId) {
        cAccountId = accountId;
      });
    } else {
      promise.resolve();
    }

    return promise.then(function () {
      var orderObj;
      return dL.getNum("orderIndexNum").then(function (num) {
        const orderId = utils.guid();

        const serviceTypes = getServiceTypes(items);
        const serviceOrderIds = [];

        var nextPaymentDate, nextPaymentAmount

        if (totalMonths > 0) {
          nextPaymentDate = Moment().add(1, "month").toDate()
          nextPaymentAmount = monthlyPayments[1]
        }

        return db
          .getObj("Order", orderId)
          .set("chargeAccount", db.getObj("Account", cAccountId))
          .set("user", db.getObj("User", userId))
          .set("company", dL.getObj("Company", session.company.id))
          .set("charge", chargeId ? db.getObj("Charge", chargeId) : null)
          .set("proposal", proposalId ? db.getObj("ServiceProposal", proposalId) : null)
          .set("project", projectId ? db.getObj("Project", projectId) : null)
          .set("createdBy", db.getObj("User", createdById))
          .set("orderNumber", "ORD" + num)
          .set("email", email)
          .set("subTotal", total)
          .set("total", total)

          .set("nextPaymentDate", nextPaymentDate)
          .set("nextPaymentAmount", nextPaymentAmount)

          .set("monthlyPayments", monthlyPayments)
          .set("contractTotal", contractTotal)
          .set("paymentTermType", paymentTermType)
          .set("totalMonths", totalMonths)

          .set("serviceTypes", serviceTypes)
          .set("paymentMethod", paymentMethod)
          .set("orderDate", new Date())
          .set("status", "pending")
          .save()
          .then(function (obj) {
            orderObj = obj;

            const promises = [];
            items.forEach((cartItem) => {
              const { quantity, itemType, proposal, proposalItem, priceMap } = cartItem;

              for (var i = 0; i < quantity; i++) {
                const newId = utils.guid();
                const newCartItem = Object.assign({}, cartItem);
                newCartItem.id = newId;
                newCartItem.canStart = true;
                serviceOrderIds.push(newId);
                if (itemType == "service") {
                  promises[promises.length] = dL.createServiceOrder({ accountId, userId, orderId, projectId, item: newCartItem, parentSOIds: [], proposal, proposalItem, priceMap });
                } else if (itemType == "staff") {
                  promises[promises.length] = dL.createStaffOrder({ accountId, userId, orderId, projectId, item: newCartItem });
                }
              }
            });
            return Promise.all(promises);
          })
          .then(function () {
            if (proposalId) {
              return dL.updateServiceProposalAfterPurchase({ proposalId })
            }
          }).then(function () {
            return db.getQuery("ServiceOrder")
              .equalTo("order", dL.getObj("Order", orderId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                return db.getObj("Order", orderId)
                  .set("totalServiceOrders", count)
                  .set("serviceOrders", serviceOrderIds.map((id) => db.getObj("ServiceOrder", id)))
                  .save();
              });
          })
          .then(function () {
            return dL.addOrderHistory({ orderId, type: "o-create", description: "Order placed." });
          })
          .then(function () {
            return orderId
          });
      });
    });
  },
  saveForm: function (formId, { name, description, formItems, isSystem }) {
    return db.getObj("Form", formId).set("name", name).set("description", description).set("isSystem", isSystem).set("formItems", JSON.stringify(formItems)).set("user", db.getObj("User", session.user.id)).set("createdBy", db.getObj("User", session.user.id)).save();
  },
  getBuyerProposal: function ({ autoCreate }) {
    //check to see for the open proposal on the buyer side
    //createdBy, status==open
    //if none exists then create
  },
  _getService: function ({ serviceId }) {
    if (cache[serviceId]) {
      const rtn = cache[serviceId]
      return rtn.promise.then(function () {
        return rtn.value
      })
    }

    const promise = new PromiseA()
    const rtn = { promise }
    cache[serviceId] = rtn

    dL.getQuery("Service")
      .include("ownerUserService")
      .include("ownerUserService.user")
      .include("createdBy")
      .include("team")
      .include("tags")
      .include("draftVersion")
      .include("currentVersion")
      .get(serviceId)
      .then(function (obj) {
        const service = dL.loadService(obj);
        rtn.value = service
        promise.resolve(service)
      })
      .catch(function (err) {
        promise.reject(err)
      })

    return promise
  },
  _getUserService: function ({ userServiceId }) {
    if (cache[userServiceId]) {
      const rtn = cache[userServiceId]
      return rtn.promise.then(function () {
        return rtn.value
      })
    }

    const promise = new PromiseA()
    const rtn = { promise }
    cache[userServiceId] = rtn

    dL.getQuery("UserService")
      .include("createdBy")
      .include("team")
      .include("user")
      .include("tags")
      .include("draftVersion")
      .include("currentVersion")
      .include("userRole")
      .include("businessRole")
      .include("primaryCategory")
      .include("secondaryCategory")
      .include("deliverables")
      .include("packages")
      .include("options")
      .include("subServices")
      .include("inputs")
      .include("tasks")
      .get(userServiceId)
      .then(function (obj) {
        const userService = dL.loadUserService(obj);
        rtn.value = userService
        promise.resolve(userService)
      })
      .catch(function (err) {
        promise.reject(err)
      })

    return promise
  },
  _getServiceVersion: function ({ serviceVersionId }) {
    if (cache[serviceVersionId]) {
      const rtn = cache[serviceVersionId]
      return rtn.promise.then(function () {
        return rtn.value
      })
    }

    console.log(serviceVersionId)

    const promise = new PromiseA()
    const rtn = { promise }
    cache[serviceVersionId] = rtn

    dL.getQuery("ServiceVersion")
      .include("team")
      .include("tags")
      .include("deliverables")
      .include("packages")
      .include("options")
      .include("subServices")
      .include("inputs")
      .include("tasks")
      .include("businessRole")
      .include("userRole")
      .include("primaryCategory")
      .include("secondaryCategory")
      .include("service")
      .include("service.currentVersion")
      .include("userService")
      .include("userService.currentVersion")
      .include("userService.user")
      .include("masterOverrides")
      .get(serviceVersionId)
      .then(function (obj) {
        const serviceVersion = dL.loadServiceVersion(obj);
        rtn.value = serviceVersion
        promise.resolve(serviceVersion)
      })
      .catch(function (err) {
        promise.reject(err)
      })

    return promise
  },
  getUserService2: function ({ userServiceId, disableSub }) {
    return dL.getUserService({ userServiceId, full: true, disableSub })
  },
  getUserService: function ({ teamId, userId, serviceId, userServiceId, full, disableSub }) {
    if (userServiceId) {
      var userService
      return dL._getUserService({ userServiceId }).then(function (_userService) {
        userService = _userService

        if (full) {
          const promises = [];
          const { workerUserServices, currentVersion, draftVersion } = userService;

          if (workerUserServices) {
            promises[promises.length] = dL._loadWorkerUserServices(workerUserServices)
          }

          promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: draftVersion.id, disableSub }).then(function (serviceVersion) {
            userService.draftVersion = serviceVersion
          });

          if (currentVersion) {
            promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: currentVersion.id, disableSub }).then(function (serviceVersion) {
              userService.currentVersion = serviceVersion
            });
          }
          return Promise.all(promises);
        }
      }).then(function () {
        return userService
      })
    } else {
      const query = db.getQuery("UserService")
      if (userId) {
        query.equalTo("user", dL.getObj("User", userId))
      }
      if (teamId) {
        query.equalTo("team", dL.getObj("Team", teamId))
      }
      if (full) {
        query
          .include("currentVersion")
          .include("draftVersion")
      }
      return query
        .equalTo("service", dL.getObj("Service", serviceId))
        .containedIn("removed", [undefined, false])
        .include("user")
        .include("userRole")
        .first()
        .then(function (obj) {
          if (obj) {
            return dL.loadUserService(obj);
          }
        });
    }
  },
  saveServiceProposal: function (proposalId, { isBuyerSide, businessDevelopment, templateId, serviceRequest, title, objectives, description, user, items, additionalNotes, templateName, isTemplate, proposalType, projectManager, strategyConsultant, deliveryDays, total, fluxDelivery, fluxScope }, proposalItems) {
    const isNew = !proposalId;
    if (!proposalId) {
      proposalId = utils.guid();
    }

    const promise = new PromiseA();
    promise.resolve();

    var num;
    return promise
      .then(function () {
        if (isNew) {
          return dL.getNum("proposalIndexNum").then(function (_num) {
            num = _num;
          });
        }
      })
      .then(function () {
        const proposalVersionId = utils.guid();
        const obj = db.getObj("ServiceProposal", proposalId);

        if (isNew) {
          obj
            .set("isBuyerSide", isBuyerSide)
            .set("proposalNumber", "PR" + num)
            .set("templateId", templateId)
            .set("proposalVersionNum", 1)
            .set("status", "draft")
            .set("createdBy", db.getObj("User", session.user.id))
            .set("createdAt", new Date())
            .set("currentProposalVersion", db.getObj("ServiceProposalVersion", proposalVersionId));

          if (isBuyerSide) {
            obj.set("company", db.getObj("Company", session.company.id))
          }
        }

        return obj
          .set("proposalDate", new Date())
          .set("fluxDelivery", fluxDelivery)
          .set("fluxScope", fluxScope)
          .set("deliveryDays", deliveryDays)
          .set("total", total)
          .set("isTemplate", isTemplate)
          .set("templateName", templateName)
          .set("proposalType", proposalType)
          .set("additionalNotes", additionalNotes)
          .set("title", title)
          .set("description", description)
          .set("objectives", objectives)
          .set("items", JSON.stringify(items))
          .set("user", user ? db.getObj("User", user.id) : serviceRequest ? db.getObj("User", serviceRequest.user.id) : null)

          .set("serviceRequest", serviceRequest ? db.getObj("ServiceRequest", serviceRequest.id) : null)

          .set("businessDevelopment", businessDevelopment ? db.getObj("User", businessDevelopment.id) : null)
          .set("projectManager", projectManager ? db.getObj("User", projectManager.id) : null)
          .set("strategyConsultant", strategyConsultant ? db.getObj("User", strategyConsultant.id) : null)

          .add("accessUsers", user ? { role: "user", userId: user.id } : null)
          .add("accessUsers", projectManager ? { role: "pm", userId: projectManager.id } : null)
          .add("accessUsers", strategyConsultant ? { role: "sc", userId: strategyConsultant.id } : null)
          .add("accessUsers", businessDevelopment ? { role: "bd", userId: businessDevelopment.id } : null)

          .save()
          .then(function () {
            if (isNew) {
              return db.getObj("ServiceProposalVersion", proposalVersionId)
                .set("createdAt", new Date())
                .set("versionNum", 1)
                .set("status", "draft")
                .set("createdBy", db.getObj("User", session.user.id))
                .set("proposal", db.getObj("ServiceProposal", proposalId))
                .save();
            }
          })
          .then(function () {
            if (proposalItems) {
              const items = []

              return Promise.all(proposalItems.map(item => {
                item.proposal = { id: proposalId }
                item.proposalVersion = { id: proposalVersionId }
                return dL.saveProposalItem({ item }).then(function () {
                  items.push({ id: item.id })

                  return db.getObj("ServiceProposalVersion", proposalVersionId)
                    .set("items", JSON.stringify(items))
                    .save();
                })
              }))
            }
          })
          .then(function () {
            return proposalId;
          });
      });
  },

  _saveServiceItem: function ({ recordId, item, service, userService, serviceVersion, type, saveVersionObj, isNewVersion }) {
    const typeLower = getFirstLetterLower(type)
    const collectionBase = "Service" + type
    const collection = collectionBase + "Version"
    const fieldNameBase = typeLower
    const fieldName = fieldNameBase + "s"
    const serviceVersionId = serviceVersion.id
    const serviceVersionNum = serviceVersion.versionNum
    const serviceId = service.id
    const userServiceId = userService ? userService.id : null

    if (!recordId) {
      return db
        .getObj(collectionBase)
        .set("service", db.getObj("Service", serviceId))
        .set("userService", userService ? db.getObj("UserService", userServiceId) : null)
        .set("serviceVersion", db.getObj("ServiceVersion", serviceVersionId))
        .set("serviceVersionNum", serviceVersionNum)
        .save()
        .then(function (itemObj) {
          const versionObj = db.getObj(collection)
          saveVersionObj(versionObj)

          if (!recordId) {
            versionObj
              .set("service", db.getObj("Service", serviceId))
              .set("userService", userService ? db.getObj("UserService", userServiceId) : null)
              .set("serviceVersion", db.getObj("ServiceVersion", serviceVersionId))
              .set("serviceVersionNum", serviceVersionNum)
              .set(fieldNameBase, db.getObj(collectionBase, itemObj.id));
          }

          return versionObj
            .save()
            .then(function () {
              return db.getObj("ServiceVersion", serviceVersionId)
                .add(fieldName, db.getObj(collection, versionObj.id))
                .set("lastSavedAt", new Date())
                .save();
            })
            .then(function () {
              return versionObj.id;
            });
        });
    } else {
      if (isNewVersion) {
        const newRecordId = utils.guid();
        const newObj = db.getObj(collection, newRecordId)
        saveVersionObj(newObj)
        return newObj
          .set("service", db.getObj("Service", serviceId))
          .set("userService", userService ? db.getObj("UserService", userServiceId) : null)
          .set("serviceVersion", db.getObj("ServiceVersion", serviceVersionId))
          .set("serviceVersionNum", serviceVersionNum)
          .set(fieldNameBase, db.getObj(collectionBase, item.id))
          .save()
          .then(function () {
            return db.getObj("ServiceVersion", serviceVersionId)
              .remove(fieldName, db.getObj(collection, recordId))
              .save();
          })
          .then(function () {
            return db.getObj("ServiceVersion", serviceVersionId)
              .add(fieldName, db.getObj(collection, newRecordId))
              .set("lastSavedAt", new Date())
              .save();
          })
          .then(function () {
            return newRecordId;
          });
      } else {
        const obj = db.getObj(collection, recordId)
        saveVersionObj(obj)
        return obj
          .save()
          .then(function () {
            return recordId;
          });
      }
    }
  },

  saveServiceDeliverable: function ({ recordId, data: { icon, deliverable, reviewPeriod, limitedToOptions, limitedToPackages, service, name, description, file, formItems, deliverableType, serviceVersion, userService }, isNewVersion }) {
    return dL._saveServiceItem({
      type: "Deliverable",
      recordId, service, userService, serviceVersion, item: deliverable,
      saveVersionObj: (obj) => {
        obj
          .set("icon", icon)
          .set("deliverableType", deliverableType)
          .set("formItems", JSON.stringify(formItems))
          .set("limitedToPackages", limitedToPackages)
          .set("limitedToOptions", limitedToOptions)
          .set("name", name)
          .set("description", description)
          .set("reviewPeriod", reviewPeriod)
          .set("file", file);
      }, isNewVersion
    })
  },

  saveServicePackage: function ({ recordId, data: { icon, servicePackage, isFixedPriced, allowedRevisions, name, shortDescription, description, price, deliveryDays, includedDeliverableIds, serviceVersion, service, hasExtraFastDelivery, extraFastDeliveryDays, extraFastPrice, hours, fastDeliveryPercent, userService }, isNewVersion }) {
    return dL._saveServiceItem({
      type: "Package",
      recordId, service, userService, serviceVersion, item: servicePackage,
      saveVersionObj: (obj) => {
        obj
          .set("isFixedPriced", isFixedPriced)
          .set("icon", icon)
          .set("name", name)
          .set("shortDescription", shortDescription)
          .set("description", description)
          .set("price", price)
          .set("hours", hours)
          .set("deliveryDays", deliveryDays)
          .set("allowedRevisions", allowedRevisions)
          .set("includedDeliverableIds", includedDeliverableIds)
          .set("hasExtraFastDelivery", hasExtraFastDelivery)
          .set("extraFastDeliveryDays", extraFastDeliveryDays)
          .set("fastDeliveryPercent", fastDeliveryPercent)
          .set("extraFastPrice", extraFastPrice);
      }, isNewVersion
    })
  },

  saveServiceTask: function ({ recordId, data: { icon, task, hours, name, description, serviceVersion, service, limitedToOptions, limitedToPackages, requiredPreServices, taskInstructions, userService }, isNewVersion }) {
    return dL._saveServiceItem({
      type: "Task",
      recordId, service, userService, serviceVersion, item: task,
      saveVersionObj: (obj) => {
        obj
          .set("icon", icon)
          .set("name", name)
          .set("description", description)
          .set("hours", hours)
          .set("limitedToOptions", limitedToOptions)
          .set("limitedToPackages", limitedToPackages)
          .set("requiredPreServices", requiredPreServices)
          .set("taskInstructions", taskInstructions);
      }, isNewVersion
    })
  },

  saveServiceSubService: function ({ recordId, data: { packageId, startDayIndex, doneDayIndex, actualServiceVersion, actualVersionType, userService, userServiceVersion, workerUserServices, subService, unitPrice, parentService, parentUserService, parentServiceVersion, service, servicePackage, serviceVersion, deliveryType, total, deliveryDays, serviceOptionIds, delayOptions, assignedTo, requiredPreServices, limitedToOptions, limitedToPackages, inputData, priceMap }, isNewVersion }) {
    const saveVersionObj = function (obj) {
      obj
        .set("service", db.getObj("Service", service.id))
        .set("actualVersionType", actualVersionType)
        .set("actualServiceVersion", db.getObj("ServiceVersion", actualServiceVersion.id))
        .set("serviceVersion", db.getObj("ServiceVersion", serviceVersion.id))
        .set("serviceVersionNum", serviceVersion.versionNum)
        .set("servicePackage", servicePackage ? db.getObj("ServicePackage", servicePackage.package.id) : null)
        .set("servicePackageVersion", servicePackage ? db.getObj("ServicePackageVersion", servicePackage.id) : null)
        .set("userService", userService ? db.getObj("UserService", userService.id) : null)
        .set("userServiceVersion", userServiceVersion ? db.getObj("ServiceVersion", userServiceVersion.id) : null)
        .set("startDayIndex", startDayIndex)
        .set("doneDayIndex", doneDayIndex)
        .set("packageId", packageId)
        .set("deliveryType", deliveryType)
        .set("deliveryDays", deliveryDays)
        .set("unitPrice", unitPrice)
        .set("total", total)
        .set("serviceOptionIds", serviceOptionIds)
        .set("delayOptions", delayOptions)
        .set("priceMap", priceMap)
        //.set("assignedToUser", assignedToUser)
        .set("assignedTo", assignedTo ? db.getObj("User", assignedTo.id) : null)
        .set("requiredPreServices", requiredPreServices)
        .set("limitedToOptions", limitedToOptions)
        .set("limitedToPackages", limitedToPackages)

        .set("inputData", inputData ? JSON.stringify(inputData) : null)
        .set("deliveryType", deliveryType)

        .set("workerUserServices", workerUserServices ? workerUserServices.map((item) => {
          const { userService, userServiceVersion } = item
          return { userServiceId: userService.id, userServiceVersionId: userServiceVersion.id }
        }) : null)
    }

    if (!recordId) {
      return db
        .getObj("ServiceSubServiceVersion")
        .set("parentService", db.getObj("Service", parentService.id))
        .set("parentUserService", parentUserService ? db.getObj("UserService", parentUserService.id) : null)
        .set("parentServiceVersion", db.getObj("ServiceVersion", parentServiceVersion.id))
        .set("parentServiceVersionNum", parentServiceVersion.versionNum)
        .save()
        .then(function (taskObj) {
          const versionObj = db.getObj("ServiceSubServiceVersion", recordId)
          saveVersionObj(versionObj)
          if (!recordId) {
            versionObj
              .set("parentService", db.getObj("Service", parentService.id))
              .set("parentUserService", parentUserService ? db.getObj("UserService", parentUserService.id) : null)
              .set("parentServiceVersion", db.getObj("ServiceVersion", parentServiceVersion.id))
              .set("parentServiceVersionNum", parentServiceVersion.versionNum)
              .set("subService", db.getObj("ServiceSubService", taskObj.id));
          }

          return versionObj
            .save()
            .then(function () {
              return db.getObj("ServiceVersion", parentServiceVersion.id)
                .add("subServices", db.getObj("ServiceSubServiceVersion", versionObj.id))
                .save();
            })
            .then(function () {
              return versionObj.id;
            });
        });
    } else {
      if (isNewVersion) {
        const newRecordId = utils.guid();
        const newObj = db.getObj("ServiceSubServiceVersion", newRecordId)
        saveVersionObj(newObj)
        newObj
          .set("parentService", db.getObj("Service", parentService.id))
          .set("parentServiceVersion", db.getObj("ServiceVersion", parentServiceVersion.id))
          .set("parentServiceVersionNum", parentServiceVersion.versionNum)
          .set("subService", db.getObj("ServiceSubService", subService.id))
        return newObj
          .save()
          .then(function () {
            return db.getObj("ServiceVersion", parentServiceVersion.id)
              .remove("subServices", db.getObj("ServiceSubServiceVersion", recordId))
              .save();
          })
          .then(function () {
            return db.getObj("ServiceVersion", parentServiceVersion.id)
              .add("subServices", db.getObj("ServiceSubServiceVersion", newRecordId))
              .save();
          })
          .then(function () {
            return newRecordId;
          });
      } else {
        const obj = db.getObj("ServiceSubServiceVersion", recordId)
        saveVersionObj(obj)
        return obj
          .save()
          .then(function () {
            return recordId;
          });
      }
    }
  },

  saveServiceInput: function ({ recordId, data: { icon, input, formItems, limitedToOptions, limitedToPackages, name, description, serviceVersion, service, similarServices, userService }, isNewVersion }) {
    return dL._saveServiceItem({
      type: "Input",
      recordId, service, userService, serviceVersion, item: input,
      saveVersionObj: (obj) => {
        obj
          .set("icon", icon)
          .set("name", name)
          .set("description", description)
          .set("limitedToOptions", limitedToOptions)
          .set("limitedToPackages", limitedToPackages)
          .set("formItems", JSON.stringify(formItems))
          .set("similarServices", similarServices ? similarServices.map((item) => db.getObj("Service", item.id)) : null);
      }, isNewVersion
    })
  },

  saveServiceOption: function ({ recordId, data: { icon, option, limitedToPackages, isFixedPriced, name, description, price, deliveryDays, serviceVersion, service, userService }, isNewVersion }) {
    return dL._saveServiceItem({
      type: "Option",
      recordId, service, userService, serviceVersion, item: option,
      saveVersionObj: (obj) => {
        obj
          .set("icon", icon)
          .set("name", name)
          .set("description", description)
          .set("isFixedPriced", isFixedPriced)
          .set("price", price)
          .set("deliveryDays", deliveryDays)
          .set("limitedToPackages", limitedToPackages);
      }, isNewVersion
    })
  },

  saveServiceDeliverables: function ({ serviceVersion }) {
    const { id, deliverables } = serviceVersion
    return db
      .getObj("ServiceVersion", id)
      .set(
        "deliverablesSeq",
        deliverables.map((item) => item.id)
      )
      .set("lastSavedAt", new Date())
      .save();
  },
  saveServiceInputs: function ({ serviceVersion }) {
    const { id, inputs } = serviceVersion
    return db
      .getObj("ServiceVersion", id)
      .set(
        "inputsSeq",
        inputs.map((item) => item.id)
      )
      .set("lastSavedAt", new Date())
      .save();
  },
  saveServiceSubServices: function ({ serviceVersion }) {
    const { id, subServices } = serviceVersion
    return db
      .getObj("ServiceVersion", id)
      .set(
        "subServicesSeq",
        subServices.map((item) => item.id)
      )
      .set("lastSavedAt", new Date())
      .save();
  },
  saveServiceTasks: function ({ serviceVersion }) {
    const { id, tasks } = serviceVersion
    return db
      .getObj("ServiceVersion", id)
      .set(
        "tasksSeq",
        tasks.map((item) => item.id)
      )
      .set("lastSavedAt", new Date())
      .save();
  },
  saveServiceOptions: function ({ serviceVersion }) {
    const { id, options } = serviceVersion
    return db
      .getObj("ServiceVersion", id)
      .set(
        "optionsSeq",
        options.map((item) => item && item.id)
      )
      .set("lastSavedAt", new Date())
      .save();
  },
  saveServicePackages: function ({ serviceVersion }) {
    const { id, packages } = serviceVersion
    return db
      .getObj("ServiceVersion", id)
      .set(
        "packagesSeq",
        packages.map((item) => item.id)
      )
      .set("lastSavedAt", new Date())
      .save();
  },

  removeServiceInput: function ({ serviceVersion, versionItem }) {
    return dL._removeServiceItem({ type: "Input", serviceVersion, versionItem })
  },
  removeServiceSubService: function ({ serviceVersion, versionItem }) {
    return dL._removeServiceItem({ type: "SubService", serviceVersion, versionItem })
  },
  _removeServiceItem: function ({ type, serviceVersion, versionItem }) {
    const recordId = versionItem.id;
    const { id } = serviceVersion;
    const typeLower = getFirstLetterLower(type)

    const obj = db.getObj("Service" + type + "Version", recordId);
    if (versionItem.serviceVersion.id == id) {
      obj.set("removed", true);
    } else {
      obj.set("removedOnServiceVersion", db.getObj("ServiceVersion", serviceVersion.id));
    }
    return obj.save().then(function () {
      return db.getObj("ServiceVersion", id).remove(typeLower + "s", db.getObj("Service" + type + "Version", recordId)).remove(typeLower + "sSeq", recordId).save();
    });
  },
  removeServiceTask: function ({ serviceVersion, versionItem }) {
    return dL._removeServiceItem({ type: "Task", serviceVersion, versionItem })
  },
  removeServiceOption: function ({ serviceVersion, versionItem }) {
    return dL._removeServiceItem({ type: "Option", serviceVersion, versionItem })
  },
  removeServiceDeliverable: function ({ serviceVersion, versionItem }) {
    return dL._removeServiceItem({ type: "Deliverable", serviceVersion, versionItem })
  },
  removeServicePackage: function ({ serviceVersion, versionItem }) {
    return dL._removeServiceItem({ type: "Package", serviceVersion, versionItem })
  },

  removeMasterOverrideRecordPB: function ({ recordId, proposalId }) {
    return db.getObj("ServiceProposal", proposalId)
      .remove("buyerMasterOverrides", db.getObj("MasterOverrideRecord", recordId))
      .save()
  },
  removeMasterOverrideRecordP: function ({ recordId, rootProposalVersion }) {
    return db.getObj("ServiceProposalVersion", rootProposalVersion.id)
      .remove("masterOverrides", db.getObj("MasterOverrideRecord", recordId))
      .save()
  },
  removeMasterOverrideRecord: function ({ recordId, rootServiceVersion }) {
    return db.getObj("ServiceVersion", rootServiceVersion.id)
      .remove("masterOverrides", db.getObj("MasterOverrideRecord", recordId))
      .save()
  },
  saveMasterOverrideRecord: function ({ isNew, recordId, data: { isBuyerSide, priceMap, startDayIndex, doneDayIndex, packageId, recommendedUser, proposalItem, isBuyerOverride, quantity, overridePrice, actualVersionType, actualServiceVersion, rootService, rootServiceVersion, subServiceVersion, rootProposal, rootProposalVersion, isRemoved, path, overrideType, userService, userServiceVersion, workerUserServices, subService, unitPrice, service, servicePackage, serviceVersion, deliveryType, total, deliveryDays, serviceOptionIds, delayOptions, inputData } }) {
    return db.getObj("MasterOverrideRecord", recordId)
      .set("path", path)
      .set("priceMap", priceMap)
      .set("startDayIndex", startDayIndex)
      .set("doneDayIndex", doneDayIndex)
      .set("packageId", packageId)
      .set("overrideType", overrideType)
      .set("isRemoved", isRemoved)
      .set("isBuyerOverride", isBuyerOverride)
      .set("isBuyerSide", isBuyerSide)

      .set("recommendedUser", recommendedUser ? db.getObj("User", recommendedUser.id) : null)
      .set("proposalItem", proposalItem ? db.getObj("ServiceProposalItem", proposalItem.id) : null)
      .set("rootService", rootService ? db.getObj("Service", rootService.id) : null)
      .set("rootProposal", rootProposal ? db.getObj("ServiceProposal", rootProposal.id) : null)
      .set("rootServiceVersion", rootServiceVersion ? db.getObj("ServiceVersion", rootServiceVersion.id) : null)
      .set("rootProposalVersion", rootProposalVersion ? db.getObj("ServiceProposalVersion", rootProposalVersion.id) : null)

      .set("actualVersionType", actualVersionType)
      .set("actualServiceVersion", actualServiceVersion ? db.getObj("ServiceServiceVersion", actualServiceVersion.id) : null)

      .set("subService", subService ? db.getObj("ServiceSubService", subService.id) : null)
      .set("subServiceVersion", subServiceVersion ? db.getObj("ServiceSubServiceVersion", subServiceVersion.id) : null)

      .set("service", db.getObj("Service", service.id))
      .set("serviceVersion", db.getObj("ServiceVersion", serviceVersion.id))
      .set("serviceVersionNum", serviceVersion.versionNum)
      .set("servicePackage", servicePackage ? dL.getObj("ServicePackage", servicePackage.package.id) : null)
      .set("servicePackageVersion", servicePackage ? dL.getObj("ServicePackageVersion", servicePackage.id) : null)
      .set("userService", userService ? db.getObj("UserService", userService.id) : null)
      .set("userServiceVersion", userServiceVersion ? db.getObj("ServiceVersion", userServiceVersion.id) : null)
      .set("deliveryType", deliveryType)
      .set("deliveryDays", deliveryDays)
      .set("unitPrice", unitPrice)
      .set("total", total)
      .set("serviceOptionIds", serviceOptionIds)
      .set("delayOptions", delayOptions)

      .set("quantity", quantity)
      .set("overridePrice", overridePrice)

      .set("inputData", inputData ? JSON.stringify(inputData) : null)
      .set("deliveryType", deliveryType)

      .set("workerUserServices", workerUserServices ? workerUserServices.map((item) => {
        const { userService, userServiceVersion } = item
        return { userServiceId: userService.id, userServiceVersionId: userServiceVersion.id }
      }) : null)
      .save()
      .then(function (obj) {
        if (isNew) {
          if (rootServiceVersion) {
            return db.getObj("ServiceVersion", rootServiceVersion.id)
              .add("masterOverrides", db.getObj("MasterOverrideRecord", obj.id))
              .save()

          } else if (rootProposalVersion) {
            if (isBuyerOverride) {
              return db.getObj("ServiceProposal", rootProposal.id)
                .add("buyerMasterOverrides", db.getObj("MasterOverrideRecord", obj.id))
                .save()
            } else {
              return db.getObj("ServiceProposalVersion", rootProposalVersion.id)
                .add("masterOverrides", db.getObj("MasterOverrideRecord", obj.id))
                .save()
            }
          } else if (isBuyerSide) {
            return db.getObj("ServiceProposal", rootProposal.id)
              .add("buyerMasterOverrides", db.getObj("MasterOverrideRecord", obj.id))
              .save()
          }
        }
      })
  },

  getForm: function (formId) {
    return db
      .getQuery("Form")
      .containedIn("removed", [undefined, false])
      .get(formId)
      .then(function (obj) {
        return dL.loadForm(obj);
      });
  },
  getForms: function (params) {
    const { isSystem } = params ? params : {};
    return (
      db
        .getQuery("Form")
        //.equalTo("isSystem", isSystem)
        .equalTo("user", dL.getObj("User", session.user.id))
        .containedIn("removed", [undefined, false])
        .find()
        .then(function (objs) {
          return dL.loadObjects("Form", objs);
        })
    );
  },
  getServicePackages: function ({ serviceId }) {
    return db
      .getQuery("ServicePackage")
      .equalTo("service", db.getObj("Service", serviceId))
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        return dL.loadObjects("ServicePackage", objs);
      });
  },
  saveBusinessInitiative: function (recordId, { isPublic, name, shortDescription, businessSector }) {
    return db.getObj("BusinessInitiative", recordId)
      .set("company", db.getObj("Company", session.company.id))
      .set("createdBy", db.getObj("User", session.user.id))
      .set("name", name)
      .set("isPublic", isPublic)
      .set("businessSector", businessSector)
      .set("shortDescription", shortDescription)
      .set("searchText", [...(name + " " + shortDescription).toLowerCase().split(" "), name.toLowerCase()])
      .save();
  },
  saveTeam: function (teamId, { companyStage, icon, name, shortDescription, description, businessSectors, avatar, tags }) {
    return db.getObj("Team", teamId)
      .set("name", name)
      .set("icon", icon)
      .set("avatar", avatar)
      .set("companyStage", companyStage)
      .set("businessSectors", businessSectors)
      .set("shortDescription", shortDescription)
      .set("description", description)
      .set("searchText", [...(name + " " + shortDescription + " " + description).toLowerCase().split(" "), name.toLowerCase(), ...tags ? tags.map(item => item.name) : []])
      .set("tags", tags ? tags.map(item => db.getObj("Tag", item.id)) : null)
      .save();
  },
  newTeam: function ({ companyStage, name, businessSector, shortDescription }) {
    var teamId;
    return db
      .getObj("Team")
      .set("name", name)
      .set("companyStage", companyStage)
      .set("businessSector", businessSector)
      .set("shortDescription", shortDescription)
      .set("teamLeader", db.getObj("User", session.user.id))
      .add("users", db.getObj("User", session.user.id))
      .set("createdBy", db.getObj("User", session.user.id))
      .set("status", "active")
      .set("searchText", [...(name + " " + shortDescription).toLowerCase().split(" "), name.toLowerCase()])
      .save()
      .then(function (obj) {
        teamId = obj.id;
        return db.getObj("Member")
          .set("type", "team")
          .set("user", db.getObj("User", session.user.id))
          .set("team", db.getObj("Team", obj.id))
          .set("role", "admin")
          .set("displayInPublicProfile", true)
          .set("isLeadership", true)
          .set("createdBy", db.getObj("User", session.user.id))
          .save();
      })
      .then(function () {
        return teamId;
      });
  },
  saveProject: function (projectId, { name, businessSector, budget, projectType }) {
    return db.getObj("Project", projectId)
      .set("name", name).set("projectType", projectType)
      .set("businessSector", businessSector)
      .set("budget", budget)
      .save();
  },
  newProject: function ({ name, businessSector, budget, projectType }) {
    var projectId;
    return db
      .getObj("Project")
      .set("name", name)
      .set("projectType", projectType)
      .set("businessSector", businessSector)
      .set("budget", budget)
      .set("projectManager", db.getObj("User", session.user.id))
      .add("users", db.getObj("User", session.user.id))
      .set("createdBy", db.getObj("User", session.user.id))
      .set("company", dL.getObj("Company", session.company.id))
      .set("status", "active")
      .save()
      .then(function (obj) {
        projectId = obj.id;
        return db.getObj("Member").set("user", db.getObj("User", session.user.id)).set("project", db.getObj("Project", obj.id)).set("role", "admin").set("createdBy", db.getObj("User", session.user.id)).save();
      })
      .then(function () {
        return projectId;
      });
  },
  saveCompany: function (recordId, { name, primaryColor, logo, companyGoals, employeeSize, revenueSize, shortBio, companyStage, companyCity, companyIndustry, website }) {
    return db.getObj("Company", recordId)
      .set("name", name)
      .set("primaryColor", primaryColor)
      .set("logo", logo)
      .set("shortBio", shortBio)
      .set("companyGoals", companyGoals)
      .set("website", website)
      .set("companyStage", companyStage)
      .set("companyIndustry", companyIndustry)
      .set("companyCity", companyCity)
      .set("employeeSize", employeeSize)
      .set("revenueSize", revenueSize)
      .save();
  },
  newCompany: function ({ name }) {
    var companyId;
    return db
      .getObj("Company")
      .set("name", name)
      .set("companyAdmin", db.getObj("User", session.user.id))
      .add("users", db.getObj("User", session.user.id))
      .set("createdBy", db.getObj("User", session.user.id))
      .set("status", "active")
      .save()
      .then(function (obj) {
        companyId = obj.id;
        return db.getObj("Member").set("user", db.getObj("User", session.user.id)).set("company", db.getObj("Company", obj.id)).set("role", "admin").set("createdBy", db.getObj("User", session.user.id)).save();
      })
      .then(function () {
        return companyId;
      });
  },
  saveProposalPackages: function ({ proposalId, packages }) {
    return db.getObj("ServiceProposal", proposalId).set("packages", JSON.stringify(packages)).save();
  },
  saveProposalOptions: function ({ proposalId, options }) {
    return db.getObj("ServiceProposal", proposalId).set("options", JSON.stringify(options)).save();
  },
  updateProposalItems: function ({ proposal }) {
    const { currentProposalVersion, id, total, deliveryDays } = proposal;
    const allServiceItems = dL.getAllServiceProposalItems({ proposal });

    const serviceTypes = {};
    const arrS = [];
    allServiceItems.forEach((item) => {
      const { serviceType } = item

      if (serviceType) {
        serviceTypes[serviceType] = true;
      }
    });
    for (var key in serviceTypes) {
      arrS.push(key);
    }

    return db
      .getObj("ServiceProposal", id)
      .set("total", total)
      .set("deliveryDays", deliveryDays)
      .set("serviceTypes", arrS)
      .save()
      .then(function () {
        const { items, overrides } = currentProposalVersion;
        return db.getObj("ServiceProposalVersion", currentProposalVersion.id)
          .set("items", JSON.stringify(items))
          .set("overrides", JSON.stringify(overrides))
          .save();
      });
  },
  updateStaffAugServiceShares: function ({ serviceId, seqs }) {
    return db.getObj("StaffAugService", serviceId).set("sharesSeq", seqs).save();
  },
  removeUserRole: function ({ userId, userRoleId }) {
    return db
      .getQuery("UserRole")
      .get(userRoleId)
      .then(function (obj) {
        const userRole = dL.loadUserRole(obj);

        return db
          .getObj("UserRole", userRoleId)
          .set("removed", true)
          .save()
          .then(function () {
            return db.getObj("User", userId).remove("userRoles", db.getObj("UserRole", userRoleId)).remove("businessRoles", db.getObj("BusinessRole", userRole.businessRole.id)).save();
          });
      });
  },
  removeUserService: function ({ userId, userServiceId }) {
    return db
      .getQuery("UserService")
      .get(userServiceId)
      .then(function (obj) {
        const userService = dL.loadUserService(obj);

        return db
          .getObj("UserService", userServiceId)
          .set("removed", true)
          .save()
          .then(function () {
            return db.getObj("User", userId).remove("services", db.getObj("Service", userService.service.id)).remove("userServices", db.getObj("UserService", userServiceId)).save();
          });
      });
  },
  removeTaskRecord: function ({ recordId }) {
    return db.getObj("TaskRecord", recordId).set("removed", true).save();
  },
  removeStaffAugShare: function ({ serviceId, recordId }) {
    return db
      .getObj("StaffAugShare", recordId)
      .set("removed", true)
      .save()
      .then(function () {
        return db.getObj("StaffAugService", serviceId).remove("shares", db.getObj("StaffAugShare", recordId)).remove("sharesSeq", recordId).save();
      });
  },
  getStats: function (props) {
    const { type, onWhere, where, filters, onFilter, topFilter } = props;

    const getQueryCount = function (item) {
      const { onQuery, value, onGetStat } = item;
      if (onGetStat) {
        return onGetStat()
      }
      var query = db.getQuery(type).containedIn("removed", [undefined, false]);
      if (onQuery) {
        onQuery(query);
      } else {
        query.equalTo("status", value);
      }
      if (where) {
        const { userId } = where;
        if (userId) {
          query.equalTo("accessUsers.userId", userId);
        }
      }
      if (onWhere) {
        onWhere(query);
      }
      if (topFilter) {
        topFilter.onQuery(query);
      }
      if (onFilter) {
        onFilter(query);
      }
      return query.count();
    };

    var stats = {};
    const promises = filters.map((item) => {
      const { disableStat, value } = item;
      if (!disableStat) {
        return getQueryCount(item).then(function (count) {
          stats[value] = count;
        });
      }
    });

    return Promise.all(promises).then(function () {
      return stats;
    });
  },
  getTopStats: function (props) {
    const { type, onWhere, where, topFilters } = props;

    const getQueryCount = function (item) {
      const { onQuery, onQueryStats } = item;
      var query = db.getQuery(type).containedIn("removed", [undefined, false]);
      if (onWhere) {
        onWhere(query);
      }
      if (where) {
        const { userId } = where;
        if (userId) {
          query.equalTo("accessUsers.userId", userId);
        }
      }
      if (onQuery) {
        onQuery(query);
      }
      if (onQueryStats) {
        onQueryStats(query);
      }
      return query.count();
    };

    var stats = {};
    const promises = topFilters.map((item) => {
      const { disableStat, value } = item;

      if (!disableStat) {
        return getQueryCount(item).then(function (count) {
          stats[value] = count;
        });
      }
    });

    return Promise.all(promises).then(function () {
      return stats;
    });
  },
  getList: function (props) {
    const { topFilter, onWhere, type, filter, where, searchText, status, includes, defaultSort, searchFields, onFilter } = props ? props : {};
    const { onQuery, sort } = filter ? filter : {};

    const getQuery = function () {
      var query = db.getQuery(type).containedIn("removed", [undefined, false]);

      if (!searchText) {
        if (onQuery) {
          onQuery(query);
        } else {
          if (status) {
            query.equalTo("status", status);
          }
        }
      }
      if (where) {
        const { userId } = where;
        if (userId) {
          query.equalTo("accessUsers.userId", userId);
        }
      }

      if (onWhere) {
        onWhere(query);
      }
      if (onFilter) {
        onFilter(query);
      }
      if (topFilter) {
        topFilter.onQuery(query);
      }
      if (includes) {
        includes.forEach((item) => {
          query.include(item);
        });
      }
      if (sort) {
        sort.forEach((item) => {
          const { desc, field } = item;
          if (desc) {
            query.descending(field);
          } else {
            query.ascending(field);
          }
        });
      } else {
        if (defaultSort) {
          const { desc, field } = defaultSort;
          if (desc) {
            query.descending(field);
          } else {
            query.ascending(field);
          }
        }
      }
      return query;
    };

    if (searchText) {
      const promises = [];

      searchFields.forEach((item) => {
        const { field, onQuery, prefix } = item;
        const queryS = getQuery();
        if (onQuery) {
          onQuery({ queryS, searchText });
        } else {
          if (prefix) {
            queryS.startsWith(field, prefix + searchText);
          } else {
            queryS.startsWith(field, searchText);
          }
        }
        promises[promises.length] = queryS.find();
      });

      return Promise.all(promises)
        .then(function (arr) {
          var farr = [];
          arr.forEach((item) => {
            farr = farr.concat(item);
          });
          return dL.loadObjects(type, farr);
        })
        .catch(function (err) {
          alert("Error:" + err.message);
        });
    } else {
      return getQuery()
        .find()
        .then(function (objs) {
          return dL.loadObjects(type, objs);
        })
        .catch(function (err) {
          alert("Error:" + err.message);
        });
    }
  },
  getPeoplePerBusinessRole: function () {
    return db
      .getQuery("UserRole")
      .aggregate([
        {
          $match: {
            removed: { $in: [undefined, false] },
          },
        },
        {
          $group: {
            _id: "$_p_businessRole",
            count: { $sum: 1 },
          },
        },
      ])
      .then(function (objs) {
        const counts = {};
        objs.forEach((item) => {
          counts[item._id.split("$")[1]] = item.count;
        });
        return counts;
      });
  },
  getPeoplePerSector: function () {
    return db
      .getQuery("UserRole")
      .aggregate([
        {
          $match: {
            removed: { $in: [undefined, false] },
          },
        },
        {
          $group: {
            _id: "$businessSector",
            count: { $sum: 1 },
          },
        },
      ])
      .then(function (objs) {
        const counts = {};
        objs.forEach((item) => {
          counts[item._id] = item.count;
        });
        return counts;
      });
  },
  getBIPerSector: function ({ tabValue }) {
    const match = {
      removed: { $in: [undefined, false] },
    }
    if (tabValue == "public") {
      match.isPublic = true
    }
    return db
      .getQuery("BusinessInitiative")
      .aggregate([
        {
          $match: match,
        },
        {
          $group: {
            _id: "$businessSector",
            count: { $sum: 1 },
          },
        },
      ])
      .then(function (objs) {
        const counts = {};
        objs.forEach((item) => {
          counts[item._id] = item.count;
        });
        return counts;
      });
  },
  /*
  getSubService: function ({ serviceId, servicePackageId, userServiceId }) {
    var service;
    var servicePackage;
    var userService;
    return dL
      .getService(serviceId)
      .then(function (_service) {
        service = _service;
        if (servicePackageId) {
          servicePackage = service.packages.find((item) => item.id == servicePackageId);
        }
        if (userServiceId) {
          return dL
            .getQuery("UserService")
            .include("user")
            .include("userRole")
            .get(userServiceId)
            .then(function (obj) {
              userService = dL.loadUserService(obj);
            });
        }
      })
      .then(function () {
        return { service, servicePackage, userService };
      });
  },
  */
  getListRenderStatsPerSector: function (props) {
    var { match, collection } = props;
    if (!match) {
      match = {};
    }
    match.removed = { $in: [undefined, false] };
    return db
      .getQuery(collection)
      .aggregate([
        {
          $match: match,
        },
        {
          $group: {
            _id: "$serviceType",
            count: { $sum: 1 },
          },
        },
      ])
      .then(function (objs) {
        const counts = {};
        objs.forEach((item) => {
          counts[item._id] = item.count;
        });
        return counts;
      });
  },
  getServicesPerSector: function ({ onQuery }) {
    var match = {
      removed: { $in: [undefined, false] },
      status: "active",
    };
    onQuery && onQuery(match);
    return db
      .getQuery("Service")
      .aggregate([
        {
          $match: match,
        },
        {
          $group: {
            _id: "$serviceType",
            count: { $sum: 1 },
          },
        },
      ])
      .then(function (objs) {
        const counts = {};
        objs.forEach((item) => {
          counts[item._id] = item.count;
        });
        return counts;
      });
  },
  getServicesPerSector2: function ({ userServices, status }) {
    return db
      .getQuery("Service")
      .aggregate([
        {
          $match: {
            removed: { $in: [undefined, false] },
            status: "active",
            _id: status == "mine" ? { $in: userServices.map((item) => item.service.id) } : { $nin: userServices.map((item) => item.service.id) },
          },
        },
        {
          $group: {
            _id: "$serviceType",
            count: { $sum: 1 },
          },
        },
      ])
      .then(function (objs) {
        const counts = {};
        objs.forEach((item) => {
          counts[item._id] = item.count;
        });
        return counts;
      });
  },
  getRolesPerSector: function ({ status }) {
    return db
      .getQuery("BusinessRole")
      .aggregate([
        {
          $match: {
            removed: { $in: [undefined, false] },
            status,
          },
        },
        {
          $group: {
            _id: "$businessSector",
            count: { $sum: 1 },
          },
        },
      ])
      .then(function (objs) {
        const counts = {};
        objs.forEach((item) => {
          counts[item._id] = item.count;
        });
        return counts;
      });
  },
  userServiceRemove: function (userServiceId) {
    return dL
      .getObj("UserService", userServiceId)
      .set("removed", true)
      .save()
      .then(function () {
        return "";
      });
  },
  serviceRemove: function (serviceId) {
    return dL
      .getObj("Service", serviceId)
      .set("removed", true)
      .save()
      .then(function () {
        return "";
      });
  },
  getRecord: function (type, recordId) {
    return dL.getQuery(type).get(recordId);
  },
  userServiceSetStatus: function ({ serviceId, userServiceId, status }) {
    const obj = dL.getObj("UserService", userServiceId)
    if (status == "archived") {
      obj.set("archivedAt", new Date())
    }
    return obj
      .set("status", status)
      .save().then(function () {
        if (status == "active") {
          return db.getObj("Service", serviceId).increment("activeUserServices").save()
        } else if (status == "archived") {
          return db.getObj("Service", serviceId).increment("activeUserServices", -1).save()
        }
      })
  },
  serviceSetStatus: function ({ serviceId, status }) {
    if (status == "active" || status == "pending") {
      //return dL.getService(serviceId, true).then(function (service) {
      /*
      const { tasks } = service.draftVersion
  
      if (tasks.length == 0) {
        if (status == "pending") {
          return "Must enter at least one task to submit this service for approval.";
        } else {
          return "Must enter at least one task to make this service active.";
        }
      }
      */

      return dL.publishService(serviceId)
      //});
    } else {
      const obj = dL.getObj("Service", serviceId)
      if (status == "archived") {
        obj.set("archivedAt", new Date())
      }
      return obj
        .set("status", status)
        .save()
    }
  },
  processRetry: function (staffService) {
    const { chargeAccount, id } = staffService;

    return dL.processAccountCharges({ testMode: session.user.testMode, accountId: chargeAccount.id }).then(function (results) {
      const { chargeStatus, nextRetryAt } = results;

      return db.getObj("StaffAugService", id).set("nextRetryAt", nextRetryAt).set("chargeStatus", chargeStatus).save();
    });
  },
  processCharge: function (staffService) {
    const { nextChargeDate, overageBillRate, id, monthlyCost, chargeAccount } = staffService;

    if (nextChargeDate <= new Date()) {
      const newChargeDate = Moment(nextChargeDate).add(1, "month").startOf("day").toDate();

      return dL.createAccountCharge({ accountId: chargeAccount.id, description: "Monthly Service Charge", amount: monthlyCost, recordId: id }).then(function () {
        var stats;
        return dL
          .getStaffAugMonthlyStats(id)
          .then(function (_stats) {
            stats = _stats;
            const { overrageHours } = stats;

            if (overrageHours > 0) {
              return dL.createAccountCharge({ accountId: chargeAccount.id, description: "Monthly Overage Charge", amount: utils.roundMoney(overrageHours * overageBillRate), recordId: id });
            }
          })
          .then(function (stats) {
            const { pullForwardHours, canRolloverHours } = stats;

            return db.getObj("StaffAugService", id).increment("pullForwardHours", pullForwardHours).increment("rolloverHours", canRolloverHours).set("nextChargeDate", newChargeDate).save();
          })
          .then(function () {
            return dL.processAccountCharges({ testMode: session.user.testMode, accountId: chargeAccount.id });
          })
          .then(function (results) {
            const { chargeStatus, nextRetryAt } = results;

            return db.getObj("StaffAugService", id).set("nextRetryAt", nextRetryAt).set("chargeStatus", chargeStatus).save();
          });
      });

      //get charges for the next month
      //add to charges: 1) monthly cost 2) overage rate charges 3) add/set rollover hours if extra hours exist 4) add/set pulled forward hours if used
      //set nextChargeDate
      //try payment process if charges>0, set chargeStatus=="success"
      //if fail, retry in 24 hours, set chargeStatus=="failed", retryCount=0, nextRetryDate
    } else {
      var promise = new PromiseA();
      promise.resolve();
      return promise;
    }
  },
  getStaffAugService: function (recordId) {
    return dL
      .getQuery("StaffAugService")
      .include("user")
      .include("assignedTo")
      .include("createdBy")
      .include("projectManager")
      .include("businessRole")
      .include("chargeAccount")
      .get(recordId)
      .then(function (obj) {
        return dL.loadStaffAugService(obj);
      });
  },
  getChargesAndPayments: function ({ accountId }) {
    const promises = [];
    var payments, charges;
    promises[promises.length] = db
      .getQuery("AccountPayment")
      .equalTo("account", dL.getObj("Account", accountId))
      .containedIn("removed", [undefined, false])
      .include("createdBy")
      .descending("paymentDate")
      .find()
      .then(function (objs) {
        payments = dL.loadObjects("AccountPayment", objs);
      });

    promises[promises.length] = db
      .getQuery("AccountCharge")
      .equalTo("account", dL.getObj("Account", accountId))
      .containedIn("removed", [undefined, false])
      .include("createdBy")
      .descending("chargeDate")
      .find()
      .then(function (objs) {
        charges = dL.loadObjects("AccountCharge", objs);
      });

    return Promise.all(promises).then(function () {
      return { charges, payments };
    });
  },
  getStaffAugServiceFull: function (recordId) {
    var staffService, shares, tasks, times, charges, payments;

    return dL
      .getQuery("StaffAugService")
      .include("user")
      .include("assignedTo")
      .include("createdBy")
      .include("projectManager")
      .include("businessRole")
      .include("chargeAccount")
      .get(recordId)
      .then(function (obj) {
        staffService = dL.loadStaffAugService(obj);

        const { chargeAccount } = staffService;

        var promises = [];
        promises[promises.length] = db
          .getQuery("StaffAugShare")
          .equalTo("staffAugService", dL.getObj("StaffAugService", recordId))
          .containedIn("removed", [undefined, false])
          .include("assignedTo")
          .include("createdBy")
          .find()
          .then(function (objs) {
            shares = dL.loadObjects("StaffAugShare", objs);
          });

        promises[promises.length] = db
          .getQuery("TaskRecord")
          .equalTo("staffAugService", dL.getObj("StaffAugService", recordId))
          .containedIn("removed", [undefined, false])
          .include("assignedTo")
          .include("createdBy")
          .include("service")
          .include("servicePackage")
          .include("userService")
          .include("serviceOptions")
          .include("staffAugService")
          .include("staffAugService.businessRole")
          .include("staffAugShare")
          .include("staffAugShare.assignedTo")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            tasks = dL.loadObjects("TaskRecord", objs);
          });

        promises[promises.length] = db
          .getQuery("TimeRecord")
          .equalTo("staffAugService", dL.getObj("StaffAugService", recordId))
          .containedIn("removed", [undefined, false])
          .include("user")
          .include("task")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            times = dL.loadObjects("TimeRecord", objs);
          });

        if (chargeAccount) {
          promises[promises.length] = dL.getChargesAndPayments({ accountId: chargeAccount.id }).then(function (data) {
            charges = data.charges;
            payments = data.payments;
          });
        }

        return Promise.all(promises);
      })
      .then(function () {
        shares.forEach((share) => {
          share.subShares = shares.filter((share2) => share2.parentStaffAugShare && share2.parentStaffAugShare.id == share.id);
          share.tasks = tasks.filter((task) => task.staffAugShare && task.staffAugShare.id == share.id);
        });

        const rootShares = shares.filter((share) => !share.parentStaffAugShare);
        return { tasks, times, shares: rootShares, allShares: shares, charges, staffService, payments };
      });
  },
  getBusinessRoles: function (props) {
    const { searchText } = props ? props : {};

    var query = db.getQuery("BusinessRole").containedIn("removed", [undefined, false]);

    if (searchText) {
      query.startsWith("searchText", searchText);
    }
    return query
      .find()
      .then(function (objs) {
        return dL.loadObjects("BusinessRole", objs);
      })
      .catch(function (err) {
        alert("Error:" + err.message);
      });
  },
  getUserWorkerUser: function (userId) {
    return db
      .getQuery("User")
      .include("userRoles")
      .get(userId)
      .then(function (userObj) {
        return dL.loadUser(userObj);
      });
  },
  getUser: function (userId) {
    return db
      .getQuery("User")
      .get(userId)
      .then(function (userObj) {
        return dL.loadUser(userObj);
      });
  },
  getUserLogin: function (userId) {
    return db
      .getQuery("User")
      .include("defaultCompany")
      .include("userRoles")
      .include("businessRoles")
      .get(userId)
      .then(function (userObj) {
        const user = dL.loadUser(userObj);
        user.userRoles.forEach(item => {
          item.businessRole = user.businessRoles.find(item2 => item2.id == item.businessRole.id)
        })
        return user
      });
  },
  getUsers: function (props) {
    const { searchText, onWhere, includeRoles, businessRoleId } = props ? props : {};

    const query = db.getQuery("User")
      .containedIn("removed", [undefined, false]);

    //display in buyer directory
    //display in worker directory

    if (searchText) {
      query.startsWith("searchText", searchText);
    }
    if (onWhere) {
      onWhere(query);
    }
    if (businessRoleId) {
      query.equalToAO("businessRoles", dL.getObj("BusinessRole", businessRoleId));
    }
    if (includeRoles) {
      query.include("userRoles");
      query.include("businessRoles");
    }
    return query
      .limit(50)
      .find()
      .then(function (objs) {
        return dL.loadObjects("User", objs);
      })
      .catch(function (err) {
        alert("Error:" + err.message);
      });
  },
  getServices: function (props) {
    const { type, searchText, serviceType } = props ? props : {};
    var query = db.getQuery("Service")
      .equalTo("isBuyerPublic", true)
      .equalTo("status", "active")
      .containedIn("removed", [undefined, false]);
    if (searchText) {
      query.startsWith("name", searchText);
    }
    if (serviceType) {
      query.equalTo("serviceType", serviceType);
    }
    if (type == "recent") {
      query.descending("createdAt")
    } else if (type == "popular") {
      query.descending("popularCount")
    } else if (type == "updated") {
      query.descending("lastVersionUpdatedAt")
    } else {
      query.ascending("name")
    }
    return query
      .include("businessRole")
      .limit(100)
      .find()
      .then(function (objs) {
        return dL.loadObjects("Service", objs);
      })
      .catch(function (err) {
        alert("Error:" + err.message);
      });
  },
  getUserServices: function (props) {
    const { type, searchText, serviceType } = props ? props : {};
    var query = db.getQuery("UserService")
      .containedIn("removed", [undefined, false])
      .equalTo("isWorkerPublic", true)
      .equalTo("status", "active")
    if (searchText) {
      query.startsWith("name", searchText);
    }
    if (serviceType) {
      query.equalTo("serviceType", serviceType);
    }
    if (type == "recent") {
      query.descending("createdAt")
    } else if (type == "popular") {
      query.descending("popularCount")
    } else if (type == "updated") {
      query.descending("lastVersionUpdatedAt")
    } else {
      query.ascending("name")
    }
    return query
      .include("businessRole")
      .include("userRole")
      .limit(100)
      .find()
      .then(function (objs) {
        return dL.loadObjects("UserService", objs);
      })
      .catch(function (err) {
        alert("Error:" + err.message);
      });
  },
  getServiceOrder2: function (serviceOrderId) {
    var model;
    return dL
      .getQuery("ServiceOrder")
      .include("order")
      .include("assignedTo")
      .include("service")
      .include("servicePackageVersion")
      .include("serviceOptionVersions")
      .include("strategyConsultant")
      .include("projectManager")
      .include("serviceDeliverableVersions")
      .include("project")
      .include("lastServiceDelivery")
      .include("lastServiceDelivery.createdBy")
      .include("parentServiceOrder")
      .include("parentServiceOrder.service")
      .include("requiredTasks")
      .include("requiredServiceOrders")
      .get(serviceOrderId)
      .then(function (obj) {
        model = dL.loadServiceOrder(obj);

        return dL.getService2({ serviceId: model.service.id });
      })
      .then(function (service) {
        model.service = service;

        return dL.getServiceVersion2({ serviceVersionId: model.serviceVersion.id });
      })
      .then(function (serviceVersion) {
        model.serviceVersion = serviceVersion
        return model;
      });
  },
  getServiceOrderFull: function (serviceOrderId) {
    var model;
    var deliveries;
    var allTeam

    return dL.getServiceOrder2(serviceOrderId)
      .then(function (_model) {
        model = _model;

        return db.getQuery("ServiceOrder")
          .containedIn("removed", [undefined, false])
          .equalTo("parentServiceOrder", dL.getObj("ServiceOrder", serviceOrderId))
          .select([])
          .find();
      })
      .then(function (objs) {
        const serviceOrders = [];
        return Promise.all(
          objs.map((obj) => {
            return dL.getServiceOrder2(obj.id).then(function (model) {
              serviceOrders.push(model);
            });
          })
        ).then(function () {
          model.serviceOrders = serviceOrders;
        });
      })
      .then(function () {
        return db
          .getQuery("WorkRequest")
          .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId))
          .containedIn("removed", [undefined, false])
          .include("user")
          .find()
          .then(function (objs) {
            model.workRequests = dL.loadObjects("WorkRequest", objs);
          });
      })
      .then(function () {
        return db.getQuery("TaskRecord")
          .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            model.tasks = dL.loadObjects("TaskRecord", objs)
          })
      }).then(function () {
        return db
          .getQuery("ServiceOrder")
          .equalTo("parentSOIds", serviceOrderId)
          .existsObj("assignedTo", true)
          .containedIn("removed", [undefined, false])
          .include("assignedTo")
          .find()
          .then(function (objs) {
            const hash = new HashTable()
            objs.forEach(obj => {
              const userId = obj.get("assignedTo").id
              hash.set(userId, dL.loadUser(obj.get("assignedTo")))
            })
            allTeam = hash.values()
          });
      }).then(function () {
        return db.getQuery("ServiceDelivery").equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId)).containedIn("removed", [undefined, false]).include("createdBy").include("responseBy").include("serviceDeliverable").descending("createdAt").find();
      })
      .then(function (objs) {
        deliveries = dL.loadObjects("ServiceDelivery", objs);
        return { serviceOrder: model, deliveries, allTeam };
      });
  },
  getServicePackage: function (servicePackageId) {
    return dL
      .getQuery("ServicePackage")
      .get(servicePackageId)
      .then(function (obj) {
        return dL.loadServicePackage(obj);
      });
  },
  getServiceVersion4: function ({ serviceVersionId, circularIds }) {
    var serviceVersion
    return dL._getServiceVersion({ serviceVersionId }).then(function (_serviceVersion) {
      serviceVersion = _serviceVersion

      const { userService, service } = serviceVersion

      const promises = []

      if (userService) {
        promises[promises.length] = dL.getUserService2({ userServiceId: userService.id, circularIds }).then(function (userService) {
          serviceVersion.userService = userService;
        });
      }

      promises[promises.length] = dL.getService2({ serviceId: service.id, circularIds }).then(function (service) {
        serviceVersion.service = service;
      });

      const { subServices } = serviceVersion;
      promises[promises.length] = dL._getSubServices({ subServices, circularIds })
      return Promise.all(promises)
    }).then(function () {
      return serviceVersion
    })
  },
  getServiceVersion2: function ({ serviceVersionId, circularIds }) {
    var serviceVersion
    return dL._getServiceVersion({ serviceVersionId }).then(function (_serviceVersion) {
      serviceVersion = _serviceVersion

      const promises = []
      const { subServices } = serviceVersion;
      promises[promises.length] = dL._getSubServices({ subServices, circularIds })
      return Promise.all(promises)
    }).then(function () {
      return serviceVersion
    })
  },
  getServiceVersion: function ({ serviceVersionId, circularIds, disableSub }) {
    var serviceVersion
    return dL.getQuery("ServiceVersion")
      .include("team")
      .include("tags")
      .include("deliverables")
      .include("packages")
      .include("options")
      .include("subServices")
      .include("inputs")
      .include("tasks")
      .include("businessRole")
      .include("userRole")
      .include("primaryCategory")
      .include("secondaryCategory")
      .include("masterOverrides")
      .get(serviceVersionId)
      .then(function (obj) {
        serviceVersion = dL.loadServiceVersion(obj);
        if (!disableSub) {
          const { subServices } = serviceVersion;
          return dL._getSubServices({ subServices, circularIds })
        }
      }).then(function () {
        return serviceVersion
      })
  },
  _loadWorkerUserServices: function (workerUserServices) {
    return Promise.all(workerUserServices.map(({ userServiceId, userServiceVersionId }, index) => {
      return dL.getUserService2({ userServiceId }).then(function (userService) {
        return dL.getServiceVersion2({ serviceVersionId: userServiceVersionId }).then(function (userServiceVersion) {
          workerUserServices[index] = {
            userService,
            userServiceVersion
          }
        })
      })
    }))
  },
  getSubServiceVersion: function ({ subServiceVersionId }) {
    return dL.getQuery("ServiceSubServiceVersion")
      .include("assignedTo")
      .include("userService")
      .include("userService.user")
      .include("userService.userRole")
      .get(subServiceVersionId).then(function (obj) {
        const subService = dL.loadServiceSubServiceVersion(obj)

        const { service, servicePackage, serviceVersion, userService, userServiceVersion, workerUserServices, actualServiceVersion } = subService;

        const promises = []
        if (workerUserServices) {
          promises[promises.length] = dL._loadWorkerUserServices(workerUserServices)
        }

        promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: actualServiceVersion.id, disableSub: true }).then(function (serviceVersion) {
          subService.actualServiceVersion = serviceVersion;
        });

        promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: serviceVersion.id, disableSub: true }).then(function (serviceVersion) {
          subService.serviceVersion = serviceVersion;
        });

        if (userService) {
          promises[promises.length] = dL.getUserService2({ userServiceId: userService.id, disableSub: true }).then(function (userService) {
            subService.userService = userService;
          });

          promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userServiceVersion.id, disableSub: true }).then(function (serviceVersion) {
            subService.userServiceVersion = serviceVersion;
          });
        }

        promises[promises.length] = dL.getService2({ serviceId: service.id }).then(function (service) {
          subService.service = service;

          if (servicePackage) {
            subService.servicePackage = service.packages.find((item) => item.package.id == servicePackage.id);
          }
        });
        return Promise.all(promises).then(function () {
          return subService
        })
      })
  },
  _getSubServices: function ({ subServices, circularIds }) {
    return Promise.all(
      subServices.map((subService) => {
        //.include("assignedTo")

        const { packageId, userService, service, servicePackage, serviceVersion, userServiceVersion, workerUserServices, actualServiceVersion } = subService;

        const promises = []
        if (workerUserServices) {
          promises[promises.length] = dL._loadWorkerUserServices(workerUserServices)
        }

        promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: actualServiceVersion.id }).then(function (serviceVersion) {
          subService.actualServiceVersion = serviceVersion;
        });

        promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: serviceVersion.id }).then(function (serviceVersion) {
          subService.serviceVersion = serviceVersion;
        });

        if (userServiceVersion) {
          promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: userServiceVersion.id }).then(function (serviceVersion) {
            subService.userServiceVersion = serviceVersion;
          });
        }

        if (userService) {
          promises[promises.length] = dL.getUserService2({ userServiceId: userService.id, circularIds }).then(function (userService) {
            subService.userService = userService;
          });
        }

        promises[promises.length] = dL.getService2({ serviceId: service.id, circularIds }).then(function (service) {
          subService.service = service;
        });

        return Promise.all(promises).then(function () {
          if (servicePackage) {
            subService.servicePackage = subService.actualServiceVersion.packages.find((item) => item.package.id == packageId);
          }
        })
      })
    );
  },
  saveServiceWorkerServices: function ({ service }) {
    const { workerUserServices } = service
    return dL.getObj("Service", service.id)
      .set("workerUserServices", workerUserServices ? workerUserServices.map((item) => {
        const { userService, userServiceVersion } = item
        return { userServiceId: userService.id, userServiceVersionId: userServiceVersion.id }
      }) : null)
  },
  getServiceVersionHistory: function ({ serviceId, userServiceId }) {
    const query = dL.getQuery("ServiceVersion")
    if (userServiceId) {
      query.equalTo("userService", dL.getObj("UserService", userServiceId))
    } else {
      query.existsObj("userService", false)
    }
    return query
      .equalTo("service", dL.getObj("Service", serviceId))
      .containedIn("removed", [undefined, false])
      .include("createdBy")
      .descending("createdAt")
      .find()
      .then(function (objs) {
        return dL.loadObjects("ServiceVersion", objs);
      });
  },
  getForkedUserServiceForService: function ({ serviceId }) {
    return dL.getQuery("UserService")
      .equalTo("service", dL.getObj("Service", serviceId))
      .containedIn("removed", [undefined, false])
      .include("user")
      .include("currentVersion")
      .include("forkedFromServiceVersion")
      .descending("createdAt")
      .find()
      .then(function (objs) {
        return dL.loadObjects("UserService", objs);
      })
  },
  /*
  getUserService3: function ({ userServiceId }) {
    var userService;
    return dL.getQuery("UserService")
      .include("user")
      .include("team")
      .include("tags")
      .include("deliverables")
      .include("packages")
      .include("options")
      .include("subServices")
      .include("inputs")
      .include("tasks")
      .include("businessRole")
      .include("userRole")
      .include("primaryCategory")
      .include("secondaryCategory")
      .get(userServiceId)
      .then(function (obj) {
        userService = dL.loadUserService(obj);
        return userService;
      });
  },
  */
  /*
  getService3: function ({ serviceId }) {
    var service;
    return dL.getQuery("Service")
      .include("createdBy")
      .include("team")
      .include("tags")
      .include("deliverables")
      .include("packages")
      .include("options")
      .include("subServices")
      .include("inputs")
      .include("tasks")
      .include("businessRole")
      .include("primaryCategory")
      .include("secondaryCategory")
      .get(serviceId)
      .then(function (obj) {
        service = dL.loadService(obj);
        return service;
      });
  },
  */
  getService2: function ({ serviceId, circularIds }) {
    var service;
    return dL._getService({ serviceId }).then(function (_service) {
      service = _service

      const promises = [];
      const { workerUserServices, currentVersion, draftVersion } = service;

      if (workerUserServices) {
        promises[promises.length] = dL._loadWorkerUserServices(workerUserServices)
      }

      promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: draftVersion.id, circularIds }).then(function (serviceVersion) {
        service.draftVersion = serviceVersion
      });

      if (currentVersion) {
        promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: currentVersion.id, circularIds }).then(function (serviceVersion) {
          service.currentVersion = serviceVersion
        });
      }
      return Promise.all(promises);
    }).then(function () {
      return service;
    });
  },
  getService: function (serviceId, full, index, circularIds) {
    if (full) {
      return dL.getService2({ serviceId })
    }

    if (!circularIds) { circularIds = {} } else {
      if (circularIds[serviceId]) {
        console.log(circularIds)
        throw "Circular references error:" + serviceId
        return
      }
    }
    circularIds[serviceId] = true

    if (full) {
      if (index == null) {
        index = 1;
      }
    }
    var service;
    var query = dL.getQuery("Service")
      .include("createdBy")
      .include("team")
      .include("tags")
      .include("draftVersion")
      .include("currentVersion")
      .include("businessRole")
      .include("primaryCategory")
      .include("secondaryCategory");

    if (full) {
      query.include("deliverables")
        .include("packages")
        .include("options")
        .include("subServices")
        .include("inputs")
        .include("tasks")
    }
    return query
      .get(serviceId)
      .then(function (obj) {
        return dL.loadService(obj);
      })
      .then(function (_service) {
        service = _service;
        service._isFull = full;

        if (full) {
          const promises = [];
          const { workerUserServices, currentVersion, draftVersion } = service;

          if (workerUserServices) {
            promises[promises.length] = dL._loadWorkerUserServices(workerUserServices)
          }

          promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: draftVersion.id, circularIds })
            .then(function (serviceVersion) {
              service.draftVersion = serviceVersion
            });
          if (currentVersion) {
            promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: currentVersion.id, circularIds })
              .then(function (serviceVersion) {
                service.currentVersion = serviceVersion
              });
          }
          return Promise.all(promises);
        }
      })
      .then(function () {
        return service;
      });
  },
  loadBonus: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      relatedIds: obj.get("relatedIds"),
      totalAmount: obj.get("totalAmount"),
      amountPerUser: obj.get("amountPerUser"),
      issueDate: getDate(obj.get("issueDate")),
      description: obj.get("description"),
      bonusType: obj.get("bonusType"),
      items: obj.get("items"),
    };

    var obj2 = obj.get("users");
    if (obj2) {
      rtn.users = dL.loadObjects("User", obj2);
    }

    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }

    return rtn;
  },
  loadBonusItem: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      relatedIds: obj.get("relatedIds"),
      amount: obj.get("amount"),
      createdAt: getDate(obj.get("createdAt")),
      issueDate: getDate(obj.get("issueDate")),
      vestingDate: getDate(obj.get("vestingDate")),
      description: obj.get("description"),
      bonusType: obj.get("bonusType"),
      withdrawn: obj.get("withdrawn"),
      withdrawalDate: getDate(obj.get("withdrawalDate")),
      testMode: obj.get("testMode"),
    };

    var obj2 = obj.get("bonus");
    if (obj2) {
      rtn.bonus = dL.loadBonus(obj2);
    }

    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }

    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }

    return rtn;
  },
  loadOrderHistory: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      type: obj.get("type"),
      createdAt: getDate(obj.get("createdAt")),
      description: obj.get("description"),
    };

    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }

    var obj2 = obj.get("order");
    if (obj2) {
      rtn.order = dL.loadOrder(obj2);
    }

    var obj2 = obj.get("serviceOrder");
    if (obj2) {
      rtn.serviceOrder = dL.loadServiceOrder(obj2);
    }

    return rtn;
  },
  loadOrder: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,

      monthlyPayments: obj.get("monthlyPayments"),
      contractTotal: obj.get("contractTotal"),
      paymentTermType: obj.get("paymentTermType"),
      totalMonths: obj.get("totalMonths"),

      nextPaymentDate: getDate(obj.get("nextPaymentDate")),
      nextPaymentAmount: obj.get("nextPaymentAmount"),

      orderNumber: obj.get("orderNumber"),
      orderDate: getDate(obj.get("orderDate")),
      total: obj.get("total"),
      status: obj.get("status"),
      serviceTypes: obj.get("serviceTypes"),
      email: obj.get("email"),
      totalServiceOrders: obj.get("totalServiceOrders")
    };

    loadObj(rtn, obj, "Account", "chargeAccount");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    var obj2 = obj.get("serviceOrders");
    if (obj2) {
      rtn.serviceOrders = dL.loadObjects("ServiceOrder", obj2);
    }

    var obj2 = obj.get("strategyConsultant");
    if (obj2) {
      rtn.strategyConsultant = dL.loadUser(obj2);
    }

    var obj2 = obj.get("projectManager");
    if (obj2) {
      rtn.projectManager = dL.loadUser(obj2);
    }

    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }

    var obj2 = obj.get("payment");
    if (obj2) {
      rtn.payment = dL.loadPayment(obj2);
    }
    var obj2 = obj.get("charge");
    if (obj2) {
      rtn.charge = dL.loadCharge(obj2);
    }
    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }
    loadObj(rtn, obj, "ServiceProposal", "proposal");
    return rtn;
  },
  loadServiceVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      versionNum: obj.get("versionNum"),
      enableServiceOptions: obj.get("enableServiceOptions"),
      enablePackages: obj.get("enablePackages"),
      status: obj.get("status"),

      lastSavedAt: getDate(obj.get("lastSavedAt")),
      priceMap: obj.get("priceMap"),

      tasksSeq: obj.get("tasksSeq"),
      packagesSeq: obj.get("packagesSeq"),
      inputsSeq: obj.get("inputsSeq"),
      subServicesSeq: obj.get("subServicesSeq"),
      deliverablesSeq: obj.get("deliverablesSeq"),
      optionsSeq: obj.get("optionsSeq"),


      name: obj.get("name"),
      icon: obj.get("icon"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      serviceType: obj.get("serviceType"),
      companyStages: obj.get("companyStages"),
      serviceCore: obj.get("serviceCore"),
      minPrice: obj.get("minPrice"),
      maxPrice: obj.get("maxPrice"),
      actualPrice: obj.get("actualPrice"),
      actualDeliveryDays: obj.get("actualDeliveryDays"),
      workHours: obj.get("workHours"),
      minDeliveryDays: obj.get("minDeliveryDays"),
      enablePackages: obj.get("enablePackages"),
      createdAt: getDate(obj.get("createdAt")),
      price: obj.get("price"),
      deliveryDays: obj.get("deliveryDays"),
      hasExtraFastDelivery: obj.get("hasExtraFastDelivery"),
      extraFastDeliveryDays: obj.get("extraFastDeliveryDays"),
      extraFastPrice: obj.get("extraFastPrice"),
      fastDeliveryPercent: obj.get("fastDeliveryPercent"),
      allowedRevisions: obj.get("allowedRevisions"),
      skillLevel: obj.get("skillLevel"),
      enableServiceOptions: obj.get("enableServiceOptions"),
      isFixedPriced: obj.get("isFixedPriced"),
    };

    if (!rtn.priceMap) {
      rtn.priceMap = []
    }

    rtn.enableServiceOptions = true

    var obj2 = obj.get("businessInitiatives");
    if (obj2) {
      rtn.businessInitiatives = dL.loadObjects("BusinessInitiative", obj2);
    }

    var obj2 = obj.get("masterOverrides");
    if (obj2) {
      rtn.masterOverrides = dL.loadObjects("MasterOverrideRecord", obj2);
    } else {
      rtn.masterOverrides = []
    }

    var obj2 = obj.get("tags");
    if (obj2) {
      rtn.tags = dL.loadObjects("Tag", obj2);
    }

    var obj2 = obj.get("tasks");
    if (obj2) {
      rtn.tasks = utils.readSeq(dL.loadObjects("ServiceTaskVersion", obj2), obj.get("tasksSeq"));
    }

    var obj2 = obj.get("subServices");
    if (obj2) {
      rtn.subServices = utils.readSeq(dL.loadObjects("ServiceSubServiceVersion", obj2), obj.get("subServicesSeq"));
    }

    var obj2 = obj.get("inputs");
    if (obj2) {
      rtn.inputs = utils.readSeq(dL.loadObjects("ServiceInputVersion", obj2), obj.get("inputsSeq"));
    }

    var obj2 = obj.get("deliverables");
    if (obj2) {
      rtn.deliverables = utils.readSeq(dL.loadObjects("ServiceDeliverableVersion", obj2), obj.get("deliverablesSeq"));
    }

    var obj2 = obj.get("options");
    if (obj2) {
      rtn.options = utils.readSeq(dL.loadObjects("ServiceOptionVersion", obj2), obj.get("optionsSeq"));
      rtn.serviceOptions = rtn.options;
    }

    var obj2 = obj.get("packages");
    if (obj2) {
      rtn.packages = utils.readSeq(dL.loadObjects("ServicePackageVersion", obj2), obj.get("packagesSeq"));
    }

    if (!rtn.deliverables) {
      rtn.deliverables = [];
    }
    if (!rtn.options) {
      rtn.options = [];
      rtn.serviceOptions = rtn.options
    }
    if (!rtn.inputs) {
      rtn.inputs = [];
    }
    if (!rtn.packages) {
      rtn.packages = [];
    }
    if (!rtn.tasks) {
      rtn.tasks = [];
    }
    if (!rtn.subServices) {
      rtn.subServices = [];
    }
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "ServiceCategory", "primaryCategory");
    loadObj(rtn, obj, "ServiceCategory", "secondaryCategory");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "UserRole", "userRole");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "User", "createdBy");
    loadJSON(rtn, obj, "overrides", {});
    return rtn;
  },
  loadTrainer: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      noteCount: obj.get("noteCount"),
      lastNoteUpdatedAt: getDate(obj.get("lastNoteUpdatedAt")),
      createdAt: getDate(obj.get("createdAt"))
    };

    var obj2 = obj.get("userServices");
    if (Array.isArray(obj2)) {
      rtn.userServices = dL.loadObjects("UserService", obj2);
    }

    loadObj(rtn, obj, "User", "user");
    loadObj(rtn, obj, "Service", "service");
    return rtn;
  },
  loadTrainingNote: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    //referenceType
    //referenceId
    //referenceVersionId

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      status: obj.get("status"),
      shortDescription: obj.get("shortDescription"),
      noteType: obj.get("noteType"),
      createdAt: getDate(obj.get("createdAt"))
    };

    var obj2 = obj.get("taskVersion");
    if (obj2) {
      rtn.task = dL.loadServiceTaskVersion(obj2);
    }
    var obj2 = obj.get("deliverableVersion");
    if (obj2) {
      rtn.deliverable = dL.loadServiceDeliverableVersion(obj2);
    }

    loadJSON(rtn, obj, "content");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadInvitation: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      email: obj.get("email")
    };

    loadObj(rtn, obj, "User", "user");
    return rtn;
  },
  loadTag: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      name: obj.get("name")
    };

    return rtn;
  },
  loadServiceProposalVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      sentAt: getDate(obj.get("sentAt")),
      expireDate: getDate(obj.get("expireDate")),
      versionNum: obj.get("versionNum"),
      status: obj.get("status"),
      total: obj.get("total"),
      deliveryDays: obj.get("deliveryDays"),

      priceMap: obj.get("priceMap")
    };

    if (!rtn.priceMap) {
      rtn.priceMap = []
    }
    rtn.priceMap = rtn.priceMap.filter(item => item != null)

    if (!rtn.overrides) {
      rtn.overrides = {}
    }

    var obj2 = obj.get("masterOverrides");
    if (obj2) {
      rtn.masterOverrides = dL.loadObjects("MasterOverrideRecord", obj2);
    } else {
      rtn.masterOverrides = []
    }

    loadJSON(rtn, obj, "packages", []);
    loadJSON(rtn, obj, "options", []);

    loadObj(rtn, obj, "ServiceProposal", "proposal");
    loadObj(rtn, obj, "User", "sentBy");
    loadObj(rtn, obj, "User", "createdBy");
    loadJSON(rtn, obj, "items", []);
    loadJSON(rtn, obj, "overrides", {});

    rtn.items = rtn.items.filter(item => item != null)
    return rtn;
  },
  loadCartItem: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      packageId: obj.get("packageId"),
      startDayIndex: obj.get("startDayIndex"),
      doneDayIndex: obj.get("doneDayIndex"),
      itemType: obj.get("itemType"),

      monthlyTotal: obj.get("monthlyTotal"),
      contractTotal: obj.get("contractTotal"),

      quantity: obj.get("quantity"),
      deliveryDays: obj.get("deliveryDays"),
      unitPrice: obj.get("unitPrice"),
      total: obj.get("total"),

      deliveryType: obj.get("deliveryType"),
      assignedToUser: obj.get("assignedToUser"),
      delayOptions: obj.get("delayOptions"),
      serviceOptionIds: obj.get("serviceOptionIds"),
      actualVersionType: obj.get("actualVersionType"),

      monthlyCost: obj.get("monthlyCost"),
      workerSelectionType: obj.get("workerSelectionType"),
      hoursPerMonth: obj.get("hoursPerMonth"),
      staffServiceType: obj.get("staffServiceType"),
      startDate: getDate(obj.get("startDate")),
      hasStartDate: obj.get("hasStartDate"),
      numberOfMonths: obj.get("numberOfMonths"),
      priorityLevel: obj.get("priorityLevel"),
      skillLevel: obj.get("skillLevel"),
      allowPullForward: obj.get("allowPullForward"),
      allowRollover: obj.get("allowRollover"),

      priceMap: obj.get("priceMap"),
    };

    var obj2 = obj.get("workerUsers");
    if (Array.isArray(obj2)) {
      rtn.workerUsers = dL.loadObjects("User", obj2);
    }

    var obj2 = obj.get("serviceOptions");
    if (obj2) {
      rtn.serviceOptions = dL.loadObjects("ServiceOption", obj2);
    }

    loadObj(rtn, obj, "ServiceVersion", "actualServiceVersion");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "ServicePackage", "servicePackage");
    loadObj(rtn, obj, "UserRole", "userRole");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "ServiceProposal", "proposal");
    loadObj(rtn, obj, "ServiceProposalVersion", "proposalVersion");
    loadObj(rtn, obj, "ServiceProposalItem", "proposalItem");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadServiceProposalItem: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      isBuyerSide: obj.get("isBuyerSide"),
      inCart: obj.get("inCart"),
      packageId: obj.get("packageId"),
      startDayIndex: obj.get("startDayIndex"),
      doneDayIndex: obj.get("doneDayIndex"),
      itemType: obj.get("itemType"),
      name: obj.get("name"),
      icon: obj.get("icon"),
      description: obj.get("description"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),
      quantity: obj.get("quantity"),
      deliveryDays: obj.get("deliveryDays"),
      unitPrice: obj.get("unitPrice"),
      total: obj.get("total"),
      requiredPreServices: obj.get("requiredPreServices"),

      priceMap: obj.get("priceMap"),
      overridePrice: obj.get("overridePrice"),

      recommended: obj.get("recommended"),
      deliveryType: obj.get("deliveryType"),
      assignedToUser: obj.get("assignedToUser"),
      delayOptions: obj.get("delayOptions"),
      serviceOptionIds: obj.get("serviceOptionIds"),
      actualVersionType: obj.get("actualVersionType"),

      monthlyCost: obj.get("monthlyCost"),
      workerSelectionType: obj.get("workerSelectionType"),
      hoursPerMonth: obj.get("hoursPerMonth"),
      staffServiceType: obj.get("staffServiceType"),
      startDate: getDate(obj.get("startDate")),
      hasStartDate: obj.get("hasStartDate"),
      numberOfMonths: obj.get("numberOfMonths"),
      priorityLevel: obj.get("priorityLevel"),
      skillLevel: obj.get("skillLevel"),
      allowPullForward: obj.get("allowPullForward"),
      allowRollover: obj.get("allowRollover")
    };

    var obj2 = obj.get("workerUsers");
    if (Array.isArray(obj2)) {
      rtn.workerUsers = dL.loadObjects("User", obj2);
    }

    var obj2 = obj.get("serviceOptions");
    if (obj2) {
      rtn.serviceOptions = dL.loadObjects("ServiceOption", obj2);
    }

    //stage item: {limitedToOptions, limitedToPackages, consolidatedView, name, strategyConsultant, description}

    //stage: {limitedToOptions, limitedToPackages, name, description, projectManager}

    //service: {serviceOptionIds, deliveryType, servicePackageId, service, servicePackage, userService, unitPrice, quantity, total}

    //staff: {staffServiceType, businessRole, userRole, workerUsers,  unitPrice, quantity, total}, {startDate,  staffServiceType, hasStartDate, assignedToUser, userRole, skillLevel, priorityLevel, allowPullForward, allowRollover, user, businessRole, hoursPerMonth, assignedTo, numberOfMonths}

    loadObj(rtn, obj, "ServiceProposalItem", "parentProposalItem");
    loadObj(rtn, obj, "User", "recommendedUser");
    loadObj(rtn, obj, "ServiceProposalVersion", "removedOnProposalVersion");
    loadObj(rtn, obj, "User", "projectManager");
    loadObj(rtn, obj, "User", "strategyConsultant");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "actualServiceVersion");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    loadObj(rtn, obj, "ServicePackage", "servicePackage");
    loadObj(rtn, obj, "UserRole", "userRole");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "ServiceVersion", "userServiceVersion");
    loadObj(rtn, obj, "ServiceProposal", "proposal");
    loadObj(rtn, obj, "ServiceProposalVersion", "proposalVersion");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadServiceProposal: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      isBuyerSide: obj.get("isBuyerSide"),
      profitMargin: obj.get("profitMargin"),
      marginAdder: obj.get("marginAdder"),
      profitMarginLevel: obj.get("profitMarginLevel"),
      fluxDelivery: obj.get("fluxDelivery"),
      fluxScope: obj.get("fluxScope"),
      isNew: obj.get("isNew"),
      isTemplate: obj.get("isTemplate"),
      templateName: obj.get("templateName"),
      templateId: obj.get("templateId"),
      proposalType: obj.get("proposalType"),
      serviceTypes: obj.get("serviceTypes"),
      proposalDate: getDate(obj.get("proposalDate")),
      proposalNumber: obj.get("proposalNumber"),
      additionalNotes: obj.get("additionalNotes"),
      title: obj.get("title"),
      objectives: obj.get("objectives"),
      description: obj.get("description"),
      files: obj.get("files"),
      createdAt: getDate(obj.get("createdAt")),
      sentAt: getDate(obj.get("sentAt")),
      status: obj.get("status"),
      total: obj.get("total"),
      deliveryDays: obj.get("deliveryDays"),
      accessUsers: obj.get("accessUsers"),

      expireDate: getDate(obj.get("expireDate")),
      withdrawnAt: getDate(obj.get("withdrawnAt")),

      buyerPriceMap: obj.get("buyerPriceMap"),
      buyerItemsInCart: obj.get("buyerItemsInCart"),
      buyerCartTotal: obj.get("buyerCartTotal"),
      buyerItemsForFuture: obj.get("buyerItemsForFuture"),
      buyerFutureTotal: obj.get("buyerFutureTotal"),
      buyerSharedWith: obj.get("buyerSharedWith"),
      staffSharedWith: obj.get("staffSharedWith")
    };

    if (!rtn.buyerSharedWith) {
      rtn.buyerSharedWith = []
    }
    if (!rtn.staffSharedWith) {
      rtn.staffSharedWith = []
    }
    var obj2 = obj.get("buyerMasterOverrides");
    if (obj2) {
      rtn.buyerMasterOverrides = dL.loadObjects("MasterOverrideRecord", obj2);
    } else {
      rtn.buyerMasterOverrides = []
    }

    loadJSON(rtn, obj, "packages", []);
    loadJSON(rtn, obj, "options", []);

    var obj2 = obj.get("userSelected");
    if (obj2) {
      rtn.userSelected = utils.jsonParse(obj2);
    }

    var obj2 = obj.get("buyerProposalItems");
    if (obj2) {
      rtn.buyerProposalItems = dL.loadObjects("ServiceProposalItem", obj2);
    }

    var obj2 = obj.get("serviceItems");
    if (obj2) {
      rtn.serviceItems = utils.jsonParse(obj2);
    }

    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "ServiceProposalVersion", "currentProposalVersion");
    loadObj(rtn, obj, "ServiceProposalVersion", "lastSentProposalVersion");

    var obj2 = obj.get("service");
    if (obj2) {
      rtn.service = dL.loadService(obj2);
    }
    var obj2 = obj.get("serviceRequest");
    if (obj2) {
      rtn.serviceRequest = dL.loadServiceRequest(obj2);
    }
    var obj2 = obj.get("projectManager");
    if (obj2) {
      rtn.projectManager = dL.loadUser(obj2);
    }
    var obj2 = obj.get("services");
    if (obj2) {
      rtn.services = dL.loadObjects("Service", obj2);
    }
    var obj2 = obj.get("businessDevelopment");
    if (obj2) {
      rtn.businessDevelopment = dL.loadUser(obj2);
    }
    var obj2 = obj.get("strategyConsultant");
    if (obj2) {
      rtn.strategyConsultant = dL.loadUser(obj2);
    }
    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }
    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }
    return rtn;
  },
  loadServiceRequest: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      hasRFP: obj.get("hasRFP"),
      requestNumber: obj.get("requestNumber"),
      title: obj.get("title"),
      deliverables: obj.get("deliverables"),
      requestType: obj.get("requestType"),
      serviceType: obj.get("serviceType"),
      files: obj.get("files"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      industryType: obj.get("industryType"),
      businessType: obj.get("businessType"),
      companyStage: obj.get("companyStage"),
      companySize: obj.get("companySize"),
      budget: obj.get("budget"),
      urgency: obj.get("urgency"),
      createdAt: getDate(obj.get("createdAt")),
      status: obj.get("status"),

      expireDate: getDate(obj.get("expireDate")),
      requestDate: getDate(obj.get("requestDate")),
      lastProposalAt: getDate(obj.get("lastProposalAt")),

      proposalsWithdrawn: obj.get("proposalsWithdrawn"),
      proposals: obj.get("proposals"),
      proposalCount: obj.get("proposalCount"),
    };

    var obj2 = obj.get("serviceOrders");
    if (obj2) {
      rtn.serviceOrders = dL.loadObjects("ServiceOrder", obj2);
    }
    var obj2 = obj.get("services");
    if (obj2) {
      rtn.services = dL.loadObjects("Service", obj2);
    }
    var obj2 = obj.get("project");
    if (obj2) {
      rtn.project = dL.loadProject(obj2);
    }
    var obj2 = obj.get("businessDevelopment");
    if (obj2) {
      rtn.businessDevelopment = dL.loadUser(obj2);
    }
    var obj2 = obj.get("strategyConsultant");
    if (obj2) {
      rtn.strategyConsultant = dL.loadUser(obj2);
    }
    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }
    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }

    var obj2 = obj.get("childServiceRequests");
    if (obj2) {
      rtn.childServiceRequests = dL.loadObjects("parentServiceRequest", obj2);
    }
    loadObj(rtn, obj, "ServiceRequest", "parentServiceRequest");
    return rtn;
  },
  loadWorkRequest: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      prefExpiresAt: getDate(obj.get("prefExpiresAt")),
      visibleAt: getDate(obj.get("visibleAt")),
      serviceType: obj.get("serviceType"),
      comments: obj.get("comments"),
      createdAt: getDate(obj.get("createdAt")),
      status: obj.get("status"),
    };

    var obj2 = obj.get("order");
    if (obj2) {
      rtn.order = dL.loadOrder(obj2);
    }

    var obj2 = obj.get("serviceOrder");
    if (obj2) {
      rtn.serviceOrder = dL.loadServiceOrder(obj2);
    }

    var obj2 = obj.get("service");
    if (obj2) {
      rtn.service = dL.loadService(obj2);
    }

    var obj2 = obj.get("servicePackage");
    if (obj2) {
      rtn.servicePackage = dL.loadServicePackage(obj2);
    }

    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }

    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }
    return rtn;
  },
  getUserWorker: function ({ userId }) {
    var model;
    return dL
      .getQuery("User")
      .include("userRoles")
      .get(userId)
      .then(function (obj) {
        model = dL.loadUser(obj);

        return Promise.all(
          model.userRoles.map((item) => {
            return db
              .getQuery("BusinessRole")
              .containedIn("removed", [undefined, false])
              .get(item.businessRole.id)
              .then(function (obj) {
                item.businessRole = dL.loadBusinessRole(obj);
              });
          })
        );
      })
      .then(function () {
        return dL
          .getQuery("Member")
          .equalTo("type", "team")
          .equalTo("user", dL.getObj("User", userId))
          .containedIn("removed", [undefined, false])
          .include("team")
          .include("team.teamLeader")
          .find()
          .then(function (objs) {
            model.teamMembers = dL.loadObjects("Member", objs)
          });
      })
      .then(function () {
        return dL
          .getQuery("Service")
          .equalTo("createdBy", dL.getObj("User", userId))
          .containedIn("removed", [undefined, false])
          .find()
          .then(function (objs) {
            model.customServices = dL.loadObjects("Service", objs);
          });
      })
      .then(function () {
        return model;
      });
  },
  loadUserRole: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      role: obj.get("role"),
      businessSector: obj.get("businessSector"),
      createdAt: getDate(obj.get("createdAt")),
      hourlyRate: obj.get("hourlyRate"),
      skillLevel: obj.get("skillLevel"),
      yearsOfExp: obj.get("yearsOfExp"),
    };

    var obj2 = obj.get("businessRole");
    if (obj2) {
      rtn.businessRole = dL.loadBusinessRole(obj2);
    }

    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }

    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }

    return rtn;
  },
  _loadServiceDetails: function (rtn, obj) {
    var obj2 = obj.get("tags");
    if (obj2) {
      rtn.tags = dL.loadObjects("Tag", obj2);
    }

    var obj2 = obj.get("tasks");
    if (obj2) {
      rtn.tasks = utils.readSeq(dL.loadObjects("ServiceTaskVersion", obj2), obj.get("tasksSeq"));
    }

    var obj2 = obj.get("subServices");
    if (obj2) {
      rtn.subServices = utils.readSeq(dL.loadObjects("ServiceSubServiceVersion", obj2), obj.get("subServicesSeq"));
    }

    var obj2 = obj.get("inputs");
    if (obj2) {
      rtn.inputs = utils.readSeq(dL.loadObjects("ServiceInputVersion", obj2), obj.get("inputsSeq"));
    }

    var obj2 = obj.get("deliverables");
    if (obj2) {
      rtn.deliverables = utils.readSeq(dL.loadObjects("ServiceDeliverableVersion", obj2), obj.get("deliverablesSeq"));
    }

    var obj2 = obj.get("options");
    if (obj2) {
      rtn.options = utils.readSeq(dL.loadObjects("ServiceOptionVersion", obj2), obj.get("optionsSeq"));
      rtn.serviceOptions = rtn.options;
    }

    var obj2 = obj.get("packages");
    if (obj2) {
      rtn.packages = utils.readSeq(dL.loadObjects("ServicePackageVersion", obj2), obj.get("packagesSeq"));
    }

    rtn.workerUserServices = obj.get("workerUserServices")

    if (!rtn.workerUsers) {
      rtn.workerUsers = [];
    }
    if (!rtn.deliverables) {
      rtn.deliverables = [];
    }
    if (!rtn.options) {
      rtn.options = [];
      rtn.serviceOptions = rtn.options
    }
    if (!rtn.inputs) {
      rtn.inputs = [];
    }
    if (!rtn.packages) {
      rtn.packages = [];
    }
    if (!rtn.tasks) {
      rtn.tasks = [];
    }
    if (!rtn.subServices) {
      rtn.subServices = [];
    }

    loadObj(rtn, obj, "ServiceCategory", "primaryCategory");
    loadObj(rtn, obj, "ServiceCategory", "secondaryCategory");
  },
  loadUserService: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      isWorkerPublic: obj.get("isWorkerPublic"),
      status: obj.get("status"),
      overrides: obj.get("overrides"),
      makeServiceSellable: obj.get("makeServiceSellable"),
      rateType: obj.get("rateType"),
      createdAt: getDate(obj.get("createdAt")),
      needsReview: obj.get("needsReview"),
      lastReviewedAt: getDate(obj.get("lastReviewedAt")),
      lastReviewedVersionNum: obj.get("lastReviewedVersionNum"),
      lastVersionUpdatedAt: getDate(obj.get("lastVersionUpdatedAt")),

      name: obj.get("name"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      icon: obj.get("icon"),
      serviceType: obj.get("serviceType"),
      companyStages: obj.get("companyStages"),
      serviceCore: obj.get("serviceCore"),
      enablePackages: obj.get("enablePackages"),
      price: obj.get("price"),
      deliveryDays: obj.get("deliveryDays"),
      hasExtraFastDelivery: obj.get("hasExtraFastDelivery"),
      extraFastDeliveryDays: obj.get("extraFastDeliveryDays"),
      extraFastPrice: obj.get("extraFastPrice"),
      fastDeliveryPercent: obj.get("fastDeliveryPercent"),
      allowedRevisions: obj.get("allowedRevisions"),
      skillLevel: obj.get("skillLevel"),
      enableServiceOptions: obj.get("enableServiceOptions"),


      minPrice: obj.get("minPrice"),
      maxPrice: obj.get("maxPrice"),
      actualPrice: obj.get("actualPrice"),
      actualDeliveryDays: obj.get("actualDeliveryDays"),
      workHours: obj.get("workHours"),
      minDeliveryDays: obj.get("minDeliveryDays"),

      priceMap: obj.get("priceMap"),
    };

    if (!rtn.priceMap) {
      rtn.priceMap = []
    }
    if (!rtn.overrides) {
      rtn.overrides = {};
    }

    var obj2 = obj.get("businessInitiatives");
    if (obj2) {
      rtn.businessInitiatives = dL.loadObjects("BusinessInitiative", obj2);
    }

    dL._loadServiceDetails(rtn, obj)

    loadObj(rtn, obj, "UserService", "forkedFromUserService");
    loadObj(rtn, obj, "ServiceVersion", "forkedFromServiceVersion");
    loadObj(rtn, obj, "UserRole", "userRole");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "User", "user");
    loadObj(rtn, obj, "User", "createdBy");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "currentVersion");
    loadObj(rtn, obj, "ServiceVersion", "draftVersion");
    return rtn;
  },
  loadServiceDelivery: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      comments: obj.get("comments"),
      responseAt: getDate(obj.get("responseAt")),
      createdAt: getDate(obj.get("createdAt")),
      files: obj.get("files"),
      status: obj.get("status"),
      reviewPeriod: obj.get("reviewPeriod"),
      responseComments: obj.get("responseComments"),
      responseFiles: obj.get("responseFiles"),
      clientReviewDueDate: getDate(obj.get("clientReviewDueDate")),
    };

    loadJSON(rtn, obj, "formData");
    loadJSON(rtn, obj, "formItems");

    loadObj(rtn, obj, "ServiceDeliverable", "serviceDeliverable");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "Order", "order");
    loadObj(rtn, obj, "ServiceOrder", "serviceOrder");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServicePackage", "servicePackage");
    loadObj(rtn, obj, "User", "createdBy");
    loadObj(rtn, obj, "User", "responseBy");
    loadObj(rtn, obj, "User", "reviewer");
    return rtn;
  },
  loadEarning: function (obj) {
    var data = {
      id: obj.id,
      earningDate: getDate(obj.get("earningDate")),
      amount: obj.get("amount"),
      withdrawn: obj.get("withdrawn"),
      testMode: obj.get("testMode"),
      withdrawalDate: getDate(obj.get("withdrawalDate")),
      releaseDate: getDate(obj.get("releaseDate")),
      testMode: obj.get("testMode"),
    };
    var obj2 = obj.get("chargeAccount");
    if (obj2) {
      data.chargeAccount = dL.loadAccount(obj2);
    }
    var obj2 = obj.get("payment");
    if (obj2) {
      data.payment = dL.loadPayment(obj2);
    }
    var obj2 = obj.get("charge");
    if (obj2) {
      data.charge = dL.loadCharge(obj2);
    }
    var obj2 = obj.get("service");
    if (obj2) {
      data.service = dL.loadService(obj2);
    }
    var obj2 = obj.get("serviceOrder");
    if (obj2) {
      data.serviceOrder = dL.loadServiceOrder(obj2);
    }
    var obj2 = obj.get("servicePackage");
    if (obj2) {
      data.servicePackage = dL.loadServicePackage(obj2);
    }
    var obj2 = obj.get("order");
    if (obj2) {
      data.order = dL.loadOrder(obj2);
    }
    var obj2 = obj.get("user");
    if (obj2) {
      data.user = dL.loadUser(obj2);
    }
    return data;
  },
  loadServiceOrder: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      path: obj.get("path"),
      revenue: obj.get("revenue"),
      grossProfit: obj.get("grossProfit"),
      netProfit: obj.get("netProfit"),
      workerBonus: obj.get("workerBonus"),
      netProfit: obj.get("netProfit"),
      workerPayType: obj.get("workerPayType"),
      workerEarning: obj.get("workerEarning"),
      workHours: obj.get("workHours"),
      accessUsers: obj.get("accessUsers"),
      allowedRevisions: obj.get("allowedRevisions"),

      serviceOptionIds: obj.get("serviceOptionIds"),
      orderNumber: obj.get("orderNumber"),
      orderDate: getDate(obj.get("orderDate")),
      deliveryType: obj.get("deliveryType"),
      deliveryDays: obj.get("deliveryDays"),
      serviceType: obj.get("serviceType"),
      dueDate: getDate(obj.get("dueDate")),
      lastServiceDeliveryAt: getDate(obj.get("lastServiceDeliveryAt")),
      status: obj.get("status"),
      hasInputs: obj.get("hasInputs"),
      hasInputsComplete: obj.get("hasInputsComplete"),
      total: obj.get("total"),
      email: obj.get("email"),
      clientReviewDueDate: getDate(obj.get("clientReviewDueDate")),
      hasReviewed: obj.get("hasReviewed"),
      review: obj.get("review"),

      //serviceDeliverableIds: obj.get("serviceDeliverableIds"),
      requiredPreServices: obj.get("requiredPreServices"),
      parentSOIds: obj.get("parentSOIds"),
      startDate: getDate(obj.get("startDate")),
      doneDate: getDate(obj.get("doneDate")),
      delayStartDate: getDate(obj.get("delayStartDate")),
      delayType: obj.get("delayType"),
      delayStart: obj.get("delayStart"),
      delayDays: obj.get("delayDays"),
      canStart: obj.get("canStart"),
      canStartAt: getDate(obj.get("canStartAt")),
    };

    var obj2 = obj.get("requiredTasks");
    if (obj2) {
      rtn.requiredTasks = dL.loadObjects("TaskRecord", obj2);
    }
    var obj2 = obj.get("childServiceOrders");
    if (obj2) {
      rtn.childServiceOrders = dL.loadObjects("ServiceOrder", obj2);
    }
    var obj2 = obj.get("requiredProposalItems");
    if (obj2) {
      rtn.requiredProposalItems = dL.loadObjects("ServiceProposalItem", obj2);
    }
    var obj2 = obj.get("requiredServiceOrders");
    if (obj2) {
      rtn.requiredServiceOrders = dL.loadObjects("ServiceOrder", obj2);
    }
    var objs = obj.get("serviceDeliverableVersions");
    if (objs) {
      rtn.serviceDeliverables = dL.loadObjects("ServiceDeliverableVersion", objs);
    }
    var objs = obj.get("serviceOptionVersions");
    if (objs) {
      rtn.serviceOptions = dL.loadObjects("ServiceOptionVersion", objs);
    }

    if (!rtn.review) {
      rtn.review = {};
    }
    loadObj(rtn, obj, "ServiceOrder", "parentServiceOrder");
    loadObj(rtn, obj, "Account", "chargeAccount");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    loadObj(rtn, obj, "ServiceProposalItem", "proposalItem");
    loadObj(rtn, obj, "ServiceProposal", "proposal");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    var obj2 = obj.get("payment");
    if (obj2) {
      rtn.payment = dL.loadPayment(obj2);
    }
    var obj2 = obj.get("charge");
    if (obj2) {
      rtn.charge = dL.loadCharge(obj2);
    }
    loadJSON(rtn, obj, "inputData");

    var obj2 = obj.get("lastServiceDelivery");
    if (obj2) {
      rtn.lastServiceDelivery = dL.loadServiceDelivery(obj2);
    }

    var obj2 = obj.get("order");
    if (obj2) {
      rtn.order = dL.loadOrder(obj2);
    }

    var obj2 = obj.get("service");
    if (obj2) {
      rtn.service = dL.loadService(obj2);
    }

    var obj2 = obj.get("parentServiceOrder");
    if (obj2) {
      rtn.parentServiceOrder = dL.loadServiceOrder(obj2);
    }

    var obj2 = obj.get("servicePackageVersion");
    if (obj2) {
      rtn.servicePackage = dL.loadServicePackageVersion(obj2);
    }

    /*
    var obj2 = obj.get("servicePackageVersion");
    if (obj2) {
      rtn.servicePackageVersion = dL.loadServicePackageVersion(obj2);
    }
    */

    var obj2 = obj.get("strategyConsultant");
    if (obj2) {
      rtn.strategyConsultant = dL.loadUser(obj2);
    }

    var obj2 = obj.get("projectManager");
    if (obj2) {
      rtn.projectManager = dL.loadUser(obj2);
    }

    var obj2 = obj.get("assignedTo");
    if (obj2) {
      rtn.assignedTo = dL.loadUser(obj2);
    }

    var obj2 = obj.get("createdBy");
    if (obj2) {
      rtn.createdBy = dL.loadUser(obj2);
    }

    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }
    return rtn;
  },
  loadTimeRecord: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      startDate: getDate(obj.get("startDate")),
      endDate: getDate(obj.get("endDate")),
      seconds: obj.get("seconds"),
      priorityLevel: obj.get("priorityLevel"),
    };

    loadObj(rtn, obj, "BusinessRoleTask", "businessRoleTask");
    loadObj(rtn, obj, "ServiceProposalItem", "proposalItem");
    loadObj(rtn, obj, "ServiceProposal", "proposal");
    loadObj(rtn, obj, "Account", "chargeAccount");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    loadObj(rtn, obj, "Order", "order");
    loadObj(rtn, obj, "ServiceOrder", "serviceOrder");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    var obj2 = obj.get("user");
    if (obj2) {
      rtn.user = dL.loadUser(obj2);
    }
    var obj2 = obj.get("task");
    if (obj2) {
      rtn.task = dL.loadTaskRecord(obj2);
    }
    var obj2 = obj.get("staffAugService");
    if (obj2) {
      rtn.staffAugService = dL.loadStaffAugService(obj2);
    }
    var obj2 = obj.get("staffAugShare");
    if (obj2) {
      rtn.staffAugShare = dL.loadStaffAugShare(obj2);
    }
    return rtn;
  },
  loadTrainingRecord: function () {
    //createdBy
    //createdAt
    //lastUpdatedAt
    //thumbsUp,thumbsDown
    //likes
    //comments
    //content (rich)

    //service
    //deliverable
    //task

    //addedOnVersion
    //removedOnVersion
  },
  loadBusinessRoleTask: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      title: obj.get("title"),
      description: obj.get("description"),
      skillLevel: obj.get("skillLevel"),
      workHours: obj.get("workHours"),
      deliveryDays: obj.get("deliveryDays"),
      deliverableNotes: obj.get("deliverableNotes"),
    };

    loadJSON(rtn, obj, "stepItems", []);
    loadJSON(rtn, obj, "formItems", []);

    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  getIsAccountUpToDate: function ({ chargeAccount }) {
    return dL.getAccountBalance(chargeAccount.id).then(function (balance) {
      if (balance < 0) {
        return "Account is past due. Can't start item."
      }
    })
  },
  loadTaskRecord: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      taskType: obj.get("taskType"),
      timeLogSeconds: obj.get("timeLogSeconds"),
      taskNumber: obj.get("taskNumber"),
      title: obj.get("title"),
      description: obj.get("description"),
      status: obj.get("status"),
      hours: obj.get("hours"),
      deliverableNotes: obj.get("deliverableNotes"),

      completedAt: getDate(obj.get("completedAt")),
      startedAt: getDate(obj.get("startedAt")),
      lastStartedAt: getDate(obj.get("lastStartedAt")),

      startDate: getDate(obj.get("startDate")),
      dueDate: getDate(obj.get("dueDate")),
      createdAt: getDate(obj.get("createdAt")),
      priorityLevel: obj.get("priorityLevel"),
      turboCharge: obj.get("turboCharge"),
      notes: obj.get("notes"),
      delayStart: obj.get("delayStart"),

      serviceOptionIds: obj.get("serviceOptionIds"),
      deliveryType: obj.get("deliveryType"),

      requiredPreServices: obj.get("requiredPreServices"),
      parentSOIds: obj.get("parentSOIds"),
      canStart: obj.get("canStart"),
      canStartAt: getDate(obj.get("canStartAt")),

      approvalStatus: obj.get("approvalStatus"),
    };

    var obj2 = obj.get("blockingTasks");
    if (obj2) {
      rtn.blockingTasks = dL.loadObjects("TaskRecord", obj2);
    }
    var obj2 = obj.get("requiredTasks");
    if (obj2) {
      rtn.requiredTasks = dL.loadObjects("TaskRecord", obj2);
    }
    var obj2 = obj.get("requiredProposalItems");
    if (obj2) {
      rtn.requiredProposalItems = dL.loadObjects("ServiceProposalItem", obj2);
    }
    var obj2 = obj.get("requiredServiceOrders");
    if (obj2) {
      rtn.requiredServiceOrders = dL.loadObjects("ServiceOrder", obj2);
    }
    var obj2 = obj.get("serviceOptions");
    if (obj2) {
      rtn.serviceOptions = dL.loadObjects("ServiceOption", obj2);
    }

    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    loadObj(rtn, obj, "ServiceTask", "task");
    loadObj(rtn, obj, "ServiceTaskVersion", "taskVersion");
    loadObj(rtn, obj, "BusinessRoleTask", "businessRoleTask");
    loadObj(rtn, obj, "ServiceProposalItem", "proposalItem");
    loadObj(rtn, obj, "ServiceProposal", "proposal");
    loadObj(rtn, obj, "User", "approver");
    loadObj(rtn, obj, "Account", "chargeAccount");
    loadJSON(rtn, obj, "inputData");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "ServicePackage", "servicePackage");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "User", "createdBy");
    loadObj(rtn, obj, "StaffAugService", "staffAugService");
    loadObj(rtn, obj, "StaffAugShare", "staffAugShare");
    loadObj(rtn, obj, "Order", "order");
    loadObj(rtn, obj, "ServiceOrder", "serviceOrder");
    loadObj(rtn, obj, "Service", "service");

    return rtn;
  },
  loadStaffAugShare: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      requireApproval: obj.get("requireApproval"),
      hoursMonth: obj.get("hoursMonth"),
      hoursWeek: obj.get("hoursWeek"),
      hoursDay: obj.get("hoursDay"),
    };

    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "StaffAugShare", "parentStaffAugShare");
    loadObj(rtn, obj, "StaffAugService", "staffAugService");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadStaffAugService: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      serviceNumber: obj.get("serviceNumber"),
      skillLevel: obj.get("skillLevel"),
      turboBoostsPerMonth: obj.get("turboBoostsPerMonth"),
      startDate: getDate(obj.get("startDate")),
      endDate: getDate(obj.get("endDate")),
      createdAt: getDate(obj.get("createdAt")),
      assignedAt: getDate(obj.get("assignedAt")),
      hoursPerMonth: obj.get("hoursPerMonth"),
      baseBillRate: obj.get("baseBillRate"),
      finalBillRate: obj.get("finalBillRate"),
      overageBillRate: obj.get("overageBillRate"),
      workerRate: obj.get("workerRate"),
      maxPullForwardHours: obj.get("maxPullForwardHours"),
      maxRollOverHours: obj.get("maxRollOverHours"),
      status: obj.get("status"),
      numberOfMonths: obj.get("numberOfMonths"),
      maxRolloverPerMonth: obj.get("maxRolloverPerMonth"),
      allowRollover: obj.get("allowRollover"),
      allowPullForward: obj.get("allowPullForward"),
      priorityLevel: obj.get("priorityLevel"),
      monthlyCost: obj.get("monthlyCost"),
      serviceType: obj.get("serviceType"),

      pullForwardHours: obj.get("pullForwardHours"),
      rolloverHours: obj.get("rolloverHours"),
      turboBoostsLeft: obj.get("turboBoostsLeft"),

      nextRetryAt: getDate(obj.get("nextRetryAt")),
      nextChargeDate: getDate(obj.get("nextChargeDate")),
      chargeStatus: obj.get("chargeStatus"),
    };

    rtn.turboBoostsPerMonth = 10

    if (!rtn.rolloverHours) {
      rtn.rolloverHours = 0;
    }
    if (!rtn.pullForwardHours) {
      rtn.pullForwardHours = 0;
    }

    loadObj(rtn, obj, "Proposal", "proposal");
    loadObj(rtn, obj, "ProposalItem", "proposalItem");
    loadObj(rtn, obj, "Order", "order");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "Account", "chargeAccount");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "User", "projectManager");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "User", "user");

    var obj2 = obj.get("shares");
    if (obj2) {
      rtn.shares = utils.readSeq(dL.loadObjects("StaffAugShare", obj2), obj.get("sharesSeq"));
    }

    return rtn;
  },
  loadBusinessInitiative: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      description: obj.get("description"),
      shortDescription: obj.get("shortDescription"),
      businessSector: obj.get("businessSector"),
      isPublic: obj.get("isPublic")
    };

    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "User", "createdBy");

    return rtn;
  },
  loadBusinessRole: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      description: obj.get("description"),
      businessSector: obj.get("businessSector"),
      rates: obj.get("rates"),
      taskListText: obj.get("taskListText"),
      jobDescription: obj.get("jobDescription"),
      enableRates: obj.get("enableRates"),
      needsReview: obj.get("needsReview"),
      status: obj.get("status")
    };

    var obj2 = obj.get("tasks");
    if (obj2 && typeof obj2 == "string") {
      rtn.taskListText = obj2
    } else {
      if (obj2) {
        rtn.tasks = utils.readSeq(dL.loadObjects("BusinessRoleTask", obj2), obj.get("tasksSeq"));
      } else {
        rtn.tasks = []
      }
    }

    if (!rtn.rates) {
      rtn.rates = {};
    }
    return rtn;
  },
  getBonusStats: function (userId) {
    var count1, count2, count3;
    var promises = [];
    const getMatch = function (data) {
      if (userId) {
        data._p_user = "User$" + userId
      }
      return data
    }
    promises[promises.length] = db
      .getQuery("BonusItem")
      .aggregate([
        {
          $match: getMatch({
            removed: { $in: [undefined, false] },
            withdrawn: false,
            vestingDate: { $gt: new Date() },
          }),
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$amount" },
          },
        },
      ])
      .then(function (data) {
        count1 = data.length > 0 ? data[0].count : 0;
      });

    promises[promises.length] = db
      .getQuery("BonusItem")
      .aggregate([
        {
          $match: getMatch({
            removed: { $in: [undefined, false] },
            withdrawn: false,
            vestingDate: { $lt: new Date() },
          }),
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$amount" },
          },
        },
      ])
      .then(function (data) {
        count2 = data.length > 0 ? data[0].count : 0;
      });

    promises[promises.length] = db
      .getQuery("BonusItem")
      .aggregate([
        {
          $match: getMatch({
            removed: { $in: [undefined, false] },
            withdrawn: true,
          }),
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$amount" },
          },
        },
      ])
      .then(function (data) {
        count3 = data.length > 0 ? data[0].count : 0;
      });

    return Promise.all(promises).then(function () {
      return { pending: count1, available: count2, withdrawn: count3 };
    });
  },
  saveCart: function () {
    return;
    const userId = session.user.id;
    const cart = session.cart;

    return (
      db
        .getObj("User", userId)
        //.set("cart", JSON.stringify(cart))
        .save()
    );
  },
  getEarningStats: function (userId) {
    var count1, count2, count3;
    var promises = [];
    promises[promises.length] = db
      .getQuery("Earning")
      .aggregate([
        {
          $match: {
            _p_user: "User$" + userId,
            removed: { $in: [undefined, false] },
            withdrawn: false,
            releaseDate: { $gt: new Date() },
          },
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$amount" },
          },
        },
      ])
      .then(function (data) {
        count1 = data.length > 0 ? data[0].count : 0;
      });

    promises[promises.length] = db
      .getQuery("Earning")
      .aggregate([
        {
          $match: {
            _p_user: "User$" + userId,
            removed: { $in: [undefined, false] },
            withdrawn: false,
            releaseDate: { $lt: new Date() },
          },
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$amount" },
          },
        },
      ])
      .then(function (data) {
        count2 = data.length > 0 ? data[0].count : 0;
      });

    promises[promises.length] = db
      .getQuery("Earning")
      .aggregate([
        {
          $match: {
            _p_user: "User$" + userId,
            removed: { $in: [undefined, false] },
            withdrawn: true,
          },
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$amount" },
          },
        },
      ])
      .then(function (data) {
        count3 = data.length > 0 ? data[0].count : 0;
      });

    return Promise.all(promises).then(function () {
      return { pending: count1, available: count2, withdrawn: count3 };
    });
  },
  isSS: function () {
    return session.user.enabledRoles.solutionSpecialist;
  },
  isR: function () {
    return session.user.enabledRoles.recruiter;
  },
  isPM: function () {
    return session.user.enabledRoles.projectManager;
  },
  isSC: function () {
    return session.user.enabledRoles.strategyConsultant;
  },
  isBD: function () {
    return session.user.enabledRoles.businessDevelopment;
  },
  loadUser: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      testMode: obj.get("testMode"),
      companyName: obj.get("companyName"),
      companyTitle: obj.get("companyTitle"),
      firstName: obj.get("firstName"),
      lastName: obj.get("lastName"),
      name: obj.get("firstName") + " " + obj.get("lastName"),
      email: obj.get("email"),
      flags: obj.get("flags"),
      isSystemAdmin: obj.get("isSystemAdmin"),
      isWorker: obj.get("isWorker"),
      avatar: obj.get("avatar"),
      pushToken: obj.get("pushToken"),
      settings: obj.get("settings"),
      userRole: obj.get("userRole"),
      status: obj.get("status"),

      linkedInUrl: obj.get("linkedInUrl"),
      website: obj.get("website"),

      serviceCore: obj.get("serviceCore"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      serviceTypes: obj.get("serviceTypes"),
      companyStages: obj.get("companyStages"),
      industries: obj.get("industries"),
      employeeSizes: obj.get("employeeSizes"),
      revenueSizes: obj.get("revenueSizes"),
      goals: obj.get("goals"),
      roles: obj.get("roles"),
      expertise: obj.get("expertise"),
      availableInCities: obj.get("availableInCities"),
      homeCity: obj.get("homeCity"),
      availableToStart: obj.get("availableToStart"),
      yearsOfExp: obj.get("yearsOfExp"),

      country: obj.get("country"),
      countryRegion: obj.get("countryRegion"),
      minHourlyRate: obj.get("minHourlyRate"),
      maxHoursPerDay: obj.get("maxHoursPerDay"),
      maxHoursPerMonth: obj.get("maxHoursPerMonth"),
      maxHoursPerWeek: obj.get("maxHoursPerWeek"),
      remoteOnly: obj.get("remoteOnly"),
      hasStaffServices: obj.get("hasStaffServices"),
      hasAdvisoryServices: obj.get("hasAdvisoryServices"),
      enabledRoles: obj.get("enabledRoles"),
      permissions: obj.get("permissions"),

      allowSell: obj.get("allowSell"),
      allowSellAdmin: obj.get("allowSellAdmin"),

      adminServiceTypes: obj.get("adminServiceTypes"),
      workerLevel: obj.get("workerLevel"),

      allowedInvitations: obj.get("allowedInvitations"),

      isPublicWorkerDirectory: obj.get("isPublicWorkerDirectory"),
      isPublicBuyerDirectory: obj.get("isPublicBuyerDirectory"),
    };

    if (!rtn.enabledRoles) {
      rtn.enabledRoles = {};
    }
    if (!rtn.permissions) {
      rtn.permissions = {};
    }
    if (!rtn.expertise) {
      rtn.expertise = {};
    }

    loadJSON(rtn, obj, "workHoursOverrides", {});
    loadObj(rtn, obj, "Company", "defaultCompany");

    var obj2 = obj.get("cart");
    if (obj2) {
      rtn.cart = JSON.parse(obj2);
    }

    loadObj(rtn, obj, "TimeRecord", "timeRecord");

    var obj2 = obj.get("businessRoles");
    if (obj2) {
      rtn.businessRoles = dL.loadObjects("BusinessRole", obj2);
    }

    var obj2 = obj.get("services");
    if (obj2) {
      rtn.services = dL.loadObjects("Service", obj2);
    }

    var obj2 = obj.get("userRoles");
    if (obj2) {
      rtn.userRoles = dL.loadObjects("UserRole", obj2);
    } else {
      rtn.userRoles = [];
    }

    var obj2 = obj.get("userServices");
    if (obj2) {
      rtn.userServices = dL.loadObjects("UserService", obj2);
    } else {
      rtn.userServices = [];
    }

    if (!rtn.creditCards) {
      rtn.creditCards = [];
    }
    if (!rtn.test_creditCards) {
      rtn.test_creditCards = [];
    }

    if (!rtn.settings) {
      rtn.settings = {};
    }
    if (!rtn.flags) {
      rtn.flags = {};
    }
    return rtn;
  },
  loadObjects: function (name, objs) {
    if (!objs) {
      return [];
    }
    return objs.map((obj) => {
      return dL["load" + name](obj);
    });
  },
  loadCompany: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      status: obj.get("status"),
      primaryColor: obj.get("primaryColor"),
      logo: obj.get("logo"),
      shortBio: obj.get("shortBio"),
      companyGoals: obj.get("companyGoals"),
      website: obj.get("website"),
      companyStage: obj.get("companyStage"),
      companyIndustry: obj.get("companyIndustry"),
      companyCity: obj.get("companyCity"),
      employeeSize: obj.get("employeeSize"),
      revenueSize: obj.get("revenueSize"),
    };

    loadObj(rtn, obj, "User", "companyAdmin");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  getMyProjects: function () {
    return dL
      .getQuery("Project")
      .equalToAO("users", db.getObj("User", session.user.id))
      .equalTo("status", "active")
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        return dL.loadObjects("Project", objs);
      });
  },
  loadTeamRoleRequest: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      message: obj.get("message"),
      status: obj.get("status"),
      shortlisted: obj.get("shortlisted")
    };

    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "TeamRole", "teamRole");
    loadObj(rtn, obj, "TeamRolePosition", "teamRolePosition");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadTeamRolePosition: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      remoteOnly: obj.get("remoteOnly"),
      hoursPerMonth: obj.get("hoursPerMonth"),
      locationCity: obj.get("locationCity"),
      country: obj.get("country"),
      countryRegion: obj.get("countryRegion"),
      positionCount: obj.get("positionCount"),
      description: obj.get("description"),
      status: obj.get("status")
    };

    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "TeamRole", "teamRole");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadTeamRole: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      quantity: obj.get("quantity"),
      skillLevel: obj.get("skillLevel"),
      staffType: obj.get("staffType"),
      description: obj.get("description"),
      status: obj.get("status")
    };

    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "BusinessRole", "businessRole");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadTeam: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      companyStage: obj.get("companyStage"),
      avatar: obj.get("avatar"),
      icon: obj.get("icon"),
      name: obj.get("name"),
      isPublic: obj.get("isPublic"),
      businessSectors: obj.get("businessSectors"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      status: obj.get("status"),
      isArchived: obj.get("isArchived"),
    };

    var obj2 = obj.get("users");
    if (obj2) {
      rtn.users = dL.loadObjects("User", obj2);
    }

    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "User", "teamLeader");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadProject: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      projectType: obj.get("projectType"),
      businessSector: obj.get("businessSector"),
      budget: obj.get("budget"),
      status: obj.get("status"),
      isArchived: obj.get("isArchived"),
    };

    var obj2 = obj.get("users");
    if (obj2) {
      rtn.users = dL.loadObjects("User", obj2);
    }

    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "User", "projectManager");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadMember: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      email: obj.get("email"),
      role: obj.get("role"),
      status: obj.get("status"),

      displayInPublicProfile: obj.get("displayInPublicProfile"),
      isLeadership: obj.get("isLeadership"),
    };

    var obj2 = obj.get("businessRoles");
    if (obj2) {
      rtn.businessRoles = dL.loadObjects("BusinessRole", obj2)
    }
    var obj2 = obj.get("teamRoles");
    if (obj2) {
      rtn.teamRoles = dL.loadObjects("TeamRole", obj2)
      rtn.teamRoles.forEach(item => {
        const { businessRole } = item
        if (rtn.businessRoles && businessRole) {
          item.businessRole = rtn.businessRoles.find(item2 => item2.id == businessRole.id)
        }
      })
    }

    loadObj(rtn, obj, "TeamRolePosition", "teamRolePosition");
    loadObj(rtn, obj, "TeamRoleRequest", "teamRoleRequest");
    loadObj(rtn, obj, "User", "user");
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Project", "project");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadForm: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      description: obj.get("description"),
      isSystem: obj.get("isSystem"),
    };

    loadJSON(rtn, obj, "formItems");
    return rtn;
  },
  loadService: function (obj): IService {
    if (!obj.get) {
      return { id: obj.id };
    }

    const rtn: IService = {
      id: obj.id,
      checklist: obj.get("checklist"),
      name: obj.get("name"),
      icon: obj.get("icon"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      status: obj.get("status"),
      serviceType: obj.get("serviceType"),
      companyStages: obj.get("companyStages"),
      serviceCore: obj.get("serviceCore"),
      minPrice: obj.get("minPrice"),
      maxPrice: obj.get("maxPrice"),
      actualPrice: obj.get("actualPrice"),
      actualDeliveryDays: obj.get("actualDeliveryDays"),
      workHours: obj.get("workHours"),
      minDeliveryDays: obj.get("minDeliveryDays"),
      enablePackages: obj.get("enablePackages"),
      createdAt: getDate(obj.get("createdAt")),
      isBuyerPublic: obj.get("isBuyerPublic"),
      //isWorkerPublic: obj.get("isWorkerPublic"),
      price: obj.get("price"),
      deliveryDays: obj.get("deliveryDays"),
      includedDeliverableIds: obj.get("includedDeliverableIds"),
      hasExtraFastDelivery: obj.get("hasExtraFastDelivery"),
      extraFastDeliveryDays: obj.get("extraFastDeliveryDays"),
      extraFastPrice: obj.get("extraFastPrice"),
      fastDeliveryPercent: obj.get("fastDeliveryPercent"),
      allowedRevisions: obj.get("allowedRevisions"),
      skillLevel: obj.get("skillLevel"),
      enableServiceOptions: obj.get("enableServiceOptions"),
      isFixedPriced: obj.get("isFixedPriced"),
      lastVersionUpdatedAt: getDate(obj.get("lastVersionUpdatedAt")),
      needsReview: obj.get("needsReview"),
      lastReviewedAt: getDate(obj.get("lastReviewedAt")),
      lastReviewedVersionNum: obj.get("lastReviewedVersionNum"),

      activeUserServices: obj.get("activeUserServices"),
      publicUserServices: obj.get("publicUserServices"),

      workerUserServices: obj.get("workerUserServices"),
      priceMap: obj.get("priceMap")
    };
    rtn.enableServiceOptions = true

    var obj2 = obj.get("businessInitiatives");
    if (obj2) {
      rtn.businessInitiatives = dL.loadObjects("BusinessInitiative", obj2);
    }

    if (!rtn.priceMap) {
      rtn.priceMap = []
    }
    if (!Array.isArray(rtn.companyStages)) {
      rtn.companyStages = [];
    }

    if (!rtn.checklist) {
      rtn.checklist = {};
    }

    dL._loadServiceDetails(rtn, obj)

    loadObj(rtn, obj, "UserService", "ownerUserService");
    loadObj(rtn, obj, "Team", "team");
    loadObj(rtn, obj, "User", "user");
    loadObj(rtn, obj, "ServiceVersion", "draftVersion");
    loadObj(rtn, obj, "ServiceVersion", "currentVersion");
    loadObj(rtn, obj, "User", "createdBy");
    loadObj(rtn, obj, "BusinessRole", "businessRole");

    return rtn;
  },
  loadServiceCategory: function (obj): IServiceCategory {
    if (!obj.get) {
      return { id: obj.id }
    }

    const rtn: IServiceCategory = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name")
    };

    return rtn;
  },
  loadServiceOption: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      isFixedPriced: obj.get("isFixedPriced"),
      hours: obj.get("hours"),
      price: obj.get("price"),
      actualPrice: obj.get("actualPrice"),
      actualDeliveryDays: obj.get("actualDeliveryDays"),
      workHours: obj.get("workHours"),
      deliveryDays: obj.get("deliveryDays"),
      minDeliveryDays: obj.get("minDeliveryDays"),
      limitedToPackages: obj.get("limitedToPackages"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceOptionVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      isFixedPriced: obj.get("isFixedPriced"),
      hours: obj.get("hours"),
      price: obj.get("price"),
      actualPrice: obj.get("actualPrice"),
      actualDeliveryDays: obj.get("actualDeliveryDays"),
      workHours: obj.get("workHours"),
      deliveryDays: obj.get("deliveryDays"),
      minDeliveryDays: obj.get("minDeliveryDays"),
      limitedToPackages: obj.get("limitedToPackages"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadObj(rtn, obj, "ServiceVersion", "removedOnServiceVersion");
    loadObj(rtn, obj, "ServiceOption", "option");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServicePackage: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      isFixedPriced: obj.get("isFixedPriced"),
      hours: obj.get("hours"),
      price: obj.get("price"),
      actualPrice: obj.get("actualPrice"),
      actualDeliveryDays: obj.get("actualDeliveryDays"),
      workHours: obj.get("workHours"),
      deliveryDays: obj.get("deliveryDays"),
      minDeliveryDays: obj.get("minDeliveryDays"),
      allowedRevisions: obj.get("allowedRevisions"),
      includedDeliverableIds: obj.get("includedDeliverableIds"),
      hasExtraFastDelivery: obj.get("hasExtraFastDelivery"),
      extraFastDeliveryDays: obj.get("extraFastDeliveryDays"),
      extraFastPrice: obj.get("extraFastPrice"),
      fastDeliveryPercent: obj.get("fastDeliveryPercent"),
      minPrice: obj.get("minPrice"),
      maxPrice: obj.get("maxPrice"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    if (!rtn.includedDeliverableIds) {
      rtn.includedDeliverableIds = {};
    }

    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServicePackageVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      shortDescription: obj.get("shortDescription"),
      description: obj.get("description"),
      isFixedPriced: obj.get("isFixedPriced"),
      hours: obj.get("hours"),
      price: obj.get("price"),
      actualPrice: obj.get("actualPrice"),
      actualDeliveryDays: obj.get("actualDeliveryDays"),
      workHours: obj.get("workHours"),
      deliveryDays: obj.get("deliveryDays"),
      minDeliveryDays: obj.get("minDeliveryDays"),
      allowedRevisions: obj.get("allowedRevisions"),
      includedDeliverableIds: obj.get("includedDeliverableIds"),
      hasExtraFastDelivery: obj.get("hasExtraFastDelivery"),
      extraFastDeliveryDays: obj.get("extraFastDeliveryDays"),
      extraFastPrice: obj.get("extraFastPrice"),
      fastDeliveryPercent: obj.get("fastDeliveryPercent"),
      minPrice: obj.get("minPrice"),
      maxPrice: obj.get("maxPrice"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    if (!rtn.includedDeliverableIds) {
      rtn.includedDeliverableIds = {};
    }

    loadObj(rtn, obj, "ServiceVersion", "removedOnServiceVersion");
    loadObj(rtn, obj, "ServicePackage", "package");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceTask: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      description: obj.get("description"),
      hours: obj.get("hours"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),
      requiredPreServices: obj.get("requiredPreServices"),
      taskInstructions: obj.get("taskInstructions"),
      trainingNoteCount: obj.get("trainingNoteCount"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceTaskVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      description: obj.get("description"),
      hours: obj.get("hours"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),
      requiredPreServices: obj.get("requiredPreServices"),
      taskInstructions: obj.get("taskInstructions"),
      trainingNoteCount: obj.get("trainingNoteCount"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadObj(rtn, obj, "ServiceVersion", "removedOnServiceVersion");
    loadObj(rtn, obj, "ServiceTask", "task");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceInput: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      description: obj.get("description"),
      deliverableType: obj.get("deliverableType"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadJSON(rtn, obj, "formItems", []);
    var obj2 = obj.get("similarServices");
    if (obj2) {
      rtn.similarServices = dL.loadObjects("Service", obj2);
    }

    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceInputVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      icon: obj.get("icon"),
      name: obj.get("name"),
      description: obj.get("description"),
      deliverableType: obj.get("deliverableType"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadJSON(rtn, obj, "formItems", []);
    var obj2 = obj.get("similarServices");
    if (obj2) {
      rtn.similarServices = dL.loadObjects("Service", obj2);
    }

    loadObj(rtn, obj, "ServiceVersion", "removedOnServiceVersion");
    loadObj(rtn, obj, "ServiceInput", "input");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadMasterOverrideRecord: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      startDayIndex: obj.get("startDayIndex"),
      doneDayIndex: obj.get("doneDayIndex"),
      priceMap: obj.get("priceMap"),
      packageId: obj.get("packageId"),
      isBuyerOverride: obj.get("isBuyerOverride"),
      overrideType: obj.get("overrideType"),
      path: obj.get("path"),
      isRemoved: obj.get("isRemoved"),

      deliveryType: obj.get("deliveryType"),
      deliveryDays: obj.get("deliveryDays"),
      unitPrice: obj.get("unitPrice"),
      total: obj.get("total"),
      serviceOptionIds: obj.get("serviceOptionIds"),
      delayOptions: obj.get("delayOptions"),
      deliveryType: obj.get("deliveryType"),

      quantity: obj.get("quantity"),
      overridePrice: obj.get("overridePrice"),

      actualVersionType: obj.get("actualVersionType"),
      serviceVersionNum: obj.get("serviceVersionNum"),
      workerUserServices: obj.get("workerUserServices"),
    };

    var obj2 = obj.get("workerUsers");
    if (Array.isArray(obj2)) {
      rtn.workerUsers = dL.loadObjects("User", obj2);
    }

    var obj2 = obj.get("servicePackageVersion");
    if (obj2) {
      rtn.servicePackage = dL.loadServicePackageVersion(obj2);
    }

    loadJSON(rtn, obj, "inputData");

    loadObj(rtn, obj, "User", "recommendedUser");
    loadObj(rtn, obj, "ServiceProposal", "rootProposal");
    loadObj(rtn, obj, "ServiceProposalItem", "proposalItem");
    loadObj(rtn, obj, "ServiceVersion", "actualServiceVersion");
    loadObj(rtn, obj, "ServiceSubService", "subService");
    loadObj(rtn, obj, "ServiceSubServiceVersion", "subServiceVersion");
    loadObj(rtn, obj, "ServiceProposalVersion", "rootProposalVersion");
    loadObj(rtn, obj, "ServiceVersion", "rootServiceVersion");
    loadObj(rtn, obj, "ServiceVersion", "userServiceVersion");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    loadObj(rtn, obj, "ServicePackage", "servicePackageVersion");
    return rtn;
  },
  loadServiceSubService: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      packageId: obj.get("packageId"),
      startDayIndex: obj.get("startDayIndex"),
      doneDayIndex: obj.get("doneDayIndex"),
      deliveryType: obj.get("deliveryType"),
      deliveryDays: obj.get("deliveryDays"),
      unitPrice: obj.get("unitPrice"),
      total: obj.get("total"),
      serviceOptionIds: obj.get("serviceOptionIds"),
      delayOptions: obj.get("delayOptions"),
      requiredPreServices: obj.get("requiredPreServices"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),
      deliveryType: obj.get("deliveryType"),
      priceMap: obj.get("priceMap"),

      actualVersionType: obj.get("actualVersionType"),
      serviceVersionNum: obj.get("serviceVersionNum"),
      workerUserServices: obj.get("workerUserServices")
    };

    var obj2 = obj.get("workerUsers");
    if (Array.isArray(obj2)) {
      rtn.workerUsers = dL.loadObjects("User", obj2);
    }

    var obj2 = obj.get("servicePackageVersion");
    if (obj2) {
      rtn.servicePackage = dL.loadServicePackageVersion(obj2)
    }

    loadJSON(rtn, obj, "inputData");

    loadObj(rtn, obj, "ServiceVersion", "actualServiceVersion");
    loadObj(rtn, obj, "ServiceVersion", "userServiceVersion");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "Service", "parentService");
    loadObj(rtn, obj, "ServiceVersion", "parentServiceVersion");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceSubServiceVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      packageId: obj.get("packageId"),
      startDayIndex: obj.get("startDayIndex"),
      doneDayIndex: obj.get("doneDayIndex"),
      deliveryType: obj.get("deliveryType"),
      deliveryDays: obj.get("deliveryDays"),
      unitPrice: obj.get("unitPrice"),
      total: obj.get("total"),
      serviceOptionIds: obj.get("serviceOptionIds"),
      delayOptions: obj.get("delayOptions"),
      requiredPreServices: obj.get("requiredPreServices"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),
      deliveryType: obj.get("deliveryType"),
      priceMap: obj.get("priceMap"),

      actualVersionType: obj.get("actualVersionType"),
      serviceVersionNum: obj.get("serviceVersionNum"),
      workerUserServices: obj.get("workerUserServices")
    };

    var obj2 = obj.get("workerUsers");
    if (Array.isArray(obj2)) {
      rtn.workerUsers = dL.loadObjects("User", obj2);
    }

    var obj2 = obj.get("servicePackageVersion");
    if (obj2) {
      rtn.servicePackage = dL.loadServicePackageVersion(obj2)
    }

    var obj2 = obj.get("workerUsers");
    if (Array.isArray(obj2)) {
      rtn.workerUsers = dL.loadObjects("User", obj2);
    }

    loadJSON(rtn, obj, "inputData");

    loadObj(rtn, obj, "ServiceVersion", "actualServiceVersion");
    loadObj(rtn, obj, "UserService", "userService");
    loadObj(rtn, obj, "ServiceVersion", "userServiceVersion");
    loadObj(rtn, obj, "ServiceVersion", "removedOnServiceVersion");
    loadObj(rtn, obj, "ServiceSubService", "subService");
    loadObj(rtn, obj, "Service", "parentService");
    loadObj(rtn, obj, "ServiceVersion", "parentServiceVersion");
    loadObj(rtn, obj, "User", "assignedTo");
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceDeliverable: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      icon: obj.get("icon"),
      reviewPeriod: obj.get("reviewPeriod"),
      description: obj.get("description"),
      deliverableType: obj.get("deliverableType"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),
      file: obj.get("file"),
      files: obj.get("files"),
      trainingNoteCount: obj.get("trainingNoteCount"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadJSON(rtn, obj, "formItems", []);
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadServiceDeliverableVersion: function (obj) {
    if (!obj.get) {
      return { id: obj.id };
    }

    var rtn = {
      id: obj.id,
      name: obj.get("name"),
      icon: obj.get("icon"),
      reviewPeriod: obj.get("reviewPeriod"),
      description: obj.get("description"),
      deliverableType: obj.get("deliverableType"),
      limitedToOptions: obj.get("limitedToOptions"),
      limitedToPackages: obj.get("limitedToPackages"),
      file: obj.get("file"),
      files: obj.get("files"),
      trainingNoteCount: obj.get("trainingNoteCount"),

      serviceVersionNum: obj.get("serviceVersionNum")
    };

    loadObj(rtn, obj, "ServiceVersion", "removedOnServiceVersion");
    loadObj(rtn, obj, "ServiceDeliverable", "deliverable");
    loadJSON(rtn, obj, "formItems", []);
    loadObj(rtn, obj, "Service", "service");
    loadObj(rtn, obj, "ServiceVersion", "serviceVersion");
    return rtn;
  },
  loadWithdrawal: function (obj) {
    var data = {
      id: obj.id,
      withdrawalDate: getDate(obj.get("withdrawalDate")),
      amount: obj.get("amount"),
      error: obj.get("error"),
      testMode: obj.get("testMode"),
      bankAccount: obj.get("bankAccount"),
      testMode: obj.get("testMode"),
    };
    return data;
  },
  loadCharge: function (obj) {
    var data = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      type: obj.get("type"),
      description: obj.get("description"),
      amount: obj.get("amount"),
      status: obj.get("status"),
      error: obj.get("error"),
      directCharge: obj.get("directCharge"),
      creditCard: obj.get("creditCard"),
    };

    loadObj(data, obj, "User", "createdBy");
    loadObj(data, obj, "User", "user");
    return data;
  },
  loadAccount: function (obj) {
    var rtn = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      description: obj.get("description"),
      creditCard: obj.get("creditCard"),
      lastProcessedAt: getDate(obj.get("lastProcessedAt")),
      lastSuccessAt: getDate(obj.get("lastSuccessAt")),

      nextRetryDate: getDate(obj.get("nextRetryDate")),
      retryCount: obj.get("retryCount"),
      chargeStatus: obj.get("chargeStatus"),
    };
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadAccountCharge: function (obj) {
    var rtn = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      chargeDate: getDate(obj.get("chargeDate")),
      description: obj.get("description"),
      amount: obj.get("amount"),
    };
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Account", "account");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  loadAccountPayment: function (obj) {
    var rtn = {
      id: obj.id,
      createdAt: getDate(obj.get("createdAt")),
      paymentDate: getDate(obj.get("paymentDate")),
      description: obj.get("description"),
      amount: obj.get("amount"),
      status: obj.get("status"),
      creditCard: obj.get("creditCard"),
    };
    loadObj(rtn, obj, "Company", "company");
    loadObj(rtn, obj, "Account", "account");
    loadObj(rtn, obj, "User", "createdBy");
    return rtn;
  },
  createTask: function ({ userService, serviceVersion, accountId, orderId, projectId, item, serviceOrderId, serviceId, parentSOIds, newIds, preReqItems, proposal, proposalItem, assignedTo }) {
    const taskVersion = item
    const { canStart, startDate, doneDate, task } = item;
    const { name, description, deliverables, requiredPreServices, hours } = item;
    const { requiredTasks, requiredServiceOrders, requiredProposalItems } = dL.getPreList({ proposalItem, requiredPreServices, preReqItems, newIds });
    return dL.getNum("taskRecordIndexNum").then(function (num) {
      //ensure hours and dueDate are correctly set
      //this will adjust the dueDates (based on the client input delays)
      return (
        db
          .getObj("TaskRecord")
          .set("userService", userService ? db.getObj("UserService", userService.id) : null)
          .set("serviceVersion", db.getObj("ServiceVersion", serviceVersion.id))
          .set("task", db.getObj("ServiceTask", task.id))
          .set("taskVersion", db.getObj("ServiceTaskVersion", taskVersion.id))
          .set("assignedTo", assignedTo ? db.getObj("User", assignedTo.id) : null)
          .set("proposal", proposal ? db.getObj("ServiceProposal", proposal.id) : null)
          .set("proposalItem", proposalItem ? db.getObj("ServiceProposalItem", proposalItem.id) : null)
          .set("chargeAccount", accountId ? db.getObj("Account", accountId) : null)
          .set("project", projectId ? db.getObj("Project", projectId) : null)
          .set("createdBy", db.getObj("User", session.user.id))
          .set("company", dL.getObj("Company", session.company.id))
          .set("taskNumber", num)
          .set("title", name)
          .set("description", description)
          .set("deliverables", deliverables)
          .set("order", orderId ? db.getObj("Order", orderId) : null)
          .set("service", db.getObj("Service", serviceId))
          .set("serviceOrder", db.getObj("ServiceOrder", serviceOrderId))
          .set("status", "ready")
          .set("requiredPreServices", requiredPreServices)
          .set("startDate", startDate)
          .set("dueDate", doneDate)
          .set("hours", hours)
          .set("requiredTasks", requiredTasks)
          .set("requiredProposalItems", requiredProposalItems)
          .set("requiredServiceOrders", requiredServiceOrders)
          //.set("delayStartDate", delayStartDate)
          //.set("delayType", delayType)
          //.set("delayStart", delayStart)
          //.set("delayDays", delayDays)
          .set("parentSOIds", parentSOIds)
          .set("canStart", canStart)
          .set("canStartAt", canStart ? new Date() : null)
          .save()
      );
    });
  },
  createStaffOrder: function ({ orderId, projectId, item, accountId }) {
    return dL._addStaffService(null, accountId, { ...item, orderId, projectId });
  },
  getPreList: function ({ proposalItem, requiredPreServices, preReqItems, newIds }) {
    const requiredTasks = [];
    const requiredServiceOrders = [];
    const requiredProposalItems = []

    if (requiredPreServices) {
      requiredPreServices.forEach((id) => {
        const preItem = preReqItems.find((item) => item.id == id);
        if (preItem) {
          const { type, id } = preItem;
          if (type == "service") {
            requiredServiceOrders.push(db.getObj("ServiceOrder", newIds[id]));
          } else if (type == "task") {
            requiredTasks.push(db.getObj("TaskRecord", newIds[id]));
          }
        }
      });
    }

    if (proposalItem) {
      const { requiredPreServices } = proposalItem
      if (requiredPreServices) {
        requiredPreServices.forEach((id) => {
          requiredProposalItems.push(db.getObj("ServiceProposalItem", newIds[id]));
        });
      }
    }


    return { requiredTasks, requiredServiceOrders, requiredProposalItems };
  },
  createServiceOrder: function ({ subService, staffAugService, staffAugShare, accountId, userId, orderId, projectId, item, parentServiceOrderId, parentSOIds: defaultParentSOIds, newIds, preReqItems, proposal, proposalItem, path: startPath, circularIds: defaultCircularIds, priceMap: defaultPriceMap, itemStartDate: defaultItemStartDate }) {
    const itemStartDate = defaultItemStartDate ? defaultItemStartDate : new Date()
    const subServiceId = subService ? subService.id : null
    const parentSOIds = defaultParentSOIds ? defaultParentSOIds : []
    const { actualServiceVersion, task, id, projectManager, strategyConsultant } = item;

    const serviceVersionId = actualServiceVersion.id
    return dL.getServiceVersion2({ serviceVersionId }).then(function (serviceVersion) {
      var { service, userService } = serviceVersion
      const serviceId = service.id

      const circularIds = getCircularIds({ circularIds: defaultCircularIds, id: service.id })
      if (!circularIds) {
        return
      }

      var path = ""
      const userServiceId = userService ? userService.id : null

      var configItem
      var isOverride
      var originalPrice
      var priceMap = defaultPriceMap
      if (!parentServiceOrderId) {
        configItem = item
        if (proposalItem) {
          if (!priceMap) {
            priceMap = proposal.buyerPriceMap ? proposal.buyerPriceMap : proposal.priceMap
          }
          path = `p-item[${proposalItem.id}]`
        } else {
          if (!priceMap) {
            priceMap = serviceVersion.priceMap
          }
        }
      } else {
        path = (startPath ? (startPath + ".") : "") + `service[${subServiceId}]`
        const mapItem = priceMap.find(item => item.path == path)
        if (!mapItem) {
          throw "Price map item not found for service: " + service.name + ": sub-service id:" + subServiceId
        }
        const { item, isOverride: ssIsOverride, originalPrice: ssOriginalPrice } = mapItem
        const { isRemoved } = item
        configItem = item
        isOverride = ssIsOverride
        originalPrice = ssOriginalPrice
        if (isRemoved) {
          return
        }
      }

      const { workerUserServices, workHours, deliveryType, deliveryDays: d1, inputData, packageId, serviceOptionIds, unitPrice: u1, days, price } = configItem
      //const deliveryDays = d1 ? d1 : days
      const unitPrice = u1 ? u1 : price

      const { requiredPreServices, delayStartDate, delayType, delayStart, delayDays, canStart } = item;
      const { allowedRevisions } = serviceVersion;

      var startDate, doneDate, deliveryDays
      if (parentServiceOrderId) {
        const { days: g1, startDayIndex } = getDataFromPriceMap({ priceMap, path })
        deliveryDays = g1
        startDate = Moment().businessAdd(startDayIndex)
        doneDate = Moment(startDate).businessAdd(deliveryDays)
      } else {
        deliveryDays = d1 ? d1 : days
        startDate = Moment()
        doneDate = Moment(startDate).businessAdd(deliveryDays)
      }

      var service
      var userService
      return dL.getService2({ serviceId }).then(function (_service) {
        service = _service

        if (userServiceId) {
          return dL.getUserService2({ userServiceId }).then(function (_userService) {
            userService = _userService
          })
        }
      }).then(function () {
        const { inputs, packages, options } = serviceVersion

        const preReqItems = getServicePreReqItems({ serviceData: serviceVersion });

        if (userService) {
          dL.getObj("UserService", userService.id)
            .increment("popularCount")
            .set("lastPurchasedAt", new Date())
            .save()
        }

        return dL.getNum("serviceOrderIndexNum").then(function (num) {
          const hasInputs = inputs && inputs.length > 0
          const status = hasInputs ? "pending" : "ready"

          const deliverables = getDeliverablesForService({ serviceVersion, packageId, serviceOptionIds })

          const { requiredTasks, requiredServiceOrders, requiredProposalItems } = dL.getPreList({ proposalItem, requiredPreServices, preReqItems, newIds });

          const servicePackage = packageId ? packages.find(item => item.package.id == packageId) : null
          const serviceOptions = options.filter(item => serviceOptionIds[item.option.id])

          var serviceOrderId = id
          return (
            db
              .getObj("ServiceOrder", id)
              .set("isOverridePrice", isOverride)
              .set("originalUnitPrice", originalPrice)

              .set("task", task ? db.getObj("TaskRecord", task.id) : null)
              .set("staffAugShare", staffAugShare ? db.getObj("StaffAugShare", staffAugShare.id) : null)
              .set("staffAugService", staffAugService ? db.getObj("StaffAugService", staffAugService.id) : null)
              .set("chargeAccount", accountId ? db.getObj("Account", accountId) : null)
              .set("proposal", proposal ? db.getObj("ServiceProposal", proposal.id) : null)
              .set("proposalItem", proposalItem ? db.getObj("ServiceProposalItem", proposalItem.id) : null)
              .set("project", projectId ? db.getObj("Project", projectId) : null)
              .set("order", orderId ? db.getObj("Order", orderId) : null)
              .set("parentServiceOrder", parentServiceOrderId ? db.getObj("ServiceOrder", parentServiceOrderId) : null)
              .set("projectManager", projectManager ? db.getObj("User", projectManager.id) : null)
              .set("strategyConsultant", strategyConsultant ? db.getObj("User", strategyConsultant.id) : null)
              .set("service", db.getObj("Service", service.id))
              .set("serviceVersion", serviceVersion ? db.getObj("ServiceVersion", serviceVersion.id) : null)
              .set("servicePackageVersion", servicePackage ? db.getObj("ServicePackageVersion", servicePackage.id) : null)
              .set("servicePackage", servicePackage ? db.getObj("ServicePackage", servicePackage.package.id) : null)
              .set("userservice", userService ? db.getObj("UserService", userService.id) : null)
              .set("user", db.getObj("User", userId))
              .set("createdBy", db.getObj("User", session.user.id))
              .set("company", dL.getObj("Company", session.company.id))
              .set("orderNumber", "SO" + num)
              .set("orderDate", new Date())
              .set("workHours", workHours)
              .set("serviceType", service.serviceType)
              .set("deliveryDays", deliveryDays)
              .set("serviceOptionIds", serviceOptionIds)
              .set("serviceOptionVersions", serviceOptions ? serviceOptions.map((item) => db.getObj("ServiceOptionVersion", item.id)) : null)
              .set("serviceOptions", serviceOptions ? serviceOptions.map((item) => db.getObj("ServiceOption", item.option.id)) : null)
              .set("inputData", inputData ? JSON.stringify(inputData) : null)
              .set("deliveryType", deliveryType)
              .set("total", unitPrice)
              .set(
                "serviceDeliverables",
                deliverables.map((item) => db.getObj("ServiceDeliverable", item.deliverable.id))
              )
              .set(
                "serviceDeliverableVersions",
                deliverables.map((item) => db.getObj("ServiceDeliverableVersion", item.id))
              )
              .set("path", path)
              .set("status", status)
              .set("parentSOIds", parentSOIds)
              .set("requiredTasks", requiredTasks)
              .set("requiredProposalItems", requiredProposalItems)
              .set("requiredServiceOrders", requiredServiceOrders)
              .set("requiredPreServices", requiredPreServices)
              .set("startDate", startDate)
              .set("doneDate", doneDate)
              .set("dueDate", doneDate)
              .set("delayStartDate", delayStartDate)
              .set("delayType", delayType)
              .set("delayStart", delayStart)
              .set("delayDays", delayDays)
              .set("hasInputs", hasInputs)
              .set("canStart", canStart)
              .set("canStartAt", canStart ? new Date() : null)
              .set("allowedRevisions", allowedRevisions)
              .save()
              .then(function () {
                if (parentServiceOrderId) {
                  return dL.getObj("ServiceOrder", parentServiceOrderId)
                    .add("childServiceOrders", db.getObj("ServiceOrder", serviceOrderId))
                    .save()
                }
              })
              .then(function () {
                const { tasks, subServices } = serviceVersion

                //get only the tasks that are required for this selection of packageId and serviceOptionIds
                subServices.forEach((subServiceVersion) => {
                  const { subService } = subServiceVersion;
                  const { id } = subService

                  const { days: deliveryDays, startDayIndex } = getDataFromPriceMap({ priceMap, path: (path ? path + "." : "") + "service[" + id + "]" })
                  const startDate = Moment().businessAdd(startDayIndex)
                  const doneDate = Moment(startDate).businessAdd(deliveryDays)
                  subServiceVersion.startDate = startDate
                  subServiceVersion.doneDate = doneDate
                });

                const newIds = {};

                if (tasks && tasks.length > 0) {
                  tasks.forEach((sItem) => {
                    const { task, deliveryDays, requiredPreServices, limitedToOptions, limitedToPackages } = sItem;
                    const { id } = task

                    if (isRequiredItem({ limitedToOptions, limitedToPackages, packageId, serviceOptionIds })) {
                      const newId = utils.guid();
                      newIds[id] = newId;
                      sItem.id = newId;

                      sItem.canStart = false;
                      sItem.startDate = getStartDate(preReqItems, item, null, itemStartDate);
                      sItem.doneDate = Moment(item.startDate).businessAdd(deliveryDays);

                      if (!requiredPreServices || requiredPreServices.length == 0) {
                        sItem.canStart = true;
                      }
                    }
                  });
                }

                if (subServices && subServices.length > 0) {
                  subServices.forEach((sItem) => {
                    const { subService, deliveryDays, requiredPreServices, limitedToOptions, limitedToPackages } = sItem;
                    const { id } = subService
                    if (isRequiredItem({ limitedToOptions, limitedToPackages, packageId, serviceOptionIds })) {
                      const newId = utils.guid();
                      newIds[id] = newId;
                      sItem.id = newId;

                      sItem.canStart = false;
                      //sItem.startDate = getStartDate(preReqItems, item, null, itemStartDate);
                      //sItem.doneDate = Moment(item.startDate).businessAdd(deliveryDays);

                      if (!requiredPreServices || requiredPreServices.length == 0) {
                        sItem.canStart = true;
                      }
                    }
                  });
                }

                if (tasks && tasks.length > 0) {
                  tasks.forEach((item) => {
                    const { requiredPreServices } = item;

                    if (requiredPreServices) {
                      item.requiredPreServices = requiredPreServices.map((pid) => newIds[pid]);
                    }
                  });
                }

                if (subServices && subServices.length > 0) {
                  subServices.forEach((item) => {
                    const { requiredPreServices } = item;

                    if (requiredPreServices) {
                      item.requiredPreServices = requiredPreServices.map((pid) => newIds[pid]);
                    }
                  });
                }

                const promises = [];
                promises[promises.length] = Promise.all(
                  tasks.map((sItem) => {
                    return dL.createTask({ userService, serviceVersion, accountId, orderId, projectId, item: sItem, serviceOrderId, serviceId: service.id, parentSOIds: [...parentSOIds, serviceOrderId], newIds, preReqItems, proposal, proposalItem });
                  })
                );

                promises[promises.length] = Promise.all(
                  subServices.map((sItem) => {
                    const { subService, startDate } = sItem

                    return dL.createServiceOrder({ itemStartDate: startDate, path, priceMap, subService, accountId, userId, orderId, projectId, item: sItem, parentServiceOrderId: serviceOrderId, parentSOIds: [...parentSOIds, serviceOrderId], newIds, preReqItems, proposal, proposalItem, circularIds });
                  })
                );

                return Promise.all(promises);
              }).then(function () {
                //assign this to user (this will set the earning information)
                //workerUserServices from the priceMap or from the service

                const assignToUser = function (user) {
                  return dL.setServiceOrderWorker({ orderId, serviceOrderId, userId: user.id });
                }

                const promises = []
                return dL.getServiceOrder(serviceOrderId).then(function (serviceOrder) {
                  const { autoAssign } = proposalItem ? proposalItem : {}
                  if (userService) { // && autoAssign
                    const { user } = userService
                    promises[promises.length] = assignToUser(user)
                  } else {
                    const users = {}

                    if (userService) {
                      const { user } = userService
                      users[user.id] = {
                        userService,
                        tier: 1
                      }
                    }

                    const sendToUserServices = function (list, tier) {
                      list.forEach(workerUserService => {
                        const { user } = workerUserService
                        users[user.id] = {
                          userService: workerUserService,
                          tier
                        }
                      })
                    }

                    if (workerUserServices) {
                      sendToUserServices(workerUserServices, 2)
                    }

                    const { workerUserServices: aWorkerUserServices } = subService
                    if (aWorkerUserServices) {
                      sendToUserServices(aWorkerUserServices, 3)
                    }

                    const { workerUserServices: sWorkerUserServices } = service
                    if (sWorkerUserServices) {
                      sendToUserServices(sWorkerUserServices, 4)
                    }

                    for (var key in users) {
                      const { user, tier } = users[key]
                      promises[promises.length] = dL.createWorkRequest2({ serviceOrder, userId: user.id, tier })
                    }
                  }

                  return Promise.all(promises)
                })
              })
              .then(function () {
                return dL.addOrderHistory({ orderId, serviceOrderId, type: "so-create", description: "Service order created." });
              })
          );
        });
      })
    })
  },
  getStaffOrderCancellationCost: function ({ staffAugServiceId }) {
    return dL.getQuery("StaffAugService").get(staffAugServiceId).then(function (obj) {
      const staffAugService = dL.getStaffAugService(obj)

      const { startDate, endDate, monthlyCost, hoursPerMonth, finalBillRate } = staffAugService
      if (startDate < new Date()) {
        //service has not yet started
        return { cancelCost: 0, endOfServiceDate: new Date() }
      } else {
        var endOfServiceDate
        //get the number of days left for the service
        const daysLeftInService = Moment(new Date()).diff(endDate, "days")
        //const totalDaysInService = Moment(startDate).diff(endDate, "days")
        const daysUsedInService = Moment(startDate).diff(new Date(), "days")
        const monthsUsed = Math.ceil(daysUsedInService / 30)

        const { finalBillRate: newBillRate } = getStaffTotal({ model: { ...staffAugService, numberOfMonths: monthsUsed } });

        var cancelCost
        var hourDiffCancelCost = 0
        if (newBillRate > finalBillRate) {
          hourDiffCancelCost = (newBillRate - finalBillRate) * monthsUsed * hoursPerMonth
        }
        cancelCost += hourDiffCancelCost

        if (daysLeftInService > 30) {
          const maxCancelCost = 500 * (32 * (hoursPerMonth / 5))
          if (monthlyCost > maxCancelCost) {
            cancelCost += maxCancelCost
          }
          endOfServiceDate = Moment().date(Moment(endDate).date()).toDate()
        } else {
          endOfServiceDate = endDate
          //monthlyCost += -1 * (daysLeftInService / 30) * monthlyCost * .75
        }

        return { cancelCost, endOfServiceDate }
      }

    })
  },
  getServiceOrderCancelCost: function ({ serviceOrderId }) {
    //get the open tasks (get the task hours)
    //get the open sub-service (get the related cost)
    //take 75% of that and allow to cancel
    const returnRate = .9

    return dL.getObj("ServiceOrder")
      .include("userRole")
      .include("businessRole")
      .get(serviceOrderId).then(function (obj) {
        const serviceOrder = dL.loadServiceOrder(obj)
        const { childServiceOrders, total, userRole, businessRole, skillLevel } = serviceOrder

        const promises = []

        //cancel all sub services
        var subReturnAmount = 0
        if (childServiceOrders) {
          promises[promises.length] = Promise.all(childServiceOrders.map(serviceOrder => {
            return dL.getServiceOrderCancelCost({ serviceOrderId: serviceOrder.id }).then(function ({ amount }) {
              subReturnAmount += amount
            })
          }))
        }

        //cancel all tasks that are open
        var cancelWorkHours = 0
        promises[promises.length] = db.getQuery("TaskRecord")
          .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId))
          .notContainedIn("status", ["completed", "canceled", "started"])
          .containedIn("removed", [undefined, false])
          .select(["hours"])
          .find().then(function (objs) {
            var tasks = dL.loadObjects("TaskRecord", objs)
            tasks.forEach(task => {
              const { hours } = task
              cancelWorkHours += hours
            })
          })

        return Promise.all(promises).then(function () {
          const hourlyRate = userRole ? userRole.hourlyRate : businessRole.rates[skillLevel]

          var returnAmount = total - cancelWorkHours * hourlyRate + subReturnAmount

          if (returnAmount > 0) {
            returnAmount = returnAmount * returnRate
          }

          return {
            returnAmount
          }
        })
      })
  },
  cancelServiceOrder: function ({ serviceOrderId }) {
    //what is the cancel cost
    return dL.getObj("ServiceOrder")
      .get(serviceOrderId).then(function (obj) {
        const serviceOrder = dL.loadServiceOrder(obj)
        const { childServiceOrders } = serviceOrder

        const promises = []

        //cancel all sub services
        if (childServiceOrders) {
          promises[promises.length] = Promise.all(childServiceOrders.map(serviceOrder => {
            return dL.cancelServiceOrder({ serviceOrderId: serviceOrder.id })
          }))
        }

        //cancel all tasks that are open
        promises[promises.length] = db.getQuery("TaskRecord")
          .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId))
          .notContainedIn("status", ["completed", "canceled"])
          .containedIn("removed", [undefined, false])
          .select([])
          .find().then(function (objs) {
            return Promise.all(objs.map(obj => {
              return obj
                .set("canceledAt", new Date())
                .set("status", "canceled")
                .save()
            }))
          })

        //cancel all workRequest for this serviceOrder
        promises[promises.length] = db.getQuery("TimeRecord")
          .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId))
          .exists("endDate", false)
          .containedIn("removed", [undefined, false])
          .select([])
          .find().then(function (objs) {
            return Promise.all(objs.map(obj => {
              return dL.stopTimeRecord(obj.id)
            }))
          })

        //stop all timeRecord for all tasks that are open
        promises[promises.length] = db.getQuery("WorkRequest")
          .equalTo("serviceOrder", dL.getObj("ServiceOrder", serviceOrderId))
          .equalTo("status", "pending")
          .containedIn("removed", [undefined, false])
          .select([])
          .find()
          .then(function (objs) {
            return Promise.all(objs.map(obj => {
              return obj
                .set("canceledAt", new Date())
                .set("status", "canceled")
                .save()
            }))
          })

        return Promise.all(promises).then(function () {
          return dL.getObj("ServiceOrder", serviceOrderId)
            .set("status", "canceled")
            .set("canceledAt", new Date())
            .save()
        })
      })
  },
  getCartStrategyMap: function () {
    return dL.getQuery("ServiceProposal")
      .equalTo("user", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .first().then(function (obj) {
        if (obj) {
          return dL.loadServiceProposal(obj)
        } else {
          const model = {
            title: "Cart Strategy Map",
            user: session.user
          }
          //send to client (yes)
          return dL.saveServiceProposal(null, model).then(function (obj) {
            return dL.loadServiceProposal(obj)
          })
        }
      })
  },
  addProposalItem: function ({ item, proposal }) {
    /*
    item.proposal = proposal;
    //use the last sent to client version (?)
    return saveProposalItem({ isNew: true, item }).then(function () {
      //use the last
      //calculatePriceMap()
    })
    */
  }
};

export default dL;
