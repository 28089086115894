import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import http from "root/utils/http";
import utils from "root/utils/functions";
import session from "root/utils/session";
import PromiseA from "root/utils/promise";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { __DEV__ } from "root/dev";
import { ServiceRequestsBuyer } from "root/service-requests";
import { OrdersBuyer } from "root/orders";
import { ServiceOrdersBuyer } from "root/service-orders";
import { SelectUsers, ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { Loading,Section, ModalBasic, LabelItem, FlexExpand, FlexRow, TabBar, Text, TextLine, View, TrashIcon, FormItem, MyInput, SelectBox, NoRecords } from "root/pack-1";
import { ChatWindow,getChatRoomsBuyer } from "root/chat";

const { money, serviceTypes, projectTypes, getLabelItem } = utils;

export function NewProject({ onCancel, onSave }) {
  const [state, setState] = useState({ model: { name: "" } });
  const { model } = state;
  const { businessSector, name } = model;

  const buttons = [];

  buttons.push({
    label: "Create Project",
    onPress: () => {
      if (!name) {
        alert("Must enter project name.");
        return;
      }
      return dL.newProject({ name, businessSector, projectType: "project" }).then(function (projectId) {
        return dL
          .getQuery("Project")
          .get(projectId)
          .then(function (obj) {
            const project = dL.loadProject(obj);
            onSave(project);
          });
      });
    }
  });

  //project type: Project, Improvement, New Business Initiative
  //add a project budget?

  return (
    <ModalBasic title="New Project" onCancel={onCancel} buttons={buttons}>
      <MyInput
        label="Project Name:"
        placeholder="Enter name"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        style={{ marginBottom: 0 }}
        label="Business Sector:"
        value={utils.serviceTypes.find(item => item.value == businessSector)}
        options={utils.serviceTypes}
        description="Optionally, select a business sector for this project."
        onChange={item => {
          model.businessSector = item.value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

export function EditProject({ projectId, onSave, onCancel }) {
  const [state, setState] = useState({ isLoading: true });

  useEffect(() => {
    dL.getQuery("Project")
      .get(projectId)
      .then(function (obj) {
        setState({ ...state, isLoading: false, model: dL.loadProject(obj) });
      });
  }, []);

  const { isLoading, model } = state;
  if (isLoading) {
    return <Loading/>;
  }

  const { name, businessSector, budget } = model;

  const buttons = [];

  buttons.push({
    label: "Save",
    onPress: () => {
      if (!name) {
        alert("Must enter project name.");
        return;
      }
      if (!utils.isMoney(budget)) {
        alert("Must enter valid budget.");
        return;
      }
      return dL.saveProject(projectId, { name, businessSector, budget }).then(function () {
        onSave();
      });
    }
  });

  return (
    <ModalBasic title="Edit Project" onCancel={onCancel} buttons={buttons}>
      <MyInput
        label="Project Name:"
        placeholder="Enter name"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        label="Business Sector:"
        value={utils.serviceTypes.find(item => item.value == businessSector)}
        options={utils.serviceTypes}
        description="Optionally, select a business sector for this project."
        onChange={item => {
          model.businessSector = item.value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        style={{ maxWidth: 125 }}
        boxStyle={{ marginBottom: 0 }}
        inputType="money"
        minValue={0}
        label="Budget:"
        description="Enter a budget value for this project."
        value={budget}
        onChange={value => {
          model.budget = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

export function Project() {
  const { eid, projectId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ tabValue: "details", isLoading: true });
  const { members, member, newTeamMemberVisible, isLoading, project, tabValue } = state;
  const [projectVisible, setProjectVisible] = useState();
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    var promise = new PromiseA();
    if (eid) {
      promise = db
        .getQuery("Member")
        .equalTo("_id", eid)
        .containedIn("removed", [undefined, false])
        .select([])
        .first()
        .then(function (obj) {
          if (obj) {
            return obj
              .set("status", "")
              .set("user", db.getObj("User", session.user.id))
              .save();
          } else {
            alert("Project invitation not found.");
          }
        });
    } else {
      promise.resolve();
    }

    promise
      .then(function () {
        dL.getQuery("Project")
          .include("projectManager")
          .get(projectId)
          .then(function (obj) {
            const project = dL.loadProject(obj);

            //stats: number of orders, number of service orders, budget, business sector, dollars spent, dollars projected to spend

            //spent (this is the number of service orders completed)
            //pendingSpend (this is the number of service orders not completed)

            const newState = { ...state };
            const promises = [];

            promises[promises.length] = dL
              .getQuery("ServiceOrder")
              .equalTo("project", dL.getObj("Project", projectId))
              .equalTo("status", "completed")
              .containedIn("removed", [undefined, false])
              .select(["total"])
              .find()
              .then(function (objs) {
                const serviceOrders = dL.loadObjects("ServiceOrder", objs);
                var total = 0;
                serviceOrders.forEach(item => {
                  total += item.total;
                });
                newState.spent = total;
              });

            promises[promises.length] = dL
              .getQuery("ServiceOrder")
              .equalTo("project", dL.getObj("Project", projectId))
              .notEqualTo("status", "completed")
              .containedIn("removed", [undefined, false])
              .select(["total"])
              .find()
              .then(function (objs) {
                const serviceOrders = dL.loadObjects("ServiceOrder", objs);
                var total = 0;
                serviceOrders.forEach(item => {
                  total += item.total;
                });
                newState.pendingSpend = total;
              });

            promises[promises.length] = dL
              .getQuery("Member")
              .equalTo("project", dL.getObj("Project", projectId))
              .containedIn("removed", [undefined, false])
              .include("user")
              .find()
              .then(function (objs) {
                const members = dL.loadObjects("Member", objs);
                newState.members = members;
                newState.memberCount = members.length;
              });

            promises[promises.length] = dL
              .getQuery("ServiceProposal")
              .equalTo("project", dL.getObj("Project", projectId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.proposalCount = count;
              });

            promises[promises.length] = dL
              .getQuery("ServiceRequest")
              .equalTo("project", dL.getObj("Project", projectId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.serviceRequestCount = count;
              });

            promises[promises.length] = dL
              .getQuery("Order")
              .equalTo("project", dL.getObj("Project", projectId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.orderCount = count;
              });

            promises[promises.length] = dL
              .getQuery("ServiceOrder")
              .equalTo("project", dL.getObj("Project", projectId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.serviceOrderCount = count;
              });

            promises[promises.length] = dL
              .getQuery("Member")
              .equalTo("project", dL.getObj("Project", projectId))
              .equalTo("user", dL.getObj("User", session.user.id))
              .containedIn("removed", [undefined, false])
              .include("createdBy")
              .first()
              .then(function (obj) {
                if (obj) {
                  newState.member = dL.loadMember(obj);
                }
              });

            return Promise.all(promises).then(function () {
              newState.isLoading = false;
              newState.project = project;
              setState(newState);
            });
          });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { name, businessSector, budget, isArchived } = project;
  const { proposalCount, memberCount, serviceOrderCount, orderCount, serviceRequestCount, spent, pendingSpend } = state;
  const { role } = member ? member : {};

  const buttons = [];
  if (role == "admin") {
    if (!isArchived) {
      buttons.push({
        label: "Edit",
        onPress: () => {
          setProjectVisible(true);
        }
      });
      buttons.push({
        label: "Delete",
        onPress: () => {
          dL.getObj("Project", projectId)
            .set("removed", true)
            .save()
            .then(function () {
              history.goBack();
            });
        }
      });
      buttons.push({
        label: "Archive",
        onPress: () => {
          dL.getObj("Project", projectId)
            .set("isArchived", true)
            .save()
            .then(function () {
              project.isArchived = true;
              setState({ ...state, project });
            });
        }
      });
    } else {
      buttons.push({
        label: "Un-Archive",
        onPress: () => {
          dL.getObj("Project", projectId)
            .set("isArchived", false)
            .save()
            .then(function () {
              project.isArchived = false;
              setState({ ...state, project });
            });
        }
      });
    }
  }

  const tabs = [];
  tabs.push({ label: "Details", value: "details" });
  tabs.push({ label: "Team", value: "team" });
  tabs.push({ label: "Service Requests", value: "service-requests" });
  tabs.push({ label: "Orders", value: "orders" });
  tabs.push({ label: "Service Orders", value: "service-orders" });
  //tabs.push({label: "Proposals", value: "proposals"});
  //tabs.push({label: "Tasks", value: "tasks"});
  //tabs.push({label: "Time Log", value: "time-log"});

  return (
    <ChatWindow>
      <HeaderText label={name} buttons={buttons} />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 15 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ? (
        <Section>
          <HeaderText subHeader label="Overview:" />

          <FlexRow>
            <LabelItem label="Business Sector:" value={getLabelItem(serviceTypes, businessSector)} />
            <LabelItem label="Budget:" value={money(budget)} />
          </FlexRow>

          <FlexRow>
            <LabelItem label="Orders:" value={orderCount} />
            <LabelItem label="Service Orders:" value={serviceOrderCount} />
            <LabelItem label="Service Requests:" value={serviceRequestCount} />
            <LabelItem label="Team Members:" value={memberCount} />
          </FlexRow>

          <FlexRow>
            <LabelItem label="New Proposals:" value={proposalCount} />
            <LabelItem label="Spent:" value={money(spent)} />
            <LabelItem label="Pending Spend:" value={money(pendingSpend)} />
          </FlexRow>
        </Section>
      ) : null}

      {tabValue == "team" ? (
        <Section>
          <HeaderText
            subHeader
            label="Team:"
            description="Add member to your project team."
            onButtonPress={
              role == "admin"
                ? () => {
                  setState({ ...state, newTeamMemberVisible: true });
                }
                : null
            }
          />

          <FlexRow>
            {members.length == 0 ? (
              <NoRecords label="No team members found." />
            ) : (
              members.map((item, index) => {
                const { user, status, email, id, role } = item;
                return (
                  <BoxRowItem
                    style={{ cursor: "pointer", height: 95, marginRight: 10, marginBottom: 10 }}
                    onPress={() => {
                      //show the profile of this person (?)
                    }}>
                    <FlexRow>
                      <FlexExpand>
                        {user ? (
                          <UserItem avatarSize={65} user={user}>
                            <FlexRow>
                              <TextLine value={role} uppercase size={10} spacer />
                              {status == "pending" ? <TextLine value="Pending" size={12} /> : null}
                            </FlexRow>
                          </UserItem>
                        ) : (
                          <View>
                            <TextLine value={email} />
                            <FlexRow>
                              <TextLine value={role} uppercase size={10} spacer />
                              {status == "pending" ? <TextLine value="Pending" size={12} /> : null}
                            </FlexRow>
                          </View>
                        )}
                      </FlexExpand>

                      {(user && user.id != session.user.id) || !user ? (
                        <TrashIcon
                          style={{ marginLeft: 25 }}
                          onPress={() => {
                            db.getObj("Member", id)
                              .set("removed", true)
                              .save();
                            members.splice(index, 1);
                            setState({ ...state, members });
                          }}
                        />
                      ) : null}
                    </FlexRow>
                  </BoxRowItem>
                );
              })
            )}
          </FlexRow>
        </Section>
      ) : null}

      {tabValue == "service-requests" ? <ServiceRequestsBuyer projectId={projectId} /> : null}

      {tabValue == "proposals" ? <View>See the proposals for this project</View> : null}

      {tabValue == "orders" ? <OrdersBuyer projectId={projectId} /> : null}

      {tabValue == "service-orders" ? <ServiceOrdersBuyer projectId={projectId} /> : null}

      {tabValue == "tasks" ? <View>See the tasks in this</View> : null}

      {tabValue == "time-logs" ? <View>See the time-logs in this project</View> : null}

      {newTeamMemberVisible ? (
        <InviteMember
          projectId={projectId}
          onAddEmail={({ email }) => {
            return db
              .getObj("Member")
              .set("email", email)
              .set("project", db.getObj("Project", projectId))
              .set("createdBy", db.getObj("User", session.user.id))
              .set("status", "pending")
              .save();
          }}
          onAddUser={user => {
            const { id } = user;
            return db
              .getObj("Member")
              .set("user", db.getObj("User", id))
              .set("project", db.getObj("Project", projectId))
              .set("createdBy", db.getObj("User", session.user.id))
              .set("status", "pending")
              .save();
          }}
          onCancel={() => {
            setState({ ...state, newTeamMemberVisible: false });
          }}
          onDone={() => {
            setState({ ...state, newTeamMemberVisible: false });
            setRefresh(new Date());
          }}
        />
      ) : null}
      {projectVisible ? (
        <EditProject
          projectId={projectId}
          onCancel={() => {
            setProjectVisible(false);
          }}
          onSave={() => {
            setProjectVisible(false);
            setRefresh(new Date());
          }}
        />
      ) : null}
    </ChatWindow>
  );

  //show the project details
  //show the project members
  //stats: open costs, budget, used $
  //project manager
  //open: service requests, proposals, orders, service orders, tasks, time log
}

export function InviteMember({ projectId, onCancel, onAddUser, onAddEmail, onDone }) {
  const [state, setState] = useState({ inviteUsers: [] });
  const { inviteUsers, inviteEmails, inviteMessage } = state;

  return (
    <ModalBasic
      title="Invite Member"
      okText="Invite"
      onCancel={onCancel}
      onOk={() => {
        if (inviteUsers.length == 0 && !inviteEmails) {
          alert("Must select an user.");
          return;
        }

        const sendEmail = function ({ email, id }) {
          var message = "Hi,<br><br>You are invite to join a Growly platform chat by " + session.user.firstName + " " + session.user.lastName + ".<br><br>" + inviteMessage + "<br><br>Follow the following link to join the chat: http://growly.app/user/project/" + projectId + "/" + id;
          console.log(message);
          return http.run("sendEmail", {
            to: email,
            subject: "New Invitation",
            message: message,
            fromEmail: "support@interviewxpress.com"
          });
        };

        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              if (!utils.isEmail(email)) {
                alert(email + " is not a valid email.");
                return;
              }
            }
          }
        }

        const promises = [];
        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              promises[promises.length] = onAddEmail({ email }).then(function (obj) {
                return sendEmail({ email, id: obj.id });
              });
            }
          }
        }

        inviteUsers.forEach(user => {
          const { email } = user;

          promises[promises.length] = onAddUser(user).then(function (obj) {
            return sendEmail({ email, id: obj.id });
          });
        });

        return Promise.all(promises).then(function () {
          return onDone();
        });
      }}>
      <FormItem label="Add members:" required>
        <SelectUsers
          excludedValues={[...inviteUsers, session.user]}
          onChange={items => {
            setState({ ...state, inviteUsers: items });
          }}
          value={inviteUsers}
        />
      </FormItem>

      <TextLine bold bottom={25} value="OR" />
      <MyInput
        multiline
        label="Emails:"
        description="Enter one email per line to invite."
        value={inviteEmails}
        onChange={value => {
          setState({ ...state, inviteEmails: value });
        }}
      />
      <MyInput
        lastItem
        multiline
        label="Email Message:"
        description="Enter a short message in the email invite body."
        value={inviteMessage}
        onChange={value => {
          setState({ ...state, inviteMessage: value });
        }}
      />
    </ModalBasic>
  );
}

export function Projects() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [projectVisible, setProjectVisible] = useState();
  const refC = useRef();

  const buttons = [
    {
      label: "Add",
      onPress: () => {
        setProjectVisible(true);
      }
    }
  ];

  return (
    <React.Fragment>
      {projectVisible ? (
        <NewProject
          clientId={session.user.id}
          onCancel={() => {
            setProjectVisible(false);
          }}
          onSave={() => {
            setProjectVisible(false);
            refC.current.refresh();
          }}
        />
      ) : null}

      <ListRender
        setRef={ref => {
          refC.current = ref;
        }}
        searchFields={[{ field: "name" }]}
        defaultSort={{ field: "createdAt", desc: true }}
        onWhere={query => {
          const userId = session.user.id;
          query.equalToAO("users", dL.getObj("User", userId));
        }}
        buttons={buttons}
        type="Project"
        emptyLabel="No projects created."
        includes={["projectManager", "users"]}
        title="Projects"
        description="Create and manage your business initiative projects."
        renderItem={item => {
          const { id } = item;

          return (
            <RenderProjectItem
              key={id}
              item={item}
              onPress={() => {
                history.push(`/${sectionId}/project/${id}`);
              }}
            />
          );
        }}
      />
    </React.Fragment>
  );
}

function RenderProjectItem({ item, onPress }) {
  const [state, setState] = useState({ isLoading: true });

  const { name, id, users, projectManager, projectType, budget, businessSector } = item;
  const { proposalCount } = state;

  useEffect(() => {
    const newState = { ...state };
    const promises = [];

    promises[promises.length] = dL
      .getQuery("ServiceProposal")
      .equalTo("project", dL.getObj("Project", id))
      .containedIn("removed", [undefined, false])
      .count()
      .then(function (count) {
        newState.proposalCount = count;
      });
      
      //display the spend
      //number of open service request
      //number of open service orders
      //number of team members (avatars only)

    Promise.all(promises).then(function () {
      setState(newState);
    });
  }, []);

  // + ", " + getLabelItem(projectTypes, projectType)
  return (
    <BoxRowItem>
      <FlexRow alignTop>
        <FlexExpand>
          <TextLine size={22} value={name} onPress={onPress} />
          {businessSector ? <TextLine grey top={6} size={13} value={getLabelItem(serviceTypes, businessSector)} /> : null}
          {budget ? <TextLine grey top={6} size={13} label="Budget:" value={money(budget)} /> : null}

          <View top={8}>
            <FlexRow>
              <View right={25}>
                <TextLine size={12} bottom={3} value="Project Leader:" />
                {projectManager ? <UserItem user={projectManager} /> : null}
              </View>

              <View>
                <TextLine size={12} bottom={3} value="Team Members:" />

                <FlexRow>
                  {users.map(user => (
                    <UserItem user={user} style={{ marginRight: 15 }} />
                  ))}
                </FlexRow>
              </View>
            </FlexRow>
          </View>
        </FlexExpand>

        {proposalCount ? <LabelItem inline label="New Proposals:" value={proposalCount} /> : null}
      </FlexRow>
    </BoxRowItem>
  );
}
