import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import colors from "root/colors";
import Moment from "moment-business-days";
import { Switch, Route, useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { HashTable } from "root/hashtable";
import { RenderClientInputRequirements } from "root/forms";
import http from "root/utils/http";
import { EditKeyUser, DisplayKeyUser, RenderLimitedOptions, RenderLimitedPackages, Quantity, Price } from "root/pack-3";
import { __DEV__ } from "root/dev";
import { StaffOrderStats } from "root/staff-aug"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactSlider from "react-slider"
import { getTotalTimeline, RenderStartDoneDates, getTimelineBasicData, getTimelineData, RenderVisualTimeline } from "root/services"
import { getMainServiceModel, getMainProposalModel2, getMainProposalModel } from "root/service-model"
import { SelectUsers, BasicTop, SelectOptions, BoxRowItem, HeaderText, LabelUserItem, UserItem } from "root/pack-2";
import { MyInput, DragHandle, SortableList, ItemExpandSection, ItemExpand, RenderIcon, DateTimeItem, BoxItem4, NoRecordsBox, DeleteConfirm, Section, ModalBasic, BoxItem, LabelItem, LinkButton, Hide, FlexExpand, FlexRow, TabBar, Text, TextLine, View, UnDeleteIcon, TrashIcon, EditIcon, CheckIcon, FormItem, TouchButton, NoRecords } from "root/pack-1";
import { createProposalItemPriceMap, getDeliveryDaysAll, getStartDate, getDoneDateAll, getDoneDate, getSubServicesForService, getDataFromPriceMap, createProposalPriceMapBuyer, getFinalPriceDays2, getPriceMapFromPriceMap, createProposalPriceMap, canDisplay, getTasksForService, getWorkHours, getServicePrice2, calcStartDayIndex } from "root/service-functions";
import { ChatWindow, getChatRoomsWorker, getChatRoomsBuyer } from "root/chat";
import { EditProposalServiceBuyer, EditStaffInCart, EditServiceInCart, SaveProposalTemplate, EditProposalOption, ViewProposalStaffServiceItem, ViewProposalServiceItem, EditProposalPackage, EditProposalStaff, EditProposalService, EditProposalStage, EditProposalStageStep } from "root/proposals-1";
import Datetime from "react-datetime";
import { RenderOrderWorker } from "root/orders"
import { RenderCartItems } from "root/cart"
import { ViewSubService } from "root/services-worker"
import { RenderWorkConfig, RenderServiceNeedsUpdate, EditServiceSubServiceConfigBuyer, EditServiceSubServiceConfig } from "root/services"
import { RestOfTeam } from "root/users"

const { roundMoney, profitMargins, getValueItem, roundNumber, updateCartItem, getAllItemsForProposal, getAllItemsForVersion, getDeleteRemovedItems, pluralText, pluralTab, plural, getLabelItem, money, skillLevels, staffServiceTypes } = utils;

export function getPaymentType(totalMonths) {
  const paymentTermTypes = []

  if (totalMonths > 1) {
    paymentTermTypes.push({ label: "All At Once", value: "once" })
    paymentTermTypes.push({ label: "Monthly", value: "monthly" })
    paymentTermTypes.push({ label: "Monthly - Equal", value: "monthly-equal" })
  }

  if (totalMonths >= 3) {
    paymentTermTypes.push({ label: "Quarterly", value: "quarterly" })
  }

  if (totalMonths >= 12) {
    paymentTermTypes.push({ label: "Yearly", value: "yearly" })
  }
  return paymentTermTypes
}

function getData1({ proposal, proposalItem }) {
  const { stageId, stageItemId } = proposalItem;
  const { allItems, strategyConsultant, projectManager } = proposal;

  var sc = strategyConsultant;
  var pm = projectManager;
  if (stageId) {
    if (stageItem.projectManager) {
      pm = stageItem.projectManager;
    }

    const stageItem = allItems.get(stageItemId);
    if (stageItem.strategyConsultant) {
      sc = stageItem.strategyConsultant;
    }
  }

  return { pm, sc };
}

export function getCartItemFromProposalItem({ proposal, proposalItem, proposalVersion }) {
  const path = `p-item[${proposalItem.id}]`
  const { buyerPriceMap } = proposal
  const { priceMap: pPriceMap } = proposalVersion
  const priceMap = buyerPriceMap ? buyerPriceMap : pPriceMap
  var mapItem = priceMap.find(item => item.path == path)
  if (buyerPriceMap && !mapItem) {
    mapItem = pPriceMap.find(item => item.path == path)
  }

  const { buyerMasterOverrides: masterOverrides } = proposal
  const overrides = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

  const { service, userService, userServiceVersion, serviceVersion, actualVersionType, packageId, deliveryType, actualServiceVersion, deliveryDays, quantity, unitPrice, serviceOptionIds } = overrides ? overrides : proposalItem

  const total = unitPrice * quantity

  const { packages, options } = actualServiceVersion

  const serviceOptions = options.filter((item) => serviceOptionIds[item.option.id]);
  const servicePackage = packages.find(item => item.package.id == packageId)

  const { id, itemType, startDate, inputData } = proposalItem;

  const { workerUsers, staffServiceType, skillLevel, businessRole, hoursPerMonth, monthlyCost, finalBillRate, baseBillRate, overageBillRate, workerRate, maxRolloverPerMonth, maxPullForwardHours, maxRollOverHours, numberOfMonths, endDate, priorityLevel, allowPullForward, allowRollover } = proposalItem;

  const { pm, sc } = getData1({ proposal, proposalItem });

  var itemPriceMap
  if (itemType == "service") {
    itemPriceMap = getPriceMapFromPriceMap({ priceMap: buyerPriceMap ? buyerPriceMap : pPriceMap, path: `p-item[${id}]` })
  }

  const cartItem = {
    id: utils.guid(),
    itemType,
    quantity,
    unitPrice: total,
    total: total * quantity,

    projectManager: pm,
    strategyConsultant: sc,

    priceMap: itemPriceMap,
    proposal,
    proposalItem,

    startDate,

    service,
    userService,
    userServiceVersion,
    serviceVersion,
    actualServiceVersion,
    actualVersionType,
    userRole: userService ? userService.userRole : null,
    assignedTo: userService ? userService.user : null,
    servicePackage,
    serviceOptions,
    serviceOptionIds,
    inputData,
    deliveryType,
    deliveryDays,

    staffServiceType,
    skillLevel,
    businessRole,
    hoursPerMonth,
    monthlyCost,
    finalBillRate,
    baseBillRate,
    overageBillRate,
    workerRate,
    maxRolloverPerMonth,
    maxPullForwardHours,
    maxRollOverHours,
    numberOfMonths,
    startDate,
    endDate,
    priorityLevel,
    allowPullForward,
    allowRollover,
    workerUsers
  };

  return cartItem;
}

export function addProposalItemToCart({ proposal, proposalItem, proposalVersion }) {
  const cartItem = getCartItemFromProposalItem({ proposal, proposalItem, proposalVersion })

  /*
  const dt = Object.assign({}, proposalItem);
  const { id, userRole, service, servicePackage, userService, serviceOptions, deliveryType, deliveryDays, startDate, total, inputData, assignedTo, serviceOptionIds } = dt;

  const { workerUsers, staffServiceType, skillLevel, businessRole, hoursPerMonth, monthlyCost, finalBillRate, baseBillRate, overageBillRate, workerRate, maxRolloverPerMonth, maxPullForwardHours, maxRollOverHours, numberOfMonths, endDate, priorityLevel, allowPullForward, allowRollover } = dt;

  const { pm, sc, qty } = getData1({ proposal, proposalItem });

  const { priceMap: pPriceMap } = proposalVersion
  const { buyerPriceMap } = proposal
  const priceMap = getPriceMapFromPriceMap({ priceMap: buyerPriceMap ? buyerPriceMap : pPriceMap, path: `p-item[${id}]` })

  const cartItem = {
    projectManager: pm,
    strategyConsultant: sc,

    priceMap,
    proposal,
    proposalItem,

    startDate,

    service,
    userService,
    userRole,
    assignedTo,
    servicePackage,
    serviceOptions,
    serviceOptionIds,
    inputData,
    deliveryType,
    deliveryDays,

    staffServiceType,
    skillLevel,
    businessRole,
    hoursPerMonth,
    monthlyCost,
    finalBillRate,
    baseBillRate,
    overageBillRate,
    workerRate,
    maxRolloverPerMonth,
    maxPullForwardHours,
    maxRollOverHours,
    numberOfMonths,
    startDate,
    endDate,
    priorityLevel,
    allowPullForward,
    allowRollover,
    workerUsers,

    quantity: qty,
    unitPrice: total,
    total: total * qty
  };
*/

  const foundCartItem = session.cart.items.find(item2 => item2.proposalItem && item2.proposalItem.id == proposalItem.id);

  if (foundCartItem) {
    cartItem.id = foundCartItem.id;
  }

  updateCartItem(cartItem);
}

export function updateTotals(thisVersion, proposal, userSelected) {
  const { allItems, buyerPriceMap } = proposal;
  const { items, priceMap } = thisVersion;

  const finalPriceMap = buyerPriceMap ? buyerPriceMap : priceMap

  if (!userSelected) {
    userSelected = {};
  }

  var finalTotal = 0;
  var finalDeliveryDays = 0;

  items.map(item => {
    const { items } = item;
    const proposalItem = allItems.get(item.id);
    const { id, itemType } = proposalItem;

    const path = `p-item[${id}]`
    //const overrideItem = buyerMasterOverrides ? buyerMasterOverrides.find(item = item.proposalItem.id == id) : null
    const { price: unitPrice, days: deliveryDays } = getDataFromPriceMap({ priceMap: finalPriceMap, path, includeRoot: true })

    if (itemType == "service" || itemType == "staff") {
      const { recommended, quantity: qty } = proposalItem;
      //const overrideUnitPrice = overridePrice != null ? overridePrice : overrides ? overrides[id + "-price"] : null
      //const unitPrice = overrideUnitPrice ? overrideUnitPrice : defaultUnitPrice
      //const qty = buyerOverrides[id + "qty"] ? buyerOverrides[id + "qty"] : quantity ? quantity : 1;

      if (userSelected ? (userSelected[id] != null ? userSelected[id] : false) : recommended) {
        finalTotal += unitPrice * qty;
        if (deliveryDays && deliveryDays > finalDeliveryDays) {
          finalDeliveryDays = deliveryDays;
        }
      }
    } else if (itemType == "stage") {
      var stageTotal = 0;
      var stageDeliveryDays = 0;

      items.forEach(item => {
        const { id, items } = item;
        const stageItem = allItems.get(id);
        var itemTotal = 0;
        var itemDeliveryDays = 0;

        items.forEach(item => {
          const serviceItem = allItems.get(item.id);
          const { id, recommended, quantity: qty } = serviceItem;
          const { price: unitPrice, days: deliveryDays } = getDataFromPriceMap({ priceMap: finalPriceMap, path, includeRoot: true })
          const total = unitPrice * qty

          //const { id, overridePrice, unitPrice: defaultUnitPrice, total, deliveryDays, recommended, quantity } = serviceItem;
          //const overrideUnitPrice = overridePrice != null ? overridePrice : overrides ? overrides[id + "-price"] : null
          //const unitPrice = overrideUnitPrice ? overrideUnitPrice : defaultUnitPrice
          //const qty = buyerOverrides[id + "qty"] ? buyerOverrides[id + "qty"] : quantity ? quantity : 1;

          if (userSelected ? (userSelected[id] != null ? userSelected[id] : false) : recommended) {
            finalTotal += unitPrice * qty;
            if (deliveryDays && deliveryDays > finalDeliveryDays) {
              finalDeliveryDays = deliveryDays;
            }

            stageTotal += total;
            if (deliveryDays && deliveryDays > stageDeliveryDays) {
              stageDeliveryDays = deliveryDays;
            }

            itemTotal += total;
            if (deliveryDays && deliveryDays > itemDeliveryDays) {
              itemDeliveryDays = deliveryDays;
            }
          }
        });

        stageItem.finalTotal = itemTotal;
        stageItem.finalDeliveryDays = itemDeliveryDays;
      });

      proposalItem.finalTotal = stageTotal;
      proposalItem.finalDeliveryDays = stageDeliveryDays;
    }
  });

  proposal.finalTotal = finalTotal;
  proposal.finalDeliveryDays = finalDeliveryDays;
}

export function getAllTeam(proposal) {
  const { currentProposalVersion, allItems } = proposal;
  const arrIds = {};

  if (currentProposalVersion) {
    const { items } = currentProposalVersion;

    items.forEach(item => {
      const { id, items } = item;
      const proposalItem = allItems.get(id);
      const { itemType } = proposalItem;
      if (itemType == "service") {
        const { assignedTo } = proposalItem;
        if (assignedTo) {
          arrIds[assignedTo.id] = assignedTo;
        }
      } else if (itemType == "staff") {
        const { assignedTo } = proposalItem;
        if (assignedTo) {
          arrIds[assignedTo.id] = assignedTo;
        }
      } else if (itemType == "stage") {
        const { projectManager } = proposalItem;
        if (projectManager) {
          arrIds[projectManager.id] = projectManager;
        }

        items.forEach(item => {
          const { id, items } = item;
          const stageItem = allItems.get(id);
          const { strategyConsultant } = stageItem;
          if (strategyConsultant) {
            arrIds[strategyConsultant.id] = strategyConsultant;
          }
          items.forEach(item => {
            const { id } = item;
            const serviceItem = allItems.get(id);
            const { assignedTo } = serviceItem;
            if (assignedTo) {
              arrIds[assignedTo.id] = assignedTo;
            }
          });
        });
      }
    });
  }

  const arr = [];
  for (var key in arrIds) {
    arr.push(arrIds[key]);
  }

  return arr;
}

export function RenderPreReq({ proposal, services }) {
  const { allItems } = proposal;
  return services ? (
    <FlexRow top={12}>
      <TextLine color="grey" size={13} value="Pre-Required:" right={5} />
      {services.map((pId) => {
        const item = allItems.get(pId);
        const { stageId, stageItemId, service } = item;

        return (
          <FlexRow style={{ marginRight: 10 }}>
            {stageId ? <TextLine color="grey" size={13} value={allItems.get(stageId).name + ": " + allItems.get(stageItemId).name} /> : null}
            <TextLine color="grey" size={13} value={service.name} style={{ margin: stageId ? 10 : 0 }} />
          </FlexRow>
        );
      })}
    </FlexRow>
  ) : null;
}

export function ProposalCheckmark({ disabled, checked, onChecked, tooltip }) {
  return (
    <CheckIcon
      disabled={disabled}
      style={{ marginTop: 10, textAlign: "right" }}
      checked={checked}
      onChecked={onChecked}
      tooltip={tooltip}
      onPress={() => {
        if (!checked) {
          onChecked(true);
        } else {
          onChecked(false);
        }
      }}
    />
  );
}

function RenderProposalService({ priceMap, thisVersion, deleted, historicalProposalVersion, disableCheck, proposal, proposalItem, onChecked, onEdit, onDelete, onPress, checked, onUpdate, serviceOrders, onUnDelete, onUpdateOverrides }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { addItem } = state;

  const path = `p-item[${proposalItem.id}]`

  //const mapItem = priceMap.find(item => item.path == path)
  const { actualVersionType, packageId, serviceOptionIds } = proposalItem// mapItem.item

  const { inCart, quantity, overridePrice, service, deliveryType, id, servicePackage, delayStart, assignedTo, requiredPreServices, limitedToPackages, limitedToOptions, actualServiceVersion } = proposalItem;
  const { userService, name, options } = actualServiceVersion
  const serviceOptions = options.filter((item) => serviceOptionIds[item.option.id]);

  const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

  const circularIds = {}
  circularIds[service.id] = true

  const purchased = serviceOrders ? serviceOrders.filter(item => item.proposalItem.id == proposalItem.id) : null

  const rtn = getMainServiceModel({
    configData: proposalItem
  })

  const getPriceDays = function ({ serviceVersion }) {
    const rtn = getMainServiceModel({
      configData: { ...proposalItem, actualServiceVersion: serviceVersion }
    })

    const days = rtn.getTotalDeliveryDays()
    const total = rtn.getTotalPrice()
    return { total, days }
  }

  const deliveryDays = rtn.getTotalDeliveryDays()
  const unitPrice = rtn.getTotalPrice()
  const startDate = rtn.getStartDate()
  const doneDate = rtn.getDoneDate()
  const total = quantity * unitPrice

  return (
    <RenderRow key={id} onEdit={onEdit} onDelete={onDelete} onUnDelete={onUnDelete}>
      <BoxItem render={<View>
        <RenderServiceNeedsUpdate
          style={{ marginTop: 10 }}
          configData={proposalItem}
          usingVersion={actualServiceVersion}
          currentVersion={actualVersionType == "worker" ? userService.currentVersion : service.currentVersion}
          price={total}
          days={deliveryDays}
          onGetVersionPriceDays={(serviceVersion) => {
            return getPriceDays({ serviceVersion })
          }}
          onUpdateNewest={() => {
            onEdit()
          }}
        />
        {assignedTo ? <BoxItem><UserItem user={assignedTo} label="Consultant:" /></BoxItem> : null}

        {serviceOptions && serviceOptions.length > 0 ? (
          <View style={{ marginLeft: 15, marginTop: 8 }}>
            {serviceOptions.map(item => {
              const { name } = item;
              return <TextLine size={16} value={"+ " + name} />;
            })}
          </View>
        ) : null}

        <RenderPreReq proposal={proposal} services={requiredPreServices} />

        {!historicalProposalVersion ? (
          inCart ? (
            <TouchButton
              onPress={() => {
                proposalItem.inCart = false
                dL.saveProposalItem({ item: proposalItem })
                setState({ ...state });
              }}
              micro
              grey
              description="Remove this items from the cart."
              label="Remove from Cart"
            />
          ) : (
            <TouchButton
              onPress={() => {
                setState({ ...state, addItem: true });
              }}
              micro
              description="Add this service to the cart."
              label="Add to Cart"
            />
          )
        ) : null}

        {subServices.length > 0 ? <View top={15}>
          <ItemExpandSection label={plural(subServices.length, "Sub Service") + ":"} >
            {subServices.map(subServiceVersion => {
              return <DisplaySubServiceItem
                proposalItem={proposalItem}
                key={id}
                rtn={rtn}
                circularIds={circularIds}
                rootProposalVersion={thisVersion}
                historicalProposalVersion={historicalProposalVersion}
                onUpdateOverrides={onUpdateOverrides}
                subServiceVersion={subServiceVersion}
              />
            })}
          </ItemExpandSection>
        </View> : null}
      </View>}>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine onPress={onPress} strike={deleted} onPress={onPress} bold size={22} value={name} />
            {servicePackage ? <TextLine color="grey" size={16} label="Package:" value={servicePackage.name} /> : null}

            <RenderStartDoneDates deliveryType={deliveryType} startDate={startDate} doneDate={doneDate} />
          </FlexExpand>

          <Quantity
            value={quantity}
            allowAdjust={!deleted}
            onUpdate={(value) => {
              const quantity = value
              const total = unitPrice * quantity
              proposalItem.quantity = quantity
              proposalItem.total = total
              proposalItem.priceMap = createProposalItemPriceMap({ proposalVersion: thisVersion, proposalItem, serviceVersion: actualServiceVersion, deliveryType, packageId, serviceOptionIds })
              onUpdateOverrides()

              dL.getObj("ServiceProposalItem", proposalItem.id)
                .set("quantity", quantity)
                .set("total", total)
                .save()

              setState({ ...state })
            }}
          />

          <View style={{ width: 115, display: "flex", flexDirection: "column", alignItems: "flex-end", alignSelf: "stretch", marginBottom: 0 }}>
            <FlexRow alignRight>
              <Price price={total} days={deliveryDays} size={18} hasOverride={overridePrice != null} />
            </FlexRow>
            <View style={{ flex: 1 }} />
            <ProposalCheckmark disabled={disableCheck} checked={checked} onChecked={onChecked} tooltip="Make this service recommended." />
          </View>
        </FlexRow>

        <RenderLimitedPackages packages={thisVersion.packages} value={limitedToPackages} />
        <RenderLimitedOptions options={thisVersion.options} value={limitedToOptions} />

        {addItem ? (
          <RenderAddToCartButtons
            thisVersion={thisVersion}
            proposal={proposal}
            proposalItem={proposalItem}
            onAddToCart={() => {
              proposalItem.inCart = true
              dL.saveProposalItem({ item: proposalItem })
              setState({ ...state, addItem: false });
            }}
            onClose={() => {
              setState({ ...state, addItem: false });
            }}
            onUpdate={() => {
              onUpdate();
            }}
          />
        ) : null}

        {purchased && purchased.length > 0 ? (
          <View style={{ marginTop: 25 }}>
            <TextLine bold value="Purchased Service Orders:" />

            {purchased.map(serviceOrder => {
              const { orderNumber, orderDate, total, id, status } = serviceOrder;

              return (
                <BoxItem
                  name={"Ref #: " + orderNumber}
                  onPress={() => {
                    history.push(`/${sectionId}/service-order/${id}`);
                  }} rightRender={<Price price={total} />}>
                  {Moment(orderDate).format("M/D/YY")} {status}
                </BoxItem>
              );
            })}
          </View>
        ) : null}
      </BoxItem>
    </RenderRow>
  );
}

export function RenderProposalStaff({ thisVersion, recommended, deleted, historicalProposalVersion, disableCheck, allowAdjustQty, isBuyerView, proposal, checked, proposalItem, onChecked, onEdit, onDelete, onPress, onUpdate, onUnDelete, staffOrders, onUpdateOverrides }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { addItem } = state;

  const { status, expireDate } = proposal;
  const isClosed = status != "open" || new Date() >= expireDate;

  const { recommendedUser, staffServiceType, workerUsers, total, id, unitPrice, businessRole, quantity, assignedTo, requiredPreServices, hoursPerMonth, limitedToPackages, limitedToOptions, skillLevel, numberOfMonths } = proposalItem;

  const v1 = getAllItemsForProposal({ proposal, proposalVersion: thisVersion })
  const startDate = getDoneDate(v1, proposalItem);
  const doneDate = getDoneDate(v1, proposalItem);

  const purchased = staffOrders ? staffOrders.filter(item => item.proposalItem.id == proposalItem.id) : null

  return (
    <RenderRow key={id} onEdit={onEdit} onDelete={onDelete} onUnDelete={onUnDelete}>
      <BoxRowItem onPress={onPress}>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine strike={deleted} onPress={onPress} bold size={22} value={businessRole.name} />

            <FlexRow>
              {staffServiceType != "staff" ? <TextLine color="grey" label="Type:" value={getLabelItem(staffServiceTypes, staffServiceType)} spacer /> : null}
              <TextLine color="grey" label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} />
            </FlexRow>

            <TextLine color="grey" top={8} size={14} label="Terms:" value={plural(hoursPerMonth, "hr") + " per month for " + plural(numberOfMonths, "month")} />

            <FlexRow>
              <TextLine color="grey" size={14} label="Start Date:" value={Moment(startDate).format("M/D/YYYY")} spacer />
              <TextLine color="grey" size={14} label="End Date:" value={Moment(doneDate).format("M/D/YYYY")} />
            </FlexRow>

            <View style={{ height: 12 }} />
            <RenderPreReq proposal={proposal} services={requiredPreServices} />

            <View style={{ height: 12 }} />

            {assignedTo ? <UserItem label="Selected Consultant:" user={assignedTo} /> : null}

            {recommendedUser && recommendedUser.id != assignedTo.id ? <UserItem label="Recommended Consultant:" user={recommendedUser} /> : null}

            {workerUsers && workerUsers.length > 0 ? <TextLine color="grey" size={12} value={plural(workerUsers.length + (recommendedUser.id == assignedTo.id ? 0 : 1), "other consultant") + " recommended"} top={8} /> : null}
          </FlexExpand>

          <Quantity
            value={quantity}
            allowAdjust={allowAdjustQty}
            onUpdate={value => {
              proposalItem.quantity = value
              proposalItem.total = unitPrice * value
              onUpdateOverrides()

              dL.getObj("ServiceProposalItem", proposalItem.id)
                .set("quantity", proposalItem.quantity)
                .set("total", proposalItem.total)
                .save()

              setState({ ...state })
            }}
          />

          <View style={{ width: 115, display: "flex", flexDirection: "column", alignItems: "flex-end", alignSelf: "stretch", marginBottom: 0 }}>
            <Price price={total} size={18} />
            <View style={{ flex: 1 }} />
            <ProposalCheckmark disabled={disableCheck} checked={checked} onChecked={onChecked} />
          </View>
        </FlexRow>

        <RenderLimitedPackages packages={thisVersion.packages} value={limitedToPackages} />
        <RenderLimitedOptions options={thisVersion.options} value={limitedToOptions} />

        {isBuyerView ? (
          <FlexRow top={10}>
            {!isClosed && !historicalProposalVersion ? (
              <TouchButton
                onPress={() => {
                  setState({ ...state, addItem: true });
                }}
                micro
                description="Add this staff to the cart."
                label="Add to Cart"
              />
            ) : null}
            {recommended ? <TextLine left={15} bold size={12} color="red" value="Recommended" /> : null}
          </FlexRow>
        ) : null}

        {addItem ? (
          <RenderAddToCartButtons
            thisVersion={thisVersion}
            proposal={proposal}
            proposalItem={proposalItem}
            onAddToCart={() => {
              onChecked(false);
              setState({ ...state, addItem: false });
            }}
            onClose={() => {
              setState({ ...state, addItem: false });
            }}
            onUpdate={() => {
              onUpdate();
            }}
          />
        ) : null}

        {purchased && purchased.length > 0 ? (
          <View style={{ marginTop: 25 }}>
            <TextLine bold>Last Purchased:</TextLine>
            {purchased.map(staffOrder => {
              const { serviceNumber, orderDate, total, id } = staffOrder;
              return (
                <LinkButton
                  onPress={() => {
                    history.push(`/${sectionId}/staff-service/${id}`);
                  }}>
                  {Moment(orderDate).format("M/D/YY") + ": Ref #" + serviceNumber + " " + money(total)}
                </LinkButton>
              );
            })}
          </View>
        ) : null}
      </BoxRowItem>
    </RenderRow>
  );
}

export function RenderProposalStage({ userSelected, thisVersion, items, proposal, children, readonly, proposalItem, onExpand, onEdit, onDelete, onUnDelete }) {
  const { icon, id, name, description, expanded, finalTotal, finalDeliveryDays, projectManager, limitedToPackages, limitedToOptions, requiredPreServices } = proposalItem;

  const onPress = function () {
    if (!expanded) {
      onExpand(true);
    } else {
      onExpand(false);
    }
  };

  var selectedCount = 0;
  var serviceCount = 0;
  var recommendedServiceCount = 0;
  items.forEach(item => {
    const items = getDeleteRemovedItems(thisVersion, item.items);
    recommendedServiceCount += items.filter(item => {
      const { id } = item;
      const { recommended } = proposal.allItems.get(id);
      if (userSelected && userSelected[id]) {
        selectedCount++;
      }
      return recommended;
    }).length;
    serviceCount += items.length;
  });

  const optionalCount = serviceCount - recommendedServiceCount;
  return (
    <View key={id}>
      <RenderRow readonly={readonly} onEdit={onEdit} onDelete={onDelete} onUnDelete={onUnDelete}>
        <BoxRowItem onPress={onPress}>
          <View>
            <FlexRow alignTop>
              <FlexExpand>
                <FlexRow>
                  <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} />
                  <View>
                    <TextLine onPress={onPress} bold size={22} style={{ flex: 1 }} value={name} />
                    <TextLine color="grey" size={14} top={4} value={description} />
                    {onPress ? <ItemExpand onPress={onPress} expanded={expanded} /> : null}
                  </View>
                </FlexRow>
                <TextLine color="grey" size={14} top={8} value={plural(items.length, "step") + ", " + recommendedServiceCount + " recommended" + (optionalCount ? ", " + optionalCount + " optional" : "") + pluralText(optionalCount ? optionalCount : recommendedServiceCount, " service")} spacer />
              </FlexExpand>

              {projectManager ? <UserItem user={projectManager} label="Project Manager:" style={{ marginTop: 15, marginLeft: 35 }} /> : null}

              <View alignRight>
                <Price style={{ width: 100 }} price={finalTotal} days={finalDeliveryDays} />

                {selectedCount ? <TextLine color="grey" size={14} top={8} value={selectedCount + " selected"} /> : null}
              </View>
            </FlexRow>

            <RenderPreReq proposal={proposal} services={requiredPreServices} />

            <RenderLimitedPackages packages={thisVersion.packages} value={limitedToPackages} />
            <RenderLimitedOptions options={thisVersion.options} value={limitedToOptions} />
          </View>
        </BoxRowItem>
      </RenderRow>

      <View style={{ marginLeft: 35 }}>{children}</View>
    </View>
  );
}

export function RenderProposalStepItem({ thisVersion, userSelected, proposal, readonly, selected, proposalItem, onPress, onEdit, onDelete, items, onUnDelete }) {
  const { requiredPreServices, icon, id, strategyConsultant, name, description, finalTotal, finalDeliveryDays, limitedToPackages, limitedToOptions } = proposalItem;

  var selectedCount = 0;
  var serviceCount = 0;
  var recommendedServiceCount = 0;

  recommendedServiceCount += items.filter(item => {
    const { id } = item;
    const { recommended } = proposal.allItems.get(id);
    if (userSelected && userSelected[id]) {
      selectedCount++;
    }
    return recommended;
  }).length;
  serviceCount += items.length;

  const optionalCount = serviceCount - recommendedServiceCount;

  return (
    <RenderRow key={id} readonly={readonly} onEdit={onEdit} onDelete={onDelete} onUnDelete={onUnDelete}>
      <BoxRowItem style={{ width: 250, minHeight: 105, marginBottom: 10, borderColor: selected ? colors.blue : null }} onPress={onPress}>
        <FlexRow alignTop>
          <FlexExpand>
            <FlexRow>
              <RenderIcon icon={icon} style={{ marginRight: 15, fontSize: 35 }} />
              <View>
                <TextLine onPress={onPress} bold size={16} value={name} />
              </View>
            </FlexRow>

            {serviceCount ? (
              <View>
                {recommendedServiceCount ? <TextLine color="grey" size={12} value={recommendedServiceCount + " recommended"} /> : null}
                {optionalCount ? <TextLine color="grey" size={12} value={optionalCount + " optional"} /> : null}
              </View>
            ) : null}
          </FlexExpand>

          <View alignRight>
            <Price size={18} size2={12} style={{ width: 100 }} price={finalTotal} days={finalDeliveryDays} />

            {selectedCount ? <TextLine color="grey" size={12} top={8} value={selectedCount + " selected"} /> : null}
          </View>
        </FlexRow>

        <TextLine color="grey" top={4} size={14} value={description} />

        {strategyConsultant ? <UserItem user={strategyConsultant} label="Strategy Consultant:" style={{ marginTop: 15 }} /> : null}

        <RenderPreReq proposal={proposal} services={requiredPreServices} />

        <RenderLimitedPackages packages={thisVersion.packages} value={limitedToPackages} />
        <RenderLimitedOptions options={thisVersion.options} value={limitedToOptions} />
      </BoxRowItem>
    </RenderRow>
  );
}

export function RenderRow({ children, readonly, onEdit, onDelete, onUnDelete }) {
  return (
    <FlexRow style={{ position: "relative", paddingRight: 35 }}>
      <FlexExpand>{children}</FlexExpand>

      {!readonly ? (
        <View style={{ position: "absolute", right: 0, top: 15 }}>
          <Hide>
            <SelectOptions options={[{ label: "Edit", value: "edit" }]} onChange={() => { }}>
              <FontAwesomeIcon icon={faCog} style={{ fontSize: 18, color: "#c0c0c0" }} />
            </SelectOptions>
          </Hide>

          <DragHandle style={{ marginBottom: 8 }} />
          {onEdit ? <EditIcon onPress={onEdit} tooltip="Edit this item." /> : null}
          {onDelete ? <TrashIcon onPress={onDelete} tooltip="Delete this item from this proposal version." /> : null}
          {onUnDelete ? <UnDeleteIcon onPress={onUnDelete} tooltip="Restore this item into this proposal version." /> : null}
        </View>
      ) : null}
    </FlexRow>
  );
}

export function RenderAddToCartButtons({ proposal, proposalItem, thisVersion, onClose, onAddToCart, onUpdate }) {
  const { itemType } = proposalItem;
  const cartItem = getCartItemFromProposalItem({ proposal, proposalItem, proposalVersion: thisVersion });

  return (
    <React.Fragment>
      {itemType == "staff" ? (
        <EditStaffInCart
          cartItem={cartItem}
          onCancel={onClose}
          onAddToCart={cartItem => {
            updateCartItem(cartItem);
            onUpdate();
            dL.saveCart();

            alert("Added to cart.");
            onAddToCart(cartItem);
          }}
          onSave={() => {
            onUpdate();
            onClose();
          }}
        />
      ) : itemType == "service" ? (
        <EditServiceInCart
          cartItem={cartItem}
          onCancel={onClose}
          onAddToCart={cartItem => {
            updateCartItem(cartItem);
            onUpdate();
            dL.saveCart();

            alert("Added to cart.");
            onAddToCart(cartItem);
          }}
          onSave={() => {
            onUpdate();
            onClose();
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

export function StepWrapper({ stepItems, stageItem, buttons, children }) {
  return (
    <View style={{ marginBottom: 35, marginTop: 25 }}>
      <HeaderText subHeader label={pluralTab({ list: stepItems, label: "Step" }) + " for " + stageItem.name + ":"} nameSmall="(click to view services)" style={{ marginBottom: 0 }} />

      {stepItems.length == 0 ? <NoRecordsBox label="No steps added.">{buttons}</NoRecordsBox> : children}
    </View>
  );
}

export function ServiceWrapper({ items, selectedStepItem, buttons, children }) {
  return (
    <View style={{ marginTop: 25, marginBottom: 35 }}>
      <HeaderText subHeader label={pluralTab({ list: items, label: "Service" }) + " for " + selectedStepItem.name + ":"} style={{ marginBottom: 0 }} />
      {items.length == 0 ? <NoRecordsBox label="No services added.">{buttons}</NoRecordsBox> : children}
    </View>
  );
}

export function ProposalOverview({ proposal, onButtonPress, isBuyerView }) {
  const { lastSentProposalVersion, currentProposalVersion, proposalDate, proposalNumber, objectives, description, additionalNotes, user, createdBy, serviceRequest, status, finalTotal, finalDeliveryDays } = proposal;

  const { versionNum, expireDate } = isBuyerView ? lastSentProposalVersion : currentProposalVersion;

  return (
    <React.Fragment>
      <Section>
        <HeaderText subHeader label="Strategy Overview:" buttonClass="btn-mn" buttonIcon="pencil-alt" buttonText="Edit" onButtonPress={onButtonPress} />
        <FlexRow>
          <LabelItem inline label="Strategy Date:" value={Moment(proposalDate).format("M/D/YYYY [at] h:mm a")} />

          <LabelItem inline label="Status:" value={utils.getServiceProposalStatus(status)} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Strategy #:" value={proposalNumber} />

          {serviceRequest ? <LabelItem label="Service Request #:" value={serviceRequest.requestNumber} /> : null}

          {user && !isBuyerView ? <LabelUserItem label="Client:" user={user} /> : null}

          {createdBy && (!user || user.id != createdBy.id) ? <LabelUserItem label="Submitted By:" user={createdBy} /> : null}

          <LabelItem label="Strategy Version:" value={"#" + versionNum} description="Current strategy map version" />

          <LabelItem label="Expires:" value={Moment(expireDate).format("M/D/YYYY [at] h:mm a")} />
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="Strategy Pricing:" />

        <FlexRow>
          <LabelItem label="Recommendation Total:" value={money(finalTotal)} />
          <LabelItem label="Delivery Days:" value={plural(finalDeliveryDays, "day")} />
        </FlexRow>
      </Section>

      {objectives || description ? (
        <Section>
          <HeaderText subHeader label="Strategy Insights:" buttonClass="btn-mn" buttonIcon="pencil-alt" buttonText="Edit" onButtonPress={onButtonPress} />
          {objectives ? <LabelItem label="Business Objectives:" value={objectives} /> : null}
          {description ? <LabelItem label="Strategy Intro:" value={description} /> : null}
        </Section>
      ) : null}

      {additionalNotes ? (
        <Section>
          <HeaderText subHeader label="Additional Notes/Terms and Conditions:" buttonClass="btn-mn" buttonIcon="pencil-alt" buttonText="Edit" onButtonPress={onButtonPress} />
          <TextLine value={additionalNotes} />
        </Section>
      ) : null}
    </React.Fragment>
  );
}

export function getProposalChatRoom({ proposal, isBuyerView }) {
  const { id, proposalNumber, user, projectManager, businessDevelopment, strategyConsultant } = proposal;

  const clientUsers = [user], growlyUsers = [projectManager, businessDevelopment, strategyConsultant, user]
  const chatRoomName = "Strategy #: " + proposalNumber;
  if (isBuyerView) {
    return getChatRoomsBuyer({ id, chatRoomName, clientUsers, growlyUsers })
  } else {
    return getChatRoomsWorker({ id, chatRoomName, clientUsers, growlyUsers })
  }
}

export function getProposalSelectedItems({ proposal, userSelected }) {
  const allItems = proposal.allItems.values();
  const selectedItems = [];
  const selectedRecommendItems = [];
  allItems.forEach(item => {
    const { id, recommended } = item;
    if (userSelected) {
      if (userSelected[id] && userSelected[id] == true) {
        selectedItems.push(item);
        if (recommended) {
          selectedRecommendItems.push(item);
        }
      }
    }
  });
  return { selectedItems, selectedRecommendItems: selectedRecommendItems };
}

export function RenderServiceBlocks({ priceMap, currentPVersion, historicalProposalVersion, onAddToCart, onReset, proposal, serviceOrders, userSelected, onRemove, isBuyerView }) {
  const thisVersion = historicalProposalVersion ? historicalProposalVersion : currentPVersion;
  const { expireDate, status } = proposal;
  const allItems = getDeleteRemovedItems(thisVersion, proposal.allItems.values());
  const allServiceItems = allItems.filter(item => item.itemType == "service" || item.itemType == "staff");
  const recommendItemsInProposal = allServiceItems.filter(item => item.recommended);
  const optionalItemsInProposal = allServiceItems.filter(item => !item.recommended);

  const getTotal2 = function (items) {
    var finalTotal = 0;
    items.forEach(proposalItem => {
      const path = `p-item[${proposalItem.id}]`

      const mapItem = priceMap.find(item => item.path == path)
      const { quantity } = mapItem.item
      const { price: unitPrice } = getDataFromPriceMap({ priceMap, path, includeRoot: true })
      const total = quantity * unitPrice
      finalTotal += total
    });
    return finalTotal;
  };

  const getTotal = function (items, overrides) {
    var finalTotal = 0;
    items.forEach(proposalItem => {
      const path = `p-item[${proposalItem.id}]`

      const mapItem = priceMap.find(item => item.path == path)
      const { quantity } = mapItem.item
      const { price: unitPrice } = getDataFromPriceMap({ priceMap, path, includeRoot: true })
      const total = quantity * unitPrice
      finalTotal += total
    });
    return finalTotal;
  };

  const recommendItemsInProposalCount = recommendItemsInProposal.length;
  const optionalItemsInProposalCount = optionalItemsInProposal.length;
  var purchasedItemsCount = 0;
  var recommendItemsPurchasedCount = 0;

  const { selectedItems, selectedRecommendItems } = getProposalSelectedItems({ proposal, userSelected });
  const selectedItemCount = selectedItems.length;
  const selectedRecommendItemCount = selectedRecommendItems.length;

  const ids = {};
  if (serviceOrders) {
    serviceOrders.forEach(item => {
      const { proposalItem } = item;

      if (proposalItem) {
        const { id, recommended } = proposalItem;

        if (!ids[id]) {
          ids[id] = true;
          purchasedItemsCount++;

          if (recommended) {
            recommendItemsPurchasedCount++;
          }
        }
      }
    });
  }

  const render0 = function () {
    var newRecommended = 0;
    var newItems = 0;

    proposal.allItems.values().forEach(item => {
      const { proposalVersion, recommended } = item;
      if (proposalVersion.versionNum == currentPVersion.versionNum) {
        newItems++;
        if (recommended) {
          newRecommended++;
        }
      }
    });

    if (!newItems) {
      return <View />;
    }

    return (
      <BoxItem4>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine bold size={18} value="NEW Services Included:" />
            <FlexRow>
              <TextLine grey value={newItems + " new services"} spacer />
              {newRecommended ? <TextLine grey value={newRecommended + " new recommended service"} /> : null}
            </FlexRow>
          </FlexExpand>
        </FlexRow>
      </BoxItem4>
    );
  };

  const render1 = function () {
    const days1 = getDeliveryDaysAll(recommendItemsInProposal.filter(item => item.itemType == "service"));
    const days2 = getDeliveryDaysAll(optionalItemsInProposal.filter(item => item.itemType == "service"));
    const total1 = getTotal(recommendItemsInProposal);
    const total2 = getTotal(optionalItemsInProposal);
    return (
      <BoxItem4>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine bold size={18} value="Recommended Services:" />
            <TextLine grey value={recommendItemsInProposalCount + " recommended services"} />
            {optionalItemsInProposalCount > 0 ? <TextLine grey value={optionalItemsInProposalCount + " optional services" + (total2 ? ": add " + money(total2) : "") + (days2 ? (total2 ? " plus " : ": add") + plural(days2, "day") + " for delivery" : "")} /> : null}
          </FlexExpand>
          <Price price={total1} days={days1} />

          {onReset && !isClosed && !historicalProposalVersion ? <TouchButton style={{ marginLeft: 25 }} description="Reset the selected service to recommended." onPress={onReset} micro label="Reset All" /> : null}
        </FlexRow>
      </BoxItem4>
    );
  };

  const render2 = function () {
    const days1 = getDeliveryDaysAll(selectedItems);
    const total1 = getTotal(selectedItems);
    return (
      <BoxItem4>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine bold size={18} value="Selected Services:" />
            <TextLine grey value={plural(selectedItemCount, "selected service")} />
            {recommendItemsInProposalCount > 0 ? <TextLine grey value={selectedRecommendItemCount + " of " + recommendItemsInProposalCount + " recommended selected"} /> : null}
          </FlexExpand>

          <Price price={total1} days={days1} />

          {onAddToCart && selectedItemCount ? <TouchButton style={{ marginLeft: 25 }} description="Add selected services to the cart." onPress={onAddToCart} micro label="Add to Cart" /> : null}
        </FlexRow>
      </BoxItem4>
    );
  };

  const render3 = function () {
    const total1 = getTotal2(serviceOrders);
    return (
      <BoxItem4>
        <FlexRow alignTop>
          <FlexExpand>
            <TextLine bold size={18} value="Purchased Services:" />
            <TextLine grey value={purchasedItemsCount + " already purchased"} />
            {recommendItemsInProposalCount > 0 ? <TextLine grey value={recommendItemsPurchasedCount + " of " + recommendItemsInProposalCount + " recommended already purchased"} /> : null}
          </FlexExpand>
          <View style={{ width: 25 }} />
          <Price price={total1} />
        </FlexRow>
      </BoxItem4>
    );
  };

  const isClosed = status != "open" || new Date() >= expireDate;

  return (
    <React.Fragment>
      {isClosed && status != "draft" ? <BoxItem4>Strategy map is closed or has expired.</BoxItem4> : null}

      {!historicalProposalVersion && isBuyerView && currentPVersion.versionNum != 1 ? render0() : null}

      {historicalProposalVersion ? (
        <View>
          <TextLine bold size={18} value="Selected Strategy Version:" />
          <TextLine size={12} value="You are currently view this history strategy version." />

          <RenderProposalVersion proposal={proposal} item={historicalProposalVersion} onRemove={onRemove} historicalProposalVersion={historicalProposalVersion} currentPVersion={currentPVersion} />
        </View>
      ) : null}

      {recommendItemsInProposalCount > 0 ? render1() : null}

      {!historicalProposalVersion && !isClosed && userSelected ? render2() : null}

      {purchasedItemsCount > 0 ? render3() : null}

      {historicalProposalVersion ? (
        <View top={35}>
          <TextLine bold size={18} value="Items in Historical Strategy Version:" />
          <TextLine size={12} value="Below are the non-editable items in this version." />
        </View>
      ) : null}
    </React.Fragment>
  );
}

function RenderProposalPackages({ proposal, onUpdate, onAdd, onEdit }) {
  const { proposalId } = useParams();
  const [state, setState] = useState({ editItem: {} });
  const { showDelete, deleteIndex } = state;
  const { status, packages, options } = proposal;

  return (
    <Section>
      <HeaderText
        subHeader
        label="Strategy Packages:"
        description="Setup multiple strategy packages that will selected pre-defined strategy items on purchase."
        onButtonPress={onAdd}
      />
      {packages.length == 0 ? (
        <NoRecords label="No packages added." />
      ) : (
        <SortableList
          items={packages}
          onChange={(list) => {
            proposal.packages = list;
            dL.saveProposalPackages({ proposalId, packages: list });
            setState({ ...state });
            onUpdate(proposal);
          }}
          renderItem={(item, index) => {
            const { id, name, shortDescription } = item;

            const includedOptions = options.filter(item => {
              const { limitedToPackages } = item
              return limitedToPackages && limitedToPackages.includes(id)
            })


            return (
              <BoxItem
                name={name}
                description={shortDescription}
                hasMove
                onEdit={() => {
                  onEdit(item)
                }}
                onDelete={() => {
                  setState({ ...state, showDelete: true, deleteIndex: index });
                }}>

                {includedOptions.length > 0 ? <View top={15} bottom={-15}>
                  <TextLine bold>Included Options:</TextLine>
                  <ul>
                    {includedOptions.map(item => {
                      return <li>{item.name}</li>
                    })}
                  </ul>
                </View> : null}
              </BoxItem>
            );
          }}
        />
      )}

      {showDelete ? (
        <DeleteConfirm
          onCancel={() => {
            setState({ ...state, showDelete: null });
          }}
          onConfirm={() => {
            packages.splice(deleteIndex, 1);
            dL.saveProposalPackages({ proposalId, packages });
            setState({ ...state, showDelete: null });
            onUpdate(proposal);
          }}
        />
      ) : null}
    </Section>
  );
}

function RenderProposalServiceOptions({ proposal, onUpdate, onAdd, onEdit }) {
  const { proposalId } = useParams();
  const [state, setState] = useState({ editItem: {} });
  const { showDelete, deleteIndex } = state;
  const { status, options, packages } = proposal;

  return (
    <Section>
      <HeaderText
        subHeader
        label="Strategy Options:"
        description="Setup strategy options that will select specific strategy items for purchase."
        onButtonPress={onAdd}
      />
      {options.length == 0 ? (
        <NoRecords label="No service options added." />
      ) : (
        <SortableList
          items={options}
          onChange={(list) => {
            proposal.options = list;
            dL.saveProposalOptions({ proposalId, options: proposal.options });
            onUpdate(proposal);
            setState({ ...state });
          }}
          renderItem={(item, index) => {
            const { name, description, limitedToPackages } = item;

            return (
              <BoxItem
                name={name}
                description={description}
                hasMove
                onEdit={() => {
                  onEdit(item)
                }}
                onDelete={() => {
                  setState({ ...state, showDelete: true, deleteIndex: index });
                }}>
                <RenderLimitedPackages packages={packages} value={limitedToPackages} />
              </BoxItem>
            );
          }} />
      )}

      {showDelete ? (
        <DeleteConfirm
          onCancel={() => {
            setState({ ...state, showDelete: null });
          }}
          onConfirm={() => {
            options.splice(deleteIndex, 1);
            dL.saveProposalOptions({ proposalId, options });
            setState({ ...state, showDelete: null });
            onUpdate(proposal);
          }}
        />
      ) : null}
    </Section>
  );
}

function RenderProposalVersion({ proposal, historicalProposalVersion, currentPVersion, item, onSelect, onRemove }) {
  const { versionNum, sentAt, sentBy, total, deliveryDays, overrides } = item;
  //purchase service items from this proposal

  const totalItemsInProposal = proposal.allItems.values().filter(item => {
    const { proposalVersion, id } = item;
    return proposalVersion.versionNum <= versionNum && !overrides[id + "-delete"];
  }).length;

  const newItemsInProposal = proposal.allItems.values().filter(item => {
    const { proposalVersion } = item;
    return proposalVersion.versionNum == versionNum;
  }).length;

  return (
    <BoxRowItem>
      <FlexRow alignTop>
        <FlexExpand>
          <FlexRow>
            <TextLine size={22} value={"#" + versionNum + ": "} />
            <DateTimeItem value={sentAt} fromNow style={{ marginLeft: 12 }} />
            <UserItem label="Sent By:" user={sentBy} style={{ marginLeft: 25 }} />
          </FlexRow>
          <FlexRow top={10}>
            <TextLine label="New:" size={12} value={plural(newItemsInProposal, "item")} spacer />
            <TextLine label="Total:" size={12} value={plural(totalItemsInProposal, "item")} />
          </FlexRow>
        </FlexExpand>

        <View style={{ textAlign: "right", marginRight: 15 }}>
          <Price price={total} days={deliveryDays} />

          {/*<TextLine size={12} uppercase color="grey" value={utils.getServiceProposalStatus(status)} />
                {serviceItems ? <TextLine color="grey" value={plural(serviceItems.length, "service")} /> : null}*/}
        </View>

        {onSelect ? (
          <View style={{ width: 55 }}>
            {(!historicalProposalVersion && versionNum != currentPVersion.versionNum) || (historicalProposalVersion && historicalProposalVersion.versionNum != versionNum) ? (
              <TouchButton
                size="sm"
                micro
                description="Click to go view this proposal version."
                onPress={() => {
                  onSelect(item);
                }}
                label="View"
              />
            ) : null}
          </View>
        ) : onRemove ? (
          <TouchButton
            size="sm"
            micro
            description="Click to go back to the most current proposal version."
            onPress={() => {
              onRemove(item);
            }}
            label="Remove"
          />
        ) : null}
      </FlexRow>
    </BoxRowItem>
  );
}

export function RenderProposalVersions({ currentPVersion, historicalProposalVersion, proposal, proposalVersions, onSelect, description }) {
  return (
    <Section>
      <HeaderText subHeader label="Strategy Versions:" description={description} />

      {proposalVersions.map((item) => {
        const { id } = item;
        return (
          <RenderProposalVersion
            proposal={proposal}
            historicalProposalVersion={historicalProposalVersion}
            currentPVersion={currentPVersion}
            key={id}
            item={item}
            onSelect={() => {
              onSelect(item);
            }}
          />
        );
      })}
    </Section>
  );
}

function EditExpireDate({ onCancel, value, onSave }) {
  const [state, setState] = useState({ expireDate: value });
  const { expireDate } = state;

  return (
    <ModalBasic
      title="Edit Expire Date:"
      onCancel={onCancel}
      okText="Save"
      onOk={() => {
        onSave(expireDate);
      }}>
      <FormItem label="Expire Date:">
        <Datetime
          style={{ maxWidth: 155 }}
          closeOnClickOutside={true}
          closeOnSelect={true}
          value={expireDate}
          timeFormat={false}
          onChange={value => {
            setState({ ...state, expireDate: value.toDate() });
          }}
        />
      </FormItem>
    </ModalBasic>
  );
}

export function RenderCartPanel({ }) {
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading } = state;

  useEffect(() => {
    dL.loadCart({ userId: session.user.id }).then(function () {
      setState({ ...state, isLoading: false });
    }).catch(function (err) {
      alert("Error: " + err);
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var total = 0;
  const cart = session.cart;
  if (cart) {
    cart.items.forEach(item => {
      total += item.total;
    });
  }

  return <View>
    <HeaderText smallHeader label="Order Cart:" description="This is the order cart for this proposal." />

    <RenderCartItems onUpdate={() => {
      setState({ ...state })
    }} />

    <TextLine alignRight bottom={25} size={22} label="Total:" value={utils.money(total)} />

    <TouchButton full label="Place Order"
      onPress={() => {
        history.push("/user/cart")
      }} />
  </View>
}

const calculatePriceMap2 = function ({ priceMap, proposal }) {
  const { payType, fluxDelivery, fluxScope, profitMarginLevel, marginAdder } = proposal
  //const { priceMap: pPriceMap } = thisVersion
  //const priceMap = buyerPriceMap ? buyerPriceMap : pPriceMap

  var committedAmount = 0
  var totalAmount = 0
  var contractStartDate
  var contractEndDate
  var monthlyAmounts = []
  var averageMonthlyCharge = 0
  var numberOfMonthsOfService = 0

  var profitMargin = 0
  var daysMultiplier = 0
  const totalHours = 1

  profitMargin += .1 * marginAdder

  if (fluxDelivery == "faster") {
    profitMargin += .15
    daysMultiplier += .75
  } else if (fluxDelivery == "normal") {
  } else if (fluxDelivery == "cheaper") {
    profitMargin += -.1
    daysMultiplier += 1.5
  }

  if (fluxScope == "longest") {
    profitMargin += -.07
  } else if (fluxScope == "average") {

  } else if (fluxScope == "shortest") {
    profitMargin += .12
  }

  if (profitMarginLevel == "aggressive") {
    profitMargin += 1.5
  } else if (profitMarginLevel == "normal") {
    profitMargin += 1
  } else if (profitMarginLevel == "reduced") {
    profitMargin += .7
  }

  /*
  if (profitMarginLevel == "aggressive") {
    profitMargin += 2.25
  } else if (profitMarginLevel == "normal") {
    profitMargin += 1.75
  } else if (profitMarginLevel == "reduced") {
    profitMargin += 1.35
  }
  */

  //(pay entire(-10 %), pay monthly(+5 %), pay quarterly(-5 %), pay yearly(-10 %))

  if (payType == "monthly") {
    profitMargin += .05
  } else if (payType == "quarterly") {
    profitMargin += -.05
  } else if (payType == "yearly") {
    profitMargin += -.10
  } else if (payType == "entire") {
    profitMargin += -.10
  }

  /*
  (add to delivery days and unit price)
  Faster=.75x (not less than minDeliveryDays, not less than fastDeliveryDays is set) - price: +15%
  Normal=1x  price: +0%
  Cheaper=1.5 price: -10%
  
  Longest=85% of total hours price: -7%
  Average=65% of total hours  price: +0%
  Shortest=35% of total hours price: +12%

  Aggressive= price: +225%
  Normal= price: +175%
  Reduced= price: +135%
  */

  return priceMap.map(mapItem => {
    const copyMt = Object.assign({}, mapItem)
    copyMt.item = Object.assign({}, mapItem.item)
    const item = copyMt.item
    const { days, price, isFixedPrice, minDeliveryDays } = item

    if (daysMultiplier) {
      var newDays = Math.ceil(days * daysMultiplier)
      if (newDays < minDeliveryDays) {
        newDays = minDeliveryDays
      }
      if (newDays != item.days) {
        item.oldDays = item.days
        item.days = newDays
        item.deliveryDays = item.days
      }
    }
    if (!isFixedPrice || profitMargin > 0) {
      const newPrice = roundMoney(price * (1 + profitMargin))
      if (item.price != newPrice) {
        item.oldUnitPrice = item.price
        item.price = newPrice
        item.unitPrice = item.price
        item.total = roundMoney(item.quantity * item.unitPrice)
      }
    }
    return copyMt
  })
}

export function ProposalEditorView() {
  const { proposalId, sectionId } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [state, setState] = useState({ paymentTermType: "monthly-equal", priceDisplayType: "cost", isLoading: true, editItem: {}, selectedProposalOptionIds: {} });
  const [tabValue, setTabValue] = useState("details");
  const [refresh, setRefresh] = useState();
  const [templateVisible, setTemplateVisible] = useState(false);
  const { cartItems, paymentTermType, orders, priceDisplayType, staffOrders, selectedProposalOptionIds, selectedProposalPackageId, tabValue2, historicalProposalVersion, proposalVersions, parentItem, serviceOrders, serviceStaffView, serviceView, isLoading, isNew, editItem, proposal, stageItem, selectedStepItem, selectedProposalItem, visible, inputItem, inputData, inputs, expireEditVisible } = state;

  const isSubUrl = useRouteMatch(`${url}/:type/:recordId?`);
  const displayDebug = true

  useEffect(() => {
    dL.getServiceProposal({ proposalId })
      .then(function ({ proposal, serviceOrders, staffOrders }) {

        const { marginAdder, fluxDelivery, fluxScope, profitMarginLevel } = proposal;

        if (!fluxDelivery) {
          proposal.fluxDelivery = "normal"
        }
        if (!fluxScope) {
          proposal.fluxScope = "average"
        }
        if (!profitMarginLevel) {
          proposal.profitMarginLevel = profitMargins[1].value
        }
        if (!marginAdder) {
          proposal.marginAdder = 0
        }

        if (isSubUrl) {
          const { recordId, type } = isSubUrl.params;

          const { packages, options } = proposal;

          var isNew = false;
          var editItem = {};
          if (recordId) {
            if (type == "option") {
              editItem = options.find(item => item.option.id == recordId);
            } else if (type == "package") {
              editItem = packages.find(item => item.package.id == recordId);
            } else {
              const items = proposal.allItems.values();
              editItem = items.find(item => item.id == recordId);
            }
          }
        }

        return dL
          .getQuery("ServiceProposalVersion")
          .equalTo("proposal", dL.getObj("ServiceProposal", proposalId))
          .containedIn("removed", [undefined, false])
          .include("sentBy")
          .descending("createdAt")
          .find()
          .then(function (objs) {
            const proposalVersions = dL.loadObjects("ServiceProposalVersion", objs);

            return dL
              .getQuery("Order")
              .equalTo("proposal", dL.getObj("ServiceProposal", proposalId))
              .containedIn("removed", [undefined, false])
              .descending("createdAt")
              .find()
              .then(function (objs) {
                const orders = dL.loadObjects("Order", objs);

                return dL.loadCart({ userId: proposal.user.id }).then(function (cartItems) {
                  setState({ ...state, cartItems, isNew, editItem, orders, proposal, serviceOrders, staffOrders, proposalVersions, isLoading: false });
                })
              });
          });
      })
    /*
    .catch(function (err) {
      alert("Error:" + err);
    });
    */
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const allTeam = getAllTeam(proposal);
  const { profitMargin, profitMarginLevel, marginAdder, fluxDelivery, fluxScope, packages, options } = proposal
  const { currentProposalVersion, lastSentProposalVersion, allItems, proposalType, title, user, status, businessDevelopment, strategyConsultant, projectManager } = proposal;
  const currentPVersion = currentProposalVersion;
  const thisVersion = historicalProposalVersion ? historicalProposalVersion : currentPVersion;
  const rootProposalVersion = thisVersion
  const versionItems = thisVersion.items;
  const allItemsV = getAllItemsForVersion(thisVersion);
  const currentVersionNum = currentPVersion.versionNum;
  const chatRooms = getProposalChatRoom({ proposal });

  const newPriceMap2 = calculatePriceMap2({ priceMap: rootProposalVersion.priceMap, proposal, thisVersion })
  const newPriceMap = priceDisplayType == "cost" ? thisVersion.priceMap : newPriceMap2

  updateTotals(thisVersion, proposal);

  const tabs = [{ label: "Overview", value: "details" }];

  if (proposalType != "simple") {
    tabs.push({ label: "Team", value: "team" });
    tabs.push({ label: "Packages", value: "packages" });
    tabs.push({ label: "Options", value: "options" });
  }

  tabs.push({ label: "Strategy", value: "items" });
  tabs.push({ label: "Timeline", value: "timeline" });

  if (orders.length > 1) {
    tabs.push({ label: "Contracts", value: "orders" });
  }

  if (newPriceMap) {
    tabs.push({ label: "Price List", value: "price-list" });
  }

  if (proposalVersions.length > 1) {
    tabs.push({ label: "Versions", value: "versions" });
  }

  const calculatePriceMap = function () {
    const priceMap = createProposalPriceMap({ proposal, proposalVersion: currentPVersion })
    currentPVersion.priceMap = priceMap

    return dL.getObj("ServiceProposalVersion", currentPVersion.id)
      .set("priceMap", priceMap)
      .save()
  }

  const saveProposalOptions = function () {
    return db.getObj("ServiceProposal", proposal.id)
      .set("profitMargin", profitMargin)
      .set("profitMarginLevel", profitMarginLevel)
      .set("marginAdder", marginAdder)
      .set("fluxDelivery", fluxDelivery)
      .set("fluxScope", fluxScope)
      .save();
  }

  const saveProposalOverrides = function () {
    const { currentProposalVersion } = proposal;
    const { items, overrides } = currentProposalVersion;

    return db.getObj("ServiceProposalVersion", currentProposalVersion.id)
      .set("items", JSON.stringify(items))
      .set("overrides", JSON.stringify(overrides))
      .save();
  }

  const saveProposalItem = function ({ isNew, item }) {
    return dL.saveProposalItem({ isNew, item }).then(function () {
      if (isNew) {
        return saveProposalItems();
      }
    });
  };

  const saveProposalItems = function () {
    return dL.updateProposalItems({ proposal }).then(function () {
      updateTotals(thisVersion, proposal);

      const { finalTotal, finalDeliveryDays } = proposal;

      return db
        .getObj("ServiceProposalVersion", currentProposalVersion.id)
        .set("total", finalTotal)
        .set("deliveryDays", finalDeliveryDays)
        .save()
        .catch(function (err) {
          alert("Error:" + err);
        });
    });
  };

  const renderItemWorker = function ({ index, items, proposalItem }) {
    const { proposalVersion, service, recommended, itemType, id } = proposalItem;

    const deleted = currentProposalVersion.overrides[id + "-delete"];

    const onUnDelete =
      deleted && deleted.versionNum == currentVersionNum
        ? function () {
          delete currentProposalVersion.overrides[id + "-delete"];

          dL.getObj("ServiceProposalItem", proposalItem.id).unset("removedOnProposalVersion").save()

          saveProposalItems();
          setState({ ...state, proposal });
        }
        : null;

    const onDelete = !deleted
      ? function () {
        if (proposalItem.proposalVersion.id == currentProposalVersion.id) {
          items.splice(index, 1);
          dL.getObj("ServiceProposalItem", proposalItem.id)
            .set("removed", true)
            .save()
        } else {
          currentProposalVersion.overrides[id + "-delete"] = { versionNum: currentVersionNum };
          alert("This item is in a previous strategy map version and will be hidden for future versions.");

          dL.getObj("ServiceProposalItem", proposalItem.id)
            .set("removedOnProposalVersion", db.getObj("ServiceProposalVersion", currentProposalVersion.id))
            .save()
        }

        saveProposalItems();
        setState({ ...state, proposal });
      }
      : null;

    const onChecked = function (value) {
      proposalItem.recommended = value;
      saveProposalItem({ item: proposalItem });
      setState({ ...state, proposal });
    };

    const disableEdit = proposalVersion.id != currentProposalVersion.id;

    const renderItem = function () {
      if (itemType == "service") {
        const { inputs, inputData } = service;

        return (
          <RenderProposalService
            priceMap={newPriceMap}
            serviceOrders={serviceOrders}
            thisVersion={thisVersion}
            deleted={deleted}
            historicalProposalVersion={historicalProposalVersion}
            disableCheck={disableEdit}
            proposal={proposal}
            proposalItem={proposalItem}
            checked={recommended}
            onEdit={
              !disableEdit
                ? () => {
                  setState({ ...state, editItem: proposalItem });
                  history.push(`${url}/service-item/${proposalItem.id}`);
                }
                : null
            }
            inputData={inputData}
            hasInputs={inputs}
            onInput={() => {
              if (!inputData) {
                proposalItem.inputData = {};
              }
              setState({ ...state, visible: true, inputItem: proposalItem, inputData, inputs: inputs });
            }}
            onUnDelete={onUnDelete}
            onDelete={onDelete}
            onChecked={onChecked}
            onPress={() => {
              setState({ ...state, serviceView: proposalItem });
            }}
            onUpdate={() => {
              saveProposalOverrides();
              setState({ ...state });
            }}
            onUpdateOverrides={() => {
              calculatePriceMap()
              setState({ ...state })
            }}
          />
        );
      } else if (itemType == "staff") {
        return (
          <RenderProposalStaff
            thisVersion={thisVersion}
            deleted={deleted}
            historicalProposalVersion={historicalProposalVersion}
            disableCheck={disableEdit}
            proposal={proposal}
            proposalItem={proposalItem}
            staffOrders={staffOrders}
            checked={recommended}
            onEdit={
              !disableEdit
                ? () => {
                  setState({ ...state, editItem: proposalItem });
                  history.push(`${url}/staff-item/${proposalItem.id}`);
                }
                : null
            }
            onUnDelete={onUnDelete}
            onDelete={onDelete}
            onChecked={onChecked}
            onPress={() => {
              setState({ ...state, serviceStaffView: proposalItem });
            }}
          />
        );
      }
    };

    return (
      <React.Fragment>
        {renderItem()}

        <View top={-6} bottom={10}>
          {deleted ? <TextLine size={14}>{deleted.versionNum == currentProposalVersion.id ? "Above Service Removed in This Version" : "Above Service Removed in Version #" + deleted.versionNum + "."}</TextLine> : null}

          {lastSentProposalVersion && lastSentProposalVersion.versionNum == proposalVersion.versionNum ? (
            <TextLine grey size={12} top={-4} label="Last Sent Strategy Version:" value={"#" + proposalVersion.versionNum} />
          ) : currentProposalVersion.versionNum != proposalVersion.versionNum ? (
            <TextLine grey size={12} top={-4} label="Previous Strategy Version:" value={"#" + proposalVersion.versionNum} />
          ) : currentProposalVersion.versionNum == proposalVersion.versionNum ? (
            <TextLine grey size={12} top={-4} label="Current Strategy Version:" value={"#" + proposalVersion.versionNum} />
          ) : null}
        </View>
      </React.Fragment>
    );
  };

  const buttons = [];
  if (status == "draft") {
    buttons.push({
      label: "Edit Details",
      onPress: () => {
        history.push("/admin/proposal/" + proposalId + "/edit");
      }
    });
    buttons.push({
      label: "Delete Proposal",
      onPress: () => {
        return dL.deleteServiceProposal(proposalId).then(function () {
          history.goBack();
        });
      }
    });
  }

  buttons.push({
    label: "Send Proposal",
    onPress: () => {
      if (!user) {
        alert("Must select client for proposal.");
        return;
      }

      return dL.sendServiceProposal(proposalId).then(function () {
        alert("Strategy map sent.");
        setRefresh(new Date());
      });
    }
  });

  if (status == "open") {
    buttons.push({
      label: "Withdraw Proposal",
      onPress: () => {
        confirmAlert({
          title: "Alert!",
          message: "Are you sure you want to withdraw this strategy map?",
          buttons: [
            {
              label: "Yes, withdraw it.",
              onClick: () => {
                //can't withdraw if click has already purchased from it
                return dL.getQuery("ServiceOrder")
                  .equalTo("proposal", dL.getObj("ServiceProposal", proposalId))
                  .containedIn("removed", [undefined, false])
                  .count().then(function (count) {
                    if (count > 0) {
                      alert("Can't withdraw a strategy map that has already been fully or partially purchased.")
                      return
                    }
                    return dL.withdrawServiceProposal(proposalId).then(function () {
                      alert("Strategy map withdrawn.");
                      setRefresh(new Date());
                    });
                  })
              }
            },
            { abel: "Cancel" }
          ]
        })
      }
    });
  }
  buttons.push({
    label: "Preview Proposal",
    onPress: () => {
      history.push(`${url}/preview`);
    }
  });
  buttons.push({
    label: "Save As Template",
    onPress: () => {
      setTemplateVisible(true);
    }
  });

  const rtn = getMainProposalModel({ proposal, proposalVersion: currentPVersion })

  const finalDeliveryDays = 0

  const monthArr = [{
    date: Moment().toDate(),
    total: 1500
  }, {
    date: Moment().add(1, "month").toDate(),
    total: 2500
  }]

  var total = 0
  var profit = 0
  var margin
  var totalMonths = monthArr.length

  allItemsV.values().forEach(item => {
    const proposalItem = allItems.get(item.id)
    const { id } = proposalItem
    const path = `p-item[${id}]`

    const items = getPriceMapFromPriceMap({ priceMap: newPriceMap2, path })
    items.forEach(mapItem => {
      const { item } = mapItem
      total += item.unitPrice * proposalItem.quantity
      profit += (item.unitPrice - item.oldUnitPrice) * proposalItem.quantity
    })
  })

  margin = profit / total

  const renderPanel = function () {
    return (
      <React.Fragment>
        {tabValue2 == "options" ? <View>
          {packages.length > 0 ? <Section>
            <HeaderText smallHeader label="Strategy Packages:" />
            {packages.map((item) => {
              const { id, name, description } = item;

              const priceChange = 999//getPrice({ packageId: id, optionIds }) - finalTotal;
              const doneDate = 99//getDoneDateAll(getServiceItems({ packageId: id, optionIds }));

              return (
                <BoxItem
                  name={name}
                  description={description}
                  hasCheck
                  checked={selectedProposalPackageId == id}
                  onBoxPress={() => {
                    if (selectedProposalPackageId == id) {
                      setState({ ...state, selectedProposalPackageId: null });
                    } else {
                      setState({ ...state, selectedProposalPackageId: id });
                    }
                  }}
                  rightRender={<Price size={18} price={priceChange} days={Moment(doneDate).diff(Moment().startOf("day"), "days") - finalDeliveryDays} />}
                />
              );
            })}
          </Section> : null}

          {options.length > 0 ? <Section>
            <HeaderText smallHeader label="Strategy Options:" />
            {options.map((item) => {
              const { id, name, shortDescription } = item;

              const priceChange = 999//getPrice({ packageId, optionIds: dt }) - finalTotal;
              const doneDate = 99//getDoneDateAll(getServiceItems({ packageId, optionIds: dt1 }));

              return (
                <BoxItem
                  name={name}
                  description={shortDescription}
                  hasCheck
                  checked={selectedProposalOptionIds[id]}
                  onBoxPress={() => {
                    if (selectedProposalOptionIds[id]) {
                      delete selectedProposalOptionIds[id]
                    } else {
                      selectedProposalOptionIds[id] = true
                    }
                    setState({ ...state, selectedProposalOptionIds });
                  }}
                  rightRender={<Price size={18} price={priceChange} days={Moment(doneDate).diff(Moment().startOf("day"), "days") - finalDeliveryDays} />}
                />
              );
            })}
          </Section> : null}

          <Section>
            <HeaderText smallHeader label="Display Price:" />

            <TabBar
              options={[{ label: "Cost Price", value: "cost" }, { label: "Sale Price", value: "price" }]}
              onChange={item => {
                setState({ ...state, priceDisplayType: item.value })
              }}
              value={priceDisplayType}
            />
            <View style={{ height: 25 }} />

            <HeaderText smallHeader label="Strategy Flux:" description="An agile way to buy and execute projects, allow you strategy map to be dynamic with changing needs." />

            <TextLine value="Faster Delivery:" bottom={4} />
            <TextLine size={12} value="Select the delivery speed for you project. This will impact the cost and duration." bottom={10} />

            <TabBar
              options={utils.fluxDeliveryOptions}
              onChange={item => {
                proposal.fluxDelivery = item.value
                saveProposalOptions()
                setState({ ...state, proposal })
              }}
              value={fluxDelivery}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Scope Commitment:" bottom={4} />
            <TextLine size={12} value="Create flexibility by changing the committed project scope. This will allow you to change scope easily and quicker instead of committing to the entire duration of the project." bottom={10} />
            <TabBar
              options={utils.fluxScopeCommitments}
              onChange={item => {
                proposal.fluxScope = item.value
                saveProposalOptions()
                setState({ ...state, proposal })
              }}
              value={fluxScope}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Profit Aggressiveness:" bottom={4} />
            <TextLine size={12} value="Set the aggressiveness of the profit margin for this proposal." bottom={10} />
            <TabBar
              options={profitMargins}
              onChange={item => {
                proposal.profitMarginLevel = item.value
                saveProposalOptions()
                setState({ ...state, proposal })
              }}
              value={profitMarginLevel}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Profit Expander:" bottom={4} />
            <TextLine size={12} value="Set an additional level of profit using this tool." bottom={10} />
            <ReactSlider
              className="horizontal-slider"
              marks
              markClassName="example-mark"
              min={0}
              max={5}
              thumbClassName="example-thumb"
              trackClassName="example-track"
              value={marginAdder}
              renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
              onChange={(value) => {
                proposal.marginAdder = value
                saveProposalOptions()
                setState({ ...state, proposal })
              }}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Profit Margin:" bottom={4} />
            <TextLine bold size={18} value={money(profit) + " (" + roundNumber(margin * 100, 1) + "%)"} />

            <View style={{ height: 25 }} />
            <TextLine value="Payment Terms:" bottom={4} />
            <TextLine size={12} value="Set the payment terms for the cart." bottom={10} />
            <TabBar
              options={paymentTermTypes}
              onChange={item => {
                setState({ ...state, paymentTermType: item.value })
              }}
              value={paymentTermType}
            />

            <View style={{ height: 25 }} />
            <TextLine value="Contract Details:" bottom={4} />
            <TextLine bold label="Contract Total:" value={money(total)} />
            <TextLine bold label="Contract Length:" value={plural(totalMonths, "month")} />
            {paymentTermType == "monthly-equal" ? <TextLine bold label="Monthly:" value={money(roundMoney(total / totalMonths)) + " per month"} /> : paymentTermType == "monthly" ? monthArr.map((item, index) => {
              return <TextLine bold label={"Month " + (index + 1) + ":"} value={money(item.total)} />
            }) : null}
          </Section>
        </View> : null}
      </React.Fragment>
    );
  };

  const paymentTermTypes = getPaymentType(totalMonths)

  return (
    <Switch>
      <Route path={`${url}/option/:recordId?`}>
        <EditProposalOption
          proposal={proposal}
          packages={packages}
          model={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              //editItem.proposal = proposal;
              //editItem.proposalVersion = currentProposalVersion;
            }
            if (!proposal.options) {
              proposal.options = [];
            }
            const list = proposal.options
            const index = list.findIndex(item => item.id == editItem.id);
            if (index == -1) {
              list.push(editItem);
            } else {
              list[index] = editItem
            }

            return dL.saveProposalOptions({ proposalId, options: proposal.options }).then(function () {
              history.goBack();
              setState({ ...state, proposal });
            });
          }}
        />
      </Route>
      <Route path={`${url}/package/:recordId?`}>
        <EditProposalPackage
          proposal={proposal}
          model={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              //editItem.proposal = proposal;
              //editItem.proposalVersion = currentProposalVersion;
            }
            if (!proposal.packages) {
              proposal.packages = [];
            }
            const list = proposal.packages
            const index = list.findIndex(item => item.id == editItem.id);
            if (index == -1) {
              list.push(editItem);
            } else {
              list[index] = editItem
            }

            return dL.saveProposalPackages({ proposalId, packages: proposal.packages }).then(function () {
              history.goBack();
              setState({ ...state, proposal });
            });
          }}
        />
      </Route>
      <Route path={`${url}/staff-item/:recordId?`}>
        <EditProposalStaff
          proposal={proposal}
          proposalItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              editItem.proposal = proposal;
              editItem.proposalVersion = currentProposalVersion;
              editItem.parentProposalItem = parentItem;
              if (parentItem) {
                allItemsV.get(parentItem.id).items.push({ id: editItem.id });
              } else {
                versionItems.push({ id: editItem.id });
              }
            }
            allItems.set(editItem.id, editItem);

            return saveProposalItem({ isNew, item: editItem }).then(function () {
              setState({ ...state, isNew: false, proposal });
              history.goBack();
            });
          }}
        />
      </Route>
      <Route path={`${url}/service-item/:recordId?`}>
        <EditProposalService
          rootProposalVersion={rootProposalVersion}
          proposal={proposal}
          proposalItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              editItem.proposal = proposal;
              editItem.proposalVersion = currentProposalVersion;
              editItem.parentProposalItem = parentItem;
              if (parentItem) {
                allItemsV.get(parentItem.id).items.push({ id: editItem.id });
              } else {
                versionItems.push({ id: editItem.id });
              }
            }
            allItems.set(editItem.id, editItem);

            return saveProposalItem({ isNew, item: editItem })
              .then(function () {
                calculatePriceMap()

                history.goBack();
                setState({ ...state, isNew: false, proposal });
              })
              .catch(function (err) {
                alert("Error:" + err);
              });
          }}
        />
      </Route>
      <Route path={`${url}/stage/:recordId?`}>
        <EditProposalStage
          proposal={proposal}
          proposalItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              editItem.proposal = proposal;
              editItem.proposalVersion = currentProposalVersion;
              editItem.parentProposalItem = parentItem;
              versionItems.push({ id: editItem.id, items: [] });
            }
            allItems.set(editItem.id, editItem);

            return saveProposalItem({ isNew, item: editItem }).then(function () {
              history.goBack();
              setState({ ...state, isNew: false, proposal });
            });
          }}
        />
      </Route>
      <Route path={`${url}/stage-item/:recordId?`}>
        <EditProposalStageStep
          proposal={proposal}
          stageItem={stageItem}
          proposalItem={editItem}
          onCancel={() => {
            history.goBack();
          }}
          onDone={editItem => {
            if (isNew) {
              editItem.proposal = proposal;
              editItem.proposalVersion = currentProposalVersion;
              editItem.parentProposalItem = stageItem;
              allItemsV.get(stageItem.id).items.push({ id: editItem.id, items: [] });
            }
            allItems.set(editItem.id, editItem);

            return saveProposalItem({ isNew, item: editItem }).then(function () {
              if (isNew) {
                setState({ ...state, isNew: false, proposal, selectedProposalItem: stageItem, selectedStepItem: editItem });
              } else {
                setState({ ...state, isNew: false, proposal });
              }
              history.goBack();
            });
          }}
        />
      </Route>
      <Route>
        <ChatWindow chatRooms={chatRooms}
          tabPanel={renderPanel()}
          onTabChange={item => {
            setState({ ...state, tabValue2: item.value });
          }}
          tabOptions={tabValue == "items" || tabValue == "timeline" ? [{ label: "Options", value: "options" }, { label: "Chat", value: "chat" }] : null}
          tabValue={tabValue2}>
          <BasicTop title={title} shortDescription="Strategy to submit to buyers." buttons={buttons} />

          {templateVisible ? (
            <SaveProposalTemplate
              proposal={proposal}
              onCancel={() => {
                setTemplateVisible();
              }}
              onSave={() => {
                setTemplateVisible();
              }}
            />
          ) : null}

          <TabBar
            queryId="tb"
            style={{ marginBottom: 20 }}
            options={tabs}
            onChange={item => {
              const value = item.value
              if (value == "items" || value == "timeline") {
                setState({ ...state, tabValue2: "options" });
              }
              setTabValue(value);
            }}
            value={tabValue}
          />

          {tabValue == "price-list" ?
            <Section>
              <HeaderText subHeader label="Price List:" description="See the complete price list for this service which includes all sub-services." />

              {newPriceMap.length == 0 ? <NoRecords label="No price list exists without sub-services." /> : null}

              {newPriceMap.map(mapItem => {
                const { path, item, isOverride } = mapItem
                const { name, actualVersionType, price, days, isRemoved, actualVersionNum, actualServiceVersionId, startDayIndex, doneDayIndex, delayOptions } = item

                const { originalPrice, total, quantity } = item

                const indexSeq = path.split(".").length - 1

                const { days: pDays } = getDataFromPriceMap({ priceMap: rootProposalVersion.priceMap, path })

                return <View style={{ marginLeft: indexSeq * 25 }}>
                  <BoxItem name={name} rightRender={<Price strikeThrough={isRemoved} price={price} days={days} />}>
                    <FlexRow>
                      <TextLine size={14} label="Index:" value={indexSeq} spacer />
                      {isOverride ? <TextLine size={14} value={"OVERRIDE"} spacer /> : null}
                      {isRemoved ? <TextLine size={14} value={"REMOVED"} spacer /> : null}
                      {originalPrice ? <TextLine value={"PRICE OVERRIDE"} spacer /> : null}
                      <TextLine label="Quantity:" value={quantity} spacer />
                      <TextLine label="Total:" value={money(total)} spacer />
                      <TextLine size={14} label="PDays:" value={pDays} spacer />
                      <TextLine size={14} label="Start Index:" value={startDayIndex} spacer />
                      <TextLine size={14} label="Done Index:" value={doneDayIndex} spacer />
                    </FlexRow>

                    {delayOptions && delayOptions.delayStart ? <FlexRow>
                      <TextLine size={14} label="Delay Type:" value={delayOptions.delayType} spacer />
                      <TextLine size={14} label="Delay Days:" value={delayOptions.delayDays} spacer />
                    </FlexRow> : null}

                    {displayDebug ? <React.Fragment>
                      <View top={10} />
                      <TextLine size={12} label="Path:" value={path} />
                      <View top={10} />
                      <TextLine size={12} label="Version ID:" value={actualServiceVersionId} spacer />
                      <FlexRow>
                        <TextLine size={12} label="Version Type:" value={actualVersionType} spacer />
                        <TextLine size={12} label="Version Num:" value={actualVersionNum} />
                      </FlexRow>
                    </React.Fragment> : null}
                  </BoxItem>
                </View>
              })}
            </Section> : null}

          {tabValue == "details" ? (
            <React.Fragment>
              <ProposalOverview
                proposal={proposal}
                onButtonPress={
                  status == "draft"
                    ? () => {
                      history.push("/admin/proposal/" + proposalId + "/edit");
                    }
                    : null
                }
              />
            </React.Fragment>
          ) : null}

          {tabValue == "team" ? (
            <React.Fragment>
              <Section>
                <HeaderText subHeader label="Strategy Team:" description="Meet your proposal team." />

                <FlexRow style={{ minHeight: 95 }} alignTop>
                  <EditKeyUser
                    roleName="businessDevelopment"
                    style={{ flex: 1 }}
                    label="Business Development:"
                    value={businessDevelopment}
                    onChange={value => {
                      dL.setServiceProposalUser({
                        role: "businessDevelopment",
                        clear: value ? false : true,
                        proposalId: proposal.id,
                        userId: value ? value.id : businessDevelopment.id
                      });
                      proposal.businessDevelopment = value;
                      setState({ ...state, proposal });
                    }}
                  />
                  <EditKeyUser
                    roleName="strategyConsultant"
                    style={{ flex: 1 }}
                    label="Strategy Consultant:"
                    value={strategyConsultant}
                    onChange={value => {
                      dL.setServiceProposalUser({
                        role: "strategyConsultant",
                        clear: value ? false : true,
                        proposalId: proposal.id,
                        userId: value ? value.id : strategyConsultant.id
                      });
                      proposal.strategyConsultant = value;
                      setState({ ...state, proposal });
                    }}
                  />
                  <EditKeyUser
                    roleName="projectManager"
                    label="Project Manager:"
                    style={{ flex: 1 }}
                    value={projectManager}
                    onChange={value => {
                      dL.setServiceProposalUser({
                        role: "projectManager",
                        clear: value ? false : true,
                        proposalId: proposal.id,
                        userId: value ? value.id : projectManager.id
                      });
                      proposal.projectManager = value;
                      setState({ ...state, proposal });
                    }}
                  />
                </FlexRow>
              </Section>

              <RestOfTeam allTeam={allTeam} />
            </React.Fragment>
          ) : null}

          {tabValue == "packages" ? (
            <RenderProposalPackages
              proposal={proposal}
              onUpdate={proposal => {
                setState({ ...state, proposal });
              }}
              onEdit={(item) => {
                setState({ ...state, isNew: false, editItem: item });
                history.push(url + "/package/" + item.id);
              }}
              onAdd={() => {
                setState({ ...state, isNew: true, editItem: {} });
                history.push(url + "/package");
              }}
            />
          ) : null}

          {tabValue == "options" ? (
            <RenderProposalServiceOptions
              proposal={proposal}
              onUpdate={proposal => {
                setState({ ...state, proposal });
              }}
              onEdit={(item) => {
                setState({ ...state, isNew: false, editItem: item });
                history.push(url + "/option/" + item.id);
              }}
              onAdd={() => {
                setState({ ...state, isNew: true, editItem: {} });
                history.push(url + "/option");
              }}
            />
          ) : null}

          {tabValue == "timeline" ? <RenderProposalTimeline rtn={rtn} historicalProposalVersion={historicalProposalVersion} rootProposalVersion={rootProposalVersion} serviceOrders={serviceOrders} staffOrders={staffOrders} proposal={proposal} currentPVersion={currentPVersion} onUpdateOverrides={() => {
            calculatePriceMap()
            setState({ ...state })
          }} onUpdateProposal={() => {
            setState({ ...state, proposal })
          }} /> : null}

          {tabValue == "versions" ? (
            <RenderProposalVersions
              description="See the history of proposal versions."
              historicalProposalVersion={historicalProposalVersion}
              currentPVersion={currentPVersion}
              proposal={proposal}
              proposalVersions={proposalVersions}
              onSelect={proposalVersion => {
                if (proposalVersion.versionNum == currentPVersion.versionNum) {
                  setState({ ...state, tabValue: "items", historicalProposalVersion: null });
                } else {
                  setState({ ...state, tabValue: "items", historicalProposalVersion: proposalVersion });
                }
              }}
            />
          ) : null}

          {tabValue == "orders" ? (
            orders.map(order => {
              return <RenderOrderWorker item={order} />
            })
          ) : null}

          {tabValue == "items" ? (
            <Section>
              <HeaderText
                subHeader
                label="Strategy:"
                description="Services or stages within the strategy map."
                rightRender={
                  !historicalProposalVersion ? (
                    <FlexRow>
                      <TouchButton
                        style={{ marginRight: 4 }}
                        onPress={() => {
                          setState({ ...state, isNew: true, parentItem: null, editItem: { recommended: true, quantity: 1, itemType: "service" } });
                          history.push(url + "/service-item");
                        }}
                        micro
                        description="Add a service to this proposal."
                        label="Add Service"
                      />

                      <TouchButton
                        style={{ marginRight: 4 }}
                        onPress={() => {
                          setState({ ...state, isNew: true, parentItem: null, editItem: { recommended: true, itemType: "staff" } });
                          history.push(url + "/staff-item");
                        }}
                        micro
                        description="Add a staff role or worker to this proposal."
                        label="Add Staff"
                      />

                      <TouchButton
                        variant="secondary"
                        onPress={() => {
                          setState({ ...state, isNew: true, editItem: { expanded: true, itemType: "stage" } });
                          history.push(url + "/stage");
                        }}
                        micro
                        description="Add a proposal stage."
                        label="Add Stage"
                      />
                    </FlexRow>
                  ) : null
                }
              />

              <RenderServiceBlocks
                priceMap={newPriceMap}
                currentPVersion={currentPVersion}
                historicalProposalVersion={historicalProposalVersion}
                sectionId={sectionId}
                proposal={proposal}
                serviceOrders={serviceOrders}
                onRemove={() => {
                  setState({ ...state, historicalProposalVersion: null });
                }}
              />

              {versionItems.length == 0 ? (
                <NoRecords style={{ marginBottom: 15 }} label="No proposal items added." />
              ) : (
                <SortableList
                  disabled={historicalProposalVersion || proposalType == "simple"}
                  items={versionItems}
                  onChange={(list) => {
                    currentProposalVersion.items = list;
                    saveProposalItems();
                    setState({ ...state, proposal });
                  }}
                  renderItem={(item, index) => {
                    const { id, items } = item;
                    const proposalItem = allItems.get(id);
                    const { itemType } = proposalItem;

                    if (itemType == "service" || itemType == "staff") {
                      return renderItemWorker({ index, items: versionItems, proposalItem });
                    } else if (itemType == "stage") {
                      const { expanded } = proposalItem;
                      const pIItems = items;

                      const renderAddStepButtons = function () {
                        if (historicalProposalVersion) {
                          return <View />;
                        }
                        return (
                          <TouchButton
                            micro
                            onPress={() => {
                              setState({ ...state, isNew: true, stageItem: proposalItem, editItem: { stageId: proposalItem.id, itemType: "step" } });
                              history.push(url + "/stage-item");
                            }}
                            description="Add a stage step."
                            label="Add Step"
                          />
                        );
                      };

                      const renderAddButtons = function () {
                        if (historicalProposalVersion) {
                          return <View />;
                        }
                        return (
                          <FlexRow>
                            <TouchButton
                              micro
                              onPress={() => {
                                setState({ ...state, isNew: true, parentItem: selectedStepItem, editItem: { recommended: true, stageId: proposalItem.id, stageItemId: selectedStepItem.id, quantity: 1 } });
                                history.push(`${url}/service-item`);
                              }}
                              label="Add Service"
                              spacer
                            />
                            <TouchButton
                              micro
                              onPress={() => {
                                setState({ ...state, isNew: true, parentItem: selectedStepItem, editItem: { recommended: true, stageId: proposalItem.id, stageItemId: selectedStepItem.id, quantity: 1 } });
                                history.push(url + "/staff-item");
                              }}
                              label="Add Staff"
                            />
                          </FlexRow>
                        );
                      };

                      return (
                        <RenderProposalStage
                          thisVersion={thisVersion}
                          readonly={historicalProposalVersion}
                          items={pIItems}
                          key={proposalItem.id}
                          proposal={proposal}
                          proposalItem={proposalItem}
                          onDelete={() => {
                            if (proposalItem.proposalVersion.id == currentProposalVersion.id) {
                              items.splice(index, 1);
                            } else {
                              currentProposalVersion.overrides[proposalItem.id + "-delete"] = true;
                            }
                            saveProposalItems();
                            setState({ ...state, proposal });
                          }}
                          onEdit={() => {
                            setState({ ...state, editItem: proposalItem });
                            history.push(url + "/stage");
                          }}
                          onExpand={value => {
                            proposalItem.expanded = value;
                            setState({ ...state, proposal, selectedStepItem: null });
                          }}>
                          {expanded ? (
                            <StepWrapper stageItem={proposalItem} stepItems={pIItems} buttons={renderAddStepButtons()}>
                              <SortableList
                                disabled={historicalProposalVersion}
                                style={{ display: "flex", flexWrap: "wrap" }}
                                items={pIItems}
                                onChange={(list) => {
                                  allItemsV.get(proposalItem.id).items = list;
                                  saveProposalItems();
                                  setState({ ...state, proposal });
                                }}
                                renderItem={(item, index) => {
                                  const { id } = item;
                                  const stageItem = allItems.get(id);

                                  const selected = selectedStepItem && selectedStepItem.id == stageItem.id;

                                  return (
                                    <RenderProposalStepItem
                                      thisVersion={thisVersion}
                                      readonly={historicalProposalVersion}
                                      items={item.items}
                                      key={stageItem.id}
                                      proposal={proposal}
                                      proposalItem={stageItem}
                                      selected={selected}
                                      onEdit={() => {
                                        setState({ ...state, editItem: stageItem });
                                        history.push(url + "/stage-item");
                                      }}
                                      onDelete={() => {
                                        if (stageItem.proposalVersion.id == currentProposalVersion.id) {
                                          pIItems.splice(index, 1);
                                        } else {
                                          currentProposalVersion.overrides[stageItem.id + "-delete"] = true;
                                        }

                                        saveProposalItems();
                                        setState({ ...state, proposal });
                                      }}
                                      onPress={() => {
                                        if (selected) {
                                          setState({ ...state, selectedProposalItem: null, selectedStepItem: null });
                                        } else {
                                          setState({ ...state, selectedProposalItem: proposalItem, selectedStepItem: stageItem });
                                        }
                                      }}
                                    />
                                  );
                                }} />
                              {renderAddStepButtons()}
                            </StepWrapper>
                          ) : null}

                          {selectedStepItem && selectedProposalItem && selectedProposalItem.id == proposalItem.id ? (
                            <ServiceWrapper items={allItemsV.get(selectedStepItem.id).items} selectedStepItem={selectedStepItem} buttons={renderAddButtons()}>
                              <SortableList
                                disabled={historicalProposalVersion}
                                items={allItemsV.get(selectedStepItem.id).items}
                                onChange={(list) => {
                                  allItemsV.get(selectedStepItem.id).items = list;
                                  saveProposalItems();
                                  setState({ ...state, proposal });
                                }}
                                renderItem={(item, index) => {
                                  const { id } = item;
                                  const serviceItem = allItems.get(id);
                                  return renderItemWorker({ index, items: allItemsV.get(selectedStepItem.id).items, proposalItem: serviceItem });
                                }} />
                              {renderAddButtons()}
                            </ServiceWrapper>
                          ) : null}
                        </RenderProposalStage>
                      );
                    }
                  }} />
              )}
            </Section>
          ) : null}

          {visible ? (
            <RenderClientInputRequirements
              inputs={inputs}
              inputData={inputData}
              onDone={() => {
                inputItem.inputData = inputData;
                return saveProposalItem({ item: inputItem }).then(function () {
                  setState({ ...state, visible: false });
                });
              }}
            />
          ) : null}
        </ChatWindow>

        {expireEditVisible ? (
          <EditExpireDate
            value={proposal.expireDate}
            onSave={value => {
              proposal.expireDate = value;
              db.getObj("ServiceProposal", proposal.id)
                .set("expireDate", value)
                .save();
              setState({ ...state, proposal, expireEditVisible: null });
            }}
            onCancel={() => {
              setState({ ...state, expireEditVisible: null });
            }}
          />
        ) : serviceStaffView ? (
          <ViewProposalStaffServiceItem
            proposal={proposal}
            proposalItem={serviceStaffView}
            onClose={() => {
              setState({ ...state, serviceStaffView: null });
            }}
          />
        ) : serviceView ? (
          <ViewProposalServiceItem
            proposal={proposal}
            proposalItem={serviceView}
            onClose={() => {
              setState({ ...state, serviceView: null });
            }}
          />
        ) : null}
      </Route>
    </Switch>
  );
}

export function DisplaySubServiceItem({ proposalItem, circularIds: startC1, rootProposalVersion, historicalProposalVersion, subServiceVersion: d1, path: startPath, onUpdateOverrides, rtn: rtn1 }) {
  const [state, setState] = useState({ isLoading: true, rtn: rtn1 });
  const { rtn, subServiceVersion, isLoading, visibleConfig, subServiceVisible, actualServiceVersion } = state;

  const { subService } = d1
  const path = (startPath ? startPath + "." : "") + "service[" + subService.id + "]"
  const { masterOverrides, priceMap } = rootProposalVersion

  const overrideRecord = masterOverrides.find(item => {
    return (item.proposalItem && item.proposalItem.id == proposalItem.id) && item.path == path
  })

  const configData = overrideRecord ? overrideRecord : d1

  useEffect(() => {
    const { actualServiceVersion } = configData
    dL.getSubServiceVersion({ subServiceVersionId: d1.id }).then(function (subServiceVersion) {
      return dL.getServiceVersion4({ serviceVersionId: actualServiceVersion.id }).then(function (actualServiceVersion) {
        setState({ ...state, actualServiceVersion, subServiceVersion, isLoading: false })
      })
    })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const getCopyObj = function () {
    const rtn = Object.assign({}, configData)
    delete rtn.id
    rtn.delayOptions = Object.assign({}, rtn.delayOptions)
    return rtn
  }

  const isOverride = overrideRecord != null
  const { deliveryType, overridePrice, isRemoved, actualVersionType, serviceId, packageId, serviceOptionIds } = configData
  const { quantity } = proposalItem
  const isCircularRef = startC1[serviceId] != null
  const circularIds = { ...startC1 }
  circularIds[serviceId] = true

  const { name, icon, shortDescription, service, userService } = actualServiceVersion
  const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

  const getPriceDays = function ({ serviceVersion }) {
    const itemG = rtn.setOverride({ path, masterOverride: { ...configData, actualServiceVersion: serviceVersion } })
    const days = itemG.getTotalDeliveryDays()
    const total = itemG.getTotalPrice()
    return { total, days }
  }

  const itemG = rtn.getItem(path)
  const deliveryDays = itemG.getTotalDeliveryDays()
  const unitPrice = itemG.getTotalPrice()
  const startDate = itemG.getStartDate()
  const doneDate = itemG.getDoneDate()
  const total = quantity * unitPrice

  const doReset = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    if (record) {
      const index = masterOverrides.findIndex(item => item.id == record.id)
      masterOverrides.splice(index, 1)
      dL.removeMasterOverrideRecordP({ recordId: record.id, rootProposalVersion })
    }

    onUpdateOverrides()
    setState({ ...state, visibleConfig: null })
  }

  const getOverrideRecord = function () {
    var record = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

    const isNew = !record
    if (isNew) {
      record = createNewOverrideRecord()
      record._isNew = true
    }

    return record
  }

  const createNewOverrideRecord = function () {
    const record = getCopyObj()
    record.id = utils.guid()
    record.rootProposal = rootProposalVersion.proposal
    record.rootProposalVersion = rootProposalVersion
    record.proposalItem = proposalItem
    record.subService = subService
    record.subServiceVersion = subServiceVersion
    record.path = path
    record.overrideType = "sub-service"
    masterOverrides.push(record)
    return record
  }

  const saveOverrideRecord = function ({ record }) {
    const itemG = rtn.setOverride({ path, masterOverride: record })
    dL.saveMasterOverrideRecord({ isNew: record._isNew, recordId: record.id, data: record })
    onUpdateOverrides()
    setState({ ...state, rtn })
  }

  return <React.Fragment>
    {isCircularRef ? <TextLine color="red" size={14} bold value="The below service is a CIRCULAR REFERENCE: It will removed automatically." /> : null}

    <BoxItem strikeThrough={isCircularRef || isRemoved} name={name} icon={icon} description={shortDescription} onPress={() => {
      setState({ ...state, subServiceVisible: true });
    }} rightRender={
      !isCircularRef ? <Price strikeThrough={isRemoved} price={total} days={deliveryDays} hasOverride={overridePrice != null} /> : null
    } render={
      !isCircularRef ?
        <View>
          {!isRemoved && actualVersionType == "worker" ?
            <RenderWorkConfig subServiceVersion={subServiceVersion} />
            : null}

          <FlexRow>
            {isRemoved ? <TextLine top={10} size={12} color="red" bold value="Service Removed" spacer /> : null}

            {!isRemoved && userService && !userService.isWorkerPublic ? <TextLine top={10} size={12} value="Private Service (not configurable)" spacer /> : null}

            {!historicalProposalVersion ?
              <SelectOptions
                options={[!isRemoved ? {
                  label: "Edit Overrides", value: "config"
                } : null, !isRemoved && isOverride ? {
                  label: "Remove Overrides", value: "remove-overides"
                } : null, !isRemoved ? {
                  label: "Remove Service", value: "remove"
                } : {
                  label: "Add Service Back", value: "add"
                }]}
                onChange={selItem => {
                  const { value } = selItem
                  if (value == "config") {
                    setState({ ...state, visibleConfig: true })
                  } else if (value == "remove") {
                    const record = getOverrideRecord()

                    record.isRemoved = true

                    saveOverrideRecord({ record })

                    setState({ ...state, visibleConfig: null })
                  } else if (value == "add") {
                    doReset()
                  } else if (value == "remove-overides") {
                    doReset()
                  }
                }}>
                <View style={{ marginTop: 10, marginRight: 10 }}>
                  <TextLine size={12} value="Edit Configuration" underline />
                </View>
              </SelectOptions>
              : null}

            {isOverride && !isRemoved ? <TextLine top={10} size={12} value="(has overrides)" /> : null}
          </FlexRow>

          {!isRemoved && subServices.length > 0 ?
            <View top={15}>
              <ItemExpandSection label={plural(subServices.length, "Sub-Service") + ":"}>
                {subServices.map(subServiceVersion => {
                  const { id } = subServiceVersion

                  return <DisplaySubServiceItem
                    proposalItem={proposalItem}
                    key={id}
                    rtn={rtn}
                    circularIds={{ ...circularIds }}
                    rootProposalVersion={rootProposalVersion}
                    historicalProposalVersion={historicalProposalVersion}
                    onUpdateOverrides={onUpdateOverrides}
                    subServiceVersion={subServiceVersion}
                    path={path} />
                })}
              </ItemExpandSection>
            </View>
            : null}
        </View> : null
    }>
      <RenderStartDoneDates deliveryType={deliveryType} startDate={startDate} doneDate={doneDate} />

      {!isCircularRef && !isRemoved ?
        <RenderServiceNeedsUpdate
          style={{ marginTop: 10 }}
          configData={configData}
          usingVersion={actualServiceVersion}
          currentVersion={actualVersionType == "worker" ? userService.currentVersion : service.currentVersion}
          price={total}
          days={deliveryDays}
          onGetVersionPriceDays={(serviceVersion) => {
            return getPriceDays({ serviceVersion })
          }}
          onUpdateNewest={() => {
            setState({ ...state, visibleConfig: true })
          }}
        /> : null}
    </BoxItem>

    {subServiceVisible ? (
      <ViewSubService
        hidePrice={true}
        item={subServiceVersion}
        onClose={() => {
          setState({ ...state, subServiceVisible: null });
        }}
      />
    ) : null}

    {visibleConfig ? <EditServiceSubServiceConfig
      rtn={rtn}
      rootServiceVersion={rootProposalVersion}
      path={path}
      editItem={getCopyObj()}
      onCancel={() => {
        setState({ ...state, visibleConfig: null })
      }}
      hasOverride={isOverride}
      onReset={() => {
        doReset()
      }}
      onSave={(newRecord) => {
        const record = getOverrideRecord()

        Object.assign(record, newRecord)
        record.isRemoved = false
        saveOverrideRecord({ record })

        setState({ ...state, visibleConfig: null })
      }} /> : null}
  </React.Fragment>
}

const processItems = function ({ arr, removedServices, serviceVersion, packageId, serviceOptionIds }) {
  const { masterOverrides } = serviceVersion

  const circularIds = {}
  const processServiceVersion = function ({ serviceVersion, circularIds, path: startPath }) {
    const { service } = serviceVersion
    circularIds[service.id] = true

    const subServices = getSubServicesForService({ serviceVersion, packageId, serviceOptionIds });

    subServices.forEach((subServiceVersion) => {
      const { subService, service } = subServiceVersion
      const path = startPath + (startPath ? "." : "") + "service[" + subService.id + "]"

      const override = masterOverrides.find(item => item.path == path && item.overrideType == "sub-service")

      const { actualServiceVersion, packageId, serviceOptionIds, deliveryType } = override ? override : subServiceVersion

      const { businessRole } = actualServiceVersion
      const tasks = getTasksForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });
      const workHours = getWorkHours({ tasks });
      const { price } = getServicePrice2({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds, deliveryType })

      const data = {
        path,
        subServiceVersion,
        service,
        serviceVersion: actualServiceVersion,
        businessRole,
        workHours,
        price,
        tasks, packageId, serviceOptionIds, deliveryType
      }

      var cont = true
      if (override) {
        const { isRemoved } = override
        if (isRemoved) {
          removedServices.push(data)
          cont = false
        }
      }
      if (cont) {
        arr.push(data)
        processServiceVersion({ serviceVersion: actualServiceVersion, path, circularIds, packageId, serviceOptionIds })
      }
    })
  }

  processServiceVersion({ serviceVersion, circularIds, path: "" })
}

export function RenderPercentCompleteForMonth({ serviceOrders }) {
  const [state, setState] = useState({ isLoading: true });
  const { totalHours, completedHours, isLoading } = state;

  useEffect(() => {
    dL.getQuery("TaskRecord")
      .equalTo("parentSOIds", serviceOrders.map(item => item.id))
      .containedIn("removed", [undefined, false])
      .select(["status", "hours"])
      .find()
      .then(function (objs) {
        const tasks = dL.loadObjects("TaskRecord", objs)

        var total = 0
        var completed = 0
        tasks.forEach(task => {
          const { status, hours } = task
          total += hours
          if (status == "completed") {
            completed += hours
          }
        })

        setState({ ...state, isLoading: false, totalHours: total, completedHours: completed })
      });
  }, []);

  if (isLoading) {
    return <View />
  }
  if (totalHours == 0) {
    return <View />
  }
  return <TextLine label="Completed:" value={roundNumber(completedHours / totalHours, 2) + "%"} />
}

function getItems({ proposal, proposalVersion }) {
  const allItemsV = getAllItemsForVersion(proposalVersion).values()
  const allItems = proposal.allItems

  return allItemsV.map(({ id }) => {
    const item = allItems.get(id)
    return item
  }).filter(item => item != null)
}

export function RenderProposalTimeline({ rtn, historicalProposalVersion, rootProposalVersion, serviceOrders, staffOrders, proposal, currentPVersion, onUpdateOverrides }) {
  const [state, setState] = useState({ tabTValue: "recommended", isLoading: true })
  const { tabTValue, isLoading, monthArr, selectedMonthItem } = state
  const { fluxDelivery, fluxScope } = proposal

  const getMonthItem = function ({ date }) {
    const dt = Moment(date).format("MM-YYYY")

    return {
      dt,
      date: Moment(date).startOf("month").toDate(),
      services: [],
      proposalItems: [],
      businessRoles: new HashTable(),

      serviceOrders: [],
      staffOrders: [],
    }
  }

  const getMonthlyListSO = function () {
    const months = {}

    serviceOrders.forEach((serviceOrder) => {
      const { startDate } = serviceOrder

      const dt = Moment(startDate).format("MM-YYYY")
      if (!months[dt]) {
        months[dt] = getMonthItem({ date: startDate })
      }

      months[dt].serviceOrders.push(serviceOrder)
    })

    staffOrders.forEach((staffOrder) => {
      const { numberOfMonths, startDate } = staffOrder

      var date = Moment(startDate)
      for (var i = 0; i < numberOfMonths; i++) {
        const dt = date.format("MM-YYYY")

        if (!months[dt]) {
          months[dt] = getMonthItem({ date })
        }

        months[dt].staffOrders.push(staffOrder)
        date = date.add(1, "month")
      }
    })

    const getWorkerItem = function ({ user }) {
      return {
        user,
        serviceOrders: [],
        staffOrders: [],
        proposalItems: []
      }
    }
    const getBusinessItem = function ({ businessRole }) {
      return {
        businessRole,
        serviceOrders: [],
        staffOrders: [],
        proposalItems: []
      }
    }

    const monthArr = []
    for (var key in months) {
      const month = months[key]

      const { serviceOrders, staffOrders } = month

      const businessRoles = {}
      const workers = {}
      const services = {}
      const proposalItems = {}
      const noWorker = []
      month.noWorker = {
        serviceOrders: [],
        staffOrders: []
      }

      var total = 0
      serviceOrders.forEach(serviceOrder => {
        const { assignedTo, businessRole, service, proposalItem } = serviceOrder
        const assignedToId = assignedTo ? assignedTo.id : null
        if (!workers[assignedToId]) {
          noWorker.serviceOrders.push(serviceOrder)
          workers[assignedToId] = getWorkerItem({ user: assignedTo })
        }
        workers[assignedToId].serviceOrders.push(serviceOrder)

        const businessRoleId = businessRole.id
        if (!businessRoles[businessRoleId]) {
          businessRoles[businessRoleId] = getBusinessItem({ businessRole })
        }
        businessRoles[businessRoleId].serviceOrders.push(serviceOrder)

        const serviceId = service.id
        services[serviceId] = service

        const proposalItemId = proposalItem.id
        proposalItems[proposalItemId] = proposalItem

        total += serviceOrder.total
      })

      staffOrders.forEach(staffOrder => {
        const { assignedTo, businessRole, proposalItem } = staffOrder
        const assignedToId = assignedTo ? assignedTo.id : null
        if (!workers[assignedToId]) {
          noWorker.staffOrders.push(staffOrder)
          workers[assignedToId] = getWorkerItem({ user: assignedTo })
        }
        workers[assignedToId].staffOrders.push(staffOrder)

        const businessRoleId = businessRole.id
        if (!businessRoles[businessRoleId]) {
          businessRoles[businessRoleId] = getBusinessItem({ businessRole })
        }
        businessRoles[businessRoleId].staffOrders.push(staffOrder)

        const proposalItemId = proposalItem.id
        proposalItems[proposalItemId] = proposalItem

        total += staffOrder.monthlyCost
      })

      month.businessRoles = []
      for (var key1 in businessRoles) {
        month.businessRoles.push(businessRoles[key1])
      }

      month.workers = []
      for (var key1 in workers) {
        month.workers.push(workers[key1])
      }

      month.services = []
      for (var key1 in services) {
        month.services.push(services[key1])
      }

      month.proposalItems = []
      for (var key1 in proposalItems) {
        month.proposalItems.push(proposalItems[key1])
      }

      month.total = total
      monthArr.push(month)
    }

    return monthArr
  }

  const getPItems = function ({ proposalItems }) {
    //const serviceItems = proposalItems.filter(item => item.itemType == "service")
    //const staffItems = proposalItems.filter(item => item.itemType == "staff")

    //per week: number of staff items: business roles: hours, consultants: (business role, hours), staff items with no consultant (business role, hours) selected

    //get all services items, get all micro-services, get all staff items
    //timeline for all the proposal Items, micro-services (obtained from each of these items)

    const staffItems = rtn.getStaffItems()
    const serviceItems = rtn.getServiceItems()
    const microServices = rtn.getMicroServices()
    getTimelineData(microServices).then(function ({ weekArr, roleArr, workerArr, workers }) {
      setState({ ...state, rtn, weekArr, roleArr, workerArr, workers, isLoadingTimeline: false })
    })

    //staffItems available per week:
    const weeks = {}

    var maxDate
    staffItems.forEach(staffItem => {
      const startDate = staffItem.getStartDate()
      const doneDate = staffItem.getDoneDate()

      const weekNum = Moment(doneDate).diff(startDate, "days") / 7 + 1

      var date = Moment(startDate).startOf("week").startOf("day")
      for (var i = 0; i < weekNum; i++) {
        const dt = date.format("M-D-YY")

        if (!weeks[dt]) {
          weeks[dt] = {
            dt,
            date: date.toDate(),
            staffItems: []
          }
        }

        weeks[dt].staffItems.push(staffItem)

        date = date.add(1, "week")
      }

      if (!maxDate || doneDate > maxDate) {
        maxDate = doneDate
      }
    })

    const weekNum = Moment(maxDate).diff(new Date(), "days") / 7 + 1
    const weekArr = []

    var date = Moment().startOf("week").startOf("day")
    for (var i = 0; i < weekNum; i++) {
      const dt = date.format("M-D-YY")
      if (weeks[dt]) {
        weekArr.push(weeks[dt])
      } else {
        weekArr.push({
          dt,
          date: date.toDate(),
          items: []
        })
      }
      date = date.add(1, "week")
    }


    const services = {}
    const months = {}
    const removedServices = []
    const noWorker = []

    var arr = []
    serviceItems.forEach((proposalItem) => {
      const { id, packageId, serviceOptionIds } = proposalItem

      const path = `p-item[${id}]`
      const itemG = rtn.getItemPP({ proposalItemId: proposalItem.id })
      //const deliveryDays = itemG.getTotalDeliveryDays()
      //const unitPrice = itemG.getTotalPrice()
      const startDate = itemG.getStartDate()
      const doneDate = itemG.getDoneDate()
      //const { days: pDays, startDayIndex } = getDataFromPriceMap({ priceMap: rootProposalVersion.priceMap, path })
      //const startDate = Moment().businessAdd(startDayIndex)
      //const doneDate = Moment(startDate).businessAdd(pDays)

      proposalItem._doneDate = doneDate
      proposalItem._startDate = startDate

      const actualServiceVersion = proposalItem.actualServiceVersion
      const dt = Moment(startDate).format("MM-YYYY")

      if (!months[dt]) {
        months[dt] = getMonthItem({ date: startDate })
      }

      const monthItem = months[dt]
      const { proposalItems } = monthItem

      arr.push(actualServiceVersion)

      processItems({ arr, removedServices, serviceVersion: actualServiceVersion, packageId, serviceOptionIds })

      proposalItems.push(proposalItem)
    })

    staffItems.forEach((proposalItem) => {
      const { numberOfMonths } = proposalItem

      const startDate = getStartDate(proposalItems, proposalItem);
      const doneDate = getDoneDate(proposalItems, proposalItem);
      proposalItem._doneDate = doneDate
      proposalItem._startDate = startDate

      var sDate = Moment(startDate)
      for (var i = 0; i < numberOfMonths; i++) {
        const dt = sDate.format("MM-YYYY")

        if (!months[dt]) {
          months[dt] = getMonthItem({ date: sDate })
        }

        const monthItem = months[dt]
        const { proposalItems } = monthItem

        proposalItems.push(proposalItem)
        sDate = sDate.add(1, "month")
      }
    })

    const workers = {}
    const businessRoles = {}
    arr.forEach(item => {
      const { businessRole, workHours, subServiceVersion } = item
      const { userService, service } = subServiceVersion ? subServiceVersion : item

      if (userService) {
        const { user } = userService
        const { id } = user

        if (!workers[id]) {
          workers[id] = { workHours: 0, user, proposalItems: [] }
        }
        const worker = workers[id]
        const { proposalItems } = worker
        proposalItems.push(item)

        worker.workHours += workHours
      } else {
        noWorker.push(item)
      }

      const { id } = businessRole
      if (!businessRoles[id]) {
        businessRoles[id] = { workHours: 0, businessRole, proposalItems: [] }
      }

      const serviceId = service.id
      services[serviceId] = service

      const role = businessRoles[id]
      const { proposalItems } = role
      proposalItems.push(item)

      role.workHours += workHours
    })

    const monthArr = []
    for (var key in months) {
      const month = months[key]

      const { proposalItems } = month

      var total = 0
      proposalItems.forEach(service => {
        total += service.total
      })
      month.total = total

      month.businessRoles = []
      for (var key1 in businessRoles) {
        month.businessRoles.push(businessRoles[key1])
      }

      month.workers = []
      for (var key1 in workers) {
        month.workers.push(workers[key1])
      }

      month.services = []
      for (var key1 in services) {
        month.services.push(services[key1])
      }
      monthArr.push(month)
    }
    return monthArr
  }

  const getMonthArr = function (tabTValue) {
    var proposalItems = getItems({ proposal, proposalVersion: currentPVersion })
    var monthArr

    if (tabTValue == "recommended") {
      proposalItems = proposalItems.filter(item => item.recommended)
      monthArr = getPItems({ proposalItems })
    } else if (tabTValue == "cart") {
      proposalItems = proposalItems.filter(item => item.inCart)
      monthArr = getPItems({ proposalItems })
    } else if (tabTValue == "purchased") {
      monthArr = getMonthlyListSO()
    }
    return monthArr
  }

  useEffect(() => {
    const monthArr = getMonthArr(tabTValue)
    setState({ ...state, monthArr, selectedMonthItem: monthArr[0], isLoading: false })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  //get all the items for this proposalVersion, get the startDate for each service item, enter the startDate for each staff resource for the duration of months based on contract terms, display the month (30, 60, 90 and the items within the duration and the related cost)

  //display the project flux cost based on selected services
  //more flexible scope (1.75x on delivery days, reduce price), less flexible scope (1x on delivery days, increase price)
  //lock in all services
  //lock in 25 (increase price), 50, 75, 100% (100% reduce price)
  //cost reduced by 10% for total lockin and increase by 10% for least lockin

  //monthly scope or weekly scope
  //monthly for projects > 2 months, else every 2 weeks

  var label1, label2, label3
  if (tabTValue == "recommended") {
    label1 = "Services purchased during the selected month:"
    label2 = "Consultants for recommended services:"
    label3 = "Business roles for recommended services:"

  } else if (tabTValue == "cart") {
    label1 = " Services in cart during the selected month:"
    label2 = "Consultants for in cart services:"
    label3 = "Business roles for the in cart services:"
  }

  const { proposalItems, businessRoles, workers, noWorker } = selectedMonthItem ? selectedMonthItem : {}

  const renderProposalItem = function ({ proposalItem }) {
    const { itemType, path } = proposalItem

    if (itemType == "service") {
      const { actualServiceVersion } = proposalItem
      const { name, workHours } = actualServiceVersion

      return <BoxItem>
        <TextLine label="Service:" value={name} />
        <TextLine label="Work Hours:" value={workHours} />
        <TextLine label="Path:" value={path} />
      </BoxItem>
    } else {
      const { businessRole, hoursPerMonth } = proposalItem
      const { name } = businessRole

      return <BoxItem>
        <TextLine label="Business Role:" value={name} />
        <TextLine label="Work Hours:" value={hoursPerMonth} />
        <TextLine label="Path:" value={path} />
      </BoxItem>
    }
  }

  const render1 = function () {
    return <React.Fragment>
      <Section>
        <TextLine bottom={0} size={18} bold value={label1} />

        <ItemExpandSection label={plural(serviceOrders.length, "Service") + ":"}>
          {serviceOrders.map(serviceOrder => {
            const { id, service, _startDate, _doneDate, quantity, total } = serviceOrder

            const { name, shortDescription } = service
            const days = Moment(_doneDate).diff(Moment().startOf("day"), "days")

            return <BoxItem key={id} name={name} description={shortDescription}>
              <FlexRow top={10}>
                <FlexExpand>
                  <TextLine size={14} label="Start Date:" value={Moment(_startDate).format("M/D/YYYY")} />
                  <TextLine size={14} label="Delivery:" value={Moment(_doneDate).format("M/D/YYYY") + " (" + plural(days, "day") + ")"} />
                </FlexExpand>
                <Quantity value={quantity} />
                <Price price={total} size={24} />
              </FlexRow>
            </BoxItem>
          })}
        </ItemExpandSection>
      </Section>

      <Section>
        <TextLine bottom={0} size={18} bold value={label2} />
        <ItemExpandSection label={plural(workers.length, "Consultant") + ":"}>
          {workers.map(item => {
            const { user, workHours, proposalItems } = item

            return <BoxItem>
              <UserItem label="Consultant:" user={user} />
              <TextLine label="Work Hours:" value={workHours} />

              {proposalItems.map(item => {
                return renderProposalItem({ proposalItem: item })
              })}
            </BoxItem>
          })}
        </ItemExpandSection>
      </Section>

      <Section>
        <TextLine bottom={0} size={18} bold value={label3} />

        <ItemExpandSection label={plural(staffOrders.length, "Staff Order") + ":"}>
          {staffOrders.map(staffOrder => {
            const { id, _startDate, _doneDate, quantity, total, businessRole } = staffOrder
            const { name } = businessRole
            const days = Moment(_doneDate).diff(Moment().startOf("day"), "days")

            return <BoxItem key={id} name={name}>
              <FlexRow top={10}>
                <FlexExpand>
                  <TextLine size={14} label="Start Date:" value={Moment(_startDate).format("M/D/YYYY")} />
                  <TextLine size={14} label="Delivery:" value={Moment(_doneDate).format("M/D/YYYY") + " (" + plural(days, "day") + ")"} />
                </FlexExpand>
                <Quantity value={quantity} />
                <Price price={total} size={24} />
              </FlexRow>

              <StaffOrderStats id={id} />
            </BoxItem>
          })}
        </ItemExpandSection>

        <ItemExpandSection label={plural(businessRoles.length, "Role") + ":"}>
          {businessRoles.map(item => {
            const { businessRole, workHours, proposalItems } = item
            const { name } = businessRole

            return <BoxItem>
              <TextLine label="Business Role:" value={name} />
              <TextLine label="Work Hours:" value={workHours} />

              <ItemExpandSection label={plural(proposalItems.length, "Service") + " to complete:"}>
                {proposalItems.map(item => {
                  return renderProposalItem({ proposalItem: item })
                })}
              </ItemExpandSection>
            </BoxItem>
          })}
        </ItemExpandSection>
      </Section>
    </React.Fragment>
  }

  const renderServiceOrders = function ({ serviceOrders }) {
    <ItemExpandSection label={plural(serviceOrders.length, "Service") + " to complete:"}>
      {serviceOrders.map(serviceOrder => {
        return renderServiceOrder({ serviceOrder })
      })}
    </ItemExpandSection>
  }

  const renderStaffOrders = function ({ staffOrders }) {
    return <ItemExpandSection label={plural(staffOrders.length, "Staff Role") + ":"}>
      {staffOrders.map(staffOrder => {
        return renderStaffOrder({ staffOrder })
      })}
    </ItemExpandSection>
  }

  const renderServiceOrder = function ({ serviceOrder }) {
    const { path, workHours, service } = serviceOrder

    const { name } = service

    return <BoxItem>
      <TextLine label="Service:" value={name} />
      <TextLine label="Work Hours:" value={workHours} />
      <TextLine label="Path:" value={path} />
    </BoxItem>
  }

  const renderStaffOrder = function ({ staffOrder }) {
    const { businessRole, path } = staffOrder
    const { name } = businessRole

    return <BoxItem>
      <TextLine label="Business Role:" value={name} />
      <TextLine label="Path:" value={path} />
    </BoxItem>
  }

  const render2 = function () {
    return <React.Fragment>
      <Section>
        <TextLine bottom={0} size={18} bold value="Services purchased during the selected month:" />

        <ItemExpandSection label={plural(proposalItems.length, "Service") + ":"}>
          {proposalItems.map(proposalItem => {
            const { id, itemType, service, serviceVersion, _startDate, _doneDate, quantity, total } = proposalItem

            if (itemType == "service") {
              const { subServices } = serviceVersion

              const { name, shortDescription } = service
              const days = Moment(_doneDate).diff(Moment().startOf("day"), "days")

              const path = `p-item[${id}]`

              const circularIds = {}
              circularIds[service.id] = true

              return <BoxItem key={id} name={name} description={shortDescription} rightRender={<View>
                <FlexRow>
                  <Quantity value={quantity} />
                  <Price price={total} size={24} />
                </FlexRow>
              </View>}
                render={<View>
                  {subServices.length > 0 ? <View top={15}>
                    <ItemExpandSection label={plural(subServices.length, "Sub-Service") + ":"}>
                      {subServices.map(subServiceVersion => {
                        const { id } = subServiceVersion

                        return <DisplaySubServiceItem
                          proposalItem={proposalItem}
                          key={id}
                          rtn={rtn}
                          circularIds={{ ...circularIds }}
                          rootProposalVersion={rootProposalVersion}
                          historicalProposalVersion={historicalProposalVersion}
                          subServiceVersion={subServiceVersion}
                          path={path}
                          onUpdateOverrides={onUpdateOverrides} />
                      })}
                    </ItemExpandSection>
                  </View> : null}
                </View>}>

                <FlexRow top={10}>
                  <TextLine size={14} label="Start Date:" value={Moment(_startDate).format("M/D/YYYY")} spacer />
                  <TextLine size={14} label="Delivery:" value={Moment(_doneDate).format("M/D/YYYY") + " (" + plural(days, "day") + ")"} />
                </FlexRow>
              </BoxItem>

            } else if (itemType == "staff") {
              const { skillLevel, businessRole, _startDate, _doneDate, total } = proposalItem
              const { name } = businessRole

              return <BoxItem key={id} name={name}>
                <FlexRow top={10}>
                  <FlexExpand>
                    <TextLine size={14} label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} />
                    <TextLine size={14} label="Start Date:" value={Moment(_startDate).format("M/D/YYYY")} />
                    <TextLine size={14} label="End Date:" value={Moment(_doneDate).format("M/D/YYYY")} />
                  </FlexExpand>
                  <Quantity value={quantity} />
                  <Price price={total} size={24} />
                </FlexRow>
              </BoxItem>
            }
          })}
        </ItemExpandSection>
      </Section>

      <Section>
        <TextLine bottom={0} size={18} bold value="Business roles for the purchased services:" />
        <ItemExpandSection label={plural(businessRoles.length, "Role") + ":"}>
          {businessRoles.map(item => {
            const { businessRole, serviceOrders, staffOrders } = item
            const { name } = businessRole

            return <BoxItem>
              <TextLine label="Business Role:" value={name} />

              {renderServiceOrders({ serviceOrders })}
              {renderStaffOrders({ staffOrders })}
            </BoxItem>
          })}
        </ItemExpandSection>
      </Section>

      <Section>
        <TextLine bottom={0} size={18} bold value="Consultants for purchased services:" />
        <ItemExpandSection label={plural(workers.length, "Consultant") + ":"}>
          {workers.map(item => {
            const { user, workHours, serviceOrders, staffOrders } = item

            return <BoxItem>
              <UserItem label="Consultant:" user={user} />
              <TextLine label="Work Hours:" value={workHours} />

              {renderServiceOrders({ serviceOrders })}
              {renderStaffOrders({ staffOrders })}
            </BoxItem>
          })}
        </ItemExpandSection>
      </Section>

      <Section>
        <TextLine bottom={0} size={18} bold value="Services with no consultants for purchased services:" />

        {renderServiceOrders({ serviceOrders: noWorker.serviceOrders })}
        {renderStaffOrders({ staffOrders: noWorker.staffOrders })}
      </Section>
    </React.Fragment >
  }

  return (
    <Section>
      <HeaderText subHeader label="Strategy Cost &amp; Delivery Timeline:" description="See the cost and timeline for delivering on this proposal." rightRender={
        <TabBar
          options={[{ label: "Recommended", value: "recommended" }, { label: "Purchased", value: "purchased" }, { label: "Cart", value: "cart" }]}
          onChange={item => {
            const monthArr = getMonthArr(item.value)
            setState({ ...state, monthArr, selectedMonthItem: monthArr[0], tabTValue: item.value })
          }}
          value={tabTValue}
        />
      } />

      <Section>
        <FlexRow style={{ overflowY: "scroll" }}>
          {monthArr.map((item) => {
            const { date, proposalItems, serviceOrders, total } = item

            return <BoxRowItem style={{ marginRight: 15, marginBottom: 15, minWidth: 125, borderWidth: 2, borderColor: selectedMonthItem == item ? "black" : null }} onPress={() => {
              setState({ ...state, selectedMonthItem: item })
            }}>
              <TextLine bold size={22} value={Moment(date).format("M-YYYY") + ":"} />

              <View top={10}>
                {tabTValue == "purchased" ? <View>
                  <TextLine value={proposalItems.length + " purchased"} />
                  <TextLine value={money(total)} />

                  <TextLine size={14} top={10} value={"25 micro services"} />
                </View> : null}

                {tabTValue == "recommended" ? <View>
                  <TextLine value={proposalItems.length + " recommended"} />
                  <TextLine value={money(total)} />

                  <TextLine size={14} top={10} value={"25 micro services"} />
                </View> : null}

                {tabTValue == "cart" ? <View>
                  <TextLine value={proposalItems.length + " in cart"} />
                  <TextLine value={money(total)} />

                  <TextLine size={14} top={10} value={"25 micro services"} />
                </View> : null}
              </View>

              <RenderPercentCompleteForMonth serviceOrders={serviceOrders} />
            </BoxRowItem>
          })}
        </FlexRow>
      </Section>

      {!selectedMonthItem ? <NoRecords label="No strategy items found." /> : null}

      {selectedMonthItem && tabTValue == "cart" ?
        render1()
        : null}

      {selectedMonthItem && tabTValue == "recommended" ?
        render1()
        : null}

      {selectedMonthItem && tabTValue == "purchased" ?
        render2()
        : null}
    </Section>
  );
}

export function InviteMember({ proposalId, onCancel, onAddUser, onAddEmail, onDone }) {
  const [state, setState] = useState({ inviteUsers: [] });
  const { inviteUsers, inviteEmails, inviteMessage } = state;

  return (
    <ModalBasic
      title="Invite Member"
      okText="Invite"
      onCancel={onCancel}
      onOk={() => {
        if (inviteUsers.length == 0 && !inviteEmails) {
          alert("Must select an user.");
          return;
        }

        const sendEmail = function ({ email, id }) {
          var message = "Hi,<br><br>You are invite to join a Growly platform chat by " + session.user.firstName + " " + session.user.lastName + ".<br><br>" + inviteMessage + "<br><br>Follow the following link to join the chat: http://growly.app/user/proposal/" + proposalId + "/" + id;
          console.log(message);
          return http.run("sendEmail", {
            to: email,
            subject: "New Invitation",
            message: message,
            fromEmail: "support@interviewxpress.com"
          });
        };

        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              if (!utils.isEmail(email)) {
                alert(email + " is not a valid email.");
                return;
              }
            }
          }
        }

        const promises = [];
        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              promises[promises.length] = onAddEmail({ email }).then(function (obj) {
                return sendEmail({ email, id: obj.id });
              });
            }
          }
        }

        inviteUsers.forEach(user => {
          const { email } = user;

          promises[promises.length] = onAddUser(user).then(function (obj) {
            return sendEmail({ email, id: obj.id });
          });
        });

        return Promise.all(promises).then(function () {
          return onDone();
        });
      }}>
      <FormItem label="Add users:" required>
        <SelectUsers
          excludedValues={[...inviteUsers, session.user]}
          onChange={items => {
            setState({ ...state, inviteUsers: items });
          }}
          value={inviteUsers}
        />
      </FormItem>

      <TextLine bold bottom={25} value="OR" />
      <MyInput
        multiline
        label="Emails:"
        description="Enter one email per line to invite."
        value={inviteEmails}
        onChange={value => {
          setState({ ...state, inviteEmails: value });
        }}
      />
      <MyInput
        lastItem
        multiline
        label="Email Message:"
        description="Enter a short message in the email invite body."
        value={inviteMessage}
        onChange={value => {
          setState({ ...state, inviteMessage: value });
        }}
      />
    </ModalBasic>
  );
}
