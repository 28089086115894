import Moment from "moment-business-days";
import { HashTable } from "root/hashtable";

const utils = {
  getDeleteRemovedItems: function (version, items) {
    const { overrides } = version
    return items.filter(item => {
      return !overrides[item.id + "-delete"];
    });
  },
  getAllItemsForVersion: function (version) {
    const { items } = version

    const hash = new HashTable();

    getDeleteRemovedItems(version, items).forEach(item => {
      const { id, items } = item;
      hash.set(id, item);

      if (items) {
        getDeleteRemovedItems(version, items).forEach(item => {
          const { id, items } = item;
          hash.set(id, item);

          getDeleteRemovedItems(version, items).forEach(item => {
            const { id } = item;
            hash.set(id, item);
          });
        });
      }
    });

    return hash;
  },
  combineArray: function (a, min) {
    var fn = function (n, src, got, all) {
      if (n == 0) {
        if (got.length > 0) {
          all[all.length] = got;
        }
        return;
      }
      for (var j = 0; j < src.length; j++) {
        fn(n - 1, src.slice(j + 1), got.concat([src[j]]), all);
      }
      return;
    }
    var all = [];
    for (var i = min; i < a.length; i++) {
      fn(i, a, [], all);
    }
    all.push(a);
    return all;
  },
  dateParser: function (key, value) {
    const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
    const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

    if (typeof value === "string") {
      var a = reISO.exec(value);
      if (a) return new Date(value);
      a = reMsAjax.exec(value);
      if (a) {
        var b = a[1].split(/[-+,.]/);
        return new Date(b[0] ? +b[0] : 0 - +b[1]);
      }
    }
    return value;
  },
  guid: function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  },
  getCircularIds: function ({ circularIds: defaultCircularIds, id }) {
    const circularIds = defaultCircularIds ? defaultCircularIds : {}
    if (circularIds[id]) {
      return
    }
    circularIds[id] = true
    return circularIds
  },
  isRequiredItem: function ({ limitedToPackages, limitedToOptions, packageId, serviceOptionIds }) {
    if (!limitedToPackages || ((packageId && limitedToPackages.includes(packageId)) || limitedToPackages.length == 0)) {
      if (!limitedToOptions || (limitedToOptions.length == 0 || limitedToOptions.filter(id => (serviceOptionIds ? serviceOptionIds[id] : false)).length > 0)) {
        return true;
      }
    }
  },
  readSeq: function (arr2, seq) {
    const arr = [];
    if (seq) {
      seq.forEach(id => {
        const indexF = arr2.findIndex(item => id == item.id);
        if (indexF != -1) {
          arr.push(arr2[indexF]);
          arr2.splice(indexF, 1);
        }
      });
    }
    return arr.concat(arr2);
  },
  jsonParse: function (str) {
    return JSON.parse(str, utils.dateParser);
  },
  roundMoney: function (value, decimals) {
    if (!decimals) {
      decimals = 2;
    }
    return utils.roundNumber(value, decimals);
  },
  roundNumber: function (value, decimals) {
    if (!decimals) { decimals = 0 }
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }
};

const { getDeleteRemovedItems } = utils

export default utils;
