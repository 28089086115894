import "./App.css";
import React, { useState, useEffect } from "react";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import Moment from "moment-business-days";
import "root/App.css";
import { __DEV__ } from "root/dev";
import { BoxRowItem, HeaderText } from "root/pack-2";
import { FlexExpand, Section, LabelItem, FlexRow, TextLine, TouchButton, NoRecords } from "root/pack-1";

const { money } = utils;

export function DisplayAccountCharges({ model, account, charges, isAdmin }) {
  const { retryCount, nextRetryDate, chargeStatus, id } = account;
  const [state, setState] = useState({ isLoading: true });
  const { balanceDue, isLoading } = state;

  useEffect(() => {
    dL.getAccountBalance(id).then(function (amount) {
      setState({ ...state, isLoading: false, balanceDue: amount });
    });
  }, []);

  return (
    <Section>
      <HeaderText subHeader label="Charges:" />
      <FlexRow bottom={10}>
        <FlexExpand>
          {chargeStatus ? <TextLine value={"Last Charge Status: " + chargeStatus} spacer /> : null}
          <TextLine value={"Balance Due: " + (isLoading ? "?" : money(balanceDue))} spacer />
        </FlexExpand>

        {isAdmin && balanceDue > 0 ? (
          <TouchButton
            micro
            style={{ marginRight: 10 }}
            label="Process Charges"
            onPress={() => {
              return dL.processCharge(model).then(function () {
                alert("Completed.");
              });
            }}
          />
        ) : null}

        <TouchButton
          micro
          label="Update Card"
          onPress={() => {
            alert("Not yet supported.");
            //update the credit card on file for this account
          }}
        />
      </FlexRow>

      {chargeStatus == "failed" ? (
        <FlexRow bottom={10}>
          <TextLine value={"Retry Count: " + retryCount} spacer />
          <TextLine value={"Next Retry Date: " + Moment(nextRetryDate).format("M/D/YYYY [at] h:mm a")} />
        </FlexRow>
      ) : null}

      {charges.length == 0 ? (
        <NoRecords label="No charges found." />
      ) : (
        charges.map(item => {
          const { createdAt, description, amount } = item;
          return (
            <BoxRowItem>
              <FlexRow bottom={-10}>
                <LabelItem label="Date:" value={Moment(createdAt).format("M/D/YYYY [at] h:mm a")} />
                <LabelItem label="Description:" value={description} style={{ flex: 1 }} />
                <LabelItem label="Amount:" value={utils.money(amount)} />
              </FlexRow>
            </BoxRowItem>
          );
        })
      )}
    </Section>
  );
}

export function DisplayAccountPayments({ payments }) {
  return (
    <Section>
      <HeaderText subHeader label="Payments:" />
      {payments.length == 0 ? (
        <NoRecords label="No payments found." />
      ) : (
        payments.map(item => {
          const { createdAt, creditCard, amount, status } = item;
          return (
            <BoxRowItem>
              <FlexRow bottom={-10}>
                <LabelItem label="Date:" value={Moment(createdAt).format("M/D/YYYY [at] h:mm a")} />
                <LabelItem label="Credit Card:" value={creditCard.cardType + " ending in " + creditCard.last4} style={{ flex: 1 }} />
                <LabelItem label="Amount:" value={utils.money(amount)} />
                <LabelItem label="Status:" value={status} />
              </FlexRow>
            </BoxRowItem>
          );
        })
      )}
    </Section>
  );
}
