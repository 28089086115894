import "./App.css";
import React, { useState, useEffect } from "react";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import http from "root/utils/http";
import { __DEV__ } from "root/dev";
import { Price, Quantity } from "root/pack-3";
import { BoxRowItem, HeaderText, UserItem, SelectUser } from "root/pack-2";
import { NoRecordsBox, BoxItem, MyInput, Toggle, LinkButton, ModalBasic, Section, LabelItem, FlexExpand, FlexRow, Text, TextLine, View, TrashIcon, EditIcon, FormItem, TouchButton, SelectBox, NoRecords } from "root/pack-1";
import { EditStaffInCart, EditServiceInCart } from "root/proposals-1";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { SelectCreditCard } from "root/credit-card";
import { NewProject } from "root/projects";
import { confirmAlert } from "react-confirm-alert";

const { roundMoney, deleteCartItem, staffServiceTypes, plural, money, getLabelItem } = utils;

export function CreateAccountPayment({ accountId, amount: defaultAmount, onCancel, onSave }) {
  const [state, setState] = useState({ amount: defaultAmount });
  const { termsVisible, agreeToTerms, creditCard, amount } = state;

  return <ModalBasic
    large
    notCentered
    title="Payment:"
    okText="Pay Now"
    onCancel={onCancel}
    onOk={() => {
      if (!utils.isNumber(amount)) {
        alert("Must enter valid hours.");
        return;
      }
      if (!agreeToTerms) {
        alert("Must agree to terms.");
        return;
      }
      if (!creditCard) {
        alert("Must select credit card.");
        return;
      }

      const totalAmount = Number(amount);
      const testMode = session.user.testMode;

      return http.run("chargeCard2", {
        testMode,
        accountId,
        creditCard,
        referenceId: session.user.id,
        createdById: session.user.id,
        amount,
      }).then(function (results) {
        const { card, err, paymentId } = results;
        if (err) {
          alert("Error processing charges.")
        } else {
          onSave(totalAmount)
        }
      })
    }}>

    <MyInput
      style={{ maxWidth: 125 }}
      inputType="money"
      minValue={0}
      label="Payment Amount:"
      value={amount}
      onChange={(value) => {
        setState({ ...state, amount: value });
      }}
    />
    <Section>
      <HeaderText subHeader label="Credit Card:" description="Enter your credit card information to complete your purchase." />
      <SelectCreditCard
        referenceId={session.user.id}
        value={creditCard}
        onChange={value => {
          setState({ ...state, creditCard: value });
        }}
      />
    </Section>

    <Toggle
      label="Agree to Terms and Conditions:"
      value={agreeToTerms}
      onChange={value => {
        setState({ ...state, agreeToTerms: value });
      }} >
      <LinkButton
        onPress={() => {
          setState({ ...state, termsVisible: true });
        }}
        label="View Terms and Conditions"
      />
    </Toggle>

    {termsVisible ? <ModalBasic
      large
      notCentered
      title="Terms and Conditions:"
      okText="Done"
      onOk={() => {
        setState({ ...state, termsVisible: false })
      }}>
      <TextLine value="TERMS HERE" />
    </ModalBasic>
      : null}
  </ModalBasic>
}

const RenderService = function ({ cartItem, onPress }) {
  const { id } = cartItem;
  const { proposal, userService, deliveryType, service, servicePackage, deliveryDays, serviceOptions } = cartItem;

  return (
    <View>
      <TextLine size={18} bold value={service.name} onPress={onPress} />
      {servicePackage ? <TextLine size={14} grey value={"Package: " + servicePackage.name} /> : null}
      <TextLine size={14} grey value={(deliveryType == "fast" ? "FAST " : "") + "Delivery in " + plural(deliveryDays, "day")} />
      {proposal ? <TextLine top={8} grey label="Proposal:" value={proposal.title} /> : null}
      {userService ? <UserItem label="Consultant:" user={userService.user} style={{ marginTop: 8 }} /> : null}

      {serviceOptions && serviceOptions.length > 0 ? (
        <View style={{ marginLeft: 15, marginTop: 8 }}>
          {serviceOptions.map(item => {
            const { name } = item;
            return <TextLine size={16} value={"+ " + name} />;
          })}
        </View>
      ) : null}
    </View>
  );
};

const RenderStaff = function ({ cartItem, onPress }) {
  const { id } = cartItem;

  const { proposal, staffServiceType, skillLevel, hoursPerMonth, numberOfMonths, businessRole, assignedTo } = cartItem;

  return (
    <View>
      <TextLine onPress={onPress} bold size={18} value={businessRole.name + " (" + getLabelItem(staffServiceTypes, staffServiceType) + ")"}
      />
      <TextLine color="grey" size={14} label="Skill Level:" value={getLabelItem(utils.skillLevels, skillLevel)} />
      <TextLine color="grey" size={14} top={8} label="Terms:" value={plural(hoursPerMonth, "hrs per month") + ", " + plural(numberOfMonths, "month")} />
      {proposal ? <TextLine size={14} top={8} grey label="Proposal:" value={proposal.title} /> : null}
      {assignedTo ? <UserItem label="Consultant:" user={assignedTo} style={{ marginTop: 8 }} /> : null}
    </View>
  );
};

export function RenderCartItems({ onUpdate }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const [editViewItem, setEditViewItem] = useState();

  const cart = session.cart;

  if (!cart) {
    return <NoRecords label="No cart items." />
  }

  //noProposalServiceItems
  //proposals
  //break down by timeline
  //set the total proposal
  //set the monthly cost (average) or (per month with auto charge for the next month what is approved)

  return <React.Fragment>
    {cart.items.length > 0 ? (
      cart.items.map((cartItem) => {
        const { itemType, monthlyTotal, total, quantity } = cartItem;

        return (
          <BoxRowItem style={{ marginBottom: 15 }}>
            <FlexRow alignTop>
              <FlexExpand>
                {itemType == "service" ? <RenderService cartItem={cartItem}
                  onPress={() => {
                    const { userService, service } = cartItem

                    if (userService) {
                      history.push(`/${sectionId}/service/${service.id}/${userService.user.id}`);
                    } else {
                      history.push(`/${sectionId}/service/${service.id}`);
                    }
                  }} /> : itemType == "staff" ? <RenderStaff cartItem={cartItem} onPress={() => {
                    const { assignedTo } = cartItem

                    history.push(`/${sectionId}/user/people/${assignedTo.id}?tb=roles`);
                  }} /> : "NO MATCH"}
              </FlexExpand>

              <Quantity
                value={quantity}
                allowAdjust={itemType == "service" ? true : false}
                onUpdate={value => {
                  cartItem.quantity = value;
                  cartItem.total = cartItem.unitPrice * value;
                  utils.updateCartItem(cartItem);
                  dL.saveCart();
                  onUpdate()
                  setState({ ...state });
                }}
              />

              <View style={{ width: 125 }} alignRight>
                <TextLine size={22} value={money(itemType == "service" ? total : monthlyTotal)} left={25} />
                {itemType == "staff" ? <TextLine size={12} value="per month" /> : null}
              </View>

              <FlexRow>
                <EditIcon
                  style={{ marginLeft: 15 }}
                  onPress={() => {
                    setEditViewItem(cartItem);
                  }}
                />
                <TrashIcon
                  style={{ marginLeft: 15 }}
                  onPress={() => {
                    deleteCartItem(cartItem);
                    onUpdate()
                    setState({ ...state });
                  }}
                />
              </FlexRow>
            </FlexRow>
          </BoxRowItem>
        );
      })
    ) : (
      <NoRecords label="No cart items." />
    )}

    {editViewItem ? (
      editViewItem.itemType == "staff" ? (
        <EditStaffInCart
          isEdit
          cartItem={editViewItem}
          onCancel={() => {
            setEditViewItem();
          }}
          onAddToCart={cartItem => {
            const { total } = cartItem;
            cartItem.unitPrice = total;
            cartItem.total = total;
            utils.updateCartItem(cartItem);
            dL.saveCart();
            setEditViewItem();
            onUpdate()
          }}
        />
      ) : editViewItem.itemType == "service" ? (
        <EditServiceInCart
          isEdit
          cartItem={editViewItem}
          onCancel={() => {
            setEditViewItem();
          }}
          onAddToCart={cartItem => {
            const { total, unitPrice, quantity } = cartItem;
            cartItem.unitPrice = unitPrice;
            cartItem.quantity = quantity;
            cartItem.total = total;
            utils.updateCartItem(cartItem);
            dL.saveCart();
            setEditViewItem();
            onUpdate()
          }}
        />
      ) : null
    ) : null}
  </React.Fragment>
}

export function Cart({ }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const [state, setState] = useState({});

  const [proposals, setProposals] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dL.getQuery("ServiceProposal")
      .equalTo("user", dL.getObj("User", session.user.id))
      .greaterThan("buyerItemsInCart", 0)
      .containedIn("removed", [undefined, false])
      .descending("lastBuyerCartUpdatedAt")
      .find()
      .then(function (objs) {
        const proposals = dL.loadObjects("ServiceProposal", objs)
        setProposals(proposals)
        setIsLoading(false)
      })
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return <View style={{ maxWidth: 650 }}>
    <Section>
      <HeaderText label="My Service Carts:" description="Below are a list of your open service carts." />

      {proposals.length == 0 ?
        <NoRecordsBox label="No carts found.">
          <TouchButton
            micro
            onPress={() => {
              alert("Not yet implemented.")
            }}
            description="Create a sharable cart with your services."
            label="Create a cart"
          />
        </NoRecordsBox>
        : null}

      {proposals.map((proposal, index) => {
        const { id, title, buyerItemsInCart, buyerCartTotal } = proposal

        return <BoxItem
          name={title}
          description={plural(buyerItemsInCart, "item")}
          rightRender={<View>
            <Price price={buyerCartTotal} />
          </View>}
          onBoxPress={() => {
            history.push(`/${sectionId}/proposal/${id}`);
          }} onDelete={() => {
            confirmAlert({
              title: "Alert!",
              message: "Are you sure you want to delete this cart?",
              buttons: [
                {
                  label: "Yes, delete it.",
                  onClick: () => {
                    proposals.splice(index, 1)
                    dL.getObj("ServiceProposal", id).set("status", "archived").save()
                    setState({ ...state, proposals })
                  }
                },
                {
                  label: "No."
                }
              ]
            });
          }} />
      })}
    </Section>
  </View>
}

export function CartOLD({ }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [client, setClient] = useState(sectionId == "user" ? session.user : null);
  const [project, setProject] = useState();
  const [visible, setVisible] = useState();

  const [state, setState] = useState({ isLoading: true });
  const { termsVisible, agreeToTerms, reviewOrder, creditCard, projects, isLoading } = state;

  //utils.clearShoppingCart()

  useEffect(() => {
    var projects
    dL.getMyProjects().then(function (_projects) {
      projects = _projects
      return dL.loadCart({ userId: session.user.id })
    }).then(function () {
      setState({ ...state, isLoading: false, projects });
    })
    /*.catch(function (err) {
      alert("Error: " + err);
    });
    */
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var total = 0;

  const cart = session.cart;
  if (cart) {
    cart.items.forEach(item => {
      total += item.total;
    });
  }

  const proposal = cart.items.length > 0 ? cart.items[0].proposal : null

  var contractTotal = 0
  var monthlyTotal = 0
  var totalMonths = 0, monthArr
  var paymentTermType = "monthly-equal"
  var monthlyPayments = []

  cart.items.forEach(item => {
    const { contractTotal: ciContractTotal, monthlyTotal: ciMonthlyTotal, numberOfMonths } = item
    contractTotal += ciContractTotal
    monthlyTotal += ciMonthlyTotal
    if (numberOfMonths > totalMonths) {
      totalMonths = numberOfMonths
    }
  })

  var paymentTotal = monthlyTotal

  const renderContract = function () {
    return <Section>
      <HeaderText subHeader label="Contract Details:" description="See the specific contract details for these staff roles and service orders." />

      {totalMonths > 0 ? paymentTermType == "monthly-equal" ? <TextLine size={18} label="Monthly Total:" value={money(roundMoney(monthlyTotal)) + " / month"} /> : monthArr.map((item, index) => {
        return <TextLine size={18} label={"Month " + (index + 1) + ":"} value={money(item.total)} />
      }) : null}

      <TextLine size={18} top={8} label="Contract Total:" value={money(contractTotal)} />
      {totalMonths > 0 ? <TextLine size={18} top={8} label="Contract Length:" value={plural(totalMonths, "month")} /> : null}

      <TextLine top={25} size={22} top={25} label="Due Today:" value={money(paymentTotal)} />
    </Section>
  }

  const renderEditOrder = function () {
    return <View style={{ maxWidth: 550 }}>
      <HeaderText label="Order Cart:" />

      <Section>
        <HeaderText subHeader label="Services:" description="Below is the list of service that are ready for purchase." />
        <RenderCartItems onUpdate={() => {
          setState({ ...state })
        }} />
      </Section>

      {renderContract()}

      {sectionId != "user" ? (
        <Section>
          <HeaderText subHeader label="Client:" />
          <FormItem box>
            <SelectUser
              onChange={item => {
                setClient(item);
              }}
              value={client}
            />
          </FormItem>
        </Section>
      ) : null}

      {client ? (
        <Section>
          <HeaderText subHeader label="Project:" description="Optionally, select or create a project for this order." />
          <SelectBox
            placeholder="Select existing project..."
            style={{ marginBottom: 10 }}
            value={project ? projects.find(item => item.id == project.id) : null}
            options={projects}
            valueField="id"
            textField="name"
            onChange={item => {
              setProject(item);
            }}
          />
          <TouchButton
            micro
            onPress={() => {
              setVisible(true);
            }}
            label="Create New Project"
          />
        </Section>
      ) : null}

      {client ? (
        <Section>
          <HeaderText subHeader label="Credit Card:" description="Enter your credit card information to complete your purchase." />
          <SelectCreditCard
            referenceId={client.id}
            value={creditCard}
            onChange={value => {
              setState({ ...state, creditCard: value });
            }}
          />
        </Section>
      ) : null}

      <Toggle
        label="Agree to Terms and Conditions:"
        value={agreeToTerms}
        onChange={value => {
          setState({ ...state, agreeToTerms: value });
        }} >
        <LinkButton
          onPress={() => {
            setState({ ...state, termsVisible: true });
          }}
          label="View Terms and Conditions"
        />
      </Toggle>

      {termsVisible ? <ModalBasic
        large
        notCentered
        title="Terms and Conditions:"
        okText="Done"
        onOk={() => {
          setState({ ...state, termsVisible: false })
        }}>
        <TextLine value="TERMS HERE" />
      </ModalBasic>
        : null}

      <TouchButton
        onPress={() => {
          if (!agreeToTerms) {
            alert("Must agree to terms.");
            return;
          }
          if (!client) {
            alert("Must select client.");
            return;
          }
          if (!creditCard) {
            alert("Must select credit card.");
            return;
          }
          window.scrollTo(0, 0)
          setState({ ...state, reviewOrder: true })
        }}
        label="Review Order"
      />
    </View>
  }

  const renderReviewOrder = function () {
    return <View style={{ maxWidth: 550 }}>
      <HeaderText label="Review Cart:" />

      <Section>
        <HeaderText subHeader label="Services:" />
        {cart && cart.items.length > 0 ? (
          cart.items.map((cartItem) => {
            const { itemType, total, quantity } = cartItem;

            return (
              <BoxRowItem style={{ marginBottom: 15 }}>
                <FlexRow alignTop>
                  <FlexExpand>
                    {itemType == "service" ? <RenderService cartItem={cartItem}
                      onPress={() => {
                        const { userService, service } = cartItem

                        if (userService) {
                          history.push(`/${sectionId}/service/${service.id}/${userService.user.id}`);
                        } else {
                          history.push(`/${sectionId}/service/${service.id}`);
                        }
                      }} /> : itemType == "staff" ? <RenderStaff cartItem={cartItem} onPress={() => {
                        const { assignedTo } = cartItem

                        history.push(`/${sectionId}/user/people/${assignedTo.id}?tb=roles`);
                      }} /> : "NO MATCH"}
                  </FlexExpand>

                  <Quantity
                    allowAdjust={false}
                    value={quantity}
                  />

                  <TextLine size={22} value={money(total)} left={25} />
                </FlexRow>
              </BoxRowItem>
            );
          })
        ) : (
          <NoRecords label="No cart items." />
        )}
      </Section>

      {renderContract()}

      {sectionId != "user" ? (
        <Section>
          <HeaderText subHeader label="Client:" />
          <LabelItem value={client.firstName + " " + client.lastName + " (" + client.email + ")"} />
        </Section>
      ) : null}

      {project ? (
        <Section>
          <HeaderText subHeader label="Project:" />
          <LabelItem value={project.name} />
        </Section>
      ) : null}

      {client ? (
        <Section>
          <HeaderText subHeader label="Credit Card:" />
          <LabelItem value={creditCard.name} />
        </Section>
      ) : null}

      <FlexRow>
        <TouchButton
          style={{ marginRight: 15 }}
          onPress={() => {
            setState({ ...state, reviewOrder: false })
          }}
          grey
          label="Change Order"
        />

        <TouchButton
          onPress={() => {
            if (!client) {
              alert("Must select client.");
              return;
            }
            if (!creditCard) {
              alert("Must select credit card.");
              return;
            }

            const testMode = session.user.testMode;

            //create charge account
            return dL
              .createAccount({})
              .then(function (accountId) {
                //create the charge
                return dL.createAccountCharge({ accountId, description: "Order Charge", amount: total }).then(function () {
                  return dL
                    .processAccountCharges({
                      referenceId: session.user.id,
                      creditCard,
                      testMode,
                      accountId
                    })
                    .then(function (results) {
                      const { chargeStatus } = results;
                      if (chargeStatus == "success") {
                        return dL
                          .createOrder({
                            monthlyPayments,
                            contractTotal,
                            paymentTotal,
                            paymentTermType,
                            totalMonths,
                            total: paymentTotal,
                            proposalId: proposal ? proposal.id : null,
                            projectId: project ? project.id : null,
                            accountId,
                            userId: client.id,
                            email: client.email,
                            createdById: session.user.id,
                            items: cart.items
                          })
                          .then(function (orderId) {
                            utils.clearShoppingCart();
                            history.replace("/user/order/" + orderId);
                          })
                          .then(function () {
                            alert("DONE PURCHASED!");
                          });
                      } else {
                        alert("Error in charge.");
                      }
                    });
                });
              })
            /*
            .catch(function (err) {
              alert("Error in purchase:" + err);
            });
            */
          }}
          label="Place Order"
        />
      </FlexRow>
    </View>
  }

  return (
    <View style={{ alignItems: "center" }}>
      {reviewOrder ? renderReviewOrder() : renderEditOrder()}

      {visible ? (
        <NewProject
          clientId={client.id}
          onCancel={() => {
            setVisible(false);
          }}
          onSave={project => {
            projects.push(project);
            setProject(project);
            setState({ ...state, projects });
            setVisible(false);
          }}
        />
      ) : null}
    </View>
  );
}
