import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { ServiceCatList } from "root/pack-3";
import { __DEV__ } from "root/dev";
import renderHTML from "react-render-html";
import { UserItem, LabelUserItem, Card2, ListRender, HeaderText } from "root/pack-2";
import { FormBuilder, FormEditor } from "root/forms";
import { FlexExpand, SortableList, BoxItem, DeleteConfirm, Toggle, Section, EditDialog, ModalBasic, LabelItem, LinkButton, Hide, FlexRow, TabBar, Text, TextLine, View, MyInput, TouchButton, SelectBox, NoRecords } from "root/pack-1";
import { UserList } from "root/users";
import { Portal } from "react-portal";
import Moment from "moment-business-days";

const { yesNo, getLabelItem, plural } = utils;

export function EditBusinessInitiative() {
  const { recordId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ model: {}, isLoading: recordId ? true : false });
  const { isLoading, model } = state;

  useEffect(() => {
    if (recordId) {
      var model;
      dL.getQuery("BusinessInitiative")
        .get(recordId)
        .then(function (obj) {
          model = dL.loadBusinessInitiative(obj);

          setState({ ...state, isLoading: false, model });
        });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { name, shortDescription, businessSector, isPublic } = model;

  const doSave = function () {
    if (!name) {
      alert("Must enter help with title.");
      return;
    }
    if (!businessSector) {
      alert("Must select business sector.");
      return;
    }

    return dL
      .saveBusinessInitiative(recordId, model)
      .then(function () {
        history.goBack()
      });
  }

  return (
    <EditDialog>
      <HeaderText label="Edit Business Initiative" />

      <Section>
        <MyInput
          required
          label="Help with..."
          value={name}
          onChange={value => {
            model.name = value;
            setState({ ...state, model });
          }}
        />
        <MyInput
          maxLength={144}
          label="Short description:"
          value={shortDescription}
          onChange={value => {
            model.shortDescription = value;
            setState({ ...state, model });
          }}
        />
        <SelectBox
          style={{ marginRight: 10 }}
          label="Business Sector:"
          value={utils.serviceTypes.find(item => item.value == businessSector)}
          options={utils.serviceTypes}
          onChange={item => {
            model.businessSector = item.value;
            setState({ ...state, model });
          }}
        />

        <Toggle
          label="Is Public:"
          value={isPublic}
          onChange={value => {
            model.isPublic = value
            setState({ ...state, model });
          }}
        />
      </Section>

      <TouchButton
        label="Save"
        onPress={() => {
          doSave()
        }}
      />
    </EditDialog>
  );
}

export function ViewBusinessInitiative() {
  const { sectionId, recordId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model } = state;

  useEffect(() => {
    dL.getQuery("BusinessInitiative")
      .include("company")
      .include("createdBy")
      .get(recordId)
      .then(function (obj) {
        const model = dL.loadBusinessInitiative(obj);

        setState({ ...state, isLoading: false, model });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { company, createdBy, name, shortDescription, businessSector, isPublic } = model;

  return (
    <View>
      <HeaderText
        label={name}
        description={shortDescription}
        buttons={[
          {
            label: "Edit Initiative",
            onPress: () => {
              history.push(`/${sectionId}/business-initiative/${recordId}/edit`);
            }
          }
        ]}
      />

      <Section>
        <FlexRow>
          <LabelItem label="Business Sector:" value={getLabelItem(utils.serviceTypes, businessSector)} />
          <LabelItem label="Public:" value={yesNo(isPublic)} />
          {company ? <LabelItem label="Company:" value={company.name} /> : null}
          <LabelUserItem label="Created By:" user={createdBy} />
        </FlexRow>
      </Section>
    </View>
  );
}

export function BusinessInitiativesAdminSectors() {
  const { sectionId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ list: [] });
  const [searchText, setSearchText] = useState();
  const [tabValue, setTabValue] = useState("public");
  const [isLoading, setIsLoading] = useState(true);
  const [biPerSector, setBiPerSector] = useState();
  const { list, } = state;

  useEffect(() => {
    return dL.getBIPerSector({ tabValue }).then(function (biPerSector) {
      setBiPerSector(biPerSector)
      setIsLoading(false)
    });
  }, []);

  useEffect(() => {
    return dL.getBIPerSector({ tabValue }).then(function (biPerSector) {
      setBiPerSector(biPerSector)
    });
  }, [tabValue]);

  useEffect(() => {
    dL.getQuery("BusinessInitiative")
      .startsWith("searchText", searchText)
      .containedIn("removed", [undefined, false])
      .include("company")
      .include("createdBy")
      .find()
      .then(function (objs) {
        const list = dL.loadObjects("BusinessInitiative", objs);
        setState({ ...state, isSearching: false, list });
      });
  }, [searchText]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <View>
      <HeaderText
        label="Business Initiatives"
        description="Manage business initiatives on the platform."
        onButtonPress={() => {
          history.push(`/${sectionId}/business-initiative/add`);
        }}
        rightRender={<TabBar
          queryId="tb"
          options={[
            {
              label: "Public",
              value: "public",
              description: "All public business initiatives."
            },
            {
              label: "All",
              value: "all",
              description: "All business initiatives."
            }
          ]}
          onChange={item => {
            setTabValue(item.value);
          }}
          value={tabValue}
        />}
      />

      <MyInput
        placeholder="Search..."
        value={searchText}
        onChange={text => {
          setSearchText(text);
          setState({ ...state, isSearching: true });
        }}
      />

      {searchText ? (
        <FlexRow style={{ flexWrap: "wrap" }}>
          {list.length == 0 ? (
            <NoRecords label="No business initiatives found." />
          ) : (
            list.map(item => {
              const { businessSector, id, name, shortDescription, createdBy, company, createdAt } = item;

              return (
                <Card2
                  cardSize="col-sm-6 col-xl-6 mb-2 mb-sm-6"
                  bodyStyle={{ minHeight: 100 }}
                  key={id}
                  title={name}
                  description={shortDescription}
                  onPress={() => {
                    history.push(`/${sectionId}/business-initiative/${id}`);
                  }}>
                  {company ? <TextLine size={13} top={4} label="Company:" value={company.name} /> : null}
                  <View style={{ height: 15 }} />
                  <UserItem label="Created By:" user={createdBy} />

                  <TextLine size={11} top={20} label="Created:" value={Moment(createdAt).fromNow()} />
                </Card2>
              );
            })
          )}
        </FlexRow>
      ) : (
        <ServiceCatList
          onGetDescription={sectorValue => {
            var str = "";
            const roleCount = biPerSector[sectorValue] ? biPerSector[sectorValue] : 0
            if (roleCount) {
              str = plural(roleCount, "initiative");
            }
            return str;
          }}
          onSelect={item => {
            history.push(`/${sectionId}/${item.value}/business-initiatives`);
          }}
        />
      )}
    </View>
  );
}

export function BusinessInitiativesAdmin() {
  const { sectionId, category } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { rolePeople, isLoading } = state;

  useEffect(() => {
    return dL.getPeoplePerBusinessRole().then(function (rolePeople) {
      setState({ ...state, isLoading: false, rolePeople });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var foundCategory;
  if (category) {
    foundCategory = utils.serviceTypes.find(item => item.value == category);
  }

  return (
    <ListRender
      columns
      searchFields={[{ field: "searchText" }]}
      defaultSort={{ field: "searchText" }}
      filters={[
        {
          label: "Public",
          value: "public",
          description: "All active business initiatives.",
          onQuery: (query) => {
            query.equalTo("isPublic", true)
          }
        },
        {
          label: "Recently Added",
          value: "recent",
          description: "All recently added business initiatives.",
          onQuery: (query) => {
            query.descending("createdAt")
          }
        },
        {
          label: "Archived",
          value: "archived",
          description: "All archived business initiatives.",
          onQuery: (query) => {
            query.equalTo("status", "archived")
          }
        }
      ]}
      onButtonPress={() => {
        history.push(`/${sectionId}/business-initiative/add`);
      }}
      onWhere={query => {
        if (category) {
          query.equalTo("businessSector", category);
        }
      }}
      type="BusinessInitiative"
      emptyLabel="No business initiative found."
      includes={["company", "createdBy"]}
      title={foundCategory ? "Business Initiative: " + foundCategory.label : "Business Initiatives"}
      description="Manage business initiatives on the platform."
      renderItem={item => {
        const { id, name, company, createdBy, createdAt } = item;

        const peopleCount = rolePeople ? (rolePeople[id] ? rolePeople[id] : 0) : 0;
        var description; //getLabelItem(serviceTypes, businessSector);
        if (peopleCount) {
          description = plural(peopleCount, "person");
        }

        return (
          <Card2
            cardSize="col-sm-6 col-xl-6 mb-2 mb-sm-6"
            bodyStyle={{ minHeight: 100 }}
            key={id}
            title={name}
            description={description}
            onPress={() => {
              history.push(`/${sectionId}/business-initiative/${id}`);
            }}>
            {company ? <TextLine size={13} top={4} label="Company:" value={company.name} /> : null}

            <View style={{ height: 15 }} />
            <UserItem label="Created By:" user={createdBy} />

            <TextLine size={11} top={20} label="Created:" value={Moment(createdAt).fromNow()} />
          </Card2>
        );
      }}
    />
  );
}
