import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { components } from 'react-select';
import { ListRender2, Price, RenderServiceItemBuyer } from "root/pack-3";
import { HeaderText } from "root/pack-2";
import { getMainServiceModel } from "root/service-model"
import { FlexExpand, TabBar, MyInput, SelectBox, Loading, Section, ModalBasic, FlexRow, Text, TextLine, View, TouchButton, Avatar, NoRecords } from "root/pack-1";
import { toast } from 'react-toastify';
import {
  getFinalPriceDays2,
  getServicePrice2,
  getServiceDeliveryDays,
  getDeliverablesForService,
  getVisiblePackage,
  getServicePriceAndDelivery, loadUserService_Worker
} from "root/service-functions";
import { getServiceFull } from "root/services"
import { ServiceDetails1, RenderUserService } from "root/services-worker"
import { ServiceEdit } from "root/services-select"
import { RenderClientInputRequirementsBuyer } from "root/forms"

const { plural } = utils

export function AddInputsButtonBuyer({ label, viewOnly, inputs, inputData, onSave, onSaveDraft }) {
  const [state, setState] = useState({});
  const { visible } = state;

  return (
    <React.Fragment>
      <TouchButton micro
        onPress={() => {
          setState({ ...state, visible: true });
        }}
        label={label ? label : "Edit Questions"}
      />

      {visible ? (
        <RenderClientInputRequirementsBuyer
          viewOnly={viewOnly}
          inputs={inputs}
          inputData={inputData}
          onCancel={() => {
            setState({ ...state, visible: false });
          }}
          onSaveDraft={() => {
            onSaveDraft(inputData);
            setState({ ...state, visible: false });
          }}
          onSave={() => {
            onSave(inputData);
            setState({ ...state, visible: false });
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

function PurchasedService({ hidePrice, service, userService, packageId, serviceOptionIds, deliveryType, subHeader }) {
  const { enablePackages, name, shortDescription, description } = service;

  const renderPrice = function () {
    const { price: total, days } = getServicePrice2({ service, userService, serviceOptionIds, packageId, deliveryType });

    return <Price price={total} days={days} />;
  };

  return (
    <View>
      <HeaderText subHeader={subHeader} label={name} description={shortDescription} rightRender={!enablePackages && !hidePrice ? renderPrice() : null} />

      <FlexRow top={25}>
        <ServiceDetails1 service={service} />
      </FlexRow>

      {description ? <Section>{description}</Section> : null}

      <ServiceEdit
        model={{ serviceOptionIds, deliveryType, service, servicePackage: packageId ? service.packages.find((item) => item.package.id == packageId) : null, userService }}
        displaySelected={true}
        hidePrice={hidePrice} />
    </View>
  );
}

export function ServicesBuyerAdmin() {
  const { sectionId } = useParams();
  const history = useHistory();

  const filters = [
    {
      label: "Draft",
      value: "draft",
      description: "Your draft services, not yet published to the catalog.",
      onQuery: function (query) {
        query.equalTo("createdBy", db.getObj("User", session.user.id))
        query.equalTo("status", "draft");
      }
    },
    {
      label: "Public",
      value: "public",
      description: "Services that have been made public in the service catalog.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.equalTo("isBuyerPublic", true);
      }
    },
    {
      label: "1st Review",
      value: "1-review",
      description: "Services that require review for the first time.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.equalTo("needsReview", true);
        query.exists("lastReviewedAt", false);
      }
    },
    {
      label: "Needs Review",
      value: "needs-review",
      description: "Services that require review after being modified.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.equalTo("needsReview", true);
      }
    },
    {
      label: "Private",
      value: "private",
      description: "Services that have been published but are private.",
      onQuery: function (query) {
        query.equalTo("status", "active");
        query.containedIn("isBuyerPublic", [undefined, false]);
      }
    },
    {
      label: "Archived", value: "archived",
      onQuery: function (query) {
        query.equalTo("status", "archived");
      }
    }
  ];

  return (
    <ListRender2
      displayNoCategory={true}
      queryId="lr"
      statFunction="getServicesPerSector"
      searchFields={[{ field: "searchText" }]}
      defaultSort={{ field: "searchText" }}
      filters={filters}
      onButtonPress={() => {
        history.push(`/${sectionId}/service/add`);
      }}
      type="Service"
      emptyLabel="No services found."
      includes={["deliverables", "packages", "businessRole"]}
      title="Buyer Service Catalog"
      description="Setup and manage services that buyers can purchase."
      renderItem={(item) => {
        const { id } = item;

        return (
          <RenderServiceItemBuyer
            key={item}
            onPress={() => {
              history.push(`/${sectionId}/service/${id}/admin-edit`);
            }}
            item={item}
          />
        );
      }}
    />
  );
}

export function ServiceBuyer() {
  const { serviceId, userServiceId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ deliveryType: "standard", serviceOptionIds: {}, isLoading: true });
  const { addToCartVisible, isLoading, serviceOptionIds, servicePackage, deliveryType, servicePeople, relatedServices, service, userService } = state;

  const userId = session.user.id

  useEffect(() => {
    var data;
    var userService;
    getServiceFull({ serviceId }).then(function (_data) {
      data = _data;

      if (userServiceId) {
        return dL.getUserService2({ userServiceId }).then(function (_userService) {
          userService = _userService
        });
      }
    }).then(function () {
      const { servicePeople, relatedServices, service } = data;

      setState({ ...state, isLoading: false, userService, servicePeople, relatedServices, service });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const packageId = servicePackage ? servicePackage.package.id : null;

  const serviceVersion = userService ? userService.currentVersion : service.currentVersion
  const actualVersionType = userService ? "worker" : "service"

  const configData = {
    service,
    userService,
    serviceVersion: service.currentVersion,
    userServiceVersion: userService ? userService.currentVersion : null,
    servicePackage,
    actualVersionType,
    actualServiceVersion: serviceVersion,
    packageId,
    deliveryType,
    serviceOptionIds
  }

  const rtn = getMainServiceModel({ configData })

  const days = rtn.getTotalDeliveryDays()
  const total = rtn.getTotalPrice()

  const { enablePackages, serviceOptions } = serviceVersion;
  const { allowedRevisions } = servicePackage ? servicePackage : service;

  const deliverables = getDeliverablesForService({ serviceVersion, packageId, serviceOptionIds });

  const renderUser = function () {
    const { user } = userService;
    const { firstName, lastName, avatar, title, companyName, userText, id } = user;
    return (
      <Section>
        <HeaderText subHeader label="Service Provider" description="This service is particularly provided by this user." />
        <FlexRow>
          <FlexRow>
            <Avatar size={65} value={avatar} style={{ marginRight: 15 }} />

            <View>
              <TextLine value={firstName + " " + lastName} size={18} />
              {companyName ? <TextLine top={-3} value={title ? title + " at " + companyName : companyName} size={14} color="grey" /> : null}
              {userText ? <TextLine top={-3} value={userText} size={14} color="grey" /> : null}
            </View>
          </FlexRow>

          <TouchButton
            style={{ marginLeft: 35 }}
            size="sm"
            micro
            onPress={() => {
              history.push(`/user/people/${id}`);
            }}
            label="View Profile"
          />
        </FlexRow>
      </Section>
    );
  };

  return (
    <div class="row">
      <div class="col-8">
        <DisplayService
          serviceVersion={serviceVersion}
          RenderServiceItem={RenderServiceItemBuyer}
          userService={userService}
          servicePeople={userId ? servicePeople.filter((item) => item.user.id != userId) : servicePeople}
          relatedServices={relatedServices}
          service={service}
          hideMainPrice
          onSelectionUpdate={(data) => {
            setState({ ...state, ...data });
          }}
        />

        {userService ? renderUser() : null}
      </div>

      <div class="col-4">
        <div class="card-bordered">
          <div class="card-body">
            <div>
              <span class="h2 text-lh-sm mr-1">{!enablePackages || servicePackage ? <Price price={total} days={days} deliveryText="Delivery in " allowedRevisions={allowedRevisions} /> : null}</span>
            </div>

            <div>
              <div class="mb-2">
                <TouchButton label="Add to Cart"
                  style={{ width: "100%" }}
                  onPress={() => {
                    if (enablePackages && !servicePackage) {
                      alert("Must select a bundled packages to add to cart.");
                      return;
                    }

                    setState({ ...state, addToCartVisible: true })
                  }} />
              </div>

              {addToCartVisible ? <AddToCartDialog total={total} days={days} itemType="service" configData={configData} onSave={() => {
                setState({ ...state, addToCartVisible: false })
              }} onCancel={() => {
                setState({ ...state, addToCartVisible: false })
              }} /> : null}

              {deliverables && deliverables.length > 0 ? (
                <View style={{ marginTop: 25 }}>
                  <h2 class="h4">Service will includes:</h2>

                  {deliverables.map((item) => {
                    const { name, id } = item;

                    return (
                      <div key={id} class="media text-body font-size-1 mb-2">
                        <div class="min-w-3rem text-center mr-3">
                          <i class="fa fa-check" style={{ color: "green" }} />
                        </div>
                        <div class="media-body">{name}</div>
                      </div>
                    );
                  })}
                </View>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Option = props => {
  const item = props.data.item
  const { title, buyerItemsInCart } = item

  return (
    <components.Option {...props}>
      <FlexRow>
        <FlexExpand>
          <TextLine value={title} />
        </FlexExpand>
        <TextLine size={12} value={plural(buyerItemsInCart, "item")} />
      </FlexRow>
    </components.Option>
  );
};

export function AddToCartDialog({ itemType, total, days, configData, onCancel, onSave }) {
  const [isLoading, setIsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(session.defaultProposalId ? "existing" : "new");
  const [model, setModel] = useState({
    proposal: session.defaultProposalId ? { id: session.defaultProposalId } : null
  });
  const [proposals, setProposals] = useState();
  const [visible, setVisible] = useState();

  useEffect(() => {
    dL.getQuery("ServiceProposal")
      .equalTo("user", dL.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const proposals = dL.loadObjects("ServiceProposal", objs)
        setProposals(proposals)
        setIsLoading(false)
      })
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  const { name, proposal } = model

  const proposalSel = proposals.map(item => {
    const { title, id, buyerItemsInCart } = item
    return { label: title + " (" + plural(buyerItemsInCart, "item") + ")", value: id, item }
  })

  return <ModalBasic
    title="Service Cart:"
    okText="Add to Cart"
    onCancel={onCancel}
    onOk={() => {
      const proposalItem = {
        ...configData,
        itemType,
        inCart: true,
        isBuyerSide: true,
        unitPrice: total,
        deliveryDays: days,
        quantity: 1,
        total
      }

      const runAdded = function () {
        toast.success("Added to cart.")
        session.dispatch("update-cart")
        onSave()
      }

      if (tabValue == "new") {
        if (!name) {
          alert("Must enter name.")
          return
        }

        const proposal = {
          title: name,
          user: session.user,
          isBuyerSide: true
        }

        return dL.saveServiceProposal(null, proposal)
          .then(function (proposalId) {
            return dL.saveProposalItem({ isNew: true, proposalId, item: proposalItem }).then(function () {
              const { total } = proposalItem

              return dL.getObj("ServiceProposal", proposalId)
                .increment("buyerItemsInCart")
                .increment("buyerCartTotal", total)
                .set("lastBuyerCartUpdatedAt", new Date())
                .save()
            }).then(function () {
              session.defaultProposalId = proposalId
              return runAdded()
            })
          })
          .catch(function (err) {
            alert("Error in strategy map:" + err);
          });
      } else {
        if (!proposal) {
          alert("Must select proposal.")
          return
        }

        const proposalId = proposal.id
        return dL.saveProposalItem({ isNew: true, proposalId, item: proposalItem }).then(function () {
          const { total } = proposalItem

          return dL.getObj("ServiceProposal", proposalId)
            .increment("buyerItemsInCart")
            .increment("buyerCartTotal", total)
            .set("lastBuyerCartUpdatedAt", new Date())
            .save()
        }).then(function () {
          session.defaultProposalId = proposalId
          return runAdded()
        }).catch(function (err) {
          alert("Error in strategy map:" + err);
        });
      }
      onSave()
    }}>

    {proposals.length > 0 ? <TabBar
      style={{ marginBottom: 25 }}
      options={[{ label: "New Cart", value: "new" }, { label: "Existing Cart", value: "existing" }]}
      onChange={item => {
        setTabValue(item.value)
      }}
      value={tabValue}
    /> : null}

    {tabValue == "new" ? <Section>
      <TextLine size={18} value="Name your cart:" />
      <TextLine size={14} bottom={10} value="Give a project or business initiative name." />
      <MyInput
        showDescription
        placeholder="Enter name..."
        required
        value={name}
        onChange={value => {
          model.name = value
          setModel({ ...model })
        }}
      />
    </Section> : null}

    {tabValue == "existing" ? <Section>
      <TextLine size={18} bottom={10} value="Select an existing cart:" />
      <SelectBox
        components={{ Option }}
        placeholder="Select existing cart..."
        style={{ marginBottom: 10 }}
        value={proposal ? proposalSel.find(value => value.item.id == proposal.id) : null}
        options={proposalSel}
        onChange={value => {
          model.proposal = value.item
          setModel({ ...model })
        }}
      />
    </Section> : null}
  </ModalBasic>
}

export function DisplayService({ serviceVersion, RenderServiceItem, displaySelected, userService, hideMainPrice, hidePrice, servicePeople, relatedServices, service, subHeader, onSelectionUpdate }) {
  const allowSelect = onSelectionUpdate != null;
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    isLoading: true,
    serviceData: {
      service,
      userService,
      actualServiceVersion: serviceVersion,
      serviceOptionIds: {},
      deliveryType: "standard",
    },
  });
  const { serviceData } = state;
  const { servicePackage, serviceOptionIds, deliveryType } = serviceData;

  const onSelectPackage = function (servicePackage) {
    var dType = deliveryType;
    if (deliveryType == "fast" && !servicePackage.hasExtraFastDelivery) {
      dType = "standard";
    }
    setState({ ...state, serviceOptionIds: {}, deliveryType: dType, servicePackage });
    onSelectionUpdate && onSelectionUpdate({ servicePackage, serviceOptionIds: {}, deliveryType: dType });
  };

  useEffect(() => {
    if (allowSelect) {
      const servicePackage = getVisiblePackage({ serviceData: serviceVersion });

      if (servicePackage) {
        onSelectPackage(servicePackage);
      }
    }
  }, []);

  const { icon, enablePackages, name, shortDescription, description } = serviceVersion

  const packageId = servicePackage ? servicePackage.package.id : null;

  const rtn = getMainServiceModel({
    configData: serviceData
  })

  const days = rtn.getTotalDeliveryDays()
  const total = rtn.getTotalPrice()

  return (
    <View>
      <HeaderText icon={icon} subHeader={subHeader} label={name} description={shortDescription} rightRender={!enablePackages && !hideMainPrice ? <Price price={total} days={days} /> : null} />

      <Section>
        <FlexRow>
          <ServiceDetails1 service={service} userService={userService} serviceVersion={serviceVersion} />
        </FlexRow>
      </Section>

      {description ? <Section>
        <HeaderText subHeader label="Description of service:" />
        {description}
      </Section> : null}

      <Section>
        <ServiceEdit
          displaySelected={displaySelected}
          hidePrice={hidePrice}
          allowSelect={allowSelect}
          model={serviceData}
          onUpdate={(serviceData) => {
            setState({ ...state, serviceData });

            onSelectionUpdate && onSelectionUpdate(serviceData);
          }}
        />
      </Section>

      {relatedServices.length > 0 ? (
        <Section>
          <HeaderText subHeader label="Related Services:" description="See other related services that can help complete any pre-requirements for this service." />

          {relatedServices.length == 0 ? (
            <NoRecords label="No related services found." />
          ) : (
            relatedServices.map((item) => {
              return (
                <RenderServiceItem
                  onPress={() => {
                    history.push(`/${sectionId}/service/${item.id}`);
                    setState({ ...state });
                  }}
                  item={item}
                />
              );
            })
          )}
        </Section>
      ) : null}

      {servicePeople.length > 0 ? (
        <Section>
          <HeaderText subHeader label="Service Experts:" description="See some of the people that can deliver this service." />
          {servicePeople.length == 0 ? (
            <NoRecords label="No experts found." />
          ) : (
            <FlexRow>
              {servicePeople.map((userService) => {
                const { user, service } = userService;

                return <RenderUserService userService={userService} onPress={() => {
                  history.push(`/${sectionId}/service/${service.id}/${user.id}`);
                  setState({ ...state });
                }} />
              })}
            </FlexRow>
          )}
        </Section>
      ) : null}
    </View>
  );
}

export function ViewServicePurchased({ serviceId, serviceVersion, packageId, serviceOptionIds, deliveryType, hidePrice, onClose }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, service } = state;

  useEffect(() => {
    dL.getService2({ serviceId }).then(function (service) {
      setState({ ...state, isLoading: false, service });
    });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ModalBasic notCentered full onCancel={onClose}>
      <PurchasedService
        deliveryType={deliveryType}
        hidePrice={hidePrice}
        subHeader
        service={service}
        serviceVersion={serviceVersion}
        packageId={packageId}
        serviceOptionIds={serviceOptionIds}
        onSelectionUpdate={(data) => {
          setState({ ...state, ...data });
        }}
      />
    </ModalBasic>
  );
}
