import "./App.css";
import { TouchableOpacity } from "react-native";
import { Dropdown, DropdownButton, ListGroup } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import { Spinner } from "react-bootstrap"
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useHistory } from "react-router-dom";
import "root/App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useDebounce, RenderIcon, Section, BreadCrumb, DropdownLink, TouchButtonOnOff, ModalBasic, BoxItem, LabelItem, LinkButton, FlexExpand, FlexRow, TabBar, Text, TextLine, View, TrashIcon, CommentIcon, EditIcon, MyInput, TouchButton, Avatar, NoRecords } from "root/pack-1";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-widgets/dist/css/react-widgets.css";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import { getRoomId, openChat } from "root/chat";
import { UserProfileBuyer, UserProfileWorker } from "root/worker";

const { skillLevels } = utils;

export function BasicTop({ leftRender, style, children, title, shortDescription, description, breadCrumb, onEditPress, status, rightRender, buttons }) {
  const headerS = { fontSize: 28 };
  const shortS = { fontSize: 16, color: "grey", marginTop: 8 };
  const statusS = { marginLeft: 10, fontSize: 16 };
  const detailsS = { fontSize: 16, color: "grey" };

  return (
    <FlexRow style={{ marginBottom: 15, ...style }}>
      <FlexExpand>
        <FlexRow>
          {leftRender}
          <FlexExpand>
            <View>
              {breadCrumb ? <BreadCrumb label={breadCrumb} /> : null}
              <FlexRow>
                <Text style={headerS}>{title}</Text>
                {status ? <Text style={statusS}>[{status}]</Text> : null}
                {onEditPress ? (
                  <EditIcon
                    style={{ marginLeft: 8 }}
                    onPress={() => {
                      onEditPress();
                    }}
                  />
                ) : null}
              </FlexRow>
            </View>

            {shortDescription ? <Text maxLines={1} style={shortS}>{shortDescription}</Text> : null}
            {children}
          </FlexExpand>
        </FlexRow>

        {description ? <TextLine maxLines={1} style={{ marginTop: 18 }} textStyle={detailsS} value={description} /> : null}
      </FlexExpand>

      <RenderButtons buttons={buttons} renderType="single" />

      {rightRender}
    </FlexRow>
  );
}

export function DashBoxItem2({ title, description, value, onChange, children }) {
  return (
    <BoxItem2 title={title} description={description} rightRender={<TouchButtonOnOff
      ONLabel="Disable"
      OFFLabel="Enable"
      micro
      value={value}
      onPress={onChange}
    />}>
      <View style={{ marginBottom: 20 }}>{value ? children : null}</View>

    </BoxItem2>
  );
}

export function BoxItem2({ title, description, children, rightRender }) {
  return (
    <Section>
      <HeaderText subHeader label={title} description={description} rightRender={rightRender} />

      {children ? <BoxItem style={{ marginTop: 0, marginBottom: 15 }}>
        {children}
      </BoxItem> : null}
    </Section>
  );
}

export function Card2({ icon, title, description, onPress, onEdit, onDelete, children, cardSize, bodyStyle, aboveEdit }) {
  return (
    <div class={cardSize ? cardSize : "col-sm-6 col-xl-4 mb-2 mb-sm-3"} style={{ cursor: "pointer" }}>
      <div class="card card-bordered card-hover-shadow h-100" onClick={onPress}>
        <div class="card-body" style={bodyStyle}>
          <div class="media align-items-center" style={{ position: "relative" }}>
            <div class="media-body">
              <FlexRow>
                <FlexExpand>
                  <FlexRow>
                    {icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 65 }} /> : null}
                    <View>
                      <h4 class="text-hover-primary mb-0">{title}</h4>
                      {description ? (
                        <div class="text-body" style={{ marginTop: 4 }}>
                          {description}
                        </div>
                      ) : null}

                      {children}
                    </View>
                  </FlexRow>
                </FlexExpand>
                <View>
                  {aboveEdit}
                  {onEdit || onDelete ? (
                    <FlexRow alignRight>
                      {onEdit ? <EditIcon style={{ marginRight: 8 }} onPress={onEdit} /> : null}
                      {onDelete ? <TrashIcon onPress={onDelete} /> : null}
                    </FlexRow>
                  ) : (
                    <div class="text-muted text-hover-primary pl-2 ml-auto">
                      <i class="fas fa-angle-right" />
                    </div>
                  )}
                </View>
              </FlexRow>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Card1({ icon, description, title, footer, onPress, rightRender, children }) {
  return (
    <div class="card card-bordered h-100 mb-3" style={{ cursor: "pointer" }} onClick={onPress}>
      <div class="card-body">
        <div class="row">
          <div class="col-10">
            <FlexRow>
              {icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} /> : null}
              <View>
                <h4 class="card-title">{title}</h4>
                {description ? (
                  <p class="card-text" style={{ marginTop: 4, marginBottom: 4 }}>
                    {description}
                  </p>
                ) : null}
              </View>
            </FlexRow>

            <View style={{ height: 10 }} />

            {children}

            {footer ? (
              <p class="card-text">
                <small class="text-muted">{footer}</small>
              </p>
            ) : null}
          </div>
          <div class="col-2 text-right">{rightRender}</div>
        </div>
      </div>
    </div>
  );
}

export function LabelUserItem({ label, user }) {
  return (
    <LabelItem label={label}>
      <UserItem avatarSize={25} user={user} commentIcon />
    </LabelItem>
  );
}

export function UserItem({ showProfileButton, label, avatarSize, fontSize, user, style, showEmail, commentIcon, children }) {
  const [profileVisible, setProfileVisible] = useState()

  return (
    <FlexRow style={style}>
      <FlexExpand>
        <FlexRow>
          {user ? <Avatar size={avatarSize ? avatarSize : 35} value={user.avatar} style={{ marginRight: 12 }} /> : null}
          <View>
            {label ? <TextLine bottom={-2} value={label} size={11} color="grey" /> : null}
            {user ? (
              <TextLine color="grey" size={fontSize}>
                {user.name ? user.name : user.firstName + " " + user.lastName} {showEmail ? <Text>: {user.email}</Text> : null}
              </TextLine>
            ) : (
              <TextLine color="grey" size={fontSize - 2} value="None" />
            )}
            {children}
          </View>

          {commentIcon && user ? (
            <CommentIcon
              style={{ marginLeft: 10 }}
              onPress={() => {
                openChat({
                  roomId: getRoomId([session.user, user]),
                  chatType: "direct",
                  users: [session.user, user]
                });
              }}
            />
          ) : null}
        </FlexRow>
      </FlexExpand>

      {showProfileButton ? <TouchButton
        style={{ marginLeft: 35 }}
        size="sm"
        micro
        onPress={() => {
          setProfileVisible(true);
        }}
        label="View Profile"
      /> : null}

      {profileVisible ? (
        <ModalBasic
          full
          notCentered
          onCancel={() => {
            setProfileVisible();
          }}>
          <UserProfileBuyer userId={user.id} />
        </ModalBasic>
      ) : null}
    </FlexRow>
  );
}

export function SelectOptions({ options, children, onChange }) {
  return (
    <DropdownLink toggleContent={children}>
      {options.filter(item => item).map(item => {
        return (
          <TouchableOpacity
            onPress={() => {
              const { onPress } = item
              if (onPress) {
                onPress()
              }
              onChange && onChange(item);
            }}>
            {item.label}
          </TouchableOpacity>
        );
      })}
    </DropdownLink>
  );
}

export function RenderButtons({ buttonText, buttons, renderType }) {
  if (buttons && buttons.length > 0) {
    const arr = buttons.filter(item => item != null);
    if (renderType == "single") {
      if (arr.length > 1) {
        return (
          <DropdownButton className="btn-mn-2" menuAlign="right" size="sm" title={buttonText ? buttonText : "Actions"}>
            {arr.map(button => {
              const { label, onPress } = button;
              return <Dropdown.Item onSelect={onPress}>{label}</Dropdown.Item>;
            })}
          </DropdownButton>
        );
      } else {
        return (
          <FlexRow style={{ marginLeft: 10 }}>
            <TouchButton micro {...buttons[0]} />
          </FlexRow>
        );
      }
    } else {
      return (
        <FlexRow>
          {arr.map(button => {
            return (
              <FlexRow style={{ marginLeft: 10 }}>
                <TouchButton micro {...button} />
              </FlexRow>
            );
          })}
        </FlexRow>
      );
    }
  } else {
    return <View />;
  }
}

export function HeaderText({ avatar, smallHeader, buttonIcon, buttonText, breadcrumbs, subHeader, style, label, description, rightRender, onButtonPress, buttons, buttonClass, icon, leftRender }) {
  return (
    <div class="page-header page-header-reset" style={style}>
      <div class="row align-items-center">
        <div class="col-sm mb-2 mb-sm-0">
          {breadcrumbs ? (
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-no-gutter">
                {breadcrumbs.map((item, index) => {
                  const { label, onClick } = item;
                  return (
                    <li key={index} class="breadcrumb-item">
                      <a class="breadcrumb-link" href="javascript:;" onClick={onClick}>
                        {label}
                      </a>
                    </li>
                  );
                })}
              </ol>
            </nav>
          ) : null}

          <FlexRow>
            {leftRender}
            {icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 65 }} /> : null}
            {avatar}
            <View>
              {smallHeader ? <h5 class="page-header-title">{label}</h5> : subHeader ? <h3 class="page-header-title">{label}</h3> : <h2 class="page-header-title">{label}</h2>}
              {description ? (
                <p class="page-header-text" style={{ fontSize: smallHeader ? 12 : subHeader ? 14 : null }}>
                  {description}
                </p>
              ) : null}
            </View>
          </FlexRow>
        </div>

        {rightRender ? <div>{rightRender}</div> : null}

        <div class="col-sm-auto">
          {onButtonPress ? (
            <TouchButton micro onPress={onButtonPress}>
              {buttonIcon ? <i class={"fas mr-1 fa-" + buttonIcon} /> : <i class="fas fa-plus mr-1" />} {buttonText ? buttonText : "Add"}
            </TouchButton>
          ) : null}

          <RenderButtons buttons={buttons} buttonText={buttonText} renderType="single" />
        </div>
      </div>
    </div>
  );
}

export function StatLabelItem({ label, count, value, onPress }) {
  return <FlexRow onPress={onPress} style={{ marginRight: 10 }}>
    <TextLine bold size={14} value={count} right={8} />
    <TextLine value={label} size={12} />
    <TextLine bold size={14} value={value} left={4} />
  </FlexRow>
}

export function FaqItem(props) {
  return <BoxItem {...props} />
}

export function StatItem2({ label, description, count, value, onPress, children }) {
  return <BoxRowItem onPress={onPress}>
    <FlexRow>
      <TextLine bold size={16} value={count} right={8} />
      <TextLine value={label} size={14} />
      <TextLine bold size={16} value={value} left={8} />
    </FlexRow>

    <TextLine value={description} grey size={12} />

    {children ? <FlexRow top={10} right={-10}>{children}</FlexRow> : null}
  </BoxRowItem>
}

export function StatItem({ label, description, count, value, onPress, children }) {
  return <BoxRowItem onPress={onPress}>
    <FlexRow>
      <TextLine bold size={16} value={count} right={8} />
      <TextLine value={label} size={14} />
      <TextLine bold size={16} value={value} left={8} />
      <View style={{ marginLeft: 10 }}>{children}</View>
    </FlexRow>

    <TextLine value={description} grey size={12} />
  </BoxRowItem>
}

export function ActionItem({ label, count, onPress }) {
  return <BoxRowItem>
    <FlexRow>
      <FlexExpand>
        <FlexRow>
          <TextLine bold size={24} value={count} right={12} />
          <TextLine value={label} /></FlexRow>
      </FlexExpand>
      <TouchButton style={{ marginLeft: 25 }} micro label="View" onPress={onPress} />
    </FlexRow>
  </BoxRowItem>
}

export function BoxRowItem({ style, onPress, children, onEdit, onDelete }) {
  const rect = { position: "relative", backgroundColor: "white", borderStyle: "solid", borderWidth: 1, borderColor: "#e0e0e0", padding: 12, borderRadius: 9, marginTop: 12, marginBottom: 12, cursor: onPress ? "pointer" : null };
  return (
    <View className="card card-bordered card-hover-shadow hidden-parent" style={[rect, style]} onPress={onPress} noLinkClass>
      <FlexRow alignTop>
        <FlexExpand>{children}</FlexExpand>

        {onEdit || onDelete ? (
          <FlexRow left={10}>
            {onEdit ? <EditIcon style={{ marginRight: 8 }} onPress={onEdit} /> : null}
            {onDelete ? <TrashIcon onPress={onDelete} /> : null}
          </FlexRow>
        ) : null}
      </FlexRow>
    </View>
  );
}

export function ListRender({ renderTop, queryId, defaultFilter, subHeader, setRef, buttons, emptyRender, style, topFilters, onResetFilter, filterValues, columns, defaultSearchText, onRefresh, rightRender, onWhere, filters, onButtonPress, where, type, emptyLabel, includes, title, description, statuses, renderItem, searchFields, searchNumber, defaultSort, renderFilter, onFilter }) {
  const history = useHistory();
  const params = new URLSearchParams(document.location.search.substring(1));
  const [state, setState] = useState({ isLoading: true, filterVisible: false });
  const [status, setStatus] = useState(defaultFilter ? defaultFilter : filters && filters.length > 0 ? filters[0].value : null);
  const [searchText, setSearchText] = useState(queryId && params.get(queryId) ? params.get(queryId) : defaultSearchText);
  const [isSearching, setIsSearching] = useState(false);
  const [stats, setStats] = useState();
  const [topStats, setTopStats] = useState();
  const [refresh, setRefresh] = useState(new Date());
  const [tabTopValue, setTabTopValue] = useState(topFilters ? topFilters[0].value : null);
  const { isLoading, list, filterVisible } = state;
  const debouncedSearchText = useDebounce(searchText, 250);

  var onQuery;
  var filter;
  if (filters) {
    filter = filters.find(item => item.value == status);
    onQuery = filter.onQuery;
  }
  var topFilter;
  if (tabTopValue && topFilters) {
    topFilter = topFilters.find(item => item.value == tabTopValue);
  }

  const tabItems = filters ? filters : statuses;

  const getTagItems = function () {
    var options;

    if (tabItems) {
      options = [];

      tabItems.forEach(item => {
        const { label, value, autoHide, description } = item;
        if (!autoHide || !stats || stats[value] > 0) {
          options.push({ label: label + (stats && stats[value] ? " (" + stats[value] + ")" : ""), value, description });
        }
      });
    }
    return options;
  };

  useEffect(() => {
    if (setRef) {
      setRef({
        deleteIndex: (index) => {
          delete list[index]
          setState({ ...state, list })
        },
        refresh: () => {
          setRefresh(new Date());
        }
      });
    }
  }, [list]);

  useEffect(() => {
    if (filters) {
      dL.getStats({ type, filters, onWhere, where, onFilter, topFilter }).then(function (stats) {
        setStats(stats);

        const options = getTagItems();
        const foundOpt = options.find(item => item.value == status);
        if (!foundOpt && options.length > 0) {
          setStatus(options[0].value);
        }
      });
    }

    if (topFilters) {
      dL.getTopStats({ type, onWhere, where, topFilters }).then(function (stats) {
        setTopStats(stats);
      });
    }
  }, [refresh, tabTopValue]);

  useEffect(() => {
    var list;

    dL.getList({
      onWhere,
      searchFields,
      searchNumber,
      defaultSort,
      type,
      filter,
      onQuery,
      where,
      searchText,
      status: status != "all" ? status : null,
      includes,
      onFilter,
      topFilter
    })
      .then(function (_list) {
        list = _list;
        if (onRefresh) {
          return onRefresh({ list, status, searchText });
        }
      })
      .then(function () {
        setIsSearching(false);
        setState({ ...state, isLoading: false, list });
      });
  }, [status, debouncedSearchText, status, refresh, tabTopValue]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const options = getTagItems();

  if (searchText && options) {
    options.unshift({ label: "Search", value: "search" });
  }

  var topOptions;
  if (topFilters) {
    topOptions = [];
    topFilters.forEach(item => {
      const { label, value, autoHide, description } = item;
      if (!autoHide || !topStats || topStats[value] > 0) {
        topOptions.push({ label: label + (topStats && topStats[value] ? " (" + topStats[value] + ")" : ""), value, description });
      }
    });
  }

  const optionItem = tabItems ? tabItems.find(item => item.value == status) : null;
  const topOptionItem = topOptions ? topOptions.find(item => item.value == tabTopValue) : null;

  const render = function () {
    return list.length == 0 ? (
      emptyRender ? (
        emptyRender
      ) : (
        <NoRecords label={emptyLabel} />
      )
    ) : (
      list.map((item, index) => {
        return renderItem(item, index);
      })
    );
  };

  const hasSearch = searchFields || renderFilter;
  const renderSearch = function () {
    var hasFilter = false;
    for (var { } in filterValues) {
      hasFilter = true;
      break;
    }
    return (
      <FlexRow alignTop>
        {searchFields ? (
          <FlexRow style={{ flex: 1, position: "relative" }}>
            <MyInput
              boxStyle={{ flex: 1, marginBottom: 0 }}
              isLoading={isSearching}
              placeholder="Search..."
              value={searchText}
              onChange={text => {
                setIsSearching(true);
                setSearchText(text);

                if (queryId) {
                  if (text) {
                    params.set(queryId, text);
                  } else {
                    params.delete(queryId);
                  }
                  history.replace({ search: params.toString() });
                }
              }}
            />
            {isSearching ? <View style={{ position: "absolute", right: 10, top: 10 }}><Spinner animation="border" /></View> : null}
          </FlexRow>
        ) : null}

        {renderFilter ? (
          <TouchButtonOnOff
            style={{ marginLeft: 15 }}
            ONLabel="Filter ON"
            OFFLabel="Filter OFF"
            value={hasFilter}
            onPress={() => {
              setState({ ...state, filterVisible: true });
            }}
          />
        ) : null}

        {filterVisible ? (
          <ModalBasic
            title="Filter"
            okText="Filter"
            onCancel={() => {
              setState({ ...state, filterVisible: false });
            }}
            buttons={[
              {
                label: "Reset",
                onPress: () => {
                  onResetFilter();
                  setRefresh(new Date());
                  setState({ ...state, filterVisible: false });
                }
              }
            ]}
            onOk={() => {
              setRefresh(new Date());
              setState({ ...state, filterVisible: false });
            }}>
            {renderFilter()}
          </ModalBasic>
        ) : null}
      </FlexRow>
    );
  };

  return (
    <View style={style}>
      {title ? (
        <HeaderText
          subHeader={subHeader}
          onButtonPress={onButtonPress}
          label={title}
          description={description}
          style={{ marginBottom: 10 }}
          buttons={buttons}
          rightRender={
            <FlexRow>
              {renderTop}
              {renderSearch()}
              {rightRender}
            </FlexRow>
          }
        />
      ) : hasSearch ? (
        <View style={{ marginBottom: 20 }}>{renderTop}{renderSearch()}</View>
      ) : null}

      {(topFilters && topOptions.length > 1) || (filters && options.length > 1) ? (
        <View style={{ marginBottom: 20 }}>
          <FlexRow alignTop>
            {topFilters && topOptions.length > 1 ? (
              <TabBar
                queryId={(queryId ? queryId : "") + "tl"}
                style={{ marginRight: 10 }}
                options={topOptions}
                onChange={item => {
                  setTabTopValue(item.value);
                }}
                value={tabTopValue}
              />
            ) : null}

            {filters && options.length > 1 ? (
              <TabBar
                queryId={(queryId ? queryId : "") + "tb"}
                options={options}
                onChange={item => {
                  setStatus(item.value);
                }}
                value={searchText ? "search" : status}
              />
            ) : null}
          </FlexRow>
          <FlexRow>
            {topOptionItem && topOptionItem.description && !searchText ? <TextLine top={8} color="grey" size={14} value={topOptionItem.description} /> : null}
            {optionItem.description && !searchText ? <TextLine left={12} top={8} color="grey" size={14} value={optionItem.description} /> : null}
          </FlexRow>
        </View>
      ) : null}

      {columns ? <FlexRow style={{ flexWrap: "wrap" }}>{render()}</FlexRow> : render()}
    </View>
  );
}

function RenderUserItem({ item }) {
  const { companyName, title, userRoles, businessRoles, avatar, name } = item;

  var userText = "";
  if (userRoles) {
    userRoles.forEach(userRole => {
      const businessRole = businessRoles.find(item => item.id == userRole.businessRole.id);

      if (userText) {
        userText += ", ";
      }
      userText += businessRole.name + " (" + userRole.yearsOfExp + "yrs, " + utils.getLabelItem(skillLevels, userRole.skillLevel) + ")";
    });
  }

  return (
    <FlexRow>
      <Avatar size={45} value={avatar} style={{ marginRight: 15 }} />
      <View>
        <TextLine value={name} size={13} />
        {companyName ? <TextLine top={-3} value={title ? title + " at " + companyName : companyName} size={10} color="grey" /> : null}
        {userText ? <TextLine top={-3} value={userText} size={10} color="grey" /> : null}
      </View>
    </FlexRow>
  );
}

function RenderUserItem2({ user, onPress, onSelect }) {
  const { companyName, title, userRoles, businessRoles, avatar, name } = user;

  var userText = "";
  if (userRoles) {
    userRoles.forEach(userRole => {
      const businessRole = businessRoles.find(item => item.id == userRole.businessRole.id);

      if (userText) {
        userText += ", ";
      }
      userText += businessRole.name + " (" + userRole.yearsOfExp + "yrs, " + utils.getLabelItem(skillLevels, userRole.skillLevel) + ")";
    });
  }

  return (
    <BoxRowItem style={{ marginRight: 10, marginBottom: 10, height: 100, width: 400 }} onPress={onPress}>
      <FlexRow alignTop>
        <FlexExpand>
          <FlexRow>
            <Avatar size={65} value={avatar} style={{ marginRight: 15 }} />
            <View>
              <TextLine value={name} />
              {companyName ? <TextLine top={-3} value={title ? title + " at " + companyName : companyName} size={13} color="grey" /> : null}
              {userText ? <TextLine top={-3} value={userText} size={13} color="grey" /> : null}
            </View>
          </FlexRow>
        </FlexExpand>
        <TouchButton
          size="sm"
          micro
          label="Select"
          onPress={() => {
            onSelect();
          }}
        />
      </FlexRow>
    </BoxRowItem>
  );
}

export function getUserItem(item) {
  const { companyName, title, userRoles, businessRoles, id, avatar, email, firstName, lastName } = item;

  return { companyName, title, userRoles, businessRoles, avatar, email, firstName: firstName, lastName: lastName, name: firstName + " " + lastName, id };
}

export function SelectUsers({ roleName, businessRole, excludedValues, readonly, onChange, value, isWorker }) {
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [visible, setVisible] = useState(false);
  const refC = useRef();


  const handleSearch = query => {
    setIsLoading(true);

    dL.getUsers({
      includeRoles: true,
      searchText: query,
      onWhere: function (query) {
        if (isWorker) {
          query.equalTo("isWorker", true);
        }
        if (roleName) {
          query.equalTo("enabledRoles." + roleName, true);
        }
        if (businessRole) {
          query.equalToAO("businessRoles", dL.getObj("BusinessRole", businessRole.id));
        }
      }
    }).then(function (list) {
      if (value) {
        list = list.filter(item => {
          var foundItem = value.find(item2 => item.id == item2.id);
          if (foundItem) {
            return false;
          } else {
            return true;
          }
        });
      }
      if (excludedValues) {
        list = list.filter(item => {
          var foundItem = excludedValues.find(item2 => item.id == item2.id);
          if (foundItem) {
            return false;
          } else {
            return true;
          }
        });
      }

      setOptions(list.map(item => getUserItem(item)));
      setIsLoading(false);
    });
  };

  return (
    <View>
      <View style={{ marginBottom: 15 }}>
        {value
          ? value.map((item, index) => {
            return (
              <FlexRow style={{ marginBottom: 10 }}>
                <UserItem size={30} user={item} />

                {!readonly ? (
                  <TrashIcon
                    style={{ marginLeft: 15 }}
                    onPress={() => {
                      value.splice(index, 1);
                      onChange(value);
                      setUpdatedAt(new Date());
                    }}
                  />
                ) : null}
              </FlexRow>
            );
          })
          : null}
      </View>

      {!readonly ? (
        <AsyncTypeahead
          positionFixed
          style={{ flex: 1 }}
          ref={ref => {
            refC.current = ref;
          }}
          isLoading={isLoading}
          minLength={0}
          labelKey="name"
          onSearch={handleSearch}
          options={options}
          onChange={items => {
            if (items.length > 0) {
              var arr = value ? value : [];
              arr.push(items[0]);
              onChange(arr);
              refC.current.clear();
              setUpdatedAt(new Date());
            }
          }}
          placeholder="Search for user..."
          renderMenuItemChildren={(item) => <RenderUserItem item={item} />}
        />
      ) : null}
    </View>
  );
}

export function SelectUser({ serviceId, readonly, onChange, value, isWorker, hideMySelf, hasStaffServices, businessRoleId, commentIcon }) {
  const [visible, setVisible] = useState(false);
  const [userId, setUserId] = useState();

  if (visible) {
    return (
      <React.Fragment>
        <ModalBasic
          notCentered
          full
          title="Select User:"
          onCancel={() => {
            setVisible(false);
          }}>
          <ListRender
            columns
            searchFields={[{ field: "searchText" }]}
            defaultSort={{ field: "firstName" }}
            type="User"
            emptyLabel="No users found."
            includes={["userRoles", "businessRoles"]}
            renderItem={user => {
              const { id } = user;

              return (
                <RenderUserItem2
                  user={user}
                  onPress={() => {
                    setUserId(id);
                  }}
                  onSelect={() => {
                    onChange(user);
                    setVisible(false);
                  }}
                />
              );
            }}
            onWhere={query => {
              query.include("userRoles");
              query.include("businessRoles");
              query.include("services");
              if (isWorker) {
                query.equalTo("isWorker", true);
              }
              if (hasStaffServices) {
                query.equalTo("hasStaffServices", true);
              }

              if (serviceId) {
                query.equalToAO("services", dL.getObj("Service", serviceId));
              }
              if (businessRoleId) {
                query.equalToAO("businessRoles", dL.getObj("BusinessRole", businessRoleId));
              }
            }}
          />
        </ModalBasic>

        {userId ? (
          <ModalBasic
            full
            notCentered
            onCancel={() => {
              setUserId();
            }}>
            {isWorker ? <UserProfileWorker userId={userId} /> : <UserProfileBuyer userId={userId} />}
          </ModalBasic>
        ) : null}
      </React.Fragment>
    );
  }

  const options = [];
  options.push({
    label: "Select User",
    onPress: () => {
      setVisible(true);
    }
  });
  if (!hideMySelf) {
    options.push({
      label: "Select MySelf",
      onPress: () => {
        db.getQuery("User")
          .get(session.user.id)
          .then(function (obj) {
            const user = dL.loadUser(obj);

            onChange(getUserItem(user));
          });
      }
    });
  }

  if (value) {
    options.push({
      label: "Clear User",
      onPress: () => {
        onChange(null);
      }
    });
  }

  return (
    <FlexRow>
      {value ? (
        <FlexRow style={{ marginRight: 10 }}>
          <UserItem size={30} user={value} commentIcon={commentIcon} />
        </FlexRow>
      ) : (
        <TextLine color="grey" value="Empty" right={10} />
      )}

      {!readonly ? (
        <SelectOptions
          options={options}
          onChange={selItem => {
            selItem.onPress();
          }}>
          <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 16, color: "#c0c0c0" }} />
        </SelectOptions>
      ) : null}
    </FlexRow>
  );
}
