var __DEV__ = true;
var baseUrl;
if (__DEV__) {
  baseUrl = "https://macos.ngrok.io";
} else {
  baseUrl = "https://app.niftywidgets.io";
}
var url = baseUrl + "/app/growly/";

var http = {
  baseUrl,
  masterUrl: url,
  run: function(functionName, data) {
    return http.post(url + functionName, {dataStr: JSON.stringify(data)}).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        var err = new Error("Response failed");
        err.response = response;
        console.log("Response failed:");
        console.log(err);
        return Promise.reject(err);
      }
    });
  },
  postForm: function(url, data) {
    var formData = new FormData();
    for (var key in data) {
      var value = data[key];
      formData.append(key, value);
    }

    return fetch(url, {
      method: "POST",
      body: formData
    });
  },
  post: function(url, data) {
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
  },
  get: function(url) {
    return fetch(url, {
      method: "GET"
    }).then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        var err = new Error("Response failed");
        err.response = response;
        console.log("Response failed:");
        console.log(err);
        return Promise.reject(err);
      }
    });
  }
};

export default http;
