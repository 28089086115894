import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { DisplayKeyUser, ListRender2, Price, RenderChecklist } from "root/pack-3"
import ScriptTag from "react-script-tag";
import { BasicTop, StatLabelItem, StatItem2, StatItem, BoxRowItem, getUserItem, UserItem, ListRender, Card2, HeaderText } from "root/pack-2";
import { DateTimeItem, DeleteConfirm, LocationSearchInput, EditDialog, ImageElement, MyInput, CommentIcon, FormItem, TabBar, Circle2, Section, ModalBasic, BoxItem, LabelItem, LinkButton, FlexExpand, FlexRow, Text, TextLine, View, TrashIcon, CheckIcon, Toggle, TouchButton, SelectBox, Avatar, NoRecords } from "root/pack-1";
import { ChatWindow, getChatRoomsWorker, getChatRoomsBuyer } from "root/chat";
import { useChatCount, getChatStr, MyChats, Chat, ChatDock } from "root/chat";
import { getFinalPriceDays2, getWorkerRate } from "root/service-functions";
import { RenderServiceOrderItem } from "root/service-orders"
import { SelectBusinessRole } from "root/business-roles";
import { RenderTeamItem } from "root/teams"
import Datetime from "react-datetime";
import { getRoomId, openChat } from "root/chat";
import { CountryDropdown, RegionDropdown, CountryRegionData } from "react-country-region-selector";
import { MyServices } from "root/services-worker";
import Moment from "moment-business-days";

const { isNumber, moneyRange, getHasExpertise, yesNo, pluralTab, serviceCores, money, skillLevels: skillLevelsList, plural, getLabelItem, serviceTypes: serviceTypesList, userStatuses } = utils;

export function EditUserRoleDialog({ businessRole: defaultBusinessRole, userId, userRoleId, onSave, onCancel }) {
  if (!userId) {
    userId = session.user.id;
  }
  const [state, setState] = useState({ model: { businessRole: defaultBusinessRole }, isLoading: userRoleId ? true : false });
  const { isLoading, model } = state;

  useEffect(() => {
    if (userRoleId) {
      dL.getQuery("UserRole")
        .include("businessRole")
        .get(userRoleId)
        .then(function (obj) {
          setState({ ...state, isLoading: false, model: dL.loadUserRole(obj) });
        });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { skillLevel, businessRole, hourlyRate, yearsOfExp } = model;

  return <ModalBasic title={userRoleId ? "Edit Business Role" : "Add Business Role"} description="Edit this business role." onCancel={onCancel}
    onOk={() => {
      if (!businessRole) {
        alert("Must select business role.");
        return;
      }

      if (!Number(hourlyRate)) {
        alert("Must enter valid hourly rate.");
        return;
      }

      if (Number(hourlyRate) <= 0) {
        alert("Must enter valid hourly rate.");
        return;
      }

      if (!Number(yearsOfExp)) {
        alert("Must enter valid years of experience.");
        return;
      }

      if (Number(yearsOfExp) < 0) {
        alert("Must enter valid years of experience.");
        return;
      }

      return dL
        .saveUserRole(userRoleId, {
          userId,
          businessRoleId: businessRole.id,
          businessSector: businessRole.businessSector,
          skillLevel,
          hourlyRate: Number(hourlyRate),
          yearsOfExp: Number(yearsOfExp)
        })
        .then(function (id) {
          return dL.getQuery("UserRole")
            .get(id)
            .then(function (obj) {
              const userRole = dL.loadUserRole(obj)
              onSave(userRole)
            })
        })
        .catch(function (err) {
          alert("Error: " + err);
        });
    }}>
    <FormItem label="Select a business role:" description="Select a business role that you would like to add.">
      <SelectBusinessRole
        onChange={item => {
          model.businessRole = item;
          setState({ ...state, model });
        }}
        value={businessRole}
      />
    </FormItem>

    <FlexRow>
      <MyInput
        placeholder=" i.e. 0.00"
        style={{ maxWidth: 175 }}
        inputType="integer"
        minValue={0}
        label="Hourly Rate:"
        description="Set your hourly rate for this business role when staff augmentation services are purchased."
        value={hourlyRate}
        onChange={value => {
          model.hourlyRate = value;
          setState({ ...state, model });
        }}
      />
      <View style={{ width: 15 }} />
      <SelectBox
        label="Skill Level:"
        value={skillLevelsList.find(item => item.value == skillLevel)}
        options={skillLevelsList}
        description="What is your skill level in this role?"
        onChange={item => {
          model.skillLevel = item.value;
          setState({ ...state, model });
        }}
      />
      <View style={{ width: 15 }} />
      <MyInput
        placeholder="i.e. 2.5"
        style={{ maxWidth: 175 }}
        inputType="integer"
        minValue={0}
        label="Years of Experience:"
        description="How many years of experience do you have in this role?"
        value={yearsOfExp}
        onChange={value => {
          model.yearsOfExp = value;
          setState({ ...state, model });
        }}
      />
    </FlexRow>
  </ModalBasic>
}

export function EditUserRole() {
  var { userId, userRoleId } = useParams();
  if (!userId) {
    userId = session.user.id;
  }
  const history = useHistory();
  const [state, setState] = useState({ model: {}, isLoading: userRoleId ? true : false });
  const { isLoading, model } = state;

  useEffect(() => {
    if (userRoleId) {
      dL.getQuery("UserRole")
        .include("businessRole")
        .get(userRoleId)
        .then(function (obj) {
          setState({ ...state, isLoading: false, model: dL.loadUserRole(obj) });
        });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { skillLevel, businessRole, hourlyRate, yearsOfExp } = model;

  return (
    <EditDialog>
      <HeaderText label={userRoleId ? "Edit Business Role" : "Add Business Role"} description="Edit this business role." />

      <FormItem label="Select a business role:" description="Select a business role that you would like to add.">
        <SelectBusinessRole
          onChange={item => {
            model.businessRole = item;
            setState({ ...state, model });
          }}
          value={businessRole}
        />
      </FormItem>

      <FlexRow>
        <MyInput
          placeholder=" i.e. 0.00"
          style={{ maxWidth: 175 }}
          inputType="integer"
          minValue={0}
          label="Hourly Rate:"
          description="Set your hourly rate for this business role when staff augmentation services are purchased."
          value={hourlyRate}
          onChange={value => {
            model.hourlyRate = value;
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 15 }} />
        <SelectBox
          label="Skill Level:"
          value={skillLevelsList.find(item => item.value == skillLevel)}
          options={skillLevelsList}
          description="What is your skill level in this role?"
          onChange={item => {
            model.skillLevel = item.value;
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 15 }} />
        <MyInput
          placeholder="i.e. 2.5"
          style={{ maxWidth: 175 }}
          inputType="integer"
          minValue={0}
          label="Years of Experience:"
          description="How many years of experience do you have in this role?"
          value={yearsOfExp}
          onChange={value => {
            model.yearsOfExp = value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      <TouchButton
        label="Save Role"
        onPress={() => {
          if (!businessRole) {
            alert("Must select business role.");
            return;
          }

          if (!Number(hourlyRate)) {
            alert("Must enter valid hourly rate.");
            return;
          }

          if (Number(hourlyRate) <= 0) {
            alert("Must enter valid hourly rate.");
            return;
          }

          if (!Number(yearsOfExp)) {
            alert("Must enter valid years of experience.");
            return;
          }

          if (Number(yearsOfExp) < 0) {
            alert("Must enter valid years of experience.");
            return;
          }

          return dL
            .saveUserRole(userRoleId, {
              userId,
              businessRoleId: businessRole.id,
              businessSector: businessRole.businessSector,
              skillLevel,
              hourlyRate: Number(hourlyRate),
              yearsOfExp: Number(yearsOfExp)
            })
            .then(function () {
              history.goBack();
            })
            .catch(function (err) {
              alert("Error: " + err);
            });
        }}
      />
    </EditDialog>
  );
}

function DisplayUserServiceCatalogAdmin({ userServices }) {
  const { userId } = useParams();

  return <MyServices userId={userId} subHeader={true} />;
}

export function WorkerSelection({ userId, userRoleId, model, onChange }) {
  const [state, setState] = useState({ isLoading: userId ? true : false, tabValue: userId ? "person" : "role" });
  const { tabValue, userRoles, isLoading } = state;
  const { assignedToUser, userRole, skillLevel, businessRole, assignedTo } = model;

  useEffect(() => {
    if (userId) {
      dL.getUser(userId)
        .then(function (user) {
          model.assignedTo = user;
          return dL.getUserRoles({ userId });
        })
        .then(function (roles) {
          if (userRoleId) {
            const userRole = roles.find(item => item.id == userRoleId);
            model.businessRole = userRole.businessRole;
            model.userRole = userRole;
            model.skillLevel = userRole.skillLevel;
            onChange(model);
          }

          setState({ ...state, isLoading: false, model, userRoles: roles });
        });
    } else {
      setState({ ...state, isLoading: false });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  var skillLevels = skillLevelsList
  if (businessRole) {
    skillLevels = skillLevelsList.map(item => {
      return { label: item.label + " (" + money(businessRole.rates[item.value]) + "/hr)", value: item.value };
    });
  }

  var finalUserRoles = [];
  if (finalUserRoles && userRoles) {
    finalUserRoles = userRoles.map(item => {
      const { businessRole, id, skillLevel, hourlyRate } = item;
      return { item, label: businessRole.name + " (" + getLabelItem(skillLevelsList, skillLevel) + ") (" + money(hourlyRate) + "/hr)", value: id };
    });
  }

  return (
    <React.Fragment>
      <Section>
        <HeaderText
          subHeader
          label="Consultant Selection:"
          rightRender={<TabBar
            options={[{ label: "By Role", value: "role" }, { label: "By Person", value: "person" }]}
            onChange={item => {
              const tabValue = item.value;

              model.assignedTo = null;
              model.businessRole = null;
              model.skillLevel = null;
              model.userRole = null;
              setState({ ...state, tabValue });
              onChange(model);
            }}
            value={tabValue}
          />}
        />

        {tabValue == "role" ? (
          <React.Fragment>
            <LabelItem label="Business Role:">
              <SelectBusinessRole
                onChange={item => {
                  model.businessRole = item;
                  onChange(model);
                }}
                value={businessRole}
              />
            </LabelItem>

            {businessRole ? (
              <React.Fragment>
                <Toggle
                  label="Select specific person:"
                  value={assignedToUser}
                  onChange={value => {
                    model.assignedToUser = value;
                    onChange(model);
                  }}
                />
                {assignedToUser ? (
                  <LabelItem label="Consultant:" box>
                    <SelectWorker
                      businessRoleId={businessRole ? businessRole.id : null}
                      onChange={value => {
                        const { user } = value ? value : {};
                        if (user) {
                          dL.getUser(user.id)
                            .then(function (user) {
                              model.assignedTo = user;
                              return dL.getUserRoles({ userId: user.id });
                            })
                            .then(function (roles) {
                              const userRole = roles.find(item => item.businessRole.id == businessRole.id);
                              model.userRole = userRole;
                              model.skillLevel = userRole.skillLevel;
                              setState({ ...state, model, userRoles: roles });
                              onChange(model);
                            });
                        } else {
                          model.assignedTo = null;
                          setState({ ...state, model });
                          onChange(model);
                        }
                      }}
                      value={assignedTo}
                    />
                  </LabelItem>
                ) : (
                  <SelectBox
                    label="Skill Level:"
                    value={skillLevelsList.find(item => item.value == skillLevel)}
                    options={skillLevels}
                    onChange={item => {
                      model.skillLevel = item.value;
                      onChange(model);
                    }}
                  />
                )}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}

        {tabValue == "person" ? (
          <React.Fragment>
            <LabelItem label="Consultant:" box>
              <SelectWorker
                businessRoleId={businessRole ? businessRole.id : null}
                onChange={value => {
                  const { user } = value ? value : {};
                  if (user) {
                    dL.getUser(user.id)
                      .then(function (user) {
                        model.assignedTo = user;
                        return dL.getUserRoles({ userId: user.id });
                      })
                      .then(function (roles) {
                        setState({ ...state, model, userRoles: roles });
                        onChange(model);
                      });
                  } else {
                    model.assignedTo = null;
                    onChange(model);
                  }
                }}
                value={assignedTo}
              />
            </LabelItem>

            {assignedTo ? (
              <SelectBox
                label="Business Role:"
                value={userRole ? finalUserRoles.find(item => item.value == userRole.id) : null}
                options={finalUserRoles}
                onChange={item => {
                  const userRole = item.item;

                  model.userRole = userRole;
                  model.skillLevel = userRole.skillLevel;
                  onChange(model);
                }}
              />
            ) : null}
          </React.Fragment>
        ) : null}
      </Section>
    </React.Fragment>
  );
}

export function WorkerSelection2({ model, onChange }) {
  const [state, setState] = useState({});
  const { userRoles } = state;
  const { recommendedUser, workerUsers, userRole, skillLevel, businessRole, assignedTo } = model;

  const [visibleUserId, setVisibleUserId] = useState();

  var skillLevels = skillLevelsList
  if (businessRole) {
    skillLevels = skillLevelsList.map(item => {
      return { label: item.label + " (" + money(businessRole.rates[item.value]) + "/hr)", value: item.value };
    });
  }

  var finalUserRoles = [];
  if (finalUserRoles && userRoles) {
    finalUserRoles = userRoles.map(item => {
      const { businessRole, id, skillLevel, hourlyRate } = item;
      return { item, label: businessRole.name + " (" + getLabelItem(skillLevelsList, skillLevel) + ") (" + money(hourlyRate) + "/hr)", value: id };
    });
  }

  const getUserPrice = function () {
    const { baseBillRate } = getWorkerRate({ userRole, businessRole, assignedTo, skillLevel });
    return baseBillRate;
  };

  return (
    <React.Fragment>
      <Section>
        <HeaderText subHeader label="Consultant Selection:" />

        {assignedTo ? (
          <LabelItem label="Recommended Consultant:" style={{ marginRight: 0 }}>
            <BoxRowItem
              onPress={() => {
                setVisibleUserId(recommendedUser.id);
              }}>
              <FlexRow>
                <FlexExpand>
                  <UserItem user={recommendedUser} />
                  <View style={{ marginLeft: 40 }}>
                    <TextLine grey size={14} top={-4} value={"Skill Level: " + getLabelItem(skillLevelsList, skillLevel)} />
                    <TextLine grey size={14} top={-4} value={"Hourly Rate: " + money(getUserPrice())} />
                  </View>
                </FlexExpand>

                {recommendedUser.id == model.assignedTo.id ? <CheckIcon checked={true} style={{ marginRight: 15 }} /> : null}

                {onChange ? (
                  <TouchButton
                    size="sm"
                    micro
                    onPress={() => {
                      model.assignedTo = recommendedUser
                      onChange(model);
                      setState({ ...state });
                    }}
                    label="Select"
                  />
                ) : null}
              </FlexRow>
            </BoxRowItem>
          </LabelItem>
        ) : null}

        {workerUsers && workerUsers.length > 0 ? (
          <LabelItem label="Other Recommended Consultants:" style={{ marginRight: 0 }}>
            {workerUsers.map((user) => {
              const userRole = user.userRoles.find(item => item.businessRole.id == businessRole.id);
              const { skillLevel } = userRole;

              const { baseBillRate } = getWorkerRate({ userRole, businessRole, assignedTo: user, skillLevel });

              return (
                <BoxRowItem
                  onPress={() => {
                    setVisibleUserId(user.id);
                  }}>
                  <FlexRow>
                    <FlexExpand>
                      <UserItem user={user} />
                      <View style={{ marginLeft: 40 }}>
                        <TextLine grey size={14} top={-4} value={"Skill Level: " + getLabelItem(skillLevelsList, skillLevel)} />
                        <TextLine grey size={14} top={-4} value={"Hourly Rate: " + money(baseBillRate)} />
                      </View>
                    </FlexExpand>

                    {recommendedUser && recommendedUser.id == user.id ? <CheckIcon checked={true} style={{ marginRight: 15 }} /> : null}

                    {onChange ? (
                      <TouchButton
                        size="sm"
                        micro
                        onPress={() => {
                          model.recommendedUser = user
                          onChange(model);
                          setState({ ...state });
                        }}
                        label="Select"
                      />
                    ) : null}
                  </FlexRow>
                </BoxRowItem>
              );
            })}
          </LabelItem>
        ) : null}
      </Section>

      {visibleUserId ? (
        <ModalBasic
          full
          notCentered
          onCancel={() => {
            setVisibleUserId();
          }}>
          <UserProfileBuyer userId={visibleUserId} />
        </ModalBasic>
      ) : null}
    </React.Fragment>
  );
}

export function WorkerSelectionProposal({ model, onChange }) {
  const { recommendedUser, workerSelectionType, userRole, skillLevel, businessRole, assignedTo, workerUsers } = model;
  const [visibleUserId, setVisibleUserId] = useState({ assignedToUser: recommendedUser ? true : false });
  const [state, setState] = useState({});
  const { assignedToUser, workerVisible, userRoles } = state;

  var skillLevels = skillLevelsList
  if (businessRole) {
    skillLevels = skillLevelsList.map(item => {
      return { label: item.label + " (" + money(businessRole.rates[item.value]) + "/hr)", value: item.value };
    });
  }

  var finalUserRoles = [];
  if (finalUserRoles && userRoles) {
    finalUserRoles = userRoles.map(item => {
      const { businessRole, id, skillLevel, hourlyRate } = item;
      return { item, label: businessRole.name + " (" + getLabelItem(skillLevelsList, skillLevel) + ") (" + money(hourlyRate) + "/hr)", value: id };
    });
  }

  return (
    <React.Fragment>
      <Section>
        <HeaderText
          subHeader
          label="Consultant Selection:"
          rightRender={<TabBar
            options={[{ label: "By Role", value: "role" }, { label: "By Consultant", value: "person" }]}
            onChange={item => {
              model.workerSelectionType = item.value;
              model.assignedTo = null;
              model.businessRole = null;
              model.skillLevel = null;
              model.userRole = null;
              setState({ ...state, model });
              onChange(model);
            }}
            value={workerSelectionType}
          />}
        />

        {workerSelectionType == "role" ? (
          <React.Fragment>
            <FormItem label="Business Role:">
              <SelectBusinessRole
                onChange={item => {
                  model.businessRole = item;
                  onChange(model);

                  setState({ ...state, model });
                }}
                value={businessRole}
              />
            </FormItem>

            {businessRole ? (
              <React.Fragment>
                <SelectBox
                  label="Skill Level:"
                  value={skillLevels.find(item => item.value == skillLevel)}
                  options={skillLevels}
                  onChange={item => {
                    model.skillLevel = item.value;
                    onChange(model);
                    setState({ ...state, model });
                  }}
                />

                <Toggle
                  label="Recommend Specific Consultant:"
                  value={assignedToUser}
                  onChange={value => {
                    onChange(model);
                    setState({ ...state, model, assignedToUser: value });
                  }}
                />
                {assignedToUser ? (
                  <LabelItem label="Recommended Consultant:" box>
                    <SelectWorker
                      hideMySelf
                      businessRoleId={businessRole ? businessRole.id : null}
                      onChange={value => {
                        const { user } = value ? value : {};
                        if (user) {
                          dL.getUser(user.id)
                            .then(function (user) {
                              model.assignedTo = user;
                              model.recommendedUser = user;
                              return dL.getUserRoles({ userId: user.id });
                            })
                            .then(function (roles) {
                              const userRole = roles.find(item => item.businessRole.id == businessRole.id);
                              model.userRole = userRole;
                              model.skillLevel = userRole.skillLevel;
                              setState({ ...state, model, userRoles: roles });
                              onChange(model);
                            });
                        } else {
                          model.assignedTo = null;
                          model.recommendedUser = null;
                          setState({ ...state, model });
                          onChange(model);
                        }
                      }}
                      value={assignedTo}
                    />
                  </LabelItem>
                ) : null}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}

        {workerSelectionType == "person" ? (
          <React.Fragment>
            <LabelItem label="Recommended Consultant:" box>
              <SelectWorker
                hideMySelf
                businessRoleId={businessRole ? businessRole.id : null}
                onChange={value => {
                  const { user } = value ? value : {};
                  if (user) {
                    dL.getUser(user.id)
                      .then(function (user) {
                        model.assignedTo = user;
                        model.recommendedUser = user;
                        return dL.getUserRoles({ userId: user.id });
                      })
                      .then(function (roles) {
                        setState({ ...state, model, userRoles: roles });
                        onChange(model);
                      });
                  } else {
                    model.assignedTo = null;
                    model.recommendedUser = null;
                    onChange(model);
                    setState({ ...state, model });
                  }
                }}
                value={assignedTo}
              />
            </LabelItem>

            {assignedTo ? (
              <SelectBox
                label="Business Role:"
                value={userRole ? finalUserRoles.find(item => item.value == userRole.id) : null}
                options={finalUserRoles}
                onChange={item => {
                  const userRole = item.item;

                  model.userRole = userRole;
                  model.skillLevel = userRole.skillLevel;
                  onChange(model);
                  setState({ ...state, model });
                }}
              />
            ) : null}
          </React.Fragment>
        ) : null}

        {(businessRole || userRole) && assignedTo ? (
          <LabelItem label="Other Recommended Consultants:" description="Add additional consultants as options for selection. This will change the price for the service based on their hourly rates." style={{ marginRight: 0 }}>
            <View bottom={15}>
              {!workerUsers || workerUsers.length == 0 ? (
                <NoRecords label="No other consultants recommended." />
              ) : (
                workerUsers.map((user, index) => {
                  const userRole = user.userRoles.find(item => item.businessRole.id == businessRole.id);
                  const { skillLevel } = userRole;

                  const { baseBillRate } = getWorkerRate({ userRole, businessRole, assignedTo: user, skillLevel });

                  return (
                    <BoxRowItem>
                      <FlexRow>
                        <FlexExpand>
                          <UserItem user={user} />
                          <View style={{ marginLeft: 40 }}>
                            <TextLine grey size={14} top={-4} value={"Skill Level: " + getLabelItem(skillLevelsList, skillLevel)} />
                            <TextLine grey size={14} top={-4} value={"Hourly Rate: " + money(baseBillRate)} />
                          </View>
                        </FlexExpand>

                        <TrashIcon
                          style={{ marginLeft: 15 }}
                          onPress={() => {
                            workerUsers.splice(index, 1);
                            setState({ ...state, model });
                            onChange(model);
                          }}
                        />
                      </FlexRow>
                    </BoxRowItem>
                  );
                })
              )}
            </View>

            <TouchButton
              size="sm"
              micro
              onPress={() => {
                setState({ ...state, workerVisible: true });
              }}
              label="Add Another Consultant"
            />

            {workerVisible ? (
              <SelectWorkerDialog
                businessRoleId={businessRole ? businessRole.id : userRole ? userRole.businessRole.id : null}
                onCancel={() => {
                  setState({ ...state, workerVisible: false });
                }}
                onSelect={({ user }) => {
                  dL.getUserWorkerUser(user.id).then(function (user) {
                    if (!model.workerUsers) {
                      model.workerUsers = [];
                    }
                    model.workerUsers.push(user);
                    setState({ ...state, model, workerVisible: false });
                    onChange(model);
                  });
                }}
              />
            ) : null}
          </LabelItem>
        ) : null}
      </Section>

      {visibleUserId ? (
        <ModalBasic
          full
          notCentered
          onCancel={() => {
            setVisibleUserId();
          }}>
          <UserProfileBuyer userId={visibleUserId} />
        </ModalBasic>
      ) : null}
    </React.Fragment>
  );
}

function DisplayUserServiceCatalogWorker({ userServices }) {
  const { userId, sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ serviceTabValue: "public" });
  const { serviceTabValue } = state;

  var fList = userServices//.filter(item => item.isWorkerPublic);

  return (
    <View>
      <Section>
        <HeaderText subHeader label="Service Catalog:" description="See the services this user can provide. These service are either direct to buyer sellable or part of the private consultant service catalog." />

        <FlexRow style={{ marginLeft: -10, flexWrap: "wrap" }}>
          {fList.length == 0 ? (
            <NoRecords label="No services found." />
          ) : (
            fList.map((item) => {
              const { service, id } = item;
              const { name, serviceType, minPrice, maxPrice } = service;

              //const priceText = getUserServicePriceText({ userService: item, workerHourlyRate: session.user.minHourlyRate });

              return (
                <Card2
                  cardSize="col-sm-6 mb-2 mb-sm-3"
                  style={{ width: 450, margin: 10 }}
                  key={id}
                  title={name}
                  description={moneyRange(minPrice, maxPrice)}
                  onPress={() => {
                    history.push(`/${sectionId}/service/${service.id}/${userId}`);
                  }}>
                  <TextLine color="grey" top={8} size={12} value={getLabelItem(serviceTypesList, serviceType)} />
                </Card2>
              );
            })
          )}
        </FlexRow>
      </Section>
    </View>
  );
}

function DisplayUserServiceCatalogBuyer({ userServices }) {
  const { userId, sectionId } = useParams();
  const history = useHistory();

  const fList = userServices//.filter(item => item.makeServiceSellable);

  return (
    <View>
      <Section>
        <HeaderText subHeader label="Service Catalog:" description="See the services this user can provide. " />

        <FlexRow style={{ marginLeft: -10, flexWrap: "wrap" }}>
          {fList.length == 0 ? (
            <NoRecords label="No services added." />
          ) : (
            fList.map((item) => {
              const { service, id } = item;
              const { name, serviceType, minPrice, maxPrice } = service;

              //const priceText = getUserServicePriceText({ userService: item, workerHourlyRate: session.user.minHourlyRate });

              return (
                <Card2
                  cardSize="col-sm-6 mb-2 mb-sm-3"
                  style={{ width: 450, margin: 10 }}
                  key={id}
                  title={name}
                  description={moneyRange(minPrice, maxPrice)}
                  onPress={() => {
                    history.push(`/${sectionId}/service/${service.id}/${userId}`);
                  }}>
                  <TextLine color="grey" top={8} size={12} value={getLabelItem(serviceTypesList, serviceType)} />
                </Card2>
              );
            })
          )}
        </FlexRow>
      </Section>
    </View>
  );
}

export function WorkerSkills({ user, onEdit }) {
  var { sectionId } = useParams();
  const vIsSystemAdmin = session.user.isSystemAdmin && sectionId == "admin";

  const { companyStages } = user;
  const { roles, serviceCore, serviceTypes, goals, employeeSizes, revenueSizes, expertise } = user;

  const hasExpertise = getHasExpertise({ expertise });

  return (
    <Section>
      <HeaderText
        subHeader
        label="Expert Skills:"
        description="Highlight some skills that you would like to list."
        buttonClass="btn-mn"
        buttonIcon="pencil-alt"
        buttonText="Edit"
        onButtonPress={
          session.user.id == user.id || vIsSystemAdmin
            ? () => {
              onEdit();
            }
            : null
        }
      />
      <LabelItem label="Service Level:" value={serviceCore} />
      <LabelItem label="Business Sectors:" value={serviceTypes} />
      <LabelItem label="Company Stages:" value={companyStages} />
      <LabelItem label="Roles:" value={roles} />
      <LabelItem label="Can help with:" value={goals} />
      <LabelItem label="Best with companies of employee size:" value={employeeSizes} />
      <LabelItem label="Best with companies of revenue size:" value={revenueSizes} />

      <div class="row" style={{ maxWidth: 750, marginTop: 25 }}>
        <div class="col-sm-12">
          <HeaderText subHeader label="Expertise:" description="Highlight some of the skill sets for this user within each business area." />

          <div class="row">
            <div class="col-md-4" />
            <div class="col-md-2 row-item-header">None</div>
            <div class="col-md-2 row-item-header">A Little</div>
            <div class="col-md-2 row-item-header">Somewhat</div>
            <div class="col-md-2 row-item-header">Lots</div>
          </div>

          {!hasExpertise ? <NoRecords label="No expertise set." /> : null}

          {utils.expertise.map(item => {
            const { value, label } = item;
            const value1 = expertise[value];
            if (value1) {
              return (
                <div className="row expertise-row">
                  <div className="col-md-4">
                    <b>{label}:</b>
                  </div>
                  <div className="col-md-2 radio-sel">
                    <Circle2 selected={!value1} />
                  </div>
                  <div className="col-md-2 radio-sel">
                    <Circle2 selected={value1 == "little"} />
                  </div>
                  <div className="col-md-2 radio-sel">
                    <Circle2 selected={value1 == "somewhat"} />
                  </div>
                  <div className="col-md-2 radio-sel">
                    <Circle2 selected={value1 == "lots"} />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </Section>
  );
}

function RenderUserItem2({ businessRole, user, onPress }) {
  const { companyName, title, avatar, name, userRoles } = user;

  var userText = "";

  if (businessRole) {
    const userRole = userRoles.find(userRole => businessRole.id == userRole.businessRole.id);
    if (userRole) {
      if (userText) {
        userText = userText + ", ";
      }
      userText += businessRole.name + " (" + plural(userRole.yearsOfExp, "yr") + ", " + utils.getLabelItem(skillLevelsList, userRole.skillLevel) + ")";
    }
    /*
    userRoles.forEach(role => {
      const userRole = businessRoles.find(item => businessRole.id == userRole.businessRole.id);

      if (userText) {
        userText = userText + ", ";
      }
      userText += businessRole.name + " (" + plural(userRole.yearsOfExp, "yr") + ", " + utils.getLabelItem(skillLevels, userRole.skillLevel) + ")";
    });
    */
  }

  return (
    <FlexRow>
      <Avatar size={65} value={avatar} style={{ marginRight: 15 }} />

      <View>
        <TextLine value={name} size={18} onPress={onPress} />
        {companyName ? <TextLine top={-3} value={title ? title + " at " + companyName : companyName} size={14} color="grey" /> : null}
        {userText ? <TextLine top={-3} value={userText} size={14} color="grey" /> : null}
      </View>
    </FlexRow>
  );
}

export function SelectWorkerDialog({ onGetVersionPriceDays, onCancel, onSelect, serviceId, businessRoleId, hasStaffServices, serviceConfig }) {
  const [userId, setUserId] = useState();

  if (serviceId) {
    return (
      <React.Fragment>
        <ModalBasic notCentered full title="Consultant Selection:" onCancel={onCancel}>
          <ListRender
            searchFields={[{ field: "searchText" }]}
            defaultSort={{ field: "firstName" }}
            type="UserService"
            emptyLabel="No workers found."
            includes={["user", "user.userRoles", "userRole", "businessRole", "tasks", "subServices", "options", "packages"]}
            renderItem={userService => {
              const { id } = userService;

              return (
                <RenderWorkerItem2
                  key={id}
                  onGetVersionPriceDays={onGetVersionPriceDays}
                  userService={userService}
                  onPress={() => {
                    setUserId(userId);
                  }}
                  showStats={hasStaffServices}
                  onSelect={props => {
                    onSelect(props);
                  }}
                />
              );
            }}
            onWhere={query => {
              query.equalTo("isWorkerPublic", true);
              query.equalTo("service", dL.getObj("Service", serviceId));
            }}
          />
        </ModalBasic>

        {userId ? (
          <ModalBasic full onCancel={() => {
            setUserId();
          }}>
            <UserProfileWorker userId={userId} />
          </ModalBasic>
        ) : null}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ModalBasic notCentered full title="Consultant Selection:" onCancel={onCancel}>
          <ListRender
            searchFields={[{ field: "searchText" }]}
            defaultSort={{ field: "firstName" }}
            type="User"
            emptyLabel="No consultants found."
            renderItem={user => {
              const { id } = user;

              return (
                <RenderWorkerItem
                  onPress={() => {
                    setUserId(id);
                  }}
                  key={id}
                  showStats={hasStaffServices}
                  item={user}
                  serviceId={serviceId}
                  onSelect={props => {
                    onSelect(props);
                  }}
                />
              );
            }}
            onWhere={query => {
              query.include("userRoles");
              query.include("businessRoles");
              query.include("services");

              query.equalTo("isWorker", true);

              if (hasStaffServices) {
                query.equalTo("hasStaffServices", true);
              }

              if (serviceId) {
                query.equalToAO("services", dL.getObj("Service", serviceId));
              }
              if (businessRoleId) {
                query.equalToAO("businessRoles", dL.getObj("BusinessRole", businessRoleId));
              }
            }}
          />
        </ModalBasic>

        {userId ? (
          <ModalBasic full onCancel={() => {
            setUserId();
          }}>
            <UserProfileWorker userId={userId} />
          </ModalBasic>
        ) : null}
      </React.Fragment>
    );
  }
}

export function SelectWorker({ onGetVersionPriceDays, hasStaffServices, serviceId, businessRoleId, readonly, onChange, value, hideMySelf, commentIcon, rightRender, children }) {
  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <FlexRow>
        <FlexExpand>
          <FlexRow>
            {value ? (
              <FlexRow style={{ marginRight: 10 }}>
                <UserItem avatarSize={55} size={30} user={value} commentIcon={commentIcon}>
                  {children}
                </UserItem>
              </FlexRow>
            ) : (
              <TextLine color="grey" value="Empty" right={10} />
            )}

            {!readonly ? (
              <React.Fragment>
                <LinkButton
                  onPress={() => {
                    setVisible(true);
                  }}
                  label="Select"
                />
                {!hideMySelf ? (
                  <LinkButton
                    style={{ marginLeft: 6 }}
                    onPress={() => {
                      db.getQuery("User")
                        .get(session.user.id)
                        .then(function (obj) {
                          const user = dL.loadUser(obj);

                          onChange(getUserItem(user));
                        });
                    }}
                    label="MySelf"
                  />
                ) : null}
                {value ? (
                  <LinkButton
                    style={{ marginLeft: 6 }}
                    onPress={() => {
                      onChange(null);
                    }}
                    label="Clear"
                  />
                ) : null}
              </React.Fragment>
            ) : null}
          </FlexRow>
        </FlexExpand>

        {rightRender}
      </FlexRow>

      {visible ? (
        <SelectWorkerDialog
          serviceId={serviceId}
          onGetVersionPriceDays={onGetVersionPriceDays}
          businessRoleId={businessRoleId}
          hasStaffServices={hasStaffServices}
          onCancel={() => {
            setVisible(false);
          }}
          onSelect={({ user, userService }) => {
            onChange({ user, userService });
            setVisible(false);
            return
            if (user && serviceId) {
              dL.getUserService({ userId: user.id, serviceId }).then(function (userService) {
                onChange({ user, userService });
                setVisible(false);
              });
            } else {
              onChange({ user });
              setVisible(false);
            }
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

function PriceRender({ onGetVersionPriceDays, userService }) {
  const [isLoading, setIsLoading] = useState(true)
  const [state, setState] = useState({})

  useEffect(() => {
    dL.getServiceVersion2({ serviceVersionId: userService.currentVersion.id })
      .then(function (serviceVersion) {
        return onGetVersionPriceDays(serviceVersion)
      }).then(function ({ days, total }) {
        setState({ ...state, days, total })
        setIsLoading(false);
      });

  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { days, total } = state
  return <Price price={total} days={days} />
}

function RenderWorkerItem2({ userService, onSelect, onPress, onGetVersionPriceDays }) {
  const { user, businessRole, minPrice, maxPrice, actualDeliveryDays } = userService

  return (
    <BoxItem>
      <FlexRow alignTop>
        <FlexExpand>
          <RenderUserItem2 businessRole={businessRole} user={user} onPress={onPress} />
        </FlexExpand>

        {onGetVersionPriceDays ? <PriceRender userService={userService} onGetVersionPriceDays={onGetVersionPriceDays} /> : <Price price={moneyRange(minPrice, maxPrice)} days={actualDeliveryDays} />}

        <TouchButton
          style={{ marginLeft: 25 }}
          size="sm"
          onPress={() => {
            onSelect({ user, userService });
          }}
          label="Select"
        />
      </FlexRow>
    </BoxItem>
  );
}

export function RenderWorkerItem({ showStats, item, onSelect, onPress, serviceId }) {
  const [stats, setStats] = useState();
  const [userService, setUserService] = useState();
  const { id, minHourlyRate, maxHoursPerMonth, maxHoursPerWeek, maxHoursPerDay } = item;

  useEffect(() => {
    if (serviceId) {
      dL.getQuery("UserService")
        .equalTo("service", dL.getObj("Service", serviceId))
        .equalTo("user", dL.getObj("User", id))
        .containedIn("removed", [undefined, false])
        .include("service")
        .include("service.packages")
        .include("service.deliverables")
        .include("service.businessRole")
        .first()
        .then(function (obj) {
          const userService = obj ? dL.loadUserService(obj) : null

          setUserService(userService);
        });
    }
  }, []);

  useEffect(() => {
    if (showStats) {
      dL.getStaffServiceWorkerStats(item).then(function (stats) {
        setStats(stats);
      });
    }
  }, []);

  var priceText;
  if (userService) {
    //priceText = getUserServicePriceText({ userService, workerHourlyRate: minHourlyRate });
  }
  const { makeServiceSellable } = userService ? userService : {};

  const renderStats = function () {
    const { bookedTotalHours, bookedPriorityHours, totalPriorityHours, totalHours } = stats;

    const availableHours = totalHours - bookedTotalHours;
    const availablePriorityHours = totalPriorityHours - bookedPriorityHours;

    return (
      <View style={{ marginTop: 15, marginBottom: -15 }}>
        <FlexRow>
          <LabelItem label="Hourly Rate:" value={money(minHourlyRate)} />
          <LabelItem label="Hours Per Day:" value={plural(maxHoursPerDay, "hr")} />
          <LabelItem label="Hours Per Week:" value={plural(maxHoursPerWeek, "hr")} />
          <LabelItem label="Hours Per Month:" value={plural(maxHoursPerMonth, "hr")} />
        </FlexRow>

        <FlexRow>
          <LabelItem label="Available Hours:" value={availableHours + " of " + plural(totalHours, "hr")} />
          <LabelItem label="Available Priority Hours:" value={availablePriorityHours + " of " + plural(totalPriorityHours, "hr")} />
        </FlexRow>
      </View>
    );
  };

  return (
    <BoxItem>
      <FlexRow alignTop>
        <FlexExpand>
          <RenderUserItem2 user={item} onPress={onPress} />

          {stats ? renderStats() : null}

          {priceText ? (
            <FlexRow top={15} bottom={-15}>
              <LabelItem label="Selling:" value={yesNo(makeServiceSellable)} />
              {!makeServiceSellable ? <LabelItem label="Consultant Rate:" value={money(minHourlyRate) + "/hr"} /> : null}
              <LabelItem label={makeServiceSellable ? "Consultant Price:" : "Consultant Cost:"} value={priceText} />
            </FlexRow>
          ) : null}
        </FlexExpand>

        <TouchButton
          size="sm"
          onPress={() => {
            onSelect({ user: item, userService });
          }}
          label="Select"
        />
      </FlexRow>
    </BoxItem>
  );
}

export function UserProfileWorker(props) {
  var { userId, sectionId } = useParams();
  if (props && props.userId) {
    userId = props.userId;
  }
  const history = useHistory();
  const [stats, setStats] = useState({})
  const [state, setState] = useState({ model: {}, isLoading: true });
  const { isLoading, model, userServices } = state;
  const [tabValue, setTabValue] = useState("profile");

  const getWorkerStats = function (model) {
    const promises = []
    const stats = {}
    promises[promises.length] = dL.getQuery("UserService")
      .equalTo("user", dL.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .count().then(function (count) {
        stats.servicesCount = count
      })
    promises[promises.length] = dL.getQuery("UserService")
      .equalTo("user", dL.getObj("User", session.user.id))
      .equalTo("isWorkerPublic", true)
      .containedIn("removed", [undefined, false])
      .count().then(function (count) {
        stats.servicesPublicCount = count
      })

    promises[promises.length] = dL.getQuery("ServiceProposal")
      .equalTo("accessUsers.userId", session.user.id)
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .count().then(function (count) {
        stats.proposalPartOf = count
      })

    promises[promises.length] = dL.getQuery("ServiceProposal")
      .equalTo("createdBy", dL.getObj("User", session.user.id))
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .count().then(function (count) {
        stats.proposalSentCount = count
      })

    promises[promises.length] = dL.getQuery("ServiceOrder")
      .equalTo("assignedTo", dL.getObj("User", session.user.id))
      .equalTo("status", "completed")
      .containedIn("removed", [undefined, false])
      .count().then(function (count) {
        stats.serviceOrdersCompleted = count
      })

    promises[promises.length] = dL.getQuery("ServiceOrder")
      .equalTo("assignedTo", dL.getObj("User", session.user.id))
      .notContainedIn("status", ["completed", "canceled"])
      .containedIn("removed", [undefined, false])
      .count().then(function (count) {
        stats.serviceOrdersQueue = count
      })

    const getHours2 = function ({ startDate }) {
      return dL.getTimeRecordHours({ startDate, endDate: new Date() })
    }

    promises[promises.length] = getHours2({ startDate: Moment().add(-30, "days").toDate() }).then(function (hours) {
      stats.hoursCompleted30 = hours
    });

    promises[promises.length] = getHours2({ startDate: Moment().add(-10, "years").toDate() })
      .then(function (hours) {
        stats.hoursCompleted = hours
      });

    const getHours = function ({ startDate, endDate }) {
      return db
        .getQuery("TaskRecord")
        .aggregate([
          {
            $match: {
              _p_assignedTo: "User$" + session.user.id,
              dueDate: { $gt: startDate },
              dueDate: { $lte: endDate },
              removed: { $in: [undefined, false] }
            },
          },
          {
            $group: {
              _id: null,
              total: { $sum: "$hours" }
            }
          }
        ]).then(function (obj) {
          if (obj.length > 0) {
            return Math.round(obj[0].total / 60)
          } else {
            return 0
          }
        });
    }

    promises[promises.length] = getHours({ startDate: Moment().add(-30, "day").toDate(), endDate: Moment().add(30, "days").toDate() }).then(function (hours) {
      stats.hoursQueued30 = hours
    });

    promises[promises.length] = getHours({ startDate: Moment().add(-30, "day").toDate(), endDate: Moment().add(60, "days").toDate() }).then(function (hours) {
      stats.hoursQueued60 = hours
    });

    promises[promises.length] = getHours({ startDate: Moment().add(-30, "day").toDate(), endDate: Moment().add(90, "days").toDate() }).then(function (hours) {
      stats.hoursQueued90 = hours
    });

    return Promise.all(promises).then(function () {
      const { maxHoursPerMonth } = model
      stats.hoursAvailable30 = maxHoursPerMonth - stats.hoursQueued30
      stats.hoursAvailable60 = maxHoursPerMonth * 2 - stats.hoursAvailable30 - stats.hoursQueued60
      stats.hoursAvailable90 = maxHoursPerMonth * 3 - stats.hoursAvailable60 - stats.hoursQueued90
      setStats(stats)
    })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }

  useEffect(() => {
    dL.getUserWorker({ userId }).then(function (model) {
      getWorkerStats(model)

      dL.getQuery("UserService")
        .equalTo("user", dL.getObj("User", userId))
        .equalTo("isWorkerPublic", true)
        .containedIn("removed", [undefined, false])
        .include("service")
        .find().then(function (objs) {
          const userServices = dL.loadObjects("UserService", objs)
          setState({ ...state, isLoading: false, model, userServices });
        })

    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { teamMembers, hasAdvisoryServices, enabledRoles, userRoles, tagLine, userRole, hasStaffServices, remoteOnly, companyName, companyTitle, avatar, firstName, lastName, website, linkedInUrl, shortDescription, description } = model;

  const tabs = [{
    label: "Profile", value: "profile"
  },
  { label: "Skills", value: "skills" },
  { label: "Work", value: "work" },
  { label: pluralTab({ list: userRoles, label: "Role" }), value: "roles" },
  { label: pluralTab({ list: userServices.filter(item => item.isWorkerPublic), label: "Service" }), value: "services" }];

  if (teamMembers.length > 0) {
    tabs.push({ label: pluralTab({ list: teamMembers, label: "Team" }), value: "teams" })
  }

  const renderStats = function () {
    return <Section style={{ maxWidth: 350 }}>
      <HeaderText subHeader label="Workers Stats:" style={{ marginBottom: 0 }} />

      <StatItem label="Number of Services:" value={stats.servicesCount} description="Total number of services in their service catalog." />

      <StatItem label="Public Services:" value={stats.servicesPublicCount} description="Number of services made public in then consulting marketplace." />

      <StatItem2 label="Strategy Maps:">
        <StatLabelItem label="Sent:" value={plural(stats.proposalSentCount, "map")} />
        <StatLabelItem label="Team Member:" value={plural(stats.proposalPartOf, "map")} />
      </StatItem2>

      <StatItem2 label="Services:">
        <StatLabelItem label="Completed:" value={plural(stats.serviceOrdersCompleted, "service")} spacer />
        <StatLabelItem label="Queued:" value={plural(stats.serviceOrdersQueue, "service")} />
      </StatItem2>

      <StatItem2 label="Hours Completed (Past):">
        <StatLabelItem label="30 Days:" value={plural(stats.hoursCompleted30, "hr")} />
        <StatLabelItem label="Lifetime:" value={plural(stats.hoursCompleted, "hr")} />
      </StatItem2>

      <StatItem2 label="Hours Queued (Future):">
        <StatLabelItem label="30 Days:" value={plural(stats.hoursQueued30, "hr")} />
        <StatLabelItem label="60 Days:" value={plural(stats.hoursQueued60, "hr")} />
        <StatLabelItem label="90 Days:" value={plural(stats.hoursQueued90, "hr")} />
      </StatItem2>

      <StatItem2 label="Hours Available (Future):">
        <StatLabelItem label="30 Days:" value={plural(stats.hoursAvailable30, "hr")} />
        <StatLabelItem label="60 Days:" value={plural(stats.hoursAvailable60, "hr")} />
        <StatLabelItem label="90 Days:" value={plural(stats.hoursAvailable90, "hr")} />
      </StatItem2>
    </Section>
  }

  return (
    <FlexRow alignTop>
      <FlexExpand>
        <HeaderText
          label={firstName + " " + lastName}
          description={tagLine}
          rightRender={<TabBar
            queryId="tb"
            style={{ marginLeft: 10 }}
            options={tabs}
            onChange={item => {
              setTabValue(item.value);
            }}
            value={tabValue}
          />}
        />
        {tabValue == "profile" ? (
          <View>
            <Section>
              <Avatar value={avatar} icon={<CommentIcon iconStyle={{ fontSize: 25 }} style={{ marginRight: -20, marginTop: 0 }} onPress={() => {
                openChat({
                  roomId: getRoomId([session.user, model]),
                  chatType: "direct",
                  users: [session.user, model]
                });
              }} />} />
            </Section>

            <Section>
              <HeaderText subHeader label="Personal Bio:" description="Share more about the user in this bio section." />
              <LabelItem label="Short Bio:" value={shortDescription} />
              <LabelItem label="About the User:" value={description} />
              <LabelItem label="Linked In:" value={linkedInUrl} />
              <LabelItem label="Website:" value={website} />
              <LabelItem label="Primary Role:" value={getLabelItem(utils.userRoles, userRole)} />
            </Section>

            <Section>
              <HeaderText subHeader label="Last Notable Company:" description="Tell us about the last notable employer or company." />
              <FlexRow>
                <LabelItem label="Company Name:" value={companyName} />
                <LabelItem label="Title:" value={companyTitle} />
              </FlexRow>
            </Section>

            <Section>
              <HeaderText subHeader label="Home Location:" description="Share your home location." />
              <FlexRow>
                <LabelItem label="Home City:" value={model.homeCity} />
                <LabelItem label="Country:" value={model.country} />
                <LabelItem label="Region:" value={model.countryRegion} />
              </FlexRow>
            </Section>
          </View>
        ) : null}

        {tabValue == "skills" ? <WorkerSkills user={model} description="Highlighted work skills for this user." /> : null}

        {tabValue == "work" ? (
          <Section>
            <HeaderText subHeader label="Work Details:" description="See below your setting for availability on the platform." />

            <Section>
              <HeaderText subHeader label="Roles:" description="The below are roles that you have been approved to perform on our platform." />

              <FlexRow>
                <LabelItem label="Business Development:" value={enabledRoles.businessDevelopment ? "YES" : "NO"} />

                <LabelItem label="Strategy Consultant:" value={enabledRoles.strategyConsultant ? "YES" : "NO"} />

                <LabelItem label="Project Manager:" value={enabledRoles.projectManager ? "YES" : "NO"} />

                <LabelItem label="Recruiter:" value={enabledRoles.recruiter ? "YES" : "NO"} />
              </FlexRow>
            </Section>

            <Section>
              <HeaderText subHeader label="Service Types:" description="The below are service types you are available for." />
              <FlexRow>
                <LabelItem label="Staff Services:" value={hasStaffServices ? "YES" : "NO"} />

                <LabelItem label="Advisory Services:" value={hasAdvisoryServices ? "YES" : "NO"} />
              </FlexRow>
            </Section>

            <Section>
              <HeaderText subHeader label="Location:" description="Details about your work  location preferences." />
              <LabelItem label="Remote Only" value={remoteOnly ? "YES" : "NO"} />

              {!remoteOnly ? <LabelItem label="Available in Cities:" value={model.availableInCities} /> : null}
            </Section>

            <Section>
              <HeaderText subHeader label="Hourly Rates & Availability:" description="Details about your desired rates and availability for work." />

              <LabelItem label="Min. Hourly Rate:" value={money(model.minHourlyRate)} />

              <FlexRow>
                <LabelItem label="Max. Hours per Day:" description="Maximum number of hours you are available to work per day." style={{ marginRight: 25 }} value={model.maxHoursPerDay + " hrs"} />
                <LabelItem label="Max. Hours per Week:" description="Maximum number of hours you are available to work per week." style={{ marginRight: 25 }} value={model.maxHoursPerWeek + " hrs"} />
                <LabelItem label="Max. Hours per Month:" description="Maximum number of hours you are available to work per month." value={model.maxHoursPerMonth + " hrs"} />
              </FlexRow>
            </Section>
          </Section>
        ) : null}

        {tabValue == "roles" ? (
          <Section>
            <HeaderText subHeader label="Business Roles:" description="See the business roles that this user can fulfill for staff augmentation or advisory services." />

            <FlexRow style={{ marginLeft: -10 }}>
              {userRoles.length == 0 ? (
                <NoRecords label="No business roles added." />
              ) : (
                userRoles.map((item) => {
                  const { skillLevel, businessRole, yearsOfExp, hourlyRate, id } = item;
                  return (
                    <Card2
                      cardSize="col-sm-6 col-xl-6 mb-2 mb-sm-6"
                      style={{ width: 300, margin: 10 }}
                      key={id}
                      title={businessRole.name}
                      description={money(hourlyRate) + "/hr  -  " + plural(yearsOfExp, "yr") + "  -  " + getLabelItem(skillLevelsList, skillLevel)}
                      onPress={() => {
                        history.push(`/${sectionId}/business-roles/${id}`);
                      }}>
                      <TextLine color="grey" top={8} size={12} value={getLabelItem(serviceTypesList, businessRole.businessSector)} />
                    </Card2>
                  );
                })
              )}
            </FlexRow>
          </Section>
        ) : null}

        {tabValue == "services" ? <DisplayUserServiceCatalogWorker userServices={userServices} /> : null}

        {tabValue == "teams" ? (
          <Section>
            <HeaderText subHeader label="Teams:" description="This user is part of the following teams." />

            <FlexRow style={{ marginLeft: -10 }}>
              {teamMembers.length == 0 ? (
                <NoRecords label="No teams found." />
              ) :
                teamMembers.map((teamMember) => {
                  const { id, team } = teamMember;
                  return <RenderTeamItem key={id} team={team}
                    onPress={() => {
                      history.push(`/${sectionId}/team/${team.id}`);
                    }}>
                  </RenderTeamItem>
                })
              }
            </FlexRow>
          </Section>
        ) : null}
      </FlexExpand>
      <View style={{ paddingLeft: 35 }}>
        {renderStats()}
      </View>
    </FlexRow>
  );
}

export function UserProfileBuyer(props) {
  var { userId, sectionId } = useParams();
  if (props && props.userId) {
    userId = props.userId;
  }
  const history = useHistory();
  const [state, setState] = useState({ model: {}, isLoading: true });
  const { isLoading, model, userServices } = state;
  const [tabValue, setTabValue] = useState("profile");

  useEffect(() => {
    dL.getUserWorker({ userId }).then(function (model) {
      return dL.getQuery("UserService")
        .equalTo("user", dL.getObj("User", userId))
        .equalTo("isWorkerPublic", true)
        .containedIn("removed", [undefined, false])
        .include("service")
        .postIncludeEqualTo("_p_service.isBuyerPublic", true)
        .find().then(function (objs) {
          var userServices = dL.loadObjects("UserService", objs)
          setState({ ...state, isLoading: false, model, userServices });
        })
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { homeCity, country, countryRegion, hasAdvisoryServices, userRoles, tagLine, hasStaffServices, remoteOnly, companyName, companyTitle, avatar, firstName, lastName, website, linkedInUrl, shortDescription, description } = model;

  const tabs = [{ label: "Profile", value: "profile" }, { label: "Skills", value: "skills" }, { label: "Work", value: "work" }, { label: pluralTab({ list: userRoles, label: "Role" }), value: "roles" }, { label: pluralTab({ list: userServices, label: "Service" }), value: "services" }];

  return (
    <View style={{ marginHorizontal: 23 }}>
      <HeaderText
        label={firstName + " " + lastName}
        description={tagLine}
        rightRender={<TabBar
          queryId="tb"
          style={{ marginLeft: 10 }}
          options={tabs}
          onChange={item => {
            setTabValue(item.value);
          }}
          value={tabValue}
        />}
      />

      {tabValue == "profile" ? (
        <View>
          <Section>
            <Avatar value={avatar} icon={<CommentIcon iconStyle={{ fontSize: 25 }} style={{ marginRight: -20, marginTop: 0 }} onPress={() => {
              openChat({
                roomId: getRoomId([session.user, model]),
                chatType: "direct",
                users: [session.user, model]
              });
            }} />} />
          </Section>

          <Section>
            <HeaderText subHeader label="Personal Bio:" description="Share more about the user in this bio section." />
            <LabelItem label="Short Bio:" value={shortDescription} />
            <LabelItem label="About the User:" value={description} hideIfNull />
            <LabelItem label="Linked In:" value={linkedInUrl} hideIfNull />
            <LabelItem label="Website:" value={website} hideIfNull />
          </Section>

          {companyName || companyTitle ? (
            <Section>
              <HeaderText subHeader label="Last Notable Company:" description="Tell us about the last notable employer or company." />
              <FlexRow>
                <LabelItem label="Company Name:" value={companyName} hideIfNull />
                <LabelItem label="Title:" value={companyTitle} hideIfNull />
              </FlexRow>
            </Section>
          ) : null}

          <Section>
            <HeaderText subHeader label="Home Location:" description="Share your home location." />
            <FlexRow>
              <LabelItem label="Home City:" value={homeCity} />
              <LabelItem label="Country:" value={country} />
              <LabelItem label="Region:" value={countryRegion} />
            </FlexRow>
          </Section>
        </View>
      ) : null}

      {tabValue == "skills" ? <WorkerSkills user={model} description="Highlighted work skills for this user." /> : null}

      {tabValue == "work" ? (
        <Section>
          <HeaderText subHeader label="Work Details:" description="See below when the user is availability and for what types of work." />

          <Section>
            <HeaderText subHeader label="Service Types:" description="The below are service types the user is available for." />
            <FlexRow>
              <LabelItem label="Staff Services:" value={hasStaffServices ? "YES" : "NO"} />

              <LabelItem label="Advisory Services:" value={hasAdvisoryServices ? "YES" : "NO"} />
            </FlexRow>
          </Section>

          <Section>
            <HeaderText subHeader label="Location:" description="Details about your work  location preferences." />
            <LabelItem label="Remote Only" value={remoteOnly ? "YES" : "NO"} />

            {!remoteOnly ? <LabelItem label="Available in Cities:" value={model.availableInCities} /> : null}
          </Section>

          <Section>
            <HeaderText subHeader label="Hourly Rates & Availability:" description="Details about your desired rates and availability for work." />

            <FlexRow>
              <LabelItem label="Hourly Rate:" value={money(model.minHourlyRate)} />

              <LabelItem label="Hours per Month:" description="Maximum number of hours you are available to work per month." value={model.maxHoursPerMonth + " hrs"} />
            </FlexRow>
          </Section>
        </Section>
      ) : null}

      {tabValue == "roles" ? (
        <Section>
          <HeaderText subHeader label="Business Roles:" description="See the business roles that this user can fulfill for staff augmentation or advisory services." />

          <FlexRow style={{ marginLeft: -10 }}>
            {userRoles.length == 0 ? (
              <NoRecords label="No business roles added." />
            ) : (
              userRoles.map((item) => {
                const { skillLevel, businessRole, yearsOfExp, hourlyRate, id } = item;
                return (
                  <Card2
                    cardSize="col-sm-6 col-xl-6 mb-2 mb-sm-6"
                    style={{ width: 300, margin: 10 }}
                    key={id}
                    title={businessRole.name}
                    description={money(hourlyRate) + "/hr  -  " + plural(yearsOfExp, "yr") + "  -  " + getLabelItem(skillLevelsList, skillLevel)}
                    onPress={() => {
                      history.push(`/${sectionId}/business-roles/${id}`);
                    }}>
                    <TextLine color="grey" top={8} size={14} value={getLabelItem(utils.serviceTypes, businessRole.businessSector)} />

                    <TouchButton
                      style={{ marginTop: 12 }}
                      size="sm"
                      micro
                      onPress={() => {
                        history.push(`/${sectionId}/staff-services/add-staff/${userId}/${businessRole.id}`);
                      }}
                      label="Hire"
                    />
                  </Card2>
                );
              })
            )}
          </FlexRow>
        </Section>
      ) : null}

      {tabValue == "services" ? <DisplayUserServiceCatalogBuyer userServices={userServices} /> : null}
    </View>
  );
}

export function ExpertiseSelector({ value: expertise, onChange }) {
  const expSelect = function (item, value) {
    expertise[item.value] = value;
    onChange(expertise)
  };

  return <View>
    <div class="row">
      <div class="col-md-4" />
      <div class="col-md-2 row-item-header">None</div>
      <div class="col-md-2 row-item-header">A Little</div>
      <div class="col-md-2 row-item-header">Somewhat</div>
      <div class="col-md-2 row-item-header">Lots</div>
    </div>

    {utils.expertise.map(item => {
      const { value, label } = item;
      const value1 = expertise[value];

      return (
        <div className="row expertise-row">
          <div className="col-md-4">
            <b>{label}:</b>
          </div>
          <div
            className="col-md-2 radio-sel"
            onClick={() => {
              expSelect(item, null);
            }}>
            <Circle2 selected={!value1} />
          </div>
          <div
            className="col-md-2 radio-sel"
            onClick={() => {
              expSelect(item, "little");
            }}>
            <Circle2 selected={value1 == "little"} />
          </div>
          <div
            className="col-md-2 radio-sel"
            onClick={() => {
              expSelect(item, "somewhat");
            }}>
            <Circle2 selected={value1 == "somewhat"} />
          </div>
          <div
            className="col-md-2 radio-sel"
            onClick={() => {
              expSelect(item, "lots");
            }}>
            <Circle2 selected={value1 == "lots"} />
          </div>
        </div>
      );
    })}
  </View>
}

export function EditUserWorker() {
  const { sectionId, userId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ model: { status: "draft" }, isLoading: userId ? true : false });
  const { isLoading, model } = state;
  const [tabValue, setTabValue] = useState("profile");
  const refC1 = useRef();

  useEffect(() => {
    if (userId) {
      dL.getQuery("User")
        .get(userId)
        .then(function (obj) {
          setState({ ...state, isLoading: false, model: dL.loadUser(obj) });
        });
    }
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!model.expertise) {
    model.expertise = {};
  }

  //type (advisory, strategy, execution, etc...)
  //last notable employer: title, company
  //home base location (city select)
  //business sectors (select 3 from our business sectors list, select multi)
  //available type: inter-rim staffer/staff augmentation, micro service consultant (select many)
  //work location: cities (select many) OR countries (Select many), remote only (toggle)
  //salary: min rate per hour (date), max hours per week/month (rate)
  //objective: none, staff aug, full-time hire, consulting role only
  //services can do: business sector -> service
  //roles can be in: business sector -> role
  //expert at:
  //-help with companies in the following stage:
  //-help for companies that have following issues: add up to 10, or select from our list
  //-key skill set map ()

  //i am a consulting partner:
  //-company name, website, location

  //status: draft, published, archived

  const {
    availableToStart,
    status,
    companyStages,
    serviceTypes,
    serviceCore,
    minHourlyRate,
    maxHoursPerDay,
    maxHoursPerMonth,
    maxHoursPerWeek,
    employeeSizes,
    revenueSizes,
    goals,
    roles,
    industries,
    userRole,
    hasStaffServices,
    hasAdvisoryServices,
    remoteOnly,
    companyName,
    companyTitle,
    avatar,
    firstName,
    lastName,
    website,
    linkedInUrl,
    shortDescription,
    description,
    expertise
  } = model;

  const selectCountry = function (val) {
    model.country = val;
    setState({ ...state, model });
  };

  const selectRegion = function (val) {
    model.countryRegion = val;
    setState({ ...state, model });
  };

  const saveUser = function ({ validateAll }) {
    if (!firstName) {
      alert("Must enter first name.");
      return;
    }

    if (validateAll) {
      if (!lastName) {
        alert("Must enter last name.");
        return;
      }

      if (hasStaffServices || hasAdvisoryServices) {
        if (!isNumber(minHourlyRate)) {
          alert("Must enter valid min hourly rate.");
          return;
        }
        if (!isNumber(maxHoursPerMonth)) {
          alert("Must enter valid max hours per month.");
          return;
        }
        if (!isNumber(maxHoursPerWeek)) {
          alert("Must enter valid max hours per week.");
          return;
        }
        if (!isNumber(maxHoursPerDay)) {
          alert("Must enter valid max hours per day.");
          return;
        }
      }

      model.minHourlyRate = minHourlyRate ? Number(minHourlyRate) : null;
      model.maxHoursPerMonth = maxHoursPerMonth ? Number(maxHoursPerMonth) : null;
      model.maxHoursPerWeek = maxHoursPerWeek ? Number(maxHoursPerWeek) : null;
      model.maxHoursPerDay = maxHoursPerDay ? Number(maxHoursPerDay) : null;
    }

    return dL.saveUser(userId, model).then(function (obj) {
      if (!userId) {
        history.replace("/" + sectionId + "/people/" + obj.id);
      } else {
        history.goBack();
      }
    });
  };

  return (
    <EditDialog>
      <HeaderText
        label="Edit Profile"
        description="Edit your user profile information."
        rightRender={<FlexRow>
          <TabBar
            queryId="tb"
            style={{ marginLeft: 10 }}
            options={[{ label: "Profile", value: "profile" }, { label: "Skills", value: "skills" }, { label: "Work", value: "work" }]}
            onChange={item => {
              setTabValue(item.value);
            }}
            value={tabValue}
          />
        </FlexRow>}
      />

      <ScriptTag
        src="//maps.googleapis.com/maps/api/js?key=AIzaSyCuHtEOEHrsv6Kshy0LyodwjOJ81KhhNJ0&libraries=places"
        onLoad={() => {
          setState({ ...state, isLoaded2: true });
        }}
      />

      {
        tabValue == "profile" ? (
          <View>
            <Section>
              <ImageElement
                label="Avatar:"
                value={avatar}
                onChange={value => {
                  model.avatar = value;
                  setState({ ...state, model });
                }}
              />

              <FlexRow>
                <MyInput
                  label="First Name:"
                  value={firstName}
                  onChange={text => {
                    model.firstName = text;
                    setState({ ...state, model });
                  }}
                />
                <View style={{ width: 15 }} />
                <MyInput
                  label="Last Name:"
                  value={lastName}
                  onChange={text => {
                    model.lastName = text;
                    setState({ ...state, model });
                  }}
                />
              </FlexRow>

              {sectionId == "admin" ? (
                <SelectBox
                  label="Status:"
                  value={userStatuses.find(item => item.value == status)}
                  options={userStatuses}
                  onChange={item => {
                    model.status = item.value;
                    setState({ ...state, model });
                  }}
                />
              ) : null}
            </Section>

            <Section>
              <HeaderText subHeader label="Personal Bio:" description="Share more about the user in this bio section." />
              <MyInput
                maxLength={144}
                label="Short Bio:"
                value={shortDescription}
                onChange={value => {
                  model.shortDescription = value;
                  setState({ ...state, model });
                }}
              />

              <MyInput
                multiline
                label="About User:"
                value={description}
                onChange={value => {
                  model.description = value;
                  setState({ ...state, model });
                }}
              />

              <MyInput
                label="Linked In:"
                value={linkedInUrl}
                onChange={value => {
                  model.linkedInUrl = value;
                  setState({ ...state, model });
                }}
              />

              <MyInput
                label="Website:"
                value={website}
                onChange={value => {
                  model.website = value;
                  setState({ ...state, model });
                }}
              />
            </Section>

            <Section>
              <HeaderText subHeader label="Last Notable Company:" description="Tell us about the last notable employer or company." />
              <MyInput
                label="Company Name:"
                value={companyName}
                onChange={value => {
                  model.companyName = value;
                  setState({ ...state, model });
                }}
              />
              <MyInput
                label="Title:"
                value={companyTitle}
                onChange={value => {
                  model.companyTitle = value;
                  setState({ ...state, model });
                }}
              />
            </Section>

            <Section>
              <HeaderText subHeader label="Home Location:" description="Share your home location." />
              <FormItem label="Home City:">
                {state.isLoaded2 ? (
                  <LocationSearchInput
                    defaultValue={model.homeCity}
                    onChange={value => {
                      model.homeCity = value;
                      setState({ ...state, model });
                    }}
                  />
                ) : null}
              </FormItem>

              <FormItem label="Home Region:">
                <View>
                  <CountryDropdown style={{ borderWidth: 2, borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7 }} value={model.country} onChange={val => selectCountry(val)} />
                  <RegionDropdown style={{ marginTop: 15, borderWidth: 2, borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7 }} country={model.country} value={model.countryRegion} onChange={val => selectRegion(val)} />
                </View>
              </FormItem>
            </Section>
          </View>
        ) : null
      }

      {
        tabValue == "skills" ? (
          <Section>
            <HeaderText subHeader label="Skills:" description="Highlight some skills that you would like to list." />

            <SelectBox
              multiple
              label="Service Level:"
              value={serviceCore}
              options={serviceCores}
              onChange={items => {
                model.serviceCore = items;
                setState({ ...state, model });
              }}
            />

            <FlexRow alignTop>
              <SelectBox
                multiple
                label="Business Sectors:"
                value={serviceTypes}
                options={serviceTypesList}
                onChange={items => {
                  model.serviceTypes = items;
                  setState({ ...state, model });
                }}
              />
              <View style={{ width: 10 }} />
              <SelectBox
                multiple
                label="Company Stages:"
                description="Share what stages of companies you enjoy to work with."
                value={companyStages}
                options={utils.companyStages}
                onChange={items => {
                  model.companyStages = items;
                  setState({ ...state, model });
                }}
              />
            </FlexRow>

            <SelectBox
              multiple
              maxItems={3}
              label="Industries:"
              description="Pick up to 3 items."
              value={industries}
              options={utils.industries}
              onChange={items => {
                model.industries = items;
                setState({ ...state, model });
              }}
            />
            <SelectBox
              multiple
              maxItems={3}
              label="Roles:"
              value={roles}
              options={utils.roles}
              onChange={items => {
                model.roles = items;
                setState({ ...state, model });
              }}
            />

            <SelectBox
              groupBy="category"
              multiple
              maxItems={7}
              label="Can help with:"
              description="Pick up to 7 items."
              value={goals}
              options={utils.goals}
              onChange={items => {
                model.goals = items;
                setState({ ...state, model });
              }}
            />

            <SelectBox
              multiple
              maxItems={3}
              label="Best with companies of employee size:"
              description="Pick up to 3 items."
              value={employeeSizes}
              options={utils.employeeSizes}
              onChange={items => {
                model.employeeSizes = items;
                setState({ ...state, model });
              }}
            />

            <SelectBox
              multiple
              maxItems={3}
              label="Best with companies of revenue size:"
              description="Pick up to 3 items."
              value={revenueSizes}
              options={utils.revenueSizes}
              onChange={items => {
                model.revenueSizes = items;
                setState({ ...state, model });
              }}
            />

            <Section>
              <HeaderText subHeader label="Expertise:" />
              <ExpertiseSelector value={expertise} onChange={(value) => {
                model.expertise = value
                setState({ ...state, model });
              }} />
            </Section>
          </Section>
        ) : null}

      {tabValue == "work" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Work Preferences:" description="The below are service types you are available for." />
            <Toggle
              label="Available for staff services:"
              description="Make this user available for staff services."
              value={hasStaffServices}
              onChange={value => {
                model.hasStaffServices = value;
                setState({ ...state, model });
              }}
            />

            <Toggle
              label="Available for advisory services:"
              description="Make this user available for advisory services."
              value={hasAdvisoryServices}
              onChange={value => {
                model.hasAdvisoryServices = value;
                setState({ ...state, model });
              }}
            />
          </Section>

          <Section>
            <HeaderText subHeader label="Work Location:" description="Details about your work  location preferences." />

            <Toggle
              label="Remote Only"
              description="Only take on remote roles."
              value={remoteOnly}
              onChange={value => {
                model.remoteOnly = value;
                setState({ ...state, model });
              }}
            />

            {!remoteOnly ? (
              <FormItem label="Available in Cities:">
                {model.availableInCities
                  ? model.availableInCities.map((item, index) => {
                    return (
                      <FlexRow>
                        <TextLine value={item} />
                        <TrashIcon
                          onPress={() => {
                            model.availableInCities.splice(index, 1);
                            setState({ ...state, model });
                          }}
                        />
                      </FlexRow>
                    );
                  })
                  : null}

                {state.isLoaded2 ? (
                  <LocationSearchInput
                    ref={ref => {
                      refC1.current = ref;
                    }}
                    onChange={value => {
                      if (!model.availableInCities) {
                        model.availableInCities = [];
                      }

                      model.availableInCities.push(value);
                      setState({ ...state, model });

                      refC1.current.clear();
                    }}
                  />
                ) : null}
              </FormItem>
            ) : null}
          </Section>

          <Section>
            <HeaderText subHeader label="Hourly Rates & Availability:" description="Details about your desired rates and availability for work." />

            <FormItem label="Available to start:" description="When are you available to start if not right now.">
              <Datetime
                style={{ maxWidth: 155 }}
                closeOnClickOutside={true}
                closeOnSelect={true}
                value={availableToStart}
                timeFormat={false}
                onChange={value => {
                  model.availableToStart = value.toDate();
                  setState({ ...state, model });
                }}
              />
            </FormItem>

            <FlexRow>
              <MyInput
                style={{ maxWidth: 125 }}
                inputType="money"
                minValue={0}
                label="Min. Hourly Rate:"
                value={model.minHourlyRate}
                onChange={value => {
                  model.minHourlyRate = value;
                  setState({ ...state, model });
                }}
              />
            </FlexRow>

            <FlexRow>
              <MyInput
                style={{ maxWidth: 175 }}
                inputType="integer"
                minValue={0}
                label="Max Hours per Day:"
                value={model.maxHoursPerDay}
                onChange={value => {
                  model.maxHoursPerDay = value;
                  setState({ ...state, model });
                }}
              />
              <View style={{ width: 25 }} />
              <MyInput
                style={{ maxWidth: 175 }}
                inputType="integer"
                minValue={0}
                label="Max Hours per Week:"
                value={model.maxHoursPerWeek}
                onChange={value => {
                  model.maxHoursPerWeek = value;
                  setState({ ...state, model });
                }}
              />
              <View style={{ width: 25 }} />
              <MyInput
                style={{ maxWidth: 175 }}
                inputType="integer"
                minValue={0}
                label="Max Hours per Month:"
                value={model.maxHoursPerMonth}
                onChange={value => {
                  model.maxHoursPerMonth = value;
                  setState({ ...state, model });
                }}
              />
            </FlexRow>

            <SelectBox
              label="Primary Role:"
              value={utils.userRoles.find(item => item.value == userRole)}
              options={utils.userRoles}
              onChange={item => {
                model.userRole = item.value;
                setState({ ...state, model });
              }}
            />

            {/*
        //objective: none, staff aug, full-time hire, consulting role only //services can do: business sector -> service //roles can be in: business sector -> role //expert at: //-help with companies in the following stage: //-help for companies that have following issues: add up to 10, or select from our list //-key skill set map ()*/}
          </Section>
        </React.Fragment>
      ) : null
      }

      {
        status == "draft" ? (
          <TouchButton
            style={{ marginRight: 10 }}
            label="Save Draft"
            onPress={() => {
              saveUser({});
            }}
          />
        ) : (
          <TouchButton
            label="Save User"
            onPress={() => {
              saveUser({ validateAll: true });
            }}
          />
        )
      }
    </EditDialog >
  );
}

export function EditUserAdmin() {
  const { userId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model } = state;
  const [tabValue, setTabValue] = useState("profile");

  useEffect(() => {
    dL.getQuery("User")
      .get(userId)
      .then(function (obj) {
        setState({ ...state, isLoading: false, model: dL.loadUser(obj) });
      });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { status, isSystemAdmin, isWorker, adminServiceTypes, workerLevel } = model;

  return (
    <EditDialog>
      <HeaderText
        label="Edit User Admin"
        description="Setup the admin settings for this user profile."
        rightRender={<FlexRow>
          <TabBar
            style={{ marginLeft: 10 }}
            options={[{ label: "Profile", value: "profile" }, { label: "Permissions", value: "permissions" }]}
            onChange={item => {
              setTabValue(item.value);
            }}
            value={tabValue}
          />
        </FlexRow>}
      />

      { tabValue == "profile" ? (
        <View>
          <Section>
            <SelectBox
              label="Status:"
              value={userStatuses.find(item => item.value == status)}
              options={userStatuses}
              onChange={item => {
                model.status = item.value;
                setState({ ...state, model });
              }}
            />
          </Section>

          <SelectBox
            label="Consultant Level:"
            value={utils.workerLevels.find((item) => item.value == workerLevel)}
            options={utils.workerLevels}
            onChange={(item) => {
              model.workerLevel = item.value
              setState({ ...state, model });
            }}
          />
          <SelectBox
            multiple
            label="Business Sector (Admin):"
            description="Manage as system admin in the following business sectors."
            value={adminServiceTypes ? serviceTypesList.find((item) => adminServiceTypes.find(item2 => item2 == item.value)) : []}
            options={serviceTypesList}
            onChange={(value) => {
              model.adminServiceTypes = value.map(item => item.value)
              setState({ ...state, model });
            }}
          />
        </View>
      ) : null}

      {
        tabValue == "permissions" ? (
          <Section>
            <HeaderText subHeader label="Admin:" description="" />

            {session.user.isSystemAdmin ? (
              <Toggle
                label="System Admin:"
                value={isSystemAdmin}
                onChange={value => {
                  model.isSystemAdmin = value;
                  setState({ ...state, model });
                }}
              />
            ) : null}
            <Toggle
              label="Consultant:"
              value={isWorker}
              onChange={value => {
                model.isWorker = value;
                setState({ ...state, model });
              }}
            />
          </Section>
        ) : null
      }

      <TouchButton
        label="Save User"
        onPress={() => {
          return dL
            .saveUser(userId, model)
            .then(function () {
              history.goBack();
            });
        }}
      />
    </EditDialog >
  );
}

export function UserProfileAdmin() {
  var { userId, sectionId } = useParams();
  if (!userId) {
    userId = session.user.id;
  }
  const history = useHistory();
  const { url } = useRouteMatch();
  const [state, setState] = useState({ model: {}, isLoading: userId ? true : false });
  const { dialogType, isLoading, model, showDelete, deleteId, deleteIndex } = state;
  const [tabValue, setTabValue] = useState("profile");

  useEffect(() => {
    dL.getUserWorker({ userId }).then(function (user) {
      setState({ ...state, isLoading: false, model: user });
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const vIsSystemAdmin = session.user.isSystemAdmin && sectionId == "admin";

  const { serviceCore, companyStages, roles, goals, employeeSizes, revenueSizes } = model;
  const { isPublicWorkerDirectory, isPublicBuyerDirectory, userRoles, customServices, userServices } = model;
  const { serviceTypes, status, expertise, permissions, hasAdvisoryServices, enabledRoles, tagLine, userRole, hasStaffServices, remoteOnly, serviceType, companyName, companyTitle, avatar, firstName, lastName, website, linkedInUrl, shortDescription, description, isWorker, isSystemAdmin, homeCity, country, countryRegion } = model;

  const hasExpertise = getHasExpertise({ expertise });

  const tabs = [{ label: "Profile", value: "profile" }, { label: "Skills", value: "skills" }, { label: "Work", value: "work" }, { label: pluralTab({ list: userRoles.length, label: "Role" }), value: "roles" }, { label: pluralTab({ list: userServices.length, label: "Service" }), value: "services" }];
  if (vIsSystemAdmin) {
    tabs.push({ label: "Admin", value: "admin" });
  }

  const checklistItems = [];
  checklistItems.push({
    label: "Complete basic profile details.",
    value: "details",
    buttonText: "Edit Details",
    onButtonPress: () => {
      setTabValue("profile");
      onEditProfile();
    },
    isComplete: () => {
      if (firstName && lastName && tagLine && companyName && companyTitle && shortDescription && avatar && serviceType && linkedInUrl && description && homeCity && country && countryRegion) {
        return true;
      }
    }
  });
  checklistItems.push({
    label: "Enter your skills and experience.",
    value: "skills",
    buttonText: "Edit Skills",
    onButtonPress: () => {
      setTabValue("skills");
    },
    isComplete: () => {
      if (hasExpertise && serviceCore && (serviceTypes && serviceTypes.length > 0) && (companyStages && companyStages.length > 0) && (roles && roles.length > 0) && (goals && goals.length > 0) && (employeeSizes && employeeSizes.length > 0) && (revenueSizes && revenueSizes.length > 0)) {
        return true;
      }
    }
  });

  checklistItems.push({
    label: "Setup your work preferences and availability.",
    value: "work",
    buttonText: "Edit Work",
    onButtonPress: () => {
      setTabValue("work");
    },
    isComplete: () => {
      return true;
    }
  });
  checklistItems.push({
    label: "Setup your business roles.",
    value: "roles",
    buttonText: "Edit Roles",
    onButtonPress: () => {
      setTabValue("roles");
    },
    isComplete: () => {
      if (userRoles.length > 0) {
        return true;
      }
    }
  });
  checklistItems.push({
    label: "Setup your micro services.",
    value: "services",
    buttonText: "Edit Services",
    onButtonPress: () => {
      setTabValue("services");
    },
    isComplete: () => {
      if (userServices.filter(item => item.status != "draft").length > 0) {
        return true;
      }
    }
  });
  checklistItems.push({
    label: "Publish your profile.",
    value: "public",
    buttonText: "Publish Profile",
    onButtonPress: () => {
      //make your profile published to the worker directory
      dL.getObj("User", model.id).set("isPublicWorkerDirectory", true).save()
    },
    isComplete: () => {
      if (isPublicWorkerDirectory) {
        return true;
      }
    }
  });
  /*
  checklistItems.push({
    label: "Join a team.",
    value: "team",
    buttonText: "View Teams",
    onButtonPress: () => {},
    isComplete: () => {
      return true;
    }
  });
  */

  const saveUserPermissions = function () {
    return dL
      .getObj("User", model.id)
      .set("permissions", permissions)
      .save();
  };

  const buttons = [];

  const onEditProfile = function (tab) {
    history.push(`/admin/people/${userId}/edit?tb=${tab}`);
  };

  if (vIsSystemAdmin) {
    buttons.push({
      label: "Admin Setup",
      onPress: () => {
        history.push("/admin/people/" + userId + "/edit-admin");
      }
    });
  }

  const { testMode } = session.user;

  return (
    <View style={{ marginHorizontal: 23 }}>
      <HeaderText
        label={firstName + " " + lastName}
        description={tagLine}
        buttons={buttons}
        rightRender={<TabBar
          queryId="tb"
          style={{ marginLeft: 10 }}
          options={tabs}
          onChange={item => {
            setTabValue(item.value);
          }}
          value={tabValue}
        />}
      />

      <FlexRow alignTop>
        <FlexExpand>
          {tabValue == "purchase" ? (
            <View>
              <TouchButton
                label="Buy Staff Services"
                onPress={() => {
                  history.push("/admin/staff-services/add-staff");
                }}
              />
            </View>
          ) : null}

          {tabValue == "profile" ? (
            <View>
              <Section>
                <Avatar value={avatar} />
              </Section>

              <Section>
                <HeaderText
                  subHeader
                  label="Personal Bio:"
                  description="Share more about the user in this bio section."
                  buttonClass="btn-mn"
                  buttonIcon="pencil-alt"
                  buttonText="Edit"
                  onButtonPress={
                    session.user.id == model.id || vIsSystemAdmin
                      ? () => {
                        onEditProfile("profile");
                      }
                      : null
                  }
                />
                <LabelItem label="Short Bio:" value={shortDescription} />
                <LabelItem label="About the User:" value={description} />
                <LabelItem label="Linked In:" value={linkedInUrl} />
                <LabelItem label="Website:" value={website} />
                <LabelItem label="Primary Role:" value={getLabelItem(utils.userRoles, userRole)} />

                {isSystemAdmin ? (
                  <Toggle
                    label="Test Mode:"
                    value={testMode}
                    onChange={value => {
                      session.user.testMode = value;
                      dL.getObj("User", session.user.id).set("testMode", value).save()
                      setState({ ...state });
                    }}
                  />
                ) : null}
              </Section>

              <Section>
                <HeaderText subHeader label="Last Notable Company:" description="Tell us about the last notable employer or company." />
                <FlexRow>
                  <LabelItem label="Company Name:" value={companyName} />
                  <LabelItem label="Title:" value={companyTitle} />
                </FlexRow>
              </Section>

              <Section>
                <HeaderText subHeader label="Home Location:" description="Share your home location." />
                <FlexRow>
                  <LabelItem label="Home City:" value={homeCity} />
                  <LabelItem label="Country:" value={country} />
                  <LabelItem label="Region:" value={countryRegion} />
                </FlexRow>
              </Section>

              <Section>
                <HeaderText subHeader label="Visibility:" description="Set your profile visibility."
                  buttonClass="btn-mn"
                  buttonIcon="pencil-alt"
                  buttonText="Edit"
                  onButtonPress={() => {
                    setState({ ...state, dialogType: "visibility" })
                  }} />
                <FlexRow>
                  <LabelItem label="Visible in 'People' directory:" value={yesNo(isPublicWorkerDirectory)} />

                  {vIsSystemAdmin ? <LabelItem label="Visible in Buyer People Directory:" value={yesNo(isPublicBuyerDirectory)} /> : null}
                </FlexRow>
              </Section>
            </View>
          ) : null}

          {tabValue == "skills" ? (
            <WorkerSkills
              user={model}
              description="Highlight some skills that you would like to list."
              onEdit={() => {
                onEditProfile("skills");
              }}
            />
          ) : null}

          {tabValue == "work" ? (
            <Section>
              <HeaderText
                subHeader
                label="Work Details:"
                description="See below your setting for availability on the platform."
                buttonClass="btn-mn"
                buttonIcon="pencil-alt"
                buttonText="Edit"
                onButtonPress={
                  session.user.id == model.id || vIsSystemAdmin
                    ? () => {
                      onEditProfile("work");
                    }
                    : null
                }
              />

              <Section>
                <HeaderText subHeader label="Roles:" description="The below are roles that you have been approved to perform on our platform." />

                <FlexRow>
                  <LabelItem label="Business Development:" value={enabledRoles.businessDevelopment ? "YES" : "NO"} />

                  <LabelItem label="Strategy Consultant:" value={enabledRoles.strategyConsultant ? "YES" : "NO"} />

                  <LabelItem label="Project Manager:" value={enabledRoles.projectManager ? "YES" : "NO"} />

                  <LabelItem label="Recruiter:" value={enabledRoles.recruiter ? "YES" : "NO"} />
                </FlexRow>
              </Section>

              <Section>
                <HeaderText subHeader label="Service Types:" description="The below are service types you are available for." />
                <FlexRow>
                  <LabelItem label="Staff Services:" value={hasStaffServices ? "YES" : "NO"} />

                  <LabelItem label="Advisory Services:" value={hasAdvisoryServices ? "YES" : "NO"} />
                </FlexRow>
              </Section>

              <Section>
                <HeaderText subHeader label="Location:" description="Details about your work  location preferences." />
                <LabelItem label="Remote Only" value={remoteOnly ? "YES" : "NO"} />

                {!remoteOnly ? <LabelItem label="Available in Cities:" value={model.availableInCities} /> : null}
              </Section>

              <Section>
                <HeaderText subHeader label="Hourly Rates & Availability:" description="Details about your desired rates and availability for work." />

                <LabelItem label="Min. Hourly Rate:" value={money(model.minHourlyRate)} />

                <FlexRow>
                  <LabelItem label="Max. Hours per Day:" description="Maximum number of hours you are available to work per day." style={{ marginRight: 25 }} value={model.maxHoursPerDay + " hrs"} />
                  <LabelItem label="Max. Hours per Week:" description="Maximum number of hours you are available to work per week." style={{ marginRight: 25 }} value={model.maxHoursPerWeek + " hrs"} />
                  <LabelItem label="Max. Hours per Month:" description="Maximum number of hours you are available to work per month." value={model.maxHoursPerMonth + " hrs"} />
                </FlexRow>
              </Section>
            </Section>
          ) : null}

          {tabValue == "admin" ? (
            <View>
              <Section>
                <HeaderText subHeader label="Admin Settings:" />
                <FlexRow>
                  <LabelItem label="Consultant" value={isWorker ? "YES" : "NO"} />
                  <LabelItem label="System Admin:" value={isSystemAdmin ? "YES" : "NO"} />
                  <LabelItem label="Status:" value={getLabelItem(userStatuses, status)} />
                </FlexRow>
              </Section>

              <Section>
                <HeaderText subHeader label="Permissions:" description="Setup the user permissions to access certain admin and workflow process features." />
                <Toggle
                  label="Allow to create fixed price service:"
                  description="Allow user to set fixed price for service instead of hourly rates."
                  value={permissions.allowFixedPriceService}
                  onChange={value => {
                    permissions.allowFixedPriceService = value;
                    saveUserPermissions();
                    setState({ ...state, model });
                  }}
                />

                <Toggle
                  label="Allow user to add teams:"
                  description="Allow user to add and make teams public."
                  value={permissions.allowAddTeam}
                  onChange={value => {
                    permissions.allowAddTeam = value;
                    saveUserPermissions();
                    setState({ ...state, model });
                  }}
                />

                <Toggle
                  label="Allow to publish service to buyer catalog:"
                  description="Allow user to make service public in service catalog."
                  value={permissions.allowMakeServicesPublic}
                  onChange={value => {
                    permissions.allowMakeServicesPublic = value;
                    saveUserPermissions();
                    setState({ ...state, model });
                  }}
                />

                <Toggle
                  label="Allow to edit business roles:"
                  description="This user will be able to add and edit business roles."
                  value={permissions.editBusinessRoles}
                  onChange={value => {
                    permissions.editBusinessRoles = value;
                    saveUserPermissions();
                    setState({ ...state, model });
                  }}
                />

                <Toggle
                  label="Allow to edit all services:"
                  description="This user will be able to add and edit service definitions."
                  value={permissions.editServiceDefinitions}
                  onChange={value => {
                    permissions.editServiceDefinitions = value;
                    saveUserPermissions();
                    setState({ ...state, model });
                  }}
                />

                <Toggle
                  label="Allow to edit service request/orders/service orders assignment:"
                  description="This user will be able to assign service requests/orders/service orders to users."
                  value={permissions.serviceRequestAssignment}
                  onChange={value => {
                    permissions.serviceRequestAssignment = value;
                    saveUserPermissions();
                    setState({ ...state, model });
                  }}
                />

                <Toggle
                  label="Allow to handle user profile approval:"
                  description="This user will be able to approve new user profiles."
                  value={permissions.userApproval}
                  onChange={value => {
                    permissions.userApproval = value;
                    saveUserPermissions();
                    setState({ ...state, model });
                  }}
                />
              </Section>

              <Section>
                <HeaderText subHeader label="Visibility:" description="Set the visibility of this user profile in the user directory." />

                <Toggle
                  label="Display in buyer user directory:"
                  description="Make this user visible on the buyer people directory visible to only buyers on the platform."
                  value={isPublicBuyerDirectory}
                  onChange={value => {
                    model.isPublicBuyerDirectory = value;
                    dL.getObj("User", model.id)
                      .set("isPublicBuyerDirectory", value)
                      .save();
                    setState({ ...state, model });
                  }}
                />
              </Section>
            </View>
          ) : null}

          {tabValue == "roles" ? (
            <Section>
              <HeaderText
                subHeader
                label="My Business Roles:"
                description="Setup the business roles that you can fulfill for staff augmentation or advisory services."
                onButtonPress={() => {
                  history.push(url + "/user-role/add");
                }}
              />

              <FlexRow style={{ marginLeft: -10 }}>
                {userRoles.length == 0 ? (
                  <NoRecords label="No business roles added." />
                ) : (
                  userRoles.map((item, index) => {
                    const { skillLevel, businessRole, yearsOfExp, hourlyRate, id } = item;
                    return (
                      <Card2
                        cardSize="col-sm-12 mb-2 mb-sm-3"
                        key={id}
                        title={businessRole.name}
                        description={money(hourlyRate) + "/hr  -  " + plural(yearsOfExp, "yr") + "  -  " + getLabelItem(skillLevelsList, skillLevel)}
                        onPress={() => {
                          history.push(`/${sectionId}/business-roles/${id}`);
                        }}
                        onEdit={() => {
                          history.push(url + "/user-role/" + id);
                        }}
                        onDelete={() => {
                          setState({ ...state, showDelete: true, deleteId: id, deleteIndex: index });
                        }}>
                        <TextLine color="grey" top={8} size={12} value={getLabelItem(serviceTypesList, businessRole.businessSector)} />
                      </Card2>
                    );
                  })
                )}
              </FlexRow>

              {showDelete ? (
                <DeleteConfirm
                  onCancel={() => {
                    setState({ ...state, showDelete: false });
                  }}
                  onConfirm={() => {
                    dL.removeUserRole({ userId, userRoleId: deleteId });
                    userRoles.splice(deleteIndex, 1);
                    setState({ ...state, showDelete: false, model });
                  }}
                />
              ) : null}
            </Section>
          ) : null}

          {tabValue == "services" ? <DisplayUserServiceCatalogAdmin userServices={userServices} customServices={customServices} /> : null}
        </FlexExpand>

        <View style={{ marginLeft: 25 }}>
          <RenderChecklist label="Steps to publish profile:" description="Complete your profile to get more access to features on the platform." items={checklistItems} />
        </View>
      </FlexRow>

      {dialogType == "visibility" ? (
        <EditProfileVisibility
          user={model}
          onCancel={() => {
            setState({ ...state, dialogType: null });
          }}
          onSave={(user) => {
            setState({ ...state, dialogType: null, model: Object.assign(model, user) });
          }}
        />
      ) : null}
    </View>
  );
}

function EditProfileVisibility({ user, onCancel, onSave }) {
  const [state, setState] = useState({ model: Object.assign({}, user) });
  const { model } = state

  const { isPublicWorkerDirectory } = model;

  return (
    <ModalBasic
      title="Profile Visibility:"
      description="Set the visibility of your profile."
      okText="Save"
      onCancel={onCancel}
      onOk={() => {
        onSave(model)

        return dL.getObj("User", model.id)
          .set("isPublicWorkerDirectory", isPublicWorkerDirectory)
          .save()
      }}>

      <Toggle
        value={isPublicWorkerDirectory}
        onChange={(value) => {
          model.isPublicWorkerDirectory = value;
          setState({ ...state, model });
        }}
        label="Make your profile visible in the people directory:"
        style={{ marginBottom: 23 }}
      />
    </ModalBasic>
  );
}

export function WorkRequestsWorker() {
  const [state, setState] = useState({ isLoading: false })
  const { sectionId } = useParams();
  const history = useHistory();
  const { isLoading, waitingCount, firstVisibleAt } = state

  useEffect(() => {
    dL.getQuery("WorkRequest")
      .equalTo("assignedTo", dL.getObj("User", session.user.iud))
      .equalTo("status", "pending")
      .greaterThan("visibleAt", new Date())
      .containedIn("removed", [undefined, false])
      .ascending("visibleAt")
      .select(["visibleAt"])
      .find()
      .then(function (objs) {
        const waitingCount = objs.length
        var firstVisibleAt
        if (waitingCount > 0) {
          const workRequest = dL.loadWorkRequest(objs[0])
          firstVisibleAt = workRequest.visibleAt
        }

        setState({ firstVisibleAt, waitingCount, isLoading: false })
      })
  }, []);

  const renderTop = function () {
    if (waitingCount && waitingCount > 0) {
      return <BoxItem name="Waiting Work Requests:" description="Some work requests are waiting to be shown. These request have other consultants that need to either decline to skip the requests first based on work load.">
        <TextLine label="Waiting requests:" value={plural(waitingCount, "request")} />
        <TextLine label="Next visible request may be seen:" value={Moment(firstVisibleAt).fromNow()} />
      </BoxItem>
    }
  }

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <ListRender2
      showSectorsWithItemsOnly={true}
      defaultSort={{ field: "createdAt", desc: true }}
      filters={[
        {
          label: "Open",
          value: "pending",
          onQuery: function (query) {
            query.lessThan("visibleAt", new Date());
            query.equalTo("status", "pending");
          }
        },
        {
          label: "Accepted",
          value: "accept",
          sort: [{ field: "responseAt", desc: true }],
          onQuery: function (query) {
            query.equalTo("status", "accept");
          }
        },
        {
          label: "Declined",
          value: "decline",
          sort: [{ field: "responseAt", desc: true }],
          onQuery: function (query) {
            query.equalTo("status", "decline");
          }
        },
        {
          label: "Assigned",
          value: "assigned",
          onQuery: function (query) {
            query.existsObj("isAssigned", true);
          }
        }
      ]}
      onWhere={query => {
        query.equalTo("user", dL.getObj("User", session.user.id));
      }}
      type="WorkRequest"
      emptyLabel="No work request found."
      includes={["user", "createdBy", "service", "serviceOrder", "servicePackage"]}
      title="Work Requests"
      description="Manage work requests."
      statuses={utils.workRequestStatuses}
      renderTop={renderTop()}
      renderItem={item => {
        const { id, createdAt, createdBy, serviceOrder, service, servicePackage, status } = item;
        const { serviceType, serviceCore } = service;

        return (
          <BoxRowItem>
            <FlexRow alignTop>
              <FlexExpand>
                <TextLine bold>Ref #: {serviceOrder.orderNumber}</TextLine>
                {serviceOrder && service ? (
                  <TextLine
                    size={22}
                    value={service.name}
                    onPress={() => {
                      history.push(`/${sectionId}/work-request/${id}`);
                    }}
                  />
                ) : null}
                {servicePackage ? <TextLine grey value={"Package: " + servicePackage.name} /> : null}
                {createdBy ? <UserItem label="Sent By:" user={createdBy} style={{ marginTop: 12 }} /> : null}
              </FlexExpand>

              <View alignRight>
                <TextLine size={22} value={utils.money(serviceOrder.workerEarning)} />
                <TextLine size={12} uppercase color="grey" value={status} />
                <View style={{ height: 12 }} />
                <DateTimeItem value={createdAt} fromNow alignRight />
              </View>
            </FlexRow>

            {serviceType ? <TextLine size={14} color="grey" top={10} value={getLabelItem(serviceTypesList, serviceType) + ", " + getLabelItem(utils.serviceCores, serviceCore)} /> : null}
          </BoxRowItem>
        );
      }}
    />
  );
}

export function WorkRequests() {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      defaultSort={{ field: "createdAt", desc: true }}
      filters={[
        { label: "All", value: "all", disableStat: true },
        { label: "Pending", value: "pending" },
        { label: "Accepted", value: "accept", sort: [{ field: "responseAt", desc: true }] },
        { label: "Declined", value: "decline", sort: [{ field: "responseAt", desc: true }] },
        {
          label: "Assigned",
          value: "assigned",
          onQuery: function (query) {
            query.existsObj("isAssigned", true);
          }
        }
      ]}
      onWhere={query => {
        const userId = session.user.id;
        if (sectionId == "worker") {
          query.equalTo("accessUsers.userId", userId);
        }
      }}
      type="WorkRequest"
      emptyLabel="No work request found."
      includes={["user", "service", "serviceOrder", "servicePackage"]}
      title="Work Requests"
      description="Manage work requests."
      statuses={utils.workRequestStatuses}
      renderItem={item => {
        const { id, createdAt, serviceOrder, service, user, status } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/work-request/${id}`);
            }}
            style={{ flexDirection: "row" }}>
            <View style={{ flex: 1 }}>
              <DateTimeItem value={createdAt} fromNow />
              {serviceOrder && service ? <TextLine size={22} value={serviceOrder.orderNumber + ": " + service.name} top={6} /> : null}
              {user ? <UserItem label="Consultant:" user={user} style={{ marginTop: 8 }} /> : null}
            </View>
            <View alignRight>
              <TextLine size={12} uppercase color="grey" value={status} />
            </View>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function WorkRequest() {
  const { workRequestId, sectionId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ isLoading: true });
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(new Date());
  const { isLoading, model } = state;

  const [chatOrderUsers, setChatOrderUsers] = useState([]);
  const [roomId, setRoomId] = useState("");
  const [roomName, setRoomName] = useState("");
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    var model;

    return db
      .getQuery("WorkRequest")
      .include("user")
      .include("order")
      .include("service")
      .include("servicePackage")
      .include("order.strategyConsultant")
      .include("order.projectManager")
      .include("serviceOrder")
      .include("serviceOrder.assignedTo")
      .include("serviceOrder.service")
      .include("serviceOrder.servicePackage")
      .include("serviceOrder.strategyConsultant")
      .include("serviceOrder.projectManager")
      .get(workRequestId)
      .then(function (obj) {
        model = dL.loadWorkRequest(obj);

        setChatOrderUsers([model.order.projectManager, model.user]);
        setRoomId(model.id);
        setRoomName("Work Request #: " + model.serviceOrder.orderNumber);

        setState({ ...state, isLoading: false, model });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { serviceOrder, status, id, order } = model;

  const loadChat = function (users) {
    setChatOrderUsers(users);
    setRoomId(getChatStr(users));
    setRoomName("");
  };
  // breadCrumb={"Orders : " + order.orderNumber + " : " + serviceOrder.orderNumber + " : Work Request"}
  return (
    <View style={{ flexDirection: "row" }}>
      <View style={{ flex: 1 }}>
        <View style={{ flexDirection: "row", marginBottom: 25 }}>
          <BasicTop title={"Work Request: " + serviceOrder.orderNumber} />

          <FlexRow>
            {status == "pending" ? (
              <React.Fragment>
                <TouchButton
                  onPress={() => {
                    return dL.acceptWorkRequest(id, { orderId: order.id, serviceOrderId: serviceOrder.id }).then(function () {
                      history.push(`/${sectionId}/work-requests`);
                      setRefresh(new Date());
                    });
                  }}
                  label="Accept"
                />
                <TouchButton
                  style={{ marginLeft: 4 }}
                  variant="secondary"
                  onPress={() => {
                    return dL.declineWorkRequest(id, { orderId: order.id, serviceOrderId: serviceOrder.id }).then(function () {
                      history.push(`/${sectionId}/work-requests`);
                      setRefresh(new Date());
                    });
                  }}
                  label="Decline"
                />
              </React.Fragment>
            ) : null}
          </FlexRow>
        </View>

        <Section>
          <RenderServiceOrderItem item={model.serviceOrder} />
        </Section>
      </View>

      <View style={{ marginLeft: 25, width: 350 }}>
        <DisplayKeyUser
          label="Strategy Consultant:"
          value={order.strategyConsultant}
        />
        <DisplayKeyUser
          label="Project Manager:"
          value={order.projectManager}
        />

        <Chat roomName={roomName} roomId={roomId} name={session.user.firstName + " " + session.user.lastName} avatar={session.user.avatar} roomUserId={model.id + session.user.id} defaultUsers={chatOrderUsers} chatType="group" />
      </View>
    </View>
  );
}
