import {firestore, firebase} from "./firebase-db";

var factory = {
  unpresence: function(roomId, roomUserId, data) {
    firebase.database().ref("/status/" + roomUserId).set(
      Object.assign(
        {
          roomId,
          roomUserId,
          state: "offline",
          last_changed: firebase.database.ServerValue.TIMESTAMP
        },
        data
      )
    );
      
    firestore
      .collection("status")
      .doc(roomUserId)
      .set(
        Object.assign(
          {
            roomId,
            roomUserId,
            state: "offline",
            last_changed: firebase.firestore.FieldValue.serverTimestamp()
          },
          data
        )
      );
  },
  presence: function(roomId, roomUserId, data) {
    var userStatusDatabaseRef = firebase.database().ref("/status/" + roomUserId);
    var isOfflineForDatabase = Object.assign(
      {
        roomId,
        roomUserId,
        state: "offline",
        last_changed: firebase.database.ServerValue.TIMESTAMP
      },
      data
    );

    var isOnlineForDatabase = Object.assign(
      {
        roomId,
        roomUserId,
        state: "online",
        last_changed: firebase.database.ServerValue.TIMESTAMP
      },
      data
    );

    var userStatusFirestoreRef = firestore.collection("status").doc(roomUserId);

    var isOfflineForFirestore = Object.assign(
      {
        roomId,
        roomUserId,
        state: "offline",
        last_changed: firebase.firestore.FieldValue.serverTimestamp()
      },
      data
    );

    var isOnlineForFirestore = Object.assign(
      {
        roomId,
        roomUserId,
        state: "online",
        last_changed: firebase.firestore.FieldValue.serverTimestamp()
      },
      data
    );

    firebase.database().ref(".info/connected").on("value", function(snapshot) {
      if (snapshot.val() == false) {
        userStatusFirestoreRef.set(isOfflineForFirestore);
        return;
      }

      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function() {
          userStatusDatabaseRef.set(isOnlineForDatabase);
          userStatusFirestoreRef.set(isOnlineForFirestore);
        });
    });
  }
};

export default factory;
