import "./App.css";
import React, { useRef, useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import http from "root/utils/http";
import utils from "root/utils/functions";
import session from "root/utils/session";
import PromiseA from "root/utils/promise";
import { useParams, useHistory } from "react-router-dom";
import "root/App.css";
import { __DEV__ } from "root/dev";
import renderHTML from "react-render-html";
import { RenderChecklist, RenderUser } from "root/pack-3";
import ScriptTag from "react-script-tag";
import Moment from "moment-business-days";
import { RenderButtons, BasicTop, LabelUserItem, SelectUser, ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { Tags, Loading, RenderIcon, IconPicker, DisplayCenterBox, TouchButton, Toggle, Avatar, Hide, EditIcon, ImageElement, LocationSearchInput, Section, ModalBasic, LabelItem, FlexExpand, FlexRow, TabBar, Text, TextLine, View, TrashIcon, UnDeleteIcon, ArchiveIcon, FormItem, MyInput, SelectBox, NoRecords } from "root/pack-1";
import { RenderUserItem } from "root/users"
import { ChatWindow } from "root/chat";
import { SelectBusinessRole } from "root/business-roles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { ServiceRequestsWorker } from "root/service-requests";
import { OrdersWorker } from "root/orders";
import { ServiceOrdersWorker } from "root/service-orders";
import { ProposalsWorker } from "root/proposals-3"
import { MyServices } from "root/services-worker"
import { TeamBonusList } from "root/earnings"
import { UserProfileWorker } from "root/worker"

const { companyStages2, staffTypes, plural, renderArray, skillLevels, getLabelItems, serviceTypes, getLabelItem } = utils;

const hoursPerMonthItems = [{ label: "1/16 Month", value: 160 / 16 }, { label: "1/8 Month", value: 160 / 8 }, { label: "1/4 Month", value: 160 / 4 }, { label: "1/2 Month", value: 160 / 2 }, { label: "Full Month", value: 160 }]
hoursPerMonthItems.forEach(item => {
  item.label = item.label + " (" + item.value + " hrs)"
})

export function NewTeam({ onCancel, onSave }) {
  const [state, setState] = useState({ model: { name: "" } });
  const { model } = state;
  const { companyStage, businessSectors, name, shortDescription } = model;

  const buttons = [];

  buttons.push({
    label: "Create Team",
    onPress: () => {
      if (!name) {
        alert("Must enter team name.");
        return;
      }
      if (!shortDescription) {
        alert("Must enter a short description about the goals or focus of the team.");
        return;
      }

      return dL.newTeam({ companyStage, name, businessSectors, shortDescription }).then(function (teamId) {
        return dL
          .getQuery("Team")
          .get(teamId)
          .then(function (obj) {
            const team = dL.loadTeam(obj);
            onSave(team);
          });
      });
    }
  });

  return (
    <ModalBasic title="New Team" onCancel={onCancel} buttons={buttons}>
      <MyInput
        label="Team Name:"
        placeholder="Enter name"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        multiple
        maxItems={3}
        label="Business Sector:"
        value={businessSectors ? serviceTypes.filter(item => businessSectors.find(id => id == item.value)) : null}
        options={serviceTypes}
        description="Optionally, select a business sector for this team."
        onChange={item => {
          model.businessSectors = item.map(item => item.value)
          setState({ ...state, model });
        }}
      />

      <SelectBox
        required
        label="Work with businesses in the following stage:"
        value={companyStages2.find(item => item.value == companyStage)}
        options={companyStage}
        onChange={item => {
          model.companyStage = item.value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        boxStyle={{ marginBottom: 0 }}
        maxLength={144}
        multiline
        label="Team Description:"
        description="Enter a short description about the focus on the team."
        value={shortDescription}
        onChange={value => {
          model.shortDescription = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

export function EditTeam({ teamId, onSave, onCancel }) {
  const [state, setState] = useState({ isLoading: true });

  useEffect(() => {
    dL.getQuery("Team")
      .include("tags")
      .get(teamId)
      .then(function (obj) {
        setState({ ...state, isLoading: false, model: dL.loadTeam(obj) });
      });
  }, []);

  const { isLoading, model } = state;
  if (isLoading) {
    return <Loading />;
  }

  //—average monthly budget size with the team (what would the be the average monthly budget sizes?)
  
  const { companyStage, tags, icon, name, businessSectors, description, shortDescription, avatar } = model;

  const buttons = [];

  buttons.push({
    label: "Save",
    onPress: () => {
      if (!name) {
        alert("Must enter team name.");
        return;
      }
      return dL.saveTeam(teamId, model).then(function () {
        onSave();
      });
    }
  });

  return (
    <ModalBasic title="Edit Team" onCancel={onCancel} buttons={buttons}>
      <ImageElement
        label="Avatar:"
        allowClear
        value={avatar}
        onChange={value => {
          model.avatar = value
          setState({ ...state, model });
        }}
      />

      <MyInput
        label="Team Name:"
        placeholder="Enter name"
        value={name}
        onChange={value => {
          model.name = value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        multiple
        maxItems={3}
        label="Business Sector:"
        value={businessSectors ? serviceTypes.filter(item => businessSectors.find(id => id == item.value)) : null}
        options={serviceTypes}
        description="Optionally, select a business sector for this team."
        onChange={item => {
          model.businessSectors = item.map(item => item.value)
          setState({ ...state, model });
        }}
      />

      <SelectBox
        required
        label="Work with businesses in the following stage:"
        value={companyStages2.find(item => item.value == companyStage)}
        options={companyStage}
        onChange={item => {
          model.companyStage = item.value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        maxLength={144}
        multiline
        label="Short team description:"
        description="Enter a short description about the focus on the team."
        value={shortDescription}
        onChange={value => {
          model.shortDescription = value;
          setState({ ...state, model });
        }}
      />

      <IconPicker
        label="Optionally, select an icon for this team:"
        value={icon}
        onChange={(value) => {
          model.icon = value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        style={{ height: 350 }}
        multiline
        label="About the team:"
        description="Enter a short description about the objective and mission of the team."
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <FormItem label="Add tags to search/describe team:" description="">
        <Tags value={tags} onChange={(tags) => {
          model.tags = tags;
          setState({ ...state, model });
        }} />
      </FormItem>
    </ModalBasic>
  );
}

function EditTeamRole({ team, onCancel, onSave, recordId }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model } = state

  useEffect(() => {
    if (recordId) {
      dL.getQuery("TeamRole")
        .include("businessRole")
        .get(recordId).then(function (obj) {
          const role = dL.loadTeamRole(obj)
          setState({ ...state, isLoading: false, model: role })
        })
    } else {
      setState({ ...state, isLoading: false, model: { team } })
    }
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { businessRole, skillLevel, staffType, description } = model

  return (
    <ModalBasic title={recordId ? "Edit Team Role:" : "Add Team Role:"} onCancel={onCancel} okText="Save" onOk={() => {
      if (!businessRole) {
        alert("Must select business role.");
        return;
      }
      if (!skillLevel) {
        alert("Must select skill level.");
        return;
      }

      return dL.saveTeamRole(recordId, model).then(function (id) {
        model.id = id
        onSave(model);
      });
    }}>
      <FormItem label="Select a business role:" description="Select a business role that you would like to add to this team.">
        <SelectBusinessRole
          allowCreate
          onChange={item => {
            model.businessRole = item;
            setState({ ...state, model });
          }}
          value={businessRole}
          required
        />
      </FormItem>

      <SelectBox
        required
        label="What type of role?"
        value={staffTypes.find(item => item.value == staffType)}
        options={staffTypes}
        onChange={item => {
          model.staffType = item.value;
          setState({ ...state, model });
        }}
      />

      <SelectBox
        required
        label="What is the skill level required for this role?"
        value={skillLevels.find(item => item.value == skillLevel)}
        options={skillLevels}
        onChange={item => {
          model.skillLevel = item.value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        style={{ height: 250 }}
        label="More about this role:"
        description="Add role responsibilities or a longer description."
        value={description}
        onChange={value => {
          model.description = value
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

function EditTeamRolePosition({ recordId, teamRole: defaultTeamRole, team, onCancel, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model, teamRoles } = state

  useEffect(() => {
    var model, teamRoles
    dL.getQuery("TeamRole")
      .equalTo("team", db.getObj("Team", team.id))
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .find().then(function (objs) {
        teamRoles = dL.loadObjects("TeamRole", objs)

        if (recordId) {
          return dL.getQuery("TeamRolePosition")
            .include("businessRole")
            .include("teamRole")
            .include("teamRole.businessRole")
            .get(recordId)
            .then(function (obj) {
              model = dL.loadTeamRolePosition(obj)
            })
        }
      }).then(function () {
        setState({
          ...state,
          isLoading: false,
          teamRoles: teamRoles,
          model: model ? model : {
            team,
            teamRole: defaultTeamRole
          }
        })
      })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { description, teamRole, hoursPerMonth, country, countryRegion, locationCity, remoteOnly } = model

  const selectCountry = function (val) {
    model.country = val;
    setState({ ...state, model });
  };

  const selectRegion = function (val) {
    model.countryRegion = val;
    setState({ ...state, model });
  };

  return (
    <ModalBasic title="Team Role Position:" onCancel={onCancel} okText="Save" onOk={() => {
      if (!teamRole) {
        alert("Must select team role.");
        return;
      }

      model.status = "open"
      return dL.saveTeamRolePosition(recordId, model).then(function (id) {
        model.id = id
        onSave(model);
      });
    }}>
      <ScriptTag
        src="//maps.googleapis.com/maps/api/js?key=AIzaSyCuHtEOEHrsv6Kshy0LyodwjOJ81KhhNJ0&libraries=places"
        onLoad={() => {
          setState({ ...state, isLoaded2: true });
        }}
      />

      <SelectBox
        required
        label="Select a business role that you would like to apply for?"
        value={teamRole}
        options={teamRoles}
        onGetText={(item) => {
          return item.businessRole.name
        }}
        valueField="id"
        onChange={item => {
          model.teamRole = item
          setState({ ...state, model });
        }}
      />

      <SelectBox
        required
        label="How much time do you need this person per month?"
        value={hoursPerMonth ? hoursPerMonthItems.find(item => item.value == hoursPerMonth) : null}
        options={hoursPerMonthItems}
        onChange={item => {
          model.hoursPerMonth = item.value;
          setState({ ...state, model });
        }}
      />

      <Toggle
        label="Remote Only"
        description="Only take on remote roles."
        value={remoteOnly}
        onChange={value => {
          model.remoteOnly = value;
          setState({ ...state, model });
        }}
      />

      {!remoteOnly ? <React.Fragment>
        <FormItem label="Location (City):">
          {state.isLoaded2 ? (
            <LocationSearchInput
              defaultValue={locationCity}
              onChange={value => {
                model.locationCity = value;
                setState({ ...state, model });
              }}
            />
          ) : null}
        </FormItem>

        <FormItem label="Location (Region):">
          <View>
            <CountryDropdown style={{ borderWidth: 2, borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7 }} value={country} onChange={val => selectCountry(val)} />
            <RegionDropdown style={{ marginTop: 15, borderWidth: 2, borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7 }} country={country} value={countryRegion} onChange={val => selectRegion(val)} />
          </View>
        </FormItem>
      </React.Fragment> : null}

      <MyInput
        multiline
        style={{ height: 250 }}
        label="More about this opening:"
        description="Add additional information about this job role opening."
        value={description}
        onChange={value => {
          model.description = value
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

function AddTeamMember({ teamRoleRequest, team, onCancel, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model, teamRolesList } = state

  useEffect(() => {
    var teamRoles
    dL.getQuery("TeamRole")
      .equalTo("team", db.getObj("Team", team.id))
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .find().then(function (objs) {
        teamRoles = dL.loadObjects("TeamRole", objs)

        const { teamRole, teamRolePosition, createdBy } = teamRoleRequest

        setState({
          ...state,
          isLoading: false,
          teamRolesList: teamRoles,
          model: {
            teamRolePosition,
            teamRoleRequest,
            user: createdBy,
            team,
            teamRoles: [teamRole]
          }
        })
      })
  }, [])

  if (isLoading) {
    return <View />
  }

  const { displayInPublicProfile, teamRoles, isLeadership } = model

  return (
    <ModalBasic title="Add Team Member:" onCancel={onCancel} okText="Add Member" onOk={() => {
      if (teamRoles.length == 0) {
        alert("Must select at least one team role.");
        return;
      }

      return dL.getQuery("Member")
        .equalTo("user", db.getObj("User", model.user.id))
        .equalTo("team", db.getObj("Team", team.id))
        .containedIn("removed", [undefined, false])
        .first()
        .then(function (obj) {
          if (obj) {
            alert("User is already part of the team.")
            return
          }
          return dL.newTeamMember(model).then(function (id) {
            model.id = id
            onSave(model);
          });
        })
    }}>

      <SelectBox
        required
        multiple
        label="Team Roles:"
        description="Select the team roles that this team member will fulfill for the team."
        value={teamRoles ? teamRolesList.filter(item => teamRoles.find(item2 => item2.id == item.id)) : null}
        options={teamRolesList}
        onGetText={(item) => {
          const { businessRole, skillLevel } = item
          return businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"
        }}
        valueField="id"
        onChange={item => {
          model.teamRoles = item
          setState({ ...state, model });
        }}
      />

      <Toggle
        label="Display in public team profile:"
        description="Mark this team member display on the public team profile."
        value={displayInPublicProfile}
        onChange={value => {
          model.displayInPublicProfile = value;
          setState({ ...state, model });
        }}
      />

      <Toggle
        label="Team Leadership:"
        description="Mark this team member as part of team leadership."
        value={isLeadership}
        onChange={value => {
          model.isLeadership = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

function EditTeamMember({ teamRoles: defaultTeamRoles, team, recordId, onCancel, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model, teamRolesList } = state

  useEffect(() => {
    var model, teamRoles
    dL.getQuery("TeamRole")
      .equalTo("team", db.getObj("Team", team.id))
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .find().then(function (objs) {
        teamRoles = dL.loadObjects("TeamRole", objs)

        if (recordId) {
          return dL.getQuery("Member")
            .include("businessRoles")
            .include("teamRoles")
            .include("user")
            .get(recordId)
            .then(function (obj) {
              model = dL.loadMember(obj)
            })
        }
      }).then(function () {
        setState({
          ...state,
          isLoading: false,
          teamRolesList: teamRoles,
          model: model ? model : {
            teamRoles: defaultTeamRoles
          }
        })
      })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { displayInPublicProfile, teamRoles, isLeadership } = model

  return (
    <ModalBasic title="Edit Team Member:" onCancel={onCancel} okText="Save" onOk={() => {
      if (teamRoles.length == 0) {
        alert("Must select at least one team role.");
        return;
      }

      return dL.saveTeamMember(recordId, model).then(function (id) {
        model.id = id
        onSave(model);
      });
    }}>

      <SelectBox
        required
        multiple
        label="Team Roles:"
        description="Select the team roles that this team member will fulfill for the team."
        value={teamRoles ? teamRolesList.filter(item => teamRoles.find(item2 => item2.id == item.id)) : null}
        options={teamRolesList}
        onGetText={(item) => {
          const { businessRole, skillLevel } = item
          return businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"
        }}
        valueField="id"
        onChange={item => {
          model.teamRoles = item
          setState({ ...state, model });
        }}
      />

      <Toggle
        label="Display in public team profile:"
        description="Mark this team member display on the public team profile."
        value={displayInPublicProfile}
        onChange={value => {
          model.displayInPublicProfile = value;
          setState({ ...state, model });
        }}
      />

      <Toggle
        label="Team Leadership:"
        description="Mark this team member as part of team leadership."
        value={isLeadership}
        onChange={value => {
          model.isLeadership = value;
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

function EditTeamRoleRequest({ teamRolePosition: defaultTeamRolePosition, team, onCancel, onSave }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model, teamRolePositions } = state

  useEffect(() => {
    dL.getQuery("TeamRolePosition")
      .equalTo("team", db.getObj("Team", team.id))
      .equalTo("status", "open")
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .include("teamRole")
      .find().then(function (objs) {
        const roles = dL.loadObjects("TeamRolePosition", objs)
        setState({
          ...state,
          isLoading: false,
          teamRolePositions: roles,
          model: {
            team,
            teamRolePosition: defaultTeamRolePosition
          }
        })
      })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { teamRolePosition, description, skillLevel, hoursPerMonth } = model

  return (
    <ModalBasic title="Team Member Application:" onCancel={onCancel} okText="Apply" onOk={() => {
      if (!description) {
        alert("Enter a short description of why you want to join the team");
        return;
      }

      return dL.newTeamRoleRequest(model).then(function () {
        onSave();
      });
    }}>
      <SelectBox
        required
        label="Select a team role that you would like to apply for?"
        value={teamRolePosition}
        options={teamRolePositions}
        onGetText={(item) => {
          const { businessRole } = item
          return businessRole.name
        }}
        onChange={item => {
          model.teamRolePosition = item
          setState({ ...state, model });
        }}
      />
      <SelectBox
        required
        label="What is your skill level in this role?"
        value={utils.skillLevels.find(item => item.value == skillLevel)}
        options={utils.skillLevels}
        onChange={item => {
          model.skillLevel = item.value;
          setState({ ...state, model });
        }}
      />
      <SelectBox
        required
        label="How much time are you available for this role per month?"
        value={hoursPerMonth ? hoursPerMonthItems.find(item => item.value == hoursPerMonth) : null}
        options={hoursPerMonthItems}
        onChange={item => {
          model.hoursPerMonth = item.value;
          setState({ ...state, model });
        }}
      />

      <MyInput
        multiline
        style={{ marginBottom: 0, height: 250 }}
        label="Share a little about why you want to join th?"
        value={description}
        onChange={value => {
          model.description = value
          setState({ ...state, model });
        }}
      />
    </ModalBasic>
  );
}

export function ViewTeamRolePosition() {
  const { sectionId, teamId, positionId } = useParams()
  const [state, setState] = useState({ tabValue: "details", isLoading: true });
  const { isViewTeamRoleRequest, editTeamMemberVisible, defaultTeamRoles, tabValue, teamRoleRequests, isLoading, model } = state
  const [userId, setUserId] = useState();

  useEffect(() => {
    dL.getQuery("TeamRolePosition")
      .include("businessRole")
      .include("teamRole")
      .get(positionId)
      .then(function (obj) {
        const request = dL.loadTeamRolePosition(obj)
        return dL.getQuery("TeamRoleRequest")
          .equalTo("team", db.getObj("Team", teamId))
          .equalTo("teamRolePosition", db.getObj("TeamRolePosition", positionId))
          .containedIn("removed", [undefined, false])
          .include("teamRolePosition")
          .include("teamRole")
          .include("businessRole")
          .include("createdBy")
          .find().then(function (objs) {
            const teamRoleRequests = dL.loadObjects("TeamRoleRequest", objs)
            setState({ ...state, isLoading: false, model: request, teamRoleRequests })
          })
      })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { description, teamRole, businessRole, hoursPerMonth, country, countryRegion, locationCity, remoteOnly } = model
  const { description: teamRoleDescription, skillLevel } = teamRole
  const { name } = businessRole

  const list = teamRoleRequests
  const newList = list.filter(item => item.status == "new")
  const applyList = list.filter(item => item.status == "open" || item.status == "new")
  const shortedList = list.filter(item => item.shortlisted && item.status != "archived")
  const archiveList = list.filter(item => item.status == "archived")

  const tabs = [
    { label: "Overview", value: "details" },
  ]

  if (newList.length > 0) {
    tabs.push({ label: "New Applicants (" + newList.length + ")", value: "new" })
  }

  if (shortedList.length > 0) {
    tabs.push({ label: "Shortlisted (" + shortedList.length + ")", value: "shortlisted" })
  }

  tabs.push({ label: "Applicants (" + applyList.length + ")", value: "applied" })

  if (archiveList.length > 0) {
    tabs.push({ label: "Archived (" + archiveList.length + ")", value: "archived" })
  }

  return (
    <View>
      <BasicTop title={name} />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 15 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ?
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Role Overview:" />

            <FlexRow>
              <TextLine label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} spacer />
              {hoursPerMonth ? <TextLine label="Availability:" value={plural(hoursPerMonth, "hr/month")} grey spacer /> : null}
            </FlexRow>

            <FlexRow>
              {remoteOnly ? <TextLine label="Location:" value={"Remote"} grey spacer /> : null}
              {locationCity ? <TextLine label="Location:" value={locationCity} grey spacer /> : null}
              {country ? <TextLine label="Country:" value={country} grey /> : null}
              {countryRegion ? <TextLine value={countryRegion} grey /> : null}
            </FlexRow>
          </Section>

          <Section>
            <HeaderText subHeader label="About the role:" />
            {teamRoleDescription ? renderHTML(teamRoleDescription.replace(/\n/g, "<br>")) : <NoRecords label="No role description found." />}
          </Section>

          <Section>
            <HeaderText subHeader label="More about the opening:" />
            {description ? renderHTML(description.replace(/\n/g, "<br>")) : <NoRecords label="No opening description found." />}
          </Section>
        </React.Fragment> : null}

      {tabValue == "new" || tabValue == "applied" || tabValue == "archived" || tabValue == "shortlisted" ? <View>

        {(tabValue == "new" ? newList : tabValue == "applied" ? applyList : tabValue == "archived" ? archiveList : tabValue == "shortlisted" ? shortedList : null).map(teamRoleRequest => {
          const item = teamRoleRequest
          const { shortlisted, team, id, teamRole, status, createdAt, createdBy, teamRolePosition, message } = teamRoleRequest
          const { skillLevel } = teamRolePosition

          const buttons = []
          if (status == "new") {
            buttons.push({
              label: "Mark Read", onPress: () => {
                item.status = "open"
                dL.getObj("TeamRoleRequest", id).set("status", item.status).save()
                setState({ ...state })
              }
            })
          } else if (status == "open") {
            buttons.push({
              label: "Mark New", onPress: () => {
                item.status = "new"
                dL.getObj("TeamRoleRequest", id).set("status", item.status).save()
                setState({ ...state })
              }
            })
          }
          if (status == "archived") {
            buttons.push({
              label: "Un-Archive", onPress: () => {
                item.status = "open"
                dL.getObj("TeamRoleRequest", id).set("status", item.status).save()
                setState({ ...state })
              }
            })

          } else {
            buttons.push({
              label: "Archive", onPress: () => {
                item.status = "archived"
                item.shortlisted = false
                dL.getObj("TeamRoleRequest", id).set("shortlisted", item.shortlisted).set("status", item.status).save()
                setState({ ...state })
              }
            })
          }

          if (shortlisted) {
            buttons.push({
              label: "Remove Shortlist", onPress: () => {
                item.shortlisted = false
                dL.getObj("TeamRoleRequest", id).set("shortlisted", item.shortlisted).save()
                setState({ ...state })
              }
            })
          } else {
            buttons.push({
              label: "Shortlist", onPress: () => {
                item.shortlisted = true
                item.status = "open"
                dL.getObj("TeamRoleRequest", id).set("status", item.status).set("shortlisted", item.shortlisted).save()
                setState({ ...state })
              }
            })
          }
          buttons.push({
            label: "Add to Team", onPress: () => {
              setState({ ...state, editTeamMemberVisible: teamRoleRequest, defaultTeamRoles: [] })
            }
          })

          return <FlexRow key={id} alignTop>
            <View>
              <RenderUserItem item={createdBy} />
            </View>

            <FlexExpand>
              <BoxRowItem style={{ marginTop: 0, marginBottom: 0 }}>
                <FlexRow bottom={15}>
                  <FlexExpand>
                    <FlexRow>
                      <TextLine value={Moment(createdAt).format("M/D/YYYY") + " (" + Moment(createdAt).fromNow() + ")"} spacer />

                      <TextLine label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} spacer />
                      {hoursPerMonth ? <TextLine label="Availability:" value={plural(hoursPerMonth, "hr per month")} grey spacer /> : null}

                      {status == "new" ? <TextLine color="red" value="NEW" /> : null}
                    </FlexRow>
                  </FlexExpand>

                  <RenderButtons buttons={buttons} buttonText={"Actions"} renderType="single" />
                </FlexRow>

                <LabelItem label="Message from Applicant:">
                  {message ? renderHTML(message.replace(/\n/g, "<br>")) : <NoRecords label="No message added." />}
                </LabelItem>
              </BoxRowItem>

              <FlexRow top={15}>
                <TouchButton label="View Profile" micro onPress={() => {
                  setUserId(createdBy.id);
                }} />
                <View style={{ width: 15 }} />
                <TouchButton label="View Application" micro onPress={() => {
                  setState({ ...state, isViewTeamRoleRequest: id })
                }} />
              </FlexRow>
            </FlexExpand>

            {userId ? (
              <ModalBasic
                full
                notCentered
                onCancel={() => {
                  setUserId();
                }}>
                <UserProfileWorker userId={userId} />
              </ModalBasic>
            ) : null}

            {isViewTeamRoleRequest ? (
              <ViewTeamRoleRequest
                recordId={isViewTeamRoleRequest}
                onCancel={() => {
                  setState({ ...state, isViewTeamRoleRequest: false });
                }}
                onSave={() => {
                  setState({ ...state, isViewTeamRoleRequest: false });
                }}
              />
            ) : null}
            {editTeamMemberVisible ? (
              <AddTeamMember
                teamRoleRequest={editTeamMemberVisible}
                team={team}
                onCancel={() => {
                  setState({ ...state, editTeamMemberVisible: null });
                }}
                onSave={(editItem) => {
                  setState({ ...state, editTeamMemberVisible: null });
                }}
              />
            ) : null}

          </FlexRow>
        })}
      </View> : null}

    </View>
  );
}

function ViewTeamRolePositionPublic({ recordId, onCancel }) {
  const [state, setState] = useState({ isLoading: true });
  const { applyVisible, isLoading, model, request } = state

  useEffect(() => {
    dL.getQuery("TeamRolePosition")
      .include("businessRole")
      .include("teamRole")
      .get(recordId)
      .then(function (obj) {
        const position = dL.loadTeamRolePosition(obj)

        return dL.getQuery("TeamRoleRequest")
          .equalTo("teamRolePosition", db.getObj("TeamRolePosition", request.id))
          .equalTo("user", db.getObj("User", session.user.id))
          .containedIn("removed", [undefined, false])
          .first()
          .then(function (obj) {
            const request = dL.loadTeamRoleRequest(obj)
            setState({ ...state, isLoading: false, model: position, request })
          })
      })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { description, teamRole, businessRole, hoursPerMonth, country, countryRegion, locationCity, remoteOnly } = model
  const { description: teamRoleDescription, skillLevel } = teamRole
  const { name } = businessRole

  return (
    <ModalBasic title="Team Role Opening:" onCancel={onCancel} okText={request ? "Already Applied" : "Apply"} onOk={() => {
      if (request) { return }
      setState({ ...state, applyVisible: true })
    }}>
      <Section>
        <HeaderText subHeader label={name} />

        <FlexRow>
          <TextLine label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} spacer />
          {hoursPerMonth ? <TextLine label="Availability:" value={plural(hoursPerMonth, "hr/month")} grey spacer /> : null}
        </FlexRow>

        <FlexRow>
          {remoteOnly ? <TextLine label="Location:" value={"Remote"} grey spacer /> : null}
          {locationCity ? <TextLine label="Location:" value={locationCity} grey spacer /> : null}
          {country ? <TextLine label="Country:" value={country} grey /> : null}
          {countryRegion ? <TextLine value={countryRegion} grey /> : null}
        </FlexRow>
      </Section>

      <Section>
        <HeaderText subHeader label="About the role:" />
        {teamRoleDescription ? renderHTML(teamRoleDescription.replace(/\n/g, "<br>")) : <NoRecords label="No role description found." />}
      </Section>

      <Section>
        <HeaderText subHeader label="More about the opening:" />
        {description ? renderHTML(description.replace(/\n/g, "<br>")) : <NoRecords label="No opening description found." />}
      </Section>

      {applyVisible ? (
        <EditTeamRoleRequest
          team={model.team}
          teamRolePosition={model}
          onCancel={() => {
            setState({ ...state, applyVisible: false });
          }}
          onSave={() => {
            setState({ ...state, applyVisible: false });
          }}
        />
      ) : null}
    </ModalBasic>
  );
}

function ViewTeamRoleRequest({ recordId, onCancel, onOk }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model } = state

  useEffect(() => {
    dL.getQuery("TeamRoleRequest")
      .include("businessRole")
      .get(recordId)
      .then(function (obj) {
        const request = dL.loadTeamRoleRequest(obj)
        setState({ ...state, isLoading: false, model: request })
      })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { id, createdBy, description, businessRole, skillLevel, hoursPerMonth } = model

  const chatRoomName = "Team Member Application: " + businessRole.name
  const chatRooms = [{ roomName: chatRoomName, roomId: id + "-g", users: [createdBy, session.user] }];

  return (
    <ModalBasic full notCentered title="Team Member Application:" cancelText="Close" onCancel={onCancel} okText="Save" onOk={onOk}>
      <ChatWindow
        chatRooms={chatRooms}>
        <FlexRow>
          <LabelItem
            label="Business role applied for:"
            value={businessRole.name}
          />

          <LabelItem
            label="Skill Level:"
            value={getLabelItem(skillLevels, skillLevel)}
          />

          <LabelItem
            label="Availablity:"
            value={getLabelItem(hoursPerMonthItems, hoursPerMonth)}
          />
        </FlexRow>

        <LabelItem
          label="Why worker wants to join team?"
          value={description}
        />
      </ChatWindow>
    </ModalBasic>
  );
}

const getIsAdvisor = function (item) {
  const { teamRoles } = item
  var isAdvisor = false
  teamRoles.forEach(item => {
    if (item.staffType == "advisor") {
      isAdvisor = true
    }
  })
  return isAdvisor
}

export function TeamPublic() {
  const history = useHistory();
  const { teamId, sectionId } = useParams()
  const [state, setState] = useState({ isLoading: true });
  const { teamRoles, viewTeamRolePosition, applyVisible, isLoading, team, openBusinessRoles, teamMembers, teamRolePositions } = state

  useEffect(() => {
    dL.getQuery("Team")
      .include("teamLeader")
      .get(teamId)
      .then(function (obj) {
        const team = dL.loadTeam(obj)

        return dL.getQuery("TeamRole")
          .equalTo("team", db.getObj("Team", teamId))
          .containedIn("removed", [undefined, false])
          .include("businessRole")
          .find()
          .then(function (objs) {
            const teamRoles = dL.loadObjects("TeamRole", objs)

            return dL.getQuery("Member")
              .equalTo("team", db.getObj("Team", teamId))
              .equalTo("status", "active")
              .containedIn("removed", [undefined, false])
              .include("user")
              .include("businessRoles")
              .include("teamRoles")
              .find()
              .then(function (objs) {
                const teamMembers = dL.loadObjects("Member", objs)

                return dL.getQuery("UserRole")
                  .equalTo("user", teamMembers.filter(item => item.user).map(item => dL.getObj("User", item.user.id)))
                  .containedIn("removed", [undefined, false])
                  .include("businessRole")
                  .select(["businessRole"])
                  .find()
                  .then(function (objs) {
                    const userRoles = dL.loadObjects("UserRole", objs)
                    const roleIds = {}
                    userRoles.forEach(item => {
                      const { businessRole } = item
                      if (!roleIds[businessRole.id]) {
                        roleIds[businessRole.id] = {
                          businessRole, count: 0
                        }
                      }
                      roleIds[businessRole.id].count++
                    })
                    var businessRoles = []
                    for (var key in roleIds) {
                      const item = roleIds[key]
                      businessRoles.push(item)
                    }
                    businessRoles.sort(function (a, b) {
                      return b.count - a.count
                    });

                    return dL.getQuery("TeamRolePosition")
                      .equalTo("team", db.getObj("Team", teamId))
                      .equalTo("status", "open")
                      .containedIn("removed", [undefined, false])
                      .include("teamRole")
                      .include("businessRole")
                      .find().then(function (objs) {
                        const teamRolePositions = dL.loadObjects("TeamRolePosition", objs)
                        setState({ ...state, isLoading: false, team, teamRolePositions, teamRoles, teamMembers })
                      })
                  })
              })
          })
      })
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const teamMembersUsers = teamMembers.filter(item => item.user)
  const advisorMembers = teamMembersUsers.filter(item => {
    return getIsAdvisor(item)
  })

  const staffMembers = teamMembersUsers.filter(item => {
    const { teamRoles } = item
    var isStaff = false
    teamRoles.forEach(item => {
      if (item.staffType != "advisor") {
        isStaff = true
      }
    })
    return isStaff
  })

  const leadershipMembers = teamMembersUsers.filter(item => {
    const { isLeadership } = item
    return isLeadership
  })

  //team name
  //team leader
  //number of team members
  //business roles in team
  //team roles open requested (for application)
  //public team profile
  //stats: number of proposal submitted, number of service order fulfilled, number of team services, number of clients worked with
  //team rating, team bonus pool rating
  //team services that are provided: visible to buyer public, visible to internal worker marketplace


  //show the team content: as listing of articles and events
  //show the team services: as listing of services

  const { icon, avatar, teamLeader, name, description, shortDescription, businessSectors } = team

  const renderMembers = function ({ list, label, description }) {
    return list.length > 0 ? (
      <Section>
        <HeaderText subHeader label={label} description={description} />
        <FlexRow style={{ flexWrap: "wrap" }}>
          {list.map((item) => {
            const { user } = item;
            return (
              <RenderUser
                style={{ position: "relative" }}
                onPress={() => {
                  history.push(`/${sectionId}/team/${teamId}/user/${user.id}`);
                  setState({ ...state });
                }}
                item={user}>
              </RenderUser>
            );
          })}
        </FlexRow>
      </Section>
    ) : null
  }

  const maxRolesDisplay = 5

  return <FlexRow alignTop>
    <FlexExpand>
      <FlexRow>
        {icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} /> : null}
        {avatar ? <Avatar value={avatar} style={{ marginRight: 25 }} /> : null}
        <View>
          <TextLine value={name} size={24} bold />
          <TextLine size={12} top={-4} value={getLabelItems(serviceTypes, businessSectors)} />
          <TextLine size={14} top={4} value={shortDescription} grey />
        </View>
      </FlexRow>

      <Section style={{ marginTop: 35 }}>
        <HeaderText subHeader label="About the Team:" />
        {description ? renderHTML(description.replace(/\n/g, "<br>")) : <NoRecords label="No description added." />}
      </Section>

      {renderMembers({ list: leadershipMembers, label: "Leadership Team:", description: "Meet the team leadership." })}
      {renderMembers({ list: advisorMembers, label: "Advisory Team:", description: "Meet some of the advisors on this team." })}
      {renderMembers({ list: staffMembers, label: "Other Team Members:", description: "Meet some of the people on the team." })}
    </FlexExpand>

    <View style={{ minWidth: 350 }}>
      <Section>
        <HeaderText subHeader label="Team Stats:" />
        <UserItem label="Team Leader:" user={teamLeader} commentIcon />
        <TextLine top={25} label="Team Members:" value={teamMembers.length} />
        <TextLine top={4} label="Team Rating:" value={"4.8 stars"} />
        <TextLine top={4} label="Bonus Pool:" value={"Hyper-Growth Level"} />
      </Section>

      <Section>
        <TextLine label="Submitted Proposals:" value={999} />
        <TextLine top={4} label="Orders Completed:" value={999} />
        <TextLine top={4} label="Team Services:" value={999} />
        <TextLine top={4} label="Clients:" value={999} />
      </Section>

      <Section>
        <LabelItem label="Business roles in the team:">
          {teamRoles.length > 0 ? <View>
            {teamRoles.slice(0, maxRolesDisplay).map(item => {
              const { businessRole, staffType } = item
              const { name } = businessRole
              return <TextLine value={name + (staffType != "staff" ? " (" + getLabelItem(staffTypes, staffType) + ")" : "")} />
            })}
          </View> : null}

          <TextLine size={13}>{teamRoles.length - maxRolesDisplay > 0 ? "plus " + plural(teamRoles.length - maxRolesDisplay, "other") : ""}</TextLine>
        </LabelItem>
      </Section>

      {teamRolePositions.length > 0 ?
        <Section>
          <LabelItem label="Open Team Roles:" style={{ marginTop: 35 }} >
            {teamRolePositions.map(teamRolePosition => {
              const { id, hoursPerMonth, businessRole, remoteOnly, locationCity, country, countryRegion, teamRole } = teamRolePosition
              const { skillLevel } = teamRole
              const { name } = businessRole

              return <BoxRowItem onPress={() => {
                setState({ ...state, viewTeamRolePosition: id })
              }}>
                <TextLine value={name} />

                <FlexRow>
                  <TextLine size={12} value={getLabelItem(skillLevels, skillLevel)} />
                  {hoursPerMonth ? <TextLine size={12} value={plural(hoursPerMonth, "hr/month")} grey /> : null}
                </FlexRow>

                <FlexRow>
                  {remoteOnly ? <TextLine size={12} value={"Remote"} grey /> : null}
                  {locationCity ? <TextLine size={12} value={locationCity} grey /> : null}
                  {country ? <TextLine size={12} value={country} grey /> : null}
                  {countryRegion ? <TextLine size={12} value={countryRegion} grey /> : null}
                </FlexRow>
              </BoxRowItem>
            })}

            <TouchButton micro label="Apply for a role" style={{ marginTop: 15 }} onPress={() => {
              setState({ ...state, applyVisible: true })
            }} />
          </LabelItem>
        </Section> : null}
    </View>

    {viewTeamRolePosition ? (
      <ViewTeamRolePositionPublic
        recordId={viewTeamRolePosition}
        onCancel={() => {
          setState({ ...state, viewTeamRolePosition: null });
        }}
      />
    ) : null}

    {applyVisible ? (
      <EditTeamRoleRequest
        team={team}
        onCancel={() => {
          setState({ ...state, applyVisible: false });
        }}
        onSave={() => {
          setState({ ...state, applyVisible: false });
        }}
      />
    ) : null}

    <Hide>
      Stats:
      Team Leader: John Smith
      Team Members: 25 members

      Number of Proposal Submitted: 25
      Number of Service Orders Fulfilled: 10
      Number of Team Services: 25
      Number of clients: 25

      Business roles in team:
      Digital Marketer, ABC, ZYD, plus 25 others

      Open Business Roles:
      Digital Marketer (Expert) (City)
      AYX Marketer (Junior) (Remote)

      Team Rating: 5 stars (for all)
    </Hide>
  </FlexRow>
}

//Team Leader View
//action items:
//-number of new applications to review
//-number of new team service request (assigned to team)
//-number of new proposals received for team issued service requests

//Team Worker View:
export function TeamAdmin() {
  const { sectionId, eid, teamId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ tabValueSR: "open", tabValue: "details", isLoading: true });
  const { services, teamRoleRequests, editTeamMemberVisible, isNew, editItem, editIndex, teamRolePositions, members, member, newTeamMemberVisible, isLoading, team, tabValue, teamRoles } = state;
  const [projectVisible, setProjectVisible] = useState();
  const [refresh, setRefresh] = useState();
  const { tabValueSR } = state
  const [userId, setUserId] = useState();
  const serviceRef = useRef()

  useEffect(() => {
    var promise = new PromiseA();
    if (eid) {
      promise = db
        .getQuery("Member")
        .equalTo("_id", eid)
        .containedIn("removed", [undefined, false])
        .select([])
        .first()
        .then(function (obj) {
          if (obj) {
            return obj
              .set("status", "active")
              .set("user", db.getObj("User", session.user.id))
              .save();
          } else {
            alert("Team invitation not found.");
          }
        });
    } else {
      promise.resolve();
    }

    promise
      .then(function () {
        dL.getQuery("Team")
          .include("tags")
          .include("teamLeader")
          .get(teamId)
          .then(function (obj) {
            const team = dL.loadTeam(obj);
            const newState = { ...state };
            const promises = [];

            promises[promises.length] = dL.getQuery("Service")
              .equalTo("team", db.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .find().then(function (objs) {
                const items = dL.loadObjects("Service", objs)
                newState.services = items
              })

            promises[promises.length] = dL.getQuery("TeamRole")
              .equalTo("team", db.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .include("businessRole")
              .find().then(function (objs) {
                const items = dL.loadObjects("TeamRole", objs)
                newState.teamRoles = items
              })

            promises[promises.length] = dL.getQuery("TeamRolePosition")
              .equalTo("team", db.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .include("teamRole")
              .include("teamRole.businessRole")
              .find().then(function (objs) {
                const items = dL.loadObjects("TeamRolePosition", objs)
                newState.teamRolePositions = items
              })

            promises[promises.length] = dL.getQuery("TeamRoleRequest")
              .equalTo("team", db.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .include("teamRole")
              .include("teamRolePosition")
              .include("businessRole")
              .include("createdBy")
              .find().then(function (objs) {
                const items = dL.loadObjects("TeamRoleRequest", objs)
                newState.teamRoleRequests = items
              })

            promises[promises.length] = dL
              .getQuery("Member")
              .equalTo("team", dL.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .include("user")
              .include("teamRoles")
              .include("businessRoles")
              .find()
              .then(function (objs) {
                const members = dL.loadObjects("Member", objs);
                newState.members = members;
                newState.memberCount = members.length;
              });

            promises[promises.length] = dL
              .getQuery("ServiceProposal")
              .equalTo("team", dL.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.proposalCount = count;
              });

            promises[promises.length] = dL
              .getQuery("ServiceRequest")
              .equalTo("team", dL.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.serviceRequestCount = count;
              });

            promises[promises.length] = dL
              .getQuery("Order")
              .equalTo("team", dL.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.orderCount = count;
              });

            promises[promises.length] = dL
              .getQuery("ServiceOrder")
              .equalTo("team", dL.getObj("Team", teamId))
              .containedIn("removed", [undefined, false])
              .count()
              .then(function (count) {
                newState.serviceOrderCount = count;
              });

            promises[promises.length] = dL
              .getQuery("Member")
              .equalTo("team", dL.getObj("Team", teamId))
              .equalTo("user", dL.getObj("User", session.user.id))
              .containedIn("removed", [undefined, false])
              .include("createdBy")
              .first()
              .then(function (obj) {
                if (obj) {
                  newState.member = dL.loadMember(obj);
                }
              });

            return Promise.all(promises).then(function () {
              newState.isLoading = false;
              newState.team = team;
              setState(newState);
            });
          });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { tags, icon, teamLeader, name, businessSectors, shortDescription, description, avatar, isArchived } = team;
  const { addRolePositionVisible, addRoleVisible, proposalCount, memberCount, serviceOrderCount, orderCount, serviceRequestCount } = state;
  const { role } = member ? member : {};

  const buttons = [];
  if (role == "admin") {
    if (!isArchived) {
      buttons.push({
        label: "Edit",
        onPress: () => {
          setProjectVisible(true);
        }
      });
      buttons.push({
        label: "Delete",
        onPress: () => {
          dL.getObj("Team", teamId)
            .set("removed", true)
            .save()
            .then(function () {
              history.goBack();
            });
        }
      });
      buttons.push({
        label: "Archive",
        onPress: () => {
          dL.getObj("Team", teamId)
            .set("isArchived", true)
            .save()
            .then(function () {
              team.isArchived = true;
              setState({ ...state, team });
            });
        }
      });
    } else {
      buttons.push({
        label: "Un-Archive",
        onPress: () => {
          dL.getObj("Team", teamId)
            .set("isArchived", false)
            .save()
            .then(function () {
              team.isArchived = false;
              setState({ ...state, team });
            });
        }
      });
    }
  }

  const tabs = [];
  tabs.push({ label: "Details", value: "details" });
  tabs.push({ label: "Team Roles", value: "team-roles" });
  tabs.push({ label: "Team Members", value: "team" });
  //tabs.push({ label: "Orders", value: "orders" });
  //tabs.push({ label: "Service Orders", value: "service-orders" });
  //tabs.push({ label: "Proposals", value: "proposals" });
  //tabs.push({ label: "Service Requests", value: "service-requests" });
  tabs.push({ label: "Service Catalog", value: "services" });
  tabs.push({ label: "Recruitment", value: "recruitment" });
  //tabs.push({ label: "Bonus Pool", value: "bonus-pool" });
  //tabs.push({ label: "History", value: "history" });

  //tabs.push({ label: "Content", value: "content" });
  //can be content calendar or can be content articles
  //team page: this will show the services,

  var rolePositions
  if (tabValueSR == "open") {
    rolePositions = teamRolePositions.filter(item => item.status == "open")
  } else if (tabValueSR == "archived") {
    rolePositions = teamRolePositions.filter(item => item.status == "archived")
  }

  const checklistItems = [];
  checklistItems.push({
    label: "Complete basic team details.",
    value: "details",
    buttonText: "Edit Details",
    onButtonPress: () => {
      setProjectVisible(true);
    },
    isComplete: () => {
      if (teamLeader && name && businessSectors.length > 0 && shortDescription && description && avatar) {
        return true
      }
    }
  });
  checklistItems.push({
    label: "Add team roles.",
    description: "",
    value: "roles",
    buttonText: "Add Role",
    onButtonPress: () => {
      setState({ ...state, isNew: true, addRoleVisible: true, editItem: {} })
    },
    isComplete: () => {
      return teamRoles.length > 0
    }
  });
  checklistItems.push({
    label: "Invite team members.",
    description: "",
    value: "members",
    buttonText: "Invite Members",
    onButtonPress: () => {
      setState({ ...state, newTeamMemberVisible: true });
    },
    isComplete: () => {
      return members.length > 0
    }
  });
  checklistItems.push({
    label: "Add open roles.",
    description: "",
    value: "positions",
    buttonText: "Add Opening",
    onButtonPress: () => {
      setState({ ...state, isNew: true, addRolePositionVisible: true, editItem: {} })
    },
    isComplete: () => {
      return rolePositions.length > 0
    }
  });
  checklistItems.push({
    label: "Add team services.",
    description: "",
    value: "services",
    buttonText: "Add Service",
    onButtonPress: () => {
      serviceRef.current.addService()
    },
    isComplete: () => {
      return services.length > 0
    }
  });
  checklistItems.push({
    label: "Publish Team.",
    description: "Make this team public.",
    value: "publish",
    buttonText: "Publish Team",
    onButtonPress: () => {
      team.isPublic = true
      return dL.getObj("Team", team.id).set("isPublic", true).save()
    },
    isComplete: () => {
      return team.isPublic == true
    }
  });

  return (
    <View>
      <HeaderText avatar={<Avatar size={65} value={avatar} style={{ marginRight: 25 }} />} label={name} description={shortDescription} buttons={buttons} leftRender={
        icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} /> : null
      } />

      <FlexRow alignTop>
        <FlexExpand>
          <TabBar
            queryId="tb"
            style={{ marginBottom: 15 }}
            options={tabs}
            onChange={item => {
              setState({ ...state, tabValue: item.value });
            }}
            value={tabValue}
          />

          {tabValue == "recruitment" ?
            <Section>
              <HeaderText subHeader label="Open Team Roles:" description="Add team roles that you are looking for. Other workers can apply for your open staff roles." onButtonPress={() => {
                setState({ ...state, isNew: true, addRolePositionVisible: true, editItem: {} })
              }} />

              <TabBar
                queryId="tb2"
                style={{ marginBottom: 15 }}
                options={[{ label: "Open Positions", value: "open" }, { label: "Archived", value: "archived" }]}
                onChange={item => {
                  setState({ ...state, tabValueSR: item.value });
                }}
                value={tabValueSR}
              />

              <View bottom={15}>
                <FlexRow style={{ flexWrap: "wrap" }}>
                  {rolePositions.length == 0 ? (
                    <NoRecords label="No positions found." />
                  ) : (
                    rolePositions.map((item, index) => {
                      const { id, hoursPerMonth, teamRole, locationCity, remoteOnly, country, countryRegion } = item
                      const { businessRole } = teamRole
                      const { name, skillLevel } = businessRole

                      const list = teamRoleRequests.filter(item => item.teamRolePosition.id == id)
                      const newList = list.filter(item => item.status == "new")
                      const applyList = list.filter(item => item.status == "open")
                      const shortlisted = list.filter(item => item.shortlisted)

                      return (
                        <BoxRowItem onPress={() => {
                          history.push(`/${sectionId}/team/${team.id}/position/${id}`)
                        }} style={{ marginRight: 10, marginBottom: 10, minWidth: 450 }}>
                          <FlexRow alignTop>
                            <FlexExpand>
                              <TextLine size={18} bold value={name} />

                              <FlexRow>
                                <TextLine size={14} label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} spacer />
                                <TextLine size={14} label="Time:" value={plural(hoursPerMonth, "hr") + " per month"} spacer />
                              </FlexRow>

                              <FlexRow>
                                {remoteOnly ? <TextLine size={14} value="Remote Only" /> : <React.Fragment>
                                  <TextLine size={14} value={locationCity} spacer />
                                  <TextLine size={14} value={country} spacer />
                                  <TextLine size={14} value={countryRegion} spacer />
                                </React.Fragment>}
                              </FlexRow>

                              <FlexRow>
                                {newList.length > 0 ? <TextLine size={14} color="red" label="New:" value={newList.length} spacer /> : null}
                                <TextLine size={14} label="Applied:" value={applyList.length} spacer />
                                {shortlisted.length > 0 ? <TextLine size={14} label="Shortlisted:" value={shortlisted.length} /> : null}
                              </FlexRow>
                            </FlexExpand>


                            {tabValueSR == "open" ?
                              <React.Fragment>
                                <EditIcon
                                  style={{ marginLeft: 15 }}
                                  onPress={() => {
                                    setState({ ...state, addRolePositionVisible: true, isNew: false, editItem: Object.assign({}, item), editIndex: index });
                                  }}
                                />
                                <ArchiveIcon
                                  tooltip="Archive this role position."
                                  style={{ marginLeft: 15 }}
                                  onPress={() => {
                                    item.status = "archived"
                                    dL.getObj("TeamRolePosition", id).set("status", "archived").save()
                                    setState({ ...state, teamRoles });
                                  }}
                                />
                              </React.Fragment> : <React.Fragment>
                                <UnDeleteIcon
                                  tooltip="Un-Archive this role position."
                                  style={{ marginLeft: 15 }}
                                  onPress={() => {
                                    item.status = "open"
                                    dL.getObj("TeamRolePosition", id).set("status", "open").save()
                                    setState({ ...state, teamRoles });
                                  }}
                                />
                                <TrashIcon
                                  style={{ marginLeft: 15 }}
                                  tooltip="Permanently delete this role position."
                                  onPress={() => {
                                    teamRolePositions.splice(index, 1);
                                    dL.getObj("TeamRolePosition", id).set("removed", true).save()
                                    setState({ ...state, teamRoles });
                                  }}
                                />
                              </React.Fragment>}
                          </FlexRow>
                        </BoxRowItem>
                      );
                    })
                  )}
                </FlexRow>
              </View>

              {addRolePositionVisible ? (
                <EditTeamRolePosition
                  team={team}
                  recordId={editItem.id}
                  onCancel={() => {
                    setState({ ...state, addRolePositionVisible: false });
                  }}
                  onSave={(editItem) => {
                    if (isNew) {
                      teamRolePositions.push(editItem)
                    } else {
                      teamRolePositions[editIndex] = editItem
                    }
                    setState({ ...state, addRolePositionVisible: null, teamRolePositions });
                  }}
                />
              ) : null}
            </Section> : null}

          {tabValue == "team-roles" ? (
            <Section>
              <HeaderText subHeader label="Team Roles:" description="Manage the business roles that make up your team." onButtonPress={() => {
                setState({ ...state, isNew: true, addRoleVisible: true, editItem: {} })
              }} />
              <View bottom={15}>
                <FlexRow style={{ flexWrap: "wrap" }}>
                  {teamRoles.length == 0 ? (
                    <NoRecords label="No team roles added." />
                  ) : (
                    teamRoles.map((item, index) => {
                      const { id, businessRole, skillLevel, staffType } = item

                      return (
                        <BoxRowItem style={{ marginRight: 10, marginBottom: 10 }}>
                          <FlexRow alignTop >
                            <FlexExpand>
                              <TextLine size={18} bold value={businessRole.name} />
                              <FlexRow>
                                <TextLine size={14} value={getLabelItem(serviceTypes, businessRole.businessSector) + ": " + getLabelItem(staffTypes, staffType)} spacer />
                                <TextLine size={14} label="Skill Level:" value={getLabelItem(skillLevels, skillLevel)} />
                              </FlexRow>
                            </FlexExpand>

                            <EditIcon
                              style={{ marginLeft: 15 }}
                              onPress={() => {
                                setState({ ...state, addRoleVisible: true, isNew: false, editItem: Object.assign({}, item), editIndex: index });
                              }}
                            />
                            <TrashIcon
                              style={{ marginLeft: 15 }}
                              onPress={() => {
                                teamRoles.splice(index, 1);
                                dL.getObj("TeamRole", id).set("removed", true).save()
                                setState({ ...state, teamRoles });
                              }}
                            />
                          </FlexRow>
                        </BoxRowItem>
                      );
                    })
                  )}
                </FlexRow>
              </View>

              {addRoleVisible ? (
                <EditTeamRole
                  team={team}
                  recordId={editItem.id}
                  onCancel={() => {
                    setState({ ...state, addRoleVisible: null });
                  }}
                  onSave={(editItem) => {
                    if (isNew) {
                      teamRoles.push(editItem)
                    } else {
                      teamRoles[editIndex] = editItem
                    }
                    setState({ ...state, addRoleVisible: null });
                  }}
                />
              ) : null}
            </Section>
          ) : null}

          {tabValue == "details" ? (
            <React.Fragment>
              <Section>
                <HeaderText subHeader label="Overview:" />
                <FlexRow>
                  <LabelItem label="Business Sectors:" value={getLabelItems(serviceTypes, businessSectors)} />
                  <LabelUserItem label="Team Leader:" user={teamLeader} />
                  <LabelItem label="Team Members:" value={memberCount} />
                </FlexRow>

                <FlexRow>
                  {tags ? <LabelItem label="Tags:" style={{ maxWidth: 250 }} onValue={() => {
                    var str = ""
                    tags.forEach(item => {
                      if (str) { str += ", " }
                      str += item.name
                    })
                    return str ? str : null
                  }} /> : null}
                </FlexRow>

                <FlexRow>
                  <LabelItem label="Orders:" value={orderCount} />
                  <LabelItem label="Service Orders:" value={serviceOrderCount} />
                  <LabelItem label="Service Requests:" value={serviceRequestCount} />
                  <LabelItem label="Proposals:" value={proposalCount} />
                </FlexRow>
              </Section>

              {description ? <Section>
                <HeaderText subHeader label="About the Team:" />
                <LabelItem value={description} />
              </Section> : null}

            </React.Fragment>
          ) : null}

          {tabValue == "team" ? (
            <Section>
              <HeaderText
                subHeader
                label="Team Members:"
                description="Add member to your team."
                onButtonPress={
                  role == "admin"
                    ? () => {
                      setState({ ...state, newTeamMemberVisible: true });
                    }
                    : null
                }
              />

              <FlexRow style={{ flexWrap: "wrap" }}>
                {members.length == 0 ? (
                  <NoRecords label="No team members found." />
                ) : (
                  members.map((item, index) => {
                    const { user, status, email, id, role, isLeadership, teamRoles, displayInPublicProfile } = item;
                    const isAdvisor = getIsAdvisor(item)
                    return (
                      <BoxRowItem
                        style={{ cursor: "pointer", minWidth: 350, alignSelf: "stretch", marginRight: 10, marginBottom: 10 }}
                        onPress={() => {
                          setUserId(user.id);
                        }}>
                        <FlexRow alignTop>
                          <FlexExpand>
                            {user ? (
                              <UserItem avatarSize={65} user={user}>
                                <FlexRow>
                                  <TextLine value={role} uppercase size={10} spacer />
                                  {status == "pending" ? <TextLine value="Pending" size={12} /> : null}
                                </FlexRow>
                              </UserItem>
                            ) : (
                              <View>
                                <TextLine value={email} />
                                <FlexRow>
                                  <TextLine value={role} uppercase size={10} spacer />
                                  {status == "pending" ? <TextLine value="Pending" size={12} /> : null}
                                </FlexRow>
                              </View>
                            )}

                            <FlexRow top={10}>
                              {displayInPublicProfile ? <TextLine size={12} value="Public" spacer /> : null}
                              {isLeadership ? <TextLine size={12} value="Leadership" spacer /> : null}
                              {isAdvisor ? <TextLine size={12} value="Advisor" spacer /> : null}
                            </FlexRow>

                            <View top={10}>
                              {teamRoles.map((item, index) => {
                                const { businessRole, skillLevel } = item
                                const { name } = businessRole
                                return <TextLine top={-4} size={14} value={name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} />
                              })}
                            </View>
                          </FlexExpand>

                          <EditIcon
                            style={{ marginLeft: 15 }}
                            onPress={() => {
                              setState({ ...state, editTeamMemberVisible: item, editItem: Object.assign({}, item), editIndex: index });
                            }}
                          />

                          {(user && user.id != session.user.id) || !user ? (
                            <TrashIcon
                              style={{ marginLeft: 15 }}
                              onPress={() => {
                                db.getObj("Member", id)
                                  .set("removed", true)
                                  .save();
                                members.splice(index, 1);
                                setState({ ...state, members });
                              }}
                            />
                          ) : null}
                        </FlexRow>
                      </BoxRowItem>
                    );
                  })
                )}
              </FlexRow>

              {userId ? (
                <ModalBasic
                  full
                  onCancel={() => {
                    setUserId();
                  }}>
                  <UserProfileWorker userId={userId} />
                </ModalBasic>
              ) : null}

              {editTeamMemberVisible ? (
                <EditTeamMember
                  team={team}
                  recordId={editItem.id}
                  onCancel={() => {
                    setState({ ...state, editTeamMemberVisible: null });
                  }}
                  onSave={(editItem) => {
                    if (isNew) {
                      members.push(editItem)
                    } else {
                      members[editIndex] = editItem
                    }
                    setState({ ...state, editTeamMemberVisible: null });
                  }}
                />
              ) : null}
            </Section>
          ) : null}

          {tabValue == "bonus-pool" ? <TeamBonusList teamId={teamId} /> : null}

          {tabValue == "services" ? <MyServices setRef={ref => {
            serviceRef.current = ref
          }} teamId={teamId} /> : null}

          {tabValue == "service-requests" ? <ServiceRequestsWorker teamId={teamId} /> : null}

          {tabValue == "proposals" ? <ProposalsWorker teamId={teamId} /> : null}

          {tabValue == "orders" ? <OrdersWorker teamId={teamId} /> : null}

          {tabValue == "service-orders" ? <ServiceOrdersWorker teamId={teamId} /> : null}

          {newTeamMemberVisible ? (
            <InviteMember
              team={team}
              onCancel={() => {
                setState({ ...state, newTeamMemberVisible: false });
              }}
              onDone={() => {
                setState({ ...state, newTeamMemberVisible: false });
                setRefresh(new Date());
              }}
            />
          ) : null}
          {projectVisible ? (
            <EditTeam
              teamId={teamId}
              onCancel={() => {
                setProjectVisible(false);
              }}
              onSave={() => {
                setProjectVisible(false);
                setRefresh(new Date());
              }}
            />
          ) : null}
        </FlexExpand>

        <View left={25}>
          <RenderChecklist label="Steps to publish team:" description="Complete this checklist to publish this team for public." items={checklistItems} />
        </View>
      </FlexRow>
    </View>
  );

  //team name
  //team members
  //member requests
  //proposals
  //service requests
  //orders, service orders
  //services
}

//team services (able to assign sub-services to specific team members)

export function InviteMember({ team, onCancel, onDone }) {
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, model, teamRolesList } = state
  const { inviteUser, inviteEmails, inviteMessage } = state;

  useEffect(() => {
    var teamRoles
    dL.getQuery("TeamRole")
      .equalTo("team", db.getObj("Team", team.id))
      .containedIn("removed", [undefined, false])
      .include("businessRole")
      .find().then(function (objs) {
        teamRoles = dL.loadObjects("TeamRole", objs)
        setState({
          ...state,
          isLoading: false,
          teamRolesList: teamRoles,
          model: {}
        })
      })
  }, [])

  if (isLoading) {
    return <Loading />;
  }

  const { teamRoles } = model
  const teamId = team.id

  const getO = function () {
    return db
      .getObj("Member")
      .set("team", db.getObj("Team", teamId))
      .set("teamRoles", teamRoles ? teamRoles.map(item => dL.getObj("TeamRole", item.id)) : null)
      .set("businessRoles", teamRoles ? teamRoles.map(item => dL.getObj("BusinessRole", item.businessRole.id)) : null)
      .set("displayInPublicProfile", true)
      .set("createdBy", db.getObj("User", session.user.id))
      .set("status", "pending")
  }

  const onAddEmail = function ({ email }) {
    return getO()
      .set("email", email)
      .save();
  }

  const onAddUser = function (user) {
    const { id } = user;
    return getO()
      .set("user", db.getObj("User", id))
      .save();
  }

  return (
    <ModalBasic
      title="Invite Team Member"
      okText="Invite"
      onCancel={onCancel}
      onOk={() => {
        if (!inviteUser && !inviteEmails) {
          alert("Must select an user.");
          return;
        }

        const sendEmail = function ({ email, id }) {
          var message = "Hi,<br><br>You are invite to join a Growly platform chat by " + session.user.firstName + " " + session.user.lastName + ".<br><br>" + inviteMessage + "<br><br>Follow the following link to join the chat: http://growly.app/worker/team/" + teamId + "/" + id;
          console.log(message);
          return http.run("sendEmail", {
            to: email,
            subject: "New Invitation",
            message: message,
            fromEmail: "support@interviewxpress.com"
          });
        };

        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              if (!utils.isEmail(email)) {
                alert(email + " is not a valid email.");
                return;
              }
            }
          }
        }

        const promises = [];
        if (inviteEmails) {
          var sp = inviteEmails.split(/\n/);
          for (var i = 0; i < sp.length; i++) {
            const email = sp[i];
            if (email) {
              promises[promises.length] = onAddEmail({ email }).then(function (obj) {
                return sendEmail({ email, id: obj.id });
              });
            }
          }
        }

        if (inviteUser) {
          const { email } = inviteUser;

          promises[promises.length] = onAddUser(inviteUser).then(function (obj) {
            return sendEmail({ email, id: obj.id });
          });
        }

        return Promise.all(promises).then(function () {
          return onDone();
        });
      }}>

      <SelectBox
        required
        multiple
        label="What are the team roles for this member?"
        description="Select the team roles that this team member will fulfill for the team."
        value={teamRoles ? teamRolesList.filter(item => teamRoles.find(item2 => item2.id == item.id)) : null}
        options={teamRolesList}
        onGetText={(item) => {
          const { businessRole, skillLevel } = item
          return businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"
        }}
        valueField="id"
        onChange={item => {
          model.teamRoles = item
          setState({ ...state, model });
        }}
      />

      <FormItem label="Select team member:" required>
        <SelectUser
          hideMySelf
          onChange={value => {
            setState({ ...state, inviteUser: value });
          }}
          value={inviteUser}
        />
      </FormItem>

      <TextLine bold bottom={25} value="OR" />
      <MyInput
        label="Email:"
        value={inviteEmails}
        onChange={value => {
          setState({ ...state, inviteEmails: value });
        }}
      />
      <MyInput
        lastItem
        multiline
        label="Email Message:"
        description="Enter a short message in the email invite body."
        value={inviteMessage}
        onChange={value => {
          setState({ ...state, inviteMessage: value });
        }}
      />
    </ModalBasic>
  );
}

export function SearchForTeams() {
  const { sectionId } = useParams();
  const history = useHistory();

  //LIKE THE PEOPLE SEARCH BUT FOR TEAMS
  //ADD FILTER

  //these are teams worker can see
  //show in card format like people
  //avatar for the team, business sectors, short tag line, number of team members, open business roles, looking for people, team leader ?

  //show apply for team button or already applied
  //apply for dialog (why?, when are you ready to join, how much time per month?, pay rate?, skill level)

  return <ListRender
    searchFields={[{ field: "searchText" }]}
    defaultSort={{ field: "lastUpdatedAt", desc: true }}
    type="Team"
    emptyLabel="No teams found."
    includes={["teamLeader"]}
    title="Search Teams:"
    description="View teams on the platform."
    onWhere={query => {
      //query.equalTo("isWorkerPublic", true);
    }}
    renderItem={item => {
      const { id } = item;

      return <RenderTeamItem key={id} team={item} onPress={() => {
        history.push("/worker/team/" + id)
      }}>
      </RenderTeamItem>
    }}
  />
}

export function RenderTeamItem({ team, onPress }) {
  const { icon, name, id, users, teamLeader, shortDescription, avatar } = team

  return (
    <BoxRowItem>
      <FlexRow alignTop>
        <FlexExpand>
          <FlexRow>
            {icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} /> : null}
            {avatar ? <Avatar size={85} value={avatar} style={{ marginRight: 30 }} /> : null}
            <View>
              <TextLine size={22} value={name} onPress={onPress} />
              <TextLine size={14} grey value={shortDescription} />
              <TextLine size={13} top={6} label="Team Members:" value={users.length} />
            </View>
          </FlexRow>
        </FlexExpand>

        <View>
          {teamLeader ? <UserItem label="Team Leader:" user={teamLeader} /> : null}
        </View>

        <Hide>
          <FlexRow>
            {users.map(user => (
              <UserItem user={user} style={{ marginRight: 15 }} />
            ))}
          </FlexRow>
        </Hide>
      </FlexRow>
    </BoxRowItem>
  );
}

//Find a team to join (see all the teams, search by business sector, then search by team name as searchText) (see avatar, team name, business sectors, number of team members, open role count?, specializations)
//Create a team (either dont have permission or see the create team dialog)
//My teams (see all the teams that you are part of as cards?)

export function TeamsWorker() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [teamVisible, setTeamVisible] = useState();
  const [state, setState] = useState({ isLoading: true });
  const { isLoading, teams } = state

  const user = session.user
  const allowAddTeam = user.permissions.allowAddTeam

  useEffect(() => {
    dL.getQuery("Member")
      .equalTo("type", "team")
      .equalTo("user", dL.getObj("User", user.id))
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const teams = dL.loadObjects("Member", objs)
        setState({ ...state, isLoading: false, teams })
      })

  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return <View>
    <HeaderText label="Search for Teams:"
      description="Find and manage your teams here." />

    <FlexRow>
      <DisplayCenterBox icon={faSearch} label="Find a team to join" description="Search from hundreds of teams to join with varing concentratins, industry focus and specializations." buttonLabel="Search for teams" style={{ minHeight: 375 }} onPress={() => {
        history.push("/worker/teams/public")
      }} />

      <View style={{ width: 25 }} />

      {allowAddTeam && teams.length == 0 ?
        <DisplayCenterBox icon={faPlus} label="Create my own team" description="Can't find the right team, start your own!" buttonLabel="Create Team" style={{ minHeight: 375 }} onPress={() => {
          setTeamVisible(true);
        }} /> : <DisplayCenterBox icon={faUsers} label="My Teams" description={"Part of " + plural(teams.length, "team")} buttonLabel="See My Teams" style={{ minHeight: 375 }} onPress={() => {
          history.push("/worker/teams/mine")
        }} />}
    </ FlexRow>

    {teamVisible ? (
      <NewTeam
        onCancel={() => {
          setTeamVisible(false);
        }}
        onSave={(team) => {
          history.push("/worker/team/" + team.id + "/admin")
          setTeamVisible(false);
        }}
      />
    ) : null}
  </View>
}

export function MyTeams() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [teamVisible, setTeamVisible] = useState();
  const refC = useRef();

  const buttons = [];
  const user = session.user

  if (user.permissions.allowAddTeam) {
    buttons.push({
      label: "Create Team",
      onPress: () => {
        setTeamVisible(true);
      }
    });
  }

  return (
    <React.Fragment>
      {teamVisible ? (
        <NewTeam
          onCancel={() => {
            setTeamVisible(false);
          }}
          onSave={(team) => {
            setTeamVisible(false);
            //refC.current.refresh();
            history.push("/worker/team/" + team.id + "/admin")
          }}
        />
      ) : null}

      <ListRender
        setRef={ref => {
          refC.current = ref;
        }}
        buttons={buttons}
        searchFields={[{ field: "name" }]}
        defaultSort={{ field: "createdAt", desc: true }}
        onWhere={query => {
          query.equalTo("type", "team");
          query.equalTo("user", dL.getObj("User", user.id));
        }}
        type="Member"
        emptyLabel="No teams created."
        includes={["team", "team.teamLeader"]}
        title="My Teams"
        description="See the teams that you are part of or that you lead."
        renderItem={item => {
          const { id, team, role } = item

          return <RenderTeamItem key={id} team={team}
            onPress={() => {
              if (role == "admin") {
                history.push(`/${sectionId}/team/${team.id}/admin`);
              } else {
                history.push(`/${sectionId}/team/${team.id}/member`);
              }
            }}>
          </RenderTeamItem>
        }}
      />
    </React.Fragment>
  );
}

/*
export function TeamsPublic() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [projectVisible, setProjectVisible] = useState();
  const refC = useRef();

  const buttons = [];

  if (session.user.permissions.allowAddTeam) {
    buttons.push({
      label: "Add",
      onPress: () => {
        setProjectVisible(true);
      }
    });
  }

  //edit teams
  //filter teams by: has open roles to fill, certain business sector or select certain roles (one or more for filtering)

  //search for teams (?) (browse other teams that you can join (those teams that have public flagged as true and show the business roles they are looking for))
  //create my own team (?)/my teams
  //part of teams ()
  //your team join requests

  return (
    <React.Fragment>
      {projectVisible ? (
        <NewTeam
          onCancel={() => {
            setProjectVisible(false);
          }}
          onSave={() => {
            setProjectVisible(false);
            refC.current.refresh();
          }}
        />
      ) : null}

      <ListRender
        setRef={ref => {
          refC.current = ref;
        }}
        buttons={buttons}
        searchFields={[{ field: "name" }]}
        defaultSort={{ field: "createdAt", desc: true }}
        onWhere={query => {
          const userId = session.user.id;
          query.equalToAO("users", dL.getObj("User", userId));
        }}
        type="Team"
        emptyLabel="No teams created."
        includes={["teamLeader", "users"]}
        title="Teams"
        description="Manage teams."
        renderItem={item => {
          const { name, id, users, teamLeader } = item;

          return (
            <BoxRowItem>
              <FlexRow>
                <View style={{ flex: 1 }}>
                  <TextLine
                    size={22}
                    value={name}
                    onPress={() => {
                      history.push(`/${sectionId}/team/${id}`);
                    }}
                  />

                  <View top={8}>
                    <FlexRow>
                      <View right={25}>
                        <TextLine size={12} bottom={3} value="Team Leader:" />
                        {teamLeader ? <UserItem user={teamLeader} /> : null}
                      </View>

                      <View>
                        <TextLine size={12} bottom={3} value="Team Members:" />

                        <FlexRow>
                          {users.map(user => (
                            <UserItem user={user} style={{ marginRight: 15 }} />
                          ))}
                        </FlexRow>
                      </View>
                    </FlexRow>
                  </View>
                </View>
              </FlexRow>
            </BoxRowItem>
          );
        }}
      />
    </React.Fragment>
  );
}
*/