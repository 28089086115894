import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import Moment from "moment-business-days";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { __DEV__ } from "root/dev";
import { ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { DeleteConfirm, Counter, EditDialog, Section, ModalBasic, BoxItem, FlexExpand, FlexRow, TextLine, View, TrashIcon, EditIcon, FormItem, MyInput, TouchButton, SelectBox, NoRecords } from "root/pack-1";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { SelectUsers } from "root/pack-2";

const { plural, money, requestTypes, getLabelItem, roundMoney } = utils;

function ListEditor({ onNew, label, description, onValidate, addTitle, items, emptyPlaceholder, renderItem, renderEditItem, onChange }) {
  const [state, setState] = useState({});
  const { editItem, visible, deleteConfirm, itemIndex, isNew } = state;

  return (
    <View>
      <HeaderText
        subHeader
        label={label}
        description={description}
        onButtonPress={() => {
          const editItem = {};
          onNew && onNew(editItem);
          setState({ ...state, visible: true, isNew: true, editItem });
        }}
      />

      {items.length == 0 ? <NoRecords label={emptyPlaceholder ? emptyPlaceholder : "No items added."} /> : null}

      {items.map((item, index) => {
        return (
          <View style={{ position: "relative" }}>
            {renderItem(item)}

            <FlexRow style={{ position: "absolute", top: 10, right: 10 }}>
              <EditIcon
                style={{ marginRight: 8 }}
                onPress={() => {
                  setState({ ...state, visible: true, isNew: false, editItem: Object.assign({}, item), itemIndex: index });
                }}
              />
              <TrashIcon
                onPress={() => {
                  setState({ ...state, deleteConfirm: true, itemIndex: index });
                }}
              />
            </FlexRow>
          </View>
        );
      })}

      {deleteConfirm ? (
        <DeleteConfirm
          onCancel={() => {
            setState({ ...state, deleteConfirm: false });
          }}
          onConfirm={() => {
            items.splice(itemIndex, 1);
            onChange && onChange(items);
            setState({ ...state, deleteConfirm: false });
          }}
        />
      ) : null}

      {visible ? (
        <ModalBasic
          title={addTitle ? addTitle : "Add Item"}
          okText="Save"
          onCancel={() => {
            setState({ ...state, visible: false });
          }}
          onOk={() => {
            if (!onValidate(editItem)) {
              return;
            }

            if (isNew) {
              items.push(editItem);
            } else {
              items[itemIndex] = editItem;
            }
            onChange && onChange(items);
            setState({ ...state, visible: false });
          }}>
          {renderEditItem(editItem)}
        </ModalBasic>
      ) : null}
    </View>
  );
}

export function WithdrawBonus(){
  //withdraw the bonus here
  //amount to withdraw
}

//or you can let the bonus sit and it will add value as equity (based on the current equity value number)

export function IssueBonus() {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ model: { items: [], users: [] } });
  const { model, editItem, visible, deleteConfirm, itemIndex, isNew } = state;

  const { users, description, bonusType, items } = model;

  const issueTypes = [{ label: "Days from today", value: "days" }, { label: "Exact Date", value: "date" }];

  var amountPerUser = 0;
  items.forEach(item => {
    amountPerUser += item.amount;
  });

  const totalAmount = amountPerUser * users.length;

  return (
    <EditDialog>
      <HeaderText label="Issue Bonus" description="Issue bonus." />

      <FlexRow>
        <SelectBox
          required
          label="Bonus Type:"
          value={utils.bonusTypes.find(item => item.value == bonusType)}
          options={utils.bonusTypes}
          onChange={item => {
            model.bonusType = item.value;
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 15 }} />
        <FormItem label="Total Per User:">
          <TextLine value={utils.money(amountPerUser)} />
        </FormItem>
        <View style={{ width: 15 }} />
        <FormItem label="Total Issued:">
          <TextLine value={utils.money(totalAmount)} />
        </FormItem>
      </FlexRow>

      <FormItem label="Issue bonus to users:" required>
        <SelectUsers
          excludedValues={model.users}
          onChange={items => {
            model.users = items;
            setState({ ...state, model });
          }}
          value={users}
        />
      </FormItem>
      <MyInput
        required
        label="Description:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <Section>
        <ListEditor
          label="Vesting Schedule:"
          description="Create bonus vesting schedule."
          emptyPlaceholder="No issue schedule added."
          items={items.sort(function (a, b) {
            return a.issueDate - b.issueDate;
          })}
          onNew={editItem => {
            editItem.issueType = "days";
          }}
          renderItem={item => {
            const { issueDate, amount } = item;

            return <BoxItem name={Moment(issueDate).format("M/D/YY") + " - in " + plural(Moment(issueDate).diff(new Date(), "days"), "day")} description={"Amount: " + utils.money(amount)} />;
          }}
          renderEditItem={editItem => {
            return (
              <React.Fragment>
                <SelectBox
                  required
                  label="Issue Type:"
                  value={issueTypes.find(item => item.value == editItem.issueType)}
                  options={issueTypes}
                  onChange={item => {
                    editItem.issueType = item.value;
                    setState({ ...state, model });
                  }}
                />
                {editItem.issueType == "days" ? (
                  <MyInput
                    style={{ maxWidth: 155 }}
                    inputType="integer"
                    minValue={0}
                    label="Days from Today:"
                    value={editItem.issueDays}
                    onChange={value => {
                      editItem.issueDays = value;
                      setState({ ...state, model });
                    }}
                  />
                ) : (
                  <FormItem label="Issue Date:">
                    <Datetime
                      closeOnClickOutside={true}
                      closeOnSelect={true}
                      value={editItem.issueDate}
                      timeFormat={false}
                      onChange={value => {
                        editItem.issueDate = value.toDate();
                        setState({ ...state, model });
                      }}
                    />
                  </FormItem>
                )}
                <MyInput
                  style={{ maxWidth: 125 }}
                  inputType="money"
                  minValue={0}
                  label="Amount:"
                  value={editItem.amount}
                  onChange={value => {
                    editItem.amount = value;
                    setState({ ...state, model });
                  }}
                />
              </React.Fragment>
            );
          }}
          onValidate={editItem => {
            const { amount, issueType, issueDays, issueDate } = editItem;

            if (!utils.isNumber(amount)) {
              alert("Must enter valid amount.");
              return;
            }
            if (issueType == "days") {
              if (!utils.isNumber(issueDays)) {
                alert("Must enter valid issue days.");
                return;
              }

              editItem.issueDays = Number(issueDays);
              editItem.issueDate = Moment()
                .endOf("day")
                .add(editItem.issueDays, "days")
                .toDate();
            } else {
              if (!issueDate) {
                alert("Must enter issue date.");
                return;
              }
              editItem.issueDate = Moment(issueDate)
                .endOf("day")
                .toDate();
            }
            editItem.amount = Number(amount);
            return true;
          }}
          onChange={items => {
            model.items = items;
            setState({ ...state, model });
          }}
        />
      </Section>

      <TouchButton
        label="Issue Bonus"
        onPress={() => {
          if (users.length == 0) {
            alert("Must select user.");
            return;
          }
          if (!bonusType) {
            alert("Must select bonus type.");
            return;
          }
          if (!description) {
            alert("Must enter description.");
            return;
          }
          if (items.length == 0) {
            alert("Must enter issue schedule.");
            return;
          }

          return dL
            .issueBonus({
              amountPerUser,
              totalAmount,
              users,
              items,
              description,
              bonusType
            })
            .then(function (obj) {
              history.goBack();
            });
        }}
      />
    </EditDialog>
  );
}

export function BonusPool() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({});
  const { stats } = state;
  const { pending, available, withdrawn } = stats ? stats : {};

  useEffect(() => {
    return dL.getBonusStats(sectionId == "worker" ? session.user.id : null).then(function (stats) {
      setState({ ...state, stats });
    });
  }, []);

  return (
    <ListRender
      rightRender={
        <React.Fragment>
          <TextLine bold>Not Vested: {utils.money(pending)}</TextLine>
          <TextLine bold>Not Withdrawn: {utils.money(available)}</TextLine>
          <TextLine bold>Total: {utils.money(available + pending)}</TextLine>
        </React.Fragment>
      }
      filters={[
        {
          label: "Not Vested",
          value: "pending",
          onQuery: function (query) {
            query.greaterThan("vestingDate", new Date());
            query.ascending("vestingDate");
          }
        },
        {
          label: "Not Withdrawn",
          value: "vested",
          onQuery: function (query) {
            query.equalTo("withdrawn", false);
            query.lessThan("vestingDate", new Date());
            query.ascending("vestingDate");
          }
        },
        {
          label: "History", value: "all",
          disableStat: true,
          onQuery: function (query) {
            query.descending("vestingDate");
          }
        },
      ]}
      onWhere={query => {
        const userId = session.user.id;
        if (sectionId == "worker") {
          query.equalTo("user", dL.getObj("User", userId));
        }
      }}
      type="BonusItem"
      emptyLabel="No bonuses found."
      includes={["user"]}
      title="Bonus Pool"
      description="See all bonuses issued."
      renderItem={item => {
        const { id, withdrawn, issueDate, vestingDate, user, amount, withdrawalDate, description, bonusType } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/bonus-item/${id}`);
            }}>
            <FlexRow>
              <View style={{ flex: 1 }}>
                <TextLine>{Moment(issueDate).format("M/D/YY")}:</TextLine>
                <TextLine bold size={16} top={6} value={description} />
                <UserItem user={user} style={{ marginTop: 8 }} />
              </View>
              <View alignRight>
                <TextLine size={22} value={utils.money(amount)} />
                <TextLine color="grey" value={bonusType} />
                {withdrawn ? <TextLine color="grey" value={"Withdrawn On: " + Moment(withdrawalDate).format("M/D/YY")} /> : <TextLine color="grey" value={"Released On: " + Moment(vestingDate).format("M/D/YY")} />}
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function TeamBonusList({ teamId }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const [state, setState] = useState({});
  const { count1 } = state;

  useEffect(() => {
    var count1;
    var promises = [];
    promises[promises.length] = db
      .getQuery("Bonus")
      .aggregate([
        {
          $match: {
            removed: { $in: [undefined, false] },
            _p_team: "Team$" + teamId
          }
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$totalAmount" }
          }
        }
      ])
      .then(function (data) {
        count1 = data.length > 0 ? data[0].count : 0;
      });

    return Promise.all(promises).then(function () {
      setState({ ...state, count1 });
    });
  }, []);

  return (
    <ListRender
      subHeader
      rightRender={
        <React.Fragment>
          <TextLine bold>Total: {utils.money(count1)}</TextLine>
        </React.Fragment>
      }
      defaultSort={{ field: "issueDate", desc: true }}
      type="Bonus"
      emptyLabel="No bonuses issued."
      includes={["createdBy", "users"]}
      title="Bonuses"
      description="See all bonuses issued."
      onWhere={query => {
        if (teamId) {
          query.equalTo("team", dL.getObj("Team", teamId));
        }
      }}
      renderItem={item => {
        const { id, issueDate, users, totalAmount, amountPerUser, description, bonusType } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/bonus-list/${id}`);
            }}>
            <FlexRow>
              <View style={{ flex: 1 }}>
                <TextLine>{Moment(issueDate).format("M/D/YY")}:</TextLine>
                <TextLine bold size={16} top={6} value={description} />

                <FlexRow top={8}>
                  {users.map(user => (
                    <UserItem user={user} style={{ marginRight: 4 }} />
                  ))}
                </FlexRow>
              </View>
              <View alignRight>
                {amountPerUser == totalAmount ? <TextLine size={22} value={utils.money(totalAmount)} /> : <TextLine size={22} value={utils.money(amountPerUser) + "/" + utils.money(totalAmount)} />}
                <TextLine color="grey" value={bonusType} />
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function BonusList() {
  const { sectionId } = useParams();
  const history = useHistory();

  const [state, setState] = useState({});
  const { count1 } = state;

  useEffect(() => {
    var count1;
    var promises = [];
    promises[promises.length] = db
      .getQuery("Bonus")
      .aggregate([
        {
          $match: {
            removed: { $in: [undefined, false] }
          }
        },
        {
          $group: {
            _id: null,
            count: { $sum: "$totalAmount" }
          }
        }
      ])
      .then(function (data) {
        count1 = data.length > 0 ? data[0].count : 0;
      });

    return Promise.all(promises).then(function () {
      setState({ ...state, count1 });
    });
  }, []);

  return (
    <ListRender
      rightRender={
        <React.Fragment>
          <TextLine bold>Total: {utils.money(count1)}</TextLine>
        </React.Fragment>
      }
      defaultSort={{ field: "issueDate", desc: true }}
      type="Bonus"
      emptyLabel="No bonuses issued."
      includes={["createdBy", "users"]}
      title="Bonuses"
      description="See all bonuses issued."
      onButtonPress={() => {
        history.push(`/${sectionId}/bonus-list/add`);
      }}
      renderItem={item => {
        const { id, issueDate, users, totalAmount, amountPerUser, description, bonusType } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/bonus-list/${id}`);
            }}>
            <FlexRow>
              <View style={{ flex: 1 }}>
                <TextLine>{Moment(issueDate).format("M/D/YY")}:</TextLine>
                <TextLine bold size={16} top={6} value={description} />

                <FlexRow top={8}>
                  {users.map(user => (
                    <UserItem user={user} style={{ marginRight: 4 }} />
                  ))}
                </FlexRow>
              </View>
              <View alignRight>
                {amountPerUser == totalAmount ? <TextLine size={22} value={utils.money(totalAmount)} /> : <TextLine size={22} value={utils.money(amountPerUser) + "/" + utils.money(totalAmount)} />}
                <TextLine color="grey" value={bonusType} />
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function Earnings() {
  const { sectionId } = useParams();
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [state, setState] = useState({});
  const { stats } = state;
  const { pending, available, withdrawn } = stats ? stats : {};

  useEffect(() => {
    dL.getEarningStats(session.user.id).then(function (stats) {
      setState({ ...state, stats });
    });
  }, []);

  return (
    <ListRender
      rightRender={
        !searchText ? (
          <View>
            <Counter label="Pending" value={pending} type="money" />
            <Counter label="Available" value={available} type="money" />
            <Counter label="Withdrawn" value={withdrawn} type="money" />
          </View>
        ) : null
      }
      defaultSort={{ field: "earningDate", desc: true }}
      filters={[
        { label: "All", value: "all", disableStat: true },
        {
          label: "Pending",
          value: "pending",
          onQuery: function (query) {
            query.greaterThan("releaseDate", new Date());
            query.equalTo("withdrawn", false);
          }
        },
        {
          label: "Available to Withdraw",
          value: "available",
          onQuery: function (query) {
            query.lessThan("releaseDate", new Date());
            query.equalTo("withdrawn", false);
          }
        },
        {
          label: "Withdrawn",
          value: "withdrawn",
          onQuery: function (query) {
            query.equalTo("withdrawn", true);
          }
        }
      ]}
      onWhere={query => {
        const userId = session.user.id;
        if (sectionId == "worker") {
          query.equalTo("user", dL.getObj("User", userId));
        }
      }}
      type="Earning"
      emptyLabel="No earnings found."
      includes={["order", "serviceOrder", "user"]}
      title="Earnings"
      description="See and withdraw earnings."
      renderItem={item => {
        const { order, earningDate, user, releaseDate, amount, serviceOrder, withdrawn, withdrawalDate } = item;
        return (
          <BoxRowItem>
            <FlexRow alignTop>
              <FlexExpand>
                <FlexRow>
                  <View>
                    <TextLine size={12}>{Moment(earningDate).format("M/D/YY [at] h:mm a")}</TextLine>
                    <TextLine
                      bold
                      size={16}
                      top={6}
                      onPress={() => {
                        history.push(`/${sectionId}/service-order/${serviceOrder.id}`);
                      }}>
                      Order #: {order.orderNumber}
                    </TextLine>
                    <TextLine size={14} grey>
                      Service Order #: {serviceOrder.orderNumber}
                    </TextLine>
                  </View>

                  <UserItem label="Consultant:" user={user} style={{ marginLeft: 35, marginTop: 12 }} />
                </FlexRow>
              </FlexExpand>

              <View alignRight>
                <TextLine size={22} value={utils.money(amount)} />
                {withdrawn ? <TextLine size={14} color="grey" value={"Withdrawn: " + Moment(withdrawalDate).format("M/D/YY h:mm a")} /> : <TextLine size={14} color="grey" value={"Released: " + Moment(releaseDate).format("M/D/YY h:mm a")} />}
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}
