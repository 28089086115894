import utils from "root/utils/functions";
import dL from "root/utils/dl";
import Moment from "moment-business-days";

const { getAllItemsForVersion, roundMoney } = utils;

const marginRate = 1.25
const overRate = 1.2;

export function getDeliveryDaysForSubServices({ subServices }) {
  var maxDeliveryDays = 0;
  subServices.forEach(item => {
    const days = getDeliveryDays(subServices, item);
    if (days > maxDeliveryDays) {
      maxDeliveryDays = days;
    }
  });
  return maxDeliveryDays;
}

export function getDeliveryDaysAll(items, noBusinessDays) {
  const dt = getDoneDateAll(items, noBusinessDays);
  return Moment(dt).diff(Moment().startOf("day"), "days");
}

function getDeliveryDays(items, item) {
  const dt = getDoneDateAll(items);
  return Moment(dt).diff(Moment().startOf("day"), "days");
}

export function getDoneDateAll(items, noBusinessDays) {
  var dt = Moment().startOf("day");
  items.forEach(item => {
    const startDate = getStartDate(items, item, noBusinessDays);
    const { deliveryDays } = item;
    var rdt;
    if (noBusinessDays) {
      rdt = Moment(startDate)
        .add(deliveryDays, "days")
        .toDate();
    } else {
      rdt = Moment(startDate)
        .businessAdd(deliveryDays)
        .toDate();
    }
    if (rdt > dt) {
      dt = rdt;
    }
  });
  return dt;
}

export function getStartDate(items, item, noBusinessDays, orderStartDate) {
  if (!orderStartDate) { orderStartDate = new Date() }
  const { requiredPreServices, delayOptions } = item;
  const { delayStartDate, delayType, delayStart, delayDays } = delayOptions ? delayOptions : {};
  var dt = Moment(orderStartDate).startOf("day");

  if (requiredPreServices && requiredPreServices.length > 0) {
    requiredPreServices.forEach(pId => {
      const item = items.find(item => (item.task ? item.task : item.subService ? item.subService : item.service).id == pId);

      if (item) {
        const doneDate = getDoneDate(items, item, noBusinessDays, orderStartDate);

        if (doneDate > dt) {
          dt = doneDate;
        }
      }
    });
  }

  if (delayStart) {
    if (delayType == "start-date") {
      if (dt < delayStartDate) {
        dt = delayStartDate;
      }
    } else if (delayType == "start-of-order") {
      if (noBusinessDays) {
        dt = Moment(orderStartDate)
          .add(delayDays, "days")
          .toDate();
      } else {
        dt = Moment(orderStartDate)
          .businessAdd(delayDays)
          .toDate();
      }
    } else if (delayType == "days-from-pre-req") {
      if (noBusinessDays) {
        dt = Moment(dt)
          .add(delayDays, "days")
          .toDate();
      } else {
        dt = Moment(dt)
          .businessAdd(delayDays)
          .toDate();
      }
    }
  }
  return Moment(dt).toDate();
}

export function getDoneDate(items, item, noBusinessDays, orderStartDate) {
  const startDate = getStartDate(items, item, noBusinessDays, orderStartDate);
  const { deliveryDays, itemType, actualServiceVersion, packageId, serviceOptionIds } = item;
  if (actualServiceVersion) {
    const subServices = getSubServicesForService({ serviceVersion: actualServiceVersion, packageId, serviceOptionIds });

    var subDoneDate
    subServices.forEach(subService => {
      const doneDate = getDoneDate(items, subService, noBusinessDays, startDate);

      if (!subDoneDate || doneDate > subDoneDate) {
        subDoneDate = doneDate;
      }
    });
  }

  if (itemType == "staff") {
    const { endDate } = item;
    return endDate
  } else {
    const stDate = subDoneDate ? subDoneDate : startDate
    if (noBusinessDays) {
      return Moment(stDate)
        .add(deliveryDays, "days")
        .toDate();
    } else {
      return Moment(stDate)
        .businessAdd(deliveryDays)
        .toDate();
    }
  }
}

export function getServiceOptionData({ service, userService, serviceVersion, serviceOption }) {
  const { id } = serviceOption.option
  const { userRole, businessRole, skillLevel } = getVersionData({ service, userService, serviceVersion })

  const dels = getIncludedDeliverablesForServiceOption({ service, userService, serviceVersion, serviceOptionId: id });
  const tasks = getTasksForServiceOption({ service, userService, serviceVersion, serviceOptionId: id });
  const subServices = getSubServicesForServiceOption({ service, userService, serviceVersion, serviceOptionId: id });

  const workHours = getWorkHours({ tasks });
  const subServiceCost = getSubServiceCost({ subServices });

  var standardCost;
  var hourlyRate;
  if (userRole) {
    hourlyRate = userRole.hourlyRate
    standardCost = hourlyRate * workHours;
  } else if (businessRole) {
    hourlyRate = businessRole.rates[skillLevel];
    standardCost = hourlyRate * workHours;
  }

  return { dels, tasks, subServices, workHours, hourlyRate, standardCost, subServiceCost };
}

export function getServicePackageData({ service, serviceVersion, servicePackage, userService }) {
  const { package: sPackage } = servicePackage;
  const { id } = sPackage;
  const { serviceOptions, userRole, businessRole, skillLevel } = getVersionData({ service, userService, serviceVersion })

  const opts = getIncludedServiceOptionsForBundledPackage({ serviceOptions, packageId: id });
  const optOpts = getOptionalServiceOptionsForBundledPackage({ serviceOptions, packageId: id });
  const dels = getDeliverablesForBundledPackage({ service, userService, serviceVersion, packageId: id });
  const tasks = getTasksForBundledPackage({ service, userService, serviceVersion, packageId: id });
  const subServices = getSubServicesForBundledPackage({ service, userService, serviceVersion, packageId: id });

  const workHours = getWorkHours({ tasks });
  const subServiceCost = getSubServiceCost({ subServices });

  var standardCost;
  var hourlyRate;
  if (userRole) {
    hourlyRate = userRole.hourlyRate
    standardCost = hourlyRate * workHours;
  } else if (businessRole) {
    hourlyRate = businessRole.rates[skillLevel];
    standardCost = hourlyRate * workHours;
  }

  /*
  var overrideId, overridePrice;
  if (userService) {
    const { rateType, overrides } = userService;
    const rateTypePrice = rateType == "worker-rate" ? roundMoney(workHours * userService.user.minHourlyRate) : price;

    overrideId = rateType + "-" + id;
    overridePrice = overrides[overrideId];
    standardCost = overridePrice != null ? overridePrice : rateTypePrice;
  }
  */

  return {
    //overridePrice,
    //overrideId,
    opts,
    optOpts,
    dels,
    tasks,
    subServices,
    workHours,
    hourlyRate,
    standardCost,
    subServiceCost
  };
}

export function getHoursPerMonth(staffServiceType, hoursPerMonth) {
  const rateList = getRateList(staffServiceType);
  return utils.getValueItem(rateList, hoursPerMonth);
}

export const priorityLevelsList = [{ label: "High Priority", value: "high", rateM: 1.1 }, { label: "Normal", value: "normal", rateM: 1 }, { label: "Reduced Cost", value: "low", rateM: 0.9 }];

export function getPriorityLevel(priorityLevel) {
  return utils.getValueItem(priorityLevelsList, priorityLevel);
}

export function getRateList(staffServiceType) {
  return staffServiceType == "staff"
    ? [{ label2: "1/16 Month", value: 160 / 16, rateM: 1.45 }, { label2: "1/8 Month", value: 160 / 8, rateM: 1.35 }, { label2: "1/4 Month", value: 160 / 4, rateM: 1.2 }, { label2: "1/2 Month", value: 160 / 2, rateM: 1.1 }, { label2: "Full Month", value: 160, rateM: 1 }]
    : [{ label2: "1 hour", value: 1, rateM: 1.45 }, { label2: "2 hours", value: 2, rateM: 1.35 }, { label2: "4 hours", value: 4, rateM: 1.2 }, { label2: "5 hours", value: 5, rateM: 1.1 }, { label2: "10 hours", value: 10, rateM: 1 }, { label2: "20 hours", value: 20, rateM: 0.95 }];
}

export function getServiceTotal({ staffServiceType, baseBillRate, hoursPerMonth, numberOfMonths, priorityLevel, allowPullForward, allowRollover }) {
  var total = 0,
    overageBillRate,
    monthlyCost,
    finalBillRate, monthlySubTotal

  if (hoursPerMonth && numberOfMonths) {
    //reduce cost as you get closer to 12 months
    //1 month: +10%
    //3 month: +5%
    //6 month: 0%
    //12 month: -5%

    var adderPercent = 0
    if (numberOfMonths < 3) {
      adderPercent = 10
    } else if (numberOfMonths < 6) {
      adderPercent = 5
    } else if (numberOfMonths < 12) {
      adderPercent = 0
    } else if (numberOfMonths >= 12) {
      adderPercent = -5
    }

    const hoursPerMonthItem = getHoursPerMonth(staffServiceType, hoursPerMonth);

    const finalBillRate2 = roundMoney(baseBillRate * hoursPerMonthItem.rateM * getPriorityLevel(priorityLevel).rateM);
    finalBillRate = finalBillRate2 + (finalBillRate2 * adderPercent / 100)
    overageBillRate = roundMoney(finalBillRate2 * overRate);
    monthlyCost = roundMoney(finalBillRate * hoursPerMonthItem.value);
    monthlySubTotal = roundMoney(finalBillRate * hoursPerMonthItem.value);
    if (allowPullForward) {
      monthlyCost += 25;
    }
    if (allowRollover) {
      monthlyCost += 25;
    }

    total = monthlyCost * numberOfMonths
  }

  return { contractTotal: total, total, overageBillRate, monthlySubTotal, monthlyTotal: monthlyCost, monthlyCost, finalBillRate };
}

export function getWorkerRate({ userRole, businessRole, assignedTo, skillLevel }) {
  var workerRate, baseBillRate;
  if (assignedTo || userRole) {
    if (userRole) {
      workerRate = userRole.hourlyRate;
    } else {
      workerRate = assignedTo.minHourlyRate;
    }
  } else if (businessRole) {
    workerRate = businessRole.rates[skillLevel];
  }
  baseBillRate = workerRate//roundMoney(workerRate * marginRate);

  return { baseBillRate, workerRate }
}

/*
export function getServicePriceText({ service, workerHourlyRate, includeSubServiceCost }) {
  const { enablePackages, isFixedPriced } = service;

  if (enablePackages) {
    return getPriceRangeText({ service, rateType: isFixedPriced ? "standard" : "worker-rate", workerHourlyRate, includeSubServiceCost });
  } else {
    return money(isFixedPriced ? getStandardPrice({ service, includeSubServiceCost }) : getWorkerRatePrice({ service, includeSubServiceCost, workerHourlyRate }));
  }
}
*/

export function getServiceDeliveryDays({ service, userService, serviceVersion, deliveryType, packageId, serviceOptionIds }) {
  const { enablePackages } = getVersionData({ service, userService, serviceVersion })

  const opts = getSelectedServiceOptions({ service, serviceVersion, serviceOptionIds });
  const subServices = getSubServicesForService({ service, serviceVersion, packageId, serviceOptionIds });

  if (enablePackages) {
    const servicePackage = getPackageInService({ service, serviceVersion, packageId });

    if (servicePackage) {
      const { deliveryDays, extraFastDeliveryDays } = servicePackage;
      var days = deliveryType == "fast" ? extraFastDeliveryDays : deliveryDays;

      opts.forEach(serviceOption => {
        const { deliveryDays } = serviceOption;
        if (deliveryDays) {
          days += deliveryDays;
        }
      });

      return days + getDeliveryDaysForSubServices({ subServices });
    } else {
      return 0;
    }
  } else {
    const { deliveryDays, extraFastDeliveryDays } = getVersionData({ service, userService, serviceVersion })
    var days = deliveryType == "fast" ? extraFastDeliveryDays : deliveryDays;

    opts.forEach(serviceOption => {
      const { deliveryDays } = serviceOption;
      if (deliveryDays) {
        days += deliveryDays;
      }
    });

    return days + getDeliveryDaysForSubServices({ subServices });
  }
}

function canDisplaySO({ serviceOptionIds, limitedToOptions }) {
  if (limitedToOptions && serviceOptionIds && limitedToOptions.filter(id => serviceOptionIds[id]).length > 0) {
    return true;
  }
  return false;
}

export function canDisplay({ packageId, serviceOptionIds, limitedToOptions, limitedToPackages }) {
  if (packageId) {
    if (limitedToPackages && limitedToPackages.length > 0 && !limitedToPackages.includes(packageId)) {
      return false;
    }
  }
  if (serviceOptionIds) {
    if (limitedToOptions && limitedToOptions.length > 0 && limitedToOptions.filter(id => serviceOptionIds[id]).length == 0) {
      return false;
    }
  }
  return true;
}

export function getIncludedServiceOptionsForBundledPackage({ serviceOptions, packageId }) {
  return serviceOptions.filter(item => {
    const { limitedToPackages } = item;

    if (limitedToPackages && limitedToPackages.includes(packageId)) {
      return true;
    }
  });
}

export function getOptionalServiceOptionsForBundledPackage({ serviceOptions, packageId }) {
  return serviceOptions.filter(item => {
    const { limitedToPackages } = item;

    if (!limitedToPackages || !limitedToPackages.includes(packageId)) {
      return true;
    }
  });
}

export function getIncludedDeliverablesForServiceOption({ service, userService, serviceVersion, serviceOptionId }) {
  const { deliverables } = getVersionData({ service, userService, serviceVersion })

  var d1 = {};
  d1[serviceOptionId] = true;

  return deliverables.filter(item => {
    const { limitedToOptions } = item;
    return canDisplaySO({ limitedToOptions, serviceOptionIds: d1 });
  });
}

function _getItemsForServiceOption({ list, serviceOptionId }) {
  return list.filter(item => {
    const { limitedToOptions } = item;

    if (limitedToOptions && limitedToOptions.includes(serviceOptionId)) {
      return true;
    }
  });
}

export function getVersionData({ service, userService, serviceVersion }) {
  return (serviceVersion ? serviceVersion : userService ? userService : service ? service : {})
}

function _getItemsForBundledPackage({ list, service, userService, serviceVersion, packageId, excludeServiceOptions }) {
  const { options } = getVersionData({ service, userService, serviceVersion })

  const opts = excludeServiceOptions ? [] : getIncludedServiceOptionsForBundledPackage({ serviceOptions: options, packageId });

  return list.filter(item => {
    const { limitedToPackages, limitedToOptions } = item;

    if ((!limitedToPackages || limitedToPackages.length == 0 || limitedToPackages.includes(packageId)) && (!limitedToOptions || limitedToOptions.length == 0 || limitedToOptions.filter(id => opts.find(item => item.id == id)).length > 0)) {
      return true;
    }
  });
}

function _getItemsForService({ list, service, userService, serviceVersion, bundleList, optionsList, packageId }) {
  var rtnList;

  if (packageId) {
    rtnList = bundleList;
  } else {
    const { enablePackages, enableServiceOptions } = getVersionData({ service, userService, serviceVersion })

    rtnList = list.filter(item => {
      const { limitedToPackages, limitedToOptions } = item;

      if ((!enablePackages || !limitedToPackages || limitedToPackages.length == 0) && (!enableServiceOptions || !limitedToOptions || limitedToOptions.length == 0)) {
        return true;
      }
    });
  }

  rtnList = rtnList.concat(optionsList);

  const finalList = [];
  rtnList.forEach(item => {
    if (!finalList.includes(item)) {
      finalList.push(item);
    }
  });
  return finalList;
}

function _getForService({ type, service, userService, serviceVersion, packageId, serviceOptionIds }) {
  const list = getVersionData({ service, userService, serviceVersion })[type];

  return _getItemsForService({ list, service, userService, serviceVersion, bundleList: _getForBundledPackage({ type, service, userService, serviceVersion, packageId }), optionsList: _getForServiceOptions({ type, service, userService, serviceVersion, serviceOptionIds }), service, packageId });
}

function _getForServiceOptions({ type, service, serviceVersion, serviceOptionIds }) {
  var rtnList = [];
  if (serviceOptionIds) {
    for (var key in serviceOptionIds) {
      rtnList = rtnList.concat(_getForServiceOption({ type, service, serviceVersion, serviceOptionId: key }));
    }
  }
  return rtnList;
}

function _getForServiceOption({ type, service, userService, serviceVersion, serviceOptionId }) {
  const list = getVersionData({ service, userService, serviceVersion })[type];

  return _getItemsForServiceOption({ list, serviceVersion, serviceOptionId });
}

function _getForBundledPackage({ type, service, userService, serviceVersion, packageId, excludeServiceOptions }) {
  const list = getVersionData({ service, userService, serviceVersion })[type];

  return _getItemsForBundledPackage({ list, service, userService, serviceVersion, packageId, excludeServiceOptions });
}

function _getForServiceMain({ type, service, userService, serviceVersion, packageId, serviceOptionIds }) {
  const list = getVersionData({ service, userService, serviceVersion })[type];

  return _getItemsForService({ list, service, userService, serviceVersion, packageId, bundleList: _getForService({ type, service, userService, serviceVersion, packageId }), optionsList: _getForServiceOptions({ type, service, userService, serviceVersion, serviceOptionIds }) });
}

export function getDeliverablesForService({ service, userService, serviceVersion, packageId, serviceOptionIds }) {
  return _getForServiceMain({ type: "deliverables", service, userService, serviceVersion, packageId, serviceOptionIds });
}

export function getSelectedServiceOptions({ service, userService, serviceVersion, serviceOptionIds }) {
  const { options } = getVersionData({ service, userService, serviceVersion })
  const opts = [];
  for (var key in serviceOptionIds) {
    const option = options.find(item => item.option.id == key);
    if (option) {
      opts.push(option);
    } else {
      //fail
    }
  }
  return opts;
}

export function getInputsForService({ service, serviceVersion, packageId, serviceOptionIds }) {
  return _getForServiceMain({ type: "inputs", service, serviceVersion, packageId, serviceOptionIds });
}

export function getSubServicesForService({ service, serviceVersion, packageId, serviceOptionIds }) {
  return _getForServiceMain({ type: "subServices", service, serviceVersion, packageId, serviceOptionIds });
}

export function getTasksForService({ service, serviceVersion, packageId, serviceOptionIds }) {
  return _getForServiceMain({ type: "tasks", service, serviceVersion, packageId, serviceOptionIds });
}

export function getInputsForServiceOption({ service, userService, serviceVersion, serviceOptionId }) {
  const { inputs } = getVersionData({ service, userService, serviceVersion })

  return _getItemsForServiceOption({ list: inputs, service, userService, serviceVersion, serviceOptionId });
}

export function getSubServicesForServiceOption({ service, userService, serviceVersion, serviceOptionId }) {
  const { subServices } = getVersionData({ service, userService, serviceVersion })

  return _getItemsForServiceOption({ list: subServices, serviceOptionId });
}

export function getTasksForServiceOption({ service, userService, serviceVersion, serviceOptionId }) {
  const { tasks } = getVersionData({ service, userService, serviceVersion })

  return _getItemsForServiceOption({ list: tasks, serviceOptionId });
}

export function getInputsForBundledPackage({ service, userService, serviceVersion, packageId, excludeServiceOptions }) {
  const { inputs } = getVersionData({ service, userService, serviceVersion })

  return _getItemsForBundledPackage({ list: inputs, service, userService, serviceVersion, packageId, excludeServiceOptions });
}

export function getTasksForBundledPackage({ service, userService, serviceVersion, packageId, excludeServiceOptions }) {
  const { tasks } = getVersionData({ service, userService, serviceVersion })

  return _getItemsForBundledPackage({ list: tasks, service, userService, serviceVersion, packageId, excludeServiceOptions });
}

export function getSubServicesForBundledPackage({ service, userService, serviceVersion, packageId, excludeServiceOptions }) {
  const { subServices } = getVersionData({ service, userService, serviceVersion })

  return _getItemsForBundledPackage({ list: subServices, service, serviceVersion, packageId, excludeServiceOptions });
}

export function getDeliverablesForBundledPackage({ service, userService, serviceVersion, packageId }) {
  const { options, deliverables } = getVersionData({ service, userService, serviceVersion })

  const opts = getIncludedServiceOptionsForBundledPackage({ serviceOptions: options, packageId });

  const dt = {};
  opts.forEach(item => {
    dt[item.id] = true;
  });

  return _getItemsAll({ list: deliverables, packageId, serviceOptionIds: dt });
}

function _getItemsAll({ list, packageId, serviceOptionIds }) {
  return list.filter(item => {
    const { limitedToOptions, limitedToPackages } = item;
    return canDisplay({ serviceOptionIds, limitedToOptions, limitedToPackages, packageId });
  });
}

export function getDeliverablesAll({ deliverables, packageId, serviceOptionIds }) {
  return _getItemsAll({ list: deliverables, packageId, serviceOptionIds });
}

export function getWorkHours({ tasks, overrides }) {
  var workHours = 0;

  tasks.forEach(item => {
    var { hours, id } = item;
    if (overrides && overrides[id]) {
      hours = overrides[id];
    }

    workHours += hours;
  });

  return workHours;
}

/*
export function getUserServicePriceText({ userService, workerHourlyRate }) {
  const includeSubServiceCost = true;
  const { service, serviceVersion, rateType, makeServiceSellable, overrides } = userService;
  const { subServices, enablePackages, packages } = getVersionData({ service, userService, serviceVersion })

  if (!makeServiceSellable) {
    const subServiceCost = includeSubServiceCost ? getSubServiceCost({ subServices }) : 0;

    if (!enablePackages) {
      const tasks = getTasksForService({ service, serviceVersion });
      const workHours = getWorkHours({ tasks, overrides });
      return money(roundMoney(workHours * workerHourlyRate) + subServiceCost);
    } else {
      var maxHourlyRate = 0;
      var minHourlyRate = 99999;
      var minPrice = 99999;
      var maxPrice = 0;

      packages.forEach(item => {
        const { id } = item;

        const tasks = getTasksForBundledPackage({ packageId: id, service, serviceVersion });
        const workHours = getWorkHours({ tasks, overrides });
        const priceSet = roundMoney(workHours * workerHourlyRate) + subServiceCost;

        if (priceSet) {
          if (priceSet > maxPrice) {
            maxPrice = priceSet;
          }
          if (priceSet < minPrice) {
            minPrice = priceSet;
          }

          if (workHours) {
            const hourlyRate = priceSet / workHours;
            if (hourlyRate > maxHourlyRate) {
              maxHourlyRate = hourlyRate;
            }
            if (hourlyRate < minHourlyRate) {
              minHourlyRate = hourlyRate;
            }
          }
        }
      });
      return moneyRange(minPrice, maxPrice);
    }
  } else {
    if (enablePackages) {
      return getPriceRangeText({ overrides, service, serviceVersion, rateType, workerHourlyRate, includeSubServiceCost });
    } else {
      return money(getStandardPrice({ overrides, service, serviceVersion, includeSubServiceCost }));
    }
  }
}
*/

export function getPackageInService({ service, userService, serviceVersion, packageId }) {
  const { packages } = getVersionData({ service, userService, serviceVersion })
  const servicePackage = packages.find(item => item.package.id == packageId);
  if (!servicePackage) {
    //fail
  }
  return servicePackage;
}

export function getSubServiceCost({ subServices, overrides, masterOverrides, path, dynamic }) {
  return _getSubServiceCost({ subServices, overrides, masterOverrides, path, circularIds: {}, dynamic })
}

function _getSubServiceCost({ subServices, overrides, masterOverrides, path, circularIds, dynamic }) {
  var cost = 0;
  subServices.forEach(item => {
    if (!dynamic) {
      const { total, id } = item;
      if (overrides && overrides[id]) {
        const { total } = overrides[id];
        cost += total;
      } else {
        cost += total;
      }
    } else {
      const { total, serviceVersion, subService } = item;

      if (masterOverrides) {
        const p1 = path + ".service[" + subService.id + "]"
        const o1 = masterOverrides.find(item => item.path == p1 && item.overrideType == "sub-service")
        if (o1) {
          if (!o1.isRemoved) {
            const { userServiceVersion, packageId, serviceOptionIds, deliveryType } = o1
            const version = userServiceVersion ? userServiceVersion : serviceVersion
            const { total: t1 } = getServicePriceAndDelivery({ serviceVersion: version, packageId, serviceOptionIds, deliveryType, masterOverrides, path: p1, circularIds: { ...circularIds }, dynamic })
            cost += t1;
          }
        } else {
          const { userServiceVersion, packageId, serviceOptionIds, deliveryType } = item
          const version = userServiceVersion ? userServiceVersion : serviceVersion
          const { total: t1 } = getServicePriceAndDelivery({ serviceVersion: version, packageId, serviceOptionIds, deliveryType, masterOverrides, path: p1, circularIds: { ...circularIds }, dynamic })
          cost += t1;
        }
      } else {
        cost += total;
      }
    }
  });

  return cost;
}

export function getStandardPrice({ overrides, service, userService, serviceVersion, includeSubServiceCost }) {
  const { id, price } = service;
  const { subServices } = getVersionData({ service, userService, serviceVersion })
  const rateType = "standard";
  const overrideBasePrice = overrides ? overrides[rateType + "-" + id] : null;

  const subServicePrice = includeSubServiceCost ? getSubServiceCost({ subServices }) : 0;

  return (overrideBasePrice ? overrideBasePrice : price) + subServicePrice;
}

export function getWorkerRatePrice({ overrides, service, userService, serviceVersion, workerHourlyRate, includeSubServiceCost }) {
  const { id } = service;
  const { subServices } = getVersionData({ service, userService, serviceVersion })

  const rateType = "worker-rate";

  const subServicePrice = includeSubServiceCost ? getSubServiceCost({ subServices }) : 0;

  const tasks = getTasksForService({ service });
  const workHours = getWorkHours({ tasks, overrides });

  const overrideBasePrice = overrides ? overrides[rateType + "-" + id] : null;
  const servicePrice = overrideBasePrice ? overrideBasePrice : roundMoney(workHours * workerHourlyRate);
  return servicePrice + subServicePrice;
}

/*
export function getPriceRangeText({ overrides, service, userService, serviceVersion, rateType, workerHourlyRate, includeSubServiceCost }) {
  const { minPrice, maxPrice } = getPriceInfo({ overrides, service, userService, serviceVersion, rateType, workerHourlyRate, includeSubServiceCost });
  return moneyRange(minPrice, maxPrice);
}

export function getPriceInfo({ overrides, service, userService, serviceVersion, rateType, workerHourlyRate, includeSubServiceCost }) {
  const { price } = service;
  const { subServices, options: serviceOptions, packages } = getVersionData({ service, userService, serviceVersion })

  var maxHourlyRate = 0;
  var minHourlyRate = 99999;
  var minPrice = 99999;
  var maxPrice = 0;

  const baseWorkHours = getWorkerHours({ service, serviceVersion, overrides });
  const overrideBasePrice = overrides ? overrides[rateType + "-" + service.id] : null;
  var basePrice;
  if (rateType == "standard") {
    basePrice = overrideBasePrice ? overrideBasePrice : price;
  } else if (rateType == "worker-rate") {
    basePrice = overrideBasePrice ? overrideBasePrice : roundMoney(baseWorkHours * workerHourlyRate);
  }

  if (basePrice > maxPrice) {
    maxPrice = basePrice;
  }
  if (basePrice < minPrice) {
    minPrice = basePrice;
  }

  const baseHourlyRate = basePrice / baseWorkHours;

  if (baseHourlyRate > maxHourlyRate) {
    maxHourlyRate = baseHourlyRate;
  }
  if (baseHourlyRate < minHourlyRate) {
    minHourlyRate = baseHourlyRate;
  }

  const getRatePrice = function ({ rateType, workHours, price }) {
    return rateType == "worker-rate" ? roundMoney(workHours * workerHourlyRate) : price;
  };

  serviceOptions.forEach(item => {
    const { id, price } = item;

    const tasks = getTasksForServiceOption({ serviceOptionId: id, service });
    const workHours = getWorkHours({ tasks, overrides });

    const overrideId = rateType + "-" + id;
    const overridePrice = overrides ? overrides[overrideId] : null;
    const priceSet = overridePrice != null ? overridePrice : getRatePrice({ rateType, workHours, price });

    if (priceSet) {
      if (workHours) {
        const hourlyRate = (basePrice + priceSet) / (baseWorkHours + workHours);

        if (hourlyRate > maxHourlyRate) {
          maxHourlyRate = hourlyRate;
        }
        if (hourlyRate < minHourlyRate) {
          minHourlyRate = hourlyRate;
        }
      }
    }
  });

  packages.forEach(item => {
    const { id, price } = item;

    const tasks = getTasksForBundledPackage({ packageId: id, service });
    const workHours = getWorkHours({ tasks, overrides });

    const overrideId = rateType + "-" + id;
    const overridePrice = overrides ? overrides[overrideId] : null;
    const priceSet = overridePrice != null ? overridePrice : getRatePrice({ rateType, workHours, price });

    if (priceSet) {
      if (priceSet > maxPrice) {
        maxPrice = priceSet;
      }
      if (priceSet < minPrice) {
        minPrice = priceSet;
      }

      if (workHours) {
        const hourlyRate = priceSet / workHours;
        if (hourlyRate > maxHourlyRate) {
          maxHourlyRate = hourlyRate;
        }
        if (hourlyRate < minHourlyRate) {
          minHourlyRate = hourlyRate;
        }
      }
    }
  });

  const subServicePrice = includeSubServiceCost ? getSubServiceCost({ subServices }) : 0;
  basePrice += subServicePrice;

  return {
    baseHourlyRate,
    maxHourlyRate,
    minHourlyRate,
    minPrice,
    maxPrice,
    baseWorkHours,
    overrideBasePrice,
    basePrice
  };
}
*/

export function getVisiblePackage({ serviceData }) {
  var servicePackage;
  const { enablePackages, packages } = serviceData
  if (enablePackages && packages.length > 0) {
    servicePackage = packages[0];
  }
  return servicePackage;
}

export function getServicePreReqItems({ serviceData }) {
  const { tasks, subServices } = serviceData

  var preReqItems = subServices.map((item) => {
    return { type: "sub-service", subService: item, id: item.subService.id };
  });

  preReqItems = preReqItems.concat(
    tasks.map((item) => {
      return { type: "task", task: item, id: item.task.id };
    })
  );
  return preReqItems;
}

export function getServiceOptionPrice({ serviceData, serviceOptionId }) {
  const { userRole, options: serviceOptions, businessRole, skillLevel } = serviceData;

  const serviceOption = serviceOptions.find(item => item.option.id == serviceOptionId);
  const { isFixedPriced, price } = serviceOption;


  const tasks = getTasksForServiceOption({ serviceVersion: serviceData, serviceOptionId });
  const subServices = getSubServicesForServiceOption({ serviceVersion: serviceData, serviceOptionId });

  const workHours = getWorkHours({ tasks });
  const { price: subServiceCost, days: subServiceDays } = getSubServiceCost2({ serviceVersion: serviceData, subServices2: subServices });

  var hourlyRate;
  if (userRole) {
    hourlyRate = userRole.hourlyRate;
  } else if (businessRole) {
    hourlyRate = businessRole.rates[skillLevel];
  }

  const standardCost = hourlyRate * workHours;
  const finalPrice = roundMoney(isFixedPriced ? price : standardCost);
  return { total: finalPrice + subServiceCost, servicePrice: finalPrice, subServiceDays, subServiceCost, workHours };
}

var cache = {}

export function clearCache() {
  cache = {}
}

export function getPriceMapFromPriceMap({ priceMap, path: rootPath, includeRoot, subItemsOnly }) {
  var newMap = []
  const spRoot = rootPath.split(".")
  priceMap.forEach(mapItem => {
    const { path, item } = mapItem

    if ((!rootPath || includeRoot) || path.startsWith(rootPath)) {
      const cont = true
      if (subItemsOnly) {
        const sp = path.split(".")
        if (sp.length != spRoot.length + 1) {
          cont = false
        }
      }
      if (cont) {
        const { isRemoved } = item
        if (!isRemoved) {
          newMap.push({
            path: path.slice(rootPath.length + 1),
            item
          })
        }
      }
    }
  })

  return newMap
}

function getSubItems({ priceMap, path: rootPath }) {
  const items = []
  const sp = rootPath.split(".")
  priceMap.forEach(mapItem => {
    const { path, item } = mapItem
    const { isRemoved } = item
    if (!isRemoved) {
      if (path.startsWith(rootPath) && (path.split(".").length == sp.length + 1)) {
        items.push(mapItem)
      }
    }
  })
  return items
}

export function calcStartDayIndex(model) {
  const { actualServiceVersion } = model

  const { tasks, subServices } = actualServiceVersion

  var allItems = []
  allItems = allItems.concat(tasks)
  allItems = allItems.concat(subServices)
  const doneDate = getDoneDate(allItems, model, true);
  const startDate = getStartDate(allItems, model, true);
  const startDayIndex = Moment(startDate).diff(Moment().startOf("day"), "days")
  const doneDayIndex = Moment(doneDate).diff(Moment().startOf("day"), "days")

  model.startDayIndex = startDayIndex
  model.doneDayIndex = doneDayIndex
}

export function getDoneIndex({ priceMap, path, item }) {
  const items = getSubItems({ priceMap, path })
  const { doneDayIndex } = item
  if (items.length == 0) {
    return doneDayIndex
  }
  var maxDoneIndex = 0
  items.forEach(mapItem => {
    const { path, item } = mapItem
    const d1 = getDoneIndex({ priceMap, path, item })
    if (d1 > maxDoneIndex) {
      maxDoneIndex = d1
    }
  })
  return maxDoneIndex + doneDayIndex
}

export function getDataFromPriceMap({ priceMap, path: rootPath, includeRoot }) {
  var totalPrice = 0, totalDays = 0

  const parentPath = getParentPath(rootPath)
  var addStartIndex = 0
  if (parentPath) {
    const { startDayIndex } = getDataFromPriceMap({ priceMap, path: parentPath })
    addStartIndex = startDayIndex
  }

  var startDayIndex = 0
  for (var i = 0; i < priceMap.length; i++) {
    const mapItem = priceMap[i]
    const { path, item } = mapItem

    if (rootPath == path) {
      const { isRemoved, startDayIndex: s1 } = item
      if (!isRemoved) {
        startDayIndex = s1 + addStartIndex
        const dt = getDoneIndex({ priceMap, path, item }) + addStartIndex
        totalDays = dt
        break
      }
    }
  }

  priceMap.forEach(mapItem => {
    const { path, item } = mapItem

    if ((!rootPath || includeRoot) || path.startsWith(rootPath)) {
      const { price, isRemoved } = item

      if (!isRemoved) {
        totalPrice += price
      }
    }
  })

  return { price: totalPrice, days: totalDays, startDayIndex }
}

export function applyPriceMap({ rootPriceMap, rootPath, priceMap }) {
  const newPriceMap = [...priceMap]
  const applyMaps = []
  rootPriceMap.forEach(mapItem => {
    const { path, item } = mapItem
    if (path.startsWith(rootPath) && rootPath != path) {
      applyMaps.push({ path: path.slice(rootPath.length + 1), item })
    }
  })

  applyMaps.forEach(mapItem => {
    const { path } = mapItem

    const foundIndex = newPriceMap.findIndex(item => item.path == path)
    if (foundIndex != -1) {
      newPriceMap[foundIndex] = mapItem
    } else {
      newPriceMap.push(mapItem)
    }
  })

  return newPriceMap
}

function copyServiceConfig(config) {
  const { startDayIndex, doneDayIndex, itemType, actualVersionType, actualServiceVersion } = config
  const { unitPrice, quantity, total, deliveryDays, service, userService, serviceVersion, userServiceVersion, delayOptions, assignedTo, workerUserServices, deliveryType, packageId, serviceOptionIds } = config

  const { businessRole, id, versionNum, name, workHours, minDeliveryDays, hasExtraFastDelivery, extraFastDeliveryDays, allowedRevisions, isFixedPriced, requiredPreServices } = actualServiceVersion

  const qty = quantity ? quantity : 1
  return {
    itemType,
    startDayIndex, doneDayIndex,

    businessRoleId: businessRole ? businessRole.id : null,

    quantity: qty,
    total: qty * (unitPrice ? unitPrice : total),
    price: unitPrice ? unitPrice : total,
    days: deliveryDays,

    name, workHours, minDeliveryDays, hasExtraFastDelivery, extraFastDeliveryDays, allowedRevisions, isFixedPriced, requiredPreServices,

    serviceId: service.id,
    serviceVersionId: serviceVersion.id,
    userServiceId: userService ? userService.id : null,
    userServiceVersionId: userService ? userServiceVersion.id : null,

    actualServiceVersionId: id,
    actualVersionNum: versionNum,
    actualVersionType,

    unitPrice: unitPrice ? unitPrice : total,
    deliveryDays: deliveryDays,

    deliveryType,
    packageId,
    serviceOptionIds,
    delayOptions: delayOptions ? { ...delayOptions } : null,
    assignedToId: assignedTo ? assignedTo.id : null,
    workerUserServices: workerUserServices ? workerUserServices.map(item => {
      const { userService, userServiceVersion } = item
      return { userServiceId: userService.id, userServiceVersionId: userServiceVersion.id }
    }) : null
  }
}

function copyServiceConfigBuyer(config) {
  const { workerSelectionType, hoursPerMonth, staffServiceType, startDate, numberOfMonths, priorityLevel, allowPullForward, allowRollover, recommendedUser } = config

  return {
    ...copyServiceConfig(config),

    workerSelectionType, hoursPerMonth, staffServiceType, startDate, numberOfMonths, priorityLevel, allowPullForward, allowRollover,

    recommendedUser: recommendedUser ? recommendedUser.id : null,
  }
}

export function createSubServicePriceMap({ serviceVersion, packageId, serviceOptionIds, delayOptions }) {
  var { masterOverrides } = serviceVersion

  const subServices = getSubServicesForService({ serviceVersion, packageId, serviceOptionIds });
  var finalPriceMap = []
  subServices.forEach(subServiceVersion => {
    const { priceMap, subService } = subServiceVersion

    const startPath = `service[${subService.id}]`
    finalPriceMap.push({
      path: startPath,
      item: copyServiceConfig(subServiceVersion)
    })

    priceMap.forEach(mapItem => {
      const { path, item } = mapItem

      finalPriceMap.push({ path: startPath + "." + path, item })
    })
  })

  finalPriceMap = applyMasterOverrides({ finalPriceMap, masterOverrides, copyServiceConfig })

  return finalPriceMap
}

export function createProposalItemPriceMap({ proposalVersion, proposalItem, serviceVersion, packageId, serviceOptionIds }) {
  const { masterOverrides } = proposalVersion

  var finalPriceMap = []

  const { id, overridePrice } = proposalItem
  const startPathP = `p-item[${id}]`
  const finalItem = Object.assign({}, copyServiceConfig(proposalItem))
  if (overridePrice != null) {
    finalItem.originalPrice = finalItem.price
    finalItem.price = overridePrice
  }
  finalPriceMap.push({
    path: startPathP,
    item: finalItem
  })

  const subServices = getSubServicesForService({ serviceVersion, packageId, serviceOptionIds });

  const subItems = []
  subServices.forEach(subServiceVersion => {
    const { priceMap, subService } = subServiceVersion

    const startPath = `${startPathP}.service[${subService.id}]`

    subItems.push({
      path: startPath,
      item: copyServiceConfig(subServiceVersion)
    })

    priceMap.forEach(mapItem => {
      const { path, item } = mapItem

      subItems.push({ path: startPath + "." + path, item })
    })
  })

  subItems.forEach(mapItem => {
    const { path, item } = mapItem

    if (overridePrice != null) {
      item.originalPrice = item.price
      item.price = 0
    }
    finalPriceMap.push({ path, item })
  })

  finalPriceMap = applyMasterOverrides({ finalPriceMap, masterOverrides, copyServiceConfig })

  return finalPriceMap
}

export function createServicePriceMap({ serviceVersion }) {
  const { subServices, masterOverrides } = serviceVersion

  var finalPriceMap = []
  subServices.forEach(subServiceVersion => {
    const { priceMap, subService } = subServiceVersion

    const startPath = `service[${subService.id}]`
    finalPriceMap.push({
      path: startPath,
      item: copyServiceConfig(subServiceVersion)
    })

    priceMap.forEach(mapItem => {
      const { path, item } = mapItem

      finalPriceMap.push({ path: startPath + "." + path, item })
    })
  })

  finalPriceMap = applyMasterOverrides2({ finalPriceMap, masterOverrides, copyServiceConfig })

  return finalPriceMap.filter(item => item != null)
}

export function createProposalPriceMap({ proposal, proposalVersion }) {
  const { masterOverrides } = proposalVersion

  const allItemsV = getAllItemsForVersion(proposalVersion).values()
  const allItems = proposal.allItems

  var finalPriceMap = []
  allItemsV.forEach(item => {
    const proposalItem = allItems.get(item.id)
    const { priceMap } = proposalItem

    finalPriceMap = finalPriceMap.concat(priceMap)
  })

  finalPriceMap = applyMasterOverrides({ finalPriceMap, masterOverrides, copyServiceConfig })

  return finalPriceMap.filter(item => item != null)
}

export function createProposalPriceMapBuyer({ proposal, proposalVersion }) {
  var { priceMap: finalPriceMap } = proposalVersion
  const { buyerMasterOverrides } = proposal

  finalPriceMap = applyMasterOverrides({ finalPriceMap, masterOverrides: buyerMasterOverrides, copyServiceConfig: copyServiceConfigBuyer })

  return finalPriceMap.filter(item => item != null)
}

function applyMasterOverrides2({ finalPriceMap, masterOverrides, copyServiceConfig }) {
  masterOverrides = masterOverrides.sort((a, b) => a.path.length - b.path.length);

  masterOverrides.forEach(masterItem => {
    const { priceMap, id, path: startPath, isRemoved, overridePrice } = masterItem

    finalPriceMap = finalPriceMap.filter(mapItem => {
      return !mapItem.path.startsWith(startPath)
    })

    const finalItem = Object.assign({ isRemoved }, copyServiceConfig(masterItem))
    if (overridePrice != null) {
      finalItem.originalPrice = finalItem.price
      finalItem.price = overridePrice
    }
    finalPriceMap.push({
      path: startPath,
      isOverride: true,
      overrideId: id,
      item: finalItem
    })

    priceMap.forEach(mapItem => {
      const { path, item } = mapItem
      const finalItem = Object.assign({ isRemoved }, item)

      if (overridePrice != null) {
        finalItem.originalPrice = finalItem.price
        finalItem.price = 0
      }

      finalPriceMap.push({
        path: startPath + "." + path,
        item: finalItem
      })
    })
  })

  return finalPriceMap
}

function applyMasterOverrides({ finalPriceMap, masterOverrides, copyServiceConfig }) {
  masterOverrides = masterOverrides.sort((a, b) => a.path.length - b.path.length);

  masterOverrides.forEach(masterItem => {
    const { priceMap, id, path, isRemoved, overridePrice } = masterItem

    finalPriceMap = finalPriceMap.filter(mapItem => {
      return !mapItem.path.startsWith(path)
    })
    /*
        const finalItem = Object.assign({ isRemoved }, copyServiceConfig(masterItem))
        if (overridePrice != null) {
          finalItem.originalPrice = finalItem.price
          finalItem.price = overridePrice
        }
        finalPriceMap.push({
          path,
          isOverride: true,
          overrideId: id,
          item: finalItem
        })
    */
    priceMap.forEach(mapItem => {
      const { path, item } = mapItem
      const finalItem = Object.assign({ isRemoved }, item)
      /*
      if (overridePrice != null) {
        finalItem.originalPrice = finalItem.price
        finalItem.price = 0
      }
      */
      finalPriceMap.push({
        path,
        item: finalItem
      })
    })
  })

  return finalPriceMap
}

export function loadUser_Worker({ userId }) {
  if (cache[userId]) {
    return Promise.resolve(cache[userId])
  }
  var user
  return dL.getQuery("User")
    .include("userRoles")
    .include("businessRoles")
    .get(userId)
    .then(function (obj) {
      user = dL.loadServiceVersion(obj)

      cache[userId] = user
      return user
    })
}

export function loadSubServiceVersion_Worker({ subServiceVersion, options }) {
  const { subService, actualServiceVersion, serviceVersion, service, userService, userServiceVersion, workerUserServices } = subServiceVersion

  const promises = []

  if (workerUserServices) {
    promises[promises.length] = Promise.all(workerUserServices.map((userService, index) => {
      return dL.getUserService2({ userServiceId: userService.id, dontClearCache: true, options }).then(function (userService) {
        workerUserServices[index] = userService
        userService.parentSubService = subService
      })
    }))
  }

  if (actualServiceVersion) {
    promises[promises.length] = dL.getServiceVersion2({ serviceVersionId: actualServiceVersion.id, dontClearCache: true, options }).then(function (serviceVersion) {
      subServiceVersion.actualServiceVersion = serviceVersion
      serviceVersion.parentSubService = subService
    })
  }

  promises[promises.length] = dL.getService2({ serviceId: service.id, dontClearCache: true, options }).then(function (service) {
    subServiceVersion.service = service
    service.parentSubService = subService

    return dL.getServiceVersion2({ serviceVersionId: serviceVersion.id, dontClearCache: true, options }).then(function (serviceVersion) {
      subServiceVersion.serviceVersion = serviceVersion
      serviceVersion.parentSubService = subService
    })
  })

  if (userService) {
    promises[promises.length] = dL.getUserService2({ userServiceId: userService.id, dontClearCache: true, options }).then(function (userService) {
      subServiceVersion.userService = userService
      userService.parentSubService = subService

      if (userServiceVersion) {
        return dL.getServiceVersion2({ serviceVersionId: userServiceVersion.id, dontClearCache: true, options }).then(function (serviceVersion) {
          subServiceVersion.userServiceVersion = serviceVersion
          serviceVersion.parentSubService = subService
        })
      }
    })
  }

  return Promise.all(promises)
}

export function loadServiceVersion_Worker({ serviceVersionId, dontClearCache, options, path }) {
  if (!dontClearCache) { clearCache() }
  if (cache[serviceVersionId]) {
    return Promise.resolve(cache[serviceVersionId])
  }
  var serviceVersion
  return dL.getQuery("ServiceVersion")
    .include("tags")
    .include("deliverables")
    .include("packages")
    .include("options")
    .include("subServices")
    .include("inputs")
    .include("tasks")
    .include("businessRole")
    .include("userRole")
    .include("primaryCategory")
    .include("secondaryCategory")
    .include("masterOverrides")
    .get(serviceVersionId)
    .then(function (obj) {
      serviceVersion = dL.loadServiceVersion(obj)
      cache[serviceVersionId] = serviceVersion

      const { userService } = serviceVersion
      if (userService) {
        return loadUserService_Worker({ userServiceId: userService.id, dontClearCache: true, options, path }).then(function (userService) {
          serviceVersion.userService = userService
        })
      }

    }).then(function () {
      const promises = []
      const { subServices } = serviceVersion

      promises[promises.length] = Promise.all(subServices.map(subServiceVersion => {
        return loadSubServiceVersion_Worker({ subServiceVersion, dontClearCache: true, options, path })
      }))

      return Promise.all(promises)
    }).then(function () {
      const { masterOverrides } = serviceVersion

      return Promise.all(masterOverrides.map(masterOverride => {
        return loadSubServiceVersion_Worker({ subServiceVersion: masterOverride, dontClearCache: true, options, path })
      }))
    }).then(function () {
      return serviceVersion
    })
}

export function loadUserService_Worker({ userServiceId, dontClearCache, options, path }) {
  if (!dontClearCache) { clearCache() }
  if (cache[userServiceId]) {
    return Promise.resolve(cache[userServiceId])
  }
  var userService
  return dL.getQuery("UserService")
    .include("createdBy")
    .include("businessRole")
    .include("userRole")
    .include("team")
    .include("draftVersion")
    .include("currentVersion")
    .include("user")
    .get(userServiceId)
    .then(function (obj) {
      userService = dL.loadUserService(obj)
      cache[userServiceId] = userService

      const { draftVersion } = userService
      const { loadDraftVersion } = options ? options : {}

      const promises = []
      if (!options || loadDraftVersion) {
        promises[promises.length] = loadServiceVersion_Worker({ serviceVersionId: draftVersion.id, dontClearCache: true, options, path }).then(function (serviceVersion) {
          userService.draftVersion = serviceVersion
        })
      }

      return Promise.all(promises)
    }).then(function () {
      const { currentVersion } = userService
      const { loadCurrentVersion } = options ? options : {}

      if ((!options || loadCurrentVersion) && currentVersion) {
        return loadServiceVersion_Worker({ serviceVersionId: currentVersion.id, dontClearCache: true, options, path }).then(function (serviceVersion) {
          userService.currentVersion = serviceVersion
        })
      }

    }).then(function () {
      return userService
    })
}

export function loadService_Worker({ serviceId, dontClearCache, options, path }) {
  if (!dontClearCache) { clearCache() }
  if (cache[serviceId]) {
    return Promise.resolve(cache[serviceId])
  }
  var service
  return dL.getQuery("Service")
    .include("createdBy")
    .include("team")
    .include("businessRole")
    .include("draftVersion")
    .include("currentVersion")
    .get(serviceId)
    .then(function (obj) {
      service = dL.loadService(obj)
      cache[serviceId] = service

      const { draftVersion } = service
      const { loadDraftVersion } = options ? options : {}

      const promises = []
      if (!options || loadDraftVersion) {
        promises[promises.length] = loadServiceVersion_Worker({ serviceVersionId: draftVersion.id, dontClearCache: true, options, path }).then(function (serviceVersion) {
          service.draftVersion = serviceVersion
        })
      }

      return Promise.all(promises)
    }).then(function () {
      const { currentVersion } = service
      const { loadCurrentVersion } = options ? options : {}

      const promises = []
      if ((!options || loadCurrentVersion) && currentVersion) {
        promises[promises.length] = loadServiceVersion_Worker({ serviceVersionId: currentVersion.id, dontClearCache: true, options, path }).then(function (serviceVersion) {
          service.currentVersion = serviceVersion
        })
      }

      return Promise.all(promises)
    }).then(function () {
      return service
    })
}

export function getMinDeliveryDays({ workerHours }) {
  const getDeliveryDaysCalculation = function ({ workerHours, deliveryType }) {
    var hoursInDay = 6;
    if (deliveryType == "fast") {
      hoursInDay = 8;
    }
    return Math.ceil(workerHours / hoursInDay);
  };

  const minDeliveryDays = getDeliveryDaysCalculation({ workerHours });
  const minFastDeliveryDays = getDeliveryDaysCalculation({ workerHours, deliveryType: "fast" });

  return { minDeliveryDays, minFastDeliveryDays };
};

export function getServicePriceAndDelivery({ service, userService, serviceVersion, packageId, serviceOptionIds, deliveryType }) {
  return getFinalPriceDays2({ serviceVersion: serviceVersion ? serviceVersion : userService ? userService : service, packageId, serviceOptionIds, deliveryType });
}

export function getServicePrice2({ service, serviceVersion, userService, packageId, serviceOptionIds, deliveryType }) {
  return getFinalPriceDays2({ serviceVersion: serviceVersion ? serviceVersion : userService ? userService : service, packageId, serviceOptionIds, deliveryType });
}

export function getServicePriceCore2({ rootPriceMap, rootPath, serviceVersion, packageId, serviceOptionIds: defaultServiceOptionIds, serviceOptionId, deliveryType }) {
  var serviceOptionIds = defaultServiceOptionIds
  if (!serviceOptionIds) { serviceOptionIds = {} }
  if (serviceOptionId) {
    serviceOptionIds[serviceOptionId] = true
  }

  const { total: price, workHours, days } = getFinalPriceDays2({ rootPriceMap, rootPath, serviceVersion, packageId, serviceOptionIds, deliveryType })
  return { price, workHours, days }
}

export function getFinalPriceDays2({ rootPriceMap, rootPath, serviceVersion, deliveryType, packageId, serviceOptionIds }) {
  //serviceVersion is really serviceData
  const { price: sPrice, days: sDays } = getSubServiceCost2({ rootPriceMap, rootPath, serviceVersion, deliveryType, packageId, serviceOptionIds })
  const { price, days, workHours } = getServicePriceAndDays2({ serviceVersion, deliveryType, packageId, serviceOptionIds })

  return { total: price + sPrice, days: days + sDays, workHours }
}

export function getSubServiceCost2({ subServices2, rootPriceMap, rootPath, serviceVersion, deliveryType, packageId, serviceOptionIds }) {
  var subServiceCost = 0
  var subServiceDays = 0
  const { priceMap, enablePackages } = serviceVersion

  var newPriceMap = priceMap
  if (rootPriceMap && rootPath) {
    newPriceMap = applyPriceMap({ rootPriceMap, rootPath, priceMap })
  }

  var fastDeliveryPercent
  if (enablePackages) {
    const servicePackage = getPackageInService({ serviceVersion, packageId });
    if (servicePackage) {
      fastDeliveryPercent = servicePackage.fastDeliveryPercent
    }
  } else {
    fastDeliveryPercent = serviceVersion.fastDeliveryPercent
  }
  if (!fastDeliveryPercent) { fastDeliveryPercent = 25 }

  const subServices = subServices2 ? subServices2 : getSubServicesForService({ serviceVersion, packageId, serviceOptionIds });

  subServices.forEach(subServiceVersion => {
    const { subService } = subServiceVersion
    const { id } = subService
    const { price, days } = getDataFromPriceMap({ priceMap: newPriceMap, path: `service[${id}]` })

    subServiceCost += price
    subServiceDays += days
  })

  var finalPrice = subServiceCost
  if (deliveryType == "fast") {
    const percentChange = 1 + (fastDeliveryPercent ? fastDeliveryPercent : 25) / 100;
    finalPrice = roundMoney(subServiceCost * percentChange)
  }
  return { price: finalPrice, days: subServiceDays }
}

function getParentPath(path) {
  var parentPath = ""
  const sp = path.split(".")
  for (var i = 0; i < sp.length - 1; i++) {
    if (parentPath) { parentPath += "." }
    parentPath += sp[i]
  }
  return parentPath
}

export function getServicePriceAndDays2({ serviceVersion, packageId, serviceOptionIds, deliveryType }) {
  const { serviceOptions, userRole, businessRole, skillLevel, enablePackages } = serviceVersion
  const hourlyRate = userRole ? userRole.hourlyRate : businessRole && businessRole.rates ? businessRole.rates[skillLevel] : 0;

  const taskIds = {};
  const run = function ({ dt, workHours }) {
    const { deliveryDays, extraFastDeliveryDays, price: finalPrice, isFixedPriced, fastDeliveryPercent, extraFastPrice } = dt;

    var totalDeliveryDays = 0
    if (deliveryType == "fast" && extraFastDeliveryDays != null) {
      totalDeliveryDays += extraFastDeliveryDays
    } else {
      totalDeliveryDays += deliveryDays
    }

    var totalWorkHours = workHours
    var optionCost = 0;

    const opts = getSelectedServiceOptions({ serviceVersion, packageId, serviceOptionIds });
    opts.forEach(serviceOption => {
      const { option, price, isFixedPriced, deliveryDays } = serviceOption;
      const { id } = option
      if (deliveryDays) {
        totalDeliveryDays += deliveryDays
      }
      if (isFixedPriced) {
        optionCost += price;
      } else {
        var tasks = getTasksForServiceOption({ serviceVersion, serviceOptionId: id });
        tasks = tasks.filter(item => !taskIds[item.id]);
        tasks.forEach(item => {
          taskIds[item.id] = true;
        });

        const workHours = getWorkHours({ tasks });

        optionCost += roundMoney(workHours * hourlyRate);

        totalWorkHours += workHours
      }
    });

    var finalPrice2;

    if (isFixedPriced) {
      if (deliveryType == "fast") {
        finalPrice2 = (extraFastPrice + optionCost)
      } else {
        finalPrice2 = (finalPrice + optionCost)
      }
    } else {
      if (deliveryType == "fast") {
        const percentChange = 1 + (fastDeliveryPercent ? fastDeliveryPercent : 25) / 100;
        finalPrice2 = roundMoney((workHours * hourlyRate + optionCost) * percentChange)
      } else {
        finalPrice2 = roundMoney(workHours * hourlyRate + optionCost)
      }
    }
    return { days: totalDeliveryDays, price: finalPrice2, workHours: totalWorkHours };
  };

  if (enablePackages) {
    const tasks = getTasksForBundledPackage({ serviceVersion, packageId, excludeServiceOptions: true });
    tasks.forEach(item => {
      taskIds[item.id] = true;
    });
    const workHours = getWorkHours({ tasks });

    const opts = getIncludedServiceOptionsForBundledPackage({ serviceOptions, packageId });
    opts.forEach(item => { serviceOptionIds[item.option.id] = true })

    const servicePackage = getPackageInService({ serviceVersion, packageId });

    return run({ dt: servicePackage ? servicePackage : {}, tasks, workHours });
  } else {
    const tasks = getTasksForService({ serviceVersion });
    tasks.forEach(item => {
      taskIds[item.id] = true;
    });
    const workHours = getWorkHours({ tasks });

    return run({ dt: serviceVersion, tasks, workHours });
  }
}

export function getWorkerHours({ service, userService, serviceVersion, serviceOptionId, serviceOptionIds: defaultServiceOptionIds, packageId, overrides }) {
  var workerHours = 0;
  var serviceOptionIds = defaultServiceOptionIds
  if (serviceOptionId) {
    serviceOptionIds = {}
    serviceOptionIds[serviceOptionId] = true
  }
  const { enablePackages, enableServiceOptions, tasks } = getVersionData({ service, userService, serviceVersion })

  tasks.forEach(item => {
    var { hours, id, limitedToOptions, limitedToPackages } = item;
    if (overrides && overrides[id]) {
      hours = overrides[id];
    }

    if (!enablePackages || !limitedToPackages || ((packageId && limitedToPackages.includes(packageId)) || limitedToPackages.length == 0)) {
      if (!enableServiceOptions || !limitedToOptions || (limitedToOptions.length == 0 || limitedToOptions.filter(id => (serviceOptionIds ? serviceOptionIds[id] : false)).length > 0)) {
        workerHours += hours;
      }
    }
  });

  return workerHours;
}

export function getSubServiceCostRate_OLD(service, userService, serviceVersion, serviceOptionIds, packageId) {
  var costPrice = 0;
  var costHours = 0;
  return dL.getService(service.id, true)
    .then(function (service) {
      var { businessRole, skillLevel } = getVersionData({ service, userService, serviceVersion })
      if (!skillLevel) {
        skillLevel = "expert";
      }

      const { tasks, subServices, enableServiceOptions, enablePackages } = getVersionData({ service, userService, serviceVersion })

      tasks.forEach(item => {
        const { hours, limitedToOptions, limitedToPackages } = item;

        if (!enablePackages || !limitedToPackages || ((packageId && limitedToPackages.includes(packageId)) || limitedToPackages.length == 0)) {
          if (!enableServiceOptions || !limitedToOptions || (limitedToOptions.length == 0 || limitedToOptions.filter(id => (serviceOptionIds ? serviceOptionIds[id] : false)).length > 0)) {
            costHours += hours;
          }
        }
      });

      if (businessRole) {
        costPrice += businessRole.rates[skillLevel] * costHours;
      }

      if (subServices) {
        return Promise.all(
          subServices.map(subServiceItem => {
            const { total } = subServiceItem;
            costPrice += total;
          })
        );
      }
    })
    .then(function () {
      return { costPrice, costHours };
    });
}
