import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import Moment from "moment-business-days";
import { useParams, useRouteMatch, useHistory } from "react-router-dom";
import "root/App.css";
import { DisplayKeyUser, EditKeyUser, Price } from "root/pack-3";
import { __DEV__ } from "root/dev";
import { RenderServiceOrdersWorker, RenderServiceOrdersBuyer, OrderHistory } from "root/service-orders";
import { BasicTop, ListRender, BoxRowItem, HeaderText, UserItem } from "root/pack-2";
import { TouchButton, BoxItem, DateTimeItem, Section, LabelItem, FlexExpand, FlexRow, TabBar, Text, TextLine, View } from "root/pack-1";
import { CreateAccountPayment } from "root/cart"
import { StaffServiceRecord } from "root/staff-aug"
import { ChatWindow, getChatRoomsWorker, getChatRoomsBuyer } from "root/chat";
import { DisplayAccountPayments, DisplayAccountCharges } from "root/charge-account";
import { RestOfTeam } from "root/users"
import { HashTable } from "root/hashtable";

const { roundMoney, pluralTab, getCountItem, plural, getLabelItem, money } = utils;

function getOrder({ orderId }) {
  var model, charges, payments, staffServices, allTeam
  const stats = {}
  return dL
    .getQuery("Order")
    .include("user")
    .include("createdBy")
    .include("strategyConsultant")
    .include("projectManager")
    .include("chargeAccount")
    .include("project")
    .get(orderId)
    .then(function (obj) {
      model = dL.loadOrder(obj);

      return db
        .getQuery("ServiceOrder")
        .equalTo("order", dL.getObj("Order", orderId))
        .existsObj("parentServiceOrder", false)
        .select([])
        .find();
    })
    .then(function (objs) {
      const serviceOrders = [];
      return Promise.all(
        objs.map(obj => {
          return dL.getServiceOrder2(obj.id).then(function (model) {
            serviceOrders.push(model);
          });
        })
      ).then(function () {
        model.serviceOrders = serviceOrders;
      });
    })
    .then(function () {
      return Promise.all(
        model.serviceOrders.map(item => {
          return db
            .getQuery("WorkRequest")
            .equalTo("serviceOrder", dL.getObj("ServiceOrder", item.id))
            .containedIn("removed", [undefined, false])
            .include("user")
            .find()
            .then(function (objs) {
              item.workRequests = dL.loadObjects("WorkRequest", objs);
            });
        })
      );
    })
    .then(function () {
      return db
        .getQuery("ServiceOrder")
        .equalTo("order", dL.getObj("Order", orderId))
        .existsObj("assignedTo", true)
        .containedIn("removed", [undefined, false])
        .include("assignedTo")
        .select(["assignedTo"])
        .find()
        .then(function (objs) {
          const hash = new HashTable()
          objs.forEach(obj => {
            const assignedTo = obj.get("assignedTo")
            const userId = assignedTo.id
            hash.set(userId, dL.loadUser(assignedTo))
          })
          allTeam = hash.values()
        });
    }).then(function () {
      const { chargeAccount } = model;
      if (chargeAccount) {
        return dL.getChargesAndPayments({ accountId: chargeAccount.id }).then(function (data) {
          charges = data.charges;
          payments = data.payments;
        });
      }
    })
    .then(function () {
      return dL.getQuery("StaffAugService")
        .equalTo("order", dL.getObj("Order", orderId))
        .containedIn("removed", [undefined, false])
        .include("businessRole")
        .include("projectManager")
        .include("assignedTo")
        .find()
        .then(function (objs) {
          staffServices = dL.loadObjects("StaffAugService", objs);
        });
    })
    .then(function () {
      return { order: model, stats, charges, payments, staffServices, allTeam };
    });
}

export function OrderWorker() {
  const { orderId, sectionId } = useParams();
  const [state, setState] = useState({ tabValue: "details", tabValue2: "details", isLoading: true });
  const { stats, allTeam, alerts, isLoading, model, tabValue, tabValue2, charges, payments, staffServices } = state;

  const [refresh, setRefresh] = useState(new Date());

  const getOrderAlerts = function (order) {
    const alerts = []
    const promises = []

    return Promise.all(promises).then(function () { return alerts })
  }

  useEffect(() => {
    return getOrder({ orderId }).then(function ({ order, stats, charges, payments, staffServices, allTeam }) {
      return getOrderAlerts(order).then(function (alerts) {
        setState({ ...state, isLoading: false, model: order, stats, allTeam, staffServices, alerts, charges, payments });
      })
    })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { totalServiceOrders, chargeAccount, id, strategyConsultant, projectManager, status, orderNumber, orderDate, user, total, serviceOrders, deliveryDays, dueDate, project, createdBy } = model;


  const tabs = [];
  tabs.push({ label: "Details", value: "details" });
  tabs.push({ label: "Team", value: "team" });
  if (serviceOrders.length > 0) {
    tabs.push({ label: pluralTab({ list: serviceOrders, label: "Service Order" }), value: "services" });
  }
  tabs.push(getCountItem({ label: "Charges", value: "charges" }, charges));
  tabs.push(getCountItem({ label: "Payments", value: "payments" }, payments));

  //service orders (number)
  //service, package selected, total, status, delivery date (time till delivery?), bundle selected, options selected, included deliverables, start date, can start (need service orders to complete before start)
  //service order: if project manager: show the work requests (new (submitted by workers), pending, declined, rejected)
  //change in

  const chatRoomName = "Order #: " + orderNumber;
  const chatRooms = getChatRoomsWorker({ id, chatRoomName, clientUsers: [user], growlyUsers: [projectManager, strategyConsultant, user] })

  const renderPanel = function () {
    return (
      <React.Fragment>
        {tabValue2 == "history" ? <OrderHistory orderId={orderId} /> : null}

        {tabValue2 == "alerts" ? <View>
          {alerts.map(item => {
            const { message } = item
            return <BoxItem name={message} />
          })}
        </View> : null}
      </React.Fragment>
    );
  };

  return (
    <ChatWindow
      chatRooms={chatRooms}
      tabPanel={renderPanel()}
      onTabChange={item => {
        setState({ ...state, tabValue2: item.value });
      }}
      tabOptions={[{ label: "Chat", value: "chat" }, { label: "History", value: "history" }]}
      tabValue={tabValue2}>
      <BasicTop
        title="Service Contract"
        rightRender={<Price price={total}>
          <TextLine size={18} color="grey" value={utils.getOrderStatus(status)} />
        </Price>}>
        <TextLine top={4} size={14} color="grey" value={"Order Date: " + Moment(orderDate).format("M/D/YYYY [at] h:mm a")} />
      </BasicTop>

      <TabBar
        queryId="tb"
        style={{ marginBottom: 15 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {tabValue == "details" ? (
        <Section>
          <HeaderText subHeader label="Overview:" description="Details about your order." />

          <FlexRow>
            <LabelItem label="Ref #:" value={orderNumber} />
            <LabelItem label="Contract Total:" value={money(total)} />
            <LabelItem label="Status:" value={utils.getOrderStatus(status)} />
          </FlexRow>

          <FlexRow>
            {serviceOrders.length > 0 ? <LabelItem label="Ordered Services:" value={plural(serviceOrders.length, "service")} /> : null}
            {totalServiceOrders ? <LabelItem label="Total Micro Services:" value={plural(totalServiceOrders, "service")} /> : null}
          </FlexRow>

          <FlexRow>
            {project ? <LabelItem label="Project:">{project.name}</LabelItem> : null}
            <LabelItem label="Created By:">
              <UserItem user={createdBy} />
            </LabelItem>
          </FlexRow>
        </Section>
      ) : null}


      {tabValue == "team" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Service Team:" description="Meet your order team." />

            <FlexRow>
              <EditKeyUser
                roleName="strategyConsultant"
                label="Strategy Consultant:"
                readonly={sectionId != "admin"}
                onChange={value => {
                  dL.setOrderStrategyConsultant({ clear: value ? false : true, orderId: model.id, userId: value ? value.id : model.strategyConsultant.id });
                  model.strategyConsultant = value;
                  setState({ ...state, model });
                }}
                value={strategyConsultant}
              />
              <View style={{ width: 35 }} />
              <EditKeyUser
                roleName="projectManager"
                label="Project Manager:"
                readonly={sectionId != "admin"}
                onChange={value => {
                  dL.setOrderProjectManager({ clear: value ? false : true, orderId: model.id, userId: value ? value.id : model.projectManager.id });
                  model.projectManager = value;

                  setState({ ...state, model });
                }}
                value={projectManager}
              />
            </FlexRow>
          </Section>

          {staffServices.length > 0 ? <Section>
            <HeaderText subHeader label="Staff Team:" description="Meet your staff that you have recruited." />

            {staffServices.map(item => {
              return <StaffServiceRecord key={item.id} hideClient item={item} />
            })}
          </Section> : null}

          <RestOfTeam allTeam={allTeam} />
        </React.Fragment>
      ) : null}

      {tabValue == "services" ? (
        <RenderServiceOrdersWorker
          orderId={orderId}
          serviceOrders={serviceOrders}
          onUpdate={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}
      {tabValue == "charges" ? <DisplayAccountCharges model={model} account={chargeAccount} charges={charges} /> : null}
      {tabValue == "payments" ? <DisplayAccountPayments payments={payments} /> : null}
    </ChatWindow>
  );
}

export function OrderBuyer() {
  const { orderId, sectionId } = useParams();
  const [state, setState] = useState({ tabValue: "details", tabValue2: "chat", isLoading: true });
  const { chargeVisible, stats, allTeam, isLoading, model, staffServices, tabValue, tabValue2, charges, payments } = state;
  const [refresh, setRefresh] = useState(new Date());

  useEffect(() => {
    return getOrder({ orderId })
      .then(function ({ order, charges, payments, staffServices, allTeam, stats }) {
        setState({ ...state, isLoading: false, staffServices, allTeam, stats, model: order, charges, payments });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { project, chargeAccount, deliveryDays, createdBy, dueDate, strategyConsultant, projectManager, status, orderNumber, orderDate, user, total, serviceOrders, id } = model;
  const { nextPaymentDate, nextPaymentAmount } = model
  const { contractTotal, totalMonths, paymentTermType, monthlyPayments } = model

  const tabs = [];
  tabs.push({ label: "Details", value: "details" });
  tabs.push({ label: "Team", value: "team" });
  if (serviceOrders.length > 0) {
    tabs.push({ label: pluralTab({ list: serviceOrders, label: "Service Order" }), value: "services" });
  }
  tabs.push(getCountItem({ label: "Charges", value: "charges" }, charges));
  tabs.push(getCountItem({ label: "Payments", value: "payments" }, payments));

  const chatRoomName = "Order #: " + orderNumber;
  const chatRooms = getChatRoomsBuyer({ id, chatRoomName, clientUsers: [user], growlyUsers: [projectManager, strategyConsultant, user] })

  const renderPanel = function () {
    return <React.Fragment>
      {tabValue2 == "history" ? <OrderHistory orderId={orderId} /> : null}
    </React.Fragment>;
  };

  const { totalServiceOrderCount } = stats
  //need order actions: enter questions to start, review deliverables
  //view: contract total, total months, payment type (equal), monthly payments
  //update the cancellation cost to account for the additional credit in the account
  //cancellation cost (once canceled add to the account table)

  return (
    <ChatWindow
      chatRooms={chatRooms}
      tabPanel={renderPanel()}
      onTabChange={item => {
        setState({ ...state, tabValue2: item.value });
      }}
      tabOptions={[{ label: "Chat", value: "chat" }, { label: "History", value: "history" }]}
      tabValue={tabValue2}>
      <BasicTop title="Service Contract" rightRender={<Price price={total} />}>
        <TextLine top={4} size={14} color="grey" value={"Created: " + Moment(orderDate).format("M/D/YYYY [at] h:mm a")} />
      </BasicTop>

      <TabBar
        queryId="tb"
        style={{ marginBottom: 15 }}
        options={tabs}
        onChange={item => {
          setState({ ...state, tabValue: item.value });
        }}
        value={tabValue}
      />

      {chargeVisible ? <CreateAccountPayment amount={chargeVisible} accountId={chargeAccount.id} onCancel={() => {
        setState({ ...state, chargeVisible: null })
      }} onSave={(amount) => {
        alert("Payment made: " + money(amount))
        setState({ ...state, chargeVisible: null })
      }} /> : null}

      {tabValue == "details" ? (
        <Section>
          <HeaderText subHeader label="Overview:" description="Details about your order." />

          <FlexRow>
            <LabelItem label="Ref #:" value={orderNumber} />
            <LabelItem label="Contract Total:" value={money(total)} />
            <LabelItem label="Status:" value={utils.getOrderStatus(status)} />
          </FlexRow>

          <FlexRow>
            {serviceOrders.length > 0 ? <LabelItem label="Ordered Services:" value={plural(serviceOrders.length, "service")} /> : null}
            {totalServiceOrderCount ? <LabelItem label="Total Micro Services:" value={plural(totalServiceOrderCount, "service")} /> : null}
          </FlexRow>

          {contractTotal ? <FlexRow>
            <LabelItem label="Contract Total:" value={money(contractTotal)} />
            {totalMonths?<LabelItem label="Contract Length:" value={plural(totalMonths, "month")} />:null}
            {totalMonths?paymentTermType ? <LabelItem label="Monthly Payments:">
              {paymentTermType == "monthly-equal" ? <TextLine bold label="Monthly:" value={money(roundMoney(total / totalMonths)) + " per month"} /> : monthlyPayments.map((item, index) => {
                return <TextLine bold label={"Month " + (index + 1) + ":"} value={money(item.total)} />
              })}
            </LabelItem> : null: null}
          </FlexRow> : null}

          {nextPaymentDate ? <BoxItem>
            <FlexRow>
              <FlexExpand>
                <TextLine label="Next Payment Date:" value={Moment(nextPaymentDate).format("M/D/YYYY")} />
                <TextLine label="Next Payment Amount:" value={money(nextPaymentAmount)} />
              </FlexExpand>

              <TouchButton label="Pay" onPress={() => {
                setState({ ...state, chargeVisible: nextPaymentAmount })
              }} />
            </FlexRow>
          </BoxItem> : null}


          <FlexRow>
            {project ? <LabelItem label="Project:">{project.name}</LabelItem> : null}
            <LabelItem label="Created By:">
              <UserItem user={createdBy} />
            </LabelItem>
          </FlexRow>
        </Section>
      ) : null}

      {tabValue == "team" ? (
        <React.Fragment>
          <Section>
            <HeaderText subHeader label="Service Team:" description="Meet your order team." />

            <FlexRow>
              <DisplayKeyUser label="Strategy Consultant:" value={strategyConsultant} />
              <View style={{ width: 35 }} />
              <DisplayKeyUser label="Project Manager" value={projectManager} />
            </FlexRow>
          </Section>

          {staffServices.length > 0 ? <Section>
            <HeaderText subHeader label="Staff Team:" description="Meet your staff that you have recruited." />

            {staffServices.map(item => {
              return <StaffServiceRecord key={item.id} hideClient item={item} />
            })}
          </Section> : null}

          <RestOfTeam allTeam={allTeam} />
        </React.Fragment>
      ) : null}

      {tabValue == "services" ? (
        <RenderServiceOrdersBuyer
          sectionId={sectionId}
          orderId={orderId}
          serviceOrders={serviceOrders}
          onUpdate={() => {
            setRefresh(new Date());
          }}
        />
      ) : null}

      { tabValue == "charges" ? <DisplayAccountCharges model={model} account={chargeAccount} charges={charges} /> : null}
      { tabValue == "payments" ? <DisplayAccountPayments payments={payments} /> : null}
    </ChatWindow >
  );
}

export function OrdersAdmin() {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      searchFields={[{ field: "orderNumber", prefix: "ORD" }, { field: "email" }]}
      defaultSort={{ field: "orderDate", desc: true }}
      filters={[
        { label: "All", value: "all", disableStat: true },
        { label: "Pending", value: "pending" },
        {
          label: "No PM",
          value: "no-pm",
          onQuery: function (query) {
            query.existsObj("projectManager", false);
          }
        },
        {
          label: "No SC",
          value: "no-sc",
          onQuery: function (query) {
            query.existsObj("strategyConsultant", false);
          }
        },
        { label: "Working", value: "working" },
        { label: "Completed", value: "completed" }
      ]}
      onWhere={query => {
        const userId = session.user.id;
        if (sectionId == "worker") {
          query.equalTo("accessUsers.userId", userId);
        }
      }}
      type="Order"
      emptyLabel="No orders found."
      includes={["user", "serviceOrders", "team"]}
      title="Service Contracts"
      description="Manage service contracts."
      statuses={utils.orderStatuses}
      renderItem={item => {
        const { team, serviceTypes, id, orderDate, orderNumber, user, status, total, serviceOrders } = item;
        return (
          <BoxRowItem>
            <FlexRow>
              <View style={{ flex: 1 }}>
                <DateTimeItem value={orderDate} fromNow />
                <TextLine
                  bold
                  size={16}
                  style={{ marginTop: 6 }}
                  onPress={() => {
                    history.push(`/${sectionId}/order/${id}`);
                  }}>
                  Order #: {orderNumber}
                </TextLine>
                {user ? <UserItem label="Client:" user={user} style={{ marginTop: 8 }} /> : null}
                {team ? <BoxItem style={{ marginTop: 8 }} >
                  <TextLine label="Team:" value={team.name} />
                </BoxItem> : null}
              </View>
              <View alignRight>
                <TextLine size={22} value={utils.money(total)} />
                <TextLine color="grey" value={plural(serviceOrders.length, "service")} />
                <TextLine size={12} uppercase color="grey" value={utils.getOrderStatus(status)} />
              </View>
            </FlexRow>

            {serviceTypes ? (
              <TextLine size={14} color="grey" top={6}>
                {serviceTypes.map((item, index) => (index != 0 ? ", " : "") + getLabelItem(utils.serviceTypes, item))}
              </TextLine>
            ) : null}
          </BoxRowItem>
        );
      }}
    />
  );
}

export function OrdersBuyer({ projectId }) {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      subHeader={projectId != null}
      searchFields={[{ field: "orderNumber", prefix: "ORD" }, { field: "email" }]}
      defaultSort={{ field: "orderDate", desc: true }}
      filters={[{ label: "Pending", value: "pending" }, { label: "Working", value: "working" }, { label: "Completed", value: "completed" }]}
      onWhere={query => {
        const userId = session.user.id;
        if (projectId) {
          query.equalTo("project", dL.getObj("Project", projectId));
        } else {
          query.equalTo("user", dL.getObj("User", userId));
        }
      }}
      type="Order"
      emptyLabel="No orders found."
      includes={["user", "serviceOrders", "project"]}
      title="Service Contracts"
      description="View and manage your service contract."
      statuses={utils.orderStatuses}
      renderItem={item => {
        const { project, serviceTypes, id, orderDate, orderNumber, status, total, serviceOrders } = item;
        return (
          <BoxRowItem
            onPress={() => {
              history.push(`/${sectionId}/order/${id}`);
            }}>
            <FlexRow alignTop>
              <View style={{ flex: 1 }}>
                <DateTimeItem value={orderDate} fromNow />
                <TextLine bold top={6}
                  onPress={() => {
                    history.push(`/${sectionId}/order/${id}`);
                  }} value={"Order #:" + orderNumber} />
                {serviceTypes ? (
                  <TextLine size={14} color="grey" top={6}>
                    {serviceTypes.map((item, index) => (index != 0 ? ", " : "") + getLabelItem(utils.serviceTypes, item))}
                  </TextLine>
                ) : null}

                {project ? <TextLine label="Project:" value={project.name} /> : null}
              </View>
              <View alignRight>
                <TextLine size={22} value={money(total)} />
                <TextLine color="grey" value={plural(serviceOrders.length, "service")} />
                <TextLine size={12} uppercase color="grey" value={utils.getOrderStatus(status)} />
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function OrdersWorker({ teamId }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const userId = session.user.id;

  return (
    <ListRender
      subHeader={teamId != null}
      searchFields={[{ field: "orderNumber", prefix: "ORD" }]}
      defaultSort={{ field: "orderDate", desc: true }}
      topFilters={[
        {
          label: "All",
          value: "all",
          onQuery: query => {
            query.equalTo("accessUsers.userId", userId);
          },
          autoHide: true
        },
        {
          label: "PM",
          value: "pm",
          onQuery: query => {
            query.equalTo("projectManager", dL.getObj("User", userId));
          },
          autoHide: true
        },
        {
          label: "SC",
          value: "sc",
          onQuery: query => {
            query.equalTo("strategyConsultant", dL.getObj("User", userId));
          },
          autoHide: true
        }
      ]}
      filters={[{ label: "Pending", value: "pending" }, { label: "Working", value: "working" }, { label: "Completed", value: "completed" }]}
      type="Order"
      emptyLabel="No orders found."
      includes={["team", "user", "serviceOrders", "strategyConsultant", "projectManager"]}
      title="Service Contracts"
      description="View and manage service contracts that you are part of."
      statuses={utils.orderStatuses}
      onWhere={query => {
        if (teamId) {
          query.equalTo("team", dL.getObj("Team", teamId));
        }
      }}
      renderItem={item => {
        return <RenderOrderWorker item={item} />
      }}
    />
  );
}

export function RenderOrderWorker({ item }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const { team, serviceTypes, id, orderDate, orderNumber, user, status, total, serviceOrders, strategyConsultant, projectManager } = item;

  return (
    <BoxRowItem key={id}>
      <FlexRow alignTop>
        <FlexExpand>
          <DateTimeItem value={orderDate} fromNow />
          <TextLine bold size={18} top={6}
            onPress={() => {
              history.push(`/${sectionId}/order/${id}`);
            }} value={"Ref #:" + orderNumber} />

          <FlexRow top={12}>
            {user ? <UserItem label="Client:" user={user} style={{ marginRight: 25 }} /> : null}
            {projectManager ? <UserItem label="PM:" user={projectManager} style={{ marginRight: 25 }} /> : null}
            {strategyConsultant ? <UserItem label="SC:" user={strategyConsultant} /> : null}
            {team ? <BoxItem style={{ marginTop: 8 }} >
              <TextLine label="Team:" value={team.name} />
            </BoxItem> : null}
          </FlexRow>
        </FlexExpand>

        <View alignRight>
          <TextLine size={18} value={utils.money(total)} />
          <TextLine color="grey" value={plural(serviceOrders.length, "service")} />

          <TextLine size={12} top={10} uppercase color="grey" value={utils.getOrderStatus(status)} />
        </View>
      </FlexRow>

      {serviceTypes ? (
        <TextLine size={14} color="grey" top={12}>
          {serviceTypes.map((item, index) => (index != 0 ? ", " : "") + getLabelItem(utils.serviceTypes, item))}
        </TextLine>
      ) : null}
    </BoxRowItem>
  );
}