import http from "root/utils/http";
import utils from "root/utils/functions";

const __DEV__ = process.env.NODE_ENV == "development" == true;

var factoryFunct = function () {
  var factory = {
    cache: {},
    masterUrl: "",
    logout: function () {
      var userId;
      if (factory.user) {
        userId = factory.user.id;
        delete factory.sessionId;
        delete factory.user;
      }

      localStorage.removeItem("loginData");
      factory.dispatch("on-logout");

      if (userId) {
        return utils.logout().then(function () {
          factoryMaster = factoryFunct();
        });
      } else {
        factoryMaster = factoryFunct();
        return Promise.resolve();
      }
    }
  };

  if (__DEV__) {
    factory.masterUrl = "http://macos.ngrok.io";
  } else {
    factory.masterUrl = "https://app.niftywidgets.io";
  }

  factory.watches = {};

  factory.dispatch = function (action, data) {
    var arr = factory.watches[action];

    if (arr) {
      arr.map(item => {
        item(action, data);
      });
    }
  };

  factory.watch = function (actions, funct) {
    var add1 = function (action) {
      var arr = factory.watches[action];
      if (!arr) {
        arr = [];
        factory.watches[action] = arr;
      }

      arr.push(funct);
    };

    if (Array.isArray(actions)) {
      actions.map(item => {
        add1(item);
      });

      return function () {
        actions.map(item => {
          var arr = factory.watches[item];
          for (var i = 0; i < arr.length; i++) {
            if (arr[i] == funct) {
              arr.splice(i, 1);
              break;
            }
          }
        });
      };
    } else {
      add1(actions);

      return function () {
        var arr = factory.watches[actions];
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] == funct) {
            arr.splice(i, 1);
            break;
          }
        }
      };
    }
  };

  factory.unwatch = function (action, funct) {
    var arr = factory.watches[action];

    if (!arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] == funct) {
          arr.splice(i, 1);
          break;
        }
      }
    }
  };

  return factory;
};

var factoryMaster = factoryFunct();

export default factoryMaster;
