import "./App.css";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Modal2 from "root/modal";
import { ActivityIndicator, TouchableOpacity } from "react-native";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";
import http from "root/utils/http";
import arrayMove from "array-move";
import { SortableHandle } from "react-sortable-hoc";
import utils from "root/utils/functions";
import session from "root/utils/session";
import colors from "root/colors";
import Moment from "moment-business-days";
import { useHistory, useLocation } from "react-router-dom";
import "root/App.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashRestore, faArchive, faBook, faUser, faComment, faPencilAlt, faCheck, faArrowsAlt, faTrash, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import RelativePortal from "root/rp";
import "bootstrap/dist/css/bootstrap.css";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-widgets/dist/css/react-widgets.css";
import "react-datetime/css/react-datetime.css";
import styles from "root/styles";
import { Portal } from "react-portal";
import dL from "root/utils/dl";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { components } from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import { fontAwesome } from "root/icon-defs"
import { Spinner } from "react-bootstrap"
import { SelectOptions } from "root/pack-2"
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';

const { sortByString, plural } = utils;

export class ColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: '241',
      g: '112',
      b: '19',
      a: '1',
    },
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb })
  };

  render() {
    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        { this.state.displayColorPicker ? <div style={styles.popover}>
          <div style={styles.cover} onClick={this.handleClose} />
          <SketchPicker color={this.state.color} onChange={this.handleChange} />
        </div> : null}

      </div>
    )
  }
}

export function IconPicker(props) {
  const { value, onChange, ...others } = props

  const iconProps = {
    icons: fontAwesome,
    theme: 'bluegrey',
    renderUsing: 'class',
    isMulti: false,
    closeOnSelect: true
  };

  return <FormItem  {...others} labelStyle={{ marginBottom: 5 }}>
    <FontIconPicker {...iconProps} value={value} onChange={(value) => {
      onChange && onChange(value)
    }} />
  </FormItem>
}

export function RenderIcon({ icon, style }) {
  return icon ? <i className={icon} style={style} /> : null
}

export function Tags({ onChange, value }) {
  const promiseOptions = inputValue => {
    return dL.getQuery("Tag")
      .containedIn("removed", [undefined, false])
      .startsWith("searchText", inputValue)
      .find()
      .then(function (objs) {
        return sortByString(objs.map(obj => {
          return { name: obj.get("name"), id: obj.id }
        }), "name")
      })
  }

  return (
    <AsyncCreatableSelect
      getOptionLabel={item => {
        if (item.label) { return item.label }
        return item.name
      }}
      getOptionValue={item => {
        if (item.value) { return item.value }
        return item.id
      }}
      menuPortalTarget={document.body}
      styles={{
        control: provided => ({
          ...provided,
          boxShadow: "none"
        }),
        valueContainer: provided => ({
          ...provided,
          padding: "8px"
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
      }}
      menuPlacement="auto"
      menuShouldScrollIntoView={false}
      value={value}
      isMulti
      onChange={(items) => {
        const newItem = items.find(item => item.__isNew__)
        if (newItem) {
          const id = utils.guid()
          const { label } = newItem
          dL.getObj("Tag", id)
            .set("name", label.toLowerCase())
            .set("actualName", label)
            .set("searchText", [...(label).toLowerCase().split(" "), label.toLowerCase()])
            .set("createdAt", new Date())
            .set("createdBy", dL.getObj("User", session.user.id))
            .save()
          newItem.name = label
          newItem.id = id
          delete newItem.label
          delete newItem.value
          delete newItem.__isNew__
        }
        onChange && onChange(items)
      }}
      cacheOptions
      defaultOptions
      loadOptions={promiseOptions}
    />
  );
}

export function Hide() {
  return <View />;
}

export function Columns({ style, left, middle, right }) {
  return (
    <View style={[{ flexDirection: "row" }, style]}>
      {left}
      {middle ? (
        <React.Fragment>
          <View style={{ width: 25 }} />
          {middle}
        </React.Fragment>
      ) : null}
      <View style={{ width: 25 }} />
      {right}
    </View>
  );
}

export function Spacer() {
  return <View style={{ width: 15 }} />;
}

export function ImageElement({ style, label, value, onChange, allowClear }) {
  const [state, setState] = useState({});
  const [progress, setProgress] = useState(0);
  const refC = useRef();
  const rect = { flex: 1, marginBottom: 23 };
  const textS = { fontSize: 16, marginBottom: 12 };
  const textS2 = { fontSize: 16, marginTop: 8, textDecoration: "underline" };
  const textS3 = { fontSize: 16, marginTop: 8 };

  var value1 = state.imgSrc;
  if (!value1 && value) {
    value1 = "https://growly.s3.amazonaws.com/" + value.path + value.file;
  }
  return (
    <View style={[rect, style]}>
      <Text style={textS}>{label}</Text>

      <FlexRow>
        {value1 ? <img src={value1} style={{ height: "100px", width: "100px", borderRadius: 100 }} /> : null}

        <View style={{ marginLeft: 25 }}>
          {progress ? (
            <Text style={textS3}>Uploading: {utils.roundNumber(progress, 1)}%</Text>
          ) : (
            <FlexRow>
              <TouchableOpacity
                onPress={() => {
                  refC.current.click();
                }}>
                <Text style={textS2}>Change</Text>
              </TouchableOpacity>

              {allowClear ? <TouchableOpacity
                onPress={() => {
                  onChange && onChange()
                }} style={{ marginLeft: 15 }}>
                <Text style={textS2}>Clear</Text>
              </TouchableOpacity> : null}
            </FlexRow>
          )}
        </View>
      </FlexRow>
      <input
        ref={ref => {
          refC.current = ref;
        }}
        style={{ display: "none" }}
        type="file"
        onChange={e => {
          var files = e.target.files;
          if (files.length == 0) {
            return;
          }

          var file = files[0];
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);

          reader.onloadend = function (e) {
            setState({ ...state, imgSrc: [reader.result] });
          }.bind(this);

          const path = "apps/root/";
          const fileName = utils.guid();
          const query = {
            fileName: path + fileName,
            fileType: file.type
          };

          http
            .post(session.masterUrl + "/sign_s3", query)
            .then(function (response) {
              return response.json();
            })
            .then(function (result) {
              const returnData = result.data.returnData;
              const signedRequest = returnData.signedRequest;
              const options = {
                headers: {
                  "Content-Type": file.type
                },
                onUploadProgress: function (progressEvent) {
                  setProgress((progressEvent.loaded / progressEvent.total) * 100);
                }
              };
              axios
                .put(signedRequest, file, options)
                .then(result => {
                  onChange({
                    fileName: file.name,
                    path,
                    file: fileName,
                    type: file.type,
                    size: file.size
                  });

                  setProgress(0);
                })
                .catch(error => {
                  alert("ERROR " + JSON.stringify(error));
                });
            });
        }}
      />
    </View>
  );
}

export function UploadFileElement({ style, children, onProgress, onChange }) {
  const [state, setState] = useState({});
  const refC = useRef();

  return (
    <React.Fragment>
      <TouchableOpacity
        style={style}
        onPress={() => {
          refC.current.click();
        }}>
        {children}
      </TouchableOpacity>

      <input
        ref={ref => {
          refC.current = ref;
        }}
        style={{ display: "none" }}
        type="file"
        onChange={e => {
          const files = e.target.files;
          if (files.length == 0) {
            return;
          }

          const file = files[0];

          const path = "apps/root/";
          const fileName = utils.guid();
          const query = {
            fileName: path + fileName,
            fileType: file.type
          };

          http
            .post(session.masterUrl + "/sign_s3", query)
            .then(function (response) {
              return response.json();
            })
            .then(function (result) {
              const returnData = result.data.returnData;
              const signedRequest = returnData.signedRequest;
              const options = {
                headers: {
                  "Content-Type": file.type
                },
                onUploadProgress: function (progressEvent) {
                  onProgress && onProgress((progressEvent.loaded / progressEvent.total) * 100);
                }
              };
              axios
                .put(signedRequest, file, options)
                .then(result => {
                  onChange({
                    fileName: file.name,
                    path,
                    file: fileName,
                    type: file.type,
                    size: file.size
                  });
                })
                .catch(error => {
                  alert("ERROR " + JSON.stringify(error));
                });
            });
        }}
      />
    </React.Fragment>
  );
}

export function FileElement({ readonly, style, label, value, onChange }) {
  const [state, setState] = useState({});
  const [progress, setProgress] = useState(0);
  const refC = useRef();
  const rect = { flex: 1, marginBottom: 23 };
  const textS = { fontSize: 16, marginBottom: 12 };
  const textS2 = { fontSize: 16, marginTop: 8, textDecoration: "underline" };
  const textS3 = { fontSize: 16, marginTop: 8 };

  var value1;
  if (value) {
    value1 = http.baseUrl + "/sign_file?remotePath=" + value.path + value.file + "&name=" + value.fileName;
  }

  return (
    <View style={[rect, style]}>
      {label ? <Text style={textS}>{label}</Text> : null}

      <FlexRow>
        {value1 ? (
          <a href={value1} download={value.fileName}>
            {value.fileName}
          </a>
        ) : null}

        {progress ? (
          <Text style={textS3}>Uploading: {utils.roundNumber(progress, 1)}%</Text>
        ) : !readonly ? (
          <TouchableOpacity
            onPress={() => {
              refC.current.click();
            }}>
            <Text style={textS2}>Upload file</Text>
          </TouchableOpacity>
        ) : null}
      </FlexRow>
      <input
        ref={ref => {
          refC.current = ref;
        }}
        style={{ display: "none" }}
        type="file"
        onChange={e => {
          var files = e.target.files;
          if (files.length == 0) {
            return;
          }

          var file = files[0];
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);

          reader.onloadend = function (e) {
            setState({ ...state, imgSrc: [reader.result] });
          }.bind(this);

          const path = "apps/root/";
          const fileName = utils.guid();
          const query = {
            fileName: path + fileName,
            fileType: file.type
          };

          http
            .post(session.masterUrl + "/sign_s3", query)
            .then(function (response) {
              return response.json();
            })
            .then(function (result) {
              const returnData = result.data.returnData;
              const signedRequest = returnData.signedRequest;
              const options = {
                headers: {
                  "Content-Type": file.type
                },
                onUploadProgress: function (progressEvent) {
                  setProgress((progressEvent.loaded / progressEvent.total) * 100);
                }
              };
              axios
                .put(signedRequest, file, options)
                .then(result => {
                  onChange({
                    fileName: file.name,
                    path,
                    file: fileName,
                    type: file.type,
                    size: file.size
                  });

                  setProgress(0);
                })
                .catch(error => {
                  alert("ERROR " + JSON.stringify(error));
                });
            });
        }}
      />
    </View>
  );
}

export function FileElements({ readonly, style, label, value, onChange }) {
  const [state, setState] = useState({});
  const [progress, setProgress] = useState(0);
  const refC = useRef();
  const rect = { flex: 1, marginBottom: 23 };
  const textS = { fontSize: 16, marginBottom: 12 };
  const textS2 = { fontSize: 16, marginTop: 8, textDecoration: "underline" };
  const textS3 = { fontSize: 16, marginTop: 8 };
  const headerS = { fontSize: 14, color: colors.darkGrey };
  var value1;
  if (value) {
    value1 = value.map(item => {
      return http.baseUrl + "/sign_file?remotePath=" + item.path + item.file + "&name=" + item.fileName;
    });
  }

  return (
    <View style={[rect, style]}>
      {readonly ? <Text style={headerS}>{label}</Text> : <Text style={textS}>{label}</Text>}

      <FlexRow>
        {value1
          ? value1.map((item, index) => {
            var fileItem = value[index];
            return (
              <FlexRow>
                <a href={value1} download={fileItem.fileName}>
                  {fileItem.fileName}
                </a>
                {!readonly ? (
                  <TrashIcon
                    style={{ marginLeft: 10 }}
                    onPress={() => {
                      value.splice(index, 1);
                      onChange(value);
                    }}
                  />
                ) : null}
              </FlexRow>
            );
          })
          : null}

        {progress ? (
          <Text style={textS3}>Uploading: {utils.roundNumber(progress, 1)}%</Text>
        ) : !readonly ? (
          <TouchableOpacity
            onPress={() => {
              refC.current.click();
            }}>
            <Text style={textS2}>Upload file</Text>
          </TouchableOpacity>
        ) : null}
      </FlexRow>
      <input
        ref={ref => {
          refC.current = ref;
        }}
        style={{ display: "none" }}
        type="file"
        onChange={e => {
          var files = e.target.files;
          if (files.length == 0) {
            return;
          }

          var file = files[0];
          var reader = new FileReader();
          var url = reader.readAsDataURL(file);

          reader.onloadend = function (e) {
            setState({ ...state, imgSrc: [reader.result] });
          }.bind(this);

          const path = "apps/root/";
          const fileName = utils.guid();
          const query = {
            fileName: path + fileName,
            fileType: file.type
          };

          http
            .post(session.masterUrl + "/sign_s3", query)
            .then(function (response) {
              return response.json();
            })
            .then(function (result) {
              const returnData = result.data.returnData;
              const signedRequest = returnData.signedRequest;
              const options = {
                headers: {
                  "Content-Type": file.type
                },
                onUploadProgress: function (progressEvent) {
                  setProgress((progressEvent.loaded / progressEvent.total) * 100);
                }
              };
              axios
                .put(signedRequest, file, options)
                .then(result => {
                  var arr = value ? value : [];
                  arr.push({
                    fileName: file.name,
                    path,
                    file: fileName,
                    type: file.type,
                    size: file.size
                  });
                  onChange(arr);
                  setProgress(0);
                })
                .catch(error => {
                  alert("ERROR " + JSON.stringify(error));
                });
            });
        }}
      />
    </View>
  );
}

export function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children ? children : <View />;
}

export function Avatar({ style, label, value, size, icon }) {
  if (!size) {
    size = 100;
  }

  var value1;
  if (value) {
    value1 = "https://growly.s3.amazonaws.com/" + value.path + value.file;
  }
  return <View style={{ display: "flex" }}>
    <FlexRow style={{ position: "relative" }}>
      <View style={[{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#e0e0e0", borderRadius: 100, height: size + "px", width: size + "px", overflow: "hidden" }, style]}>
        {value1 ? <img src={value1} style={{ height: size + "px", width: size + "px" }} /> : <FontAwesomeIcon icon={faUser} style={{ fontSize: size / 2, color: "grey" }} />}
      </View>
      {icon ? <View style={{ position: "absolute", bottom: 0, right: 0 }}>{icon}</View> : null}
    </FlexRow>
  </View>
}

export function NoRecords({ style, label }) {
  return <TextLine style={style} value={label} />;
}

export function BackButton({ onPress }) {
  const history = useHistory();

  return (
    <TouchableOpacity
      onPress={() => {
        if (onPress) {
          onPress();
        } else {
          history.goBack();
        }
      }}>
      <Text style={{ fontSize: 14, fontWeight: "bold" }}>BACK</Text>
    </TouchableOpacity>
  );
}

export function FlexExpand({ children, style, onPress }) {
  return (
    <View style={[{ flex: 1 }, style]} onPress={onPress}>
      {children}
    </View>
  );
}

export function FlexRow({ wrap, className, top, bottom, alignTop, alignBottom, alignRight, children, style, onPress, noLinkClass }) {
  return (
    <View noLinkClass={noLinkClass} className={className} onPress={onPress} style={{ ...{ marginBottom: bottom, marginTop: top, flexDirection: "row", alignItems: alignBottom ? "flex-end" : alignTop ? "flex-start" : "center", justifyContent: alignRight ? "flex-end" : null, flexWrap: wrap ? "wrap" : null }, ...style }}>
      {children}
    </View>
  );
}

export function View(props) {
  const { noLinkClass, style, onPress, alignRight, alignCenter, children, top, bottom, left, right, ...others } = props;
  var style2 = style;
  var { className } = props;
  if (!className) {
    className = "";
  }
  if (Array.isArray(style)) {
    style2 = {};
    style.forEach(item => {
      style2 = Object.assign(style2, item);
    });
  }
  var style1 = {};
  if (style2) {
    if (style2.flexDirection == "row") {
      style1 = { display: "flex" };
    }
  }
  if (onPress && !noLinkClass) {
    className += "link-class ";
  }
  if (alignRight) {
    style1.display = "flex"
    style1.flexDirection = "column"
    style1.alignItems = "flex-end"
  }
  if (alignCenter) {
    style1.display = "flex"
    style1.flexDirection = "column"
    style1.alignItems = "center"
  }

  return (
    <div className={className} style={{ marginBottom: bottom, marginTop: top, marginLeft: left, marginRight: right, ...style1, ...style2 }} {...others} onClick={onPress}>
      {children}
    </div>
  );
}

export function UnDeleteIcon({ onPress, style, tooltip }) {
  return (
    <SimpleTooltip label={tooltip}>
      <View
        style={style}
        onPress={e => {
          if (onPress) {
            e.stopPropagation();
            onPress();
          }
        }}>
        <FontAwesomeIcon icon={faTrashRestore} style={{ fontSize: 18, color: "grey" }} />
      </View>
    </SimpleTooltip>
  );
}

export function TrashIcon({ onPress, style, tooltip }) {
  return (
    <SimpleTooltip label={tooltip}>
      <View
        style={style}
        onPress={e => {
          if (onPress) {
            e.stopPropagation();
            onPress();
          }
        }}>
        <FontAwesomeIcon icon={faTrash} style={{ fontSize: 18, color: "grey" }} />
      </View>
    </SimpleTooltip>
  );
}

export function ArchiveIcon({ onPress, style, tooltip }) {
  return (
    <SimpleTooltip label={tooltip}>
      <View
        style={style}
        onPress={e => {
          if (onPress) {
            e.stopPropagation();
            onPress();
          }
        }}>
        <FontAwesomeIcon icon={faArchive} style={{ fontSize: 18, color: "grey" }} />
      </View>
    </SimpleTooltip>
  );
}

export function MoveIcon({ onPress, style, className, tooltip }) {
  return (
    <SimpleTooltip label={tooltip}>
      <View className={className}>
        <TouchableOpacity
          style={style}
          onPress={e => {
            if (onPress) {
              e.stopPropagation();
              onPress();
            }
          }}>
          <FontAwesomeIcon icon={faArrowsAlt} style={{ fontSize: 18, color: "grey" }} />
        </TouchableOpacity>
      </View>
    </SimpleTooltip>
  );
}

export function TrainingIcon({ count, onPress, style, color, tooltip }) {
  return (
    <TouchableOpacity
      style={style}
      onPress={e => {
        if (onPress) {
          e.stopPropagation();
          onPress();
        }
      }}>
      <SimpleTooltip label={tooltip}>
        <View style={{ position: "relative" }}>
          {count ? <View style={{ backgroundColor: "red", borderColor: "white", borderWidth: 1, borderStyle: "solid", paddingLeft: 4, paddingRight: 4, borderRadius: 150, position: "absolute", top: -6, right: -8 }}><TextLine style={{ marginTop: -6, marginBottom: -3 }} color="white" bold size={11} value={count} /></View> : null}
          <FontAwesomeIcon icon={faBook} style={{ color: color ? color : "grey" }} />
        </View>
      </SimpleTooltip>
    </TouchableOpacity>
  );
}

export function CommentIcon({ onPress, iconStyle, style, color, tooltip }) {
  return (
    <TouchableOpacity
      style={style}
      disabled={!onPress}
      onPress={e => {
        if (onPress) {
          e.stopPropagation();
          onPress();
        }
      }}>
      <SimpleTooltip label={tooltip}>
        <FontAwesomeIcon icon={faComment} style={{ color: color ? color : "#c0c0c0", ...iconStyle }} />
      </SimpleTooltip>
    </TouchableOpacity>
  );
}

export function EditIcon({ onPress, style, tooltip }) {
  return (
    <SimpleTooltip label={tooltip}>
      <View
        style={style}
        onPress={e => {
          if (onPress) {
            e.stopPropagation();
            onPress();
          }
        }}>
        <FontAwesomeIcon icon={faPencilAlt} style={{ fontSize: 18, color: "grey" }} />
      </View>
    </SimpleTooltip>
  );
}

export function CheckIcon({ tooltip, disabled, checked, onPress, style }) {
  return (
    <SimpleTooltip label={tooltip}>
      <TouchableOpacity
        style={style}
        disabled={!onPress || disabled}
        onPress={e => {
          if (onPress) {
            e.stopPropagation();
            onPress();
          }
        }}>
        <FontAwesomeIcon icon={faCheck} style={{ fontSize: 22, color: checked ? "green" : "#c0c0c0" }} />
      </TouchableOpacity>
    </SimpleTooltip>
  );
}

export function Loading() {
  return <Modal2 visible={true} dialogClassName={"model-vertical-2"}>
    <View style={{ alignSelf: "stretch", display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: 0, right: 0, bottom: 0, left: 0, paddingTop: 150 }}>
      <Spinner variant="light" animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </View>
  </Modal2>
}

export function ModalBasic({ description, buttons, title, large, children, onCancel, cancelText, okText, onOk, full, notCentered, headerRight }) {
  var eProps = {};
  var className = "";

  if (full) {
    className += " modal-90w";
    eProps = { dialogClassName: "modal-90w", ariaLabelledby: "example-custom-modal-styling-title" };
  }

  if (large) {
    className += " modal-550w";
    eProps = { dialogClassName: "modal-550w", ariaLabelledby: "example-custom-modal-styling-title" };
  }
  if (!notCentered) {
    className += " modal-dialog-centered modal-dialog-scrollable model-vertical";
    //eProps.centered = true;
  } else {
    className += " modal-dialog-scrollable model-vertical";
  }
  //style={full ? {"max-height": "calc(100vh - 210px)", "overflow-y": "auto"} : null}
  return (
    <Modal2 visible={true} dialogClassName={className}>
      {title ? (
        <div className="modal-header" style={{ padding: "10px 0px 8px 16px", backgroundColor: "#f0f0f0" }}>
          <FlexRow style={{ flex: 1 }}>
            <FlexExpand>
              <h5 className="modal-title" style={{ fontSize: 24 }}>
                {title}
              </h5>
              {description ? <small style={{ fontSize: 15, color: "grey", marginTop: -10 }}>{description}</small> : null}
            </FlexExpand>
            {headerRight}
          </FlexRow>
        </div>
      ) : null}
      <div className="modal-body">{children}</div>
      <div className="modal-footer" style={{ padding: 10, backgroundColor: "#f0f0f0" }}>
        {onCancel ? (
          <TouchButton
            variant="secondary"
            onPress={() => {
              onCancel();
            }}
            label={cancelText ? cancelText : "Cancel"}
          />
        ) : null}

        {buttons &&
          buttons.map(button => {
            return <TouchButton {...button} />;
          })}

        {onOk ? (
          <TouchButton
            onPress={() => {
              return onOk();
            }}
            label={okText ? okText : "Ok"}
          />
        ) : null}
      </div>
    </Modal2>

  );

  return (
    <Modal
      show={true}
      {...eProps}
      onHide={() => {
        onCancel && onCancel();
      }}>
      {title ? (
        <Modal.Header>
          <Modal.Title style={{ fontSize: 22 }}>{title}</Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body style={full ? { "max-height": "calc(100vh - 210px)", "overflow-y": "auto" } : null}>{children}</Modal.Body>
      <Modal.Footer>
        {onCancel ? (
          <TouchButton
            variant="secondary"
            onPress={() => {
              onCancel();
            }}
            label="Cancel"
          />
        ) : null}

        {buttons &&
          buttons.map(button => {
            return <TouchButton {...button} />;
          })}

        {onOk ? (
          <TouchButton
            onPress={() => {
              return onOk();
            }}
            label={okText ? okText : "Ok"}
          />
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

export function LinkButton2({ className, onPress, description, label, style, textStyle, children }) {
  const textS2 = { fontSize: 16 };

  return (
    <div className={`link-class ${className}`}>
      <TouchableOpacity style={style} onPress={onPress}>
        <Text style={[textS2, textStyle]}>
          <FlexRow>
            {children ? children : label}
            <HelpIcon label={description} style={{ marginLeft: 8, marginTop: -10 }} />
          </FlexRow>
        </Text>
      </TouchableOpacity>
    </div>
  );
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below). 
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value]
  );

  return debouncedValue;
}

export function LinkButton({ onPress, label, style, textStyle }) {
  const textS2 = { fontSize: 14, textDecoration: "underline" };

  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Text style={[textS2, textStyle]}>{label}</Text>
    </TouchableOpacity>
  );
}

export const DragHandle = SortableHandle(({ style }) => {
  return <MoveIcon className="my-handle" style={style} />
});

export function BoxItem({ bold, smallHeader, spacer, boxHeight, strikeThrough, onBoxPress, icons, onPress, icon, nameSmall, render, required, name, description, hasMove, onEdit, onDelete, rightRender, children, style, rightText, checked, hasCheck, rightSide, verticalIcons }) {
  const rect = { backgroundColor: "white", borderStyle: "solid", borderWidth: 1, borderColor: "#e0e0e0", padding: 12, borderRadius: 9, marginTop: 12, marginBottom: 12, cursor: onPress ? "pointer" : null, marginRight: spacer ? 15 : null };
  const rectSq = { height: boxHeight, width: boxHeight }
  const requiredS = { fontSize: 13, color: "red" };

  const IconRow = !verticalIcons ? FlexRow : View
  const iconStyle = !verticalIcons ? { marginRight: 8 } : { marginBottom: 8 }
  return (
    <View className="card card-bordered card-hover-shadow" style={{ ...rect, ...(onBoxPress ? { cursor: "pointer" } : null), ...(boxHeight ? rectSq : null), ...style }} onPress={onBoxPress}>
      <FlexRow alignTop>
        <FlexExpand>
          <FlexExpand onPress={onPress}>
            <FlexRow>
              {icon ? <RenderIcon icon={icon} style={{ marginRight: 15, fontSize: 35 }} /> : null}
              <TextLine strikeThrough={strikeThrough} bold size={smallHeader ? 15 : 18} value={name} />
              {nameSmall ? <TextLine bold={bold} strikeThrough={strikeThrough} style={{ marginLeft: 8 }} size={14} value={nameSmall} /> : null}
            </FlexRow>

            {required ? <Text style={requiredS}>Required</Text> : null}
          </FlexExpand>

          {description ? <TextLine top={4} size={smallHeader ? 13 : 14} color="grey" value={description} /> : null}
          {children}
        </FlexExpand>

        <View alignRight>{rightRender ? rightRender : <Text style={{ fontSize: smallHeader ? 15 : 18 }}>{rightText}</Text>}</View>

        {hasCheck ? <CheckIcon checked={checked} style={{ marginLeft: 15 }} onPress={onPress} /> : null}

        {hasMove || onEdit || onDelete || rightRender ? (
          <View style={{ marginLeft: 10 }}>
            <IconRow style={{ justifyContent: "flex-end" }}>
              {icons ? <View style={iconStyle}>{icons}</View> : null}
              {hasMove ? <DragHandle style={iconStyle} /> : null}
              {onEdit ? <EditIcon style={iconStyle} onPress={onEdit} /> : null}
              {onDelete ? <TrashIcon onPress={onDelete} /> : null}
            </IconRow>
            {rightSide}
          </View>
        ) : null}
      </FlexRow>

      {render}
    </View>
  );
}

export class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: props.defaultValue };
  }

  clear = () => {
    this.setState({ address: "" });
  };

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => console.log("Success", latLng))
      .catch(error => console.error("Error", error));

    this.setState({ address });

    if (this.props.onChange) {
      this.props.onChange(address);
    }
  };

  render() {
    return (
      <PlacesAutocomplete searchOptions={{ types: ["(cities)"] }} highlightFirstSuggestion={true} value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Enter city..."
              })}
              className="form-control"
              style={this.props.style}
            />
            <div className="autocomplete-dropdown-container">
              {loading && true == false && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                const style = suggestion.active ? { backgroundColor: "#fafafa", cursor: "pointer" } : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}>
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export function DisplayCenterBox({ label, icon, description, children, buttonLabel, onPress }) {
  return <CenterBox style={{ minHeight: 375, flex: 1, paddingTop: 35, paddingBottom: 35, paddingLeft: 50, paddingRight: 50 }}>
    <FontAwesomeIcon icon={icon} style={{ fontSize: 75, color: "grey" }} />
    <TextLine top={25} size={22} bold value={label} />
    <TextLine top={12} alignCenter value={description} />
    <View style={{ height: 35 }} />
    {children}
    <TouchButton label={buttonLabel} onPress={onPress} />
  </CenterBox>
}

export function CenterBox({ children, style }) {
  return (
    <BoxItem4 style={{ padding: 25, display: "flex", justifyContent: "center", alignItems: "center", ...style }}>
      {children}
    </BoxItem4>
  );
}

export function NoRecordsBox({ label, children, style }) {
  return (
    <CenterBox style={style}>
      <TextLine value={label} bottom={children ? 15 : null} />
      {children}
    </CenterBox>
  );
}

export function BoxItem4({ children, style }) {
  const rect = { backgroundColor: "white", borderStyle: "solid", borderWidth: 1, borderColor: "#e0e0e0", padding: 12, borderRadius: 9, marginTop: 12, marginBottom: 12 };

  return (
    <View className="card card-bordered card-hover-shadow" style={[rect, style]}>
      {children}
    </View>
  );
}

export function Counter({ label, value, type }) {
  return (
    <TextLine bold>
      {label}: {utils.money(value)}
    </TextLine>
  );

  useEffect(() => {
    alert(1);
    eval("var counter = new HSCounter($(this)).init();");
  }, []);

  return (
    <div class="col-lg-3 col-sm-6 mb-5 mb-lg-0">
      <div
        class="js-counter h3"
        data-hs-counter-options='{
           "isCommaSeparated": true
         }'>
        {type == "money" ? "$" : ""}
        {value}
      </div>
      <h4>{label}</h4>
    </div>
  );
}

export function ItemExpandSection({ label, defaultExpanded, children, onChange }) {
  const [expanded, setExpanded] = useState(defaultExpanded)

  return (
    <View>
      <FlexRow onPress={() => {
        var stateExp
        if (expanded) {
          stateExp = false
        } else {
          stateExp = true
        }
        setExpanded(stateExp)
        onChange && onChange(stateExp)
      }}>
        {label ? <FlexExpand>
          <TextLine bold size={14} value={label} />
        </FlexExpand> : null}

        <TextLine color="grey" top={6} >
          {expanded ? "- shrink" : "+ expand"}
        </TextLine>
      </FlexRow>

      {expanded ? children : null}
    </View>
  );
}

export function ItemExpand({ expanded, onPress }) {
  return (
    <TextLine color="grey" top={6} onPress={onPress}>
      {expanded ? "- shrink" : "+ expand"}
    </TextLine>
  );
}

export function DeleteConfirm({ onCancel, onConfirm }) {
  return (
    <SweetAlert allowEscape showCancel confirmBtnCssClass="btn btn-danger" danger confirmBtnText="Delete" cancelBtnText="Cancel" title="Delete!" onCancel={onCancel} onConfirm={onConfirm}>
      Are you sure you want to delete this item?
    </SweetAlert>
  );
}

export function DateTimeItem({ value, fromNow, alignRight, style }) {
  return (
    <View style={style} alignRight={alignRight} >
      <TextLine value={Moment(value).format("M/D/YY")} />
      {fromNow ? <TextLine size={12} top={-6} value={Moment(value).fromNow()} /> : null}
    </View>
  );
}

export function BoxItem3({ hasCheck, children, checked, style }) {
  const rect = { backgroundColor: "white", borderStyle: "solid", borderWidth: 1, borderColor: "#e0e0e0", padding: 12, borderRadius: 9, marginTop: 12, marginBottom: 12 };

  return (
    <View className="card card-bordered card-hover-shadow" style={[rect, style]}>
      <FlexRow alignTop>
        <FlexExpand>{children}</FlexExpand>

        {hasCheck ? <CheckIcon checked={checked} style={{ marginLeft: 15 }} /> : null}
      </FlexRow>
    </View>
  );
}
export function HelpIcon({ label, style }) {
  return label ? (
    <OverlayTrigger delay={{ hide: 400 }} overlay={<Tooltip id="button-tooltip">{label}</Tooltip>} >
      <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: 18, color: "grey", ...style }} />
    </OverlayTrigger >
  ) : null;
}

export function SimpleTooltip({ label, style, children }) {
  return label ? (
    <OverlayTrigger delay={{ hide: 400 }} overlay={<Tooltip id="button-tooltip">{label}</Tooltip>} >
      { children}
    </OverlayTrigger >
  ) : (
    children
  );
}

export function RenderArrayItems({ label, value, list, onGetText, onGetValue }) {
  if (!value || value.length == 0) {
    return "";
  }
  var arr = [];
  value.forEach(itemId => {
    const item = list.find(item2 => (onGetValue ? onGetValue(item2) : item2.id) == itemId);
    if (item) {
      arr.push(item);
    }
  });
  arr = arr.map(item => (onGetText ? onGetText(item) : item.name));
  if (arr.length > 0) {
    return <TextLine color="grey" size={12} value={label + " " + utils.renderArray(arr)} />;
  } else {
    return "";
  }
}

export function Circle2({ selected }) {
  const size = 24;
  return selected ? <div style={{ borderRadius: 100, width: size, height: size, backgroundColor: "#EC433B" }} /> : <div style={{ borderRadius: 100, width: size, height: size, backgroundColor: "#f0f0f0" }} />;
}

export function EditDialog({ children, style }) {
  return <View style={{ maxWidth: 550, marginHorizontal: 23, ...style }}>{children}</View>;
}

export function SmallHeader(props) {
  const { label, ...others } = props;
  return <TextLine grey bold value={label} {...others} />;
}

export function LabelItem({ bold, style, inline, label, description, value: defaultValue, onValue, children, lastItem, hideIfNull, fontSize, onPress, alignRight }) {
  var value = defaultValue ? defaultValue : onValue ? onValue() : null
  if ((value == null || value == "") && hideIfNull) {
    return <View />;
  }

  const headerS = { fontSize: 14, color: "#494949" };
  const detailsS = { fontSize: 12, color: "grey" };
  const valueS = { fontSize: fontSize ? fontSize : 16, fontWeight: bold ? "600" : null };
  const valueSE = { fontSize: fontSize ? fontSize - 2 : 14, color: "grey" };

  if (Array.isArray(value)) {
    value = utils.renderArray(value);
  }

  const spacerRight = 25;
  if (inline) {
    return (
      <FlexRow onPress={onPress} alignRight={alignRight} style={{ marginBottom: 15, marginRight: !lastItem ? spacerRight : 0, ...style }}>
        <TextLine style={{ marginRight: 6 }} textStyle={headerS} value={label} />
        {!children ? value != null && value != "" ? <Text style={valueS}>{value}</Text> : <Text style={valueSE}>Empty</Text> : children}

        <HelpIcon label={description} style={{ marginLeft: 8, marginTop: -8 }} />
      </FlexRow>
    );
  } else {
    return (
      <TouchableOpacity disabled={!onPress} onPress={onPress} style={[{ textAlign: alignRight ? "right" : null, marginBottom: 15, marginRight: !lastItem ? spacerRight : 0 }, style]}>
        <FlexRow>
          <TextLine bottom={4} style={{ flex: 1 }} textStyle={headerS} value={label} />
          <HelpIcon label={description} style={{ marginLeft: 8, marginTop: -12 }} />
        </FlexRow>
        <View>
          {!children ? value != null && value != "" ? <Text style={valueS}>{value}</Text> : <Text style={valueSE}>Empty</Text> : null}

          {children}
        </View>
      </TouchableOpacity>
    );
  }
}

export function SelectBox({ children, components:componentsM, allowCreate, onCreate, onGetOptions, maxItems, groupBy, description, multiple, readonly, options, required, placeholder, textStyle, style, label, value, onChange, onGetText, textField, valueField, onButtonPress, addLabel, onEditPress, editLabel }) {
  const rect = { flex: 1, marginBottom: 28 };
  //const textS = { fontSize: 16, marginBottom: 12 };
  const textS = { fontSize: 14, color: "#494949", marginBottom: 12 };
  const requiredS = { marginLeft: 10, fontSize: 13, color: "red" };
  const textS2 = { fontSize: 16, marginTop: 8, textDecoration: "underline" };

  const Menu = props => {
    const optionSelectedLength = props.getValue().length || 0;

    return (
      <components.Menu {...props}>
        {!maxItems || optionSelectedLength < maxItems ? (
          props.children
        ) : (
          <div style={{ padding: 10 }}>Max selected items reached.</div>
        )}
      </components.Menu>
    );
  };

  const isValidNewOption = (inputValue, selectValue) => {
    return !maxItems || inputValue.length > 0 && selectValue.length < maxItems;
  }

  const T = allowCreate ? AsyncCreatableSelect : Select

  return (
    <View style={[rect, style]}>
      {label ? (
        <FlexRow bottom={-4}>
          <FlexExpand>
            <TextLine textStyle={textS} value={label} />
          </FlexExpand>

          {required ? <Text style={requiredS}>Required</Text> : null}
          {maxItems ? <Text style={requiredS}>Max {plural(maxItems, "item")}</Text> : null}

          <HelpIcon label={description} style={{ marginTop: -8, marginLeft: 8 }} />
        </FlexRow>
      ) : null}

      {multiple ? (
        <T
          getNewOptionData={(inputValue, optionLabel) => {
            const item = {}
            item[textField ? textField : "label"] = optionLabel
            item[valueField ? valueField : "value"] = inputValue
            return item
          }}
          formatCreateLabel={(inputValue) => {
            return "Create New: " + inputValue
          }}
          onCreateOption={onCreate}
          defaultOptions
          loadOptions={onGetOptions}
          components={{ Menu, ...componentsM }}
          isValidNewOption={isValidNewOption}
          placeholder={placeholder ? placeholder : "Select"}
          hideSelectedOptions={true}
          className="select-box"
          isMulti
          isDisabled={readonly}
          options={options}
          value={value}
          menuPortalTarget={document.body}
          styles={{
            control: provided => ({
              ...provided,
              boxShadow: "none"
            }),
            valueContainer: provided => ({
              ...provided,
              padding: "8px"
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
          }}
          menuPlacement="auto"
          menuShouldScrollIntoView={false}
          getOptionLabel={item => {
            if (onGetText) {
              return onGetText(item)
            }
            return item[textField ? textField : "label"];
          }}
          getOptionValue={item => {
            return item[valueField ? valueField : "value"];
          }}
          onChange={item => {
            onChange(item ? item : []);
          }}
        />
      ) : (
        <T
          components={{ ...componentsM }}
          isValidNewOption={(inputValue, selectValue, options, accessors) => {
            if (!inputValue) { return false }
            return !options.find(item => item[textField ? textField : "label"].toLowerCase() == inputValue.toLowerCase())
          }}
          getNewOptionData={(inputValue, optionLabel) => {
            const item = {}
            item[textField ? textField : "label"] = optionLabel
            item[valueField ? valueField : "value"] = inputValue
            return item
          }}
          formatCreateLabel={(inputValue) => {
            return "Create New: " + inputValue
          }}
          onCreateOption={onCreate}
          defaultOptions
          loadOptions={onGetOptions}
          placeholder={placeholder ? placeholder : "Select"}
          className="select-box"
          isDisabled={readonly}
          options={options}
          value={value}
          menuPortalTarget={document.body}
          styles={{
            control: provided => ({
              ...provided,
              boxShadow: "none"
            }),
            valueContainer: provided => ({
              ...provided,
              padding: "8px"
            }),
            menuPortal: base => ({ ...base, zIndex: 9999 })
          }}
          menuPlacement="auto"
          menuShouldScrollIntoView={false}
          getOptionLabel={item => {
            if (onGetText) {
              return onGetText(item)
            }
            return item[textField ? textField : "label"];
          }}
          getOptionValue={item => {
            return item[valueField ? valueField : "value"];
          }}
          onChange={item => {
            onChange(item ? item : []);
          }}
        />
      )}
      <FlexRow>
        {onEditPress ? (
          <TouchableOpacity
            onPress={() => {
              onEditPress(value);
            }}
            style={{ marginRight: 10 }}>
            <Text style={textS2}>{editLabel}</Text>
          </TouchableOpacity>
        ) : null}
        {onButtonPress ? (
          <TouchableOpacity
            onPress={() => {
              onButtonPress();
            }}>
            <Text style={textS2}>{addLabel}</Text>
          </TouchableOpacity>
        ) : null}
      </FlexRow>
      {children}
    </View>
  );
}

export class Countdown extends React.Component {
  timer = null;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.startTime();
  }

  startTime = () => {
    var th = this;
    var props = th.props;

    clearTimeout(th.timer);
    th.timer = setInterval(() => {
      const { date, onExpired } = props;

      var ms = Moment(date).diff(new Date(), "milliseconds");

      if (ms < 0) {
        clearTimeout(th.timer);
        if (onExpired) {
          onExpired();
        }
      }

      th.setState({});
    }, 1000);
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidUpdate(prevProps) {
    var th = this;

    const { date } = th.props;
    var prevDate = prevProps.date;

    if (date && prevDate && date.getTime() != prevDate.getTime()) {
      th.startTime();
    } else if (date && !prevDate) {
      th.startTime();
    }
  }

  render() {
    const unitsText = {
      fontSize: 12,
      color: "#fdfdfd",
      textTransform: "uppercase"
    };
    const numText = {
      fontSize: 20,
      color: "#fdfdfd"
    };

    var props = this.props;
    const { date, style, seperator, seperatorStyle, boxStyle, textStyle, hideLabels } = props;

    var ms = Moment(date).diff(new Date(), "milliseconds");
    if (ms < 0) {
      ms = 0;
    }

    var rtn = utils.getCountdownBreakdown(ms);

    const itemRect2 = {
      paddingTop: 2,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      height: 56,
      width: 65,
      marginRight: !seperator ? 15.3 : 0,
      borderRadius: 6.7,
      backgroundColor: "grey"
    };

    const timerRect = {
      marginRight: !seperator ? -15.3 : 0,
      flexDirection: "row",
      alignItems: "center"
    };

    return (
      <View style={timerRect}>
        {rtn.days > 0 ? (
          <React.Fragment>
            <View style={[itemRect2, boxStyle]}>
              <TextLine textStyle={{ ...numText, ...textStyle }}>{utils.padWithZero(rtn.days)}</TextLine>
              {!hideLabels ? <Text style={unitsText}>Days</Text> : null}
            </View>
            {seperator ? seperator : null}
          </React.Fragment>
        ) : null}
        {rtn.hours > 0 ? (
          <React.Fragment>
            <View style={[itemRect2, boxStyle]}>
              <TextLine textStyle={{ ...numText, ...textStyle }}>{utils.padWithZero(rtn.hours)}</TextLine>
              {!hideLabels ? <Text style={unitsText}>Hours</Text> : null}
            </View>
            {seperator ? seperator : null}
          </React.Fragment>
        ) : null}
        <View style={[itemRect2, boxStyle]}>
          <TextLine textStyle={{ ...numText, ...textStyle }}>{utils.padWithZero(rtn.minutes)}</TextLine>
          {!hideLabels ? <Text style={unitsText}>Mins</Text> : null}
        </View>
        {seperator ? seperator : null}
        <View style={[itemRect2, boxStyle]}>
          <TextLine textStyle={{ ...numText, ...textStyle }}>{utils.padWithZero(rtn.seconds)}</TextLine>
          {!hideLabels ? <Text style={unitsText}>Secs</Text> : null}
        </View>
      </View>
    );
  }
}

export class DropdownLink extends React.Component {
  constructor(props) {
    super(props);

    var th = this;
    th.state = {
      show: props.open
    };

    th._setShowAsyncTimer = null;
  }

  _handleShow = e => {
    e.stopPropagation();
    this._setShowAsync(true);
  };

  _handleHide = e => {
    if (e) {
      e.stopPropagation();
    }
    this._setShowAsync(false);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ show: nextProps.open });
  }

  componentWillUnmount() {
    clearTimeout(this._setShowAsyncTimer);
  }

  _setShowAsync(show) {
    clearTimeout(this._setShowAsyncTimer);

    var _setShowAsyncTimer = setTimeout(() => {
      this.props.onToggle && this.props.onToggle(show);
      this.setState({ show });
    }, 0);
  }

  render() {
    const { show } = this.state;

    var props = {};

    if (this.props.position == "right") {
      props.right = 0;
    } else {
      props.left = 0;
    }

    return (
      <React.Fragment>
        <div
          style={{ display: "inline-block" }}
          onClick={
            show
              ? e => {
                this._handleHide(e);
              }
              : e => {
                this._handleShow(e);
              }
          }>
          {this.props.toggleContent}
        </div>

        <RelativePortal component="div" top={0} right={0} {...props} onOutClick={show ? this._handleHide : null} style={{ zIndex: 99999 }}>
          {show && (
            <div
              style={{
                zIndex: 99999,
                padding: 10,
                backgroundColor: "#FFF",
                border: "1px solid grey"
              }}>
              {this.props.children}
            </div>
          )}
        </RelativePortal>
      </React.Fragment>
    );
  }
}

export function BreadCrumb({ label }) {
  const smallTextS = { fontSize: 12, color: "grey", textTransform: "uppercase" };
  return <TextLine style={{ marginBottom: 2 }} textStyle={smallTextS} value={label} />;
}

export function Section({ children, style }) {
  return <View style={[{ marginBottom: 35 }, style]}>{children}</View>;
}

export function Toggle({ children, style, label, description, value, onChange }) {
  const rect = { flex: 1, marginBottom: 23 };
  const rectT = { marginBottom: 12, marginLeft: 25, height: 35, backgroundColor: value ? "green" : "grey", width: 65, borderRadius: 15.7, padding: 5, flexDirection: value ? "row-reverse" : "row" };
  const circle = { borderRadius: 100, backgroundColor: "white", width: 25, height: 25 };
  const textS = { fontSize: 16, color: "#494949", };

  return (
    <FlexRow style={{ ...rect, ...style }}>
      <FlexExpand style={{ marginRight: 25 }}>
        <TextLine textStyle={textS} value={label} right={10} size={16} />

        {description ? (
          <TextLine grey size={12} value={description} />
        ) : null}
        {children}
      </FlexExpand>

      <TouchableOpacity
        style={rectT}
        onPress={() => {
          var pvalue;
          if (!value) {
            pvalue = true;
          } else {
            pvalue = false;
          }
          if (onChange) {
            onChange(pvalue);
          }
        }}>
        <View style={circle} />
      </TouchableOpacity>
    </FlexRow>
  );
}

export function FormItem({ bold, required, label, description, children, style, box, labelStyle }) {
  const rect = { flex: 1, marginBottom: 23 };
  const textS = { fontSize: 16, };
  const requiredS = { marginLeft: 10, fontSize: 13, color: "red" };

  const boxS = { borderWidth: 1, borderStyle: "solid", borderColor: "#e0e0e0", padding: 10, borderRadius: 6.7 };

  return (
    <View style={[rect, style]}>
      <FlexRow bottom={6} style={{ marginBottom: 12, ...labelStyle }}>
        <TextLine bold={bold} style={{ flex: 1 }} textStyle={textS} value={label} />

        {required ? <Text style={requiredS}>Required</Text> : null}

        <HelpIcon label={description} style={{ marginLeft: 8 }} />
      </FlexRow>

      <View style={box ? boxS : null}>{children}</View>
    </View>
  );
}

export function MyInput({ showDescription, focus, boxStyle, id, description, hideRequired, rightLabelRender, type, validation, lastItem, isLoading, readonly, required, maxLength, placeholder, textStyle, multiline, style, label, value, onChange, inputType }) {
  const [height, setHeight] = useState(0);
  const refC = useRef();

  const maxCharsS = { fontSize: 13, color: "grey" };
  const requiredS = { marginLeft: 10, fontSize: 13, color: "red" };

  useEffect(() => {
    if (focus) {
      refC.current.focus()
    }
  }, [])

  return (
    <View style={[{ marginBottom: 28 }, boxStyle]}>
      {label ? (
        <FlexRow bottom={6}>
          <FlexExpand>{label}</FlexExpand>
          {rightLabelRender}
          {maxLength ? (
            <Text style={maxCharsS}>
              {value ? value.length : 0} of {maxLength}
            </Text>
          ) : null}
          {required && !hideRequired ? <Text style={requiredS}>Required</Text> : null}

          {!showDescription ? <HelpIcon label={description} style={{ marginTop: -8, marginLeft: 8 }} /> : null}
        </FlexRow>
      ) : null}

      {showDescription ? <TextLine value={description} /> : null}

      {multiline ? (
        <textarea
          ref={refC}
          style={style}
          id={id}
          type={type ? type : "text"}
          aria-label={label}
          data-msg={validation ? validation : "Please enter a valid value."}
          required={required}
          className="form-control"
          editable={!readonly}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={event => {
            onChange(event.target.value);

            if (multiline) {
              setHeight(event.nativeEvent.srcElement.scrollHeight);
            }
          }}>
          {value}
        </textarea>
      ) : (
        <input
          ref={refC}
          style={style}
          id={id}
          type={type ? type : "text"}
          aria-label={label}
          data-msg={validation ? validation : "Please enter a valid value."}
          required={required}
          className="form-control"
          editable={!readonly}
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={event => {
            onChange(event.target.value);

            if (multiline) {
              setHeight(event.nativeEvent.srcElement.scrollHeight);
            }
          }}
        />
      )}
    </View>
  );

  /*
  return (
    <div class="js-form-message form-group">
      <label class="input-label" for="signinSrEmailExample1">
        <FlexRow style={{marginBottom: 6}}>
          <TextLine style={{flex: 1}} value={label} />
          {maxLength ? (
            <Text style={maxCharsS} class="input-label">
              {value ? value.length : 0} of {maxLength}
            </Text>
          ) : null}
          {required ? <Text style={requiredS}>Required</Text> : null}
        </FlexRow>
      </label>
      <View style={{position: "relative", width: "100%"}}>
        <TextInput
          aria-label={label}
          data-msg="Please enter a valid value."
          required={required}
          className="form-control"
          editable={!readonly}
          maxLength={maxLength}
          placeholder={placeholder}
          multiline={multiline}
          secureTextEntry={secureTextEntry}
          style={[{width: "100%", minHeight: height, borderWidth: 2, borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7}, textStyle]}
          value={value}
          onChange={event => {
            onChange(event.target.value);

            if (multiline) {
              setHeight(event.nativeEvent.srcElement.scrollHeight);
            }
          }}
        />
        {isLoading ? <Spinner style={{position: "absolute", right: 8, top: 12}} animation="grow" size="sm" /> : null}
      </View>
    </div>
  );

  return (
    <View style={[rect, style]}>
      <FlexRow style={{marginBottom: 6}}>
        <TextLine style={{flex: 1}} textStyle={textS} value={label} />
        {maxLength ? (
          <Text style={maxCharsS}>
            {value ? value.length : 0} of {maxLength}
          </Text>
        ) : null}
        {required ? <Text style={requiredS}>Required</Text> : null}
      </FlexRow>

      <View style={{position: "relative", width: "100%"}}>
        <TextInput
          aria-label={label}
          data-msg="Please enter a valid value."
          required={required}
          className="form-control"
          editable={!readonly}
          maxLength={maxLength}
          placeholder={placeholder}
          multiline={multiline}
          secureTextEntry={secureTextEntry}
          style={[{width: "100%", minHeight: height, borderWidth: 2, borderColor: "#c0c0c0", padding: 10, borderRadius: 6.7}, textStyle]}
          value={value}
          /*
        onBlur={event => {
          const text = event.target.value;
          if (inputType == "money") {
            if (!utils.isNumber(text)) {
              alert("Value is not a number.");
              onChange(null);
            }
          } else if (inputType == "integer") {
            if (!utils.isNumber(text)) {
              alert("Value is not a number.");
              onChange(null);
            } else {
              var number = utils.isNumber(text);
              if (number < 0) {
                alert("Value is not a valid number.");
                onChange(null);
              }
            }
          }
        }}
          onChange={event => {
            onChange(event.target.value);

            if (multiline) {
              setHeight(event.nativeEvent.srcElement.scrollHeight);
            }
          }}
        />
        {isLoading ? <Spinner style={{position: "absolute", right: 8, top: 12}} animation="grow" size="sm" /> : null}
      </View>
    </View>
  );
*/
}

export function TouchButtonOnOff(props) {
  const { ONLabel, OFFLabel, value, onPress, ...leftOver } = props;

  return <TouchButton onPress={onPress} label={value ? ONLabel : OFFLabel} grey={value ? false : true} {...leftOver} />;
}

export function MicroButton(props) {
  return <TouchButton size="sm" micro {...props} />
}

export function TouchButton({ full, micro, className, variant, size, fontStyle, onPress, label, style, spacer, grey, description, children }) {
  const [state, setState] = useState({});
  const { isLoading } = state;

  const textS = { fontSize: 14, color: "white" };
  const rect2 = { position: "absolute", right: 3, top: 10 };
  const rect = { paddingHorizontal: 10, position: "relative" };
  const spacerStyle = spacer ? { marginRight: 10 } : {};

  if (full) {
    rect.width = "100%"
  }

  return (
    <SimpleTooltip label={description}>
      <Button
        className={(className ? className : micro ? "btn-mn" : null) + " transition-3d-hover"}
        size={size}
        variant={variant ? variant : grey ? "secondary" : "primary"}
        style={{ ...rect, ...style, ...spacerStyle }}
        onClick={e => {
          e.stopPropagation();
          if (!isLoading && onPress) {
            const rtn = onPress();
            if (rtn && rtn.then) {
              setState({ isLoading: true });
              rtn.then(function () {
                setState({ isLoading: false });
              });
            }
          }
        }}>
        {children}
        <Text style={{ ...textS, ...fontStyle }}>{label}</Text>
        {isLoading ? <ActivityIndicator color="white" style={rect2} /> : null}
      </Button>
    </SimpleTooltip>
  );
}

export function Text({ className, style, children }) {
  var style2 = style;
  if (Array.isArray(style2)) {
    style2 = {};
    style.forEach(item => {
      style2 = Object.assign(style2, item);
    });
  }

  return (
    <span className={className} style={style2}>
      {children}
    </span>
  );
}

export function TextLine({ strikeThrough, alignCenter, strike, alignRight, left, right, onPress, top, bottom, uppercase, bold, style, textStyle, value, children, size, color, maxLines, spacer, label, tooltip, underline }) {
  if (children || value != null) {
    var className = "";
    const style2 = {};
    const styleValue = {}
    var styleExt = { fontSize: 16 };
    if (top) {
      style2.marginTop = top;
    }
    if (left) {
      style2.marginLeft = left;
    }
    if (right) {
      style2.marginRight = right;
    }
    if (spacer) {
      style2.marginRight = 10;
    }
    if (bottom) {
      style2.marginBottom = bottom;
    }
    if (alignRight) {
      style2.alignText = "right";
      //style2.textAlign = "flex-end";
      //styleValue.alignText = "right";
      style2.justifyContent = "flex-end";
    }
    if (alignCenter) {
      //style2.textAlign = "center";
      style2.justifyContent = "center";
    }
    if (maxLines) {
      className += "d-text ";
    }
    if (strike) {
      styleExt.textDecoration = "line-through";
    }
    if (bold) {
      styleExt.fontWeight = "bold";
    }
    if (onPress) {
      className += "link-class ";
    }
    if (size) {
      styleExt.fontSize = size;
    }
    if (color) {
      styleExt.color = color;
    }
    if (uppercase) {
      styleValue.textTransform = "uppercase";
    }
    if (strikeThrough) {
      styleValue.textDecoration = "line-through";
    }
    if (underline) {
      styleValue.textDecoration = "underline";
    }

    return (
      <FlexRow
        onPress={e => {
          if (onPress) {
            e.stopPropagation();
            onPress();
          }
        }}
        noLinkClass
        style={{ ...style, ...style2 }}>
        <Text className={className} style={[textStyle, styleExt]}>
          {label ? label + " " : null}
          <Text style={{ ...textStyle, ...styleValue }}>{value != null ? value : children}</Text>
        </Text>
        {tooltip ? <HelpIcon label={tooltip} style={{ marginLeft: 8 }} /> : null}
      </FlexRow>
    );
  } else {
    return <View />;
  }
}

const getParamValue = function ({ name, list }) {
  const params = new URLSearchParams(document.location.search.substring(1));
  const value = params.get(name);
  if (value) {
    const sp = value.split(",")
    return list.find(item => sp.includes(item.value));
  }
}

const addToParam = function ({ history, name, value, removeValue }) {
  const params = new URLSearchParams(document.location.search.substring(1));
  if (value) {
    var paramValue = params.get(name);
    if (paramValue) {
      if (removeValue) {
        var sp = paramValue.split(",").filter(item => item != removeValue)
        var str = ""
        sp.forEach(value => {
          if (str) { str += "," }
          str += value
        })
        paramValue = str
      }
      if (!paramValue.split(",").includes(value)) {
        params.set(name, (paramValue ? paramValue + "," : "") + value);
      }
    } else {
      params.set(name, value);
    }
  } else {
    params.delete(name);
  }
  history.replace({ search: params.toString() });
}

const removeFromParam = function ({ history, name, value }) {
  const params = new URLSearchParams(document.location.search.substring(1));
  const paramValue = params.get(name);
  if (paramValue) {
    var sp = paramValue.split(",")
    sp = sp.filter(value1 => value1 != value)
    var str = ""
    sp.forEach(value => {
      if (str) { str += "," }
      str += value
    })
    if (str) {
      params.set(name, str)
    } else {
      params.delete(name)
    }
    history.replace({ search: params.toString() })
  }
}

export function TabBar({ autoHide, queryId, style, options, value, onChange }) {
  const history = useHistory();

  const fOptions = options.filter(item => item != null);

  useEffect(() => {
    return function () {
      removeFromParam({ history, name: queryId, value })
    }
  }, [value]);

  useEffect(() => {
    if (queryId) {
      const item = getParamValue({ name: queryId, list: fOptions })
      if (item) {
        onChange(item);
      }
    }
  }, [history]);

  if (autoHide && fOptions.length == 1) {
    return <View />;
  }

  const maxCount = 6
  var list = fOptions
  var showMore
  var moreOptions
  if (fOptions.length > maxCount) {
    showMore = true
    list = fOptions.slice(0, maxCount)
    moreOptions = fOptions.slice(maxCount).map(item => {
      const { label, value } = item
      return { label, value, item }
    })
  }

  return (
    <div style={style}>
      <ul class="nav nav-segment nav-pills scrollbar-horizontal" role="tablist">
        {list.slice().map(item => {
          const { label } = item;
          return (
            <li class="nav-item" key={item.value}>
              <a
                class={"nav-link " + (value == item.value ? "active" : "")}
                data-toggle="pill"
                onClick={() => {
                  const { value: newValue } = item;

                  if (queryId) {
                    addToParam({ history, name: queryId, value: newValue, removeValue: value })
                  }

                  onChange(item);
                }}
                role="tab">
                {label}
              </a>
            </li>
          );
        })}
        {showMore ? <SelectOptions
          options={moreOptions}
          onChange={selItem => {
            const { item } = selItem
            const { value: newValue } = item;

            if (queryId) {
              addToParam({ history, name: queryId, value: newValue, removeValue: value })
            }

            onChange(item);
          }}>
          <li class="nav-item">
            <a class="nav-link" data-toggle="pill" role="tab">
              More
              </a>
          </li>
        </SelectOptions> : null}
      </ul>
    </div>
  );
}

export function TabBar2({ style, options, value, onChange }) {
  const rect = { flexDirection: "row" };
  const textS = { paddingRight: 12, fontSize: 18 };
  const textSS = { fontWeight: "bold" };

  return (
    <View style={[rect, style]}>
      {options.map(item => {
        const { label } = item;
        return (
          <TouchableOpacity
            key={item.value}
            onPress={() => {
              onChange(item);
            }}>
            <Text style={[textS, value == item.value ? textSS : null]}>{label}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
}

export function VerticalNavBar({ options, value, isSelected, onChange }) {
  const rect = {};
  const textS = { fontSize: 18, paddingHorizontal: 10 };
  const textSS = { fontWeight: "bold" };

  return (
    <View style={rect}>
      {options.map((item, index) => {
        const { label, onClick } = item;
        var sel;
        if (item.url) {
          sel = !onClick && isSelected ? isSelected(item) : value.startsWith(item.url);
        }
        return (
          <TouchableOpacity
            key={item.value}
            onPress={() => {
              onChange(item);
            }}>
            <Text style={[textS, sel ? textSS : null]}>{label}</Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
}

const SortItem = SortableElement(({ item, renderItem, iindex }) => {
  return <View style={{ zIndex: 999999 }}>{renderItem(item, iindex)}</View>;
});

const SortableList2 = SortableContainer(({ renderItem, items }) => {
  return (
    <View>
      {items.map((x, i) => {
        return <SortItem renderItem={renderItem} iindex={i} item={x} index={i} key={x.id} />;
      })}
    </View>
  );
});

export function SortableList(props) {
  const { items, onChange, emptyRender, disabled, renderItem } = props;

  if (emptyRender && items.length == 0) {
    return emptyRender;
  }

  if (disabled) {
    return (
      <View>
        {items.map((x, i) => {
          return renderItem(x, i);
        })}
      </View>
    );
  } else {
    return (
      <SortableList2
        useDragHandle={true}
        onSortEnd={({ oldIndex, newIndex }) => {
          onChange(arrayMove(items, oldIndex, newIndex));
        }}
        {...props}
      />
    );
  }
}
