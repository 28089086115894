import {firestore, firebase} from "root/firebase-db";
import session from "root/utils/session";

const utils = {
  guid: function() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  }
};

const fs = {
  sendChatMessageAPI: function({roomId, roomUserId, message, files}) {
    return firestore
      .collection("ChatUser")
      .where("roomId", "==", roomId)
      .get()
      .then(function(results) {
        const users = [];

        results.forEach(function(doc) {
          users.push(doc.data());
        });

        return fs.sendChatMessage({
          firstName: session.user.firstName,
          lastName: session.user.lastName,
          avatar: session.user.avatar,
          chatType: "group",
          roomUserId,
          roomId,
          message,
          files,
          users
        });
      });
  },
  sendChatMessage: function({firstName,lastName, avatar, chatType, roomUserId, roomId, message, files, users, replyMessage}) {
    const id = utils.guid();

    //mention regEx
    const regEx = /\@\[([^\]]+)\]\(([^\)]*)\)/g;
    const matches = [...message.matchAll(regEx)];
    var hashes = [];
    matches.forEach(item => {
      hashes.push(item[1]);
    });

    const createdAt = new Date();
    firestore
      .collection("ChatMessage")
      .doc(id)
      .set({
        replyMessage,
        mentions: hashes,
        id,
        roomId,
        roomUserId,
        userId: session.user.id,
        user: {id: session.user.id, firstName,lastName, avatar},
        message,
        files,
        createdAt
      });

    firestore
      .collection("ChatUser")
      .doc(roomUserId)
      .set(
        {
          hidden: false,
          lastSendAt: createdAt,
          messagePending: null,
          lastViewedAt: createdAt
        },
        {merge: true}
      );

    firestore
      .collection("ChatRoom")
      .doc(roomId)
      .set(
        {
          lastMessageAt: createdAt,
          lastMessageBy: roomUserId
        },
        {merge: true}
      );

    /*
    db.getObj("ChatThread", roomId)
      .set("lastMessageAt", createdAt)
      .set("lastMessageBy", roomUserId)
      .save();
      */

    if (chatType != "broadcast") {
      //const message = session.user.name + " responded to chat thread.";

      users.forEach(user => {
        //!user.commentVisible &&
        if (user.userId != session.user.id &&  (user.waitingHidden || !user.hidden)) {
          var data = {
            hidden: false,
            newMessageCount: firebase.firestore.FieldValue.increment(1),
            lastMessageAt: createdAt
          };
          if (user.newMessageCount == 0) {
            user.lastReadAt = createdAt;
            data.lastReadAt = user.lastReadAt;
          }

          if (!user.newMessageCount) {
            user.newMessageCount = 0;
          }
          user.newMessageCount++;

          firestore
            .collection("ChatUser")
            .doc(user.id)
            .set(data, {merge: true});

          /*
        const dt = Moment()
          .add(-1, "hour")
          .toDate();

        if ((user.newMessageCount == 0 || user.lastMessageAt < dt) && user.userId && !user.isMute) {
          db.getQuery("User")
            .select(["pushToken"])
            .get(user.userId)
            .then(function(obj) {
              if (obj) {
                var pushToken = obj.get("pushToken");
                if (pushToken) {
                  return http.run("sendPush", {
                    notification: {
                      include_player_ids: [pushToken.userId],
                      contents: {
                        en: message
                      },
                      data: {
                        type: "view-chat-thread",
                        chatThreadId,
                        message
                      }
                    }
                  });
                }
              }
            });
        }
        */
        }
      });
    }
  }
};

export default fs;
