import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import "root/App.css";
import { ListRender2, Price } from "root/pack-3";
import { UserItem, HeaderText } from "root/pack-2";
import { Loading, RenderIcon, Section, ModalBasic, BoxItem, LinkButton, FlexExpand, FlexRow, TextLine, View, FormItem, TouchButton, NoRecords } from "root/pack-1";
import { getMainServiceModel } from "root/service-model"
import "root/loading.css";
import {
  getSubServiceCost2,
  getServicePriceAndDays2,
  getIncludedServiceOptionsForBundledPackage,
  getOptionalServiceOptionsForBundledPackage,
  getIncludedDeliverablesForServiceOption,
  getDeliverablesForService,
  getSelectedServiceOptions,
  getDeliverablesForBundledPackage,
  getServiceOptionPrice,
  getServicePackageData,
  getVisiblePackage,
  getVersionData,
  getServicePriceAndDelivery, loadUserService_Worker
} from "root/service-functions";
import { RenderClientInputRequirements } from "root/forms";
import { DisplayDeliverables, DisplayOptions, ServiceViewDialog, RenderServiceDetails } from "root/services-worker"

const { moneyRange, plural, skillLevels, getLabelItem } = utils;

export function ServiceEdit({ onPressDeliverables, model, serviceVersion: defaultServiceVersion, displaySelected, hidePrice, onUpdate, allowSelect, renderServicePackage, renderServiceOption }) {
  const [state, setState] = useState({});
  const { serviceOptionIds, deliveryType, service, userService, actualServiceVersion: modelServiceVersion, packageId } = model;
  const serviceVersion = defaultServiceVersion ? defaultServiceVersion : modelServiceVersion ? modelServiceVersion : model.service
  const { options } = serviceVersion;

  return (
    <React.Fragment>
      <RenderServicePackages
        displaySelected={displaySelected}
        hidePrice={hidePrice}
        service={service}
        serviceVersion={serviceVersion}
        userService={userService}
        packageId={packageId}
        onChange={
          allowSelect
            ? (item) => {
              if (model.servicePackage != item) {
                model.packageId = item.package.id
                model.servicePackage = item;
                model.serviceOptionIds = {};
                if (deliveryType == "fast" && !item.hasExtraFastDelivery) {
                  model.deliveryType = "standard";
                }
                setState({ ...state, model });
                onUpdate(model);
              }
            }
            : null
        }
        renderServicePackage={renderServicePackage}
      ></RenderServicePackages>

      <RenderServiceOptions
        displaySelected={displaySelected}
        hidePrice={hidePrice}
        service={service}
        serviceVersion={serviceVersion}
        userService={userService}
        packageId={packageId}
        serviceOptionIds={serviceOptionIds}
        onChange={
          allowSelect
            ? (value) => {
              model.serviceOptionIds = value;
              model.serviceOptions = options.filter((item) => serviceOptionIds[item.option.id]);
              setState({ ...state, model });
              onUpdate(model);
            }
            : null
        }
        renderServiceOption={renderServiceOption}
      ></RenderServiceOptions>

      <RenderDeliverables displaySelected={displaySelected} service={service} serviceVersion={serviceVersion} userService={userService} packageId={packageId} serviceOptionIds={serviceOptionIds} onPress={onPressDeliverables} />

      <RenderDeliveryOptions
        displaySelected={displaySelected}
        hidePrice={hidePrice}
        deliveryType={deliveryType}
        service={service}
        serviceVersion={serviceVersion}
        userService={userService}
        packageId={packageId}
        serviceOptionIds={serviceOptionIds}
        onChange={
          allowSelect
            ? (value) => {
              if (model.deliveryType != value) {
                model.deliveryType = value;
                setState({ ...state, model });
                onUpdate(model);
              }
            }
            : null
        }
      />
    </React.Fragment>
  );
}

export function RenderDeliveryOptions({ displaySelected, service, serviceVersion, userService, deliveryType: defaultDeliveryType, onChange, hidePrice, serviceOptionIds, packageId }) {
  const deliveryType = defaultDeliveryType ? defaultDeliveryType : "standard"

  const serviceData = getVersionData({ service, userService, serviceVersion })
  const { packages } = serviceData

  var servicePackage;
  if (packageId) {
    servicePackage = packages.find((item) => item.package.id == packageId);
  }
  const { hasExtraFastDelivery } = servicePackage ? servicePackage : serviceData;

  if (!hasExtraFastDelivery) {
    return <View />;
  }

  const rtn = getMainServiceModel({
    configData: {
      actualServiceVersion: serviceVersion,
      deliveryType: "standard",
      serviceOptionIds,
      packageId
    }
  })

  /*
  const standardDeliveryDays = rtn.getTotalDeliveryDays()
  const standardPrice = rtn.getTotalPrice()

  var fastDeliveryDays, fastPrice

  if (hasExtraFastDelivery) {
    const rtnFast = getMainServiceModel({
      configData: {
        actualServiceVersion: serviceVersion,
        deliveryType: "fast",
        serviceOptionIds,
        packageId
      }
    })

    fastDeliveryDays = rtnFast.getTotalDeliveryDays()
    fastPrice = rtnFast.getTotalPrice()
  }
  */

  const { days: standardDeliveryDays, total: standardPrice } = getServicePriceAndDelivery({ serviceVersion: serviceData, packageId, serviceOptionIds })

  const { days: fastDeliveryDays, total: fastPrice } = hasExtraFastDelivery ? getServicePriceAndDelivery({ serviceVersion: serviceData, packageId, serviceOptionIds, deliveryType: "fast" }) : {}

  return hasExtraFastDelivery ? (
    <Section>
      {displaySelected ? <HeaderText subHeader label="Delivery Options:" description="Selected delivery option for this service." /> : <HeaderText subHeader label="Delivery Options:" description="See the delivery options for this service." />}

      {!displaySelected || deliveryType == "standard" ? (
        <BoxItem
          onBoxPress={() => {
            onChange("standard");
          }}
          name="Regular Delivery:"
          hasCheck={onChange}
          checked={deliveryType == "standard"}
          rightRender={<Price price={!hidePrice && standardPrice != fastPrice ? standardPrice : null} days={standardDeliveryDays} />}
        />
      ) : null}

      {(!displaySelected || deliveryType == "fast") && hasExtraFastDelivery ? (
        <BoxItem
          onBoxPress={() => {
            onChange("fast");
          }}
          name="Extra Fast Delivery:"
          hasCheck={onChange}
          checked={deliveryType == "fast"}
          rightRender={<Price price={!hidePrice && standardPrice != fastPrice ? fastPrice : null} days={fastDeliveryDays} />}
        />
      ) : null}
    </Section>
  ) : null;
}

export function RenderServicePackages({ displaySelected, hidePrice, service, serviceVersion, userService, onChange, packageId, showWorkHours, renderServicePackage }) {
  const serviceData = getVersionData({ service, userService, serviceVersion })
  const { enablePackages, packages } = serviceData;

  return enablePackages ? (
    <Section>
      <HeaderText subHeader label="Bundled Packages:" description="Bundled packages for this service." />
      {packages.length == 0 ? (
        <NoRecords label="No bundled packages found." />
      ) : (
        packages.map((item) => {
          const { icon, name, shortDescription, package: servicePackage } = item;
          const { id } = servicePackage

          const { dels, opts } = getServicePackageData({ serviceVersion: serviceData, servicePackage: item });

          const { price: subServiceCost, days: subServiceDays } = getSubServiceCost2({ serviceVersion: serviceData, packageId: id })
          const { price: servicePrice, days: serviceDays, workHours } = getServicePriceAndDays2({ serviceVersion: serviceData, packageId: id })

          const total = servicePrice + subServiceCost
          const deliveryDays = serviceDays + subServiceDays

          if (displaySelected && id != packageId) {
            return;
          }
          return (
            <BoxItem
              hasCheck={onChange != null}
              checked={packageId == id}
              onPress={
                onChange
                  ? () => {
                    onChange(item);
                  }
                  : null
              }
              icon={icon}
              name={name}
              description={shortDescription}
              rightRender={<Price price={!hidePrice ? total : null} days={deliveryDays} />}>
              {showWorkHours ? <TextLine size={14} grey label="Work Hours:" value={plural(workHours, "hr")} /> : null}
              <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} />
              <DisplayOptions service={service} options={opts} />
              {renderServicePackage ? renderServicePackage({ item, total, servicePrice, subServiceCost, deliveryDays, serviceDays, subServiceDays, workHours }) : null}
            </BoxItem>
          );
        })
      )}
    </Section>
  ) : null;
}

export function RenderServiceOptions({ displaySelected, service, serviceVersion, userService, hidePrice, packageId, serviceOptionIds, onChange, showWorkHours, renderServiceOption }) {
  const serviceData = getVersionData({ service, userService, serviceVersion })
  const { enablePackages, options } = serviceData;

  if (enablePackages && !packageId) {
    return <View />;
  }

  var arr;
  if (packageId) {
    arr = getOptionalServiceOptionsForBundledPackage({ serviceOptions: options, packageId });
  } else {
    arr = options;
  }

  return arr.length > 0 ? (
    <Section>
      {displaySelected ? <HeaderText subHeader label="Additional Options:" description="Selected additional options for this service." /> : <HeaderText subHeader label="Additional Options:" description="Choose from additional options for this service." />}
      {arr.map((item) => {
        const { icon, name, description, option, deliveryDays: optionDeliveryDays } = item;
        const { id } = option
        const dels = getIncludedDeliverablesForServiceOption({ serviceVersion: serviceData, serviceOptionId: id });

        const { servicePrice, subServiceCost, workHours, subServiceDays } = getServiceOptionPrice({ serviceData, serviceOptionId: id });

        const total = servicePrice + subServiceCost
        const deliveryDays = optionDeliveryDays + subServiceDays

        if (displaySelected && !serviceOptionIds[id]) {
          return;
        }
        return (
          <BoxItem
            onPress={
              onChange
                ? () => {
                  if (!serviceOptionIds[id]) {
                    serviceOptionIds[id] = true;
                  } else {
                    delete serviceOptionIds[id];
                  }
                  onChange(serviceOptionIds);
                }
                : null
            }
            icon={icon}
            name={name}
            description={description}
            hasCheck={onChange != null}
            checked={serviceOptionIds[id]}
            rightRender={<Price price={!hidePrice ? total : null} days={deliveryDays} deliveryText="+" priceText="+" />}>
            {showWorkHours ? <TextLine size={14} grey label="Work Hours:" value={plural(workHours, "hr")} /> : null}
            {dels.length > 0 ? <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} /> : null}
            {renderServiceOption ? renderServiceOption({ item, total, servicePrice, subServiceCost, deliveryDays, subServiceDays, workHours }) : null}
          </BoxItem>
        );
      })}
    </Section>
  ) : null;
}

export function SelectServiceDialog({ onCancel, onSelect }) {
  const [state, setState] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [memberOfTeams, setMemberOfTeams] = useState();
  const [serviceId, setServiceId] = useState();
  const { tabValue } = state

  useEffect(() => {
    return dL.getQuery("Member")
      .equalTo("type", "team")
      .equalTo("user", db.getObj("User", session.user.id))
      .containedIn("removed", [undefined, false])
      .find()
      .then(function (objs) {
        const memberOfTeams = dL.loadObjects("Member", objs);
        setMemberOfTeams(memberOfTeams);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <ModalBasic full notCentered title="Service Selection:" onCancel={onCancel}>
        {tabValue == "service-market" ? <ListRender2
          showSectorsWithItemsOnly
          defaultTabValue={tabValue}
          searchFields={[{ field: "searchText" }]}
          defaultSort={{ field: "name" }}
          type="Service"
          onFilterChange={(item) => {
            setState({ ...state, tabValue: item.value })
          }}
          filters={[
            {
              label: "My Catalog",
              value: "mine"
            }, {
              label: "Team Catalog",
              value: "team"
            },
            {
              label: "Service Marketplace",
              value: "service-market",
              description: "See the services within the service marketplace.",
              onQuery: function (query) {
                query.greaterThan("publicUserServices", 0);
              }
            },
          ]}
          includes={["businessRole", "team"]}
          emptyLabel="No services found."
          renderItem={(service) => {
            const { minPrice, maxPrice, actualDeliveryDays, icon, name, id, shortDescription, businessRole, skillLevel, team } = service;

            return (
              <BoxItem key={id} >
                <FlexRow>
                  {icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} /> : null}

                  <FlexExpand>
                    <FlexRow alignTop>
                      <View>
                        <TextLine bold size={18}
                          onPress={() => {
                            setServiceId(service.id);
                          }}
                          value={name}
                        />
                        {shortDescription ? <TextLine grey size={14} value={shortDescription} /> : null}

                        {businessRole ? <TextLine grey size={14} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} spacer /> : null}

                        {team ? <TextLine grey size={14} label="Team:" value={team.name} /> : null}
                      </View>
                    </FlexRow>
                  </FlexExpand>

                  <Price priceText2={moneyRange(minPrice, maxPrice)} days={actualDeliveryDays} />

                  <TouchButton
                    style={{ marginLeft: 25 }}
                    size="sm"
                    onPress={() => {
                      onSelect({ service });
                    }}
                    label="Select"
                  />
                </FlexRow>
              </BoxItem>
            );
          }}
          onWhere={(query) => {
            query.equalTo("status", "active");
          }}
        /> : <ListRender2
          showSectorsWithItemsOnly
          defaultTabValue={tabValue}
          searchFields={[{ field: "searchText" }]}
          defaultSort={{ field: "name" }}
          type="UserService"
          onFilterChange={(item) => {
            setState({ ...state, tabValue: item.value })
          }}
          filters={[
            {
              label: "My Catalog",
              value: "mine",
              description: "See the services within your service catalog.",
              onQuery: function (query) {
                query.equalTo("user", dL.getObj("User", session.user.id));
              },
            }, {
              label: "Team Catalog",
              value: "team",
              description: "See the services within teams that you belong too.",
              onQuery: function (query) {
                query.containedIn("team", memberOfTeams.map((item) => db.getObj("team", item.team.id)));
                query.equalTo("isWorkerPublic", true);
              },
            },
            {
              label: "Service Marketplace",
              value: "service-market",
              onGetStat: function () {
                return db.getQuery("Service")
                  .containedIn("removed", [undefined, false])
                  .greaterThan("publicUserServices", 0)
                  .count()
              }
            },
          ]}
          includes={["businessRole", "team", "currentVersion", "userRole", "user"]}
          emptyLabel="No services found."
          renderItem={(userService) => {
            const { user, minPrice, maxPrice, actualDeliveryDays, icon, name, id, shortDescription, businessRole, skillLevel, team, service } = userService;

            return (
              <BoxItem key={id} >
                <FlexRow>
                  {icon ? <RenderIcon icon={icon} style={{ marginRight: 25, fontSize: 55 }} /> : null}

                  <FlexExpand>
                    <FlexRow alignTop>
                      <View>
                        <TextLine bold size={18}
                          onPress={() => {
                            setServiceId(service.id);
                          }}
                          value={name}
                        />
                        {shortDescription ? <TextLine grey size={14} value={shortDescription} /> : null}

                        {businessRole ? <TextLine grey size={14} value={"Business Role: " + businessRole.name + " (" + getLabelItem(skillLevels, skillLevel) + ")"} spacer /> : null}

                        {team ? <TextLine grey size={14} label="Team:" value={team.name} /> : null}
                      </View>

                    </FlexRow>
                  </FlexExpand>

                  <View style={{ marginLeft: 25 }}>
                    <UserItem alignRight label="Consultant:" user={user} />
                  </View>

                  <View style={{ minWidth: 200 }}>
                    <Price priceText2={moneyRange(minPrice, maxPrice)} days={actualDeliveryDays} />
                  </View>

                  <TouchButton
                    style={{ marginLeft: 25 }}
                    size="sm"
                    onPress={() => {
                      onSelect({ userService, service });
                    }}
                    label="Select"
                  />
                </FlexRow>
              </BoxItem>
            );
          }}
          onWhere={(query) => {
            query.equalTo("status", "active");
          }}
        />}
      </ModalBasic>

      {serviceId ? (
        <ServiceViewDialog
          serviceId={serviceId}
          onClose={() => {
            setServiceId();
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

export function SelectService({ allowClear, readonly, onChange, value, rightRender }) {
  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <FlexRow>
        <FlexExpand>
          <FlexRow>
            <View right={15}>
              {value ?
                <View>
                  <TextLine value={value.name} />
                  <TextLine size={14} value={value.shortDescription} />
                </View> :
                <TextLine color="grey" value="Empty" />
              }
            </View>

            {!readonly ? (
              <React.Fragment>
                <LinkButton
                  onPress={() => {
                    setVisible(true);
                  }}
                  label="Select"
                />

                {value && allowClear ? (
                  <LinkButton
                    style={{ marginLeft: 6 }}
                    onPress={() => {
                      onChange(null);
                    }}
                    label="Clear"
                  />
                ) : null}
              </React.Fragment>
            ) : null}
          </FlexRow>
        </FlexExpand>

        {rightRender}
      </FlexRow>

      {visible ? (
        <SelectServiceDialog
          onSelect={(data) => {
            onChange(data);
            setVisible(false);
          }}
          onCancel={() => {
            setVisible(false);
          }}
        />
      ) : null}
    </React.Fragment>
  );
}

export function RenderSelectedService({ serviceItem, recommendedPackageId, recommendedServiceOptionIds, children }) {
  if (!serviceItem.delayOptions) {
    serviceItem.delayOptions = {};
  }
  const { service, serviceVersion, servicePackage, userService } = serviceItem;
  const { name, description } = getVersionData({ service, userService, serviceVersion })
  const { delayOptions, serviceOptionIds, deliveryType } = serviceItem;

  const hidePrice = false;
  const packageId = servicePackage ? servicePackage.package.id : null;
  const { delayType, delayDays, delayStart, delayStartDate } = delayOptions;
  const { enablePackages, serviceOptions } = getVersionData({ service, userService, serviceVersion })

  const { days, total } = service || servicePackage ? getServicePriceAndDelivery({ service, serviceVersion, userService, deliveryType, packageId, serviceOptionIds }) : {}

  const { deliveryDays, price, extraFastDeliveryDays } = servicePackage ? servicePackage : serviceItem;

  var opts;
  if (packageId) {
    opts = getOptionalServiceOptionsForBundledPackage({ serviceOptions, packageId });
  } else {
    opts = getSelectedServiceOptions({ service, serviceVersion, userService, serviceOptionIds });
  }

  const dels = getDeliverablesForService({ service, serviceVersion, userService, packageId, serviceOptionIds });

  const DisplayBundledPackage = function ({ service, item, hidePrice }) {
    const { icon, name, shortDescription, id, price, deliveryDays } = item;

    const opts = getIncludedServiceOptionsForBundledPackage({ serviceOptions, packageId: id });
    const dels = getDeliverablesForBundledPackage({ service, serviceVersion, userService, packageId: id });

    return (
      <View>
        {recommendedPackageId == id ? <TextLine size={12} color="red" value="Recommended" /> : null}
        <BoxItem style={{ marginRight: 15, marginBottom: 15 }}
          icon={icon}
          name={name}
          description={shortDescription}
          rightRender={<Price price={!hidePrice ? price : null} days={deliveryDays} />}>
          <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} />
          <DisplayOptions options={opts} service={service} />
        </BoxItem>
      </View>
    );
  };

  return (
    <View>
      <HeaderText subHeader label={name} description={description} rightRender={service && (!enablePackages || servicePackage) ? <Price price={total} days={days} deliveryText="Delivery in " /> : null} />

      <RenderServiceDetails service={service} serviceVersion={serviceVersion} userService={userService} servicePackage={servicePackage}>
        {children}
      </RenderServiceDetails>

      {servicePackage ? (
        <Section>
          <HeaderText subHeader label="Bundled Package:" description="Details about the bundled package selected." />
          <DisplayBundledPackage service={service} serviceVersion={serviceVersion} item={servicePackage} hidePrice={hidePrice} recommendedPackageId={recommendedPackageId} />
        </Section>
      ) : null}

      {opts.length > 0 ? (
        <Section>
          <HeaderText subHeader label="Additional Service Options:" description="Details about the other service options selected." />

          {opts.map((item) => {
            const { icon, name, description, id, price, deliveryDays } = item;

            const dels = getIncludedDeliverablesForServiceOption({ service, serviceOptionId: id });

            return (
              <View>
                {recommendedServiceOptionIds && recommendedServiceOptionIds[id] ? <TextLine size={12} color="red" value="Recommended" /> : null}

                <BoxItem icon={icon} name={name} description={description} rightRender={<Price price={!hidePrice ? price : null} days={deliveryDays} deliveryText="+" priceText="+" />}>
                  <DisplayDeliverables deliverables={dels} style={{ marginTop: 25 }} />
                </BoxItem>
              </View>
            );
          })}
        </Section>
      ) : null}

      {!servicePackage && dels.length > 0 ? (
        <Section>
          <HeaderText subHeader label="Included Deliverables:" description="Deliverables that will be completed for this service." />

          {dels.map((item) => {
            const { icon, name, description, id } = item;

            return <BoxItem key={id} icon={icon} name={name} description={description} />;
          })}
        </Section>
      ) : null}

      (Other bundled packages)
    </View>
  );
}

export function RenderDeliverables({ onPress, service, userService, serviceVersion, packageId, serviceOptionIds }) {
  const serviceData = getVersionData({ service, userService, serviceVersion })
  const { enablePackages } = serviceData;

  if (enablePackages) {
    return <View />;
  }

  const arr = getDeliverablesForService({ service, userService, serviceVersion, packageId, serviceOptionIds });

  return arr.length > 0 ? (
    <Section>
      <HeaderText subHeader label="Included Deliverables:" description="List of the deliverable that are included with the options selected." />

      {arr.map((item) => {
        const { icon, name, description, id } = item;

        return <BoxItem onPress={onPress ? () => {
          onPress(item)
        } : null} key={id} icon={icon} name={name} description={description} hasCheck checked={true} />;
      })}
    </Section>
  ) : null;
}

export function AddInputsButton({ label, viewOnly, inputs, inputData, onChange }) {
  const [state, setState] = useState({});
  const { visible } = state;

  return (
    <React.Fragment>
      <TouchButton micro
        onPress={() => {
          setState({ ...state, visible: true });
        }}
        label={label ? label : "Edit Questions"}
      />

      {visible ? (
        <RenderClientInputRequirements
          viewOnly={viewOnly}
          inputs={inputs}
          inputData={inputData}
          onDone={() => {
            onChange(inputData);
            setState({ ...state, visible: false });
          }}
        />
      ) : null}
    </React.Fragment>
  );
}
