import "./App.css";
import React, { useState, useEffect } from "react";
import db from "root/utils/db";
import dL from "root/utils/dl";
import utils from "root/utils/functions";
import session from "root/utils/session";
import PromiseA from "root/utils/promise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import Moment from "moment-business-days";
import { useParams, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import "root/App.css";
import { __DEV__ } from "root/dev";
import { EditKeyUser, DisplayKeyUser, Price, ListRender2 } from "root/pack-3";
import { BasicTop, ListRender, BoxRowItem, HeaderText, UserItem, SelectUser } from "root/pack-2";
import { Toggle, NoRecordsBox, CenterBox, EditDialog, Section, DateTimeItem, LabelItem, FlexExpand, FlexRow, TabBar, Text, TextLine, View, FormItem, MyInput, TouchButton, SelectBox, FileElements } from "root/pack-1";
import { ChatWindow, getChatRoomsWorker, getChatRoomsBuyer } from "root/chat";
import { NewProject } from "root/projects";

const { yesNo, plural, money, serviceTypes, requestTypes, getLabelItem, roundMoney } = utils;

export function getProposalAveragePrice({ proposals }) {
  var avgProposalPrice;

  if (proposals) {
    var vtotal = 0;
    proposals.forEach(proposal => {
      const { total } = proposal;
      vtotal += total;
    });
    avgProposalPrice = roundMoney(vtotal / proposals.length);
  }
  return avgProposalPrice;
}

function RenderServiceRequest({ item, onPress }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const { requestType, serviceType, id, createdAt, title, services, servicePackages, user, status, proposalCount, proposals, strategyConsultant, businessDevelopment } = item;

  const avgProposalPrice = getProposalAveragePrice({ proposals });

  return (
    <BoxRowItem key={id}>
      <FlexRow alignTop>
        <FlexExpand>
          <TextLine
            size={22}
            value={title}
            onPress={() => {
              history.push(`/${sectionId}/service-request/${id}`);
            }}
          />

          <FlexRow top={10}>
            <UserItem label="Client:" user={user} style={{ marginRight: 10 }} />
            {strategyConsultant ? <UserItem label="SC:" user={strategyConsultant} style={{ marginRight: 10 }} /> : null}
          </FlexRow>

          <TextLine size={14} top={14} value={getLabelItem(serviceTypes, serviceType) + ": " + getLabelItem(requestTypes, requestType)} />
        </FlexExpand>

        <View alignRight>
          <TextLine alignRight size={18} uppercase color="grey" value={status} />
          <View style={{ height: 8 }} />
          <DateTimeItem value={createdAt} fromNow />
          <View style={{ height: 12 }} />
          <TextLine alignRight label="Proposals:" value={proposalCount} />
          {avgProposalPrice ? <TextLine label="Avg. Strategy Price:" value={money(avgProposalPrice)} alignRight /> : null}
        </View>
      </FlexRow>
    </BoxRowItem>
  );
}

export function ServiceRequestsWorker({ teamId }) {
  const { sectionId } = useParams();
  const history = useHistory();
  const [filter, setFilter] = useState({});

  const dt1 = Moment()
    .add(2, "days")
    .toDate();

  const filters = [
    {
      label: "New",
      value: "new",
      onQuery: function (query) {
        query.equalTo("proposalCount", 0);
        query.equalTo("status", "open");
        query.greaterThan("expireDate", new Date());
      }
    },
    {
      label: "Open",
      value: "open",
      onQuery: function (query) {
        query.greaterThan("proposalCount", 0);
        query.equalTo("status", "open");
        query.greaterThan("expireDate", new Date());
      }
    },
    {
      label: "Expiring Soon",
      value: "expiring",
      onQuery: function (query) {
        query.equalTo("status", "open");
        query.lessThan("expireDate", dt1);
      }
    },
    {
      label: "Submitted",
      value: "submitted",
      onQuery: function (query) {
        query.equalTo("proposals.userId", session.user.id);
        query.equalTo("status", "open");
      }
    },
    {
      label: "Withdrawn",
      value: "withdrawn",
      onQuery: function (query) {
        query.equalTo("proposalsWithdrawn.userId", session.user.id);
        query.equalTo("status", "open");
      }
    }
  ];

  if (dL.isSC()) {
    if (teamId) {
      filters.unshift({
        label: "Assigned To Team",
        value: "assigned-team",
        onQuery: function (query) {
          query.equalTo("team", db.getObj("Team", teamId));
          query.equalTo("status", "open");
        }
      });
    } else {
      filters.unshift({
        label: "Assigned to You",
        value: "assigned",
        onQuery: function (query) {
          query.equalTo("strategyConsultant", db.getObj("User", session.user.id));
          query.equalTo("status", "open");
        }
      });
    }
  }

  if (session.user.permissions.serviceRequestAssignment) {
    filters.unshift({
      label: "Not Assigned",
      value: "not-assigned",
      onQuery: function (query) {
        query.existsObj("strategyConsultant", false);
        query.equalTo("status", "open");
        query.greaterThan("expireDate", new Date());
      }
    });
  }

  return (
    <ListRender2
      subHeader={teamId != null}
      showSectorsWithItemsOnly={true}
      onResetFilter={() => {
        setFilter({});
      }}
      renderFilter={() => {
        const { serviceType, budget, companyStage, companySize, requestType } = filter;
        return (
          <View>
            <SelectBox
              label="Request Type:"
              value={utils.requestTypes.find(item => item.value == requestType)}
              options={utils.requestTypes}
              onChange={item => {
                filter.requestType = item.value;
                setFilter(filter);
              }}
            />
            <SelectBox
              label="Business Sector:"
              value={utils.serviceTypes.find(item => item.value == serviceType)}
              options={utils.serviceTypes}
              onChange={item => {
                filter.serviceType = item.value;
                setFilter(filter);
              }}
            />
            <SelectBox
              label="Budget:"
              value={utils.budgets.find(item => item.value == budget)}
              options={utils.budgets}
              onChange={item => {
                filter.budget = item.value;
                setFilter(filter);
              }}
            />
            <SelectBox
              label="Company Type:"
              value={utils.companyStages.find(item => item.value == companyStage)}
              options={utils.companyStages}
              onChange={item => {
                filter.companyStage = item.value;
                setFilter(filter);
              }}
            />
            <SelectBox
              label="Company Size:"
              value={utils.employeeSizes.find(item => item.value == companySize)}
              options={utils.employeeSizes}
              onChange={item => {
                filter.companySize = item.value;
                setFilter(filter);
              }}
            />
          </View>
        );
      }}
      filterValues={filter}
      onFilter={query => {
        const { serviceType, budget, companyStage, companySize, requestType } = filter;
        if (serviceType) {
          query.equalTo("serviceType", serviceType);
        }
        if (budget) {
          query.equalTo("budget", budget);
        }
        if (companyStage) {
          query.equalTo("companyStage", companyStage);
        }
        if (companySize) {
          query.equalTo("companySize", companySize);
        }
        if (requestType) {
          query.equalTo("requestType", requestType);
        }
      }}
      onWhere={query => {
        if (teamId) {
          query.equalTo("team", dL.getObj("Team", teamId));
        }
      }}
      searchFields={[{ field: "requestNumber", prefix: "SR" }, { field: "email" }]}
      defaultSort={{ field: "requestDate", desc: true }}
      filters={filters}
      type="ServiceRequest"
      emptyLabel="No service requests found."
      includes={["user", "services", "servicePackages", "strategyConsultant"]}
      title="Service Requests"
      description="Find work submitted on the platform."
      renderItem={item => {
        return <RenderServiceRequest item={item} />;
      }}
    />
  );
}

export function ServiceRequestsAdmin() {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender2
      showSectorsWithItemsOnly={true}
      searchFields={[{ field: "requestNumber", prefix: "SR" }, { field: "email" }]}
      defaultSort={{ field: "createdAt", desc: true }}
      filters={[
        { label: "All", value: "all", disableStat: true },
        {
          label: "New",
          value: "new",
          onQuery: function (query) {
            query.equalTo("status", "new");
          }
        },
        {
          label: "Open",
          value: "open",
          onQuery: function (query) {
            query.equalTo("status", "open");
          }
        },
        {
          label: "No BD",
          value: "no-bd",
          onQuery: function (query) {
            query.existsObj("businessDevelopment", false);
          }
        },
        {
          label: "No SC",
          value: "no-sc",
          onQuery: function (query) {
            query.existsObj("strategyConsultant", false);
          }
        },
        {
          label: "Captured",
          value: "captured",
          onQuery: function (query) {
            query.equalTo("status", "captured");
          }
        },
        {
          label: "Lost",
          value: "lost",
          onQuery: function (query) {
            query.equalTo("status", "lost");
          }
        }
      ]}
      onButtonPress={() => {
        history.push(`/${sectionId}/service-request/edit`);
      }}
      onWhere={query => {
        const userId = session.user.id;
        if (sectionId == "worker") {
          query.equalTo("accessUsers.userId", userId);
        } else if (sectionId == "user") {
          query.equalTo("user", dL.getObj("User", userId));
        }
      }}
      type="ServiceRequest"
      emptyLabel="No service requests found."
      includes={["user", "services", "servicePackages"]}
      title="Service Requests"
      description="Manage service requests."
      statuses={utils.serviceRequestStatuses}
      renderItem={item => {
        return <RenderServiceRequest item={item} />;
      }}
    />
  );
}

export function ServiceRequestsBuyer({ projectId }) {
  const { sectionId } = useParams();
  const history = useHistory();

  const [state, setState] = useState({ isLoading: true });
  const { isLoading, isEmptyRender } = state;
  const userId = session.user.id

  useEffect(() => {
    if (!projectId) {
      dL.getQuery("ServiceRequest")
        .equalTo("user", dL.getObj("User", userId))
        .containedIn("removed", [undefined, false])
        .count()
        .then(function (count) {
          setState({ ...state, isLoading: false, isEmptyRender: count == 0 })
        })
    } else {
      setState({ ...state, isLoading: false })
    }
  }, [])

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (isEmptyRender) {
    return <View>
      <HeaderText label="Service Requests"
        description="Ask our consultants to prepare a strategy map for service solution." />

      <NoRecordsBox label="No requests have been created." style={{ marginBottom: 10 }} />

      <FlexRow>
        <CenterBox style={{ flex: 1, paddingTop: 35, paddingBottom: 35, maxWidth: 650, paddingLeft: 50, paddingRight: 50 }}>
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: 75, color: "grey" }} />
          <TextLine top={25} size={22} bold value="Create a new service request" />
          <TextLine top={8} alignCenter value="Ask our team anything to help grow your business, 250+ services and 1000+ focused experts to help your business grow with predictable results)" />
          <View style={{ height: 35 }} />
          <TouchButton label="Create Service Request" onPress={() => {
            history.push("/user/service-request/edit")
          }} />
        </CenterBox>
      </ FlexRow>
    </View>
  }


  return (
    <ListRender
      queryId={projectId ? "p" : null}
      subHeader={projectId != null}
      searchFields={[{ field: "requestNumber", prefix: "SR" }]}
      defaultSort={{ field: "requestDate", desc: true }}
      onButtonPress={() => {
        if (projectId) {
          history.push(`/${sectionId}/project/${projectId}/service-request`);
        } else {
          history.push(`/${sectionId}/service-request/edit`);
        }
      }}
      onWhere={query => {
        const userId = session.user.id;
        if (projectId) {
          query.equalTo("project", dL.getObj("Project", projectId));
        } else {
          query.equalTo("user", dL.getObj("User", userId));
        }
      }}
      filters={[
        {
          label: "Draft",
          value: "draft",
          onQuery: function (query) {
            query.equalTo("status", "draft");
          }
        },
        {
          label: "Open",
          value: "open",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.greaterThan("expireDate", new Date());
            query.descending("lastProposalAt");
          }
        },
        {
          label: "Expired",
          value: "expired",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.lessThan("expireDate", new Date());
          },
          autoHide: true
        }
      ]}
      type="ServiceRequest"
      emptyLabel="No service requests created."
      includes={["project", "user", "services", "servicePackages", "createdBy", "strategyConsultant"]}
      title="Service Requests"
      description="Ask our consultants to prepare a strategy map for service solution."
      renderItem={item => {
        const { project, lastProposalAt, requestType, serviceType, id, createdAt, title, services, servicePackages, user, status, proposalCount, proposals, createdBy, strategyConsultant } = item;

        const avgProposalPrice = getProposalAveragePrice({ proposals });

        return (
          <BoxRowItem key={id}>
            <FlexRow alignTop>
              <FlexExpand>
                <TextLine
                  size={22}
                  value={title}
                  onPress={() => {
                    if (status == "draft") {
                      history.push(`/${sectionId}/service-request/edit/${id}`);
                    } else {
                      history.push(`/${sectionId}/service-request/${id}`);
                    }
                  }}
                />

                <FlexRow top={10}>
                  <UserItem label="Submitted By:" user={createdBy} style={{ marginRight: 10 }} />
                  {strategyConsultant ? <UserItem label="SC:" user={strategyConsultant} style={{ marginRight: 10 }} /> : null}
                </FlexRow>

                <View style={{ marginTop: 14 }} />
                {project ? <TextLine size={14} label="Project:" value={project.name} /> : null}

                <TextLine size={14} value={getLabelItem(serviceTypes, serviceType) + ": " + getLabelItem(requestTypes, requestType)} />
              </FlexExpand>

              <View alignRight>
                <TextLine alignRight size={18} uppercase color="grey" value={status} />
                <View style={{ height: 8 }} />
                <TextLine alignRight label="Strategy Maps:" value={proposalCount} />
                {lastProposalAt ? <TextLine top={-6} alignRight grey size={12} value={Moment(lastProposalAt).fromNow()} /> : null}
                {avgProposalPrice ? <TextLine label="Avg. Strategy Price:" value={money(avgProposalPrice)} alignRight /> : null}
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

export function ServiceRequestsBuyer2({ projectId }) {
  const { sectionId } = useParams();
  const history = useHistory();

  return (
    <ListRender
      queryId={projectId ? "p" : null}
      subHeader={projectId != null}
      searchFields={[{ field: "requestNumber", prefix: "SR" }]}
      defaultSort={{ field: "requestDate", desc: true }}
      onButtonPress={() => {
        if (projectId) {
          history.push(`/${sectionId}/project/${projectId}/service-request`);
        } else {
          history.push(`/${sectionId}/service-request/edit`);
        }
      }}
      onWhere={query => {
        const userId = session.user.id;
        if (projectId) {
          query.equalTo("project", dL.getObj("Project", projectId));
        } else {
          query.equalTo("user", dL.getObj("User", userId));
        }
      }}
      filters={[
        {
          label: "Draft",
          value: "draft",
          onQuery: function (query) {
            query.equalTo("status", "draft");
          }
        },
        {
          label: "Open",
          value: "open",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.greaterThan("expireDate", new Date());
            query.descending("lastProposalAt");
          }
        },
        {
          label: "Expired",
          value: "expired",
          onQuery: function (query) {
            query.equalTo("status", "open");
            query.lessThan("expireDate", new Date());
          },
          autoHide: true
        }
      ]}
      type="ServiceRequest"
      emptyLabel="No service requests created."
      includes={["project", "user", "services", "servicePackages", "createdBy", "strategyConsultant"]}
      title="Service Requests"
      description="Ask our consultants to prepare a strategy map for service solution."
      renderItem={item => {
        const { project, lastProposalAt, requestType, serviceType, id, createdAt, title, services, servicePackages, user, status, proposalCount, proposals, createdBy, strategyConsultant } = item;

        const avgProposalPrice = getProposalAveragePrice({ proposals });

        return (
          <BoxRowItem key={id}>
            <FlexRow alignTop>
              <FlexExpand>
                <TextLine
                  size={22}
                  value={title}
                  onPress={() => {
                    if (status == "draft") {
                      history.push(`/${sectionId}/service-request/edit/${id}`);
                    } else {
                      history.push(`/${sectionId}/service-request/${id}`);
                    }
                  }}
                />

                <FlexRow top={10}>
                  <UserItem label="Submitted By:" user={createdBy} style={{ marginRight: 10 }} />
                  {strategyConsultant ? <UserItem label="SC:" user={strategyConsultant} style={{ marginRight: 10 }} /> : null}
                </FlexRow>

                <View style={{ marginTop: 14 }} />
                {project ? <TextLine size={14} label="Project:" value={project.name} /> : null}

                <TextLine size={14} value={getLabelItem(serviceTypes, serviceType) + ": " + getLabelItem(requestTypes, requestType)} />
              </FlexExpand>

              <View alignRight>
                <TextLine alignRight size={18} uppercase color="grey" value={status} />
                <View style={{ height: 8 }} />
                <TextLine alignRight label="Strategy Maps:" value={proposalCount} />
                {lastProposalAt ? <TextLine top={-6} alignRight grey size={12} value={Moment(lastProposalAt).fromNow()} /> : null}
                {avgProposalPrice ? <TextLine label="Avg. Strategy Price:" value={money(avgProposalPrice)} alignRight /> : null}
              </View>
            </FlexRow>
          </BoxRowItem>
        );
      }}
    />
  );
}

function getServiceRequest({ serviceRequestId, isWorker }) {
  var model;
  var proposals;
  return db
    .getQuery("ServiceRequest")
    .include("user")
    .include("strategyConsultant")
    .include("businessDevelopment")
    .include("createdBy")
    .get(serviceRequestId)
    .then(function (obj) {
      model = dL.loadServiceRequest(obj);

      const query = db.getQuery("ServiceProposal").equalTo("serviceRequest", dL.getObj("ServiceRequest", serviceRequestId));

      if (isWorker) {
        query.equalTo("createdBy", dL.getObj("User", session.user.id));
      }
      return query
        .containedIn("removed", [undefined, false])
        .include("createdBy")
        .descending("createdAt")
        .find();
    })
    .then(function (objs) {
      proposals = dL.loadObjects("ServiceProposal", objs);

      return { serviceRequest: model, proposals };
    });
}

function RenderServiceRequestOverview({ serviceRequest, proposals }) {
  const { hasRFP, deliverables, createdAt, requestType, createdBy, user, serviceType, status, id, title, requestNumber, strategyConsultant, businessDevelopment, companyStage, companySize, urgency, budget, files, shortDescription, description, proposalCount } = serviceRequest;

  const avgProposalPrice = getProposalAveragePrice({ proposals });

  return (
    <React.Fragment>
      <Section>
        <HeaderText subHeader label="Overview:" description="Details of the service request." />
        <LabelItem inline label="Date Requested:" value={Moment(createdAt).format("M/D/YYYY [at] h:mm a")} />

        <FlexRow>
          <LabelItem label="Request #:" value={requestNumber} />
          <LabelItem label="Type:" value={getLabelItem(requestTypes, requestType)} />
          <LabelItem label="Status:" value={status} />
          {hasRFP ? <LabelItem label="RFP:" value={yesNo(hasRFP)} /> : null}
        </FlexRow>
        <LabelItem label="Submitted By:">
          <UserItem user={createdBy} commentIcon />
        </LabelItem>
      </Section>

      <Section>
        <HeaderText subHeader label="Client Details:" description="Information about the buyer and their company." />
        <FlexRow>
          <LabelItem label="Business Sector:" value={getLabelItem(utils.serviceTypes, serviceType)} />
          <LabelItem label="Company Type:" value={getLabelItem(utils.companyStages, companyStage)} />
          <LabelItem label="Company Size:" value={getLabelItem(utils.employeeSizes, companySize)} />
        </FlexRow>
        <LabelItem label="Client:">
          <UserItem user={user} commentIcon />
        </LabelItem>
      </Section>

      <Section>
        <HeaderText subHeader label="Service Requirements:" description="Learn more about the requirements for the service." />
        <LabelItem inline label="Title:" value={title} />
        {shortDescription ? <LabelItem label="Type of services needed:" value={shortDescription} /> : null}
        {deliverables ? <LabelItem label="Deliverables:" value={deliverables} /> : null}

        <FlexRow>
          <LabelItem inline label="Urgency:" value={getLabelItem(utils.urgencyLevels, urgency)} />
          <LabelItem inline label="Budget:" value={getLabelItem(utils.budgets, budget)} />
        </FlexRow>

        {description ? <LabelItem label="More notes:" value={description} /> : null}

        {files && files.length > 0 ? <FileElements readonly label="Additional/Example Files:" value={files} /> : null}
      </Section>

      <Section>
        <HeaderText subHeader label="Strategy Map Stats:" description="See the stats on submitted strategy maps for this service request from all workers." />
        <FlexRow>
          <LabelItem inline label="Submitted Strategy Maps:" description="Number of strategy maps submitted by all workers." value={proposalCount} />

          {avgProposalPrice ? <LabelItem inline label="Avg. Strategy Price:" value={money(avgProposalPrice)} description="Average price of suggested services in strategy map." /> : null}
        </FlexRow>
      </Section>
    </React.Fragment>
  );
}

export function ServiceRequestWorker() {
  const { serviceRequestId, sectionId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ isLoading: serviceRequestId ? true : false });
  const [visible, setVisible] = useState(false);
  const [tabValue, setTabValue] = useState("details");
  const [refresh, setRefresh] = useState(new Date());
  const { isLoading, model, proposals } = state;

  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    getServiceRequest({ serviceRequestId, isWorker: true })
      .then(function ({ serviceRequest, proposals }) {
        setState({ ...state, isLoading: false, model: serviceRequest, proposals });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const isSystemAdmin = session.user.isSystemAdmin;
  const { hasRFP, deliverables, createdAt, requestType, createdBy, user, serviceType, status, id, title, requestNumber, strategyConsultant, businessDevelopment, companyStage, companySize, urgency, budget, files, shortDescription, description, proposalCount } = model;

  const avgProposalPrice = getProposalAveragePrice({ proposals });

  const chatRoomName = "Service Request #: " + requestNumber;
  const chatRooms = getChatRoomsWorker({ id, chatRoomName, clientUsers: [user], growlyUsers: [businessDevelopment, strategyConsultant, session.user] })

  return (
    <ChatWindow chatRooms={chatRooms}>
      <BasicTop title={"Service Request #" + requestNumber} />

      <TabBar
        queryId="tbm"
        style={{ marginBottom: 15 }}
        options={[{ label: "Details", value: "details" }, { label: "Team", value: "team" }, { label: "Strategy Maps (" + proposals.length + ")", value: "proposals" }]}
        onChange={item => {
          setTabValue(item.value);
        }}
        value={tabValue}
      />

      {tabValue == "details" ? <RenderServiceRequestOverview serviceRequest={model} proposals={proposals} /> : null}

      {tabValue == "team" ? (
        <Section>
          <HeaderText subHeader label="Team:" description="The team associated with the service request." />

          <FlexRow>
            <EditKeyUser
              roleName="businessDevelopment"
              label="Business Development:"
              readonly={!(createdBy.id == session.userId || isSystemAdmin)}
              value={businessDevelopment}
              onChange={value => {
                dL.setServiceRequestBusinessDevelopment({
                  clear: value ? false : true,
                  serviceRequestId: model.id,
                  userId: value ? value.id : businessDevelopment.id
                });
                model.businessDevelopment = value;
                setState({ ...state, model });
              }}
            />
            <View style={{ width: 25 }} />
            <EditKeyUser
              roleName="strategyConsultant"
              label="Strategy Consultant:"
              readonly={!(createdBy.id == session.userId || isSystemAdmin)}
              value={strategyConsultant}
              onChange={value => {
                dL.setServiceRequestStrategyConsultant({
                  clear: value ? false : true,
                  serviceRequestId: model.id,
                  userId: value ? value.id : strategyConsultant.id
                });
                model.strategyConsultant = value;
                setState({ ...state, model });
              }}
            />
          </FlexRow>
        </Section>
      ) : null}

      {tabValue == "proposals" ? (
        <Section>
          <HeaderText
            subHeader
            label="Strategy Maps:"
            description="Create and submit a strategy map for this service request."
            buttons={[
              {
                label: "Add Simple Map",
                onPress: () => {
                  history.push(`/${sectionId}/service-request/${serviceRequestId}/proposal/add-simple`);
                }
              },
              {
                label: "Add Complex Map",
                onPress: () => {
                  history.push(`/${sectionId}/service-request/${serviceRequestId}/proposal/add`);
                }
              }
            ]}
          />
          <ListRender
            filters={[
              {
                label: "Draft",
                value: "draft",
                onQuery: function (query) {
                  query.equalTo("status", "draft");
                  query.descending("sentAt");
                }
              },
              {
                label: "Submitted",
                value: "submitted",
                onQuery: function (query) {
                  query.equalTo("status", "open");
                  query.greaterThan("expireDate", new Date());
                  query.descending("sentAt");
                }
              },
              {
                label: "Expiring Soon",
                value: "expiring",
                onQuery: function (query) {
                  const et = Moment()
                    .add(2, "days")
                    .toDate();

                  query.equalTo("status", "open");
                  query.lessThan("expireDate", et);
                  query.descending("sentAt");
                },
                autoHide: true
              },
              {
                label: "Expired",
                value: "expired",
                onQuery: function (query) {
                  query.equalTo("status", "open");
                  query.lessThan("expireDate", new Date());
                  query.descending("expireDate");
                },
                autoHide: true
              },
              {
                label: "Withdrawn",
                value: "withdrawn",
                onQuery: function (query) {
                  query.equalTo("status", "withdrawn");
                  query.descending("withdrawnAt");
                },
                autoHide: true
              }
            ]}
            onWhere={query => {
              query.equalTo("createdBy", dL.getObj("User", session.user.id));
              query.equalTo("serviceRequest", dL.getObj("ServiceRequest", serviceRequestId));
            }}
            type="ServiceProposal"
            emptyLabel="No strategy maps found."
            emptyRender={<NoRecordsBox label="No strategy maps found.">
              <TouchButton
                label="Submit a Strategy"
                micro
                onPress={() => {
                  history.push(`/${sectionId}/service-request/${serviceRequestId}/proposal/add-simple`);
                }}
              />
            </NoRecordsBox>}
            includes={["user", "createdBy"]}
            renderItem={item => {
              const { id, title, sentAt, total, deliveryDays, proposalType, serviceItems } = item;

              return (
                <BoxRowItem>
                  <FlexRow alignTop>
                    <FlexExpand>
                      <DateTimeItem value={sentAt} fromNow />
                      <TextLine
                        size={22}
                        value={title}
                        top={6}
                        onPress={() => {
                          if (proposalType == "simple") {
                            history.push(`/${sectionId}/service-request/${serviceRequestId}/proposal/${id}`);
                          } else {
                            history.push(`/${sectionId}/proposal/${id}`);
                          }
                        }}
                      />
                    </FlexExpand>

                    <View alignRight>
                      <Price price={total} days={deliveryDays} />
                      {serviceItems ? <TextLine color="grey" value={plural(serviceItems.length, "service")} /> : null}
                    </View>
                  </FlexRow>
                </BoxRowItem>
              );
            }}
          />
        </Section>
      ) : null}
    </ChatWindow>
  );
}

export function ServiceRequestBuyer() {
  const { serviceRequestId, sectionId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const [state, setState] = useState({ isLoading: serviceRequestId ? true : false });
  const [visible, setVisible] = useState(false);
  const [tabValue, setTabValue] = useState("details");
  const [refresh, setRefresh] = useState(new Date());
  const { isLoading, model, proposals } = state;

  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    getServiceRequest({ serviceRequestId })
      .then(function ({ serviceRequest, proposals }) {
        setState({ ...state, isLoading: false, model: serviceRequest, proposals });
      })
      .catch(function (err) {
        alert("Error: " + err);
      });
  }, [refresh]);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { proposalCount, createdAt, requestType, createdBy, user, serviceType, status, id, title, requestNumber, strategyConsultant, businessDevelopment, companyStage, companySize, urgency, budget, files, shortDescription, description } = model;

  const chatRoomName = "Service Request #: " + requestNumber;
  const chatRooms = getChatRoomsBuyer({ id, chatRoomName, clientUsers: [user], growlyUsers: [businessDevelopment, strategyConsultant, session.user] })

  const tabs = [{ label: "Details", value: "details" }];
  if (businessDevelopment || strategyConsultant) {
    tabs.push({ label: "Team", value: "team" });
  }
  tabs.push({ label: "Strategy Maps (" + proposalCount + ")", value: "proposals" });

  return (
    <ChatWindow chatRooms={chatRooms}>
      <BasicTop title={"Service Request #" + requestNumber} />

      <TabBar
        queryId="tb"
        style={{ marginBottom: 15 }}
        options={tabs}
        onChange={item => {
          setTabValue(item.value);
        }}
        value={tabValue}
      />

      {tabValue == "details" ? <RenderServiceRequestOverview serviceRequest={model} proposals={proposals} /> : null}

      {tabValue == "team" ? (
        <Section>
          <HeaderText subHeader label="Team:" description="Meet the team associated with the service request." />
          <FlexRow>
            {businessDevelopment ? <DisplayKeyUser roleName="businessDevelopment" label="Business Development:" value={businessDevelopment} /> : null} <View style={{ width: 25 }} />
            {strategyConsultant ? <DisplayKeyUser roleName="strategyConsultant" label="Strategy Consultant:" value={strategyConsultant} /> : null}
          </FlexRow>
        </Section>
      ) : null}

      {tabValue == "proposals" ? (
        <Section>
          <HeaderText subHeader label="Strategy Maps:" description="See all the strategy maps submitted for this service request." />

          <ListRender
            defaultSort={{ field: "sentAt", desc: true }}
            filters={[
              {
                label: "Submitted",
                value: "submitted",
                onQuery: function (query) {
                  query.equalTo("status", "open");
                  query.greaterThan("expireDate", new Date());
                  query.descending("sentAt");
                },
                autoHide: true
              },
              {
                label: "Expiring Soon",
                value: "expiring",
                onQuery: function (query) {
                  const et = Moment()
                    .add(2, "days")
                    .toDate();

                  query.equalTo("status", "open");
                  query.lessThan("expireDate", et);
                  query.descending("sentAt");
                },
                autoHide: true
              },
              {
                label: "Expired",
                value: "expired",
                onQuery: function (query) {
                  query.equalTo("status", "open");
                  query.lessThan("expireDate", new Date());
                  query.descending("expireDate");
                },
                autoHide: true
              },
              {
                label: "Withdrawn",
                value: "withdrawn",
                onQuery: function (query) {
                  query.equalTo("status", "withdrawn");
                  query.descending("withdrawnAt");
                },
                autoHide: true
              }
            ]}
            onWhere={query => {
              query.equalTo("serviceRequest", dL.getObj("ServiceRequest", serviceRequestId));
            }}
            type="ServiceProposal"
            emptyLabel="No strategy maps found."
            emptyRender={<NoRecordsBox label="No strategy maps have been submitted yet." />}
            includes={["user", "createdBy"]}
            renderItem={item => {
              const { id, title, sentAt, total, deliveryDays, createdBy, serviceItems } = item;

              return (
                <BoxRowItem>
                  <FlexRow alignTop>
                    <FlexExpand>
                      <TextLine
                        size={22}
                        value={title}
                        top={6}
                        onPress={() => {
                          history.push(`/${sectionId}/proposal/${id}`);
                        }}
                      />

                      <UserItem label="Submitted By:" user={createdBy} style={{ marginTop: 8 }} />
                    </FlexExpand>

                    <View alignRight>
                      <Price price={total} days={deliveryDays} />
                      <DateTimeItem value={sentAt} fromNow alignRight />
                      {serviceItems ? <TextLine top={8} color="grey" value={plural(serviceItems.length, "service")} /> : null}
                    </View>
                  </FlexRow>
                </BoxRowItem>
              );
            }}
          />
        </Section>
      ) : null}
    </ChatWindow>
  );
}

export function EditServiceRequest() {
  const { serviceCategory, serviceRequestId, sectionId, projectId } = useParams();
  const history = useHistory();
  const [state, setState] = useState({ projects: [], model: { status: "draft" }, isLoading: true });
  const { isLoading, model, projects } = state;
  const [projectVisible, setProjectVisible] = useState();

  useEffect(() => {
    var promise = new PromiseA();
    var projects;
    const newState = { ...state, isLoading: false };
    if (sectionId == "user") {
      promise = dL.getMyProjects().then(function (_projects) {
        newState.projects = _projects;
      });
    } else {
      promise.resolve();
    }
    promise.then(function (projects) {
      if (serviceRequestId) {
        dL.getQuery("ServiceRequest")
          .get(serviceRequestId)
          .then(function (obj) {
            newState.model = dL.loadServiceRequest(obj);
            setState(newState);
          });
      } else {
        setState(newState);
      }
    });
  }, []);

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  const { hasRFP, project, user, budget, urgency, status, title, shortDescription, description, serviceType, companyStage, companySize, files, requestType, deliverables } = model;

  const saveRecord = function ({ isDraft, status }) {
    if (!title) {
      alert("Must enter title.");
      return;
    }

    if (!isDraft) {
      if (!requestType) {
        alert("Must select request type.");
        return;
      }

      if (!serviceType) {
        alert("Must select business sector.");
        return;
      }

      if (hasRFP) {
        if (files.length == 0) {
          alert("Must add an RFP file for review.");
          return;
        }
      }
    }

    return dL
      .saveServiceRequest(serviceRequestId, {
        deliverables,
        title,
        shortDescription,
        description,
        serviceType,
        companyStage,
        budget,
        urgency,
        status,
        companySize,
        files,
        requestType,
        status,
        hasRFP,
        projectId: projectId ? projectId : project ? project.id : null,
        userId: user ? user.id : session.user.id
      })
      .then(function (obj) {
        history.goBack();
      });
  };

  return (
    <EditDialog>
      <HeaderText label="Request for Services" description="Tell us what type of service you need and our consultants will prepare a free strategy map." />

      <MyInput
        placeholder="Enter title for request"
        maxLength={55}
        required
        label="Title:"
        value={title}
        onChange={value => {
          model.title = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        placeholder="Enter description of service requested"
        multiline
        maxLength={255}
        label="Tell us what type of services you need:"
        value={shortDescription}
        onChange={value => {
          model.shortDescription = value;
          setState({ ...state, model });
        }}
      />
      <MyInput
        placeholder="Enter list of deliverables"
        required
        multiline
        label="Tell us what deliverables are you looking for:"
        value={deliverables}
        onChange={value => {
          model.deliverables = value;
          setState({ ...state, model });
        }}
      />

      {sectionId != "user" ? (
        <LabelItem label="Client:">
          <SelectUser
            onChange={item => {
              model.user = item;
              setState({ ...state, model });
            }}
            value={user}
          />
        </LabelItem>
      ) : null}

      <FlexRow>
        <SelectBox
          required
          label="Request Type:"
          value={utils.requestTypes.find(item => item.value == requestType)}
          options={utils.requestTypes}
          onChange={item => {
            model.requestType = item.value;
            setState({ ...state, model });
          }}
        />

        <View style={{ width: 10 }} />
        <SelectBox
          required
          label="Business Sector:"
          value={utils.serviceTypes.find(item => item.value == serviceType)}
          options={utils.serviceTypes}
          onChange={item => {
            model.serviceType = item.value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      <FlexRow>
        <SelectBox
          label="Company Type:"
          value={utils.companyStages.find(item => item.value == companyStage)}
          options={utils.companyStages}
          onChange={item => {
            model.companyStage = item.value;
            setState({ ...state, model });
          }}
        />

        <View style={{ width: 10 }} />
        <SelectBox
          label="Company Size:"
          value={utils.employeeSizes.find(item => item.value == companySize)}
          options={utils.employeeSizes}
          onChange={item => {
            model.companySize = item.value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      <FlexRow>
        <SelectBox
          label="Level of Urgency:"
          value={utils.urgencyLevels.find(item => item.value == urgency)}
          options={utils.urgencyLevels}
          onChange={item => {
            model.urgency = item.value;
            setState({ ...state, model });
          }}
        />
        <View style={{ width: 10 }} />
        <SelectBox
          label="Budget:"
          value={utils.budgets.find(item => item.value == budget)}
          options={utils.budgets}
          onChange={item => {
            model.budget = item.value;
            setState({ ...state, model });
          }}
        />
      </FlexRow>

      {sectionId == "user" && !projectId ? (
        <FormItem>
          <SelectBox
            placeholder="Select existing project..."
            style={{ marginBottom: 10 }}
            label="Project:"
            value={project ? projects.find(item => item.id == project.id) : null}
            options={projects}
            valueField="id"
            textField="name"
            onChange={item => {
              model.project = item;
              setState({ ...state, model });
            }}
          />
          <TouchButton
            micro
            onPress={() => {
              setProjectVisible(true);
            }}
            label="Create New Project"
          />
        </FormItem>
      ) : null}

      <MyInput
        multiline
        label="Additional Notes:"
        value={description}
        onChange={value => {
          model.description = value;
          setState({ ...state, model });
        }}
      />

      <Toggle
        label="Is there an existing RFP (Request for Proposal):"
        value={hasRFP}
        onChange={value => {
          model.hasRFP = value
          setState({ ...state });
        }}
      />

      <FileElements
        label="Additional Files:"
        value={files}
        onChange={value => {
          model.files = value;
          setState({ ...state, model });
        }}
      />

      <FlexRow>
        <TouchButton
          label="Send Request"
          onPress={() => {
            return saveRecord({ status: "open" });
          }}
        />

        {status == "draft" ? (
          <TouchButton
            style={{ marginLeft: 10 }}
            grey
            label="Save Draft"
            onPress={() => {
              return saveRecord({ isDraft: true, status });
            }}
          />
        ) : null}
      </FlexRow>

      {projectVisible ? (
        <NewProject
          clientId={user.id}
          onCancel={() => {
            setProjectVisible(false);
          }}
          onSave={project => {
            projects.push(project);
            model.project = project;
            setState({ ...state, projects, model });
            setProjectVisible(false);
          }}
        />
      ) : null}
    </EditDialog>
  );
}
