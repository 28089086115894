import http from "root/utils/http";

var api = {
  queryOr: function () {
    //sum all the queries and then run remotely using an union
    var query = api.getQuery(arguments[0].collection);
    query._unionsQueries = arguments;
    return query;
  },
  getObj: function (collection, id, fields) {
    //convert fields that have "$" into an object (use schema sync here to get the current database schema)
    if (fields) {
      for (var key in fields) {
        var value = fields[key];

        if (value != null) {
          if (key[0] == "_") {
            if (key[1] == "p") {
              if (key[2] == "_") {
                if (typeof value == "object") {
                  //this is an included object
                  if (value._id) {
                    fields[key.slice(3)] = api.getObj(value._collection, value._id, value);
                  }
                  //if array then go through each item
                } else {
                  //create the object here
                  var sp = value.split("$");
                  fields[key.slice(3)] = api.getObj(sp[0], sp[1]);
                }
              }
            }
          }

          if (Array.isArray(value)) {
            for (var i = 0; i < value.length; i++) {
              var vle = value[i];
              if (vle) {
                if (typeof vle == "object") {
                  if (vle._collection) {
                    value[i] = api.getObj(vle._collection, vle._id, vle);
                  } else if (vle.__type == "Pointer") {
                    value[i] = api.getObj(vle.className, vle.objectId);
                  }
                }
              }
            }
          }
        }
      }
    }

    if (!fields) {
      fields = {};
    }

    var factory = {
      _object: true,
      collection: collection,
      id: id,
      fields: fields,
      dirtyFields: null,
      ops: {},
      inc: null,
      get: function (name) {
        return factory.fields[name];
      },
      forceSet: function (name, value) {
        if (value == null) {
          return factory.unset(name)
        }
        return factory.set(name, value)
      },
      set: function (name, value) {
        if (value == null && id) {
          return factory.unset(name).unset("_p_" + name);
        }
        if (!factory.dirtyFields) {
          factory.dirtyFields = {};
        }
        if (Array.isArray(value)) {
          for (var i = 0; i < value.length; i++) {
            var vle = value[i];
            if (typeof vle == "object") {
              if (vle._object) {
                value[i] = {
                  __type: "Pointer",
                  className: vle.collection,
                  objectId: vle.id
                };
              }
            }
          }
        }

        factory.dirtyFields[name] = value;
        factory.fields[name] = value;
        return factory;
      },
      increment: function (key, value) {
        if (value == null) {
          value = 1;
        }
        if (!factory.inc) {
          factory.inc = {};
        }
        factory.inc[key] = value;
        return factory;
      },
      add: function (key, value) {
        if (!value) {
          return factory;
        }
        if (typeof value == "object") {
          if (value._object) {
            value = {
              __type: "Pointer",
              className: value.collection,
              objectId: value.id
            };
          }
        }

        if (!id) {
          if (!fields[key]) {
            fields[key] = [];
          }
          fields[key].push(value);
          if (!factory.dirtyFields) {
            factory.dirtyFields = {};
          }
          factory.dirtyFields[key] = fields[key];
          return factory;
        }
        var ops = factory.ops;
        if (!ops.$push) {
          ops.$push = {};
        }
        ops.$push[key] = value;
        if (!factory.dirtyFields2) {
          factory.dirtyFields2 = {};
        }
        factory.dirtyFields2[key] = true;
        return factory;
      },
      addAll: function (key, value) {
        value = value.map(value => {
          if (typeof value == "object") {
            if (value._object) {
              value = {
                __type: "Pointer",
                className: value.collection,
                objectId: value.id
              };
            }
          }
        });

        if (!id) {
          if (!fields[key]) {
            fields[key] = [];
          }
          fields[key] = fields[key].concat(value);
          factory.dirtyFields[key] = value;
          return factory;
        }
        var ops = factory.ops;
        if (!ops.$pushAll) {
          ops.$pushAll = {};
        }
        ops.$pushAll[key] = { $each: value };
        return factory;
      },
      addUnique: function (key, value) {
        if (typeof value == "object") {
          if (value._object) {
            value = {
              __type: "Pointer",
              className: value.collection,
              objectId: value.id
            };
          }
        }

        if (!id) {
          if (!fields[key]) {
            fields[key] = [];
          }
          var vleStr = JSON.stringify(value);
          var found = false;
          for (var i = 0; i < fields[key].length; i++) {
            if (JSON.stringify(fields[key][i]) == vleStr) {
              return factory;
            }
          }
          fields[key].push(value);
          factory.dirtyFields[key] = value;
          return factory;
        }
        var ops = factory.ops;
        if (!ops.$addToSet) {
          ops.$addToSet = {};
        }
        ops.$addToSet[key] = value;

        return factory;
      },
      addUniqueAll: function (key, value) {
        value = value.map(value => {
          if (typeof value == "object") {
            if (value._object) {
              value = {
                __type: "Pointer",
                className: value.collection,
                objectId: value.id
              };
            }
          }
        });

        if (!id) {
          if (!fields[key]) {
            fields[key] = [];
          }
          var vleAdd = function (value) {
            var vleStr = JSON.stringify(value);
            var found = false;
            for (var i = 0; i < fields[key].length; i++) {
              if (JSON.stringify(fields[key][i]) == vleStr) {
                return factory;
              }
            }
            fields[key].push(value);
          };
          value.each(vleAdd);
          factory.dirtyFields[key] = value;
          return factory;
        }

        var ops = factory.ops;
        if (!ops.$addToSet) {
          ops.$addToSet = {};
        }
        ops.$addToSet[key] = { $each: value };

        return factory;
      },
      remove: function (key, value) {
        if (!value) {
          return factory;
        }
        if (typeof value == "object") {
          if (value._object) {
            value = {
              __type: "Pointer",
              className: value.collection,
              objectId: value.id
            };
          }
        }

        if (!id) {
          if (!fields[key]) {
            fields[key] = [];
          }

          var vleAdd = function (value) {
            var vleStr = JSON.stringify(value);
            var found = false;
            for (var i = 0; i < fields[key].length; i++) {
              if (JSON.stringify(fields[key][i]) == vleStr) {
                fields[key].splice(i, 1);
                return factory;
              }
            }
          };

          vleAdd(value);
          factory.dirtyFields[key] = value;
          return factory;
        }

        var ops = factory.ops;
        if (!ops.$pull) {
          ops.$pull = {};
        }
        ops.$pull[key] = value;

        return factory;
      },
      removeAll: function (key, value) {
        value = value.map(value => {
          if (typeof value == "object") {
            if (value._object) {
              value = {
                __type: "Pointer",
                className: value.collection,
                objectId: value.id
              };
            }
          }
        });

        if (!id) {
          if (!fields[key]) {
            fields[key] = [];
          }

          var vleAdd = function (value) {
            var vleStr = JSON.stringify(value);
            var found = false;
            for (var i = 0; i < fields[key].length; i++) {
              if (JSON.stringify(fields[key][i]) == vleStr) {
                fields[key].splice(i, 1);
                return factory;
              }
            }
          };

          value.each(vleAdd);
          factory.dirtyFields[key] = value;
          return factory;
        }

        var ops = factory.ops;
        if (!ops.$pull) {
          ops.$pull = {};
        }
        ops.$pull[key] = { $in: value };

        return factory;
      },
      unsetObj: function (key) {
        var ops = factory.ops;
        if (!ops.$unset) {
          ops.$unset = {};
        }
        ops.$unset["_p_" + key] = "";

        return factory;
      },
      unset: function (key) {
        var ops = factory.ops;
        if (!ops.$unset) {
          ops.$unset = {};
        }
        ops.$unset[key] = "";

        return factory;
      },
      destroy: function () {
        return http
          .run("destroyDb", {
            collection: factory.collection,
            id: factory.id
          })
          .then(function (data) {
            return factory;
          });
      },
      save: function () {
        if (!factory.dirtyFields && !factory.dirtyFields2 && !factory.inc && !factory.ops) {
          return Promise.resolve();
        }

        if (!id && !factory.fields["createdAt"]) {
          factory.set("createdAt", new Date());
        }

        factory.set("lastUpdatedAt", new Date());

        var d1 = {};
        for (var key in factory.dirtyFields) {
          var value = factory.dirtyFields[key];

          if (value != null) {
            if (typeof value == "object") {
              if (value._object) {
                value = value.collection + "$" + value.id;
                key = "_p_" + key;
              }
            }

            d1[key] = value;
          }
        }

        return http
          .run("saveDb", {
            collection: factory.collection,
            data: d1,
            ops: factory.ops,
            inc: factory.inc,
            id: factory.id,
            options: factory.options
          })
          .then(function (data) {
            factory.id = data._id;
            factory.dirtyFields = {};
            factory.ops = {}
            Object.assign(factory.fields, data);
            return factory;
          });
      }
    };
    //factory = Object.assign(factory, fields);
    return factory;
  },
  getQuery: function (collection) {
    var factory = {
      collection: collection,
      query: {},
      postQuery: null,
      options: {},
      select: function (names) {
        if (!factory.options.project) {
          factory.options.project = {};
        }

        for (var i = 0; i < names.length; i++) {
          factory.options.project[names[i]] = 1;
        }
        return factory;
      },
      exists: function (name, exists) {
        factory.query[name] = { $exists: exists == null ? true : exists };
        return factory;
      },
      existsObj: function (name, exists) {
        if (exists == null) {
          exists = true;
        }
        factory.query["_p_" + name] = { $exists: exists };
        return factory;
      },
      doesNotExist: function (name) {
        factory.query[name] = { $exists: false };
        return factory;
      },
      containedInO: function (name, values) {
        values = values.map(value => {
          if (value != null) {
            if (typeof value == "object") {
              if (value._object) {
                return value.collection + "$" + value.id;
              }
            }
          }
          return value;
        });

        factory.query["_p_" + name] = { $in: values };
        return factory;
      },
      containsAllAO: function (name, values) {
        values = values.map(value => {
          return value.id;
        });

        factory.query[name + ".objectId"] = { $all: values };
        return factory;
      },
      containedInAO: function (name, values) {
        values = values.map(value => {
          return value.id;
        });

        factory.query[name + ".objectId"] = { $in: values };
        return factory;
      },
      containedIn: function (name, values) {
        values = values.map(value => {
          if (value != null) {
            if (typeof value == "object") {
              if (value._object) {
                return value.collection + "$" + value.id;
              }
            }
          }
          return value;
        });

        factory.query[name] = { $in: values };
        return factory;
      },
      notContainedIn: function (name, values) {
        values = values.map(value => {
          if (value != null) {
            if (typeof value == "object") {
              if (value._object) {
                return value.collection + "$" + value.id;
              }
            }
          }
          return value;
        });

        factory.query[name] = { $nin: values };
        return factory;
      },
      greaterThanOrEqualTo: function (name, value) {
        factory.query[name] = { $gte: value };
        return factory;
      },
      lessThanOrEqualTo: function (name, value) {
        factory.query[name] = { $lte: value };
        return factory;
      },
      greaterThan: function (name, value) {
        factory.query[name] = { $gt: value };
        return factory;
      },
      lessThan: function (name, value) {
        factory.query[name] = { $lt: value };
        return factory;
      },
      notEqualTo: function (name, value) {
        if (value != null) {
          if (typeof value == "object") {
            if (value._object) {
              factory.query["_p_" + name] = {
                $ne: value.collection + "$" + value.id
              };
              return factory;
            }
          }
        }
        factory.query[name] = { $ne: value };
        return factory;
      },
      notEqualToAO: function (name, value) {
        factory.query[name + ".objectId"] = { $ne: value.id };
        return factory;
      },
      regEx: function (name, pattern, options) {
        factory.query[name] = { $regex: pattern, $options: options };
        return factory;
      },
      postIncludeStartsWith: function (name, value) {
        if (!factory.postQuery) {
          factory.postQuery = {};
        }
        factory.postQuery[name] = { $regex: "^" + value, $options: "i" };
        return factory;
      },
      postIncludeContainedIn: function (name, value) {
        if (!factory.postQuery) {
          factory.postQuery = {};
        }
        factory.postQuery[name] = { $in: value };
        return factory;
      },
      postIncludeNotEqualTo: function (name, value) {
        if (!factory.postQuery) {
          factory.postQuery = {};
        }
        factory.postQuery[name] = { $ne: value };
        return factory;
      },
      postIncludeEqualTo: function (name, value) {
        if (!factory.postQuery) {
          factory.postQuery = {};
        }
        factory.postQuery[name] = value;
        return factory;
      },
      startsWith: function (name, value) {
        factory.query[name] = { $regex: "^" + value, $options: "i" };
        return factory;
      },
      equalTo: function (name, value) {
        if (value != null) {
          if (typeof value == "object") {
            if (value._object) {
              factory.query["_p_" + name] = value.collection + "$" + value.id;
              return factory;
            }
          }
        }
        factory.query[name] = value;
        return factory;
      },
      equalToAO: function (name, value) {
        factory.query[name + ".objectId"] = value.id;
        return factory;
      },
      include: function (fieldName) {
        if (!factory.includes) {
          factory.includes = [];
        }
        factory.includes.push({
          field: fieldName
        });
        return factory;
      },
      ascending: function (fieldName) {
        if (!factory.orderBy) {
          factory.orderBy = [];
        }
        factory.orderBy.push({
          field: fieldName,
          asc: true
        });
        return factory;
      },
      descending: function (fieldName) {
        if (!factory.orderBy) {
          factory.orderBy = [];
        }
        factory.orderBy.push({
          field: fieldName
        });
        return factory;
      },
      select: function (fields) {
        factory.fields = fields;
        return factory;
      },
      count: function () {
        factory.options.count = true;

        return http
          .run("queryDb", {
            collection: factory.collection,
            query: factory.query,
            options: factory.options,
            fields: factory.fields
          })
          .then(function (doc) {
            //console.log(doc.count);
            return doc.count;
          });
      },
      skip: function (skip) {
        factory.options.skip = skip;
        return factory;
      },
      limit: function (limit) {
        factory.options.limit = limit;
        return factory;
      },
      first: function () {
        factory.options.limit = 1;
        return factory.find().then(function (docs) {
          if (docs.length > 0) {
            return docs[0];
          }
        });
      },
      text: function (value) {
        factory.query["$text"] = { $search: value };
        return factory;
      },
      postIncludeAgg: function (value) {
        factory.postQueryAgg = value;
        return factory;
      },
      matchAgg: function (value) {
        factory.matchAgg = value;
        return factory;
      },
      find: function () {
        //if compound query then need to create seperate queries for the main elements and then use a where and sort for the bottom elements
        return http
          .run("queryDb", {
            collection: factory.collection,
            query: factory.query,
            postQuery: factory.postQuery,
            matchAgg: factory.matchAgg,
            postQueryAgg: factory.postQueryAgg,
            options: factory.options,
            orderBy: factory.orderBy,
            includes: factory.includes,
            fields: factory.fields
          })
          .then(function (docs) {
            var arr = docs.map(doc => {
              return api.getObj(factory.collection, doc._id, doc);
            });
            //console.log(arr);
            return arr;
          });
      },
      get: function (id) {
        return http
          .run("queryGetDb", {
            collection: factory.collection,
            id: id,
            options: factory.options,
            includes: factory.includes,
            fields: factory.fields
          })
          .then(function (result) {
            //console.log(result);
            if (!result.doc) {
              throw { message: "Document not found." };
            }
            var doc = result.doc;
            return api.getObj(factory.collection, doc._id, doc);
          });
      },
      update: function (id, update) {
        return http
          .run("updateDb", {
            collection: factory.collection,
            query: {
              _id: id
            },
            update
          })
          .then(function (docs) {
            return docs;
          });
      },
      updateMany: function (query, update) {
        return http
          .run("updateDb", {
            collection: factory.collection,
            query,
            update,
            options: { multi: true }
          })
          .then(function (docs) {
            return docs;
          });
      },
      aggregate: function (query) {
        return http
          .run("aggDb", {
            collection: factory.collection,
            query: query
          })
          .then(function (docs) {
            return docs;
          });
      }
    };
    return factory;
  },
  getQuery2: function (collection) {
    var factory = {
      _type: "query",
      _collection: collection,
      query: {},
      options: {},
      add: function () { },
      or: function () { },
      select: function (names) {
        if (!factory.options.project) {
          factory.options.project = {};
        }

        for (var i = 0; i < names.length; i++) {
          factory.options.project[names[i]] = 1;
        }
        return factory;
      },
      containedIn: function (name, values) {
        factory.query[name] = { $in: values };
        return factory;
      },
      greaterThanOrEqualTo: function (name, value) {
        factory.query[name] = { $gte: value };
        return factory;
      },
      lessThanOrEqualTo: function (name, value) {
        factory.query[name] = { $lte: value };
        return factory;
      },
      greaterThan: function (name, value) {
        factory.query[name] = { $gt: value };
        return factory;
      },
      lessThan: function (name, value) {
        factory.query[name] = { $lt: value };
        return factory;
      },
      notEqualTo: function (name, value) {
        factory.query[name] = { $ne: value };
        return factory;
      },
      equalTo: function (name, value) {
        factory.query[name] = value;
        return factory;
      },
      include: function (fieldName) {
        if (!factory.includes) {
          factory.includes = [];
        }
        factory.includes.push({
          field: fieldName
        });
        return factory;
      },
      addAscending: function (fieldName) {
        if (!factory.orderBy) {
          factory.orderBy = [];
        }
        factory.orderBy.push({
          field: fieldName,
          asc: true
        });
        return factory;
      },
      addDescending: function (fieldName) {
        if (!factory.orderBy) {
          factory.orderBy = [];
        }
        factory.orderBy.push({
          field: fieldName
        });
        return factory;
      },
      select: function (fields) {
        factory.fields = fields;
        return factory;
      },
      first: function () {
        factory.options.limit = 1;
        return factory.find().then(function (docs) {
          if (docs.length > 0) {
            return docs[0];
          }
        });
      },
      find: function () {
        return http
          .run("queryDb", {
            collection: factory.collection,
            query: factory.query,
            options: factory.options,
            orderBy: factory.orderBy,
            includes: factory.includes,
            fields: factory.fields
          })
          .then(function (docs) {
            var arr = [];
            for (var i = 0; i < docs.length; i++) {
              var doc = docs[i];
              arr.push(api.getObj(factory.collection, doc._id, doc));
            }
            //console.log(arr);
            return arr;
          });
      },
      get: function (id) {
        return http
          .run("queryGetDb", {
            collection: factory.collection,
            id: id,
            options: factory.options,
            includes: factory.includes,
            fields: factory.fields
          })
          .then(function (result) {
            //console.log(result);
            if (!result.doc) {
              throw { message: "Document not found." };
            }
            var doc = result.doc;
            return api.getObj(factory.collection, doc._id, doc);
          });
      }
    };
    return factory;
  },
  getObj2: function (collection, id) {
    return {
      _type: "object",
      _collection: collection,
      id: id,
      add: function (key, value) { },
      addAll: function (key, value) { },
      addAllUnique: function (key, value) { },
      addUnique: function (key, value) { },
      destroy: function () { },
      isNew: function () { },
      isDirty: function () { },
      dirtyKeys: function () { },
      remove: function (key, value) { },
      removeAll: function (key, value) { },
      save: function () {
        //send this save query and return the promise
      },
      saveAll: function () { },
      set: function (key, value) { },
      get: function (key, value) { },
      unset: function (key, value) { },
      increment: function (key, value) { },
      has: function (key, value) { },
      revert: function () { },
      exists: function () { },
      fetch: function () { },
      toJSON: function () { },
      fromJSON: function (str) { }
    };
  }
};

export default api;
